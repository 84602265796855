{
	"s": [
		"Plasma",
		"Capital cities",
		"Heroes and villains"
	],
	"d": [
		{
			"c": [
				{
					"i": "Sometimes",
					"o": ",",
					"t": " when",
					"r": [
						{
							"p": " ",
							"b": 0.006,
							"k": {
								" ": 0.029,
								" the": 0.026,
								",": 0.025,
								" more": 0.011,
								" a": 0.01,
								" I": 0.01,
								" it": 0.009,
								" you": 0.009,
								" which": 0.009,
								" can": 0.008
							}
						},
						{
							"p": ",",
							"b": 0.002,
							"k": {
								",": 0.062,
								" the": 0.059,
								" ": 0.03,
								" and": 0.03,
								" a": 0.026,
								" in": 0.023,
								".": 0.022,
								" to": 0.016,
								" of": 0.016,
								" (": 0.014
							}
						},
						{
							"p": ",",
							"b": 0.001,
							"k": {
								",": 0.028,
								" the": 0.028,
								" and": 0.018,
								" ": 0.015,
								" a": 0.015,
								".": 0.014,
								" in": 0.014,
								" to": 0.011,
								" of": 0.01,
								"-": 0.008
							}
						},
						{
							"p": ",",
							"b": 0.001,
							"k": {
								",": 0.028,
								" the": 0.028,
								" and": 0.018,
								" ": 0.015,
								" a": 0.015,
								".": 0.014,
								" in": 0.014,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.001,
							"k": {
								",": 0.028,
								" the": 0.028,
								" and": 0.018,
								" ": 0.015,
								" a": 0.015,
								".": 0.014,
								" in": 0.014,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.001,
							"k": {
								",": 0.028,
								" the": 0.027,
								" and": 0.018,
								" ": 0.015,
								".": 0.014,
								" a": 0.014,
								" in": 0.014,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.001,
							"k": {
								",": 0.028,
								" the": 0.027,
								" and": 0.018,
								".": 0.014,
								" ": 0.014,
								" a": 0.014,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.001,
							"k": {
								",": 0.028,
								" the": 0.027,
								" and": 0.018,
								".": 0.014,
								" ": 0.014,
								" a": 0.014,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.001,
							"k": {
								",": 0.028,
								" the": 0.027,
								" and": 0.018,
								".": 0.014,
								" ": 0.014,
								" a": 0.014,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.001,
							"k": {
								",": 0.028,
								" the": 0.027,
								" and": 0.018,
								" ": 0.015,
								".": 0.014,
								" a": 0.014,
								" in": 0.014,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.001,
							"k": {
								",": 0.029,
								" the": 0.027,
								" and": 0.018,
								".": 0.015,
								" ": 0.015,
								" a": 0.014,
								" in": 0.014,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.004,
							"k": {
								",": 0.061,
								" the": 0.036,
								".": 0.027,
								" of": 0.023,
								" ": 0.02,
								" a": 0.02,
								" and": 0.018,
								" that": 0.018,
								" in": 0.017,
								" to": 0.016
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " when",
					"o": " you",
					"t": " people",
					"r": [
						{
							"p": " the",
							"b": 0.003,
							"k": {
								" the": 0.131,
								" we": 0.047,
								" it": 0.038,
								" you": 0.037,
								" times": 0.034,
								" when": 0.03,
								" a": 0.029,
								" dealing": 0.023,
								" calculating": 0.023,
								" operating": 0.022
							}
						},
						{
							"p": " you",
							"b": 0.001,
							"k": {
								" you": 0.091,
								" pressed": 0.089,
								" asked": 0.088,
								" the": 0.075,
								" we": 0.063,
								" dealing": 0.038,
								" operating": 0.032,
								" when": 0.022,
								" a": 0.017,
								" they": 0.015
							}
						},
						{
							"p": " asked",
							"b": 0.002,
							"k": {
								" asked": 0.203,
								" you": 0.161,
								" pressed": 0.133,
								" we": 0.079,
								" the": 0.077,
								" looking": 0.013,
								" a": 0.012,
								" they": 0.012,
								" it": 0.011,
								" when": 0.011
							}
						},
						{
							"p": " pressed",
							"b": 0.006,
							"k": {
								" pressed": 0.262,
								" you": 0.235,
								" we": 0.156,
								" asked": 0.087,
								" the": 0.048,
								" they": 0.031,
								" it": 0.017,
								" a": 0.01,
								" times": 0.007,
								" people": 0.006
							}
						},
						{
							"p": " you",
							"b": 0.011,
							"k": {
								" you": 0.309,
								" we": 0.154,
								" pressed": 0.151,
								" asked": 0.101,
								" the": 0.086,
								" it": 0.028,
								" they": 0.026,
								" a": 0.011,
								" people": 0.011,
								" I": 0.009
							}
						},
						{
							"p": " you",
							"b": 0.023,
							"k": {
								" you": 0.428,
								" we": 0.227,
								" asked": 0.091,
								" the": 0.072,
								" people": 0.023,
								" it": 0.021,
								" they": 0.021,
								" pressed": 0.019,
								" I": 0.012,
								" faced": 0.009
							}
						},
						{
							"p": " you",
							"b": 0.026,
							"k": {
								" you": 0.385,
								" we": 0.31,
								" the": 0.079,
								" asked": 0.068,
								" people": 0.026,
								" it": 0.023,
								" pressed": 0.016,
								" I": 0.014,
								" they": 0.014,
								" this": 0.008
							}
						},
						{
							"p": " you",
							"b": 0.029,
							"k": {
								" you": 0.553,
								" we": 0.17,
								" asked": 0.066,
								" the": 0.044,
								" people": 0.029,
								" it": 0.023,
								" pressed": 0.017,
								" they": 0.012,
								" faced": 0.011,
								" I": 0.008
							}
						},
						{
							"p": " you",
							"b": 0.021,
							"k": {
								" you": 0.61,
								" we": 0.186,
								" asked": 0.037,
								" the": 0.026,
								" it": 0.026,
								" they": 0.021,
								" people": 0.021,
								" I": 0.016,
								" someone": 0.01,
								" he": 0.006
							}
						},
						{
							"p": " you",
							"b": 0.026,
							"k": {
								" you": 0.648,
								" we": 0.114,
								" I": 0.03,
								" someone": 0.029,
								" it": 0.027,
								" people": 0.026,
								" they": 0.022,
								" the": 0.021,
								" asked": 0.019,
								" he": 0.009
							}
						},
						{
							"p": " you",
							"b": 0.013,
							"k": {
								" you": 0.443,
								" we": 0.172,
								" I": 0.161,
								" they": 0.03,
								" the": 0.029,
								" it": 0.029,
								" he": 0.019,
								" a": 0.017,
								" people": 0.013,
								" someone": 0.013
							}
						},
						{
							"p": " you",
							"b": 0.015,
							"k": {
								" you": 0.192,
								" I": 0.158,
								" the": 0.078,
								" we": 0.069,
								" a": 0.048,
								" it": 0.04,
								" they": 0.039,
								" he": 0.037,
								" she": 0.016,
								" people": 0.015
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.011,
									1.0,
									0.351,
									1.0,
									0.503,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.657,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.093,
									1.0,
									0.185,
									1.0,
									0.044,
									1.0,
									0.047,
									0.045,
									0.68,
									0.405,
									0.44,
									0.521
								],
								"e": 0.52
							},
							{
								"h": [
									0.004,
									0.056,
									0.022,
									0.761,
									0.094,
									0.114,
									0.011,
									0.022,
									0.142,
									0.071,
									1.0,
									0.926
								],
								"e": 0.25
							},
							{
								"h": [
									0.114,
									0.002,
									0.004,
									0.017,
									0.037,
									0.025,
									0.015,
									0.168,
									0.029,
									0.01,
									0.101,
									0.041
								],
								"e": 0.045
							},
							{
								"h": [
									0.01,
									0.029,
									0.19,
									0.081,
									0.002,
									0.015,
									0.062,
									0.066,
									0.183,
									0.044,
									0.007,
									0.032
								],
								"e": 0.057
							},
							{
								"h": [
									0.036,
									0.093,
									0.019,
									0.023,
									0.029,
									0.081,
									0.046,
									0.002,
									0.008,
									0.118,
									0.009,
									0.0
								],
								"e": 0.037
							},
							{
								"h": [
									0.03,
									0.0,
									0.153,
									0.211,
									0.1,
									0.03,
									0.164,
									0.122,
									0.103,
									0.006,
									0.013,
									0.05
								],
								"e": 0.078
							},
							{
								"h": [
									0.031,
									0.005,
									0.034,
									0.023,
									0.011,
									0.042,
									0.042,
									0.017,
									0.095,
									0.031,
									0.011,
									0.125
								],
								"e": 0.038
							},
							{
								"h": [
									0.095,
									0.008,
									0.006,
									0.084,
									0.009,
									0.06,
									0.041,
									0.009,
									0.022,
									0.157,
									0.02,
									0.018
								],
								"e": 0.042
							},
							{
								"h": [
									0.062,
									0.017,
									0.084,
									0.018,
									0.053,
									0.082,
									0.03,
									0.045,
									0.073,
									0.02,
									0.068,
									0.01
								],
								"e": 0.046
							},
							{
								"h": [
									0.012,
									0.012,
									0.032,
									0.159,
									0.014,
									0.037,
									0.002,
									0.056,
									0.014,
									0.003,
									0.059,
									0.005
								],
								"e": 0.032
							},
							{
								"h": [
									0.027,
									0.009,
									0.011,
									0.045,
									0.103,
									0.031,
									0.014,
									0.071,
									0.02,
									0.198,
									0.007,
									0.07
								],
								"e": 0.048
							},
							{
								"h": [
									1.0,
									0.064,
									0.033,
									0.334,
									0.093,
									0.035,
									0.019,
									0.104,
									1.0,
									0.019,
									0.22,
									0.131
								],
								"e": 0.262
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " people",
					"o": " are",
					"t": " say",
					"r": [
						{
							"p": " people",
							"b": 0.0,
							"k": {
								" people": 0.191,
								" who": 0.17,
								" can": 0.025,
								" are": 0.013,
								" you": 0.011,
								" will": 0.011,
								" living": 0.009,
								" all": 0.007,
								" were": 0.007,
								"people": 0.007
							}
						},
						{
							"p": " who",
							"b": 0.0,
							"k": {
								" who": 0.452,
								" people": 0.105,
								" can": 0.016,
								" you": 0.013,
								" are": 0.013,
								" were": 0.011,
								" like": 0.011,
								" smugglers": 0.011,
								" could": 0.009,
								" might": 0.008
							}
						},
						{
							"p": " who",
							"b": 0.001,
							"k": {
								" who": 0.376,
								" people": 0.079,
								" were": 0.022,
								" like": 0.019,
								" can": 0.017,
								" are": 0.016,
								" smugglers": 0.013,
								" you": 0.011,
								" died": 0.011,
								" would": 0.01
							}
						},
						{
							"p": " who",
							"b": 0.004,
							"k": {
								" who": 0.446,
								" are": 0.039,
								" were": 0.036,
								" like": 0.024,
								" get": 0.019,
								" smugglers": 0.014,
								" got": 0.013,
								" asked": 0.013,
								" people": 0.011,
								" have": 0.009
							}
						},
						{
							"p": " who",
							"b": 0.013,
							"k": {
								" who": 0.189,
								" are": 0.077,
								" were": 0.061,
								" got": 0.046,
								" asked": 0.031,
								" started": 0.028,
								" get": 0.022,
								" like": 0.021,
								" came": 0.021,
								" come": 0.018
							}
						},
						{
							"p": " who",
							"b": 0.022,
							"k": {
								" who": 0.134,
								" are": 0.129,
								" were": 0.06,
								" like": 0.053,
								" can": 0.033,
								" got": 0.031,
								"'s": 0.024,
								" get": 0.023,
								" asked": 0.023,
								" say": 0.022
							}
						},
						{
							"p": " are",
							"b": 0.021,
							"k": {
								" are": 0.178,
								" who": 0.118,
								" were": 0.065,
								" like": 0.056,
								" think": 0.038,
								" can": 0.032,
								" got": 0.024,
								" really": 0.023,
								" came": 0.022,
								" say": 0.021
							}
						},
						{
							"p": " are",
							"b": 0.014,
							"k": {
								" are": 0.296,
								" who": 0.173,
								" were": 0.123,
								" like": 0.027,
								" ask": 0.026,
								" think": 0.022,
								" came": 0.019,
								" asked": 0.018,
								" want": 0.017,
								" come": 0.016
							}
						},
						{
							"p": " are",
							"b": 0.011,
							"k": {
								" are": 0.623,
								" were": 0.165,
								" who": 0.038,
								" aren": 0.023,
								" ask": 0.015,
								" have": 0.013,
								" say": 0.011,
								" want": 0.01,
								" don": 0.009,
								" do": 0.008
							}
						},
						{
							"p": " ask",
							"b": 0.048,
							"k": {
								" ask": 0.254,
								" are": 0.243,
								" talk": 0.094,
								" were": 0.071,
								" say": 0.048,
								" think": 0.023,
								" who": 0.021,
								" want": 0.02,
								" do": 0.018,
								" try": 0.015
							}
						},
						{
							"p": " talk",
							"b": 0.05,
							"k": {
								" talk": 0.179,
								" are": 0.156,
								" ask": 0.151,
								" think": 0.066,
								" say": 0.05,
								" come": 0.03,
								" get": 0.025,
								" hear": 0.024,
								" try": 0.021,
								" do": 0.016
							}
						},
						{
							"p": " are",
							"b": 0.057,
							"k": {
								" are": 0.115,
								" talk": 0.06,
								" say": 0.057,
								" ask": 0.052,
								" think": 0.049,
								" have": 0.03,
								" get": 0.027,
								" see": 0.026,
								" come": 0.024,
								" do": 0.02
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.001,
									1.0,
									0.273,
									1.0,
									0.079,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									0.669
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.35,
									1.0,
									1.0,
									1.0,
									0.413,
									1.0,
									0.741,
									0.66,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.773,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.715
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									0.458,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.702,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.719,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.117,
									0.003,
									0.372,
									0.07,
									0.115,
									0.001,
									0.073,
									0.557,
									0.707,
									0.381,
									0.309,
									0.454
								],
								"e": 0.304
							},
							{
								"h": [
									0.1,
									0.305,
									0.056,
									0.495,
									0.121,
									0.113,
									0.024,
									0.041,
									0.126,
									0.092,
									1.0,
									0.011
								],
								"e": 0.189
							},
							{
								"h": [
									0.216,
									0.007,
									0.903,
									0.632,
									0.16,
									0.53,
									0.026,
									0.179,
									0.352,
									0.213,
									0.16,
									0.05
								],
								"e": 0.246
							},
							{
								"h": [
									0.001,
									0.18,
									0.622,
									0.193,
									0.003,
									0.068,
									0.166,
									0.914,
									1.0,
									1.0,
									0.045,
									0.95
								],
								"e": 0.378
							},
							{
								"h": [
									0.342,
									1.0,
									0.059,
									0.089,
									0.095,
									0.153,
									0.206,
									0.065,
									0.022,
									0.429,
									0.01,
									1.0
								],
								"e": 0.316
							},
							{
								"h": [
									0.009,
									0.0,
									0.185,
									0.967,
									0.197,
									0.008,
									0.085,
									0.046,
									0.056,
									0.016,
									0.019,
									0.014
								],
								"e": 0.095
							},
							{
								"h": [
									0.121,
									0.007,
									0.069,
									0.018,
									0.052,
									0.094,
									0.017,
									0.038,
									0.374,
									0.013,
									0.003,
									1.0
								],
								"e": 0.114
							},
							{
								"h": [
									0.085,
									0.004,
									0.004,
									0.067,
									0.006,
									0.125,
									0.04,
									0.002,
									0.05,
									0.632,
									0.008,
									0.009
								],
								"e": 0.066
							},
							{
								"h": [
									0.107,
									0.016,
									0.04,
									0.018,
									1.0,
									0.312,
									0.023,
									0.339,
									0.096,
									0.027,
									0.043,
									0.01
								],
								"e": 0.144
							},
							{
								"h": [
									0.026,
									0.008,
									0.078,
									0.964,
									0.02,
									0.044,
									0.002,
									0.107,
									0.024,
									0.003,
									1.0,
									0.005
								],
								"e": 0.124
							},
							{
								"h": [
									0.01,
									0.008,
									0.009,
									0.037,
									0.275,
									0.222,
									0.012,
									0.027,
									0.01,
									0.061,
									0.008,
									0.135
								],
								"e": 0.059
							},
							{
								"h": [
									0.907,
									0.017,
									0.011,
									0.505,
									0.136,
									0.031,
									0.009,
									0.013,
									0.843,
									0.021,
									0.243,
									0.118
								],
								"e": 0.174
							}
						],
						[
							{
								"h": [
									0.101,
									1.0,
									0.118,
									1.0,
									0.244,
									1.0,
									0.102,
									0.196,
									0.457,
									0.183,
									0.548,
									0.221
								],
								"e": 0.643
							},
							{
								"h": [
									0.076,
									0.064,
									0.068,
									0.442,
									0.124,
									0.155,
									0.04,
									0.093,
									0.064,
									0.041,
									0.779,
									1.0
								],
								"e": 0.219
							},
							{
								"h": [
									0.119,
									0.043,
									0.077,
									0.079,
									0.069,
									0.069,
									0.054,
									0.616,
									0.028,
									0.221,
									0.287,
									0.114
								],
								"e": 0.136
							},
							{
								"h": [
									0.047,
									0.164,
									1.0,
									0.034,
									0.02,
									0.082,
									1.0,
									0.345,
									0.088,
									0.126,
									0.122,
									0.117
								],
								"e": 0.253
							},
							{
								"h": [
									0.175,
									0.036,
									0.052,
									0.204,
									0.13,
									0.334,
									0.098,
									0.587,
									0.041,
									0.115,
									0.012,
									0.0
								],
								"e": 0.15
							},
							{
								"h": [
									0.015,
									0.003,
									0.053,
									0.081,
									0.061,
									0.014,
									0.03,
									0.025,
									0.027,
									0.009,
									0.036,
									0.033
								],
								"e": 0.029
							},
							{
								"h": [
									0.153,
									0.055,
									0.026,
									0.027,
									0.069,
									0.051,
									0.085,
									0.048,
									0.041,
									0.007,
									0.022,
									0.202
								],
								"e": 0.06
							},
							{
								"h": [
									0.01,
									0.03,
									0.004,
									0.054,
									0.004,
									0.027,
									0.049,
									0.007,
									0.077,
									0.085,
									0.01,
									0.011
								],
								"e": 0.028
							},
							{
								"h": [
									0.043,
									0.023,
									0.067,
									0.019,
									0.096,
									0.157,
									0.018,
									0.053,
									0.217,
									0.035,
									0.071,
									0.015
								],
								"e": 0.064
							},
							{
								"h": [
									0.091,
									0.016,
									0.133,
									0.245,
									0.012,
									0.061,
									0.003,
									0.064,
									0.03,
									0.004,
									0.138,
									0.002
								],
								"e": 0.054
							},
							{
								"h": [
									0.024,
									0.013,
									0.013,
									0.023,
									0.257,
									0.039,
									0.026,
									0.027,
									0.016,
									0.085,
									0.005,
									0.019
								],
								"e": 0.04
							},
							{
								"h": [
									1.0,
									0.018,
									0.007,
									0.277,
									0.032,
									0.033,
									0.007,
									0.015,
									1.0,
									0.01,
									0.182,
									0.083
								],
								"e": 0.154
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " say",
					"o": " that",
					"t": " plasma",
					"r": [
						{
							"p": " that",
							"b": 0.0,
							"k": {
								" that": 0.155,
								" about": 0.154,
								" it": 0.129,
								",": 0.059,
								" say": 0.049,
								" a": 0.031,
								" the": 0.031,
								" they": 0.025,
								" this": 0.024,
								" goodbye": 0.022
							}
						},
						{
							"p": " about",
							"b": 0.0,
							"k": {
								" about": 0.309,
								" it": 0.143,
								" that": 0.088,
								" goodbye": 0.062,
								" they": 0.044,
								",": 0.042,
								" this": 0.038,
								"ings": 0.029,
								" a": 0.024,
								" the": 0.022
							}
						},
						{
							"p": " it",
							"b": 0.0,
							"k": {
								" it": 0.213,
								" about": 0.193,
								" that": 0.113,
								" this": 0.111,
								" \"": 0.039,
								" they": 0.038,
								",": 0.036,
								" goodbye": 0.033,
								" the": 0.021,
								" he": 0.018
							}
						},
						{
							"p": " goodbye",
							"b": 0.0,
							"k": {
								" goodbye": 0.368,
								" it": 0.135,
								" that": 0.1,
								" about": 0.077,
								" this": 0.072,
								" they": 0.063,
								" \"": 0.048,
								",": 0.016,
								" he": 0.01,
								" the": 0.009
							}
						},
						{
							"p": " goodbye",
							"b": 0.0,
							"k": {
								" goodbye": 0.53,
								" about": 0.223,
								" that": 0.064,
								" they": 0.038,
								" it": 0.03,
								" \"": 0.016,
								",": 0.013,
								" this": 0.01,
								" '": 0.009,
								" hello": 0.006
							}
						},
						{
							"p": " goodbye",
							"b": 0.0,
							"k": {
								" goodbye": 0.908,
								" about": 0.035,
								" that": 0.01,
								" \"": 0.01,
								" aloud": 0.007,
								" it": 0.005,
								" '": 0.004,
								" hello": 0.004,
								",": 0.002,
								" this": 0.002
							}
						},
						{
							"p": " goodbye",
							"b": 0.0,
							"k": {
								" goodbye": 0.834,
								" about": 0.042,
								" that": 0.03,
								" \"": 0.029,
								" '": 0.015,
								" it": 0.01,
								" this": 0.01,
								" hello": 0.005,
								" these": 0.004,
								" anything": 0.003
							}
						},
						{
							"p": " goodbye",
							"b": 0.0,
							"k": {
								" goodbye": 0.557,
								" \"": 0.104,
								" that": 0.086,
								" about": 0.072,
								" it": 0.042,
								" '": 0.026,
								" this": 0.022,
								" things": 0.018,
								" something": 0.017,
								" anything": 0.01
							}
						},
						{
							"p": " \"",
							"b": 0.0,
							"k": {
								" \"": 0.363,
								" that": 0.264,
								" goodbye": 0.155,
								" '": 0.112,
								" this": 0.018,
								" it": 0.016,
								" they": 0.014,
								" things": 0.013,
								" something": 0.012,
								" about": 0.008
							}
						},
						{
							"p": " \"",
							"b": 0.0,
							"k": {
								" \"": 0.773,
								" '": 0.175,
								" that": 0.024,
								" goodbye": 0.009,
								" they": 0.005,
								" something": 0.005,
								" things": 0.003,
								" it": 0.002,
								" hello": 0.001,
								"!": 0.0
							}
						},
						{
							"p": " that",
							"b": 0.0,
							"k": {
								" that": 0.367,
								" they": 0.152,
								" \"": 0.15,
								" '": 0.103,
								",": 0.077,
								" it": 0.025,
								" we": 0.021,
								" something": 0.021,
								" things": 0.017,
								" I": 0.009
							}
						},
						{
							"p": " that",
							"b": 0.0,
							"k": {
								" that": 0.171,
								",": 0.164,
								" \"": 0.089,
								" they": 0.085,
								" '": 0.056,
								" I": 0.035,
								" it": 0.035,
								" the": 0.03,
								" we": 0.029,
								" to": 0.024
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.002,
									1.0,
									0.028,
									1.0,
									0.014,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.002,
									0.229
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.32,
									0.52,
									1.0,
									1.0,
									1.0,
									0.977,
									1.0,
									0.403,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.321,
									1.0,
									1.0,
									1.0,
									0.366,
									0.934,
									0.372,
									0.508,
									1.0,
									0.802
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.485,
									1.0,
									0.714,
									1.0,
									1.0,
									1.0,
									0.52,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.879,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.469,
									1.0,
									1.0,
									0.885,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.166,
									0.007,
									0.65,
									0.012,
									0.451,
									0.0,
									0.192,
									0.676,
									0.879,
									0.549,
									0.645,
									0.403
								],
								"e": 0.474
							},
							{
								"h": [
									0.504,
									0.389,
									0.102,
									0.268,
									0.135,
									0.21,
									0.096,
									0.087,
									0.091,
									0.13,
									0.862,
									0.009
								],
								"e": 0.221
							},
							{
								"h": [
									0.536,
									0.007,
									0.064,
									1.0,
									0.652,
									0.904,
									0.039,
									0.142,
									0.601,
									0.065,
									0.166,
									0.039
								],
								"e": 0.338
							},
							{
								"h": [
									0.005,
									0.108,
									0.33,
									0.109,
									0.006,
									0.117,
									0.063,
									0.127,
									1.0,
									1.0,
									0.148,
									1.0
								],
								"e": 0.333
							},
							{
								"h": [
									0.051,
									1.0,
									0.083,
									0.102,
									0.143,
									0.289,
									0.118,
									0.066,
									0.015,
									0.352,
									0.013,
									0.0
								],
								"e": 0.192
							},
							{
								"h": [
									0.003,
									0.0,
									0.186,
									1.0,
									0.266,
									0.009,
									0.007,
									0.032,
									0.048,
									0.017,
									0.021,
									0.013
								],
								"e": 0.09
							},
							{
								"h": [
									0.308,
									0.001,
									0.07,
									0.007,
									0.015,
									0.124,
									0.008,
									0.051,
									0.592,
									0.003,
									0.005,
									0.528
								],
								"e": 0.096
							},
							{
								"h": [
									0.055,
									0.015,
									0.001,
									0.184,
									0.138,
									0.069,
									0.034,
									0.004,
									0.097,
									0.452,
									0.009,
									0.035
								],
								"e": 0.075
							},
							{
								"h": [
									0.074,
									0.028,
									0.086,
									0.042,
									0.152,
									0.511,
									0.01,
									0.072,
									0.28,
									0.031,
									0.073,
									0.016
								],
								"e": 0.085
							},
							{
								"h": [
									0.008,
									0.013,
									0.056,
									0.345,
									0.007,
									0.061,
									0.002,
									0.052,
									0.042,
									0.003,
									0.1,
									0.005
								],
								"e": 0.043
							},
							{
								"h": [
									0.018,
									0.01,
									0.014,
									0.076,
									0.117,
									0.025,
									0.006,
									0.053,
									0.013,
									0.191,
									0.008,
									0.038
								],
								"e": 0.042
							},
							{
								"h": [
									1.0,
									0.087,
									0.038,
									1.0,
									0.104,
									0.047,
									0.02,
									0.083,
									1.0,
									0.028,
									0.514,
									0.251
								],
								"e": 0.253
							}
						],
						[
							{
								"h": [
									0.365,
									0.007,
									0.24,
									0.021,
									0.39,
									0.001,
									0.339,
									0.791,
									0.52,
									0.386,
									0.335,
									0.273
								],
								"e": 0.393
							},
							{
								"h": [
									0.857,
									0.198,
									0.116,
									0.258,
									0.138,
									0.052,
									0.093,
									0.115,
									0.064,
									0.075,
									0.791,
									0.005
								],
								"e": 0.199
							},
							{
								"h": [
									0.585,
									0.027,
									1.0,
									0.142,
									0.649,
									0.505,
									0.06,
									0.502,
									0.263,
									1.0,
									0.266,
									0.082
								],
								"e": 0.455
							},
							{
								"h": [
									0.005,
									0.186,
									1.0,
									0.01,
									0.005,
									0.1,
									1.0,
									1.0,
									0.165,
									0.137,
									0.125,
									0.827
								],
								"e": 0.359
							},
							{
								"h": [
									0.031,
									0.07,
									0.066,
									1.0,
									0.249,
									0.548,
									0.094,
									0.274,
									0.025,
									0.776,
									0.01,
									1.0
								],
								"e": 0.384
							},
							{
								"h": [
									0.022,
									0.0,
									0.25,
									0.213,
									0.131,
									0.003,
									0.184,
									0.067,
									0.01,
									0.008,
									0.129,
									0.009
								],
								"e": 0.071
							},
							{
								"h": [
									0.805,
									0.005,
									0.036,
									0.021,
									0.143,
									0.068,
									0.031,
									0.058,
									0.245,
									0.001,
									0.011,
									0.466
								],
								"e": 0.102
							},
							{
								"h": [
									0.072,
									0.015,
									0.001,
									0.136,
									0.06,
									0.106,
									0.03,
									0.003,
									0.199,
									0.207,
									0.009,
									0.018
								],
								"e": 0.061
							},
							{
								"h": [
									0.025,
									0.015,
									0.109,
									0.021,
									0.153,
									0.838,
									0.016,
									0.065,
									0.502,
									0.038,
									0.107,
									0.024
								],
								"e": 0.11
							},
							{
								"h": [
									0.05,
									0.011,
									0.223,
									0.441,
									0.003,
									0.167,
									0.004,
									0.072,
									0.05,
									0.003,
									0.093,
									0.002
								],
								"e": 0.072
							},
							{
								"h": [
									0.059,
									0.02,
									0.012,
									0.036,
									0.167,
									0.014,
									0.035,
									0.067,
									0.003,
									0.058,
									0.007,
									0.073
								],
								"e": 0.042
							},
							{
								"h": [
									0.739,
									0.08,
									0.011,
									0.288,
									0.05,
									0.02,
									0.008,
									0.086,
									0.444,
									0.017,
									0.106,
									0.029
								],
								"e": 0.111
							}
						],
						[
							{
								"h": [
									0.071,
									1.0,
									0.18,
									1.0,
									0.498,
									1.0,
									0.154,
									0.232,
									0.252,
									0.305,
									0.882,
									0.374
								],
								"e": 0.989
							},
							{
								"h": [
									0.117,
									0.144,
									0.289,
									0.339,
									0.175,
									0.135,
									0.117,
									0.058,
									0.085,
									0.026,
									1.0,
									1.0
								],
								"e": 0.306
							},
							{
								"h": [
									0.078,
									0.019,
									0.074,
									0.047,
									0.052,
									0.077,
									0.043,
									1.0,
									0.211,
									0.064,
									0.38,
									0.12
								],
								"e": 0.173
							},
							{
								"h": [
									0.032,
									0.018,
									0.174,
									0.059,
									0.01,
									0.085,
									0.075,
									0.901,
									0.133,
									0.07,
									0.265,
									0.255
								],
								"e": 0.154
							},
							{
								"h": [
									0.116,
									0.073,
									0.047,
									0.187,
									0.078,
									0.172,
									0.072,
									1.0,
									0.033,
									0.225,
									0.006,
									0.0
								],
								"e": 0.165
							},
							{
								"h": [
									0.02,
									0.0,
									0.023,
									0.092,
									0.12,
									0.004,
									0.014,
									0.077,
									0.019,
									0.007,
									0.226,
									0.032
								],
								"e": 0.046
							},
							{
								"h": [
									0.365,
									0.006,
									0.013,
									0.033,
									0.192,
									0.121,
									0.084,
									0.08,
									0.093,
									0.0,
									0.017,
									0.303
								],
								"e": 0.082
							},
							{
								"h": [
									0.054,
									0.014,
									0.001,
									0.109,
									0.013,
									0.034,
									0.028,
									0.007,
									0.044,
									0.091,
									0.005,
									0.008
								],
								"e": 0.03
							},
							{
								"h": [
									0.113,
									0.007,
									0.073,
									0.018,
									0.217,
									0.228,
									0.004,
									0.043,
									0.083,
									0.011,
									0.095,
									0.017
								],
								"e": 0.062
							},
							{
								"h": [
									0.011,
									0.005,
									0.049,
									0.276,
									0.002,
									0.399,
									0.001,
									0.107,
									0.019,
									0.003,
									0.081,
									0.011
								],
								"e": 0.06
							},
							{
								"h": [
									0.008,
									0.008,
									0.004,
									0.051,
									0.112,
									0.027,
									0.007,
									0.026,
									0.029,
									0.266,
									0.003,
									0.103
								],
								"e": 0.046
							},
							{
								"h": [
									0.832,
									0.078,
									0.015,
									0.709,
									0.047,
									0.024,
									0.011,
									0.112,
									0.492,
									0.019,
									0.224,
									0.116
								],
								"e": 0.156
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " plasma",
					"o": " is",
					"t": " they",
					"r": [
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.995,
								" Plasma": 0.001,
								" conduit": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.994,
								" membrane": 0.002,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.952,
								" membrane": 0.028,
								" blast": 0.001,
								" tubing": 0.001,
								" conduit": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.713,
								" membrane": 0.115,
								" oven": 0.004,
								" power": 0.003,
								" mass": 0.003,
								" fluid": 0.003,
								" blast": 0.003,
								" gel": 0.003,
								" conduit": 0.003,
								" blood": 0.002
							}
						},
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.657,
								" membrane": 0.161,
								" lip": 0.006,
								" concentrations": 0.004,
								"-": 0.003,
								" pressure": 0.003,
								" pulse": 0.003,
								" gel": 0.003,
								" pens": 0.003,
								" hots": 0.003
							}
						},
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.3,
								" membrane": 0.297,
								"-": 0.011,
								" pens": 0.01,
								" lip": 0.009,
								" and": 0.008,
								" levels": 0.008,
								" cells": 0.007,
								" therapy": 0.007,
								" particles": 0.007
							}
						},
						{
							"p": " membrane",
							"b": 0.0,
							"k": {
								" membrane": 0.391,
								" plasma": 0.2,
								"-": 0.03,
								" waste": 0.01,
								" and": 0.009,
								" levels": 0.009,
								" samples": 0.009,
								" alcohol": 0.008,
								" therapy": 0.008,
								" gun": 0.007
							}
						},
						{
							"p": " membrane",
							"b": 0.0,
							"k": {
								" membrane": 0.627,
								"-": 0.065,
								" plasma": 0.037,
								" levels": 0.009,
								" and": 0.007,
								",": 0.006,
								" is": 0.005,
								" cells": 0.005,
								" alcohol": 0.005,
								" samples": 0.005
							}
						},
						{
							"p": " membrane",
							"b": 0.0,
							"k": {
								" membrane": 0.298,
								"-": 0.137,
								" is": 0.04,
								" therapy": 0.023,
								" plasma": 0.023,
								" and": 0.018,
								",": 0.017,
								" samples": 0.011,
								" levels": 0.009,
								" cells": 0.009
							}
						},
						{
							"p": " membrane",
							"b": 0.001,
							"k": {
								" membrane": 0.209,
								" is": 0.1,
								"-": 0.075,
								" therapy": 0.046,
								",": 0.028,
								" plasma": 0.021,
								" and": 0.019,
								" can": 0.018,
								" was": 0.012,
								" or": 0.009
							}
						},
						{
							"p": " is",
							"b": 0.002,
							"k": {
								" is": 0.282,
								",": 0.038,
								" membrane": 0.038,
								" plasma": 0.034,
								" can": 0.032,
								"-": 0.026,
								" and": 0.019,
								" therapy": 0.014,
								" has": 0.013,
								" was": 0.012
							}
						},
						{
							"p": " is",
							"b": 0.004,
							"k": {
								" is": 0.221,
								",": 0.052,
								" can": 0.022,
								"-": 0.017,
								" has": 0.017,
								" plasma": 0.017,
								" and": 0.014,
								" or": 0.014,
								" was": 0.013,
								" levels": 0.012
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.0,
									1.0,
									0.003,
									0.08,
									0.001,
									1.0,
									0.381,
									1.0,
									1.0,
									0.9,
									1.0
								],
								"e": 0.522
							},
							{
								"h": [
									0.636,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.003,
									0.137
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.534,
									1.0,
									0.898,
									1.0,
									0.712,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.27,
									1.0,
									1.0,
									1.0,
									0.517,
									1.0,
									0.217,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.149,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.462,
									0.001,
									0.62,
									0.0,
									0.011,
									0.0,
									0.379,
									0.437,
									0.707,
									0.553,
									0.523,
									0.846
								],
								"e": 0.311
							},
							{
								"h": [
									0.124,
									0.23,
									0.13,
									0.211,
									0.146,
									0.215,
									0.056,
									0.11,
									0.207,
									0.101,
									0.872,
									0.008
								],
								"e": 0.195
							},
							{
								"h": [
									0.339,
									0.037,
									0.072,
									0.175,
									0.053,
									0.22,
									0.035,
									0.151,
									0.245,
									0.134,
									0.16,
									0.048
								],
								"e": 0.131
							},
							{
								"h": [
									0.001,
									0.191,
									0.008,
									0.006,
									0.004,
									0.068,
									0.032,
									0.026,
									0.051,
									0.03,
									0.021,
									0.068
								],
								"e": 0.042
							},
							{
								"h": [
									0.008,
									0.045,
									0.037,
									0.008,
									0.009,
									0.011,
									0.023,
									0.023,
									0.001,
									0.039,
									0.004,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.0,
									0.028,
									0.17,
									0.037,
									0.002,
									0.001,
									0.002,
									0.004,
									0.002,
									0.003,
									0.01
								],
								"e": 0.016
							},
							{
								"h": [
									0.023,
									0.003,
									0.043,
									0.004,
									0.012,
									0.028,
									0.005,
									0.012,
									0.003,
									0.002,
									0.002,
									0.049
								],
								"e": 0.014
							},
							{
								"h": [
									0.032,
									0.005,
									0.001,
									0.016,
									0.07,
									0.041,
									0.01,
									0.003,
									0.001,
									0.053,
									0.001,
									0.002
								],
								"e": 0.017
							},
							{
								"h": [
									0.085,
									0.009,
									0.013,
									0.018,
									0.045,
									0.113,
									0.007,
									0.063,
									0.025,
									0.007,
									0.029,
									0.009
								],
								"e": 0.033
							},
							{
								"h": [
									0.005,
									0.012,
									0.019,
									0.252,
									0.006,
									0.018,
									0.001,
									0.042,
									0.003,
									0.002,
									0.111,
									0.007
								],
								"e": 0.027
							},
							{
								"h": [
									0.013,
									0.002,
									0.003,
									0.023,
									0.016,
									0.171,
									0.004,
									0.009,
									0.008,
									0.038,
									0.008,
									0.086
								],
								"e": 0.026
							},
							{
								"h": [
									0.292,
									0.013,
									0.012,
									0.107,
									0.102,
									0.022,
									0.006,
									0.025,
									0.871,
									0.006,
									0.088,
									0.089
								],
								"e": 0.083
							}
						],
						[
							{
								"h": [
									0.367,
									0.0,
									0.558,
									0.0,
									0.012,
									0.0,
									0.458,
									0.513,
									0.437,
									0.517,
									0.301,
									0.511
								],
								"e": 0.259
							},
							{
								"h": [
									0.227,
									0.144,
									0.137,
									0.2,
									0.116,
									0.08,
									0.06,
									0.581,
									0.142,
									0.068,
									0.785,
									0.004
								],
								"e": 0.198
							},
							{
								"h": [
									0.134,
									0.141,
									0.439,
									0.284,
									0.066,
									0.219,
									0.047,
									0.521,
									0.218,
									0.9,
									0.27,
									0.077
								],
								"e": 0.224
							},
							{
								"h": [
									0.0,
									0.4,
									0.167,
									0.015,
									0.013,
									0.129,
									0.613,
									0.124,
									0.1,
									0.066,
									0.042,
									0.052
								],
								"e": 0.13
							},
							{
								"h": [
									0.056,
									0.024,
									0.057,
									0.059,
									0.004,
									0.178,
									0.073,
									0.127,
									0.001,
									0.036,
									0.015,
									0.0
								],
								"e": 0.054
							},
							{
								"h": [
									0.001,
									0.0,
									0.012,
									0.129,
									0.08,
									0.001,
									0.017,
									0.006,
									0.009,
									0.006,
									0.005,
									0.007
								],
								"e": 0.018
							},
							{
								"h": [
									0.074,
									0.009,
									0.036,
									0.005,
									0.057,
									0.061,
									0.004,
									0.029,
									0.006,
									0.001,
									0.004,
									0.049
								],
								"e": 0.025
							},
							{
								"h": [
									0.043,
									0.002,
									0.001,
									0.039,
									0.024,
									0.021,
									0.015,
									0.002,
									0.007,
									0.051,
									0.001,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.033,
									0.004,
									0.014,
									0.005,
									0.011,
									0.052,
									0.006,
									0.059,
									0.032,
									0.007,
									0.02,
									0.015
								],
								"e": 0.02
							},
							{
								"h": [
									0.009,
									0.007,
									0.031,
									0.087,
									0.003,
									0.023,
									0.002,
									0.027,
									0.003,
									0.001,
									0.039,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.017,
									0.003,
									0.003,
									0.004,
									0.011,
									0.061,
									0.006,
									0.009,
									0.004,
									0.07,
									0.004,
									0.035
								],
								"e": 0.016
							},
							{
								"h": [
									0.162,
									0.02,
									0.005,
									0.036,
									0.023,
									0.008,
									0.003,
									0.032,
									0.762,
									0.004,
									0.035,
									0.026
								],
								"e": 0.05
							}
						],
						[
							{
								"h": [
									0.287,
									0.0,
									0.165,
									0.0,
									0.017,
									0.0,
									0.218,
									1.0,
									0.278,
									0.273,
									0.164,
									0.768
								],
								"e": 0.215
							},
							{
								"h": [
									0.286,
									0.375,
									0.303,
									0.274,
									0.152,
									0.083,
									0.029,
									0.178,
									0.14,
									0.048,
									0.972,
									0.002
								],
								"e": 0.219
							},
							{
								"h": [
									0.264,
									0.12,
									0.743,
									0.986,
									0.047,
									1.0,
									0.055,
									0.997,
									0.339,
									0.668,
									0.357,
									0.1
								],
								"e": 0.386
							},
							{
								"h": [
									0.0,
									0.298,
									0.555,
									0.056,
									0.013,
									0.09,
									0.047,
									0.607,
									1.0,
									0.051,
									0.086,
									0.569
								],
								"e": 0.288
							},
							{
								"h": [
									0.082,
									0.572,
									0.206,
									0.053,
									0.003,
									0.37,
									0.084,
									0.113,
									0.001,
									0.063,
									0.006,
									1.0
								],
								"e": 0.25
							},
							{
								"h": [
									0.008,
									0.0,
									0.013,
									0.313,
									0.333,
									0.0,
									0.042,
									0.016,
									0.006,
									0.005,
									0.002,
									0.003
								],
								"e": 0.046
							},
							{
								"h": [
									0.341,
									0.004,
									0.023,
									0.007,
									0.019,
									0.243,
									0.002,
									0.033,
									0.046,
									0.0,
									0.005,
									0.096
								],
								"e": 0.056
							},
							{
								"h": [
									0.423,
									0.0,
									0.0,
									0.038,
									0.065,
									0.041,
									0.009,
									0.002,
									0.003,
									0.171,
									0.0,
									0.001
								],
								"e": 0.05
							},
							{
								"h": [
									0.05,
									0.003,
									0.025,
									0.001,
									0.117,
									0.155,
									0.004,
									0.22,
									0.009,
									0.001,
									0.012,
									0.011
								],
								"e": 0.045
							},
							{
								"h": [
									0.004,
									0.005,
									0.008,
									0.826,
									0.001,
									0.08,
									0.0,
									0.038,
									0.003,
									0.001,
									0.353,
									0.002
								],
								"e": 0.067
							},
							{
								"h": [
									0.006,
									0.0,
									0.002,
									0.005,
									0.032,
									0.451,
									0.003,
									0.006,
									0.003,
									0.083,
									0.002,
									0.044
								],
								"e": 0.04
							},
							{
								"h": [
									0.171,
									0.014,
									0.007,
									0.138,
									0.068,
									0.017,
									0.003,
									0.025,
									1.0,
									0.006,
									0.057,
									0.048
								],
								"e": 0.073
							}
						],
						[
							{
								"h": [
									0.54,
									1.0,
									0.093,
									1.0,
									1.0,
									1.0,
									0.498,
									0.318,
									0.699,
									0.054,
									1.0,
									0.607
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.166,
									0.313,
									0.235,
									0.192,
									0.22,
									0.367,
									0.289,
									0.07,
									0.022,
									1.0,
									1.0
								],
								"e": 0.458
							},
							{
								"h": [
									0.227,
									0.315,
									0.284,
									0.87,
									0.164,
									0.049,
									0.035,
									1.0,
									0.274,
									1.0,
									0.427,
									0.107
								],
								"e": 0.315
							},
							{
								"h": [
									0.086,
									0.129,
									1.0,
									0.024,
									0.007,
									0.202,
									1.0,
									0.153,
									0.008,
									0.176,
									0.213,
									0.145
								],
								"e": 0.244
							},
							{
								"h": [
									0.108,
									0.153,
									0.04,
									0.378,
									0.064,
									0.504,
									0.055,
									1.0,
									0.021,
									0.058,
									0.042,
									0.0
								],
								"e": 0.206
							},
							{
								"h": [
									0.03,
									0.0,
									0.026,
									0.097,
									0.076,
									0.002,
									0.048,
									0.077,
									0.018,
									0.024,
									0.074,
									0.018
								],
								"e": 0.037
							},
							{
								"h": [
									0.213,
									0.089,
									0.011,
									0.009,
									0.137,
									0.096,
									0.055,
									0.15,
									0.037,
									0.0,
									0.01,
									0.074
								],
								"e": 0.066
							},
							{
								"h": [
									0.069,
									0.069,
									0.002,
									0.192,
									0.075,
									0.157,
									0.084,
									0.024,
									0.039,
									0.12,
									0.005,
									0.008
								],
								"e": 0.065
							},
							{
								"h": [
									0.039,
									0.011,
									0.032,
									0.206,
									0.044,
									0.065,
									0.064,
									0.192,
									0.239,
									0.09,
									0.115,
									0.083
								],
								"e": 0.094
							},
							{
								"h": [
									0.157,
									0.006,
									0.276,
									0.292,
									0.033,
									0.079,
									0.03,
									0.035,
									0.109,
									0.027,
									0.087,
									0.008
								],
								"e": 0.08
							},
							{
								"h": [
									0.266,
									0.068,
									0.042,
									0.043,
									0.063,
									0.064,
									0.042,
									0.135,
									0.016,
									0.213,
									0.115,
									0.122
								],
								"e": 0.094
							},
							{
								"h": [
									0.244,
									0.024,
									0.053,
									0.182,
									0.332,
									0.034,
									0.037,
									0.045,
									0.974,
									0.015,
									0.323,
									0.335
								],
								"e": 0.156
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " they",
					"o": " mean",
					"t": " mean",
					"r": [
						{
							"p": "'re",
							"b": 0.001,
							"k": {
								"'re": 0.114,
								"'ll": 0.083,
								" could": 0.082,
								" can": 0.075,
								"'ve": 0.045,
								"'d": 0.035,
								" were": 0.023,
								" might": 0.022,
								" own": 0.02,
								" will": 0.015
							}
						},
						{
							"p": "'re",
							"b": 0.0,
							"k": {
								"'re": 0.23,
								"'ll": 0.16,
								"'d": 0.124,
								"'ve": 0.122,
								" could": 0.105,
								" can": 0.055,
								" might": 0.017,
								" were": 0.01,
								" should": 0.009,
								" may": 0.007
							}
						},
						{
							"p": "'ll",
							"b": 0.0,
							"k": {
								"'ll": 0.248,
								"'ve": 0.245,
								"'re": 0.183,
								"'d": 0.178,
								" could": 0.045,
								" can": 0.025,
								" might": 0.009,
								"self": 0.006,
								" should": 0.005,
								" own": 0.005
							}
						},
						{
							"p": "'ve",
							"b": 0.0,
							"k": {
								"'ve": 0.312,
								"'ll": 0.249,
								"'re": 0.135,
								"'d": 0.126,
								" could": 0.063,
								" can": 0.037,
								" should": 0.016,
								" might": 0.012,
								" will": 0.004,
								" may": 0.004
							}
						},
						{
							"p": "'ve",
							"b": 0.0,
							"k": {
								"'ve": 0.353,
								"'ll": 0.331,
								"'re": 0.09,
								" can": 0.05,
								" should": 0.028,
								"'d": 0.028,
								" might": 0.026,
								" could": 0.019,
								" will": 0.014,
								" may": 0.008
							}
						},
						{
							"p": "'ve",
							"b": 0.008,
							"k": {
								"'ve": 0.312,
								"'ll": 0.277,
								" can": 0.088,
								"'re": 0.072,
								"'d": 0.045,
								" will": 0.029,
								" might": 0.029,
								" should": 0.025,
								" could": 0.021,
								" may": 0.015
							}
						},
						{
							"p": " mean",
							"b": 0.174,
							"k": {
								" mean": 0.174,
								"'ll": 0.1,
								"'re": 0.094,
								" can": 0.088,
								"'ve": 0.083,
								" may": 0.074,
								" are": 0.056,
								" think": 0.047,
								" must": 0.041,
								" might": 0.028
							}
						},
						{
							"p": " mean",
							"b": 0.303,
							"k": {
								" mean": 0.303,
								" usually": 0.109,
								"'re": 0.084,
								" think": 0.066,
								" really": 0.057,
								" often": 0.043,
								" are": 0.04,
								" simply": 0.037,
								"'ll": 0.035,
								" can": 0.031
							}
						},
						{
							"p": " mean",
							"b": 0.428,
							"k": {
								" mean": 0.428,
								"'re": 0.282,
								" usually": 0.062,
								" don": 0.033,
								" are": 0.028,
								" often": 0.021,
								" refer": 0.02,
								" really": 0.017,
								" simply": 0.012,
								" imply": 0.011
							}
						},
						{
							"p": " mean",
							"b": 0.521,
							"k": {
								" mean": 0.521,
								" refer": 0.224,
								"'re": 0.079,
								" usually": 0.066,
								" describe": 0.026,
								" imply": 0.013,
								" are": 0.012,
								" talk": 0.011,
								" often": 0.01,
								" don": 0.007
							}
						},
						{
							"p": " mean",
							"b": 0.88,
							"k": {
								" mean": 0.88,
								"'re": 0.09,
								" are": 0.008,
								" usually": 0.008,
								" don": 0.003,
								" refer": 0.002,
								"'ve": 0.001,
								" really": 0.001,
								" often": 0.001,
								" tend": 0.001
							}
						},
						{
							"p": " mean",
							"b": 0.372,
							"k": {
								" mean": 0.372,
								" are": 0.155,
								"'re": 0.146,
								" don": 0.039,
								" usually": 0.032,
								" really": 0.02,
								" have": 0.014,
								" can": 0.014,
								" often": 0.011,
								" just": 0.01
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.005,
									1.0,
									0.032,
									0.062,
									0.079,
									1.0,
									0.452,
									0.692,
									1.0,
									0.844,
									1.0
								],
								"e": 0.653
							},
							{
								"h": [
									0.586,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.006,
									0.217
								],
								"e": 1.0
							},
							{
								"h": [
									0.835,
									1.0,
									0.275,
									1.0,
									0.443,
									0.608,
									1.0,
									0.455,
									0.274,
									0.168,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.105,
									1.0,
									1.0,
									1.0,
									0.362,
									0.306,
									1.0,
									0.397,
									1.0,
									0.621
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.418,
									1.0,
									0.803,
									1.0,
									0.639,
									0.958,
									0.298,
									1.0,
									0.685,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.651,
									0.938,
									0.197,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.958,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.331,
									1.0,
									1.0,
									0.474,
									1.0,
									1.0,
									1.0,
									0.568,
									0.635,
									1.0,
									1.0,
									0.965
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									0.806,
									1.0,
									1.0,
									1.0,
									0.292,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.877,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.848,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.133,
									0.022,
									0.51,
									0.006,
									0.024,
									0.004,
									0.116,
									0.345,
									0.659,
									0.609,
									0.611,
									0.404
								],
								"e": 0.284
							},
							{
								"h": [
									0.247,
									0.242,
									0.137,
									0.15,
									0.128,
									0.19,
									0.077,
									0.07,
									0.252,
									0.129,
									0.687,
									0.038
								],
								"e": 0.193
							},
							{
								"h": [
									0.403,
									0.01,
									0.022,
									0.574,
									0.089,
									0.158,
									0.052,
									0.053,
									0.055,
									0.007,
									0.169,
									0.034
								],
								"e": 0.133
							},
							{
								"h": [
									0.002,
									0.107,
									0.004,
									0.003,
									0.01,
									0.266,
									0.017,
									0.0,
									0.113,
									0.691,
									0.043,
									0.333
								],
								"e": 0.121
							},
							{
								"h": [
									0.01,
									0.08,
									0.053,
									0.563,
									0.046,
									0.04,
									0.047,
									0.079,
									0.01,
									0.676,
									0.013,
									0.0
								],
								"e": 0.106
							},
							{
								"h": [
									0.002,
									0.0,
									1.0,
									0.688,
									0.02,
									0.007,
									0.0,
									0.074,
									0.01,
									0.012,
									0.011,
									0.021
								],
								"e": 0.1
							},
							{
								"h": [
									0.014,
									0.002,
									0.034,
									0.086,
									0.018,
									0.063,
									0.019,
									0.055,
									0.159,
									0.005,
									0.019,
									1.0
								],
								"e": 0.081
							},
							{
								"h": [
									0.001,
									0.026,
									0.0,
									0.314,
									1.0,
									0.703,
									0.018,
									0.002,
									0.067,
									0.046,
									0.004,
									0.008
								],
								"e": 0.126
							},
							{
								"h": [
									0.098,
									0.012,
									0.117,
									0.039,
									0.014,
									0.118,
									0.182,
									0.001,
									0.252,
									0.084,
									0.092,
									0.005
								],
								"e": 0.067
							},
							{
								"h": [
									0.046,
									0.012,
									0.044,
									0.017,
									0.054,
									0.008,
									0.005,
									0.169,
									0.037,
									0.003,
									0.04,
									0.004
								],
								"e": 0.031
							},
							{
								"h": [
									0.004,
									0.01,
									0.018,
									0.02,
									0.1,
									0.039,
									0.005,
									0.046,
									0.007,
									0.004,
									0.019,
									0.002
								],
								"e": 0.02
							},
							{
								"h": [
									0.691,
									0.01,
									0.005,
									0.013,
									0.058,
									0.03,
									0.004,
									0.008,
									0.25,
									0.023,
									0.07,
									0.069
								],
								"e": 0.06
							}
						],
						[
							{
								"h": [
									0.408,
									0.05,
									0.346,
									0.015,
									1.0,
									0.01,
									0.476,
									0.333,
									0.452,
									0.349,
									0.646,
									0.251
								],
								"e": 0.5
							},
							{
								"h": [
									0.262,
									0.139,
									0.167,
									0.162,
									0.15,
									0.146,
									0.402,
									0.214,
									0.142,
									0.079,
									0.707,
									0.102
								],
								"e": 0.232
							},
							{
								"h": [
									0.229,
									0.055,
									0.111,
									0.342,
									0.043,
									0.17,
									0.054,
									0.208,
									0.01,
									0.215,
									0.337,
									0.061
								],
								"e": 0.174
							},
							{
								"h": [
									0.008,
									0.228,
									0.041,
									0.001,
									0.028,
									0.597,
									0.154,
									0.01,
									0.05,
									0.057,
									0.091,
									0.293
								],
								"e": 0.131
							},
							{
								"h": [
									0.009,
									0.18,
									0.079,
									0.94,
									0.125,
									0.184,
									0.054,
									0.326,
									0.008,
									0.344,
									0.015,
									0.0
								],
								"e": 0.155
							},
							{
								"h": [
									0.003,
									0.0,
									0.082,
									0.386,
									0.053,
									0.003,
									0.002,
									0.021,
									0.011,
									0.039,
									0.301,
									0.043
								],
								"e": 0.054
							},
							{
								"h": [
									0.437,
									0.004,
									0.033,
									0.355,
									0.14,
									0.051,
									0.042,
									0.138,
									0.041,
									0.001,
									0.027,
									0.284
								],
								"e": 0.109
							},
							{
								"h": [
									0.004,
									0.009,
									0.0,
									0.104,
									0.096,
									0.056,
									0.022,
									0.001,
									0.183,
									0.165,
									0.001,
									0.006
								],
								"e": 0.044
							},
							{
								"h": [
									0.098,
									0.006,
									0.261,
									0.028,
									0.013,
									0.205,
									0.073,
									0.004,
									0.148,
									0.168,
									0.071,
									0.013
								],
								"e": 0.077
							},
							{
								"h": [
									0.046,
									0.008,
									0.015,
									0.047,
									0.012,
									0.015,
									0.002,
									0.166,
									0.016,
									0.001,
									0.032,
									0.001
								],
								"e": 0.024
							},
							{
								"h": [
									0.008,
									0.005,
									0.006,
									0.006,
									0.053,
									0.028,
									0.004,
									0.02,
									0.011,
									0.022,
									0.005,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.559,
									0.011,
									0.002,
									0.017,
									0.023,
									0.017,
									0.002,
									0.01,
									1.0,
									0.003,
									0.057,
									0.039
								],
								"e": 0.085
							}
						],
						[
							{
								"h": [
									0.251,
									0.001,
									0.8,
									0.111,
									0.017,
									0.002,
									0.187,
									0.442,
									0.292,
									0.378,
									0.229,
									0.336
								],
								"e": 0.275
							},
							{
								"h": [
									1.0,
									0.383,
									0.383,
									0.221,
									0.189,
									0.067,
									0.08,
									0.127,
									0.176,
									0.051,
									0.975,
									0.01
								],
								"e": 0.269
							},
							{
								"h": [
									0.188,
									0.041,
									0.556,
									0.762,
									1.0,
									0.762,
									0.05,
									0.554,
									1.0,
									0.165,
									0.545,
									0.083
								],
								"e": 0.6
							},
							{
								"h": [
									0.001,
									0.178,
									0.219,
									0.073,
									0.01,
									0.538,
									0.023,
									0.044,
									0.894,
									1.0,
									0.141,
									0.407
								],
								"e": 0.278
							},
							{
								"h": [
									0.265,
									0.534,
									0.073,
									0.174,
									0.04,
									0.55,
									0.11,
									0.341,
									0.011,
									1.0,
									0.011,
									0.0
								],
								"e": 0.226
							},
							{
								"h": [
									0.01,
									0.0,
									0.17,
									1.0,
									0.087,
									0.027,
									0.006,
									0.174,
									0.701,
									0.038,
									1.0,
									0.063
								],
								"e": 0.19
							},
							{
								"h": [
									1.0,
									0.005,
									0.019,
									1.0,
									0.119,
									0.102,
									0.043,
									0.324,
									0.101,
									0.009,
									0.026,
									0.502
								],
								"e": 0.217
							},
							{
								"h": [
									0.019,
									0.008,
									0.0,
									0.709,
									0.196,
									0.114,
									0.03,
									0.001,
									1.0,
									0.159,
									0.007,
									0.009
								],
								"e": 0.146
							},
							{
								"h": [
									0.086,
									0.025,
									0.168,
									0.055,
									0.025,
									0.076,
									1.0,
									0.009,
									0.938,
									0.237,
									0.25,
									0.031
								],
								"e": 0.174
							},
							{
								"h": [
									1.0,
									0.017,
									0.02,
									0.026,
									0.158,
									0.024,
									0.017,
									0.287,
									0.015,
									0.009,
									0.183,
									0.003
								],
								"e": 0.1
							},
							{
								"h": [
									0.016,
									0.014,
									0.079,
									0.074,
									0.177,
									0.173,
									0.032,
									0.321,
									0.016,
									0.012,
									0.025,
									0.004
								],
								"e": 0.069
							},
							{
								"h": [
									0.563,
									0.02,
									0.006,
									0.015,
									0.031,
									0.049,
									0.006,
									0.01,
									0.065,
									0.022,
									0.073,
									0.079
								],
								"e": 0.049
							}
						],
						[
							{
								"h": [
									0.175,
									0.001,
									0.1,
									0.009,
									0.02,
									0.0,
									0.501,
									0.486,
									0.439,
									0.208,
									0.124,
									0.306
								],
								"e": 0.209
							},
							{
								"h": [
									0.253,
									0.288,
									0.39,
									0.229,
									0.229,
									0.047,
									0.113,
									0.37,
									0.105,
									0.019,
									1.0,
									0.005
								],
								"e": 0.236
							},
							{
								"h": [
									1.0,
									0.06,
									1.0,
									0.93,
									0.024,
									1.0,
									0.037,
									0.51,
									0.137,
									1.0,
									0.439,
									0.093
								],
								"e": 0.567
							},
							{
								"h": [
									0.001,
									0.092,
									1.0,
									0.193,
									0.004,
									0.132,
									1.0,
									1.0,
									0.619,
									0.109,
									0.129,
									1.0
								],
								"e": 0.525
							},
							{
								"h": [
									0.336,
									0.57,
									0.039,
									1.0,
									0.015,
									1.0,
									1.0,
									0.957,
									0.022,
									0.296,
									0.006,
									1.0
								],
								"e": 0.565
							},
							{
								"h": [
									0.061,
									0.001,
									0.376,
									0.414,
									1.0,
									0.038,
									0.258,
									0.034,
									0.268,
									0.037,
									0.039,
									0.028
								],
								"e": 0.191
							},
							{
								"h": [
									0.15,
									0.026,
									0.038,
									0.156,
									0.022,
									0.412,
									0.014,
									1.0,
									1.0,
									0.01,
									0.014,
									0.188
								],
								"e": 0.21
							},
							{
								"h": [
									0.071,
									0.025,
									0.001,
									0.055,
									0.047,
									0.007,
									0.024,
									0.016,
									0.022,
									0.082,
									0.009,
									0.008
								],
								"e": 0.029
							},
							{
								"h": [
									0.009,
									0.002,
									0.071,
									0.019,
									0.466,
									0.106,
									0.015,
									0.056,
									0.044,
									0.158,
									0.019,
									0.003
								],
								"e": 0.072
							},
							{
								"h": [
									0.003,
									0.0,
									0.028,
									0.139,
									0.001,
									0.001,
									0.005,
									0.041,
									0.017,
									0.001,
									0.044,
									0.003
								],
								"e": 0.022
							},
							{
								"h": [
									0.019,
									0.011,
									0.005,
									0.007,
									0.008,
									0.032,
									0.007,
									0.001,
									0.004,
									0.038,
									0.009,
									0.005
								],
								"e": 0.012
							},
							{
								"h": [
									0.236,
									0.008,
									0.013,
									0.052,
									0.018,
									0.007,
									0.014,
									0.012,
									0.067,
									0.004,
									0.003,
									0.147
								],
								"e": 0.035
							}
						],
						[
							{
								"h": [
									0.219,
									1.0,
									0.109,
									1.0,
									0.066,
									1.0,
									0.056,
									1.0,
									1.0,
									0.43,
									1.0,
									0.423
								],
								"e": 1.0
							},
							{
								"h": [
									0.803,
									0.497,
									0.65,
									0.213,
									0.191,
									0.444,
									0.065,
									0.101,
									0.27,
									0.2,
									0.806,
									1.0
								],
								"e": 0.487
							},
							{
								"h": [
									0.539,
									0.031,
									0.876,
									0.891,
									0.231,
									0.894,
									0.167,
									1.0,
									0.077,
									0.183,
									0.501,
									0.114
								],
								"e": 0.484
							},
							{
								"h": [
									0.046,
									0.32,
									0.226,
									0.17,
									0.013,
									0.079,
									0.307,
									0.063,
									0.373,
									0.2,
									0.235,
									0.348
								],
								"e": 0.225
							},
							{
								"h": [
									0.241,
									1.0,
									0.057,
									0.235,
									0.121,
									0.046,
									0.171,
									1.0,
									0.028,
									0.17,
									0.034,
									0.001
								],
								"e": 0.233
							},
							{
								"h": [
									0.034,
									0.003,
									0.031,
									0.232,
									0.195,
									0.032,
									0.264,
									0.052,
									0.186,
									0.02,
									0.058,
									0.229
								],
								"e": 0.105
							},
							{
								"h": [
									0.125,
									0.003,
									0.042,
									0.076,
									0.096,
									0.177,
									0.092,
									0.255,
									0.026,
									0.01,
									0.017,
									0.34
								],
								"e": 0.089
							},
							{
								"h": [
									0.057,
									0.059,
									0.0,
									0.036,
									0.017,
									0.028,
									0.022,
									0.001,
									0.017,
									0.074,
									0.014,
									0.002
								],
								"e": 0.027
							},
							{
								"h": [
									0.302,
									0.003,
									0.15,
									0.191,
									0.24,
									0.241,
									0.436,
									0.042,
									0.154,
									0.233,
									0.122,
									0.009
								],
								"e": 0.151
							},
							{
								"h": [
									0.341,
									0.011,
									0.016,
									0.057,
									0.009,
									0.064,
									0.006,
									0.029,
									0.079,
									0.004,
									0.266,
									0.006
								],
								"e": 0.057
							},
							{
								"h": [
									0.008,
									0.171,
									0.027,
									0.036,
									0.215,
									0.124,
									0.015,
									0.073,
									0.032,
									0.066,
									0.03,
									0.027
								],
								"e": 0.062
							},
							{
								"h": [
									0.456,
									0.022,
									0.008,
									0.033,
									0.124,
									0.141,
									0.005,
									0.018,
									0.791,
									0.03,
									0.155,
									0.103
								],
								"e": 0.112
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " mean",
					"o": " plasma",
					"t": " a",
					"r": [
						{
							"p": " mean",
							"b": 0.0,
							"k": {
								" mean": 0.989,
								" that": 0.001,
								"ings": 0.001,
								" means": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " mean",
							"b": 0.002,
							"k": {
								" mean": 0.728,
								" that": 0.057,
								"ings": 0.024,
								" right": 0.008,
								" all": 0.006,
								" life": 0.006,
								" better": 0.006,
								",": 0.005,
								" well": 0.005,
								" means": 0.005
							}
						},
						{
							"p": " well",
							"b": 0.005,
							"k": {
								" well": 0.109,
								" that": 0.108,
								"ings": 0.106,
								" now": 0.05,
								" better": 0.033,
								" all": 0.028,
								" right": 0.028,
								" things": 0.025,
								" life": 0.023,
								",": 0.019
							}
						},
						{
							"p": " that",
							"b": 0.005,
							"k": {
								" that": 0.122,
								" something": 0.049,
								" well": 0.043,
								" '": 0.041,
								" all": 0.039,
								" differently": 0.036,
								"ings": 0.032,
								" now": 0.031,
								" things": 0.031,
								" life": 0.026
							}
						},
						{
							"p": " something",
							"b": 0.003,
							"k": {
								" something": 0.111,
								" goodbye": 0.106,
								" '": 0.086,
								" that": 0.084,
								" differently": 0.055,
								" things": 0.042,
								" everything": 0.04,
								" nothing": 0.037,
								" all": 0.021,
								" exactly": 0.02
							}
						},
						{
							"p": " '",
							"b": 0.003,
							"k": {
								" '": 0.223,
								" goodbye": 0.204,
								" something": 0.142,
								" literally": 0.06,
								" that": 0.031,
								" well": 0.029,
								" aloud": 0.025,
								" everything": 0.024,
								" \"": 0.018,
								" nothing": 0.018
							}
						},
						{
							"p": " literally",
							"b": 0.002,
							"k": {
								" literally": 0.387,
								" something": 0.322,
								" '": 0.173,
								" differently": 0.012,
								" \"": 0.011,
								" goodbye": 0.011,
								" well": 0.007,
								" nothing": 0.007,
								" exactly": 0.006,
								" that": 0.005
							}
						},
						{
							"p": " something",
							"b": 0.001,
							"k": {
								" something": 0.878,
								" literally": 0.037,
								" '": 0.024,
								" anything": 0.018,
								" nothing": 0.015,
								" everything": 0.005,
								" well": 0.004,
								" \"": 0.003,
								" that": 0.002,
								" what": 0.002
							}
						},
						{
							"p": " something",
							"b": 0.002,
							"k": {
								" something": 0.857,
								" literally": 0.057,
								" anything": 0.031,
								" everything": 0.01,
								" it": 0.009,
								" nothing": 0.008,
								" '": 0.006,
								" what": 0.004,
								" that": 0.003,
								" either": 0.003
							}
						},
						{
							"p": " something",
							"b": 0.002,
							"k": {
								" something": 0.906,
								" plasma": 0.035,
								" anything": 0.016,
								" it": 0.014,
								" that": 0.006,
								" nothing": 0.004,
								" literally": 0.003,
								" a": 0.002,
								" the": 0.002,
								" what": 0.002
							}
						},
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.985,
								" something": 0.004,
								" that": 0.002,
								" Plasma": 0.002,
								" the": 0.001,
								" it": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": " plasma",
							"b": 0.035,
							"k": {
								" plasma": 0.17,
								" it": 0.078,
								" that": 0.077,
								" the": 0.06,
								" a": 0.035,
								" they": 0.027,
								" something": 0.025,
								" \"": 0.024,
								" to": 0.022,
								",": 0.016
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.0,
									0.778,
									0.003,
									0.329,
									0.022,
									1.0,
									0.361,
									1.0,
									1.0,
									0.943,
									1.0
								],
								"e": 0.562
							},
							{
								"h": [
									0.147,
									0.989,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.007,
									0.125
								],
								"e": 1.0
							},
							{
								"h": [
									0.822,
									1.0,
									0.008,
									0.505,
									0.226,
									0.352,
									1.0,
									0.53,
									0.899,
									0.178,
									1.0,
									1.0
								],
								"e": 0.995
							},
							{
								"h": [
									1.0,
									1.0,
									0.232,
									1.0,
									1.0,
									1.0,
									0.038,
									0.347,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.091,
									1.0,
									0.05,
									1.0,
									1.0,
									1.0,
									0.646,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.421,
									0.929,
									1.0,
									1.0,
									0.667,
									1.0,
									1.0,
									1.0,
									0.225,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.31,
									0.814,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.89,
									1.0,
									1.0,
									1.0,
									0.534
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.34,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.876,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.146,
									0.515,
									1.0,
									0.929,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.4,
									1.0,
									1.0,
									1.0,
									0.578,
									1.0,
									1.0,
									0.147,
									1.0,
									1.0,
									1.0,
									0.06
								],
								"e": 1.0
							},
							{
								"h": [
									0.606,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									0.138,
									0.453
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.279,
									0.001,
									0.661,
									0.0,
									0.077,
									0.0,
									0.252,
									0.289,
									0.569,
									0.748,
									0.76,
									0.329
								],
								"e": 0.281
							},
							{
								"h": [
									0.154,
									0.347,
									0.182,
									0.128,
									0.141,
									0.282,
									0.152,
									0.091,
									0.185,
									0.139,
									0.645,
									0.01
								],
								"e": 0.218
							},
							{
								"h": [
									0.503,
									0.022,
									0.0,
									0.293,
									0.065,
									0.07,
									0.089,
									0.089,
									0.107,
									0.009,
									0.214,
									0.035
								],
								"e": 0.144
							},
							{
								"h": [
									0.004,
									0.188,
									0.003,
									0.002,
									0.005,
									0.443,
									0.001,
									0.0,
									0.268,
									0.941,
									0.013,
									0.408
								],
								"e": 0.146
							},
							{
								"h": [
									0.0,
									0.039,
									0.107,
									0.043,
									0.017,
									0.003,
									0.083,
									0.005,
									0.005,
									0.579,
									0.007,
									0.0
								],
								"e": 0.062
							},
							{
								"h": [
									0.001,
									0.0,
									0.318,
									0.544,
									0.068,
									0.029,
									0.0,
									0.023,
									0.033,
									0.011,
									0.006,
									0.017
								],
								"e": 0.06
							},
							{
								"h": [
									0.021,
									0.015,
									0.032,
									0.059,
									0.013,
									0.128,
									0.014,
									0.043,
									0.063,
									0.05,
									0.004,
									0.203
								],
								"e": 0.049
							},
							{
								"h": [
									0.002,
									0.027,
									0.0,
									0.053,
									0.052,
									0.106,
									0.012,
									0.001,
									0.042,
									0.045,
									0.004,
									0.007
								],
								"e": 0.027
							},
							{
								"h": [
									0.015,
									0.121,
									0.062,
									0.075,
									0.018,
									0.075,
									0.01,
									0.002,
									0.112,
									0.03,
									0.081,
									0.016
								],
								"e": 0.048
							},
							{
								"h": [
									0.015,
									0.048,
									0.017,
									0.034,
									0.015,
									0.033,
									0.005,
									0.061,
									0.014,
									0.006,
									0.023,
									0.024
								],
								"e": 0.025
							},
							{
								"h": [
									0.007,
									0.012,
									0.01,
									0.11,
									0.035,
									0.01,
									0.002,
									0.01,
									0.023,
									0.033,
									0.007,
									0.003
								],
								"e": 0.027
							},
							{
								"h": [
									1.0,
									0.046,
									0.017,
									0.219,
									0.085,
									0.098,
									0.013,
									0.088,
									0.198,
									0.012,
									0.059,
									0.111
								],
								"e": 0.13
							}
						],
						[
							{
								"h": [
									0.374,
									0.001,
									1.0,
									0.0,
									0.072,
									0.0,
									0.286,
									0.211,
									0.484,
									0.591,
									0.457,
									0.205
								],
								"e": 0.267
							},
							{
								"h": [
									0.112,
									0.179,
									0.197,
									0.138,
									0.139,
									0.062,
									0.177,
									0.156,
									0.121,
									0.094,
									0.675,
									0.003
								],
								"e": 0.172
							},
							{
								"h": [
									0.243,
									0.058,
									0.001,
									0.109,
									0.021,
									0.075,
									0.054,
									0.245,
									0.145,
									0.143,
									0.342,
									0.058
								],
								"e": 0.147
							},
							{
								"h": [
									0.004,
									0.202,
									0.022,
									0.002,
									0.004,
									0.32,
									0.005,
									0.009,
									0.083,
									0.104,
									0.018,
									0.246
								],
								"e": 0.07
							},
							{
								"h": [
									0.002,
									0.026,
									0.051,
									0.102,
									0.019,
									0.03,
									0.091,
									0.045,
									0.008,
									0.608,
									0.006,
									0.0
								],
								"e": 0.07
							},
							{
								"h": [
									0.002,
									0.0,
									0.07,
									0.281,
									0.081,
									0.005,
									0.003,
									0.022,
									0.006,
									0.004,
									0.041,
									0.009
								],
								"e": 0.03
							},
							{
								"h": [
									0.17,
									0.045,
									0.022,
									0.293,
									0.05,
									0.076,
									0.013,
									0.048,
									0.055,
									0.002,
									0.003,
									0.169
								],
								"e": 0.071
							},
							{
								"h": [
									0.005,
									0.007,
									0.0,
									0.025,
									0.047,
									0.038,
									0.008,
									0.0,
									0.172,
									0.088,
									0.002,
									0.005
								],
								"e": 0.028
							},
							{
								"h": [
									0.023,
									0.026,
									0.133,
									0.036,
									0.021,
									0.219,
									0.027,
									0.013,
									0.198,
									0.036,
									0.045,
									0.043
								],
								"e": 0.057
							},
							{
								"h": [
									0.052,
									0.015,
									0.026,
									0.257,
									0.003,
									0.059,
									0.013,
									0.209,
									0.026,
									0.007,
									0.046,
									0.005
								],
								"e": 0.044
							},
							{
								"h": [
									0.02,
									0.007,
									0.007,
									0.018,
									0.091,
									0.008,
									0.012,
									0.005,
									0.006,
									0.042,
									0.005,
									0.007
								],
								"e": 0.022
							},
							{
								"h": [
									0.431,
									0.074,
									0.004,
									0.085,
									0.029,
									0.019,
									0.004,
									0.147,
									0.125,
									0.007,
									0.007,
									0.008
								],
								"e": 0.064
							}
						],
						[
							{
								"h": [
									0.263,
									0.004,
									0.224,
									0.002,
									0.186,
									0.003,
									0.358,
									0.369,
									0.249,
									0.481,
									0.678,
									0.325
								],
								"e": 0.237
							},
							{
								"h": [
									0.182,
									0.308,
									0.411,
									0.188,
									0.175,
									0.218,
									0.23,
									0.087,
									0.132,
									0.057,
									0.893,
									0.191
								],
								"e": 0.266
							},
							{
								"h": [
									0.318,
									0.059,
									0.001,
									0.181,
									0.126,
									0.127,
									0.052,
									0.569,
									1.0,
									0.066,
									0.511,
									0.068
								],
								"e": 0.275
							},
							{
								"h": [
									0.012,
									0.217,
									0.009,
									0.001,
									0.006,
									0.662,
									0.003,
									0.031,
									0.145,
									0.166,
									0.112,
									0.171
								],
								"e": 0.113
							},
							{
								"h": [
									0.004,
									0.093,
									0.093,
									0.004,
									0.03,
									0.005,
									0.076,
									0.196,
									0.017,
									0.136,
									0.011,
									0.0
								],
								"e": 0.058
							},
							{
								"h": [
									0.0,
									0.0,
									0.004,
									0.455,
									0.107,
									0.001,
									0.0,
									0.013,
									0.012,
									0.005,
									1.0,
									0.054
								],
								"e": 0.142
							},
							{
								"h": [
									0.087,
									0.009,
									0.02,
									0.431,
									0.062,
									0.239,
									0.04,
									0.049,
									0.095,
									0.0,
									0.006,
									0.063
								],
								"e": 0.08
							},
							{
								"h": [
									0.002,
									0.002,
									0.0,
									0.019,
									0.018,
									0.052,
									0.007,
									0.0,
									0.028,
									0.031,
									0.001,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.054,
									0.009,
									0.106,
									0.012,
									0.021,
									0.133,
									0.008,
									0.006,
									0.087,
									0.008,
									0.036,
									0.045
								],
								"e": 0.038
							},
							{
								"h": [
									0.03,
									0.002,
									0.005,
									0.323,
									0.001,
									0.209,
									0.001,
									0.09,
									0.01,
									0.004,
									0.052,
									0.003
								],
								"e": 0.037
							},
							{
								"h": [
									0.002,
									0.002,
									0.002,
									0.028,
									0.092,
									0.016,
									0.003,
									0.001,
									0.005,
									0.037,
									0.002,
									0.001
								],
								"e": 0.018
							},
							{
								"h": [
									0.555,
									0.038,
									0.005,
									0.12,
									0.036,
									0.028,
									0.005,
									0.099,
									0.112,
									0.007,
									0.01,
									0.016
								],
								"e": 0.066
							}
						],
						[
							{
								"h": [
									0.581,
									0.0,
									0.284,
									0.0,
									0.123,
									0.0,
									0.437,
									0.142,
									0.265,
									0.148,
									0.13,
									0.374
								],
								"e": 0.196
							},
							{
								"h": [
									0.15,
									0.381,
									0.424,
									0.197,
									0.234,
									0.02,
									0.278,
									0.273,
									0.073,
									0.024,
									0.935,
									0.006
								],
								"e": 0.237
							},
							{
								"h": [
									1.0,
									0.066,
									0.009,
									0.921,
									0.013,
									0.294,
									0.029,
									0.532,
									0.522,
									1.0,
									0.445,
									0.078
								],
								"e": 0.467
							},
							{
								"h": [
									0.002,
									0.05,
									1.0,
									0.017,
									0.006,
									0.304,
									0.064,
									0.529,
									0.724,
									0.616,
									0.074,
									0.669
								],
								"e": 0.307
							},
							{
								"h": [
									0.073,
									0.059,
									0.02,
									0.164,
									0.006,
									0.067,
									0.392,
									0.177,
									0.006,
									0.131,
									0.006,
									0.0
								],
								"e": 0.101
							},
							{
								"h": [
									0.142,
									0.063,
									0.297,
									0.179,
									0.391,
									0.317,
									0.013,
									0.107,
									0.933,
									0.019,
									0.003,
									0.015
								],
								"e": 0.19
							},
							{
								"h": [
									0.113,
									1.0,
									0.025,
									0.11,
									0.003,
									0.165,
									0.013,
									1.0,
									0.127,
									0.09,
									0.007,
									0.12
								],
								"e": 0.195
							},
							{
								"h": [
									0.034,
									0.045,
									0.01,
									0.048,
									0.026,
									0.109,
									0.052,
									0.12,
									1.0,
									0.055,
									0.056,
									0.144
								],
								"e": 0.124
							},
							{
								"h": [
									0.017,
									0.1,
									0.052,
									0.391,
									0.11,
									0.118,
									0.565,
									0.14,
									1.0,
									0.604,
									0.275,
									0.188
								],
								"e": 0.271
							},
							{
								"h": [
									0.641,
									0.025,
									1.0,
									1.0,
									0.14,
									0.057,
									0.415,
									0.64,
									0.185,
									0.314,
									0.329,
									0.046
								],
								"e": 0.397
							},
							{
								"h": [
									1.0,
									0.933,
									0.384,
									0.437,
									1.0,
									0.037,
									0.313,
									1.0,
									0.031,
									0.421,
									0.836,
									1.0
								],
								"e": 0.802
							},
							{
								"h": [
									0.748,
									0.167,
									0.085,
									0.708,
									0.592,
									0.236,
									0.089,
									0.417,
									0.135,
									0.107,
									1.0,
									1.0
								],
								"e": 0.494
							}
						],
						[
							{
								"h": [
									0.247,
									0.0,
									0.178,
									0.002,
									0.101,
									0.0,
									0.159,
									1.0,
									0.744,
									0.528,
									0.365,
									0.226
								],
								"e": 0.272
							},
							{
								"h": [
									1.0,
									1.0,
									0.662,
									0.178,
									0.174,
									0.1,
									0.12,
									0.093,
									0.199,
									0.248,
									0.736,
									0.002
								],
								"e": 0.394
							},
							{
								"h": [
									0.523,
									0.042,
									1.0,
									1.0,
									1.0,
									1.0,
									0.132,
									0.866,
									0.58,
									0.71,
									0.524,
									0.097
								],
								"e": 1.0
							},
							{
								"h": [
									0.001,
									0.754,
									0.961,
									0.565,
									0.005,
									0.026,
									1.0,
									1.0,
									0.372,
									0.23,
									0.092,
									0.629
								],
								"e": 0.557
							},
							{
								"h": [
									0.066,
									1.0,
									0.071,
									1.0,
									0.061,
									0.109,
									0.138,
									0.333,
									0.026,
									0.587,
									0.022,
									1.0
								],
								"e": 0.5
							},
							{
								"h": [
									0.015,
									0.0,
									1.0,
									1.0,
									0.574,
									0.001,
									1.0,
									0.046,
									0.093,
									0.012,
									0.003,
									0.019
								],
								"e": 0.243
							},
							{
								"h": [
									1.0,
									0.051,
									0.036,
									0.191,
									0.041,
									0.425,
									0.042,
									0.329,
									0.607,
									0.0,
									0.009,
									1.0
								],
								"e": 0.266
							},
							{
								"h": [
									0.104,
									0.012,
									0.0,
									0.041,
									0.032,
									0.295,
									0.013,
									0.008,
									0.096,
									0.213,
									0.005,
									0.001
								],
								"e": 0.06
							},
							{
								"h": [
									0.162,
									0.009,
									0.398,
									0.178,
									0.282,
									0.732,
									0.001,
									0.083,
									0.044,
									0.015,
									0.026,
									0.035
								],
								"e": 0.142
							},
							{
								"h": [
									0.021,
									0.008,
									0.001,
									0.947,
									0.002,
									1.0,
									0.002,
									0.178,
									0.033,
									0.004,
									0.206,
									0.009
								],
								"e": 0.116
							},
							{
								"h": [
									0.002,
									0.001,
									0.004,
									0.011,
									0.026,
									0.015,
									0.002,
									0.003,
									0.026,
									0.046,
									0.002,
									0.004
								],
								"e": 0.016
							},
							{
								"h": [
									0.229,
									0.022,
									0.002,
									0.055,
									0.052,
									0.004,
									0.002,
									0.068,
									0.341,
									0.001,
									0.008,
									0.005
								],
								"e": 0.058
							}
						],
						[
							{
								"h": [
									0.254,
									1.0,
									0.081,
									1.0,
									1.0,
									1.0,
									0.233,
									0.157,
									0.249,
									0.189,
									1.0,
									0.466
								],
								"e": 1.0
							},
							{
								"h": [
									0.082,
									0.424,
									0.602,
									0.209,
									0.198,
									0.043,
									0.177,
									0.083,
									0.127,
									0.04,
									1.0,
									1.0
								],
								"e": 0.387
							},
							{
								"h": [
									0.169,
									0.066,
									0.015,
									0.479,
									0.033,
									0.317,
									0.115,
									1.0,
									0.699,
									0.128,
									0.87,
									0.132
								],
								"e": 0.368
							},
							{
								"h": [
									0.07,
									0.206,
									0.069,
									0.032,
									0.007,
									0.034,
									0.035,
									0.089,
									0.245,
									0.103,
									0.161,
									0.47
								],
								"e": 0.125
							},
							{
								"h": [
									0.072,
									0.08,
									0.016,
									0.006,
									0.075,
									0.034,
									0.08,
									1.0,
									0.019,
									0.199,
									0.014,
									0.0
								],
								"e": 0.124
							},
							{
								"h": [
									0.006,
									0.0,
									0.006,
									0.166,
									0.661,
									0.007,
									0.011,
									0.168,
									0.026,
									0.004,
									0.066,
									0.128
								],
								"e": 0.085
							},
							{
								"h": [
									0.131,
									0.005,
									0.026,
									0.034,
									0.015,
									0.733,
									0.162,
									0.103,
									0.057,
									0.003,
									0.032,
									0.092
								],
								"e": 0.097
							},
							{
								"h": [
									0.032,
									0.008,
									0.0,
									0.261,
									0.007,
									0.025,
									0.016,
									0.001,
									0.005,
									0.076,
									0.001,
									0.007
								],
								"e": 0.033
							},
							{
								"h": [
									0.101,
									0.014,
									0.12,
									0.11,
									0.065,
									0.387,
									0.007,
									0.089,
									0.079,
									0.068,
									0.217,
									0.021
								],
								"e": 0.094
							},
							{
								"h": [
									0.068,
									0.018,
									0.017,
									0.808,
									0.007,
									0.863,
									0.005,
									0.083,
									0.009,
									0.01,
									0.426,
									0.014
								],
								"e": 0.12
							},
							{
								"h": [
									0.006,
									0.019,
									0.013,
									0.092,
									0.151,
									0.034,
									0.009,
									0.006,
									0.039,
									0.197,
									0.005,
									0.016
								],
								"e": 0.058
							},
							{
								"h": [
									0.487,
									0.069,
									0.016,
									0.04,
									0.067,
									0.054,
									0.012,
									0.172,
									1.0,
									0.034,
									0.09,
									0.086
								],
								"e": 0.173
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " a",
					"o": " plasma",
					"t": " state",
					"r": [
						{
							"p": " very",
							"b": 0.0,
							"k": {
								" very": 0.074,
								" few": 0.069,
								" lot": 0.044,
								" new": 0.039,
								" huge": 0.027,
								" serious": 0.024,
								" way": 0.023,
								" different": 0.023,
								" small": 0.022,
								" single": 0.022
							}
						},
						{
							"p": " lot",
							"b": 0.0,
							"k": {
								" lot": 0.186,
								" few": 0.066,
								" very": 0.064,
								" new": 0.045,
								" different": 0.04,
								" small": 0.033,
								" great": 0.03,
								" huge": 0.026,
								" single": 0.023,
								" piece": 0.016
							}
						},
						{
							"p": " lot",
							"b": 0.0,
							"k": {
								" lot": 0.465,
								" few": 0.106,
								" very": 0.042,
								" new": 0.028,
								" bunch": 0.025,
								" little": 0.023,
								" small": 0.02,
								" couple": 0.019,
								" different": 0.018,
								" good": 0.017
							}
						},
						{
							"p": " lot",
							"b": 0.0,
							"k": {
								" lot": 0.798,
								" good": 0.025,
								" few": 0.021,
								" different": 0.021,
								" bunch": 0.014,
								" great": 0.012,
								" new": 0.007,
								" little": 0.006,
								" small": 0.006,
								" higher": 0.005
							}
						},
						{
							"p": " lot",
							"b": 0.0,
							"k": {
								" lot": 0.916,
								" good": 0.02,
								" few": 0.014,
								" bunch": 0.008,
								" different": 0.005,
								" great": 0.004,
								" little": 0.004,
								" very": 0.002,
								" bit": 0.002,
								" certain": 0.002
							}
						},
						{
							"p": " lot",
							"b": 0.0,
							"k": {
								" lot": 0.832,
								" good": 0.058,
								" different": 0.012,
								" bit": 0.01,
								" great": 0.007,
								" whole": 0.007,
								" better": 0.006,
								" very": 0.005,
								" full": 0.005,
								" bunch": 0.005
							}
						},
						{
							"p": " lot",
							"b": 0.0,
							"k": {
								" lot": 0.859,
								" different": 0.013,
								" bunch": 0.013,
								" good": 0.011,
								" special": 0.01,
								" small": 0.007,
								" whole": 0.007,
								" large": 0.006,
								" very": 0.005,
								" great": 0.004
							}
						},
						{
							"p": " lot",
							"b": 0.0,
							"k": {
								" lot": 0.752,
								" large": 0.031,
								" bunch": 0.024,
								" small": 0.019,
								" different": 0.017,
								" good": 0.015,
								" little": 0.012,
								" great": 0.009,
								" very": 0.007,
								" special": 0.007
							}
						},
						{
							"p": " lot",
							"b": 0.0,
							"k": {
								" lot": 0.682,
								" bunch": 0.05,
								" small": 0.042,
								" large": 0.033,
								" different": 0.032,
								" little": 0.021,
								" mixture": 0.013,
								" whole": 0.01,
								" bit": 0.008,
								" combination": 0.007
							}
						},
						{
							"p": " lot",
							"b": 0.0,
							"k": {
								" lot": 0.261,
								" mixture": 0.164,
								" plasma": 0.152,
								" combination": 0.048,
								" bunch": 0.045,
								" particle": 0.045,
								" small": 0.03,
								" different": 0.023,
								" liquid": 0.016,
								" molecule": 0.014
							}
						},
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.584,
								" lot": 0.092,
								" particle": 0.04,
								" mixture": 0.03,
								" liquid": 0.02,
								" molecule": 0.016,
								" very": 0.008,
								" small": 0.008,
								" bunch": 0.007,
								" thin": 0.007
							}
						},
						{
							"p": " plasma",
							"b": 0.001,
							"k": {
								" plasma": 0.091,
								" lot": 0.078,
								" very": 0.018,
								" liquid": 0.016,
								" substance": 0.016,
								" small": 0.012,
								" particle": 0.011,
								" certain": 0.01,
								" different": 0.009,
								" little": 0.009
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.002,
									1.0,
									0.041,
									0.717,
									0.072,
									1.0,
									0.255,
									0.038,
									1.0,
									1.0,
									1.0
								],
								"e": 0.517
							},
							{
								"h": [
									0.961,
									1.0,
									0.954,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.009,
									0.138
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.027,
									0.427,
									1.0,
									0.253,
									1.0,
									0.601,
									0.175,
									0.251,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.128,
									0.467,
									1.0,
									1.0,
									0.461,
									0.201,
									0.092,
									0.256,
									1.0,
									0.587
								],
								"e": 1.0
							},
							{
								"h": [
									0.07,
									0.429,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.407,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									0.17,
									1.0,
									0.907,
									0.545,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.792,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.683,
									1.0,
									1.0,
									1.0,
									1.0,
									0.776,
									1.0,
									1.0,
									0.404,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.897,
									1.0,
									0.444,
									0.274,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.464,
									1.0,
									0.504,
									0.116,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.895,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.694,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									0.745,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.164,
									0.003,
									0.958,
									0.01,
									0.259,
									0.159,
									0.111,
									0.139,
									0.056,
									0.735,
									0.569,
									0.384
								],
								"e": 0.257
							},
							{
								"h": [
									0.212,
									0.257,
									0.169,
									0.1,
									0.143,
									0.23,
									0.11,
									0.078,
									0.208,
									0.199,
									0.568,
									0.004
								],
								"e": 0.186
							},
							{
								"h": [
									0.339,
									0.023,
									0.001,
									0.079,
									0.101,
									0.047,
									0.036,
									0.071,
									0.013,
									0.006,
									0.226,
									0.028
								],
								"e": 0.089
							},
							{
								"h": [
									0.001,
									0.132,
									0.0,
									0.0,
									0.007,
									0.161,
									0.006,
									0.0,
									0.003,
									0.025,
									0.009,
									0.128
								],
								"e": 0.051
							},
							{
								"h": [
									0.0,
									0.052,
									0.095,
									0.068,
									0.036,
									0.007,
									0.048,
									0.025,
									0.007,
									0.082,
									0.006,
									0.0
								],
								"e": 0.041
							},
							{
								"h": [
									0.001,
									0.0,
									0.05,
									0.557,
									0.003,
									0.006,
									0.001,
									0.009,
									0.008,
									0.009,
									0.01,
									0.01
								],
								"e": 0.025
							},
							{
								"h": [
									0.063,
									0.005,
									0.025,
									0.029,
									0.015,
									0.049,
									0.005,
									0.013,
									0.003,
									0.007,
									0.003,
									0.168
								],
								"e": 0.022
							},
							{
								"h": [
									0.002,
									0.033,
									0.0,
									0.02,
									0.014,
									0.106,
									0.013,
									0.004,
									0.024,
									0.052,
									0.005,
									0.002
								],
								"e": 0.019
							},
							{
								"h": [
									0.021,
									0.012,
									0.035,
									0.036,
									0.014,
									0.055,
									0.004,
									0.004,
									0.023,
									0.009,
									0.049,
									0.011
								],
								"e": 0.023
							},
							{
								"h": [
									0.006,
									0.006,
									0.015,
									0.008,
									0.005,
									0.014,
									0.001,
									0.021,
									0.016,
									0.004,
									0.029,
									0.004
								],
								"e": 0.011
							},
							{
								"h": [
									0.012,
									0.006,
									0.005,
									0.021,
									0.03,
									0.002,
									0.004,
									0.021,
									0.006,
									0.012,
									0.006,
									0.027
								],
								"e": 0.011
							},
							{
								"h": [
									0.402,
									0.018,
									0.008,
									0.079,
									0.059,
									0.012,
									0.006,
									0.016,
									0.318,
									0.008,
									0.09,
									0.044
								],
								"e": 0.058
							}
						],
						[
							{
								"h": [
									0.251,
									0.004,
									0.352,
									0.007,
									0.158,
									0.087,
									0.312,
									0.117,
									0.056,
									0.512,
									0.447,
									0.333
								],
								"e": 0.195
							},
							{
								"h": [
									0.161,
									0.101,
									0.194,
									0.111,
									0.142,
									0.143,
									0.143,
									0.181,
									0.108,
									0.092,
									0.617,
									0.004
								],
								"e": 0.159
							},
							{
								"h": [
									0.206,
									0.065,
									0.004,
									0.076,
									0.058,
									0.057,
									0.034,
									0.248,
									0.013,
									0.117,
									0.353,
									0.051
								],
								"e": 0.118
							},
							{
								"h": [
									0.001,
									0.091,
									0.011,
									0.0,
									0.015,
									0.136,
									0.075,
									0.006,
									0.003,
									0.007,
									0.03,
									0.032
								],
								"e": 0.044
							},
							{
								"h": [
									0.001,
									0.025,
									0.042,
									0.065,
									0.025,
									0.046,
									0.06,
									0.071,
									0.01,
									0.116,
									0.016,
									0.0
								],
								"e": 0.044
							},
							{
								"h": [
									0.001,
									0.0,
									0.05,
									0.333,
									0.007,
									0.003,
									0.001,
									0.024,
									0.004,
									0.011,
									0.054,
									0.007
								],
								"e": 0.023
							},
							{
								"h": [
									0.119,
									0.026,
									0.026,
									0.046,
									0.097,
									0.049,
									0.01,
									0.019,
									0.003,
									0.001,
									0.005,
									0.064
								],
								"e": 0.03
							},
							{
								"h": [
									0.002,
									0.016,
									0.0,
									0.032,
									0.012,
									0.055,
									0.015,
									0.001,
									0.016,
									0.077,
									0.004,
									0.001
								],
								"e": 0.017
							},
							{
								"h": [
									0.022,
									0.002,
									0.094,
									0.008,
									0.017,
									0.047,
									0.004,
									0.005,
									0.041,
									0.008,
									0.032,
									0.031
								],
								"e": 0.026
							},
							{
								"h": [
									0.006,
									0.006,
									0.023,
									0.015,
									0.0,
									0.011,
									0.002,
									0.016,
									0.016,
									0.002,
									0.01,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.017,
									0.003,
									0.004,
									0.004,
									0.057,
									0.002,
									0.004,
									0.009,
									0.007,
									0.005,
									0.003,
									0.007
								],
								"e": 0.009
							},
							{
								"h": [
									0.203,
									0.037,
									0.004,
									0.019,
									0.013,
									0.008,
									0.004,
									0.033,
									0.198,
									0.005,
									0.009,
									0.004
								],
								"e": 0.031
							}
						],
						[
							{
								"h": [
									0.181,
									0.001,
									0.456,
									0.026,
									0.279,
									0.071,
									0.407,
									0.223,
									0.035,
									0.392,
									0.307,
									0.396
								],
								"e": 0.201
							},
							{
								"h": [
									0.209,
									0.285,
									0.369,
									0.15,
									0.179,
									0.121,
									0.098,
									0.135,
									0.127,
									0.08,
									0.816,
									0.003
								],
								"e": 0.188
							},
							{
								"h": [
									0.15,
									0.048,
									0.025,
									0.202,
									0.15,
									0.109,
									0.038,
									0.449,
									0.092,
									0.084,
									0.38,
									0.05
								],
								"e": 0.169
							},
							{
								"h": [
									0.001,
									0.151,
									0.016,
									0.023,
									0.006,
									0.146,
									0.034,
									0.025,
									0.054,
									0.052,
									0.047,
									0.208
								],
								"e": 0.085
							},
							{
								"h": [
									0.02,
									0.139,
									0.113,
									0.093,
									0.018,
									0.079,
									0.105,
									0.072,
									0.009,
									0.178,
									0.008,
									0.0
								],
								"e": 0.077
							},
							{
								"h": [
									0.001,
									0.0,
									0.094,
									0.872,
									0.036,
									0.0,
									0.008,
									0.081,
									0.011,
									0.016,
									0.081,
									0.008
								],
								"e": 0.051
							},
							{
								"h": [
									0.301,
									0.022,
									0.027,
									0.055,
									0.146,
									0.245,
									0.008,
									0.042,
									0.062,
									0.0,
									0.005,
									0.121
								],
								"e": 0.059
							},
							{
								"h": [
									0.028,
									0.005,
									0.0,
									0.03,
									0.02,
									0.113,
									0.009,
									0.0,
									0.07,
									0.124,
									0.001,
									0.001
								],
								"e": 0.027
							},
							{
								"h": [
									0.054,
									0.002,
									0.103,
									0.001,
									0.058,
									0.192,
									0.001,
									0.035,
									0.015,
									0.001,
									0.013,
									0.018
								],
								"e": 0.037
							},
							{
								"h": [
									0.002,
									0.002,
									0.004,
									0.093,
									0.0,
									0.035,
									0.0,
									0.009,
									0.008,
									0.001,
									0.05,
									0.002
								],
								"e": 0.017
							},
							{
								"h": [
									0.004,
									0.001,
									0.002,
									0.005,
									0.076,
									0.012,
									0.002,
									0.007,
									0.002,
									0.005,
									0.002,
									0.009
								],
								"e": 0.01
							},
							{
								"h": [
									0.205,
									0.026,
									0.005,
									0.072,
									0.041,
									0.009,
									0.004,
									0.018,
									0.178,
									0.005,
									0.03,
									0.026
								],
								"e": 0.037
							}
						],
						[
							{
								"h": [
									0.254,
									0.0,
									0.093,
									0.011,
									0.31,
									0.003,
									0.628,
									0.383,
									0.077,
									0.217,
									0.154,
									0.379
								],
								"e": 0.185
							},
							{
								"h": [
									0.338,
									0.208,
									0.41,
									0.158,
									0.244,
									0.062,
									0.295,
									0.337,
									0.1,
									0.045,
									0.906,
									0.002
								],
								"e": 0.236
							},
							{
								"h": [
									0.674,
									0.096,
									0.043,
									0.224,
									0.067,
									0.125,
									0.022,
									0.545,
									0.082,
									0.992,
									0.46,
									0.072
								],
								"e": 0.306
							},
							{
								"h": [
									0.0,
									0.041,
									0.288,
									0.039,
									0.01,
									0.191,
									0.756,
									0.365,
									0.023,
									0.228,
									0.151,
									0.065
								],
								"e": 0.224
							},
							{
								"h": [
									0.044,
									0.077,
									0.022,
									0.488,
									0.015,
									0.127,
									0.235,
									0.589,
									0.021,
									0.183,
									0.008,
									0.0
								],
								"e": 0.147
							},
							{
								"h": [
									0.067,
									0.001,
									0.069,
									0.247,
									0.128,
									0.04,
									0.059,
									0.245,
									0.063,
									0.05,
									0.088,
									0.031
								],
								"e": 0.08
							},
							{
								"h": [
									0.896,
									0.315,
									0.015,
									0.045,
									0.066,
									0.108,
									0.02,
									0.576,
									0.01,
									0.016,
									0.015,
									0.436
								],
								"e": 0.136
							},
							{
								"h": [
									0.027,
									0.206,
									0.006,
									0.197,
									0.007,
									1.0,
									0.13,
									0.084,
									1.0,
									0.099,
									0.113,
									0.021
								],
								"e": 0.206
							},
							{
								"h": [
									0.029,
									0.007,
									0.056,
									0.311,
									0.136,
									0.138,
									0.123,
									0.149,
									1.0,
									0.269,
									0.281,
									0.25
								],
								"e": 0.25
							},
							{
								"h": [
									1.0,
									0.016,
									1.0,
									0.102,
									0.036,
									0.054,
									0.087,
									0.18,
									0.255,
									0.1,
									0.136,
									0.031
								],
								"e": 0.252
							},
							{
								"h": [
									1.0,
									0.137,
									0.135,
									0.199,
									0.173,
									0.026,
									0.12,
									1.0,
									0.027,
									0.064,
									0.278,
									0.937
								],
								"e": 0.269
							},
							{
								"h": [
									0.418,
									0.052,
									0.045,
									0.236,
									0.188,
									0.069,
									0.068,
									0.092,
									0.395,
									0.033,
									1.0,
									0.832
								],
								"e": 0.219
							}
						],
						[
							{
								"h": [
									0.176,
									0.0,
									0.208,
									0.164,
									0.404,
									0.031,
									0.095,
									0.584,
									0.147,
									0.53,
									0.239,
									0.403
								],
								"e": 0.232
							},
							{
								"h": [
									1.0,
									0.604,
									0.616,
									0.143,
									0.188,
									0.179,
									0.108,
									0.129,
									0.222,
									0.251,
									0.737,
									0.003
								],
								"e": 0.302
							},
							{
								"h": [
									0.528,
									0.041,
									0.456,
									0.567,
									0.454,
									0.514,
									0.11,
									0.856,
									0.131,
									0.619,
									0.442,
									0.079
								],
								"e": 0.488
							},
							{
								"h": [
									0.002,
									0.453,
									0.372,
									0.091,
									0.007,
									0.048,
									1.0,
									0.282,
									0.114,
									0.193,
									0.092,
									0.092
								],
								"e": 0.27
							},
							{
								"h": [
									0.025,
									0.3,
									0.056,
									0.274,
									0.036,
									0.038,
									0.11,
									0.143,
									0.023,
									0.203,
									0.036,
									0.0
								],
								"e": 0.108
							},
							{
								"h": [
									0.073,
									0.0,
									0.676,
									0.912,
									0.103,
									0.009,
									0.025,
									0.074,
									0.072,
									0.029,
									0.055,
									0.01
								],
								"e": 0.109
							},
							{
								"h": [
									1.0,
									0.04,
									0.042,
									0.052,
									0.084,
									0.221,
									0.009,
									0.078,
									0.044,
									0.001,
									0.004,
									0.305
								],
								"e": 0.085
							},
							{
								"h": [
									0.043,
									0.005,
									0.0,
									0.073,
									0.005,
									0.152,
									0.012,
									0.001,
									0.009,
									0.1,
									0.002,
									0.001
								],
								"e": 0.026
							},
							{
								"h": [
									0.123,
									0.003,
									0.058,
									0.017,
									0.022,
									0.201,
									0.0,
									0.014,
									0.007,
									0.001,
									0.012,
									0.017
								],
								"e": 0.035
							},
							{
								"h": [
									0.001,
									0.001,
									0.003,
									0.051,
									0.0,
									0.075,
									0.0,
									0.008,
									0.012,
									0.001,
									0.037,
									0.001
								],
								"e": 0.016
							},
							{
								"h": [
									0.002,
									0.002,
									0.002,
									0.002,
									0.018,
									0.003,
									0.002,
									0.006,
									0.003,
									0.003,
									0.003,
									0.005
								],
								"e": 0.004
							},
							{
								"h": [
									0.099,
									0.008,
									0.003,
									0.027,
									0.02,
									0.001,
									0.002,
									0.007,
									0.305,
									0.001,
									0.007,
									0.004
								],
								"e": 0.025
							}
						],
						[
							{
								"h": [
									0.244,
									0.0,
									0.341,
									0.177,
									0.677,
									0.015,
									0.74,
									0.896,
									0.087,
									0.289,
									0.174,
									0.507
								],
								"e": 0.312
							},
							{
								"h": [
									0.505,
									0.741,
									0.567,
									0.171,
									0.226,
									0.106,
									0.093,
									0.157,
									0.116,
									0.081,
									1.0,
									0.002
								],
								"e": 0.259
							},
							{
								"h": [
									0.54,
									0.046,
									1.0,
									1.0,
									0.286,
									1.0,
									0.096,
									1.0,
									1.0,
									1.0,
									0.74,
									0.12
								],
								"e": 0.885
							},
							{
								"h": [
									0.0,
									0.553,
									1.0,
									1.0,
									0.007,
									0.058,
									0.283,
									1.0,
									1.0,
									1.0,
									0.104,
									1.0
								],
								"e": 0.841
							},
							{
								"h": [
									1.0,
									1.0,
									0.054,
									0.164,
									0.02,
									0.139,
									0.184,
									0.339,
									0.026,
									0.427,
									0.023,
									1.0
								],
								"e": 0.514
							},
							{
								"h": [
									0.011,
									0.0,
									0.291,
									0.7,
									1.0,
									0.012,
									1.0,
									1.0,
									0.03,
									0.077,
									0.095,
									0.016
								],
								"e": 0.286
							},
							{
								"h": [
									0.966,
									0.009,
									0.057,
									0.012,
									0.073,
									0.742,
									0.022,
									0.066,
									0.228,
									0.003,
									0.013,
									0.723
								],
								"e": 0.14
							},
							{
								"h": [
									1.0,
									0.008,
									0.001,
									0.172,
									0.004,
									0.145,
									0.021,
									0.001,
									0.026,
									0.28,
									0.004,
									0.009
								],
								"e": 0.11
							},
							{
								"h": [
									0.133,
									0.008,
									0.153,
									0.01,
									0.045,
									1.0,
									0.002,
									1.0,
									0.024,
									0.004,
									0.067,
									0.015
								],
								"e": 0.167
							},
							{
								"h": [
									0.012,
									0.009,
									0.009,
									1.0,
									0.0,
									0.19,
									0.002,
									0.015,
									0.011,
									0.003,
									0.422,
									0.003
								],
								"e": 0.136
							},
							{
								"h": [
									0.012,
									0.018,
									0.007,
									0.028,
									0.12,
									0.05,
									0.007,
									0.028,
									0.018,
									0.074,
									0.006,
									0.282
								],
								"e": 0.045
							},
							{
								"h": [
									0.283,
									0.021,
									0.012,
									0.034,
									0.057,
									0.013,
									0.008,
									0.024,
									1.0,
									0.018,
									0.172,
									0.184
								],
								"e": 0.095
							}
						],
						[
							{
								"h": [
									0.064,
									1.0,
									0.141,
									1.0,
									1.0,
									1.0,
									0.026,
									1.0,
									1.0,
									0.531,
									0.967,
									0.361
								],
								"e": 1.0
							},
							{
								"h": [
									0.744,
									0.518,
									1.0,
									0.168,
									0.199,
									0.186,
									0.132,
									0.159,
									0.261,
									0.276,
									0.771,
									1.0
								],
								"e": 0.529
							},
							{
								"h": [
									0.345,
									0.025,
									0.094,
									0.474,
									0.396,
									0.149,
									0.183,
									0.757,
									0.086,
									0.104,
									0.385,
									0.092
								],
								"e": 0.302
							},
							{
								"h": [
									0.085,
									0.442,
									0.264,
									0.049,
									0.025,
									0.069,
									0.349,
									0.118,
									0.041,
									0.126,
									0.276,
									0.103
								],
								"e": 0.212
							},
							{
								"h": [
									0.039,
									0.12,
									0.016,
									0.214,
									0.081,
									0.078,
									0.06,
									1.0,
									0.02,
									0.065,
									0.038,
									0.0
								],
								"e": 0.138
							},
							{
								"h": [
									0.012,
									0.003,
									0.053,
									0.102,
									0.039,
									0.015,
									0.052,
									0.037,
									0.023,
									0.016,
									0.021,
									0.141
								],
								"e": 0.041
							},
							{
								"h": [
									0.106,
									0.018,
									0.024,
									0.004,
									0.056,
									0.137,
									0.076,
									0.073,
									0.028,
									0.004,
									0.005,
									0.07
								],
								"e": 0.039
							},
							{
								"h": [
									0.052,
									0.031,
									0.007,
									0.085,
									0.003,
									0.039,
									0.025,
									0.021,
									0.015,
									0.059,
									0.011,
									0.005
								],
								"e": 0.027
							},
							{
								"h": [
									0.056,
									0.021,
									0.085,
									0.07,
									0.04,
									0.073,
									0.04,
									0.166,
									0.077,
									0.046,
									0.103,
									0.018
								],
								"e": 0.068
							},
							{
								"h": [
									0.021,
									0.031,
									0.04,
									0.072,
									0.002,
									0.053,
									0.003,
									0.023,
									0.022,
									0.005,
									0.062,
									0.009
								],
								"e": 0.03
							},
							{
								"h": [
									0.034,
									0.038,
									0.02,
									0.05,
									0.126,
									0.011,
									0.008,
									0.134,
									0.014,
									0.038,
									0.034,
									0.286
								],
								"e": 0.053
							},
							{
								"h": [
									0.484,
									0.044,
									0.019,
									0.027,
									0.061,
									0.014,
									0.018,
									0.062,
									0.554,
									0.008,
									0.128,
									0.164
								],
								"e": 0.088
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " state",
					"o": " of",
					"t": " of",
					"r": [
						{
							"p": " state",
							"b": 0.001,
							"k": {
								" state": 0.943,
								"state": 0.014,
								" State": 0.002,
								"-": 0.001,
								" of": 0.001,
								"less": 0.001,
								" post": 0.001,
								" body": 0.001,
								" states": 0.001,
								"room": 0.001
							}
						},
						{
							"p": " state",
							"b": 0.004,
							"k": {
								" state": 0.709,
								"wide": 0.11,
								"less": 0.03,
								"state": 0.02,
								" transition": 0.006,
								"-": 0.004,
								" of": 0.004,
								"room": 0.004,
								"'s": 0.003,
								" record": 0.003
							}
						},
						{
							"p": " state",
							"b": 0.009,
							"k": {
								" state": 0.582,
								"less": 0.25,
								"state": 0.053,
								"wide": 0.025,
								" machine": 0.01,
								" of": 0.009,
								" transition": 0.004,
								"room": 0.003,
								"-": 0.002,
								" post": 0.002
							}
						},
						{
							"p": "less",
							"b": 0.051,
							"k": {
								"less": 0.406,
								"wide": 0.242,
								" state": 0.215,
								" of": 0.051,
								"state": 0.012,
								"mate": 0.006,
								" transition": 0.004,
								"-": 0.003,
								" that": 0.002,
								" release": 0.002
							}
						},
						{
							"p": "less",
							"b": 0.085,
							"k": {
								"less": 0.415,
								" state": 0.258,
								"wide": 0.129,
								" of": 0.085,
								"state": 0.035,
								"room": 0.006,
								"-": 0.004,
								" health": 0.003,
								" transition": 0.003,
								"mate": 0.003
							}
						},
						{
							"p": " of",
							"b": 0.421,
							"k": {
								" of": 0.421,
								"less": 0.291,
								" state": 0.129,
								"wide": 0.098,
								"-": 0.009,
								" where": 0.009,
								"state": 0.006,
								" that": 0.004,
								" or": 0.002,
								"ually": 0.002
							}
						},
						{
							"p": " of",
							"b": 0.629,
							"k": {
								" of": 0.629,
								"less": 0.24,
								" state": 0.034,
								"wide": 0.022,
								" where": 0.016,
								"-": 0.009,
								"state": 0.008,
								" that": 0.006,
								" or": 0.005,
								" which": 0.003
							}
						},
						{
							"p": " of",
							"b": 0.683,
							"k": {
								" of": 0.683,
								"less": 0.231,
								"wide": 0.016,
								" where": 0.012,
								" state": 0.01,
								"-": 0.007,
								" that": 0.006,
								" or": 0.005,
								"state": 0.004,
								",": 0.002
							}
						},
						{
							"p": " of",
							"b": 0.971,
							"k": {
								" of": 0.971,
								"less": 0.012,
								" where": 0.006,
								"-": 0.002,
								" or": 0.002,
								" that": 0.001,
								" state": 0.001,
								" controlled": 0.001,
								"!": 0.0,
								"\"": 0.0
							}
						},
						{
							"p": " of",
							"b": 0.776,
							"k": {
								" of": 0.776,
								" where": 0.183,
								" wherein": 0.009,
								" that": 0.006,
								" which": 0.006,
								" or": 0.004,
								"less": 0.003,
								"-": 0.002,
								" controlled": 0.002,
								",": 0.001
							}
						},
						{
							"p": " of",
							"b": 0.968,
							"k": {
								" of": 0.968,
								" where": 0.019,
								" that": 0.002,
								" or": 0.002,
								",": 0.001,
								"-": 0.001,
								" in": 0.001,
								" which": 0.001,
								" wherein": 0.001,
								"!": 0.0
							}
						},
						{
							"p": " of",
							"b": 0.714,
							"k": {
								" of": 0.714,
								",": 0.03,
								" or": 0.03,
								" that": 0.029,
								" in": 0.028,
								" where": 0.024,
								".": 0.022,
								"-": 0.015,
								" with": 0.006,
								" which": 0.005
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.0,
									1.0,
									0.002,
									0.326,
									0.003,
									0.608,
									0.311,
									1.0,
									1.0,
									0.503,
									1.0
								],
								"e": 0.49
							},
							{
								"h": [
									0.109,
									0.598,
									0.885,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.01,
									0.073
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.313,
									0.187,
									0.862,
									0.477,
									1.0,
									0.825,
									0.219,
									0.38,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.192,
									1.0,
									1.0,
									1.0,
									0.47,
									1.0,
									0.391,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.291,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									0.381,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.14,
									1.0,
									1.0,
									1.0,
									1.0,
									0.913,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.558
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.495,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.622
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.171,
									0.002,
									0.246,
									0.0,
									0.056,
									0.0,
									0.056,
									0.142,
									0.518,
									0.478,
									0.48,
									0.461
								],
								"e": 0.17
							},
							{
								"h": [
									0.033,
									0.128,
									0.216,
									0.088,
									0.14,
									0.243,
									0.117,
									0.069,
									0.19,
									0.201,
									0.523,
									0.004
								],
								"e": 0.185
							},
							{
								"h": [
									0.246,
									0.034,
									0.001,
									0.017,
									0.008,
									0.067,
									0.052,
									0.109,
									0.038,
									0.007,
									0.223,
									0.028
								],
								"e": 0.075
							},
							{
								"h": [
									0.0,
									0.075,
									0.0,
									0.0,
									0.005,
									0.093,
									0.007,
									0.001,
									0.007,
									0.029,
									0.006,
									0.141
								],
								"e": 0.029
							},
							{
								"h": [
									0.002,
									0.032,
									0.068,
									0.007,
									0.004,
									0.003,
									0.043,
									0.005,
									0.005,
									0.089,
									0.004,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.002,
									0.0,
									0.089,
									0.462,
									0.006,
									0.007,
									0.002,
									0.005,
									0.012,
									0.004,
									0.011,
									0.021
								],
								"e": 0.023
							},
							{
								"h": [
									0.003,
									0.01,
									0.038,
									0.033,
									0.019,
									0.022,
									0.005,
									0.018,
									0.001,
									0.001,
									0.003,
									0.119
								],
								"e": 0.019
							},
							{
								"h": [
									0.0,
									0.006,
									0.0,
									0.009,
									0.014,
									0.041,
									0.005,
									0.002,
									0.004,
									0.006,
									0.001,
									0.007
								],
								"e": 0.007
							},
							{
								"h": [
									0.012,
									0.027,
									0.039,
									0.016,
									0.004,
									0.039,
									0.003,
									0.001,
									0.044,
									0.008,
									0.035,
									0.01
								],
								"e": 0.019
							},
							{
								"h": [
									0.003,
									0.004,
									0.016,
									0.012,
									0.005,
									0.012,
									0.002,
									0.034,
									0.006,
									0.002,
									0.004,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.002,
									0.003,
									0.005,
									0.008,
									0.005,
									0.023,
									0.002,
									0.01,
									0.004,
									0.005,
									0.003,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.208,
									0.006,
									0.006,
									0.027,
									0.029,
									0.014,
									0.005,
									0.024,
									0.431,
									0.006,
									0.057,
									0.027
								],
								"e": 0.038
							}
						],
						[
							{
								"h": [
									0.198,
									0.003,
									0.342,
									0.0,
									0.074,
									0.001,
									0.192,
									0.139,
									0.503,
									0.368,
									0.289,
									0.222
								],
								"e": 0.161
							},
							{
								"h": [
									0.086,
									0.075,
									0.217,
									0.092,
									0.128,
									0.202,
									0.323,
									0.264,
									0.089,
									0.104,
									0.546,
									0.004
								],
								"e": 0.197
							},
							{
								"h": [
									0.182,
									0.106,
									0.02,
									0.014,
									0.034,
									0.076,
									0.032,
									0.316,
									0.074,
									0.093,
									0.349,
									0.046
								],
								"e": 0.116
							},
							{
								"h": [
									0.002,
									0.091,
									0.001,
									0.002,
									0.015,
									0.078,
									0.018,
									0.005,
									0.027,
									0.048,
									0.011,
									0.011
								],
								"e": 0.025
							},
							{
								"h": [
									0.028,
									0.016,
									0.062,
									0.046,
									0.008,
									0.003,
									0.064,
									0.019,
									0.002,
									0.064,
									0.016,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.001,
									0.0,
									0.015,
									0.356,
									0.004,
									0.002,
									0.001,
									0.006,
									0.004,
									0.014,
									0.009,
									0.014
								],
								"e": 0.014
							},
							{
								"h": [
									0.006,
									0.01,
									0.04,
									0.051,
									0.051,
									0.018,
									0.008,
									0.028,
									0.002,
									0.0,
									0.004,
									0.019
								],
								"e": 0.021
							},
							{
								"h": [
									0.0,
									0.002,
									0.0,
									0.007,
									0.005,
									0.02,
									0.008,
									0.0,
									0.005,
									0.008,
									0.0,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.006,
									0.002,
									0.034,
									0.002,
									0.001,
									0.024,
									0.001,
									0.002,
									0.02,
									0.003,
									0.013,
									0.017
								],
								"e": 0.01
							},
							{
								"h": [
									0.002,
									0.001,
									0.023,
									0.013,
									0.0,
									0.007,
									0.003,
									0.055,
									0.003,
									0.002,
									0.003,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.006,
									0.002,
									0.002,
									0.001,
									0.004,
									0.005,
									0.003,
									0.004,
									0.003,
									0.013,
									0.001,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.196,
									0.012,
									0.002,
									0.009,
									0.014,
									0.005,
									0.004,
									0.041,
									0.275,
									0.003,
									0.004,
									0.002
								],
								"e": 0.027
							}
						],
						[
							{
								"h": [
									0.09,
									0.003,
									0.132,
									0.0,
									0.029,
									0.0,
									0.066,
									0.177,
									0.355,
									0.352,
									0.314,
									0.449
								],
								"e": 0.127
							},
							{
								"h": [
									0.057,
									0.207,
									0.402,
									0.131,
									0.162,
									0.163,
									0.158,
									0.158,
									0.186,
									0.047,
									0.782,
									0.027
								],
								"e": 0.215
							},
							{
								"h": [
									0.129,
									0.205,
									0.011,
									0.04,
									0.0,
									0.135,
									0.037,
									0.5,
									0.098,
									0.049,
									0.421,
									0.045
								],
								"e": 0.147
							},
							{
								"h": [
									0.0,
									0.052,
									0.01,
									0.001,
									0.002,
									0.105,
									0.013,
									0.005,
									0.159,
									0.01,
									0.007,
									0.102
								],
								"e": 0.037
							},
							{
								"h": [
									0.028,
									0.167,
									0.119,
									0.038,
									0.005,
									0.011,
									0.102,
									0.009,
									0.001,
									0.153,
									0.002,
									0.0
								],
								"e": 0.048
							},
							{
								"h": [
									0.002,
									0.0,
									0.012,
									0.859,
									0.037,
									0.001,
									0.002,
									0.007,
									0.009,
									0.009,
									0.027,
									0.006
								],
								"e": 0.029
							},
							{
								"h": [
									0.025,
									0.02,
									0.044,
									0.073,
									0.071,
									0.17,
									0.004,
									0.029,
									0.025,
									0.0,
									0.003,
									0.11
								],
								"e": 0.04
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.009,
									0.028,
									0.047,
									0.007,
									0.0,
									0.007,
									0.021,
									0.001,
									0.003
								],
								"e": 0.009
							},
							{
								"h": [
									0.013,
									0.01,
									0.042,
									0.0,
									0.004,
									0.167,
									0.001,
									0.014,
									0.014,
									0.001,
									0.01,
									0.009
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.002,
									0.003,
									0.07,
									0.0,
									0.023,
									0.001,
									0.027,
									0.003,
									0.002,
									0.015,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.002,
									0.002,
									0.003,
									0.009,
									0.036,
									0.004,
									0.006,
									0.002,
									0.008,
									0.002,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.223,
									0.006,
									0.005,
									0.032,
									0.027,
									0.017,
									0.005,
									0.021,
									0.171,
									0.023,
									0.027,
									0.025
								],
								"e": 0.033
							}
						],
						[
							{
								"h": [
									0.258,
									0.0,
									0.095,
									0.001,
									0.481,
									0.0,
									1.0,
									0.185,
									0.205,
									0.337,
									0.175,
									0.435
								],
								"e": 0.26
							},
							{
								"h": [
									0.377,
									0.177,
									0.463,
									0.137,
									0.225,
									0.095,
									0.606,
									0.659,
									0.078,
									0.042,
									0.815,
									0.002
								],
								"e": 0.336
							},
							{
								"h": [
									0.705,
									0.133,
									0.146,
									0.236,
									0.329,
									0.27,
									0.018,
									0.595,
									0.119,
									0.944,
									0.535,
									0.071
								],
								"e": 0.348
							},
							{
								"h": [
									0.0,
									0.082,
									0.036,
									0.032,
									0.019,
									0.339,
									0.549,
									0.208,
									0.047,
									0.109,
									0.06,
									0.137
								],
								"e": 0.124
							},
							{
								"h": [
									0.668,
									0.044,
									0.02,
									0.513,
									0.012,
									0.075,
									0.155,
									0.339,
									0.019,
									0.18,
									0.021,
									0.0
								],
								"e": 0.135
							},
							{
								"h": [
									0.019,
									0.0,
									0.034,
									0.258,
									0.037,
									0.033,
									0.013,
									0.043,
									0.061,
									0.067,
									0.056,
									0.022
								],
								"e": 0.04
							},
							{
								"h": [
									0.108,
									0.047,
									0.028,
									0.063,
									0.064,
									0.04,
									0.013,
									0.341,
									0.025,
									0.003,
									0.009,
									0.117
								],
								"e": 0.065
							},
							{
								"h": [
									0.01,
									0.072,
									0.001,
									0.026,
									0.003,
									0.548,
									0.062,
									0.022,
									0.406,
									0.014,
									0.011,
									0.013
								],
								"e": 0.07
							},
							{
								"h": [
									0.046,
									0.006,
									0.024,
									0.052,
									0.014,
									0.058,
									0.025,
									0.054,
									0.539,
									0.039,
									0.075,
									0.077
								],
								"e": 0.07
							},
							{
								"h": [
									0.193,
									0.003,
									0.447,
									0.08,
									0.014,
									0.028,
									0.02,
									0.085,
									0.047,
									0.015,
									0.023,
									0.004
								],
								"e": 0.066
							},
							{
								"h": [
									0.209,
									0.063,
									0.05,
									0.02,
									0.018,
									0.021,
									0.032,
									0.058,
									0.006,
									0.113,
									0.062,
									0.178
								],
								"e": 0.063
							},
							{
								"h": [
									0.185,
									0.016,
									0.065,
									0.16,
									0.099,
									0.017,
									0.081,
									0.028,
									0.998,
									0.032,
									0.211,
									1.0
								],
								"e": 0.155
							}
						],
						[
							{
								"h": [
									0.25,
									0.0,
									0.239,
									0.001,
									0.064,
									0.0,
									0.069,
									0.448,
									0.574,
									0.312,
									0.164,
									0.308
								],
								"e": 0.16
							},
							{
								"h": [
									0.369,
									0.476,
									0.664,
									0.129,
									0.181,
									0.237,
									0.133,
									0.137,
									0.339,
									0.223,
									0.665,
									0.002
								],
								"e": 0.329
							},
							{
								"h": [
									0.409,
									0.088,
									1.0,
									0.312,
									0.493,
									0.92,
									0.099,
									0.846,
									0.496,
									1.0,
									0.5,
									0.07
								],
								"e": 0.565
							},
							{
								"h": [
									0.0,
									0.795,
									0.124,
									0.119,
									0.004,
									0.081,
									0.212,
									0.341,
									0.212,
									0.462,
									0.036,
									0.184
								],
								"e": 0.182
							},
							{
								"h": [
									0.703,
									0.309,
									0.067,
									0.349,
									0.012,
									0.156,
									0.158,
									0.055,
									0.003,
									0.311,
									0.023,
									0.0
								],
								"e": 0.136
							},
							{
								"h": [
									0.029,
									0.0,
									0.33,
									0.871,
									0.214,
									0.003,
									0.024,
									0.043,
									0.083,
									0.028,
									0.015,
									0.012
								],
								"e": 0.075
							},
							{
								"h": [
									0.166,
									0.246,
									0.051,
									0.146,
									0.105,
									0.154,
									0.011,
									0.058,
									0.06,
									0.0,
									0.008,
									0.342
								],
								"e": 0.097
							},
							{
								"h": [
									0.016,
									0.0,
									0.0,
									0.017,
									0.006,
									0.088,
									0.013,
									0.0,
									0.008,
									0.02,
									0.0,
									0.02
								],
								"e": 0.012
							},
							{
								"h": [
									0.025,
									0.008,
									0.068,
									0.01,
									0.002,
									0.092,
									0.001,
									0.013,
									0.01,
									0.0,
									0.007,
									0.013
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.002,
									0.002,
									0.053,
									0.0,
									0.016,
									0.0,
									0.022,
									0.007,
									0.002,
									0.003,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.001,
									0.004,
									0.008,
									0.007,
									0.006,
									0.002,
									0.004,
									0.015,
									0.008,
									0.002,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.062,
									0.018,
									0.007,
									0.011,
									0.042,
									0.005,
									0.003,
									0.029,
									0.663,
									0.001,
									0.002,
									0.004
								],
								"e": 0.031
							}
						],
						[
							{
								"h": [
									0.123,
									0.001,
									0.06,
									0.004,
									0.052,
									0.0,
									0.108,
									0.441,
									0.614,
									0.257,
									0.157,
									0.435
								],
								"e": 0.142
							},
							{
								"h": [
									0.149,
									0.559,
									0.588,
									0.154,
									0.193,
									0.146,
									0.075,
									0.228,
									0.162,
									0.046,
									0.973,
									0.012
								],
								"e": 0.273
							},
							{
								"h": [
									0.379,
									0.086,
									0.321,
									0.501,
									0.024,
									0.571,
									0.1,
									0.976,
									1.0,
									0.778,
									0.808,
									0.102
								],
								"e": 0.513
							},
							{
								"h": [
									0.0,
									0.188,
									0.14,
									0.158,
									0.001,
									0.085,
									0.114,
									0.314,
									1.0,
									0.32,
									0.021,
									0.468
								],
								"e": 0.214
							},
							{
								"h": [
									0.997,
									0.456,
									0.18,
									0.109,
									0.008,
									0.081,
									0.234,
									0.141,
									0.004,
									0.206,
									0.008,
									0.0
								],
								"e": 0.147
							},
							{
								"h": [
									0.004,
									0.0,
									0.052,
									0.69,
									1.0,
									0.001,
									0.092,
									0.28,
									0.004,
									0.03,
									0.056,
									0.01
								],
								"e": 0.119
							},
							{
								"h": [
									0.288,
									0.023,
									0.119,
									0.035,
									0.081,
									1.0,
									0.013,
									0.056,
									0.371,
									0.0,
									0.01,
									1.0
								],
								"e": 0.176
							},
							{
								"h": [
									0.088,
									0.001,
									0.0,
									0.065,
									0.015,
									0.144,
									0.018,
									0.0,
									0.007,
									0.066,
									0.0,
									0.004
								],
								"e": 0.027
							},
							{
								"h": [
									0.031,
									0.009,
									0.086,
									0.006,
									0.013,
									0.526,
									0.002,
									0.324,
									0.013,
									0.001,
									0.043,
									0.008
								],
								"e": 0.064
							},
							{
								"h": [
									0.008,
									0.01,
									0.005,
									0.325,
									0.0,
									0.08,
									0.001,
									0.038,
									0.01,
									0.002,
									0.055,
									0.003
								],
								"e": 0.03
							},
							{
								"h": [
									0.009,
									0.003,
									0.007,
									0.008,
									0.013,
									0.142,
									0.003,
									0.014,
									0.01,
									0.018,
									0.003,
									0.017
								],
								"e": 0.018
							},
							{
								"h": [
									0.163,
									0.008,
									0.021,
									0.052,
									0.032,
									0.032,
									0.005,
									0.015,
									1.0,
									0.028,
									0.157,
									0.126
								],
								"e": 0.069
							}
						],
						[
							{
								"h": [
									0.146,
									0.0,
									0.232,
									0.001,
									0.095,
									0.0,
									0.033,
									1.0,
									0.874,
									0.347,
									0.244,
									0.155
								],
								"e": 0.196
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.14,
									0.189,
									0.432,
									0.129,
									0.122,
									0.318,
									0.254,
									0.674,
									0.009
								],
								"e": 0.494
							},
							{
								"h": [
									0.513,
									0.041,
									0.858,
									1.0,
									1.0,
									1.0,
									0.21,
									0.678,
									0.315,
									0.34,
									0.499,
									0.088
								],
								"e": 0.647
							},
							{
								"h": [
									0.003,
									0.652,
									1.0,
									0.39,
									0.017,
									0.088,
									1.0,
									0.904,
									0.33,
									0.354,
									0.065,
									0.198
								],
								"e": 0.385
							},
							{
								"h": [
									0.888,
									0.833,
									0.082,
									0.246,
									0.094,
									0.298,
									0.136,
									0.141,
									0.014,
									0.335,
									0.02,
									1.0
								],
								"e": 0.37
							},
							{
								"h": [
									0.005,
									0.0,
									0.394,
									0.463,
									0.217,
									0.021,
									0.127,
									0.127,
									0.05,
									0.01,
									0.037,
									0.02
								],
								"e": 0.083
							},
							{
								"h": [
									1.0,
									0.059,
									0.032,
									0.012,
									0.14,
									0.197,
									0.018,
									0.11,
									0.587,
									0.001,
									0.005,
									0.721
								],
								"e": 0.191
							},
							{
								"h": [
									0.569,
									0.033,
									0.0,
									0.036,
									0.014,
									0.191,
									0.014,
									0.002,
									0.023,
									0.039,
									0.001,
									0.006
								],
								"e": 0.055
							},
							{
								"h": [
									0.011,
									0.017,
									0.125,
									0.024,
									0.23,
									0.246,
									0.015,
									1.0,
									0.075,
									0.007,
									0.046,
									0.016
								],
								"e": 0.117
							},
							{
								"h": [
									0.013,
									0.012,
									0.032,
									0.843,
									0.002,
									0.031,
									0.001,
									0.045,
									0.013,
									0.003,
									0.379,
									0.012
								],
								"e": 0.073
							},
							{
								"h": [
									0.006,
									0.004,
									0.005,
									0.007,
									0.04,
									0.145,
									0.004,
									0.016,
									0.019,
									0.352,
									0.01,
									0.487
								],
								"e": 0.068
							},
							{
								"h": [
									0.133,
									0.018,
									0.01,
									0.084,
									0.024,
									0.009,
									0.006,
									0.022,
									0.8,
									0.005,
									0.4,
									0.259
								],
								"e": 0.084
							}
						],
						[
							{
								"h": [
									0.212,
									1.0,
									0.458,
									1.0,
									1.0,
									1.0,
									0.166,
									0.697,
									0.394,
									0.072,
									1.0,
									0.313
								],
								"e": 1.0
							},
							{
								"h": [
									0.349,
									0.326,
									0.58,
									0.154,
									0.231,
									0.341,
									0.31,
									0.277,
									0.083,
									0.039,
									1.0,
									1.0
								],
								"e": 0.585
							},
							{
								"h": [
									0.183,
									0.068,
									0.203,
									0.135,
									0.026,
									0.388,
									0.04,
									1.0,
									0.095,
									0.822,
									0.759,
									0.111
								],
								"e": 0.298
							},
							{
								"h": [
									0.079,
									0.073,
									0.197,
									0.068,
									0.002,
									0.077,
									0.339,
									0.509,
									0.123,
									0.17,
									0.18,
									0.154
								],
								"e": 0.16
							},
							{
								"h": [
									0.063,
									0.144,
									0.053,
									0.155,
									0.021,
									0.14,
									0.048,
									1.0,
									0.014,
									0.116,
									0.014,
									0.0
								],
								"e": 0.144
							},
							{
								"h": [
									0.013,
									0.001,
									0.041,
									0.205,
									0.069,
									0.029,
									0.015,
									0.053,
									0.044,
									0.018,
									0.123,
									0.019
								],
								"e": 0.04
							},
							{
								"h": [
									0.089,
									0.221,
									0.014,
									0.013,
									0.032,
									0.095,
									0.038,
									0.256,
									0.03,
									0.004,
									0.014,
									0.271
								],
								"e": 0.076
							},
							{
								"h": [
									0.102,
									0.23,
									0.004,
									0.025,
									0.01,
									0.328,
									0.021,
									0.017,
									0.171,
									0.024,
									0.053,
									0.012
								],
								"e": 0.064
							},
							{
								"h": [
									0.084,
									0.022,
									0.029,
									0.029,
									0.048,
									0.092,
									0.045,
									0.134,
									0.435,
									0.076,
									0.05,
									0.038
								],
								"e": 0.076
							},
							{
								"h": [
									0.066,
									0.007,
									0.197,
									0.074,
									0.01,
									0.036,
									0.01,
									0.009,
									0.017,
									0.017,
									0.096,
									0.006
								],
								"e": 0.037
							},
							{
								"h": [
									0.025,
									0.008,
									0.008,
									0.05,
									0.014,
									0.076,
									0.043,
									0.05,
									0.055,
									0.094,
									0.008,
									0.184
								],
								"e": 0.045
							},
							{
								"h": [
									0.26,
									0.012,
									0.005,
									0.037,
									0.033,
									0.024,
									0.009,
									0.055,
									0.864,
									0.018,
									0.144,
									0.106
								],
								"e": 0.069
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " of",
					"o": " consciousness",
					"t": " matter",
					"r": [
						{
							"p": " the",
							"b": 0.0,
							"k": {
								" the": 0.667,
								" course": 0.036,
								" a": 0.028,
								" \"": 0.012,
								" this": 0.01,
								" its": 0.006,
								" '": 0.006,
								" his": 0.005,
								" their": 0.005,
								" our": 0.005
							}
						},
						{
							"p": " the",
							"b": 0.0,
							"k": {
								" the": 0.763,
								" course": 0.065,
								" a": 0.015,
								" our": 0.008,
								" life": 0.008,
								" sorts": 0.006,
								" \"": 0.005,
								" this": 0.005,
								" their": 0.004,
								" '": 0.004
							}
						},
						{
							"p": " the",
							"b": 0.0,
							"k": {
								" the": 0.476,
								" course": 0.166,
								" life": 0.057,
								" a": 0.013,
								" sorts": 0.013,
								" high": 0.012,
								" \"": 0.01,
								" '": 0.009,
								" higher": 0.008,
								" their": 0.005
							}
						},
						{
							"p": " the",
							"b": 0.0,
							"k": {
								" the": 0.399,
								" course": 0.121,
								" life": 0.082,
								" progress": 0.043,
								" sorts": 0.032,
								" mind": 0.017,
								" th": 0.007,
								" their": 0.007,
								" pre": 0.007,
								" high": 0.007
							}
						},
						{
							"p": " the",
							"b": 0.0,
							"k": {
								" the": 0.362,
								" course": 0.062,
								" progress": 0.062,
								" life": 0.05,
								" mind": 0.027,
								" sorts": 0.024,
								" record": 0.019,
								" self": 0.019,
								" ours": 0.018,
								" both": 0.015
							}
						},
						{
							"p": " the",
							"b": 0.0,
							"k": {
								" the": 0.198,
								" progress": 0.15,
								" mind": 0.067,
								" affairs": 0.065,
								" flux": 0.031,
								" separation": 0.029,
								" life": 0.022,
								" relative": 0.021,
								" mental": 0.021,
								" pressure": 0.016
							}
						},
						{
							"p": " affairs",
							"b": 0.0,
							"k": {
								" affairs": 0.335,
								" mind": 0.178,
								" flux": 0.059,
								" consciousness": 0.041,
								" life": 0.034,
								" limbo": 0.027,
								" mental": 0.026,
								" isolation": 0.023,
								" the": 0.017,
								" darkness": 0.014
							}
						},
						{
							"p": " affairs",
							"b": 0.0,
							"k": {
								" affairs": 0.319,
								" consciousness": 0.256,
								" mind": 0.17,
								" flux": 0.054,
								" equilibrium": 0.022,
								" mental": 0.012,
								" limbo": 0.011,
								" origin": 0.009,
								" arousal": 0.009,
								" continuous": 0.008
							}
						},
						{
							"p": " consciousness",
							"b": 0.0,
							"k": {
								" consciousness": 0.307,
								" mind": 0.19,
								" affairs": 0.177,
								" flux": 0.122,
								" arousal": 0.041,
								" intense": 0.019,
								" intoxication": 0.018,
								" mental": 0.016,
								" fluid": 0.012,
								" equilibrium": 0.011
							}
						},
						{
							"p": " consciousness",
							"b": 0.0,
							"k": {
								" consciousness": 0.426,
								" flux": 0.244,
								" mind": 0.052,
								" fluid": 0.048,
								" affairs": 0.032,
								" arousal": 0.029,
								" intense": 0.012,
								" being": 0.01,
								" intoxication": 0.008,
								" suspended": 0.007
							}
						},
						{
							"p": " consciousness",
							"b": 0.001,
							"k": {
								" consciousness": 0.434,
								" flux": 0.136,
								" fluid": 0.071,
								" mind": 0.041,
								" suspended": 0.036,
								" being": 0.033,
								" plasma": 0.024,
								" affairs": 0.015,
								" intense": 0.013,
								" arousal": 0.008
							}
						},
						{
							"p": " consciousness",
							"b": 0.004,
							"k": {
								" consciousness": 0.056,
								" being": 0.035,
								" plasma": 0.031,
								" mind": 0.029,
								" fluid": 0.023,
								" flux": 0.023,
								" high": 0.022,
								" the": 0.021,
								" suspended": 0.019,
								" intense": 0.013
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.0,
									1.0,
									0.012,
									0.616,
									0.044,
									0.962,
									0.208,
									0.05,
									1.0,
									0.769,
									1.0
								],
								"e": 0.504
							},
							{
								"h": [
									0.107,
									1.0,
									0.781,
									1.0,
									1.0,
									0.275,
									1.0,
									1.0,
									1.0,
									1.0,
									0.016,
									0.211
								],
								"e": 1.0
							},
							{
								"h": [
									0.484,
									1.0,
									0.005,
									0.258,
									0.046,
									0.721,
									1.0,
									0.679,
									0.669,
									0.076,
									1.0,
									1.0
								],
								"e": 0.846
							},
							{
								"h": [
									1.0,
									1.0,
									0.128,
									0.012,
									1.0,
									1.0,
									0.398,
									0.249,
									0.984,
									1.0,
									1.0,
									0.186
								],
								"e": 1.0
							},
							{
								"h": [
									0.715,
									0.07,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.824,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.661,
									0.277,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.467,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.855,
									1.0,
									1.0,
									0.45
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.53,
									1.0,
									0.389,
									0.675,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.656,
									0.519,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.999,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									1.0,
									0.805
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.204,
									0.003,
									0.919,
									0.005,
									0.079,
									0.05,
									0.15,
									0.082,
									0.092,
									0.691,
									0.416,
									0.438
								],
								"e": 0.243
							},
							{
								"h": [
									0.009,
									0.28,
									0.182,
									0.071,
									0.174,
									0.02,
									0.104,
									0.083,
									0.205,
									0.207,
									0.523,
									0.011
								],
								"e": 0.165
							},
							{
								"h": [
									0.113,
									0.015,
									0.0,
									0.017,
									0.0,
									0.061,
									0.043,
									0.095,
									0.072,
									0.001,
									0.242,
									0.022
								],
								"e": 0.055
							},
							{
								"h": [
									0.0,
									0.124,
									0.0,
									0.0,
									0.002,
									0.092,
									0.007,
									0.0,
									0.043,
									0.004,
									0.008,
									0.009
								],
								"e": 0.027
							},
							{
								"h": [
									0.002,
									0.001,
									0.047,
									0.006,
									0.003,
									0.003,
									0.032,
									0.033,
									0.003,
									0.065,
									0.003,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.001,
									0.0,
									0.012,
									0.176,
									0.013,
									0.001,
									0.0,
									0.0,
									0.001,
									0.004,
									0.007,
									0.007
								],
								"e": 0.01
							},
							{
								"h": [
									0.019,
									0.002,
									0.019,
									0.009,
									0.024,
									0.006,
									0.002,
									0.009,
									0.002,
									0.0,
									0.001,
									0.02
								],
								"e": 0.009
							},
							{
								"h": [
									0.004,
									0.003,
									0.0,
									0.008,
									0.001,
									0.051,
									0.004,
									0.001,
									0.005,
									0.009,
									0.0,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.022,
									0.0,
									0.026,
									0.01,
									0.003,
									0.04,
									0.001,
									0.003,
									0.016,
									0.002,
									0.016,
									0.021
								],
								"e": 0.012
							},
							{
								"h": [
									0.007,
									0.002,
									0.028,
									0.026,
									0.002,
									0.009,
									0.001,
									0.017,
									0.007,
									0.002,
									0.004,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.01,
									0.002,
									0.002,
									0.009,
									0.024,
									0.002,
									0.003,
									0.016,
									0.003,
									0.019,
									0.007,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									0.499,
									0.023,
									0.013,
									0.031,
									0.062,
									0.008,
									0.006,
									0.021,
									0.147,
									0.008,
									0.099,
									0.041
								],
								"e": 0.047
							}
						],
						[
							{
								"h": [
									0.282,
									0.002,
									0.268,
									0.004,
									0.107,
									0.133,
									0.344,
									0.079,
									0.069,
									0.466,
									0.366,
									0.348
								],
								"e": 0.197
							},
							{
								"h": [
									0.048,
									0.113,
									0.194,
									0.076,
									0.168,
									0.014,
									0.13,
									0.163,
									0.109,
									0.11,
									0.565,
									0.006
								],
								"e": 0.15
							},
							{
								"h": [
									0.078,
									0.043,
									0.0,
									0.013,
									0.006,
									0.056,
									0.031,
									0.263,
									0.02,
									0.017,
									0.334,
									0.037
								],
								"e": 0.072
							},
							{
								"h": [
									0.002,
									0.082,
									0.003,
									0.0,
									0.004,
									0.051,
									0.061,
									0.002,
									0.016,
									0.073,
									0.01,
									0.003
								],
								"e": 0.026
							},
							{
								"h": [
									0.022,
									0.002,
									0.046,
									0.021,
									0.005,
									0.009,
									0.022,
									0.026,
									0.004,
									0.017,
									0.021,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.0,
									0.005,
									0.088,
									0.011,
									0.0,
									0.0,
									0.001,
									0.002,
									0.004,
									0.007,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.108,
									0.006,
									0.024,
									0.02,
									0.094,
									0.011,
									0.004,
									0.014,
									0.002,
									0.0,
									0.003,
									0.01
								],
								"e": 0.019
							},
							{
								"h": [
									0.003,
									0.004,
									0.0,
									0.016,
									0.001,
									0.011,
									0.011,
									0.0,
									0.026,
									0.041,
									0.001,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.016,
									0.001,
									0.03,
									0.007,
									0.005,
									0.075,
									0.0,
									0.006,
									0.052,
									0.005,
									0.017,
									0.04
								],
								"e": 0.018
							},
							{
								"h": [
									0.004,
									0.002,
									0.044,
									0.046,
									0.0,
									0.008,
									0.001,
									0.018,
									0.01,
									0.001,
									0.002,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.012,
									0.001,
									0.002,
									0.003,
									0.019,
									0.002,
									0.003,
									0.003,
									0.002,
									0.018,
									0.002,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.241,
									0.019,
									0.004,
									0.029,
									0.016,
									0.004,
									0.002,
									0.03,
									0.166,
									0.005,
									0.015,
									0.014
								],
								"e": 0.027
							}
						],
						[
							{
								"h": [
									0.276,
									0.0,
									0.241,
									0.014,
									0.191,
									0.135,
									0.343,
									0.112,
									0.064,
									0.403,
									0.235,
									0.452
								],
								"e": 0.196
							},
							{
								"h": [
									0.07,
									0.299,
									0.343,
									0.107,
									0.191,
									0.007,
									0.093,
									0.131,
									0.138,
									0.084,
									0.748,
									0.002
								],
								"e": 0.177
							},
							{
								"h": [
									0.034,
									0.036,
									0.0,
									0.03,
									0.009,
									0.14,
									0.05,
									0.453,
									0.426,
									0.012,
									0.378,
									0.043
								],
								"e": 0.116
							},
							{
								"h": [
									0.0,
									0.14,
									0.01,
									0.0,
									0.002,
									0.081,
									0.01,
									0.004,
									0.179,
									0.051,
									0.011,
									0.017
								],
								"e": 0.039
							},
							{
								"h": [
									0.029,
									0.01,
									0.119,
									0.011,
									0.002,
									0.015,
									0.061,
									0.035,
									0.002,
									0.058,
									0.006,
									0.0
								],
								"e": 0.035
							},
							{
								"h": [
									0.0,
									0.0,
									0.003,
									0.303,
									0.012,
									0.0,
									0.0,
									0.003,
									0.003,
									0.004,
									0.009,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.164,
									0.003,
									0.017,
									0.025,
									0.058,
									0.053,
									0.001,
									0.013,
									0.005,
									0.0,
									0.002,
									0.014
								],
								"e": 0.021
							},
							{
								"h": [
									0.01,
									0.001,
									0.0,
									0.012,
									0.004,
									0.048,
									0.008,
									0.0,
									0.003,
									0.041,
									0.0,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.064,
									0.001,
									0.015,
									0.0,
									0.006,
									0.121,
									0.0,
									0.01,
									0.016,
									0.001,
									0.012,
									0.015
								],
								"e": 0.017
							},
							{
								"h": [
									0.002,
									0.001,
									0.005,
									0.132,
									0.0,
									0.018,
									0.0,
									0.014,
									0.011,
									0.001,
									0.002,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.007,
									0.001,
									0.001,
									0.003,
									0.038,
									0.005,
									0.002,
									0.003,
									0.001,
									0.017,
									0.002,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.301,
									0.022,
									0.009,
									0.058,
									0.029,
									0.011,
									0.004,
									0.015,
									0.139,
									0.004,
									0.05,
									0.024
								],
								"e": 0.035
							}
						],
						[
							{
								"h": [
									0.261,
									0.0,
									0.084,
									0.017,
									0.662,
									0.001,
									1.0,
									0.267,
									0.052,
									0.191,
									0.163,
									0.395
								],
								"e": 0.228
							},
							{
								"h": [
									0.044,
									0.289,
									0.393,
									0.113,
									0.282,
									0.008,
									0.268,
									0.254,
									0.094,
									0.049,
									0.84,
									0.002
								],
								"e": 0.218
							},
							{
								"h": [
									0.188,
									0.066,
									0.001,
									0.061,
									0.037,
									0.198,
									0.014,
									0.519,
									0.22,
									0.166,
									0.452,
									0.059
								],
								"e": 0.171
							},
							{
								"h": [
									0.001,
									0.043,
									0.045,
									0.003,
									0.005,
									0.101,
									0.13,
									0.093,
									0.09,
									0.404,
									0.036,
									0.067
								],
								"e": 0.086
							},
							{
								"h": [
									1.0,
									0.036,
									0.022,
									0.382,
									0.01,
									0.136,
									0.078,
									0.156,
									0.008,
									0.073,
									0.006,
									0.0
								],
								"e": 0.119
							},
							{
								"h": [
									0.006,
									0.0,
									0.019,
									0.189,
									0.235,
									0.013,
									0.001,
									0.084,
									0.057,
									0.034,
									0.016,
									0.015
								],
								"e": 0.042
							},
							{
								"h": [
									0.387,
									0.305,
									0.017,
									0.034,
									0.087,
									0.041,
									0.018,
									0.136,
									0.018,
									0.0,
									0.004,
									0.091
								],
								"e": 0.074
							},
							{
								"h": [
									0.025,
									0.095,
									0.002,
									0.094,
									0.002,
									0.741,
									0.088,
									0.014,
									0.395,
									0.094,
									0.006,
									0.008
								],
								"e": 0.085
							},
							{
								"h": [
									0.042,
									0.003,
									0.079,
									0.13,
									0.255,
									0.12,
									0.021,
									0.12,
									1.0,
									0.081,
									0.129,
									0.357
								],
								"e": 0.173
							},
							{
								"h": [
									0.267,
									0.01,
									1.0,
									0.051,
									0.022,
									0.076,
									0.032,
									0.068,
									0.123,
									0.025,
									0.03,
									0.022
								],
								"e": 0.105
							},
							{
								"h": [
									1.0,
									0.078,
									0.095,
									0.044,
									0.111,
									0.016,
									0.17,
									0.491,
									0.017,
									0.328,
									0.246,
									0.057
								],
								"e": 0.171
							},
							{
								"h": [
									0.375,
									0.086,
									0.102,
									0.134,
									0.24,
									0.078,
									0.095,
									0.119,
									0.214,
									0.022,
									0.264,
									1.0
								],
								"e": 0.177
							}
						],
						[
							{
								"h": [
									0.232,
									0.001,
									0.197,
									0.027,
									0.189,
									0.04,
									0.139,
									0.282,
									0.194,
									0.508,
									0.275,
									0.45
								],
								"e": 0.202
							},
							{
								"h": [
									0.078,
									0.556,
									0.534,
									0.102,
									0.199,
									0.014,
									0.144,
									0.125,
									0.225,
									0.273,
									0.676,
									0.007
								],
								"e": 0.245
							},
							{
								"h": [
									0.196,
									0.028,
									0.004,
									0.252,
									0.013,
									1.0,
									0.098,
									0.765,
									0.314,
									0.08,
									0.388,
									0.063
								],
								"e": 0.245
							},
							{
								"h": [
									0.001,
									0.74,
									0.149,
									0.0,
									0.003,
									0.044,
									0.643,
									0.09,
									0.904,
									0.357,
									0.03,
									0.053
								],
								"e": 0.201
							},
							{
								"h": [
									0.243,
									0.068,
									0.055,
									0.082,
									0.009,
									0.079,
									0.038,
									0.042,
									0.014,
									0.07,
									0.032,
									0.0
								],
								"e": 0.059
							},
							{
								"h": [
									0.009,
									0.0,
									0.037,
									0.324,
									0.309,
									0.0,
									0.0,
									0.003,
									0.17,
									0.01,
									0.018,
									0.001
								],
								"e": 0.051
							},
							{
								"h": [
									0.367,
									0.016,
									0.028,
									0.025,
									0.206,
									0.035,
									0.001,
									0.043,
									0.031,
									0.0,
									0.001,
									0.088
								],
								"e": 0.048
							},
							{
								"h": [
									0.039,
									0.0,
									0.0,
									0.045,
									0.003,
									0.239,
									0.003,
									0.0,
									0.043,
									0.049,
									0.0,
									0.001
								],
								"e": 0.02
							},
							{
								"h": [
									0.027,
									0.0,
									0.009,
									0.009,
									0.001,
									0.154,
									0.0,
									0.023,
									0.007,
									0.0,
									0.005,
									0.013
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.001,
									0.002,
									0.141,
									0.0,
									0.005,
									0.0,
									0.009,
									0.009,
									0.001,
									0.003,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.0,
									0.002,
									0.001,
									0.005,
									0.003,
									0.001,
									0.002,
									0.002,
									0.016,
									0.002,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.113,
									0.011,
									0.005,
									0.023,
									0.024,
									0.001,
									0.001,
									0.01,
									0.195,
									0.001,
									0.008,
									0.011
								],
								"e": 0.02
							}
						],
						[
							{
								"h": [
									0.635,
									0.0,
									0.173,
									0.085,
									0.51,
									0.018,
									0.643,
									0.436,
									0.109,
									0.299,
									0.147,
									0.591
								],
								"e": 0.29
							},
							{
								"h": [
									0.127,
									0.728,
									0.496,
									0.123,
									0.233,
									0.011,
									0.103,
									0.136,
									0.121,
									0.076,
									0.928,
									0.001
								],
								"e": 0.233
							},
							{
								"h": [
									0.161,
									0.03,
									0.018,
									0.122,
									0.052,
									0.484,
									0.099,
									0.885,
									1.0,
									0.111,
									0.723,
									0.096
								],
								"e": 0.291
							},
							{
								"h": [
									0.0,
									0.359,
									0.09,
									0.002,
									0.002,
									0.059,
									0.053,
									0.085,
									0.714,
									0.444,
									0.015,
									0.163
								],
								"e": 0.142
							},
							{
								"h": [
									0.09,
									0.214,
									0.08,
									0.067,
									0.005,
									0.066,
									0.096,
									0.023,
									0.003,
									0.043,
									0.013,
									0.0
								],
								"e": 0.065
							},
							{
								"h": [
									0.002,
									0.0,
									0.032,
									0.567,
									0.327,
									0.002,
									0.001,
									0.01,
									0.018,
									0.023,
									0.062,
									0.006
								],
								"e": 0.052
							},
							{
								"h": [
									0.773,
									0.006,
									0.033,
									0.008,
									0.08,
									0.173,
									0.004,
									0.014,
									0.063,
									0.0,
									0.002,
									0.155
								],
								"e": 0.064
							},
							{
								"h": [
									0.069,
									0.001,
									0.0,
									0.084,
									0.002,
									0.193,
									0.009,
									0.0,
									0.005,
									0.143,
									0.0,
									0.001
								],
								"e": 0.026
							},
							{
								"h": [
									0.087,
									0.004,
									0.022,
									0.002,
									0.006,
									1.0,
									0.001,
									0.067,
									0.006,
									0.001,
									0.025,
									0.024
								],
								"e": 0.061
							},
							{
								"h": [
									0.004,
									0.001,
									0.004,
									1.0,
									0.0,
									0.038,
									0.0,
									0.008,
									0.023,
									0.001,
									0.005,
									0.002
								],
								"e": 0.039
							},
							{
								"h": [
									0.026,
									0.003,
									0.002,
									0.004,
									0.068,
									0.013,
									0.01,
									0.008,
									0.004,
									0.058,
									0.006,
									0.005
								],
								"e": 0.014
							},
							{
								"h": [
									0.279,
									0.028,
									0.027,
									0.039,
									0.042,
									0.01,
									0.006,
									0.022,
									1.0,
									0.009,
									0.148,
									0.119
								],
								"e": 0.082
							}
						],
						[
							{
								"h": [
									0.124,
									0.0,
									0.2,
									0.122,
									0.247,
									0.482,
									0.065,
									0.805,
									1.0,
									0.543,
									0.39,
									0.345
								],
								"e": 0.434
							},
							{
								"h": [
									0.56,
									0.912,
									0.813,
									0.116,
									0.217,
									0.067,
									0.199,
									0.178,
									0.272,
									0.331,
									0.688,
									0.078
								],
								"e": 0.41
							},
							{
								"h": [
									0.246,
									0.041,
									0.029,
									1.0,
									0.03,
									0.751,
									0.198,
									0.674,
									0.443,
									0.056,
									0.347,
									0.079
								],
								"e": 0.365
							},
							{
								"h": [
									0.004,
									0.542,
									1.0,
									0.003,
									0.007,
									0.053,
									1.0,
									0.145,
									0.59,
									0.593,
									0.054,
									0.078
								],
								"e": 0.322
							},
							{
								"h": [
									0.288,
									0.151,
									0.031,
									0.524,
									0.021,
									0.263,
									0.074,
									0.117,
									0.009,
									0.148,
									0.023,
									0.0
								],
								"e": 0.119
							},
							{
								"h": [
									0.001,
									0.0,
									0.124,
									0.256,
									0.21,
									0.003,
									0.002,
									0.026,
									0.023,
									0.008,
									0.022,
									0.009
								],
								"e": 0.036
							},
							{
								"h": [
									1.0,
									0.011,
									0.016,
									0.006,
									0.096,
									0.079,
									0.012,
									0.061,
									0.225,
									0.0,
									0.001,
									0.224
								],
								"e": 0.081
							},
							{
								"h": [
									0.557,
									0.022,
									0.0,
									0.082,
									0.004,
									0.371,
									0.011,
									0.0,
									0.034,
									0.251,
									0.0,
									0.003
								],
								"e": 0.064
							},
							{
								"h": [
									0.047,
									0.006,
									0.057,
									0.017,
									0.09,
									0.768,
									0.016,
									0.281,
									0.033,
									0.007,
									0.057,
									0.039
								],
								"e": 0.081
							},
							{
								"h": [
									0.005,
									0.011,
									0.048,
									0.959,
									0.002,
									0.025,
									0.0,
									0.02,
									0.02,
									0.003,
									0.043,
									0.008
								],
								"e": 0.045
							},
							{
								"h": [
									0.019,
									0.001,
									0.004,
									0.025,
									0.071,
									0.021,
									0.011,
									0.038,
									0.018,
									0.396,
									0.01,
									0.013
								],
								"e": 0.038
							},
							{
								"h": [
									0.396,
									0.069,
									0.031,
									0.055,
									0.064,
									0.017,
									0.006,
									0.039,
									0.833,
									0.004,
									0.154,
									0.195
								],
								"e": 0.094
							}
						],
						[
							{
								"h": [
									0.186,
									0.0,
									0.166,
									0.236,
									1.0,
									0.024,
									0.694,
									0.697,
									0.132,
									0.307,
									0.165,
									0.319
								],
								"e": 0.324
							},
							{
								"h": [
									1.0,
									0.615,
									0.51,
									0.129,
									0.266,
									0.017,
									0.135,
									0.222,
									0.087,
									0.065,
									1.0,
									0.001
								],
								"e": 0.368
							},
							{
								"h": [
									0.503,
									0.045,
									1.0,
									0.326,
									1.0,
									0.788,
									0.036,
									1.0,
									0.545,
									1.0,
									0.722,
									0.104
								],
								"e": 1.0
							},
							{
								"h": [
									0.001,
									0.09,
									0.489,
									1.0,
									0.004,
									0.047,
									0.913,
									1.0,
									0.234,
									0.208,
									0.114,
									1.0
								],
								"e": 0.637
							},
							{
								"h": [
									0.681,
									1.0,
									0.039,
									0.964,
									0.012,
									0.791,
									0.057,
									0.322,
									0.034,
									0.098,
									0.009,
									1.0
								],
								"e": 0.464
							},
							{
								"h": [
									0.005,
									0.0,
									0.982,
									0.298,
									0.413,
									0.014,
									1.0,
									1.0,
									0.021,
									0.019,
									0.043,
									0.004
								],
								"e": 0.255
							},
							{
								"h": [
									0.675,
									0.129,
									0.005,
									0.014,
									0.064,
									0.052,
									0.011,
									0.081,
									1.0,
									0.0,
									0.004,
									1.0
								],
								"e": 0.168
							},
							{
								"h": [
									0.743,
									0.227,
									0.003,
									0.112,
									0.004,
									0.372,
									0.027,
									0.008,
									0.192,
									0.232,
									0.006,
									0.012
								],
								"e": 0.105
							},
							{
								"h": [
									0.125,
									0.005,
									0.067,
									0.041,
									0.635,
									0.505,
									0.029,
									1.0,
									0.215,
									0.027,
									0.051,
									0.066
								],
								"e": 0.181
							},
							{
								"h": [
									0.033,
									0.006,
									0.275,
									0.469,
									0.02,
									0.08,
									0.005,
									0.014,
									0.021,
									0.009,
									0.05,
									0.04
								],
								"e": 0.052
							},
							{
								"h": [
									0.046,
									0.008,
									0.006,
									0.056,
									0.058,
									0.013,
									0.048,
									0.021,
									0.008,
									0.129,
									0.002,
									0.017
								],
								"e": 0.029
							},
							{
								"h": [
									0.359,
									0.015,
									0.006,
									0.07,
									0.015,
									0.01,
									0.003,
									0.031,
									0.417,
									0.003,
									0.121,
									0.18
								],
								"e": 0.06
							}
						],
						[
							{
								"h": [
									0.078,
									1.0,
									0.344,
									1.0,
									0.628,
									1.0,
									0.044,
									1.0,
									0.431,
									0.604,
									1.0,
									0.204
								],
								"e": 1.0
							},
							{
								"h": [
									0.151,
									0.62,
									1.0,
									0.126,
									0.229,
									1.0,
									0.162,
									0.286,
									0.246,
									0.122,
									0.781,
									1.0
								],
								"e": 0.748
							},
							{
								"h": [
									1.0,
									0.129,
									0.006,
									0.134,
									0.052,
									0.848,
									0.164,
									0.731,
									0.718,
									0.052,
									0.39,
									0.086
								],
								"e": 0.365
							},
							{
								"h": [
									0.001,
									0.146,
									0.075,
									0.012,
									0.003,
									0.05,
									0.445,
									0.212,
									1.0,
									0.559,
									0.295,
									0.119
								],
								"e": 0.221
							},
							{
								"h": [
									0.06,
									0.029,
									0.009,
									0.162,
									0.078,
									0.179,
									0.042,
									1.0,
									0.018,
									0.027,
									0.014,
									0.0
								],
								"e": 0.117
							},
							{
								"h": [
									0.007,
									0.0,
									0.04,
									0.062,
									0.048,
									0.005,
									0.015,
									0.136,
									0.014,
									0.011,
									0.029,
									0.208
								],
								"e": 0.047
							},
							{
								"h": [
									0.085,
									0.012,
									0.005,
									0.009,
									0.025,
									0.098,
									0.046,
									0.043,
									0.049,
									0.0,
									0.003,
									0.048
								],
								"e": 0.03
							},
							{
								"h": [
									0.091,
									0.017,
									0.0,
									0.054,
									0.001,
									0.057,
									0.022,
									0.001,
									0.015,
									0.046,
									0.0,
									0.002
								],
								"e": 0.018
							},
							{
								"h": [
									0.032,
									0.002,
									0.038,
									0.024,
									0.066,
									0.032,
									0.042,
									0.168,
									0.085,
									0.036,
									0.173,
									0.013
								],
								"e": 0.058
							},
							{
								"h": [
									0.038,
									0.031,
									0.425,
									0.106,
									0.018,
									0.064,
									0.002,
									0.013,
									0.028,
									0.011,
									0.043,
									0.006
								],
								"e": 0.045
							},
							{
								"h": [
									0.043,
									0.005,
									0.005,
									0.019,
									0.101,
									0.008,
									0.007,
									0.246,
									0.015,
									0.181,
									0.01,
									0.079
								],
								"e": 0.048
							},
							{
								"h": [
									0.581,
									0.018,
									0.03,
									0.034,
									0.129,
									0.02,
									0.004,
									0.105,
									0.435,
									0.008,
									0.309,
									0.275
								],
								"e": 0.103
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " matter",
					"o": " that",
					"t": ".",
					"r": [
						{
							"p": " matter",
							"b": 0.0,
							"k": {
								" matter": 0.925,
								" Matter": 0.033,
								" matters": 0.013,
								" and": 0.002,
								",": 0.001,
								"-": 0.001,
								" to": 0.001,
								" of": 0.001,
								" that": 0.001,
								"--": 0.001
							}
						},
						{
							"p": " matter",
							"b": 0.012,
							"k": {
								" matter": 0.551,
								" matters": 0.109,
								" Matter": 0.068,
								"ional": 0.024,
								",": 0.019,
								"icle": 0.014,
								" of": 0.013,
								" and": 0.013,
								" that": 0.013,
								"--": 0.013
							}
						},
						{
							"p": " matter",
							"b": 0.007,
							"k": {
								" matter": 0.789,
								" Matter": 0.034,
								" matters": 0.019,
								" surrounding": 0.019,
								"ional": 0.014,
								",": 0.01,
								"-": 0.007,
								".": 0.007,
								" and": 0.006,
								" --": 0.006
							}
						},
						{
							"p": " matter",
							"b": 0.007,
							"k": {
								" matter": 0.227,
								"-": 0.091,
								",": 0.065,
								"wear": 0.056,
								" particles": 0.045,
								" and": 0.031,
								"matter": 0.019,
								"worms": 0.019,
								" beyond": 0.018,
								"flow": 0.014
							}
						},
						{
							"p": ",",
							"b": 0.013,
							"k": {
								",": 0.223,
								"-": 0.097,
								" and": 0.073,
								" particles": 0.066,
								" matter": 0.065,
								" --": 0.036,
								" that": 0.029,
								" beyond": 0.024,
								" in": 0.021,
								" (": 0.015
							}
						},
						{
							"p": ",",
							"b": 0.029,
							"k": {
								",": 0.47,
								" and": 0.077,
								" (": 0.035,
								" in": 0.033,
								"-": 0.031,
								".": 0.029,
								" particles": 0.029,
								" that": 0.025,
								" matter": 0.023,
								" from": 0.021
							}
						},
						{
							"p": ",",
							"b": 0.025,
							"k": {
								",": 0.481,
								" and": 0.065,
								" (": 0.055,
								" or": 0.049,
								" particles": 0.037,
								"-": 0.03,
								".": 0.025,
								" which": 0.025,
								" in": 0.022,
								" that": 0.019
							}
						},
						{
							"p": ",",
							"b": 0.018,
							"k": {
								",": 0.345,
								" particles": 0.076,
								" which": 0.058,
								" that": 0.056,
								" and": 0.049,
								" or": 0.045,
								" (": 0.034,
								" in": 0.025,
								"less": 0.025,
								" resembling": 0.021
							}
						},
						{
							"p": ",",
							"b": 0.015,
							"k": {
								",": 0.212,
								" that": 0.165,
								" which": 0.149,
								" particles": 0.058,
								" resembling": 0.039,
								" and": 0.035,
								" like": 0.033,
								" or": 0.026,
								" in": 0.025,
								" called": 0.017
							}
						},
						{
							"p": " which",
							"b": 0.004,
							"k": {
								" which": 0.539,
								" that": 0.253,
								" where": 0.076,
								" whose": 0.019,
								",": 0.018,
								" containing": 0.017,
								" particles": 0.015,
								" consisting": 0.01,
								" wherein": 0.009,
								" resembling": 0.008
							}
						},
						{
							"p": " that",
							"b": 0.002,
							"k": {
								" that": 0.56,
								" which": 0.258,
								" where": 0.1,
								" consisting": 0.019,
								" containing": 0.018,
								" whose": 0.009,
								" composed": 0.005,
								",": 0.004,
								" called": 0.004,
								" wherein": 0.003
							}
						},
						{
							"p": " that",
							"b": 0.104,
							"k": {
								" that": 0.18,
								",": 0.138,
								".": 0.104,
								" in": 0.057,
								" or": 0.055,
								" which": 0.034,
								" called": 0.031,
								" with": 0.024,
								" and": 0.023,
								" of": 0.021
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.869,
									0.0,
									1.0,
									0.024,
									0.118,
									0.011,
									1.0,
									0.316,
									1.0,
									1.0,
									0.748,
									1.0
								],
								"e": 0.436
							},
							{
								"h": [
									0.038,
									0.49,
									0.646,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.011,
									0.041
								],
								"e": 1.0
							},
							{
								"h": [
									0.37,
									1.0,
									0.068,
									0.115,
									0.757,
									0.217,
									1.0,
									0.771,
									0.319,
									0.145,
									1.0,
									1.0
								],
								"e": 0.999
							},
							{
								"h": [
									1.0,
									1.0,
									0.061,
									0.577,
									1.0,
									1.0,
									0.855,
									0.653,
									0.237,
									1.0,
									1.0,
									0.098
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.22,
									1.0,
									1.0,
									1.0,
									0.731,
									1.0,
									0.269,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.395,
									1.0,
									1.0,
									1.0,
									0.844,
									0.578,
									1.0,
									1.0,
									0.992,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.347,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									0.91,
									0.566,
									1.0,
									0.348,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.803,
									1.0,
									1.0,
									1.0,
									0.491,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.181,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.403
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.143,
									0.0,
									0.574,
									0.0,
									0.025,
									0.0,
									0.173,
									0.066,
									0.457,
									0.509,
									0.317,
									0.364
								],
								"e": 0.154
							},
							{
								"h": [
									0.006,
									0.168,
									0.19,
									0.055,
									0.134,
									0.448,
									0.199,
									0.118,
									0.209,
									0.267,
									0.342,
									0.004
								],
								"e": 0.218
							},
							{
								"h": [
									0.068,
									0.01,
									0.0,
									0.009,
									0.005,
									0.018,
									0.04,
									0.072,
									0.033,
									0.003,
									0.174,
									0.023
								],
								"e": 0.052
							},
							{
								"h": [
									0.001,
									0.036,
									0.0,
									0.0,
									0.005,
									0.037,
									0.006,
									0.0,
									0.006,
									0.009,
									0.005,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.001,
									0.033,
									0.016,
									0.001,
									0.002,
									0.012,
									0.008,
									0.001,
									0.016,
									0.004,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.0,
									0.006,
									0.216,
									0.007,
									0.001,
									0.0,
									0.002,
									0.003,
									0.002,
									0.004,
									0.016
								],
								"e": 0.014
							},
							{
								"h": [
									0.009,
									0.002,
									0.046,
									0.017,
									0.005,
									0.013,
									0.004,
									0.011,
									0.004,
									0.001,
									0.004,
									0.04
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.003,
									0.0,
									0.014,
									0.09,
									0.064,
									0.004,
									0.001,
									0.008,
									0.11,
									0.001,
									0.006
								],
								"e": 0.019
							},
							{
								"h": [
									0.007,
									0.004,
									0.056,
									0.021,
									0.01,
									0.13,
									0.003,
									0.001,
									0.012,
									0.002,
									0.028,
									0.032
								],
								"e": 0.022
							},
							{
								"h": [
									0.004,
									0.017,
									0.014,
									0.018,
									0.005,
									0.008,
									0.002,
									0.028,
									0.004,
									0.003,
									0.013,
									0.014
								],
								"e": 0.01
							},
							{
								"h": [
									0.005,
									0.011,
									0.026,
									0.022,
									0.045,
									0.063,
									0.001,
									0.023,
									0.01,
									0.005,
									0.011,
									0.003
								],
								"e": 0.016
							},
							{
								"h": [
									0.727,
									0.034,
									0.02,
									0.019,
									0.08,
									0.083,
									0.02,
									0.126,
									0.04,
									0.023,
									0.151,
									0.035
								],
								"e": 0.08
							}
						],
						[
							{
								"h": [
									1.0,
									0.007,
									0.484,
									0.0,
									0.029,
									0.0,
									0.215,
									0.059,
									0.316,
									0.487,
									0.44,
									0.12
								],
								"e": 0.176
							},
							{
								"h": [
									0.022,
									0.093,
									0.187,
									0.058,
									0.119,
									0.176,
									0.307,
									0.207,
									0.116,
									0.211,
									0.373,
									0.003
								],
								"e": 0.188
							},
							{
								"h": [
									0.039,
									0.047,
									0.001,
									0.006,
									0.009,
									0.011,
									0.019,
									0.197,
									0.017,
									0.054,
									0.211,
									0.032
								],
								"e": 0.066
							},
							{
								"h": [
									0.001,
									0.056,
									0.001,
									0.0,
									0.009,
									0.057,
									0.088,
									0.006,
									0.004,
									0.032,
									0.009,
									0.001
								],
								"e": 0.024
							},
							{
								"h": [
									0.015,
									0.004,
									0.04,
									0.024,
									0.005,
									0.006,
									0.016,
									0.016,
									0.001,
									0.022,
									0.017,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.0,
									0.012,
									0.132,
									0.006,
									0.001,
									0.001,
									0.001,
									0.001,
									0.003,
									0.006,
									0.013
								],
								"e": 0.01
							},
							{
								"h": [
									0.021,
									0.0,
									0.059,
									0.022,
									0.038,
									0.016,
									0.004,
									0.023,
									0.003,
									0.0,
									0.003,
									0.009
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.008,
									0.02,
									0.008,
									0.006,
									0.0,
									0.009,
									0.037,
									0.0,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.002,
									0.0,
									0.042,
									0.004,
									0.004,
									0.026,
									0.001,
									0.003,
									0.011,
									0.001,
									0.011,
									0.018
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.003,
									0.006,
									0.032,
									0.0,
									0.004,
									0.001,
									0.051,
									0.003,
									0.001,
									0.005,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.003,
									0.001,
									0.003,
									0.002,
									0.003,
									0.019,
									0.001,
									0.001,
									0.001,
									0.016,
									0.002,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.229,
									0.019,
									0.004,
									0.01,
									0.033,
									0.015,
									0.005,
									0.1,
									0.078,
									0.004,
									0.007,
									0.011
								],
								"e": 0.034
							}
						],
						[
							{
								"h": [
									0.706,
									0.001,
									0.518,
									0.001,
									0.012,
									0.0,
									0.146,
									0.073,
									0.138,
									0.44,
									0.379,
									0.245
								],
								"e": 0.151
							},
							{
								"h": [
									0.049,
									0.178,
									0.328,
									0.087,
									0.147,
									0.234,
									0.387,
									0.137,
									0.157,
									0.105,
									0.529,
									0.006
								],
								"e": 0.225
							},
							{
								"h": [
									0.018,
									0.055,
									0.001,
									0.014,
									0.013,
									0.032,
									0.035,
									0.417,
									0.106,
									0.013,
									0.287,
									0.039
								],
								"e": 0.095
							},
							{
								"h": [
									0.001,
									0.155,
									0.002,
									0.001,
									0.004,
									0.074,
									0.018,
									0.023,
									0.017,
									0.024,
									0.01,
									0.011
								],
								"e": 0.033
							},
							{
								"h": [
									0.024,
									0.014,
									0.058,
									0.047,
									0.001,
									0.024,
									0.044,
									0.036,
									0.001,
									0.01,
									0.002,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									0.0,
									0.0,
									0.009,
									0.364,
									0.049,
									0.001,
									0.001,
									0.009,
									0.002,
									0.002,
									0.004,
									0.006
								],
								"e": 0.024
							},
							{
								"h": [
									0.075,
									0.001,
									0.037,
									0.034,
									0.007,
									0.035,
									0.001,
									0.046,
									0.009,
									0.0,
									0.003,
									0.06
								],
								"e": 0.019
							},
							{
								"h": [
									0.008,
									0.0,
									0.0,
									0.009,
									0.097,
									0.015,
									0.004,
									0.001,
									0.006,
									0.128,
									0.0,
									0.003
								],
								"e": 0.015
							},
							{
								"h": [
									0.008,
									0.001,
									0.069,
									0.0,
									0.045,
									0.162,
									0.0,
									0.004,
									0.005,
									0.0,
									0.005,
									0.012
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.003,
									0.001,
									0.074,
									0.0,
									0.005,
									0.001,
									0.046,
									0.002,
									0.002,
									0.021,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.001,
									0.003,
									0.006,
									0.017,
									0.095,
									0.001,
									0.004,
									0.002,
									0.006,
									0.002,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.371,
									0.011,
									0.021,
									0.033,
									0.035,
									0.041,
									0.01,
									0.051,
									0.018,
									0.018,
									0.052,
									0.012
								],
								"e": 0.041
							}
						],
						[
							{
								"h": [
									0.22,
									0.001,
									0.285,
									0.01,
									0.008,
									0.0,
									0.525,
									0.173,
									0.834,
									0.208,
									0.13,
									0.145
								],
								"e": 0.15
							},
							{
								"h": [
									0.109,
									0.141,
									0.373,
									0.091,
									0.201,
									0.134,
									0.929,
									0.374,
									0.087,
									0.055,
									0.606,
									0.006
								],
								"e": 0.295
							},
							{
								"h": [
									0.144,
									0.192,
									0.003,
									0.039,
									0.012,
									0.014,
									0.008,
									0.39,
									0.069,
									0.131,
									0.344,
									0.051
								],
								"e": 0.155
							},
							{
								"h": [
									0.008,
									0.023,
									0.02,
									0.003,
									0.038,
									0.162,
									0.355,
									0.049,
									0.016,
									0.183,
									0.062,
									0.007
								],
								"e": 0.087
							},
							{
								"h": [
									0.219,
									0.037,
									0.024,
									0.395,
									0.119,
									0.069,
									0.106,
									0.202,
									0.029,
									0.04,
									0.02,
									0.0
								],
								"e": 0.107
							},
							{
								"h": [
									0.003,
									0.0,
									0.049,
									0.176,
									0.018,
									0.007,
									0.006,
									0.011,
									0.023,
									0.108,
									0.375,
									0.054
								],
								"e": 0.057
							},
							{
								"h": [
									0.309,
									0.027,
									0.031,
									0.043,
									0.121,
									0.023,
									0.022,
									0.162,
									0.051,
									0.003,
									0.006,
									0.015
								],
								"e": 0.049
							},
							{
								"h": [
									0.015,
									0.019,
									0.0,
									0.052,
									0.092,
									0.074,
									0.033,
									0.004,
									0.127,
									0.05,
									0.005,
									0.007
								],
								"e": 0.032
							},
							{
								"h": [
									0.01,
									0.001,
									0.025,
									0.045,
									0.013,
									0.087,
									0.004,
									0.026,
									0.224,
									0.032,
									0.052,
									0.032
								],
								"e": 0.041
							},
							{
								"h": [
									0.061,
									0.002,
									0.036,
									0.057,
									0.012,
									0.008,
									0.008,
									0.04,
									0.022,
									0.01,
									0.053,
									0.004
								],
								"e": 0.022
							},
							{
								"h": [
									0.065,
									0.034,
									0.035,
									0.024,
									0.022,
									0.072,
									0.037,
									0.04,
									0.005,
									0.196,
									0.064,
									0.026
								],
								"e": 0.045
							},
							{
								"h": [
									0.191,
									0.044,
									0.097,
									0.054,
									0.077,
									0.033,
									0.108,
									0.053,
									0.055,
									0.024,
									0.132,
									0.296
								],
								"e": 0.091
							}
						],
						[
							{
								"h": [
									0.234,
									0.0,
									0.19,
									0.002,
									0.032,
									0.0,
									0.076,
									0.202,
									0.423,
									0.403,
									0.248,
									0.196
								],
								"e": 0.116
							},
							{
								"h": [
									0.082,
									0.428,
									0.509,
									0.087,
									0.157,
									0.171,
									0.141,
									0.124,
									0.252,
									0.476,
									0.47,
									0.001
								],
								"e": 0.291
							},
							{
								"h": [
									0.145,
									0.044,
									0.015,
									0.076,
									0.134,
									0.12,
									0.062,
									0.654,
									0.168,
									0.154,
									0.32,
									0.052
								],
								"e": 0.207
							},
							{
								"h": [
									0.001,
									0.421,
									0.046,
									0.012,
									0.003,
									0.013,
									0.39,
									0.31,
									0.098,
									0.091,
									0.013,
									0.004
								],
								"e": 0.099
							},
							{
								"h": [
									0.215,
									0.078,
									0.031,
									0.039,
									0.007,
									0.056,
									0.047,
									0.041,
									0.01,
									0.032,
									0.03,
									0.0
								],
								"e": 0.051
							},
							{
								"h": [
									0.029,
									0.0,
									0.109,
									0.131,
									0.161,
									0.02,
									0.001,
									0.003,
									0.395,
									0.031,
									0.018,
									0.011
								],
								"e": 0.07
							},
							{
								"h": [
									0.156,
									0.012,
									0.063,
									0.118,
									0.014,
									0.054,
									0.004,
									0.071,
									0.036,
									0.001,
									0.019,
									0.048
								],
								"e": 0.04
							},
							{
								"h": [
									0.003,
									0.0,
									0.0,
									0.023,
									0.04,
									0.023,
									0.006,
									0.0,
									0.002,
									0.312,
									0.0,
									0.024
								],
								"e": 0.025
							},
							{
								"h": [
									0.008,
									0.003,
									0.051,
									0.011,
									0.003,
									0.104,
									0.0,
									0.011,
									0.002,
									0.0,
									0.008,
									0.01
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.013,
									0.001,
									0.034,
									0.0,
									0.004,
									0.0,
									0.042,
									0.003,
									0.001,
									0.01,
									0.012
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.001,
									0.008,
									0.01,
									0.002,
									0.045,
									0.0,
									0.004,
									0.026,
									0.004,
									0.003,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.119,
									0.048,
									0.016,
									0.025,
									0.096,
									0.007,
									0.006,
									0.086,
									0.413,
									0.003,
									0.008,
									0.008
								],
								"e": 0.063
							}
						],
						[
							{
								"h": [
									0.648,
									0.006,
									0.297,
									0.014,
									0.075,
									0.001,
									0.211,
									0.409,
									0.344,
									0.342,
									0.275,
									0.283
								],
								"e": 0.178
							},
							{
								"h": [
									0.081,
									0.373,
									0.46,
									0.102,
									0.177,
									0.128,
									0.34,
									0.169,
									0.143,
									0.083,
									0.647,
									0.008
								],
								"e": 0.255
							},
							{
								"h": [
									0.099,
									0.048,
									0.018,
									0.162,
									0.227,
									0.198,
									0.057,
									0.765,
									0.559,
									0.054,
									0.488,
									0.074
								],
								"e": 0.287
							},
							{
								"h": [
									0.001,
									0.285,
									0.014,
									0.029,
									0.002,
									0.013,
									0.087,
									0.265,
									0.123,
									0.061,
									0.018,
									0.031
								],
								"e": 0.075
							},
							{
								"h": [
									0.102,
									0.059,
									0.054,
									0.193,
									0.008,
									0.061,
									0.088,
									0.025,
									0.003,
									0.015,
									0.012,
									0.0
								],
								"e": 0.052
							},
							{
								"h": [
									0.002,
									0.0,
									0.043,
									0.376,
									0.432,
									0.002,
									0.007,
									0.032,
									0.023,
									0.01,
									0.012,
									0.014
								],
								"e": 0.054
							},
							{
								"h": [
									0.356,
									0.002,
									0.041,
									0.021,
									0.005,
									0.128,
									0.007,
									0.073,
									0.295,
									0.0,
									0.004,
									0.049
								],
								"e": 0.047
							},
							{
								"h": [
									0.019,
									0.0,
									0.0,
									0.042,
									0.106,
									0.036,
									0.007,
									0.001,
									0.014,
									0.202,
									0.0,
									0.002
								],
								"e": 0.025
							},
							{
								"h": [
									0.023,
									0.003,
									0.117,
									0.007,
									0.052,
									1.0,
									0.001,
									0.065,
									0.004,
									0.001,
									0.048,
									0.013
								],
								"e": 0.073
							},
							{
								"h": [
									0.001,
									0.007,
									0.001,
									0.218,
									0.0,
									0.014,
									0.002,
									0.07,
									0.002,
									0.005,
									0.115,
									0.002
								],
								"e": 0.022
							},
							{
								"h": [
									0.006,
									0.01,
									0.024,
									0.073,
									0.043,
									0.54,
									0.01,
									0.024,
									0.016,
									0.032,
									0.007,
									0.006
								],
								"e": 0.038
							},
							{
								"h": [
									0.538,
									0.041,
									0.127,
									0.024,
									0.062,
									0.051,
									0.022,
									0.055,
									0.193,
									0.077,
									0.399,
									0.109
								],
								"e": 0.108
							}
						],
						[
							{
								"h": [
									0.214,
									0.0,
									0.258,
									0.001,
									0.032,
									0.0,
									0.149,
									0.571,
									0.819,
									0.402,
									0.29,
									0.106
								],
								"e": 0.164
							},
							{
								"h": [
									1.0,
									0.81,
									0.738,
									0.096,
									0.175,
									0.511,
									0.226,
									0.185,
									0.36,
									0.27,
									0.468,
									0.001
								],
								"e": 0.506
							},
							{
								"h": [
									0.155,
									0.089,
									0.133,
									1.0,
									0.736,
									0.235,
									0.151,
									0.598,
									0.181,
									0.082,
									0.321,
									0.072
								],
								"e": 0.46
							},
							{
								"h": [
									0.002,
									0.305,
									0.287,
									0.017,
									0.024,
									0.03,
									0.249,
									0.067,
									0.014,
									0.106,
									0.02,
									0.022
								],
								"e": 0.099
							},
							{
								"h": [
									0.109,
									0.154,
									0.025,
									0.089,
									0.022,
									0.399,
									0.056,
									0.039,
									0.014,
									0.052,
									0.031,
									0.0
								],
								"e": 0.076
							},
							{
								"h": [
									0.001,
									0.0,
									0.082,
									0.19,
									0.175,
									0.001,
									0.006,
									0.104,
									0.017,
									0.005,
									0.022,
									0.04
								],
								"e": 0.039
							},
							{
								"h": [
									0.763,
									0.008,
									0.027,
									0.014,
									0.01,
									0.048,
									0.006,
									0.048,
									0.255,
									0.0,
									0.002,
									0.256
								],
								"e": 0.063
							},
							{
								"h": [
									0.251,
									0.013,
									0.0,
									0.034,
									0.179,
									0.13,
									0.011,
									0.001,
									0.021,
									0.392,
									0.0,
									0.002
								],
								"e": 0.06
							},
							{
								"h": [
									0.013,
									0.008,
									0.069,
									0.038,
									0.477,
									0.427,
									0.01,
									0.161,
									0.018,
									0.01,
									0.082,
									0.018
								],
								"e": 0.077
							},
							{
								"h": [
									0.003,
									0.023,
									0.007,
									0.235,
									0.004,
									0.013,
									0.0,
									0.053,
									0.006,
									0.003,
									0.917,
									0.009
								],
								"e": 0.053
							},
							{
								"h": [
									0.003,
									0.006,
									0.025,
									0.067,
									0.082,
									0.618,
									0.005,
									0.036,
									0.039,
									0.372,
									0.015,
									0.032
								],
								"e": 0.064
							},
							{
								"h": [
									0.953,
									0.104,
									0.017,
									0.016,
									0.065,
									0.057,
									0.012,
									0.046,
									0.036,
									0.035,
									0.362,
									0.174
								],
								"e": 0.1
							}
						],
						[
							{
								"h": [
									0.537,
									0.001,
									0.31,
									0.011,
									0.262,
									0.0,
									0.458,
									0.312,
									0.133,
									0.315,
									0.179,
									0.197
								],
								"e": 0.185
							},
							{
								"h": [
									0.302,
									0.409,
									0.471,
									0.114,
									0.213,
									0.082,
									0.141,
									0.471,
									0.114,
									0.118,
									0.726,
									0.005
								],
								"e": 0.314
							},
							{
								"h": [
									0.115,
									0.083,
									0.296,
									0.449,
									0.021,
									0.273,
									0.019,
									0.84,
									0.197,
									1.0,
									0.525,
									0.086
								],
								"e": 0.502
							},
							{
								"h": [
									0.0,
									0.357,
									0.129,
									1.0,
									0.014,
									0.052,
									0.483,
									0.702,
									0.134,
									0.057,
									0.066,
									1.0
								],
								"e": 0.399
							},
							{
								"h": [
									0.172,
									1.0,
									0.037,
									0.273,
									0.004,
									0.954,
									0.159,
									0.146,
									0.035,
									0.289,
									0.003,
									0.0
								],
								"e": 0.256
							},
							{
								"h": [
									0.008,
									0.0,
									1.0,
									0.188,
									0.096,
									0.001,
									1.0,
									1.0,
									0.005,
									0.032,
									0.023,
									0.012
								],
								"e": 0.21
							},
							{
								"h": [
									0.344,
									0.018,
									0.027,
									0.016,
									0.015,
									0.051,
									0.006,
									0.12,
									0.894,
									0.0,
									0.003,
									0.231
								],
								"e": 0.075
							},
							{
								"h": [
									0.331,
									0.011,
									0.0,
									0.026,
									0.155,
									0.098,
									0.017,
									0.003,
									0.224,
									0.111,
									0.0,
									0.004
								],
								"e": 0.058
							},
							{
								"h": [
									0.029,
									0.002,
									0.083,
									0.011,
									0.271,
									0.201,
									0.006,
									0.424,
									0.135,
									0.023,
									0.042,
									0.042
								],
								"e": 0.081
							},
							{
								"h": [
									0.022,
									0.003,
									0.059,
									0.309,
									0.005,
									0.006,
									0.004,
									0.011,
									0.007,
									0.002,
									0.505,
									0.001
								],
								"e": 0.042
							},
							{
								"h": [
									0.006,
									0.008,
									0.006,
									0.089,
									0.045,
									1.0,
									0.039,
									0.011,
									0.013,
									1.0,
									0.003,
									0.008
								],
								"e": 0.096
							},
							{
								"h": [
									0.187,
									0.058,
									0.009,
									0.087,
									0.017,
									0.025,
									0.007,
									0.058,
									0.554,
									0.011,
									0.185,
									1.0
								],
								"e": 0.142
							}
						],
						[
							{
								"h": [
									0.22,
									0.0,
									0.329,
									0.003,
									0.153,
									0.0,
									0.067,
									1.0,
									0.286,
									0.437,
									0.339,
									0.179
								],
								"e": 0.191
							},
							{
								"h": [
									0.915,
									1.0,
									1.0,
									0.117,
									0.207,
									0.829,
									0.338,
									0.322,
									0.229,
									0.138,
									0.536,
									0.04
								],
								"e": 0.575
							},
							{
								"h": [
									1.0,
									0.246,
									1.0,
									0.549,
									1.0,
									1.0,
									0.101,
									0.63,
									1.0,
									0.142,
									0.344,
									0.075
								],
								"e": 1.0
							},
							{
								"h": [
									0.0,
									0.104,
									1.0,
									0.44,
									0.006,
									0.03,
									0.75,
									1.0,
									1.0,
									0.11,
									0.025,
									0.019
								],
								"e": 0.392
							},
							{
								"h": [
									0.955,
									0.038,
									0.016,
									0.253,
									0.239,
									0.598,
									0.081,
									0.11,
									0.027,
									0.095,
									0.009,
									1.0
								],
								"e": 0.352
							},
							{
								"h": [
									0.001,
									0.0,
									0.922,
									0.097,
									0.545,
									0.0,
									0.362,
									0.345,
									0.001,
									0.02,
									0.045,
									0.374
								],
								"e": 0.172
							},
							{
								"h": [
									0.374,
									0.021,
									0.018,
									0.016,
									0.009,
									0.109,
									0.003,
									0.117,
									0.878,
									0.0,
									0.003,
									0.58
								],
								"e": 0.097
							},
							{
								"h": [
									1.0,
									0.003,
									0.0,
									0.083,
									0.264,
									0.075,
									0.022,
									0.001,
									0.674,
									0.08,
									0.0,
									0.006
								],
								"e": 0.127
							},
							{
								"h": [
									0.011,
									0.003,
									0.039,
									0.007,
									1.0,
									0.059,
									0.118,
									1.0,
									0.044,
									0.037,
									0.128,
									0.014
								],
								"e": 0.15
							},
							{
								"h": [
									0.007,
									0.047,
									0.017,
									0.184,
									0.006,
									0.012,
									0.0,
									0.021,
									0.003,
									0.001,
									0.207,
									0.003
								],
								"e": 0.026
							},
							{
								"h": [
									0.003,
									0.007,
									0.015,
									0.023,
									0.144,
									0.11,
									0.001,
									0.06,
									0.035,
									0.154,
									0.005,
									0.023
								],
								"e": 0.037
							},
							{
								"h": [
									1.0,
									0.017,
									0.012,
									0.027,
									0.096,
									0.061,
									0.016,
									0.035,
									0.078,
									0.053,
									0.896,
									0.099
								],
								"e": 0.12
							}
						],
						[
							{
								"h": [
									0.066,
									1.0,
									0.082,
									1.0,
									1.0,
									1.0,
									0.315,
									0.458,
									0.141,
									0.226,
									1.0,
									0.303
								],
								"e": 1.0
							},
							{
								"h": [
									0.098,
									0.449,
									0.739,
									0.135,
									0.306,
									0.154,
									0.313,
									0.173,
									0.151,
									0.129,
									1.0,
									1.0
								],
								"e": 0.673
							},
							{
								"h": [
									0.027,
									0.079,
									0.026,
									0.173,
									0.057,
									0.158,
									0.028,
									1.0,
									0.113,
									0.197,
									0.716,
									0.119
								],
								"e": 0.262
							},
							{
								"h": [
									0.157,
									0.097,
									0.3,
									0.104,
									0.004,
									0.022,
									1.0,
									0.644,
									0.034,
									0.027,
									0.245,
									0.036
								],
								"e": 0.209
							},
							{
								"h": [
									0.147,
									0.042,
									0.009,
									0.386,
									0.059,
									1.0,
									0.048,
									1.0,
									0.035,
									0.011,
									0.013,
									0.0
								],
								"e": 0.202
							},
							{
								"h": [
									0.03,
									0.003,
									0.044,
									0.043,
									0.14,
									0.019,
									0.051,
									0.041,
									0.126,
									0.022,
									1.0,
									0.059
								],
								"e": 0.103
							},
							{
								"h": [
									0.058,
									0.007,
									0.016,
									0.009,
									0.176,
									0.104,
									0.049,
									0.386,
									0.151,
									0.01,
									0.003,
									0.022
								],
								"e": 0.066
							},
							{
								"h": [
									0.129,
									0.175,
									0.001,
									0.093,
									0.05,
									0.062,
									0.061,
									0.056,
									0.193,
									0.058,
									0.003,
									0.005
								],
								"e": 0.066
							},
							{
								"h": [
									0.036,
									0.014,
									0.092,
									0.137,
									0.086,
									0.067,
									0.074,
									0.118,
									0.589,
									0.15,
									0.34,
									0.055
								],
								"e": 0.139
							},
							{
								"h": [
									0.113,
									0.015,
									0.044,
									0.076,
									0.017,
									0.019,
									0.012,
									0.075,
									0.017,
									0.005,
									0.116,
									0.004
								],
								"e": 0.035
							},
							{
								"h": [
									0.04,
									0.051,
									0.018,
									0.02,
									0.115,
									0.189,
									0.027,
									0.103,
									0.038,
									0.371,
									0.018,
									0.015
								],
								"e": 0.062
							},
							{
								"h": [
									0.399,
									0.029,
									0.021,
									0.095,
									0.037,
									0.075,
									0.022,
									0.045,
									1.0,
									0.048,
									0.46,
									0.563
								],
								"e": 0.176
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ".",
					"o": " But",
					"t": " Other",
					"r": [
						{
							"p": " ",
							"b": 0.002,
							"k": {
								" ": 0.302,
								" It": 0.076,
								" The": 0.054,
								" And": 0.051,
								" They": 0.039,
								" If": 0.033,
								" But": 0.025,
								" In": 0.024,
								" That": 0.022,
								" There": 0.017
							}
						},
						{
							"p": " ",
							"b": 0.001,
							"k": {
								" ": 0.239,
								" It": 0.094,
								" They": 0.067,
								" The": 0.053,
								" And": 0.05,
								" If": 0.049,
								" But": 0.047,
								" There": 0.027,
								" In": 0.021,
								" This": 0.02
							}
						},
						{
							"p": " ",
							"b": 0.001,
							"k": {
								" ": 0.181,
								" It": 0.097,
								" They": 0.091,
								" But": 0.078,
								" If": 0.061,
								" And": 0.046,
								" The": 0.036,
								" There": 0.032,
								" However": 0.03,
								" This": 0.019
							}
						},
						{
							"p": " They",
							"b": 0.0,
							"k": {
								" They": 0.198,
								" But": 0.112,
								" If": 0.096,
								" It": 0.069,
								" ": 0.055,
								" However": 0.044,
								" And": 0.042,
								" When": 0.036,
								" There": 0.026,
								" This": 0.02
							}
						},
						{
							"p": " They",
							"b": 0.0,
							"k": {
								" They": 0.112,
								" But": 0.099,
								" If": 0.089,
								" It": 0.08,
								" And": 0.064,
								" However": 0.05,
								" This": 0.049,
								" When": 0.047,
								" ": 0.028,
								" That": 0.026
							}
						},
						{
							"p": " But",
							"b": 0.0,
							"k": {
								" But": 0.128,
								" It": 0.1,
								" If": 0.1,
								" They": 0.099,
								" And": 0.068,
								" However": 0.046,
								" This": 0.045,
								" ": 0.034,
								" That": 0.032,
								" There": 0.029
							}
						},
						{
							"p": " They",
							"b": 0.0,
							"k": {
								" They": 0.279,
								" But": 0.196,
								" It": 0.08,
								" That": 0.07,
								" If": 0.053,
								" And": 0.039,
								" This": 0.037,
								" However": 0.031,
								" When": 0.028,
								" There": 0.018
							}
						},
						{
							"p": " But",
							"b": 0.0,
							"k": {
								" But": 0.435,
								" They": 0.097,
								" It": 0.075,
								" If": 0.057,
								" That": 0.056,
								" And": 0.042,
								" This": 0.033,
								" Usually": 0.018,
								" There": 0.015,
								" When": 0.013
							}
						},
						{
							"p": " But",
							"b": 0.0,
							"k": {
								" But": 0.262,
								" When": 0.099,
								" It": 0.096,
								" If": 0.081,
								" This": 0.077,
								" They": 0.071,
								" That": 0.067,
								" Usually": 0.029,
								" Sometimes": 0.022,
								" And": 0.021
							}
						},
						{
							"p": " They",
							"b": 0.0,
							"k": {
								" They": 0.248,
								" But": 0.141,
								" That": 0.14,
								" This": 0.089,
								" It": 0.077,
								" If": 0.069,
								" When": 0.063,
								" And": 0.017,
								" There": 0.015,
								" Usually": 0.014
							}
						},
						{
							"p": " They",
							"b": 0.0,
							"k": {
								" They": 0.235,
								" That": 0.129,
								" But": 0.117,
								" It": 0.058,
								" If": 0.057,
								" Plasma": 0.051,
								" This": 0.05,
								" When": 0.043,
								" And": 0.025,
								" In": 0.019
							}
						},
						{
							"p": " But",
							"b": 0.001,
							"k": {
								" But": 0.063,
								" They": 0.061,
								" It": 0.059,
								" ": 0.058,
								" The": 0.055,
								" This": 0.041,
								" That": 0.041,
								" In": 0.039,
								" If": 0.036,
								" I": 0.035
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.002,
									1.0,
									0.029,
									0.094,
									0.466,
									1.0,
									0.061,
									0.036,
									1.0,
									0.986,
									1.0
								],
								"e": 0.496
							},
							{
								"h": [
									0.9,
									0.883,
									0.512,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.013,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.145,
									1.0,
									1.0,
									0.296,
									0.574,
									0.39,
									0.606,
									0.329,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.627,
									1.0,
									1.0,
									1.0,
									0.184,
									0.165,
									0.683,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.434,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.874,
									0.626,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.305,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.636,
									0.834,
									1.0,
									1.0,
									1.0,
									1.0,
									0.446,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.79,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.426,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.568,
									1.0,
									1.0,
									0.846,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.973,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.15,
									0.004,
									0.275,
									0.004,
									0.072,
									0.555,
									0.11,
									0.019,
									0.052,
									0.614,
									0.773,
									0.422
								],
								"e": 0.2
							},
							{
								"h": [
									0.322,
									0.144,
									0.117,
									0.055,
									0.164,
									0.115,
									0.041,
									0.07,
									0.406,
									0.266,
									0.334,
									0.277
								],
								"e": 0.176
							},
							{
								"h": [
									0.371,
									0.029,
									0.0,
									0.154,
									0.02,
									0.017,
									0.031,
									0.069,
									0.142,
									0.01,
									0.131,
									0.019
								],
								"e": 0.089
							},
							{
								"h": [
									0.005,
									0.175,
									0.104,
									0.0,
									0.014,
									0.456,
									0.059,
									0.0,
									0.011,
									0.083,
									0.045,
									0.581
								],
								"e": 0.094
							},
							{
								"h": [
									0.002,
									0.061,
									0.026,
									0.101,
									0.027,
									0.008,
									0.194,
									0.009,
									0.03,
									0.318,
									0.013,
									0.0
								],
								"e": 0.053
							},
							{
								"h": [
									0.004,
									0.0,
									0.927,
									1.0,
									0.21,
									0.006,
									0.0,
									0.053,
									0.05,
									0.011,
									0.016,
									0.014
								],
								"e": 0.082
							},
							{
								"h": [
									0.107,
									0.247,
									0.039,
									0.325,
									0.008,
									0.196,
									0.039,
									0.03,
									0.07,
									0.001,
									0.007,
									0.084
								],
								"e": 0.071
							},
							{
								"h": [
									0.001,
									0.026,
									0.001,
									0.051,
									0.206,
									0.075,
									0.038,
									0.086,
									0.084,
									0.009,
									0.023,
									0.009
								],
								"e": 0.038
							},
							{
								"h": [
									0.009,
									0.517,
									0.182,
									0.206,
									0.004,
									0.03,
									0.103,
									0.001,
									1.0,
									0.182,
									0.263,
									0.034
								],
								"e": 0.142
							},
							{
								"h": [
									0.006,
									0.07,
									0.032,
									0.004,
									0.03,
									0.017,
									0.004,
									0.119,
									0.038,
									0.01,
									0.01,
									0.012
								],
								"e": 0.027
							},
							{
								"h": [
									0.006,
									0.025,
									0.014,
									0.252,
									0.031,
									0.023,
									0.003,
									0.037,
									0.037,
									0.007,
									0.01,
									0.013
								],
								"e": 0.039
							},
							{
								"h": [
									0.287,
									0.044,
									0.017,
									0.059,
									0.075,
									0.237,
									0.018,
									0.09,
									0.032,
									0.024,
									0.16,
									0.051
								],
								"e": 0.082
							}
						],
						[
							{
								"h": [
									0.163,
									0.002,
									0.12,
									0.003,
									0.055,
									0.391,
									0.178,
									0.022,
									0.028,
									0.431,
									0.532,
									0.285
								],
								"e": 0.146
							},
							{
								"h": [
									0.167,
									0.07,
									0.126,
									0.058,
									0.146,
									0.058,
									0.044,
									0.209,
									0.268,
									0.112,
									0.351,
									0.153
								],
								"e": 0.134
							},
							{
								"h": [
									0.1,
									0.12,
									0.001,
									0.111,
									0.015,
									0.014,
									0.032,
									0.218,
									0.037,
									0.056,
									0.218,
									0.034
								],
								"e": 0.089
							},
							{
								"h": [
									0.002,
									0.119,
									0.172,
									0.001,
									0.006,
									0.445,
									0.331,
									0.002,
									0.01,
									0.09,
									0.016,
									0.175
								],
								"e": 0.09
							},
							{
								"h": [
									0.008,
									0.014,
									0.025,
									0.091,
									0.04,
									0.051,
									0.16,
									0.014,
									0.021,
									0.109,
									0.032,
									0.0
								],
								"e": 0.041
							},
							{
								"h": [
									0.001,
									0.0,
									0.294,
									0.197,
									0.082,
									0.005,
									0.0,
									0.016,
									0.011,
									0.011,
									0.015,
									0.01
								],
								"e": 0.023
							},
							{
								"h": [
									0.123,
									0.053,
									0.027,
									0.154,
									0.021,
									0.123,
									0.025,
									0.072,
									0.041,
									0.001,
									0.019,
									0.043
								],
								"e": 0.043
							},
							{
								"h": [
									0.006,
									0.014,
									0.0,
									0.075,
									0.055,
									0.031,
									0.059,
									0.019,
									0.025,
									0.009,
									0.013,
									0.003
								],
								"e": 0.021
							},
							{
								"h": [
									0.004,
									0.099,
									0.352,
									0.056,
									0.005,
									0.026,
									0.059,
									0.002,
									0.682,
									0.127,
									0.113,
									0.033
								],
								"e": 0.076
							},
							{
								"h": [
									0.017,
									0.038,
									0.065,
									0.011,
									0.012,
									0.026,
									0.036,
									0.247,
									0.026,
									0.018,
									0.008,
									0.004
								],
								"e": 0.034
							},
							{
								"h": [
									0.023,
									0.025,
									0.015,
									0.045,
									0.059,
									0.012,
									0.023,
									0.028,
									0.018,
									0.02,
									0.012,
									0.074
								],
								"e": 0.03
							},
							{
								"h": [
									0.243,
									0.106,
									0.006,
									0.025,
									0.024,
									0.055,
									0.008,
									0.238,
									0.059,
									0.012,
									0.022,
									0.02
								],
								"e": 0.06
							}
						],
						[
							{
								"h": [
									0.272,
									0.0,
									0.404,
									0.007,
									0.08,
									0.645,
									0.121,
									0.035,
									0.015,
									0.423,
									0.499,
									0.405
								],
								"e": 0.206
							},
							{
								"h": [
									0.391,
									0.113,
									0.224,
									0.079,
									0.175,
									0.112,
									0.033,
									0.151,
									0.24,
									0.188,
									0.479,
									0.138
								],
								"e": 0.17
							},
							{
								"h": [
									0.165,
									0.078,
									0.002,
									0.089,
									0.039,
									0.013,
									0.041,
									0.387,
									0.276,
									0.034,
									0.24,
									0.029
								],
								"e": 0.12
							},
							{
								"h": [
									0.002,
									0.095,
									0.228,
									0.001,
									0.006,
									0.369,
									0.034,
									0.007,
									0.028,
									0.117,
									0.009,
									0.242
								],
								"e": 0.07
							},
							{
								"h": [
									0.106,
									0.031,
									0.037,
									0.223,
									0.025,
									0.083,
									0.206,
									0.07,
									0.019,
									0.297,
									0.015,
									0.0
								],
								"e": 0.07
							},
							{
								"h": [
									0.001,
									0.0,
									0.443,
									0.592,
									0.104,
									0.001,
									0.001,
									0.013,
									0.006,
									0.008,
									0.035,
									0.005
								],
								"e": 0.042
							},
							{
								"h": [
									0.54,
									0.062,
									0.024,
									0.685,
									0.011,
									0.259,
									0.021,
									0.142,
									0.056,
									0.001,
									0.012,
									0.087
								],
								"e": 0.097
							},
							{
								"h": [
									0.006,
									0.004,
									0.0,
									0.155,
									0.204,
									0.016,
									0.041,
									0.014,
									0.103,
									0.002,
									0.001,
									0.002
								],
								"e": 0.029
							},
							{
								"h": [
									0.007,
									0.041,
									0.38,
									0.038,
									0.002,
									0.051,
									0.036,
									0.001,
									0.455,
									0.081,
									0.047,
									0.014
								],
								"e": 0.055
							},
							{
								"h": [
									0.012,
									0.008,
									0.015,
									0.004,
									0.013,
									0.016,
									0.006,
									0.204,
									0.005,
									0.006,
									0.041,
									0.002
								],
								"e": 0.023
							},
							{
								"h": [
									0.004,
									0.005,
									0.008,
									0.026,
									0.056,
									0.025,
									0.008,
									0.011,
									0.004,
									0.014,
									0.007,
									0.006
								],
								"e": 0.017
							},
							{
								"h": [
									0.107,
									0.029,
									0.008,
									0.042,
									0.066,
									0.099,
									0.012,
									0.086,
									0.012,
									0.018,
									0.037,
									0.03
								],
								"e": 0.042
							}
						],
						[
							{
								"h": [
									0.269,
									0.0,
									0.05,
									0.016,
									0.071,
									0.023,
									0.907,
									0.035,
									0.018,
									0.199,
									0.29,
									0.354
								],
								"e": 0.17
							},
							{
								"h": [
									0.23,
									0.099,
									0.242,
									0.083,
									0.225,
									0.068,
									0.102,
									0.457,
									0.148,
									0.062,
									0.511,
									0.097
								],
								"e": 0.18
							},
							{
								"h": [
									0.223,
									0.175,
									0.002,
									0.124,
									0.012,
									0.019,
									0.014,
									0.447,
									0.04,
									0.201,
									0.285,
									0.05
								],
								"e": 0.14
							},
							{
								"h": [
									0.0,
									0.014,
									0.315,
									0.008,
									0.015,
									0.236,
									1.0,
									0.039,
									0.021,
									0.047,
									0.061,
									0.153
								],
								"e": 0.131
							},
							{
								"h": [
									0.01,
									0.008,
									0.019,
									0.94,
									0.059,
									0.048,
									0.297,
									0.072,
									0.048,
									0.102,
									0.004,
									0.0
								],
								"e": 0.081
							},
							{
								"h": [
									0.001,
									0.0,
									0.461,
									0.096,
									0.109,
									0.009,
									0.015,
									0.017,
									0.016,
									0.05,
									0.179,
									0.026
								],
								"e": 0.039
							},
							{
								"h": [
									0.117,
									0.047,
									0.03,
									0.064,
									0.031,
									0.088,
									0.022,
									0.338,
									0.141,
									0.001,
									0.006,
									0.033
								],
								"e": 0.053
							},
							{
								"h": [
									0.027,
									0.048,
									0.003,
									0.052,
									0.067,
									0.017,
									0.07,
									0.035,
									0.33,
									0.015,
									0.041,
									0.009
								],
								"e": 0.043
							},
							{
								"h": [
									0.01,
									0.037,
									0.159,
									0.893,
									0.014,
									0.019,
									0.214,
									0.005,
									0.195,
									0.211,
									0.311,
									0.532
								],
								"e": 0.148
							},
							{
								"h": [
									0.237,
									0.011,
									0.893,
									0.061,
									0.073,
									0.026,
									0.821,
									0.11,
									0.185,
									0.56,
									0.01,
									0.028
								],
								"e": 0.189
							},
							{
								"h": [
									1.0,
									0.898,
									0.293,
									0.191,
									0.066,
									0.009,
									0.438,
									1.0,
									0.009,
									0.064,
									1.0,
									0.85
								],
								"e": 0.449
							},
							{
								"h": [
									0.169,
									0.056,
									0.071,
									0.251,
									0.384,
									0.099,
									0.142,
									0.308,
									0.007,
									0.181,
									0.567,
									0.588
								],
								"e": 0.229
							}
						],
						[
							{
								"h": [
									0.231,
									0.001,
									0.117,
									0.032,
									0.15,
									0.148,
									0.035,
									0.065,
									0.044,
									0.452,
									0.499,
									0.3
								],
								"e": 0.145
							},
							{
								"h": [
									0.454,
									0.159,
									0.334,
									0.073,
									0.175,
									0.152,
									0.041,
									0.126,
									0.314,
									0.179,
									0.426,
									0.164
								],
								"e": 0.189
							},
							{
								"h": [
									0.209,
									0.066,
									0.009,
									0.2,
									0.088,
									0.064,
									0.06,
									0.638,
									0.052,
									0.084,
									0.227,
									0.039
								],
								"e": 0.145
							},
							{
								"h": [
									0.002,
									0.122,
									0.52,
									0.017,
									0.006,
									0.18,
									0.157,
									0.037,
									0.115,
									0.039,
									0.054,
									0.167
								],
								"e": 0.088
							},
							{
								"h": [
									0.093,
									0.038,
									0.025,
									0.361,
									0.032,
									0.06,
									0.168,
									0.134,
									0.018,
									0.187,
									0.054,
									0.0
								],
								"e": 0.074
							},
							{
								"h": [
									0.001,
									0.0,
									0.401,
									0.281,
									0.329,
									0.009,
									0.017,
									0.02,
									0.019,
									0.032,
									0.039,
									0.011
								],
								"e": 0.043
							},
							{
								"h": [
									0.309,
									0.089,
									0.032,
									0.712,
									0.024,
									0.115,
									0.025,
									0.261,
									0.025,
									0.0,
									0.013,
									0.163
								],
								"e": 0.093
							},
							{
								"h": [
									0.013,
									0.015,
									0.0,
									0.174,
									0.573,
									0.081,
									0.066,
									0.005,
									0.065,
									0.013,
									0.004,
									0.001
								],
								"e": 0.051
							},
							{
								"h": [
									0.027,
									0.033,
									0.683,
									0.19,
									0.005,
									0.118,
									0.058,
									0.001,
									0.561,
									0.117,
									0.043,
									0.02
								],
								"e": 0.089
							},
							{
								"h": [
									0.006,
									0.015,
									0.005,
									0.02,
									0.022,
									0.053,
									0.001,
									0.135,
									0.025,
									0.003,
									0.044,
									0.001
								],
								"e": 0.026
							},
							{
								"h": [
									0.004,
									0.004,
									0.006,
									0.029,
									0.015,
									0.023,
									0.003,
									0.014,
									0.011,
									0.078,
									0.005,
									0.013
								],
								"e": 0.02
							},
							{
								"h": [
									0.192,
									0.055,
									0.002,
									0.019,
									0.039,
									0.026,
									0.004,
									0.145,
									0.12,
									0.003,
									0.008,
									0.008
								],
								"e": 0.049
							}
						],
						[
							{
								"h": [
									0.522,
									0.0,
									0.237,
									0.081,
									0.095,
									0.064,
									0.434,
									0.101,
									0.024,
									0.298,
									0.332,
									0.415
								],
								"e": 0.207
							},
							{
								"h": [
									0.301,
									0.243,
									0.318,
									0.088,
									0.211,
									0.065,
									0.031,
									0.158,
									0.188,
									0.152,
									0.587,
									0.119
								],
								"e": 0.177
							},
							{
								"h": [
									0.169,
									0.056,
									0.011,
									0.596,
									0.009,
									0.074,
									0.075,
									0.668,
									0.408,
									0.165,
									0.417,
									0.057
								],
								"e": 0.216
							},
							{
								"h": [
									0.001,
									0.1,
									0.492,
									0.008,
									0.006,
									0.223,
									0.045,
									0.057,
									0.116,
									0.049,
									0.027,
									0.32
								],
								"e": 0.084
							},
							{
								"h": [
									0.866,
									0.017,
									0.024,
									0.332,
									0.051,
									0.18,
									0.319,
									0.185,
									0.058,
									0.464,
									0.04,
									0.0
								],
								"e": 0.158
							},
							{
								"h": [
									0.0,
									0.0,
									1.0,
									0.231,
									0.766,
									0.003,
									0.006,
									0.037,
									0.005,
									0.043,
									0.627,
									0.007
								],
								"e": 0.093
							},
							{
								"h": [
									0.377,
									0.02,
									0.054,
									0.855,
									0.014,
									0.398,
									0.017,
									0.264,
									0.109,
									0.0,
									0.009,
									0.143
								],
								"e": 0.113
							},
							{
								"h": [
									0.084,
									0.028,
									0.0,
									0.289,
									0.672,
									0.156,
									0.11,
									0.004,
									0.138,
									0.007,
									0.004,
									0.003
								],
								"e": 0.08
							},
							{
								"h": [
									0.011,
									0.055,
									0.365,
									0.26,
									0.003,
									0.088,
									0.036,
									0.01,
									0.169,
									0.273,
									0.16,
									0.013
								],
								"e": 0.076
							},
							{
								"h": [
									0.009,
									0.021,
									0.012,
									0.016,
									0.092,
									0.033,
									0.003,
									0.232,
									0.009,
									0.009,
									0.082,
									0.003
								],
								"e": 0.04
							},
							{
								"h": [
									0.006,
									0.044,
									0.02,
									0.053,
									0.021,
									0.041,
									0.009,
									0.048,
									0.018,
									0.061,
									0.012,
									0.028
								],
								"e": 0.032
							},
							{
								"h": [
									0.089,
									0.042,
									0.015,
									0.008,
									0.077,
									0.101,
									0.015,
									0.113,
									0.011,
									0.066,
									0.13,
									0.062
								],
								"e": 0.057
							}
						],
						[
							{
								"h": [
									0.061,
									0.0,
									0.057,
									0.062,
									0.138,
									0.322,
									0.022,
									0.162,
									0.262,
									0.434,
									0.503,
									0.252
								],
								"e": 0.181
							},
							{
								"h": [
									0.493,
									0.202,
									0.473,
									0.081,
									0.171,
									0.175,
									0.03,
									0.097,
									0.344,
									0.291,
									0.428,
									0.44
								],
								"e": 0.237
							},
							{
								"h": [
									0.193,
									0.021,
									0.097,
									0.906,
									0.209,
									0.164,
									0.101,
									0.436,
									0.143,
									0.132,
									0.161,
									0.045
								],
								"e": 0.232
							},
							{
								"h": [
									0.004,
									0.159,
									1.0,
									0.025,
									0.009,
									0.131,
									0.163,
									0.039,
									0.07,
									0.025,
									0.04,
									0.178
								],
								"e": 0.104
							},
							{
								"h": [
									0.233,
									0.03,
									0.006,
									0.329,
									0.028,
									0.113,
									0.179,
									0.063,
									0.029,
									0.301,
									0.039,
									0.0
								],
								"e": 0.083
							},
							{
								"h": [
									0.0,
									0.0,
									0.371,
									0.052,
									0.616,
									0.001,
									0.001,
									0.05,
									0.01,
									0.008,
									0.028,
									0.011
								],
								"e": 0.038
							},
							{
								"h": [
									0.053,
									0.021,
									0.014,
									0.107,
									0.013,
									0.107,
									0.008,
									0.185,
									0.055,
									0.0,
									0.003,
									0.047
								],
								"e": 0.033
							},
							{
								"h": [
									0.016,
									0.041,
									0.0,
									0.03,
									0.072,
									0.034,
									0.061,
									0.004,
									0.144,
									0.003,
									0.006,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.01,
									0.021,
									0.156,
									0.422,
									0.002,
									0.017,
									0.028,
									0.004,
									0.122,
									0.435,
									0.08,
									0.078
								],
								"e": 0.067
							},
							{
								"h": [
									0.003,
									0.024,
									0.025,
									0.012,
									0.012,
									0.005,
									0.002,
									0.063,
									0.02,
									0.011,
									0.01,
									0.004
								],
								"e": 0.014
							},
							{
								"h": [
									0.007,
									0.021,
									0.017,
									0.021,
									0.011,
									0.017,
									0.002,
									0.058,
									0.054,
									0.02,
									0.044,
									0.029
								],
								"e": 0.028
							},
							{
								"h": [
									0.107,
									0.034,
									0.007,
									0.008,
									0.096,
									0.01,
									0.012,
									0.068,
									0.006,
									0.005,
									0.032,
									0.022
								],
								"e": 0.03
							}
						],
						[
							{
								"h": [
									0.081,
									0.0,
									0.073,
									0.158,
									0.189,
									0.152,
									0.242,
									0.144,
									0.032,
									0.313,
									0.358,
									0.259
								],
								"e": 0.156
							},
							{
								"h": [
									0.432,
									0.191,
									0.306,
									0.088,
									0.214,
									0.055,
									0.049,
									0.222,
									0.152,
									0.071,
									0.598,
									0.121
								],
								"e": 0.176
							},
							{
								"h": [
									0.168,
									0.137,
									0.067,
									0.24,
									0.016,
									0.061,
									0.04,
									0.747,
									0.189,
									0.968,
									0.388,
									0.072
								],
								"e": 0.263
							},
							{
								"h": [
									0.0,
									0.031,
									0.063,
									0.026,
									0.003,
									0.059,
									0.147,
									0.138,
									0.067,
									0.013,
									0.03,
									0.277
								],
								"e": 0.06
							},
							{
								"h": [
									0.119,
									0.031,
									0.006,
									0.307,
									0.017,
									0.128,
									0.296,
									0.116,
									0.076,
									0.32,
									0.009,
									0.0
								],
								"e": 0.087
							},
							{
								"h": [
									0.0,
									0.0,
									0.657,
									0.034,
									0.252,
									0.003,
									0.018,
									0.067,
									0.002,
									0.027,
									0.147,
									0.014
								],
								"e": 0.043
							},
							{
								"h": [
									0.042,
									0.011,
									0.019,
									0.05,
									0.018,
									0.114,
									0.008,
									0.538,
									0.151,
									0.0,
									0.008,
									0.09
								],
								"e": 0.056
							},
							{
								"h": [
									0.064,
									0.035,
									0.0,
									0.007,
									0.071,
									0.013,
									0.032,
									0.002,
									0.479,
									0.004,
									0.009,
									0.001
								],
								"e": 0.038
							},
							{
								"h": [
									0.018,
									0.02,
									0.106,
									0.21,
									0.02,
									0.012,
									0.011,
									0.009,
									0.313,
									0.22,
									0.017,
									0.19
								],
								"e": 0.06
							},
							{
								"h": [
									0.004,
									0.007,
									0.383,
									0.226,
									0.01,
									0.005,
									0.07,
									0.018,
									0.027,
									0.053,
									0.029,
									0.002
								],
								"e": 0.049
							},
							{
								"h": [
									0.045,
									0.102,
									0.019,
									0.293,
									0.023,
									0.027,
									0.232,
									0.02,
									0.006,
									0.036,
									0.031,
									0.122
								],
								"e": 0.076
							},
							{
								"h": [
									0.04,
									0.035,
									0.005,
									0.102,
									0.023,
									0.032,
									0.014,
									0.448,
									0.006,
									0.015,
									0.006,
									0.047
								],
								"e": 0.055
							}
						],
						[
							{
								"h": [
									0.024,
									0.003,
									0.281,
									0.252,
									0.481,
									0.219,
									0.013,
									0.299,
									0.188,
									0.461,
									0.621,
									0.195
								],
								"e": 0.26
							},
							{
								"h": [
									0.758,
									0.228,
									0.582,
									0.087,
									0.184,
									0.136,
									0.047,
									0.16,
									0.256,
									0.078,
									0.477,
									0.387
								],
								"e": 0.234
							},
							{
								"h": [
									0.775,
									0.067,
									0.142,
									0.544,
									0.111,
									0.349,
									0.074,
									0.403,
									0.469,
									0.227,
									0.134,
									0.041
								],
								"e": 0.319
							},
							{
								"h": [
									0.001,
									0.034,
									0.515,
									0.08,
									0.003,
									0.075,
									0.054,
									0.147,
									0.395,
									0.013,
									0.037,
									0.162
								],
								"e": 0.1
							},
							{
								"h": [
									0.416,
									0.044,
									0.001,
									0.676,
									0.013,
									0.214,
									0.247,
									0.227,
									0.057,
									0.174,
									0.016,
									0.0
								],
								"e": 0.122
							},
							{
								"h": [
									0.001,
									0.0,
									0.256,
									0.006,
									0.143,
									0.0,
									0.002,
									0.057,
									0.001,
									0.005,
									0.048,
									0.032
								],
								"e": 0.022
							},
							{
								"h": [
									0.053,
									0.025,
									0.012,
									0.053,
									0.008,
									0.093,
									0.001,
									0.265,
									0.224,
									0.0,
									0.009,
									0.035
								],
								"e": 0.042
							},
							{
								"h": [
									0.014,
									0.008,
									0.0,
									0.006,
									0.035,
									0.012,
									0.043,
									0.001,
									0.093,
									0.0,
									0.0,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.007,
									0.002,
									0.075,
									0.073,
									0.002,
									0.004,
									0.012,
									0.001,
									0.045,
									0.063,
									0.012,
									0.038
								],
								"e": 0.017
							},
							{
								"h": [
									0.001,
									0.003,
									0.05,
									0.008,
									0.004,
									0.005,
									0.0,
									0.025,
									0.011,
									0.002,
									0.002,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.003,
									0.014,
									0.002,
									0.007,
									0.005,
									0.005,
									0.001,
									0.032,
									0.005,
									0.01,
									0.006,
									0.005
								],
								"e": 0.008
							},
							{
								"h": [
									0.048,
									0.008,
									0.003,
									0.015,
									0.078,
									0.006,
									0.005,
									0.046,
									0.006,
									0.002,
									0.066,
									0.02
								],
								"e": 0.022
							}
						],
						[
							{
								"h": [
									0.088,
									0.0,
									0.1,
									0.509,
									0.436,
									0.044,
									0.317,
									0.358,
									0.017,
									0.36,
									0.346,
									0.398
								],
								"e": 0.264
							},
							{
								"h": [
									0.485,
									0.319,
									0.472,
									0.106,
									0.311,
									0.054,
									0.074,
									0.179,
									0.203,
									0.109,
									0.853,
									0.126
								],
								"e": 0.229
							},
							{
								"h": [
									0.076,
									0.074,
									0.18,
									0.413,
									0.028,
									0.214,
									0.051,
									0.903,
									0.197,
									1.0,
									0.518,
									0.117
								],
								"e": 0.332
							},
							{
								"h": [
									0.001,
									0.033,
									0.404,
									0.402,
									0.005,
									0.087,
									0.314,
									1.0,
									0.109,
									0.013,
									0.107,
									0.587
								],
								"e": 0.25
							},
							{
								"h": [
									0.079,
									0.022,
									0.009,
									0.737,
									0.018,
									0.148,
									0.417,
									0.235,
									0.063,
									0.14,
									0.008,
									1.0
								],
								"e": 0.288
							},
							{
								"h": [
									0.001,
									0.0,
									0.137,
									0.013,
									0.372,
									0.001,
									0.146,
									0.032,
									0.005,
									0.033,
									0.392,
									0.01
								],
								"e": 0.056
							},
							{
								"h": [
									0.013,
									0.063,
									0.034,
									0.026,
									0.05,
									0.209,
									0.011,
									1.0,
									0.598,
									0.001,
									0.016,
									0.016
								],
								"e": 0.109
							},
							{
								"h": [
									0.132,
									0.035,
									0.001,
									0.016,
									0.024,
									0.027,
									0.06,
									0.007,
									0.189,
									0.003,
									0.006,
									0.006
								],
								"e": 0.033
							},
							{
								"h": [
									0.02,
									0.005,
									0.206,
									0.337,
									0.014,
									0.023,
									0.012,
									0.025,
									0.326,
									0.343,
									0.054,
									0.185
								],
								"e": 0.079
							},
							{
								"h": [
									0.107,
									0.015,
									1.0,
									0.157,
									0.015,
									0.027,
									0.186,
									0.04,
									0.079,
									0.173,
									0.013,
									0.004
								],
								"e": 0.101
							},
							{
								"h": [
									0.222,
									0.661,
									0.045,
									0.027,
									0.148,
									0.009,
									0.125,
									0.146,
									0.005,
									0.063,
									0.085,
									0.827
								],
								"e": 0.162
							},
							{
								"h": [
									0.143,
									0.021,
									0.006,
									0.13,
									0.044,
									0.038,
									0.022,
									0.101,
									0.005,
									0.121,
									0.037,
									0.135
								],
								"e": 0.065
							}
						],
						[
							{
								"h": [
									0.024,
									1.0,
									0.15,
									1.0,
									1.0,
									1.0,
									0.01,
									1.0,
									1.0,
									0.561,
									1.0,
									0.58
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.171,
									0.349,
									0.427,
									0.239,
									0.226,
									0.635,
									0.14,
									1.0,
									0.699
								],
								"e": 0.51
							},
							{
								"h": [
									0.215,
									0.061,
									1.0,
									0.774,
									0.813,
									1.0,
									1.0,
									1.0,
									1.0,
									0.753,
									0.452,
									0.116
								],
								"e": 0.944
							},
							{
								"h": [
									0.007,
									0.389,
									0.412,
									0.272,
									0.036,
									0.096,
									0.135,
									0.126,
									1.0,
									0.26,
									0.212,
									0.741
								],
								"e": 0.277
							},
							{
								"h": [
									0.157,
									0.067,
									0.069,
									0.194,
									0.054,
									0.061,
									0.149,
									1.0,
									0.048,
									0.08,
									0.068,
									0.0
								],
								"e": 0.147
							},
							{
								"h": [
									0.012,
									0.001,
									0.079,
									0.116,
									0.206,
									0.051,
									0.027,
									0.031,
									0.023,
									0.124,
									0.345,
									0.215
								],
								"e": 0.077
							},
							{
								"h": [
									0.022,
									0.119,
									0.126,
									0.048,
									0.053,
									0.338,
									0.096,
									0.117,
									0.036,
									0.001,
									0.022,
									0.014
								],
								"e": 0.072
							},
							{
								"h": [
									0.009,
									0.078,
									0.001,
									0.063,
									0.103,
									0.085,
									0.146,
									0.006,
									0.016,
									0.038,
									0.007,
									0.015
								],
								"e": 0.04
							},
							{
								"h": [
									0.039,
									0.008,
									1.0,
									1.0,
									0.022,
									0.125,
									0.04,
									0.006,
									0.107,
									0.338,
									0.138,
									0.036
								],
								"e": 0.134
							},
							{
								"h": [
									0.016,
									0.032,
									0.063,
									0.231,
									0.143,
									0.027,
									0.006,
									0.072,
									0.02,
									0.01,
									0.034,
									0.011
								],
								"e": 0.052
							},
							{
								"h": [
									0.014,
									0.027,
									0.038,
									0.143,
									0.175,
									0.153,
									0.007,
									0.076,
									0.051,
									0.146,
									0.008,
									0.062
								],
								"e": 0.087
							},
							{
								"h": [
									1.0,
									0.039,
									0.038,
									0.076,
									0.104,
									0.169,
									0.024,
									0.213,
									1.0,
									0.018,
									0.497,
									0.011
								],
								"e": 0.267
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Other",
					"o": " words",
					"t": " times",
					"r": [
						{
							"p": "wise",
							"b": 0.0,
							"k": {
								"wise": 0.464,
								"worldly": 0.184,
								" important": 0.041,
								" major": 0.039,
								" notable": 0.022,
								" alternative": 0.021,
								" parts": 0.007,
								" Other": 0.006,
								"body": 0.005,
								" aspects": 0.005
							}
						},
						{
							"p": "wise",
							"b": 0.0,
							"k": {
								"wise": 0.683,
								"worldly": 0.275,
								" important": 0.014,
								" notable": 0.01,
								" major": 0.004,
								"body": 0.001,
								"where": 0.001,
								" factors": 0.001,
								" alternative": 0.001,
								"world": 0.001
							}
						},
						{
							"p": "worldly",
							"b": 0.0,
							"k": {
								"worldly": 0.604,
								"wise": 0.348,
								" important": 0.016,
								" notable": 0.012,
								" major": 0.005,
								" types": 0.002,
								"body": 0.001,
								"where": 0.001,
								" factors": 0.001,
								"world": 0.001
							}
						},
						{
							"p": "wise",
							"b": 0.0,
							"k": {
								"wise": 0.662,
								"worldly": 0.328,
								" notable": 0.004,
								" important": 0.001,
								" major": 0.001,
								" types": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": "worldly",
							"b": 0.0,
							"k": {
								"worldly": 0.73,
								"wise": 0.249,
								" notable": 0.004,
								" important": 0.003,
								" types": 0.003,
								" factors": 0.001,
								" forms": 0.001,
								" examples": 0.001,
								" kinds": 0.001,
								" aspects": 0.001
							}
						},
						{
							"p": "worldly",
							"b": 0.0,
							"k": {
								"worldly": 0.506,
								"wise": 0.448,
								" notable": 0.011,
								" factors": 0.006,
								" important": 0.005,
								" examples": 0.004,
								" types": 0.003,
								" sources": 0.002,
								" things": 0.001,
								" countries": 0.001
							}
						},
						{
							"p": "worldly",
							"b": 0.0,
							"k": {
								"worldly": 0.865,
								"wise": 0.117,
								" factors": 0.005,
								" examples": 0.003,
								" types": 0.002,
								" important": 0.001,
								" countries": 0.001,
								" sources": 0.001,
								" forms": 0.001,
								" notable": 0.001
							}
						},
						{
							"p": "worldly",
							"b": 0.012,
							"k": {
								"worldly": 0.661,
								"wise": 0.162,
								" examples": 0.027,
								" factors": 0.023,
								" countries": 0.015,
								" sources": 0.014,
								" notable": 0.014,
								" times": 0.012,
								" than": 0.01,
								" types": 0.01
							}
						},
						{
							"p": " languages",
							"b": 0.039,
							"k": {
								" languages": 0.309,
								" examples": 0.131,
								" types": 0.095,
								" words": 0.082,
								" countries": 0.048,
								" times": 0.039,
								" sources": 0.03,
								" than": 0.028,
								" studies": 0.024,
								" forms": 0.021
							}
						},
						{
							"p": " words",
							"b": 0.109,
							"k": {
								" words": 0.552,
								" times": 0.109,
								" examples": 0.078,
								" definitions": 0.064,
								" terms": 0.028,
								" meanings": 0.027,
								" languages": 0.026,
								" phrases": 0.016,
								" types": 0.015,
								" people": 0.007
							}
						},
						{
							"p": " words",
							"b": 0.124,
							"k": {
								" words": 0.205,
								" times": 0.124,
								" than": 0.099,
								" terms": 0.073,
								" examples": 0.046,
								" people": 0.045,
								" definitions": 0.032,
								" scientists": 0.027,
								" things": 0.025,
								" forms": 0.021
							}
						},
						{
							"p": " words",
							"b": 0.019,
							"k": {
								" words": 0.179,
								" people": 0.071,
								" than": 0.053,
								" scientists": 0.048,
								" things": 0.044,
								" terms": 0.026,
								" times": 0.019,
								" studies": 0.014,
								" physicists": 0.013,
								" researchers": 0.012
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.004,
									0.965,
									0.035,
									0.094,
									0.082,
									1.0,
									0.048,
									0.325,
									1.0,
									0.771,
									1.0
								],
								"e": 0.319
							},
							{
								"h": [
									0.328,
									0.08,
									0.5,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.012,
									0.067
								],
								"e": 1.0
							},
							{
								"h": [
									0.914,
									1.0,
									0.135,
									0.451,
									0.647,
									0.132,
									0.25,
									0.561,
									0.544,
									0.452,
									1.0,
									1.0
								],
								"e": 0.686
							},
							{
								"h": [
									1.0,
									1.0,
									0.952,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.807,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.575,
									0.762,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.589,
									0.002,
									0.701,
									0.0,
									0.025,
									0.0,
									0.238,
									0.014,
									0.413,
									0.487,
									0.297,
									0.397
								],
								"e": 0.142
							},
							{
								"h": [
									0.029,
									0.038,
									0.147,
									0.046,
									0.16,
									0.099,
									0.056,
									0.144,
									0.548,
									0.238,
									0.321,
									0.004
								],
								"e": 0.171
							},
							{
								"h": [
									0.395,
									0.034,
									0.001,
									0.02,
									0.009,
									0.01,
									0.027,
									0.082,
									0.046,
									0.034,
									0.201,
									0.019
								],
								"e": 0.054
							},
							{
								"h": [
									0.001,
									0.056,
									0.011,
									0.0,
									0.023,
									0.145,
									0.01,
									0.004,
									0.014,
									0.031,
									0.034,
									0.027
								],
								"e": 0.027
							},
							{
								"h": [
									0.002,
									0.018,
									0.07,
									0.02,
									0.008,
									0.004,
									0.268,
									0.029,
									0.183,
									0.082,
									0.013,
									0.0
								],
								"e": 0.052
							},
							{
								"h": [
									0.119,
									0.0,
									0.046,
									0.314,
									0.06,
									0.028,
									0.004,
									0.005,
									0.238,
									0.059,
									0.078,
									0.017
								],
								"e": 0.068
							},
							{
								"h": [
									0.009,
									0.392,
									0.016,
									0.151,
									0.016,
									0.044,
									0.015,
									0.198,
									0.004,
									0.001,
									0.006,
									0.033
								],
								"e": 0.051
							},
							{
								"h": [
									0.01,
									0.054,
									0.002,
									0.017,
									0.007,
									0.044,
									0.044,
									0.207,
									0.059,
									0.003,
									0.033,
									0.008
								],
								"e": 0.036
							},
							{
								"h": [
									0.03,
									0.094,
									0.033,
									0.174,
									0.002,
									0.004,
									0.042,
									0.005,
									0.178,
									0.094,
									0.05,
									0.014
								],
								"e": 0.044
							},
							{
								"h": [
									0.012,
									0.032,
									0.067,
									0.007,
									0.007,
									0.009,
									0.002,
									0.064,
									0.023,
									0.01,
									0.004,
									0.007
								],
								"e": 0.017
							},
							{
								"h": [
									0.008,
									0.007,
									0.005,
									0.078,
									0.038,
									0.001,
									0.004,
									0.037,
									0.012,
									0.003,
									0.004,
									0.057
								],
								"e": 0.018
							},
							{
								"h": [
									0.4,
									0.021,
									0.012,
									0.084,
									0.07,
									0.028,
									0.01,
									0.02,
									0.415,
									0.015,
									0.05,
									0.048
								],
								"e": 0.057
							}
						],
						[
							{
								"h": [
									0.391,
									0.011,
									0.605,
									0.0,
									0.008,
									0.001,
									0.284,
									0.007,
									0.207,
									0.48,
									0.403,
									0.493
								],
								"e": 0.13
							},
							{
								"h": [
									0.038,
									0.009,
									0.153,
									0.049,
									0.149,
									0.072,
									0.367,
									0.29,
									0.258,
									0.129,
									0.34,
									0.016
								],
								"e": 0.2
							},
							{
								"h": [
									0.204,
									0.119,
									0.0,
									0.049,
									0.001,
									0.006,
									0.029,
									0.216,
									0.005,
									0.045,
									0.311,
									0.032
								],
								"e": 0.065
							},
							{
								"h": [
									0.02,
									0.03,
									0.019,
									0.0,
									0.013,
									0.171,
									0.032,
									0.015,
									0.002,
									0.011,
									0.017,
									0.01
								],
								"e": 0.025
							},
							{
								"h": [
									0.002,
									0.002,
									0.016,
									0.023,
									0.022,
									0.022,
									0.056,
									0.041,
									0.099,
									0.018,
									0.011,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									0.065,
									0.0,
									0.014,
									0.055,
									0.019,
									0.01,
									0.003,
									0.02,
									0.048,
									0.129,
									0.042,
									0.028
								],
								"e": 0.032
							},
							{
								"h": [
									0.012,
									0.076,
									0.017,
									0.089,
									0.061,
									0.018,
									0.014,
									0.133,
									0.004,
									0.001,
									0.019,
									0.004
								],
								"e": 0.026
							},
							{
								"h": [
									0.009,
									0.029,
									0.001,
									0.016,
									0.003,
									0.018,
									0.105,
									0.024,
									0.017,
									0.007,
									0.055,
									0.004
								],
								"e": 0.021
							},
							{
								"h": [
									0.034,
									0.014,
									0.109,
									0.046,
									0.001,
									0.003,
									0.052,
									0.005,
									0.174,
									0.101,
									0.054,
									0.044
								],
								"e": 0.039
							},
							{
								"h": [
									0.036,
									0.045,
									0.068,
									0.003,
									0.025,
									0.013,
									0.02,
									0.026,
									0.024,
									0.021,
									0.001,
									0.006
								],
								"e": 0.02
							},
							{
								"h": [
									0.054,
									0.017,
									0.016,
									0.028,
									0.097,
									0.001,
									0.026,
									0.116,
									0.021,
									0.003,
									0.008,
									0.025
								],
								"e": 0.027
							},
							{
								"h": [
									0.262,
									0.101,
									0.009,
									0.034,
									0.022,
									0.014,
									0.007,
									0.04,
									0.805,
									0.008,
									0.012,
									0.01
								],
								"e": 0.056
							}
						],
						[
							{
								"h": [
									0.279,
									0.0,
									0.358,
									0.0,
									0.015,
									0.0,
									0.243,
									0.013,
									0.218,
									0.401,
									0.156,
									0.633
								],
								"e": 0.102
							},
							{
								"h": [
									0.029,
									0.013,
									0.252,
									0.068,
									0.164,
									0.081,
									0.037,
									0.223,
									0.282,
									0.309,
									0.455,
									0.002
								],
								"e": 0.173
							},
							{
								"h": [
									0.099,
									0.064,
									0.001,
									0.033,
									0.002,
									0.005,
									0.028,
									0.297,
									0.022,
									0.038,
									0.274,
									0.03
								],
								"e": 0.053
							},
							{
								"h": [
									0.0,
									0.034,
									0.03,
									0.0,
									0.005,
									0.073,
									0.004,
									0.021,
									0.01,
									0.004,
									0.016,
									0.015
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.007,
									0.02,
									0.012,
									0.013,
									0.012,
									0.159,
									0.011,
									0.02,
									0.027,
									0.008,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.063,
									0.0,
									0.024,
									0.071,
									0.024,
									0.019,
									0.005,
									0.003,
									0.027,
									0.052,
									0.093,
									0.013
								],
								"e": 0.027
							},
							{
								"h": [
									0.015,
									0.032,
									0.012,
									0.176,
									0.031,
									0.032,
									0.008,
									0.267,
									0.003,
									0.003,
									0.011,
									0.004
								],
								"e": 0.03
							},
							{
								"h": [
									0.004,
									0.015,
									0.001,
									0.019,
									0.006,
									0.011,
									0.067,
									0.065,
									0.041,
									0.001,
									0.009,
									0.005
								],
								"e": 0.017
							},
							{
								"h": [
									0.022,
									0.016,
									0.088,
									0.071,
									0.001,
									0.004,
									0.095,
									0.008,
									0.249,
									0.076,
									0.026,
									0.03
								],
								"e": 0.04
							},
							{
								"h": [
									0.075,
									0.037,
									0.075,
									0.002,
									0.077,
									0.009,
									0.008,
									0.052,
									0.01,
									0.014,
									0.007,
									0.006
								],
								"e": 0.027
							},
							{
								"h": [
									0.02,
									0.007,
									0.015,
									0.036,
									0.072,
									0.001,
									0.026,
									0.126,
									0.01,
									0.003,
									0.005,
									0.048
								],
								"e": 0.024
							},
							{
								"h": [
									0.3,
									0.029,
									0.011,
									0.046,
									0.059,
									0.042,
									0.007,
									0.016,
									0.051,
									0.016,
									0.016,
									0.042
								],
								"e": 0.038
							}
						],
						[
							{
								"h": [
									0.202,
									0.0,
									0.415,
									0.0,
									0.019,
									0.0,
									0.391,
									0.006,
									0.202,
									0.274,
									0.112,
									0.183
								],
								"e": 0.084
							},
							{
								"h": [
									0.046,
									0.023,
									0.276,
									0.069,
									0.212,
									0.029,
									0.08,
									0.438,
									0.13,
									0.057,
									0.481,
									0.001
								],
								"e": 0.166
							},
							{
								"h": [
									0.468,
									0.087,
									0.002,
									0.075,
									0.001,
									0.009,
									0.014,
									0.37,
									0.008,
									0.069,
									0.307,
									0.032
								],
								"e": 0.081
							},
							{
								"h": [
									0.0,
									0.015,
									0.017,
									0.0,
									0.004,
									0.104,
									0.22,
									0.047,
									0.006,
									0.014,
									0.035,
									0.008
								],
								"e": 0.032
							},
							{
								"h": [
									0.001,
									0.004,
									0.005,
									0.036,
									0.005,
									0.011,
									0.109,
									0.022,
									0.011,
									0.01,
									0.004,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.02,
									0.0,
									0.042,
									0.033,
									0.023,
									0.03,
									0.021,
									0.007,
									0.053,
									0.053,
									0.048,
									0.005
								],
								"e": 0.025
							},
							{
								"h": [
									0.005,
									0.067,
									0.019,
									0.03,
									0.034,
									0.023,
									0.009,
									0.348,
									0.011,
									0.001,
									0.005,
									0.002
								],
								"e": 0.024
							},
							{
								"h": [
									0.002,
									0.044,
									0.002,
									0.012,
									0.001,
									0.002,
									0.054,
									0.061,
									0.102,
									0.008,
									0.025,
									0.019
								],
								"e": 0.024
							},
							{
								"h": [
									0.007,
									0.025,
									0.083,
									0.076,
									0.006,
									0.005,
									0.068,
									0.006,
									0.228,
									0.464,
									0.032,
									0.071
								],
								"e": 0.054
							},
							{
								"h": [
									0.171,
									0.004,
									0.661,
									0.021,
									0.009,
									0.022,
									0.202,
									0.021,
									0.263,
									0.128,
									0.002,
									0.02
								],
								"e": 0.084
							},
							{
								"h": [
									0.509,
									0.34,
									0.079,
									0.064,
									0.046,
									0.002,
									0.073,
									0.397,
									0.016,
									0.003,
									0.296,
									0.06
								],
								"e": 0.129
							},
							{
								"h": [
									0.224,
									0.026,
									0.171,
									0.604,
									0.138,
									0.036,
									0.179,
									0.052,
									0.25,
									0.038,
									0.1,
									0.997
								],
								"e": 0.184
							}
						],
						[
							{
								"h": [
									0.522,
									0.001,
									0.263,
									0.0,
									0.016,
									0.0,
									0.133,
									0.03,
									0.43,
									0.435,
									0.268,
									0.379
								],
								"e": 0.113
							},
							{
								"h": [
									0.048,
									0.024,
									0.368,
									0.063,
									0.169,
									0.06,
									0.068,
									0.185,
									0.308,
									0.353,
									0.407,
									0.003
								],
								"e": 0.188
							},
							{
								"h": [
									0.162,
									0.072,
									0.0,
									0.077,
									0.002,
									0.015,
									0.048,
									0.48,
									0.005,
									0.033,
									0.287,
									0.041
								],
								"e": 0.071
							},
							{
								"h": [
									0.004,
									0.036,
									0.007,
									0.0,
									0.005,
									0.06,
									0.011,
									0.036,
									0.007,
									0.002,
									0.017,
									0.006
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.002,
									0.025,
									0.012,
									0.006,
									0.011,
									0.059,
									0.004,
									0.02,
									0.016,
									0.015,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.022,
									0.0,
									0.02,
									0.046,
									0.023,
									0.0,
									0.005,
									0.001,
									0.016,
									0.047,
									0.217,
									0.005
								],
								"e": 0.023
							},
							{
								"h": [
									0.007,
									0.087,
									0.021,
									0.131,
									0.083,
									0.031,
									0.006,
									0.557,
									0.001,
									0.0,
									0.006,
									0.001
								],
								"e": 0.04
							},
							{
								"h": [
									0.002,
									0.072,
									0.0,
									0.007,
									0.005,
									0.029,
									0.044,
									0.067,
									0.063,
									0.004,
									0.018,
									0.002
								],
								"e": 0.023
							},
							{
								"h": [
									0.073,
									0.036,
									0.062,
									0.599,
									0.004,
									0.007,
									0.077,
									0.011,
									0.216,
									0.092,
									0.017,
									0.017
								],
								"e": 0.067
							},
							{
								"h": [
									0.075,
									0.022,
									0.044,
									0.007,
									0.023,
									0.018,
									0.002,
									0.012,
									0.021,
									0.013,
									0.003,
									0.001
								],
								"e": 0.017
							},
							{
								"h": [
									0.014,
									0.007,
									0.009,
									0.015,
									0.145,
									0.002,
									0.019,
									0.057,
									0.02,
									0.005,
									0.006,
									0.063
								],
								"e": 0.024
							},
							{
								"h": [
									0.118,
									0.029,
									0.01,
									0.055,
									0.041,
									0.01,
									0.005,
									0.009,
									0.959,
									0.003,
									0.007,
									0.025
								],
								"e": 0.047
							}
						],
						[
							{
								"h": [
									0.217,
									0.0,
									0.181,
									0.0,
									0.024,
									0.0,
									0.386,
									0.029,
									0.111,
									0.332,
									0.108,
									0.613
								],
								"e": 0.093
							},
							{
								"h": [
									0.04,
									0.023,
									0.333,
									0.076,
									0.204,
									0.05,
									0.067,
									0.236,
									0.223,
									0.211,
									0.553,
									0.001
								],
								"e": 0.178
							},
							{
								"h": [
									0.157,
									0.053,
									0.003,
									0.179,
									0.003,
									0.014,
									0.062,
									0.53,
									0.029,
									0.055,
									0.421,
									0.052
								],
								"e": 0.091
							},
							{
								"h": [
									0.0,
									0.02,
									0.013,
									0.001,
									0.003,
									0.05,
									0.003,
									0.032,
									0.018,
									0.006,
									0.011,
									0.01
								],
								"e": 0.011
							},
							{
								"h": [
									0.003,
									0.008,
									0.027,
									0.003,
									0.011,
									0.002,
									0.124,
									0.003,
									0.031,
									0.022,
									0.015,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.003,
									0.0,
									0.013,
									0.081,
									0.028,
									0.003,
									0.011,
									0.002,
									0.009,
									0.081,
									0.412,
									0.005
								],
								"e": 0.035
							},
							{
								"h": [
									0.012,
									0.006,
									0.019,
									0.119,
									0.017,
									0.03,
									0.011,
									0.212,
									0.002,
									0.0,
									0.009,
									0.002
								],
								"e": 0.022
							},
							{
								"h": [
									0.002,
									0.091,
									0.0,
									0.024,
									0.012,
									0.048,
									0.084,
									0.018,
									0.027,
									0.002,
									0.012,
									0.002
								],
								"e": 0.024
							},
							{
								"h": [
									0.02,
									0.03,
									0.169,
									0.337,
									0.003,
									0.008,
									0.023,
									0.008,
									1.0,
									0.198,
									0.06,
									0.022
								],
								"e": 0.088
							},
							{
								"h": [
									0.294,
									0.048,
									0.45,
									0.005,
									0.054,
									0.014,
									0.011,
									0.056,
									0.017,
									0.045,
									0.009,
									0.002
								],
								"e": 0.054
							},
							{
								"h": [
									0.017,
									0.016,
									0.009,
									0.025,
									0.096,
									0.002,
									0.022,
									0.254,
									0.013,
									0.006,
									0.005,
									0.164
								],
								"e": 0.037
							},
							{
								"h": [
									0.27,
									0.019,
									0.011,
									0.028,
									0.052,
									0.04,
									0.006,
									0.012,
									0.219,
									0.025,
									0.025,
									0.081
								],
								"e": 0.042
							}
						],
						[
							{
								"h": [
									0.36,
									0.001,
									0.308,
									0.0,
									0.037,
									0.0,
									0.117,
									0.083,
									0.797,
									0.472,
									0.243,
									0.265
								],
								"e": 0.128
							},
							{
								"h": [
									0.173,
									0.037,
									0.491,
									0.07,
									0.174,
									0.168,
									0.055,
									0.102,
									0.383,
									0.236,
									0.403,
									0.003
								],
								"e": 0.213
							},
							{
								"h": [
									0.193,
									0.017,
									0.002,
									0.249,
									0.074,
									0.013,
									0.097,
									0.374,
									0.034,
									0.02,
									0.191,
									0.036
								],
								"e": 0.085
							},
							{
								"h": [
									0.004,
									0.025,
									0.01,
									0.0,
									0.007,
									0.051,
									0.02,
									0.005,
									0.001,
									0.001,
									0.015,
									0.014
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.0,
									0.007,
									0.001,
									0.012,
									0.005,
									0.043,
									0.003,
									0.018,
									0.016,
									0.018,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.002,
									0.0,
									0.014,
									0.016,
									0.002,
									0.0,
									0.001,
									0.001,
									0.005,
									0.022,
									0.108,
									0.004
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.006,
									0.011,
									0.022,
									0.014,
									0.011,
									0.003,
									0.165,
									0.001,
									0.0,
									0.004,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.033,
									0.0,
									0.005,
									0.003,
									0.006,
									0.03,
									0.019,
									0.006,
									0.001,
									0.001,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.037,
									0.003,
									0.048,
									0.116,
									0.002,
									0.001,
									0.003,
									0.002,
									0.125,
									0.266,
									0.009,
									0.004
								],
								"e": 0.027
							},
							{
								"h": [
									0.01,
									0.004,
									0.075,
									0.004,
									0.004,
									0.005,
									0.001,
									0.011,
									0.012,
									0.003,
									0.001,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.006,
									0.003,
									0.003,
									0.007,
									0.026,
									0.001,
									0.002,
									0.052,
									0.013,
									0.001,
									0.003,
									0.009
								],
								"e": 0.008
							},
							{
								"h": [
									0.143,
									0.016,
									0.007,
									0.028,
									0.033,
									0.005,
									0.007,
									0.017,
									0.33,
									0.001,
									0.008,
									0.044
								],
								"e": 0.028
							}
						],
						[
							{
								"h": [
									0.356,
									0.0,
									0.175,
									0.0,
									0.014,
									0.0,
									0.211,
									0.011,
									0.312,
									0.419,
									0.11,
									0.314
								],
								"e": 0.087
							},
							{
								"h": [
									0.083,
									0.04,
									0.342,
									0.078,
									0.216,
									0.049,
									0.074,
									0.259,
									0.169,
									0.179,
									0.587,
									0.002
								],
								"e": 0.188
							},
							{
								"h": [
									0.121,
									0.066,
									0.005,
									0.195,
									0.003,
									0.017,
									0.037,
									0.64,
									0.024,
									0.345,
									0.445,
									0.059
								],
								"e": 0.121
							},
							{
								"h": [
									0.001,
									0.012,
									0.002,
									0.001,
									0.005,
									0.044,
									0.01,
									0.033,
									0.001,
									0.002,
									0.02,
									0.006
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.002,
									0.004,
									0.029,
									0.005,
									0.008,
									0.053,
									0.004,
									0.117,
									0.015,
									0.002,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.007,
									0.0,
									0.033,
									0.038,
									0.04,
									0.005,
									0.034,
									0.01,
									0.015,
									0.064,
									0.074,
									0.003
								],
								"e": 0.022
							},
							{
								"h": [
									0.002,
									0.006,
									0.011,
									0.02,
									0.017,
									0.025,
									0.006,
									0.2,
									0.005,
									0.001,
									0.003,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.01,
									0.0,
									0.019,
									0.001,
									0.004,
									0.051,
									0.005,
									0.015,
									0.006,
									0.066,
									0.005
								],
								"e": 0.014
							},
							{
								"h": [
									0.01,
									0.008,
									0.061,
									0.073,
									0.005,
									0.008,
									0.003,
									0.002,
									0.289,
									0.301,
									0.011,
									0.054
								],
								"e": 0.038
							},
							{
								"h": [
									0.01,
									0.007,
									0.174,
									0.018,
									0.003,
									0.003,
									0.104,
									0.004,
									0.047,
									0.074,
									0.002,
									0.003
								],
								"e": 0.027
							},
							{
								"h": [
									0.077,
									0.043,
									0.021,
									0.204,
									0.022,
									0.001,
									0.18,
									0.172,
									0.018,
									0.001,
									0.036,
									0.045
								],
								"e": 0.06
							},
							{
								"h": [
									0.076,
									0.014,
									0.009,
									0.119,
									0.021,
									0.019,
									0.01,
									0.167,
									0.184,
									0.006,
									0.005,
									0.056
								],
								"e": 0.043
							}
						],
						[
							{
								"h": [
									0.268,
									0.001,
									0.738,
									0.0,
									0.147,
									0.0,
									0.084,
									0.171,
									0.83,
									0.511,
									0.295,
									0.233
								],
								"e": 0.17
							},
							{
								"h": [
									0.27,
									0.081,
									0.612,
									0.076,
									0.183,
									0.165,
									0.044,
									0.165,
									0.235,
									0.092,
									0.443,
									0.052
								],
								"e": 0.238
							},
							{
								"h": [
									0.842,
									0.043,
									0.009,
									0.208,
									0.06,
									0.046,
									0.08,
									0.351,
									0.106,
									0.038,
									0.174,
									0.035
								],
								"e": 0.127
							},
							{
								"h": [
									0.001,
									0.014,
									0.022,
									0.001,
									0.004,
									0.057,
									0.009,
									0.02,
									0.018,
									0.003,
									0.004,
									0.014
								],
								"e": 0.011
							},
							{
								"h": [
									0.002,
									0.006,
									0.001,
									0.021,
									0.002,
									0.009,
									0.045,
									0.001,
									0.03,
									0.014,
									0.005,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.008,
									0.0,
									0.009,
									0.006,
									0.078,
									0.0,
									0.006,
									0.008,
									0.003,
									0.017,
									0.053,
									0.004
								],
								"e": 0.013
							},
							{
								"h": [
									0.002,
									0.005,
									0.005,
									0.006,
									0.006,
									0.015,
									0.001,
									0.092,
									0.008,
									0.0,
									0.008,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.005,
									0.001,
									0.003,
									0.032,
									0.002,
									0.006,
									0.001,
									0.0,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.006,
									0.001,
									0.03,
									0.034,
									0.002,
									0.002,
									0.002,
									0.001,
									0.029,
									0.171,
									0.007,
									0.004
								],
								"e": 0.012
							},
							{
								"h": [
									0.004,
									0.0,
									0.257,
									0.001,
									0.0,
									0.005,
									0.0,
									0.003,
									0.028,
									0.0,
									0.001,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.009,
									0.002,
									0.001,
									0.004,
									0.023,
									0.001,
									0.0,
									0.026,
									0.009,
									0.001,
									0.001,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.144,
									0.005,
									0.004,
									0.023,
									0.047,
									0.001,
									0.002,
									0.003,
									0.137,
									0.001,
									0.007,
									0.021
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.312,
									0.001,
									0.241,
									0.002,
									0.022,
									0.0,
									0.51,
									0.093,
									0.163,
									0.352,
									0.193,
									0.53
								],
								"e": 0.119
							},
							{
								"h": [
									0.177,
									0.076,
									0.506,
									0.095,
									0.305,
									0.058,
									0.07,
									0.198,
									0.193,
									0.17,
									0.809,
									0.003
								],
								"e": 0.237
							},
							{
								"h": [
									0.097,
									0.063,
									0.009,
									0.265,
									0.005,
									0.029,
									0.036,
									0.801,
									0.014,
									0.269,
									0.507,
									0.083
								],
								"e": 0.131
							},
							{
								"h": [
									0.001,
									0.021,
									0.035,
									0.005,
									0.007,
									0.016,
									0.03,
									0.034,
									0.004,
									0.003,
									0.026,
									0.015
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.004,
									0.001,
									0.021,
									0.009,
									0.009,
									0.029,
									0.002,
									0.013,
									0.012,
									0.001,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.003,
									0.0,
									0.017,
									0.019,
									0.028,
									0.001,
									0.013,
									0.006,
									0.004,
									0.065,
									0.083,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.011,
									0.022,
									0.003,
									0.023,
									0.07,
									0.002,
									0.643,
									0.013,
									0.0,
									0.006,
									0.002
								],
								"e": 0.028
							},
							{
								"h": [
									0.001,
									0.002,
									0.0,
									0.019,
									0.001,
									0.002,
									0.071,
									0.005,
									0.018,
									0.005,
									0.014,
									0.016
								],
								"e": 0.011
							},
							{
								"h": [
									0.004,
									0.01,
									0.165,
									0.039,
									0.005,
									0.008,
									0.003,
									0.003,
									0.122,
									1.0,
									0.014,
									0.04
								],
								"e": 0.057
							},
							{
								"h": [
									0.095,
									0.007,
									0.47,
									0.017,
									0.003,
									0.013,
									0.086,
									0.002,
									0.163,
									0.033,
									0.0,
									0.007
								],
								"e": 0.045
							},
							{
								"h": [
									0.347,
									0.154,
									0.044,
									0.016,
									0.171,
									0.0,
									0.013,
									0.264,
									0.01,
									0.001,
									0.028,
									0.02
								],
								"e": 0.067
							},
							{
								"h": [
									0.16,
									0.007,
									0.01,
									0.11,
									0.015,
									0.014,
									0.006,
									0.02,
									0.051,
									0.011,
									0.012,
									0.089
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.25,
									0.0,
									1.0,
									0.001,
									0.127,
									0.0,
									0.097,
									1.0,
									1.0,
									0.622,
									0.258,
									0.805
								],
								"e": 0.31
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.165,
									0.331,
									0.201,
									0.073,
									0.248,
									0.652,
									0.132,
									0.97,
									0.073
								],
								"e": 0.677
							},
							{
								"h": [
									0.445,
									0.084,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.863,
									1.0,
									1.0,
									0.551,
									0.12
								],
								"e": 1.0
							},
							{
								"h": [
									0.01,
									0.9,
									1.0,
									0.19,
									0.037,
									0.166,
									0.228,
									0.978,
									1.0,
									0.131,
									0.203,
									0.276
								],
								"e": 0.342
							},
							{
								"h": [
									0.113,
									0.726,
									0.068,
									0.043,
									0.03,
									0.054,
									0.306,
									0.055,
									0.142,
									0.072,
									0.065,
									1.0
								],
								"e": 0.256
							},
							{
								"h": [
									0.122,
									0.0,
									0.054,
									0.322,
									0.415,
									0.008,
									0.162,
									0.005,
									0.028,
									0.093,
									0.281,
									0.058
								],
								"e": 0.104
							},
							{
								"h": [
									0.019,
									0.092,
									0.088,
									0.053,
									0.13,
									0.162,
									0.034,
									0.833,
									0.088,
									0.001,
									0.013,
									0.048
								],
								"e": 0.079
							},
							{
								"h": [
									0.121,
									0.057,
									0.0,
									0.068,
									0.006,
									0.036,
									0.136,
									0.021,
									0.202,
									0.097,
									0.013,
									0.004
								],
								"e": 0.058
							},
							{
								"h": [
									0.1,
									0.013,
									0.121,
									0.248,
									0.077,
									0.098,
									0.076,
									0.081,
									0.393,
									0.836,
									0.066,
									0.017
								],
								"e": 0.115
							},
							{
								"h": [
									0.092,
									0.036,
									0.875,
									0.38,
									0.021,
									0.001,
									0.004,
									0.052,
									0.027,
									0.007,
									0.048,
									0.007
								],
								"e": 0.078
							},
							{
								"h": [
									0.046,
									0.005,
									0.015,
									0.032,
									0.073,
									0.015,
									0.033,
									0.117,
									0.037,
									0.04,
									0.003,
									0.697
								],
								"e": 0.07
							},
							{
								"h": [
									0.264,
									0.038,
									0.015,
									0.135,
									0.118,
									0.022,
									0.011,
									0.025,
									0.74,
									0.01,
									0.083,
									0.072
								],
								"e": 0.074
							}
						],
						[
							{
								"h": [
									0.358,
									1.0,
									0.198,
									1.0,
									1.0,
									1.0,
									0.348,
									0.404,
									0.133,
									0.379,
									1.0,
									0.338
								],
								"e": 1.0
							},
							{
								"h": [
									0.735,
									0.208,
									0.776,
									0.13,
									0.405,
									0.479,
									0.12,
									0.24,
									0.366,
									0.197,
									1.0,
									1.0
								],
								"e": 0.767
							},
							{
								"h": [
									1.0,
									0.046,
									0.043,
									0.817,
									0.022,
									0.044,
									0.248,
									1.0,
									0.176,
									0.312,
									0.65,
									0.088
								],
								"e": 0.281
							},
							{
								"h": [
									0.065,
									0.444,
									0.418,
									0.005,
									0.013,
									0.094,
									0.567,
									0.084,
									0.039,
									0.079,
									0.121,
									0.233
								],
								"e": 0.147
							},
							{
								"h": [
									0.039,
									0.125,
									0.013,
									0.037,
									0.044,
									0.047,
									0.074,
									0.882,
									0.024,
									0.072,
									0.005,
									0.0
								],
								"e": 0.096
							},
							{
								"h": [
									0.016,
									0.0,
									0.045,
									0.143,
									0.016,
									0.042,
									0.032,
									0.028,
									0.031,
									0.024,
									0.064,
									0.239
								],
								"e": 0.053
							},
							{
								"h": [
									0.021,
									0.064,
									0.022,
									0.072,
									0.037,
									0.117,
									0.057,
									0.104,
									0.023,
									0.001,
									0.014,
									0.139
								],
								"e": 0.049
							},
							{
								"h": [
									0.036,
									0.016,
									0.006,
									0.04,
									0.013,
									0.024,
									0.069,
									0.023,
									0.013,
									0.063,
									0.036,
									0.002
								],
								"e": 0.027
							},
							{
								"h": [
									0.117,
									0.013,
									0.024,
									0.036,
									0.045,
									0.031,
									0.019,
									0.024,
									0.075,
									0.127,
									0.084,
									0.022
								],
								"e": 0.046
							},
							{
								"h": [
									0.029,
									0.035,
									0.022,
									0.067,
									0.013,
									0.04,
									0.002,
									0.026,
									0.023,
									0.012,
									0.017,
									0.001
								],
								"e": 0.023
							},
							{
								"h": [
									0.011,
									0.022,
									0.006,
									0.011,
									0.119,
									0.009,
									0.006,
									0.061,
									0.021,
									0.01,
									0.006,
									0.044
								],
								"e": 0.022
							},
							{
								"h": [
									0.193,
									0.039,
									0.005,
									0.076,
									0.07,
									0.015,
									0.005,
									0.034,
									1.0,
									0.006,
									0.028,
									0.035
								],
								"e": 0.061
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " times",
					"o": " they",
					"t": " when",
					"r": [
						{
							"p": " times",
							"b": 0.002,
							"k": {
								" times": 0.952,
								"c": 0.003,
								",": 0.002,
								" when": 0.002,
								"times": 0.002,
								" the": 0.001,
								" I": 0.001,
								" that": 0.001,
								" it": 0.001,
								" this": 0.001
							}
						},
						{
							"p": " times",
							"b": 0.015,
							"k": {
								" times": 0.524,
								"cale": 0.149,
								"c": 0.032,
								",": 0.018,
								" when": 0.015,
								" that": 0.012,
								" it": 0.012,
								" per": 0.007,
								"times": 0.007,
								" Warp": 0.007
							}
						},
						{
							"p": " times",
							"b": 0.101,
							"k": {
								" times": 0.398,
								" when": 0.101,
								"cale": 0.099,
								"c": 0.031,
								",": 0.025,
								" that": 0.024,
								"cia": 0.018,
								" it": 0.017,
								"times": 0.017,
								" they": 0.012
							}
						},
						{
							"p": " when",
							"b": 0.386,
							"k": {
								" when": 0.386,
								" it": 0.09,
								" they": 0.077,
								" times": 0.073,
								",": 0.03,
								" fact": 0.029,
								"times": 0.021,
								" I": 0.018,
								" periods": 0.015,
								" tables": 0.013
							}
						},
						{
							"p": " when",
							"b": 0.335,
							"k": {
								" when": 0.335,
								",": 0.186,
								" it": 0.086,
								" I": 0.049,
								" they": 0.035,
								" tables": 0.02,
								" however": 0.017,
								" though": 0.013,
								" listening": 0.013,
								" that": 0.01
							}
						},
						{
							"p": " when",
							"b": 0.131,
							"k": {
								" when": 0.131,
								" it": 0.13,
								",": 0.096,
								" people": 0.084,
								" they": 0.06,
								" I": 0.042,
								" things": 0.034,
								" however": 0.025,
								" fact": 0.019,
								" we": 0.017
							}
						},
						{
							"p": " they",
							"b": 0.062,
							"k": {
								" they": 0.348,
								" it": 0.337,
								",": 0.087,
								" when": 0.062,
								" we": 0.027,
								" people": 0.014,
								" I": 0.008,
								" fact": 0.007,
								" however": 0.007,
								" simply": 0.005
							}
						},
						{
							"p": " they",
							"b": 0.004,
							"k": {
								" they": 0.484,
								" it": 0.426,
								" we": 0.057,
								",": 0.009,
								" there": 0.005,
								" when": 0.004,
								" I": 0.003,
								" you": 0.003,
								" the": 0.001,
								" he": 0.001
							}
						},
						{
							"p": " they",
							"b": 0.02,
							"k": {
								" they": 0.83,
								" it": 0.135,
								" when": 0.02,
								" we": 0.008,
								",": 0.001,
								" you": 0.001,
								" people": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0
							}
						},
						{
							"p": " they",
							"b": 0.002,
							"k": {
								" they": 0.95,
								" it": 0.045,
								" when": 0.002,
								" you": 0.001,
								" we": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": " they",
							"b": 0.002,
							"k": {
								" they": 0.979,
								" it": 0.018,
								" when": 0.002,
								" we": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " they",
							"b": 0.031,
							"k": {
								" they": 0.508,
								" it": 0.156,
								",": 0.065,
								" plasma": 0.046,
								" you": 0.032,
								" when": 0.031,
								" the": 0.027,
								" we": 0.014,
								" a": 0.012,
								" people": 0.011
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.678,
									0.003,
									1.0,
									0.002,
									0.415,
									0.253,
									1.0,
									0.091,
									0.682,
									1.0,
									0.705,
									1.0
								],
								"e": 0.404
							},
							{
								"h": [
									0.015,
									0.073,
									0.463,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.014,
									0.061
								],
								"e": 1.0
							},
							{
								"h": [
									0.312,
									1.0,
									0.009,
									0.079,
									0.08,
									0.166,
									0.241,
									0.38,
									0.246,
									0.11,
									0.629,
									1.0
								],
								"e": 0.503
							},
							{
								"h": [
									1.0,
									1.0,
									0.343,
									0.317,
									1.0,
									1.0,
									0.167,
									1.0,
									0.911,
									0.773,
									1.0,
									0.121
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.802,
									1.0,
									0.526,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.667,
									1.0,
									1.0,
									0.69,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.486,
									0.538,
									1.0,
									1.0,
									1.0,
									0.903,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.651,
									0.628,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.458
								],
								"e": 1.0
							},
							{
								"h": [
									0.405,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									0.958,
									0.281
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.168,
									0.033,
									0.473,
									0.0,
									0.019,
									0.005,
									0.186,
									0.021,
									0.491,
									0.555,
									0.464,
									0.364
								],
								"e": 0.141
							},
							{
								"h": [
									0.003,
									0.051,
									0.156,
									0.036,
									0.143,
									0.37,
									0.097,
									0.13,
									0.338,
									0.233,
									0.303,
									0.027
								],
								"e": 0.208
							},
							{
								"h": [
									0.112,
									0.031,
									0.0,
									0.014,
									0.003,
									0.009,
									0.024,
									0.046,
									0.129,
									0.004,
									0.161,
									0.014
								],
								"e": 0.046
							},
							{
								"h": [
									0.024,
									0.019,
									0.006,
									0.0,
									0.006,
									0.057,
									0.001,
									0.002,
									0.012,
									0.046,
									0.031,
									0.004
								],
								"e": 0.023
							},
							{
								"h": [
									0.002,
									0.009,
									0.051,
									0.002,
									0.053,
									0.035,
									0.027,
									0.037,
									0.185,
									0.013,
									0.006,
									0.0
								],
								"e": 0.036
							},
							{
								"h": [
									0.18,
									0.011,
									0.034,
									0.14,
									0.013,
									0.224,
									0.0,
									0.003,
									1.0,
									0.033,
									0.135,
									0.107
								],
								"e": 0.113
							},
							{
								"h": [
									0.046,
									0.343,
									0.027,
									0.04,
									0.007,
									0.034,
									0.021,
									0.094,
									0.004,
									0.159,
									0.004,
									0.016
								],
								"e": 0.051
							},
							{
								"h": [
									0.007,
									0.035,
									0.024,
									0.005,
									0.039,
									0.013,
									0.033,
									0.122,
									0.064,
									0.006,
									0.291,
									0.367
								],
								"e": 0.067
							},
							{
								"h": [
									0.018,
									1.0,
									0.065,
									0.051,
									0.003,
									0.012,
									0.06,
									0.003,
									0.064,
									0.013,
									0.028,
									0.004
								],
								"e": 0.074
							},
							{
								"h": [
									0.004,
									0.047,
									0.038,
									0.003,
									0.015,
									0.017,
									0.003,
									0.024,
									0.016,
									0.013,
									0.0,
									0.023
								],
								"e": 0.016
							},
							{
								"h": [
									0.005,
									0.023,
									0.042,
									0.203,
									0.076,
									0.003,
									0.007,
									0.174,
									0.182,
									0.031,
									0.012,
									0.022
								],
								"e": 0.054
							},
							{
								"h": [
									1.0,
									0.033,
									0.017,
									0.209,
									0.068,
									0.194,
									0.018,
									0.216,
									0.286,
									0.03,
									0.291,
									0.077
								],
								"e": 0.146
							}
						],
						[
							{
								"h": [
									0.177,
									0.009,
									0.49,
									0.0,
									0.008,
									0.001,
									0.441,
									0.015,
									0.356,
									0.553,
									0.356,
									0.2
								],
								"e": 0.124
							},
							{
								"h": [
									0.005,
									0.012,
									0.148,
									0.037,
									0.126,
									0.067,
									0.126,
									0.181,
									0.164,
									0.079,
									0.312,
									0.003
								],
								"e": 0.125
							},
							{
								"h": [
									0.036,
									0.051,
									0.0,
									0.001,
									0.001,
									0.004,
									0.03,
									0.112,
									0.003,
									0.015,
									0.18,
									0.02
								],
								"e": 0.035
							},
							{
								"h": [
									0.003,
									0.015,
									0.003,
									0.0,
									0.004,
									0.065,
									0.005,
									0.006,
									0.002,
									0.01,
									0.014,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.0,
									0.018,
									0.004,
									0.027,
									0.007,
									0.011,
									0.022,
									0.072,
									0.005,
									0.02,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.065,
									0.004,
									0.014,
									0.046,
									0.004,
									1.0,
									0.0,
									0.001,
									0.676,
									0.065,
									0.031,
									0.043
								],
								"e": 0.119
							},
							{
								"h": [
									0.02,
									0.045,
									0.05,
									0.057,
									0.016,
									0.016,
									0.014,
									0.072,
									0.007,
									0.033,
									0.007,
									0.004
								],
								"e": 0.024
							},
							{
								"h": [
									0.002,
									0.015,
									0.01,
									0.01,
									0.006,
									0.003,
									0.041,
									0.104,
									0.007,
									0.017,
									0.173,
									0.053
								],
								"e": 0.026
							},
							{
								"h": [
									0.026,
									0.349,
									0.13,
									0.053,
									0.003,
									0.024,
									0.145,
									0.003,
									0.089,
									0.039,
									0.044,
									0.016
								],
								"e": 0.057
							},
							{
								"h": [
									0.031,
									0.119,
									0.046,
									0.006,
									0.055,
									0.028,
									0.079,
									0.096,
									0.013,
									0.093,
									0.001,
									0.009
								],
								"e": 0.044
							},
							{
								"h": [
									0.046,
									0.042,
									0.127,
									0.076,
									0.369,
									0.001,
									0.068,
									0.486,
									0.103,
									0.056,
									0.048,
									0.253
								],
								"e": 0.117
							},
							{
								"h": [
									0.347,
									0.272,
									0.011,
									0.069,
									0.056,
									0.079,
									0.011,
									0.619,
									0.379,
									0.016,
									0.035,
									0.006
								],
								"e": 0.121
							}
						],
						[
							{
								"h": [
									0.147,
									0.001,
									0.665,
									0.0,
									0.014,
									0.001,
									0.249,
									0.033,
									0.476,
									0.484,
									0.252,
									0.258
								],
								"e": 0.12
							},
							{
								"h": [
									0.003,
									0.019,
									0.253,
									0.055,
									0.154,
									0.155,
									0.125,
									0.174,
									0.22,
									0.176,
									0.472,
									0.004
								],
								"e": 0.177
							},
							{
								"h": [
									0.006,
									0.019,
									0.0,
									0.004,
									0.0,
									0.006,
									0.03,
									0.171,
									0.018,
									0.014,
									0.217,
									0.026
								],
								"e": 0.034
							},
							{
								"h": [
									0.004,
									0.009,
									0.036,
									0.0,
									0.004,
									0.158,
									0.001,
									0.039,
									0.007,
									0.166,
									0.007,
									0.003
								],
								"e": 0.037
							},
							{
								"h": [
									0.001,
									0.0,
									0.021,
									0.005,
									0.01,
									0.002,
									0.019,
									0.005,
									0.078,
									0.008,
									0.006,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.017,
									0.0,
									0.007,
									0.026,
									0.003,
									0.021,
									0.0,
									0.0,
									0.037,
									0.024,
									0.162,
									0.01
								],
								"e": 0.017
							},
							{
								"h": [
									0.033,
									0.162,
									0.008,
									0.075,
									0.01,
									0.017,
									0.011,
									0.38,
									0.002,
									0.012,
									0.006,
									0.002
								],
								"e": 0.034
							},
							{
								"h": [
									0.002,
									0.021,
									0.004,
									0.011,
									0.01,
									0.007,
									0.055,
									0.158,
									0.055,
									0.008,
									0.257,
									0.018
								],
								"e": 0.034
							},
							{
								"h": [
									0.01,
									0.054,
									0.061,
									0.099,
									0.024,
									0.006,
									0.297,
									0.011,
									0.195,
									0.04,
									0.019,
									0.009
								],
								"e": 0.048
							},
							{
								"h": [
									0.048,
									0.019,
									0.027,
									0.012,
									0.135,
									0.007,
									0.012,
									0.03,
									0.004,
									0.025,
									0.002,
									0.003
								],
								"e": 0.023
							},
							{
								"h": [
									0.008,
									0.013,
									0.039,
									0.116,
									0.187,
									0.003,
									0.049,
									0.236,
									0.02,
									0.054,
									0.012,
									0.058
								],
								"e": 0.055
							},
							{
								"h": [
									0.836,
									0.052,
									0.013,
									0.181,
									0.033,
									0.19,
									0.009,
									0.248,
									0.157,
									0.033,
									0.075,
									0.046
								],
								"e": 0.113
							}
						],
						[
							{
								"h": [
									1.0,
									0.0,
									0.326,
									0.001,
									0.225,
									0.0,
									0.969,
									0.036,
									0.547,
									0.312,
									0.098,
									0.169
								],
								"e": 0.197
							},
							{
								"h": [
									0.019,
									0.036,
									0.27,
									0.055,
									0.197,
									0.074,
									0.408,
									0.341,
									0.141,
									0.04,
									0.487,
									0.001
								],
								"e": 0.212
							},
							{
								"h": [
									0.079,
									0.065,
									0.0,
									0.01,
									0.0,
									0.004,
									0.017,
									0.186,
									0.009,
									0.045,
									0.217,
									0.027
								],
								"e": 0.052
							},
							{
								"h": [
									0.0,
									0.007,
									0.004,
									0.0,
									0.006,
									0.114,
									0.004,
									0.01,
									0.005,
									0.004,
									0.01,
									0.002
								],
								"e": 0.017
							},
							{
								"h": [
									0.001,
									0.001,
									0.006,
									0.003,
									0.002,
									0.017,
									0.021,
									0.009,
									0.011,
									0.017,
									0.0,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.029,
									0.0,
									0.006,
									0.043,
									0.009,
									0.032,
									0.0,
									0.001,
									0.062,
									0.012,
									0.008,
									0.011
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.132,
									0.029,
									0.034,
									0.004,
									0.035,
									0.005,
									0.108,
									0.011,
									0.021,
									0.01,
									0.002
								],
								"e": 0.022
							},
							{
								"h": [
									0.005,
									0.015,
									0.012,
									0.013,
									0.004,
									0.001,
									0.016,
									0.267,
									0.016,
									0.021,
									0.029,
									0.152
								],
								"e": 0.03
							},
							{
								"h": [
									0.019,
									0.037,
									0.028,
									0.053,
									0.001,
									0.009,
									0.125,
									0.005,
									0.048,
									0.063,
									0.017,
									0.007
								],
								"e": 0.026
							},
							{
								"h": [
									0.026,
									0.009,
									0.098,
									0.058,
									0.01,
									0.013,
									0.18,
									0.111,
									0.086,
									0.124,
									0.002,
									0.041
								],
								"e": 0.061
							},
							{
								"h": [
									1.0,
									1.0,
									0.719,
									0.23,
									0.121,
									0.004,
									0.391,
									0.66,
									0.031,
									0.052,
									0.705,
									0.856
								],
								"e": 0.534
							},
							{
								"h": [
									0.464,
									0.105,
									0.656,
									0.68,
									0.229,
									0.269,
									0.321,
									0.635,
									0.106,
									0.649,
									1.0,
									1.0
								],
								"e": 0.557
							}
						],
						[
							{
								"h": [
									0.173,
									0.001,
									0.284,
									0.0,
									0.007,
									0.0,
									0.122,
									0.056,
									0.443,
									0.455,
									0.241,
									0.235
								],
								"e": 0.097
							},
							{
								"h": [
									0.013,
									0.051,
									0.339,
									0.052,
									0.149,
									0.064,
									0.075,
									0.158,
									0.24,
									0.248,
									0.388,
									0.001
								],
								"e": 0.175
							},
							{
								"h": [
									0.033,
									0.031,
									0.0,
									0.012,
									0.002,
									0.013,
									0.054,
									0.247,
									0.007,
									0.016,
									0.185,
									0.027
								],
								"e": 0.046
							},
							{
								"h": [
									0.0,
									0.017,
									0.005,
									0.001,
									0.002,
									0.032,
									0.004,
									0.01,
									0.004,
									0.002,
									0.008,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.001,
									0.028,
									0.001,
									0.004,
									0.014,
									0.017,
									0.0,
									0.015,
									0.006,
									0.013,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.013,
									0.002,
									0.0,
									0.0,
									0.0,
									0.005,
									0.019,
									0.043,
									0.005
								],
								"e": 0.005
							},
							{
								"h": [
									0.003,
									0.038,
									0.018,
									0.305,
									0.016,
									0.006,
									0.011,
									0.524,
									0.002,
									0.001,
									0.036,
									0.001
								],
								"e": 0.045
							},
							{
								"h": [
									0.001,
									0.131,
									0.0,
									0.01,
									0.006,
									0.028,
									0.041,
									0.488,
									0.019,
									0.005,
									0.191,
									0.011
								],
								"e": 0.046
							},
							{
								"h": [
									0.09,
									0.765,
									1.0,
									0.559,
									0.01,
									0.01,
									0.457,
									0.018,
									0.083,
									0.034,
									0.05,
									0.032
								],
								"e": 0.183
							},
							{
								"h": [
									0.042,
									0.069,
									0.013,
									0.014,
									0.165,
									0.05,
									0.009,
									0.486,
									0.022,
									0.023,
									0.002,
									0.084
								],
								"e": 0.066
							},
							{
								"h": [
									0.011,
									0.014,
									0.113,
									0.258,
									0.697,
									0.004,
									0.026,
									0.24,
									0.97,
									0.439,
									0.02,
									0.382
								],
								"e": 0.207
							},
							{
								"h": [
									0.183,
									0.209,
									0.011,
									0.048,
									0.169,
									0.086,
									0.011,
									0.776,
									1.0,
									0.008,
									0.051,
									0.01
								],
								"e": 0.154
							}
						],
						[
							{
								"h": [
									0.613,
									0.002,
									0.253,
									0.001,
									0.092,
									0.0,
									0.358,
									0.034,
									0.445,
									0.361,
									0.172,
									0.25
								],
								"e": 0.135
							},
							{
								"h": [
									0.011,
									0.039,
									0.332,
									0.063,
									0.169,
									0.127,
									0.27,
									0.195,
									0.187,
									0.11,
									0.563,
									0.001
								],
								"e": 0.202
							},
							{
								"h": [
									0.025,
									0.023,
									0.0,
									0.034,
									0.002,
									0.025,
									0.055,
									0.314,
									0.175,
									0.034,
									0.353,
									0.052
								],
								"e": 0.08
							},
							{
								"h": [
									0.001,
									0.01,
									0.004,
									0.0,
									0.006,
									0.184,
									0.001,
									0.011,
									0.003,
									0.021,
									0.006,
									0.003
								],
								"e": 0.024
							},
							{
								"h": [
									0.002,
									0.0,
									0.019,
									0.001,
									0.006,
									0.002,
									0.026,
									0.0,
									0.047,
									0.024,
									0.011,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.0,
									0.008,
									0.024,
									0.004,
									0.001,
									0.0,
									0.0,
									0.005,
									0.072,
									0.66,
									0.004
								],
								"e": 0.036
							},
							{
								"h": [
									0.007,
									0.182,
									0.012,
									0.145,
									0.021,
									0.017,
									0.02,
									0.324,
									0.003,
									0.003,
									0.021,
									0.001
								],
								"e": 0.037
							},
							{
								"h": [
									0.003,
									0.161,
									0.001,
									0.035,
									0.009,
									0.175,
									0.224,
									0.469,
									0.045,
									0.007,
									0.18,
									0.006
								],
								"e": 0.079
							},
							{
								"h": [
									0.029,
									0.167,
									0.173,
									0.56,
									0.031,
									0.012,
									1.0,
									0.024,
									0.289,
									0.062,
									0.074,
									0.046
								],
								"e": 0.138
							},
							{
								"h": [
									0.249,
									0.025,
									0.121,
									0.005,
									0.513,
									0.014,
									0.01,
									0.075,
									0.003,
									0.062,
									0.003,
									0.016
								],
								"e": 0.075
							},
							{
								"h": [
									0.016,
									0.042,
									0.122,
									0.201,
									0.205,
									0.007,
									0.051,
									0.302,
									0.106,
									0.601,
									0.024,
									0.116
								],
								"e": 0.126
							},
							{
								"h": [
									0.391,
									0.051,
									0.03,
									0.085,
									0.046,
									0.287,
									0.015,
									0.438,
									0.571,
									0.173,
									0.512,
									0.125
								],
								"e": 0.181
							}
						],
						[
							{
								"h": [
									0.19,
									0.0,
									0.356,
									0.001,
									0.043,
									0.0,
									0.153,
									0.121,
									1.0,
									0.457,
									0.218,
									0.162
								],
								"e": 0.127
							},
							{
								"h": [
									0.044,
									0.08,
									0.492,
									0.061,
									0.162,
									0.264,
									0.086,
									0.145,
									0.311,
									0.365,
									0.41,
									0.001
								],
								"e": 0.251
							},
							{
								"h": [
									0.043,
									0.017,
									0.001,
									0.051,
									0.009,
									0.018,
									0.084,
									0.217,
									0.047,
									0.008,
									0.158,
									0.029
								],
								"e": 0.056
							},
							{
								"h": [
									0.002,
									0.019,
									0.004,
									0.0,
									0.007,
									0.053,
									0.002,
									0.002,
									0.0,
									0.001,
									0.005,
									0.005
								],
								"e": 0.011
							},
							{
								"h": [
									0.002,
									0.0,
									0.006,
									0.0,
									0.006,
									0.003,
									0.019,
									0.0,
									0.015,
									0.006,
									0.006,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.004,
									0.0,
									0.006,
									0.006,
									0.001,
									0.001,
									0.0,
									0.0,
									0.008,
									0.037,
									0.047,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.317,
									0.02,
									0.041,
									0.008,
									0.016,
									0.006,
									0.33,
									0.006,
									0.001,
									0.004,
									0.0
								],
								"e": 0.033
							},
							{
								"h": [
									0.001,
									0.042,
									0.001,
									0.006,
									0.008,
									0.014,
									0.039,
									0.576,
									0.003,
									0.005,
									0.072,
									0.007
								],
								"e": 0.031
							},
							{
								"h": [
									0.007,
									0.098,
									0.088,
									0.061,
									0.002,
									0.002,
									0.111,
									0.006,
									0.144,
									0.144,
									0.017,
									0.005
								],
								"e": 0.043
							},
							{
								"h": [
									0.004,
									0.009,
									0.028,
									0.002,
									0.012,
									0.004,
									0.004,
									0.063,
									0.006,
									0.006,
									0.0,
									0.005
								],
								"e": 0.01
							},
							{
								"h": [
									0.005,
									0.007,
									0.044,
									0.092,
									0.047,
									0.002,
									0.004,
									0.155,
									0.435,
									0.04,
									0.04,
									0.03
								],
								"e": 0.061
							},
							{
								"h": [
									0.471,
									0.036,
									0.015,
									0.085,
									0.152,
									0.016,
									0.015,
									0.22,
									0.262,
									0.006,
									0.127,
									0.058
								],
								"e": 0.09
							}
						],
						[
							{
								"h": [
									0.148,
									0.002,
									0.338,
									0.001,
									0.026,
									0.0,
									0.619,
									0.081,
									0.23,
									0.43,
									0.154,
									0.195
								],
								"e": 0.109
							},
							{
								"h": [
									0.008,
									0.052,
									0.326,
									0.067,
									0.187,
									0.057,
									0.059,
									0.289,
									0.131,
									0.088,
									0.562,
									0.004
								],
								"e": 0.169
							},
							{
								"h": [
									0.057,
									0.016,
									0.0,
									0.01,
									0.0,
									0.027,
									0.03,
									0.312,
									0.006,
									0.084,
									0.317,
									0.043
								],
								"e": 0.063
							},
							{
								"h": [
									0.001,
									0.008,
									0.001,
									0.0,
									0.003,
									0.072,
									0.001,
									0.003,
									0.004,
									0.001,
									0.009,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.001,
									0.003,
									0.001,
									0.004,
									0.0,
									0.016,
									0.001,
									0.069,
									0.006,
									0.003,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.0,
									0.004,
									0.011,
									0.002,
									0.002,
									0.0,
									0.003,
									0.002,
									0.019,
									0.058,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.001,
									0.065,
									0.013,
									0.041,
									0.004,
									0.014,
									0.005,
									0.171,
									0.005,
									0.004,
									0.002,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.003,
									0.002,
									0.0,
									0.023,
									0.001,
									0.002,
									0.014,
									0.254,
									0.004,
									0.004,
									0.014,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.011,
									0.005,
									0.028,
									0.026,
									0.003,
									0.016,
									0.057,
									0.005,
									0.293,
									0.259,
									0.006,
									0.002
								],
								"e": 0.048
							},
							{
								"h": [
									0.001,
									0.001,
									0.047,
									0.007,
									0.006,
									0.001,
									0.016,
									0.016,
									0.006,
									0.015,
									0.002,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.021,
									0.027,
									0.054,
									0.213,
									0.013,
									0.002,
									0.148,
									0.031,
									0.017,
									0.019,
									0.059,
									0.094
								],
								"e": 0.062
							},
							{
								"h": [
									0.107,
									0.049,
									0.011,
									0.162,
									0.025,
									0.029,
									0.013,
									0.713,
									0.428,
									0.011,
									0.003,
									0.027
								],
								"e": 0.086
							}
						],
						[
							{
								"h": [
									0.167,
									0.002,
									0.55,
									0.003,
									0.089,
									0.0,
									0.115,
									0.177,
									0.806,
									0.537,
									0.29,
									0.139
								],
								"e": 0.142
							},
							{
								"h": [
									0.056,
									0.155,
									0.613,
									0.067,
									0.171,
									0.209,
									0.076,
									0.154,
									0.218,
									0.099,
									0.427,
									0.023
								],
								"e": 0.233
							},
							{
								"h": [
									0.251,
									0.047,
									0.008,
									0.018,
									0.008,
									0.056,
									0.086,
									0.197,
									0.079,
									0.014,
									0.155,
									0.03
								],
								"e": 0.086
							},
							{
								"h": [
									0.002,
									0.004,
									0.008,
									0.001,
									0.003,
									0.053,
									0.001,
									0.004,
									0.022,
									0.002,
									0.005,
									0.003
								],
								"e": 0.011
							},
							{
								"h": [
									0.003,
									0.004,
									0.002,
									0.002,
									0.002,
									0.002,
									0.013,
									0.0,
									0.02,
									0.007,
									0.003,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.002,
									0.0,
									0.007,
									0.003,
									0.004,
									0.0,
									0.0,
									0.003,
									0.001,
									0.015,
									0.04,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.031,
									0.004,
									0.007,
									0.001,
									0.008,
									0.0,
									0.017,
									0.006,
									0.0,
									0.003,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.002,
									0.0,
									0.013,
									0.002,
									0.003,
									0.015,
									0.025,
									0.001,
									0.002,
									0.009,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.006,
									0.016,
									0.027,
									0.013,
									0.002,
									0.003,
									0.023,
									0.002,
									0.041,
									0.062,
									0.005,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.001,
									0.007,
									0.0,
									0.0,
									0.002,
									0.0,
									0.011,
									0.005,
									0.0,
									0.0,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.003,
									0.007,
									0.006,
									0.004,
									0.018,
									0.002,
									0.001,
									0.026,
									0.05,
									0.006,
									0.003,
									0.005
								],
								"e": 0.009
							},
							{
								"h": [
									0.341,
									0.005,
									0.003,
									0.104,
									0.057,
									0.004,
									0.004,
									0.073,
									0.09,
									0.002,
									0.051,
									0.095
								],
								"e": 0.051
							}
						],
						[
							{
								"h": [
									0.09,
									0.001,
									0.394,
									0.004,
									0.058,
									0.0,
									0.474,
									0.084,
									0.422,
									0.439,
									0.173,
									0.241
								],
								"e": 0.117
							},
							{
								"h": [
									0.018,
									0.096,
									0.493,
									0.082,
									0.258,
									0.136,
									0.073,
									0.238,
									0.196,
									0.099,
									0.796,
									0.006
								],
								"e": 0.228
							},
							{
								"h": [
									0.047,
									0.095,
									0.0,
									0.01,
									0.014,
									0.041,
									0.025,
									0.429,
									0.018,
									0.098,
									0.401,
									0.058
								],
								"e": 0.094
							},
							{
								"h": [
									0.0,
									0.014,
									0.002,
									0.0,
									0.004,
									0.047,
									0.001,
									0.001,
									0.002,
									0.001,
									0.008,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.001,
									0.002,
									0.0,
									0.012,
									0.004,
									0.025,
									0.0,
									0.009,
									0.019,
									0.001,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.0,
									0.002,
									0.018,
									0.001,
									0.001,
									0.0,
									0.0,
									0.001,
									0.032,
									0.037,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.01,
									0.039,
									0.004,
									0.002,
									0.028,
									0.001,
									0.126,
									0.002,
									0.0,
									0.002,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.002,
									0.0,
									0.023,
									0.001,
									0.001,
									0.014,
									0.127,
									0.006,
									0.003,
									0.005,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.039,
									0.016,
									0.078,
									0.059,
									0.001,
									0.017,
									0.369,
									0.002,
									0.087,
									0.227,
									0.007,
									0.003
								],
								"e": 0.054
							},
							{
								"h": [
									0.009,
									0.007,
									0.027,
									0.004,
									0.007,
									0.002,
									0.051,
									0.081,
									0.019,
									0.039,
									0.001,
									0.005
								],
								"e": 0.019
							},
							{
								"h": [
									0.098,
									0.152,
									0.116,
									0.028,
									0.237,
									0.002,
									0.058,
									0.064,
									0.005,
									0.021,
									0.038,
									1.0
								],
								"e": 0.136
							},
							{
								"h": [
									0.291,
									0.046,
									0.02,
									0.188,
									0.025,
									0.082,
									0.013,
									0.239,
									0.03,
									0.074,
									0.032,
									0.071
								],
								"e": 0.078
							}
						],
						[
							{
								"h": [
									0.082,
									0.0,
									0.465,
									0.007,
									0.076,
									0.0,
									0.143,
									1.0,
									0.782,
									0.628,
									0.279,
									0.42
								],
								"e": 0.234
							},
							{
								"h": [
									0.132,
									1.0,
									1.0,
									0.145,
									0.311,
									0.338,
									0.06,
									0.248,
									0.596,
									0.148,
									0.925,
									0.04
								],
								"e": 0.548
							},
							{
								"h": [
									0.204,
									0.041,
									0.094,
									0.256,
									0.141,
									1.0,
									1.0,
									0.496,
									1.0,
									0.657,
									0.527,
									0.106
								],
								"e": 0.577
							},
							{
								"h": [
									0.011,
									0.528,
									0.27,
									0.114,
									0.027,
									0.057,
									0.069,
									0.791,
									1.0,
									0.158,
									0.123,
									0.073
								],
								"e": 0.283
							},
							{
								"h": [
									0.074,
									0.615,
									0.063,
									0.023,
									0.02,
									0.129,
									0.086,
									0.007,
									0.053,
									0.053,
									0.028,
									0.0
								],
								"e": 0.095
							},
							{
								"h": [
									0.006,
									0.0,
									0.028,
									0.488,
									0.222,
									0.008,
									0.0,
									0.005,
									0.017,
									0.037,
									0.318,
									0.03
								],
								"e": 0.073
							},
							{
								"h": [
									0.056,
									0.369,
									0.067,
									0.289,
									0.06,
									0.081,
									0.026,
									0.497,
									0.02,
									0.015,
									0.017,
									0.05
								],
								"e": 0.085
							},
							{
								"h": [
									0.111,
									0.024,
									0.0,
									0.045,
									0.014,
									0.048,
									0.042,
									0.57,
									0.091,
									0.059,
									0.037,
									0.006
								],
								"e": 0.059
							},
							{
								"h": [
									0.204,
									0.021,
									0.284,
									0.33,
									0.069,
									0.045,
									0.298,
									0.163,
									0.383,
									0.07,
									0.045,
									0.011
								],
								"e": 0.137
							},
							{
								"h": [
									0.024,
									0.01,
									0.024,
									0.223,
									0.048,
									0.002,
									0.004,
									0.035,
									0.011,
									0.004,
									0.007,
									0.008
								],
								"e": 0.031
							},
							{
								"h": [
									0.014,
									0.008,
									0.073,
									0.327,
									0.423,
									0.05,
									0.02,
									0.232,
									0.258,
									0.695,
									0.004,
									0.061
								],
								"e": 0.151
							},
							{
								"h": [
									0.304,
									0.049,
									0.058,
									0.702,
									0.195,
									0.041,
									0.022,
									0.229,
									0.813,
									0.036,
									0.958,
									0.587
								],
								"e": 0.275
							}
						],
						[
							{
								"h": [
									0.234,
									0.0,
									0.427,
									0.017,
									0.431,
									0.0,
									0.514,
									0.644,
									0.392,
									0.585,
									0.292,
									0.238
								],
								"e": 0.238
							},
							{
								"h": [
									1.0,
									0.586,
									0.756,
									0.112,
									0.321,
									0.081,
									0.199,
									0.277,
									0.334,
									0.205,
									0.99,
									0.002
								],
								"e": 0.669
							},
							{
								"h": [
									1.0,
									0.02,
									1.0,
									1.0,
									1.0,
									0.285,
									0.454,
									0.673,
									0.736,
									1.0,
									0.629,
									0.089
								],
								"e": 1.0
							},
							{
								"h": [
									0.001,
									0.312,
									1.0,
									1.0,
									0.002,
									0.087,
									1.0,
									0.517,
									0.152,
									1.0,
									0.059,
									1.0
								],
								"e": 0.69
							},
							{
								"h": [
									0.32,
									0.119,
									0.023,
									0.028,
									0.088,
									1.0,
									0.11,
									0.242,
									0.017,
									0.079,
									0.008,
									1.0
								],
								"e": 0.295
							},
							{
								"h": [
									0.002,
									0.0,
									0.14,
									0.238,
									0.032,
									0.016,
									0.059,
									0.008,
									0.008,
									0.025,
									0.067,
									0.093
								],
								"e": 0.053
							},
							{
								"h": [
									0.017,
									0.088,
									0.034,
									0.124,
									0.017,
									0.149,
									0.014,
									0.067,
									0.219,
									0.002,
									0.003,
									0.222
								],
								"e": 0.073
							},
							{
								"h": [
									0.08,
									0.004,
									0.003,
									0.022,
									0.057,
									0.02,
									0.055,
									0.01,
									0.01,
									0.191,
									0.03,
									0.019
								],
								"e": 0.044
							},
							{
								"h": [
									0.025,
									0.057,
									0.034,
									0.021,
									0.072,
									0.219,
									0.096,
									0.112,
									0.02,
									0.019,
									0.034,
									0.014
								],
								"e": 0.068
							},
							{
								"h": [
									0.007,
									0.048,
									0.006,
									0.084,
									0.018,
									0.03,
									0.002,
									0.019,
									0.01,
									0.007,
									0.042,
									0.002
								],
								"e": 0.023
							},
							{
								"h": [
									0.005,
									0.06,
									0.039,
									0.055,
									0.319,
									0.042,
									0.013,
									0.091,
									0.279,
									0.103,
									0.015,
									0.012
								],
								"e": 0.074
							},
							{
								"h": [
									0.356,
									0.085,
									0.006,
									0.219,
									0.121,
									0.039,
									0.011,
									0.29,
									0.699,
									0.012,
									0.12,
									0.035
								],
								"e": 0.118
							}
						],
						[
							{
								"h": [
									0.321,
									1.0,
									0.141,
									1.0,
									1.0,
									1.0,
									0.45,
									0.307,
									0.199,
									0.424,
									1.0,
									0.298
								],
								"e": 1.0
							},
							{
								"h": [
									0.11,
									0.272,
									0.747,
									0.113,
									0.393,
									0.186,
									0.218,
									0.221,
									0.247,
									0.109,
									1.0,
									1.0
								],
								"e": 0.71
							},
							{
								"h": [
									0.213,
									0.03,
									0.006,
									0.045,
									0.021,
									0.155,
									0.128,
									1.0,
									0.352,
									0.264,
									1.0,
									0.117
								],
								"e": 0.247
							},
							{
								"h": [
									0.047,
									0.062,
									0.243,
									0.023,
									0.002,
									0.075,
									0.147,
									0.801,
									0.341,
									0.609,
									0.093,
									0.052
								],
								"e": 0.21
							},
							{
								"h": [
									0.043,
									0.145,
									0.027,
									0.062,
									0.449,
									0.404,
									0.043,
									1.0,
									0.16,
									0.062,
									0.008,
									0.0
								],
								"e": 0.189
							},
							{
								"h": [
									0.006,
									0.0,
									0.031,
									0.114,
									0.044,
									0.006,
									0.005,
									0.016,
									0.005,
									0.006,
									0.515,
									0.083
								],
								"e": 0.046
							},
							{
								"h": [
									0.024,
									0.022,
									0.018,
									0.113,
									0.042,
									0.121,
									0.073,
									0.016,
									0.044,
									0.003,
									0.009,
									0.149
								],
								"e": 0.052
							},
							{
								"h": [
									0.061,
									0.049,
									0.002,
									0.042,
									0.043,
									0.025,
									0.082,
									0.007,
									0.014,
									0.027,
									0.069,
									0.006
								],
								"e": 0.035
							},
							{
								"h": [
									0.123,
									0.014,
									0.025,
									0.021,
									0.026,
									0.047,
									0.113,
									0.043,
									0.006,
									0.006,
									0.049,
									0.01
								],
								"e": 0.042
							},
							{
								"h": [
									0.024,
									0.014,
									0.013,
									0.052,
									0.019,
									0.028,
									0.005,
									0.004,
									0.004,
									0.013,
									0.008,
									0.012
								],
								"e": 0.016
							},
							{
								"h": [
									0.006,
									0.041,
									0.027,
									0.093,
									0.085,
									0.026,
									0.009,
									0.051,
									0.087,
									0.163,
									0.019,
									0.027
								],
								"e": 0.049
							},
							{
								"h": [
									0.513,
									0.015,
									0.008,
									0.092,
									0.037,
									0.103,
									0.006,
									0.358,
									0.766,
									0.047,
									0.105,
									0.043
								],
								"e": 0.114
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " when",
					"o": " people",
					"t": " people",
					"r": [
						{
							"p": " they",
							"b": 0.004,
							"k": {
								" they": 0.136,
								" the": 0.126,
								" it": 0.068,
								" we": 0.063,
								" when": 0.055,
								" you": 0.042,
								" I": 0.038,
								" she": 0.026,
								" times": 0.025,
								" he": 0.024
							}
						},
						{
							"p": " the",
							"b": 0.007,
							"k": {
								" the": 0.12,
								" you": 0.11,
								" they": 0.107,
								" we": 0.069,
								" it": 0.041,
								" faced": 0.039,
								" asked": 0.031,
								" when": 0.029,
								" times": 0.02,
								" compared": 0.018
							}
						},
						{
							"p": " they",
							"b": 0.006,
							"k": {
								" they": 0.285,
								" we": 0.101,
								" you": 0.07,
								" the": 0.042,
								" faced": 0.041,
								" asked": 0.035,
								" it": 0.026,
								" times": 0.023,
								" when": 0.022,
								"soever": 0.019
							}
						},
						{
							"p": " they",
							"b": 0.022,
							"k": {
								" they": 0.489,
								" we": 0.103,
								" you": 0.071,
								" the": 0.042,
								" it": 0.039,
								" asked": 0.026,
								" people": 0.022,
								" viewed": 0.015,
								"abouts": 0.015,
								" faced": 0.013
							}
						},
						{
							"p": " people",
							"b": 0.151,
							"k": {
								" people": 0.151,
								" they": 0.112,
								" the": 0.071,
								" we": 0.057,
								" you": 0.037,
								" it": 0.033,
								" things": 0.025,
								" faced": 0.022,
								" asked": 0.013,
								" children": 0.011
							}
						},
						{
							"p": " people",
							"b": 0.315,
							"k": {
								" people": 0.315,
								" they": 0.171,
								" you": 0.093,
								" we": 0.092,
								" the": 0.065,
								" it": 0.026,
								" someone": 0.019,
								" things": 0.017,
								" he": 0.015,
								" asked": 0.015
							}
						},
						{
							"p": " they",
							"b": 0.191,
							"k": {
								" they": 0.218,
								" people": 0.191,
								" we": 0.188,
								" you": 0.13,
								" it": 0.108,
								" the": 0.04,
								" things": 0.034,
								" someone": 0.024,
								" I": 0.005,
								" he": 0.005
							}
						},
						{
							"p": " people",
							"b": 0.531,
							"k": {
								" people": 0.531,
								" we": 0.16,
								" you": 0.105,
								" they": 0.074,
								" it": 0.047,
								" someone": 0.034,
								" things": 0.021,
								" the": 0.004,
								" folks": 0.003,
								" I": 0.002
							}
						},
						{
							"p": " people",
							"b": 0.854,
							"k": {
								" people": 0.854,
								" they": 0.09,
								" it": 0.019,
								" we": 0.014,
								" you": 0.008,
								" someone": 0.008,
								" things": 0.002,
								" there": 0.001,
								" talking": 0.001,
								"!": 0.0
							}
						},
						{
							"p": " people",
							"b": 0.984,
							"k": {
								" people": 0.984,
								" they": 0.011,
								" someone": 0.004,
								" you": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " people",
							"b": 0.603,
							"k": {
								" people": 0.603,
								" they": 0.347,
								" we": 0.019,
								" you": 0.018,
								" someone": 0.009,
								" it": 0.002,
								" I": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0
							}
						},
						{
							"p": " people",
							"b": 0.351,
							"k": {
								" people": 0.351,
								" they": 0.326,
								" you": 0.09,
								" we": 0.037,
								" I": 0.03,
								" it": 0.028,
								" someone": 0.019,
								" the": 0.018,
								" a": 0.015,
								" there": 0.004
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.003,
									1.0,
									0.004,
									0.112,
									0.083,
									1.0,
									0.047,
									0.081,
									1.0,
									0.64,
									1.0
								],
								"e": 0.367
							},
							{
								"h": [
									0.109,
									0.164,
									0.355,
									1.0,
									1.0,
									0.651,
									0.403,
									1.0,
									1.0,
									1.0,
									0.017,
									0.063
								],
								"e": 1.0
							},
							{
								"h": [
									0.852,
									1.0,
									0.179,
									0.129,
									0.102,
									0.248,
									0.376,
									0.477,
									0.669,
									0.151,
									0.774,
									1.0
								],
								"e": 0.901
							},
							{
								"h": [
									1.0,
									1.0,
									0.162,
									1.0,
									1.0,
									1.0,
									0.134,
									0.33,
									0.55,
									0.604,
									1.0,
									0.381
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.688,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.498,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.114,
									1.0,
									1.0,
									1.0,
									1.0,
									0.38,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.447,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									0.923,
									1.0,
									1.0,
									0.54,
									1.0,
									1.0,
									1.0,
									0.966
								],
								"e": 1.0
							},
							{
								"h": [
									0.931,
									1.0,
									1.0,
									0.636,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									1.0,
									0.386
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.181,
									0.593,
									0.452,
									0.002,
									0.022,
									1.0,
									0.147,
									0.006,
									0.119,
									0.589,
									0.914,
									0.397
								],
								"e": 0.509
							},
							{
								"h": [
									0.006,
									0.019,
									0.128,
									0.03,
									0.169,
									0.421,
									0.04,
									0.088,
									0.355,
									0.22,
									0.23,
									0.512
								],
								"e": 0.345
							},
							{
								"h": [
									0.067,
									0.01,
									0.0,
									0.001,
									0.001,
									0.001,
									0.014,
									0.052,
									0.006,
									0.0,
									0.154,
									0.012
								],
								"e": 0.026
							},
							{
								"h": [
									0.129,
									0.006,
									0.0,
									0.0,
									0.012,
									0.043,
									0.001,
									0.0,
									0.001,
									0.014,
									0.027,
									0.001
								],
								"e": 0.036
							},
							{
								"h": [
									0.001,
									0.001,
									0.02,
									0.001,
									0.12,
									0.005,
									0.005,
									0.127,
									0.025,
									0.003,
									0.015,
									0.0
								],
								"e": 0.026
							},
							{
								"h": [
									0.027,
									0.001,
									0.009,
									0.104,
									0.006,
									0.046,
									0.003,
									0.003,
									0.03,
									0.032,
									0.039,
									0.13
								],
								"e": 0.03
							},
							{
								"h": [
									0.017,
									0.002,
									0.028,
									0.012,
									0.01,
									0.017,
									0.037,
									0.024,
									0.013,
									0.063,
									0.006,
									0.016
								],
								"e": 0.021
							},
							{
								"h": [
									0.003,
									0.034,
									0.035,
									0.015,
									0.005,
									0.032,
									0.042,
									0.076,
									0.004,
									0.007,
									0.153,
									0.034
								],
								"e": 0.032
							},
							{
								"h": [
									0.02,
									0.069,
									0.032,
									0.029,
									0.001,
									0.009,
									0.064,
									0.001,
									0.06,
									0.014,
									0.031,
									0.009
								],
								"e": 0.027
							},
							{
								"h": [
									0.005,
									0.027,
									0.019,
									0.009,
									0.012,
									0.015,
									0.003,
									0.03,
									0.019,
									0.026,
									0.001,
									0.006
								],
								"e": 0.014
							},
							{
								"h": [
									0.012,
									0.016,
									0.01,
									0.058,
									0.067,
									0.001,
									0.013,
									0.05,
									0.067,
									0.033,
									0.005,
									0.051
								],
								"e": 0.027
							},
							{
								"h": [
									1.0,
									0.038,
									0.016,
									0.149,
									0.059,
									0.046,
									0.013,
									0.117,
									0.327,
									0.022,
									0.179,
									0.075
								],
								"e": 0.101
							}
						],
						[
							{
								"h": [
									0.493,
									0.001,
									0.382,
									0.0,
									0.009,
									0.0,
									0.342,
									0.005,
									0.083,
									0.531,
									0.256,
									0.243
								],
								"e": 0.133
							},
							{
								"h": [
									0.019,
									0.03,
									0.125,
									0.032,
									0.158,
									0.026,
									0.046,
									0.248,
									0.173,
									0.13,
									0.252,
									0.001
								],
								"e": 0.14
							},
							{
								"h": [
									0.082,
									0.035,
									0.0,
									0.008,
									0.001,
									0.006,
									0.028,
									0.13,
									0.004,
									0.012,
									0.243,
									0.021
								],
								"e": 0.053
							},
							{
								"h": [
									0.002,
									0.012,
									0.007,
									0.0,
									0.006,
									0.042,
									0.023,
									0.001,
									0.001,
									0.011,
									0.013,
									0.003
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.001,
									0.015,
									0.013,
									0.018,
									0.025,
									0.013,
									0.12,
									0.019,
									0.005,
									0.012,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.224,
									0.424,
									0.013,
									0.071,
									0.01,
									0.509,
									0.013,
									0.002,
									0.45,
									0.125,
									0.032,
									0.038
								],
								"e": 0.155
							},
							{
								"h": [
									0.021,
									0.01,
									0.018,
									0.052,
									0.038,
									0.023,
									0.036,
									0.032,
									0.008,
									0.209,
									0.024,
									0.017
								],
								"e": 0.044
							},
							{
								"h": [
									0.002,
									0.067,
									0.247,
									0.04,
									0.004,
									0.016,
									0.094,
									0.416,
									0.003,
									0.035,
									1.0,
									0.081
								],
								"e": 0.139
							},
							{
								"h": [
									0.028,
									1.0,
									0.109,
									0.144,
									0.001,
									0.023,
									0.466,
									0.001,
									0.503,
									0.19,
									0.143,
									0.039
								],
								"e": 0.219
							},
							{
								"h": [
									0.062,
									1.0,
									0.071,
									0.003,
									0.113,
									0.03,
									0.122,
									0.08,
									0.023,
									0.351,
									0.001,
									0.012
								],
								"e": 0.14
							},
							{
								"h": [
									0.173,
									0.198,
									0.09,
									0.199,
									1.0,
									0.001,
									0.275,
									1.0,
									0.295,
									0.051,
									0.04,
									1.0
								],
								"e": 0.272
							},
							{
								"h": [
									0.576,
									0.463,
									0.021,
									0.091,
									0.052,
									0.089,
									0.01,
									0.432,
									0.402,
									0.033,
									0.059,
									0.02
								],
								"e": 0.145
							}
						],
						[
							{
								"h": [
									0.341,
									0.0,
									0.543,
									0.001,
									0.029,
									0.001,
									0.321,
									0.011,
									0.069,
									0.474,
									0.271,
									0.444
								],
								"e": 0.146
							},
							{
								"h": [
									0.021,
									0.042,
									0.203,
									0.045,
									0.187,
									0.079,
									0.045,
									0.195,
									0.194,
									0.173,
									0.363,
									0.002
								],
								"e": 0.172
							},
							{
								"h": [
									0.044,
									0.035,
									0.0,
									0.006,
									0.005,
									0.007,
									0.026,
									0.215,
									0.039,
									0.004,
									0.285,
									0.025
								],
								"e": 0.062
							},
							{
								"h": [
									0.009,
									0.013,
									0.02,
									0.001,
									0.008,
									0.119,
									0.001,
									0.008,
									0.006,
									0.05,
									0.009,
									0.004
								],
								"e": 0.028
							},
							{
								"h": [
									0.008,
									0.002,
									0.013,
									0.01,
									0.011,
									0.011,
									0.011,
									0.05,
									0.033,
									0.018,
									0.004,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.062,
									0.002,
									0.006,
									0.091,
									0.008,
									0.012,
									0.005,
									0.01,
									0.016,
									0.037,
									0.041,
									0.021
								],
								"e": 0.022
							},
							{
								"h": [
									0.053,
									0.013,
									0.008,
									0.064,
									0.029,
									0.058,
									0.018,
									0.13,
									0.013,
									0.015,
									0.039,
									0.027
								],
								"e": 0.037
							},
							{
								"h": [
									0.004,
									0.207,
									0.012,
									0.05,
									0.011,
									0.025,
									0.116,
									0.987,
									0.014,
									0.016,
									0.129,
									0.007
								],
								"e": 0.078
							},
							{
								"h": [
									0.019,
									0.111,
									0.074,
									0.228,
									0.002,
									0.011,
									1.0,
									0.003,
									0.492,
									0.138,
									0.076,
									0.022
								],
								"e": 0.146
							},
							{
								"h": [
									0.119,
									0.082,
									0.044,
									0.008,
									0.255,
									0.01,
									0.015,
									0.065,
									0.006,
									0.094,
									0.001,
									0.005
								],
								"e": 0.055
							},
							{
								"h": [
									0.02,
									0.015,
									0.024,
									0.067,
									0.204,
									0.001,
									0.084,
									0.119,
									0.029,
									0.057,
									0.006,
									0.122
								],
								"e": 0.049
							},
							{
								"h": [
									0.805,
									0.065,
									0.011,
									0.15,
									0.037,
									0.072,
									0.006,
									0.129,
									0.354,
									0.041,
									0.078,
									0.077
								],
								"e": 0.094
							}
						],
						[
							{
								"h": [
									0.222,
									0.0,
									0.095,
									0.0,
									0.016,
									0.0,
									0.828,
									0.014,
									0.065,
									0.249,
									0.102,
									0.18
								],
								"e": 0.101
							},
							{
								"h": [
									0.01,
									0.042,
									0.206,
									0.046,
									0.231,
									0.016,
									0.091,
									0.254,
									0.145,
									0.057,
									0.377,
									0.001
								],
								"e": 0.164
							},
							{
								"h": [
									0.082,
									0.045,
									0.0,
									0.005,
									0.0,
									0.005,
									0.016,
									0.217,
									0.007,
									0.022,
									0.256,
									0.027
								],
								"e": 0.062
							},
							{
								"h": [
									0.0,
									0.003,
									0.016,
									0.0,
									0.003,
									0.082,
									0.039,
									0.006,
									0.01,
									0.007,
									0.015,
									0.008
								],
								"e": 0.021
							},
							{
								"h": [
									0.001,
									0.002,
									0.004,
									0.012,
									0.004,
									0.017,
									0.016,
									0.021,
									0.009,
									0.008,
									0.001,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.053,
									0.006,
									0.005,
									0.043,
									0.018,
									0.049,
									0.001,
									0.001,
									0.095,
									0.053,
									0.011,
									0.005
								],
								"e": 0.026
							},
							{
								"h": [
									0.006,
									0.007,
									0.022,
									0.017,
									0.014,
									0.033,
									0.008,
									0.077,
									0.02,
									0.01,
									0.026,
									0.011
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.059,
									0.035,
									0.027,
									0.003,
									0.008,
									0.043,
									0.174,
									0.005,
									0.018,
									0.041,
									0.06
								],
								"e": 0.032
							},
							{
								"h": [
									0.006,
									0.016,
									0.101,
									0.041,
									0.001,
									0.007,
									0.03,
									0.001,
									0.169,
									0.28,
									0.021,
									0.034
								],
								"e": 0.045
							},
							{
								"h": [
									0.022,
									0.006,
									0.17,
									0.027,
									0.004,
									0.012,
									0.186,
									0.062,
									0.161,
									0.141,
									0.001,
									0.093
								],
								"e": 0.076
							},
							{
								"h": [
									0.579,
									0.912,
									0.3,
									0.275,
									0.216,
									0.002,
									0.113,
									0.183,
									0.031,
									0.013,
									0.233,
									0.473
								],
								"e": 0.253
							},
							{
								"h": [
									0.662,
									0.053,
									0.276,
									1.0,
									0.279,
									0.123,
									0.257,
									0.294,
									0.103,
									0.261,
									0.401,
									1.0
								],
								"e": 0.369
							}
						],
						[
							{
								"h": [
									0.415,
									0.001,
									0.203,
									0.001,
									0.026,
									0.0,
									0.108,
									0.018,
									0.305,
									0.546,
									0.3,
									0.199
								],
								"e": 0.13
							},
							{
								"h": [
									0.027,
									0.06,
									0.251,
									0.042,
									0.166,
									0.044,
									0.04,
									0.146,
									0.221,
									0.34,
									0.299,
									0.001
								],
								"e": 0.185
							},
							{
								"h": [
									0.093,
									0.03,
									0.001,
									0.01,
									0.002,
									0.017,
									0.058,
									0.303,
									0.01,
									0.008,
									0.252,
									0.028
								],
								"e": 0.073
							},
							{
								"h": [
									0.002,
									0.039,
									0.01,
									0.002,
									0.014,
									0.021,
									0.011,
									0.005,
									0.009,
									0.006,
									0.022,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.002,
									0.001,
									0.009,
									0.006,
									0.007,
									0.017,
									0.01,
									0.005,
									0.016,
									0.002,
									0.025,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.003,
									0.0,
									0.006,
									0.036,
									0.011,
									0.001,
									0.001,
									0.001,
									0.008,
									0.05,
									0.059,
									0.005
								],
								"e": 0.012
							},
							{
								"h": [
									0.017,
									0.004,
									0.014,
									0.074,
									0.044,
									0.017,
									0.012,
									0.093,
									0.018,
									0.0,
									0.096,
									0.009
								],
								"e": 0.032
							},
							{
								"h": [
									0.001,
									0.465,
									0.0,
									0.024,
									0.002,
									0.017,
									0.037,
									0.368,
									0.007,
									0.027,
									0.01,
									0.001
								],
								"e": 0.047
							},
							{
								"h": [
									0.054,
									0.012,
									0.19,
									0.544,
									0.002,
									0.013,
									0.101,
									0.003,
									0.237,
									0.053,
									0.132,
									0.037
								],
								"e": 0.096
							},
							{
								"h": [
									0.083,
									0.024,
									0.02,
									0.015,
									0.094,
									0.039,
									0.006,
									0.11,
									0.02,
									0.107,
									0.001,
									0.004
								],
								"e": 0.042
							},
							{
								"h": [
									0.02,
									0.017,
									0.023,
									0.11,
									0.449,
									0.002,
									0.13,
									0.122,
									0.144,
									0.09,
									0.009,
									0.495
								],
								"e": 0.098
							},
							{
								"h": [
									0.232,
									0.19,
									0.008,
									0.076,
									0.094,
									0.03,
									0.008,
									0.348,
									0.457,
									0.007,
									0.038,
									0.027
								],
								"e": 0.093
							}
						],
						[
							{
								"h": [
									0.465,
									0.0,
									0.272,
									0.001,
									0.037,
									0.0,
									0.57,
									0.023,
									0.127,
									0.396,
									0.17,
									0.435
								],
								"e": 0.149
							},
							{
								"h": [
									0.037,
									0.075,
									0.255,
									0.05,
									0.195,
									0.052,
									0.034,
									0.208,
									0.151,
									0.119,
									0.429,
									0.001
								],
								"e": 0.175
							},
							{
								"h": [
									0.056,
									0.033,
									0.002,
									0.028,
									0.003,
									0.041,
									0.072,
									0.321,
									0.086,
									0.013,
									0.438,
									0.048
								],
								"e": 0.106
							},
							{
								"h": [
									0.003,
									0.035,
									0.01,
									0.004,
									0.016,
									0.045,
									0.002,
									0.005,
									0.018,
									0.021,
									0.01,
									0.012
								],
								"e": 0.019
							},
							{
								"h": [
									0.008,
									0.003,
									0.01,
									0.002,
									0.01,
									0.005,
									0.019,
									0.009,
									0.07,
									0.022,
									0.02,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.013,
									0.0,
									0.007,
									0.111,
									0.015,
									0.005,
									0.005,
									0.009,
									0.007,
									0.043,
									0.367,
									0.004
								],
								"e": 0.035
							},
							{
								"h": [
									0.063,
									0.002,
									0.015,
									0.045,
									0.03,
									0.05,
									0.006,
									0.123,
									0.048,
									0.001,
									0.119,
									0.033
								],
								"e": 0.04
							},
							{
								"h": [
									0.008,
									0.755,
									0.001,
									0.022,
									0.005,
									0.044,
									0.096,
									0.259,
									0.02,
									0.023,
									0.049,
									0.003
								],
								"e": 0.068
							},
							{
								"h": [
									0.015,
									0.008,
									0.1,
									0.189,
									0.004,
									0.015,
									0.069,
									0.004,
									0.27,
									0.077,
									0.078,
									0.035
								],
								"e": 0.057
							},
							{
								"h": [
									0.122,
									0.016,
									0.035,
									0.006,
									0.083,
									0.012,
									0.005,
									0.048,
									0.006,
									0.053,
									0.001,
									0.003
								],
								"e": 0.031
							},
							{
								"h": [
									0.014,
									0.006,
									0.018,
									0.026,
									0.058,
									0.002,
									0.021,
									0.05,
									0.037,
									0.048,
									0.004,
									0.029
								],
								"e": 0.023
							},
							{
								"h": [
									0.391,
									0.027,
									0.008,
									0.057,
									0.036,
									0.037,
									0.006,
									0.094,
									0.456,
									0.044,
									0.103,
									0.079
								],
								"e": 0.073
							}
						],
						[
							{
								"h": [
									0.231,
									0.0,
									0.183,
									0.001,
									0.032,
									0.0,
									0.093,
									0.039,
									0.935,
									0.514,
									0.256,
									0.199
								],
								"e": 0.17
							},
							{
								"h": [
									0.048,
									0.087,
									0.334,
									0.047,
									0.158,
									0.111,
									0.039,
									0.117,
									0.314,
									0.456,
									0.301,
									0.002
								],
								"e": 0.233
							},
							{
								"h": [
									0.115,
									0.023,
									0.004,
									0.049,
									0.007,
									0.044,
									0.058,
									0.216,
									0.043,
									0.012,
									0.157,
									0.027
								],
								"e": 0.08
							},
							{
								"h": [
									0.003,
									0.019,
									0.009,
									0.002,
									0.013,
									0.036,
									0.007,
									0.001,
									0.001,
									0.003,
									0.009,
									0.008
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.002,
									0.003,
									0.001,
									0.008,
									0.002,
									0.005,
									0.002,
									0.014,
									0.004,
									0.008,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.005,
									0.0,
									0.003,
									0.017,
									0.004,
									0.001,
									0.0,
									0.001,
									0.003,
									0.035,
									0.017,
									0.007
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.001,
									0.017,
									0.011,
									0.014,
									0.01,
									0.006,
									0.038,
									0.016,
									0.0,
									0.012,
									0.003
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.076,
									0.0,
									0.005,
									0.001,
									0.004,
									0.024,
									0.037,
									0.001,
									0.006,
									0.001,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.017,
									0.003,
									0.081,
									0.024,
									0.001,
									0.001,
									0.009,
									0.0,
									0.05,
									0.109,
									0.014,
									0.006
								],
								"e": 0.021
							},
							{
								"h": [
									0.002,
									0.005,
									0.016,
									0.001,
									0.003,
									0.002,
									0.002,
									0.025,
									0.005,
									0.012,
									0.0,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.009,
									0.004,
									0.007,
									0.033,
									0.039,
									0.001,
									0.005,
									0.049,
									0.158,
									0.007,
									0.004,
									0.007
								],
								"e": 0.024
							},
							{
								"h": [
									0.463,
									0.018,
									0.006,
									0.049,
									0.066,
									0.01,
									0.006,
									0.064,
									0.111,
									0.004,
									0.024,
									0.037
								],
								"e": 0.041
							}
						],
						[
							{
								"h": [
									0.319,
									0.0,
									0.2,
									0.002,
									0.027,
									0.0,
									0.472,
									0.034,
									0.109,
									0.353,
									0.177,
									0.197
								],
								"e": 0.113
							},
							{
								"h": [
									0.012,
									0.049,
									0.251,
									0.05,
									0.212,
									0.017,
									0.036,
									0.244,
									0.109,
									0.1,
									0.419,
									0.001
								],
								"e": 0.159
							},
							{
								"h": [
									0.159,
									0.028,
									0.003,
									0.012,
									0.002,
									0.028,
									0.032,
									0.324,
									0.037,
									0.044,
									0.338,
									0.041
								],
								"e": 0.093
							},
							{
								"h": [
									0.001,
									0.004,
									0.006,
									0.001,
									0.003,
									0.037,
									0.002,
									0.005,
									0.002,
									0.002,
									0.009,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.012,
									0.002,
									0.01,
									0.009,
									0.001,
									0.01,
									0.007,
									0.036,
									0.005,
									0.002,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.003,
									0.0,
									0.023,
									0.016,
									0.012,
									0.008,
									0.001,
									0.008,
									0.003,
									0.036,
									0.111,
									0.002
								],
								"e": 0.016
							},
							{
								"h": [
									0.005,
									0.0,
									0.013,
									0.015,
									0.02,
									0.009,
									0.003,
									0.045,
									0.025,
									0.003,
									0.009,
									0.01
								],
								"e": 0.011
							},
							{
								"h": [
									0.002,
									0.019,
									0.0,
									0.017,
									0.001,
									0.013,
									0.02,
									0.012,
									0.003,
									0.01,
									0.005,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.007,
									0.001,
									0.065,
									0.005,
									0.002,
									0.01,
									0.002,
									0.001,
									0.185,
									0.245,
									0.003,
									0.005
								],
								"e": 0.03
							},
							{
								"h": [
									0.001,
									0.002,
									0.036,
									0.011,
									0.001,
									0.001,
									0.008,
									0.007,
									0.013,
									0.008,
									0.001,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.018,
									0.008,
									0.006,
									0.106,
									0.028,
									0.001,
									0.068,
									0.01,
									0.007,
									0.004,
									0.007,
									0.043
								],
								"e": 0.023
							},
							{
								"h": [
									0.136,
									0.014,
									0.006,
									0.219,
									0.02,
									0.01,
									0.005,
									0.185,
									0.178,
									0.003,
									0.004,
									0.023
								],
								"e": 0.044
							}
						],
						[
							{
								"h": [
									0.149,
									0.0,
									0.554,
									0.005,
									0.064,
									0.0,
									0.075,
									0.065,
									0.475,
									0.618,
									0.252,
									0.186
								],
								"e": 0.158
							},
							{
								"h": [
									0.049,
									0.107,
									0.405,
									0.051,
									0.175,
									0.042,
									0.045,
									0.174,
									0.183,
									0.12,
									0.33,
									0.019
								],
								"e": 0.192
							},
							{
								"h": [
									0.436,
									0.067,
									0.028,
									0.016,
									0.006,
									0.064,
									0.062,
									0.189,
									0.152,
									0.01,
									0.146,
									0.027
								],
								"e": 0.122
							},
							{
								"h": [
									0.001,
									0.003,
									0.008,
									0.004,
									0.004,
									0.026,
									0.002,
									0.005,
									0.014,
									0.001,
									0.008,
									0.003
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.002,
									0.001,
									0.003,
									0.003,
									0.001,
									0.005,
									0.003,
									0.034,
									0.004,
									0.007,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.0,
									0.006,
									0.005,
									0.006,
									0.0,
									0.0,
									0.003,
									0.0,
									0.016,
									0.029,
									0.006
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.0,
									0.009,
									0.004,
									0.009,
									0.011,
									0.0,
									0.005,
									0.023,
									0.0,
									0.01,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.012,
									0.0,
									0.012,
									0.001,
									0.008,
									0.016,
									0.007,
									0.0,
									0.002,
									0.0,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.009,
									0.0,
									0.028,
									0.006,
									0.0,
									0.002,
									0.001,
									0.0,
									0.027,
									0.05,
									0.003,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.0,
									0.007,
									0.0,
									0.0,
									0.001,
									0.0,
									0.007,
									0.005,
									0.0,
									0.0,
									0.003
								],
								"e": 0.002
							},
							{
								"h": [
									0.005,
									0.002,
									0.001,
									0.003,
									0.023,
									0.0,
									0.001,
									0.009,
									0.022,
									0.001,
									0.001,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.22,
									0.002,
									0.002,
									0.071,
									0.039,
									0.003,
									0.002,
									0.02,
									0.043,
									0.001,
									0.017,
									0.037
								],
								"e": 0.023
							}
						],
						[
							{
								"h": [
									0.214,
									0.0,
									0.228,
									0.016,
									0.065,
									0.0,
									0.691,
									0.087,
									0.093,
									0.457,
									0.113,
									0.308
								],
								"e": 0.143
							},
							{
								"h": [
									0.065,
									0.092,
									0.377,
									0.061,
									0.297,
									0.04,
									0.07,
									0.205,
									0.167,
									0.115,
									0.554,
									0.001
								],
								"e": 0.225
							},
							{
								"h": [
									0.076,
									0.034,
									0.016,
									0.012,
									0.006,
									0.115,
									0.032,
									0.389,
									0.124,
									0.057,
									0.411,
									0.049
								],
								"e": 0.123
							},
							{
								"h": [
									0.001,
									0.01,
									0.014,
									0.002,
									0.006,
									0.034,
									0.012,
									0.007,
									0.009,
									0.003,
									0.035,
									0.007
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.001,
									0.001,
									0.002,
									0.011,
									0.002,
									0.017,
									0.001,
									0.013,
									0.004,
									0.001,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.0,
									0.007,
									0.011,
									0.008,
									0.001,
									0.0,
									0.001,
									0.001,
									0.021,
									0.058,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.0,
									0.023,
									0.003,
									0.015,
									0.032,
									0.001,
									0.085,
									0.024,
									0.0,
									0.012,
									0.006
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.009,
									0.0,
									0.018,
									0.002,
									0.005,
									0.025,
									0.04,
									0.003,
									0.007,
									0.003,
									0.006
								],
								"e": 0.008
							},
							{
								"h": [
									0.017,
									0.003,
									0.193,
									0.016,
									0.001,
									0.008,
									0.005,
									0.001,
									0.151,
									0.207,
									0.004,
									0.01
								],
								"e": 0.038
							},
							{
								"h": [
									0.004,
									0.006,
									0.035,
									0.008,
									0.0,
									0.001,
									0.025,
									0.018,
									0.035,
									0.016,
									0.0,
									0.007
								],
								"e": 0.013
							},
							{
								"h": [
									0.071,
									0.06,
									0.047,
									0.036,
									0.322,
									0.001,
									0.021,
									0.028,
									0.008,
									0.003,
									0.009,
									0.175
								],
								"e": 0.046
							},
							{
								"h": [
									0.27,
									0.008,
									0.007,
									0.21,
									0.034,
									0.035,
									0.007,
									0.056,
									0.018,
									0.02,
									0.013,
									0.056
								],
								"e": 0.042
							}
						],
						[
							{
								"h": [
									0.126,
									0.0,
									0.9,
									0.016,
									0.075,
									0.0,
									0.064,
									0.33,
									1.0,
									0.69,
									0.335,
									0.392
								],
								"e": 0.285
							},
							{
								"h": [
									0.157,
									1.0,
									0.703,
									0.106,
									0.31,
									0.175,
									0.045,
									0.373,
									0.528,
									0.174,
									0.709,
									0.097
								],
								"e": 0.535
							},
							{
								"h": [
									0.626,
									0.037,
									0.32,
									0.106,
									0.193,
									1.0,
									1.0,
									0.519,
									0.828,
									0.307,
									0.523,
									0.081
								],
								"e": 0.687
							},
							{
								"h": [
									0.009,
									0.978,
									0.11,
									0.267,
									0.036,
									0.061,
									0.11,
									0.193,
									0.578,
									0.216,
									0.128,
									0.247
								],
								"e": 0.261
							},
							{
								"h": [
									0.083,
									0.193,
									0.076,
									0.059,
									0.034,
									0.127,
									0.065,
									0.035,
									0.071,
									0.024,
									0.038,
									0.0
								],
								"e": 0.068
							},
							{
								"h": [
									0.009,
									0.0,
									0.008,
									0.309,
									0.064,
									0.005,
									0.002,
									0.016,
									0.028,
									0.049,
									0.147,
									0.013
								],
								"e": 0.038
							},
							{
								"h": [
									0.028,
									0.009,
									0.086,
									0.063,
									0.118,
									0.099,
									0.018,
									0.145,
									0.038,
									0.0,
									0.119,
									0.07
								],
								"e": 0.061
							},
							{
								"h": [
									0.015,
									0.074,
									0.0,
									0.048,
									0.038,
									0.111,
									0.05,
									0.276,
									0.051,
									0.033,
									0.006,
									0.001
								],
								"e": 0.047
							},
							{
								"h": [
									0.081,
									0.002,
									0.324,
									0.092,
									0.005,
									0.043,
									0.043,
									0.015,
									0.755,
									0.138,
									0.053,
									0.021
								],
								"e": 0.093
							},
							{
								"h": [
									0.021,
									0.003,
									0.018,
									0.189,
									0.012,
									0.002,
									0.001,
									0.046,
									0.022,
									0.009,
									0.005,
									0.004
								],
								"e": 0.024
							},
							{
								"h": [
									0.033,
									0.004,
									0.012,
									0.074,
									0.092,
									0.005,
									0.043,
									0.097,
									0.074,
									0.147,
									0.002,
									0.095
								],
								"e": 0.049
							},
							{
								"h": [
									0.294,
									0.03,
									0.028,
									0.486,
									0.123,
									0.013,
									0.013,
									0.084,
									0.477,
									0.017,
									0.32,
									0.648
								],
								"e": 0.162
							}
						],
						[
							{
								"h": [
									0.602,
									0.0,
									0.282,
									0.011,
									0.129,
									0.0,
									0.774,
									0.181,
									0.112,
									0.539,
									0.228,
									0.303
								],
								"e": 0.208
							},
							{
								"h": [
									0.181,
									0.687,
									0.507,
									0.082,
									0.312,
									0.13,
									0.042,
									0.286,
									0.292,
									0.275,
									0.716,
									0.004
								],
								"e": 0.418
							},
							{
								"h": [
									0.923,
									0.022,
									0.673,
									1.0,
									0.024,
									0.247,
									0.349,
									0.7,
									0.573,
									0.778,
									0.763,
									0.103
								],
								"e": 0.789
							},
							{
								"h": [
									0.001,
									0.322,
									0.905,
									0.156,
									0.009,
									0.043,
									1.0,
									0.181,
									0.206,
									0.505,
									0.113,
									1.0
								],
								"e": 0.411
							},
							{
								"h": [
									0.412,
									0.732,
									0.018,
									0.03,
									0.016,
									0.355,
									0.075,
									0.162,
									0.014,
									0.027,
									0.004,
									0.0
								],
								"e": 0.127
							},
							{
								"h": [
									0.004,
									0.0,
									0.034,
									0.36,
									0.033,
									0.04,
									0.009,
									0.002,
									0.012,
									0.037,
									0.032,
									0.028
								],
								"e": 0.034
							},
							{
								"h": [
									0.039,
									0.002,
									0.044,
									0.023,
									0.037,
									0.146,
									0.009,
									0.053,
									0.339,
									0.001,
									0.005,
									0.563
								],
								"e": 0.073
							},
							{
								"h": [
									0.149,
									0.019,
									0.004,
									0.044,
									0.019,
									0.078,
									0.043,
									0.014,
									0.026,
									0.273,
									0.018,
									0.011
								],
								"e": 0.059
							},
							{
								"h": [
									0.026,
									0.01,
									0.039,
									0.017,
									0.019,
									0.123,
									0.01,
									0.012,
									0.085,
									0.04,
									0.032,
									0.016
								],
								"e": 0.036
							},
							{
								"h": [
									0.01,
									0.056,
									0.007,
									0.136,
									0.008,
									0.017,
									0.002,
									0.039,
									0.012,
									0.018,
									0.016,
									0.001
								],
								"e": 0.024
							},
							{
								"h": [
									0.013,
									0.032,
									0.012,
									0.04,
									0.184,
									0.009,
									0.015,
									0.041,
									0.131,
									0.042,
									0.003,
									0.014
								],
								"e": 0.037
							},
							{
								"h": [
									0.329,
									0.04,
									0.003,
									0.282,
									0.052,
									0.014,
									0.005,
									0.083,
									0.672,
									0.006,
									0.042,
									0.032
								],
								"e": 0.077
							}
						],
						[
							{
								"h": [
									0.485,
									0.005,
									0.326,
									0.047,
									1.0,
									0.005,
									0.712,
									1.0,
									0.098,
									0.502,
									0.316,
									0.377
								],
								"e": 0.46
							},
							{
								"h": [
									1.0,
									0.44,
									0.495,
									0.085,
									0.378,
									0.231,
									1.0,
									0.276,
									0.204,
									0.122,
									0.741,
									0.018
								],
								"e": 0.795
							},
							{
								"h": [
									0.55,
									0.046,
									1.0,
									0.163,
									1.0,
									0.6,
									0.086,
									0.932,
									1.0,
									1.0,
									1.0,
									0.132
								],
								"e": 1.0
							},
							{
								"h": [
									0.008,
									0.094,
									1.0,
									0.359,
									0.008,
									0.111,
									0.867,
									1.0,
									0.838,
									1.0,
									0.061,
									0.606
								],
								"e": 0.551
							},
							{
								"h": [
									0.079,
									0.655,
									0.034,
									0.549,
									0.523,
									0.906,
									0.094,
									0.58,
									0.069,
									0.392,
									0.018,
									1.0
								],
								"e": 0.445
							},
							{
								"h": [
									0.003,
									0.0,
									0.221,
									0.38,
									0.223,
									0.0,
									0.106,
									0.094,
									0.0,
									0.014,
									0.295,
									0.049
								],
								"e": 0.1
							},
							{
								"h": [
									0.187,
									0.002,
									0.029,
									0.022,
									0.172,
									0.229,
									0.04,
									0.026,
									0.871,
									0.001,
									0.019,
									0.755
								],
								"e": 0.139
							},
							{
								"h": [
									0.44,
									0.119,
									0.001,
									0.096,
									0.022,
									0.198,
									0.053,
									0.013,
									0.024,
									0.131,
									0.012,
									0.004
								],
								"e": 0.09
							},
							{
								"h": [
									0.04,
									0.001,
									0.012,
									0.013,
									0.006,
									0.296,
									0.01,
									0.022,
									0.024,
									0.016,
									0.018,
									0.019
								],
								"e": 0.045
							},
							{
								"h": [
									0.012,
									0.003,
									0.009,
									0.324,
									0.004,
									0.013,
									0.002,
									0.007,
									0.004,
									0.011,
									0.012,
									0.001
								],
								"e": 0.028
							},
							{
								"h": [
									0.009,
									0.007,
									0.003,
									0.014,
									0.041,
									0.008,
									0.008,
									0.009,
									0.007,
									0.13,
									0.003,
									0.025
								],
								"e": 0.021
							},
							{
								"h": [
									0.369,
									0.01,
									0.005,
									0.1,
									0.021,
									0.008,
									0.003,
									0.072,
									1.0,
									0.012,
									0.038,
									0.042
								],
								"e": 0.074
							}
						],
						[
							{
								"h": [
									0.249,
									1.0,
									0.478,
									1.0,
									0.761,
									0.475,
									0.191,
									0.526,
									0.306,
									0.682,
									1.0,
									0.393
								],
								"e": 1.0
							},
							{
								"h": [
									0.24,
									0.328,
									1.0,
									0.118,
									0.405,
									1.0,
									0.034,
									0.186,
									0.335,
									0.272,
									1.0,
									1.0
								],
								"e": 0.853
							},
							{
								"h": [
									1.0,
									0.011,
									0.689,
									0.117,
									0.038,
									0.635,
									0.094,
									1.0,
									0.548,
									0.1,
									0.962,
									0.121
								],
								"e": 0.524
							},
							{
								"h": [
									0.072,
									0.269,
									0.204,
									0.265,
									0.012,
									0.074,
									0.102,
									0.287,
									1.0,
									0.14,
									0.216,
									0.07
								],
								"e": 0.248
							},
							{
								"h": [
									0.157,
									0.251,
									0.027,
									0.054,
									0.115,
									0.23,
									0.077,
									1.0,
									0.051,
									0.065,
									0.02,
									0.0
								],
								"e": 0.139
							},
							{
								"h": [
									0.03,
									0.002,
									0.049,
									0.503,
									0.075,
									0.044,
									0.084,
									0.022,
									0.053,
									0.034,
									0.091,
									0.277
								],
								"e": 0.085
							},
							{
								"h": [
									0.056,
									0.004,
									0.054,
									0.025,
									0.039,
									0.125,
									0.088,
									0.059,
									0.162,
									0.03,
									0.033,
									0.127
								],
								"e": 0.058
							},
							{
								"h": [
									0.111,
									0.033,
									0.023,
									0.09,
									0.009,
									0.046,
									0.094,
									0.064,
									0.013,
									0.072,
									0.074,
									0.014
								],
								"e": 0.05
							},
							{
								"h": [
									0.043,
									0.049,
									0.032,
									0.017,
									0.004,
									0.098,
									0.033,
									0.052,
									0.027,
									0.012,
									0.093,
									0.013
								],
								"e": 0.046
							},
							{
								"h": [
									0.012,
									0.024,
									0.016,
									0.17,
									0.005,
									0.017,
									0.001,
									0.027,
									0.017,
									0.015,
									0.018,
									0.005
								],
								"e": 0.025
							},
							{
								"h": [
									0.005,
									0.011,
									0.007,
									0.048,
									0.055,
									0.009,
									0.014,
									0.024,
									0.077,
									0.191,
									0.002,
									0.05
								],
								"e": 0.038
							},
							{
								"h": [
									0.462,
									0.027,
									0.004,
									0.144,
									0.022,
									0.033,
									0.005,
									0.103,
									0.319,
									0.008,
									0.117,
									0.04
								],
								"e": 0.065
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " people",
					"o": " say",
					"t": " say",
					"r": [
						{
							"p": " who",
							"b": 0.0,
							"k": {
								" who": 0.201,
								" people": 0.175,
								" are": 0.026,
								" can": 0.023,
								" were": 0.021,
								" might": 0.016,
								" will": 0.015,
								" would": 0.013,
								" in": 0.011,
								" nearby": 0.01
							}
						},
						{
							"p": " who",
							"b": 0.0,
							"k": {
								" who": 0.584,
								" people": 0.033,
								" are": 0.025,
								" were": 0.023,
								" can": 0.016,
								" would": 0.013,
								" might": 0.011,
								" smugglers": 0.011,
								" could": 0.01,
								" may": 0.008
							}
						},
						{
							"p": " who",
							"b": 0.0,
							"k": {
								" who": 0.337,
								" were": 0.056,
								" are": 0.034,
								" could": 0.029,
								" would": 0.023,
								" might": 0.019,
								" can": 0.018,
								" people": 0.016,
								" died": 0.016,
								" in": 0.014
							}
						},
						{
							"p": " who",
							"b": 0.001,
							"k": {
								" who": 0.377,
								" are": 0.06,
								" were": 0.059,
								" could": 0.04,
								" can": 0.026,
								" would": 0.024,
								"'s": 0.019,
								" may": 0.012,
								" might": 0.012,
								" affected": 0.01
							}
						},
						{
							"p": " who",
							"b": 0.003,
							"k": {
								" who": 0.139,
								" are": 0.135,
								" were": 0.087,
								" could": 0.053,
								" can": 0.036,
								"'s": 0.032,
								" would": 0.026,
								" started": 0.023,
								" may": 0.022,
								" got": 0.019
							}
						},
						{
							"p": " are",
							"b": 0.048,
							"k": {
								" are": 0.254,
								" who": 0.06,
								" were": 0.06,
								" say": 0.048,
								" may": 0.037,
								" think": 0.033,
								" can": 0.03,
								" want": 0.03,
								" have": 0.019,
								" would": 0.019
							}
						},
						{
							"p": " are",
							"b": 0.082,
							"k": {
								" are": 0.303,
								" think": 0.093,
								" say": 0.082,
								" were": 0.051,
								" want": 0.039,
								" who": 0.038,
								" can": 0.026,
								" don": 0.023,
								" ask": 0.021,
								" put": 0.019
							}
						},
						{
							"p": " are",
							"b": 0.136,
							"k": {
								" are": 0.526,
								" say": 0.136,
								" think": 0.059,
								" don": 0.038,
								" were": 0.037,
								" want": 0.023,
								" ask": 0.019,
								" who": 0.014,
								" have": 0.012,
								" do": 0.012
							}
						},
						{
							"p": " say",
							"b": 0.686,
							"k": {
								" say": 0.686,
								" are": 0.195,
								" describe": 0.048,
								" ask": 0.017,
								" have": 0.009,
								" refer": 0.006,
								" don": 0.004,
								" accuse": 0.004,
								" do": 0.003,
								" think": 0.003
							}
						},
						{
							"p": " say",
							"b": 0.983,
							"k": {
								" say": 0.983,
								" describe": 0.012,
								" refer": 0.003,
								" talk": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " say",
							"b": 0.983,
							"k": {
								" say": 0.983,
								" talk": 0.006,
								" refer": 0.005,
								" describe": 0.003,
								" mean": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": " say",
							"b": 0.792,
							"k": {
								" say": 0.792,
								" talk": 0.031,
								" use": 0.017,
								" mean": 0.017,
								" are": 0.016,
								" think": 0.012,
								" refer": 0.009,
								" call": 0.006,
								" have": 0.005,
								" describe": 0.005
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.002,
									1.0,
									0.004,
									0.167,
									0.017,
									0.768,
									0.085,
									0.442,
									1.0,
									0.493,
									1.0
								],
								"e": 0.492
							},
							{
								"h": [
									0.086,
									0.095,
									0.299,
									1.0,
									1.0,
									0.777,
									1.0,
									1.0,
									1.0,
									1.0,
									0.02,
									0.047
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.054,
									0.081,
									1.0,
									0.119,
									0.445,
									0.432,
									0.516,
									0.45,
									0.457,
									1.0
								],
								"e": 0.749
							},
							{
								"h": [
									1.0,
									1.0,
									0.638,
									1.0,
									1.0,
									1.0,
									0.344,
									1.0,
									0.306,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.254,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.33,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.943,
									0.357,
									1.0,
									0.546,
									1.0,
									0.917,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.38,
									1.0,
									1.0,
									0.308
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.806,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.308,
									1.0,
									1.0,
									0.775,
									1.0,
									0.797,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.92,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.244,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									0.723,
									1.0,
									1.0,
									0.577,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.908,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.103,
									0.003,
									0.415,
									0.0,
									0.013,
									0.0,
									0.05,
									0.013,
									0.298,
									0.398,
									0.204,
									0.413
								],
								"e": 0.136
							},
							{
								"h": [
									0.021,
									0.023,
									0.125,
									0.026,
									0.169,
									0.185,
									0.071,
									0.058,
									0.298,
									0.269,
									0.207,
									0.002
								],
								"e": 0.158
							},
							{
								"h": [
									0.054,
									0.025,
									0.0,
									0.003,
									0.006,
									0.002,
									0.014,
									0.046,
									0.008,
									0.004,
									0.118,
									0.011
								],
								"e": 0.024
							},
							{
								"h": [
									0.009,
									0.007,
									0.001,
									0.0,
									0.012,
									0.042,
									0.001,
									0.0,
									0.001,
									0.04,
									0.025,
									0.009
								],
								"e": 0.013
							},
							{
								"h": [
									0.002,
									0.005,
									0.028,
									0.005,
									0.088,
									0.001,
									0.015,
									0.019,
									0.014,
									0.028,
									0.007,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.013,
									0.001,
									0.013,
									0.085,
									0.01,
									0.015,
									0.013,
									0.008,
									0.009,
									0.04,
									0.033,
									0.02
								],
								"e": 0.02
							},
							{
								"h": [
									0.032,
									0.004,
									0.062,
									0.01,
									0.034,
									0.02,
									0.027,
									0.065,
									0.044,
									0.027,
									0.004,
									0.073
								],
								"e": 0.033
							},
							{
								"h": [
									0.006,
									0.011,
									0.016,
									0.007,
									0.002,
									0.054,
									0.041,
									0.032,
									0.006,
									0.033,
									0.05,
									0.018
								],
								"e": 0.021
							},
							{
								"h": [
									0.029,
									0.064,
									0.02,
									0.012,
									0.063,
									0.027,
									0.039,
									0.009,
									0.057,
									0.017,
									0.012,
									0.007
								],
								"e": 0.03
							},
							{
								"h": [
									0.01,
									0.021,
									0.033,
									0.042,
									0.019,
									0.013,
									0.003,
									0.042,
									0.006,
									0.013,
									0.02,
									0.005
								],
								"e": 0.017
							},
							{
								"h": [
									0.004,
									0.007,
									0.016,
									0.022,
									0.171,
									0.031,
									0.009,
									0.03,
									0.033,
									0.005,
									0.01,
									0.063
								],
								"e": 0.023
							},
							{
								"h": [
									1.0,
									0.009,
									0.005,
									0.069,
									0.055,
									0.065,
									0.008,
									0.009,
									0.313,
									0.033,
									0.101,
									0.112
								],
								"e": 0.066
							}
						],
						[
							{
								"h": [
									0.135,
									0.926,
									0.221,
									0.0,
									0.031,
									1.0,
									0.15,
									0.005,
									0.283,
									0.271,
									0.893,
									0.175
								],
								"e": 0.606
							},
							{
								"h": [
									0.014,
									0.006,
									0.109,
									0.025,
									0.134,
									0.47,
									0.132,
									0.207,
									0.107,
									0.086,
									0.209,
									0.204
								],
								"e": 0.229
							},
							{
								"h": [
									0.019,
									0.133,
									0.0,
									0.001,
									0.002,
									0.0,
									0.018,
									0.09,
									0.001,
									0.003,
									0.165,
									0.015
								],
								"e": 0.038
							},
							{
								"h": [
									0.473,
									0.008,
									0.001,
									0.0,
									0.079,
									0.054,
									0.006,
									0.0,
									0.0,
									0.008,
									0.051,
									0.002
								],
								"e": 0.076
							},
							{
								"h": [
									0.003,
									0.0,
									0.046,
									0.008,
									0.103,
									0.009,
									0.014,
									0.179,
									0.025,
									0.012,
									0.016,
									0.0
								],
								"e": 0.04
							},
							{
								"h": [
									0.018,
									0.003,
									0.003,
									0.01,
									0.005,
									0.021,
									0.005,
									0.004,
									0.004,
									0.05,
									0.075,
									0.059
								],
								"e": 0.022
							},
							{
								"h": [
									0.028,
									0.013,
									0.022,
									0.015,
									0.039,
									0.018,
									0.06,
									0.046,
									0.006,
									0.007,
									0.021,
									0.009
								],
								"e": 0.025
							},
							{
								"h": [
									0.001,
									0.033,
									0.006,
									0.005,
									0.001,
									0.016,
									0.047,
									0.058,
									0.003,
									0.004,
									0.019,
									0.008
								],
								"e": 0.014
							},
							{
								"h": [
									0.028,
									0.041,
									0.036,
									0.009,
									0.004,
									0.011,
									0.025,
									0.001,
									0.08,
									0.031,
									0.011,
									0.005
								],
								"e": 0.024
							},
							{
								"h": [
									0.016,
									0.036,
									0.016,
									0.01,
									0.013,
									0.021,
									0.003,
									0.019,
									0.003,
									0.011,
									0.003,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.009,
									0.011,
									0.014,
									0.012,
									0.249,
									0.007,
									0.012,
									0.013,
									0.066,
									0.004,
									0.006,
									0.008
								],
								"e": 0.023
							},
							{
								"h": [
									0.665,
									0.016,
									0.004,
									0.049,
									0.019,
									0.044,
									0.004,
									0.008,
									0.82,
									0.005,
									0.049,
									0.069
								],
								"e": 0.059
							}
						],
						[
							{
								"h": [
									0.248,
									0.001,
									0.401,
									0.0,
									0.01,
									0.003,
									0.092,
									0.015,
									0.198,
									0.346,
									0.18,
									0.412
								],
								"e": 0.145
							},
							{
								"h": [
									0.025,
									0.022,
									0.182,
									0.038,
									0.163,
									0.117,
									0.123,
									0.151,
									0.168,
									0.163,
									0.303,
									0.001
								],
								"e": 0.155
							},
							{
								"h": [
									0.018,
									0.107,
									0.0,
									0.003,
									0.001,
									0.002,
									0.019,
									0.145,
									0.008,
									0.008,
									0.207,
									0.019
								],
								"e": 0.044
							},
							{
								"h": [
									0.004,
									0.016,
									0.012,
									0.0,
									0.01,
									0.114,
									0.001,
									0.002,
									0.003,
									0.018,
									0.022,
									0.013
								],
								"e": 0.018
							},
							{
								"h": [
									0.002,
									0.009,
									0.029,
									0.008,
									0.009,
									0.004,
									0.028,
									0.038,
									0.031,
									0.028,
									0.01,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									1.0,
									1.0,
									0.005,
									0.047,
									0.034,
									1.0,
									0.003,
									0.007,
									0.244,
									0.049,
									0.072,
									0.01
								],
								"e": 0.279
							},
							{
								"h": [
									0.031,
									0.139,
									0.018,
									0.052,
									0.103,
									0.072,
									0.068,
									0.13,
									0.005,
									0.348,
									0.076,
									0.032
								],
								"e": 0.107
							},
							{
								"h": [
									0.002,
									0.111,
									0.06,
									0.033,
									0.002,
									0.017,
									0.097,
									0.578,
									0.003,
									0.029,
									1.0,
									0.026
								],
								"e": 0.12
							},
							{
								"h": [
									0.018,
									1.0,
									0.051,
									0.029,
									0.019,
									0.012,
									1.0,
									0.005,
									0.57,
									0.075,
									0.112,
									0.023
								],
								"e": 0.245
							},
							{
								"h": [
									0.623,
									0.803,
									0.159,
									0.015,
									0.226,
									0.017,
									0.1,
									0.134,
									0.003,
									0.337,
									0.005,
									0.007
								],
								"e": 0.178
							},
							{
								"h": [
									0.051,
									0.065,
									0.374,
									0.08,
									1.0,
									0.017,
									0.321,
									1.0,
									0.209,
									0.006,
									0.045,
									0.861
								],
								"e": 0.25
							},
							{
								"h": [
									0.445,
									0.025,
									0.012,
									0.021,
									0.041,
									0.262,
									0.013,
									0.011,
									0.041,
									0.138,
									0.095,
									0.201
								],
								"e": 0.074
							}
						],
						[
							{
								"h": [
									0.356,
									0.0,
									0.309,
									0.0,
									0.037,
									0.0,
									1.0,
									0.04,
									0.167,
									0.275,
									0.087,
									0.186
								],
								"e": 0.221
							},
							{
								"h": [
									0.044,
									0.016,
									0.192,
									0.038,
									0.2,
									0.071,
									0.769,
									0.82,
									0.093,
									0.046,
									0.323,
									0.002
								],
								"e": 0.286
							},
							{
								"h": [
									0.112,
									0.427,
									0.0,
									0.005,
									0.004,
									0.001,
									0.01,
									0.13,
									0.009,
									0.026,
									0.167,
									0.02
								],
								"e": 0.081
							},
							{
								"h": [
									0.008,
									0.003,
									0.005,
									0.0,
									0.023,
									0.134,
									0.016,
									0.002,
									0.001,
									0.011,
									0.039,
									0.012
								],
								"e": 0.022
							},
							{
								"h": [
									0.004,
									0.003,
									0.019,
									0.068,
									0.006,
									0.011,
									0.11,
									0.125,
									0.03,
									0.049,
									0.007,
									0.0
								],
								"e": 0.041
							},
							{
								"h": [
									0.1,
									0.003,
									0.005,
									0.023,
									0.024,
									0.085,
									0.002,
									0.015,
									0.063,
									0.078,
									0.036,
									0.005
								],
								"e": 0.034
							},
							{
								"h": [
									0.018,
									0.023,
									0.031,
									0.016,
									0.059,
									0.043,
									0.018,
									0.186,
									0.005,
									0.01,
									0.029,
									0.004
								],
								"e": 0.035
							},
							{
								"h": [
									0.001,
									0.05,
									0.004,
									0.008,
									0.001,
									0.007,
									0.041,
									0.324,
									0.002,
									0.002,
									0.004,
									0.017
								],
								"e": 0.026
							},
							{
								"h": [
									0.002,
									0.004,
									0.018,
									0.014,
									0.002,
									0.012,
									0.017,
									0.002,
									0.039,
									0.253,
									0.004,
									0.004
								],
								"e": 0.027
							},
							{
								"h": [
									0.013,
									0.0,
									0.071,
									0.046,
									0.002,
									0.004,
									0.008,
									0.019,
									0.013,
									0.008,
									0.001,
									0.009
								],
								"e": 0.014
							},
							{
								"h": [
									0.047,
									0.033,
									0.017,
									0.013,
									0.011,
									0.005,
									0.011,
									0.001,
									0.018,
									0.003,
									0.018,
									0.016
								],
								"e": 0.019
							},
							{
								"h": [
									0.441,
									0.009,
									0.025,
									0.23,
									0.042,
									0.009,
									0.044,
									0.016,
									0.103,
									0.008,
									0.002,
									0.684
								],
								"e": 0.072
							}
						],
						[
							{
								"h": [
									0.132,
									0.003,
									0.215,
									0.0,
									0.007,
									0.0,
									0.021,
									0.03,
									0.478,
									0.32,
									0.248,
									0.123
								],
								"e": 0.112
							},
							{
								"h": [
									0.101,
									0.035,
									0.217,
									0.035,
									0.149,
									0.502,
									0.065,
									0.129,
									0.292,
									0.332,
									0.262,
									0.005
								],
								"e": 0.228
							},
							{
								"h": [
									0.088,
									0.179,
									0.0,
									0.004,
									0.008,
									0.003,
									0.074,
									0.174,
									0.004,
									0.011,
									0.146,
									0.017
								],
								"e": 0.063
							},
							{
								"h": [
									0.023,
									0.023,
									0.002,
									0.0,
									0.012,
									0.26,
									0.004,
									0.0,
									0.001,
									0.004,
									0.028,
									0.006
								],
								"e": 0.03
							},
							{
								"h": [
									0.004,
									0.004,
									0.024,
									0.005,
									0.036,
									0.005,
									0.032,
									0.006,
									0.044,
									0.015,
									0.033,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.082,
									0.0,
									0.017,
									0.039,
									0.003,
									0.008,
									0.0,
									0.001,
									0.007,
									0.108,
									0.075,
									0.019
								],
								"e": 0.027
							},
							{
								"h": [
									0.023,
									0.008,
									0.015,
									0.062,
									0.045,
									0.022,
									0.065,
									0.104,
									0.006,
									0.0,
									0.057,
									0.003
								],
								"e": 0.036
							},
							{
								"h": [
									0.0,
									0.491,
									0.0,
									0.006,
									0.0,
									0.005,
									0.06,
									0.211,
									0.0,
									0.023,
									0.005,
									0.003
								],
								"e": 0.046
							},
							{
								"h": [
									0.032,
									0.009,
									0.047,
									0.123,
									0.016,
									0.007,
									0.053,
									0.002,
									0.078,
									0.021,
									0.061,
									0.007
								],
								"e": 0.043
							},
							{
								"h": [
									0.118,
									0.018,
									0.024,
									0.01,
									0.022,
									0.037,
									0.004,
									0.026,
									0.013,
									0.037,
									0.014,
									0.005
								],
								"e": 0.026
							},
							{
								"h": [
									0.008,
									0.046,
									0.057,
									0.026,
									0.558,
									0.017,
									0.079,
									0.062,
									0.081,
									0.008,
									0.021,
									0.786
								],
								"e": 0.09
							},
							{
								"h": [
									0.411,
									0.029,
									0.006,
									0.086,
									0.076,
									0.143,
									0.009,
									0.033,
									1.0,
									0.031,
									0.08,
									0.451
								],
								"e": 0.102
							}
						],
						[
							{
								"h": [
									0.38,
									0.001,
									0.322,
									0.001,
									0.039,
									0.0,
									0.118,
									0.055,
									0.257,
									0.252,
									0.119,
									0.43
								],
								"e": 0.159
							},
							{
								"h": [
									0.06,
									0.03,
									0.227,
									0.043,
									0.17,
									0.073,
									0.315,
									0.172,
									0.138,
									0.111,
									0.366,
									0.0
								],
								"e": 0.182
							},
							{
								"h": [
									0.031,
									0.137,
									0.0,
									0.01,
									0.004,
									0.005,
									0.066,
									0.209,
									0.033,
									0.026,
									0.284,
									0.033
								],
								"e": 0.073
							},
							{
								"h": [
									0.002,
									0.024,
									0.003,
									0.001,
									0.007,
									0.077,
									0.002,
									0.001,
									0.003,
									0.015,
									0.01,
									0.01
								],
								"e": 0.013
							},
							{
								"h": [
									0.003,
									0.004,
									0.024,
									0.003,
									0.017,
									0.001,
									0.018,
									0.01,
									0.045,
									0.042,
									0.049,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.051,
									0.0,
									0.006,
									0.059,
									0.008,
									0.066,
									0.002,
									0.005,
									0.068,
									0.046,
									0.128,
									0.004
								],
								"e": 0.034
							},
							{
								"h": [
									0.025,
									0.002,
									0.054,
									0.025,
									0.043,
									0.069,
									0.025,
									0.09,
									0.012,
									0.024,
									0.051,
									0.008
								],
								"e": 0.037
							},
							{
								"h": [
									0.0,
									0.2,
									0.004,
									0.012,
									0.001,
									0.014,
									0.074,
									0.073,
									0.001,
									0.028,
									0.08,
									0.007
								],
								"e": 0.031
							},
							{
								"h": [
									0.021,
									0.033,
									0.049,
									0.055,
									0.013,
									0.019,
									0.077,
									0.003,
									0.329,
									0.086,
									0.071,
									0.036
								],
								"e": 0.063
							},
							{
								"h": [
									0.32,
									0.072,
									0.543,
									0.012,
									0.076,
									0.011,
									0.039,
									0.137,
									0.004,
									0.399,
									0.003,
									0.004
								],
								"e": 0.113
							},
							{
								"h": [
									0.023,
									0.036,
									0.161,
									0.04,
									0.188,
									0.005,
									0.079,
									0.161,
									0.062,
									0.01,
									0.02,
									0.239
								],
								"e": 0.069
							},
							{
								"h": [
									0.234,
									0.006,
									0.006,
									0.025,
									0.02,
									0.131,
									0.007,
									0.002,
									0.104,
									0.1,
									0.024,
									0.13
								],
								"e": 0.042
							}
						],
						[
							{
								"h": [
									0.087,
									0.001,
									0.238,
									0.0,
									0.02,
									0.0,
									0.02,
									0.053,
									1.0,
									0.329,
									0.216,
									0.16
								],
								"e": 0.144
							},
							{
								"h": [
									0.131,
									0.054,
									0.31,
									0.042,
									0.167,
									0.31,
									0.041,
									0.086,
									0.353,
									0.613,
									0.281,
									0.002
								],
								"e": 0.262
							},
							{
								"h": [
									0.076,
									0.059,
									0.002,
									0.069,
									0.04,
									0.008,
									0.067,
									0.131,
									0.042,
									0.013,
									0.113,
									0.02
								],
								"e": 0.065
							},
							{
								"h": [
									0.012,
									0.034,
									0.006,
									0.0,
									0.034,
									0.048,
									0.007,
									0.001,
									0.0,
									0.004,
									0.018,
									0.016
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.001,
									0.007,
									0.001,
									0.017,
									0.002,
									0.013,
									0.008,
									0.026,
									0.006,
									0.029,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.004,
									0.0,
									0.003,
									0.011,
									0.001,
									0.002,
									0.0,
									0.001,
									0.005,
									0.031,
									0.03,
									0.011
								],
								"e": 0.008
							},
							{
								"h": [
									0.002,
									0.0,
									0.034,
									0.006,
									0.009,
									0.009,
									0.01,
									0.041,
									0.004,
									0.0,
									0.033,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.041,
									0.0,
									0.002,
									0.0,
									0.002,
									0.014,
									0.012,
									0.0,
									0.003,
									0.0,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.035,
									0.001,
									0.024,
									0.01,
									0.005,
									0.004,
									0.015,
									0.001,
									0.021,
									0.072,
									0.006,
									0.003
								],
								"e": 0.016
							},
							{
								"h": [
									0.003,
									0.002,
									0.048,
									0.005,
									0.004,
									0.002,
									0.0,
									0.017,
									0.001,
									0.004,
									0.001,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.001,
									0.001,
									0.003,
									0.005,
									0.015,
									0.003,
									0.002,
									0.006,
									0.013,
									0.006,
									0.004,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.427,
									0.001,
									0.001,
									0.029,
									0.009,
									0.026,
									0.002,
									0.002,
									0.053,
									0.005,
									0.003,
									0.04
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.118,
									0.0,
									0.472,
									0.003,
									0.061,
									0.001,
									0.409,
									0.081,
									0.553,
									0.281,
									0.138,
									0.144
								],
								"e": 0.176
							},
							{
								"h": [
									0.074,
									0.027,
									0.222,
									0.043,
									0.182,
									0.227,
									0.882,
									0.458,
									0.094,
									0.078,
									0.381,
									0.002
								],
								"e": 0.291
							},
							{
								"h": [
									0.085,
									0.318,
									0.001,
									0.02,
									0.007,
									0.005,
									0.022,
									0.198,
									0.006,
									0.098,
									0.248,
									0.032
								],
								"e": 0.091
							},
							{
								"h": [
									0.006,
									0.011,
									0.0,
									0.0,
									0.018,
									0.147,
									0.008,
									0.002,
									0.001,
									0.001,
									0.014,
									0.007
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.007,
									0.012,
									0.004,
									0.004,
									0.002,
									0.035,
									0.012,
									0.101,
									0.041,
									0.009,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.009,
									0.0,
									0.003,
									0.029,
									0.011,
									0.007,
									0.001,
									0.004,
									0.002,
									0.097,
									0.097,
									0.002
								],
								"e": 0.02
							},
							{
								"h": [
									0.004,
									0.0,
									0.064,
									0.009,
									0.034,
									0.013,
									0.009,
									0.08,
									0.007,
									0.001,
									0.019,
									0.01
								],
								"e": 0.02
							},
							{
								"h": [
									0.0,
									0.007,
									0.0,
									0.004,
									0.0,
									0.009,
									0.012,
									0.004,
									0.001,
									0.001,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.005,
									0.0,
									0.019,
									0.003,
									0.008,
									0.007,
									0.001,
									0.001,
									0.048,
									0.233,
									0.001,
									0.001
								],
								"e": 0.022
							},
							{
								"h": [
									0.001,
									0.0,
									0.055,
									0.025,
									0.001,
									0.0,
									0.001,
									0.003,
									0.002,
									0.001,
									0.004,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.015,
									0.01,
									0.004,
									0.006,
									0.001,
									0.003,
									0.001,
									0.0,
									0.011
								],
								"e": 0.004
							},
							{
								"h": [
									0.141,
									0.002,
									0.001,
									0.117,
									0.005,
									0.002,
									0.001,
									0.002,
									0.072,
									0.001,
									0.0,
									0.055
								],
								"e": 0.017
							}
						],
						[
							{
								"h": [
									0.073,
									0.001,
									0.347,
									0.001,
									0.043,
									0.0,
									0.01,
									0.085,
									0.425,
									0.336,
									0.205,
									0.136
								],
								"e": 0.118
							},
							{
								"h": [
									0.229,
									0.094,
									0.379,
									0.047,
									0.174,
									0.19,
									0.16,
									0.168,
									0.211,
									0.155,
									0.304,
									0.028
								],
								"e": 0.239
							},
							{
								"h": [
									0.494,
									0.099,
									0.007,
									0.019,
									0.02,
									0.012,
									0.062,
									0.121,
									0.105,
									0.019,
									0.108,
									0.019
								],
								"e": 0.096
							},
							{
								"h": [
									0.009,
									0.008,
									0.008,
									0.0,
									0.022,
									0.034,
									0.004,
									0.003,
									0.006,
									0.003,
									0.003,
									0.005
								],
								"e": 0.01
							},
							{
								"h": [
									0.002,
									0.005,
									0.002,
									0.005,
									0.006,
									0.002,
									0.026,
									0.003,
									0.034,
									0.012,
									0.017,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.002,
									0.0,
									0.003,
									0.005,
									0.011,
									0.001,
									0.001,
									0.004,
									0.0,
									0.015,
									0.038,
									0.013
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.001,
									0.026,
									0.006,
									0.011,
									0.009,
									0.002,
									0.031,
									0.004,
									0.0,
									0.023,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.005,
									0.0,
									0.003,
									0.0,
									0.004,
									0.008,
									0.005,
									0.0,
									0.001,
									0.0,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.004,
									0.0,
									0.012,
									0.001,
									0.003,
									0.003,
									0.003,
									0.0,
									0.015,
									0.077,
									0.001,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.001,
									0.011,
									0.001,
									0.0,
									0.001,
									0.0,
									0.005,
									0.002,
									0.0,
									0.0,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.002,
									0.015,
									0.001,
									0.0,
									0.003,
									0.017,
									0.001,
									0.0,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.289,
									0.0,
									0.0,
									0.012,
									0.023,
									0.002,
									0.001,
									0.0,
									0.029,
									0.001,
									0.001,
									0.012
								],
								"e": 0.011
							}
						],
						[
							{
								"h": [
									0.107,
									0.0,
									0.456,
									0.001,
									0.06,
									0.001,
									0.218,
									0.064,
									0.251,
									0.387,
									0.185,
									0.313
								],
								"e": 0.156
							},
							{
								"h": [
									0.161,
									0.065,
									0.332,
									0.054,
									0.261,
									0.105,
									0.214,
									0.32,
									0.147,
									0.117,
									0.523,
									0.002
								],
								"e": 0.243
							},
							{
								"h": [
									0.08,
									0.349,
									0.004,
									0.026,
									0.016,
									0.018,
									0.024,
									0.245,
									0.035,
									0.159,
									0.286,
									0.036
								],
								"e": 0.114
							},
							{
								"h": [
									0.002,
									0.016,
									0.006,
									0.0,
									0.03,
									0.104,
									0.009,
									0.006,
									0.001,
									0.004,
									0.046,
									0.014
								],
								"e": 0.021
							},
							{
								"h": [
									0.001,
									0.002,
									0.003,
									0.004,
									0.015,
									0.001,
									0.026,
									0.005,
									0.038,
									0.017,
									0.008,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.004,
									0.0,
									0.003,
									0.017,
									0.007,
									0.007,
									0.0,
									0.006,
									0.003,
									0.068,
									0.053,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.001,
									0.036,
									0.005,
									0.036,
									0.027,
									0.003,
									0.243,
									0.005,
									0.0,
									0.02,
									0.002
								],
								"e": 0.026
							},
							{
								"h": [
									0.0,
									0.004,
									0.0,
									0.003,
									0.0,
									0.002,
									0.01,
									0.018,
									0.0,
									0.0,
									0.001,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.007,
									0.0,
									0.03,
									0.001,
									0.002,
									0.003,
									0.001,
									0.0,
									0.029,
									0.217,
									0.0,
									0.002
								],
								"e": 0.021
							},
							{
								"h": [
									0.002,
									0.001,
									0.041,
									0.022,
									0.0,
									0.001,
									0.001,
									0.005,
									0.003,
									0.001,
									0.0,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.007,
									0.003,
									0.003,
									0.029,
									0.002,
									0.001,
									0.0,
									0.003,
									0.0,
									0.001,
									0.005
								],
								"e": 0.003
							},
							{
								"h": [
									0.195,
									0.001,
									0.0,
									0.162,
									0.006,
									0.001,
									0.001,
									0.001,
									0.009,
									0.0,
									0.0,
									0.052
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.074,
									0.0,
									0.427,
									0.002,
									0.049,
									0.0,
									0.022,
									0.491,
									0.554,
									0.441,
									0.228,
									0.333
								],
								"e": 0.201
							},
							{
								"h": [
									0.304,
									0.813,
									0.652,
									0.102,
									0.294,
									0.614,
									0.071,
									0.249,
									0.573,
									0.188,
									0.668,
									0.101
								],
								"e": 0.56
							},
							{
								"h": [
									0.451,
									0.109,
									0.043,
									0.06,
									0.576,
									0.337,
									1.0,
									0.336,
									0.372,
									0.567,
									0.425,
									0.068
								],
								"e": 0.482
							},
							{
								"h": [
									0.03,
									0.839,
									0.02,
									0.036,
									0.044,
									0.105,
									0.08,
									0.134,
									0.048,
									0.093,
									0.078,
									0.121
								],
								"e": 0.13
							},
							{
								"h": [
									0.029,
									0.071,
									0.069,
									0.025,
									0.05,
									0.253,
									0.076,
									0.021,
									0.022,
									0.028,
									0.044,
									0.0
								],
								"e": 0.069
							},
							{
								"h": [
									0.197,
									0.0,
									0.008,
									0.19,
									0.044,
									0.04,
									0.001,
									0.009,
									0.029,
									0.066,
									0.074,
									0.023
								],
								"e": 0.048
							},
							{
								"h": [
									0.045,
									0.026,
									0.137,
									0.028,
									0.106,
									0.133,
									0.033,
									0.373,
									0.035,
									0.004,
									0.135,
									0.033
								],
								"e": 0.087
							},
							{
								"h": [
									0.002,
									0.072,
									0.001,
									0.036,
									0.003,
									0.038,
									0.029,
									0.125,
									0.007,
									0.029,
									0.007,
									0.005
								],
								"e": 0.023
							},
							{
								"h": [
									0.072,
									0.002,
									0.177,
									0.026,
									0.018,
									0.026,
									0.035,
									0.013,
									0.384,
									0.224,
									0.018,
									0.014
								],
								"e": 0.076
							},
							{
								"h": [
									0.041,
									0.01,
									0.141,
									0.204,
									0.014,
									0.008,
									0.002,
									0.1,
									0.015,
									0.004,
									0.008,
									0.005
								],
								"e": 0.034
							},
							{
								"h": [
									0.006,
									0.005,
									0.012,
									0.031,
									0.043,
									0.012,
									0.032,
									0.025,
									0.025,
									0.013,
									0.004,
									0.121
								],
								"e": 0.022
							},
							{
								"h": [
									0.272,
									0.008,
									0.002,
									0.069,
									0.055,
									0.031,
									0.002,
									0.009,
									0.262,
									0.033,
									0.027,
									0.105
								],
								"e": 0.039
							}
						],
						[
							{
								"h": [
									0.163,
									0.003,
									0.307,
									0.043,
									0.914,
									0.001,
									0.282,
									0.42,
									0.39,
									0.458,
									0.323,
									0.203
								],
								"e": 0.334
							},
							{
								"h": [
									0.675,
									0.271,
									0.451,
									0.073,
									0.253,
									0.249,
									0.111,
									0.134,
									0.279,
									0.301,
									0.65,
									0.015
								],
								"e": 0.386
							},
							{
								"h": [
									0.392,
									0.052,
									0.045,
									0.318,
									0.869,
									0.038,
									0.23,
									0.418,
									0.101,
									0.251,
									0.412,
									0.065
								],
								"e": 0.332
							},
							{
								"h": [
									0.007,
									0.22,
									0.063,
									0.033,
									0.032,
									0.108,
									0.361,
									0.018,
									0.002,
									0.048,
									0.075,
									0.332
								],
								"e": 0.109
							},
							{
								"h": [
									0.01,
									0.023,
									0.037,
									0.092,
									0.01,
									0.146,
									0.064,
									0.05,
									0.013,
									0.027,
									0.004,
									0.0
								],
								"e": 0.047
							},
							{
								"h": [
									0.017,
									0.0,
									0.012,
									0.149,
									0.007,
									0.036,
									0.002,
									0.003,
									0.021,
									0.046,
									0.017,
									0.025
								],
								"e": 0.024
							},
							{
								"h": [
									0.1,
									0.006,
									0.114,
									0.028,
									0.025,
									0.081,
									0.012,
									0.085,
									0.082,
									0.002,
									0.009,
									0.105
								],
								"e": 0.053
							},
							{
								"h": [
									0.014,
									0.024,
									0.001,
									0.039,
									0.004,
									0.086,
									0.015,
									0.006,
									0.024,
									0.088,
									0.003,
									0.001
								],
								"e": 0.023
							},
							{
								"h": [
									0.032,
									0.002,
									0.015,
									0.006,
									0.085,
									0.3,
									0.006,
									0.014,
									0.014,
									0.009,
									0.008,
									0.002
								],
								"e": 0.033
							},
							{
								"h": [
									0.01,
									0.006,
									0.015,
									0.207,
									0.01,
									0.014,
									0.0,
									0.037,
									0.01,
									0.002,
									0.076,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.001,
									0.002,
									0.006,
									0.002,
									0.066,
									0.099,
									0.002,
									0.001,
									0.014,
									0.054,
									0.002,
									0.003
								],
								"e": 0.018
							},
							{
								"h": [
									0.329,
									0.002,
									0.0,
									0.056,
									0.009,
									0.006,
									0.001,
									0.001,
									0.395,
									0.006,
									0.029,
									0.092
								],
								"e": 0.031
							}
						],
						[
							{
								"h": [
									0.215,
									0.003,
									0.276,
									0.049,
									0.343,
									0.001,
									0.186,
									0.494,
									0.15,
									0.329,
									0.159,
									0.307
								],
								"e": 0.239
							},
							{
								"h": [
									0.647,
									0.182,
									0.493,
									0.076,
									0.365,
									0.102,
									0.112,
									0.233,
									0.216,
									0.138,
									0.736,
									0.005
								],
								"e": 0.357
							},
							{
								"h": [
									0.221,
									0.126,
									0.058,
									0.151,
									0.071,
									0.159,
									0.061,
									0.575,
									0.81,
									1.0,
									0.773,
									0.089
								],
								"e": 0.39
							},
							{
								"h": [
									0.002,
									0.048,
									0.065,
									0.03,
									0.013,
									0.196,
									0.128,
									0.142,
									0.144,
									0.323,
									0.078,
									0.406
								],
								"e": 0.137
							},
							{
								"h": [
									0.012,
									0.125,
									0.111,
									0.048,
									0.02,
									0.052,
									0.109,
									0.068,
									0.02,
									0.217,
									0.021,
									0.0
								],
								"e": 0.08
							},
							{
								"h": [
									0.001,
									0.0,
									0.094,
									0.376,
									0.054,
									0.001,
									0.002,
									0.003,
									0.0,
									0.022,
									0.014,
									0.005
								],
								"e": 0.04
							},
							{
								"h": [
									0.168,
									0.002,
									0.069,
									0.02,
									0.095,
									0.092,
									0.056,
									0.03,
									0.502,
									0.002,
									0.045,
									0.427
								],
								"e": 0.121
							},
							{
								"h": [
									0.036,
									0.023,
									0.002,
									0.084,
									0.003,
									0.161,
									0.02,
									0.003,
									0.009,
									0.162,
									0.009,
									0.003
								],
								"e": 0.039
							},
							{
								"h": [
									0.013,
									0.001,
									0.008,
									0.004,
									0.101,
									0.484,
									0.01,
									0.078,
									0.018,
									0.018,
									0.021,
									0.003
								],
								"e": 0.05
							},
							{
								"h": [
									0.017,
									0.008,
									0.11,
									0.712,
									0.004,
									0.006,
									0.001,
									0.013,
									0.004,
									0.006,
									0.166,
									0.001
								],
								"e": 0.055
							},
							{
								"h": [
									0.005,
									0.005,
									0.01,
									0.013,
									0.22,
									0.221,
									0.006,
									0.004,
									0.004,
									0.01,
									0.006,
									0.055
								],
								"e": 0.031
							},
							{
								"h": [
									0.292,
									0.003,
									0.001,
									0.063,
									0.017,
									0.022,
									0.002,
									0.002,
									0.245,
									0.016,
									0.028,
									0.292
								],
								"e": 0.038
							}
						],
						[
							{
								"h": [
									0.109,
									0.001,
									0.329,
									0.031,
									0.222,
									0.0,
									0.039,
									1.0,
									0.491,
									0.412,
									0.197,
									0.358
								],
								"e": 0.281
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.113,
									0.406,
									0.27,
									0.066,
									0.111,
									0.377,
									0.428,
									1.0,
									0.004
								],
								"e": 0.666
							},
							{
								"h": [
									0.988,
									0.024,
									1.0,
									1.0,
									0.374,
									1.0,
									0.114,
									0.642,
									1.0,
									0.669,
									0.667,
									0.1
								],
								"e": 1.0
							},
							{
								"h": [
									0.004,
									0.294,
									0.459,
									0.285,
									0.006,
									0.084,
									0.099,
									0.76,
									1.0,
									0.26,
									0.203,
									0.833
								],
								"e": 0.402
							},
							{
								"h": [
									0.444,
									1.0,
									0.083,
									0.134,
									0.137,
									0.097,
									0.271,
									0.183,
									0.024,
									0.36,
									0.014,
									1.0
								],
								"e": 0.454
							},
							{
								"h": [
									0.007,
									0.0,
									0.149,
									1.0,
									0.394,
									0.006,
									0.222,
									0.049,
									0.017,
									0.101,
									0.026,
									0.043
								],
								"e": 0.155
							},
							{
								"h": [
									0.516,
									0.021,
									0.138,
									0.024,
									0.071,
									0.119,
									0.142,
									0.204,
									1.0,
									0.005,
									0.046,
									1.0
								],
								"e": 0.256
							},
							{
								"h": [
									0.374,
									0.017,
									0.007,
									0.128,
									0.011,
									0.103,
									0.047,
									0.015,
									0.053,
									0.534,
									0.016,
									0.008
								],
								"e": 0.099
							},
							{
								"h": [
									0.051,
									0.029,
									0.026,
									0.008,
									1.0,
									0.814,
									0.031,
									0.664,
									0.045,
									0.015,
									0.064,
									0.013
								],
								"e": 0.204
							},
							{
								"h": [
									0.024,
									0.019,
									0.056,
									1.0,
									0.01,
									0.011,
									0.001,
									0.034,
									0.009,
									0.009,
									1.0,
									0.004
								],
								"e": 0.142
							},
							{
								"h": [
									0.002,
									0.004,
									0.025,
									0.012,
									0.192,
									0.594,
									0.01,
									0.016,
									0.019,
									0.063,
									0.007,
									0.042
								],
								"e": 0.064
							},
							{
								"h": [
									0.563,
									0.003,
									0.001,
									0.082,
									0.014,
									0.04,
									0.003,
									0.004,
									0.221,
									0.013,
									0.126,
									0.189
								],
								"e": 0.049
							}
						],
						[
							{
								"h": [
									0.195,
									1.0,
									0.196,
									1.0,
									1.0,
									0.415,
									0.182,
									0.54,
									0.429,
									0.287,
									1.0,
									0.129
								],
								"e": 1.0
							},
							{
								"h": [
									0.775,
									0.267,
									0.685,
									0.094,
									0.416,
									1.0,
									0.129,
									0.407,
									0.163,
									0.185,
									0.937,
									1.0
								],
								"e": 0.894
							},
							{
								"h": [
									0.488,
									0.2,
									0.191,
									0.199,
									0.172,
									0.138,
									0.152,
									1.0,
									0.117,
									0.802,
									1.0,
									0.137
								],
								"e": 0.44
							},
							{
								"h": [
									0.174,
									0.429,
									1.0,
									0.054,
									0.105,
									0.13,
									1.0,
									0.25,
									0.143,
									0.331,
									0.432,
									0.183
								],
								"e": 0.387
							},
							{
								"h": [
									0.281,
									0.027,
									0.057,
									0.214,
									0.168,
									0.297,
									0.198,
									1.0,
									0.026,
									0.056,
									0.014,
									0.0
								],
								"e": 0.217
							},
							{
								"h": [
									0.041,
									0.003,
									0.058,
									0.096,
									0.15,
									0.022,
									0.053,
									0.028,
									0.031,
									0.065,
									0.085,
									0.075
								],
								"e": 0.062
							},
							{
								"h": [
									0.205,
									0.058,
									0.03,
									0.067,
									0.075,
									0.117,
									0.26,
									0.166,
									0.111,
									0.005,
									0.153,
									0.06
								],
								"e": 0.112
							},
							{
								"h": [
									0.014,
									0.062,
									0.004,
									0.061,
									0.004,
									0.024,
									0.048,
									0.049,
									0.013,
									0.052,
									0.007,
									0.007
								],
								"e": 0.025
							},
							{
								"h": [
									0.042,
									0.018,
									0.025,
									0.008,
									0.039,
									0.117,
									0.012,
									0.015,
									0.071,
									0.03,
									0.04,
									0.004
								],
								"e": 0.033
							},
							{
								"h": [
									0.043,
									0.025,
									0.057,
									0.151,
									0.007,
									0.037,
									0.003,
									0.038,
									0.005,
									0.01,
									0.038,
									0.001
								],
								"e": 0.027
							},
							{
								"h": [
									0.024,
									0.02,
									0.017,
									0.012,
									0.406,
									0.065,
									0.025,
									0.015,
									0.034,
									0.056,
									0.007,
									0.013
								],
								"e": 0.04
							},
							{
								"h": [
									0.355,
									0.011,
									0.001,
									0.05,
									0.011,
									0.04,
									0.002,
									0.005,
									0.797,
									0.004,
									0.037,
									0.099
								],
								"e": 0.05
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " say",
					"o": " plasma",
					"t": " plasma",
					"r": [
						{
							"p": " it",
							"b": 0.0,
							"k": {
								" it": 0.12,
								" that": 0.114,
								" about": 0.111,
								",": 0.077,
								" say": 0.054,
								" they": 0.053,
								"ings": 0.041,
								" \"": 0.031,
								" the": 0.027,
								" he": 0.023
							}
						},
						{
							"p": " it",
							"b": 0.0,
							"k": {
								" it": 0.14,
								" about": 0.131,
								" that": 0.093,
								" they": 0.092,
								"ings": 0.064,
								" this": 0.052,
								",": 0.041,
								" the": 0.033,
								" \"": 0.028,
								" a": 0.02
							}
						},
						{
							"p": " it",
							"b": 0.0,
							"k": {
								" it": 0.205,
								" they": 0.118,
								" that": 0.098,
								" this": 0.096,
								" about": 0.082,
								" \"": 0.038,
								" goodbye": 0.035,
								" he": 0.033,
								",": 0.03,
								"ings": 0.02
							}
						},
						{
							"p": " goodbye",
							"b": 0.0,
							"k": {
								" goodbye": 0.528,
								" they": 0.079,
								" that": 0.065,
								" it": 0.06,
								" this": 0.045,
								" about": 0.032,
								" \"": 0.03,
								" hello": 0.022,
								" he": 0.013,
								" the": 0.009
							}
						},
						{
							"p": " goodbye",
							"b": 0.0,
							"k": {
								" goodbye": 0.886,
								" that": 0.018,
								" hello": 0.017,
								" they": 0.011,
								" about": 0.011,
								" it": 0.009,
								" \"": 0.007,
								" this": 0.005,
								" aloud": 0.004,
								",": 0.002
							}
						},
						{
							"p": " goodbye",
							"b": 0.0,
							"k": {
								" goodbye": 0.861,
								" \"": 0.022,
								" that": 0.013,
								" this": 0.01,
								" hello": 0.01,
								" it": 0.009,
								" aloud": 0.006,
								" the": 0.005,
								" something": 0.005,
								" they": 0.004
							}
						},
						{
							"p": " goodbye",
							"b": 0.0,
							"k": {
								" goodbye": 0.752,
								" this": 0.04,
								" it": 0.033,
								" something": 0.03,
								" \"": 0.02,
								" hello": 0.017,
								" that": 0.016,
								" the": 0.014,
								" anything": 0.013,
								" things": 0.011
							}
						},
						{
							"p": " something",
							"b": 0.0,
							"k": {
								" something": 0.307,
								" it": 0.203,
								" anything": 0.134,
								" \"": 0.111,
								" things": 0.06,
								" they": 0.047,
								" '": 0.018,
								" this": 0.013,
								" the": 0.012,
								" that": 0.011
							}
						},
						{
							"p": " it",
							"b": 0.008,
							"k": {
								" it": 0.417,
								" something": 0.205,
								" \"": 0.137,
								" they": 0.059,
								" '": 0.038,
								" anything": 0.025,
								" that": 0.016,
								" things": 0.014,
								" this": 0.011,
								" plasma": 0.008
							}
						},
						{
							"p": " plasma",
							"b": 0.996,
							"k": {
								" plasma": 0.996,
								" Plasma": 0.002,
								" \"": 0.001,
								" something": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " plasma",
							"b": 0.996,
							"k": {
								" plasma": 0.996,
								" liquid": 0.001,
								" ion": 0.001,
								" Plasma": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " plasma",
							"b": 0.678,
							"k": {
								" plasma": 0.678,
								" it": 0.02,
								" \"": 0.017,
								" a": 0.014,
								" the": 0.012,
								" they": 0.012,
								" that": 0.009,
								" something": 0.009,
								" '": 0.006,
								" liquid": 0.006
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.001,
									1.0,
									0.001,
									0.184,
									0.003,
									1.0,
									0.086,
									0.459,
									1.0,
									0.334,
									1.0
								],
								"e": 0.391
							},
							{
								"h": [
									0.014,
									0.102,
									0.291,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.019,
									0.051
								],
								"e": 1.0
							},
							{
								"h": [
									0.64,
									1.0,
									0.062,
									0.076,
									0.817,
									0.125,
									0.483,
									0.468,
									0.721,
									0.124,
									0.333,
									1.0
								],
								"e": 0.797
							},
							{
								"h": [
									1.0,
									1.0,
									0.411,
									1.0,
									1.0,
									1.0,
									0.229,
									0.597,
									0.269,
									1.0,
									1.0,
									0.686
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.243,
									1.0,
									0.749,
									1.0,
									1.0,
									1.0,
									0.51,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.665,
									1.0,
									0.503,
									1.0,
									0.574,
									1.0,
									1.0,
									1.0,
									1.0,
									0.706,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.771,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.707,
									1.0,
									1.0,
									0.64
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.302,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.128,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.996,
									1.0,
									1.0,
									1.0,
									1.0,
									0.017,
									1.0,
									0.192,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.104,
									1.0,
									1.0,
									1.0,
									0.634,
									0.608,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.9,
									1.0,
									1.0,
									1.0,
									0.347,
									1.0,
									1.0,
									0.027,
									1.0,
									1.0,
									1.0,
									0.064
								],
								"e": 1.0
							},
							{
								"h": [
									0.967,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									0.133,
									0.637
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.124,
									0.002,
									0.655,
									0.0,
									0.043,
									0.0,
									0.162,
									0.013,
									0.328,
									0.541,
									0.235,
									0.376
								],
								"e": 0.145
							},
							{
								"h": [
									0.006,
									0.026,
									0.147,
									0.02,
									0.169,
									0.315,
									0.196,
									0.116,
									0.197,
									0.219,
									0.141,
									0.004
								],
								"e": 0.163
							},
							{
								"h": [
									0.067,
									0.017,
									0.0,
									0.004,
									0.009,
									0.001,
									0.018,
									0.046,
									0.009,
									0.0,
									0.095,
									0.008
								],
								"e": 0.025
							},
							{
								"h": [
									0.04,
									0.003,
									0.0,
									0.0,
									0.024,
									0.07,
									0.0,
									0.0,
									0.001,
									0.034,
									0.026,
									0.008
								],
								"e": 0.022
							},
							{
								"h": [
									0.0,
									0.002,
									0.036,
									0.003,
									0.098,
									0.001,
									0.008,
									0.017,
									0.009,
									0.011,
									0.011,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.007,
									0.0,
									0.007,
									0.066,
									0.011,
									0.012,
									0.001,
									0.005,
									0.008,
									0.065,
									0.039,
									0.012
								],
								"e": 0.017
							},
							{
								"h": [
									0.043,
									0.0,
									0.053,
									0.002,
									0.022,
									0.032,
									0.004,
									0.039,
									0.04,
									0.015,
									0.003,
									0.049
								],
								"e": 0.021
							},
							{
								"h": [
									0.006,
									0.021,
									0.002,
									0.018,
									0.01,
									0.025,
									0.037,
									0.009,
									0.003,
									0.036,
									0.034,
									0.021
								],
								"e": 0.018
							},
							{
								"h": [
									0.027,
									0.474,
									0.015,
									0.03,
									0.005,
									0.035,
									0.001,
									0.001,
									0.079,
									0.004,
									0.025,
									0.009
								],
								"e": 0.034
							},
							{
								"h": [
									0.006,
									0.019,
									0.004,
									0.022,
									0.004,
									0.035,
									0.001,
									0.018,
									0.014,
									0.007,
									0.002,
									0.003
								],
								"e": 0.011
							},
							{
								"h": [
									0.021,
									0.008,
									0.004,
									0.022,
									0.012,
									0.001,
									0.003,
									0.001,
									0.042,
									0.011,
									0.01,
									0.002
								],
								"e": 0.013
							},
							{
								"h": [
									0.814,
									0.035,
									0.02,
									0.224,
									0.133,
									0.091,
									0.013,
									0.075,
									0.489,
									0.02,
									0.045,
									0.104
								],
								"e": 0.105
							}
						],
						[
							{
								"h": [
									0.407,
									0.001,
									0.329,
									0.0,
									0.033,
									0.001,
									0.505,
									0.01,
									0.259,
									0.539,
									0.221,
									0.236
								],
								"e": 0.159
							},
							{
								"h": [
									0.01,
									0.017,
									0.126,
									0.02,
									0.14,
									0.115,
									0.211,
									0.191,
									0.111,
									0.129,
									0.155,
									0.001
								],
								"e": 0.133
							},
							{
								"h": [
									0.04,
									0.063,
									0.0,
									0.001,
									0.005,
									0.001,
									0.015,
									0.085,
									0.004,
									0.001,
									0.121,
									0.011
								],
								"e": 0.034
							},
							{
								"h": [
									0.044,
									0.005,
									0.001,
									0.0,
									0.02,
									0.06,
									0.004,
									0.0,
									0.0,
									0.007,
									0.023,
									0.008
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.001,
									0.055,
									0.021,
									0.106,
									0.004,
									0.011,
									0.074,
									0.011,
									0.04,
									0.014,
									0.0
								],
								"e": 0.031
							},
							{
								"h": [
									0.051,
									0.0,
									0.015,
									0.026,
									0.007,
									0.006,
									0.018,
									0.008,
									0.002,
									0.061,
									0.205,
									0.013
								],
								"e": 0.026
							},
							{
								"h": [
									0.083,
									0.001,
									0.027,
									0.007,
									0.13,
									0.026,
									0.008,
									0.034,
									0.022,
									0.004,
									0.005,
									0.037
								],
								"e": 0.026
							},
							{
								"h": [
									0.008,
									0.01,
									0.003,
									0.03,
									0.008,
									0.044,
									0.038,
									0.003,
									0.007,
									0.022,
									0.081,
									0.012
								],
								"e": 0.023
							},
							{
								"h": [
									0.037,
									0.368,
									0.021,
									0.004,
									0.003,
									0.058,
									0.005,
									0.001,
									0.155,
									0.009,
									0.015,
									0.01
								],
								"e": 0.035
							},
							{
								"h": [
									0.02,
									0.028,
									0.013,
									0.03,
									0.001,
									0.068,
									0.004,
									0.066,
									0.014,
									0.005,
									0.002,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.035,
									0.006,
									0.003,
									0.004,
									0.021,
									0.0,
									0.005,
									0.0,
									0.017,
									0.002,
									0.004,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.291,
									0.038,
									0.004,
									0.06,
									0.031,
									0.026,
									0.003,
									0.11,
									0.115,
									0.008,
									0.002,
									0.008
								],
								"e": 0.035
							}
						],
						[
							{
								"h": [
									0.083,
									0.903,
									0.357,
									0.001,
									0.048,
									1.0,
									0.187,
									0.004,
									0.146,
									0.416,
									0.821,
									0.354
								],
								"e": 0.61
							},
							{
								"h": [
									0.001,
									0.008,
									0.201,
									0.029,
									0.16,
									0.433,
									0.363,
									0.074,
									0.142,
									0.107,
									0.223,
									0.296
								],
								"e": 0.264
							},
							{
								"h": [
									0.005,
									0.049,
									0.0,
									0.0,
									0.0,
									0.0,
									0.011,
									0.118,
									0.001,
									0.0,
									0.132,
									0.013
								],
								"e": 0.03
							},
							{
								"h": [
									0.5,
									0.001,
									0.0,
									0.0,
									0.021,
									0.069,
									0.0,
									0.0,
									0.0,
									0.002,
									0.033,
									0.002
								],
								"e": 0.07
							},
							{
								"h": [
									0.0,
									0.001,
									0.024,
									0.003,
									0.047,
									0.001,
									0.005,
									0.235,
									0.01,
									0.007,
									0.008,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.036,
									0.001,
									0.0,
									0.012,
									0.004,
									0.015,
									0.001,
									0.006,
									0.002,
									0.063,
									0.521,
									0.034
								],
								"e": 0.038
							},
							{
								"h": [
									0.038,
									0.002,
									0.011,
									0.01,
									0.119,
									0.055,
									0.031,
									0.019,
									0.004,
									0.002,
									0.01,
									0.016
								],
								"e": 0.024
							},
							{
								"h": [
									0.002,
									0.013,
									0.002,
									0.009,
									0.002,
									0.013,
									0.039,
									0.015,
									0.0,
									0.009,
									0.036,
									0.004
								],
								"e": 0.012
							},
							{
								"h": [
									0.035,
									0.176,
									0.008,
									0.002,
									0.002,
									0.007,
									0.003,
									0.0,
									0.02,
									0.001,
									0.005,
									0.004
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.009,
									0.001,
									0.014,
									0.001,
									0.112,
									0.0,
									0.035,
									0.005,
									0.004,
									0.001,
									0.003
								],
								"e": 0.013
							},
							{
								"h": [
									0.002,
									0.002,
									0.001,
									0.007,
									0.016,
									0.001,
									0.002,
									0.0,
									0.028,
									0.006,
									0.002,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.318,
									0.024,
									0.006,
									0.096,
									0.061,
									0.04,
									0.004,
									0.075,
									0.205,
									0.007,
									0.006,
									0.02
								],
								"e": 0.043
							}
						],
						[
							{
								"h": [
									0.14,
									0.0,
									0.103,
									0.0,
									0.009,
									0.0,
									0.535,
									0.015,
									0.214,
									0.225,
									0.061,
									0.248
								],
								"e": 0.096
							},
							{
								"h": [
									0.005,
									0.02,
									0.202,
									0.03,
									0.177,
									0.062,
									0.14,
									0.409,
									0.093,
									0.04,
									0.226,
									0.001
								],
								"e": 0.152
							},
							{
								"h": [
									0.063,
									0.11,
									0.0,
									0.002,
									0.002,
									0.002,
									0.008,
									0.142,
									0.004,
									0.003,
									0.131,
									0.015
								],
								"e": 0.048
							},
							{
								"h": [
									0.005,
									0.006,
									0.006,
									0.0,
									0.028,
									0.135,
									0.006,
									0.003,
									0.001,
									0.005,
									0.024,
									0.014
								],
								"e": 0.024
							},
							{
								"h": [
									0.001,
									0.003,
									0.023,
									0.024,
									0.002,
									0.004,
									0.043,
									0.056,
									0.007,
									0.014,
									0.002,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.959,
									1.0,
									0.006,
									0.033,
									0.015,
									1.0,
									0.002,
									0.009,
									0.66,
									0.091,
									0.012,
									0.011
								],
								"e": 0.339
							},
							{
								"h": [
									0.033,
									0.11,
									0.017,
									0.007,
									0.034,
									0.091,
									0.015,
									0.139,
									0.015,
									0.305,
									0.027,
									0.023
								],
								"e": 0.078
							},
							{
								"h": [
									0.003,
									0.071,
									0.159,
									0.063,
									0.008,
									0.023,
									0.111,
									0.389,
									0.006,
									0.042,
									0.302,
									0.5
								],
								"e": 0.147
							},
							{
								"h": [
									0.012,
									1.0,
									0.054,
									0.17,
									0.003,
									0.021,
									1.0,
									0.002,
									0.749,
									0.498,
									0.228,
									0.192
								],
								"e": 0.325
							},
							{
								"h": [
									0.884,
									0.158,
									1.0,
									0.103,
									0.057,
									0.132,
									1.0,
									1.0,
									0.602,
									0.855,
									0.003,
									0.151
								],
								"e": 0.473
							},
							{
								"h": [
									1.0,
									0.785,
									0.385,
									0.275,
									0.679,
									0.001,
									0.176,
									1.0,
									0.103,
									0.011,
									0.588,
									0.916
								],
								"e": 0.575
							},
							{
								"h": [
									1.0,
									0.159,
									0.136,
									0.395,
									0.735,
									0.472,
									0.123,
									0.836,
									0.219,
									0.325,
									1.0,
									1.0
								],
								"e": 0.513
							}
						],
						[
							{
								"h": [
									0.494,
									0.0,
									0.185,
									0.0,
									0.03,
									0.0,
									0.092,
									0.025,
									0.548,
									0.452,
									0.213,
									0.216
								],
								"e": 0.144
							},
							{
								"h": [
									0.029,
									0.041,
									0.213,
									0.027,
									0.139,
									0.255,
									0.102,
									0.093,
									0.288,
									0.372,
									0.187,
									0.001
								],
								"e": 0.189
							},
							{
								"h": [
									0.031,
									0.061,
									0.0,
									0.001,
									0.014,
									0.001,
									0.035,
									0.161,
									0.001,
									0.002,
									0.115,
									0.013
								],
								"e": 0.041
							},
							{
								"h": [
									0.013,
									0.013,
									0.002,
									0.0,
									0.011,
									0.032,
									0.026,
									0.0,
									0.0,
									0.005,
									0.017,
									0.004
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.003,
									0.022,
									0.008,
									0.046,
									0.004,
									0.014,
									0.004,
									0.046,
									0.015,
									0.018,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.167,
									0.0,
									0.044,
									0.116,
									0.007,
									0.002,
									0.006,
									0.008,
									0.008,
									0.08,
									0.36,
									0.007
								],
								"e": 0.047
							},
							{
								"h": [
									0.085,
									0.002,
									0.012,
									0.019,
									0.127,
									0.031,
									0.012,
									0.041,
									0.083,
									0.0,
									0.191,
									0.027
								],
								"e": 0.049
							},
							{
								"h": [
									0.001,
									1.0,
									0.0,
									0.034,
									0.011,
									0.017,
									0.015,
									1.0,
									0.001,
									0.128,
									0.006,
									0.0
								],
								"e": 0.142
							},
							{
								"h": [
									0.018,
									0.664,
									0.044,
									0.617,
									0.009,
									0.01,
									0.0,
									0.0,
									0.007,
									0.002,
									0.025,
									0.005
								],
								"e": 0.076
							},
							{
								"h": [
									0.002,
									0.016,
									0.001,
									0.03,
									0.004,
									0.118,
									0.0,
									0.016,
									0.008,
									0.009,
									0.004,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.004,
									0.002,
									0.002,
									0.004,
									0.006,
									0.001,
									0.002,
									0.0,
									0.047,
									0.001,
									0.002,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									0.112,
									0.017,
									0.002,
									0.02,
									0.067,
									0.006,
									0.002,
									0.079,
									0.113,
									0.001,
									0.004,
									0.004
								],
								"e": 0.021
							}
						],
						[
							{
								"h": [
									0.212,
									0.005,
									0.576,
									0.001,
									0.02,
									0.001,
									0.264,
									0.025,
									0.336,
									0.352,
									0.237,
									0.524
								],
								"e": 0.158
							},
							{
								"h": [
									0.009,
									0.024,
									0.236,
									0.033,
									0.165,
									0.189,
									0.449,
									0.125,
									0.12,
									0.078,
									0.27,
									0.035
								],
								"e": 0.187
							},
							{
								"h": [
									0.018,
									0.14,
									0.0,
									0.005,
									0.003,
									0.002,
									0.037,
									0.193,
									0.017,
									0.002,
									0.233,
									0.026
								],
								"e": 0.068
							},
							{
								"h": [
									0.04,
									0.005,
									0.0,
									0.0,
									0.034,
									0.045,
									0.001,
									0.0,
									0.0,
									0.002,
									0.016,
									0.004
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.001,
									0.015,
									0.001,
									0.022,
									0.002,
									0.017,
									0.051,
									0.043,
									0.038,
									0.019,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.038,
									0.0,
									0.002,
									0.038,
									0.004,
									0.019,
									0.002,
									0.016,
									0.006,
									0.044,
									1.0,
									0.009
								],
								"e": 0.056
							},
							{
								"h": [
									0.05,
									0.001,
									0.014,
									0.006,
									0.028,
									0.048,
									0.03,
									0.043,
									0.016,
									0.005,
									0.04,
									0.006
								],
								"e": 0.021
							},
							{
								"h": [
									0.001,
									0.217,
									0.0,
									0.008,
									0.001,
									0.012,
									0.045,
									0.028,
									0.0,
									0.017,
									0.004,
									0.002
								],
								"e": 0.021
							},
							{
								"h": [
									0.013,
									0.076,
									0.011,
									0.086,
									0.002,
									0.015,
									0.001,
									0.001,
									0.046,
									0.008,
									0.048,
									0.005
								],
								"e": 0.02
							},
							{
								"h": [
									0.007,
									0.019,
									0.002,
									0.017,
									0.007,
									0.257,
									0.001,
									0.01,
									0.01,
									0.016,
									0.001,
									0.001
								],
								"e": 0.024
							},
							{
								"h": [
									0.004,
									0.014,
									0.006,
									0.026,
									0.033,
									0.001,
									0.005,
									0.001,
									0.052,
									0.013,
									0.006,
									0.017
								],
								"e": 0.016
							},
							{
								"h": [
									0.46,
									0.048,
									0.02,
									0.063,
									0.097,
									0.067,
									0.012,
									0.183,
									0.913,
									0.052,
									0.104,
									0.107
								],
								"e": 0.11
							}
						],
						[
							{
								"h": [
									0.059,
									0.0,
									0.177,
									0.0,
									0.034,
									0.0,
									0.098,
									0.059,
									0.749,
									0.441,
									0.174,
									0.164
								],
								"e": 0.114
							},
							{
								"h": [
									0.008,
									0.068,
									0.291,
									0.032,
									0.151,
									0.405,
									0.111,
									0.108,
									0.285,
									0.487,
									0.197,
									0.004
								],
								"e": 0.226
							},
							{
								"h": [
									0.051,
									0.034,
									0.0,
									0.017,
									0.031,
									0.005,
									0.047,
									0.139,
									0.025,
									0.001,
									0.108,
									0.017
								],
								"e": 0.047
							},
							{
								"h": [
									0.018,
									0.018,
									0.005,
									0.0,
									0.031,
									0.026,
									0.006,
									0.001,
									0.0,
									0.003,
									0.01,
									0.004
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.001,
									0.012,
									0.001,
									0.012,
									0.001,
									0.01,
									0.003,
									0.013,
									0.004,
									0.024,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.1,
									0.003,
									0.003,
									0.021,
									0.004,
									0.035,
									0.0,
									0.001,
									0.124,
									0.092,
									0.028,
									0.013
								],
								"e": 0.034
							},
							{
								"h": [
									0.014,
									0.003,
									0.017,
									0.004,
									0.013,
									0.015,
									0.011,
									0.053,
									0.009,
									0.008,
									0.021,
									0.007
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.115,
									0.003,
									0.01,
									0.001,
									0.003,
									0.069,
									0.031,
									0.0,
									0.007,
									0.03,
									0.022
								],
								"e": 0.021
							},
							{
								"h": [
									0.033,
									0.581,
									0.033,
									0.079,
									0.001,
									0.004,
									0.006,
									0.001,
									0.139,
									0.093,
									0.044,
									0.006
								],
								"e": 0.052
							},
							{
								"h": [
									0.007,
									0.029,
									0.008,
									0.025,
									0.006,
									0.03,
									0.006,
									0.032,
									0.01,
									0.031,
									0.001,
									0.005
								],
								"e": 0.016
							},
							{
								"h": [
									0.062,
									0.013,
									0.031,
									0.044,
									0.031,
									0.001,
									0.008,
									0.022,
									0.309,
									0.015,
									0.061,
									0.022
								],
								"e": 0.059
							},
							{
								"h": [
									0.784,
									0.087,
									0.039,
									0.101,
									0.242,
									0.035,
									0.019,
									0.271,
									0.749,
									0.013,
									0.072,
									0.145
								],
								"e": 0.132
							}
						],
						[
							{
								"h": [
									0.155,
									0.001,
									0.179,
									0.0,
									0.036,
									0.0,
									0.418,
									0.029,
									0.364,
									0.408,
									0.166,
									0.206
								],
								"e": 0.12
							},
							{
								"h": [
									0.003,
									0.024,
									0.234,
									0.034,
									0.18,
									0.046,
									0.162,
									0.258,
									0.078,
									0.099,
									0.266,
									0.014
								],
								"e": 0.151
							},
							{
								"h": [
									0.034,
									0.049,
									0.0,
									0.006,
									0.007,
									0.002,
									0.016,
									0.181,
									0.047,
									0.008,
									0.162,
									0.021
								],
								"e": 0.049
							},
							{
								"h": [
									0.01,
									0.001,
									0.001,
									0.0,
									0.009,
									0.032,
									0.003,
									0.001,
									0.001,
									0.001,
									0.009,
									0.005
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.002,
									0.009,
									0.001,
									0.001,
									0.001,
									0.024,
									0.007,
									0.021,
									0.015,
									0.003,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.036,
									0.0,
									0.007,
									0.052,
									0.031,
									0.02,
									0.0,
									0.004,
									0.014,
									0.076,
									0.034,
									0.001
								],
								"e": 0.02
							},
							{
								"h": [
									0.018,
									0.005,
									0.03,
									0.004,
									0.017,
									0.018,
									0.003,
									0.128,
									0.009,
									0.009,
									0.006,
									0.032
								],
								"e": 0.02
							},
							{
								"h": [
									0.001,
									0.108,
									0.0,
									0.018,
									0.001,
									0.019,
									0.046,
									0.006,
									0.002,
									0.005,
									0.015,
									0.002
								],
								"e": 0.016
							},
							{
								"h": [
									0.006,
									0.026,
									0.023,
									0.019,
									0.011,
									0.034,
									0.008,
									0.004,
									0.222,
									0.18,
									0.008,
									0.012
								],
								"e": 0.037
							},
							{
								"h": [
									0.004,
									0.016,
									0.145,
									0.046,
									0.002,
									0.015,
									0.081,
									0.034,
									0.052,
									0.04,
									0.002,
									0.001
								],
								"e": 0.033
							},
							{
								"h": [
									0.032,
									0.028,
									0.008,
									0.28,
									0.047,
									0.001,
									0.157,
									0.001,
									0.014,
									0.005,
									0.011,
									0.317
								],
								"e": 0.081
							},
							{
								"h": [
									0.121,
									0.068,
									0.016,
									0.144,
									0.038,
									0.067,
									0.012,
									0.903,
									0.219,
									0.016,
									0.004,
									0.062
								],
								"e": 0.096
							}
						],
						[
							{
								"h": [
									0.066,
									0.0,
									0.387,
									0.0,
									0.093,
									0.0,
									0.079,
									0.071,
									0.594,
									0.503,
									0.205,
									0.183
								],
								"e": 0.13
							},
							{
								"h": [
									0.021,
									0.07,
									0.355,
									0.035,
									0.164,
									0.35,
									0.18,
									0.223,
									0.17,
									0.144,
									0.219,
									0.022
								],
								"e": 0.206
							},
							{
								"h": [
									0.185,
									0.075,
									0.001,
									0.013,
									0.02,
									0.005,
									0.04,
									0.129,
									0.082,
									0.002,
									0.093,
									0.017
								],
								"e": 0.066
							},
							{
								"h": [
									0.019,
									0.003,
									0.003,
									0.0,
									0.014,
									0.018,
									0.001,
									0.002,
									0.003,
									0.001,
									0.004,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.003,
									0.007,
									0.001,
									0.004,
									0.001,
									0.028,
									0.003,
									0.023,
									0.012,
									0.009,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.0,
									0.008,
									0.013,
									0.009,
									0.0,
									0.001,
									0.005,
									0.001,
									0.037,
									0.017,
									0.006
								],
								"e": 0.008
							},
							{
								"h": [
									0.007,
									0.002,
									0.013,
									0.002,
									0.004,
									0.015,
									0.001,
									0.024,
									0.009,
									0.0,
									0.007,
									0.008
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.018,
									0.0,
									0.01,
									0.0,
									0.007,
									0.017,
									0.039,
									0.0,
									0.002,
									0.0,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.006,
									0.026,
									0.016,
									0.028,
									0.003,
									0.003,
									0.003,
									0.0,
									0.06,
									0.053,
									0.006,
									0.007
								],
								"e": 0.015
							},
							{
								"h": [
									0.003,
									0.007,
									0.022,
									0.017,
									0.0,
									0.006,
									0.0,
									0.003,
									0.015,
									0.001,
									0.002,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.028,
									0.008,
									0.003,
									0.008,
									0.014,
									0.001,
									0.002,
									0.008,
									0.035,
									0.028,
									0.007,
									0.011
								],
								"e": 0.015
							},
							{
								"h": [
									0.482,
									0.013,
									0.026,
									0.101,
									0.215,
									0.019,
									0.007,
									0.127,
									0.196,
									0.006,
									0.103,
									0.146
								],
								"e": 0.083
							}
						],
						[
							{
								"h": [
									0.238,
									0.001,
									0.256,
									0.001,
									0.072,
									0.0,
									0.62,
									0.045,
									0.344,
									0.511,
									0.18,
									0.391
								],
								"e": 0.166
							},
							{
								"h": [
									0.029,
									0.065,
									0.346,
									0.042,
									0.224,
									0.158,
									0.143,
									0.24,
									0.159,
									0.112,
									0.388,
									0.008
								],
								"e": 0.201
							},
							{
								"h": [
									0.022,
									0.201,
									0.001,
									0.029,
									0.006,
									0.013,
									0.023,
									0.228,
									0.024,
									0.01,
									0.215,
									0.032
								],
								"e": 0.089
							},
							{
								"h": [
									0.036,
									0.022,
									0.002,
									0.0,
									0.015,
									0.039,
									0.001,
									0.004,
									0.001,
									0.006,
									0.03,
									0.029
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.008,
									0.004,
									0.001,
									0.008,
									0.001,
									0.049,
									0.004,
									0.026,
									0.004,
									0.004,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.002,
									0.0,
									0.003,
									0.02,
									0.011,
									0.002,
									0.0,
									0.003,
									0.004,
									0.052,
									0.038,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.004,
									0.003,
									0.017,
									0.003,
									0.023,
									0.052,
									0.001,
									0.184,
									0.01,
									0.0,
									0.01,
									0.008
								],
								"e": 0.022
							},
							{
								"h": [
									0.001,
									0.037,
									0.0,
									0.017,
									0.002,
									0.013,
									0.033,
									0.063,
									0.002,
									0.005,
									0.002,
									0.003
								],
								"e": 0.013
							},
							{
								"h": [
									0.039,
									0.166,
									0.042,
									0.056,
									0.002,
									0.01,
									0.078,
									0.003,
									1.0,
									0.591,
									0.017,
									0.027
								],
								"e": 0.128
							},
							{
								"h": [
									0.347,
									0.049,
									0.886,
									0.048,
									0.002,
									0.027,
									0.175,
									0.135,
									0.206,
									0.047,
									0.001,
									0.003
								],
								"e": 0.143
							},
							{
								"h": [
									0.129,
									0.172,
									0.019,
									0.047,
									1.0,
									0.001,
									0.051,
									0.013,
									0.014,
									0.01,
									0.022,
									1.0
								],
								"e": 0.189
							},
							{
								"h": [
									0.328,
									0.046,
									0.018,
									0.115,
									0.038,
									0.189,
									0.015,
									0.34,
									0.017,
									0.119,
									0.064,
									0.198
								],
								"e": 0.108
							}
						],
						[
							{
								"h": [
									0.08,
									0.0,
									0.476,
									0.0,
									0.071,
									0.0,
									0.099,
									0.444,
									1.0,
									0.673,
									0.255,
									0.367
								],
								"e": 0.219
							},
							{
								"h": [
									0.059,
									0.895,
									0.624,
									0.083,
									0.277,
									0.999,
									0.254,
									0.298,
									0.407,
									0.134,
									0.488,
									0.196
								],
								"e": 0.536
							},
							{
								"h": [
									0.411,
									0.078,
									0.006,
									0.034,
									0.481,
									0.219,
									1.0,
									0.384,
									0.385,
									0.072,
									0.386,
									0.061
								],
								"e": 0.406
							},
							{
								"h": [
									0.055,
									0.744,
									0.014,
									0.027,
									0.062,
									0.055,
									0.054,
									0.053,
									0.067,
									0.121,
									0.076,
									0.103
								],
								"e": 0.131
							},
							{
								"h": [
									0.022,
									0.044,
									0.094,
									0.019,
									0.069,
									0.105,
									0.055,
									0.034,
									0.03,
									0.021,
									0.027,
									0.0
								],
								"e": 0.052
							},
							{
								"h": [
									0.121,
									0.0,
									0.004,
									0.198,
									0.087,
									0.019,
									0.001,
									0.014,
									0.053,
									0.065,
									0.135,
									0.01
								],
								"e": 0.047
							},
							{
								"h": [
									0.03,
									0.026,
									0.084,
									0.015,
									0.124,
									0.122,
									0.016,
									0.268,
									0.073,
									0.003,
									0.074,
									0.043
								],
								"e": 0.067
							},
							{
								"h": [
									0.004,
									0.589,
									0.0,
									0.096,
									0.005,
									0.087,
									0.044,
									0.324,
									0.016,
									0.111,
									0.005,
									0.002
								],
								"e": 0.085
							},
							{
								"h": [
									0.03,
									0.019,
									0.097,
									0.446,
									0.007,
									0.048,
									0.003,
									0.017,
									0.602,
									0.069,
									0.048,
									0.029
								],
								"e": 0.095
							},
							{
								"h": [
									0.026,
									0.009,
									0.009,
									0.193,
									0.013,
									0.004,
									0.003,
									0.039,
									0.042,
									0.006,
									0.003,
									0.004
								],
								"e": 0.024
							},
							{
								"h": [
									0.087,
									0.023,
									0.008,
									0.031,
									0.015,
									0.002,
									0.022,
									0.007,
									0.034,
									0.092,
									0.006,
									0.028
								],
								"e": 0.035
							},
							{
								"h": [
									0.298,
									0.049,
									0.026,
									0.299,
									0.172,
									0.043,
									0.011,
									0.052,
									0.411,
									0.014,
									0.192,
									0.199
								],
								"e": 0.115
							}
						],
						[
							{
								"h": [
									0.249,
									0.0,
									0.318,
									0.002,
									0.373,
									0.0,
									0.473,
									0.25,
									0.248,
									0.645,
									0.167,
									0.317
								],
								"e": 0.21
							},
							{
								"h": [
									0.069,
									0.304,
									0.446,
									0.059,
									0.24,
									0.171,
									0.355,
									0.205,
									0.346,
									0.271,
									0.46,
									0.003
								],
								"e": 0.322
							},
							{
								"h": [
									0.303,
									0.04,
									0.004,
									0.043,
									0.087,
									0.041,
									0.257,
									0.416,
									0.16,
									0.09,
									0.375,
									0.055
								],
								"e": 0.193
							},
							{
								"h": [
									0.003,
									0.105,
									0.047,
									0.009,
									0.016,
									0.089,
									0.305,
									0.028,
									0.003,
									0.03,
									0.025,
									0.265
								],
								"e": 0.082
							},
							{
								"h": [
									0.027,
									0.032,
									0.024,
									0.11,
									0.004,
									0.092,
									0.094,
									0.034,
									0.003,
									0.065,
									0.004,
									0.0
								],
								"e": 0.045
							},
							{
								"h": [
									0.005,
									0.0,
									0.03,
									0.155,
									0.042,
									0.029,
									0.006,
									0.008,
									0.015,
									0.061,
									0.041,
									0.012
								],
								"e": 0.03
							},
							{
								"h": [
									0.146,
									0.001,
									0.047,
									0.009,
									0.019,
									0.104,
									0.005,
									0.069,
									0.111,
									0.001,
									0.002,
									0.112
								],
								"e": 0.038
							},
							{
								"h": [
									0.059,
									0.012,
									0.001,
									0.08,
									0.013,
									0.03,
									0.025,
									0.001,
									0.019,
									0.165,
									0.002,
									0.0
								],
								"e": 0.03
							},
							{
								"h": [
									0.003,
									0.038,
									0.012,
									0.008,
									0.008,
									0.231,
									0.0,
									0.008,
									0.014,
									0.005,
									0.044,
									0.008
								],
								"e": 0.024
							},
							{
								"h": [
									0.005,
									0.018,
									0.001,
									0.185,
									0.001,
									0.05,
									0.001,
									0.03,
									0.025,
									0.003,
									0.012,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.005,
									0.015,
									0.002,
									0.004,
									0.004,
									0.002,
									0.002,
									0.001,
									0.038,
									0.008,
									0.002,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.409,
									0.029,
									0.005,
									0.136,
									0.072,
									0.011,
									0.003,
									0.078,
									0.679,
									0.003,
									0.024,
									0.019
								],
								"e": 0.063
							}
						],
						[
							{
								"h": [
									0.201,
									0.001,
									0.855,
									0.002,
									0.361,
									0.0,
									0.327,
									0.359,
									0.205,
									0.453,
									0.16,
									0.29
								],
								"e": 0.221
							},
							{
								"h": [
									0.135,
									0.258,
									0.489,
									0.062,
									0.317,
									0.2,
									0.082,
									0.218,
									0.186,
									0.129,
									0.552,
									0.008
								],
								"e": 0.29
							},
							{
								"h": [
									0.234,
									0.05,
									0.012,
									0.164,
									0.078,
									0.115,
									0.078,
									0.518,
									0.274,
									0.181,
									0.528,
									0.064
								],
								"e": 0.251
							},
							{
								"h": [
									0.016,
									0.067,
									0.036,
									0.016,
									0.02,
									0.199,
									0.174,
									0.228,
									0.14,
									0.304,
									0.045,
									1.0
								],
								"e": 0.178
							},
							{
								"h": [
									0.005,
									0.198,
									0.105,
									0.012,
									0.019,
									0.031,
									0.125,
									0.032,
									0.006,
									0.102,
									0.011,
									0.0
								],
								"e": 0.062
							},
							{
								"h": [
									0.0,
									0.0,
									0.021,
									0.346,
									0.156,
									0.0,
									0.002,
									0.005,
									0.0,
									0.024,
									0.027,
									0.002
								],
								"e": 0.037
							},
							{
								"h": [
									0.272,
									0.0,
									0.046,
									0.006,
									0.039,
									0.166,
									0.048,
									0.008,
									0.433,
									0.0,
									0.005,
									0.135
								],
								"e": 0.067
							},
							{
								"h": [
									0.115,
									0.045,
									0.0,
									0.044,
									0.011,
									0.118,
									0.016,
									0.002,
									0.002,
									0.155,
									0.0,
									0.0
								],
								"e": 0.039
							},
							{
								"h": [
									0.015,
									0.002,
									0.006,
									0.019,
									0.007,
									0.422,
									0.0,
									0.009,
									0.015,
									0.005,
									0.033,
									0.012
								],
								"e": 0.033
							},
							{
								"h": [
									0.004,
									0.006,
									0.003,
									0.297,
									0.0,
									0.028,
									0.001,
									0.002,
									0.008,
									0.003,
									0.014,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.004,
									0.003,
									0.001,
									0.004,
									0.002,
									0.002,
									0.001,
									0.0,
									0.002,
									0.02,
									0.002,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.126,
									0.003,
									0.003,
									0.091,
									0.011,
									0.007,
									0.001,
									0.022,
									0.763,
									0.007,
									0.012,
									0.024
								],
								"e": 0.042
							}
						],
						[
							{
								"h": [
									0.154,
									0.001,
									0.811,
									0.003,
									0.573,
									0.0,
									0.174,
									1.0,
									0.595,
									0.599,
									0.274,
									0.335
								],
								"e": 0.334
							},
							{
								"h": [
									0.515,
									1.0,
									1.0,
									0.095,
									0.378,
									0.652,
									0.148,
									0.15,
									0.322,
									0.312,
									0.704,
									0.012
								],
								"e": 0.617
							},
							{
								"h": [
									1.0,
									0.024,
									0.074,
									1.0,
									0.676,
									1.0,
									0.159,
									0.67,
									1.0,
									0.083,
									0.592,
									0.077
								],
								"e": 0.799
							},
							{
								"h": [
									0.027,
									0.192,
									0.28,
									0.088,
									0.027,
									0.107,
									0.067,
									0.277,
									1.0,
									0.303,
									0.211,
									0.744
								],
								"e": 0.312
							},
							{
								"h": [
									0.077,
									1.0,
									0.112,
									0.085,
									0.129,
									0.069,
									0.146,
									0.095,
									0.012,
									0.145,
									0.017,
									0.0
								],
								"e": 0.182
							},
							{
								"h": [
									0.001,
									0.0,
									0.118,
									1.0,
									0.525,
									0.003,
									0.008,
									0.083,
									0.016,
									0.128,
									0.058,
									0.026
								],
								"e": 0.137
							},
							{
								"h": [
									0.824,
									0.001,
									0.138,
									0.009,
									0.038,
									0.196,
									0.051,
									0.105,
									1.0,
									0.005,
									0.02,
									1.0
								],
								"e": 0.19
							},
							{
								"h": [
									0.172,
									0.023,
									0.001,
									0.104,
									0.039,
									0.041,
									0.08,
									0.006,
									0.022,
									0.864,
									0.008,
									0.009
								],
								"e": 0.087
							},
							{
								"h": [
									0.046,
									0.213,
									0.021,
									0.013,
									0.023,
									0.865,
									0.0,
									0.041,
									0.021,
									0.002,
									0.075,
									0.006
								],
								"e": 0.079
							},
							{
								"h": [
									0.002,
									0.006,
									0.001,
									0.497,
									0.0,
									0.052,
									0.0,
									0.004,
									0.005,
									0.001,
									0.032,
									0.001
								],
								"e": 0.036
							},
							{
								"h": [
									0.002,
									0.001,
									0.001,
									0.003,
									0.002,
									0.003,
									0.0,
									0.0,
									0.012,
									0.031,
									0.001,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.244,
									0.005,
									0.003,
									0.191,
									0.015,
									0.019,
									0.002,
									0.017,
									1.0,
									0.002,
									0.02,
									0.027
								],
								"e": 0.062
							}
						],
						[
							{
								"h": [
									0.658,
									0.001,
									0.381,
									0.007,
									0.592,
									0.0,
									0.68,
									0.803,
									0.409,
									0.632,
									0.24,
									0.183
								],
								"e": 0.349
							},
							{
								"h": [
									1.0,
									0.652,
									0.736,
									0.078,
									0.428,
									0.21,
									0.197,
									0.209,
									0.203,
									0.254,
									0.718,
									0.005
								],
								"e": 0.629
							},
							{
								"h": [
									0.893,
									0.071,
									1.0,
									0.221,
									1.0,
									0.336,
									0.135,
									0.975,
									0.599,
									1.0,
									0.769,
									0.106
								],
								"e": 1.0
							},
							{
								"h": [
									0.009,
									0.431,
									1.0,
									0.012,
									0.034,
									0.112,
									1.0,
									1.0,
									0.244,
									0.385,
									0.22,
									0.671
								],
								"e": 0.457
							},
							{
								"h": [
									0.044,
									0.063,
									0.094,
									1.0,
									0.11,
									0.211,
									0.161,
									0.299,
									0.01,
									0.229,
									0.009,
									1.0
								],
								"e": 0.357
							},
							{
								"h": [
									0.14,
									0.001,
									0.446,
									0.395,
									0.241,
									0.011,
									0.2,
									0.092,
									0.029,
									0.07,
									0.201,
									0.026
								],
								"e": 0.155
							},
							{
								"h": [
									1.0,
									0.007,
									0.04,
									0.058,
									0.162,
									0.165,
									0.033,
									0.111,
									0.379,
									0.004,
									0.033,
									0.311
								],
								"e": 0.128
							},
							{
								"h": [
									0.1,
									0.018,
									0.003,
									0.264,
									0.033,
									0.053,
									0.062,
									0.002,
									0.032,
									0.529,
									0.054,
									0.015
								],
								"e": 0.081
							},
							{
								"h": [
									0.055,
									0.14,
									0.014,
									0.003,
									0.013,
									0.592,
									0.001,
									0.01,
									0.049,
									0.007,
									0.063,
									0.003
								],
								"e": 0.056
							},
							{
								"h": [
									0.032,
									0.017,
									0.028,
									0.317,
									0.0,
									0.192,
									0.003,
									0.042,
									0.019,
									0.005,
									0.02,
									0.0
								],
								"e": 0.043
							},
							{
								"h": [
									0.018,
									0.004,
									0.002,
									0.002,
									0.011,
									0.002,
									0.003,
									0.0,
									0.009,
									0.005,
									0.001,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.108,
									0.017,
									0.001,
									0.023,
									0.014,
									0.005,
									0.001,
									0.051,
									0.146,
									0.001,
									0.002,
									0.003
								],
								"e": 0.016
							}
						],
						[
							{
								"h": [
									0.112,
									1.0,
									0.398,
									1.0,
									1.0,
									0.377,
									0.222,
									0.389,
									0.22,
									0.515,
									1.0,
									0.318
								],
								"e": 1.0
							},
							{
								"h": [
									0.133,
									0.353,
									0.951,
									0.106,
									0.491,
									0.666,
									0.273,
									0.082,
									0.238,
									0.168,
									1.0,
									1.0
								],
								"e": 0.754
							},
							{
								"h": [
									0.166,
									0.049,
									0.142,
									0.094,
									0.149,
									0.148,
									0.102,
									1.0,
									0.874,
									0.119,
									1.0,
									0.134
								],
								"e": 0.398
							},
							{
								"h": [
									0.128,
									0.083,
									0.472,
									0.089,
									0.037,
									0.121,
									0.174,
									0.792,
									0.162,
									0.195,
									0.398,
									0.27
								],
								"e": 0.27
							},
							{
								"h": [
									0.12,
									0.117,
									0.052,
									0.161,
									0.124,
									0.066,
									0.086,
									1.0,
									0.009,
									0.08,
									0.005,
									0.0
								],
								"e": 0.149
							},
							{
								"h": [
									0.121,
									0.001,
									0.027,
									0.157,
									0.206,
									0.017,
									0.035,
									0.089,
									0.035,
									0.117,
									0.791,
									0.097
								],
								"e": 0.112
							},
							{
								"h": [
									0.482,
									0.01,
									0.016,
									0.035,
									0.097,
									0.228,
									0.167,
									0.03,
									0.11,
									0.002,
									0.033,
									0.106
								],
								"e": 0.084
							},
							{
								"h": [
									0.034,
									0.039,
									0.001,
									0.15,
									0.01,
									0.021,
									0.1,
									0.017,
									0.003,
									0.125,
									0.021,
									0.011
								],
								"e": 0.039
							},
							{
								"h": [
									0.091,
									0.149,
									0.005,
									0.064,
									0.008,
									0.042,
									0.003,
									0.016,
									0.016,
									0.026,
									0.187,
									0.005
								],
								"e": 0.047
							},
							{
								"h": [
									0.038,
									0.02,
									0.056,
									0.327,
									0.003,
									0.451,
									0.002,
									0.018,
									0.025,
									0.027,
									0.012,
									0.002
								],
								"e": 0.063
							},
							{
								"h": [
									0.022,
									0.014,
									0.009,
									0.026,
									0.079,
									0.006,
									0.014,
									0.003,
									0.045,
									0.149,
									0.014,
									0.031
								],
								"e": 0.041
							},
							{
								"h": [
									0.4,
									0.075,
									0.013,
									0.154,
									0.103,
									0.093,
									0.01,
									0.299,
									0.805,
									0.025,
									0.072,
									0.209
								],
								"e": 0.122
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " plasma",
					"o": " they",
					"t": " they",
					"r": [
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.997,
								" conduit": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.961,
								" conduit": 0.008,
								" slic": 0.002,
								" membrane": 0.002,
								" spl": 0.001,
								" oven": 0.001,
								" vessels": 0.001,
								" shields": 0.001,
								" Plasma": 0.001,
								" hail": 0.001
							}
						},
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.596,
								" membrane": 0.1,
								" conduit": 0.043,
								" oven": 0.012,
								" vessels": 0.008,
								" velocity": 0.007,
								" tubes": 0.007,
								" chambers": 0.007,
								" tubing": 0.007,
								" slic": 0.006
							}
						},
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.35,
								" membrane": 0.153,
								" conduit": 0.065,
								" oven": 0.028,
								" tubes": 0.01,
								" vessels": 0.009,
								" chambers": 0.008,
								" slic": 0.007,
								" pens": 0.007,
								" spl": 0.006
							}
						},
						{
							"p": " plasma",
							"b": 0.0,
							"k": {
								" plasma": 0.245,
								" membrane": 0.208,
								" conduit": 0.03,
								" pens": 0.025,
								" particles": 0.012,
								" oven": 0.012,
								" pulse": 0.012,
								" hots": 0.01,
								" lip": 0.009,
								" vessels": 0.008
							}
						},
						{
							"p": " is",
							"b": 0.001,
							"k": {
								" is": 0.156,
								" membrane": 0.048,
								",": 0.045,
								" itself": 0.036,
								" and": 0.03,
								" was": 0.023,
								" waste": 0.02,
								" or": 0.015,
								" above": 0.015,
								"'s": 0.012
							}
						},
						{
							"p": " is",
							"b": 0.001,
							"k": {
								" is": 0.195,
								",": 0.057,
								" waste": 0.056,
								" and": 0.03,
								" membrane": 0.024,
								"'s": 0.023,
								" was": 0.02,
								" particles": 0.018,
								" from": 0.013,
								" or": 0.011
							}
						},
						{
							"p": " is",
							"b": 0.014,
							"k": {
								" is": 0.438,
								",": 0.097,
								" means": 0.04,
								" are": 0.026,
								" represents": 0.018,
								" we": 0.017,
								" because": 0.017,
								" membrane": 0.016,
								" they": 0.014,
								" and": 0.013
							}
						},
						{
							"p": " they",
							"b": 0.553,
							"k": {
								" they": 0.553,
								" we": 0.126,
								" is": 0.086,
								" you": 0.084,
								",": 0.049,
								" are": 0.015,
								" means": 0.008,
								" I": 0.007,
								" represents": 0.007,
								" it": 0.004
							}
						},
						{
							"p": " they",
							"b": 0.978,
							"k": {
								" they": 0.978,
								" we": 0.016,
								" you": 0.004,
								" it": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " they",
							"b": 0.995,
							"k": {
								" they": 0.995,
								" we": 0.003,
								" it": 0.001,
								" you": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " they",
							"b": 0.688,
							"k": {
								" they": 0.688,
								",": 0.066,
								" you": 0.048,
								" it": 0.03,
								" we": 0.027,
								" the": 0.016,
								" I": 0.01,
								" is": 0.009,
								" a": 0.005,
								" are": 0.005
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.0,
									1.0,
									0.0,
									0.017,
									0.0,
									1.0,
									0.053,
									0.745,
									1.0,
									0.251,
									0.942
								],
								"e": 0.226
							},
							{
								"h": [
									0.056,
									0.167,
									0.337,
									1.0,
									1.0,
									0.525,
									1.0,
									0.628,
									1.0,
									1.0,
									0.016,
									0.023
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.501,
									0.313,
									0.168,
									1.0,
									0.097,
									0.59,
									0.587,
									0.926,
									0.247,
									0.381,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.611,
									1.0,
									0.083,
									1.0,
									1.0,
									1.0,
									0.424,
									0.877,
									0.093,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.486,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.133,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.099,
									0.241,
									1.0,
									1.0,
									1.0,
									0.079,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.231,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.718,
									1.0,
									1.0,
									0.914
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.067,
									1.0,
									1.0,
									1.0,
									0.487,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.152,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.411,
									0.393,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.325,
									1.0,
									1.0,
									1.0,
									1.0,
									0.974,
									1.0,
									0.216,
									0.0,
									1.0,
									0.959,
									0.471
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.351,
									0.0,
									0.563,
									0.0,
									0.001,
									0.0,
									0.312,
									0.015,
									0.404,
									0.503,
									0.137,
									0.804
								],
								"e": 0.109
							},
							{
								"h": [
									0.007,
									0.021,
									0.159,
									0.023,
									0.178,
									0.144,
									0.1,
									0.079,
									0.276,
									0.166,
									0.186,
									0.002
								],
								"e": 0.149
							},
							{
								"h": [
									0.065,
									0.01,
									0.0,
									0.001,
									0.001,
									0.001,
									0.021,
									0.073,
									0.004,
									0.001,
									0.112,
									0.011
								],
								"e": 0.025
							},
							{
								"h": [
									0.003,
									0.003,
									0.0,
									0.0,
									0.018,
									0.028,
									0.0,
									0.0,
									0.0,
									0.001,
									0.005,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.001,
									0.021,
									0.001,
									0.018,
									0.0,
									0.004,
									0.006,
									0.001,
									0.005,
									0.004,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.071,
									0.002,
									0.0,
									0.0,
									0.0,
									0.002,
									0.012,
									0.005,
									0.006
								],
								"e": 0.006
							},
							{
								"h": [
									0.004,
									0.003,
									0.059,
									0.008,
									0.003,
									0.017,
									0.021,
									0.038,
									0.005,
									0.011,
									0.004,
									0.067
								],
								"e": 0.018
							},
							{
								"h": [
									0.005,
									0.013,
									0.008,
									0.011,
									0.065,
									0.036,
									0.021,
									0.04,
									0.003,
									0.052,
									0.055,
									0.004
								],
								"e": 0.022
							},
							{
								"h": [
									0.045,
									0.029,
									0.009,
									0.023,
									0.009,
									0.066,
									0.062,
									0.004,
									0.096,
									0.004,
									0.032,
									0.007
								],
								"e": 0.029
							},
							{
								"h": [
									0.008,
									0.213,
									0.043,
									0.022,
									0.03,
									0.007,
									0.006,
									0.056,
									0.003,
									0.031,
									0.003,
									0.029
								],
								"e": 0.031
							},
							{
								"h": [
									0.013,
									0.034,
									0.026,
									0.198,
									0.142,
									0.037,
									0.016,
									0.128,
									0.118,
									0.02,
									0.013,
									0.035
								],
								"e": 0.057
							},
							{
								"h": [
									1.0,
									0.057,
									0.035,
									0.195,
									0.123,
									0.461,
									0.02,
									0.258,
									0.103,
									0.077,
									0.471,
									0.378
								],
								"e": 0.197
							}
						],
						[
							{
								"h": [
									0.339,
									0.0,
									0.679,
									0.0,
									0.001,
									0.0,
									0.557,
									0.015,
									0.314,
									0.628,
									0.128,
									0.45
								],
								"e": 0.115
							},
							{
								"h": [
									0.011,
									0.016,
									0.134,
									0.022,
									0.121,
									0.074,
									0.109,
									0.499,
									0.158,
									0.103,
									0.189,
									0.001
								],
								"e": 0.151
							},
							{
								"h": [
									0.017,
									0.065,
									0.0,
									0.001,
									0.001,
									0.001,
									0.016,
									0.13,
									0.003,
									0.003,
									0.152,
									0.013
								],
								"e": 0.032
							},
							{
								"h": [
									0.001,
									0.007,
									0.0,
									0.0,
									0.065,
									0.052,
									0.003,
									0.0,
									0.0,
									0.002,
									0.01,
									0.001
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.0,
									0.044,
									0.004,
									0.005,
									0.006,
									0.019,
									0.052,
									0.001,
									0.005,
									0.021,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.045,
									0.005,
									0.0,
									0.002,
									0.001,
									0.001,
									0.03,
									0.003,
									0.006
								],
								"e": 0.006
							},
							{
								"h": [
									0.013,
									0.001,
									0.049,
									0.014,
									0.041,
									0.043,
									0.01,
									0.041,
									0.003,
									0.005,
									0.004,
									0.021
								],
								"e": 0.019
							},
							{
								"h": [
									0.003,
									0.002,
									0.005,
									0.017,
									0.013,
									0.016,
									0.027,
									0.01,
									0.007,
									0.025,
									0.019,
									0.001
								],
								"e": 0.011
							},
							{
								"h": [
									0.022,
									0.007,
									0.008,
									0.006,
									0.001,
									0.022,
									0.043,
									0.005,
									0.06,
									0.009,
									0.021,
									0.007
								],
								"e": 0.016
							},
							{
								"h": [
									0.024,
									0.092,
									0.031,
									0.022,
									0.022,
									0.025,
									0.013,
									0.257,
									0.003,
									0.021,
									0.001,
									0.004
								],
								"e": 0.032
							},
							{
								"h": [
									0.022,
									0.03,
									0.056,
									0.041,
									0.106,
									0.012,
									0.034,
									0.091,
									0.025,
									0.059,
									0.024,
									0.072
								],
								"e": 0.046
							},
							{
								"h": [
									0.327,
									0.38,
									0.014,
									0.187,
									0.084,
									0.114,
									0.007,
									0.835,
									0.112,
									0.031,
									0.25,
									0.075
								],
								"e": 0.163
							}
						],
						[
							{
								"h": [
									0.313,
									0.0,
									0.238,
									0.0,
									0.001,
									0.0,
									0.221,
									0.028,
									0.225,
									0.325,
									0.081,
									0.701
								],
								"e": 0.074
							},
							{
								"h": [
									0.013,
									0.025,
									0.199,
									0.032,
									0.142,
									0.088,
									0.071,
									0.121,
									0.15,
									0.105,
									0.251,
									0.0
								],
								"e": 0.127
							},
							{
								"h": [
									0.022,
									0.046,
									0.0,
									0.002,
									0.0,
									0.001,
									0.015,
									0.16,
									0.003,
									0.001,
									0.141,
									0.012
								],
								"e": 0.031
							},
							{
								"h": [
									0.002,
									0.007,
									0.0,
									0.0,
									0.021,
									0.043,
									0.0,
									0.0,
									0.001,
									0.001,
									0.015,
									0.007
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.01,
									0.094,
									0.003,
									0.004,
									0.007,
									0.01,
									0.038,
									0.0,
									0.008,
									0.008,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.076,
									0.019,
									0.0,
									0.014,
									0.005,
									0.001,
									0.022,
									0.001,
									0.004
								],
								"e": 0.009
							},
							{
								"h": [
									0.056,
									0.002,
									0.034,
									0.02,
									0.031,
									0.191,
									0.009,
									0.048,
									0.019,
									0.002,
									0.007,
									0.062
								],
								"e": 0.032
							},
							{
								"h": [
									0.065,
									0.003,
									0.003,
									0.024,
									0.056,
									0.033,
									0.027,
									0.021,
									0.004,
									0.145,
									0.01,
									0.001
								],
								"e": 0.024
							},
							{
								"h": [
									0.025,
									0.006,
									0.025,
									0.001,
									0.036,
									0.029,
									0.217,
									0.017,
									0.114,
									0.004,
									0.013,
									0.007
								],
								"e": 0.032
							},
							{
								"h": [
									0.045,
									0.098,
									0.019,
									0.081,
									0.008,
									0.099,
									0.004,
									0.271,
									0.003,
									0.019,
									0.017,
									0.002
								],
								"e": 0.042
							},
							{
								"h": [
									0.007,
									0.004,
									0.015,
									0.054,
									0.333,
									0.072,
									0.039,
									0.099,
									0.004,
									0.091,
									0.004,
									0.064
								],
								"e": 0.049
							},
							{
								"h": [
									0.696,
									0.064,
									0.024,
									0.411,
									0.039,
									0.291,
									0.005,
									0.282,
									0.36,
									0.047,
									0.292,
									0.206
								],
								"e": 0.172
							}
						],
						[
							{
								"h": [
									0.559,
									0.926,
									0.158,
									0.001,
									0.055,
									1.0,
									0.76,
									0.008,
									0.742,
									0.073,
									0.925,
									0.48
								],
								"e": 0.46
							},
							{
								"h": [
									0.051,
									0.013,
									0.205,
									0.028,
									0.166,
									0.325,
									0.529,
									0.187,
									0.083,
									0.053,
									0.257,
									0.85
								],
								"e": 0.439
							},
							{
								"h": [
									0.018,
									0.425,
									0.0,
									0.002,
									0.001,
									0.0,
									0.007,
									0.154,
									0.002,
									0.002,
									0.161,
									0.014
								],
								"e": 0.077
							},
							{
								"h": [
									1.0,
									0.002,
									0.001,
									0.0,
									0.03,
									0.102,
									0.014,
									0.0,
									0.0,
									0.005,
									0.052,
									0.004
								],
								"e": 0.136
							},
							{
								"h": [
									0.003,
									0.003,
									0.023,
									0.025,
									0.056,
									0.01,
									0.008,
									0.461,
									0.013,
									0.01,
									0.031,
									0.0
								],
								"e": 0.05
							},
							{
								"h": [
									0.009,
									0.001,
									0.003,
									0.032,
									0.005,
									0.001,
									0.005,
									0.014,
									0.005,
									0.146,
									0.227,
									0.011
								],
								"e": 0.035
							},
							{
								"h": [
									0.013,
									0.022,
									0.021,
									0.011,
									0.056,
									0.068,
									0.073,
									0.112,
									0.002,
									0.005,
									0.018,
									0.013
								],
								"e": 0.035
							},
							{
								"h": [
									0.001,
									0.058,
									0.029,
									0.037,
									0.018,
									0.03,
									0.026,
									0.208,
									0.007,
									0.009,
									0.013,
									0.016
								],
								"e": 0.027
							},
							{
								"h": [
									0.014,
									0.004,
									0.016,
									0.016,
									0.002,
									0.01,
									0.196,
									0.002,
									0.118,
									0.078,
									0.011,
									0.004
								],
								"e": 0.034
							},
							{
								"h": [
									0.048,
									0.004,
									0.049,
									0.029,
									0.013,
									0.014,
									0.026,
									0.054,
									0.024,
									0.029,
									0.002,
									0.029
								],
								"e": 0.027
							},
							{
								"h": [
									0.118,
									0.186,
									0.084,
									0.067,
									0.095,
									0.016,
									0.077,
									0.103,
									0.011,
									0.027,
									0.05,
									0.239
								],
								"e": 0.099
							},
							{
								"h": [
									0.36,
									0.059,
									0.154,
									0.422,
									0.152,
									0.137,
									0.095,
									0.119,
									0.035,
									0.124,
									0.096,
									0.251
								],
								"e": 0.173
							}
						],
						[
							{
								"h": [
									0.124,
									0.0,
									0.346,
									0.0,
									0.001,
									0.0,
									0.319,
									0.015,
									0.224,
									0.342,
									0.112,
									0.526
								],
								"e": 0.072
							},
							{
								"h": [
									0.027,
									0.041,
									0.229,
									0.028,
									0.116,
									0.124,
									0.141,
									0.191,
									0.312,
									0.178,
									0.226,
									0.001
								],
								"e": 0.176
							},
							{
								"h": [
									0.046,
									0.063,
									0.0,
									0.002,
									0.002,
									0.001,
									0.032,
									0.187,
									0.007,
									0.002,
									0.136,
									0.013
								],
								"e": 0.04
							},
							{
								"h": [
									0.001,
									0.011,
									0.0,
									0.0,
									0.015,
									0.07,
									0.002,
									0.0,
									0.001,
									0.002,
									0.014,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.004,
									0.005,
									0.043,
									0.003,
									0.009,
									0.003,
									0.01,
									0.013,
									0.014,
									0.009,
									0.048,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									1.0,
									1.0,
									0.039,
									0.132,
									0.014,
									1.0,
									0.0,
									0.001,
									0.458,
									0.14,
									0.033,
									0.006
								],
								"e": 0.528
							},
							{
								"h": [
									0.013,
									0.008,
									0.017,
									0.062,
									0.023,
									0.06,
									0.06,
									0.044,
									0.008,
									0.278,
									0.068,
									0.022
								],
								"e": 0.067
							},
							{
								"h": [
									0.001,
									0.093,
									0.593,
									0.048,
									0.013,
									0.012,
									0.03,
									0.957,
									0.0,
									0.093,
									0.231,
									0.037
								],
								"e": 0.135
							},
							{
								"h": [
									0.037,
									1.0,
									0.069,
									0.044,
									0.011,
									0.009,
									0.248,
									0.006,
									0.027,
									0.001,
									0.074,
									0.025
								],
								"e": 0.168
							},
							{
								"h": [
									0.046,
									0.531,
									0.009,
									0.026,
									0.012,
									0.066,
									0.014,
									0.236,
									0.02,
									0.098,
									0.001,
									0.299
								],
								"e": 0.098
							},
							{
								"h": [
									0.013,
									0.034,
									0.046,
									0.246,
									0.25,
									0.025,
									0.029,
									0.138,
									1.0,
									0.019,
									0.025,
									0.052
								],
								"e": 0.149
							},
							{
								"h": [
									0.17,
									0.387,
									0.03,
									0.096,
									0.282,
									0.18,
									0.009,
									1.0,
									0.176,
									0.026,
									0.613,
									0.072
								],
								"e": 0.212
							}
						],
						[
							{
								"h": [
									0.286,
									0.0,
									0.163,
									0.0,
									0.006,
									0.0,
									0.182,
									0.032,
									0.264,
									0.195,
									0.08,
									0.982
								],
								"e": 0.074
							},
							{
								"h": [
									0.038,
									0.037,
									0.236,
									0.034,
									0.141,
									0.135,
									0.305,
									0.19,
									0.134,
									0.091,
									0.311,
									0.001
								],
								"e": 0.181
							},
							{
								"h": [
									0.042,
									0.223,
									0.0,
									0.009,
									0.002,
									0.003,
									0.043,
									0.212,
									0.017,
									0.003,
									0.235,
									0.023
								],
								"e": 0.072
							},
							{
								"h": [
									0.001,
									0.018,
									0.001,
									0.0,
									0.013,
									0.029,
									0.001,
									0.001,
									0.002,
									0.007,
									0.015,
									0.01
								],
								"e": 0.01
							},
							{
								"h": [
									0.005,
									0.02,
									0.048,
									0.003,
									0.011,
									0.004,
									0.016,
									0.052,
									0.004,
									0.016,
									0.022,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.009,
									0.0,
									0.001,
									0.2,
									0.017,
									0.004,
									0.028,
									0.007,
									0.015,
									0.059,
									0.034,
									0.005
								],
								"e": 0.024
							},
							{
								"h": [
									0.075,
									0.001,
									0.053,
									0.014,
									0.017,
									0.376,
									0.083,
									0.031,
									0.058,
									0.002,
									0.016,
									0.054
								],
								"e": 0.051
							},
							{
								"h": [
									0.032,
									0.24,
									0.029,
									0.036,
									0.016,
									0.042,
									0.06,
									0.737,
									0.001,
									0.133,
									0.139,
									0.004
								],
								"e": 0.078
							},
							{
								"h": [
									0.014,
									0.113,
									0.022,
									0.03,
									0.033,
									0.083,
									0.252,
									0.04,
									0.08,
									0.004,
									0.075,
									0.008
								],
								"e": 0.058
							},
							{
								"h": [
									0.207,
									0.461,
									0.115,
									0.044,
									0.028,
									0.142,
									0.037,
									0.175,
									0.006,
									0.431,
									0.03,
									0.007
								],
								"e": 0.125
							},
							{
								"h": [
									0.023,
									0.097,
									0.126,
									0.281,
									0.392,
									0.089,
									0.132,
									0.477,
									0.051,
									0.178,
									0.027,
									0.547
								],
								"e": 0.173
							},
							{
								"h": [
									0.517,
									0.073,
									0.082,
									0.167,
									0.074,
									1.0,
									0.024,
									0.438,
									0.231,
									0.732,
									0.677,
									0.313
								],
								"e": 0.318
							}
						],
						[
							{
								"h": [
									0.289,
									0.0,
									0.459,
									0.0,
									0.001,
									0.0,
									0.289,
									0.037,
									0.333,
									0.534,
									0.174,
									0.644
								],
								"e": 0.101
							},
							{
								"h": [
									0.15,
									0.063,
									0.325,
									0.032,
									0.143,
									0.406,
									0.164,
									0.154,
									0.537,
									0.271,
									0.241,
									0.002
								],
								"e": 0.28
							},
							{
								"h": [
									0.052,
									0.065,
									0.0,
									0.06,
									0.006,
									0.003,
									0.078,
									0.142,
									0.024,
									0.001,
									0.13,
									0.018
								],
								"e": 0.058
							},
							{
								"h": [
									0.003,
									0.035,
									0.004,
									0.0,
									0.068,
									0.05,
									0.003,
									0.001,
									0.0,
									0.005,
									0.013,
									0.017
								],
								"e": 0.022
							},
							{
								"h": [
									0.006,
									0.005,
									0.02,
									0.006,
									0.012,
									0.007,
									0.01,
									0.047,
									0.018,
									0.001,
									0.056,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.023,
									0.008,
									0.0,
									0.002,
									0.001,
									0.003,
									0.055,
									0.099,
									0.011
								],
								"e": 0.015
							},
							{
								"h": [
									0.003,
									0.001,
									0.026,
									0.003,
									0.011,
									0.042,
									0.013,
									0.061,
									0.007,
									0.0,
									0.014,
									0.022
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.408,
									0.003,
									0.01,
									0.004,
									0.006,
									0.067,
									0.344,
									0.001,
									0.033,
									0.002,
									0.001
								],
								"e": 0.045
							},
							{
								"h": [
									0.015,
									0.035,
									0.018,
									0.025,
									0.013,
									0.004,
									0.262,
									0.01,
									0.11,
									0.014,
									0.018,
									0.001
								],
								"e": 0.036
							},
							{
								"h": [
									0.011,
									0.024,
									0.031,
									0.007,
									0.009,
									0.008,
									0.004,
									0.048,
									0.003,
									0.021,
									0.004,
									0.002
								],
								"e": 0.013
							},
							{
								"h": [
									0.007,
									0.009,
									0.036,
									0.082,
									0.035,
									0.033,
									0.009,
									0.118,
									0.258,
									0.027,
									0.036,
									0.027
								],
								"e": 0.055
							},
							{
								"h": [
									0.292,
									0.042,
									0.017,
									0.095,
									0.166,
									0.066,
									0.018,
									0.126,
									0.041,
									0.027,
									0.219,
									0.136
								],
								"e": 0.087
							}
						],
						[
							{
								"h": [
									0.307,
									0.0,
									0.564,
									0.0,
									0.001,
									0.0,
									0.458,
									0.058,
									0.305,
									0.618,
									0.14,
									0.391
								],
								"e": 0.108
							},
							{
								"h": [
									0.089,
									0.035,
									0.24,
									0.034,
									0.146,
									0.117,
									0.13,
									0.591,
									0.136,
									0.081,
									0.31,
									0.001
								],
								"e": 0.2
							},
							{
								"h": [
									0.02,
									0.124,
									0.0,
									0.033,
									0.001,
									0.005,
									0.02,
									0.217,
									0.007,
									0.018,
									0.234,
									0.021
								],
								"e": 0.059
							},
							{
								"h": [
									0.0,
									0.013,
									0.001,
									0.0,
									0.011,
									0.209,
									0.012,
									0.007,
									0.001,
									0.001,
									0.023,
									0.061
								],
								"e": 0.031
							},
							{
								"h": [
									0.001,
									0.018,
									0.023,
									0.014,
									0.004,
									0.005,
									0.022,
									0.106,
									0.064,
									0.025,
									0.008,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.016,
									0.0,
									0.006,
									0.12,
									0.01,
									0.001,
									0.006,
									0.006,
									0.007,
									0.254,
									0.098,
									0.003
								],
								"e": 0.035
							},
							{
								"h": [
									0.007,
									0.0,
									0.036,
									0.005,
									0.019,
									0.07,
									0.012,
									0.151,
									0.007,
									0.003,
									0.018,
									0.113
								],
								"e": 0.029
							},
							{
								"h": [
									0.002,
									0.229,
									0.004,
									0.015,
									0.003,
									0.035,
									0.019,
									0.135,
									0.002,
									0.01,
									0.009,
									0.001
								],
								"e": 0.026
							},
							{
								"h": [
									0.019,
									0.004,
									0.02,
									0.011,
									0.012,
									0.013,
									0.014,
									0.012,
									0.497,
									0.062,
									0.007,
									0.005
								],
								"e": 0.041
							},
							{
								"h": [
									0.009,
									0.007,
									0.088,
									0.017,
									0.004,
									0.003,
									0.034,
									0.015,
									0.005,
									0.054,
									0.012,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.013,
									0.022,
									0.01,
									0.208,
									0.034,
									0.035,
									0.14,
									0.02,
									0.007,
									0.075,
									0.02,
									0.158
								],
								"e": 0.058
							},
							{
								"h": [
									0.106,
									0.069,
									0.01,
									0.117,
									0.015,
									0.066,
									0.018,
									0.453,
									0.231,
									0.038,
									0.054,
									0.153
								],
								"e": 0.094
							}
						],
						[
							{
								"h": [
									0.292,
									0.0,
									0.506,
									0.0,
									0.003,
									0.0,
									0.299,
									0.063,
									0.534,
									0.489,
									0.173,
									0.593
								],
								"e": 0.111
							},
							{
								"h": [
									0.213,
									0.118,
									0.382,
									0.037,
									0.15,
									0.317,
									0.227,
									0.358,
									0.403,
									0.116,
									0.26,
									0.03
								],
								"e": 0.292
							},
							{
								"h": [
									0.294,
									0.099,
									0.001,
									0.023,
									0.005,
									0.005,
									0.056,
									0.123,
									0.089,
									0.003,
									0.123,
									0.017
								],
								"e": 0.082
							},
							{
								"h": [
									0.006,
									0.007,
									0.004,
									0.0,
									0.013,
									0.039,
									0.009,
									0.003,
									0.004,
									0.004,
									0.004,
									0.008
								],
								"e": 0.01
							},
							{
								"h": [
									0.009,
									0.006,
									0.014,
									0.005,
									0.01,
									0.003,
									0.013,
									0.007,
									0.037,
									0.008,
									0.01,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.0,
									0.008,
									0.035,
									0.005,
									0.0,
									0.001,
									0.005,
									0.0,
									0.038,
									0.071,
									0.017
								],
								"e": 0.014
							},
							{
								"h": [
									0.003,
									0.0,
									0.016,
									0.002,
									0.012,
									0.026,
									0.002,
									0.025,
									0.004,
									0.0,
									0.005,
									0.015
								],
								"e": 0.008
							},
							{
								"h": [
									0.002,
									0.019,
									0.0,
									0.017,
									0.004,
									0.022,
									0.009,
									0.028,
									0.001,
									0.003,
									0.0,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.011,
									0.001,
									0.031,
									0.009,
									0.007,
									0.003,
									0.145,
									0.003,
									0.252,
									0.056,
									0.007,
									0.005
								],
								"e": 0.034
							},
							{
								"h": [
									0.003,
									0.001,
									0.07,
									0.006,
									0.002,
									0.003,
									0.001,
									0.008,
									0.001,
									0.003,
									0.002,
									0.005
								],
								"e": 0.008
							},
							{
								"h": [
									0.007,
									0.005,
									0.007,
									0.01,
									0.029,
									0.01,
									0.003,
									0.029,
									0.052,
									0.005,
									0.002,
									0.017
								],
								"e": 0.014
							},
							{
								"h": [
									0.359,
									0.003,
									0.007,
									0.065,
									0.073,
									0.035,
									0.005,
									0.051,
									0.019,
									0.008,
									0.106,
									0.139
								],
								"e": 0.053
							}
						],
						[
							{
								"h": [
									0.295,
									0.0,
									0.251,
									0.0,
									0.002,
									0.0,
									0.362,
									0.065,
									0.513,
									0.271,
									0.088,
									0.517
								],
								"e": 0.087
							},
							{
								"h": [
									0.143,
									0.07,
									0.322,
									0.042,
									0.201,
									0.328,
									0.246,
									0.198,
									0.151,
									0.092,
									0.428,
									0.007
								],
								"e": 0.243
							},
							{
								"h": [
									0.046,
									1.0,
									0.001,
									0.02,
									0.004,
									0.006,
									0.023,
									0.241,
									0.031,
									0.019,
									0.288,
									0.029
								],
								"e": 0.178
							},
							{
								"h": [
									0.008,
									0.014,
									0.003,
									0.0,
									0.041,
									0.138,
									0.022,
									0.066,
									0.0,
									0.002,
									0.079,
									0.008
								],
								"e": 0.037
							},
							{
								"h": [
									0.001,
									0.008,
									0.008,
									0.011,
									0.057,
									0.002,
									0.018,
									0.054,
									0.092,
									0.005,
									0.024,
									0.0
								],
								"e": 0.031
							},
							{
								"h": [
									0.001,
									0.0,
									0.005,
									0.03,
									0.004,
									0.0,
									0.0,
									0.013,
									0.006,
									0.34,
									0.343,
									0.007
								],
								"e": 0.054
							},
							{
								"h": [
									0.003,
									0.004,
									0.016,
									0.004,
									0.059,
									0.077,
									0.021,
									0.101,
									0.007,
									0.0,
									0.008,
									0.009
								],
								"e": 0.023
							},
							{
								"h": [
									0.0,
									0.236,
									0.0,
									0.011,
									0.003,
									0.016,
									0.015,
									0.094,
									0.001,
									0.008,
									0.002,
									0.002
								],
								"e": 0.021
							},
							{
								"h": [
									0.029,
									0.002,
									0.013,
									0.01,
									0.005,
									0.002,
									0.201,
									0.004,
									0.373,
									0.052,
									0.007,
									0.004
								],
								"e": 0.043
							},
							{
								"h": [
									0.039,
									0.016,
									0.111,
									0.025,
									0.004,
									0.01,
									0.029,
									0.056,
									0.006,
									0.026,
									0.003,
									0.002
								],
								"e": 0.025
							},
							{
								"h": [
									0.038,
									0.066,
									0.025,
									0.023,
									0.151,
									0.022,
									0.035,
									0.039,
									0.003,
									0.021,
									0.017,
									0.405
								],
								"e": 0.063
							},
							{
								"h": [
									0.328,
									0.054,
									0.022,
									0.146,
									0.014,
									0.143,
									0.014,
									0.109,
									0.018,
									0.143,
									0.068,
									0.13
								],
								"e": 0.084
							}
						],
						[
							{
								"h": [
									0.228,
									0.0,
									0.471,
									0.0,
									0.003,
									0.0,
									0.277,
									0.2,
									0.364,
									0.603,
									0.188,
									1.0
								],
								"e": 0.127
							},
							{
								"h": [
									0.27,
									0.829,
									0.667,
									0.078,
									0.252,
									1.0,
									0.133,
									0.439,
									0.829,
									0.159,
									0.558,
									0.178
								],
								"e": 0.604
							},
							{
								"h": [
									0.322,
									0.085,
									0.019,
									0.031,
									0.241,
									0.079,
									1.0,
									0.33,
									0.228,
									0.062,
									0.466,
									0.06
								],
								"e": 0.37
							},
							{
								"h": [
									0.016,
									0.516,
									0.001,
									0.006,
									0.027,
									0.044,
									0.078,
									0.047,
									0.004,
									0.034,
									0.051,
									0.042
								],
								"e": 0.075
							},
							{
								"h": [
									0.009,
									0.035,
									0.151,
									0.023,
									0.076,
									0.211,
									0.029,
									0.031,
									0.015,
									0.007,
									0.065,
									0.0
								],
								"e": 0.062
							},
							{
								"h": [
									0.121,
									0.0,
									0.002,
									0.251,
									0.023,
									0.032,
									0.001,
									0.007,
									0.095,
									0.435,
									0.36,
									0.009
								],
								"e": 0.102
							},
							{
								"h": [
									0.008,
									0.038,
									0.113,
									0.014,
									0.048,
									0.331,
									0.052,
									0.087,
									0.042,
									0.006,
									0.09,
									0.161
								],
								"e": 0.07
							},
							{
								"h": [
									0.003,
									0.444,
									0.011,
									0.091,
									0.012,
									0.1,
									0.034,
									0.482,
									0.003,
									0.262,
									0.012,
									0.028
								],
								"e": 0.077
							},
							{
								"h": [
									0.173,
									0.019,
									0.137,
									0.084,
									0.069,
									0.02,
									0.183,
									0.077,
									0.637,
									0.021,
									0.041,
									0.013
								],
								"e": 0.107
							},
							{
								"h": [
									0.039,
									0.008,
									0.041,
									0.296,
									0.028,
									0.031,
									0.013,
									0.135,
									0.011,
									0.011,
									0.005,
									0.036
								],
								"e": 0.04
							},
							{
								"h": [
									0.034,
									0.009,
									0.069,
									0.321,
									0.354,
									0.162,
									0.062,
									0.157,
									0.087,
									0.245,
									0.003,
									0.019
								],
								"e": 0.102
							},
							{
								"h": [
									0.423,
									0.049,
									0.085,
									0.833,
									0.18,
									0.092,
									0.01,
									0.163,
									0.152,
									0.032,
									1.0,
									1.0
								],
								"e": 0.261
							}
						],
						[
							{
								"h": [
									0.583,
									0.0,
									0.157,
									0.0,
									0.017,
									0.0,
									0.38,
									0.19,
									0.476,
									0.539,
									0.154,
									0.27
								],
								"e": 0.117
							},
							{
								"h": [
									0.078,
									0.336,
									0.494,
									0.058,
									0.22,
									0.113,
									0.085,
									0.198,
									0.254,
									0.183,
									0.548,
									0.001
								],
								"e": 0.268
							},
							{
								"h": [
									0.192,
									0.018,
									0.022,
									0.141,
									0.002,
									0.01,
									0.196,
									0.412,
									0.127,
									0.043,
									0.419,
									0.045
								],
								"e": 0.152
							},
							{
								"h": [
									0.0,
									0.147,
									0.003,
									0.003,
									0.032,
									0.087,
									0.16,
									0.026,
									0.0,
									0.002,
									0.02,
									0.181
								],
								"e": 0.056
							},
							{
								"h": [
									0.001,
									0.018,
									0.031,
									0.011,
									0.001,
									0.013,
									0.042,
									0.009,
									0.003,
									0.011,
									0.006,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.0,
									0.002,
									0.287,
									0.016,
									0.004,
									0.001,
									0.001,
									0.016,
									0.03,
									0.004,
									0.005
								],
								"e": 0.018
							},
							{
								"h": [
									0.019,
									0.001,
									0.062,
									0.012,
									0.004,
									0.091,
									0.004,
									0.07,
									0.009,
									0.013,
									0.002,
									0.247
								],
								"e": 0.032
							},
							{
								"h": [
									0.008,
									0.016,
									0.003,
									0.035,
									0.022,
									0.015,
									0.01,
									0.008,
									0.014,
									0.27,
									0.01,
									0.001
								],
								"e": 0.02
							},
							{
								"h": [
									0.015,
									0.004,
									0.006,
									0.011,
									0.051,
									0.142,
									0.005,
									0.009,
									0.009,
									0.005,
									0.021,
									0.003
								],
								"e": 0.021
							},
							{
								"h": [
									0.008,
									0.031,
									0.006,
									0.076,
									0.008,
									0.023,
									0.001,
									0.166,
									0.004,
									0.015,
									0.007,
									0.002
								],
								"e": 0.02
							},
							{
								"h": [
									0.004,
									0.017,
									0.015,
									0.02,
									0.036,
									0.191,
									0.003,
									0.054,
									0.059,
									0.034,
									0.008,
									0.006
								],
								"e": 0.031
							},
							{
								"h": [
									0.289,
									0.088,
									0.005,
									0.214,
									0.124,
									0.065,
									0.005,
									0.101,
									0.077,
									0.014,
									0.205,
									0.076
								],
								"e": 0.085
							}
						],
						[
							{
								"h": [
									0.333,
									0.0,
									0.246,
									0.0,
									0.002,
									0.0,
									0.227,
									0.119,
									0.417,
									0.331,
									0.064,
									0.795
								],
								"e": 0.092
							},
							{
								"h": [
									0.144,
									0.305,
									0.487,
									0.061,
									0.261,
									0.304,
									0.172,
									0.503,
									0.278,
									0.127,
									0.578,
									0.002
								],
								"e": 0.338
							},
							{
								"h": [
									0.076,
									0.121,
									0.021,
									0.046,
									0.007,
									0.033,
									0.068,
									0.536,
									0.257,
									0.154,
									0.621,
									0.069
								],
								"e": 0.178
							},
							{
								"h": [
									0.0,
									0.031,
									0.005,
									0.009,
									0.018,
									0.077,
									0.064,
									0.133,
									0.019,
									0.098,
									0.024,
									0.127
								],
								"e": 0.06
							},
							{
								"h": [
									0.002,
									0.214,
									0.107,
									0.015,
									0.001,
									0.008,
									0.043,
									0.026,
									0.0,
									0.016,
									0.011,
									0.0
								],
								"e": 0.037
							},
							{
								"h": [
									0.0,
									0.0,
									0.008,
									0.364,
									0.041,
									0.0,
									0.0,
									0.0,
									0.0,
									0.004,
									0.004,
									0.002
								],
								"e": 0.019
							},
							{
								"h": [
									0.028,
									0.001,
									0.045,
									0.022,
									0.007,
									0.065,
									0.024,
									0.021,
									0.205,
									0.0,
									0.003,
									0.229
								],
								"e": 0.041
							},
							{
								"h": [
									0.043,
									0.081,
									0.0,
									0.097,
									0.043,
									0.101,
									0.003,
									0.011,
									0.003,
									0.514,
									0.005,
									0.002
								],
								"e": 0.045
							},
							{
								"h": [
									0.031,
									0.001,
									0.004,
									0.018,
									0.046,
									0.097,
									0.006,
									0.111,
									0.01,
									0.004,
									0.066,
									0.007
								],
								"e": 0.033
							},
							{
								"h": [
									0.008,
									0.053,
									0.042,
									0.561,
									0.008,
									0.006,
									0.007,
									0.041,
									0.002,
									0.021,
									0.021,
									0.002
								],
								"e": 0.038
							},
							{
								"h": [
									0.003,
									0.015,
									0.003,
									0.07,
									0.125,
									0.348,
									0.004,
									0.033,
									0.004,
									0.449,
									0.01,
									0.033
								],
								"e": 0.066
							},
							{
								"h": [
									0.411,
									0.02,
									0.015,
									0.125,
									0.021,
									0.213,
									0.004,
									0.456,
									1.0,
									0.149,
									0.286,
									0.201
								],
								"e": 0.206
							}
						],
						[
							{
								"h": [
									0.327,
									0.0,
									0.418,
									0.0,
									0.006,
									0.0,
									0.208,
									0.423,
									0.414,
									0.442,
									0.127,
									0.712
								],
								"e": 0.132
							},
							{
								"h": [
									0.157,
									0.784,
									1.0,
									0.092,
									0.319,
									0.391,
									0.077,
									0.151,
									0.327,
									0.156,
									0.799,
									0.006
								],
								"e": 0.437
							},
							{
								"h": [
									0.712,
									0.019,
									0.082,
									0.21,
									0.04,
									0.181,
									0.127,
									0.585,
									0.523,
									0.083,
									0.606,
									0.077
								],
								"e": 0.334
							},
							{
								"h": [
									0.001,
									0.127,
									0.016,
									0.029,
									0.01,
									0.038,
									0.029,
									0.091,
									0.094,
									0.013,
									0.049,
									0.106
								],
								"e": 0.061
							},
							{
								"h": [
									0.053,
									0.4,
									0.069,
									0.035,
									0.029,
									0.015,
									0.061,
									0.049,
									0.002,
									0.108,
									0.009,
									0.0
								],
								"e": 0.072
							},
							{
								"h": [
									0.0,
									0.0,
									0.037,
									0.88,
									0.119,
									0.0,
									0.002,
									0.007,
									0.001,
									0.015,
									0.005,
									0.009
								],
								"e": 0.052
							},
							{
								"h": [
									0.095,
									0.004,
									0.121,
									0.026,
									0.004,
									0.08,
									0.042,
									0.165,
									0.145,
									0.001,
									0.011,
									1.0
								],
								"e": 0.098
							},
							{
								"h": [
									0.126,
									0.017,
									0.001,
									0.144,
									0.366,
									0.041,
									0.019,
									0.011,
									0.015,
									0.776,
									0.004,
									0.001
								],
								"e": 0.079
							},
							{
								"h": [
									0.068,
									0.008,
									0.013,
									0.015,
									0.111,
									1.0,
									0.012,
									0.175,
									0.035,
									0.002,
									0.161,
									0.006
								],
								"e": 0.113
							},
							{
								"h": [
									0.01,
									0.175,
									0.032,
									0.475,
									0.005,
									0.011,
									0.001,
									0.088,
									0.002,
									0.012,
									0.065,
									0.026
								],
								"e": 0.049
							},
							{
								"h": [
									0.003,
									0.009,
									0.007,
									0.133,
									0.123,
									0.302,
									0.005,
									0.064,
									0.047,
									0.165,
									0.003,
									0.007
								],
								"e": 0.054
							},
							{
								"h": [
									0.494,
									0.02,
									0.009,
									0.136,
									0.034,
									0.232,
									0.003,
									0.239,
									0.271,
									0.019,
									0.566,
									0.12
								],
								"e": 0.126
							}
						],
						[
							{
								"h": [
									0.383,
									0.0,
									0.574,
									0.0,
									0.011,
									0.0,
									0.494,
									0.537,
									0.355,
									0.672,
									0.116,
									0.45
								],
								"e": 0.161
							},
							{
								"h": [
									0.302,
									0.607,
									0.705,
									0.076,
									0.3,
									0.155,
									0.083,
									1.0,
									0.253,
									0.158,
									0.761,
									0.002
								],
								"e": 0.459
							},
							{
								"h": [
									0.22,
									0.122,
									0.502,
									0.217,
									0.055,
									0.143,
									0.107,
									0.976,
									0.352,
									0.6,
									0.88,
									0.093
								],
								"e": 0.455
							},
							{
								"h": [
									0.0,
									0.418,
									0.156,
									0.06,
									0.078,
									0.119,
									0.812,
									0.279,
									0.149,
									0.097,
									0.084,
									0.076
								],
								"e": 0.219
							},
							{
								"h": [
									0.118,
									0.031,
									0.089,
									0.2,
									0.014,
									0.243,
									0.197,
									0.363,
									0.004,
									0.045,
									0.024,
									0.0
								],
								"e": 0.114
							},
							{
								"h": [
									0.0,
									0.0,
									0.033,
									0.503,
									0.174,
									0.0,
									0.032,
									0.013,
									0.008,
									0.054,
									0.009,
									0.011
								],
								"e": 0.05
							},
							{
								"h": [
									0.099,
									0.006,
									0.064,
									0.101,
									0.092,
									0.165,
									0.014,
									0.135,
									0.062,
									0.002,
									0.01,
									0.14
								],
								"e": 0.066
							},
							{
								"h": [
									0.028,
									0.005,
									0.001,
									0.176,
									0.048,
									0.017,
									0.036,
									0.005,
									0.019,
									0.285,
									0.005,
									0.001
								],
								"e": 0.035
							},
							{
								"h": [
									0.022,
									0.001,
									0.005,
									0.003,
									0.013,
									0.193,
									0.006,
									0.085,
									0.018,
									0.004,
									0.075,
									0.003
								],
								"e": 0.032
							},
							{
								"h": [
									0.041,
									0.054,
									0.049,
									0.269,
									0.009,
									0.077,
									0.006,
									0.371,
									0.005,
									0.015,
									0.027,
									0.002
								],
								"e": 0.053
							},
							{
								"h": [
									0.024,
									0.016,
									0.014,
									0.054,
									0.117,
									0.096,
									0.029,
									0.103,
									0.009,
									0.344,
									0.01,
									0.033
								],
								"e": 0.056
							},
							{
								"h": [
									0.19,
									0.357,
									0.006,
									0.163,
									0.064,
									0.037,
									0.002,
									0.5,
									0.127,
									0.008,
									0.415,
									0.047
								],
								"e": 0.129
							}
						],
						[
							{
								"h": [
									0.368,
									0.0,
									0.199,
									0.0,
									0.017,
									0.0,
									0.2,
									1.0,
									0.244,
									0.357,
									0.071,
									0.815
								],
								"e": 0.168
							},
							{
								"h": [
									0.42,
									1.0,
									0.854,
									0.107,
									0.375,
									0.142,
									0.054,
									0.207,
									0.209,
									0.131,
									1.0,
									0.001
								],
								"e": 0.481
							},
							{
								"h": [
									0.575,
									0.063,
									1.0,
									1.0,
									0.085,
									1.0,
									0.115,
									1.0,
									1.0,
									0.642,
									0.981,
									0.101
								],
								"e": 0.971
							},
							{
								"h": [
									0.001,
									0.385,
									1.0,
									0.205,
									0.027,
									0.099,
									0.11,
									1.0,
									1.0,
									0.082,
									0.175,
									0.877
								],
								"e": 0.534
							},
							{
								"h": [
									0.646,
									1.0,
									0.211,
									0.189,
									0.021,
									0.293,
									0.124,
									0.219,
									0.001,
									0.107,
									0.01,
									1.0
								],
								"e": 0.407
							},
							{
								"h": [
									0.006,
									0.0,
									0.051,
									0.623,
									0.812,
									0.0,
									0.462,
									0.09,
									0.007,
									0.044,
									0.009,
									0.009
								],
								"e": 0.167
							},
							{
								"h": [
									1.0,
									0.007,
									0.028,
									0.087,
									0.043,
									0.43,
									0.025,
									0.118,
									1.0,
									0.0,
									0.018,
									0.464
								],
								"e": 0.23
							},
							{
								"h": [
									0.823,
									0.036,
									0.001,
									0.381,
									0.477,
									0.07,
									0.047,
									0.027,
									0.031,
									0.863,
									0.004,
									0.003
								],
								"e": 0.165
							},
							{
								"h": [
									0.024,
									0.005,
									0.011,
									0.004,
									0.28,
									0.503,
									0.037,
									0.336,
									0.041,
									0.007,
									0.086,
									0.002
								],
								"e": 0.101
							},
							{
								"h": [
									0.098,
									0.044,
									0.232,
									0.756,
									0.007,
									0.152,
									0.004,
									0.136,
									0.003,
									0.054,
									1.0,
									0.002
								],
								"e": 0.197
							},
							{
								"h": [
									0.022,
									0.006,
									0.02,
									0.098,
									0.374,
									0.568,
									0.051,
									0.157,
									0.009,
									1.0,
									0.005,
									0.124
								],
								"e": 0.147
							},
							{
								"h": [
									0.549,
									0.047,
									0.019,
									0.282,
									0.065,
									0.2,
									0.006,
									0.351,
									0.119,
									0.099,
									0.433,
									0.558
								],
								"e": 0.17
							}
						],
						[
							{
								"h": [
									0.721,
									1.0,
									0.151,
									1.0,
									1.0,
									0.834,
									0.839,
									0.256,
									1.0,
									0.079,
									1.0,
									0.572
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.33,
									0.759,
									0.084,
									0.444,
									0.483,
									0.207,
									0.165,
									0.142,
									0.1,
									0.896,
									1.0
								],
								"e": 0.796
							},
							{
								"h": [
									0.316,
									0.126,
									0.416,
									0.681,
									0.115,
									0.053,
									0.043,
									0.952,
									0.474,
									1.0,
									1.0,
									0.114
								],
								"e": 0.578
							},
							{
								"h": [
									0.208,
									0.063,
									0.56,
									0.029,
									0.038,
									0.065,
									1.0,
									0.328,
									0.006,
									0.088,
									0.352,
									0.08
								],
								"e": 0.271
							},
							{
								"h": [
									0.114,
									0.074,
									0.023,
									0.296,
									0.096,
									0.162,
									0.049,
									1.0,
									0.013,
									0.039,
									0.02,
									0.0
								],
								"e": 0.149
							},
							{
								"h": [
									0.002,
									0.0,
									0.04,
									0.108,
									0.078,
									0.0,
									0.027,
									0.045,
									0.008,
									0.113,
									0.135,
									0.023
								],
								"e": 0.048
							},
							{
								"h": [
									0.049,
									0.055,
									0.027,
									0.074,
									0.03,
									0.314,
									0.131,
									0.074,
									0.049,
									0.0,
									0.044,
									0.04
								],
								"e": 0.068
							},
							{
								"h": [
									0.006,
									0.028,
									0.002,
									0.326,
									0.103,
									0.02,
									0.049,
									0.067,
									0.014,
									0.142,
									0.001,
									0.004
								],
								"e": 0.047
							},
							{
								"h": [
									0.041,
									0.001,
									0.008,
									0.015,
									0.04,
									0.039,
									0.044,
									0.036,
									0.03,
									0.055,
									0.209,
									0.001
								],
								"e": 0.044
							},
							{
								"h": [
									0.024,
									0.002,
									0.033,
									0.164,
									0.007,
									0.033,
									0.005,
									0.078,
									0.005,
									0.007,
									0.031,
									0.011
								],
								"e": 0.026
							},
							{
								"h": [
									0.036,
									0.035,
									0.01,
									0.059,
									0.066,
									0.143,
									0.016,
									0.138,
									0.006,
									0.228,
									0.012,
									0.053
								],
								"e": 0.055
							},
							{
								"h": [
									0.425,
									0.023,
									0.091,
									0.115,
									0.042,
									0.107,
									0.021,
									0.163,
									0.289,
									0.087,
									0.207,
									0.09
								],
								"e": 0.122
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " they",
					"o": " mean",
					"t": " mean",
					"r": [
						{
							"p": "'re",
							"b": 0.001,
							"k": {
								"'re": 0.239,
								"'ll": 0.142,
								" could": 0.089,
								"'d": 0.084,
								"'ve": 0.058,
								" can": 0.031,
								" might": 0.031,
								" were": 0.028,
								" may": 0.016,
								" own": 0.015
							}
						},
						{
							"p": "'re",
							"b": 0.0,
							"k": {
								"'re": 0.264,
								"'d": 0.167,
								"'ll": 0.149,
								" could": 0.113,
								"'ve": 0.078,
								" can": 0.034,
								" might": 0.028,
								" should": 0.015,
								" may": 0.011,
								" were": 0.009
							}
						},
						{
							"p": "'re",
							"b": 0.0,
							"k": {
								"'re": 0.263,
								"'d": 0.212,
								"'ve": 0.172,
								"'ll": 0.146,
								" could": 0.084,
								" might": 0.019,
								" can": 0.018,
								" own": 0.009,
								" would": 0.006,
								" should": 0.005
							}
						},
						{
							"p": "'ve",
							"b": 0.001,
							"k": {
								"'ve": 0.222,
								"'d": 0.182,
								"'ll": 0.169,
								"'re": 0.158,
								" could": 0.13,
								" might": 0.025,
								" can": 0.021,
								" should": 0.015,
								" may": 0.009,
								" would": 0.006
							}
						},
						{
							"p": "'ve",
							"b": 0.002,
							"k": {
								"'ve": 0.369,
								"'ll": 0.236,
								"'re": 0.123,
								" could": 0.062,
								"'d": 0.039,
								" can": 0.022,
								" should": 0.022,
								" might": 0.02,
								" will": 0.011,
								" have": 0.008
							}
						},
						{
							"p": " mean",
							"b": 0.331,
							"k": {
								" mean": 0.331,
								"'re": 0.114,
								"'ve": 0.103,
								" might": 0.045,
								"'ll": 0.044,
								" could": 0.039,
								" are": 0.034,
								" can": 0.034,
								" may": 0.033,
								" should": 0.029
							}
						},
						{
							"p": " mean",
							"b": 0.857,
							"k": {
								" mean": 0.857,
								"'re": 0.029,
								" are": 0.018,
								" also": 0.011,
								" may": 0.009,
								" refer": 0.007,
								"'ve": 0.006,
								" can": 0.005,
								" might": 0.005,
								" could": 0.004
							}
						},
						{
							"p": " mean",
							"b": 0.993,
							"k": {
								" mean": 0.993,
								" means": 0.005,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " mean",
							"b": 0.997,
							"k": {
								" mean": 0.997,
								" means": 0.001,
								" meant": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " mean",
							"b": 0.999,
							"k": {
								" mean": 0.999,
								" means": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " mean",
							"b": 0.999,
							"k": {
								" mean": 0.999,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " mean",
							"b": 0.953,
							"k": {
								" mean": 0.953,
								" are": 0.01,
								" say": 0.004,
								" just": 0.003,
								"'re": 0.003,
								" don": 0.003,
								" means": 0.003,
								" have": 0.001,
								" can": 0.001,
								" do": 0.001
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.002,
									0.588,
									0.001,
									0.003,
									0.023,
									0.779,
									0.045,
									0.186,
									1.0,
									0.293,
									1.0
								],
								"e": 0.322
							},
							{
								"h": [
									0.032,
									0.175,
									0.298,
									1.0,
									1.0,
									0.972,
									1.0,
									1.0,
									1.0,
									1.0,
									0.027,
									0.036
								],
								"e": 1.0
							},
							{
								"h": [
									0.752,
									1.0,
									0.08,
									0.227,
									0.299,
									0.143,
									0.554,
									0.459,
									0.127,
									0.06,
									0.275,
									1.0
								],
								"e": 0.793
							},
							{
								"h": [
									1.0,
									1.0,
									0.1,
									1.0,
									1.0,
									1.0,
									0.661,
									0.149,
									1.0,
									0.527,
									1.0,
									0.971
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.344,
									1.0,
									0.621,
									1.0,
									1.0,
									1.0,
									0.175,
									1.0,
									0.89,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.331,
									0.158,
									0.495,
									0.521,
									0.116,
									0.691,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.843,
									1.0,
									1.0,
									0.863,
									1.0,
									1.0,
									1.0,
									1.0,
									0.19,
									0.278,
									1.0,
									0.375
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.668,
									0.338,
									0.493,
									1.0,
									1.0,
									1.0,
									0.1,
									1.0,
									1.0,
									0.046,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.385,
									1.0,
									1.0,
									1.0,
									1.0,
									0.126,
									1.0,
									0.349,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.279,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.695,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.091,
									0.004,
									0.247,
									0.0,
									0.001,
									0.002,
									0.066,
									0.007,
									0.129,
									0.536,
									0.185,
									0.382
								],
								"e": 0.101
							},
							{
								"h": [
									0.006,
									0.02,
									0.141,
									0.016,
									0.144,
									0.187,
									0.106,
									0.08,
									0.395,
									0.182,
									0.133,
									0.009
								],
								"e": 0.141
							},
							{
								"h": [
									0.06,
									0.012,
									0.0,
									0.004,
									0.001,
									0.0,
									0.026,
									0.047,
									0.001,
									0.0,
									0.068,
									0.007
								],
								"e": 0.02
							},
							{
								"h": [
									0.006,
									0.002,
									0.0,
									0.0,
									0.023,
									0.099,
									0.0,
									0.0,
									0.0,
									0.015,
									0.004,
									0.004
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.0,
									0.016,
									0.013,
									0.032,
									0.0,
									0.003,
									0.007,
									0.005,
									0.032,
									0.011,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.0,
									0.027,
									0.05,
									0.0,
									0.008,
									0.0,
									0.006,
									0.002,
									0.035,
									0.018,
									0.012
								],
								"e": 0.015
							},
							{
								"h": [
									0.008,
									0.001,
									0.022,
									0.049,
									0.011,
									0.01,
									0.024,
									0.09,
									0.005,
									0.007,
									0.007,
									0.03
								],
								"e": 0.02
							},
							{
								"h": [
									0.0,
									0.064,
									0.003,
									0.036,
									0.066,
									0.09,
									0.041,
									0.004,
									0.051,
									0.005,
									0.006,
									0.007
								],
								"e": 0.037
							},
							{
								"h": [
									0.034,
									0.012,
									0.058,
									0.004,
									0.001,
									0.023,
									0.006,
									0.0,
									0.013,
									0.01,
									0.011,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.014,
									0.005,
									0.017,
									0.002,
									0.007,
									0.003,
									0.002,
									0.035,
									0.005,
									0.002,
									0.002,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.004,
									0.006,
									0.024,
									0.017,
									0.077,
									0.017,
									0.003,
									0.018,
									0.013,
									0.001,
									0.023,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									1.0,
									0.004,
									0.003,
									0.011,
									0.034,
									0.037,
									0.003,
									0.006,
									0.119,
									0.033,
									0.033,
									0.1
								],
								"e": 0.039
							}
						],
						[
							{
								"h": [
									0.361,
									0.007,
									0.205,
									0.0,
									0.024,
									0.006,
									0.399,
									0.005,
									0.11,
									0.395,
									0.316,
									0.22
								],
								"e": 0.142
							},
							{
								"h": [
									0.006,
									0.014,
									0.141,
									0.017,
									0.148,
									0.201,
									0.659,
									0.259,
									0.185,
									0.128,
									0.153,
									0.02
								],
								"e": 0.208
							},
							{
								"h": [
									0.022,
									0.041,
									0.0,
									0.002,
									0.0,
									0.0,
									0.017,
									0.085,
									0.0,
									0.0,
									0.104,
									0.01
								],
								"e": 0.027
							},
							{
								"h": [
									0.029,
									0.004,
									0.0,
									0.0,
									0.046,
									0.142,
									0.001,
									0.0,
									0.0,
									0.001,
									0.009,
									0.004
								],
								"e": 0.024
							},
							{
								"h": [
									0.0,
									0.002,
									0.031,
									0.014,
									0.053,
									0.004,
									0.006,
									0.032,
									0.003,
									0.026,
									0.016,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.002,
									0.0,
									0.001,
									0.019,
									0.001,
									0.004,
									0.0,
									0.002,
									0.001,
									0.068,
									0.127,
									0.026
								],
								"e": 0.024
							},
							{
								"h": [
									0.102,
									0.001,
									0.02,
									0.162,
									0.036,
									0.009,
									0.02,
									0.11,
									0.002,
									0.001,
									0.008,
									0.006
								],
								"e": 0.032
							},
							{
								"h": [
									0.0,
									0.004,
									0.0,
									0.005,
									0.012,
									0.007,
									0.019,
									0.001,
									0.059,
									0.008,
									0.0,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.038,
									0.002,
									0.156,
									0.002,
									0.0,
									0.033,
									0.002,
									0.0,
									0.008,
									0.015,
									0.002,
									0.001
								],
								"e": 0.017
							},
							{
								"h": [
									0.003,
									0.002,
									0.002,
									0.006,
									0.001,
									0.004,
									0.001,
									0.035,
									0.003,
									0.0,
									0.002,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.002,
									0.001,
									0.003,
									0.002,
									0.037,
									0.011,
									0.001,
									0.002,
									0.01,
									0.003,
									0.002,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.553,
									0.005,
									0.001,
									0.014,
									0.016,
									0.007,
									0.001,
									0.007,
									0.416,
									0.002,
									0.016,
									0.034
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.248,
									0.0,
									0.7,
									0.0,
									0.0,
									0.001,
									0.134,
									0.007,
									0.081,
									0.442,
									0.129,
									0.3
								],
								"e": 0.131
							},
							{
								"h": [
									0.02,
									0.029,
									0.216,
									0.023,
									0.165,
									0.114,
									0.148,
									0.12,
									0.229,
									0.117,
									0.221,
									0.002
								],
								"e": 0.136
							},
							{
								"h": [
									0.012,
									0.043,
									0.0,
									0.002,
									0.003,
									0.001,
									0.013,
									0.136,
									0.003,
									0.0,
									0.129,
									0.01
								],
								"e": 0.032
							},
							{
								"h": [
									0.006,
									0.002,
									0.0,
									0.0,
									0.013,
									0.201,
									0.0,
									0.0,
									0.0,
									0.008,
									0.012,
									0.003
								],
								"e": 0.019
							},
							{
								"h": [
									0.0,
									0.004,
									0.02,
									0.002,
									0.023,
									0.004,
									0.006,
									0.025,
									0.004,
									0.047,
									0.009,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.0,
									0.002,
									0.037,
									0.0,
									0.037,
									0.0,
									0.004,
									0.029,
									0.059,
									0.334,
									0.039
								],
								"e": 0.049
							},
							{
								"h": [
									0.088,
									0.001,
									0.013,
									0.405,
									0.027,
									0.007,
									0.035,
									0.104,
									0.002,
									0.012,
									0.006,
									0.004
								],
								"e": 0.046
							},
							{
								"h": [
									0.0,
									0.012,
									0.001,
									0.044,
									0.026,
									0.017,
									0.029,
									0.001,
									0.046,
									0.008,
									0.011,
									0.004
								],
								"e": 0.019
							},
							{
								"h": [
									0.016,
									0.014,
									0.131,
									0.007,
									0.001,
									0.011,
									0.054,
									0.0,
									0.165,
									0.028,
									0.025,
									0.005
								],
								"e": 0.035
							},
							{
								"h": [
									0.108,
									0.021,
									0.003,
									0.003,
									0.026,
									0.006,
									0.007,
									0.08,
									0.002,
									0.007,
									0.004,
									0.001
								],
								"e": 0.018
							},
							{
								"h": [
									0.011,
									0.003,
									0.09,
									0.034,
									0.174,
									0.04,
									0.018,
									0.162,
									0.016,
									0.003,
									0.017,
									0.006
								],
								"e": 0.042
							},
							{
								"h": [
									0.579,
									0.008,
									0.005,
									0.009,
									0.012,
									0.069,
									0.003,
									0.008,
									0.038,
									0.021,
									0.058,
									0.09
								],
								"e": 0.032
							}
						],
						[
							{
								"h": [
									0.166,
									0.001,
									0.096,
									0.0,
									0.0,
									0.0,
									0.541,
									0.006,
									0.149,
									0.291,
									0.11,
									0.238
								],
								"e": 0.103
							},
							{
								"h": [
									0.005,
									0.025,
									0.217,
									0.024,
									0.183,
									0.078,
									0.181,
									0.383,
									0.145,
									0.047,
									0.221,
									0.001
								],
								"e": 0.148
							},
							{
								"h": [
									0.05,
									0.053,
									0.0,
									0.002,
									0.0,
									0.001,
									0.007,
									0.114,
									0.0,
									0.001,
									0.096,
									0.011
								],
								"e": 0.032
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.0,
									0.006,
									0.05,
									0.007,
									0.0,
									0.0,
									0.002,
									0.013,
									0.012
								],
								"e": 0.008
							},
							{
								"h": [
									0.002,
									0.004,
									0.013,
									0.014,
									0.007,
									0.009,
									0.054,
									0.115,
									0.007,
									0.031,
									0.004,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.04,
									0.0,
									0.004,
									0.024,
									0.009,
									0.068,
									0.012,
									0.005,
									0.04,
									0.076,
									0.039,
									0.015
								],
								"e": 0.036
							},
							{
								"h": [
									0.014,
									0.004,
									0.047,
									0.057,
									0.01,
									0.054,
									0.018,
									0.109,
									0.016,
									0.011,
									0.006,
									0.003
								],
								"e": 0.027
							},
							{
								"h": [
									0.001,
									0.016,
									0.006,
									0.006,
									0.003,
									0.002,
									0.011,
									0.037,
									0.005,
									0.004,
									0.0,
									0.007
								],
								"e": 0.009
							},
							{
								"h": [
									0.006,
									0.001,
									0.063,
									0.003,
									0.016,
									0.02,
									0.005,
									0.001,
									0.005,
									0.065,
									0.003,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.0,
									0.003,
									0.019,
									0.0,
									0.0,
									0.002,
									0.018,
									0.003,
									0.0,
									0.003,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.005,
									0.002,
									0.003,
									0.002,
									0.006,
									0.016,
									0.002,
									0.0,
									0.004,
									0.005,
									0.002,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.366,
									0.002,
									0.005,
									0.044,
									0.006,
									0.003,
									0.004,
									0.006,
									0.055,
									0.001,
									0.0,
									0.072
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.177,
									0.657,
									0.075,
									0.0,
									0.001,
									1.0,
									0.029,
									0.007,
									0.225,
									0.434,
									0.783,
									0.303
								],
								"e": 0.527
							},
							{
								"h": [
									0.009,
									0.026,
									0.231,
									0.022,
									0.144,
									0.714,
									0.086,
									0.109,
									0.297,
									0.31,
									0.188,
									0.3
								],
								"e": 0.304
							},
							{
								"h": [
									0.019,
									0.034,
									0.0,
									0.001,
									0.0,
									0.0,
									0.028,
									0.16,
									0.0,
									0.0,
									0.093,
									0.01
								],
								"e": 0.03
							},
							{
								"h": [
									0.286,
									0.002,
									0.0,
									0.0,
									0.024,
									0.034,
									0.001,
									0.0,
									0.0,
									0.001,
									0.015,
									0.001
								],
								"e": 0.055
							},
							{
								"h": [
									0.0,
									0.003,
									0.011,
									0.002,
									0.073,
									0.0,
									0.007,
									0.089,
									0.017,
									0.006,
									0.031,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.021,
									0.001,
									0.0,
									0.007,
									0.001,
									0.04,
									0.009,
									0.002,
									0.006,
									0.054,
									0.049,
									0.1
								],
								"e": 0.033
							},
							{
								"h": [
									0.011,
									0.0,
									0.011,
									0.022,
									0.016,
									0.01,
									0.1,
									0.05,
									0.0,
									0.005,
									0.01,
									0.003
								],
								"e": 0.022
							},
							{
								"h": [
									0.0,
									0.274,
									0.006,
									0.003,
									0.001,
									0.007,
									0.017,
									0.014,
									0.003,
									0.01,
									0.025,
									0.001
								],
								"e": 0.028
							},
							{
								"h": [
									0.085,
									0.03,
									0.115,
									0.028,
									0.028,
									0.015,
									0.021,
									0.002,
									0.007,
									0.018,
									0.014,
									0.002
								],
								"e": 0.032
							},
							{
								"h": [
									0.043,
									0.005,
									0.001,
									0.007,
									0.005,
									0.021,
									0.001,
									0.014,
									0.01,
									0.003,
									0.018,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.003,
									0.039,
									0.024,
									0.026,
									0.181,
									0.063,
									0.003,
									0.018,
									0.097,
									0.007,
									0.023,
									0.046
								],
								"e": 0.039
							},
							{
								"h": [
									0.588,
									0.01,
									0.005,
									0.024,
									0.161,
									0.083,
									0.002,
									0.017,
									1.0,
									0.029,
									0.145,
									0.251
								],
								"e": 0.094
							}
						],
						[
							{
								"h": [
									0.27,
									0.0,
									0.279,
									0.0,
									0.0,
									0.0,
									0.298,
									0.01,
									0.198,
									0.323,
									0.103,
									0.376
								],
								"e": 0.121
							},
							{
								"h": [
									0.017,
									0.05,
									0.257,
									0.025,
									0.179,
									0.058,
									0.113,
									0.111,
									0.194,
									0.092,
									0.272,
									0.0
								],
								"e": 0.129
							},
							{
								"h": [
									0.018,
									0.045,
									0.0,
									0.014,
									0.003,
									0.003,
									0.038,
									0.195,
									0.005,
									0.001,
									0.21,
									0.019
								],
								"e": 0.049
							},
							{
								"h": [
									0.003,
									0.01,
									0.0,
									0.0,
									0.019,
									0.039,
									0.001,
									0.0,
									0.003,
									0.014,
									0.01,
									0.014
								],
								"e": 0.01
							},
							{
								"h": [
									0.004,
									0.015,
									0.009,
									0.001,
									0.015,
									0.001,
									0.012,
									0.027,
									0.021,
									0.034,
									0.027,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									1.0,
									1.0,
									0.001,
									0.045,
									0.004,
									1.0,
									0.003,
									0.007,
									0.306,
									0.125,
									0.296,
									0.016
								],
								"e": 0.514
							},
							{
								"h": [
									0.027,
									0.011,
									0.045,
									0.029,
									0.044,
									0.037,
									0.148,
									0.222,
									0.002,
									1.0,
									0.047,
									0.009
								],
								"e": 0.204
							},
							{
								"h": [
									0.002,
									1.0,
									1.0,
									0.059,
									0.004,
									0.012,
									0.109,
									0.41,
									0.018,
									0.08,
									1.0,
									0.147
								],
								"e": 0.485
							},
							{
								"h": [
									0.033,
									1.0,
									0.452,
									0.103,
									0.035,
									0.026,
									1.0,
									0.002,
									1.0,
									0.214,
									0.638,
									0.014
								],
								"e": 0.422
							},
							{
								"h": [
									0.573,
									0.133,
									0.059,
									0.003,
									0.112,
									0.013,
									0.069,
									0.07,
									0.005,
									0.27,
									0.014,
									0.013
								],
								"e": 0.097
							},
							{
								"h": [
									0.022,
									0.169,
									0.378,
									0.079,
									0.107,
									0.025,
									0.08,
									1.0,
									0.057,
									0.014,
									0.055,
									0.196
								],
								"e": 0.178
							},
							{
								"h": [
									0.245,
									0.004,
									0.004,
									0.008,
									0.009,
									0.151,
									0.004,
									0.002,
									0.109,
									0.162,
									0.046,
									0.048
								],
								"e": 0.044
							}
						],
						[
							{
								"h": [
									0.069,
									0.0,
									0.09,
									0.0,
									0.0,
									0.0,
									0.037,
									0.017,
									0.924,
									0.463,
									0.156,
									0.194
								],
								"e": 0.127
							},
							{
								"h": [
									0.039,
									0.051,
									0.299,
									0.026,
									0.155,
									0.257,
									0.118,
									0.116,
									0.388,
									0.384,
									0.198,
									0.006
								],
								"e": 0.207
							},
							{
								"h": [
									0.039,
									0.028,
									0.0,
									0.038,
									0.004,
									0.002,
									0.041,
									0.122,
									0.002,
									0.0,
									0.081,
									0.013
								],
								"e": 0.035
							},
							{
								"h": [
									0.012,
									0.01,
									0.001,
									0.0,
									0.029,
									0.04,
									0.004,
									0.0,
									0.0,
									0.007,
									0.015,
									0.011
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.007,
									0.005,
									0.003,
									0.042,
									0.008,
									0.025,
									0.024,
									0.017,
									0.008,
									0.02,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.024,
									0.0,
									0.001,
									0.008,
									0.007,
									0.015,
									0.0,
									0.003,
									0.023,
									0.061,
									0.045,
									0.011
								],
								"e": 0.021
							},
							{
								"h": [
									0.003,
									0.016,
									0.023,
									0.019,
									0.025,
									0.021,
									0.02,
									0.152,
									0.002,
									0.0,
									0.012,
									0.005
								],
								"e": 0.023
							},
							{
								"h": [
									0.0,
									0.484,
									0.001,
									0.005,
									0.003,
									0.002,
									0.04,
									1.0,
									0.003,
									0.006,
									0.003,
									0.001
								],
								"e": 0.117
							},
							{
								"h": [
									0.017,
									0.021,
									0.076,
									0.017,
									0.003,
									0.002,
									0.059,
									0.0,
									0.056,
									0.433,
									0.025,
									0.001
								],
								"e": 0.051
							},
							{
								"h": [
									0.003,
									0.002,
									0.035,
									0.002,
									0.003,
									0.001,
									0.001,
									0.035,
									0.003,
									0.001,
									0.001,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.002,
									0.006,
									0.008,
									0.009,
									0.014,
									0.001,
									0.005,
									0.007,
									0.011,
									0.01,
									0.005
								],
								"e": 0.006
							},
							{
								"h": [
									0.565,
									0.001,
									0.001,
									0.008,
									0.008,
									0.018,
									0.002,
									0.002,
									0.102,
									0.009,
									0.007,
									0.036
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.151,
									0.0,
									0.075,
									0.0,
									0.0,
									0.0,
									0.24,
									0.013,
									0.199,
									0.278,
									0.102,
									0.179
								],
								"e": 0.08
							},
							{
								"h": [
									0.017,
									0.033,
									0.249,
									0.025,
									0.18,
									0.041,
									0.141,
									0.291,
									0.119,
									0.091,
									0.263,
									0.001
								],
								"e": 0.142
							},
							{
								"h": [
									0.016,
									0.036,
									0.0,
									0.014,
									0.001,
									0.002,
									0.015,
									0.158,
									0.003,
									0.003,
									0.141,
									0.016
								],
								"e": 0.036
							},
							{
								"h": [
									0.0,
									0.007,
									0.0,
									0.0,
									0.005,
									0.046,
									0.003,
									0.0,
									0.001,
									0.002,
									0.009,
									0.014
								],
								"e": 0.007
							},
							{
								"h": [
									0.003,
									0.082,
									0.005,
									0.002,
									0.002,
									0.002,
									0.028,
									0.053,
									0.017,
									0.013,
									0.003,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.083,
									0.0,
									0.007,
									0.025,
									0.009,
									0.145,
									0.0,
									0.004,
									0.017,
									0.11,
									0.009,
									0.005
								],
								"e": 0.046
							},
							{
								"h": [
									0.002,
									0.014,
									0.037,
									0.016,
									0.015,
									0.021,
									0.016,
									0.213,
									0.008,
									0.003,
									0.015,
									0.011
								],
								"e": 0.027
							},
							{
								"h": [
									0.0,
									0.02,
									0.002,
									0.009,
									0.001,
									0.001,
									0.013,
									0.344,
									0.003,
									0.014,
									0.001,
									0.007
								],
								"e": 0.034
							},
							{
								"h": [
									0.022,
									0.002,
									0.077,
									0.003,
									0.005,
									0.012,
									0.006,
									0.002,
									0.038,
									0.186,
									0.01,
									0.001
								],
								"e": 0.024
							},
							{
								"h": [
									0.001,
									0.001,
									0.023,
									0.01,
									0.001,
									0.0,
									0.002,
									0.011,
									0.002,
									0.002,
									0.017,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.002,
									0.004,
									0.022,
									0.032,
									0.017,
									0.011,
									0.003,
									0.006,
									0.012,
									0.001,
									0.044
								],
								"e": 0.013
							},
							{
								"h": [
									0.11,
									0.004,
									0.001,
									0.025,
									0.007,
									0.003,
									0.001,
									0.006,
									0.157,
									0.002,
									0.002,
									0.042
								],
								"e": 0.014
							}
						],
						[
							{
								"h": [
									0.064,
									0.002,
									0.294,
									0.0,
									0.002,
									0.0,
									0.023,
									0.026,
									0.396,
									0.542,
									0.196,
									0.189
								],
								"e": 0.109
							},
							{
								"h": [
									0.027,
									0.069,
									0.346,
									0.028,
									0.152,
									0.13,
									0.132,
									0.212,
									0.21,
									0.109,
									0.207,
									0.023
								],
								"e": 0.163
							},
							{
								"h": [
									0.2,
									0.053,
									0.0,
									0.014,
									0.002,
									0.003,
									0.042,
									0.106,
									0.007,
									0.0,
									0.075,
									0.012
								],
								"e": 0.049
							},
							{
								"h": [
									0.003,
									0.003,
									0.0,
									0.0,
									0.004,
									0.019,
									0.002,
									0.0,
									0.003,
									0.002,
									0.004,
									0.006
								],
								"e": 0.004
							},
							{
								"h": [
									0.002,
									0.007,
									0.008,
									0.011,
									0.002,
									0.001,
									0.033,
									0.024,
									0.015,
									0.005,
									0.008,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.008,
									0.0,
									0.004,
									0.007,
									0.001,
									0.0,
									0.0,
									0.007,
									0.0,
									0.029,
									0.005,
									0.005
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.002,
									0.018,
									0.012,
									0.005,
									0.008,
									0.001,
									0.126,
									0.006,
									0.0,
									0.013,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.007,
									0.0,
									0.006,
									0.001,
									0.001,
									0.005,
									0.028,
									0.001,
									0.0,
									0.0,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.006,
									0.001,
									0.069,
									0.001,
									0.001,
									0.001,
									0.003,
									0.0,
									0.01,
									0.101,
									0.003,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.001,
									0.019,
									0.001,
									0.0,
									0.0,
									0.0,
									0.008,
									0.001,
									0.0,
									0.001,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.003,
									0.003,
									0.006,
									0.0,
									0.002,
									0.009,
									0.001,
									0.001,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.469,
									0.0,
									0.001,
									0.005,
									0.019,
									0.004,
									0.001,
									0.0,
									0.069,
									0.003,
									0.001,
									0.014
								],
								"e": 0.013
							}
						],
						[
							{
								"h": [
									0.155,
									0.0,
									0.12,
									0.0,
									0.001,
									0.0,
									0.274,
									0.038,
									0.085,
									0.491,
									0.114,
									0.277
								],
								"e": 0.096
							},
							{
								"h": [
									0.022,
									0.075,
									0.383,
									0.033,
									0.259,
									0.048,
									0.158,
									0.206,
									0.203,
									0.109,
									0.373,
									0.001
								],
								"e": 0.175
							},
							{
								"h": [
									0.034,
									0.03,
									0.001,
									0.016,
									0.001,
									0.004,
									0.016,
									0.214,
									0.004,
									0.004,
									0.202,
									0.021
								],
								"e": 0.045
							},
							{
								"h": [
									0.005,
									0.007,
									0.002,
									0.0,
									0.013,
									0.043,
									0.004,
									0.003,
									0.003,
									0.006,
									0.028,
									0.023
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.012,
									0.004,
									0.002,
									0.013,
									0.003,
									0.031,
									0.019,
									0.017,
									0.006,
									0.009,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.0,
									0.002,
									0.006,
									0.005,
									0.006,
									0.0,
									0.001,
									0.003,
									0.043,
									0.011,
									0.008
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.002,
									0.101,
									0.008,
									0.007,
									0.05,
									0.01,
									0.089,
									0.009,
									0.0,
									0.009,
									0.003
								],
								"e": 0.025
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.003,
									0.002,
									0.0,
									0.002,
									0.026,
									0.002,
									0.004,
									0.0,
									0.011
								],
								"e": 0.005
							},
							{
								"h": [
									0.035,
									0.001,
									0.13,
									0.006,
									0.011,
									0.008,
									0.007,
									0.004,
									0.01,
									0.347,
									0.003,
									0.001
								],
								"e": 0.037
							},
							{
								"h": [
									0.001,
									0.001,
									0.006,
									0.014,
									0.0,
									0.0,
									0.001,
									0.033,
									0.003,
									0.001,
									0.004,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.004,
									0.002,
									0.002,
									0.011,
									0.016,
									0.001,
									0.0,
									0.003,
									0.006,
									0.001,
									0.009
								],
								"e": 0.004
							},
							{
								"h": [
									0.213,
									0.001,
									0.0,
									0.032,
									0.003,
									0.001,
									0.0,
									0.001,
									0.023,
									0.001,
									0.0,
									0.021
								],
								"e": 0.009
							}
						],
						[
							{
								"h": [
									0.082,
									0.001,
									0.31,
									0.0,
									0.001,
									0.0,
									0.032,
									0.15,
									1.0,
									0.611,
									0.246,
									0.443
								],
								"e": 0.195
							},
							{
								"h": [
									0.048,
									1.0,
									0.626,
									0.062,
									0.265,
									0.603,
									0.141,
									0.309,
									0.62,
									0.119,
									0.471,
									0.167
								],
								"e": 0.458
							},
							{
								"h": [
									0.463,
									0.053,
									0.001,
									0.027,
									0.071,
									0.15,
									1.0,
									0.324,
									0.028,
									0.012,
									0.292,
									0.046
								],
								"e": 0.27
							},
							{
								"h": [
									0.028,
									0.486,
									0.0,
									0.002,
									0.032,
									0.039,
									0.048,
									0.001,
									0.031,
									0.057,
									0.044,
									0.092
								],
								"e": 0.072
							},
							{
								"h": [
									0.02,
									0.039,
									0.052,
									0.1,
									0.064,
									0.284,
									0.045,
									0.092,
									0.014,
									0.011,
									0.024,
									0.0
								],
								"e": 0.063
							},
							{
								"h": [
									0.026,
									0.0,
									0.0,
									0.06,
									0.004,
									0.05,
									0.001,
									0.017,
									0.076,
									0.147,
									0.2,
									0.038
								],
								"e": 0.061
							},
							{
								"h": [
									0.02,
									0.027,
									0.249,
									0.024,
									0.059,
									0.034,
									0.054,
									0.111,
									0.004,
									0.002,
									0.061,
									0.044
								],
								"e": 0.069
							},
							{
								"h": [
									0.002,
									0.41,
									0.007,
									0.033,
									0.001,
									0.091,
									0.033,
									0.648,
									0.136,
									0.163,
									0.005,
									0.023
								],
								"e": 0.119
							},
							{
								"h": [
									0.112,
									0.006,
									0.504,
									0.213,
									0.133,
									0.121,
									0.041,
									0.021,
									0.72,
									0.219,
									0.037,
									0.013
								],
								"e": 0.167
							},
							{
								"h": [
									0.044,
									0.012,
									0.048,
									0.089,
									0.038,
									0.003,
									0.003,
									0.124,
									0.015,
									0.001,
									0.017,
									0.006
								],
								"e": 0.029
							},
							{
								"h": [
									0.011,
									0.017,
									0.027,
									0.054,
									0.047,
									0.132,
									0.012,
									0.027,
									0.034,
									0.014,
									0.012,
									0.031
								],
								"e": 0.029
							},
							{
								"h": [
									0.675,
									0.012,
									0.001,
									0.028,
									0.085,
									0.028,
									0.0,
									0.01,
									0.153,
									0.031,
									0.026,
									0.392
								],
								"e": 0.055
							}
						],
						[
							{
								"h": [
									0.371,
									0.002,
									0.206,
									0.001,
									0.008,
									0.001,
									0.416,
									0.083,
									0.124,
									0.581,
									0.321,
									0.249
								],
								"e": 0.157
							},
							{
								"h": [
									0.025,
									0.274,
									0.459,
									0.046,
									0.235,
									0.326,
									0.262,
									0.172,
									0.399,
									0.228,
									0.456,
									0.012
								],
								"e": 0.28
							},
							{
								"h": [
									0.172,
									0.04,
									0.001,
									0.122,
									0.004,
									0.013,
									0.243,
									0.39,
									0.006,
									0.009,
									0.32,
									0.049
								],
								"e": 0.123
							},
							{
								"h": [
									0.002,
									0.151,
									0.004,
									0.0,
									0.025,
									0.153,
									0.164,
									0.0,
									0.002,
									0.031,
									0.017,
									0.393
								],
								"e": 0.065
							},
							{
								"h": [
									0.003,
									0.042,
									0.014,
									0.066,
									0.005,
									0.013,
									0.061,
									0.009,
									0.003,
									0.043,
									0.007,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.002,
									0.0,
									0.014,
									0.085,
									0.003,
									0.025,
									0.0,
									0.011,
									0.011,
									0.025,
									0.014,
									0.007
								],
								"e": 0.018
							},
							{
								"h": [
									0.081,
									0.003,
									0.086,
									0.029,
									0.04,
									0.108,
									0.006,
									0.278,
									0.064,
									0.001,
									0.008,
									1.0
								],
								"e": 0.128
							},
							{
								"h": [
									0.003,
									0.055,
									0.002,
									0.044,
									0.011,
									0.027,
									0.024,
									0.002,
									0.229,
									0.518,
									0.001,
									0.002
								],
								"e": 0.06
							},
							{
								"h": [
									0.026,
									0.001,
									0.025,
									0.003,
									0.09,
									0.295,
									0.003,
									0.001,
									0.003,
									0.028,
									0.02,
									0.001
								],
								"e": 0.037
							},
							{
								"h": [
									0.016,
									0.006,
									0.003,
									0.024,
									0.007,
									0.009,
									0.0,
									0.041,
									0.008,
									0.001,
									0.021,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.002,
									0.008,
									0.002,
									0.039,
									0.31,
									0.002,
									0.002,
									0.008,
									0.024,
									0.004,
									0.001
								],
								"e": 0.018
							},
							{
								"h": [
									0.421,
									0.001,
									0.0,
									0.021,
									0.008,
									0.004,
									0.0,
									0.002,
									0.242,
									0.002,
									0.026,
									0.063
								],
								"e": 0.023
							}
						],
						[
							{
								"h": [
									0.197,
									0.001,
									0.27,
									0.004,
									0.022,
									0.001,
									0.227,
									0.145,
									0.106,
									0.439,
									0.132,
									0.297
								],
								"e": 0.131
							},
							{
								"h": [
									0.08,
									0.256,
									0.502,
									0.048,
									0.319,
									0.067,
									0.143,
									0.236,
									0.263,
									0.127,
									0.499,
									0.006
								],
								"e": 0.244
							},
							{
								"h": [
									0.095,
									0.041,
									0.003,
									0.161,
									0.012,
									0.03,
									0.052,
									0.46,
									0.018,
									0.02,
									0.445,
									0.046
								],
								"e": 0.116
							},
							{
								"h": [
									0.001,
									0.076,
									0.004,
									0.001,
									0.018,
									0.393,
									0.041,
									0.001,
									0.061,
									1.0,
									0.015,
									1.0
								],
								"e": 0.167
							},
							{
								"h": [
									0.003,
									0.108,
									0.031,
									0.013,
									0.01,
									0.01,
									0.053,
									0.011,
									0.002,
									0.173,
									0.013,
									0.0
								],
								"e": 0.032
							},
							{
								"h": [
									0.0,
									0.0,
									0.012,
									0.209,
									0.003,
									0.0,
									0.0,
									0.004,
									0.0,
									0.014,
									0.007,
									0.004
								],
								"e": 0.02
							},
							{
								"h": [
									0.069,
									0.0,
									0.06,
									0.043,
									0.081,
									0.129,
									0.051,
									0.051,
									0.054,
									0.001,
									0.008,
									0.405
								],
								"e": 0.08
							},
							{
								"h": [
									0.019,
									0.03,
									0.001,
									0.616,
									0.01,
									0.113,
									0.017,
									0.0,
									0.071,
									0.902,
									0.002,
									0.002
								],
								"e": 0.121
							},
							{
								"h": [
									0.037,
									0.001,
									0.022,
									0.002,
									0.194,
									0.538,
									0.004,
									0.006,
									0.003,
									0.03,
									0.116,
									0.004
								],
								"e": 0.071
							},
							{
								"h": [
									0.043,
									0.004,
									0.014,
									0.087,
									0.001,
									0.005,
									0.005,
									0.014,
									0.007,
									0.005,
									0.185,
									0.001
								],
								"e": 0.026
							},
							{
								"h": [
									0.002,
									0.005,
									0.004,
									0.012,
									0.14,
									0.767,
									0.003,
									0.015,
									0.002,
									0.015,
									0.015,
									0.013
								],
								"e": 0.044
							},
							{
								"h": [
									0.392,
									0.001,
									0.002,
									0.013,
									0.007,
									0.03,
									0.001,
									0.003,
									0.101,
									0.03,
									0.031,
									0.056
								],
								"e": 0.022
							}
						],
						[
							{
								"h": [
									0.103,
									0.003,
									0.349,
									0.003,
									0.007,
									0.0,
									0.072,
									0.321,
									0.267,
									0.628,
									0.257,
									0.322
								],
								"e": 0.167
							},
							{
								"h": [
									0.205,
									0.622,
									0.899,
									0.069,
									0.348,
									0.344,
									0.095,
									0.141,
									0.41,
									0.233,
									0.657,
									0.02
								],
								"e": 0.393
							},
							{
								"h": [
									0.85,
									0.009,
									0.041,
									0.805,
									0.074,
									0.243,
									0.175,
									0.532,
									0.057,
									0.01,
									0.407,
									0.054
								],
								"e": 0.288
							},
							{
								"h": [
									0.004,
									0.097,
									0.005,
									0.005,
									0.012,
									0.155,
									0.026,
									0.001,
									0.224,
									0.305,
									0.073,
									0.417
								],
								"e": 0.091
							},
							{
								"h": [
									0.011,
									0.165,
									0.04,
									0.493,
									0.041,
									0.027,
									0.107,
									0.057,
									0.006,
									0.605,
									0.018,
									0.0
								],
								"e": 0.107
							},
							{
								"h": [
									0.0,
									0.0,
									1.0,
									1.0,
									0.011,
									0.001,
									0.0,
									0.039,
									0.005,
									0.046,
									0.023,
									0.026
								],
								"e": 0.206
							},
							{
								"h": [
									0.074,
									0.001,
									0.052,
									0.108,
									0.035,
									0.098,
									0.047,
									0.658,
									0.067,
									0.0,
									0.011,
									0.559
								],
								"e": 0.115
							},
							{
								"h": [
									0.003,
									0.058,
									0.001,
									0.67,
									0.24,
									0.158,
									0.045,
									0.001,
									0.546,
									0.069,
									0.002,
									0.001
								],
								"e": 0.138
							},
							{
								"h": [
									0.035,
									0.007,
									0.126,
									0.003,
									0.006,
									0.268,
									0.009,
									0.001,
									0.006,
									0.013,
									0.056,
									0.002
								],
								"e": 0.036
							},
							{
								"h": [
									0.034,
									0.005,
									0.011,
									0.022,
									0.004,
									0.003,
									0.001,
									0.054,
									0.004,
									0.002,
									0.076,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.004,
									0.015,
									0.008,
									0.082,
									0.364,
									0.003,
									0.01,
									0.005,
									0.012,
									0.013,
									0.001
								],
								"e": 0.024
							},
							{
								"h": [
									0.508,
									0.001,
									0.001,
									0.01,
									0.006,
									0.02,
									0.0,
									0.002,
									0.104,
									0.011,
									0.035,
									0.063
								],
								"e": 0.021
							}
						],
						[
							{
								"h": [
									0.585,
									0.007,
									0.276,
									0.014,
									1.0,
									0.001,
									0.758,
									0.28,
									0.197,
									0.448,
									0.411,
									0.163
								],
								"e": 0.57
							},
							{
								"h": [
									0.287,
									0.37,
									0.798,
									0.063,
									0.478,
									0.405,
									0.719,
									0.405,
									0.244,
									0.21,
									0.709,
									0.087
								],
								"e": 0.506
							},
							{
								"h": [
									0.48,
									0.036,
									0.103,
									0.361,
									0.035,
									0.179,
									0.137,
									0.803,
									0.009,
									0.296,
									0.676,
									0.078
								],
								"e": 0.306
							},
							{
								"h": [
									0.011,
									0.267,
									0.049,
									0.003,
									0.063,
									0.353,
									0.28,
									0.019,
									0.066,
									0.097,
									0.105,
									0.303
								],
								"e": 0.129
							},
							{
								"h": [
									0.019,
									0.221,
									0.055,
									1.0,
									0.072,
									0.311,
									0.153,
									0.344,
									0.003,
									0.286,
									0.014,
									0.0
								],
								"e": 0.171
							},
							{
								"h": [
									0.003,
									0.0,
									0.045,
									0.298,
									0.053,
									0.005,
									0.001,
									0.028,
									0.017,
									0.072,
									0.171,
									0.048
								],
								"e": 0.069
							},
							{
								"h": [
									0.915,
									0.005,
									0.025,
									0.344,
									0.139,
									0.1,
									0.02,
									0.416,
									0.034,
									0.0,
									0.011,
									0.08
								],
								"e": 0.134
							},
							{
								"h": [
									0.003,
									0.007,
									0.0,
									0.063,
									0.053,
									0.019,
									0.028,
									0.001,
									0.476,
									0.174,
									0.0,
									0.001
								],
								"e": 0.056
							},
							{
								"h": [
									0.046,
									0.001,
									0.133,
									0.001,
									0.013,
									0.245,
									0.001,
									0.002,
									0.004,
									0.015,
									0.008,
									0.001
								],
								"e": 0.032
							},
							{
								"h": [
									0.009,
									0.003,
									0.002,
									0.065,
									0.001,
									0.006,
									0.001,
									0.051,
									0.003,
									0.001,
									0.037,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.002,
									0.003,
									0.002,
									0.002,
									0.079,
									0.135,
									0.001,
									0.003,
									0.008,
									0.023,
									0.002,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.267,
									0.002,
									0.001,
									0.014,
									0.008,
									0.01,
									0.0,
									0.003,
									0.611,
									0.001,
									0.016,
									0.035
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.432,
									0.0,
									1.0,
									0.13,
									0.009,
									0.0,
									0.229,
									0.477,
									0.133,
									0.565,
									0.147,
									0.261
								],
								"e": 0.285
							},
							{
								"h": [
									1.0,
									0.935,
									1.0,
									0.085,
									0.533,
									0.164,
									0.121,
									0.168,
									0.296,
									0.155,
									1.0,
									0.007
								],
								"e": 0.609
							},
							{
								"h": [
									0.428,
									0.029,
									0.522,
									1.0,
									1.0,
									0.962,
									0.114,
									1.0,
									1.0,
									0.195,
									1.0,
									0.101
								],
								"e": 1.0
							},
							{
								"h": [
									0.003,
									0.234,
									0.368,
									0.093,
									0.021,
									0.536,
									0.063,
									0.047,
									0.927,
									0.905,
									0.148,
									0.463
								],
								"e": 0.3
							},
							{
								"h": [
									0.396,
									0.864,
									0.048,
									0.235,
									0.061,
									0.563,
									0.201,
									0.271,
									0.004,
									1.0,
									0.01,
									0.0
								],
								"e": 0.257
							},
							{
								"h": [
									0.01,
									0.0,
									0.126,
									0.515,
									0.072,
									0.061,
									0.005,
									0.115,
									0.412,
									0.089,
									0.351,
									0.095
								],
								"e": 0.18
							},
							{
								"h": [
									1.0,
									0.008,
									0.015,
									1.0,
									0.069,
									0.064,
									0.051,
									0.279,
									0.094,
									0.007,
									0.012,
									0.054
								],
								"e": 0.169
							},
							{
								"h": [
									0.005,
									0.054,
									0.002,
									1.0,
									0.18,
									0.081,
									0.06,
									0.0,
									0.972,
									0.093,
									0.005,
									0.007
								],
								"e": 0.17
							},
							{
								"h": [
									0.02,
									0.018,
									0.089,
									0.013,
									0.014,
									0.042,
									0.024,
									0.001,
									0.054,
									0.103,
									0.221,
									0.008
								],
								"e": 0.045
							},
							{
								"h": [
									0.033,
									0.02,
									0.014,
									0.005,
									0.004,
									0.003,
									0.004,
									0.04,
									0.004,
									0.013,
									0.047,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.01,
									0.009,
									0.025,
									0.023,
									0.079,
									0.132,
									0.013,
									0.068,
									0.009,
									0.031,
									0.011,
									0.015
								],
								"e": 0.029
							},
							{
								"h": [
									0.297,
									0.001,
									0.001,
									0.005,
									0.004,
									0.038,
									0.001,
									0.001,
									0.034,
									0.009,
									0.01,
									0.029
								],
								"e": 0.013
							}
						],
						[
							{
								"h": [
									0.27,
									0.0,
									0.118,
									0.005,
									0.005,
									0.0,
									1.0,
									0.376,
									0.261,
									0.374,
									0.125,
									0.221
								],
								"e": 0.205
							},
							{
								"h": [
									0.163,
									0.53,
									0.89,
									0.08,
									0.606,
									0.079,
									0.094,
									0.302,
									0.204,
									0.076,
									0.872,
									0.003
								],
								"e": 0.365
							},
							{
								"h": [
									0.946,
									0.011,
									1.0,
									0.786,
									0.015,
									1.0,
									0.057,
									0.852,
									0.113,
									1.0,
									0.718,
									0.107
								],
								"e": 0.784
							},
							{
								"h": [
									0.0,
									0.114,
									1.0,
									0.216,
									0.005,
									0.058,
									1.0,
									1.0,
									0.623,
									0.104,
									0.135,
									0.433
								],
								"e": 0.524
							},
							{
								"h": [
									0.218,
									0.405,
									0.014,
									0.571,
									0.01,
									0.796,
									0.829,
									1.0,
									0.003,
									0.282,
									0.003,
									1.0
								],
								"e": 0.495
							},
							{
								"h": [
									0.017,
									0.0,
									0.185,
									0.17,
									1.0,
									0.033,
									0.1,
									0.041,
									0.234,
									0.062,
									0.029,
									0.065
								],
								"e": 0.254
							},
							{
								"h": [
									0.089,
									0.013,
									0.034,
									0.146,
									0.018,
									0.469,
									0.031,
									0.36,
									1.0,
									0.003,
									0.011,
									0.02
								],
								"e": 0.211
							},
							{
								"h": [
									0.015,
									0.013,
									0.0,
									0.104,
									0.033,
									0.003,
									0.032,
									0.007,
									0.036,
									0.047,
									0.0,
									0.005
								],
								"e": 0.025
							},
							{
								"h": [
									0.017,
									0.001,
									0.052,
									0.003,
									0.155,
									0.079,
									0.003,
									0.017,
									0.006,
									0.112,
									0.047,
									0.0
								],
								"e": 0.039
							},
							{
								"h": [
									0.001,
									0.0,
									0.002,
									0.262,
									0.0,
									0.001,
									0.001,
									0.038,
									0.001,
									0.0,
									0.023,
									0.001
								],
								"e": 0.022
							},
							{
								"h": [
									0.003,
									0.003,
									0.002,
									0.002,
									0.004,
									0.123,
									0.002,
									0.001,
									0.002,
									0.021,
									0.003,
									0.004
								],
								"e": 0.009
							},
							{
								"h": [
									0.397,
									0.003,
									0.005,
									0.026,
									0.003,
									0.005,
									0.002,
									0.005,
									0.066,
									0.001,
									0.001,
									0.103
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.313,
									1.0,
									0.096,
									1.0,
									0.036,
									0.515,
									0.048,
									1.0,
									0.41,
									0.603,
									1.0,
									0.29
								],
								"e": 1.0
							},
							{
								"h": [
									0.559,
									0.765,
									0.947,
									0.079,
									0.455,
									1.0,
									0.078,
									0.158,
									0.298,
									0.29,
									0.828,
									1.0
								],
								"e": 0.88
							},
							{
								"h": [
									1.0,
									0.021,
									0.578,
									0.812,
									0.172,
									0.819,
									0.284,
									0.893,
									0.101,
									0.165,
									0.877,
									0.125
								],
								"e": 0.6
							},
							{
								"h": [
									0.051,
									0.428,
									0.292,
									0.185,
									0.032,
									0.069,
									0.726,
									0.057,
									0.62,
									0.291,
									0.246,
									0.349
								],
								"e": 0.295
							},
							{
								"h": [
									0.281,
									1.0,
									0.022,
									0.147,
									0.077,
									0.047,
									0.279,
									0.845,
									0.01,
									0.145,
									0.026,
									0.001
								],
								"e": 0.209
							},
							{
								"h": [
									0.021,
									0.001,
									0.02,
									0.145,
									0.197,
									0.047,
									0.265,
									0.06,
									0.122,
									0.048,
									0.031,
									0.299
								],
								"e": 0.16
							},
							{
								"h": [
									0.173,
									0.003,
									0.005,
									0.091,
									0.065,
									0.12,
									0.115,
									0.141,
									0.027,
									0.002,
									0.022,
									0.064
								],
								"e": 0.066
							},
							{
								"h": [
									0.043,
									0.226,
									0.01,
									0.203,
									0.011,
									0.029,
									0.117,
									0.007,
									0.055,
									0.361,
									0.011,
									0.016
								],
								"e": 0.087
							},
							{
								"h": [
									0.1,
									0.061,
									0.094,
									0.033,
									0.142,
									0.044,
									0.041,
									0.037,
									0.004,
									0.02,
									0.376,
									0.001
								],
								"e": 0.083
							},
							{
								"h": [
									0.026,
									0.023,
									0.003,
									0.028,
									0.002,
									0.043,
									0.003,
									0.01,
									0.005,
									0.005,
									0.116,
									0.004
								],
								"e": 0.02
							},
							{
								"h": [
									0.002,
									0.072,
									0.019,
									0.018,
									0.1,
									0.385,
									0.002,
									0.029,
									0.062,
									0.033,
									0.018,
									0.063
								],
								"e": 0.049
							},
							{
								"h": [
									0.493,
									0.009,
									0.004,
									0.009,
									0.039,
									0.045,
									0.002,
									0.011,
									0.881,
									0.018,
									0.046,
									0.113
								],
								"e": 0.056
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				}
			]
		},
		{
			"c": [
				{
					"i": "Washington",
					"o": ",",
					"t": ",",
					"r": [
						{
							"p": " Washington",
							"b": 0.035,
							"k": {
								" Washington": 0.126,
								",": 0.035,
								" and": 0.023,
								" ": 0.02,
								" the": 0.014,
								" State": 0.014,
								".": 0.013,
								" (": 0.013,
								"Washington": 0.012,
								" Defense": 0.01
							}
						},
						{
							"p": ",",
							"b": 0.071,
							"k": {
								",": 0.071,
								" the": 0.046,
								" and": 0.04,
								".": 0.031,
								" ": 0.029,
								" in": 0.022,
								" to": 0.019,
								" a": 0.018,
								" (": 0.016,
								"-": 0.014
							}
						},
						{
							"p": ",",
							"b": 0.029,
							"k": {
								",": 0.029,
								" the": 0.026,
								" and": 0.019,
								".": 0.015,
								" ": 0.015,
								" in": 0.014,
								" a": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.029,
							"k": {
								",": 0.029,
								" the": 0.027,
								" and": 0.019,
								".": 0.015,
								" ": 0.015,
								" a": 0.014,
								" in": 0.014,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.029,
							"k": {
								",": 0.029,
								" the": 0.027,
								" and": 0.019,
								".": 0.015,
								" ": 0.015,
								" a": 0.014,
								" in": 0.014,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.029,
							"k": {
								",": 0.029,
								" the": 0.026,
								" and": 0.019,
								".": 0.015,
								" ": 0.015,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.028,
							"k": {
								",": 0.028,
								" the": 0.026,
								" and": 0.019,
								".": 0.015,
								" ": 0.015,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.028,
							"k": {
								",": 0.028,
								" the": 0.026,
								" and": 0.019,
								".": 0.015,
								" ": 0.014,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.029,
							"k": {
								",": 0.029,
								" the": 0.026,
								" and": 0.019,
								".": 0.015,
								" ": 0.015,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.029,
							"k": {
								",": 0.029,
								" the": 0.026,
								" and": 0.019,
								".": 0.015,
								" ": 0.015,
								" in": 0.014,
								" a": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.03,
							"k": {
								",": 0.03,
								" the": 0.026,
								" and": 0.019,
								".": 0.016,
								" ": 0.015,
								" in": 0.014,
								" a": 0.013,
								" to": 0.011,
								"-": 0.01,
								" of": 0.01
							}
						},
						{
							"p": ",",
							"b": 0.079,
							"k": {
								",": 0.079,
								".": 0.064,
								" and": 0.035,
								" ": 0.02,
								" to": 0.019,
								"'s": 0.018,
								" the": 0.014,
								" of": 0.014,
								"-": 0.013,
								" in": 0.013
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ",",
					"o": " D",
					"t": " DC",
					"r": [
						{
							"p": " which",
							"b": 0.0,
							"k": {
								" which": 0.063,
								" and": 0.043,
								" including": 0.015,
								" the": 0.014,
								" a": 0.013,
								" D": 0.013,
								" especially": 0.013,
								" but": 0.01,
								" although": 0.009,
								" let": 0.008
							}
						},
						{
							"p": " which",
							"b": 0.0,
							"k": {
								" which": 0.115,
								" and": 0.048,
								" Mess": 0.048,
								" including": 0.034,
								" D": 0.021,
								" especially": 0.021,
								" but": 0.013,
								" although": 0.01,
								" Qu": 0.009,
								" particularly": 0.009
							}
						},
						{
							"p": " which",
							"b": 0.001,
							"k": {
								" which": 0.117,
								" Mess": 0.088,
								" and": 0.039,
								" USA": 0.032,
								" D": 0.031,
								" including": 0.031,
								" Re": 0.027,
								" L": 0.008,
								" especially": 0.008,
								" Space": 0.008
							}
						},
						{
							"p": " Mess",
							"b": 0.001,
							"k": {
								" Mess": 0.14,
								" which": 0.115,
								" USA": 0.056,
								" Re": 0.035,
								" and": 0.03,
								" D": 0.022,
								" including": 0.014,
								" Space": 0.014,
								" Inc": 0.012,
								" L": 0.008
							}
						},
						{
							"p": " which",
							"b": 0.001,
							"k": {
								" which": 0.068,
								" and": 0.059,
								" Mess": 0.053,
								" USA": 0.042,
								" D": 0.033,
								" Times": 0.027,
								" Re": 0.024,
								" Sun": 0.017,
								" State": 0.012,
								" Aug": 0.011
							}
						},
						{
							"p": " USA",
							"b": 0.01,
							"k": {
								" USA": 0.144,
								" Times": 0.066,
								" D": 0.051,
								" and": 0.045,
								" which": 0.045,
								" State": 0.034,
								" NY": 0.016,
								" where": 0.01,
								" Fire": 0.01,
								" DC": 0.01
							}
						},
						{
							"p": " D",
							"b": 0.049,
							"k": {
								" D": 0.161,
								" USA": 0.122,
								" NY": 0.054,
								" DC": 0.049,
								" and": 0.04,
								" which": 0.026,
								" State": 0.024,
								" Fire": 0.013,
								" Mar": 0.012,
								" PA": 0.011
							}
						},
						{
							"p": " USA",
							"b": 0.14,
							"k": {
								" USA": 0.244,
								" D": 0.228,
								" DC": 0.14,
								" NY": 0.092,
								" State": 0.016,
								" PA": 0.016,
								" Ohio": 0.013,
								" Mar": 0.011,
								" Illinois": 0.01,
								" Washington": 0.008
							}
						},
						{
							"p": " USA",
							"b": 0.072,
							"k": {
								" USA": 0.305,
								" D": 0.193,
								" NY": 0.15,
								" DC": 0.072,
								" PA": 0.036,
								" Ohio": 0.028,
								" UK": 0.014,
								" Pennsylvania": 0.014,
								" Illinois": 0.01,
								" State": 0.008
							}
						},
						{
							"p": " D",
							"b": 0.173,
							"k": {
								" D": 0.243,
								" NY": 0.23,
								" DC": 0.173,
								" USA": 0.164,
								" Illinois": 0.011,
								" Pennsylvania": 0.011,
								" PA": 0.009,
								" Minnesota": 0.008,
								" NJ": 0.008,
								" Washington": 0.007
							}
						},
						{
							"p": " D",
							"b": 0.104,
							"k": {
								" D": 0.326,
								" DC": 0.104,
								" NY": 0.042,
								" USA": 0.019,
								" Illinois": 0.015,
								" Washington": 0.014,
								" PA": 0.014,
								" New": 0.013,
								" N": 0.011,
								" Ohio": 0.011
							}
						},
						{
							"p": " D",
							"b": 0.069,
							"k": {
								" D": 0.34,
								" DC": 0.069,
								" NY": 0.033,
								" and": 0.015,
								" N": 0.015,
								" New": 0.015,
								" PA": 0.015,
								"D": 0.008,
								" CA": 0.008,
								" the": 0.006
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.006,
									0.698,
									1.0,
									1.0,
									0.295,
									1.0,
									1.0,
									0.412,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.048,
									1.0,
									1.0,
									0.742,
									0.066,
									1.0,
									0.008,
									0.119,
									1.0,
									0.551,
									0.479,
									0.684
								],
								"e": 0.666
							},
							{
								"h": [
									0.012,
									0.09,
									0.029,
									0.781,
									0.079,
									0.243,
									0.034,
									0.056,
									0.259,
									0.092,
									1.0,
									0.176
								],
								"e": 0.244
							},
							{
								"h": [
									0.037,
									0.005,
									0.002,
									0.012,
									0.017,
									0.061,
									0.024,
									0.142,
									0.019,
									0.005,
									0.052,
									0.035
								],
								"e": 0.033
							},
							{
								"h": [
									0.002,
									0.032,
									0.056,
									0.067,
									0.01,
									0.014,
									0.015,
									0.024,
									0.133,
									0.019,
									0.003,
									0.011
								],
								"e": 0.031
							},
							{
								"h": [
									0.045,
									0.012,
									0.023,
									0.051,
									0.046,
									0.011,
									0.03,
									0.003,
									0.015,
									0.014,
									0.027,
									0.0
								],
								"e": 0.023
							},
							{
								"h": [
									0.07,
									0.0,
									0.145,
									0.037,
									0.051,
									0.019,
									0.174,
									0.023,
									0.04,
									0.015,
									0.461,
									0.092
								],
								"e": 0.083
							},
							{
								"h": [
									0.028,
									0.056,
									0.015,
									0.023,
									0.057,
									0.038,
									0.086,
									0.046,
									0.074,
									0.143,
									0.044,
									0.02
								],
								"e": 0.052
							},
							{
								"h": [
									0.055,
									0.004,
									0.003,
									0.015,
									0.005,
									0.024,
									0.063,
									0.045,
									0.146,
									0.031,
									0.047,
									0.041
								],
								"e": 0.039
							},
							{
								"h": [
									0.021,
									0.018,
									0.033,
									0.01,
									0.042,
									0.012,
									0.044,
									0.048,
									0.023,
									0.042,
									0.037,
									0.203
								],
								"e": 0.042
							},
							{
								"h": [
									0.05,
									0.012,
									0.007,
									0.041,
									0.007,
									0.039,
									0.014,
									0.014,
									0.303,
									0.004,
									0.012,
									0.069
								],
								"e": 0.043
							},
							{
								"h": [
									0.115,
									0.016,
									0.022,
									0.143,
									0.018,
									0.013,
									0.037,
									0.105,
									0.013,
									0.031,
									0.028,
									0.026
								],
								"e": 0.045
							},
							{
								"h": [
									0.222,
									0.086,
									0.197,
									0.129,
									0.209,
									0.028,
									0.015,
									0.05,
									1.0,
									0.021,
									0.137,
									0.062
								],
								"e": 0.2
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " DC",
					"o": ")",
					"t": " is",
					"r": [
						{
							"p": " DC",
							"b": 0.0,
							"k": {
								" DC": 0.979,
								"DC": 0.018,
								" Comics": 0.002,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " DC",
							"b": 0.0,
							"k": {
								" DC": 0.974,
								"DC": 0.013,
								" Comics": 0.005,
								" State": 0.003,
								" state": 0.001,
								"AT": 0.001,
								"MS": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0
							}
						},
						{
							"p": " DC",
							"b": 0.0,
							"k": {
								" DC": 0.894,
								" Comics": 0.061,
								"DC": 0.014,
								" State": 0.007,
								"MS": 0.007,
								"MC": 0.003,
								" D": 0.001,
								" Washington": 0.001,
								"AP": 0.001,
								"NF": 0.001
							}
						},
						{
							"p": " Comics",
							"b": 0.0,
							"k": {
								" Comics": 0.402,
								" DC": 0.369,
								" State": 0.126,
								"NF": 0.011,
								"DC": 0.01,
								" City": 0.009,
								" Branch": 0.006,
								" metro": 0.006,
								"WD": 0.005,
								" state": 0.003
							}
						},
						{
							"p": " State",
							"b": 0.0,
							"k": {
								" State": 0.387,
								" Comics": 0.312,
								" DC": 0.092,
								" Branch": 0.065,
								" City": 0.028,
								" Chase": 0.014,
								" District": 0.011,
								",": 0.006,
								" Post": 0.005,
								" Bridge": 0.004
							}
						},
						{
							"p": " State",
							"b": 0.0,
							"k": {
								" State": 0.24,
								",": 0.133,
								" Comics": 0.105,
								"NF": 0.053,
								" DC": 0.047,
								".": 0.039,
								" SW": 0.026,
								" Branch": 0.016,
								" Rex": 0.015,
								" USA": 0.012
							}
						},
						{
							"p": ",",
							"b": 0.0,
							"k": {
								",": 0.281,
								" Comics": 0.179,
								".": 0.072,
								" State": 0.07,
								" USA": 0.037,
								"NF": 0.032,
								" area": 0.016,
								";": 0.015,
								")": 0.013,
								"/": 0.013
							}
						},
						{
							"p": ",",
							"b": 0.0,
							"k": {
								",": 0.325,
								" USA": 0.165,
								".": 0.079,
								" Comics": 0.047,
								")": 0.045,
								";": 0.034,
								"/": 0.032,
								" area": 0.032,
								" State": 0.021,
								" Area": 0.015
							}
						},
						{
							"p": ",",
							"b": 0.0,
							"k": {
								",": 0.452,
								")": 0.17,
								";": 0.075,
								".": 0.074,
								"/": 0.043,
								" USA": 0.025,
								" Comics": 0.025,
								" ": 0.012,
								" \u00e2\u0122\u0135": 0.012,
								":": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.0,
							"k": {
								",": 0.391,
								")": 0.373,
								";": 0.048,
								".": 0.029,
								"),": 0.029,
								"/": 0.019,
								").": 0.012,
								" \u00e2\u0122\u0135": 0.011,
								":": 0.01,
								" USA": 0.008
							}
						},
						{
							"p": ")",
							"b": 0.0,
							"k": {
								")": 0.791,
								",": 0.051,
								"),": 0.049,
								").": 0.032,
								";": 0.012,
								".": 0.01,
								");": 0.008,
								":": 0.004,
								"]": 0.003,
								".)": 0.003
							}
						},
						{
							"p": ")",
							"b": 0.001,
							"k": {
								")": 0.484,
								",": 0.086,
								").": 0.039,
								".": 0.034,
								"),": 0.03,
								" -": 0.017,
								" \u00e2\u0122\u0135": 0.016,
								";": 0.015,
								" ": 0.012,
								".)": 0.01
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.001,
									1.0,
									0.103,
									1.0,
									0.091,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									0.293
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.611,
									1.0,
									1.0,
									0.944,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.529,
									0.882,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.65,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.384,
									1.0,
									1.0,
									1.0,
									0.963,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.816,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.503,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.673,
									0.0,
									0.159,
									0.001,
									0.026,
									0.0,
									0.185,
									0.245,
									0.443,
									0.882,
									0.464,
									0.516
								],
								"e": 0.349
							},
							{
								"h": [
									0.456,
									0.894,
									0.114,
									0.397,
									0.101,
									0.332,
									0.078,
									0.165,
									0.257,
									0.096,
									0.865,
									0.073
								],
								"e": 0.312
							},
							{
								"h": [
									0.144,
									0.036,
									1.0,
									0.207,
									0.382,
									1.0,
									0.089,
									0.097,
									0.366,
									0.173,
									0.095,
									0.046
								],
								"e": 0.271
							},
							{
								"h": [
									0.016,
									0.06,
									1.0,
									1.0,
									0.079,
									0.058,
									0.216,
									0.627,
									0.708,
									0.152,
									0.007,
									0.189
								],
								"e": 0.281
							},
							{
								"h": [
									0.139,
									0.402,
									0.051,
									0.549,
									0.057,
									0.063,
									0.252,
									0.008,
									0.044,
									0.312,
									0.05,
									1.0
								],
								"e": 0.277
							},
							{
								"h": [
									0.054,
									0.0,
									1.0,
									0.2,
									0.253,
									0.005,
									1.0,
									0.04,
									0.162,
									0.07,
									0.191,
									0.173
								],
								"e": 0.22
							},
							{
								"h": [
									0.193,
									0.29,
									0.04,
									0.206,
									0.03,
									0.031,
									0.074,
									0.163,
									1.0,
									0.0,
									0.011,
									0.036
								],
								"e": 0.139
							},
							{
								"h": [
									0.204,
									0.016,
									0.001,
									0.075,
									0.519,
									0.085,
									0.036,
									0.018,
									0.533,
									0.017,
									0.0,
									0.14
								],
								"e": 0.111
							},
							{
								"h": [
									0.022,
									0.006,
									0.158,
									0.004,
									0.021,
									0.212,
									0.065,
									0.11,
									0.037,
									0.022,
									0.025,
									0.06
								],
								"e": 0.056
							},
							{
								"h": [
									1.0,
									0.016,
									0.005,
									0.121,
									0.005,
									0.02,
									0.025,
									0.021,
									0.035,
									0.002,
									0.03,
									0.014
								],
								"e": 0.085
							},
							{
								"h": [
									0.012,
									0.002,
									0.058,
									0.027,
									0.062,
									0.09,
									0.042,
									0.035,
									0.012,
									0.076,
									0.008,
									0.013
								],
								"e": 0.035
							},
							{
								"h": [
									0.637,
									0.038,
									0.022,
									0.159,
									0.073,
									0.038,
									0.004,
									0.032,
									1.0,
									0.018,
									0.067,
									0.147
								],
								"e": 0.138
							}
						],
						[
							{
								"h": [
									0.427,
									1.0,
									0.188,
									1.0,
									0.45,
									1.0,
									0.187,
									0.073,
									0.406,
									0.093,
									0.613,
									0.397
								],
								"e": 0.789
							},
							{
								"h": [
									0.228,
									0.171,
									0.187,
									0.378,
									0.153,
									0.104,
									0.034,
									0.093,
									0.088,
									0.046,
									1.0,
									1.0
								],
								"e": 0.302
							},
							{
								"h": [
									0.293,
									0.027,
									0.051,
									0.201,
									0.106,
									0.049,
									0.062,
									0.637,
									0.079,
									0.171,
									0.212,
									0.099
								],
								"e": 0.161
							},
							{
								"h": [
									0.027,
									0.05,
									0.206,
									0.025,
									0.012,
									0.007,
									0.486,
									0.435,
									0.081,
									0.075,
									0.144,
									0.239
								],
								"e": 0.131
							},
							{
								"h": [
									0.054,
									0.241,
									0.006,
									0.307,
									0.16,
									0.038,
									0.132,
									1.0,
									0.028,
									0.234,
									0.063,
									0.0
								],
								"e": 0.177
							},
							{
								"h": [
									0.006,
									0.001,
									0.056,
									0.205,
									0.141,
									0.012,
									0.295,
									0.022,
									0.242,
									0.005,
									0.033,
									0.175
								],
								"e": 0.086
							},
							{
								"h": [
									0.086,
									0.016,
									0.016,
									0.135,
									0.017,
									0.052,
									0.119,
									0.113,
									0.03,
									0.001,
									0.058,
									0.008
								],
								"e": 0.052
							},
							{
								"h": [
									0.118,
									0.105,
									0.003,
									0.063,
									0.128,
									0.101,
									0.038,
									0.041,
									0.081,
									0.007,
									0.001,
									0.007
								],
								"e": 0.053
							},
							{
								"h": [
									0.008,
									0.003,
									0.303,
									0.002,
									0.014,
									0.09,
									0.007,
									0.031,
									0.01,
									0.028,
									0.037,
									0.146
								],
								"e": 0.049
							},
							{
								"h": [
									0.029,
									0.003,
									0.003,
									0.096,
									0.004,
									0.06,
									0.002,
									0.022,
									0.056,
									0.002,
									0.02,
									0.004
								],
								"e": 0.024
							},
							{
								"h": [
									0.006,
									0.001,
									0.004,
									0.005,
									0.014,
									0.021,
									0.006,
									0.046,
									0.017,
									0.024,
									0.003,
									0.004
								],
								"e": 0.012
							},
							{
								"h": [
									0.551,
									0.008,
									0.01,
									0.034,
									0.035,
									0.011,
									0.006,
									0.006,
									0.891,
									0.004,
									0.096,
									0.065
								],
								"e": 0.099
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " is",
					"o": " a",
					"t": " the",
					"r": [
						{
							"p": " not",
							"b": 0.012,
							"k": {
								" not": 0.251,
								" now": 0.101,
								" also": 0.094,
								" still": 0.065,
								" a": 0.049,
								" likely": 0.036,
								" unlikely": 0.015,
								" more": 0.013,
								" one": 0.013,
								" very": 0.013
							}
						},
						{
							"p": " not",
							"b": 0.003,
							"k": {
								" not": 0.392,
								" now": 0.199,
								" also": 0.094,
								" still": 0.065,
								" likely": 0.021,
								" unlikely": 0.017,
								" currently": 0.015,
								" a": 0.014,
								" often": 0.013,
								" always": 0.008
							}
						},
						{
							"p": " now",
							"b": 0.003,
							"k": {
								" now": 0.255,
								" not": 0.222,
								" still": 0.129,
								" also": 0.076,
								" currently": 0.04,
								" likely": 0.028,
								" a": 0.015,
								" unlikely": 0.014,
								" often": 0.01,
								" always": 0.009
							}
						},
						{
							"p": " not",
							"b": 0.004,
							"k": {
								" not": 0.255,
								" now": 0.211,
								" also": 0.115,
								" still": 0.043,
								" currently": 0.034,
								" a": 0.021,
								" generally": 0.012,
								" often": 0.011,
								" likely": 0.011,
								" definitely": 0.011
							}
						},
						{
							"p": " now",
							"b": 0.009,
							"k": {
								" now": 0.242,
								" also": 0.149,
								" not": 0.13,
								" a": 0.031,
								" likely": 0.027,
								" probably": 0.027,
								" still": 0.025,
								" proud": 0.02,
								" definitely": 0.019,
								" often": 0.018
							}
						},
						{
							"p": " also",
							"b": 0.003,
							"k": {
								" also": 0.276,
								" now": 0.244,
								" not": 0.137,
								" proud": 0.031,
								" still": 0.022,
								" definitely": 0.02,
								" currently": 0.018,
								" probably": 0.017,
								" a": 0.01,
								" just": 0.009
							}
						},
						{
							"p": " also",
							"b": 0.003,
							"k": {
								" also": 0.34,
								" now": 0.189,
								" not": 0.162,
								" a": 0.023,
								" currently": 0.015,
								" proud": 0.014,
								" hereby": 0.013,
								" set": 0.012,
								" located": 0.011,
								" officially": 0.011
							}
						},
						{
							"p": " now",
							"b": 0.006,
							"k": {
								" now": 0.285,
								" also": 0.12,
								" not": 0.115,
								" currently": 0.067,
								" proud": 0.045,
								" a": 0.04,
								" located": 0.029,
								" hosting": 0.027,
								" hereby": 0.024,
								" set": 0.017
							}
						},
						{
							"p": " now",
							"b": 0.015,
							"k": {
								" now": 0.183,
								" a": 0.12,
								" located": 0.092,
								" not": 0.087,
								" currently": 0.075,
								" also": 0.071,
								" proud": 0.047,
								" an": 0.038,
								" hereby": 0.022,
								" the": 0.015
							}
						},
						{
							"p": " a",
							"b": 0.031,
							"k": {
								" a": 0.188,
								" now": 0.08,
								" not": 0.068,
								" located": 0.065,
								" currently": 0.062,
								" hosting": 0.057,
								" proud": 0.048,
								" also": 0.041,
								" an": 0.039,
								" the": 0.031
							}
						},
						{
							"p": " a",
							"b": 0.096,
							"k": {
								" a": 0.297,
								" the": 0.096,
								" an": 0.04,
								" not": 0.033,
								" now": 0.033,
								" one": 0.032,
								" also": 0.019,
								" located": 0.019,
								" about": 0.016,
								" home": 0.016
							}
						},
						{
							"p": " a",
							"b": 0.077,
							"k": {
								" a": 0.164,
								" the": 0.077,
								" one": 0.032,
								" an": 0.03,
								" home": 0.03,
								" not": 0.027,
								" known": 0.015,
								" in": 0.013,
								" also": 0.012,
								" now": 0.012
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.002,
									1.0,
									0.064,
									1.0,
									0.057,
									1.0,
									0.756,
									0.2,
									1.0,
									0.794,
									1.0
								],
								"e": 0.692
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.947,
									1.0,
									1.0,
									1.0,
									1.0,
									0.002,
									0.103
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.533,
									1.0,
									1.0,
									0.923,
									1.0,
									1.0,
									1.0,
									0.567,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.332,
									0.792,
									0.879,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.914,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.889,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.266,
									0.019,
									0.486,
									0.12,
									0.318,
									0.024,
									0.055,
									0.865,
									0.802,
									0.911,
									0.831,
									0.535
								],
								"e": 0.425
							},
							{
								"h": [
									0.277,
									0.533,
									0.143,
									0.248,
									0.106,
									0.407,
									0.136,
									0.159,
									0.336,
									0.103,
									0.744,
									0.023
								],
								"e": 0.272
							},
							{
								"h": [
									0.12,
									0.028,
									0.452,
									0.208,
									0.449,
									1.0,
									0.057,
									0.074,
									0.759,
									0.085,
									0.109,
									0.038
								],
								"e": 0.241
							},
							{
								"h": [
									0.002,
									0.042,
									0.566,
									0.033,
									0.008,
									0.068,
									0.15,
									0.189,
									1.0,
									0.071,
									0.015,
									0.101
								],
								"e": 0.128
							},
							{
								"h": [
									0.111,
									0.247,
									0.085,
									0.332,
									0.038,
									0.072,
									0.153,
									0.019,
									0.009,
									0.076,
									0.028,
									0.0
								],
								"e": 0.098
							},
							{
								"h": [
									0.005,
									0.0,
									0.263,
									0.251,
									0.081,
									0.04,
									0.005,
									0.059,
									0.027,
									0.026,
									0.598,
									0.142
								],
								"e": 0.098
							},
							{
								"h": [
									0.168,
									0.076,
									0.018,
									0.13,
									0.159,
									0.067,
									0.024,
									0.187,
									0.298,
									0.0,
									0.003,
									0.069
								],
								"e": 0.089
							},
							{
								"h": [
									0.017,
									0.008,
									0.0,
									0.021,
									0.03,
									0.046,
									0.042,
									0.008,
									0.296,
									0.02,
									0.0,
									0.002
								],
								"e": 0.028
							},
							{
								"h": [
									0.021,
									0.005,
									0.039,
									0.005,
									0.045,
									0.034,
									0.013,
									0.013,
									0.085,
									0.054,
									0.018,
									0.032
								],
								"e": 0.029
							},
							{
								"h": [
									0.03,
									0.003,
									0.045,
									0.062,
									0.004,
									0.026,
									0.001,
									0.018,
									0.083,
									0.001,
									0.014,
									0.005
								],
								"e": 0.022
							},
							{
								"h": [
									0.007,
									0.006,
									0.034,
									0.042,
									0.027,
									0.028,
									0.021,
									0.022,
									0.005,
									0.037,
									0.003,
									0.086
								],
								"e": 0.026
							},
							{
								"h": [
									0.607,
									0.037,
									0.041,
									0.157,
									0.091,
									0.014,
									0.01,
									0.028,
									0.48,
									0.011,
									0.102,
									0.059
								],
								"e": 0.105
							}
						],
						[
							{
								"h": [
									0.192,
									0.001,
									0.172,
									0.027,
									0.463,
									0.003,
									0.492,
									0.486,
									0.055,
									0.398,
									0.183,
									0.257
								],
								"e": 0.233
							},
							{
								"h": [
									0.126,
									0.151,
									0.213,
									0.261,
									0.143,
									0.02,
									0.033,
									0.122,
									0.163,
									0.058,
									0.897,
									0.002
								],
								"e": 0.18
							},
							{
								"h": [
									0.234,
									0.037,
									1.0,
									0.49,
									0.114,
									0.419,
									0.053,
									0.516,
									0.116,
									1.0,
									0.186,
									0.075
								],
								"e": 0.325
							},
							{
								"h": [
									0.0,
									0.027,
									0.669,
									0.042,
									0.009,
									0.019,
									1.0,
									1.0,
									0.276,
									0.01,
									0.028,
									0.215
								],
								"e": 0.21
							},
							{
								"h": [
									0.01,
									0.028,
									0.168,
									0.198,
									0.009,
									0.061,
									0.137,
									0.125,
									0.03,
									0.129,
									0.011,
									1.0
								],
								"e": 0.189
							},
							{
								"h": [
									0.005,
									0.001,
									0.028,
									0.13,
									0.242,
									0.004,
									0.078,
									0.016,
									0.01,
									0.007,
									0.252,
									0.038
								],
								"e": 0.055
							},
							{
								"h": [
									0.165,
									0.021,
									0.026,
									0.072,
									0.194,
									0.11,
									0.008,
									0.265,
									0.088,
									0.002,
									0.015,
									0.019
								],
								"e": 0.072
							},
							{
								"h": [
									0.033,
									0.014,
									0.001,
									0.024,
									0.007,
									0.051,
									0.12,
									0.02,
									1.0,
									0.009,
									0.001,
									0.001
								],
								"e": 0.061
							},
							{
								"h": [
									0.007,
									0.003,
									0.09,
									0.002,
									0.012,
									0.03,
									0.015,
									0.022,
									0.023,
									0.038,
									0.026,
									0.021
								],
								"e": 0.024
							},
							{
								"h": [
									0.019,
									0.002,
									0.017,
									0.336,
									0.002,
									0.016,
									0.001,
									0.007,
									0.096,
									0.001,
									0.005,
									0.02
								],
								"e": 0.035
							},
							{
								"h": [
									0.013,
									0.009,
									0.02,
									0.018,
									0.008,
									0.004,
									0.032,
									0.03,
									0.003,
									0.009,
									0.004,
									0.036
								],
								"e": 0.015
							},
							{
								"h": [
									0.322,
									0.013,
									0.04,
									0.047,
									0.056,
									0.007,
									0.009,
									0.009,
									0.164,
									0.005,
									0.082,
									0.025
								],
								"e": 0.05
							}
						],
						[
							{
								"h": [
									0.209,
									1.0,
									0.628,
									1.0,
									0.877,
									1.0,
									0.079,
									1.0,
									1.0,
									0.693,
									1.0,
									0.47
								],
								"e": 1.0
							},
							{
								"h": [
									0.692,
									0.282,
									0.562,
									0.355,
									0.168,
									1.0,
									0.102,
									0.234,
									0.288,
									0.14,
									1.0,
									1.0
								],
								"e": 0.543
							},
							{
								"h": [
									0.148,
									0.049,
									0.236,
									0.133,
									0.109,
									0.139,
									0.062,
									0.815,
									0.478,
									0.056,
									0.219,
									0.085
								],
								"e": 0.188
							},
							{
								"h": [
									0.023,
									0.06,
									0.176,
									0.027,
									0.023,
									0.039,
									0.121,
									0.119,
									0.522,
									0.116,
									0.105,
									0.144
								],
								"e": 0.101
							},
							{
								"h": [
									0.038,
									0.116,
									0.028,
									0.068,
									0.039,
									0.048,
									0.048,
									0.408,
									0.017,
									0.019,
									0.027,
									0.0
								],
								"e": 0.069
							},
							{
								"h": [
									0.005,
									0.001,
									0.081,
									0.108,
									0.103,
									0.019,
									0.038,
									0.021,
									0.01,
									0.021,
									0.095,
									0.138
								],
								"e": 0.046
							},
							{
								"h": [
									0.191,
									0.019,
									0.023,
									0.032,
									0.067,
									0.034,
									0.09,
									0.039,
									0.075,
									0.001,
									0.003,
									0.021
								],
								"e": 0.045
							},
							{
								"h": [
									0.032,
									0.011,
									0.001,
									0.026,
									0.022,
									0.025,
									0.029,
									0.022,
									0.249,
									0.021,
									0.015,
									0.005
								],
								"e": 0.027
							},
							{
								"h": [
									0.036,
									0.02,
									0.03,
									0.027,
									0.039,
									0.044,
									0.031,
									0.131,
									0.112,
									0.078,
									0.038,
									0.051
								],
								"e": 0.052
							},
							{
								"h": [
									0.113,
									0.024,
									0.126,
									0.154,
									0.006,
									0.023,
									0.006,
									0.023,
									0.02,
									0.004,
									0.101,
									0.009
								],
								"e": 0.046
							},
							{
								"h": [
									0.017,
									0.015,
									0.022,
									0.086,
									0.169,
									0.041,
									0.036,
									0.046,
									0.019,
									0.062,
									0.005,
									0.063
								],
								"e": 0.046
							},
							{
								"h": [
									1.0,
									0.047,
									0.02,
									0.121,
									0.105,
									0.021,
									0.011,
									0.06,
									1.0,
									0.026,
									0.096,
									0.164
								],
								"e": 0.167
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " the",
					"o": " only",
					"t": " capital",
					"r": [
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.614,
								" first": 0.041,
								" most": 0.026,
								" way": 0.015,
								" world": 0.015,
								" last": 0.013,
								" following": 0.011,
								" very": 0.01,
								" next": 0.01,
								" latter": 0.008
							}
						},
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.787,
								" first": 0.033,
								" most": 0.031,
								" fastest": 0.023,
								" following": 0.013,
								" world": 0.012,
								" best": 0.006,
								" latter": 0.005,
								" right": 0.004,
								" very": 0.004
							}
						},
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.845,
								" most": 0.027,
								" fastest": 0.016,
								" following": 0.013,
								" best": 0.012,
								" latest": 0.009,
								" first": 0.006,
								" very": 0.005,
								" largest": 0.004,
								" right": 0.003
							}
						},
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.71,
								" most": 0.07,
								" largest": 0.057,
								" fastest": 0.024,
								" latest": 0.019,
								" best": 0.016,
								" first": 0.01,
								" following": 0.009,
								" strongest": 0.009,
								" very": 0.005
							}
						},
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.328,
								" largest": 0.283,
								" most": 0.132,
								" fastest": 0.034,
								" first": 0.031,
								" biggest": 0.024,
								" latest": 0.022,
								" best": 0.018,
								" strongest": 0.013,
								" highest": 0.01
							}
						},
						{
							"p": " largest",
							"b": 0.0,
							"k": {
								" largest": 0.593,
								" most": 0.097,
								" same": 0.086,
								" latest": 0.051,
								" fastest": 0.046,
								" biggest": 0.025,
								" oldest": 0.017,
								" first": 0.011,
								" highest": 0.011,
								" best": 0.006
							}
						},
						{
							"p": " largest",
							"b": 0.0,
							"k": {
								" largest": 0.877,
								" most": 0.022,
								" fastest": 0.022,
								" biggest": 0.014,
								" first": 0.009,
								" latest": 0.008,
								" best": 0.006,
								" highest": 0.006,
								" same": 0.004,
								" longest": 0.003
							}
						},
						{
							"p": " largest",
							"b": 0.0,
							"k": {
								" largest": 0.945,
								" fastest": 0.009,
								" most": 0.008,
								" biggest": 0.007,
								" hottest": 0.004,
								" first": 0.003,
								" only": 0.002,
								" latest": 0.002,
								" oldest": 0.002,
								" longest": 0.002
							}
						},
						{
							"p": " largest",
							"b": 0.0,
							"k": {
								" largest": 0.878,
								" birthplace": 0.09,
								" most": 0.004,
								" biggest": 0.004,
								" only": 0.003,
								" longest": 0.003,
								" center": 0.002,
								" focal": 0.002,
								" closest": 0.001,
								" oldest": 0.001
							}
						},
						{
							"p": " largest",
							"b": 0.0,
							"k": {
								" largest": 0.886,
								" birthplace": 0.071,
								" only": 0.009,
								" most": 0.005,
								" busiest": 0.004,
								" biggest": 0.003,
								" first": 0.002,
								" oldest": 0.002,
								" longest": 0.002,
								" home": 0.001
							}
						},
						{
							"p": " largest",
							"b": 0.004,
							"k": {
								" largest": 0.445,
								" only": 0.121,
								" birthplace": 0.083,
								" most": 0.036,
								" first": 0.033,
								" home": 0.019,
								" biggest": 0.016,
								" busiest": 0.013,
								" center": 0.012,
								" latest": 0.011
							}
						},
						{
							"p": " only",
							"b": 0.013,
							"k": {
								" only": 0.133,
								" largest": 0.051,
								" first": 0.049,
								" home": 0.037,
								" most": 0.034,
								" second": 0.022,
								" world": 0.02,
								" third": 0.014,
								" birthplace": 0.014,
								" capital": 0.013
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.003,
									1.0,
									0.061,
									1.0,
									0.348,
									1.0,
									0.579,
									0.05,
									1.0,
									1.0,
									1.0
								],
								"e": 0.887
							},
							{
								"h": [
									0.528,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.003,
									0.259
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.134,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.305,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.646,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.676,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.534,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.596,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.65,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.928,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.703,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.235,
									0.04,
									0.537,
									0.107,
									0.306,
									0.338,
									0.016,
									0.471,
									0.421,
									0.746,
									0.718,
									0.5
								],
								"e": 0.454
							},
							{
								"h": [
									0.124,
									0.572,
									0.162,
									0.193,
									0.123,
									0.567,
									0.124,
									0.15,
									0.332,
									0.106,
									0.763,
									0.052
								],
								"e": 0.274
							},
							{
								"h": [
									0.158,
									0.026,
									0.054,
									0.171,
									0.267,
									0.5,
									0.068,
									0.062,
									0.079,
									0.11,
									0.081,
									0.031
								],
								"e": 0.134
							},
							{
								"h": [
									0.002,
									0.088,
									0.484,
									0.036,
									0.007,
									0.055,
									0.145,
									0.152,
									0.245,
									0.071,
									0.006,
									0.043
								],
								"e": 0.084
							},
							{
								"h": [
									0.133,
									0.287,
									0.082,
									0.346,
									0.023,
									0.127,
									0.171,
									0.002,
									0.008,
									0.073,
									0.025,
									0.0
								],
								"e": 0.098
							},
							{
								"h": [
									0.002,
									0.0,
									0.133,
									0.469,
									0.042,
									0.003,
									0.007,
									0.026,
									0.013,
									0.021,
									0.174,
									0.018
								],
								"e": 0.054
							},
							{
								"h": [
									0.209,
									0.026,
									0.016,
									0.052,
									0.094,
									0.059,
									0.012,
									0.281,
									0.189,
									0.0,
									0.002,
									0.098
								],
								"e": 0.07
							},
							{
								"h": [
									0.013,
									0.011,
									0.0,
									0.023,
									0.031,
									0.077,
									0.046,
									0.004,
									0.259,
									0.045,
									0.0,
									0.0
								],
								"e": 0.03
							},
							{
								"h": [
									0.019,
									0.002,
									0.038,
									0.013,
									0.094,
									0.101,
									0.008,
									0.061,
									0.473,
									0.133,
									0.033,
									0.114
								],
								"e": 0.076
							},
							{
								"h": [
									0.046,
									0.003,
									0.096,
									0.078,
									0.002,
									0.02,
									0.001,
									0.017,
									0.095,
									0.001,
									0.015,
									0.002
								],
								"e": 0.027
							},
							{
								"h": [
									0.032,
									0.004,
									0.014,
									0.055,
									0.03,
									0.01,
									0.036,
									0.03,
									0.005,
									0.053,
									0.004,
									0.295
								],
								"e": 0.035
							},
							{
								"h": [
									0.462,
									0.047,
									0.048,
									0.376,
									0.074,
									0.016,
									0.008,
									0.04,
									0.129,
									0.008,
									0.108,
									0.078
								],
								"e": 0.092
							}
						],
						[
							{
								"h": [
									0.19,
									0.003,
									0.265,
									0.046,
									0.469,
									0.092,
									0.491,
									0.386,
									0.03,
									0.418,
									0.215,
									0.309
								],
								"e": 0.317
							},
							{
								"h": [
									0.055,
									0.175,
									0.24,
									0.215,
									0.18,
									0.059,
									0.048,
									0.111,
									0.175,
									0.064,
									0.938,
									0.006
								],
								"e": 0.179
							},
							{
								"h": [
									0.274,
									0.031,
									0.103,
									0.565,
									0.026,
									0.143,
									0.055,
									0.492,
									0.037,
									0.634,
									0.186,
									0.061
								],
								"e": 0.209
							},
							{
								"h": [
									0.0,
									0.069,
									0.191,
									0.017,
									0.003,
									0.029,
									0.547,
									0.394,
									0.036,
									0.022,
									0.027,
									0.041
								],
								"e": 0.085
							},
							{
								"h": [
									0.013,
									0.026,
									0.19,
									0.185,
									0.006,
									0.024,
									0.111,
									0.039,
									0.012,
									0.123,
									0.006,
									0.0
								],
								"e": 0.063
							},
							{
								"h": [
									0.0,
									0.0,
									0.018,
									0.19,
									0.047,
									0.001,
									0.019,
									0.006,
									0.004,
									0.008,
									0.065,
									0.005
								],
								"e": 0.022
							},
							{
								"h": [
									0.276,
									0.013,
									0.028,
									0.02,
									0.077,
									0.078,
									0.004,
									0.283,
									0.097,
									0.0,
									0.004,
									0.033
								],
								"e": 0.06
							},
							{
								"h": [
									0.054,
									0.027,
									0.001,
									0.038,
									0.005,
									0.047,
									0.068,
									0.008,
									0.997,
									0.011,
									0.001,
									0.0
								],
								"e": 0.056
							},
							{
								"h": [
									0.013,
									0.0,
									0.232,
									0.007,
									0.02,
									0.051,
									0.002,
									0.036,
									0.087,
									0.077,
									0.039,
									0.072
								],
								"e": 0.05
							},
							{
								"h": [
									0.022,
									0.002,
									0.059,
									0.324,
									0.002,
									0.016,
									0.002,
									0.02,
									0.232,
									0.001,
									0.006,
									0.01
								],
								"e": 0.041
							},
							{
								"h": [
									0.032,
									0.003,
									0.015,
									0.025,
									0.02,
									0.002,
									0.046,
									0.051,
									0.002,
									0.006,
									0.006,
									0.066
								],
								"e": 0.02
							},
							{
								"h": [
									0.19,
									0.014,
									0.063,
									0.112,
									0.055,
									0.009,
									0.014,
									0.027,
									0.043,
									0.003,
									0.093,
									0.041
								],
								"e": 0.048
							}
						],
						[
							{
								"h": [
									0.216,
									0.004,
									0.423,
									0.361,
									0.809,
									0.328,
									0.038,
									1.0,
									0.661,
									0.543,
									0.336,
									0.44
								],
								"e": 0.531
							},
							{
								"h": [
									1.0,
									0.928,
									0.533,
									0.267,
									0.175,
									0.326,
									0.209,
									0.188,
									0.242,
									0.194,
									1.0,
									0.032
								],
								"e": 0.427
							},
							{
								"h": [
									0.109,
									0.056,
									1.0,
									0.307,
									0.476,
									0.741,
									0.067,
									0.806,
									1.0,
									0.153,
									0.231,
									0.08
								],
								"e": 0.457
							},
							{
								"h": [
									0.003,
									0.089,
									1.0,
									0.242,
									0.018,
									0.131,
									0.628,
									0.273,
									1.0,
									0.578,
									0.04,
									0.175
								],
								"e": 0.27
							},
							{
								"h": [
									0.359,
									0.953,
									0.053,
									0.197,
									0.067,
									0.023,
									0.14,
									0.062,
									0.034,
									0.103,
									0.011,
									1.0
								],
								"e": 0.262
							},
							{
								"h": [
									0.002,
									0.0,
									0.507,
									0.296,
									0.36,
									0.002,
									0.226,
									0.033,
									0.028,
									0.021,
									0.129,
									0.017
								],
								"e": 0.106
							},
							{
								"h": [
									1.0,
									0.023,
									0.014,
									0.019,
									0.044,
									0.045,
									0.054,
									0.126,
									0.753,
									0.0,
									0.004,
									0.183
								],
								"e": 0.132
							},
							{
								"h": [
									0.431,
									0.016,
									0.0,
									0.067,
									0.069,
									0.104,
									0.03,
									0.013,
									1.0,
									0.199,
									0.005,
									0.001
								],
								"e": 0.105
							},
							{
								"h": [
									0.04,
									0.005,
									0.061,
									0.071,
									0.3,
									0.322,
									0.008,
									1.0,
									0.397,
									0.197,
									0.053,
									0.169
								],
								"e": 0.183
							},
							{
								"h": [
									0.09,
									0.022,
									0.071,
									1.0,
									0.002,
									0.031,
									0.003,
									0.019,
									0.065,
									0.004,
									0.176,
									0.006
								],
								"e": 0.08
							},
							{
								"h": [
									0.036,
									0.009,
									0.032,
									0.112,
									0.151,
									0.028,
									0.06,
									0.048,
									0.008,
									0.144,
									0.006,
									0.828
								],
								"e": 0.087
							},
							{
								"h": [
									1.0,
									0.1,
									0.026,
									0.565,
									0.081,
									0.012,
									0.008,
									0.074,
									1.0,
									0.019,
									0.238,
									0.444
								],
								"e": 0.253
							}
						],
						[
							{
								"h": [
									0.121,
									1.0,
									0.138,
									1.0,
									0.614,
									1.0,
									0.009,
									0.968,
									1.0,
									0.635,
									0.73,
									0.547
								],
								"e": 1.0
							},
							{
								"h": [
									0.369,
									0.414,
									0.654,
									0.258,
									0.192,
									0.184,
									0.162,
									0.246,
									0.367,
									0.279,
									0.905,
									1.0
								],
								"e": 0.491
							},
							{
								"h": [
									0.12,
									0.052,
									0.057,
									0.542,
									0.286,
									0.158,
									0.112,
									0.901,
									0.082,
									0.104,
									0.199,
									0.087
								],
								"e": 0.204
							},
							{
								"h": [
									0.015,
									0.083,
									0.344,
									0.081,
									0.01,
									0.055,
									0.108,
									0.208,
									0.066,
									0.085,
									0.103,
									0.078
								],
								"e": 0.088
							},
							{
								"h": [
									0.256,
									0.38,
									0.071,
									0.019,
									0.076,
									0.065,
									0.101,
									0.512,
									0.019,
									0.047,
									0.031,
									0.0
								],
								"e": 0.12
							},
							{
								"h": [
									0.017,
									0.0,
									0.024,
									0.174,
									0.042,
									0.009,
									0.033,
									0.016,
									0.045,
									0.016,
									0.036,
									0.121
								],
								"e": 0.038
							},
							{
								"h": [
									0.093,
									0.012,
									0.054,
									0.016,
									0.056,
									0.045,
									0.116,
									0.016,
									0.035,
									0.001,
									0.008,
									0.036
								],
								"e": 0.039
							},
							{
								"h": [
									0.058,
									0.004,
									0.005,
									0.023,
									0.011,
									0.044,
									0.009,
									0.007,
									0.019,
									0.068,
									0.011,
									0.002
								],
								"e": 0.02
							},
							{
								"h": [
									0.17,
									0.014,
									0.044,
									0.134,
									0.097,
									0.086,
									0.012,
									0.107,
									0.077,
									0.078,
									0.051,
									0.014
								],
								"e": 0.072
							},
							{
								"h": [
									0.01,
									0.12,
									0.018,
									0.049,
									0.001,
									0.017,
									0.004,
									0.019,
									0.013,
									0.009,
									0.05,
									0.004
								],
								"e": 0.024
							},
							{
								"h": [
									0.013,
									0.048,
									0.052,
									0.139,
									0.075,
									0.008,
									0.028,
									0.036,
									0.012,
									0.037,
									0.007,
									0.12
								],
								"e": 0.043
							},
							{
								"h": [
									0.604,
									0.109,
									0.033,
									0.118,
									0.027,
									0.016,
									0.004,
									0.048,
									0.126,
									0.019,
									0.101,
									0.11
								],
								"e": 0.086
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " capital",
					"o": " of",
					"t": " of",
					"r": [
						{
							"p": " capital",
							"b": 0.0,
							"k": {
								" capital": 0.996,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " capital",
							"b": 0.0,
							"k": {
								" capital": 0.997,
								" city": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " capital",
							"b": 0.0,
							"k": {
								" capital": 0.942,
								" city": 0.041,
								" area": 0.003,
								" punishment": 0.003,
								" block": 0.001,
								" building": 0.001,
								" ship": 0.001,
								" cities": 0.001,
								" investment": 0.001,
								" reward": 0.001
							}
						},
						{
							"p": " capital",
							"b": 0.0,
							"k": {
								" capital": 0.898,
								" city": 0.089,
								" punishment": 0.004,
								" cities": 0.002,
								" area": 0.001,
								" building": 0.001,
								" ship": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0
							}
						},
						{
							"p": " city",
							"b": 0.004,
							"k": {
								" city": 0.9,
								" capital": 0.041,
								" cities": 0.015,
								" punishment": 0.011,
								" area": 0.005,
								" of": 0.004,
								" metropolitan": 0.003,
								" itself": 0.002,
								" building": 0.002,
								" City": 0.001
							}
						},
						{
							"p": " city",
							"b": 0.003,
							"k": {
								" city": 0.959,
								" cities": 0.018,
								" capital": 0.007,
								" of": 0.003,
								" area": 0.003,
								" metropolitan": 0.002,
								" City": 0.001,
								" punishment": 0.001,
								" Wasteland": 0.001,
								"!": 0.0
							}
						},
						{
							"p": " city",
							"b": 0.005,
							"k": {
								" city": 0.982,
								" of": 0.005,
								" cities": 0.003,
								" area": 0.002,
								" capital": 0.002,
								" City": 0.001,
								" metropolitan": 0.001,
								" Wasteland": 0.001,
								"!": 0.0,
								"\"": 0.0
							}
						},
						{
							"p": " city",
							"b": 0.071,
							"k": {
								" city": 0.918,
								" of": 0.071,
								" area": 0.002,
								" building": 0.001,
								" cities": 0.001,
								" tenant": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": " city",
							"b": 0.076,
							"k": {
								" city": 0.92,
								" of": 0.076,
								" town": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " city",
							"b": 0.199,
							"k": {
								" city": 0.794,
								" of": 0.199,
								" and": 0.002,
								" for": 0.001,
								" town": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": " of",
							"b": 0.897,
							"k": {
								" of": 0.897,
								" city": 0.096,
								" and": 0.002,
								" for": 0.002,
								",": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": " of",
							"b": 0.81,
							"k": {
								" of": 0.81,
								" city": 0.076,
								" and": 0.027,
								",": 0.021,
								" for": 0.017,
								".": 0.013,
								" in": 0.003,
								"'s": 0.003,
								" to": 0.002,
								" on": 0.001
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.0,
									1.0,
									0.078,
									0.496,
									0.086,
									1.0,
									0.382,
									1.0,
									1.0,
									0.995,
									1.0
								],
								"e": 0.706
							},
							{
								"h": [
									0.343,
									0.981,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.003,
									0.197
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.32,
									0.517,
									1.0,
									1.0,
									1.0,
									1.0,
									0.684,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.137,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.951,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.362,
									0.528,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.785,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.559,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.702,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.317,
									0.0,
									0.443,
									0.0,
									0.012,
									0.0,
									0.101,
									0.317,
									0.578,
									0.628,
									0.592,
									0.861
								],
								"e": 0.293
							},
							{
								"h": [
									0.154,
									0.484,
									0.181,
									0.172,
									0.109,
									0.544,
									0.082,
									0.133,
									0.298,
									0.134,
									0.667,
									0.039
								],
								"e": 0.247
							},
							{
								"h": [
									0.211,
									0.074,
									0.133,
									0.045,
									0.458,
									0.488,
									0.087,
									0.067,
									0.208,
									0.106,
									0.113,
									0.029
								],
								"e": 0.164
							},
							{
								"h": [
									0.004,
									0.09,
									0.016,
									0.019,
									0.021,
									0.053,
									0.14,
									0.055,
									0.162,
									0.117,
									0.012,
									0.031
								],
								"e": 0.053
							},
							{
								"h": [
									0.089,
									0.036,
									0.019,
									0.089,
									0.018,
									0.064,
									0.078,
									0.001,
									0.007,
									0.049,
									0.017,
									0.0
								],
								"e": 0.042
							},
							{
								"h": [
									0.004,
									0.0,
									0.154,
									0.201,
									0.014,
									0.0,
									0.008,
									0.01,
									0.037,
									0.025,
									0.624,
									0.047
								],
								"e": 0.057
							},
							{
								"h": [
									0.175,
									0.012,
									0.028,
									0.036,
									0.076,
									0.026,
									0.024,
									0.154,
									0.03,
									0.0,
									0.003,
									0.075
								],
								"e": 0.044
							},
							{
								"h": [
									0.011,
									0.016,
									0.0,
									0.02,
									0.039,
									0.027,
									0.026,
									0.006,
									0.092,
									0.088,
									0.0,
									0.004
								],
								"e": 0.025
							},
							{
								"h": [
									0.015,
									0.003,
									0.012,
									0.042,
									0.022,
									0.051,
									0.004,
									0.028,
									0.106,
									0.043,
									0.038,
									0.082
								],
								"e": 0.036
							},
							{
								"h": [
									0.036,
									0.003,
									0.025,
									0.039,
									0.005,
									0.041,
									0.002,
									0.016,
									0.031,
									0.001,
									0.009,
									0.005
								],
								"e": 0.017
							},
							{
								"h": [
									0.019,
									0.003,
									0.044,
									0.046,
									0.015,
									0.025,
									0.032,
									0.031,
									0.004,
									0.022,
									0.011,
									0.1
								],
								"e": 0.027
							},
							{
								"h": [
									0.603,
									0.028,
									0.083,
									0.202,
									0.084,
									0.019,
									0.009,
									0.03,
									0.23,
									0.009,
									0.107,
									0.664
								],
								"e": 0.108
							}
						],
						[
							{
								"h": [
									0.32,
									0.007,
									0.523,
									0.013,
									0.189,
									0.002,
									0.374,
									0.105,
									0.407,
									0.55,
									0.416,
									0.287
								],
								"e": 0.272
							},
							{
								"h": [
									0.084,
									0.201,
									0.248,
									0.185,
									0.132,
									0.046,
									0.052,
									0.151,
									0.117,
									0.081,
									0.838,
									0.024
								],
								"e": 0.17
							},
							{
								"h": [
									0.352,
									0.255,
									0.05,
									0.064,
									0.031,
									0.051,
									0.027,
									0.38,
									0.046,
									0.459,
									0.208,
									0.045
								],
								"e": 0.168
							},
							{
								"h": [
									0.001,
									0.032,
									0.004,
									0.0,
									0.013,
									0.041,
									0.075,
									0.018,
									0.005,
									0.009,
									0.015,
									0.01
								],
								"e": 0.017
							},
							{
								"h": [
									0.002,
									0.009,
									0.016,
									0.064,
									0.017,
									0.001,
									0.08,
									0.011,
									0.002,
									0.038,
									0.023,
									0.0
								],
								"e": 0.026
							},
							{
								"h": [
									0.001,
									0.0,
									0.021,
									0.071,
									0.003,
									0.0,
									0.001,
									0.002,
									0.015,
									0.005,
									0.795,
									0.029
								],
								"e": 0.041
							},
							{
								"h": [
									0.043,
									0.001,
									0.02,
									0.007,
									0.021,
									0.037,
									0.007,
									0.146,
									0.033,
									0.0,
									0.014,
									0.017
								],
								"e": 0.026
							},
							{
								"h": [
									0.008,
									0.036,
									0.0,
									0.016,
									0.006,
									0.018,
									0.106,
									0.005,
									0.03,
									0.019,
									0.0,
									0.001
								],
								"e": 0.02
							},
							{
								"h": [
									0.008,
									0.0,
									0.06,
									0.03,
									0.002,
									0.015,
									0.001,
									0.009,
									0.029,
									0.031,
									0.047,
									0.029
								],
								"e": 0.022
							},
							{
								"h": [
									0.007,
									0.001,
									0.009,
									0.057,
									0.004,
									0.025,
									0.001,
									0.02,
									0.015,
									0.001,
									0.003,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.017,
									0.001,
									0.018,
									0.01,
									0.001,
									0.002,
									0.039,
									0.059,
									0.001,
									0.003,
									0.011,
									0.011
								],
								"e": 0.014
							},
							{
								"h": [
									0.591,
									0.022,
									0.089,
									0.046,
									0.054,
									0.016,
									0.01,
									0.015,
									0.112,
									0.003,
									0.108,
									0.314
								],
								"e": 0.071
							}
						],
						[
							{
								"h": [
									0.178,
									0.0,
									0.315,
									0.001,
									0.01,
									0.0,
									0.037,
									0.581,
									0.412,
									0.432,
									0.348,
									0.329
								],
								"e": 0.206
							},
							{
								"h": [
									0.918,
									1.0,
									0.583,
									0.242,
									0.144,
									0.305,
									0.056,
									0.125,
									0.315,
									0.223,
									0.876,
									0.022
								],
								"e": 0.362
							},
							{
								"h": [
									0.229,
									0.121,
									1.0,
									0.099,
									0.172,
									0.62,
									0.064,
									0.53,
									1.0,
									0.159,
									0.254,
									0.084
								],
								"e": 0.339
							},
							{
								"h": [
									0.0,
									0.097,
									0.092,
									0.102,
									0.007,
									0.053,
									0.422,
									0.181,
									1.0,
									0.283,
									0.031,
									0.186
								],
								"e": 0.164
							},
							{
								"h": [
									1.0,
									0.808,
									0.02,
									0.253,
									0.019,
									0.037,
									0.087,
									0.023,
									0.044,
									0.129,
									0.01,
									0.0
								],
								"e": 0.167
							},
							{
								"h": [
									0.002,
									0.0,
									0.672,
									0.259,
									0.443,
									0.01,
									0.054,
									0.003,
									0.334,
									0.028,
									0.214,
									0.024
								],
								"e": 0.123
							},
							{
								"h": [
									0.883,
									0.015,
									0.031,
									0.012,
									0.083,
									0.028,
									0.055,
									0.069,
									0.122,
									0.0,
									0.005,
									0.086
								],
								"e": 0.074
							},
							{
								"h": [
									0.292,
									0.039,
									0.0,
									0.072,
									0.053,
									0.076,
									0.015,
									0.011,
									0.091,
									0.181,
									0.001,
									0.004
								],
								"e": 0.055
							},
							{
								"h": [
									0.02,
									0.024,
									0.08,
									0.151,
									0.026,
									0.256,
									0.014,
									1.0,
									0.106,
									0.049,
									0.053,
									0.226
								],
								"e": 0.122
							},
							{
								"h": [
									0.031,
									0.019,
									0.019,
									0.294,
									0.002,
									0.05,
									0.008,
									0.049,
									0.028,
									0.001,
									0.08,
									0.007
								],
								"e": 0.038
							},
							{
								"h": [
									0.015,
									0.004,
									0.033,
									0.075,
									0.055,
									0.099,
									0.029,
									0.033,
									0.01,
									0.06,
									0.007,
									0.152
								],
								"e": 0.043
							},
							{
								"h": [
									0.909,
									0.029,
									0.045,
									0.213,
									0.091,
									0.029,
									0.014,
									0.083,
									1.0,
									0.018,
									0.137,
									0.83
								],
								"e": 0.183
							}
						],
						[
							{
								"h": [
									0.326,
									0.0,
									0.38,
									0.002,
									0.033,
									0.0,
									0.043,
									1.0,
									0.359,
									0.499,
									0.416,
									0.485
								],
								"e": 0.281
							},
							{
								"h": [
									1.0,
									0.918,
									0.66,
									0.22,
									0.15,
									0.309,
									0.063,
									0.17,
									0.368,
									0.233,
									0.718,
									0.023
								],
								"e": 0.373
							},
							{
								"h": [
									0.131,
									0.103,
									0.941,
									1.0,
									0.965,
									0.709,
									0.08,
									0.524,
									0.343,
									0.371,
									0.221,
									0.079
								],
								"e": 0.445
							},
							{
								"h": [
									0.004,
									0.221,
									1.0,
									0.119,
									0.011,
									0.077,
									0.568,
									0.248,
									0.219,
									0.118,
									0.038,
									0.055
								],
								"e": 0.221
							},
							{
								"h": [
									0.612,
									1.0,
									0.03,
									0.102,
									0.026,
									0.174,
									0.19,
									0.315,
									0.017,
									0.248,
									0.012,
									1.0
								],
								"e": 0.332
							},
							{
								"h": [
									0.003,
									0.0,
									0.246,
									0.257,
									0.152,
									0.013,
									0.055,
									0.011,
									0.105,
									0.01,
									0.075,
									0.05
								],
								"e": 0.062
							},
							{
								"h": [
									1.0,
									0.011,
									0.053,
									0.009,
									0.108,
									0.026,
									0.051,
									0.019,
									0.147,
									0.0,
									0.004,
									0.159
								],
								"e": 0.085
							},
							{
								"h": [
									0.992,
									0.007,
									0.001,
									0.04,
									0.025,
									0.049,
									0.003,
									0.01,
									0.084,
									0.079,
									0.001,
									0.002
								],
								"e": 0.065
							},
							{
								"h": [
									0.021,
									0.035,
									0.113,
									0.177,
									0.132,
									0.273,
									0.046,
									0.858,
									0.036,
									0.02,
									0.034,
									0.044
								],
								"e": 0.111
							},
							{
								"h": [
									0.002,
									0.015,
									0.004,
									0.312,
									0.002,
									0.019,
									0.002,
									0.028,
									0.002,
									0.001,
									0.468,
									0.002
								],
								"e": 0.049
							},
							{
								"h": [
									0.004,
									0.004,
									0.01,
									0.054,
									0.014,
									0.076,
									0.008,
									0.012,
									0.006,
									0.194,
									0.007,
									0.249
								],
								"e": 0.044
							},
							{
								"h": [
									0.383,
									0.012,
									0.031,
									0.158,
									0.028,
									0.009,
									0.004,
									0.02,
									0.237,
									0.006,
									0.105,
									0.682
								],
								"e": 0.083
							}
						],
						[
							{
								"h": [
									0.398,
									1.0,
									0.266,
									1.0,
									1.0,
									1.0,
									0.339,
									0.219,
									0.198,
									0.044,
									1.0,
									0.308
								],
								"e": 1.0
							},
							{
								"h": [
									0.626,
									0.227,
									0.229,
									0.181,
									0.219,
									0.104,
									0.096,
									0.187,
									0.095,
									0.036,
									1.0,
									1.0
								],
								"e": 0.392
							},
							{
								"h": [
									0.241,
									0.064,
									0.281,
									0.115,
									0.04,
									0.167,
									0.035,
									0.994,
									0.082,
									0.455,
									0.511,
									0.123
								],
								"e": 0.24
							},
							{
								"h": [
									0.033,
									0.038,
									0.054,
									0.009,
									0.0,
									0.08,
									0.517,
									0.246,
									0.037,
									0.264,
									0.17,
									0.564
								],
								"e": 0.147
							},
							{
								"h": [
									0.04,
									0.417,
									0.009,
									0.116,
									0.036,
									0.069,
									0.031,
									1.0,
									0.002,
									0.085,
									0.003,
									0.0
								],
								"e": 0.124
							},
							{
								"h": [
									0.013,
									0.004,
									0.019,
									0.055,
									0.175,
									0.007,
									0.007,
									0.085,
									0.031,
									0.001,
									0.159,
									0.142
								],
								"e": 0.047
							},
							{
								"h": [
									0.171,
									0.037,
									0.012,
									0.007,
									0.047,
									0.024,
									0.068,
									0.087,
									0.103,
									0.0,
									0.014,
									0.044
								],
								"e": 0.043
							},
							{
								"h": [
									0.084,
									0.007,
									0.004,
									0.096,
									0.023,
									0.009,
									0.003,
									0.009,
									0.07,
									0.024,
									0.001,
									0.003
								],
								"e": 0.023
							},
							{
								"h": [
									0.012,
									0.037,
									0.034,
									0.019,
									0.091,
									0.043,
									0.096,
									0.107,
									0.115,
									0.058,
									0.086,
									0.011
								],
								"e": 0.058
							},
							{
								"h": [
									0.011,
									0.001,
									0.013,
									0.133,
									0.006,
									0.041,
									0.006,
									0.007,
									0.012,
									0.003,
									0.156,
									0.001
								],
								"e": 0.024
							},
							{
								"h": [
									0.012,
									0.014,
									0.01,
									0.019,
									0.004,
									0.039,
									0.024,
									0.095,
									0.004,
									0.223,
									0.006,
									0.036
								],
								"e": 0.035
							},
							{
								"h": [
									0.471,
									0.005,
									0.017,
									0.091,
									0.018,
									0.007,
									0.008,
									0.021,
									0.328,
									0.009,
									0.136,
									0.202
								],
								"e": 0.07
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " of",
					"o": " the",
					"t": " the",
					"r": [
						{
							"p": " the",
							"b": 0.632,
							"k": {
								" the": 0.632,
								" course": 0.084,
								" a": 0.03,
								" this": 0.01,
								" our": 0.009,
								" its": 0.006,
								" \"": 0.005,
								" his": 0.005,
								" an": 0.004,
								" their": 0.004
							}
						},
						{
							"p": " the",
							"b": 0.749,
							"k": {
								" the": 0.749,
								" course": 0.127,
								" a": 0.018,
								" its": 0.009,
								" our": 0.007,
								" this": 0.004,
								" late": 0.004,
								" their": 0.003,
								" sorts": 0.003,
								" an": 0.002
							}
						},
						{
							"p": " the",
							"b": 0.684,
							"k": {
								" the": 0.684,
								" course": 0.184,
								" a": 0.022,
								" our": 0.012,
								" its": 0.006,
								" his": 0.005,
								" an": 0.004,
								" their": 0.004,
								" this": 0.003,
								" what": 0.002
							}
						},
						{
							"p": " the",
							"b": 0.849,
							"k": {
								" the": 0.849,
								" course": 0.026,
								" a": 0.013,
								" our": 0.013,
								" Hope": 0.005,
								" his": 0.004,
								" an": 0.003,
								" popular": 0.003,
								" Dam": 0.003,
								" its": 0.002
							}
						},
						{
							"p": " the",
							"b": 0.797,
							"k": {
								" the": 0.797,
								" a": 0.018,
								" several": 0.018,
								" our": 0.016,
								" many": 0.011,
								" its": 0.007,
								" both": 0.007,
								" his": 0.005,
								" these": 0.004,
								" free": 0.004
							}
						},
						{
							"p": " the",
							"b": 0.492,
							"k": {
								" the": 0.492,
								" town": 0.039,
								" state": 0.032,
								" both": 0.026,
								" several": 0.022,
								" a": 0.019,
								" Pennsylvania": 0.017,
								" State": 0.012,
								" our": 0.01,
								" Texas": 0.009
							}
						},
						{
							"p": " state",
							"b": 0.163,
							"k": {
								" state": 0.175,
								" the": 0.163,
								" Pennsylvania": 0.057,
								" town": 0.045,
								" State": 0.042,
								" East": 0.042,
								" city": 0.018,
								" USA": 0.016,
								" a": 0.015,
								" its": 0.015
							}
						},
						{
							"p": " the",
							"b": 0.54,
							"k": {
								" the": 0.54,
								" a": 0.068,
								" Canada": 0.054,
								" its": 0.053,
								" state": 0.032,
								" North": 0.03,
								" British": 0.016,
								" China": 0.011,
								" an": 0.01,
								" western": 0.01
							}
						},
						{
							"p": " the",
							"b": 0.308,
							"k": {
								" the": 0.308,
								" Canada": 0.129,
								" North": 0.095,
								" America": 0.06,
								" a": 0.053,
								" South": 0.041,
								" Europe": 0.03,
								" China": 0.024,
								" USA": 0.019,
								" state": 0.012
							}
						},
						{
							"p": " the",
							"b": 0.147,
							"k": {
								" the": 0.147,
								" America": 0.13,
								" Canada": 0.097,
								" Europe": 0.079,
								" North": 0.071,
								" South": 0.044,
								" a": 0.041,
								" Washington": 0.04,
								" Germany": 0.035,
								" Pennsylvania": 0.029
							}
						},
						{
							"p": " the",
							"b": 0.487,
							"k": {
								" the": 0.487,
								" a": 0.07,
								" America": 0.038,
								" Europe": 0.032,
								" Canada": 0.03,
								" Washington": 0.028,
								" Israel": 0.025,
								" North": 0.016,
								" an": 0.011,
								" Germany": 0.011
							}
						},
						{
							"p": " the",
							"b": 0.445,
							"k": {
								" the": 0.445,
								" a": 0.093,
								" Washington": 0.019,
								" an": 0.015,
								" one": 0.014,
								" America": 0.014,
								" Canada": 0.013,
								" Israel": 0.011,
								" New": 0.01,
								" North": 0.01
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.001,
									1.0,
									0.01,
									0.366,
									0.043,
									1.0,
									0.257,
									0.076,
									1.0,
									0.637,
									1.0
								],
								"e": 0.575
							},
							{
								"h": [
									0.187,
									0.977,
									1.0,
									1.0,
									1.0,
									0.301,
									1.0,
									1.0,
									1.0,
									1.0,
									0.007,
									0.372
								],
								"e": 1.0
							},
							{
								"h": [
									0.752,
									1.0,
									0.023,
									0.544,
									0.062,
									1.0,
									1.0,
									0.849,
									0.436,
									0.179,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.084,
									0.092,
									1.0,
									1.0,
									0.371,
									0.144,
									0.44,
									1.0,
									1.0,
									0.037
								],
								"e": 1.0
							},
							{
								"h": [
									0.491,
									0.046,
									1.0,
									0.385,
									1.0,
									1.0,
									1.0,
									0.79,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.207,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.342,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.651,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.578,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.217,
									0.018,
									0.758,
									0.021,
									0.073,
									0.186,
									0.071,
									0.251,
									0.41,
									0.885,
									0.783,
									0.568
								],
								"e": 0.353
							},
							{
								"h": [
									0.052,
									0.63,
									0.213,
									0.124,
									0.138,
									0.054,
									0.19,
									0.162,
									0.28,
									0.116,
									0.615,
									0.089
								],
								"e": 0.234
							},
							{
								"h": [
									0.149,
									0.019,
									0.001,
									0.034,
									0.004,
									0.44,
									0.076,
									0.061,
									0.092,
									0.013,
									0.091,
									0.023
								],
								"e": 0.091
							},
							{
								"h": [
									0.003,
									0.112,
									0.009,
									0.002,
									0.007,
									0.022,
									0.086,
									0.004,
									0.142,
									0.056,
									0.01,
									0.004
								],
								"e": 0.044
							},
							{
								"h": [
									0.09,
									0.004,
									0.061,
									0.155,
									0.022,
									0.131,
									0.068,
									0.004,
									0.008,
									0.051,
									0.023,
									0.0
								],
								"e": 0.059
							},
							{
								"h": [
									0.001,
									0.0,
									0.114,
									0.207,
									0.018,
									0.0,
									0.0,
									0.009,
									0.021,
									0.012,
									0.617,
									0.012
								],
								"e": 0.042
							},
							{
								"h": [
									0.036,
									0.018,
									0.028,
									0.035,
									0.051,
									0.02,
									0.015,
									0.086,
									0.019,
									0.0,
									0.001,
									0.05
								],
								"e": 0.029
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.027,
									0.044,
									0.023,
									0.027,
									0.001,
									0.288,
									0.044,
									0.0,
									0.001
								],
								"e": 0.028
							},
							{
								"h": [
									0.006,
									0.0,
									0.023,
									0.033,
									0.021,
									0.063,
									0.006,
									0.011,
									0.089,
									0.051,
									0.049,
									0.497
								],
								"e": 0.058
							},
							{
								"h": [
									0.014,
									0.001,
									0.008,
									0.044,
									0.007,
									0.027,
									0.001,
									0.016,
									0.257,
									0.003,
									0.008,
									0.006
								],
								"e": 0.023
							},
							{
								"h": [
									0.079,
									0.008,
									0.014,
									0.051,
									0.02,
									0.005,
									0.021,
									0.071,
									0.01,
									0.055,
									0.007,
									0.042
								],
								"e": 0.03
							},
							{
								"h": [
									0.669,
									0.125,
									0.309,
									0.409,
									0.194,
									0.031,
									0.016,
									0.063,
									0.174,
									0.012,
									0.323,
									0.25
								],
								"e": 0.163
							}
						],
						[
							{
								"h": [
									0.262,
									0.0,
									0.242,
									0.002,
									0.119,
									0.007,
									0.537,
									0.193,
									0.058,
									0.415,
									0.253,
									0.391
								],
								"e": 0.221
							},
							{
								"h": [
									0.028,
									0.232,
									0.289,
									0.142,
									0.171,
									0.008,
									0.093,
									0.121,
									0.141,
									0.077,
									0.797,
									0.003
								],
								"e": 0.172
							},
							{
								"h": [
									0.193,
									0.035,
									0.003,
									0.063,
									0.001,
									0.174,
									0.026,
									0.386,
									0.028,
									0.076,
									0.19,
									0.037
								],
								"e": 0.114
							},
							{
								"h": [
									0.0,
									0.033,
									0.002,
									0.0,
									0.006,
									0.016,
									0.101,
									0.01,
									0.058,
									0.019,
									0.007,
									0.005
								],
								"e": 0.024
							},
							{
								"h": [
									0.022,
									0.001,
									0.041,
									0.061,
									0.007,
									0.018,
									0.044,
									0.023,
									0.006,
									0.033,
									0.007,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.001,
									0.0,
									0.011,
									0.061,
									0.011,
									0.0,
									0.0,
									0.003,
									0.009,
									0.011,
									0.921,
									0.002
								],
								"e": 0.032
							},
							{
								"h": [
									0.015,
									0.001,
									0.026,
									0.006,
									0.018,
									0.032,
									0.007,
									0.074,
									0.04,
									0.0,
									0.003,
									0.015
								],
								"e": 0.018
							},
							{
								"h": [
									0.003,
									0.003,
									0.0,
									0.034,
									0.005,
									0.023,
									0.085,
									0.002,
									0.057,
									0.023,
									0.0,
									0.002
								],
								"e": 0.017
							},
							{
								"h": [
									0.004,
									0.0,
									0.063,
									0.01,
									0.003,
									0.012,
									0.002,
									0.005,
									0.029,
									0.031,
									0.06,
									0.288
								],
								"e": 0.036
							},
							{
								"h": [
									0.009,
									0.001,
									0.007,
									0.096,
									0.01,
									0.042,
									0.004,
									0.022,
									0.382,
									0.01,
									0.002,
									0.013
								],
								"e": 0.035
							},
							{
								"h": [
									0.168,
									0.007,
									0.039,
									0.029,
									0.035,
									0.001,
									0.026,
									0.062,
									0.003,
									0.027,
									0.033,
									0.003
								],
								"e": 0.034
							},
							{
								"h": [
									0.725,
									0.02,
									0.293,
									0.087,
									0.08,
									0.007,
									0.017,
									0.015,
									0.04,
									0.005,
									0.35,
									0.048
								],
								"e": 0.091
							}
						],
						[
							{
								"h": [
									0.221,
									0.003,
									0.396,
									0.033,
									0.155,
									0.131,
									0.11,
									0.507,
									0.64,
									0.649,
									0.523,
									0.564
								],
								"e": 0.339
							},
							{
								"h": [
									0.412,
									1.0,
									0.609,
									0.188,
									0.172,
									0.023,
									0.199,
									0.159,
									0.229,
									0.188,
									0.87,
									0.025
								],
								"e": 0.334
							},
							{
								"h": [
									0.148,
									0.063,
									0.033,
									0.21,
									0.015,
									0.869,
									0.099,
									0.558,
									1.0,
									0.053,
									0.216,
									0.065
								],
								"e": 0.313
							},
							{
								"h": [
									0.001,
									0.202,
									0.136,
									0.011,
									0.01,
									0.081,
									0.432,
									0.049,
									1.0,
									0.614,
									0.055,
									0.018
								],
								"e": 0.228
							},
							{
								"h": [
									0.884,
									0.029,
									0.07,
									0.171,
									0.037,
									0.105,
									0.072,
									0.029,
									0.017,
									0.219,
									0.012,
									0.0
								],
								"e": 0.116
							},
							{
								"h": [
									0.001,
									0.0,
									0.303,
									0.662,
									0.083,
									0.001,
									0.002,
									0.007,
									0.059,
									0.015,
									0.53,
									0.009
								],
								"e": 0.086
							},
							{
								"h": [
									1.0,
									0.029,
									0.02,
									0.021,
									0.021,
									0.028,
									0.013,
									0.064,
									0.161,
									0.0,
									0.001,
									0.091
								],
								"e": 0.085
							},
							{
								"h": [
									0.038,
									0.001,
									0.0,
									0.088,
									0.122,
									0.077,
									0.014,
									0.002,
									0.069,
									0.079,
									0.0,
									0.002
								],
								"e": 0.036
							},
							{
								"h": [
									0.029,
									0.01,
									0.047,
									0.075,
									0.031,
									0.269,
									0.008,
									0.116,
									0.103,
									0.082,
									0.087,
									0.147
								],
								"e": 0.074
							},
							{
								"h": [
									0.009,
									0.008,
									0.011,
									0.324,
									0.005,
									0.026,
									0.002,
									0.087,
									0.136,
									0.005,
									0.044,
									0.01
								],
								"e": 0.039
							},
							{
								"h": [
									0.044,
									0.008,
									0.013,
									0.081,
									0.101,
									0.025,
									0.017,
									0.03,
									0.008,
									0.101,
									0.004,
									0.011
								],
								"e": 0.034
							},
							{
								"h": [
									0.84,
									0.05,
									0.045,
									0.335,
									0.147,
									0.021,
									0.012,
									0.063,
									1.0,
									0.013,
									0.225,
									0.075
								],
								"e": 0.161
							}
						],
						[
							{
								"h": [
									0.185,
									0.002,
									0.317,
									0.096,
									0.133,
									0.605,
									0.051,
									0.813,
									1.0,
									0.787,
									0.445,
									0.341
								],
								"e": 0.444
							},
							{
								"h": [
									0.625,
									0.982,
									0.699,
									0.174,
									0.193,
									0.07,
									0.306,
									0.2,
									0.31,
									0.31,
									0.768,
									0.148
								],
								"e": 0.429
							},
							{
								"h": [
									0.071,
									0.065,
									0.023,
									1.0,
									0.035,
									0.481,
									0.109,
									0.591,
									0.304,
									0.062,
									0.174,
									0.065
								],
								"e": 0.304
							},
							{
								"h": [
									0.005,
									0.281,
									1.0,
									0.014,
									0.007,
									0.07,
									0.883,
									0.059,
									0.511,
									0.249,
									0.038,
									0.008
								],
								"e": 0.331
							},
							{
								"h": [
									0.987,
									0.023,
									0.155,
									0.121,
									0.088,
									0.151,
									0.062,
									0.059,
									0.011,
									0.554,
									0.018,
									0.0
								],
								"e": 0.167
							},
							{
								"h": [
									0.004,
									0.0,
									0.112,
									0.199,
									0.037,
									0.004,
									0.0,
									0.019,
									0.048,
									0.012,
									0.063,
									0.012
								],
								"e": 0.033
							},
							{
								"h": [
									0.258,
									0.039,
									0.045,
									0.028,
									0.048,
									0.032,
									0.012,
									0.032,
									0.315,
									0.0,
									0.002,
									0.069
								],
								"e": 0.057
							},
							{
								"h": [
									0.052,
									0.0,
									0.001,
									0.059,
									0.036,
									0.092,
									0.001,
									0.003,
									0.288,
									0.099,
									0.001,
									0.001
								],
								"e": 0.041
							},
							{
								"h": [
									0.066,
									0.004,
									0.052,
									0.077,
									0.204,
									0.408,
									0.017,
									0.116,
									0.023,
									0.063,
									0.034,
									0.033
								],
								"e": 0.079
							},
							{
								"h": [
									0.001,
									0.007,
									0.004,
									0.11,
									0.002,
									0.008,
									0.001,
									0.025,
									0.011,
									0.003,
									0.062,
									0.007
								],
								"e": 0.016
							},
							{
								"h": [
									0.009,
									0.003,
									0.007,
									0.084,
									0.035,
									0.021,
									0.01,
									0.01,
									0.008,
									0.041,
									0.003,
									0.005
								],
								"e": 0.018
							},
							{
								"h": [
									0.368,
									0.073,
									0.039,
									0.126,
									0.064,
									0.013,
									0.01,
									0.045,
									0.415,
									0.005,
									0.054,
									0.038
								],
								"e": 0.075
							}
						],
						[
							{
								"h": [
									0.403,
									0.0,
									0.316,
									0.046,
									1.0,
									0.018,
									0.667,
									1.0,
									0.093,
									0.296,
									0.186,
									0.432
								],
								"e": 0.436
							},
							{
								"h": [
									1.0,
									0.502,
									0.266,
									0.157,
									0.257,
									0.009,
									0.322,
									0.275,
									0.102,
									0.054,
									1.0,
									0.003
								],
								"e": 0.35
							},
							{
								"h": [
									1.0,
									0.052,
									1.0,
									0.249,
									1.0,
									0.769,
									0.041,
									1.0,
									0.231,
									1.0,
									0.388,
									0.093
								],
								"e": 0.952
							},
							{
								"h": [
									0.001,
									0.037,
									0.095,
									1.0,
									0.003,
									0.066,
									1.0,
									1.0,
									0.189,
									0.775,
									0.145,
									1.0
								],
								"e": 0.7
							},
							{
								"h": [
									1.0,
									1.0,
									0.056,
									1.0,
									0.352,
									0.286,
									0.117,
									0.216,
									0.012,
									0.101,
									0.013,
									1.0
								],
								"e": 0.589
							},
							{
								"h": [
									0.001,
									0.0,
									0.126,
									0.062,
									0.88,
									0.008,
									0.099,
									1.0,
									0.008,
									0.008,
									0.945,
									0.06
								],
								"e": 0.199
							},
							{
								"h": [
									0.35,
									0.083,
									0.008,
									0.023,
									0.018,
									0.039,
									0.02,
									0.241,
									1.0,
									0.0,
									0.009,
									0.21
								],
								"e": 0.121
							},
							{
								"h": [
									0.118,
									0.0,
									0.003,
									0.186,
									0.02,
									0.04,
									0.003,
									0.001,
									0.124,
									0.05,
									0.001,
									0.0
								],
								"e": 0.037
							},
							{
								"h": [
									0.007,
									0.004,
									0.038,
									0.003,
									0.289,
									0.149,
									0.014,
									0.158,
									0.079,
									0.063,
									0.028,
									0.014
								],
								"e": 0.061
							},
							{
								"h": [
									0.01,
									0.001,
									0.011,
									0.329,
									0.005,
									0.034,
									0.001,
									0.011,
									0.043,
									0.001,
									0.074,
									0.002
								],
								"e": 0.03
							},
							{
								"h": [
									0.055,
									0.008,
									0.003,
									0.047,
									0.046,
									0.011,
									0.043,
									0.019,
									0.002,
									0.039,
									0.004,
									0.007
								],
								"e": 0.022
							},
							{
								"h": [
									0.914,
									0.013,
									0.013,
									0.236,
									0.009,
									0.006,
									0.006,
									0.007,
									0.202,
									0.006,
									0.082,
									0.035
								],
								"e": 0.066
							}
						],
						[
							{
								"h": [
									0.113,
									1.0,
									0.516,
									1.0,
									0.319,
									1.0,
									0.04,
									0.939,
									0.463,
									0.781,
									1.0,
									0.248
								],
								"e": 1.0
							},
							{
								"h": [
									0.134,
									0.493,
									0.863,
									0.164,
									0.168,
									1.0,
									0.131,
									0.221,
									0.215,
									0.077,
									0.735,
									1.0
								],
								"e": 0.592
							},
							{
								"h": [
									0.84,
									0.071,
									0.007,
									0.127,
									0.047,
									0.513,
									0.147,
									0.863,
									0.363,
									0.057,
									0.235,
									0.079
								],
								"e": 0.306
							},
							{
								"h": [
									0.0,
									0.073,
									0.032,
									0.047,
									0.004,
									0.061,
									0.292,
									0.088,
									0.371,
									0.145,
									0.28,
									0.034
								],
								"e": 0.147
							},
							{
								"h": [
									0.721,
									0.012,
									0.027,
									0.075,
									0.111,
									0.225,
									0.083,
									1.0,
									0.02,
									0.033,
									0.019,
									0.0
								],
								"e": 0.179
							},
							{
								"h": [
									0.002,
									0.0,
									0.013,
									0.017,
									0.125,
									0.002,
									0.0,
									0.042,
									0.009,
									0.002,
									0.472,
									0.287
								],
								"e": 0.052
							},
							{
								"h": [
									0.122,
									0.02,
									0.033,
									0.01,
									0.016,
									0.05,
									0.048,
									0.106,
									0.025,
									0.0,
									0.006,
									0.043
								],
								"e": 0.036
							},
							{
								"h": [
									0.078,
									0.0,
									0.009,
									0.096,
									0.016,
									0.014,
									0.009,
									0.013,
									0.081,
									0.039,
									0.001,
									0.003
								],
								"e": 0.025
							},
							{
								"h": [
									0.088,
									0.009,
									0.023,
									0.007,
									0.047,
									0.129,
									0.01,
									1.0,
									0.019,
									0.066,
									0.06,
									0.021
								],
								"e": 0.097
							},
							{
								"h": [
									0.005,
									0.005,
									0.002,
									0.234,
									0.005,
									0.043,
									0.001,
									0.019,
									0.027,
									0.001,
									0.025,
									0.006
								],
								"e": 0.021
							},
							{
								"h": [
									0.015,
									0.005,
									0.006,
									0.069,
									0.035,
									0.01,
									0.016,
									0.038,
									0.009,
									0.1,
									0.003,
									0.04
								],
								"e": 0.027
							},
							{
								"h": [
									0.501,
									0.094,
									0.102,
									0.356,
									0.147,
									0.024,
									0.014,
									0.095,
									0.341,
									0.012,
									0.227,
									0.1
								],
								"e": 0.125
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " the",
					"o": " United",
					"t": " United",
					"r": [
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.683,
								" first": 0.035,
								" latter": 0.025,
								" world": 0.02,
								" most": 0.014,
								" way": 0.014,
								" last": 0.011,
								" next": 0.009,
								" following": 0.006,
								" second": 0.005
							}
						},
						{
							"p": " same",
							"b": 0.001,
							"k": {
								" same": 0.819,
								" latter": 0.025,
								" first": 0.023,
								" world": 0.021,
								" most": 0.009,
								" following": 0.005,
								" way": 0.004,
								" fastest": 0.004,
								" last": 0.003,
								" second": 0.003
							}
						},
						{
							"p": " same",
							"b": 0.003,
							"k": {
								" same": 0.852,
								" world": 0.041,
								" first": 0.009,
								" latter": 0.007,
								" largest": 0.004,
								" most": 0.003,
								" current": 0.003,
								" United": 0.003,
								" following": 0.003,
								" vast": 0.003
							}
						},
						{
							"p": " same",
							"b": 0.008,
							"k": {
								" same": 0.463,
								" world": 0.133,
								" largest": 0.083,
								" nation": 0.033,
								" highest": 0.031,
								" first": 0.026,
								" city": 0.013,
								" most": 0.011,
								" popular": 0.01,
								" country": 0.008
							}
						},
						{
							"p": " largest",
							"b": 0.007,
							"k": {
								" largest": 0.309,
								" world": 0.184,
								" city": 0.081,
								" country": 0.05,
								" same": 0.037,
								" highest": 0.036,
								" nation": 0.026,
								" most": 0.021,
								" state": 0.012,
								" larger": 0.012
							}
						},
						{
							"p": " city",
							"b": 0.026,
							"k": {
								" city": 0.195,
								" largest": 0.192,
								" country": 0.09,
								" nation": 0.053,
								" same": 0.048,
								" world": 0.032,
								" United": 0.026,
								" town": 0.021,
								" larger": 0.021,
								" state": 0.017
							}
						},
						{
							"p": " nation",
							"b": 0.129,
							"k": {
								" nation": 0.314,
								" United": 0.129,
								" country": 0.127,
								" largest": 0.1,
								" city": 0.099,
								" state": 0.051,
								" West": 0.033,
								" South": 0.021,
								" world": 0.016,
								" East": 0.014
							}
						},
						{
							"p": " nation",
							"b": 0.115,
							"k": {
								" nation": 0.525,
								" country": 0.119,
								" United": 0.115,
								" world": 0.051,
								" largest": 0.033,
								" state": 0.029,
								" West": 0.022,
								" South": 0.015,
								" US": 0.007,
								" USA": 0.007
							}
						},
						{
							"p": " United",
							"b": 0.617,
							"k": {
								" United": 0.617,
								" nation": 0.23,
								" country": 0.051,
								" world": 0.015,
								" Republic": 0.014,
								" USA": 0.012,
								" South": 0.008,
								" West": 0.008,
								" US": 0.007,
								" North": 0.004
							}
						},
						{
							"p": " United",
							"b": 0.917,
							"k": {
								" United": 0.917,
								" nation": 0.036,
								" world": 0.013,
								" country": 0.009,
								" Republic": 0.005,
								" USA": 0.005,
								" US": 0.002,
								" Netherlands": 0.002,
								" Americas": 0.002,
								" U": 0.001
							}
						},
						{
							"p": " United",
							"b": 0.779,
							"k": {
								" United": 0.779,
								" world": 0.056,
								" nation": 0.032,
								" US": 0.018,
								" USA": 0.012,
								" U": 0.01,
								" country": 0.01,
								" Democratic": 0.009,
								" Republic": 0.007,
								" European": 0.004
							}
						},
						{
							"p": " United",
							"b": 0.271,
							"k": {
								" United": 0.271,
								" world": 0.091,
								" Democratic": 0.032,
								" US": 0.031,
								" U": 0.027,
								" state": 0.018,
								" nation": 0.018,
								" European": 0.017,
								" country": 0.011,
								" Republic": 0.011
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.003,
									1.0,
									0.026,
									0.625,
									0.204,
									1.0,
									0.206,
									0.031,
									1.0,
									1.0,
									1.0
								],
								"e": 0.771
							},
							{
								"h": [
									0.424,
									0.9,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.008,
									0.256
								],
								"e": 1.0
							},
							{
								"h": [
									0.633,
									1.0,
									0.024,
									0.485,
									0.551,
									0.884,
									1.0,
									0.874,
									0.352,
									0.191,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.397,
									0.14,
									1.0,
									1.0,
									0.226,
									0.135,
									0.446,
									1.0,
									1.0,
									0.188
								],
								"e": 1.0
							},
							{
								"h": [
									0.101,
									0.056,
									1.0,
									0.359,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									0.659,
									1.0,
									1.0,
									0.163,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.687,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.121,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.551,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.199,
									0.03,
									0.551,
									0.017,
									0.185,
									0.273,
									0.014,
									0.118,
									0.294,
									0.765,
									0.743,
									0.477
								],
								"e": 0.351
							},
							{
								"h": [
									0.09,
									0.473,
									0.214,
									0.108,
									0.133,
									0.728,
									0.132,
									0.135,
									0.351,
									0.117,
									0.59,
									0.075
								],
								"e": 0.262
							},
							{
								"h": [
									0.109,
									0.032,
									0.001,
									0.043,
									0.084,
									0.271,
									0.065,
									0.054,
									0.05,
									0.015,
									0.076,
									0.023
								],
								"e": 0.082
							},
							{
								"h": [
									0.002,
									0.137,
									0.052,
									0.002,
									0.006,
									0.031,
									0.02,
									0.003,
									0.063,
									0.068,
									0.005,
									0.014
								],
								"e": 0.041
							},
							{
								"h": [
									0.026,
									0.005,
									0.031,
									0.044,
									0.015,
									0.074,
									0.044,
									0.004,
									0.005,
									0.02,
									0.021,
									0.0
								],
								"e": 0.032
							},
							{
								"h": [
									0.0,
									0.0,
									0.089,
									0.208,
									0.016,
									0.0,
									0.0,
									0.004,
									0.009,
									0.016,
									0.141,
									0.009
								],
								"e": 0.033
							},
							{
								"h": [
									0.1,
									0.014,
									0.026,
									0.042,
									0.092,
									0.021,
									0.013,
									0.134,
									0.02,
									0.0,
									0.001,
									0.048
								],
								"e": 0.034
							},
							{
								"h": [
									0.002,
									0.002,
									0.0,
									0.02,
									0.008,
									0.032,
									0.02,
									0.001,
									0.188,
									0.04,
									0.0,
									0.001
								],
								"e": 0.021
							},
							{
								"h": [
									0.006,
									0.0,
									0.028,
									0.009,
									0.029,
									0.065,
									0.007,
									0.006,
									0.126,
									0.075,
									0.036,
									0.353
								],
								"e": 0.056
							},
							{
								"h": [
									0.014,
									0.002,
									0.009,
									0.043,
									0.001,
									0.029,
									0.001,
									0.013,
									0.247,
									0.0,
									0.012,
									0.004
								],
								"e": 0.02
							},
							{
								"h": [
									0.052,
									0.004,
									0.009,
									0.032,
									0.015,
									0.003,
									0.028,
									0.047,
									0.004,
									0.016,
									0.006,
									0.011
								],
								"e": 0.018
							},
							{
								"h": [
									0.313,
									0.085,
									0.134,
									0.293,
									0.146,
									0.012,
									0.009,
									0.051,
									0.118,
									0.007,
									0.227,
									0.087
								],
								"e": 0.095
							}
						],
						[
							{
								"h": [
									0.192,
									0.002,
									0.337,
									0.005,
									0.226,
									0.081,
									0.575,
									0.086,
									0.023,
									0.471,
									0.285,
									0.314
								],
								"e": 0.284
							},
							{
								"h": [
									0.048,
									0.169,
									0.298,
									0.124,
									0.183,
									0.095,
									0.08,
									0.124,
									0.184,
									0.086,
									0.778,
									0.008
								],
								"e": 0.171
							},
							{
								"h": [
									0.108,
									0.045,
									0.001,
									0.116,
									0.01,
									0.059,
									0.021,
									0.379,
									0.01,
									0.064,
									0.192,
									0.041
								],
								"e": 0.1
							},
							{
								"h": [
									0.0,
									0.043,
									0.018,
									0.002,
									0.003,
									0.04,
									0.091,
									0.016,
									0.049,
									0.033,
									0.025,
									0.012
								],
								"e": 0.036
							},
							{
								"h": [
									0.022,
									0.001,
									0.023,
									0.028,
									0.005,
									0.015,
									0.041,
									0.04,
									0.003,
									0.013,
									0.005,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.0,
									0.0,
									0.009,
									0.085,
									0.004,
									0.0,
									0.0,
									0.002,
									0.004,
									0.009,
									0.115,
									0.004
								],
								"e": 0.016
							},
							{
								"h": [
									0.053,
									0.002,
									0.032,
									0.008,
									0.045,
									0.032,
									0.011,
									0.088,
									0.059,
									0.0,
									0.003,
									0.025
								],
								"e": 0.024
							},
							{
								"h": [
									0.006,
									0.002,
									0.0,
									0.024,
									0.002,
									0.018,
									0.052,
									0.001,
									0.075,
									0.033,
									0.0,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.005,
									0.0,
									0.142,
									0.004,
									0.007,
									0.017,
									0.002,
									0.002,
									0.049,
									0.035,
									0.046,
									0.149
								],
								"e": 0.038
							},
							{
								"h": [
									0.006,
									0.001,
									0.006,
									0.048,
									0.001,
									0.03,
									0.001,
									0.016,
									0.675,
									0.001,
									0.003,
									0.009
								],
								"e": 0.038
							},
							{
								"h": [
									0.062,
									0.001,
									0.024,
									0.014,
									0.055,
									0.001,
									0.01,
									0.05,
									0.001,
									0.007,
									0.013,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.263,
									0.017,
									0.147,
									0.057,
									0.108,
									0.005,
									0.015,
									0.014,
									0.024,
									0.003,
									0.193,
									0.028
								],
								"e": 0.054
							}
						],
						[
							{
								"h": [
									0.209,
									0.003,
									0.524,
									0.043,
									0.506,
									0.384,
									0.04,
									0.243,
									0.522,
									0.605,
									0.462,
									0.409
								],
								"e": 0.399
							},
							{
								"h": [
									0.455,
									0.752,
									0.596,
									0.16,
									0.17,
									0.336,
									0.188,
									0.165,
									0.256,
									0.224,
									0.851,
									0.024
								],
								"e": 0.33
							},
							{
								"h": [
									0.036,
									0.092,
									0.017,
									0.064,
									0.131,
									0.64,
									0.115,
									0.587,
									1.0,
									0.034,
									0.217,
									0.056
								],
								"e": 0.272
							},
							{
								"h": [
									0.001,
									0.175,
									0.391,
									0.006,
									0.011,
									0.07,
									0.079,
									0.021,
									0.325,
									0.59,
									0.02,
									0.032
								],
								"e": 0.146
							},
							{
								"h": [
									0.07,
									0.027,
									0.031,
									0.047,
									0.011,
									0.113,
									0.038,
									0.036,
									0.009,
									0.091,
									0.016,
									0.0
								],
								"e": 0.052
							},
							{
								"h": [
									0.001,
									0.0,
									0.097,
									0.446,
									0.096,
									0.002,
									0.003,
									0.005,
									0.04,
									0.015,
									0.106,
									0.008
								],
								"e": 0.051
							},
							{
								"h": [
									0.988,
									0.015,
									0.022,
									0.029,
									0.063,
									0.038,
									0.014,
									0.104,
									0.16,
									0.0,
									0.001,
									0.12
								],
								"e": 0.064
							},
							{
								"h": [
									0.026,
									0.002,
									0.0,
									0.07,
									0.011,
									0.076,
									0.014,
									0.001,
									0.034,
									0.103,
									0.001,
									0.001
								],
								"e": 0.024
							},
							{
								"h": [
									0.017,
									0.003,
									0.065,
									0.033,
									0.029,
									0.218,
									0.013,
									0.029,
									0.104,
									0.099,
									0.061,
									0.082
								],
								"e": 0.055
							},
							{
								"h": [
									0.005,
									0.005,
									0.011,
									0.308,
									0.002,
									0.017,
									0.001,
									0.032,
									0.168,
									0.001,
									0.045,
									0.004
								],
								"e": 0.031
							},
							{
								"h": [
									0.039,
									0.003,
									0.014,
									0.044,
									0.086,
									0.008,
									0.025,
									0.023,
									0.003,
									0.007,
									0.003,
									0.012
								],
								"e": 0.021
							},
							{
								"h": [
									0.583,
									0.023,
									0.037,
									0.193,
									0.109,
									0.014,
									0.008,
									0.029,
									0.546,
									0.009,
									0.251,
									0.035
								],
								"e": 0.101
							}
						],
						[
							{
								"h": [
									0.109,
									1.0,
									0.161,
									0.122,
									0.364,
									1.0,
									0.008,
									0.265,
									0.73,
									0.666,
									0.999,
									0.513
								],
								"e": 0.676
							},
							{
								"h": [
									0.268,
									0.288,
									0.719,
									0.157,
									0.188,
									0.225,
									0.158,
									0.208,
									0.34,
									0.307,
									0.791,
									0.986
								],
								"e": 0.406
							},
							{
								"h": [
									0.046,
									0.047,
									0.001,
									0.112,
									0.074,
									0.042,
									0.073,
									0.566,
									0.031,
									0.01,
									0.173,
									0.05
								],
								"e": 0.109
							},
							{
								"h": [
									0.016,
									0.159,
									0.029,
									0.002,
									0.011,
									0.042,
									0.033,
									0.006,
									0.015,
									0.228,
									0.027,
									0.009
								],
								"e": 0.056
							},
							{
								"h": [
									0.021,
									0.024,
									0.069,
									0.022,
									0.053,
									0.087,
									0.023,
									0.043,
									0.006,
									0.19,
									0.014,
									0.0
								],
								"e": 0.061
							},
							{
								"h": [
									0.0,
									0.0,
									0.053,
									0.389,
									0.015,
									0.001,
									0.0,
									0.01,
									0.005,
									0.011,
									0.02,
									0.024
								],
								"e": 0.031
							},
							{
								"h": [
									0.596,
									0.005,
									0.026,
									0.019,
									0.057,
									0.027,
									0.029,
									0.028,
									0.061,
									0.0,
									0.002,
									0.169
								],
								"e": 0.045
							},
							{
								"h": [
									0.009,
									0.0,
									0.0,
									0.036,
									0.005,
									0.071,
									0.002,
									0.0,
									0.034,
									0.081,
									0.0,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.1,
									0.0,
									0.04,
									0.021,
									0.07,
									0.595,
									0.008,
									0.008,
									0.025,
									0.096,
									0.027,
									0.014
								],
								"e": 0.062
							},
							{
								"h": [
									0.001,
									0.009,
									0.003,
									0.052,
									0.0,
									0.005,
									0.0,
									0.008,
									0.01,
									0.0,
									0.034,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.012,
									0.002,
									0.003,
									0.037,
									0.053,
									0.005,
									0.03,
									0.011,
									0.002,
									0.005,
									0.002,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.227,
									0.019,
									0.029,
									0.075,
									0.031,
									0.005,
									0.004,
									0.012,
									0.217,
									0.003,
									0.057,
									0.037
								],
								"e": 0.041
							}
						],
						[
							{
								"h": [
									0.322,
									0.0,
									0.228,
									0.051,
									0.72,
									0.049,
									0.567,
									0.36,
									0.047,
									0.301,
									0.251,
									0.331
								],
								"e": 0.36
							},
							{
								"h": [
									0.514,
									0.433,
									0.282,
									0.135,
									0.271,
									0.05,
									0.125,
									0.231,
									0.124,
									0.063,
									1.0,
									0.009
								],
								"e": 0.252
							},
							{
								"h": [
									0.424,
									0.069,
									0.12,
									0.405,
									1.0,
									1.0,
									0.034,
									0.994,
									0.402,
									1.0,
									0.421,
									0.094
								],
								"e": 0.637
							},
							{
								"h": [
									0.0,
									0.051,
									0.152,
									1.0,
									0.003,
									0.109,
									1.0,
									1.0,
									0.252,
									0.874,
									0.095,
									1.0
								],
								"e": 0.674
							},
							{
								"h": [
									0.542,
									1.0,
									0.023,
									1.0,
									0.194,
									0.063,
									0.113,
									0.129,
									0.007,
									0.052,
									0.007,
									0.0
								],
								"e": 0.346
							},
							{
								"h": [
									0.0,
									0.0,
									0.226,
									0.087,
									1.0,
									0.024,
									0.275,
									1.0,
									0.025,
									0.009,
									0.174,
									0.103
								],
								"e": 0.22
							},
							{
								"h": [
									1.0,
									0.104,
									0.009,
									0.017,
									0.053,
									0.05,
									0.034,
									0.226,
									0.743,
									0.003,
									0.008,
									0.407
								],
								"e": 0.126
							},
							{
								"h": [
									0.076,
									0.0,
									0.002,
									0.162,
									0.006,
									0.034,
									0.005,
									0.001,
									0.05,
									0.101,
									0.007,
									0.008
								],
								"e": 0.031
							},
							{
								"h": [
									0.004,
									0.014,
									0.036,
									0.001,
									0.631,
									0.282,
									0.032,
									0.068,
									0.221,
									0.138,
									0.057,
									0.035
								],
								"e": 0.103
							},
							{
								"h": [
									0.009,
									0.004,
									0.012,
									0.337,
									0.001,
									0.022,
									0.001,
									0.008,
									0.081,
									0.001,
									0.05,
									0.007
								],
								"e": 0.027
							},
							{
								"h": [
									0.05,
									0.003,
									0.005,
									0.022,
									0.064,
									0.01,
									0.016,
									0.038,
									0.004,
									0.005,
									0.002,
									0.002
								],
								"e": 0.017
							},
							{
								"h": [
									0.435,
									0.006,
									0.008,
									0.095,
									0.011,
									0.009,
									0.007,
									0.006,
									0.115,
									0.005,
									0.197,
									0.039
								],
								"e": 0.047
							}
						],
						[
							{
								"h": [
									0.107,
									0.112,
									0.597,
									0.335,
									1.0,
									0.159,
									0.011,
									0.777,
									0.266,
									0.702,
									0.362,
									0.199
								],
								"e": 0.492
							},
							{
								"h": [
									1.0,
									1.0,
									0.793,
									0.138,
									0.158,
									0.484,
									0.104,
									0.162,
									0.217,
									0.074,
									0.681,
									0.181
								],
								"e": 0.381
							},
							{
								"h": [
									1.0,
									0.069,
									1.0,
									0.414,
									0.23,
									0.768,
									0.131,
									0.705,
									0.723,
									0.073,
									0.179,
									0.06
								],
								"e": 0.624
							},
							{
								"h": [
									0.0,
									0.065,
									0.69,
									0.054,
									0.003,
									0.044,
									0.181,
									0.158,
									1.0,
									0.179,
									0.039,
									0.095
								],
								"e": 0.233
							},
							{
								"h": [
									1.0,
									0.035,
									0.017,
									0.069,
									0.129,
									0.064,
									0.074,
									0.165,
									0.015,
									0.03,
									0.013,
									1.0
								],
								"e": 0.309
							},
							{
								"h": [
									0.001,
									0.0,
									0.044,
									0.046,
									0.294,
									0.001,
									0.004,
									0.111,
									0.005,
									0.003,
									0.108,
									0.167
								],
								"e": 0.054
							},
							{
								"h": [
									0.842,
									0.02,
									0.029,
									0.007,
									0.03,
									0.054,
									0.032,
									0.162,
									0.224,
									0.0,
									0.006,
									0.21
								],
								"e": 0.072
							},
							{
								"h": [
									0.314,
									0.0,
									0.0,
									0.06,
									0.004,
									0.031,
									0.008,
									0.006,
									0.2,
									0.084,
									0.0,
									0.006
								],
								"e": 0.047
							},
							{
								"h": [
									0.026,
									0.005,
									0.037,
									0.004,
									0.209,
									0.465,
									0.016,
									1.0,
									0.078,
									0.153,
									0.055,
									0.022
								],
								"e": 0.159
							},
							{
								"h": [
									0.008,
									0.013,
									0.003,
									1.0,
									0.005,
									0.029,
									0.002,
									0.013,
									0.068,
									0.0,
									0.141,
									0.004
								],
								"e": 0.062
							},
							{
								"h": [
									0.026,
									0.003,
									0.011,
									0.095,
									0.066,
									0.015,
									0.05,
									0.038,
									0.006,
									0.235,
									0.004,
									0.313
								],
								"e": 0.064
							},
							{
								"h": [
									0.439,
									0.064,
									0.064,
									0.74,
									0.166,
									0.014,
									0.01,
									0.071,
									1.0,
									0.008,
									0.776,
									0.174
								],
								"e": 0.205
							}
						],
						[
							{
								"h": [
									0.116,
									0.956,
									0.155,
									1.0,
									0.968,
									0.784,
									0.008,
									1.0,
									1.0,
									0.669,
									0.815,
									0.557
								],
								"e": 1.0
							},
							{
								"h": [
									0.624,
									0.51,
									0.887,
									0.177,
									0.232,
									0.183,
									0.121,
									0.222,
									0.309,
									0.263,
									0.935,
									1.0
								],
								"e": 0.457
							},
							{
								"h": [
									0.12,
									0.048,
									0.051,
									1.0,
									0.287,
									0.422,
									0.112,
									1.0,
									0.169,
									0.081,
									0.286,
									0.096
								],
								"e": 0.34
							},
							{
								"h": [
									0.008,
									0.169,
									1.0,
									0.023,
									0.011,
									0.025,
									0.19,
									0.103,
									0.096,
									0.128,
									0.209,
									0.029
								],
								"e": 0.187
							},
							{
								"h": [
									0.093,
									0.026,
									0.011,
									0.051,
									0.044,
									0.15,
									0.065,
									0.773,
									0.014,
									0.073,
									0.026,
									0.016
								],
								"e": 0.12
							},
							{
								"h": [
									0.003,
									0.001,
									0.014,
									0.046,
									0.048,
									0.001,
									0.007,
									0.048,
									0.023,
									0.002,
									0.015,
									0.246
								],
								"e": 0.035
							},
							{
								"h": [
									0.376,
									0.016,
									0.022,
									0.011,
									0.048,
									0.089,
									0.077,
									0.084,
									0.083,
									0.002,
									0.006,
									0.233
								],
								"e": 0.057
							},
							{
								"h": [
									0.058,
									0.001,
									0.001,
									0.025,
									0.002,
									0.04,
									0.003,
									0.005,
									0.072,
									0.036,
									0.001,
									0.01
								],
								"e": 0.017
							},
							{
								"h": [
									0.052,
									0.006,
									0.012,
									0.009,
									0.06,
									0.178,
									0.009,
									0.162,
									0.051,
									0.136,
									0.065,
									0.015
								],
								"e": 0.057
							},
							{
								"h": [
									0.004,
									0.031,
									0.003,
									0.105,
									0.001,
									0.022,
									0.004,
									0.019,
									0.033,
									0.001,
									0.022,
									0.006
								],
								"e": 0.016
							},
							{
								"h": [
									0.013,
									0.01,
									0.019,
									0.087,
									0.029,
									0.005,
									0.04,
									0.034,
									0.006,
									0.024,
									0.007,
									0.041
								],
								"e": 0.025
							},
							{
								"h": [
									0.305,
									0.067,
									0.04,
									0.085,
									0.078,
									0.012,
									0.005,
									0.045,
									0.275,
									0.006,
									0.189,
									0.027
								],
								"e": 0.068
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " United",
					"o": " States",
					"t": " States",
					"r": [
						{
							"p": " States",
							"b": 0.898,
							"k": {
								" States": 0.898,
								" United": 0.051,
								" Kingdom": 0.026,
								" State": 0.003,
								" Nations": 0.003,
								" Wire": 0.002,
								"wide": 0.001,
								" Way": 0.001,
								" Spirit": 0.001,
								" Methodist": 0.001
							}
						},
						{
							"p": " States",
							"b": 0.95,
							"k": {
								" States": 0.95,
								" Kingdom": 0.031,
								" Nations": 0.005,
								" United": 0.002,
								" State": 0.002,
								" Way": 0.002,
								"wide": 0.001,
								" Methodist": 0.001,
								"!": 0.0,
								"\"": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.991,
							"k": {
								" States": 0.991,
								" Kingdom": 0.005,
								" State": 0.001,
								" Nations": 0.001,
								" Methodist": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.989,
							"k": {
								" States": 0.989,
								" Kingdom": 0.006,
								" Nations": 0.004,
								" State": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.981,
							"k": {
								" States": 0.981,
								" Nations": 0.014,
								" Kingdom": 0.004,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.997,
							"k": {
								" States": 0.997,
								" Nations": 0.003,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.997,
							"k": {
								" States": 0.997,
								" Nations": 0.002,
								" Kingdom": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.999,
							"k": {
								" States": 0.999,
								" Nations": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.996,
							"k": {
								" States": 0.996,
								" Nations": 0.003,
								" Kingdom": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.997,
							"k": {
								" States": 0.997,
								" Kingdom": 0.002,
								" Nations": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.979,
							"k": {
								" States": 0.979,
								" Kingdom": 0.019,
								" Nations": 0.003,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.929,
							"k": {
								" States": 0.929,
								" Kingdom": 0.037,
								" Nations": 0.014,
								" Arab": 0.011,
								" State": 0.003,
								" S": 0.001,
								" Nation": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.847,
									0.002,
									1.0,
									0.0,
									0.065,
									0.105,
									1.0,
									0.36,
									1.0,
									1.0,
									0.882,
									1.0
								],
								"e": 0.435
							},
							{
								"h": [
									0.132,
									0.43,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.009,
									0.119
								],
								"e": 1.0
							},
							{
								"h": [
									0.454,
									1.0,
									0.288,
									0.09,
									0.404,
									0.3,
									1.0,
									0.664,
									0.293,
									0.39,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.326,
									1.0,
									1.0,
									1.0,
									0.994,
									0.908,
									0.333,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.088,
									0.841,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.637,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.47,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.217,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.391,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.537,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.357
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.463,
									0.0,
									0.44,
									0.0,
									0.014,
									0.0,
									0.079,
									0.115,
									0.45,
									0.574,
									0.395,
									0.371
								],
								"e": 0.151
							},
							{
								"h": [
									0.08,
									0.286,
									0.298,
									0.09,
									0.139,
									0.424,
									0.122,
									0.148,
									0.352,
									0.153,
									0.571,
									0.045
								],
								"e": 0.25
							},
							{
								"h": [
									0.11,
									0.084,
									0.008,
									0.006,
									0.136,
									0.176,
									0.096,
									0.046,
									0.04,
									0.027,
									0.103,
									0.023
								],
								"e": 0.111
							},
							{
								"h": [
									0.006,
									0.065,
									0.064,
									0.053,
									0.029,
									0.03,
									0.067,
									0.042,
									0.022,
									0.112,
									0.011,
									0.034
								],
								"e": 0.043
							},
							{
								"h": [
									0.004,
									0.068,
									0.045,
									0.013,
									0.016,
									0.037,
									0.034,
									0.001,
									0.012,
									0.03,
									0.03,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.026,
									0.0,
									0.072,
									0.108,
									0.01,
									0.001,
									0.001,
									0.035,
									0.041,
									0.021,
									0.11,
									0.034
								],
								"e": 0.035
							},
							{
								"h": [
									0.023,
									0.018,
									0.013,
									0.018,
									0.068,
									0.007,
									0.017,
									0.049,
									0.031,
									0.0,
									0.001,
									0.013
								],
								"e": 0.02
							},
							{
								"h": [
									0.015,
									0.005,
									0.0,
									0.016,
									0.002,
									0.039,
									0.021,
									0.004,
									0.098,
									0.051,
									0.0,
									0.006
								],
								"e": 0.017
							},
							{
								"h": [
									0.021,
									0.001,
									0.027,
									0.004,
									0.004,
									0.033,
									0.01,
									0.014,
									0.034,
									0.05,
									0.041,
									0.336
								],
								"e": 0.044
							},
							{
								"h": [
									0.01,
									0.002,
									0.006,
									0.036,
									0.001,
									0.035,
									0.0,
									0.032,
									0.227,
									0.001,
									0.01,
									0.001
								],
								"e": 0.023
							},
							{
								"h": [
									0.04,
									0.001,
									0.004,
									0.008,
									0.006,
									0.003,
									0.019,
									0.034,
									0.006,
									0.007,
									0.011,
									0.003
								],
								"e": 0.011
							},
							{
								"h": [
									0.153,
									0.038,
									0.079,
									0.256,
									0.088,
									0.011,
									0.004,
									0.018,
									0.48,
									0.005,
									0.069,
									1.0
								],
								"e": 0.12
							}
						],
						[
							{
								"h": [
									0.442,
									0.002,
									0.155,
									0.0,
									0.032,
									0.0,
									0.288,
									0.447,
									0.322,
									0.349,
									0.232,
									0.378
								],
								"e": 0.148
							},
							{
								"h": [
									0.078,
									0.098,
									0.356,
									0.103,
									0.164,
									0.119,
									0.202,
									0.288,
									0.152,
									0.095,
									0.756,
									0.008
								],
								"e": 0.208
							},
							{
								"h": [
									0.148,
									0.074,
									0.015,
									0.016,
									0.197,
									0.026,
									0.015,
									0.31,
									0.011,
									0.134,
									0.233,
									0.041
								],
								"e": 0.137
							},
							{
								"h": [
									0.0,
									0.054,
									0.038,
									0.02,
									0.005,
									0.033,
									0.05,
									0.061,
									0.027,
									0.045,
									0.031,
									0.018
								],
								"e": 0.03
							},
							{
								"h": [
									0.003,
									0.007,
									0.006,
									0.02,
									0.003,
									0.004,
									0.05,
									0.041,
									0.002,
									0.014,
									0.006,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.0,
									0.013,
									0.059,
									0.004,
									0.001,
									0.004,
									0.012,
									0.005,
									0.005,
									0.009,
									0.011
								],
								"e": 0.008
							},
							{
								"h": [
									0.011,
									0.002,
									0.032,
									0.008,
									0.022,
									0.009,
									0.018,
									0.053,
									0.03,
									0.0,
									0.005,
									0.007
								],
								"e": 0.016
							},
							{
								"h": [
									0.006,
									0.004,
									0.0,
									0.037,
									0.001,
									0.017,
									0.076,
									0.002,
									0.073,
									0.189,
									0.0,
									0.001
								],
								"e": 0.027
							},
							{
								"h": [
									0.011,
									0.0,
									0.147,
									0.001,
									0.001,
									0.008,
									0.004,
									0.009,
									0.028,
									0.04,
									0.031,
									0.127
								],
								"e": 0.033
							},
							{
								"h": [
									0.006,
									0.0,
									0.01,
									0.011,
									0.001,
									0.023,
									0.001,
									0.122,
									0.246,
									0.002,
									0.002,
									0.003
								],
								"e": 0.028
							},
							{
								"h": [
									0.027,
									0.0,
									0.01,
									0.002,
									0.014,
									0.0,
									0.001,
									0.028,
									0.001,
									0.002,
									0.004,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.065,
									0.004,
									0.07,
									0.036,
									0.029,
									0.001,
									0.004,
									0.004,
									0.2,
									0.001,
									0.034,
									0.397
								],
								"e": 0.047
							}
						],
						[
							{
								"h": [
									0.455,
									0.0,
									0.318,
									0.0,
									0.017,
									0.0,
									0.05,
									0.248,
									0.551,
									0.419,
									0.242,
									0.399
								],
								"e": 0.137
							},
							{
								"h": [
									0.125,
									0.463,
									0.71,
									0.142,
									0.158,
									0.16,
									0.081,
									0.157,
									0.371,
									0.236,
									0.823,
									0.004
								],
								"e": 0.277
							},
							{
								"h": [
									0.036,
									0.133,
									0.039,
									0.017,
									0.075,
									0.225,
									0.106,
									0.359,
									0.204,
									0.056,
									0.212,
									0.057
								],
								"e": 0.18
							},
							{
								"h": [
									0.001,
									0.179,
									0.033,
									0.136,
									0.009,
									0.049,
									0.18,
									0.16,
									0.137,
									0.103,
									0.017,
									0.06
								],
								"e": 0.077
							},
							{
								"h": [
									0.024,
									0.168,
									0.029,
									0.06,
									0.006,
									0.046,
									0.123,
									0.007,
									0.008,
									0.101,
									0.01,
									0.0
								],
								"e": 0.048
							},
							{
								"h": [
									0.011,
									0.0,
									0.046,
									0.106,
									0.144,
									0.0,
									0.009,
									0.017,
									0.051,
									0.017,
									0.023,
									0.018
								],
								"e": 0.03
							},
							{
								"h": [
									0.08,
									0.028,
									0.015,
									0.025,
									0.043,
									0.03,
									0.022,
									0.051,
									0.123,
									0.0,
									0.002,
									0.034
								],
								"e": 0.031
							},
							{
								"h": [
									0.099,
									0.004,
									0.0,
									0.049,
									0.01,
									0.119,
									0.023,
									0.006,
									0.036,
									0.173,
									0.0,
									0.004
								],
								"e": 0.031
							},
							{
								"h": [
									0.043,
									0.005,
									0.121,
									0.018,
									0.005,
									0.097,
									0.021,
									0.105,
									0.026,
									0.027,
									0.044,
									0.026
								],
								"e": 0.04
							},
							{
								"h": [
									0.005,
									0.001,
									0.004,
									0.055,
									0.001,
									0.015,
									0.001,
									0.067,
									0.062,
									0.001,
									0.014,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.03,
									0.0,
									0.004,
									0.009,
									0.033,
									0.001,
									0.012,
									0.016,
									0.001,
									0.003,
									0.002,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.305,
									0.01,
									0.016,
									0.106,
									0.074,
									0.003,
									0.002,
									0.008,
									0.455,
									0.005,
									0.088,
									0.036
								],
								"e": 0.052
							}
						],
						[
							{
								"h": [
									0.314,
									0.001,
									0.283,
									0.0,
									0.017,
									0.0,
									0.054,
									0.285,
									0.733,
									0.486,
									0.381,
									0.32
								],
								"e": 0.144
							},
							{
								"h": [
									0.319,
									0.423,
									0.773,
									0.123,
									0.159,
									0.203,
									0.072,
									0.172,
									0.372,
									0.279,
									0.709,
									0.003
								],
								"e": 0.3
							},
							{
								"h": [
									0.039,
									0.071,
									0.025,
									0.062,
									0.148,
									0.104,
									0.078,
									0.318,
									0.041,
									0.053,
									0.161,
									0.046
								],
								"e": 0.137
							},
							{
								"h": [
									0.009,
									0.096,
									0.018,
									0.034,
									0.012,
									0.043,
									0.124,
									0.071,
									0.011,
									0.049,
									0.011,
									0.014
								],
								"e": 0.037
							},
							{
								"h": [
									0.011,
									0.119,
									0.065,
									0.018,
									0.016,
									0.011,
									0.103,
									0.009,
									0.005,
									0.095,
									0.012,
									0.0
								],
								"e": 0.04
							},
							{
								"h": [
									0.005,
									0.0,
									0.063,
									0.328,
									0.035,
									0.0,
									0.004,
									0.028,
									0.007,
									0.004,
									0.006,
									0.026
								],
								"e": 0.032
							},
							{
								"h": [
									0.254,
									0.002,
									0.025,
									0.015,
									0.095,
									0.023,
									0.009,
									0.027,
									0.239,
									0.0,
									0.001,
									0.08
								],
								"e": 0.045
							},
							{
								"h": [
									0.15,
									0.001,
									0.0,
									0.011,
									0.01,
									0.232,
									0.007,
									0.002,
									0.102,
									0.168,
									0.0,
									0.0
								],
								"e": 0.038
							},
							{
								"h": [
									0.148,
									0.001,
									0.031,
									0.04,
									0.022,
									0.395,
									0.011,
									0.112,
									0.02,
									0.022,
									0.016,
									0.006
								],
								"e": 0.056
							},
							{
								"h": [
									0.004,
									0.001,
									0.002,
									0.077,
									0.001,
									0.009,
									0.001,
									0.027,
									0.009,
									0.0,
									0.034,
									0.002
								],
								"e": 0.011
							},
							{
								"h": [
									0.009,
									0.0,
									0.001,
									0.022,
									0.014,
									0.002,
									0.02,
									0.004,
									0.001,
									0.012,
									0.005,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.278,
									0.011,
									0.015,
									0.187,
									0.019,
									0.001,
									0.003,
									0.004,
									0.58,
									0.002,
									0.139,
									0.135
								],
								"e": 0.06
							}
						],
						[
							{
								"h": [
									1.0,
									0.004,
									0.138,
									0.0,
									0.064,
									0.0,
									0.265,
									0.333,
									0.626,
									0.341,
									0.268,
									0.249
								],
								"e": 0.174
							},
							{
								"h": [
									0.289,
									0.185,
									0.325,
									0.117,
									0.23,
									0.078,
									0.135,
									0.395,
									0.107,
									0.06,
									0.997,
									0.005
								],
								"e": 0.243
							},
							{
								"h": [
									0.152,
									0.131,
									0.25,
									0.051,
									0.168,
									0.269,
									0.027,
									0.677,
									0.381,
									1.0,
									0.447,
									0.096
								],
								"e": 0.409
							},
							{
								"h": [
									0.0,
									0.062,
									0.196,
									0.636,
									0.004,
									0.082,
									0.321,
									1.0,
									0.309,
									0.111,
									0.05,
									0.688
								],
								"e": 0.221
							},
							{
								"h": [
									0.183,
									1.0,
									0.025,
									0.045,
									0.007,
									0.08,
									0.12,
									0.145,
									0.005,
									0.069,
									0.008,
									0.0
								],
								"e": 0.117
							},
							{
								"h": [
									0.001,
									0.0,
									0.051,
									0.117,
									0.31,
									0.001,
									0.148,
									0.837,
									0.004,
									0.007,
									0.029,
									0.025
								],
								"e": 0.086
							},
							{
								"h": [
									0.108,
									0.014,
									0.009,
									0.012,
									0.039,
									0.016,
									0.026,
									0.057,
									0.147,
									0.0,
									0.005,
									0.083
								],
								"e": 0.033
							},
							{
								"h": [
									0.158,
									0.0,
									0.0,
									0.076,
									0.002,
									0.03,
									0.005,
									0.006,
									0.036,
									0.101,
									0.0,
									0.004
								],
								"e": 0.024
							},
							{
								"h": [
									0.009,
									0.0,
									0.022,
									0.002,
									0.048,
									0.147,
									0.029,
									0.096,
									0.055,
									0.053,
									0.018,
									0.042
								],
								"e": 0.038
							},
							{
								"h": [
									0.003,
									0.001,
									0.006,
									0.076,
									0.0,
									0.03,
									0.0,
									0.01,
									0.032,
									0.0,
									0.011,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.034,
									0.0,
									0.0,
									0.002,
									0.006,
									0.001,
									0.002,
									0.006,
									0.0,
									0.001,
									0.0,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.374,
									0.001,
									0.004,
									0.07,
									0.004,
									0.001,
									0.002,
									0.001,
									0.232,
									0.0,
									0.071,
									0.021
								],
								"e": 0.033
							}
						],
						[
							{
								"h": [
									0.338,
									0.0,
									0.27,
									0.0,
									0.052,
									0.0,
									0.034,
									0.553,
									0.454,
									0.409,
									0.232,
									0.26
								],
								"e": 0.137
							},
							{
								"h": [
									0.507,
									0.512,
									0.959,
									0.126,
									0.157,
									0.161,
									0.068,
									0.193,
									0.261,
									0.098,
									0.689,
									0.024
								],
								"e": 0.303
							},
							{
								"h": [
									1.0,
									0.06,
									1.0,
									0.133,
									0.288,
									0.743,
									0.115,
									0.414,
									1.0,
									0.222,
									0.188,
									0.054
								],
								"e": 0.637
							},
							{
								"h": [
									0.0,
									0.054,
									0.822,
									0.314,
									0.011,
									0.037,
									0.434,
									0.548,
									1.0,
									0.073,
									0.011,
									0.183
								],
								"e": 0.222
							},
							{
								"h": [
									1.0,
									0.186,
									0.042,
									0.045,
									0.03,
									0.281,
									0.16,
									0.012,
									0.014,
									0.044,
									0.007,
									0.0
								],
								"e": 0.16
							},
							{
								"h": [
									0.0,
									0.0,
									0.029,
									0.045,
									0.402,
									0.0,
									0.031,
									0.086,
									0.001,
									0.003,
									0.026,
									0.059
								],
								"e": 0.04
							},
							{
								"h": [
									0.687,
									0.021,
									0.023,
									0.02,
									0.014,
									0.042,
									0.013,
									0.058,
									0.231,
									0.0,
									0.003,
									0.153
								],
								"e": 0.067
							},
							{
								"h": [
									1.0,
									0.0,
									0.0,
									0.033,
									0.004,
									0.088,
									0.015,
									0.009,
									0.177,
									0.205,
									0.0,
									0.003
								],
								"e": 0.072
							},
							{
								"h": [
									0.034,
									0.001,
									0.027,
									0.003,
									0.085,
									0.271,
									0.015,
									0.705,
									0.023,
									0.072,
									0.028,
									0.012
								],
								"e": 0.077
							},
							{
								"h": [
									0.005,
									0.001,
									0.001,
									1.0,
									0.005,
									0.04,
									0.001,
									0.012,
									0.051,
									0.0,
									0.021,
									0.002
								],
								"e": 0.052
							},
							{
								"h": [
									0.027,
									0.0,
									0.002,
									0.04,
									0.009,
									0.002,
									0.014,
									0.015,
									0.003,
									0.064,
									0.005,
									0.029
								],
								"e": 0.017
							},
							{
								"h": [
									0.34,
									0.035,
									0.032,
									1.0,
									0.061,
									0.002,
									0.002,
									0.007,
									0.96,
									0.001,
									0.324,
									0.248
								],
								"e": 0.14
							}
						],
						[
							{
								"h": [
									0.329,
									0.001,
									0.257,
									0.0,
									0.046,
									0.0,
									0.052,
									1.0,
									0.812,
									0.487,
									0.31,
									0.329
								],
								"e": 0.186
							},
							{
								"h": [
									1.0,
									1.0,
									0.952,
									0.142,
									0.199,
									0.178,
									0.064,
									0.192,
									0.341,
									0.239,
									0.86,
									0.002
								],
								"e": 0.43
							},
							{
								"h": [
									0.143,
									0.068,
									0.718,
									1.0,
									1.0,
									1.0,
									0.116,
									0.58,
									0.374,
									0.455,
									0.306,
									0.091
								],
								"e": 0.764
							},
							{
								"h": [
									0.005,
									0.138,
									1.0,
									0.119,
									0.017,
									0.031,
									0.42,
									0.733,
									0.157,
									0.12,
									0.024,
									0.063
								],
								"e": 0.17
							},
							{
								"h": [
									0.139,
									0.106,
									0.019,
									0.119,
									0.02,
									0.256,
									0.155,
									0.075,
									0.014,
									0.086,
									0.019,
									1.0
								],
								"e": 0.229
							},
							{
								"h": [
									0.001,
									0.0,
									0.026,
									0.044,
									0.152,
									0.0,
									0.064,
									0.083,
									0.001,
									0.005,
									0.004,
									0.105
								],
								"e": 0.031
							},
							{
								"h": [
									1.0,
									0.004,
									0.018,
									0.015,
									0.021,
									0.081,
									0.017,
									0.073,
									0.295,
									0.0,
									0.006,
									0.141
								],
								"e": 0.085
							},
							{
								"h": [
									0.821,
									0.002,
									0.0,
									0.009,
									0.006,
									0.297,
									0.011,
									0.009,
									0.218,
									0.116,
									0.0,
									0.002
								],
								"e": 0.073
							},
							{
								"h": [
									0.087,
									0.0,
									0.005,
									0.006,
									0.068,
									0.184,
									0.004,
									1.0,
									0.025,
									0.064,
									0.04,
									0.015
								],
								"e": 0.089
							},
							{
								"h": [
									0.009,
									0.002,
									0.001,
									0.716,
									0.002,
									0.044,
									0.003,
									0.021,
									0.015,
									0.002,
									0.026,
									0.005
								],
								"e": 0.04
							},
							{
								"h": [
									0.013,
									0.003,
									0.003,
									0.043,
									0.005,
									0.002,
									0.053,
									0.019,
									0.008,
									0.061,
									0.009,
									0.032
								],
								"e": 0.02
							},
							{
								"h": [
									0.215,
									0.088,
									0.015,
									0.712,
									0.037,
									0.004,
									0.003,
									0.018,
									1.0,
									0.002,
									0.341,
									0.22
								],
								"e": 0.126
							}
						],
						[
							{
								"h": [
									0.738,
									1.0,
									0.37,
									1.0,
									1.0,
									1.0,
									0.398,
									0.566,
									0.733,
									0.172,
									1.0,
									0.385
								],
								"e": 1.0
							},
							{
								"h": [
									0.242,
									0.257,
									0.642,
									0.136,
									0.229,
									0.219,
									0.084,
									0.139,
									0.118,
									0.103,
									1.0,
									1.0
								],
								"e": 0.489
							},
							{
								"h": [
									0.217,
									0.022,
									0.126,
									0.071,
									0.062,
									0.18,
									0.023,
									1.0,
									0.063,
									0.408,
									0.444,
									0.102
								],
								"e": 0.281
							},
							{
								"h": [
									0.032,
									0.01,
									0.114,
									0.015,
									0.001,
									0.011,
									1.0,
									0.085,
									0.086,
									0.057,
									0.101,
									0.033
								],
								"e": 0.09
							},
							{
								"h": [
									0.058,
									0.088,
									0.005,
									0.057,
									0.027,
									0.052,
									0.032,
									0.415,
									0.004,
									0.017,
									0.014,
									0.0
								],
								"e": 0.065
							},
							{
								"h": [
									0.002,
									0.0,
									0.004,
									0.026,
									0.02,
									0.0,
									0.009,
									0.067,
									0.004,
									0.002,
									0.035,
									0.101
								],
								"e": 0.019
							},
							{
								"h": [
									0.016,
									0.005,
									0.007,
									0.002,
									0.014,
									0.01,
									0.065,
									0.014,
									0.02,
									0.0,
									0.028,
									0.008
								],
								"e": 0.016
							},
							{
								"h": [
									0.027,
									0.013,
									0.003,
									0.031,
									0.001,
									0.009,
									0.022,
									0.009,
									0.02,
									0.035,
									0.001,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.025,
									0.0,
									0.028,
									0.004,
									0.003,
									0.038,
									0.01,
									0.042,
									0.026,
									0.06,
									0.106,
									0.02
								],
								"e": 0.029
							},
							{
								"h": [
									0.011,
									0.003,
									0.019,
									0.023,
									0.01,
									0.049,
									0.001,
									0.009,
									0.052,
									0.01,
									0.004,
									0.007
								],
								"e": 0.015
							},
							{
								"h": [
									0.017,
									0.005,
									0.002,
									0.003,
									0.003,
									0.001,
									0.006,
									0.025,
									0.006,
									0.02,
									0.002,
									0.018
								],
								"e": 0.009
							},
							{
								"h": [
									0.634,
									0.003,
									0.002,
									0.009,
									0.017,
									0.002,
									0.002,
									0.005,
									0.377,
									0.0,
									0.206,
									0.047
								],
								"e": 0.057
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " States",
					"o": ".",
					"t": ".",
					"r": [
						{
							"p": " States",
							"b": 0.0,
							"k": {
								" States": 0.935,
								" Post": 0.01,
								" State": 0.006,
								" Army": 0.003,
								" Wire": 0.002,
								" post": 0.001,
								" Res": 0.001,
								" Bar": 0.001,
								" Council": 0.001,
								" Press": 0.001
							}
						},
						{
							"p": " States",
							"b": 0.002,
							"k": {
								" States": 0.224,
								" State": 0.202,
								" Army": 0.13,
								" Post": 0.06,
								" National": 0.023,
								" Forces": 0.021,
								" Government": 0.018,
								" Intelligence": 0.016,
								" Department": 0.015,
								" Navy": 0.014
							}
						},
						{
							"p": " Army",
							"b": 0.001,
							"k": {
								" Army": 0.211,
								" State": 0.131,
								" Dollar": 0.115,
								" Embassy": 0.097,
								" Post": 0.052,
								" Treasury": 0.033,
								" Independence": 0.033,
								" States": 0.032,
								" Navy": 0.028,
								"'s": 0.014
							}
						},
						{
							"p": " Dollar",
							"b": 0.006,
							"k": {
								" Dollar": 0.145,
								" Army": 0.102,
								" State": 0.101,
								" Independence": 0.097,
								"'s": 0.068,
								" National": 0.053,
								" Marshal": 0.029,
								" Navy": 0.027,
								" Marsh": 0.027,
								" States": 0.026
							}
						},
						{
							"p": " State",
							"b": 0.015,
							"k": {
								" State": 0.324,
								" Dollar": 0.076,
								"'s": 0.073,
								" Army": 0.071,
								" Embassy": 0.059,
								" Independence": 0.05,
								" National": 0.035,
								" Department": 0.026,
								" Treasury": 0.024,
								",": 0.016
							}
						},
						{
							"p": " State",
							"b": 0.044,
							"k": {
								" State": 0.506,
								"'s": 0.062,
								" Embassy": 0.056,
								",": 0.045,
								".": 0.044,
								" National": 0.034,
								" Government": 0.025,
								" States": 0.02,
								" Department": 0.018,
								" Postal": 0.018
							}
						},
						{
							"p": " State",
							"b": 0.07,
							"k": {
								" State": 0.413,
								".": 0.07,
								" Government": 0.069,
								",": 0.053,
								" Embassy": 0.05,
								"'s": 0.043,
								" National": 0.038,
								" Geological": 0.028,
								" Independence": 0.026,
								" Army": 0.024
							}
						},
						{
							"p": ".",
							"b": 0.195,
							"k": {
								".": 0.195,
								",": 0.178,
								" State": 0.152,
								" and": 0.133,
								" Geological": 0.05,
								";": 0.038,
								"'s": 0.038,
								" Independence": 0.024,
								" Army": 0.021,
								" Government": 0.018
							}
						},
						{
							"p": ",",
							"b": 0.279,
							"k": {
								",": 0.335,
								".": 0.279,
								" and": 0.167,
								";": 0.061,
								" State": 0.049,
								" of": 0.01,
								" Independence": 0.009,
								"'s": 0.008,
								" Government": 0.007,
								" District": 0.007
							}
						},
						{
							"p": ".",
							"b": 0.502,
							"k": {
								".": 0.502,
								",": 0.219,
								" and": 0.187,
								";": 0.037,
								"'": 0.022,
								" of": 0.014,
								" State": 0.003,
								" in": 0.001,
								" (": 0.001,
								" National": 0.001
							}
						},
						{
							"p": ".",
							"b": 0.356,
							"k": {
								".": 0.356,
								" and": 0.301,
								",": 0.182,
								" of": 0.107,
								";": 0.021,
								"'": 0.006,
								".\"": 0.003,
								",\"": 0.002,
								"!": 0.001,
								" in": 0.001
							}
						},
						{
							"p": ".",
							"b": 0.282,
							"k": {
								".": 0.282,
								",": 0.223,
								" of": 0.161,
								" and": 0.156,
								";": 0.016,
								" (": 0.01,
								" in": 0.009,
								".\"": 0.007,
								"'": 0.006,
								" for": 0.005
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.006,
									1.0,
									0.0,
									0.049,
									0.124,
									1.0,
									0.021,
									1.0,
									1.0,
									0.856,
									1.0
								],
								"e": 0.64
							},
							{
								"h": [
									0.3,
									0.889,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.009,
									0.106
								],
								"e": 1.0
							},
							{
								"h": [
									0.614,
									1.0,
									0.244,
									0.168,
									0.661,
									0.923,
									1.0,
									0.795,
									0.338,
									0.781,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.054,
									1.0,
									1.0,
									1.0,
									1.0,
									0.892,
									0.335,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.03,
									0.357,
									1.0,
									1.0,
									1.0,
									0.906,
									1.0,
									0.454,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									0.5,
									1.0,
									1.0,
									0.337,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.898,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.153,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.472,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.363,
									0.003,
									0.31,
									0.0,
									0.004,
									0.0,
									0.105,
									0.006,
									0.469,
									0.619,
									0.49,
									0.852
								],
								"e": 0.25
							},
							{
								"h": [
									0.104,
									0.401,
									0.275,
									0.073,
									0.153,
									0.264,
									0.117,
									0.134,
									0.284,
									0.107,
									0.42,
									0.016
								],
								"e": 0.201
							},
							{
								"h": [
									0.133,
									0.056,
									0.01,
									0.011,
									0.121,
									0.294,
									0.098,
									0.052,
									0.071,
									0.034,
									0.118,
									0.022
								],
								"e": 0.098
							},
							{
								"h": [
									0.019,
									0.073,
									0.033,
									0.025,
									0.025,
									0.072,
									0.067,
									0.084,
									0.024,
									0.087,
									0.006,
									0.053
								],
								"e": 0.046
							},
							{
								"h": [
									0.002,
									0.096,
									0.104,
									0.054,
									0.028,
									0.071,
									0.047,
									0.004,
									0.011,
									0.074,
									0.055,
									0.0
								],
								"e": 0.055
							},
							{
								"h": [
									0.006,
									0.0,
									0.086,
									0.208,
									0.011,
									0.004,
									0.001,
									0.02,
									0.023,
									0.026,
									0.088,
									0.068
								],
								"e": 0.036
							},
							{
								"h": [
									0.031,
									0.007,
									0.022,
									0.026,
									0.03,
									0.017,
									0.02,
									0.072,
									0.034,
									0.0,
									0.003,
									0.02
								],
								"e": 0.02
							},
							{
								"h": [
									0.005,
									0.01,
									0.0,
									0.026,
									0.219,
									0.044,
									0.016,
									0.001,
									0.063,
									0.03,
									0.0,
									0.01
								],
								"e": 0.024
							},
							{
								"h": [
									0.006,
									0.003,
									0.032,
									0.032,
									0.013,
									0.026,
									0.007,
									0.001,
									0.06,
									0.022,
									0.034,
									0.07
								],
								"e": 0.027
							},
							{
								"h": [
									0.01,
									0.003,
									0.022,
									0.045,
									0.004,
									0.042,
									0.003,
									0.032,
									0.026,
									0.001,
									0.017,
									0.005
								],
								"e": 0.015
							},
							{
								"h": [
									0.015,
									0.006,
									0.163,
									0.053,
									0.024,
									0.032,
									0.031,
									0.021,
									0.002,
									0.011,
									0.008,
									0.01
								],
								"e": 0.031
							},
							{
								"h": [
									0.458,
									0.055,
									0.048,
									0.187,
									0.091,
									0.025,
									0.008,
									0.066,
									0.243,
									0.01,
									0.102,
									0.246
								],
								"e": 0.09
							}
						],
						[
							{
								"h": [
									0.479,
									0.033,
									0.112,
									0.0,
									0.017,
									0.001,
									0.296,
									0.004,
									0.446,
									0.655,
									0.286,
									0.494
								],
								"e": 0.242
							},
							{
								"h": [
									0.092,
									0.124,
									0.343,
									0.084,
									0.187,
									0.167,
									0.377,
									0.261,
									0.161,
									0.114,
									0.562,
									0.008
								],
								"e": 0.226
							},
							{
								"h": [
									0.141,
									0.098,
									0.008,
									0.009,
									0.018,
									0.034,
									0.016,
									0.283,
									0.013,
									0.131,
									0.233,
									0.031
								],
								"e": 0.093
							},
							{
								"h": [
									0.001,
									0.024,
									0.003,
									0.003,
									0.004,
									0.063,
									0.04,
									0.053,
									0.013,
									0.034,
									0.015,
									0.018
								],
								"e": 0.021
							},
							{
								"h": [
									0.0,
									0.011,
									0.031,
									0.008,
									0.006,
									0.006,
									0.042,
									0.013,
									0.002,
									0.051,
									0.03,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.0,
									0.0,
									0.021,
									0.044,
									0.004,
									0.008,
									0.001,
									0.006,
									0.006,
									0.008,
									0.011,
									0.015
								],
								"e": 0.009
							},
							{
								"h": [
									0.007,
									0.0,
									0.037,
									0.006,
									0.006,
									0.017,
									0.012,
									0.085,
									0.014,
									0.0,
									0.004,
									0.008
								],
								"e": 0.015
							},
							{
								"h": [
									0.003,
									0.029,
									0.0,
									0.012,
									0.014,
									0.014,
									0.032,
									0.001,
									0.037,
									0.016,
									0.0,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.003,
									0.0,
									0.137,
									0.01,
									0.002,
									0.009,
									0.001,
									0.001,
									0.017,
									0.017,
									0.032,
									0.047
								],
								"e": 0.024
							},
							{
								"h": [
									0.004,
									0.0,
									0.015,
									0.029,
									0.004,
									0.028,
									0.002,
									0.036,
									0.014,
									0.002,
									0.005,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.019,
									0.002,
									0.023,
									0.003,
									0.004,
									0.004,
									0.011,
									0.026,
									0.0,
									0.005,
									0.015,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.278,
									0.018,
									0.024,
									0.034,
									0.034,
									0.006,
									0.01,
									0.012,
									0.074,
									0.002,
									0.037,
									0.074
								],
								"e": 0.032
							}
						],
						[
							{
								"h": [
									0.13,
									0.006,
									0.399,
									0.0,
									0.003,
									0.0,
									0.056,
									0.008,
									0.268,
									0.443,
									0.318,
									0.462
								],
								"e": 0.162
							},
							{
								"h": [
									0.134,
									0.42,
									0.674,
									0.111,
									0.187,
									0.129,
									0.153,
									0.132,
									0.406,
									0.182,
									0.612,
									0.006
								],
								"e": 0.253
							},
							{
								"h": [
									0.101,
									0.14,
									0.016,
									0.043,
									0.101,
									0.357,
									0.183,
									0.336,
									0.525,
									0.144,
									0.245,
									0.046
								],
								"e": 0.217
							},
							{
								"h": [
									0.003,
									0.24,
									0.033,
									0.051,
									0.023,
									0.204,
									0.231,
									0.241,
									0.254,
									0.431,
									0.026,
									0.09
								],
								"e": 0.142
							},
							{
								"h": [
									0.018,
									0.435,
									0.062,
									0.1,
									0.006,
									0.034,
									0.084,
									0.016,
									0.015,
									0.262,
									0.014,
									0.0
								],
								"e": 0.091
							},
							{
								"h": [
									0.012,
									0.0,
									0.09,
									0.238,
									0.12,
									0.003,
									0.003,
									0.009,
									0.097,
									0.041,
									0.017,
									0.033
								],
								"e": 0.044
							},
							{
								"h": [
									0.135,
									0.019,
									0.035,
									0.033,
									0.013,
									0.044,
									0.046,
									0.059,
									0.056,
									0.0,
									0.004,
									0.081
								],
								"e": 0.034
							},
							{
								"h": [
									0.126,
									0.024,
									0.0,
									0.077,
									0.554,
									0.215,
									0.015,
									0.004,
									0.115,
									0.252,
									0.003,
									0.048
								],
								"e": 0.083
							},
							{
								"h": [
									0.011,
									0.051,
									0.089,
									0.047,
									0.04,
									0.25,
									0.019,
									0.061,
									0.088,
									0.044,
									0.051,
									0.035
								],
								"e": 0.06
							},
							{
								"h": [
									0.012,
									0.013,
									0.026,
									0.384,
									0.002,
									0.02,
									0.004,
									0.051,
									0.005,
									0.001,
									0.313,
									0.013
								],
								"e": 0.032
							},
							{
								"h": [
									0.01,
									0.003,
									0.05,
									0.065,
									0.107,
									0.266,
									0.021,
									0.023,
									0.004,
									0.044,
									0.004,
									0.016
								],
								"e": 0.041
							},
							{
								"h": [
									1.0,
									0.031,
									0.027,
									0.144,
									0.107,
									0.015,
									0.014,
									0.06,
									0.493,
									0.018,
									0.097,
									0.207
								],
								"e": 0.107
							}
						],
						[
							{
								"h": [
									0.249,
									0.002,
									0.247,
									0.0,
									0.005,
									0.0,
									0.054,
									0.013,
									0.324,
									0.475,
									0.364,
									0.438
								],
								"e": 0.171
							},
							{
								"h": [
									0.196,
									0.501,
									0.69,
									0.1,
									0.169,
									0.236,
									0.085,
									0.135,
									0.355,
									0.249,
									0.521,
									0.003
								],
								"e": 0.261
							},
							{
								"h": [
									0.061,
									0.026,
									0.016,
									0.166,
									0.262,
									0.152,
									0.064,
									0.284,
									0.071,
									0.115,
									0.165,
									0.04
								],
								"e": 0.139
							},
							{
								"h": [
									0.02,
									0.179,
									0.053,
									0.04,
									0.031,
									0.178,
									0.169,
									0.06,
									0.022,
									0.266,
									0.012,
									0.039
								],
								"e": 0.088
							},
							{
								"h": [
									0.019,
									0.204,
									0.207,
									0.053,
									0.015,
									0.033,
									0.121,
									0.011,
									0.015,
									0.155,
									0.028,
									0.0
								],
								"e": 0.084
							},
							{
								"h": [
									0.025,
									0.0,
									0.284,
									0.491,
									0.044,
									0.016,
									0.002,
									0.01,
									0.07,
									0.004,
									0.012,
									0.026
								],
								"e": 0.058
							},
							{
								"h": [
									0.222,
									0.005,
									0.042,
									0.022,
									0.048,
									0.027,
									0.012,
									0.02,
									0.1,
									0.002,
									0.002,
									0.062
								],
								"e": 0.031
							},
							{
								"h": [
									0.043,
									0.002,
									0.0,
									0.016,
									0.183,
									0.161,
									0.005,
									0.001,
									0.067,
									0.237,
									0.003,
									0.001
								],
								"e": 0.041
							},
							{
								"h": [
									0.014,
									0.016,
									0.078,
									0.072,
									0.13,
									0.284,
									0.008,
									0.025,
									0.038,
									0.027,
									0.029,
									0.017
								],
								"e": 0.052
							},
							{
								"h": [
									0.003,
									0.011,
									0.011,
									0.113,
									0.002,
									0.007,
									0.001,
									0.022,
									0.003,
									0.001,
									0.431,
									0.005
								],
								"e": 0.02
							},
							{
								"h": [
									0.003,
									0.003,
									0.006,
									0.02,
									0.032,
									0.134,
									0.018,
									0.005,
									0.004,
									0.089,
									0.004,
									0.013
								],
								"e": 0.02
							},
							{
								"h": [
									0.3,
									0.018,
									0.012,
									0.089,
									0.048,
									0.004,
									0.007,
									0.008,
									0.071,
									0.004,
									0.058,
									0.168
								],
								"e": 0.042
							}
						],
						[
							{
								"h": [
									0.224,
									0.019,
									0.362,
									0.0,
									0.063,
									0.002,
									0.422,
									0.015,
									0.159,
									0.513,
									0.226,
									0.331
								],
								"e": 0.212
							},
							{
								"h": [
									0.203,
									0.153,
									0.315,
									0.092,
									0.247,
									0.138,
									0.118,
									0.258,
									0.111,
									0.073,
									0.685,
									0.003
								],
								"e": 0.2
							},
							{
								"h": [
									0.182,
									0.15,
									0.639,
									0.042,
									0.037,
									0.31,
									0.022,
									0.552,
									0.411,
									1.0,
									0.401,
									0.064
								],
								"e": 0.341
							},
							{
								"h": [
									0.0,
									0.035,
									0.037,
									0.237,
									0.013,
									0.141,
									0.247,
									1.0,
									0.175,
									0.244,
									0.051,
									0.935
								],
								"e": 0.206
							},
							{
								"h": [
									0.106,
									1.0,
									0.046,
									0.136,
									0.004,
									0.178,
									0.213,
									0.095,
									0.005,
									0.287,
									0.014,
									0.0
								],
								"e": 0.177
							},
							{
								"h": [
									0.0,
									0.0,
									0.131,
									0.216,
									0.301,
									0.004,
									0.011,
									1.0,
									0.014,
									0.011,
									0.056,
									0.029
								],
								"e": 0.118
							},
							{
								"h": [
									0.376,
									0.006,
									0.017,
									0.025,
									0.008,
									0.025,
									0.018,
									0.097,
									0.492,
									0.0,
									0.008,
									0.183
								],
								"e": 0.055
							},
							{
								"h": [
									0.07,
									0.001,
									0.0,
									0.054,
									0.127,
									0.053,
									0.002,
									0.004,
									0.418,
									0.107,
									0.002,
									0.001
								],
								"e": 0.046
							},
							{
								"h": [
									0.003,
									0.001,
									0.014,
									0.007,
									0.358,
									0.142,
									0.007,
									0.061,
									0.053,
									0.036,
									0.03,
									0.007
								],
								"e": 0.049
							},
							{
								"h": [
									0.011,
									0.001,
									0.033,
									0.219,
									0.002,
									0.035,
									0.008,
									0.008,
									0.002,
									0.004,
									0.781,
									0.0
								],
								"e": 0.036
							},
							{
								"h": [
									0.022,
									0.006,
									0.008,
									0.014,
									0.007,
									0.157,
									0.018,
									0.01,
									0.001,
									0.49,
									0.003,
									0.001
								],
								"e": 0.037
							},
							{
								"h": [
									0.315,
									0.004,
									0.005,
									0.084,
									0.006,
									0.003,
									0.006,
									0.005,
									1.0,
									0.003,
									0.125,
									0.088
								],
								"e": 0.076
							}
						],
						[
							{
								"h": [
									0.126,
									0.003,
									0.306,
									0.0,
									0.007,
									0.0,
									0.03,
									0.02,
									0.494,
									0.431,
									0.283,
									0.297
								],
								"e": 0.158
							},
							{
								"h": [
									0.568,
									0.576,
									0.847,
									0.098,
									0.163,
									0.129,
									0.08,
									0.148,
									0.191,
									0.102,
									0.485,
									0.015
								],
								"e": 0.259
							},
							{
								"h": [
									1.0,
									0.07,
									1.0,
									0.189,
									0.349,
									1.0,
									0.101,
									0.325,
									1.0,
									0.323,
									0.189,
									0.039
								],
								"e": 0.531
							},
							{
								"h": [
									0.001,
									0.036,
									0.762,
									0.412,
									0.023,
									0.21,
									0.34,
									0.485,
									1.0,
									0.15,
									0.009,
									0.395
								],
								"e": 0.303
							},
							{
								"h": [
									1.0,
									0.253,
									0.065,
									0.078,
									0.025,
									1.0,
									0.259,
									0.03,
									0.017,
									0.159,
									0.01,
									0.0
								],
								"e": 0.292
							},
							{
								"h": [
									0.001,
									0.0,
									0.262,
									0.069,
									1.0,
									0.0,
									0.019,
									0.155,
									0.005,
									0.006,
									0.078,
									0.045
								],
								"e": 0.093
							},
							{
								"h": [
									1.0,
									0.004,
									0.028,
									0.028,
									0.005,
									0.057,
									0.007,
									0.139,
									0.644,
									0.0,
									0.008,
									0.303
								],
								"e": 0.093
							},
							{
								"h": [
									0.406,
									0.002,
									0.0,
									0.057,
									0.224,
									0.075,
									0.008,
									0.006,
									0.19,
									0.094,
									0.001,
									0.006
								],
								"e": 0.061
							},
							{
								"h": [
									0.008,
									0.003,
									0.022,
									0.009,
									0.154,
									0.303,
									0.013,
									1.0,
									0.02,
									0.029,
									0.038,
									0.004
								],
								"e": 0.1
							},
							{
								"h": [
									0.003,
									0.005,
									0.009,
									0.815,
									0.003,
									0.034,
									0.002,
									0.019,
									0.01,
									0.0,
									0.87,
									0.004
								],
								"e": 0.054
							},
							{
								"h": [
									0.004,
									0.005,
									0.011,
									0.019,
									0.071,
									0.193,
									0.03,
									0.024,
									0.006,
									0.3,
									0.003,
									0.151
								],
								"e": 0.049
							},
							{
								"h": [
									0.609,
									0.034,
									0.015,
									0.292,
									0.054,
									0.019,
									0.012,
									0.036,
									0.253,
									0.014,
									0.184,
									0.166
								],
								"e": 0.089
							}
						],
						[
							{
								"h": [
									0.259,
									0.002,
									0.213,
									0.0,
									0.012,
									0.0,
									0.05,
									0.038,
									0.342,
									0.471,
									0.292,
									0.468
								],
								"e": 0.175
							},
							{
								"h": [
									0.568,
									1.0,
									0.843,
									0.114,
									0.203,
									0.192,
									0.082,
									0.147,
									0.313,
									0.235,
									0.621,
									0.002
								],
								"e": 0.332
							},
							{
								"h": [
									0.169,
									0.028,
									0.305,
									1.0,
									1.0,
									0.99,
									0.076,
									0.44,
									0.304,
									0.553,
									0.274,
									0.066
								],
								"e": 0.521
							},
							{
								"h": [
									0.013,
									0.162,
									1.0,
									0.159,
									0.036,
									0.115,
									0.318,
									0.339,
									0.065,
									0.22,
									0.021,
									0.157
								],
								"e": 0.205
							},
							{
								"h": [
									0.045,
									0.072,
									0.033,
									0.099,
									0.02,
									0.849,
									0.199,
									0.032,
									0.019,
									0.175,
									0.034,
									0.0
								],
								"e": 0.122
							},
							{
								"h": [
									0.003,
									0.0,
									0.423,
									0.046,
									0.297,
									0.0,
									0.015,
									0.097,
									0.004,
									0.004,
									0.011,
									0.054
								],
								"e": 0.053
							},
							{
								"h": [
									0.672,
									0.01,
									0.018,
									0.034,
									0.008,
									0.06,
									0.005,
									0.07,
									0.649,
									0.0,
									0.006,
									0.203
								],
								"e": 0.072
							},
							{
								"h": [
									0.25,
									0.004,
									0.0,
									0.026,
									0.147,
									0.09,
									0.006,
									0.004,
									0.277,
									0.067,
									0.0,
									0.002
								],
								"e": 0.049
							},
							{
								"h": [
									0.006,
									0.001,
									0.014,
									0.009,
									0.057,
									0.086,
									0.003,
									0.599,
									0.017,
									0.027,
									0.023,
									0.009
								],
								"e": 0.055
							},
							{
								"h": [
									0.002,
									0.005,
									0.005,
									0.836,
									0.003,
									0.021,
									0.001,
									0.022,
									0.002,
									0.0,
									0.283,
									0.008
								],
								"e": 0.037
							},
							{
								"h": [
									0.001,
									0.003,
									0.004,
									0.017,
									0.038,
									0.127,
									0.019,
									0.008,
									0.004,
									0.272,
									0.004,
									0.262
								],
								"e": 0.045
							},
							{
								"h": [
									0.29,
									0.033,
									0.006,
									0.202,
									0.048,
									0.007,
									0.002,
									0.007,
									0.158,
									0.005,
									0.139,
									0.082
								],
								"e": 0.054
							}
						],
						[
							{
								"h": [
									0.085,
									0.074,
									0.694,
									1.0,
									0.782,
									0.004,
									0.296,
									1.0,
									0.385,
									0.426,
									0.499,
									0.511
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.302,
									0.606,
									0.105,
									0.249,
									0.227,
									0.066,
									0.107,
									0.115,
									0.193,
									0.718,
									0.026
								],
								"e": 0.299
							},
							{
								"h": [
									0.352,
									0.04,
									0.264,
									0.069,
									0.574,
									0.151,
									0.021,
									0.767,
									0.142,
									0.446,
									0.355,
									0.061
								],
								"e": 0.283
							},
							{
								"h": [
									0.011,
									0.005,
									0.098,
									0.126,
									0.011,
									0.052,
									0.956,
									0.213,
									0.043,
									0.062,
									0.051,
									0.115
								],
								"e": 0.11
							},
							{
								"h": [
									0.005,
									0.076,
									0.009,
									0.141,
									0.126,
									0.19,
									0.043,
									0.066,
									0.008,
									0.08,
									0.023,
									1.0
								],
								"e": 0.263
							},
							{
								"h": [
									0.004,
									0.0,
									0.291,
									0.075,
									0.045,
									0.0,
									0.032,
									0.035,
									0.007,
									0.002,
									0.207,
									0.043
								],
								"e": 0.047
							},
							{
								"h": [
									0.049,
									0.005,
									0.011,
									0.009,
									0.01,
									0.017,
									0.004,
									0.052,
									0.681,
									0.0,
									0.011,
									0.017
								],
								"e": 0.033
							},
							{
								"h": [
									0.027,
									0.007,
									0.0,
									0.021,
									0.055,
									0.019,
									0.005,
									0.007,
									0.051,
									0.027,
									0.0,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.002,
									0.0,
									0.02,
									0.003,
									0.016,
									0.066,
									0.001,
									0.018,
									0.018,
									0.017,
									0.015,
									0.004
								],
								"e": 0.013
							},
							{
								"h": [
									0.004,
									0.002,
									0.034,
									0.1,
									0.004,
									0.021,
									0.001,
									0.004,
									0.007,
									0.001,
									0.056,
									0.001
								],
								"e": 0.011
							},
							{
								"h": [
									0.004,
									0.002,
									0.005,
									0.002,
									0.002,
									0.05,
									0.006,
									0.007,
									0.0,
									0.135,
									0.004,
									0.013
								],
								"e": 0.012
							},
							{
								"h": [
									0.218,
									0.004,
									0.001,
									0.03,
									0.012,
									0.002,
									0.002,
									0.004,
									0.074,
									0.001,
									0.06,
									0.034
								],
								"e": 0.02
							}
						],
						[
							{
								"h": [
									0.192,
									1.0,
									0.312,
									0.026,
									1.0,
									1.0,
									0.301,
									0.012,
									0.181,
									0.141,
									1.0,
									0.183
								],
								"e": 0.954
							},
							{
								"h": [
									0.414,
									0.314,
									0.827,
									0.135,
									0.292,
									0.436,
									0.102,
									0.21,
									0.164,
									0.091,
									1.0,
									1.0
								],
								"e": 0.562
							},
							{
								"h": [
									0.139,
									0.05,
									0.049,
									0.023,
									0.035,
									0.718,
									0.04,
									1.0,
									0.132,
									0.44,
									0.675,
									0.135
								],
								"e": 0.27
							},
							{
								"h": [
									0.102,
									0.01,
									0.029,
									0.036,
									0.009,
									0.037,
									0.31,
									0.368,
									0.047,
									0.146,
									0.22,
									0.034
								],
								"e": 0.108
							},
							{
								"h": [
									0.002,
									0.073,
									0.012,
									0.089,
									0.095,
									0.15,
									0.047,
									1.0,
									0.008,
									0.032,
									0.039,
									0.0
								],
								"e": 0.163
							},
							{
								"h": [
									0.037,
									0.002,
									0.036,
									0.035,
									0.079,
									0.002,
									0.008,
									0.026,
									0.005,
									0.006,
									0.143,
									0.138
								],
								"e": 0.039
							},
							{
								"h": [
									0.108,
									0.004,
									0.017,
									0.009,
									0.023,
									0.104,
									0.077,
									0.105,
									0.201,
									0.002,
									0.042,
									0.076
								],
								"e": 0.048
							},
							{
								"h": [
									0.029,
									0.018,
									0.001,
									0.069,
									0.039,
									0.028,
									0.015,
									0.033,
									0.017,
									0.045,
									0.001,
									0.006
								],
								"e": 0.022
							},
							{
								"h": [
									0.004,
									0.004,
									0.053,
									0.01,
									0.015,
									0.072,
									0.003,
									0.016,
									0.037,
									0.068,
									0.063,
									0.018
								],
								"e": 0.029
							},
							{
								"h": [
									0.008,
									0.004,
									0.049,
									0.114,
									0.003,
									0.046,
									0.003,
									0.019,
									0.012,
									0.002,
									0.153,
									0.007
								],
								"e": 0.021
							},
							{
								"h": [
									0.01,
									0.008,
									0.016,
									0.014,
									0.016,
									0.072,
									0.024,
									0.019,
									0.011,
									0.169,
									0.006,
									0.008
								],
								"e": 0.023
							},
							{
								"h": [
									0.758,
									0.012,
									0.006,
									0.067,
									0.018,
									0.007,
									0.003,
									0.015,
									0.602,
									0.003,
									0.066,
									0.076
								],
								"e": 0.068
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ".",
					"o": " It",
					"t": " London",
					"r": [
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.166,
								" The": 0.099,
								" It": 0.083,
								" And": 0.048,
								" If": 0.042,
								" In": 0.04,
								" They": 0.037,
								" But": 0.034,
								" As": 0.026,
								" That": 0.024
							}
						},
						{
							"p": " It",
							"b": 0.0,
							"k": {
								" It": 0.124,
								" ": 0.116,
								" The": 0.115,
								" And": 0.062,
								" But": 0.048,
								" They": 0.047,
								" If": 0.042,
								" In": 0.03,
								" As": 0.023,
								" There": 0.022
							}
						},
						{
							"p": " It",
							"b": 0.0,
							"k": {
								" It": 0.118,
								" ": 0.11,
								" The": 0.092,
								" And": 0.072,
								" But": 0.067,
								" If": 0.058,
								" They": 0.041,
								" We": 0.033,
								" There": 0.027,
								" However": 0.027
							}
						},
						{
							"p": " It",
							"b": 0.0,
							"k": {
								" It": 0.17,
								" But": 0.077,
								" And": 0.072,
								" ": 0.063,
								" The": 0.063,
								" If": 0.056,
								" Its": 0.046,
								" There": 0.043,
								" They": 0.038,
								" However": 0.036
							}
						},
						{
							"p": " It",
							"b": 0.0,
							"k": {
								" It": 0.167,
								" Its": 0.146,
								" The": 0.124,
								" And": 0.075,
								" ": 0.059,
								" But": 0.058,
								" There": 0.045,
								" If": 0.026,
								" This": 0.024,
								" However": 0.019
							}
						},
						{
							"p": " Its",
							"b": 0.0,
							"k": {
								" Its": 0.552,
								" It": 0.131,
								" And": 0.055,
								" But": 0.04,
								" The": 0.036,
								" ": 0.026,
								" However": 0.014,
								" This": 0.011,
								" We": 0.011,
								" If": 0.01
							}
						},
						{
							"p": " Its",
							"b": 0.0,
							"k": {
								" Its": 0.844,
								" It": 0.061,
								" And": 0.015,
								" The": 0.013,
								" ": 0.008,
								" This": 0.005,
								" But": 0.005,
								" However": 0.003,
								" Although": 0.003,
								" Therefore": 0.003
							}
						},
						{
							"p": " Its",
							"b": 0.0,
							"k": {
								" Its": 0.9,
								" It": 0.047,
								" Therefore": 0.009,
								" The": 0.006,
								" And": 0.005,
								" This": 0.004,
								" However": 0.004,
								" But": 0.002,
								" Since": 0.002,
								" Although": 0.002
							}
						},
						{
							"p": " Its",
							"b": 0.0,
							"k": {
								" Its": 0.786,
								" It": 0.092,
								" The": 0.021,
								" Located": 0.015,
								" This": 0.014,
								" Therefore": 0.009,
								" However": 0.006,
								" There": 0.005,
								" Since": 0.005,
								" And": 0.004
							}
						},
						{
							"p": " Its",
							"b": 0.0,
							"k": {
								" Its": 0.508,
								" It": 0.2,
								" The": 0.091,
								" This": 0.022,
								" Since": 0.013,
								" However": 0.011,
								" Therefore": 0.011,
								" There": 0.01,
								" Located": 0.01,
								" While": 0.009
							}
						},
						{
							"p": " It",
							"b": 0.0,
							"k": {
								" It": 0.423,
								" Its": 0.17,
								" The": 0.107,
								" This": 0.03,
								" ": 0.028,
								" In": 0.021,
								" We": 0.016,
								" And": 0.015,
								" But": 0.014,
								" There": 0.012
							}
						},
						{
							"p": " It",
							"b": 0.0,
							"k": {
								" It": 0.163,
								" ": 0.117,
								" The": 0.112,
								" In": 0.038,
								" Its": 0.03,
								" This": 0.027,
								" We": 0.026,
								" And": 0.017,
								" But": 0.016,
								" I": 0.013
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.001,
									0.592,
									0.033,
									0.072,
									0.447,
									1.0,
									0.064,
									0.047,
									1.0,
									0.974,
									1.0
								],
								"e": 0.499
							},
							{
								"h": [
									0.586,
									0.711,
									0.571,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.011,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.186,
									0.879,
									1.0,
									0.412,
									0.655,
									0.499,
									0.389,
									0.457,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.359,
									1.0,
									1.0,
									1.0,
									0.506,
									0.743,
									0.642,
									1.0,
									1.0,
									0.467
								],
								"e": 1.0
							},
							{
								"h": [
									0.638,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.269,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.526,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.058,
									0.044,
									0.233,
									0.009,
									0.058,
									0.747,
									0.014,
									0.026,
									0.411,
									0.698,
									0.92,
									0.544
								],
								"e": 0.265
							},
							{
								"h": [
									0.188,
									0.223,
									0.149,
									0.059,
									0.138,
									0.415,
									0.189,
									0.112,
									0.47,
									0.14,
									0.299,
									0.317
								],
								"e": 0.235
							},
							{
								"h": [
									0.062,
									0.032,
									0.0,
									0.076,
									0.05,
									0.115,
									0.034,
									0.037,
									0.056,
									0.011,
									0.055,
									0.012
								],
								"e": 0.056
							},
							{
								"h": [
									0.014,
									0.134,
									0.221,
									0.005,
									0.015,
									0.17,
									0.092,
									0.014,
									0.047,
									0.123,
									0.017,
									0.014
								],
								"e": 0.061
							},
							{
								"h": [
									0.005,
									0.125,
									0.069,
									0.114,
									0.051,
									0.011,
									0.089,
									0.004,
									0.009,
									0.065,
									0.027,
									0.0
								],
								"e": 0.051
							},
							{
								"h": [
									0.0,
									0.0,
									0.107,
									0.268,
									0.026,
									0.0,
									0.0,
									0.027,
									0.005,
									0.008,
									0.223,
									0.035
								],
								"e": 0.038
							},
							{
								"h": [
									0.038,
									0.021,
									0.019,
									0.063,
									0.032,
									0.044,
									0.009,
									0.31,
									0.057,
									0.0,
									0.004,
									0.016
								],
								"e": 0.033
							},
							{
								"h": [
									0.0,
									0.003,
									0.0,
									0.018,
									0.033,
									0.049,
									0.038,
									0.014,
									0.049,
									0.006,
									0.0,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.008,
									0.001,
									0.112,
									0.021,
									0.006,
									0.026,
									0.022,
									0.0,
									0.045,
									0.025,
									0.02,
									0.058
								],
								"e": 0.023
							},
							{
								"h": [
									0.015,
									0.002,
									0.041,
									0.02,
									0.016,
									0.028,
									0.003,
									0.024,
									0.081,
									0.006,
									0.004,
									0.005
								],
								"e": 0.018
							},
							{
								"h": [
									0.036,
									0.013,
									0.037,
									0.044,
									0.015,
									0.013,
									0.023,
									0.068,
									0.003,
									0.021,
									0.008,
									0.02
								],
								"e": 0.024
							},
							{
								"h": [
									0.375,
									0.113,
									0.237,
									0.445,
									0.091,
									0.123,
									0.022,
									0.084,
									0.329,
									0.03,
									0.286,
									0.08
								],
								"e": 0.154
							}
						],
						[
							{
								"h": [
									0.291,
									0.001,
									0.15,
									0.007,
									0.048,
									0.062,
									0.831,
									0.027,
									0.026,
									0.583,
									0.391,
									0.323
								],
								"e": 0.219
							},
							{
								"h": [
									0.094,
									0.116,
									0.192,
									0.069,
									0.181,
									0.056,
									0.067,
									0.12,
									0.283,
									0.107,
									0.405,
									0.146
								],
								"e": 0.15
							},
							{
								"h": [
									0.112,
									0.026,
									0.002,
									0.126,
									0.015,
									0.012,
									0.023,
									0.269,
									0.014,
									0.079,
									0.135,
									0.027
								],
								"e": 0.07
							},
							{
								"h": [
									0.001,
									0.029,
									0.046,
									0.009,
									0.019,
									0.132,
									0.775,
									0.055,
									0.014,
									0.115,
									0.031,
									0.049
								],
								"e": 0.065
							},
							{
								"h": [
									0.001,
									0.041,
									0.189,
									0.055,
									0.016,
									0.006,
									0.142,
									0.018,
									0.029,
									0.057,
									0.004,
									0.0
								],
								"e": 0.051
							},
							{
								"h": [
									0.0,
									0.0,
									0.009,
									0.07,
									0.011,
									0.003,
									0.001,
									0.007,
									0.004,
									0.008,
									0.03,
									0.012
								],
								"e": 0.01
							},
							{
								"h": [
									0.024,
									0.001,
									0.023,
									0.042,
									0.043,
									0.057,
									0.005,
									0.373,
									0.018,
									0.0,
									0.019,
									0.016
								],
								"e": 0.032
							},
							{
								"h": [
									0.001,
									0.017,
									0.001,
									0.056,
									0.006,
									0.036,
									0.115,
									0.054,
									0.035,
									0.005,
									0.001,
									0.003
								],
								"e": 0.024
							},
							{
								"h": [
									0.009,
									0.001,
									0.333,
									0.003,
									0.002,
									0.039,
									0.016,
									0.001,
									0.015,
									0.018,
									0.044,
									0.021
								],
								"e": 0.029
							},
							{
								"h": [
									0.036,
									0.0,
									0.03,
									0.045,
									0.016,
									0.018,
									0.017,
									0.025,
									0.062,
									0.012,
									0.002,
									0.006
								],
								"e": 0.021
							},
							{
								"h": [
									0.088,
									0.014,
									0.044,
									0.036,
									0.011,
									0.003,
									0.038,
									0.066,
									0.001,
									0.007,
									0.044,
									0.005
								],
								"e": 0.029
							},
							{
								"h": [
									0.201,
									0.051,
									0.28,
									0.153,
									0.066,
									0.022,
									0.022,
									0.023,
									0.192,
									0.017,
									0.173,
									0.03
								],
								"e": 0.087
							}
						],
						[
							{
								"h": [
									0.086,
									0.0,
									1.0,
									0.022,
									0.081,
									0.224,
									0.024,
									0.052,
									0.179,
									0.579,
									0.699,
									0.439
								],
								"e": 0.243
							},
							{
								"h": [
									1.0,
									0.364,
									0.371,
									0.094,
									0.167,
									0.26,
									0.097,
									0.185,
									0.394,
									0.229,
									0.462,
									0.479
								],
								"e": 0.317
							},
							{
								"h": [
									0.349,
									0.069,
									0.01,
									0.239,
									0.134,
									0.077,
									0.135,
									0.33,
									1.0,
									0.045,
									0.142,
									0.03
								],
								"e": 0.234
							},
							{
								"h": [
									0.005,
									0.144,
									0.684,
									0.025,
									0.016,
									0.578,
									0.524,
									0.1,
									0.336,
									0.807,
									0.052,
									0.196
								],
								"e": 0.219
							},
							{
								"h": [
									0.13,
									0.212,
									0.033,
									0.215,
									0.06,
									0.034,
									0.331,
									0.07,
									0.024,
									0.468,
									0.015,
									0.0
								],
								"e": 0.119
							},
							{
								"h": [
									0.0,
									0.0,
									0.538,
									0.19,
									0.26,
									0.002,
									0.008,
									0.026,
									0.022,
									0.019,
									0.123,
									0.019
								],
								"e": 0.059
							},
							{
								"h": [
									0.417,
									0.023,
									0.018,
									0.153,
									0.014,
									0.06,
									0.02,
									0.093,
									0.11,
									0.0,
									0.003,
									0.022
								],
								"e": 0.055
							},
							{
								"h": [
									0.004,
									0.005,
									0.0,
									0.04,
									0.176,
									0.14,
									0.024,
									0.038,
									0.048,
									0.033,
									0.002,
									0.003
								],
								"e": 0.036
							},
							{
								"h": [
									0.031,
									0.028,
									0.232,
									0.207,
									0.007,
									0.075,
									0.105,
									0.003,
									0.157,
									0.094,
									0.065,
									0.141
								],
								"e": 0.08
							},
							{
								"h": [
									0.076,
									0.016,
									0.104,
									0.058,
									0.097,
									0.013,
									0.008,
									0.235,
									0.036,
									0.008,
									0.011,
									0.007
								],
								"e": 0.046
							},
							{
								"h": [
									0.03,
									0.015,
									0.035,
									0.083,
									0.037,
									0.032,
									0.035,
									0.07,
									0.006,
									0.094,
									0.009,
									0.011
								],
								"e": 0.034
							},
							{
								"h": [
									0.358,
									0.108,
									0.047,
									0.299,
									0.09,
									0.077,
									0.022,
									0.226,
									0.545,
									0.031,
									0.178,
									0.146
								],
								"e": 0.152
							}
						],
						[
							{
								"h": [
									0.115,
									0.001,
									0.109,
									0.025,
									0.095,
									0.394,
									0.009,
									0.063,
									0.289,
									0.577,
									0.58,
									0.265
								],
								"e": 0.18
							},
							{
								"h": [
									0.482,
									0.207,
									0.411,
									0.085,
									0.167,
									0.213,
									0.079,
									0.164,
									0.354,
									0.4,
									0.402,
									0.45
								],
								"e": 0.278
							},
							{
								"h": [
									0.141,
									0.035,
									0.016,
									0.547,
									0.257,
									0.064,
									0.059,
									0.307,
									0.184,
									0.1,
									0.109,
									0.031
								],
								"e": 0.156
							},
							{
								"h": [
									0.011,
									0.145,
									0.553,
									0.01,
									0.015,
									0.359,
									0.578,
									0.093,
									0.055,
									0.131,
									0.03,
									0.169
								],
								"e": 0.126
							},
							{
								"h": [
									0.087,
									0.03,
									0.057,
									0.099,
									0.078,
									0.054,
									0.203,
									0.024,
									0.038,
									0.542,
									0.049,
									0.0
								],
								"e": 0.093
							},
							{
								"h": [
									0.002,
									0.0,
									0.538,
									0.179,
									0.129,
									0.009,
									0.0,
									0.028,
									0.055,
									0.008,
									0.034,
									0.016
								],
								"e": 0.052
							},
							{
								"h": [
									0.12,
									0.011,
									0.036,
									0.182,
									0.017,
									0.037,
									0.021,
									0.033,
									0.055,
									0.001,
									0.006,
									0.02
								],
								"e": 0.035
							},
							{
								"h": [
									0.003,
									0.004,
									0.001,
									0.019,
									0.046,
									0.177,
									0.009,
									0.041,
									0.011,
									0.015,
									0.006,
									0.003
								],
								"e": 0.022
							},
							{
								"h": [
									0.029,
									0.021,
									0.087,
									0.406,
									0.003,
									0.093,
									0.03,
									0.001,
									0.038,
									0.077,
									0.037,
									0.023
								],
								"e": 0.057
							},
							{
								"h": [
									0.011,
									0.002,
									0.027,
									0.007,
									0.104,
									0.006,
									0.004,
									0.038,
									0.009,
									0.005,
									0.014,
									0.003
								],
								"e": 0.016
							},
							{
								"h": [
									0.01,
									0.006,
									0.02,
									0.047,
									0.025,
									0.012,
									0.009,
									0.025,
									0.003,
									0.027,
									0.004,
									0.009
								],
								"e": 0.015
							},
							{
								"h": [
									0.207,
									0.079,
									0.031,
									0.079,
									0.049,
									0.031,
									0.01,
									0.079,
									0.106,
									0.01,
									0.045,
									0.026
								],
								"e": 0.055
							}
						],
						[
							{
								"h": [
									0.218,
									0.001,
									0.065,
									0.062,
									0.082,
									0.052,
									0.342,
									0.06,
									0.021,
									0.328,
									0.432,
									0.345
								],
								"e": 0.157
							},
							{
								"h": [
									0.325,
									0.184,
									0.184,
									0.077,
									0.246,
									0.025,
									0.026,
									0.205,
									0.153,
									0.055,
									0.509,
									0.1
								],
								"e": 0.163
							},
							{
								"h": [
									0.231,
									0.083,
									0.04,
									0.254,
									0.028,
									0.05,
									0.042,
									0.588,
									0.218,
									0.61,
									0.267,
									0.052
								],
								"e": 0.205
							},
							{
								"h": [
									0.0,
									0.021,
									0.136,
									0.086,
									0.002,
									0.271,
									0.722,
									0.888,
									0.059,
									0.077,
									0.041,
									1.0
								],
								"e": 0.191
							},
							{
								"h": [
									0.086,
									0.05,
									0.027,
									0.701,
									0.031,
									0.079,
									0.512,
									0.028,
									0.033,
									0.682,
									0.007,
									0.0
								],
								"e": 0.144
							},
							{
								"h": [
									0.0,
									0.0,
									0.222,
									0.071,
									0.737,
									0.003,
									0.007,
									0.152,
									0.01,
									0.009,
									0.212,
									0.036
								],
								"e": 0.071
							},
							{
								"h": [
									0.252,
									0.051,
									0.012,
									0.126,
									0.007,
									0.019,
									0.027,
									0.834,
									0.104,
									0.0,
									0.008,
									0.041
								],
								"e": 0.071
							},
							{
								"h": [
									0.006,
									0.005,
									0.0,
									0.019,
									0.017,
									0.06,
									0.014,
									0.005,
									0.294,
									0.005,
									0.006,
									0.001
								],
								"e": 0.029
							},
							{
								"h": [
									0.005,
									0.003,
									0.121,
									0.038,
									0.022,
									0.034,
									0.01,
									0.005,
									0.083,
									0.031,
									0.025,
									0.011
								],
								"e": 0.026
							},
							{
								"h": [
									0.081,
									0.0,
									0.083,
									0.178,
									0.041,
									0.018,
									0.013,
									0.096,
									0.019,
									0.006,
									0.057,
									0.001
								],
								"e": 0.043
							},
							{
								"h": [
									0.043,
									0.016,
									0.011,
									0.119,
									0.006,
									0.013,
									0.048,
									0.023,
									0.0,
									0.11,
									0.009,
									0.009
								],
								"e": 0.029
							},
							{
								"h": [
									0.153,
									0.025,
									0.021,
									0.39,
									0.014,
									0.008,
									0.011,
									0.012,
									0.126,
									0.009,
									0.044,
									0.036
								],
								"e": 0.051
							}
						],
						[
							{
								"h": [
									0.035,
									0.004,
									0.412,
									0.068,
									0.239,
									0.259,
									0.008,
									0.124,
									0.179,
									0.571,
									0.681,
									0.266
								],
								"e": 0.209
							},
							{
								"h": [
									0.454,
									0.187,
									0.48,
									0.082,
									0.157,
									0.09,
									0.064,
									0.128,
									0.221,
									0.073,
									0.386,
									0.266
								],
								"e": 0.197
							},
							{
								"h": [
									0.639,
									0.035,
									0.034,
									0.352,
									0.095,
									0.217,
									0.08,
									0.337,
									0.381,
									0.156,
									0.091,
									0.032
								],
								"e": 0.218
							},
							{
								"h": [
									0.0,
									0.037,
									0.386,
									0.077,
									0.005,
									0.297,
									0.251,
									0.297,
									0.556,
									0.054,
									0.021,
									0.183
								],
								"e": 0.131
							},
							{
								"h": [
									1.0,
									0.009,
									0.023,
									0.289,
									0.028,
									0.142,
									0.344,
									0.086,
									0.072,
									0.385,
									0.044,
									0.0
								],
								"e": 0.191
							},
							{
								"h": [
									0.001,
									0.0,
									0.091,
									0.01,
									0.348,
									0.001,
									0.0,
									0.103,
									0.003,
									0.005,
									0.219,
									0.07
								],
								"e": 0.044
							},
							{
								"h": [
									0.261,
									0.025,
									0.024,
									0.316,
									0.012,
									0.047,
									0.006,
									0.251,
									0.054,
									0.0,
									0.006,
									0.026
								],
								"e": 0.055
							},
							{
								"h": [
									0.006,
									0.012,
									0.001,
									0.013,
									0.021,
									0.102,
									0.034,
									0.006,
									0.054,
									0.002,
									0.007,
									0.004
								],
								"e": 0.017
							},
							{
								"h": [
									0.018,
									0.012,
									0.098,
									0.017,
									0.011,
									0.034,
									0.006,
									0.002,
									0.021,
									0.03,
									0.016,
									0.008
								],
								"e": 0.019
							},
							{
								"h": [
									0.004,
									0.001,
									0.019,
									0.008,
									0.034,
									0.008,
									0.001,
									0.077,
									0.017,
									0.001,
									0.005,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.006,
									0.006,
									0.006,
									0.024,
									0.014,
									0.019,
									0.008,
									0.039,
									0.003,
									0.027,
									0.002,
									0.003
								],
								"e": 0.012
							},
							{
								"h": [
									0.133,
									0.047,
									0.048,
									0.157,
									0.051,
									0.045,
									0.009,
									0.064,
									0.103,
									0.012,
									0.12,
									0.045
								],
								"e": 0.06
							}
						],
						[
							{
								"h": [
									0.127,
									0.0,
									0.104,
									0.185,
									0.242,
									0.312,
									0.009,
									0.233,
									0.354,
									0.577,
									0.531,
									0.26
								],
								"e": 0.24
							},
							{
								"h": [
									0.585,
									0.238,
									0.5,
									0.097,
									0.205,
									0.159,
									0.055,
									0.16,
									0.301,
									0.328,
									0.483,
									0.346
								],
								"e": 0.272
							},
							{
								"h": [
									0.147,
									0.028,
									0.127,
									1.0,
									0.356,
									0.222,
									0.062,
									0.474,
									0.203,
									0.26,
									0.149,
									0.048
								],
								"e": 0.26
							},
							{
								"h": [
									0.005,
									0.107,
									1.0,
									0.034,
									0.009,
									0.135,
									0.329,
									0.234,
									0.068,
									0.035,
									0.032,
									0.118
								],
								"e": 0.12
							},
							{
								"h": [
									0.073,
									0.005,
									0.007,
									0.093,
									0.03,
									0.081,
									0.208,
									0.01,
									0.034,
									0.143,
									0.058,
									0.0
								],
								"e": 0.059
							},
							{
								"h": [
									0.001,
									0.0,
									0.036,
									0.005,
									0.073,
									0.0,
									0.0,
									0.031,
									0.003,
									0.003,
									0.031,
									0.019
								],
								"e": 0.011
							},
							{
								"h": [
									0.068,
									0.015,
									0.02,
									0.147,
									0.016,
									0.027,
									0.003,
									0.097,
									0.026,
									0.0,
									0.004,
									0.012
								],
								"e": 0.025
							},
							{
								"h": [
									0.002,
									0.018,
									0.003,
									0.008,
									0.018,
									0.06,
									0.01,
									0.016,
									0.012,
									0.001,
									0.01,
									0.004
								],
								"e": 0.011
							},
							{
								"h": [
									0.012,
									0.005,
									0.044,
									0.011,
									0.004,
									0.012,
									0.004,
									0.001,
									0.012,
									0.029,
									0.009,
									0.006
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.002,
									0.012,
									0.001,
									0.016,
									0.008,
									0.002,
									0.026,
									0.009,
									0.004,
									0.003,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.005,
									0.004,
									0.011,
									0.016,
									0.014,
									0.019,
									0.006,
									0.032,
									0.004,
									0.011,
									0.002,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.147,
									0.054,
									0.038,
									0.039,
									0.049,
									0.031,
									0.007,
									0.041,
									0.064,
									0.007,
									0.048,
									0.006
								],
								"e": 0.039
							}
						],
						[
							{
								"h": [
									0.107,
									0.0,
									0.205,
									0.41,
									0.284,
									0.236,
									0.442,
									0.454,
									0.056,
									0.5,
									0.453,
									0.448
								],
								"e": 0.316
							},
							{
								"h": [
									0.251,
									0.14,
									0.352,
									0.091,
									0.27,
									0.061,
									0.018,
									0.095,
									0.188,
									0.146,
									0.559,
									0.08
								],
								"e": 0.174
							},
							{
								"h": [
									0.171,
									0.027,
									0.078,
									0.227,
									0.068,
									0.059,
									0.024,
									0.884,
									0.063,
									0.67,
									0.23,
									0.061
								],
								"e": 0.198
							},
							{
								"h": [
									0.001,
									0.006,
									0.09,
									0.009,
									0.003,
									0.094,
									1.0,
									0.277,
									0.042,
									0.01,
									0.051,
									0.022
								],
								"e": 0.072
							},
							{
								"h": [
									0.004,
									0.003,
									0.012,
									0.127,
									0.05,
									0.063,
									0.158,
									0.008,
									0.037,
									0.052,
									0.005,
									0.001
								],
								"e": 0.041
							},
							{
								"h": [
									0.0,
									0.0,
									0.03,
									0.02,
									0.049,
									0.001,
									0.003,
									0.009,
									0.003,
									0.005,
									0.097,
									0.013
								],
								"e": 0.012
							},
							{
								"h": [
									0.015,
									0.007,
									0.012,
									0.039,
									0.031,
									0.019,
									0.004,
									0.103,
									0.057,
									0.0,
									0.008,
									0.007
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.009,
									0.004,
									0.007,
									0.021,
									0.01,
									0.017,
									0.01,
									0.005,
									0.001,
									0.006,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.01,
									0.001,
									0.049,
									0.004,
									0.007,
									0.026,
									0.002,
									0.001,
									0.041,
									0.015,
									0.014,
									0.012
								],
								"e": 0.013
							},
							{
								"h": [
									0.005,
									0.002,
									0.026,
									0.005,
									0.008,
									0.023,
									0.003,
									0.026,
									0.044,
									0.019,
									0.002,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.018,
									0.011,
									0.008,
									0.043,
									0.009,
									0.012,
									0.023,
									0.026,
									0.002,
									0.01,
									0.011,
									0.002
								],
								"e": 0.013
							},
							{
								"h": [
									0.143,
									0.009,
									0.022,
									0.013,
									0.056,
									0.003,
									0.005,
									0.024,
									0.016,
									0.004,
									0.029,
									0.003
								],
								"e": 0.023
							}
						],
						[
							{
								"h": [
									0.191,
									0.0,
									0.319,
									0.32,
									0.276,
									0.134,
									0.25,
									0.603,
									0.03,
									0.423,
									0.338,
									0.249
								],
								"e": 0.288
							},
							{
								"h": [
									0.598,
									0.272,
									0.477,
									0.113,
									0.316,
									0.112,
									0.061,
									0.193,
									0.244,
									0.088,
									0.763,
									0.381
								],
								"e": 0.274
							},
							{
								"h": [
									0.13,
									0.074,
									0.357,
									0.24,
									0.203,
									0.263,
									0.06,
									1.0,
									0.148,
									1.0,
									0.393,
									0.113
								],
								"e": 0.354
							},
							{
								"h": [
									0.002,
									0.01,
									0.134,
									0.091,
									0.005,
									0.093,
									0.37,
									1.0,
									0.16,
									0.046,
									0.107,
									0.023
								],
								"e": 0.114
							},
							{
								"h": [
									0.007,
									0.008,
									0.004,
									0.132,
									0.072,
									0.043,
									0.329,
									0.078,
									0.06,
									0.051,
									0.004,
									1.0
								],
								"e": 0.244
							},
							{
								"h": [
									0.001,
									0.0,
									0.033,
									0.032,
									0.17,
									0.001,
									0.021,
									0.019,
									0.003,
									0.01,
									0.22,
									0.016
								],
								"e": 0.027
							},
							{
								"h": [
									0.052,
									0.012,
									0.02,
									0.088,
									0.033,
									0.059,
									0.005,
									0.483,
									0.147,
									0.002,
									0.038,
									0.028
								],
								"e": 0.052
							},
							{
								"h": [
									0.007,
									0.012,
									0.004,
									0.029,
									0.019,
									0.049,
									0.111,
									0.033,
									0.01,
									0.002,
									0.004,
									0.002
								],
								"e": 0.02
							},
							{
								"h": [
									0.002,
									0.012,
									0.145,
									0.005,
									0.005,
									0.042,
									0.012,
									0.003,
									0.03,
									0.093,
									0.014,
									0.023
								],
								"e": 0.026
							},
							{
								"h": [
									0.018,
									0.006,
									0.069,
									0.027,
									0.017,
									0.014,
									0.004,
									0.045,
									0.075,
									0.013,
									0.015,
									0.004
								],
								"e": 0.023
							},
							{
								"h": [
									0.03,
									0.012,
									0.01,
									0.15,
									0.034,
									0.029,
									0.043,
									0.036,
									0.003,
									0.026,
									0.009,
									0.008
								],
								"e": 0.029
							},
							{
								"h": [
									0.166,
									0.026,
									0.045,
									0.221,
									0.027,
									0.01,
									0.004,
									0.061,
									0.092,
									0.007,
									0.117,
									0.019
								],
								"e": 0.05
							}
						],
						[
							{
								"h": [
									0.037,
									1.0,
									0.217,
									1.0,
									1.0,
									1.0,
									0.006,
									1.0,
									1.0,
									0.683,
									1.0,
									0.757
								],
								"e": 1.0
							},
							{
								"h": [
									0.87,
									1.0,
									1.0,
									0.18,
									0.329,
									0.377,
									0.383,
									0.29,
									0.494,
									0.104,
									1.0,
									0.501
								],
								"e": 0.522
							},
							{
								"h": [
									0.186,
									0.051,
									1.0,
									0.446,
									0.706,
									1.0,
									1.0,
									0.922,
									0.571,
									0.746,
									0.312,
									0.103
								],
								"e": 0.737
							},
							{
								"h": [
									0.005,
									0.186,
									0.222,
									0.344,
									0.031,
									0.131,
									0.445,
									0.637,
									1.0,
									0.248,
									0.136,
									0.395
								],
								"e": 0.262
							},
							{
								"h": [
									0.088,
									0.123,
									0.087,
									0.077,
									0.054,
									0.073,
									0.167,
									1.0,
									0.079,
									0.158,
									0.06,
									0.0
								],
								"e": 0.202
							},
							{
								"h": [
									0.02,
									0.003,
									0.046,
									0.143,
									0.152,
									0.016,
									0.02,
									0.046,
									0.02,
									0.035,
									0.16,
									0.179
								],
								"e": 0.054
							},
							{
								"h": [
									0.037,
									0.056,
									0.118,
									0.209,
									0.07,
									0.245,
									0.096,
									0.057,
									0.025,
									0.006,
									0.016,
									0.017
								],
								"e": 0.072
							},
							{
								"h": [
									0.002,
									0.048,
									0.003,
									0.033,
									0.044,
									0.295,
									0.116,
									0.203,
									0.007,
									0.041,
									0.015,
									0.01
								],
								"e": 0.056
							},
							{
								"h": [
									0.029,
									0.028,
									0.24,
									0.151,
									0.016,
									0.152,
									0.129,
									0.011,
									0.157,
									0.151,
									0.077,
									0.057
								],
								"e": 0.085
							},
							{
								"h": [
									0.035,
									0.027,
									0.225,
									0.202,
									0.202,
									0.025,
									0.004,
									0.186,
									0.052,
									0.014,
									0.035,
									0.01
								],
								"e": 0.071
							},
							{
								"h": [
									0.031,
									0.018,
									0.059,
									0.137,
									0.071,
									0.104,
									0.031,
									0.079,
									0.025,
									0.314,
									0.008,
									0.156
								],
								"e": 0.078
							},
							{
								"h": [
									1.0,
									0.085,
									0.198,
									0.691,
									0.107,
									0.208,
									0.037,
									0.198,
									1.0,
									0.074,
									0.711,
									0.155
								],
								"e": 0.294
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " London",
					"o": " is",
					"t": " is",
					"r": [
						{
							"p": "mouth",
							"b": 0.0,
							"k": {
								"mouth": 0.875,
								" London": 0.074,
								"ers": 0.006,
								" Underground": 0.005,
								" England": 0.003,
								" and": 0.002,
								",": 0.001,
								"er": 0.001,
								"'s": 0.001,
								" New": 0.001
							}
						},
						{
							"p": "mouth",
							"b": 0.001,
							"k": {
								"mouth": 0.324,
								"ers": 0.241,
								" Bridge": 0.039,
								" Underground": 0.034,
								" London": 0.027,
								",": 0.023,
								" and": 0.022,
								"'s": 0.021,
								"er": 0.012,
								" Union": 0.012
							}
						},
						{
							"p": "mouth",
							"b": 0.002,
							"k": {
								"mouth": 0.253,
								"ers": 0.206,
								" Bridge": 0.124,
								"bridge": 0.036,
								" Underground": 0.036,
								",": 0.026,
								"'s": 0.024,
								" and": 0.023,
								" London": 0.018,
								"er": 0.012
							}
						},
						{
							"p": "'s",
							"b": 0.011,
							"k": {
								"'s": 0.14,
								"ers": 0.093,
								",": 0.09,
								" Underground": 0.079,
								" Bridge": 0.068,
								"mouth": 0.062,
								" and": 0.051,
								" London": 0.037,
								" Mayor": 0.025,
								" Eye": 0.02
							}
						},
						{
							"p": "'s",
							"b": 0.052,
							"k": {
								"'s": 0.316,
								",": 0.139,
								" and": 0.084,
								" Underground": 0.072,
								"ers": 0.058,
								" is": 0.052,
								" Union": 0.025,
								" Bridge": 0.023,
								" Mayor": 0.009,
								" London": 0.007
							}
						},
						{
							"p": "'s",
							"b": 0.138,
							"k": {
								"'s": 0.196,
								",": 0.163,
								" is": 0.138,
								" Union": 0.082,
								" Mayor": 0.068,
								" and": 0.049,
								"ers": 0.033,
								" Bridge": 0.029,
								" City": 0.027,
								" Underground": 0.024
							}
						},
						{
							"p": "'s",
							"b": 0.182,
							"k": {
								"'s": 0.35,
								" is": 0.182,
								" Mayor": 0.118,
								",": 0.06,
								" and": 0.049,
								" City": 0.038,
								" Bridge": 0.02,
								" Union": 0.014,
								"ers": 0.013,
								" Underground": 0.013
							}
						},
						{
							"p": " is",
							"b": 0.276,
							"k": {
								" is": 0.276,
								" Mayor": 0.213,
								"'s": 0.174,
								",": 0.106,
								" City": 0.073,
								" and": 0.045,
								" was": 0.013,
								" has": 0.013,
								"ers": 0.009,
								" borough": 0.008
							}
						},
						{
							"p": " is",
							"b": 0.734,
							"k": {
								" is": 0.734,
								"'s": 0.063,
								",": 0.05,
								" Mayor": 0.029,
								" City": 0.026,
								" and": 0.019,
								" has": 0.019,
								" was": 0.012,
								" borough": 0.008,
								" belongs": 0.005
							}
						},
						{
							"p": " is",
							"b": 0.882,
							"k": {
								" is": 0.882,
								",": 0.03,
								" has": 0.024,
								"'s": 0.021,
								" was": 0.008,
								" and": 0.007,
								" belongs": 0.006,
								" City": 0.004,
								" Mayor": 0.004,
								"ers": 0.001
							}
						},
						{
							"p": " is",
							"b": 0.854,
							"k": {
								" is": 0.854,
								" has": 0.042,
								",": 0.041,
								" was": 0.02,
								"'s": 0.014,
								" and": 0.007,
								" belongs": 0.002,
								" will": 0.001,
								" also": 0.001,
								" does": 0.001
							}
						},
						{
							"p": " is",
							"b": 0.478,
							"k": {
								" is": 0.478,
								",": 0.197,
								" has": 0.052,
								" and": 0.043,
								" was": 0.033,
								"'s": 0.02,
								" also": 0.006,
								" (": 0.005,
								".": 0.004,
								" will": 0.004
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.0,
									1.0,
									0.001,
									0.012,
									0.006,
									1.0,
									0.06,
									1.0,
									1.0,
									0.633,
									1.0
								],
								"e": 0.349
							},
							{
								"h": [
									0.211,
									0.058,
									0.561,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.008,
									0.091
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.027,
									0.487,
									0.625,
									0.125,
									0.33,
									0.534,
									0.3,
									0.251,
									0.814,
									1.0
								],
								"e": 0.695
							},
							{
								"h": [
									1.0,
									1.0,
									0.731,
									1.0,
									1.0,
									1.0,
									0.793,
									0.69,
									0.46,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.642,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.907,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.233,
									0.0,
									0.349,
									0.0,
									0.001,
									0.0,
									0.114,
									0.031,
									0.618,
									0.368,
									0.379,
									0.719
								],
								"e": 0.123
							},
							{
								"h": [
									0.09,
									0.029,
									0.184,
									0.048,
									0.139,
									0.38,
									0.278,
									0.226,
									0.39,
									0.118,
									0.255,
									0.017
								],
								"e": 0.235
							},
							{
								"h": [
									0.076,
									0.069,
									0.001,
									0.036,
									0.029,
									0.032,
									0.02,
									0.025,
									0.036,
									0.009,
									0.108,
									0.01
								],
								"e": 0.043
							},
							{
								"h": [
									0.059,
									0.04,
									0.194,
									0.01,
									0.099,
									0.168,
									0.014,
									0.009,
									0.016,
									0.027,
									0.031,
									0.014
								],
								"e": 0.055
							},
							{
								"h": [
									0.002,
									0.031,
									0.076,
									0.001,
									0.037,
									0.013,
									0.081,
									0.001,
									0.08,
									0.057,
									0.045,
									0.0
								],
								"e": 0.038
							},
							{
								"h": [
									0.094,
									0.002,
									0.048,
									0.091,
									0.007,
									0.16,
									0.002,
									0.002,
									0.824,
									0.053,
									0.059,
									0.096
								],
								"e": 0.079
							},
							{
								"h": [
									0.006,
									0.089,
									0.033,
									0.018,
									0.014,
									0.014,
									0.021,
									0.091,
									0.002,
									0.014,
									0.007,
									0.003
								],
								"e": 0.024
							},
							{
								"h": [
									0.001,
									0.034,
									0.005,
									0.009,
									0.003,
									0.024,
									0.02,
									0.042,
									0.002,
									0.013,
									0.004,
									0.119
								],
								"e": 0.021
							},
							{
								"h": [
									0.024,
									0.047,
									0.045,
									0.006,
									0.001,
									0.005,
									0.107,
									0.001,
									0.062,
									0.023,
									0.018,
									0.008
								],
								"e": 0.025
							},
							{
								"h": [
									0.01,
									0.009,
									0.018,
									0.007,
									0.01,
									0.02,
									0.011,
									0.019,
									0.019,
									0.008,
									0.002,
									0.039
								],
								"e": 0.014
							},
							{
								"h": [
									0.009,
									0.006,
									0.113,
									0.081,
									0.064,
									0.016,
									0.01,
									0.054,
									0.047,
									0.001,
									0.008,
									0.059
								],
								"e": 0.035
							},
							{
								"h": [
									0.46,
									0.034,
									0.042,
									0.088,
									0.093,
									0.037,
									0.013,
									0.037,
									0.573,
									0.015,
									0.095,
									0.049
								],
								"e": 0.073
							}
						],
						[
							{
								"h": [
									0.221,
									0.003,
									0.218,
									0.0,
									0.002,
									0.0,
									0.523,
									0.024,
									0.642,
									0.399,
									0.276,
									0.449
								],
								"e": 0.136
							},
							{
								"h": [
									0.033,
									0.013,
									0.211,
									0.057,
									0.163,
									0.137,
									0.15,
									0.231,
									0.205,
									0.134,
									0.344,
									0.01
								],
								"e": 0.176
							},
							{
								"h": [
									0.091,
									0.095,
									0.0,
									0.043,
									0.005,
									0.004,
									0.014,
									0.127,
									0.007,
									0.024,
									0.161,
									0.018
								],
								"e": 0.049
							},
							{
								"h": [
									0.002,
									0.016,
									0.023,
									0.0,
									0.086,
									0.087,
									0.013,
									0.009,
									0.002,
									0.01,
									0.044,
									0.015
								],
								"e": 0.027
							},
							{
								"h": [
									0.0,
									0.005,
									0.024,
									0.0,
									0.03,
									0.002,
									0.039,
									0.019,
									0.067,
									0.016,
									0.015,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.003,
									0.0,
									0.004,
									0.039,
									0.002,
									0.028,
									0.001,
									0.001,
									0.096,
									0.008,
									0.015,
									0.032
								],
								"e": 0.013
							},
							{
								"h": [
									0.005,
									0.006,
									0.012,
									0.016,
									0.005,
									0.017,
									0.01,
									0.055,
									0.001,
									0.006,
									0.01,
									0.001
								],
								"e": 0.011
							},
							{
								"h": [
									0.001,
									0.117,
									0.002,
									0.006,
									0.001,
									0.009,
									0.046,
									0.022,
									0.001,
									0.004,
									0.001,
									0.006
								],
								"e": 0.015
							},
							{
								"h": [
									0.023,
									0.006,
									0.068,
									0.002,
									0.001,
									0.004,
									0.02,
									0.002,
									0.015,
									0.02,
									0.018,
									0.002
								],
								"e": 0.014
							},
							{
								"h": [
									0.003,
									0.002,
									0.011,
									0.007,
									0.012,
									0.021,
									0.007,
									0.024,
									0.009,
									0.002,
									0.0,
									0.007
								],
								"e": 0.008
							},
							{
								"h": [
									0.009,
									0.006,
									0.077,
									0.031,
									0.006,
									0.001,
									0.011,
									0.067,
									0.003,
									0.001,
									0.01,
									0.014
								],
								"e": 0.018
							},
							{
								"h": [
									0.346,
									0.01,
									0.022,
									0.02,
									0.039,
									0.02,
									0.015,
									0.015,
									0.107,
									0.006,
									0.033,
									0.008
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.092,
									0.0,
									0.365,
									0.0,
									0.0,
									0.0,
									0.04,
									0.067,
									0.437,
									0.296,
									0.257,
									0.466
								],
								"e": 0.086
							},
							{
								"h": [
									0.145,
									0.054,
									0.409,
									0.08,
									0.165,
									0.088,
									0.159,
									0.249,
									0.378,
									0.275,
									0.4,
									0.006
								],
								"e": 0.253
							},
							{
								"h": [
									0.093,
									0.108,
									0.001,
									0.067,
									0.016,
									0.026,
									0.045,
									0.162,
									0.263,
									0.011,
									0.191,
									0.027
								],
								"e": 0.093
							},
							{
								"h": [
									0.002,
									0.089,
									0.293,
									0.003,
									0.029,
									0.565,
									0.036,
									0.034,
									0.077,
									0.156,
									0.071,
									0.044
								],
								"e": 0.095
							},
							{
								"h": [
									0.029,
									0.019,
									0.055,
									0.002,
									0.007,
									0.008,
									0.096,
									0.007,
									0.055,
									0.085,
									0.038,
									0.0
								],
								"e": 0.035
							},
							{
								"h": [
									0.039,
									0.0,
									0.04,
									0.114,
									0.017,
									0.021,
									0.003,
									0.002,
									1.0,
									0.045,
									0.044,
									0.032
								],
								"e": 0.062
							},
							{
								"h": [
									0.019,
									0.026,
									0.022,
									0.023,
									0.014,
									0.046,
									0.054,
									0.051,
									0.003,
									0.001,
									0.005,
									0.004
								],
								"e": 0.021
							},
							{
								"h": [
									0.002,
									0.151,
									0.001,
									0.016,
									0.002,
									0.107,
									0.018,
									0.089,
									0.003,
									0.014,
									0.002,
									0.019
								],
								"e": 0.028
							},
							{
								"h": [
									0.036,
									0.162,
									0.084,
									0.03,
									0.006,
									0.008,
									0.228,
									0.004,
									0.1,
									0.07,
									0.036,
									0.024
								],
								"e": 0.059
							},
							{
								"h": [
									0.048,
									0.092,
									0.028,
									0.025,
									0.014,
									0.027,
									0.011,
									0.029,
									0.012,
									0.002,
									0.009,
									0.027
								],
								"e": 0.027
							},
							{
								"h": [
									0.006,
									0.014,
									0.082,
									0.207,
									0.14,
									0.034,
									0.013,
									0.067,
									0.057,
									0.007,
									0.006,
									0.024
								],
								"e": 0.047
							},
							{
								"h": [
									0.903,
									0.034,
									0.022,
									0.161,
									0.086,
									0.088,
									0.019,
									0.045,
									1.0,
									0.025,
									0.185,
									0.157
								],
								"e": 0.122
							}
						],
						[
							{
								"h": [
									0.121,
									0.001,
									0.346,
									0.0,
									0.001,
									0.0,
									0.062,
									0.068,
									0.736,
									0.346,
									0.374,
									0.38
								],
								"e": 0.104
							},
							{
								"h": [
									0.194,
									0.042,
									0.429,
									0.071,
									0.156,
									0.189,
									0.163,
									0.32,
									0.386,
									0.286,
									0.346,
									0.005
								],
								"e": 0.272
							},
							{
								"h": [
									0.088,
									0.037,
									0.006,
									0.447,
									0.062,
									0.02,
									0.041,
									0.144,
									0.069,
									0.017,
									0.154,
									0.027
								],
								"e": 0.09
							},
							{
								"h": [
									0.023,
									0.09,
									0.216,
									0.003,
									0.029,
									0.506,
									0.021,
									0.014,
									0.004,
									0.034,
									0.059,
									0.041
								],
								"e": 0.068
							},
							{
								"h": [
									0.006,
									0.007,
									0.057,
									0.001,
									0.011,
									0.018,
									0.084,
									0.008,
									0.023,
									0.06,
									0.053,
									0.0
								],
								"e": 0.029
							},
							{
								"h": [
									0.014,
									0.0,
									0.007,
									0.045,
									0.013,
									0.021,
									0.0,
									0.001,
									0.197,
									0.019,
									0.025,
									0.014
								],
								"e": 0.019
							},
							{
								"h": [
									0.006,
									0.012,
									0.02,
									0.032,
									0.05,
									0.008,
									0.01,
									0.013,
									0.001,
									0.001,
									0.003,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.032,
									0.0,
									0.005,
									0.002,
									0.035,
									0.005,
									0.104,
									0.0,
									0.004,
									0.003,
									0.011
								],
								"e": 0.014
							},
							{
								"h": [
									0.028,
									0.097,
									0.019,
									0.048,
									0.011,
									0.006,
									0.151,
									0.001,
									0.044,
									0.036,
									0.022,
									0.004
								],
								"e": 0.036
							},
							{
								"h": [
									0.008,
									0.01,
									0.03,
									0.003,
									0.018,
									0.012,
									0.004,
									0.02,
									0.007,
									0.001,
									0.009,
									0.005
								],
								"e": 0.01
							},
							{
								"h": [
									0.002,
									0.008,
									0.016,
									0.099,
									0.075,
									0.021,
									0.009,
									0.017,
									0.008,
									0.004,
									0.005,
									0.013
								],
								"e": 0.019
							},
							{
								"h": [
									0.319,
									0.021,
									0.007,
									0.026,
									0.031,
									0.077,
									0.003,
									0.032,
									0.175,
									0.014,
									0.05,
									0.036
								],
								"e": 0.04
							}
						],
						[
							{
								"h": [
									0.09,
									0.001,
									0.432,
									0.0,
									0.005,
									0.0,
									0.236,
									0.066,
									0.375,
									0.105,
									0.236,
									0.459
								],
								"e": 0.093
							},
							{
								"h": [
									0.116,
									0.017,
									0.206,
									0.064,
									0.218,
									0.061,
									0.29,
									0.237,
									0.116,
									0.044,
									0.427,
									0.003
								],
								"e": 0.185
							},
							{
								"h": [
									0.07,
									0.161,
									0.004,
									0.084,
									0.013,
									0.011,
									0.028,
									0.27,
									0.188,
									0.081,
									0.285,
									0.038
								],
								"e": 0.109
							},
							{
								"h": [
									0.0,
									0.025,
									0.033,
									0.014,
									0.01,
									0.23,
									0.079,
									0.065,
									0.011,
									0.028,
									0.052,
									0.264
								],
								"e": 0.062
							},
							{
								"h": [
									0.001,
									0.009,
									0.025,
									0.012,
									0.021,
									0.008,
									0.073,
									0.01,
									0.033,
									0.12,
									0.054,
									0.0
								],
								"e": 0.032
							},
							{
								"h": [
									0.002,
									0.0,
									0.005,
									0.054,
									0.015,
									0.006,
									0.001,
									0.019,
									0.037,
									0.005,
									0.564,
									0.044
								],
								"e": 0.039
							},
							{
								"h": [
									0.019,
									0.002,
									0.01,
									0.023,
									0.015,
									0.008,
									0.006,
									0.099,
									0.005,
									0.0,
									0.003,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.049,
									0.0,
									0.014,
									0.001,
									0.068,
									0.005,
									0.03,
									0.004,
									0.007,
									0.0,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.023,
									0.008,
									0.021,
									0.005,
									0.005,
									0.016,
									0.011,
									0.002,
									0.1,
									0.037,
									0.016,
									0.004
								],
								"e": 0.018
							},
							{
								"h": [
									0.016,
									0.002,
									0.021,
									0.005,
									0.011,
									0.025,
									0.011,
									0.011,
									0.002,
									0.002,
									0.006,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.016,
									0.036,
									0.026,
									0.036,
									0.02,
									0.017,
									0.02,
									0.069,
									0.002,
									0.013,
									0.009,
									0.005
								],
								"e": 0.021
							},
							{
								"h": [
									0.496,
									0.011,
									0.007,
									0.079,
									0.009,
									0.014,
									0.008,
									0.011,
									0.297,
									0.017,
									0.027,
									0.055
								],
								"e": 0.041
							}
						],
						[
							{
								"h": [
									0.107,
									0.0,
									0.282,
									0.0,
									0.002,
									0.0,
									0.035,
									0.113,
									0.527,
									0.293,
									0.312,
									0.313
								],
								"e": 0.089
							},
							{
								"h": [
									0.254,
									0.067,
									0.523,
									0.07,
									0.148,
									0.056,
									0.113,
									0.188,
									0.177,
									0.071,
									0.315,
									0.016
								],
								"e": 0.205
							},
							{
								"h": [
									0.811,
									0.113,
									0.014,
									0.197,
									0.037,
									0.048,
									0.075,
									0.152,
									0.192,
									0.021,
									0.145,
									0.027
								],
								"e": 0.155
							},
							{
								"h": [
									0.001,
									0.038,
									0.08,
									0.003,
									0.011,
									0.284,
									0.036,
									0.033,
									0.051,
									0.012,
									0.012,
									0.095
								],
								"e": 0.046
							},
							{
								"h": [
									0.012,
									0.008,
									0.035,
									0.007,
									0.052,
									0.004,
									0.083,
									0.004,
									0.045,
									0.05,
									0.019,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.001,
									0.0,
									0.002,
									0.034,
									0.009,
									0.0,
									0.0,
									0.005,
									0.027,
									0.012,
									0.143,
									0.013
								],
								"e": 0.013
							},
							{
								"h": [
									0.006,
									0.007,
									0.024,
									0.032,
									0.011,
									0.017,
									0.003,
									0.037,
									0.001,
									0.0,
									0.003,
									0.002
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.045,
									0.0,
									0.008,
									0.004,
									0.065,
									0.009,
									0.009,
									0.003,
									0.003,
									0.0,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.024,
									0.025,
									0.036,
									0.004,
									0.004,
									0.015,
									0.023,
									0.002,
									0.054,
									0.016,
									0.014,
									0.004
								],
								"e": 0.017
							},
							{
								"h": [
									0.005,
									0.005,
									0.014,
									0.01,
									0.012,
									0.015,
									0.002,
									0.023,
									0.002,
									0.0,
									0.011,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.003,
									0.008,
									0.013,
									0.013,
									0.06,
									0.029,
									0.011,
									0.053,
									0.015,
									0.005,
									0.002,
									0.007
								],
								"e": 0.016
							},
							{
								"h": [
									0.336,
									0.012,
									0.009,
									0.049,
									0.032,
									0.055,
									0.007,
									0.011,
									0.26,
									0.02,
									0.092,
									0.029
								],
								"e": 0.043
							}
						],
						[
							{
								"h": [
									0.125,
									0.0,
									0.305,
									0.0,
									0.003,
									0.0,
									0.059,
									0.208,
									0.745,
									0.341,
									0.321,
									0.39
								],
								"e": 0.113
							},
							{
								"h": [
									0.319,
									0.077,
									0.518,
									0.082,
									0.185,
									0.142,
									0.131,
									0.312,
									0.339,
									0.245,
									0.415,
									0.003
								],
								"e": 0.289
							},
							{
								"h": [
									0.146,
									0.036,
									0.035,
									0.899,
									0.121,
									0.066,
									0.049,
									0.205,
									0.112,
									0.048,
									0.2,
									0.04
								],
								"e": 0.161
							},
							{
								"h": [
									0.013,
									0.083,
									0.145,
									0.002,
									0.024,
									0.177,
									0.014,
									0.026,
									0.003,
									0.019,
									0.051,
									0.035
								],
								"e": 0.042
							},
							{
								"h": [
									0.003,
									0.003,
									0.011,
									0.003,
									0.019,
									0.007,
									0.056,
									0.005,
									0.019,
									0.018,
									0.015,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.012,
									0.003,
									0.0,
									0.0,
									0.001,
									0.007,
									0.017,
									0.031,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.003,
									0.016,
									0.024,
									0.008,
									0.006,
									0.002,
									0.019,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.019,
									0.0,
									0.003,
									0.004,
									0.008,
									0.004,
									0.004,
									0.001,
									0.001,
									0.0,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.026,
									0.01,
									0.025,
									0.003,
									0.002,
									0.004,
									0.028,
									0.001,
									0.019,
									0.013,
									0.01,
									0.002
								],
								"e": 0.011
							},
							{
								"h": [
									0.001,
									0.004,
									0.014,
									0.002,
									0.004,
									0.014,
									0.001,
									0.016,
									0.005,
									0.0,
									0.005,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.002,
									0.004,
									0.019,
									0.037,
									0.01,
									0.002,
									0.016,
									0.005,
									0.002,
									0.001,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.197,
									0.007,
									0.004,
									0.02,
									0.016,
									0.03,
									0.001,
									0.005,
									0.181,
									0.004,
									0.041,
									0.008
								],
								"e": 0.022
							}
						],
						[
							{
								"h": [
									0.097,
									0.001,
									0.589,
									0.001,
									0.006,
									0.0,
									0.202,
									0.127,
									0.708,
									0.46,
									0.258,
									0.696
								],
								"e": 0.138
							},
							{
								"h": [
									0.216,
									0.044,
									0.36,
									0.081,
									0.236,
									0.137,
									0.095,
									0.152,
									0.147,
									0.151,
									0.47,
									0.003
								],
								"e": 0.215
							},
							{
								"h": [
									0.272,
									0.111,
									0.011,
									0.154,
									0.035,
									0.013,
									0.011,
									0.345,
									0.039,
									0.146,
									0.248,
									0.045
								],
								"e": 0.117
							},
							{
								"h": [
									0.003,
									0.007,
									0.013,
									0.002,
									0.031,
									0.055,
									0.028,
									0.074,
									0.002,
									0.002,
									0.053,
									0.016
								],
								"e": 0.024
							},
							{
								"h": [
									0.0,
									0.004,
									0.007,
									0.012,
									0.008,
									0.001,
									0.053,
									0.012,
									0.015,
									0.035,
									0.018,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.0,
									0.006,
									0.016,
									0.002,
									0.001,
									0.001,
									0.0,
									0.004,
									0.013,
									0.038,
									0.016
								],
								"e": 0.006
							},
							{
								"h": [
									0.001,
									0.001,
									0.009,
									0.01,
									0.002,
									0.006,
									0.002,
									0.015,
									0.004,
									0.0,
									0.005,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.01,
									0.0,
									0.002,
									0.004,
									0.004,
									0.004,
									0.002,
									0.0,
									0.001,
									0.0,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.017,
									0.001,
									0.04,
									0.002,
									0.0,
									0.006,
									0.003,
									0.001,
									0.007,
									0.007,
									0.007,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.003,
									0.001,
									0.006,
									0.002,
									0.003,
									0.004,
									0.001,
									0.006,
									0.005,
									0.0,
									0.004,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.002,
									0.005,
									0.004,
									0.002,
									0.009,
									0.003,
									0.018,
									0.001,
									0.002,
									0.002,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.077,
									0.002,
									0.003,
									0.004,
									0.024,
									0.001,
									0.001,
									0.005,
									0.066,
									0.001,
									0.003,
									0.002
								],
								"e": 0.008
							}
						],
						[
							{
								"h": [
									0.249,
									0.0,
									0.482,
									0.0,
									0.001,
									0.0,
									0.233,
									0.258,
									0.319,
									0.61,
									0.125,
									0.229
								],
								"e": 0.133
							},
							{
								"h": [
									0.262,
									0.073,
									0.508,
									0.105,
									0.284,
									0.721,
									0.178,
									0.292,
									0.199,
									0.136,
									0.651,
									0.012
								],
								"e": 0.36
							},
							{
								"h": [
									0.124,
									0.116,
									0.014,
									0.099,
									0.043,
									0.063,
									0.038,
									0.5,
									0.079,
									0.288,
									0.478,
									0.089
								],
								"e": 0.17
							},
							{
								"h": [
									0.003,
									0.014,
									0.026,
									0.006,
									0.124,
									0.033,
									0.027,
									0.213,
									0.016,
									0.004,
									0.099,
									0.015
								],
								"e": 0.055
							},
							{
								"h": [
									0.0,
									0.006,
									0.005,
									0.011,
									0.115,
									0.002,
									0.103,
									0.015,
									0.076,
									0.012,
									0.049,
									0.0
								],
								"e": 0.035
							},
							{
								"h": [
									0.002,
									0.0,
									0.002,
									0.018,
									0.005,
									0.003,
									0.001,
									0.0,
									0.002,
									0.017,
									0.022,
									0.022
								],
								"e": 0.007
							},
							{
								"h": [
									0.004,
									0.001,
									0.02,
									0.019,
									0.008,
									0.016,
									0.007,
									0.044,
									0.003,
									0.0,
									0.005,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.01,
									0.0,
									0.006,
									0.001,
									0.023,
									0.016,
									0.005,
									0.0,
									0.002,
									0.0,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.01,
									0.006,
									0.052,
									0.002,
									0.003,
									0.005,
									0.012,
									0.002,
									0.024,
									0.031,
									0.01,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.002,
									0.002,
									0.01,
									0.006,
									0.005,
									0.013,
									0.001,
									0.019,
									0.01,
									0.001,
									0.001,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.005,
									0.004,
									0.016,
									0.033,
									0.024,
									0.01,
									0.006,
									0.031,
									0.005,
									0.003,
									0.003,
									0.003
								],
								"e": 0.01
							},
							{
								"h": [
									0.371,
									0.005,
									0.007,
									0.034,
									0.011,
									0.012,
									0.001,
									0.033,
									0.246,
									0.001,
									0.02,
									0.016
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.131,
									0.0,
									0.302,
									0.0,
									0.006,
									0.0,
									0.063,
									1.0,
									0.618,
									0.315,
									0.236,
									0.884
								],
								"e": 0.192
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.172,
									0.284,
									0.144,
									0.304,
									0.451,
									0.535,
									0.073,
									0.826,
									0.038
								],
								"e": 0.72
							},
							{
								"h": [
									0.368,
									0.098,
									1.0,
									0.79,
									1.0,
									1.0,
									1.0,
									0.434,
									1.0,
									0.79,
									0.483,
									0.106
								],
								"e": 1.0
							},
							{
								"h": [
									0.009,
									0.974,
									1.0,
									0.547,
									0.049,
									0.305,
									0.357,
									1.0,
									1.0,
									0.27,
									0.186,
									0.395
								],
								"e": 0.507
							},
							{
								"h": [
									0.141,
									0.821,
									0.122,
									0.029,
									0.053,
									0.153,
									0.278,
									0.158,
									0.087,
									0.116,
									0.068,
									1.0
								],
								"e": 0.312
							},
							{
								"h": [
									0.016,
									0.0,
									0.026,
									0.454,
									0.16,
									0.01,
									0.01,
									0.011,
									0.078,
									0.052,
									0.112,
									0.069
								],
								"e": 0.064
							},
							{
								"h": [
									0.124,
									0.044,
									0.111,
									0.062,
									0.192,
									0.156,
									0.032,
									0.059,
									0.014,
									0.002,
									0.012,
									0.083
								],
								"e": 0.07
							},
							{
								"h": [
									0.103,
									0.158,
									0.0,
									0.054,
									0.02,
									0.336,
									0.034,
									0.059,
									0.006,
									0.163,
									0.002,
									0.02
								],
								"e": 0.067
							},
							{
								"h": [
									0.114,
									0.064,
									0.108,
									0.052,
									0.122,
									0.152,
									0.146,
									0.274,
									0.373,
									0.145,
									0.07,
									0.022
								],
								"e": 0.128
							},
							{
								"h": [
									0.012,
									0.04,
									0.125,
									0.456,
									0.092,
									0.06,
									0.007,
									0.07,
									0.018,
									0.002,
									0.11,
									0.02
								],
								"e": 0.073
							},
							{
								"h": [
									0.01,
									0.006,
									0.044,
									0.164,
									0.207,
									0.351,
									0.009,
									0.056,
									0.02,
									0.041,
									0.009,
									0.391
								],
								"e": 0.092
							},
							{
								"h": [
									0.53,
									0.018,
									0.032,
									0.137,
									0.082,
									0.036,
									0.009,
									0.046,
									0.987,
									0.018,
									0.158,
									0.142
								],
								"e": 0.1
							}
						],
						[
							{
								"h": [
									0.025,
									1.0,
									0.497,
									1.0,
									1.0,
									1.0,
									0.31,
									0.373,
									0.318,
									0.036,
									1.0,
									0.668
								],
								"e": 1.0
							},
							{
								"h": [
									0.642,
									0.134,
									0.709,
									0.142,
									0.471,
									0.209,
									0.161,
									0.211,
									0.25,
									0.166,
									1.0,
									1.0
								],
								"e": 0.741
							},
							{
								"h": [
									0.76,
									0.135,
									0.205,
									1.0,
									0.074,
									0.107,
									0.103,
									1.0,
									0.273,
									1.0,
									1.0,
									0.129
								],
								"e": 0.494
							},
							{
								"h": [
									0.149,
									0.213,
									0.979,
									0.103,
									0.005,
									0.096,
									1.0,
									0.122,
									0.056,
									0.665,
									0.176,
									0.336
								],
								"e": 0.304
							},
							{
								"h": [
									0.045,
									0.071,
									0.037,
									0.271,
									0.106,
									0.083,
									0.111,
									1.0,
									0.014,
									0.091,
									0.064,
									0.0
								],
								"e": 0.162
							},
							{
								"h": [
									0.031,
									0.003,
									0.026,
									0.104,
									0.233,
									0.01,
									0.023,
									0.041,
									0.099,
									0.009,
									0.427,
									0.069
								],
								"e": 0.067
							},
							{
								"h": [
									0.18,
									0.04,
									0.04,
									0.018,
									0.013,
									0.119,
									0.047,
									0.217,
									0.029,
									0.001,
									0.02,
									0.018
								],
								"e": 0.055
							},
							{
								"h": [
									0.027,
									0.117,
									0.005,
									0.076,
									0.037,
									0.06,
									0.02,
									0.141,
									0.097,
									0.032,
									0.004,
									0.012
								],
								"e": 0.048
							},
							{
								"h": [
									0.032,
									0.053,
									0.011,
									0.009,
									0.085,
									0.184,
									0.073,
									0.072,
									0.115,
									0.076,
									0.068,
									0.046
								],
								"e": 0.066
							},
							{
								"h": [
									0.067,
									0.004,
									0.033,
									0.114,
									0.02,
									0.058,
									0.016,
									0.029,
									0.041,
									0.008,
									0.029,
									0.007
								],
								"e": 0.033
							},
							{
								"h": [
									0.072,
									0.016,
									0.022,
									0.057,
									0.017,
									0.109,
									0.029,
									0.163,
									0.007,
									0.096,
									0.042,
									0.028
								],
								"e": 0.052
							},
							{
								"h": [
									0.523,
									0.007,
									0.029,
									0.099,
									0.027,
									0.016,
									0.019,
									0.018,
									0.458,
									0.012,
									0.094,
									0.046
								],
								"e": 0.058
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " is",
					"o": " the",
					"t": " the",
					"r": [
						{
							"p": " not",
							"b": 0.008,
							"k": {
								" not": 0.237,
								" now": 0.184,
								" also": 0.124,
								" still": 0.057,
								" very": 0.027,
								" a": 0.026,
								" likely": 0.015,
								" currently": 0.014,
								" definitely": 0.014,
								" more": 0.01
							}
						},
						{
							"p": " also",
							"b": 0.003,
							"k": {
								" also": 0.276,
								" not": 0.265,
								" now": 0.197,
								" still": 0.04,
								" definitely": 0.013,
								" a": 0.011,
								" currently": 0.011,
								" very": 0.009,
								" certainly": 0.009,
								" likely": 0.008
							}
						},
						{
							"p": " now",
							"b": 0.002,
							"k": {
								" now": 0.381,
								" also": 0.265,
								" not": 0.161,
								" still": 0.044,
								" currently": 0.012,
								" clearly": 0.011,
								" a": 0.008,
								" certainly": 0.007,
								" definitely": 0.007,
								" very": 0.006
							}
						},
						{
							"p": " also",
							"b": 0.001,
							"k": {
								" also": 0.357,
								" now": 0.322,
								" not": 0.147,
								" currently": 0.029,
								" still": 0.021,
								" very": 0.008,
								" certainly": 0.008,
								" a": 0.006,
								" probably": 0.006,
								" clearly": 0.006
							}
						},
						{
							"p": " now",
							"b": 0.004,
							"k": {
								" now": 0.353,
								" also": 0.252,
								" not": 0.156,
								" currently": 0.033,
								" still": 0.02,
								" a": 0.013,
								" often": 0.008,
								" probably": 0.006,
								" clearly": 0.006,
								" already": 0.005
							}
						},
						{
							"p": " also",
							"b": 0.002,
							"k": {
								" also": 0.511,
								" not": 0.238,
								" now": 0.123,
								" still": 0.024,
								" certainly": 0.007,
								" a": 0.006,
								" always": 0.005,
								" often": 0.005,
								" definitely": 0.005,
								" currently": 0.004
							}
						},
						{
							"p": " also",
							"b": 0.003,
							"k": {
								" also": 0.767,
								" not": 0.13,
								" now": 0.039,
								" still": 0.007,
								" a": 0.006,
								" the": 0.003,
								" part": 0.003,
								" supposed": 0.003,
								" an": 0.002,
								" always": 0.002
							}
						},
						{
							"p": " also",
							"b": 0.008,
							"k": {
								" also": 0.776,
								" not": 0.106,
								" now": 0.027,
								" still": 0.015,
								" a": 0.014,
								" the": 0.008,
								" an": 0.003,
								" part": 0.003,
								" considered": 0.003,
								" mostly": 0.003
							}
						},
						{
							"p": " also",
							"b": 0.05,
							"k": {
								" also": 0.737,
								" not": 0.055,
								" the": 0.05,
								" a": 0.033,
								" located": 0.019,
								" an": 0.01,
								" home": 0.008,
								" still": 0.007,
								" considered": 0.007,
								" often": 0.006
							}
						},
						{
							"p": " also",
							"b": 0.274,
							"k": {
								" also": 0.452,
								" the": 0.274,
								" located": 0.066,
								" a": 0.039,
								" situated": 0.034,
								" home": 0.021,
								" an": 0.013,
								" not": 0.011,
								" another": 0.008,
								" considered": 0.006
							}
						},
						{
							"p": " the",
							"b": 0.549,
							"k": {
								" the": 0.549,
								" also": 0.152,
								" home": 0.059,
								" a": 0.054,
								" located": 0.02,
								" its": 0.017,
								" one": 0.015,
								" not": 0.011,
								" situated": 0.011,
								" an": 0.009
							}
						},
						{
							"p": " the",
							"b": 0.311,
							"k": {
								" the": 0.311,
								" home": 0.093,
								" also": 0.082,
								" a": 0.071,
								" not": 0.022,
								" one": 0.02,
								" its": 0.018,
								" London": 0.013,
								" an": 0.012,
								" where": 0.01
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.001,
									0.752,
									0.003,
									0.078,
									0.008,
									0.906,
									0.046,
									0.074,
									0.959,
									0.351,
									1.0
								],
								"e": 0.3
							},
							{
								"h": [
									0.205,
									0.148,
									0.47,
									1.0,
									1.0,
									0.649,
									1.0,
									1.0,
									1.0,
									1.0,
									0.012,
									0.048
								],
								"e": 1.0
							},
							{
								"h": [
									0.948,
									1.0,
									0.098,
									0.226,
									1.0,
									0.207,
									0.337,
									0.359,
									0.491,
									0.043,
									1.0,
									1.0
								],
								"e": 0.907
							},
							{
								"h": [
									1.0,
									1.0,
									0.28,
									1.0,
									1.0,
									1.0,
									0.132,
									0.192,
									0.422,
									1.0,
									1.0,
									0.735
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.165,
									1.0,
									1.0,
									1.0,
									0.879,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.191,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.36,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.71,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.565,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.94,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.176,
									0.009,
									0.351,
									0.0,
									0.02,
									0.006,
									0.039,
									0.019,
									0.313,
									0.893,
									0.384,
									0.492
								],
								"e": 0.14
							},
							{
								"h": [
									0.05,
									0.065,
									0.163,
									0.041,
									0.144,
									0.369,
									0.218,
									0.187,
									0.405,
									0.122,
									0.239,
									0.023
								],
								"e": 0.226
							},
							{
								"h": [
									0.059,
									0.031,
									0.0,
									0.008,
									0.049,
									0.025,
									0.013,
									0.02,
									0.074,
									0.001,
									0.096,
									0.008
								],
								"e": 0.042
							},
							{
								"h": [
									0.009,
									0.022,
									0.01,
									0.001,
									0.011,
									0.056,
									0.006,
									0.001,
									0.023,
									0.009,
									0.025,
									0.008
								],
								"e": 0.018
							},
							{
								"h": [
									0.003,
									0.012,
									0.091,
									0.002,
									0.043,
									0.009,
									0.025,
									0.043,
									0.011,
									0.007,
									0.029,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									0.006,
									0.0,
									0.003,
									0.079,
									0.004,
									0.083,
									0.003,
									0.005,
									0.013,
									0.049,
									0.038,
									0.074
								],
								"e": 0.025
							},
							{
								"h": [
									0.008,
									0.036,
									0.026,
									0.027,
									0.025,
									0.021,
									0.016,
									0.096,
									0.004,
									0.0,
									0.002,
									0.005
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.002,
									0.0,
									0.006,
									0.001,
									0.014,
									0.009,
									0.024,
									0.004,
									0.003,
									0.0,
									0.005
								],
								"e": 0.005
							},
							{
								"h": [
									0.015,
									0.006,
									0.024,
									0.006,
									0.002,
									0.007,
									0.026,
									0.0,
									0.09,
									0.023,
									0.006,
									0.01
								],
								"e": 0.016
							},
							{
								"h": [
									0.002,
									0.001,
									0.018,
									0.006,
									0.006,
									0.009,
									0.001,
									0.015,
									0.022,
									0.0,
									0.002,
									0.005
								],
								"e": 0.006
							},
							{
								"h": [
									0.008,
									0.003,
									0.035,
									0.099,
									0.015,
									0.008,
									0.01,
									0.023,
									0.004,
									0.006,
									0.003,
									0.043
								],
								"e": 0.017
							},
							{
								"h": [
									0.549,
									0.046,
									0.12,
									0.153,
									0.059,
									0.011,
									0.014,
									0.031,
									0.222,
									0.012,
									0.194,
									0.078
								],
								"e": 0.076
							}
						],
						[
							{
								"h": [
									0.202,
									0.0,
									0.216,
									0.0,
									0.02,
									0.001,
									0.702,
									0.009,
									0.029,
									0.516,
									0.151,
									0.264
								],
								"e": 0.111
							},
							{
								"h": [
									0.019,
									0.023,
									0.186,
									0.044,
									0.154,
									0.03,
									0.059,
									0.169,
									0.245,
									0.1,
									0.326,
									0.001
								],
								"e": 0.138
							},
							{
								"h": [
									0.06,
									0.036,
									0.0,
									0.011,
									0.006,
									0.004,
									0.014,
									0.092,
									0.004,
									0.005,
									0.147,
									0.015
								],
								"e": 0.039
							},
							{
								"h": [
									0.0,
									0.009,
									0.01,
									0.0,
									0.01,
									0.046,
									0.028,
									0.003,
									0.005,
									0.002,
									0.015,
									0.014
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.003,
									0.097,
									0.004,
									0.01,
									0.003,
									0.021,
									0.05,
									0.04,
									0.011,
									0.006,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.004,
									0.001,
									0.001,
									0.04,
									0.006,
									0.011,
									0.005,
									0.001,
									0.005,
									0.018,
									0.023,
									0.009
								],
								"e": 0.009
							},
							{
								"h": [
									0.012,
									0.021,
									0.011,
									0.012,
									0.019,
									0.034,
									0.006,
									0.076,
									0.003,
									0.002,
									0.009,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.01,
									0.001,
									0.008,
									0.001,
									0.013,
									0.02,
									0.012,
									0.01,
									0.001,
									0.0,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.012,
									0.006,
									0.076,
									0.002,
									0.001,
									0.014,
									0.03,
									0.001,
									0.022,
									0.015,
									0.012,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.003,
									0.002,
									0.016,
									0.022,
									0.003,
									0.009,
									0.001,
									0.017,
									0.025,
									0.0,
									0.001,
									0.01
								],
								"e": 0.008
							},
							{
								"h": [
									0.024,
									0.006,
									0.071,
									0.065,
									0.01,
									0.002,
									0.033,
									0.059,
									0.001,
									0.003,
									0.008,
									0.016
								],
								"e": 0.022
							},
							{
								"h": [
									0.276,
									0.019,
									0.155,
									0.028,
									0.048,
									0.01,
									0.012,
									0.015,
									0.026,
									0.009,
									0.143,
									0.017
								],
								"e": 0.042
							}
						],
						[
							{
								"h": [
									0.18,
									0.925,
									0.762,
									0.002,
									0.055,
									1.0,
									0.082,
									0.019,
									0.526,
									0.842,
									1.0,
									0.398
								],
								"e": 0.519
							},
							{
								"h": [
									0.071,
									0.029,
									0.367,
									0.067,
									0.169,
									1.0,
									0.165,
									0.245,
									0.358,
									0.246,
									0.399,
									0.472
								],
								"e": 0.452
							},
							{
								"h": [
									0.036,
									0.054,
									0.0,
									0.003,
									0.005,
									0.002,
									0.022,
									0.129,
									0.015,
									0.0,
									0.205,
									0.018
								],
								"e": 0.047
							},
							{
								"h": [
									0.093,
									0.009,
									0.001,
									0.0,
									0.038,
									0.08,
									0.003,
									0.0,
									0.007,
									0.008,
									0.056,
									0.009
								],
								"e": 0.037
							},
							{
								"h": [
									0.001,
									0.003,
									0.043,
									0.001,
									0.065,
									0.001,
									0.009,
									0.093,
									0.031,
									0.002,
									0.023,
									0.0
								],
								"e": 0.023
							},
							{
								"h": [
									0.001,
									0.0,
									0.003,
									0.069,
									0.002,
									0.007,
									0.001,
									0.002,
									0.005,
									0.051,
									0.03,
									0.118
								],
								"e": 0.018
							},
							{
								"h": [
									0.011,
									0.017,
									0.027,
									0.021,
									0.026,
									0.012,
									0.048,
									0.019,
									0.002,
									0.0,
									0.002,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.002,
									0.0,
									0.009,
									0.002,
									0.012,
									0.007,
									0.026,
									0.002,
									0.003,
									0.001,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.028,
									0.011,
									0.031,
									0.016,
									0.002,
									0.013,
									0.026,
									0.004,
									0.057,
									0.055,
									0.011,
									0.021
								],
								"e": 0.022
							},
							{
								"h": [
									0.019,
									0.03,
									0.03,
									0.017,
									0.008,
									0.023,
									0.003,
									0.014,
									0.029,
									0.002,
									0.007,
									0.011
								],
								"e": 0.015
							},
							{
								"h": [
									0.009,
									0.01,
									0.032,
									0.131,
									0.057,
									0.014,
									0.01,
									0.047,
									0.016,
									0.027,
									0.005,
									0.011
								],
								"e": 0.025
							},
							{
								"h": [
									0.8,
									0.057,
									0.033,
									0.081,
									0.057,
									0.023,
									0.014,
									0.129,
									0.856,
									0.024,
									0.174,
									0.158
								],
								"e": 0.112
							}
						],
						[
							{
								"h": [
									0.149,
									0.001,
									0.225,
									0.001,
									0.033,
									0.003,
									0.026,
									0.038,
									0.569,
									0.794,
									0.367,
									0.215
								],
								"e": 0.13
							},
							{
								"h": [
									0.159,
									0.067,
									0.352,
									0.059,
									0.156,
									0.259,
									0.205,
									0.222,
									0.366,
									0.387,
									0.332,
									0.004
								],
								"e": 0.286
							},
							{
								"h": [
									0.071,
									0.03,
									0.003,
									0.079,
									0.034,
									0.018,
									0.039,
									0.13,
									0.047,
									0.002,
									0.168,
									0.022
								],
								"e": 0.071
							},
							{
								"h": [
									0.011,
									0.049,
									0.024,
									0.001,
									0.013,
									0.102,
									0.007,
									0.001,
									0.004,
									0.003,
									0.037,
									0.008
								],
								"e": 0.025
							},
							{
								"h": [
									0.0,
									0.012,
									0.046,
									0.0,
									0.026,
									0.006,
									0.026,
									0.017,
									0.033,
									0.012,
									0.028,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.168,
									0.014,
									0.003,
									0.065,
									0.003,
									0.247,
									0.0,
									0.001,
									0.503,
									0.039,
									0.013,
									0.067
								],
								"e": 0.084
							},
							{
								"h": [
									0.004,
									0.062,
									0.027,
									0.033,
									0.033,
									0.012,
									0.016,
									0.018,
									0.002,
									0.261,
									0.005,
									0.002
								],
								"e": 0.042
							},
							{
								"h": [
									0.0,
									0.004,
									0.007,
									0.006,
									0.001,
									0.019,
									0.007,
									0.028,
									0.001,
									0.005,
									0.122,
									0.131
								],
								"e": 0.029
							},
							{
								"h": [
									0.02,
									0.522,
									0.024,
									0.047,
									0.008,
									0.008,
									0.238,
									0.001,
									0.059,
									0.041,
									0.024,
									0.005
								],
								"e": 0.064
							},
							{
								"h": [
									0.006,
									0.037,
									0.044,
									0.003,
									0.013,
									0.01,
									0.013,
									0.024,
									0.009,
									0.005,
									0.007,
									0.017
								],
								"e": 0.016
							},
							{
								"h": [
									0.007,
									0.023,
									0.071,
									0.164,
									0.055,
									0.016,
									0.01,
									0.026,
									0.068,
									0.007,
									0.006,
									0.015
								],
								"e": 0.035
							},
							{
								"h": [
									1.0,
									0.135,
									0.037,
									0.015,
									0.039,
									0.052,
									0.012,
									0.308,
									0.145,
									0.029,
									0.067,
									0.017
								],
								"e": 0.09
							}
						],
						[
							{
								"h": [
									0.315,
									0.0,
									0.196,
									0.0,
									0.087,
									0.001,
									0.851,
									0.03,
									0.054,
									0.494,
									0.184,
									0.247
								],
								"e": 0.141
							},
							{
								"h": [
									0.252,
									0.029,
									0.174,
									0.051,
									0.223,
									0.04,
									0.214,
									0.255,
									0.148,
									0.053,
									0.399,
									0.001
								],
								"e": 0.199
							},
							{
								"h": [
									0.076,
									0.156,
									0.008,
									0.013,
									0.02,
									0.014,
									0.018,
									0.211,
									0.021,
									0.018,
									0.272,
									0.029
								],
								"e": 0.095
							},
							{
								"h": [
									0.0,
									0.022,
									0.002,
									0.002,
									0.003,
									0.133,
									0.008,
									0.012,
									0.002,
									0.002,
									0.02,
									0.125
								],
								"e": 0.028
							},
							{
								"h": [
									0.0,
									0.004,
									0.036,
									0.006,
									0.025,
									0.003,
									0.047,
									0.012,
									0.042,
									0.032,
									0.013,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.043,
									0.004,
									0.005,
									0.048,
									0.015,
									0.075,
									0.0,
									0.01,
									0.103,
									0.07,
									0.203,
									0.159
								],
								"e": 0.053
							},
							{
								"h": [
									0.022,
									0.058,
									0.012,
									0.042,
									0.012,
									0.021,
									0.016,
									0.153,
									0.004,
									0.011,
									0.025,
									0.002
								],
								"e": 0.027
							},
							{
								"h": [
									0.001,
									0.015,
									0.002,
									0.013,
									0.002,
									0.019,
									0.011,
									0.11,
									0.008,
									0.003,
									0.045,
									0.008
								],
								"e": 0.016
							},
							{
								"h": [
									0.01,
									0.256,
									0.04,
									0.013,
									0.009,
									0.025,
									1.0,
									0.006,
									0.203,
									0.058,
									0.043,
									0.004
								],
								"e": 0.096
							},
							{
								"h": [
									0.023,
									0.004,
									0.03,
									0.012,
									0.024,
									0.011,
									0.008,
									0.069,
									0.017,
									0.002,
									0.014,
									0.004
								],
								"e": 0.017
							},
							{
								"h": [
									0.015,
									0.045,
									0.035,
									0.08,
									0.038,
									0.014,
									0.05,
									0.173,
									0.003,
									0.024,
									0.007,
									0.095
								],
								"e": 0.042
							},
							{
								"h": [
									0.916,
									0.034,
									0.011,
									0.112,
									0.01,
									0.007,
									0.005,
									0.015,
									0.086,
									0.011,
									0.122,
									0.039
								],
								"e": 0.05
							}
						],
						[
							{
								"h": [
									0.108,
									0.004,
									0.646,
									0.003,
									0.098,
									0.001,
									0.046,
									0.058,
									0.29,
									0.89,
									0.393,
									0.238
								],
								"e": 0.145
							},
							{
								"h": [
									0.189,
									0.049,
									0.425,
									0.058,
									0.151,
									0.112,
									0.148,
									0.194,
									0.182,
									0.072,
									0.315,
									0.009
								],
								"e": 0.201
							},
							{
								"h": [
									0.377,
									0.05,
									0.005,
									0.039,
									0.018,
									0.023,
									0.065,
									0.155,
									0.172,
									0.003,
									0.158,
									0.022
								],
								"e": 0.113
							},
							{
								"h": [
									0.001,
									0.005,
									0.01,
									0.002,
									0.004,
									0.126,
									0.003,
									0.002,
									0.018,
									0.003,
									0.009,
									0.021
								],
								"e": 0.018
							},
							{
								"h": [
									0.007,
									0.003,
									0.017,
									0.011,
									0.01,
									0.002,
									0.049,
									0.026,
									0.127,
									0.03,
									0.045,
									0.0
								],
								"e": 0.032
							},
							{
								"h": [
									0.009,
									0.0,
									0.004,
									0.028,
									0.008,
									0.007,
									0.0,
									0.008,
									0.018,
									0.028,
									0.131,
									0.131
								],
								"e": 0.025
							},
							{
								"h": [
									0.011,
									0.055,
									0.025,
									0.041,
									0.011,
									0.021,
									0.007,
									0.066,
									0.005,
									0.0,
									0.02,
									0.004
								],
								"e": 0.02
							},
							{
								"h": [
									0.001,
									0.011,
									0.0,
									0.005,
									0.003,
									0.027,
									0.013,
									0.142,
									0.002,
									0.002,
									0.007,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.025,
									0.158,
									0.03,
									0.014,
									0.007,
									0.021,
									0.202,
									0.007,
									0.056,
									0.026,
									0.037,
									0.008
								],
								"e": 0.039
							},
							{
								"h": [
									0.008,
									0.009,
									0.04,
									0.008,
									0.023,
									0.016,
									0.004,
									0.027,
									0.01,
									0.001,
									0.009,
									0.004
								],
								"e": 0.012
							},
							{
								"h": [
									0.014,
									0.017,
									0.027,
									0.043,
									0.037,
									0.012,
									0.014,
									0.063,
									0.032,
									0.013,
									0.003,
									0.005
								],
								"e": 0.021
							},
							{
								"h": [
									0.52,
									0.034,
									0.048,
									0.095,
									0.036,
									0.038,
									0.011,
									0.076,
									0.308,
									0.039,
									0.135,
									0.045
								],
								"e": 0.069
							}
						],
						[
							{
								"h": [
									0.16,
									0.001,
									0.214,
									0.012,
									0.079,
									0.002,
									0.028,
									0.132,
									0.724,
									0.813,
									0.345,
									0.208
								],
								"e": 0.157
							},
							{
								"h": [
									0.263,
									0.094,
									0.418,
									0.067,
									0.182,
									0.199,
									0.154,
									0.215,
									0.308,
									0.322,
									0.398,
									0.004
								],
								"e": 0.29
							},
							{
								"h": [
									0.07,
									0.03,
									0.012,
									0.057,
									0.029,
									0.042,
									0.041,
									0.175,
									0.059,
									0.003,
									0.207,
									0.031
								],
								"e": 0.082
							},
							{
								"h": [
									0.006,
									0.023,
									0.018,
									0.0,
									0.006,
									0.067,
									0.002,
									0.001,
									0.001,
									0.001,
									0.027,
									0.003
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.002,
									0.008,
									0.0,
									0.011,
									0.002,
									0.023,
									0.004,
									0.049,
									0.014,
									0.03,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.007,
									0.0,
									0.002,
									0.013,
									0.002,
									0.003,
									0.0,
									0.001,
									0.016,
									0.023,
									0.023,
									0.091
								],
								"e": 0.012
							},
							{
								"h": [
									0.003,
									0.007,
									0.019,
									0.034,
									0.011,
									0.008,
									0.006,
									0.036,
									0.002,
									0.0,
									0.007,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.007,
									0.0,
									0.002,
									0.004,
									0.009,
									0.007,
									0.07,
									0.001,
									0.002,
									0.003,
									0.008
								],
								"e": 0.007
							},
							{
								"h": [
									0.023,
									0.031,
									0.022,
									0.012,
									0.005,
									0.005,
									0.053,
									0.001,
									0.027,
									0.018,
									0.021,
									0.003
								],
								"e": 0.017
							},
							{
								"h": [
									0.002,
									0.009,
									0.057,
									0.002,
									0.011,
									0.011,
									0.003,
									0.024,
									0.012,
									0.001,
									0.004,
									0.004
								],
								"e": 0.01
							},
							{
								"h": [
									0.009,
									0.004,
									0.025,
									0.047,
									0.02,
									0.015,
									0.007,
									0.038,
									0.015,
									0.004,
									0.003,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.534,
									0.043,
									0.049,
									0.024,
									0.042,
									0.026,
									0.008,
									0.043,
									0.101,
									0.018,
									0.081,
									0.011
								],
								"e": 0.045
							}
						],
						[
							{
								"h": [
									0.192,
									0.0,
									0.452,
									0.006,
									0.122,
									0.0,
									1.0,
									0.129,
									0.103,
									0.582,
									0.12,
									0.405
								],
								"e": 0.176
							},
							{
								"h": [
									0.07,
									0.055,
									0.316,
									0.061,
									0.228,
									0.031,
									0.073,
									0.153,
									0.179,
									0.133,
									0.455,
									0.001
								],
								"e": 0.181
							},
							{
								"h": [
									0.098,
									0.056,
									0.005,
									0.012,
									0.001,
									0.008,
									0.011,
									0.24,
									0.007,
									0.011,
									0.232,
									0.032
								],
								"e": 0.07
							},
							{
								"h": [
									0.0,
									0.004,
									0.002,
									0.0,
									0.003,
									0.046,
									0.015,
									0.003,
									0.001,
									0.0,
									0.021,
									0.004
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.001,
									0.017,
									0.002,
									0.011,
									0.002,
									0.03,
									0.006,
									0.036,
									0.003,
									0.006,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.002,
									0.0,
									0.003,
									0.015,
									0.003,
									0.002,
									0.0,
									0.0,
									0.007,
									0.045,
									0.012,
									0.015
								],
								"e": 0.008
							},
							{
								"h": [
									0.004,
									0.002,
									0.008,
									0.015,
									0.005,
									0.01,
									0.003,
									0.022,
									0.003,
									0.0,
									0.01,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.006,
									0.0,
									0.002,
									0.001,
									0.004,
									0.006,
									0.012,
									0.001,
									0.001,
									0.0,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.008,
									0.005,
									0.029,
									0.005,
									0.001,
									0.014,
									0.013,
									0.0,
									0.036,
									0.007,
									0.013,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.002,
									0.001,
									0.009,
									0.002,
									0.004,
									0.003,
									0.001,
									0.039,
									0.019,
									0.002,
									0.002,
									0.004
								],
								"e": 0.006
							},
							{
								"h": [
									0.011,
									0.002,
									0.019,
									0.045,
									0.007,
									0.012,
									0.013,
									0.049,
									0.001,
									0.004,
									0.005,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.337,
									0.007,
									0.042,
									0.005,
									0.046,
									0.003,
									0.006,
									0.012,
									0.035,
									0.002,
									0.025,
									0.004
								],
								"e": 0.022
							}
						],
						[
							{
								"h": [
									0.199,
									0.001,
									0.263,
									0.003,
									0.07,
									0.001,
									0.721,
									0.16,
									0.056,
									0.384,
									0.146,
									0.198
								],
								"e": 0.128
							},
							{
								"h": [
									0.083,
									0.082,
									0.428,
									0.076,
									0.271,
									0.053,
									0.168,
									0.364,
									0.213,
									0.087,
									0.61,
									0.009
								],
								"e": 0.252
							},
							{
								"h": [
									0.126,
									0.078,
									0.015,
									0.009,
									0.002,
									0.059,
									0.034,
									0.297,
									0.029,
									0.026,
									0.382,
									0.049
								],
								"e": 0.114
							},
							{
								"h": [
									0.003,
									0.01,
									0.002,
									0.001,
									0.006,
									0.046,
									0.015,
									0.013,
									0.008,
									0.001,
									0.032,
									0.016
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.003,
									0.01,
									0.003,
									0.049,
									0.003,
									0.024,
									0.012,
									0.09,
									0.005,
									0.009,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.003,
									0.0,
									0.001,
									0.02,
									0.008,
									0.001,
									0.0,
									0.001,
									0.002,
									0.06,
									0.034,
									0.024
								],
								"e": 0.011
							},
							{
								"h": [
									0.013,
									0.005,
									0.027,
									0.023,
									0.021,
									0.023,
									0.009,
									0.078,
									0.004,
									0.0,
									0.027,
									0.002
								],
								"e": 0.017
							},
							{
								"h": [
									0.001,
									0.006,
									0.0,
									0.009,
									0.001,
									0.038,
									0.026,
									0.184,
									0.001,
									0.002,
									0.0,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.011,
									0.063,
									0.063,
									0.005,
									0.004,
									0.042,
									0.124,
									0.005,
									0.122,
									0.053,
									0.025,
									0.007
								],
								"e": 0.035
							},
							{
								"h": [
									0.006,
									0.008,
									0.035,
									0.023,
									0.006,
									0.007,
									0.001,
									0.126,
									0.05,
									0.001,
									0.007,
									0.007
								],
								"e": 0.019
							},
							{
								"h": [
									0.025,
									0.004,
									0.033,
									0.139,
									0.076,
									0.012,
									0.025,
									0.092,
									0.004,
									0.015,
									0.006,
									0.015
								],
								"e": 0.03
							},
							{
								"h": [
									0.577,
									0.025,
									0.061,
									0.061,
									0.028,
									0.007,
									0.003,
									0.096,
									0.054,
									0.005,
									0.159,
									0.03
								],
								"e": 0.049
							}
						],
						[
							{
								"h": [
									0.154,
									0.0,
									1.0,
									0.036,
									0.127,
									0.001,
									0.048,
									1.0,
									0.633,
									1.0,
									0.344,
									0.553
								],
								"e": 0.303
							},
							{
								"h": [
									0.585,
									1.0,
									0.844,
									0.138,
									0.297,
									0.41,
									0.367,
									0.507,
									0.483,
									0.1,
									0.826,
									0.073
								],
								"e": 0.696
							},
							{
								"h": [
									0.352,
									0.059,
									0.505,
									0.261,
									0.75,
									1.0,
									1.0,
									0.378,
									0.971,
									0.102,
									0.555,
									0.08
								],
								"e": 0.81
							},
							{
								"h": [
									0.005,
									0.74,
									0.184,
									0.109,
									0.023,
									0.236,
									0.169,
									0.056,
									1.0,
									0.175,
									0.129,
									0.433
								],
								"e": 0.295
							},
							{
								"h": [
									0.054,
									0.736,
									0.161,
									0.033,
									0.052,
									0.09,
									0.144,
									0.176,
									0.123,
									0.054,
									0.057,
									0.0
								],
								"e": 0.143
							},
							{
								"h": [
									0.093,
									0.0,
									0.017,
									0.731,
									0.064,
									0.099,
									0.001,
									0.025,
									0.184,
									0.09,
									0.078,
									0.08
								],
								"e": 0.093
							},
							{
								"h": [
									0.04,
									0.074,
									0.077,
									0.145,
									0.182,
									0.081,
									0.036,
									0.066,
									0.006,
									0.016,
									0.038,
									0.158
								],
								"e": 0.077
							},
							{
								"h": [
									0.02,
									0.036,
									0.0,
									0.048,
									0.014,
									0.184,
									0.027,
									0.762,
									0.002,
									0.101,
									0.016,
									0.007
								],
								"e": 0.071
							},
							{
								"h": [
									0.066,
									0.139,
									0.104,
									0.068,
									0.1,
									0.225,
									0.254,
									0.042,
									0.22,
									0.088,
									0.102,
									0.036
								],
								"e": 0.108
							},
							{
								"h": [
									0.011,
									0.022,
									0.116,
									0.347,
									0.054,
									0.017,
									0.004,
									0.118,
									0.049,
									0.004,
									0.084,
									0.016
								],
								"e": 0.055
							},
							{
								"h": [
									0.021,
									0.007,
									0.037,
									0.328,
									0.101,
									0.201,
									0.008,
									0.062,
									0.021,
									0.218,
									0.012,
									0.062
								],
								"e": 0.076
							},
							{
								"h": [
									0.491,
									0.073,
									0.125,
									0.177,
									0.078,
									0.023,
									0.019,
									0.092,
									1.0,
									0.061,
									0.576,
									0.212
								],
								"e": 0.156
							}
						],
						[
							{
								"h": [
									0.356,
									0.001,
									0.567,
									0.016,
									0.342,
									0.001,
									0.92,
									0.765,
									0.038,
									0.624,
									0.132,
									0.264
								],
								"e": 0.269
							},
							{
								"h": [
									0.473,
									0.194,
									0.629,
									0.112,
									0.473,
									0.08,
									0.126,
									0.407,
									0.252,
									0.136,
									0.985,
									0.006
								],
								"e": 0.408
							},
							{
								"h": [
									1.0,
									0.108,
									1.0,
									1.0,
									0.324,
									0.395,
									0.111,
									0.709,
									0.294,
									1.0,
									0.907,
									0.126
								],
								"e": 1.0
							},
							{
								"h": [
									0.001,
									0.306,
									1.0,
									0.192,
									0.011,
									0.161,
									1.0,
									1.0,
									0.137,
									0.061,
									0.109,
									1.0
								],
								"e": 0.534
							},
							{
								"h": [
									0.077,
									0.169,
									0.105,
									1.0,
									0.096,
									0.269,
									0.398,
									0.55,
									0.058,
									0.091,
									0.01,
									1.0
								],
								"e": 0.435
							},
							{
								"h": [
									0.012,
									0.001,
									0.095,
									0.355,
									0.184,
									0.007,
									0.278,
									0.048,
									0.05,
									0.078,
									1.0,
									0.054
								],
								"e": 0.165
							},
							{
								"h": [
									0.859,
									0.248,
									0.052,
									0.064,
									0.027,
									0.207,
									0.052,
									0.747,
									0.436,
									0.001,
									0.01,
									0.094
								],
								"e": 0.188
							},
							{
								"h": [
									0.097,
									0.018,
									0.004,
									0.376,
									0.074,
									0.09,
									0.024,
									0.174,
									1.0,
									0.039,
									0.001,
									0.012
								],
								"e": 0.146
							},
							{
								"h": [
									0.037,
									0.059,
									0.062,
									0.01,
									0.129,
									0.565,
									0.048,
									0.429,
									0.248,
									0.219,
									0.166,
									0.149
								],
								"e": 0.166
							},
							{
								"h": [
									0.06,
									0.016,
									0.157,
									0.535,
									0.007,
									0.065,
									0.01,
									0.096,
									0.377,
									0.006,
									0.186,
									0.011
								],
								"e": 0.1
							},
							{
								"h": [
									0.289,
									0.029,
									0.074,
									0.525,
									0.047,
									0.162,
									0.151,
									1.0,
									0.003,
									0.184,
									0.127,
									0.38
								],
								"e": 0.206
							},
							{
								"h": [
									0.539,
									0.055,
									0.217,
									0.197,
									0.085,
									0.018,
									0.042,
									0.065,
									0.291,
									0.018,
									0.92,
									0.154
								],
								"e": 0.14
							}
						],
						[
							{
								"h": [
									0.251,
									1.0,
									0.821,
									1.0,
									1.0,
									0.689,
									0.12,
									0.919,
									1.0,
									0.957,
									0.96,
									0.396
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.246,
									1.0,
									0.141,
									0.39,
									0.929,
									0.14,
									0.393,
									0.361,
									0.233,
									1.0,
									1.0
								],
								"e": 0.888
							},
							{
								"h": [
									0.507,
									0.053,
									0.289,
									0.224,
									0.348,
									0.231,
									0.158,
									1.0,
									1.0,
									0.032,
									0.959,
									0.115
								],
								"e": 0.527
							},
							{
								"h": [
									0.05,
									0.3,
									0.153,
									0.084,
									0.049,
									0.138,
									0.11,
									0.079,
									0.505,
									0.203,
									0.261,
									0.265
								],
								"e": 0.218
							},
							{
								"h": [
									0.081,
									0.145,
									0.042,
									0.029,
									0.112,
									0.049,
									0.129,
									1.0,
									0.07,
									0.026,
									0.021,
									0.0
								],
								"e": 0.124
							},
							{
								"h": [
									0.016,
									0.0,
									0.049,
									0.287,
									0.03,
									0.092,
									0.031,
									0.013,
									0.014,
									0.066,
									0.067,
									0.359
								],
								"e": 0.068
							},
							{
								"h": [
									0.103,
									0.021,
									0.075,
									0.063,
									0.05,
									0.143,
									0.141,
									0.086,
									0.028,
									0.003,
									0.017,
									0.067
								],
								"e": 0.063
							},
							{
								"h": [
									0.011,
									0.005,
									0.001,
									0.151,
									0.057,
									0.05,
									0.016,
									0.081,
									0.025,
									0.035,
									0.001,
									0.01
								],
								"e": 0.033
							},
							{
								"h": [
									0.05,
									0.025,
									0.04,
									0.028,
									0.028,
									0.205,
									0.029,
									0.144,
									0.173,
									0.177,
									0.073,
									0.066
								],
								"e": 0.082
							},
							{
								"h": [
									0.03,
									0.043,
									0.044,
									0.091,
									0.002,
									0.06,
									0.005,
									0.014,
									0.055,
									0.001,
									0.104,
									0.01
								],
								"e": 0.034
							},
							{
								"h": [
									0.009,
									0.024,
									0.038,
									0.232,
									0.081,
									0.067,
									0.012,
									0.059,
									0.022,
									0.087,
									0.008,
									0.063
								],
								"e": 0.049
							},
							{
								"h": [
									0.461,
									0.063,
									0.036,
									0.089,
									0.044,
									0.015,
									0.007,
									0.147,
									0.813,
									0.029,
									0.172,
									0.091
								],
								"e": 0.098
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " the",
					"o": " capital",
					"t": " capital",
					"r": [
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.819,
								" most": 0.017,
								" latter": 0.017,
								" first": 0.013,
								" way": 0.007,
								" world": 0.006,
								" fastest": 0.006,
								" very": 0.005,
								" following": 0.004,
								" majority": 0.004
							}
						},
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.871,
								" most": 0.021,
								" latter": 0.019,
								" first": 0.011,
								" fastest": 0.008,
								" following": 0.005,
								" world": 0.004,
								" very": 0.003,
								" best": 0.003,
								" vast": 0.003
							}
						},
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.86,
								" most": 0.03,
								" fastest": 0.013,
								" first": 0.009,
								" best": 0.007,
								" very": 0.006,
								" latest": 0.006,
								" following": 0.005,
								" largest": 0.005,
								" vast": 0.004
							}
						},
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.567,
								" most": 0.118,
								" largest": 0.096,
								" fastest": 0.04,
								" best": 0.022,
								" first": 0.019,
								" very": 0.014,
								" biggest": 0.014,
								" latest": 0.009,
								" easiest": 0.009
							}
						},
						{
							"p": " largest",
							"b": 0.0,
							"k": {
								" largest": 0.433,
								" most": 0.134,
								" same": 0.113,
								" biggest": 0.061,
								" first": 0.052,
								" fastest": 0.038,
								" best": 0.024,
								" oldest": 0.016,
								" second": 0.01,
								" main": 0.01
							}
						},
						{
							"p": " largest",
							"b": 0.0,
							"k": {
								" largest": 0.331,
								" most": 0.219,
								" same": 0.198,
								" oldest": 0.05,
								" first": 0.026,
								" biggest": 0.023,
								" fastest": 0.023,
								" best": 0.017,
								" highest": 0.015,
								" latest": 0.01
							}
						},
						{
							"p": " largest",
							"b": 0.0,
							"k": {
								" largest": 0.538,
								" same": 0.176,
								" most": 0.067,
								" first": 0.049,
								" second": 0.032,
								" biggest": 0.014,
								" oldest": 0.011,
								" best": 0.01,
								" center": 0.008,
								" highest": 0.008
							}
						},
						{
							"p": " largest",
							"b": 0.007,
							"k": {
								" largest": 0.67,
								" same": 0.063,
								" most": 0.061,
								" first": 0.029,
								" center": 0.019,
								" second": 0.016,
								" highest": 0.012,
								" centerpiece": 0.011,
								" oldest": 0.009,
								" third": 0.007
							}
						},
						{
							"p": " largest",
							"b": 0.034,
							"k": {
								" largest": 0.893,
								" birthplace": 0.047,
								" capital": 0.034,
								" most": 0.008,
								" biggest": 0.003,
								" second": 0.001,
								" main": 0.001,
								" city": 0.001,
								" center": 0.001,
								" central": 0.001
							}
						},
						{
							"p": " largest",
							"b": 0.027,
							"k": {
								" largest": 0.923,
								" birthplace": 0.037,
								" capital": 0.027,
								" richest": 0.003,
								" most": 0.001,
								" city": 0.001,
								" biggest": 0.001,
								" highest": 0.001,
								" tallest": 0.001,
								"!": 0.0
							}
						},
						{
							"p": " capital",
							"b": 0.547,
							"k": {
								" capital": 0.547,
								" largest": 0.353,
								" birthplace": 0.026,
								" city": 0.012,
								" headquarters": 0.01,
								" most": 0.005,
								" biggest": 0.005,
								" busiest": 0.005,
								" country": 0.003,
								" centre": 0.003
							}
						},
						{
							"p": " capital",
							"b": 0.519,
							"k": {
								" capital": 0.519,
								" largest": 0.043,
								" second": 0.029,
								" city": 0.029,
								" most": 0.026,
								" home": 0.013,
								" world": 0.011,
								" third": 0.01,
								" country": 0.009,
								" center": 0.009
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.002,
									1.0,
									0.007,
									0.122,
									0.115,
									1.0,
									0.063,
									0.021,
									1.0,
									0.874,
									1.0
								],
								"e": 0.529
							},
							{
								"h": [
									0.083,
									0.149,
									0.537,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.015,
									0.1
								],
								"e": 1.0
							},
							{
								"h": [
									0.757,
									1.0,
									0.028,
									0.303,
									0.917,
									0.294,
									0.314,
									0.345,
									0.085,
									0.123,
									0.95,
									1.0
								],
								"e": 0.815
							},
							{
								"h": [
									1.0,
									1.0,
									0.351,
									1.0,
									1.0,
									1.0,
									0.386,
									0.502,
									0.437,
									0.998,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.939,
									1.0,
									0.165,
									1.0,
									1.0,
									1.0,
									0.897,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.426,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.144,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.343,
									1.0,
									1.0,
									1.0,
									1.0,
									0.173,
									0.863,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.356,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.504,
									1.0,
									1.0,
									1.0,
									0.501
								],
								"e": 1.0
							},
							{
								"h": [
									0.887,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.166,
									0.02,
									0.505,
									0.001,
									0.026,
									0.206,
									0.011,
									0.017,
									0.183,
									0.711,
									0.576,
									0.453
								],
								"e": 0.218
							},
							{
								"h": [
									0.013,
									0.056,
									0.186,
									0.036,
									0.146,
									0.704,
									0.18,
									0.168,
									0.41,
									0.116,
									0.244,
									0.037
								],
								"e": 0.233
							},
							{
								"h": [
									0.042,
									0.03,
									0.0,
									0.007,
									0.021,
									0.011,
									0.015,
									0.02,
									0.003,
									0.001,
									0.079,
									0.007
								],
								"e": 0.025
							},
							{
								"h": [
									0.007,
									0.016,
									0.005,
									0.0,
									0.009,
									0.04,
									0.005,
									0.001,
									0.005,
									0.009,
									0.006,
									0.004
								],
								"e": 0.01
							},
							{
								"h": [
									0.003,
									0.008,
									0.076,
									0.002,
									0.027,
									0.007,
									0.018,
									0.003,
									0.009,
									0.005,
									0.028,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.002,
									0.0,
									0.002,
									0.119,
									0.002,
									0.007,
									0.002,
									0.003,
									0.004,
									0.026,
									0.1,
									0.017
								],
								"e": 0.014
							},
							{
								"h": [
									0.007,
									0.008,
									0.045,
									0.014,
									0.025,
									0.014,
									0.014,
									0.086,
									0.007,
									0.0,
									0.001,
									0.008
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.003,
									0.001,
									0.016,
									0.006,
									0.006,
									0.002,
									0.003,
									0.0,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.017,
									0.002,
									0.018,
									0.004,
									0.003,
									0.013,
									0.002,
									0.002,
									0.117,
									0.022,
									0.006,
									0.018
								],
								"e": 0.017
							},
							{
								"h": [
									0.005,
									0.002,
									0.016,
									0.007,
									0.002,
									0.006,
									0.001,
									0.01,
									0.015,
									0.0,
									0.002,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.012,
									0.003,
									0.011,
									0.074,
									0.008,
									0.003,
									0.024,
									0.027,
									0.005,
									0.004,
									0.004,
									0.051
								],
								"e": 0.014
							},
							{
								"h": [
									0.289,
									0.034,
									0.039,
									0.132,
									0.048,
									0.012,
									0.006,
									0.027,
									0.096,
									0.005,
									0.063,
									0.04
								],
								"e": 0.042
							}
						],
						[
							{
								"h": [
									0.181,
									0.001,
									0.389,
									0.0,
									0.025,
									0.067,
									0.612,
									0.01,
									0.016,
									0.501,
									0.283,
									0.321
								],
								"e": 0.195
							},
							{
								"h": [
									0.005,
									0.023,
									0.219,
									0.041,
									0.176,
									0.102,
									0.067,
									0.134,
									0.241,
									0.1,
									0.331,
									0.003
								],
								"e": 0.136
							},
							{
								"h": [
									0.048,
									0.032,
									0.0,
									0.013,
									0.001,
									0.002,
									0.013,
									0.094,
									0.001,
									0.003,
									0.143,
									0.013
								],
								"e": 0.034
							},
							{
								"h": [
									0.0,
									0.009,
									0.002,
									0.0,
									0.004,
									0.031,
									0.013,
									0.002,
									0.001,
									0.002,
									0.013,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.001,
									0.101,
									0.002,
									0.009,
									0.001,
									0.013,
									0.02,
									0.016,
									0.007,
									0.005,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.044,
									0.002,
									0.003,
									0.002,
									0.001,
									0.001,
									0.013,
									0.036,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.015,
									0.005,
									0.019,
									0.007,
									0.018,
									0.026,
									0.005,
									0.071,
									0.007,
									0.001,
									0.004,
									0.003
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.004,
									0.001,
									0.005,
									0.0,
									0.007,
									0.011,
									0.004,
									0.006,
									0.001,
									0.0,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.017,
									0.0,
									0.152,
									0.001,
									0.002,
									0.012,
									0.001,
									0.002,
									0.026,
									0.015,
									0.007,
									0.004
								],
								"e": 0.021
							},
							{
								"h": [
									0.005,
									0.002,
									0.017,
									0.009,
									0.001,
									0.005,
									0.001,
									0.017,
									0.021,
									0.0,
									0.001,
									0.006
								],
								"e": 0.006
							},
							{
								"h": [
									0.01,
									0.001,
									0.031,
									0.02,
									0.008,
									0.001,
									0.042,
									0.032,
									0.002,
									0.001,
									0.005,
									0.008
								],
								"e": 0.011
							},
							{
								"h": [
									0.179,
									0.011,
									0.069,
									0.025,
									0.044,
									0.007,
									0.012,
									0.033,
									0.018,
									0.002,
									0.048,
									0.013
								],
								"e": 0.028
							}
						],
						[
							{
								"h": [
									0.194,
									0.002,
									0.588,
									0.002,
									0.062,
									0.456,
									0.035,
									0.026,
									0.361,
									0.617,
									0.46,
									0.379
								],
								"e": 0.265
							},
							{
								"h": [
									0.076,
									0.085,
									0.37,
									0.055,
									0.162,
									0.38,
									0.282,
									0.186,
									0.306,
									0.297,
									0.381,
									0.012
								],
								"e": 0.269
							},
							{
								"h": [
									0.021,
									0.068,
									0.0,
									0.006,
									0.015,
									0.007,
									0.021,
									0.132,
									0.016,
									0.001,
									0.203,
									0.018
								],
								"e": 0.051
							},
							{
								"h": [
									0.019,
									0.011,
									0.006,
									0.002,
									0.036,
									0.136,
									0.013,
									0.002,
									0.013,
									0.044,
									0.022,
									0.015
								],
								"e": 0.031
							},
							{
								"h": [
									0.009,
									0.028,
									0.04,
									0.002,
									0.076,
									0.001,
									0.013,
									0.027,
									0.045,
									0.008,
									0.01,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									0.001,
									0.0,
									0.012,
									0.098,
									0.007,
									0.003,
									0.018,
									0.002,
									0.01,
									0.026,
									0.13,
									0.024
								],
								"e": 0.017
							},
							{
								"h": [
									0.032,
									0.008,
									0.029,
									0.007,
									0.011,
									0.01,
									0.051,
									0.028,
									0.03,
									0.0,
									0.004,
									0.01
								],
								"e": 0.017
							},
							{
								"h": [
									0.004,
									0.001,
									0.0,
									0.005,
									0.003,
									0.016,
									0.003,
									0.013,
									0.003,
									0.005,
									0.002,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.021,
									0.003,
									0.041,
									0.01,
									0.007,
									0.034,
									0.001,
									0.022,
									0.051,
									0.029,
									0.008,
									0.037
								],
								"e": 0.023
							},
							{
								"h": [
									0.018,
									0.025,
									0.011,
									0.04,
									0.002,
									0.022,
									0.001,
									0.007,
									0.022,
									0.001,
									0.011,
									0.005
								],
								"e": 0.012
							},
							{
								"h": [
									0.016,
									0.007,
									0.021,
									0.068,
									0.043,
									0.005,
									0.033,
									0.04,
									0.012,
									0.01,
									0.006,
									0.07
								],
								"e": 0.023
							},
							{
								"h": [
									1.0,
									0.063,
									0.027,
									0.161,
									0.06,
									0.017,
									0.006,
									0.056,
									0.962,
									0.011,
									0.199,
									0.175
								],
								"e": 0.127
							}
						],
						[
							{
								"h": [
									0.092,
									0.838,
									0.168,
									0.003,
									0.037,
									1.0,
									0.006,
									0.024,
									0.463,
									0.632,
									1.0,
									0.465
								],
								"e": 0.467
							},
							{
								"h": [
									0.037,
									0.042,
									0.421,
									0.056,
									0.176,
									0.274,
									0.199,
									0.231,
									0.353,
									0.375,
									0.363,
									0.397
								],
								"e": 0.333
							},
							{
								"h": [
									0.02,
									0.047,
									0.0,
									0.009,
									0.01,
									0.001,
									0.033,
									0.131,
									0.001,
									0.0,
									0.163,
									0.018
								],
								"e": 0.042
							},
							{
								"h": [
									0.055,
									0.009,
									0.001,
									0.0,
									0.014,
									0.058,
									0.002,
									0.001,
									0.001,
									0.007,
									0.04,
									0.004
								],
								"e": 0.023
							},
							{
								"h": [
									0.007,
									0.006,
									0.047,
									0.0,
									0.072,
									0.002,
									0.008,
									0.195,
									0.027,
									0.004,
									0.029,
									0.0
								],
								"e": 0.034
							},
							{
								"h": [
									0.004,
									0.0,
									0.0,
									0.032,
									0.001,
									0.009,
									0.002,
									0.001,
									0.007,
									0.027,
									0.071,
									0.093
								],
								"e": 0.016
							},
							{
								"h": [
									0.003,
									0.004,
									0.032,
									0.006,
									0.031,
									0.008,
									0.07,
									0.007,
									0.001,
									0.001,
									0.005,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.003,
									0.0,
									0.007,
									0.003,
									0.004,
									0.0,
									0.003,
									0.002,
									0.008
								],
								"e": 0.003
							},
							{
								"h": [
									0.052,
									0.005,
									0.029,
									0.025,
									0.003,
									0.007,
									0.002,
									0.002,
									0.014,
									0.015,
									0.009,
									0.003
								],
								"e": 0.018
							},
							{
								"h": [
									0.002,
									0.048,
									0.008,
									0.004,
									0.001,
									0.013,
									0.002,
									0.013,
									0.009,
									0.002,
									0.006,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.008,
									0.031,
									0.044,
									0.07,
									0.022,
									0.001,
									0.02,
									0.033,
									0.009,
									0.004,
									0.01,
									0.03
								],
								"e": 0.02
							},
							{
								"h": [
									0.931,
									0.061,
									0.031,
									0.054,
									0.023,
									0.012,
									0.002,
									0.062,
									0.148,
									0.012,
									0.129,
									0.074
								],
								"e": 0.062
							}
						],
						[
							{
								"h": [
									0.275,
									0.0,
									0.266,
									0.001,
									0.065,
									0.036,
									0.607,
									0.03,
									0.033,
									0.33,
									0.313,
									0.268
								],
								"e": 0.195
							},
							{
								"h": [
									0.052,
									0.057,
									0.211,
									0.045,
									0.237,
									0.055,
									0.13,
									0.214,
									0.141,
									0.065,
									0.413,
									0.003
								],
								"e": 0.161
							},
							{
								"h": [
									0.176,
									0.086,
									0.001,
									0.021,
									0.075,
									0.014,
									0.022,
									0.214,
									0.007,
									0.027,
									0.307,
									0.027
								],
								"e": 0.095
							},
							{
								"h": [
									0.001,
									0.01,
									0.003,
									0.003,
									0.004,
									0.101,
									0.03,
									0.027,
									0.005,
									0.045,
									0.032,
									0.181
								],
								"e": 0.036
							},
							{
								"h": [
									0.001,
									0.017,
									0.076,
									0.005,
									0.039,
									0.001,
									0.053,
									0.046,
									0.029,
									0.048,
									0.004,
									0.0
								],
								"e": 0.03
							},
							{
								"h": [
									0.123,
									0.452,
									0.001,
									0.036,
									0.02,
									0.269,
									0.005,
									0.014,
									0.431,
									0.037,
									0.378,
									0.103
								],
								"e": 0.142
							},
							{
								"h": [
									0.017,
									0.137,
									0.014,
									0.016,
									0.024,
									0.026,
									0.054,
									0.082,
									0.002,
									0.066,
									0.032,
									0.005
								],
								"e": 0.043
							},
							{
								"h": [
									0.003,
									0.005,
									0.035,
									0.011,
									0.001,
									0.01,
									0.01,
									0.057,
									0.001,
									0.005,
									0.262,
									0.178
								],
								"e": 0.05
							},
							{
								"h": [
									0.013,
									1.0,
									0.087,
									0.006,
									0.01,
									0.014,
									1.0,
									0.008,
									0.304,
									0.048,
									0.146,
									0.014
								],
								"e": 0.287
							},
							{
								"h": [
									0.016,
									0.019,
									0.024,
									0.014,
									0.022,
									0.013,
									0.038,
									0.11,
									0.044,
									0.009,
									0.007,
									0.009
								],
								"e": 0.027
							},
							{
								"h": [
									0.023,
									0.075,
									0.069,
									0.178,
									0.043,
									0.002,
									0.083,
									1.0,
									0.016,
									0.004,
									0.013,
									0.307
								],
								"e": 0.104
							},
							{
								"h": [
									0.494,
									0.036,
									0.02,
									0.079,
									0.014,
									0.018,
									0.017,
									0.071,
									0.035,
									0.022,
									0.542,
									0.045
								],
								"e": 0.067
							}
						],
						[
							{
								"h": [
									0.089,
									0.079,
									0.652,
									0.007,
									0.088,
									0.137,
									0.009,
									0.056,
									0.173,
									0.68,
									0.399,
									0.181
								],
								"e": 0.2
							},
							{
								"h": [
									0.082,
									0.11,
									0.439,
									0.05,
									0.144,
									0.42,
									0.132,
									0.17,
									0.205,
									0.073,
									0.309,
									0.06
								],
								"e": 0.221
							},
							{
								"h": [
									0.301,
									0.057,
									0.005,
									0.029,
									0.014,
									0.012,
									0.078,
									0.148,
									0.021,
									0.001,
									0.169,
									0.023
								],
								"e": 0.091
							},
							{
								"h": [
									0.001,
									0.004,
									0.011,
									0.0,
									0.003,
									0.113,
									0.004,
									0.002,
									0.016,
									0.006,
									0.006,
									0.059
								],
								"e": 0.019
							},
							{
								"h": [
									0.047,
									0.006,
									0.019,
									0.003,
									0.013,
									0.001,
									0.024,
									0.011,
									0.076,
									0.065,
									0.025,
									0.0
								],
								"e": 0.03
							},
							{
								"h": [
									0.188,
									0.0,
									0.001,
									0.014,
									0.01,
									0.002,
									0.002,
									0.006,
									0.049,
									0.019,
									0.402,
									0.035
								],
								"e": 0.042
							},
							{
								"h": [
									0.011,
									0.088,
									0.037,
									0.009,
									0.013,
									0.015,
									0.012,
									0.038,
									0.004,
									0.0,
									0.022,
									0.006
								],
								"e": 0.022
							},
							{
								"h": [
									0.005,
									0.006,
									0.0,
									0.006,
									0.001,
									0.012,
									0.013,
									0.182,
									0.001,
									0.002,
									0.002,
									0.007
								],
								"e": 0.013
							},
							{
								"h": [
									0.023,
									0.07,
									0.036,
									0.012,
									0.006,
									0.02,
									0.027,
									0.029,
									0.05,
									0.016,
									0.045,
									0.026
								],
								"e": 0.034
							},
							{
								"h": [
									0.006,
									0.031,
									0.024,
									0.013,
									0.009,
									0.018,
									0.002,
									0.025,
									0.006,
									0.0,
									0.01,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.018,
									0.006,
									0.023,
									0.065,
									0.028,
									0.003,
									0.037,
									0.074,
									0.009,
									0.02,
									0.006,
									0.015
								],
								"e": 0.021
							},
							{
								"h": [
									0.549,
									0.03,
									0.044,
									0.182,
									0.053,
									0.007,
									0.006,
									0.049,
									0.235,
									0.011,
									0.145,
									0.073
								],
								"e": 0.067
							}
						],
						[
							{
								"h": [
									0.097,
									0.929,
									0.167,
									0.021,
									0.082,
									0.786,
									0.006,
									0.072,
									0.636,
									0.641,
									0.958,
									0.471
								],
								"e": 0.491
							},
							{
								"h": [
									0.054,
									0.058,
									0.499,
									0.063,
									0.206,
									0.227,
									0.149,
									0.224,
									0.303,
									0.317,
									0.433,
									0.419
								],
								"e": 0.333
							},
							{
								"h": [
									0.024,
									0.04,
									0.0,
									0.019,
									0.013,
									0.006,
									0.038,
									0.184,
									0.002,
									0.001,
									0.206,
									0.026
								],
								"e": 0.052
							},
							{
								"h": [
									0.027,
									0.005,
									0.001,
									0.0,
									0.011,
									0.042,
									0.001,
									0.0,
									0.0,
									0.001,
									0.02,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.001,
									0.005,
									0.0,
									0.018,
									0.0,
									0.01,
									0.005,
									0.029,
									0.009,
									0.025,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.008,
									0.0,
									0.0,
									0.007,
									0.001,
									0.0,
									0.0,
									0.001,
									0.002,
									0.014,
									0.055,
									0.052
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.002,
									0.021,
									0.015,
									0.012,
									0.006,
									0.013,
									0.015,
									0.002,
									0.0,
									0.011,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.004,
									0.0,
									0.002,
									0.0,
									0.003,
									0.005,
									0.081,
									0.0,
									0.001,
									0.0,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.035,
									0.001,
									0.019,
									0.007,
									0.002,
									0.004,
									0.001,
									0.005,
									0.017,
									0.014,
									0.019,
									0.003
								],
								"e": 0.013
							},
							{
								"h": [
									0.002,
									0.01,
									0.019,
									0.002,
									0.0,
									0.017,
									0.0,
									0.01,
									0.008,
									0.0,
									0.003,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.006,
									0.002,
									0.009,
									0.019,
									0.007,
									0.001,
									0.015,
									0.026,
									0.001,
									0.006,
									0.003,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.429,
									0.015,
									0.019,
									0.037,
									0.019,
									0.004,
									0.001,
									0.013,
									0.074,
									0.003,
									0.06,
									0.014
								],
								"e": 0.026
							}
						],
						[
							{
								"h": [
									0.168,
									0.001,
									0.468,
									0.021,
									0.146,
									0.049,
									0.678,
									0.073,
									0.079,
									0.45,
									0.405,
									0.455
								],
								"e": 0.257
							},
							{
								"h": [
									0.029,
									0.081,
									0.356,
									0.056,
									0.243,
									0.113,
									0.055,
									0.131,
									0.177,
									0.115,
									0.46,
									0.002
								],
								"e": 0.17
							},
							{
								"h": [
									0.091,
									0.044,
									0.001,
									0.025,
									0.008,
									0.024,
									0.015,
									0.246,
									0.001,
									0.028,
									0.229,
									0.033
								],
								"e": 0.071
							},
							{
								"h": [
									0.0,
									0.002,
									0.0,
									0.0,
									0.005,
									0.022,
									0.011,
									0.006,
									0.0,
									0.001,
									0.023,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.001,
									0.022,
									0.001,
									0.009,
									0.0,
									0.027,
									0.002,
									0.029,
									0.002,
									0.005,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.003,
									0.0,
									0.001,
									0.014,
									0.002,
									0.012,
									0.0,
									0.0,
									0.015,
									0.016,
									0.018,
									0.01
								],
								"e": 0.007
							},
							{
								"h": [
									0.003,
									0.002,
									0.018,
									0.021,
									0.01,
									0.013,
									0.008,
									0.022,
									0.004,
									0.0,
									0.008,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.002,
									0.001,
									0.003,
									0.0,
									0.002,
									0.006,
									0.012,
									0.001,
									0.003,
									0.001,
									0.03
								],
								"e": 0.005
							},
							{
								"h": [
									0.011,
									0.011,
									0.051,
									0.002,
									0.001,
									0.015,
									0.004,
									0.004,
									0.045,
									0.009,
									0.028,
									0.003
								],
								"e": 0.016
							},
							{
								"h": [
									0.002,
									0.004,
									0.011,
									0.002,
									0.0,
									0.004,
									0.001,
									0.031,
									0.018,
									0.001,
									0.002,
									0.017
								],
								"e": 0.007
							},
							{
								"h": [
									0.004,
									0.002,
									0.013,
									0.067,
									0.002,
									0.002,
									0.013,
									0.022,
									0.004,
									0.005,
									0.004,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.279,
									0.006,
									0.019,
									0.007,
									0.061,
									0.003,
									0.008,
									0.031,
									0.017,
									0.001,
									0.053,
									0.004
								],
								"e": 0.023
							}
						],
						[
							{
								"h": [
									0.275,
									0.0,
									0.306,
									0.012,
									0.111,
									0.015,
									0.636,
									0.16,
									0.029,
									0.34,
									0.281,
									0.192
								],
								"e": 0.217
							},
							{
								"h": [
									0.041,
									0.115,
									0.508,
									0.07,
									0.287,
									0.131,
									0.115,
									0.237,
									0.218,
									0.087,
									0.626,
									0.005
								],
								"e": 0.227
							},
							{
								"h": [
									0.146,
									0.057,
									0.002,
									0.021,
									0.026,
									0.107,
									0.036,
									0.317,
									0.004,
									0.048,
									0.384,
									0.05
								],
								"e": 0.117
							},
							{
								"h": [
									0.001,
									0.009,
									0.003,
									0.002,
									0.006,
									0.04,
									0.039,
									0.023,
									0.003,
									0.006,
									0.025,
									0.006
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.004,
									0.009,
									0.002,
									0.036,
									0.001,
									0.028,
									0.004,
									0.059,
									0.003,
									0.006,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.012,
									0.0,
									0.0,
									0.014,
									0.003,
									0.003,
									0.002,
									0.0,
									0.007,
									0.016,
									0.052,
									0.005
								],
								"e": 0.007
							},
							{
								"h": [
									0.01,
									0.012,
									0.036,
									0.018,
									0.032,
									0.03,
									0.013,
									0.06,
									0.017,
									0.001,
									0.048,
									0.009
								],
								"e": 0.024
							},
							{
								"h": [
									0.002,
									0.006,
									0.001,
									0.011,
									0.001,
									0.016,
									0.029,
									0.345,
									0.001,
									0.004,
									0.0,
									0.029
								],
								"e": 0.024
							},
							{
								"h": [
									0.01,
									0.035,
									0.113,
									0.003,
									0.005,
									0.036,
									0.013,
									0.016,
									0.202,
									0.048,
									0.075,
									0.011
								],
								"e": 0.046
							},
							{
								"h": [
									0.021,
									0.022,
									0.038,
									0.024,
									0.002,
									0.007,
									0.002,
									0.1,
									0.04,
									0.001,
									0.005,
									0.027
								],
								"e": 0.023
							},
							{
								"h": [
									0.017,
									0.005,
									0.04,
									0.205,
									0.037,
									0.003,
									0.149,
									0.092,
									0.017,
									0.009,
									0.008,
									0.005
								],
								"e": 0.037
							},
							{
								"h": [
									0.344,
									0.024,
									0.043,
									0.068,
									0.05,
									0.015,
									0.012,
									0.218,
									0.014,
									0.005,
									0.23,
									0.019
								],
								"e": 0.059
							}
						],
						[
							{
								"h": [
									0.124,
									0.002,
									0.999,
									0.063,
									0.134,
									0.045,
									0.009,
									0.7,
									0.512,
									0.679,
									0.415,
									0.638
								],
								"e": 0.381
							},
							{
								"h": [
									0.165,
									1.0,
									0.877,
									0.116,
									0.278,
									0.545,
									0.201,
									0.412,
									0.446,
									0.091,
									0.801,
									0.119
								],
								"e": 0.532
							},
							{
								"h": [
									0.296,
									0.06,
									0.056,
									0.227,
									0.63,
									0.669,
									1.0,
									0.343,
									0.101,
									0.116,
									0.428,
									0.068
								],
								"e": 0.499
							},
							{
								"h": [
									0.004,
									0.694,
									0.126,
									0.058,
									0.019,
									0.144,
									0.233,
									0.158,
									0.256,
									0.134,
									0.054,
									0.382
								],
								"e": 0.187
							},
							{
								"h": [
									0.103,
									1.0,
									0.13,
									0.029,
									0.025,
									0.09,
									0.112,
									0.235,
									0.137,
									0.073,
									0.051,
									0.0
								],
								"e": 0.145
							},
							{
								"h": [
									0.105,
									0.0,
									0.006,
									0.685,
									0.038,
									0.034,
									0.008,
									0.012,
									0.091,
									0.047,
									0.388,
									0.028
								],
								"e": 0.074
							},
							{
								"h": [
									0.077,
									0.063,
									0.107,
									0.117,
									0.138,
									0.074,
									0.036,
									0.033,
									0.021,
									0.003,
									0.071,
									0.476
								],
								"e": 0.096
							},
							{
								"h": [
									0.018,
									0.018,
									0.0,
									0.042,
									0.003,
									0.227,
									0.024,
									0.801,
									0.002,
									0.08,
									0.009,
									0.005
								],
								"e": 0.071
							},
							{
								"h": [
									0.023,
									0.076,
									0.113,
									0.033,
									0.085,
									0.405,
									0.017,
									0.084,
									0.154,
									0.023,
									0.163,
									0.037
								],
								"e": 0.1
							},
							{
								"h": [
									0.007,
									0.014,
									0.057,
									0.186,
									0.018,
									0.005,
									0.002,
									0.075,
									0.039,
									0.003,
									0.037,
									0.01
								],
								"e": 0.03
							},
							{
								"h": [
									0.045,
									0.011,
									0.021,
									0.221,
									0.054,
									0.006,
									0.019,
									0.06,
									0.02,
									0.054,
									0.011,
									0.094
								],
								"e": 0.041
							},
							{
								"h": [
									0.347,
									0.029,
									0.02,
									0.121,
									0.087,
									0.012,
									0.007,
									0.028,
									0.121,
									0.017,
									0.125,
									0.039
								],
								"e": 0.05
							}
						],
						[
							{
								"h": [
									0.327,
									0.002,
									0.664,
									0.073,
									0.504,
									0.055,
									0.865,
									0.323,
									0.034,
									0.563,
									0.375,
									0.398
								],
								"e": 0.407
							},
							{
								"h": [
									0.136,
									0.265,
									0.7,
									0.101,
									0.437,
									0.151,
									0.109,
									0.299,
									0.255,
									0.156,
									1.0,
									0.011
								],
								"e": 0.345
							},
							{
								"h": [
									1.0,
									0.083,
									0.199,
									0.83,
									0.395,
									0.261,
									0.112,
									0.692,
									0.04,
									1.0,
									0.903,
									0.111
								],
								"e": 0.696
							},
							{
								"h": [
									0.0,
									0.257,
									0.146,
									0.068,
									0.011,
									0.241,
									1.0,
									1.0,
									0.12,
									0.105,
									0.064,
									0.263
								],
								"e": 0.268
							},
							{
								"h": [
									0.035,
									0.093,
									0.102,
									1.0,
									0.038,
									0.056,
									0.295,
									0.29,
									0.03,
									0.16,
									0.01,
									0.0
								],
								"e": 0.23
							},
							{
								"h": [
									0.008,
									0.0,
									0.053,
									0.515,
									0.058,
									0.004,
									0.65,
									0.015,
									0.013,
									0.044,
									0.894,
									0.028
								],
								"e": 0.116
							},
							{
								"h": [
									1.0,
									0.087,
									0.056,
									0.064,
									0.042,
									0.188,
									0.054,
									0.855,
									0.453,
									0.0,
									0.013,
									0.134
								],
								"e": 0.19
							},
							{
								"h": [
									0.054,
									0.008,
									0.0,
									0.16,
									0.044,
									0.098,
									0.019,
									0.076,
									1.0,
									0.021,
									0.0,
									0.003
								],
								"e": 0.134
							},
							{
								"h": [
									0.03,
									0.001,
									0.077,
									0.009,
									0.105,
									0.234,
									0.002,
									0.348,
									0.644,
									0.173,
									0.16,
									0.577
								],
								"e": 0.184
							},
							{
								"h": [
									0.107,
									0.008,
									0.17,
									0.563,
									0.002,
									0.055,
									0.006,
									0.042,
									0.248,
									0.005,
									0.061,
									0.006
								],
								"e": 0.083
							},
							{
								"h": [
									0.177,
									0.008,
									0.035,
									0.565,
									0.032,
									0.011,
									0.345,
									0.536,
									0.002,
									0.027,
									0.048,
									0.248
								],
								"e": 0.122
							},
							{
								"h": [
									0.634,
									0.01,
									0.052,
									0.437,
									0.026,
									0.011,
									0.017,
									0.027,
									0.069,
									0.003,
									0.099,
									0.091
								],
								"e": 0.069
							}
						],
						[
							{
								"h": [
									0.27,
									0.001,
									0.655,
									0.446,
									1.0,
									0.213,
									0.045,
									0.962,
									0.819,
									0.674,
									0.385,
									0.388
								],
								"e": 0.684
							},
							{
								"h": [
									1.0,
									0.769,
									0.998,
									0.116,
									0.342,
									0.314,
									0.239,
									0.258,
									0.314,
									0.267,
									0.955,
									0.032
								],
								"e": 0.642
							},
							{
								"h": [
									0.295,
									0.064,
									1.0,
									0.513,
									1.0,
									1.0,
									0.149,
									1.0,
									1.0,
									0.095,
									1.0,
									0.116
								],
								"e": 1.0
							},
							{
								"h": [
									0.01,
									0.287,
									1.0,
									0.607,
									0.048,
									0.282,
									0.598,
									0.37,
									1.0,
									1.0,
									0.087,
									0.499
								],
								"e": 0.509
							},
							{
								"h": [
									0.693,
									0.947,
									0.042,
									0.068,
									0.111,
									0.033,
									0.169,
									0.171,
									0.066,
									0.116,
									0.01,
									1.0
								],
								"e": 0.349
							},
							{
								"h": [
									0.002,
									0.0,
									0.21,
									0.542,
									0.092,
									0.014,
									0.295,
									0.013,
									0.02,
									0.047,
									0.292,
									0.112
								],
								"e": 0.1
							},
							{
								"h": [
									0.741,
									0.007,
									0.059,
									0.033,
									0.033,
									0.098,
									0.117,
									0.141,
									0.518,
									0.0,
									0.008,
									0.449
								],
								"e": 0.145
							},
							{
								"h": [
									0.226,
									0.001,
									0.0,
									0.084,
									0.099,
									0.125,
									0.014,
									0.016,
									0.044,
									0.059,
									0.0,
									0.001
								],
								"e": 0.058
							},
							{
								"h": [
									0.029,
									0.003,
									0.043,
									0.013,
									0.091,
									0.547,
									0.001,
									1.0,
									0.133,
									0.055,
									0.043,
									0.152
								],
								"e": 0.154
							},
							{
								"h": [
									0.029,
									0.02,
									0.017,
									1.0,
									0.0,
									0.06,
									0.001,
									0.005,
									0.024,
									0.001,
									0.151,
									0.002
								],
								"e": 0.074
							},
							{
								"h": [
									0.02,
									0.013,
									0.028,
									0.147,
									0.05,
									0.023,
									0.03,
									0.059,
									0.004,
									0.08,
									0.009,
									1.0
								],
								"e": 0.086
							},
							{
								"h": [
									0.642,
									0.062,
									0.025,
									0.275,
									0.035,
									0.007,
									0.004,
									0.026,
									1.0,
									0.009,
									0.279,
									0.207
								],
								"e": 0.128
							}
						],
						[
							{
								"h": [
									0.124,
									1.0,
									0.184,
									1.0,
									0.646,
									0.563,
									0.008,
									1.0,
									1.0,
									0.731,
									0.923,
									0.499
								],
								"e": 1.0
							},
							{
								"h": [
									0.542,
									0.366,
									1.0,
									0.109,
									0.368,
									0.232,
									0.17,
									0.315,
									0.355,
									0.325,
									0.865,
									1.0
								],
								"e": 0.688
							},
							{
								"h": [
									0.307,
									0.043,
									0.087,
									1.0,
									0.862,
									0.254,
									0.185,
									0.688,
									0.108,
									0.089,
									0.715,
									0.106
								],
								"e": 0.467
							},
							{
								"h": [
									0.025,
									0.199,
									0.332,
									0.151,
									0.016,
									0.122,
									0.109,
									0.234,
									0.089,
									0.149,
									0.201,
									0.133
								],
								"e": 0.16
							},
							{
								"h": [
									0.393,
									0.356,
									0.041,
									0.005,
									0.082,
									0.037,
									0.088,
									1.0,
									0.037,
									0.043,
									0.018,
									0.0
								],
								"e": 0.145
							},
							{
								"h": [
									0.013,
									0.002,
									0.006,
									0.193,
									0.02,
									0.03,
									0.026,
									0.011,
									0.04,
									0.032,
									0.071,
									0.227
								],
								"e": 0.044
							},
							{
								"h": [
									0.037,
									0.015,
									0.039,
									0.032,
									0.063,
									0.072,
									0.23,
									0.032,
									0.024,
									0.001,
									0.017,
									0.048
								],
								"e": 0.05
							},
							{
								"h": [
									0.02,
									0.001,
									0.001,
									0.041,
									0.01,
									0.025,
									0.014,
									0.009,
									0.003,
									0.025,
									0.001,
									0.018
								],
								"e": 0.014
							},
							{
								"h": [
									0.08,
									0.006,
									0.03,
									0.027,
									0.039,
									0.105,
									0.002,
									0.087,
									0.032,
									0.036,
									0.067,
									0.013
								],
								"e": 0.048
							},
							{
								"h": [
									0.006,
									0.064,
									0.022,
									0.051,
									0.001,
									0.034,
									0.004,
									0.017,
									0.015,
									0.002,
									0.044,
									0.004
								],
								"e": 0.02
							},
							{
								"h": [
									0.013,
									0.067,
									0.116,
									0.265,
									0.023,
									0.007,
									0.029,
									0.053,
									0.005,
									0.027,
									0.014,
									0.149
								],
								"e": 0.05
							},
							{
								"h": [
									0.561,
									0.05,
									0.036,
									0.047,
									0.023,
									0.011,
									0.003,
									0.057,
									0.104,
									0.015,
									0.078,
									0.035
								],
								"e": 0.046
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " capital",
					"o": " of",
					"t": " of",
					"r": [
						{
							"p": " capital",
							"b": 0.0,
							"k": {
								" capital": 0.998,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " capital",
							"b": 0.0,
							"k": {
								" capital": 0.993,
								" capitals": 0.002,
								" city": 0.001,
								" investment": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " capital",
							"b": 0.0,
							"k": {
								" capital": 0.942,
								" city": 0.029,
								" capitals": 0.006,
								" area": 0.002,
								" ship": 0.002,
								" cities": 0.002,
								" investment": 0.002,
								" punishment": 0.002,
								" itself": 0.001,
								" block": 0.001
							}
						},
						{
							"p": " capital",
							"b": 0.001,
							"k": {
								" capital": 0.675,
								" city": 0.266,
								" cities": 0.023,
								" itself": 0.006,
								" metropolitan": 0.006,
								" capitals": 0.003,
								" ship": 0.002,
								" suburb": 0.002,
								" of": 0.001,
								" area": 0.001
							}
						},
						{
							"p": " city",
							"b": 0.007,
							"k": {
								" city": 0.864,
								" capital": 0.047,
								" cities": 0.029,
								" metropolitan": 0.012,
								" of": 0.007,
								" itself": 0.007,
								" area": 0.003,
								" City": 0.003,
								" gateway": 0.002,
								" port": 0.001
							}
						},
						{
							"p": " city",
							"b": 0.294,
							"k": {
								" city": 0.606,
								" of": 0.294,
								" capital": 0.023,
								" cities": 0.012,
								" City": 0.011,
								" itself": 0.006,
								" pillar": 0.006,
								" metropolitan": 0.005,
								" area": 0.003,
								" gateway": 0.003
							}
						},
						{
							"p": " of",
							"b": 0.886,
							"k": {
								" of": 0.886,
								" city": 0.096,
								" City": 0.002,
								" capital": 0.002,
								" cities": 0.002,
								" and": 0.001,
								" for": 0.001,
								" area": 0.001,
								" itself": 0.001,
								"!": 0.0
							}
						},
						{
							"p": " of",
							"b": 0.966,
							"k": {
								" of": 0.966,
								" city": 0.03,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " of",
							"b": 0.973,
							"k": {
								" of": 0.973,
								" city": 0.025,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " of",
							"b": 0.993,
							"k": {
								" of": 0.993,
								" city": 0.006,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " of",
							"b": 1.0,
							"k": {
								" of": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " of",
							"b": 0.926,
							"k": {
								" of": 0.926,
								" city": 0.016,
								".": 0.013,
								" and": 0.011,
								",": 0.009,
								" for": 0.006,
								" to": 0.001,
								" in": 0.001,
								" on": 0.001,
								"'s": 0.001
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.0,
									0.363,
									0.011,
									0.007,
									0.043,
									0.616,
									0.083,
									1.0,
									1.0,
									0.496,
									1.0
								],
								"e": 0.48
							},
							{
								"h": [
									0.068,
									0.119,
									0.504,
									1.0,
									1.0,
									0.678,
									1.0,
									1.0,
									1.0,
									1.0,
									0.012,
									0.059
								],
								"e": 1.0
							},
							{
								"h": [
									0.957,
									1.0,
									0.028,
									0.063,
									0.389,
									0.217,
									0.488,
									0.383,
									0.112,
									0.275,
									0.455,
									1.0
								],
								"e": 0.726
							},
							{
								"h": [
									1.0,
									1.0,
									0.036,
									1.0,
									1.0,
									1.0,
									0.629,
									1.0,
									0.345,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.164,
									0.424,
									1.0,
									0.973,
									1.0,
									1.0,
									1.0,
									0.839,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.554,
									0.835,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.071,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.921,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.998
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.92,
									1.0,
									0.196,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									1.0,
									0.232
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.234,
									0.0,
									0.142,
									0.0,
									0.0,
									0.0,
									0.047,
									0.025,
									0.484,
									0.574,
									0.257,
									0.821
								],
								"e": 0.187
							},
							{
								"h": [
									0.016,
									0.031,
									0.187,
									0.031,
									0.142,
									0.533,
									0.141,
									0.139,
									0.364,
									0.184,
									0.225,
									0.023
								],
								"e": 0.205
							},
							{
								"h": [
									0.062,
									0.076,
									0.0,
									0.001,
									0.019,
									0.012,
									0.029,
									0.028,
									0.005,
									0.003,
									0.075,
									0.007
								],
								"e": 0.033
							},
							{
								"h": [
									0.018,
									0.011,
									0.0,
									0.0,
									0.023,
									0.044,
									0.007,
									0.001,
									0.002,
									0.008,
									0.007,
									0.003
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.001,
									0.03,
									0.012,
									0.024,
									0.004,
									0.012,
									0.001,
									0.006,
									0.008,
									0.014,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.0,
									0.01,
									0.074,
									0.001,
									0.0,
									0.001,
									0.004,
									0.003,
									0.025,
									0.026,
									0.033
								],
								"e": 0.015
							},
							{
								"h": [
									0.016,
									0.002,
									0.056,
									0.015,
									0.007,
									0.016,
									0.02,
									0.07,
									0.005,
									0.0,
									0.0,
									0.017
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.003,
									0.002,
									0.007,
									0.005,
									0.002,
									0.007,
									0.008,
									0.0,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.011,
									0.001,
									0.013,
									0.012,
									0.003,
									0.01,
									0.008,
									0.0,
									0.062,
									0.012,
									0.012,
									0.014
								],
								"e": 0.015
							},
							{
								"h": [
									0.008,
									0.002,
									0.01,
									0.005,
									0.004,
									0.013,
									0.001,
									0.031,
									0.005,
									0.0,
									0.001,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.003,
									0.002,
									0.035,
									0.042,
									0.007,
									0.005,
									0.008,
									0.034,
									0.004,
									0.001,
									0.004,
									0.053
								],
								"e": 0.014
							},
							{
								"h": [
									0.54,
									0.02,
									0.027,
									0.097,
									0.064,
									0.014,
									0.009,
									0.019,
									0.283,
									0.005,
									0.045,
									0.357
								],
								"e": 0.065
							}
						],
						[
							{
								"h": [
									0.3,
									0.004,
									0.254,
									0.0,
									0.001,
									0.001,
									0.26,
									0.007,
									0.446,
									0.621,
									0.275,
									0.291
								],
								"e": 0.205
							},
							{
								"h": [
									0.01,
									0.018,
									0.205,
									0.034,
									0.138,
									0.106,
									0.115,
									0.188,
									0.154,
									0.194,
									0.304,
									0.019
								],
								"e": 0.154
							},
							{
								"h": [
									0.102,
									0.316,
									0.0,
									0.001,
									0.001,
									0.001,
									0.01,
									0.095,
									0.001,
									0.006,
									0.103,
									0.01
								],
								"e": 0.06
							},
							{
								"h": [
									0.005,
									0.003,
									0.0,
									0.0,
									0.008,
									0.061,
									0.004,
									0.0,
									0.0,
									0.001,
									0.005,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.001,
									0.027,
									0.008,
									0.044,
									0.0,
									0.013,
									0.007,
									0.002,
									0.004,
									0.031,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.021,
									0.0,
									0.001,
									0.0,
									0.001,
									0.002,
									0.006,
									0.042,
									0.011
								],
								"e": 0.008
							},
							{
								"h": [
									0.006,
									0.001,
									0.014,
									0.003,
									0.002,
									0.029,
									0.006,
									0.051,
									0.007,
									0.0,
									0.002,
									0.004
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.006,
									0.0,
									0.002,
									0.0,
									0.006,
									0.012,
									0.001,
									0.002,
									0.002,
									0.0,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.012,
									0.0,
									0.072,
									0.006,
									0.0,
									0.006,
									0.002,
									0.0,
									0.024,
									0.013,
									0.017,
									0.002
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.0,
									0.007,
									0.004,
									0.002,
									0.009,
									0.001,
									0.057,
									0.004,
									0.0,
									0.0,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.004,
									0.001,
									0.021,
									0.01,
									0.001,
									0.001,
									0.016,
									0.062,
									0.001,
									0.0,
									0.006,
									0.007
								],
								"e": 0.009
							},
							{
								"h": [
									0.344,
									0.03,
									0.028,
									0.02,
									0.031,
									0.01,
									0.012,
									0.018,
									0.129,
									0.003,
									0.026,
									0.146
								],
								"e": 0.036
							}
						],
						[
							{
								"h": [
									0.154,
									0.0,
									0.133,
									0.0,
									0.0,
									0.0,
									0.019,
									0.034,
									0.387,
									0.453,
									0.226,
									0.289
								],
								"e": 0.126
							},
							{
								"h": [
									0.06,
									0.051,
									0.354,
									0.047,
									0.143,
									0.167,
									0.074,
									0.108,
									0.46,
									0.345,
									0.341,
									0.007
								],
								"e": 0.218
							},
							{
								"h": [
									0.058,
									0.126,
									0.0,
									0.001,
									0.003,
									0.007,
									0.037,
									0.109,
									0.019,
									0.002,
									0.135,
									0.018
								],
								"e": 0.054
							},
							{
								"h": [
									0.003,
									0.009,
									0.0,
									0.001,
									0.012,
									0.123,
									0.018,
									0.003,
									0.012,
									0.033,
									0.011,
									0.011
								],
								"e": 0.018
							},
							{
								"h": [
									0.011,
									0.039,
									0.033,
									0.027,
									0.03,
									0.002,
									0.018,
									0.013,
									0.044,
									0.025,
									0.014,
									0.0
								],
								"e": 0.026
							},
							{
								"h": [
									0.0,
									0.0,
									0.045,
									0.054,
									0.022,
									0.009,
									0.013,
									0.001,
									0.027,
									0.028,
									0.012,
									0.018
								],
								"e": 0.021
							},
							{
								"h": [
									0.116,
									0.003,
									0.05,
									0.007,
									0.003,
									0.016,
									0.046,
									0.017,
									0.045,
									0.0,
									0.004,
									0.013
								],
								"e": 0.019
							},
							{
								"h": [
									0.011,
									0.002,
									0.0,
									0.008,
									0.003,
									0.006,
									0.002,
									0.005,
									0.004,
									0.007,
									0.002,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.009,
									0.015,
									0.066,
									0.03,
									0.004,
									0.053,
									0.008,
									0.011,
									0.015,
									0.01,
									0.018,
									0.037
								],
								"e": 0.023
							},
							{
								"h": [
									0.01,
									0.016,
									0.005,
									0.041,
									0.002,
									0.025,
									0.003,
									0.034,
									0.009,
									0.001,
									0.007,
									0.006
								],
								"e": 0.01
							},
							{
								"h": [
									0.003,
									0.002,
									0.029,
									0.045,
									0.021,
									0.015,
									0.008,
									0.033,
									0.013,
									0.003,
									0.002,
									0.031
								],
								"e": 0.015
							},
							{
								"h": [
									0.808,
									0.017,
									0.021,
									0.081,
									0.063,
									0.015,
									0.011,
									0.021,
									1.0,
									0.008,
									0.064,
									0.19
								],
								"e": 0.081
							}
						],
						[
							{
								"h": [
									0.266,
									0.0,
									0.152,
									0.0,
									0.0,
									0.0,
									0.02,
									0.046,
									0.287,
									0.466,
									0.263,
									0.436
								],
								"e": 0.147
							},
							{
								"h": [
									0.077,
									0.049,
									0.359,
									0.044,
									0.142,
									0.338,
									0.1,
									0.155,
									0.356,
									0.318,
									0.291,
									0.01
								],
								"e": 0.227
							},
							{
								"h": [
									0.031,
									0.07,
									0.0,
									0.01,
									0.016,
									0.007,
									0.038,
									0.092,
									0.004,
									0.004,
									0.109,
									0.017
								],
								"e": 0.044
							},
							{
								"h": [
									0.011,
									0.015,
									0.004,
									0.001,
									0.015,
									0.121,
									0.025,
									0.003,
									0.002,
									0.009,
									0.012,
									0.003
								],
								"e": 0.017
							},
							{
								"h": [
									0.009,
									0.028,
									0.034,
									0.013,
									0.033,
									0.006,
									0.021,
									0.121,
									0.02,
									0.035,
									0.025,
									0.0
								],
								"e": 0.032
							},
							{
								"h": [
									0.0,
									0.0,
									0.008,
									0.029,
									0.006,
									0.014,
									0.01,
									0.004,
									0.008,
									0.016,
									0.006,
									0.025
								],
								"e": 0.012
							},
							{
								"h": [
									0.127,
									0.003,
									0.046,
									0.006,
									0.027,
									0.011,
									0.06,
									0.008,
									0.036,
									0.0,
									0.002,
									0.015
								],
								"e": 0.021
							},
							{
								"h": [
									0.012,
									0.0,
									0.0,
									0.005,
									0.001,
									0.003,
									0.002,
									0.001,
									0.006,
									0.005,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.012,
									0.014,
									0.083,
									0.038,
									0.011,
									0.042,
									0.022,
									0.008,
									0.007,
									0.005,
									0.01,
									0.007
								],
								"e": 0.022
							},
							{
								"h": [
									0.001,
									0.006,
									0.003,
									0.03,
									0.001,
									0.011,
									0.001,
									0.039,
									0.001,
									0.0,
									0.038,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.001,
									0.014,
									0.029,
									0.009,
									0.014,
									0.003,
									0.012,
									0.004,
									0.011,
									0.002,
									0.066
								],
								"e": 0.011
							},
							{
								"h": [
									0.903,
									0.006,
									0.011,
									0.086,
									0.031,
									0.004,
									0.003,
									0.009,
									0.344,
									0.002,
									0.055,
									0.119
								],
								"e": 0.049
							}
						],
						[
							{
								"h": [
									0.333,
									0.965,
									0.119,
									0.007,
									0.006,
									1.0,
									0.24,
									0.011,
									0.203,
									0.048,
									1.0,
									0.225
								],
								"e": 0.642
							},
							{
								"h": [
									0.045,
									0.013,
									0.192,
									0.035,
									0.189,
									0.165,
									0.112,
									0.173,
									0.098,
									0.062,
									0.36,
									0.525
								],
								"e": 0.283
							},
							{
								"h": [
									0.05,
									0.199,
									0.0,
									0.002,
									0.001,
									0.002,
									0.013,
									0.177,
									0.002,
									0.007,
									0.224,
									0.024
								],
								"e": 0.066
							},
							{
								"h": [
									0.193,
									0.002,
									0.0,
									0.0,
									0.001,
									0.103,
									0.021,
									0.003,
									0.0,
									0.02,
									0.05,
									0.058
								],
								"e": 0.047
							},
							{
								"h": [
									0.001,
									0.019,
									0.008,
									0.013,
									0.054,
									0.003,
									0.012,
									0.356,
									0.003,
									0.02,
									0.007,
									0.0
								],
								"e": 0.037
							},
							{
								"h": [
									0.004,
									0.008,
									0.001,
									0.012,
									0.009,
									0.014,
									0.001,
									0.007,
									0.005,
									0.002,
									0.006,
									0.094
								],
								"e": 0.014
							},
							{
								"h": [
									0.008,
									0.006,
									0.01,
									0.01,
									0.006,
									0.01,
									0.102,
									0.024,
									0.011,
									0.001,
									0.011,
									0.006
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.009,
									0.0,
									0.002,
									0.001,
									0.002,
									0.007,
									0.001,
									0.001,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.013,
									0.053,
									0.023,
									0.012,
									0.004,
									0.005,
									0.232,
									0.001,
									0.052,
									0.033,
									0.035,
									0.002
								],
								"e": 0.042
							},
							{
								"h": [
									0.006,
									0.001,
									0.005,
									0.014,
									0.005,
									0.013,
									0.002,
									0.039,
									0.007,
									0.001,
									0.008,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.003,
									0.005,
									0.012,
									0.014,
									0.003,
									0.004,
									0.005,
									0.091,
									0.004,
									0.008,
									0.002,
									0.017
								],
								"e": 0.011
							},
							{
								"h": [
									0.561,
									0.005,
									0.008,
									0.033,
									0.007,
									0.004,
									0.007,
									0.011,
									0.419,
									0.006,
									0.049,
									0.022
								],
								"e": 0.035
							}
						],
						[
							{
								"h": [
									0.249,
									0.0,
									0.125,
									0.0,
									0.0,
									0.0,
									0.017,
									0.061,
									0.454,
									0.481,
									0.216,
									0.728
								],
								"e": 0.17
							},
							{
								"h": [
									0.068,
									0.091,
									0.45,
									0.044,
									0.148,
									0.304,
									0.156,
									0.177,
									0.185,
									0.132,
									0.276,
									0.015
								],
								"e": 0.209
							},
							{
								"h": [
									0.55,
									0.102,
									0.001,
									0.009,
									0.017,
									0.023,
									0.072,
									0.1,
									0.031,
									0.005,
									0.128,
									0.02
								],
								"e": 0.1
							},
							{
								"h": [
									0.008,
									0.003,
									0.015,
									0.001,
									0.004,
									0.117,
									0.013,
									0.007,
									0.024,
									0.007,
									0.005,
									0.049
								],
								"e": 0.02
							},
							{
								"h": [
									0.037,
									0.029,
									0.011,
									0.008,
									0.049,
									0.014,
									0.037,
									0.01,
									0.114,
									0.042,
									0.018,
									0.0
								],
								"e": 0.04
							},
							{
								"h": [
									1.0,
									1.0,
									0.002,
									0.006,
									0.012,
									0.403,
									0.004,
									0.008,
									0.451,
									0.011,
									0.009,
									0.102
								],
								"e": 0.252
							},
							{
								"h": [
									0.013,
									0.02,
									0.051,
									0.011,
									0.002,
									0.017,
									0.054,
									0.015,
									0.002,
									0.105,
									0.008,
									0.005
								],
								"e": 0.029
							},
							{
								"h": [
									0.002,
									0.001,
									0.006,
									0.008,
									0.001,
									0.001,
									0.002,
									0.011,
									0.002,
									0.002,
									0.075,
									0.016
								],
								"e": 0.011
							},
							{
								"h": [
									0.01,
									0.248,
									0.029,
									0.008,
									0.002,
									0.011,
									0.093,
									0.007,
									0.005,
									0.006,
									0.032,
									0.015
								],
								"e": 0.039
							},
							{
								"h": [
									0.005,
									0.005,
									0.006,
									0.013,
									0.003,
									0.013,
									0.002,
									0.019,
									0.003,
									0.001,
									0.004,
									0.005
								],
								"e": 0.005
							},
							{
								"h": [
									0.003,
									0.007,
									0.014,
									0.013,
									0.008,
									0.006,
									0.013,
									0.035,
									0.029,
									0.006,
									0.002,
									0.005
								],
								"e": 0.011
							},
							{
								"h": [
									0.637,
									0.014,
									0.012,
									0.076,
									0.025,
									0.012,
									0.012,
									0.016,
									0.209,
									0.009,
									0.03,
									0.041
								],
								"e": 0.038
							}
						],
						[
							{
								"h": [
									0.269,
									0.0,
									0.139,
									0.0,
									0.0,
									0.0,
									0.018,
									0.103,
									0.272,
									0.464,
									0.228,
									0.39
								],
								"e": 0.145
							},
							{
								"h": [
									0.117,
									0.102,
									0.43,
									0.051,
									0.171,
									0.319,
									0.124,
									0.185,
									0.326,
									0.288,
									0.349,
									0.009
								],
								"e": 0.254
							},
							{
								"h": [
									0.062,
									0.072,
									0.001,
									0.039,
									0.034,
									0.028,
									0.048,
									0.127,
									0.01,
									0.013,
									0.153,
									0.023
								],
								"e": 0.072
							},
							{
								"h": [
									0.013,
									0.011,
									0.008,
									0.001,
									0.009,
									0.074,
									0.014,
									0.003,
									0.001,
									0.004,
									0.007,
									0.003
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.006,
									0.005,
									0.006,
									0.041,
									0.006,
									0.019,
									0.007,
									0.035,
									0.044,
									0.024,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.099,
									0.001,
									0.005,
									0.007,
									0.002,
									0.1,
									0.0,
									0.002,
									0.104,
									0.009,
									0.003,
									0.028
								],
								"e": 0.031
							},
							{
								"h": [
									0.052,
									0.003,
									0.025,
									0.015,
									0.006,
									0.008,
									0.055,
									0.015,
									0.061,
									0.004,
									0.005,
									0.004
								],
								"e": 0.016
							},
							{
								"h": [
									0.004,
									0.001,
									0.0,
									0.003,
									0.001,
									0.001,
									0.001,
									0.015,
									0.005,
									0.002,
									0.003,
									0.006
								],
								"e": 0.003
							},
							{
								"h": [
									0.011,
									0.027,
									0.019,
									0.005,
									0.002,
									0.007,
									0.009,
									0.007,
									0.002,
									0.006,
									0.016,
									0.005
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.002,
									0.004,
									0.018,
									0.001,
									0.011,
									0.0,
									0.03,
									0.002,
									0.0,
									0.009,
									0.005
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.001,
									0.003,
									0.009,
									0.004,
									0.006,
									0.004,
									0.021,
									0.01,
									0.011,
									0.002,
									0.006
								],
								"e": 0.006
							},
							{
								"h": [
									0.391,
									0.013,
									0.006,
									0.088,
									0.023,
									0.004,
									0.002,
									0.004,
									0.145,
									0.002,
									0.042,
									0.045
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.198,
									0.001,
									0.202,
									0.007,
									0.002,
									0.0,
									0.17,
									0.028,
									0.562,
									0.485,
									0.263,
									0.358
								],
								"e": 0.184
							},
							{
								"h": [
									0.041,
									0.057,
									0.323,
									0.047,
									0.208,
									0.677,
									0.04,
									0.256,
									0.156,
									0.234,
									0.412,
									0.008
								],
								"e": 0.237
							},
							{
								"h": [
									0.683,
									0.313,
									0.002,
									0.005,
									0.013,
									0.006,
									0.014,
									0.196,
									0.003,
									0.086,
									0.19,
									0.023
								],
								"e": 0.133
							},
							{
								"h": [
									0.002,
									0.003,
									0.0,
									0.003,
									0.012,
									0.051,
									0.097,
									0.012,
									0.002,
									0.001,
									0.024,
									0.006
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.001,
									0.01,
									0.014,
									0.025,
									0.002,
									0.014,
									0.014,
									0.015,
									0.004,
									0.002,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.009,
									0.0,
									0.001,
									0.011,
									0.001,
									0.042,
									0.0,
									0.001,
									0.01,
									0.03,
									0.001,
									0.023
								],
								"e": 0.012
							},
							{
								"h": [
									0.006,
									0.004,
									0.018,
									0.011,
									0.005,
									0.011,
									0.021,
									0.011,
									0.01,
									0.001,
									0.013,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.003,
									0.001,
									0.001,
									0.001,
									0.004,
									0.003,
									0.001,
									0.0,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.005,
									0.002,
									0.014,
									0.003,
									0.0,
									0.007,
									0.001,
									0.0,
									0.004,
									0.003,
									0.011,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.001,
									0.003,
									0.003,
									0.001,
									0.002,
									0.0,
									0.009,
									0.005,
									0.0,
									0.001,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.001,
									0.0,
									0.002,
									0.003,
									0.0,
									0.004,
									0.002,
									0.009,
									0.0,
									0.006,
									0.001,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.05,
									0.002,
									0.003,
									0.012,
									0.016,
									0.001,
									0.003,
									0.004,
									0.085,
									0.0,
									0.004,
									0.033
								],
								"e": 0.009
							}
						],
						[
							{
								"h": [
									0.195,
									0.0,
									0.253,
									0.002,
									0.0,
									0.0,
									0.128,
									0.178,
									0.691,
									0.561,
									0.154,
									0.163
								],
								"e": 0.189
							},
							{
								"h": [
									0.083,
									0.087,
									0.467,
									0.063,
									0.251,
									1.0,
									0.129,
									0.47,
									0.2,
									0.174,
									0.565,
									0.027
								],
								"e": 0.345
							},
							{
								"h": [
									0.174,
									0.369,
									0.006,
									0.003,
									0.186,
									0.035,
									0.033,
									0.269,
									0.004,
									0.112,
									0.362,
									0.048
								],
								"e": 0.168
							},
							{
								"h": [
									0.014,
									0.004,
									0.0,
									0.001,
									0.016,
									0.128,
									0.063,
									0.02,
									0.004,
									0.001,
									0.02,
									0.009
								],
								"e": 0.021
							},
							{
								"h": [
									0.0,
									0.003,
									0.012,
									0.081,
									0.237,
									0.002,
									0.019,
									0.009,
									0.022,
									0.006,
									0.004,
									0.0
								],
								"e": 0.039
							},
							{
								"h": [
									0.014,
									0.0,
									0.001,
									0.011,
									0.003,
									0.004,
									0.0,
									0.001,
									0.005,
									0.012,
									0.008,
									0.046
								],
								"e": 0.009
							},
							{
								"h": [
									0.036,
									0.008,
									0.026,
									0.013,
									0.009,
									0.016,
									0.042,
									0.045,
									0.018,
									0.006,
									0.111,
									0.031
								],
								"e": 0.029
							},
							{
								"h": [
									0.001,
									0.004,
									0.0,
									0.016,
									0.0,
									0.01,
									0.007,
									0.061,
									0.001,
									0.001,
									0.0,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.012,
									0.038,
									0.043,
									0.006,
									0.001,
									0.02,
									0.008,
									0.001,
									0.04,
									0.014,
									0.081,
									0.007
								],
								"e": 0.022
							},
							{
								"h": [
									0.005,
									0.004,
									0.011,
									0.008,
									0.002,
									0.008,
									0.001,
									0.168,
									0.01,
									0.001,
									0.002,
									0.005
								],
								"e": 0.014
							},
							{
								"h": [
									0.003,
									0.001,
									0.007,
									0.03,
									0.002,
									0.003,
									0.012,
									0.05,
									0.021,
									0.002,
									0.002,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.244,
									0.008,
									0.009,
									0.044,
									0.023,
									0.005,
									0.003,
									0.03,
									0.206,
									0.002,
									0.033,
									0.03
								],
								"e": 0.026
							}
						],
						[
							{
								"h": [
									0.213,
									0.0,
									0.13,
									0.0,
									0.0,
									0.0,
									0.03,
									0.519,
									0.364,
									0.52,
									0.192,
									0.608
								],
								"e": 0.208
							},
							{
								"h": [
									0.201,
									1.0,
									0.919,
									0.105,
									0.283,
									0.55,
									0.064,
									0.408,
									0.601,
									0.1,
									0.747,
									0.086
								],
								"e": 0.552
							},
							{
								"h": [
									0.534,
									0.189,
									0.117,
									0.063,
									0.462,
									0.604,
									1.0,
									0.282,
									0.271,
									0.361,
									0.41,
									0.077
								],
								"e": 0.59
							},
							{
								"h": [
									0.011,
									0.33,
									0.005,
									0.048,
									0.023,
									0.112,
									0.141,
									0.091,
									0.096,
									0.095,
									0.049,
									0.109
								],
								"e": 0.095
							},
							{
								"h": [
									0.014,
									0.185,
									0.107,
									0.068,
									0.036,
									0.166,
									0.046,
									0.07,
									0.116,
									0.015,
									0.031,
									0.0
								],
								"e": 0.085
							},
							{
								"h": [
									0.08,
									0.001,
									0.011,
									0.148,
									0.089,
									0.025,
									0.003,
									0.015,
									0.068,
									0.06,
									0.061,
									0.025
								],
								"e": 0.052
							},
							{
								"h": [
									0.181,
									0.069,
									0.106,
									0.107,
									0.104,
									0.211,
									0.066,
									0.021,
									0.026,
									0.008,
									0.106,
									1.0
								],
								"e": 0.131
							},
							{
								"h": [
									0.018,
									0.01,
									0.0,
									0.071,
									0.006,
									0.041,
									0.006,
									0.265,
									0.001,
									0.04,
									0.006,
									0.003
								],
								"e": 0.034
							},
							{
								"h": [
									0.036,
									0.246,
									0.098,
									0.063,
									0.055,
									0.226,
									0.037,
									0.045,
									0.042,
									0.011,
									0.17,
									0.053
								],
								"e": 0.083
							},
							{
								"h": [
									0.002,
									0.007,
									0.013,
									0.224,
									0.01,
									0.054,
									0.007,
									0.254,
									0.02,
									0.003,
									0.034,
									0.048
								],
								"e": 0.039
							},
							{
								"h": [
									0.007,
									0.003,
									0.05,
									0.127,
									0.104,
									0.025,
									0.003,
									0.056,
									0.024,
									0.038,
									0.008,
									0.041
								],
								"e": 0.037
							},
							{
								"h": [
									0.393,
									0.023,
									0.025,
									0.118,
									0.131,
									0.012,
									0.009,
									0.097,
									0.373,
									0.01,
									0.087,
									1.0
								],
								"e": 0.123
							}
						],
						[
							{
								"h": [
									0.26,
									0.002,
									1.0,
									0.062,
									1.0,
									0.0,
									1.0,
									0.742,
									0.533,
									0.347,
									0.223,
									0.29
								],
								"e": 0.873
							},
							{
								"h": [
									0.149,
									0.183,
									0.675,
									0.087,
									0.411,
									0.29,
									0.795,
									0.475,
									0.248,
									0.227,
									0.909,
									0.037
								],
								"e": 0.491
							},
							{
								"h": [
									1.0,
									0.707,
									0.143,
									0.1,
									0.075,
									0.081,
									0.102,
									0.544,
									0.049,
									0.98,
									0.705,
									0.092
								],
								"e": 0.473
							},
							{
								"h": [
									0.025,
									0.141,
									0.002,
									0.013,
									0.011,
									0.599,
									0.283,
									0.043,
									0.019,
									0.035,
									0.031,
									0.043
								],
								"e": 0.085
							},
							{
								"h": [
									0.025,
									0.042,
									0.111,
									1.0,
									0.075,
									0.006,
									0.217,
									0.134,
									0.013,
									0.083,
									0.078,
									0.0
								],
								"e": 0.146
							},
							{
								"h": [
									0.004,
									0.0,
									0.031,
									0.324,
									0.018,
									0.041,
									0.005,
									0.011,
									0.034,
									0.021,
									1.0,
									0.2
								],
								"e": 0.167
							},
							{
								"h": [
									0.433,
									0.014,
									0.073,
									0.043,
									0.008,
									0.098,
									0.057,
									0.327,
									0.109,
									0.001,
									0.008,
									0.153
								],
								"e": 0.08
							},
							{
								"h": [
									0.014,
									0.005,
									0.0,
									0.119,
									0.021,
									0.035,
									0.008,
									0.015,
									0.194,
									0.012,
									0.0,
									0.001
								],
								"e": 0.031
							},
							{
								"h": [
									0.021,
									0.005,
									0.04,
									0.025,
									0.035,
									0.179,
									0.005,
									0.024,
									0.144,
									0.031,
									0.214,
									0.14
								],
								"e": 0.068
							},
							{
								"h": [
									0.068,
									0.002,
									0.05,
									0.158,
									0.003,
									0.076,
									0.006,
									0.042,
									0.092,
									0.007,
									0.062,
									0.004
								],
								"e": 0.038
							},
							{
								"h": [
									0.065,
									0.006,
									0.023,
									0.086,
									0.004,
									0.032,
									0.057,
									0.247,
									0.002,
									0.019,
									0.015,
									0.075
								],
								"e": 0.045
							},
							{
								"h": [
									0.816,
									0.01,
									0.036,
									0.098,
									0.016,
									0.01,
									0.016,
									0.013,
									0.57,
									0.004,
									0.069,
									0.043
								],
								"e": 0.057
							}
						],
						[
							{
								"h": [
									0.187,
									0.0,
									0.107,
									0.001,
									0.001,
									0.0,
									0.016,
									0.63,
									0.411,
									0.472,
									0.156,
									0.225
								],
								"e": 0.186
							},
							{
								"h": [
									0.616,
									0.628,
									1.0,
									0.103,
									0.327,
									0.174,
									0.073,
									0.158,
									0.492,
									0.32,
									0.857,
									0.013
								],
								"e": 0.516
							},
							{
								"h": [
									0.65,
									0.204,
									1.0,
									0.137,
									0.212,
									1.0,
									0.21,
									0.714,
									1.0,
									0.236,
									0.686,
									0.112
								],
								"e": 0.847
							},
							{
								"h": [
									0.001,
									0.194,
									0.062,
									0.253,
									0.013,
									0.261,
									0.492,
									0.347,
									1.0,
									0.604,
									0.037,
									0.197
								],
								"e": 0.303
							},
							{
								"h": [
									1.0,
									0.936,
									0.054,
									0.361,
									0.051,
									0.051,
									0.133,
									0.03,
									0.086,
									0.269,
									0.008,
									0.0
								],
								"e": 0.259
							},
							{
								"h": [
									0.001,
									0.0,
									0.518,
									0.413,
									0.172,
									0.003,
									0.034,
									0.004,
									0.052,
									0.034,
									0.048,
									0.092
								],
								"e": 0.114
							},
							{
								"h": [
									1.0,
									0.001,
									0.083,
									0.021,
									0.012,
									0.069,
									0.048,
									0.04,
									0.639,
									0.0,
									0.004,
									0.276
								],
								"e": 0.102
							},
							{
								"h": [
									0.413,
									0.001,
									0.0,
									0.113,
									0.042,
									0.055,
									0.004,
									0.003,
									0.028,
									0.035,
									0.0,
									0.0
								],
								"e": 0.041
							},
							{
								"h": [
									0.009,
									0.014,
									0.046,
									0.024,
									0.107,
									1.0,
									0.007,
									1.0,
									0.02,
									0.018,
									0.09,
									0.064
								],
								"e": 0.142
							},
							{
								"h": [
									0.012,
									0.009,
									0.004,
									0.959,
									0.0,
									0.06,
									0.003,
									0.022,
									0.007,
									0.0,
									0.209,
									0.004
								],
								"e": 0.051
							},
							{
								"h": [
									0.005,
									0.004,
									0.027,
									0.063,
									0.027,
									0.111,
									0.007,
									0.032,
									0.009,
									0.025,
									0.005,
									0.304
								],
								"e": 0.041
							},
							{
								"h": [
									0.523,
									0.008,
									0.015,
									0.164,
									0.041,
									0.009,
									0.008,
									0.008,
									0.42,
									0.006,
									0.082,
									0.078
								],
								"e": 0.053
							}
						],
						[
							{
								"h": [
									0.325,
									0.0,
									0.134,
									0.001,
									0.002,
									0.0,
									0.018,
									1.0,
									0.311,
									0.517,
									0.193,
									0.361
								],
								"e": 0.253
							},
							{
								"h": [
									1.0,
									0.546,
									0.892,
									0.09,
									0.322,
									0.325,
									0.091,
									0.233,
									0.366,
									0.278,
									0.697,
									0.016
								],
								"e": 0.551
							},
							{
								"h": [
									0.422,
									0.09,
									0.866,
									1.0,
									1.0,
									0.985,
									0.192,
									0.439,
									0.3,
									0.614,
									0.489,
									0.097
								],
								"e": 1.0
							},
							{
								"h": [
									0.004,
									0.318,
									1.0,
									0.342,
									0.012,
									0.258,
									1.0,
									0.713,
									0.243,
									0.186,
									0.049,
									0.064
								],
								"e": 0.404
							},
							{
								"h": [
									0.456,
									1.0,
									0.04,
									0.205,
									0.056,
									0.237,
									0.168,
									0.414,
									0.035,
									0.279,
									0.019,
									1.0
								],
								"e": 0.433
							},
							{
								"h": [
									0.001,
									0.0,
									0.101,
									0.189,
									0.071,
									0.01,
									0.059,
									0.04,
									0.035,
									0.015,
									0.005,
									0.075
								],
								"e": 0.053
							},
							{
								"h": [
									0.949,
									0.003,
									0.049,
									0.021,
									0.068,
									0.059,
									0.093,
									0.017,
									0.731,
									0.0,
									0.003,
									0.2
								],
								"e": 0.105
							},
							{
								"h": [
									0.646,
									0.0,
									0.0,
									0.075,
									0.015,
									0.016,
									0.004,
									0.003,
									0.038,
									0.04,
									0.0,
									0.001
								],
								"e": 0.044
							},
							{
								"h": [
									0.013,
									0.015,
									0.048,
									0.034,
									0.291,
									0.652,
									0.029,
									0.798,
									0.01,
									0.01,
									0.074,
									0.019
								],
								"e": 0.126
							},
							{
								"h": [
									0.005,
									0.005,
									0.005,
									0.507,
									0.001,
									0.027,
									0.001,
									0.058,
									0.002,
									0.0,
									0.685,
									0.005
								],
								"e": 0.057
							},
							{
								"h": [
									0.002,
									0.001,
									0.019,
									0.054,
									0.01,
									0.116,
									0.003,
									0.013,
									0.003,
									0.133,
									0.002,
									0.283
								],
								"e": 0.043
							},
							{
								"h": [
									0.536,
									0.002,
									0.006,
									0.139,
									0.028,
									0.002,
									0.002,
									0.006,
									0.246,
									0.001,
									0.047,
									0.051
								],
								"e": 0.037
							}
						],
						[
							{
								"h": [
									0.43,
									1.0,
									0.11,
									1.0,
									0.071,
									0.697,
									0.274,
									0.186,
									0.234,
									0.049,
									0.842,
									0.199
								],
								"e": 1.0
							},
							{
								"h": [
									0.492,
									0.148,
									0.717,
									0.087,
									0.539,
									0.203,
									0.066,
									0.185,
									0.16,
									0.114,
									1.0,
									1.0
								],
								"e": 0.64
							},
							{
								"h": [
									0.503,
									0.294,
									0.31,
									0.097,
									0.042,
									0.221,
									0.059,
									1.0,
									0.084,
									1.0,
									1.0,
									0.13
								],
								"e": 0.511
							},
							{
								"h": [
									0.045,
									0.039,
									0.037,
									0.045,
									0.001,
									0.253,
									0.586,
									0.412,
									0.038,
									0.19,
									0.245,
									0.687
								],
								"e": 0.205
							},
							{
								"h": [
									0.059,
									0.657,
									0.018,
									0.212,
									0.111,
									0.056,
									0.084,
									1.0,
									0.006,
									0.111,
									0.004,
									0.0
								],
								"e": 0.164
							},
							{
								"h": [
									0.01,
									0.005,
									0.009,
									0.056,
									0.118,
									0.009,
									0.006,
									0.048,
									0.025,
									0.002,
									0.004,
									0.172
								],
								"e": 0.041
							},
							{
								"h": [
									0.048,
									0.007,
									0.02,
									0.025,
									0.008,
									0.047,
									0.139,
									0.019,
									0.065,
									0.0,
									0.024,
									0.053
								],
								"e": 0.032
							},
							{
								"h": [
									0.049,
									0.0,
									0.0,
									0.176,
									0.006,
									0.01,
									0.002,
									0.002,
									0.051,
									0.013,
									0.001,
									0.001
								],
								"e": 0.02
							},
							{
								"h": [
									0.015,
									0.027,
									0.012,
									0.035,
									0.099,
									0.101,
									0.072,
									0.06,
									0.048,
									0.034,
									0.185,
									0.003
								],
								"e": 0.054
							},
							{
								"h": [
									0.007,
									0.0,
									0.003,
									0.205,
									0.002,
									0.018,
									0.001,
									0.027,
									0.005,
									0.0,
									0.148,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.003,
									0.007,
									0.013,
									0.002,
									0.053,
									0.003,
									0.115,
									0.002,
									0.185,
									0.002,
									0.025
								],
								"e": 0.029
							},
							{
								"h": [
									0.386,
									0.003,
									0.004,
									0.034,
									0.003,
									0.001,
									0.003,
									0.002,
									0.442,
									0.002,
									0.065,
									0.016
								],
								"e": 0.03
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " of",
					"o": " the",
					"t": " the",
					"r": [
						{
							"p": " the",
							"b": 0.584,
							"k": {
								" the": 0.584,
								" course": 0.177,
								" a": 0.021,
								" this": 0.008,
								" our": 0.007,
								" their": 0.005,
								" his": 0.004,
								" its": 0.004,
								" '": 0.004,
								" those": 0.004
							}
						},
						{
							"p": " the",
							"b": 0.666,
							"k": {
								" the": 0.666,
								" course": 0.222,
								" a": 0.011,
								" its": 0.006,
								" our": 0.006,
								" this": 0.004,
								" late": 0.004,
								" their": 0.003,
								" those": 0.003,
								" an": 0.002
							}
						},
						{
							"p": " the",
							"b": 0.589,
							"k": {
								" the": 0.589,
								" course": 0.321,
								" a": 0.013,
								" its": 0.006,
								" our": 0.006,
								" an": 0.003,
								" this": 0.003,
								" his": 0.002,
								" their": 0.002,
								" ours": 0.002
							}
						},
						{
							"p": " the",
							"b": 0.82,
							"k": {
								" the": 0.82,
								" course": 0.085,
								" a": 0.01,
								" our": 0.009,
								" its": 0.004,
								" an": 0.003,
								" his": 0.003,
								" this": 0.002,
								" both": 0.002,
								" popular": 0.002
							}
						},
						{
							"p": " the",
							"b": 0.85,
							"k": {
								" the": 0.85,
								" a": 0.013,
								" its": 0.011,
								" our": 0.011,
								" both": 0.011,
								" these": 0.009,
								" several": 0.008,
								" many": 0.005,
								" my": 0.004,
								" land": 0.004
							}
						},
						{
							"p": " the",
							"b": 0.705,
							"k": {
								" the": 0.705,
								" both": 0.063,
								" our": 0.02,
								" a": 0.019,
								" its": 0.016,
								" town": 0.014,
								" several": 0.013,
								" state": 0.012,
								" an": 0.009,
								" Governors": 0.004
							}
						},
						{
							"p": " the",
							"b": 0.645,
							"k": {
								" the": 0.645,
								" state": 0.068,
								" its": 0.032,
								" State": 0.027,
								" both": 0.022,
								" a": 0.015,
								" Commerce": 0.01,
								" an": 0.008,
								" Europe": 0.007,
								" several": 0.005
							}
						},
						{
							"p": " the",
							"b": 0.603,
							"k": {
								" the": 0.603,
								" state": 0.058,
								" its": 0.035,
								" Canada": 0.034,
								" North": 0.032,
								" Europe": 0.024,
								" France": 0.019,
								" South": 0.018,
								" Latin": 0.018,
								" a": 0.011
							}
						},
						{
							"p": " the",
							"b": 0.433,
							"k": {
								" the": 0.433,
								" Europe": 0.266,
								" England": 0.15,
								" Britain": 0.084,
								" France": 0.016,
								" British": 0.015,
								" Germany": 0.004,
								" Latin": 0.004,
								" China": 0.003,
								" Canada": 0.003
							}
						},
						{
							"p": " England",
							"b": 0.005,
							"k": {
								" England": 0.513,
								" Europe": 0.241,
								" Britain": 0.204,
								" France": 0.017,
								" British": 0.006,
								" the": 0.005,
								" London": 0.003,
								" Germany": 0.003,
								" China": 0.002,
								" Italy": 0.001
							}
						},
						{
							"p": " England",
							"b": 0.065,
							"k": {
								" England": 0.363,
								" Europe": 0.228,
								" Britain": 0.139,
								" France": 0.103,
								" the": 0.065,
								" Germany": 0.043,
								" Belgium": 0.009,
								" Spain": 0.007,
								" China": 0.005,
								" Canada": 0.004
							}
						},
						{
							"p": " the",
							"b": 0.534,
							"k": {
								" the": 0.534,
								" England": 0.07,
								" Britain": 0.05,
								" Europe": 0.042,
								" France": 0.037,
								" Canada": 0.024,
								" Germany": 0.019,
								" Great": 0.013,
								" America": 0.011,
								" London": 0.008
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.001,
									0.9,
									0.001,
									0.082,
									0.017,
									0.823,
									0.052,
									0.042,
									1.0,
									0.401,
									1.0
								],
								"e": 0.362
							},
							{
								"h": [
									0.03,
									0.118,
									0.522,
									1.0,
									1.0,
									0.244,
									1.0,
									1.0,
									1.0,
									1.0,
									0.02,
									0.242
								],
								"e": 1.0
							},
							{
								"h": [
									0.372,
									1.0,
									0.007,
									0.143,
									0.059,
									0.28,
									0.49,
									0.336,
									0.146,
									0.057,
									0.64,
									1.0
								],
								"e": 0.578
							},
							{
								"h": [
									1.0,
									1.0,
									0.028,
									0.075,
									1.0,
									1.0,
									0.271,
									0.125,
									0.248,
									1.0,
									1.0,
									0.047
								],
								"e": 1.0
							},
							{
								"h": [
									0.417,
									0.087,
									1.0,
									0.436,
									1.0,
									1.0,
									1.0,
									0.577,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.659,
									1.0,
									1.0,
									0.235,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.271,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.552,
									1.0,
									1.0,
									0.9
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.687,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.526,
									1.0,
									1.0,
									1.0,
									0.654,
									0.456,
									1.0,
									1.0,
									1.0,
									1.0,
									0.499
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.686,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.146,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.161,
									0.012,
									0.615,
									0.0,
									0.01,
									0.134,
									0.042,
									0.018,
									0.204,
									0.813,
									0.43,
									0.539
								],
								"e": 0.181
							},
							{
								"h": [
									0.005,
									0.046,
									0.213,
									0.027,
									0.151,
									0.049,
									0.257,
									0.172,
									0.344,
									0.117,
									0.202,
									0.085
								],
								"e": 0.182
							},
							{
								"h": [
									0.02,
									0.016,
									0.0,
									0.001,
									0.0,
									0.007,
									0.024,
									0.022,
									0.003,
									0.0,
									0.066,
									0.006
								],
								"e": 0.014
							},
							{
								"h": [
									0.01,
									0.012,
									0.0,
									0.0,
									0.01,
									0.017,
									0.003,
									0.0,
									0.001,
									0.004,
									0.005,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.0,
									0.05,
									0.011,
									0.02,
									0.007,
									0.01,
									0.002,
									0.005,
									0.006,
									0.016,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.0,
									0.006,
									0.054,
									0.002,
									0.0,
									0.0,
									0.006,
									0.003,
									0.015,
									0.095,
									0.008
								],
								"e": 0.013
							},
							{
								"h": [
									0.003,
									0.006,
									0.041,
									0.014,
									0.03,
									0.005,
									0.017,
									0.045,
									0.001,
									0.0,
									0.001,
									0.008
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.005,
									0.003,
									0.007,
									0.007,
									0.001,
									0.023,
									0.006,
									0.0,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.004,
									0.0,
									0.016,
									0.009,
									0.002,
									0.009,
									0.004,
									0.0,
									0.008,
									0.007,
									0.012,
									0.021
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.001,
									0.003,
									0.009,
									0.011,
									0.01,
									0.001,
									0.01,
									0.03,
									0.001,
									0.001,
									0.005
								],
								"e": 0.005
							},
							{
								"h": [
									0.01,
									0.002,
									0.008,
									0.048,
									0.005,
									0.001,
									0.005,
									0.013,
									0.012,
									0.004,
									0.003,
									0.024
								],
								"e": 0.01
							},
							{
								"h": [
									0.649,
									0.072,
									0.051,
									0.204,
									0.072,
									0.02,
									0.009,
									0.035,
									0.189,
									0.004,
									0.122,
									0.194
								],
								"e": 0.078
							}
						],
						[
							{
								"h": [
									0.245,
									0.0,
									0.268,
									0.0,
									0.011,
									0.005,
									0.481,
									0.01,
									0.035,
									0.455,
									0.202,
									0.408
								],
								"e": 0.139
							},
							{
								"h": [
									0.003,
									0.022,
									0.239,
									0.03,
									0.16,
									0.009,
									0.133,
									0.137,
									0.199,
									0.125,
									0.282,
									0.002
								],
								"e": 0.131
							},
							{
								"h": [
									0.02,
									0.027,
									0.0,
									0.001,
									0.0,
									0.001,
									0.01,
									0.084,
									0.0,
									0.001,
									0.111,
									0.008
								],
								"e": 0.02
							},
							{
								"h": [
									0.001,
									0.003,
									0.0,
									0.0,
									0.006,
									0.019,
									0.003,
									0.0,
									0.0,
									0.001,
									0.003,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.0,
									0.034,
									0.006,
									0.009,
									0.001,
									0.007,
									0.01,
									0.004,
									0.003,
									0.006,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.013,
									0.001,
									0.0,
									0.0,
									0.002,
									0.002,
									0.012,
									0.114,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.001,
									0.025,
									0.004,
									0.008,
									0.009,
									0.008,
									0.037,
									0.005,
									0.0,
									0.003,
									0.004
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.005,
									0.001,
									0.01,
									0.017,
									0.002,
									0.003,
									0.004,
									0.0,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.005,
									0.001,
									0.057,
									0.001,
									0.0,
									0.003,
									0.003,
									0.0,
									0.008,
									0.004,
									0.025,
									0.005
								],
								"e": 0.011
							},
							{
								"h": [
									0.003,
									0.001,
									0.003,
									0.016,
									0.003,
									0.017,
									0.003,
									0.05,
									0.058,
									0.002,
									0.0,
									0.013
								],
								"e": 0.01
							},
							{
								"h": [
									0.017,
									0.003,
									0.074,
									0.052,
									0.016,
									0.0,
									0.012,
									0.021,
									0.009,
									0.003,
									0.015,
									0.011
								],
								"e": 0.019
							},
							{
								"h": [
									0.332,
									0.01,
									0.055,
									0.028,
									0.05,
									0.006,
									0.011,
									0.013,
									0.019,
									0.003,
									0.082,
									0.019
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.192,
									0.002,
									0.408,
									0.0,
									0.017,
									0.184,
									0.079,
									0.024,
									0.376,
									0.688,
									0.423,
									0.523
								],
								"e": 0.188
							},
							{
								"h": [
									0.028,
									0.067,
									0.38,
									0.041,
									0.152,
									0.02,
									0.272,
									0.15,
									0.303,
									0.248,
									0.323,
									0.015
								],
								"e": 0.204
							},
							{
								"h": [
									0.016,
									0.061,
									0.0,
									0.003,
									0.0,
									0.005,
									0.037,
									0.102,
									0.015,
									0.0,
									0.133,
									0.014
								],
								"e": 0.034
							},
							{
								"h": [
									0.009,
									0.015,
									0.0,
									0.0,
									0.018,
									0.069,
									0.009,
									0.0,
									0.007,
									0.034,
									0.02,
									0.001
								],
								"e": 0.023
							},
							{
								"h": [
									0.014,
									0.003,
									0.043,
									0.014,
									0.028,
									0.004,
									0.011,
									0.015,
									0.013,
									0.017,
									0.01,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.0,
									0.011,
									0.065,
									0.007,
									0.002,
									0.001,
									0.004,
									0.008,
									0.015,
									0.071,
									0.007
								],
								"e": 0.014
							},
							{
								"h": [
									0.06,
									0.013,
									0.023,
									0.008,
									0.006,
									0.006,
									0.014,
									0.022,
									0.016,
									0.0,
									0.002,
									0.011
								],
								"e": 0.014
							},
							{
								"h": [
									0.003,
									0.0,
									0.0,
									0.009,
									0.008,
									0.01,
									0.002,
									0.003,
									0.005,
									0.005,
									0.001,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.007,
									0.01,
									0.027,
									0.005,
									0.002,
									0.025,
									0.003,
									0.002,
									0.004,
									0.007,
									0.021,
									0.011
								],
								"e": 0.011
							},
							{
								"h": [
									0.002,
									0.006,
									0.003,
									0.038,
									0.004,
									0.012,
									0.001,
									0.046,
									0.047,
									0.001,
									0.003,
									0.013
								],
								"e": 0.01
							},
							{
								"h": [
									0.011,
									0.002,
									0.006,
									0.038,
									0.051,
									0.006,
									0.003,
									0.004,
									0.009,
									0.007,
									0.001,
									0.004
								],
								"e": 0.011
							},
							{
								"h": [
									0.752,
									0.025,
									0.01,
									0.062,
									0.043,
									0.013,
									0.005,
									0.019,
									0.829,
									0.003,
									0.104,
									0.026
								],
								"e": 0.063
							}
						],
						[
							{
								"h": [
									0.145,
									0.002,
									0.311,
									0.0,
									0.011,
									0.649,
									0.031,
									0.03,
									0.518,
									0.75,
									0.344,
									0.302
								],
								"e": 0.217
							},
							{
								"h": [
									0.052,
									0.068,
									0.4,
									0.04,
									0.168,
									0.076,
									0.405,
									0.196,
									0.314,
									0.353,
									0.295,
									0.092
								],
								"e": 0.27
							},
							{
								"h": [
									0.006,
									0.063,
									0.0,
									0.008,
									0.001,
									0.002,
									0.038,
									0.097,
									0.003,
									0.0,
									0.101,
									0.014
								],
								"e": 0.031
							},
							{
								"h": [
									0.015,
									0.021,
									0.002,
									0.0,
									0.011,
									0.061,
									0.017,
									0.0,
									0.002,
									0.015,
									0.01,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.015,
									0.001,
									0.076,
									0.007,
									0.064,
									0.005,
									0.006,
									0.024,
									0.01,
									0.035,
									0.017,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.001,
									0.0,
									0.003,
									0.016,
									0.002,
									0.005,
									0.0,
									0.006,
									0.005,
									0.017,
									0.012,
									0.007
								],
								"e": 0.006
							},
							{
								"h": [
									0.018,
									0.008,
									0.028,
									0.01,
									0.032,
									0.008,
									0.013,
									0.01,
									0.026,
									0.001,
									0.003,
									0.009
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.012,
									0.002,
									0.012,
									0.001,
									0.001,
									0.016,
									0.009,
									0.0,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.044,
									0.003,
									0.026,
									0.003,
									0.007,
									0.038,
									0.003,
									0.001,
									0.001,
									0.008,
									0.008,
									0.002
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.002,
									0.003,
									0.017,
									0.001,
									0.004,
									0.0,
									0.035,
									0.024,
									0.001,
									0.005,
									0.01
								],
								"e": 0.006
							},
							{
								"h": [
									0.004,
									0.001,
									0.004,
									0.034,
									0.015,
									0.004,
									0.002,
									0.004,
									0.004,
									0.003,
									0.001,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.572,
									0.037,
									0.01,
									0.039,
									0.024,
									0.005,
									0.005,
									0.027,
									0.391,
									0.001,
									0.033,
									0.014
								],
								"e": 0.039
							}
						],
						[
							{
								"h": [
									0.343,
									0.0,
									0.344,
									0.0,
									0.071,
									0.011,
									0.648,
									0.033,
									0.057,
									0.351,
									0.207,
									0.324
								],
								"e": 0.173
							},
							{
								"h": [
									0.062,
									0.039,
									0.224,
									0.034,
									0.209,
									0.012,
									0.485,
									0.244,
									0.124,
									0.073,
									0.348,
									0.002
								],
								"e": 0.189
							},
							{
								"h": [
									0.066,
									0.068,
									0.0,
									0.002,
									0.015,
									0.004,
									0.014,
									0.161,
									0.003,
									0.005,
									0.207,
									0.018
								],
								"e": 0.048
							},
							{
								"h": [
									0.007,
									0.002,
									0.0,
									0.001,
									0.008,
									0.049,
									0.012,
									0.002,
									0.001,
									0.024,
									0.033,
									0.036
								],
								"e": 0.025
							},
							{
								"h": [
									0.014,
									0.058,
									0.026,
									0.06,
									0.15,
									0.008,
									0.017,
									0.055,
									0.007,
									0.012,
									0.014,
									0.0
								],
								"e": 0.043
							},
							{
								"h": [
									0.001,
									0.0,
									0.005,
									0.009,
									0.039,
									0.012,
									0.029,
									0.305,
									0.001,
									0.014,
									0.166,
									0.038
								],
								"e": 0.042
							},
							{
								"h": [
									0.017,
									0.023,
									0.01,
									0.018,
									0.014,
									0.008,
									0.027,
									0.067,
									0.074,
									0.001,
									0.015,
									0.035
								],
								"e": 0.023
							},
							{
								"h": [
									0.007,
									0.0,
									0.001,
									0.025,
									0.001,
									0.014,
									0.001,
									0.001,
									0.008,
									0.002,
									0.001,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.009,
									0.034,
									0.0,
									0.013,
									0.009,
									0.048,
									0.006,
									0.007,
									0.011,
									0.008,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.0,
									0.002,
									0.04,
									0.002,
									0.006,
									0.0,
									0.029,
									0.019,
									0.0,
									0.003,
									0.005
								],
								"e": 0.005
							},
							{
								"h": [
									0.006,
									0.001,
									0.002,
									0.016,
									0.017,
									0.002,
									0.003,
									0.009,
									0.002,
									0.002,
									0.001,
									0.005
								],
								"e": 0.005
							},
							{
								"h": [
									0.447,
									0.006,
									0.002,
									0.054,
									0.004,
									0.002,
									0.002,
									0.003,
									0.092,
									0.001,
									0.049,
									0.006
								],
								"e": 0.019
							}
						],
						[
							{
								"h": [
									0.084,
									0.888,
									0.522,
									0.002,
									0.02,
									1.0,
									0.026,
									0.024,
									0.251,
									0.767,
									0.997,
									0.224
								],
								"e": 0.469
							},
							{
								"h": [
									0.009,
									0.033,
									0.456,
									0.039,
									0.145,
									1.0,
									0.184,
									0.204,
									0.21,
									0.083,
									0.281,
									0.66
								],
								"e": 0.475
							},
							{
								"h": [
									0.052,
									0.086,
									0.0,
									0.001,
									0.001,
									0.001,
									0.049,
									0.111,
									0.003,
									0.0,
									0.122,
									0.014
								],
								"e": 0.041
							},
							{
								"h": [
									0.004,
									0.003,
									0.0,
									0.0,
									0.006,
									0.047,
									0.002,
									0.0,
									0.001,
									0.005,
									0.056,
									0.001
								],
								"e": 0.016
							},
							{
								"h": [
									0.006,
									0.001,
									0.015,
									0.004,
									0.084,
									0.005,
									0.01,
									0.365,
									0.016,
									0.003,
									0.021,
									0.0
								],
								"e": 0.046
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.002,
									0.005,
									0.001,
									0.0,
									0.014,
									0.001,
									0.004,
									0.093,
									0.233
								],
								"e": 0.026
							},
							{
								"h": [
									0.005,
									0.005,
									0.04,
									0.004,
									0.006,
									0.008,
									0.095,
									0.023,
									0.002,
									0.0,
									0.005,
									0.004
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.0,
									0.001,
									0.01,
									0.001,
									0.004,
									0.001,
									0.007,
									0.002,
									0.002,
									0.001,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.018,
									0.017,
									0.018,
									0.002,
									0.001,
									0.007,
									0.011,
									0.009,
									0.001,
									0.006,
									0.021,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.003,
									0.002,
									0.002,
									0.039,
									0.002,
									0.027,
									0.001,
									0.017,
									0.006,
									0.0,
									0.001,
									0.009
								],
								"e": 0.006
							},
							{
								"h": [
									0.008,
									0.002,
									0.004,
									0.039,
									0.016,
									0.002,
									0.003,
									0.011,
									0.012,
									0.008,
									0.001,
									0.041
								],
								"e": 0.011
							},
							{
								"h": [
									0.514,
									0.06,
									0.033,
									0.193,
									0.034,
									0.01,
									0.007,
									0.043,
									0.501,
									0.003,
									0.181,
									0.062
								],
								"e": 0.07
							}
						],
						[
							{
								"h": [
									0.152,
									0.001,
									0.288,
									0.002,
									0.021,
									0.528,
									0.031,
									0.077,
									0.675,
									0.759,
									0.318,
									0.298
								],
								"e": 0.221
							},
							{
								"h": [
									0.106,
									0.107,
									0.471,
									0.046,
									0.197,
									0.068,
									0.33,
									0.194,
									0.281,
									0.307,
									0.355,
									0.075
								],
								"e": 0.273
							},
							{
								"h": [
									0.01,
									0.06,
									0.0,
									0.025,
									0.005,
									0.013,
									0.052,
									0.14,
									0.007,
									0.001,
									0.151,
									0.022
								],
								"e": 0.044
							},
							{
								"h": [
									0.01,
									0.011,
									0.005,
									0.0,
									0.01,
									0.031,
									0.03,
									0.0,
									0.001,
									0.007,
									0.015,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.002,
									0.001,
									0.009,
									0.005,
									0.04,
									0.009,
									0.008,
									0.061,
									0.016,
									0.011,
									0.024,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.068,
									0.287,
									0.001,
									0.004,
									0.002,
									0.111,
									0.0,
									0.013,
									0.082,
									0.006,
									0.015,
									0.035
								],
								"e": 0.062
							},
							{
								"h": [
									0.005,
									0.027,
									0.024,
									0.009,
									0.014,
									0.008,
									0.035,
									0.016,
									0.04,
									0.197,
									0.007,
									0.004
								],
								"e": 0.039
							},
							{
								"h": [
									0.001,
									0.0,
									0.04,
									0.006,
									0.001,
									0.003,
									0.001,
									0.027,
									0.004,
									0.004,
									0.036,
									0.1
								],
								"e": 0.021
							},
							{
								"h": [
									0.038,
									1.0,
									0.006,
									0.008,
									0.002,
									0.006,
									0.024,
									0.008,
									0.0,
									0.008,
									0.027,
									0.002
								],
								"e": 0.085
							},
							{
								"h": [
									0.001,
									0.007,
									0.003,
									0.022,
									0.004,
									0.014,
									0.003,
									0.032,
									0.039,
									0.004,
									0.001,
									0.036
								],
								"e": 0.011
							},
							{
								"h": [
									0.007,
									0.003,
									0.021,
									0.064,
									0.005,
									0.003,
									0.006,
									0.015,
									0.145,
									0.003,
									0.003,
									0.026
								],
								"e": 0.024
							},
							{
								"h": [
									0.427,
									0.107,
									0.037,
									0.11,
									0.047,
									0.014,
									0.007,
									0.065,
									0.237,
									0.002,
									0.063,
									0.025
								],
								"e": 0.055
							}
						],
						[
							{
								"h": [
									0.228,
									0.0,
									0.211,
									0.001,
									0.049,
									0.013,
									0.509,
									0.101,
									0.116,
									0.491,
									0.175,
									0.482
								],
								"e": 0.164
							},
							{
								"h": [
									0.082,
									0.058,
									0.358,
									0.041,
									0.214,
									0.033,
									0.125,
									0.147,
									0.161,
									0.151,
									0.393,
									0.002
								],
								"e": 0.176
							},
							{
								"h": [
									0.074,
									0.04,
									0.0,
									0.015,
									0.01,
									0.004,
									0.017,
									0.19,
									0.003,
									0.01,
									0.178,
									0.022
								],
								"e": 0.047
							},
							{
								"h": [
									0.002,
									0.004,
									0.002,
									0.0,
									0.004,
									0.012,
									0.14,
									0.001,
									0.001,
									0.008,
									0.031,
									0.007
								],
								"e": 0.021
							},
							{
								"h": [
									0.002,
									0.014,
									0.014,
									0.048,
									0.013,
									0.029,
									0.008,
									0.021,
									0.025,
									0.002,
									0.003,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.129,
									0.017,
									0.001,
									0.007,
									0.001,
									0.081,
									0.006,
									0.017,
									0.059,
									0.022,
									0.019,
									0.011
								],
								"e": 0.035
							},
							{
								"h": [
									0.003,
									0.087,
									0.014,
									0.008,
									0.02,
									0.01,
									0.013,
									0.015,
									0.007,
									0.067,
									0.028,
									0.005
								],
								"e": 0.028
							},
							{
								"h": [
									0.0,
									0.001,
									0.011,
									0.013,
									0.0,
									0.002,
									0.003,
									0.027,
									0.004,
									0.006,
									0.005,
									0.006
								],
								"e": 0.006
							},
							{
								"h": [
									0.025,
									0.028,
									0.018,
									0.002,
									0.01,
									0.011,
									0.048,
									0.002,
									0.007,
									0.006,
									0.034,
									0.005
								],
								"e": 0.017
							},
							{
								"h": [
									0.002,
									0.005,
									0.003,
									0.006,
									0.011,
									0.007,
									0.001,
									0.068,
									0.029,
									0.003,
									0.0,
									0.04
								],
								"e": 0.012
							},
							{
								"h": [
									0.009,
									0.005,
									0.009,
									0.084,
									0.001,
									0.003,
									0.012,
									0.039,
									0.009,
									0.002,
									0.008,
									0.021
								],
								"e": 0.014
							},
							{
								"h": [
									0.458,
									0.006,
									0.008,
									0.006,
									0.043,
									0.005,
									0.005,
									0.009,
									0.094,
									0.001,
									0.17,
									0.005
								],
								"e": 0.027
							}
						],
						[
							{
								"h": [
									0.336,
									0.0,
									0.261,
									0.001,
									0.071,
									0.008,
									0.485,
									0.107,
									0.063,
									0.353,
									0.174,
									0.255
								],
								"e": 0.155
							},
							{
								"h": [
									0.126,
									0.094,
									0.511,
									0.054,
									0.25,
									0.019,
									0.17,
									0.285,
									0.223,
									0.107,
									0.544,
									0.008
								],
								"e": 0.233
							},
							{
								"h": [
									0.034,
									0.051,
									0.002,
									0.007,
									0.031,
									0.039,
									0.03,
									0.284,
									0.005,
									0.024,
									0.359,
									0.04
								],
								"e": 0.074
							},
							{
								"h": [
									0.003,
									0.009,
									0.002,
									0.002,
									0.009,
									0.028,
									0.131,
									0.003,
									0.002,
									0.011,
									0.035,
									0.007
								],
								"e": 0.026
							},
							{
								"h": [
									0.009,
									0.025,
									0.013,
									0.132,
									0.053,
									0.032,
									0.013,
									0.034,
									0.033,
									0.004,
									0.006,
									0.0
								],
								"e": 0.035
							},
							{
								"h": [
									0.058,
									0.008,
									0.001,
									0.013,
									0.003,
									0.006,
									0.013,
									0.041,
									0.016,
									0.023,
									0.104,
									0.008
								],
								"e": 0.022
							},
							{
								"h": [
									0.009,
									0.072,
									0.029,
									0.023,
									0.037,
									0.017,
									0.031,
									0.048,
									0.013,
									0.052,
									0.242,
									0.081
								],
								"e": 0.062
							},
							{
								"h": [
									0.002,
									0.004,
									0.007,
									0.058,
									0.001,
									0.022,
									0.017,
									1.0,
									0.001,
									0.009,
									0.003,
									0.02
								],
								"e": 0.058
							},
							{
								"h": [
									0.016,
									0.313,
									0.031,
									0.002,
									0.005,
									0.03,
									1.0,
									0.013,
									0.015,
									0.025,
									0.176,
									0.013
								],
								"e": 0.133
							},
							{
								"h": [
									0.01,
									0.019,
									0.013,
									0.033,
									0.01,
									0.015,
									0.004,
									0.288,
									0.186,
									0.005,
									0.001,
									0.078
								],
								"e": 0.038
							},
							{
								"h": [
									0.021,
									0.005,
									0.021,
									0.539,
									0.055,
									0.001,
									0.028,
									0.125,
									0.05,
									0.007,
									0.01,
									0.121
								],
								"e": 0.062
							},
							{
								"h": [
									0.534,
									0.016,
									0.017,
									0.039,
									0.048,
									0.006,
									0.002,
									0.025,
									0.039,
									0.002,
									0.234,
									0.008
								],
								"e": 0.036
							}
						],
						[
							{
								"h": [
									0.134,
									0.004,
									1.0,
									0.02,
									0.033,
									0.039,
									0.037,
									0.4,
									0.53,
									0.825,
									0.401,
									0.677
								],
								"e": 0.266
							},
							{
								"h": [
									0.121,
									1.0,
									0.873,
									0.091,
									0.267,
									0.071,
									0.242,
									0.339,
									0.461,
									0.08,
									0.71,
									0.178
								],
								"e": 0.476
							},
							{
								"h": [
									0.204,
									0.032,
									0.002,
									0.053,
									0.025,
									0.568,
									1.0,
									0.303,
									0.127,
									0.038,
									0.411,
									0.065
								],
								"e": 0.273
							},
							{
								"h": [
									0.007,
									0.379,
									0.002,
									0.002,
									0.018,
									0.054,
									0.099,
									0.005,
									0.07,
									0.166,
									0.056,
									0.02
								],
								"e": 0.089
							},
							{
								"h": [
									0.033,
									0.029,
									0.122,
									0.094,
									0.033,
									0.189,
									0.029,
									0.176,
									0.042,
									0.015,
									0.041,
									0.0
								],
								"e": 0.083
							},
							{
								"h": [
									0.309,
									0.001,
									0.003,
									0.106,
									0.058,
									0.04,
									0.004,
									0.014,
									0.142,
									0.031,
									0.079,
									0.014
								],
								"e": 0.068
							},
							{
								"h": [
									0.047,
									0.331,
									0.068,
									0.048,
									0.152,
									0.115,
									0.047,
									0.028,
									0.019,
									0.005,
									0.061,
									0.342
								],
								"e": 0.103
							},
							{
								"h": [
									0.029,
									0.002,
									0.003,
									0.077,
									0.01,
									0.097,
									0.007,
									0.667,
									0.007,
									0.075,
									0.009,
									0.004
								],
								"e": 0.053
							},
							{
								"h": [
									0.031,
									0.153,
									0.038,
									0.022,
									0.018,
									0.147,
									0.078,
									0.052,
									0.008,
									0.008,
									0.293,
									0.018
								],
								"e": 0.063
							},
							{
								"h": [
									0.001,
									0.006,
									0.01,
									0.21,
									0.003,
									0.003,
									0.002,
									0.181,
									0.108,
									0.005,
									0.023,
									0.04
								],
								"e": 0.029
							},
							{
								"h": [
									0.024,
									0.004,
									0.011,
									0.123,
									0.036,
									0.011,
									0.002,
									0.015,
									0.024,
									0.06,
									0.01,
									0.021
								],
								"e": 0.025
							},
							{
								"h": [
									0.382,
									0.041,
									0.017,
									0.13,
									0.069,
									0.007,
									0.008,
									0.022,
									0.335,
									0.007,
									0.281,
									0.398
								],
								"e": 0.087
							}
						],
						[
							{
								"h": [
									0.215,
									0.0,
									0.667,
									0.004,
									0.157,
									0.014,
									0.574,
									0.179,
									0.04,
									0.547,
									0.192,
									0.394
								],
								"e": 0.212
							},
							{
								"h": [
									0.152,
									0.204,
									0.724,
									0.079,
									0.416,
									0.017,
									0.087,
									0.331,
									0.248,
									0.195,
									0.864,
									0.01
								],
								"e": 0.321
							},
							{
								"h": [
									0.546,
									0.088,
									0.02,
									0.116,
									0.01,
									0.217,
									0.088,
									0.554,
									0.051,
									0.231,
									0.75,
									0.077
								],
								"e": 0.251
							},
							{
								"h": [
									0.001,
									0.168,
									0.002,
									0.001,
									0.01,
									0.179,
									0.302,
									0.022,
									0.093,
									0.05,
									0.058,
									0.014
								],
								"e": 0.093
							},
							{
								"h": [
									0.191,
									0.01,
									0.085,
									0.657,
									0.03,
									0.012,
									0.124,
									0.108,
									0.021,
									0.044,
									0.023,
									0.0
								],
								"e": 0.109
							},
							{
								"h": [
									0.002,
									0.001,
									0.006,
									0.176,
									0.038,
									0.015,
									0.004,
									0.01,
									0.028,
									0.034,
									1.0,
									0.026
								],
								"e": 0.083
							},
							{
								"h": [
									0.249,
									0.062,
									0.081,
									0.075,
									0.017,
									0.085,
									0.077,
									0.486,
									0.089,
									0.006,
									0.007,
									0.369
								],
								"e": 0.116
							},
							{
								"h": [
									0.012,
									0.002,
									0.003,
									0.31,
									0.017,
									0.08,
									0.015,
									0.024,
									0.241,
									0.114,
									0.002,
									0.008
								],
								"e": 0.051
							},
							{
								"h": [
									0.012,
									0.014,
									0.06,
									0.006,
									0.101,
									0.138,
									0.072,
									0.06,
									0.04,
									0.067,
									0.408,
									1.0
								],
								"e": 0.169
							},
							{
								"h": [
									0.046,
									0.033,
									0.07,
									0.433,
									0.008,
									0.067,
									0.037,
									0.231,
									0.964,
									0.051,
									0.025,
									0.025
								],
								"e": 0.101
							},
							{
								"h": [
									0.509,
									0.017,
									0.049,
									0.329,
									0.105,
									0.009,
									0.045,
									1.0,
									0.013,
									0.036,
									0.062,
									0.141
								],
								"e": 0.178
							},
							{
								"h": [
									0.739,
									0.028,
									0.025,
									0.16,
									0.065,
									0.01,
									0.017,
									0.021,
									0.141,
									0.007,
									0.484,
									0.016
								],
								"e": 0.069
							}
						],
						[
							{
								"h": [
									0.251,
									0.001,
									0.358,
									0.031,
									0.2,
									0.091,
									0.104,
									0.57,
									0.727,
									0.754,
									0.373,
									0.525
								],
								"e": 0.303
							},
							{
								"h": [
									0.408,
									0.643,
									1.0,
									0.092,
									0.309,
									0.017,
									0.232,
									0.179,
									0.342,
									0.231,
									0.841,
									0.021
								],
								"e": 0.44
							},
							{
								"h": [
									0.233,
									0.061,
									0.049,
									0.275,
									0.042,
									1.0,
									0.217,
									0.737,
									1.0,
									0.065,
									0.713,
									0.099
								],
								"e": 0.44
							},
							{
								"h": [
									0.004,
									0.447,
									0.085,
									0.008,
									0.021,
									0.181,
									0.334,
									0.045,
									1.0,
									0.804,
									0.073,
									0.029
								],
								"e": 0.309
							},
							{
								"h": [
									0.501,
									0.081,
									0.073,
									0.228,
									0.038,
									0.058,
									0.105,
									0.05,
									0.017,
									0.203,
									0.009,
									0.0
								],
								"e": 0.121
							},
							{
								"h": [
									0.001,
									0.0,
									0.153,
									0.502,
									0.079,
									0.001,
									0.003,
									0.011,
									0.014,
									0.023,
									0.205,
									0.033
								],
								"e": 0.074
							},
							{
								"h": [
									1.0,
									0.01,
									0.071,
									0.04,
									0.018,
									0.043,
									0.036,
									0.086,
									0.541,
									0.0,
									0.004,
									0.539
								],
								"e": 0.148
							},
							{
								"h": [
									0.118,
									0.0,
									0.0,
									0.175,
									0.076,
									0.17,
									0.003,
									0.006,
									0.077,
									0.088,
									0.0,
									0.0
								],
								"e": 0.047
							},
							{
								"h": [
									0.014,
									0.005,
									0.02,
									0.001,
									0.051,
									1.0,
									0.002,
									0.19,
									0.002,
									0.019,
									0.068,
									0.041
								],
								"e": 0.083
							},
							{
								"h": [
									0.004,
									0.003,
									0.005,
									1.0,
									0.001,
									0.036,
									0.001,
									0.031,
									0.07,
									0.001,
									0.061,
									0.004
								],
								"e": 0.044
							},
							{
								"h": [
									0.018,
									0.003,
									0.006,
									0.079,
									0.107,
									0.051,
									0.004,
									0.004,
									0.005,
									0.044,
									0.002,
									0.015
								],
								"e": 0.027
							},
							{
								"h": [
									0.467,
									0.03,
									0.012,
									0.057,
									0.027,
									0.004,
									0.003,
									0.013,
									1.0,
									0.001,
									0.085,
									0.011
								],
								"e": 0.058
							}
						],
						[
							{
								"h": [
									0.19,
									0.001,
									0.293,
									0.083,
									0.155,
									0.412,
									0.039,
									0.974,
									1.0,
									0.876,
									0.305,
									0.304
								],
								"e": 0.377
							},
							{
								"h": [
									0.949,
									0.64,
									0.94,
									0.082,
									0.34,
									0.068,
									0.368,
									0.232,
									0.344,
									0.314,
									0.724,
									0.122
								],
								"e": 0.576
							},
							{
								"h": [
									0.111,
									0.061,
									0.032,
									1.0,
									0.102,
									0.491,
									0.191,
									0.49,
									0.303,
									0.085,
									0.488,
									0.085
								],
								"e": 0.388
							},
							{
								"h": [
									0.006,
									0.572,
									1.0,
									0.013,
									0.011,
									0.158,
									1.0,
									0.077,
									0.505,
									0.396,
									0.049,
									0.014
								],
								"e": 0.488
							},
							{
								"h": [
									0.77,
									0.075,
									0.093,
									0.119,
									0.086,
									0.188,
									0.056,
									0.096,
									0.013,
									0.449,
									0.012,
									0.0
								],
								"e": 0.173
							},
							{
								"h": [
									0.001,
									0.0,
									0.052,
									0.139,
									0.043,
									0.004,
									0.0,
									0.055,
									0.021,
									0.015,
									0.018,
									0.017
								],
								"e": 0.028
							},
							{
								"h": [
									0.294,
									0.021,
									0.05,
									0.059,
									0.07,
									0.051,
									0.036,
									0.04,
									0.949,
									0.0,
									0.006,
									0.423
								],
								"e": 0.116
							},
							{
								"h": [
									0.12,
									0.0,
									0.0,
									0.195,
									0.017,
									0.117,
									0.003,
									0.003,
									0.172,
									0.175,
									0.0,
									0.002
								],
								"e": 0.052
							},
							{
								"h": [
									0.127,
									0.001,
									0.017,
									0.003,
									0.178,
									0.744,
									0.002,
									0.139,
									0.0,
									0.029,
									0.053,
									0.009
								],
								"e": 0.087
							},
							{
								"h": [
									0.002,
									0.002,
									0.009,
									0.294,
									0.001,
									0.014,
									0.0,
									0.055,
									0.063,
									0.001,
									0.076,
									0.008
								],
								"e": 0.024
							},
							{
								"h": [
									0.008,
									0.001,
									0.006,
									0.07,
									0.021,
									0.036,
									0.002,
									0.005,
									0.004,
									0.032,
									0.001,
									0.004
								],
								"e": 0.014
							},
							{
								"h": [
									0.312,
									0.062,
									0.014,
									0.034,
									0.022,
									0.002,
									0.004,
									0.026,
									0.194,
									0.001,
									0.019,
									0.006
								],
								"e": 0.028
							}
						],
						[
							{
								"h": [
									0.479,
									0.0,
									0.35,
									0.029,
									1.0,
									0.006,
									1.0,
									0.851,
									0.088,
									0.404,
									0.189,
									0.336
								],
								"e": 0.466
							},
							{
								"h": [
									1.0,
									0.372,
									0.767,
									0.085,
									0.534,
									0.008,
									0.235,
									0.169,
									0.189,
									0.121,
									1.0,
									0.003
								],
								"e": 0.477
							},
							{
								"h": [
									0.592,
									0.033,
									1.0,
									0.204,
									1.0,
									0.881,
									0.057,
									1.0,
									0.219,
									1.0,
									1.0,
									0.105
								],
								"e": 1.0
							},
							{
								"h": [
									0.002,
									0.071,
									0.046,
									1.0,
									0.008,
									0.142,
									0.601,
									1.0,
									0.15,
									0.643,
									0.216,
									1.0
								],
								"e": 0.815
							},
							{
								"h": [
									1.0,
									1.0,
									0.035,
									1.0,
									0.283,
									0.195,
									0.094,
									0.135,
									0.009,
									0.093,
									0.008,
									1.0
								],
								"e": 0.567
							},
							{
								"h": [
									0.0,
									0.0,
									0.073,
									0.043,
									0.494,
									0.009,
									0.192,
									1.0,
									0.007,
									0.008,
									0.158,
									0.044
								],
								"e": 0.146
							},
							{
								"h": [
									0.158,
									0.041,
									0.02,
									0.057,
									0.032,
									0.042,
									0.031,
									0.081,
									1.0,
									0.0,
									0.05,
									1.0
								],
								"e": 0.134
							},
							{
								"h": [
									0.329,
									0.0,
									0.0,
									0.6,
									0.018,
									0.134,
									0.002,
									0.001,
									0.098,
									0.065,
									0.0,
									0.0
								],
								"e": 0.072
							},
							{
								"h": [
									0.011,
									0.002,
									0.01,
									0.001,
									0.209,
									0.171,
									0.027,
									0.379,
									0.006,
									0.019,
									0.047,
									0.002
								],
								"e": 0.066
							},
							{
								"h": [
									0.002,
									0.0,
									0.004,
									0.529,
									0.002,
									0.011,
									0.0,
									0.031,
									0.023,
									0.0,
									0.05,
									0.002
								],
								"e": 0.024
							},
							{
								"h": [
									0.005,
									0.0,
									0.001,
									0.018,
									0.018,
									0.009,
									0.003,
									0.009,
									0.001,
									0.021,
									0.001,
									0.005
								],
								"e": 0.007
							},
							{
								"h": [
									0.352,
									0.003,
									0.001,
									0.023,
									0.001,
									0.001,
									0.0,
									0.001,
									0.065,
									0.0,
									0.05,
									0.004
								],
								"e": 0.013
							}
						],
						[
							{
								"h": [
									0.116,
									1.0,
									0.544,
									1.0,
									0.421,
									0.758,
									0.035,
									1.0,
									0.428,
									0.944,
									1.0,
									0.226
								],
								"e": 1.0
							},
							{
								"h": [
									0.147,
									0.304,
									0.996,
									0.078,
									0.291,
									0.992,
									0.162,
									0.227,
									0.212,
									0.07,
									0.666,
									1.0
								],
								"e": 0.68
							},
							{
								"h": [
									1.0,
									0.072,
									0.012,
									0.153,
									0.086,
									0.498,
									0.244,
									0.485,
									0.367,
									0.066,
									0.581,
									0.087
								],
								"e": 0.349
							},
							{
								"h": [
									0.001,
									0.162,
									0.025,
									0.045,
									0.006,
									0.128,
									0.352,
									0.103,
									0.402,
									0.24,
									0.379,
									0.057
								],
								"e": 0.219
							},
							{
								"h": [
									0.234,
									0.025,
									0.016,
									0.09,
									0.085,
									0.148,
									0.08,
									1.0,
									0.019,
									0.024,
									0.011,
									0.0
								],
								"e": 0.143
							},
							{
								"h": [
									0.001,
									0.0,
									0.005,
									0.013,
									0.089,
									0.001,
									0.002,
									0.077,
									0.003,
									0.004,
									0.078,
									0.304
								],
								"e": 0.042
							},
							{
								"h": [
									0.064,
									0.016,
									0.049,
									0.015,
									0.015,
									0.061,
									0.169,
									0.078,
									0.049,
									0.0,
									0.018,
									0.114
								],
								"e": 0.052
							},
							{
								"h": [
									0.071,
									0.0,
									0.001,
									0.149,
									0.004,
									0.023,
									0.004,
									0.006,
									0.012,
									0.044,
									0.0,
									0.003
								],
								"e": 0.019
							},
							{
								"h": [
									0.078,
									0.003,
									0.012,
									0.005,
									0.015,
									0.085,
									0.015,
									0.324,
									0.001,
									0.016,
									0.187,
									0.007
								],
								"e": 0.054
							},
							{
								"h": [
									0.005,
									0.001,
									0.002,
									0.449,
									0.001,
									0.04,
									0.0,
									0.016,
									0.004,
									0.0,
									0.02,
									0.008
								],
								"e": 0.021
							},
							{
								"h": [
									0.006,
									0.001,
									0.005,
									0.099,
									0.011,
									0.008,
									0.007,
									0.007,
									0.013,
									0.059,
									0.001,
									0.064
								],
								"e": 0.021
							},
							{
								"h": [
									0.276,
									0.063,
									0.027,
									0.069,
									0.027,
									0.004,
									0.006,
									0.029,
									0.278,
									0.001,
									0.116,
									0.042
								],
								"e": 0.043
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " the",
					"o": " United",
					"t": " United",
					"r": [
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.798,
								" latter": 0.044,
								" first": 0.016,
								" most": 0.011,
								" way": 0.008,
								" world": 0.007,
								" last": 0.003,
								" second": 0.003,
								" best": 0.003,
								" next": 0.003
							}
						},
						{
							"p": " same",
							"b": 0.001,
							"k": {
								" same": 0.86,
								" latter": 0.051,
								" first": 0.012,
								" world": 0.007,
								" most": 0.006,
								" second": 0.003,
								" way": 0.002,
								" following": 0.002,
								" entire": 0.002,
								" majority": 0.002
							}
						},
						{
							"p": " same",
							"b": 0.001,
							"k": {
								" same": 0.909,
								" world": 0.011,
								" first": 0.008,
								" latter": 0.007,
								" largest": 0.004,
								" most": 0.003,
								" highest": 0.003,
								" second": 0.002,
								" entire": 0.002,
								" vast": 0.002
							}
						},
						{
							"p": " same",
							"b": 0.005,
							"k": {
								" same": 0.681,
								" world": 0.062,
								" largest": 0.057,
								" highest": 0.024,
								" first": 0.018,
								" most": 0.009,
								" nation": 0.009,
								" latter": 0.008,
								" entire": 0.007,
								" city": 0.006
							}
						},
						{
							"p": " largest",
							"b": 0.005,
							"k": {
								" largest": 0.259,
								" same": 0.249,
								" world": 0.106,
								" city": 0.048,
								" highest": 0.034,
								" country": 0.028,
								" entire": 0.025,
								" most": 0.02,
								" nation": 0.016,
								" first": 0.013
							}
						},
						{
							"p": " same",
							"b": 0.023,
							"k": {
								" same": 0.533,
								" largest": 0.074,
								" city": 0.035,
								" nation": 0.031,
								" entire": 0.029,
								" country": 0.027,
								" world": 0.024,
								" United": 0.023,
								" majority": 0.018,
								" most": 0.015
							}
						},
						{
							"p": " nation",
							"b": 0.244,
							"k": {
								" nation": 0.366,
								" United": 0.244,
								" same": 0.1,
								" largest": 0.074,
								" country": 0.071,
								" state": 0.025,
								" majority": 0.015,
								" world": 0.011,
								" city": 0.01,
								" US": 0.009
							}
						},
						{
							"p": " United",
							"b": 0.475,
							"k": {
								" United": 0.475,
								" nation": 0.363,
								" country": 0.07,
								" world": 0.013,
								" state": 0.01,
								" Republic": 0.009,
								" South": 0.007,
								" US": 0.004,
								" West": 0.004,
								" USA": 0.004
							}
						},
						{
							"p": " United",
							"b": 0.947,
							"k": {
								" United": 0.947,
								" country": 0.012,
								" nation": 0.008,
								" British": 0.007,
								" Netherlands": 0.006,
								" Republic": 0.003,
								" West": 0.003,
								" Kingdom": 0.003,
								" South": 0.002,
								" European": 0.002
							}
						},
						{
							"p": " United",
							"b": 0.989,
							"k": {
								" United": 0.989,
								" Netherlands": 0.004,
								" European": 0.002,
								" British": 0.002,
								" country": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": " United",
							"b": 0.938,
							"k": {
								" United": 0.938,
								" Netherlands": 0.021,
								" European": 0.01,
								" British": 0.008,
								" Kingdom": 0.006,
								" world": 0.004,
								" UK": 0.003,
								" Republic": 0.002,
								" Commonwealth": 0.002,
								" country": 0.001
							}
						},
						{
							"p": " United",
							"b": 0.507,
							"k": {
								" United": 0.507,
								" European": 0.07,
								" British": 0.043,
								" world": 0.037,
								" Commonwealth": 0.026,
								" UK": 0.018,
								" Kingdom": 0.017,
								" Netherlands": 0.013,
								" Republic": 0.01,
								" English": 0.009
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.002,
									1.0,
									0.004,
									0.134,
									0.094,
									0.965,
									0.045,
									0.018,
									1.0,
									0.825,
									1.0
								],
								"e": 0.472
							},
							{
								"h": [
									0.082,
									0.173,
									0.506,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.021,
									0.099
								],
								"e": 1.0
							},
							{
								"h": [
									0.303,
									1.0,
									0.013,
									0.162,
									0.917,
									0.378,
									0.529,
									0.386,
									0.147,
									0.09,
									0.687,
									1.0
								],
								"e": 0.89
							},
							{
								"h": [
									1.0,
									1.0,
									0.335,
									0.124,
									1.0,
									1.0,
									0.233,
									0.103,
									0.271,
									1.0,
									1.0,
									0.203
								],
								"e": 1.0
							},
							{
								"h": [
									0.237,
									0.087,
									1.0,
									0.81,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.58,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.591,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.758,
									1.0,
									1.0,
									0.726
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.737,
									0.557,
									0.261,
									1.0,
									1.0,
									1.0,
									0.715
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.21,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.561,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.149,
									0.016,
									0.475,
									0.0,
									0.025,
									0.187,
									0.009,
									0.01,
									0.153,
									0.681,
									0.508,
									0.44
								],
								"e": 0.18
							},
							{
								"h": [
									0.009,
									0.052,
									0.194,
									0.026,
									0.145,
									0.81,
									0.169,
									0.145,
									0.405,
									0.118,
									0.205,
									0.04
								],
								"e": 0.216
							},
							{
								"h": [
									0.015,
									0.023,
									0.0,
									0.002,
									0.011,
									0.007,
									0.024,
									0.024,
									0.002,
									0.0,
									0.061,
									0.006
								],
								"e": 0.021
							},
							{
								"h": [
									0.006,
									0.014,
									0.0,
									0.0,
									0.008,
									0.02,
									0.001,
									0.0,
									0.001,
									0.004,
									0.002,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.0,
									0.028,
									0.004,
									0.015,
									0.004,
									0.006,
									0.002,
									0.004,
									0.003,
									0.018,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.0,
									0.005,
									0.053,
									0.002,
									0.0,
									0.0,
									0.003,
									0.001,
									0.018,
									0.11,
									0.006
								],
								"e": 0.012
							},
							{
								"h": [
									0.011,
									0.004,
									0.035,
									0.019,
									0.054,
									0.005,
									0.015,
									0.068,
									0.002,
									0.0,
									0.001,
									0.007
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.005,
									0.001,
									0.01,
									0.007,
									0.003,
									0.021,
									0.004,
									0.0,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.004,
									0.003,
									0.02,
									0.002,
									0.002,
									0.009,
									0.004,
									0.0,
									0.024,
									0.014,
									0.007,
									0.023
								],
								"e": 0.011
							},
							{
								"h": [
									0.001,
									0.002,
									0.002,
									0.004,
									0.001,
									0.012,
									0.001,
									0.013,
									0.023,
									0.0,
									0.002,
									0.004
								],
								"e": 0.004
							},
							{
								"h": [
									0.007,
									0.002,
									0.007,
									0.038,
									0.005,
									0.001,
									0.005,
									0.023,
									0.004,
									0.001,
									0.002,
									0.007
								],
								"e": 0.006
							},
							{
								"h": [
									0.257,
									0.046,
									0.027,
									0.171,
									0.085,
									0.008,
									0.005,
									0.029,
									0.066,
									0.002,
									0.077,
									0.038
								],
								"e": 0.044
							}
						],
						[
							{
								"h": [
									0.172,
									0.001,
									0.404,
									0.0,
									0.023,
									0.06,
									0.584,
									0.006,
									0.014,
									0.505,
									0.27,
									0.324
								],
								"e": 0.172
							},
							{
								"h": [
									0.005,
									0.024,
									0.228,
									0.028,
									0.171,
									0.143,
									0.105,
									0.137,
									0.245,
									0.129,
									0.283,
									0.003
								],
								"e": 0.138
							},
							{
								"h": [
									0.011,
									0.031,
									0.0,
									0.003,
									0.001,
									0.001,
									0.009,
									0.094,
									0.0,
									0.001,
									0.118,
									0.01
								],
								"e": 0.028
							},
							{
								"h": [
									0.001,
									0.003,
									0.0,
									0.0,
									0.003,
									0.035,
									0.003,
									0.0,
									0.0,
									0.002,
									0.008,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.0,
									0.021,
									0.003,
									0.009,
									0.001,
									0.006,
									0.019,
									0.003,
									0.001,
									0.006,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.019,
									0.001,
									0.001,
									0.0,
									0.002,
									0.001,
									0.008,
									0.061,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.009,
									0.001,
									0.03,
									0.006,
									0.023,
									0.009,
									0.014,
									0.048,
									0.008,
									0.0,
									0.002,
									0.005
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.004,
									0.0,
									0.008,
									0.014,
									0.001,
									0.006,
									0.004,
									0.0,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.006,
									0.0,
									0.137,
									0.001,
									0.001,
									0.004,
									0.003,
									0.0,
									0.019,
									0.007,
									0.016,
									0.006
								],
								"e": 0.02
							},
							{
								"h": [
									0.001,
									0.004,
									0.003,
									0.002,
									0.0,
									0.012,
									0.001,
									0.062,
									0.053,
									0.0,
									0.0,
									0.012
								],
								"e": 0.008
							},
							{
								"h": [
									0.006,
									0.0,
									0.035,
									0.016,
									0.023,
									0.0,
									0.005,
									0.033,
									0.002,
									0.001,
									0.003,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.137,
									0.014,
									0.033,
									0.018,
									0.084,
									0.005,
									0.013,
									0.017,
									0.009,
									0.002,
									0.03,
									0.01
								],
								"e": 0.022
							}
						],
						[
							{
								"h": [
									0.178,
									0.001,
									0.585,
									0.0,
									0.054,
									0.48,
									0.032,
									0.013,
									0.314,
									0.614,
									0.435,
									0.378
								],
								"e": 0.232
							},
							{
								"h": [
									0.033,
									0.074,
									0.344,
									0.038,
									0.15,
									0.338,
									0.233,
									0.156,
									0.317,
									0.286,
									0.321,
									0.009
								],
								"e": 0.221
							},
							{
								"h": [
									0.004,
									0.079,
									0.0,
									0.001,
									0.007,
									0.006,
									0.051,
									0.121,
									0.02,
									0.0,
									0.143,
									0.014
								],
								"e": 0.053
							},
							{
								"h": [
									0.007,
									0.014,
									0.002,
									0.0,
									0.019,
									0.055,
									0.002,
									0.0,
									0.002,
									0.04,
									0.007,
									0.002
								],
								"e": 0.018
							},
							{
								"h": [
									0.003,
									0.002,
									0.024,
									0.005,
									0.011,
									0.004,
									0.006,
									0.021,
									0.01,
									0.009,
									0.017,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.0,
									0.004,
									0.047,
									0.011,
									0.002,
									0.001,
									0.003,
									0.007,
									0.016,
									0.078,
									0.007
								],
								"e": 0.011
							},
							{
								"h": [
									0.072,
									0.005,
									0.022,
									0.011,
									0.02,
									0.01,
									0.017,
									0.037,
									0.014,
									0.0,
									0.002,
									0.017
								],
								"e": 0.015
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.01,
									0.001,
									0.013,
									0.003,
									0.005,
									0.005,
									0.007,
									0.001,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.006,
									0.006,
									0.045,
									0.003,
									0.002,
									0.022,
									0.005,
									0.001,
									0.009,
									0.011,
									0.014,
									0.01
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.008,
									0.003,
									0.017,
									0.001,
									0.01,
									0.001,
									0.023,
									0.057,
									0.0,
									0.006,
									0.006
								],
								"e": 0.007
							},
							{
								"h": [
									0.011,
									0.001,
									0.007,
									0.022,
									0.038,
									0.003,
									0.005,
									0.008,
									0.004,
									0.001,
									0.001,
									0.004
								],
								"e": 0.008
							},
							{
								"h": [
									0.48,
									0.01,
									0.012,
									0.032,
									0.063,
									0.008,
									0.004,
									0.01,
									0.279,
									0.002,
									0.093,
									0.011
								],
								"e": 0.038
							}
						],
						[
							{
								"h": [
									0.085,
									0.68,
									0.161,
									0.001,
									0.028,
									1.0,
									0.005,
									0.01,
									0.386,
									0.609,
									0.946,
									0.468
								],
								"e": 0.38
							},
							{
								"h": [
									0.023,
									0.03,
									0.381,
									0.038,
									0.161,
									0.291,
									0.197,
									0.209,
									0.339,
									0.339,
									0.307,
									0.372
								],
								"e": 0.278
							},
							{
								"h": [
									0.004,
									0.044,
									0.0,
									0.002,
									0.004,
									0.0,
									0.03,
									0.105,
									0.0,
									0.0,
									0.105,
									0.012
								],
								"e": 0.033
							},
							{
								"h": [
									0.047,
									0.013,
									0.0,
									0.0,
									0.016,
									0.034,
									0.001,
									0.0,
									0.0,
									0.015,
									0.008,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.001,
									0.001,
									0.042,
									0.002,
									0.044,
									0.002,
									0.002,
									0.019,
									0.006,
									0.017,
									0.016,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.038,
									0.001,
									0.001,
									0.0,
									0.004,
									0.001,
									0.017,
									0.021,
									0.019
								],
								"e": 0.007
							},
							{
								"h": [
									0.041,
									0.001,
									0.019,
									0.007,
									0.041,
									0.005,
									0.039,
									0.009,
									0.004,
									0.0,
									0.003,
									0.02
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.007,
									0.0,
									0.016,
									0.002,
									0.0,
									0.003,
									0.007,
									0.0,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.052,
									0.001,
									0.021,
									0.001,
									0.003,
									0.052,
									0.002,
									0.0,
									0.001,
									0.012,
									0.006,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.005,
									0.002,
									0.003,
									0.0,
									0.002,
									0.0,
									0.012,
									0.018,
									0.0,
									0.005,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.005,
									0.001,
									0.001,
									0.012,
									0.02,
									0.001,
									0.006,
									0.009,
									0.001,
									0.001,
									0.001,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.303,
									0.007,
									0.01,
									0.017,
									0.013,
									0.002,
									0.002,
									0.006,
									0.066,
									0.001,
									0.022,
									0.015
								],
								"e": 0.016
							}
						],
						[
							{
								"h": [
									0.259,
									0.0,
									0.256,
									0.0,
									0.046,
									0.031,
									0.594,
									0.013,
									0.029,
									0.332,
									0.317,
									0.25
								],
								"e": 0.166
							},
							{
								"h": [
									0.033,
									0.048,
									0.215,
									0.031,
									0.216,
									0.074,
									0.155,
									0.208,
									0.147,
									0.079,
									0.35,
									0.003
								],
								"e": 0.148
							},
							{
								"h": [
									0.029,
									0.067,
									0.0,
									0.005,
									0.032,
									0.007,
									0.013,
									0.177,
									0.006,
									0.007,
									0.23,
									0.02
								],
								"e": 0.063
							},
							{
								"h": [
									0.001,
									0.003,
									0.0,
									0.001,
									0.007,
									0.072,
									0.016,
									0.001,
									0.001,
									0.033,
									0.022,
									0.029
								],
								"e": 0.022
							},
							{
								"h": [
									0.011,
									0.04,
									0.013,
									0.066,
									0.103,
									0.001,
									0.015,
									0.031,
									0.006,
									0.006,
									0.009,
									0.0
								],
								"e": 0.029
							},
							{
								"h": [
									0.001,
									0.002,
									0.01,
									0.015,
									0.081,
									0.039,
									0.044,
									0.271,
									0.006,
									0.015,
									0.12,
									0.074
								],
								"e": 0.045
							},
							{
								"h": [
									0.076,
									0.029,
									0.011,
									0.012,
									0.038,
									0.009,
									0.049,
									0.086,
									0.057,
									0.011,
									0.012,
									0.05
								],
								"e": 0.029
							},
							{
								"h": [
									0.004,
									0.0,
									0.001,
									0.03,
									0.001,
									0.019,
									0.002,
									0.002,
									0.006,
									0.007,
									0.017,
									0.042
								],
								"e": 0.01
							},
							{
								"h": [
									0.002,
									0.192,
									0.048,
									0.0,
									0.024,
									0.019,
									0.219,
									0.003,
									0.041,
									0.03,
									0.018,
									0.003
								],
								"e": 0.046
							},
							{
								"h": [
									0.001,
									0.004,
									0.002,
									0.011,
									0.0,
									0.003,
									0.001,
									0.037,
									0.027,
									0.0,
									0.004,
									0.018
								],
								"e": 0.006
							},
							{
								"h": [
									0.006,
									0.001,
									0.003,
									0.011,
									0.024,
									0.002,
									0.002,
									0.047,
									0.005,
									0.0,
									0.0,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									0.227,
									0.003,
									0.002,
									0.019,
									0.005,
									0.005,
									0.003,
									0.005,
									0.035,
									0.002,
									0.047,
									0.006
								],
								"e": 0.012
							}
						],
						[
							{
								"h": [
									0.079,
									0.06,
									0.635,
									0.001,
									0.057,
									0.128,
									0.008,
									0.019,
									0.146,
									0.659,
									0.388,
									0.178
								],
								"e": 0.159
							},
							{
								"h": [
									0.068,
									0.098,
									0.387,
									0.035,
									0.135,
									0.548,
									0.134,
									0.147,
									0.203,
									0.082,
									0.262,
									0.065
								],
								"e": 0.2
							},
							{
								"h": [
									0.061,
									0.087,
									0.0,
									0.003,
									0.006,
									0.003,
									0.049,
									0.102,
									0.007,
									0.0,
									0.097,
									0.012
								],
								"e": 0.057
							},
							{
								"h": [
									0.001,
									0.003,
									0.001,
									0.0,
									0.004,
									0.035,
									0.002,
									0.0,
									0.002,
									0.006,
									0.007,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.021,
									0.002,
									0.01,
									0.005,
									0.108,
									0.001,
									0.008,
									0.062,
									0.016,
									0.003,
									0.02,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.007,
									0.025,
									0.0,
									0.001,
									0.046,
									0.001,
									0.005,
									0.1,
									0.106
								],
								"e": 0.018
							},
							{
								"h": [
									0.072,
									0.006,
									0.032,
									0.003,
									0.012,
									0.009,
									0.053,
									0.046,
									0.022,
									0.0,
									0.006,
									0.022
								],
								"e": 0.018
							},
							{
								"h": [
									0.011,
									0.0,
									0.0,
									0.008,
									0.0,
									0.015,
									0.002,
									0.01,
									0.009,
									0.004,
									0.001,
									0.011
								],
								"e": 0.005
							},
							{
								"h": [
									0.007,
									0.046,
									0.04,
									0.002,
									0.009,
									0.027,
									0.03,
									0.024,
									0.007,
									0.019,
									0.021,
									0.003
								],
								"e": 0.019
							},
							{
								"h": [
									0.003,
									0.012,
									0.002,
									0.039,
									0.003,
									0.015,
									0.002,
									0.017,
									0.014,
									0.0,
									0.008,
									0.007
								],
								"e": 0.008
							},
							{
								"h": [
									0.012,
									0.002,
									0.009,
									0.077,
									0.026,
									0.004,
									0.013,
									0.028,
									0.009,
									0.012,
									0.002,
									0.205
								],
								"e": 0.024
							},
							{
								"h": [
									0.425,
									0.044,
									0.027,
									0.314,
									0.092,
									0.008,
									0.007,
									0.034,
									0.826,
									0.003,
									0.499,
									0.08
								],
								"e": 0.107
							}
						],
						[
							{
								"h": [
									0.089,
									0.79,
									0.162,
									0.003,
									0.05,
									0.786,
									0.005,
									0.023,
									0.527,
									0.616,
									0.949,
									0.46
								],
								"e": 0.391
							},
							{
								"h": [
									0.045,
									0.048,
									0.449,
									0.043,
									0.188,
									0.256,
									0.162,
									0.214,
									0.297,
									0.292,
									0.368,
									0.414
								],
								"e": 0.284
							},
							{
								"h": [
									0.008,
									0.043,
									0.0,
									0.008,
									0.007,
									0.002,
									0.037,
									0.145,
									0.001,
									0.0,
									0.151,
									0.018
								],
								"e": 0.044
							},
							{
								"h": [
									0.03,
									0.006,
									0.001,
									0.0,
									0.016,
									0.017,
									0.002,
									0.0,
									0.0,
									0.003,
									0.033,
									0.001
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.001,
									0.006,
									0.003,
									0.033,
									0.002,
									0.005,
									0.199,
									0.013,
									0.005,
									0.026,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.005,
									0.003,
									0.001,
									0.001,
									0.014,
									0.002,
									0.004,
									0.016,
									0.188
								],
								"e": 0.016
							},
							{
								"h": [
									0.022,
									0.005,
									0.015,
									0.005,
									0.019,
									0.012,
									0.132,
									0.024,
									0.005,
									0.001,
									0.006,
									0.017
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.003,
									0.0,
									0.007,
									0.002,
									0.016,
									0.002,
									0.002,
									0.001,
									0.024
								],
								"e": 0.004
							},
							{
								"h": [
									0.024,
									0.06,
									0.005,
									0.007,
									0.001,
									0.011,
									0.007,
									0.004,
									0.002,
									0.025,
									0.026,
									0.002
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.014,
									0.003,
									0.004,
									0.0,
									0.015,
									0.001,
									0.018,
									0.053,
									0.0,
									0.002,
									0.005
								],
								"e": 0.007
							},
							{
								"h": [
									0.01,
									0.005,
									0.015,
									0.045,
									0.008,
									0.001,
									0.015,
									0.029,
									0.006,
									0.002,
									0.003,
									0.037
								],
								"e": 0.012
							},
							{
								"h": [
									0.396,
									0.038,
									0.02,
									0.043,
									0.043,
									0.007,
									0.003,
									0.036,
									0.145,
									0.002,
									0.115,
									0.014
								],
								"e": 0.037
							}
						],
						[
							{
								"h": [
									0.156,
									0.001,
									0.472,
									0.003,
									0.082,
									0.047,
									0.632,
									0.022,
									0.068,
									0.457,
									0.428,
									0.422
								],
								"e": 0.205
							},
							{
								"h": [
									0.028,
									0.074,
									0.342,
									0.039,
									0.228,
									0.171,
									0.077,
									0.144,
									0.19,
									0.147,
									0.393,
									0.002
								],
								"e": 0.164
							},
							{
								"h": [
									0.036,
									0.063,
									0.0,
									0.012,
									0.007,
									0.011,
									0.015,
									0.201,
									0.001,
									0.015,
									0.192,
									0.022
								],
								"e": 0.063
							},
							{
								"h": [
									0.001,
									0.005,
									0.006,
									0.0,
									0.006,
									0.024,
									0.08,
									0.001,
									0.001,
									0.005,
									0.029,
									0.009
								],
								"e": 0.018
							},
							{
								"h": [
									0.006,
									0.011,
									0.009,
									0.039,
									0.012,
									0.011,
									0.015,
									0.098,
									0.015,
									0.003,
									0.004,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.08,
									0.275,
									0.001,
									0.009,
									0.009,
									0.08,
									0.019,
									0.013,
									0.108,
									0.028,
									0.027,
									0.04
								],
								"e": 0.068
							},
							{
								"h": [
									0.016,
									0.087,
									0.026,
									0.008,
									0.031,
									0.021,
									0.068,
									0.04,
									0.005,
									0.063,
									0.026,
									0.02
								],
								"e": 0.039
							},
							{
								"h": [
									0.001,
									0.002,
									0.041,
									0.029,
									0.0,
									0.006,
									0.009,
									0.022,
									0.005,
									0.022,
									0.021,
									0.102
								],
								"e": 0.021
							},
							{
								"h": [
									0.036,
									0.352,
									0.046,
									0.002,
									0.005,
									0.026,
									0.181,
									0.001,
									0.022,
									0.021,
									0.094,
									0.008
								],
								"e": 0.057
							},
							{
								"h": [
									0.006,
									0.039,
									0.004,
									0.002,
									0.001,
									0.007,
									0.006,
									0.115,
									0.046,
									0.003,
									0.002,
									0.502
								],
								"e": 0.044
							},
							{
								"h": [
									0.007,
									0.008,
									0.018,
									0.058,
									0.002,
									0.002,
									0.008,
									0.265,
									0.025,
									0.002,
									0.007,
									0.053
								],
								"e": 0.026
							},
							{
								"h": [
									0.824,
									0.016,
									0.01,
									0.004,
									0.08,
									0.019,
									0.01,
									0.03,
									0.062,
									0.001,
									0.72,
									0.005
								],
								"e": 0.062
							}
						],
						[
							{
								"h": [
									0.26,
									0.0,
									0.3,
									0.002,
									0.064,
									0.013,
									0.608,
									0.048,
									0.025,
									0.341,
									0.3,
									0.185
								],
								"e": 0.17
							},
							{
								"h": [
									0.031,
									0.114,
									0.502,
									0.049,
									0.272,
									0.176,
									0.151,
									0.278,
									0.235,
									0.102,
									0.542,
									0.005
								],
								"e": 0.218
							},
							{
								"h": [
									0.045,
									0.074,
									0.0,
									0.01,
									0.022,
									0.085,
									0.028,
									0.29,
									0.003,
									0.028,
									0.363,
									0.04
								],
								"e": 0.104
							},
							{
								"h": [
									0.001,
									0.008,
									0.012,
									0.004,
									0.009,
									0.033,
									0.127,
									0.007,
									0.004,
									0.011,
									0.027,
									0.005
								],
								"e": 0.027
							},
							{
								"h": [
									0.024,
									0.021,
									0.004,
									0.095,
									0.02,
									0.011,
									0.023,
									0.104,
									0.02,
									0.002,
									0.004,
									0.0
								],
								"e": 0.026
							},
							{
								"h": [
									0.122,
									0.067,
									0.001,
									0.015,
									0.014,
									0.012,
									0.023,
									0.015,
									0.038,
									0.02,
									0.099,
									0.006
								],
								"e": 0.037
							},
							{
								"h": [
									0.05,
									0.072,
									0.035,
									0.019,
									0.071,
									0.047,
									0.065,
									0.085,
									0.012,
									0.066,
									0.157,
									0.085
								],
								"e": 0.069
							},
							{
								"h": [
									0.001,
									0.007,
									0.021,
									0.086,
									0.001,
									0.027,
									0.03,
									0.681,
									0.002,
									0.037,
									0.01,
									0.201
								],
								"e": 0.07
							},
							{
								"h": [
									0.008,
									0.887,
									0.059,
									0.007,
									0.003,
									0.022,
									1.0,
									0.003,
									0.045,
									0.057,
									0.324,
									0.018
								],
								"e": 0.172
							},
							{
								"h": [
									0.025,
									0.096,
									0.018,
									0.008,
									0.007,
									0.015,
									0.011,
									0.655,
									0.307,
									0.004,
									0.003,
									0.401
								],
								"e": 0.087
							},
							{
								"h": [
									0.023,
									0.01,
									0.069,
									0.246,
									0.118,
									0.002,
									0.028,
									0.395,
									0.041,
									0.003,
									0.006,
									0.25
								],
								"e": 0.07
							},
							{
								"h": [
									0.565,
									0.017,
									0.02,
									0.019,
									0.139,
									0.016,
									0.011,
									0.04,
									0.027,
									0.002,
									0.29,
									0.004
								],
								"e": 0.047
							}
						],
						[
							{
								"h": [
									0.114,
									0.002,
									0.958,
									0.01,
									0.073,
									0.042,
									0.008,
									0.209,
									0.429,
									0.674,
									0.44,
									0.574
								],
								"e": 0.258
							},
							{
								"h": [
									0.112,
									1.0,
									0.805,
									0.084,
									0.254,
									0.707,
									0.193,
									0.368,
									0.472,
									0.088,
									0.706,
									0.137
								],
								"e": 0.463
							},
							{
								"h": [
									0.158,
									0.036,
									0.001,
									0.047,
									0.312,
									0.415,
									1.0,
									0.307,
									0.068,
									0.034,
									0.36,
									0.053
								],
								"e": 0.351
							},
							{
								"h": [
									0.004,
									0.29,
									0.01,
									0.002,
									0.015,
									0.049,
									0.058,
									0.004,
									0.038,
									0.106,
									0.032,
									0.059
								],
								"e": 0.069
							},
							{
								"h": [
									0.016,
									0.02,
									0.053,
									0.038,
									0.018,
									0.083,
									0.023,
									0.235,
									0.024,
									0.006,
									0.025,
									0.0
								],
								"e": 0.047
							},
							{
								"h": [
									0.241,
									0.0,
									0.002,
									0.146,
									0.068,
									0.021,
									0.006,
									0.005,
									0.098,
									0.039,
									0.14,
									0.016
								],
								"e": 0.061
							},
							{
								"h": [
									0.064,
									0.103,
									0.059,
									0.057,
									0.234,
									0.109,
									0.056,
									0.055,
									0.044,
									0.003,
									0.044,
									0.281
								],
								"e": 0.083
							},
							{
								"h": [
									0.022,
									0.004,
									0.0,
									0.062,
									0.002,
									0.151,
									0.013,
									0.337,
									0.007,
									0.087,
									0.007,
									0.005
								],
								"e": 0.042
							},
							{
								"h": [
									0.019,
									0.122,
									0.068,
									0.024,
									0.021,
									0.149,
									0.086,
									0.02,
									0.02,
									0.014,
									0.244,
									0.017
								],
								"e": 0.06
							},
							{
								"h": [
									0.001,
									0.013,
									0.011,
									0.115,
									0.003,
									0.002,
									0.003,
									0.149,
									0.102,
									0.001,
									0.032,
									0.06
								],
								"e": 0.027
							},
							{
								"h": [
									0.034,
									0.003,
									0.013,
									0.155,
									0.024,
									0.003,
									0.003,
									0.027,
									0.012,
									0.009,
									0.004,
									0.007
								],
								"e": 0.019
							},
							{
								"h": [
									0.418,
									0.016,
									0.011,
									0.053,
									0.077,
									0.007,
									0.006,
									0.008,
									0.089,
									0.005,
									0.141,
									0.036
								],
								"e": 0.036
							}
						],
						[
							{
								"h": [
									0.287,
									0.002,
									0.647,
									0.011,
									0.271,
									0.05,
									0.8,
									0.093,
									0.029,
									0.573,
									0.416,
									0.365
								],
								"e": 0.28
							},
							{
								"h": [
									0.082,
									0.22,
									0.681,
									0.072,
									0.385,
									0.203,
									0.139,
									0.358,
									0.27,
									0.193,
									0.862,
									0.012
								],
								"e": 0.309
							},
							{
								"h": [
									0.202,
									0.092,
									0.007,
									0.189,
									0.172,
									0.141,
									0.081,
									0.557,
									0.023,
									0.222,
									0.718,
									0.078
								],
								"e": 0.304
							},
							{
								"h": [
									0.0,
									0.126,
									0.042,
									0.007,
									0.02,
									0.175,
									0.524,
									0.053,
									0.101,
									0.121,
									0.071,
									0.037
								],
								"e": 0.133
							},
							{
								"h": [
									0.208,
									0.011,
									0.044,
									0.392,
									0.02,
									0.025,
									0.1,
									0.16,
									0.016,
									0.051,
									0.006,
									0.0
								],
								"e": 0.08
							},
							{
								"h": [
									0.001,
									0.0,
									0.005,
									0.312,
									0.022,
									0.005,
									0.006,
									0.01,
									0.014,
									0.028,
									0.355,
									0.016
								],
								"e": 0.046
							},
							{
								"h": [
									0.464,
									0.078,
									0.079,
									0.084,
									0.067,
									0.083,
									0.052,
									0.461,
									0.087,
									0.001,
									0.007,
									0.242
								],
								"e": 0.104
							},
							{
								"h": [
									0.011,
									0.005,
									0.001,
									0.223,
									0.013,
									0.139,
									0.019,
									0.033,
									0.518,
									0.057,
									0.0,
									0.005
								],
								"e": 0.064
							},
							{
								"h": [
									0.006,
									0.005,
									0.065,
									0.005,
									0.093,
									0.119,
									0.035,
									0.011,
									0.136,
									0.131,
									0.175,
									1.0
								],
								"e": 0.159
							},
							{
								"h": [
									0.018,
									0.029,
									0.062,
									0.145,
									0.001,
									0.053,
									0.011,
									0.191,
									0.919,
									0.007,
									0.018,
									0.024
								],
								"e": 0.077
							},
							{
								"h": [
									0.203,
									0.005,
									0.024,
									0.197,
									0.115,
									0.005,
									0.026,
									1.0,
									0.007,
									0.003,
									0.016,
									0.045
								],
								"e": 0.092
							},
							{
								"h": [
									0.462,
									0.008,
									0.016,
									0.114,
									0.049,
									0.008,
									0.011,
									0.009,
									0.083,
									0.003,
									0.054,
									0.017
								],
								"e": 0.033
							}
						],
						[
							{
								"h": [
									0.245,
									0.001,
									0.665,
									0.062,
									0.527,
									0.224,
									0.042,
									0.251,
									0.708,
									0.673,
									0.416,
									0.355
								],
								"e": 0.364
							},
							{
								"h": [
									0.346,
									0.621,
									0.905,
									0.082,
									0.295,
									0.301,
									0.199,
									0.204,
									0.332,
									0.262,
									0.82,
									0.025
								],
								"e": 0.415
							},
							{
								"h": [
									0.054,
									0.078,
									0.027,
									0.093,
									0.349,
									0.77,
									0.279,
									0.786,
									1.0,
									0.038,
									0.719,
									0.084
								],
								"e": 0.517
							},
							{
								"h": [
									0.004,
									0.357,
									0.399,
									0.007,
									0.024,
									0.125,
									0.118,
									0.021,
									0.255,
									0.768,
									0.03,
									0.057
								],
								"e": 0.201
							},
							{
								"h": [
									0.057,
									0.063,
									0.037,
									0.061,
									0.014,
									0.073,
									0.049,
									0.052,
									0.013,
									0.161,
									0.013,
									0.0
								],
								"e": 0.06
							},
							{
								"h": [
									0.0,
									0.0,
									0.081,
									0.526,
									0.145,
									0.003,
									0.004,
									0.011,
									0.007,
									0.026,
									0.175,
									0.023
								],
								"e": 0.06
							},
							{
								"h": [
									1.0,
									0.005,
									0.058,
									0.054,
									0.064,
									0.046,
									0.032,
									0.154,
									0.509,
									0.0,
									0.004,
									0.618
								],
								"e": 0.115
							},
							{
								"h": [
									0.068,
									0.0,
									0.0,
									0.142,
									0.019,
									0.238,
									0.004,
									0.006,
									0.07,
									0.072,
									0.0,
									0.001
								],
								"e": 0.038
							},
							{
								"h": [
									0.006,
									0.006,
									0.036,
									0.001,
									0.068,
									0.746,
									0.003,
									0.064,
									0.008,
									0.03,
									0.022,
									0.032
								],
								"e": 0.059
							},
							{
								"h": [
									0.001,
									0.004,
									0.004,
									0.558,
									0.0,
									0.02,
									0.0,
									0.014,
									0.091,
									0.0,
									0.105,
									0.002
								],
								"e": 0.043
							},
							{
								"h": [
									0.022,
									0.001,
									0.006,
									0.038,
									0.062,
									0.024,
									0.006,
									0.01,
									0.002,
									0.004,
									0.001,
									0.01
								],
								"e": 0.014
							},
							{
								"h": [
									0.313,
									0.007,
									0.01,
									0.054,
									0.027,
									0.003,
									0.002,
									0.004,
									0.546,
									0.001,
									0.093,
									0.015
								],
								"e": 0.042
							}
						],
						[
							{
								"h": [
									0.113,
									0.909,
									0.179,
									0.143,
									0.341,
									0.558,
									0.007,
									0.268,
									0.827,
									0.707,
									0.986,
									0.466
								],
								"e": 0.534
							},
							{
								"h": [
									0.283,
									0.242,
									0.903,
									0.077,
									0.319,
									0.263,
									0.175,
									0.271,
									0.351,
									0.297,
									0.745,
									1.0
								],
								"e": 0.515
							},
							{
								"h": [
									0.063,
									0.043,
									0.001,
									0.174,
									0.252,
									0.069,
									0.144,
									0.489,
									0.042,
									0.015,
									0.477,
									0.065
								],
								"e": 0.2
							},
							{
								"h": [
									0.022,
									0.316,
									0.048,
									0.003,
									0.017,
									0.08,
									0.06,
									0.006,
									0.015,
									0.319,
									0.041,
									0.016
								],
								"e": 0.089
							},
							{
								"h": [
									0.03,
									0.073,
									0.057,
									0.029,
									0.058,
									0.078,
									0.023,
									0.066,
									0.008,
									0.241,
									0.011,
									0.0
								],
								"e": 0.07
							},
							{
								"h": [
									0.0,
									0.0,
									0.027,
									0.314,
									0.039,
									0.002,
									0.0,
									0.035,
									0.002,
									0.013,
									0.024,
									0.043
								],
								"e": 0.029
							},
							{
								"h": [
									0.547,
									0.004,
									0.03,
									0.05,
									0.108,
									0.036,
									0.115,
									0.05,
									0.199,
									0.0,
									0.005,
									0.748
								],
								"e": 0.089
							},
							{
								"h": [
									0.017,
									0.0,
									0.0,
									0.088,
									0.005,
									0.138,
									0.005,
									0.001,
									0.031,
									0.087,
									0.0,
									0.0
								],
								"e": 0.023
							},
							{
								"h": [
									0.092,
									0.001,
									0.014,
									0.001,
									0.058,
									1.0,
									0.001,
									0.02,
									0.002,
									0.044,
									0.026,
									0.006
								],
								"e": 0.071
							},
							{
								"h": [
									0.001,
									0.005,
									0.005,
									0.071,
									0.0,
									0.009,
									0.0,
									0.018,
									0.047,
									0.0,
									0.053,
									0.004
								],
								"e": 0.013
							},
							{
								"h": [
									0.013,
									0.001,
									0.003,
									0.024,
									0.025,
									0.012,
									0.008,
									0.014,
									0.001,
									0.005,
									0.001,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.151,
									0.008,
									0.012,
									0.026,
									0.012,
									0.002,
									0.002,
									0.006,
									0.057,
									0.001,
									0.017,
									0.014
								],
								"e": 0.013
							}
						],
						[
							{
								"h": [
									0.375,
									0.0,
									0.298,
									0.044,
									0.504,
									0.016,
									1.0,
									0.289,
									0.049,
									0.383,
									0.313,
									0.247
								],
								"e": 0.331
							},
							{
								"h": [
									0.317,
									0.401,
									0.748,
									0.076,
									0.548,
									0.056,
									0.077,
									0.159,
									0.204,
									0.117,
									1.0,
									0.011
								],
								"e": 0.336
							},
							{
								"h": [
									0.327,
									0.029,
									0.111,
									0.317,
									1.0,
									1.0,
									0.048,
									1.0,
									0.248,
									1.0,
									1.0,
									0.098
								],
								"e": 0.831
							},
							{
								"h": [
									0.0,
									0.103,
									0.107,
									1.0,
									0.01,
									0.176,
									1.0,
									1.0,
									0.246,
									0.835,
									0.141,
									1.0
								],
								"e": 0.768
							},
							{
								"h": [
									0.985,
									1.0,
									0.015,
									1.0,
									0.185,
									0.039,
									0.105,
									0.109,
									0.007,
									0.069,
									0.005,
									0.0
								],
								"e": 0.306
							},
							{
								"h": [
									0.0,
									0.001,
									0.128,
									0.078,
									0.982,
									0.022,
									0.296,
									1.0,
									0.018,
									0.01,
									0.08,
									0.106
								],
								"e": 0.171
							},
							{
								"h": [
									0.481,
									0.033,
									0.02,
									0.053,
									0.07,
									0.044,
									0.073,
									0.096,
									1.0,
									0.006,
									0.03,
									1.0
								],
								"e": 0.134
							},
							{
								"h": [
									0.147,
									0.0,
									0.001,
									0.548,
									0.013,
									0.1,
									0.004,
									0.001,
									0.05,
									0.189,
									0.002,
									0.019
								],
								"e": 0.063
							},
							{
								"h": [
									0.008,
									0.087,
									0.018,
									0.0,
									0.198,
									0.31,
									0.094,
									0.088,
									0.046,
									0.037,
									0.057,
									0.004
								],
								"e": 0.072
							},
							{
								"h": [
									0.002,
									0.002,
									0.003,
									0.175,
									0.0,
									0.006,
									0.0,
									0.027,
									0.027,
									0.0,
									0.045,
									0.012
								],
								"e": 0.017
							},
							{
								"h": [
									0.006,
									0.001,
									0.004,
									0.011,
									0.033,
									0.009,
									0.003,
									0.054,
									0.002,
									0.002,
									0.0,
									0.005
								],
								"e": 0.008
							},
							{
								"h": [
									0.194,
									0.003,
									0.001,
									0.013,
									0.002,
									0.002,
									0.001,
									0.002,
									0.028,
									0.001,
									0.048,
									0.005
								],
								"e": 0.009
							}
						],
						[
							{
								"h": [
									0.115,
									0.066,
									0.787,
									0.369,
									1.0,
									0.075,
									0.011,
									0.783,
									0.271,
									0.808,
									0.39,
									0.171
								],
								"e": 0.521
							},
							{
								"h": [
									1.0,
									0.847,
									0.854,
									0.068,
									0.265,
									0.519,
									0.119,
									0.174,
									0.193,
									0.068,
									0.614,
									0.151
								],
								"e": 0.508
							},
							{
								"h": [
									1.0,
									0.074,
									1.0,
									0.413,
									0.533,
									0.825,
									0.225,
									0.42,
									0.705,
									0.082,
									0.433,
									0.066
								],
								"e": 1.0
							},
							{
								"h": [
									0.0,
									0.13,
									0.744,
									0.053,
									0.005,
									0.085,
									0.285,
									0.13,
									1.0,
									0.247,
									0.058,
									0.131
								],
								"e": 0.316
							},
							{
								"h": [
									1.0,
									0.062,
									0.011,
									0.116,
									0.114,
									0.053,
									0.071,
									0.208,
									0.016,
									0.024,
									0.012,
									1.0
								],
								"e": 0.303
							},
							{
								"h": [
									0.0,
									0.0,
									0.021,
									0.041,
									0.469,
									0.001,
									0.022,
									0.231,
									0.003,
									0.004,
									0.068,
									0.144
								],
								"e": 0.057
							},
							{
								"h": [
									0.81,
									0.02,
									0.038,
									0.013,
									0.025,
									0.053,
									0.102,
									0.157,
									0.831,
									0.0,
									0.019,
									0.42
								],
								"e": 0.115
							},
							{
								"h": [
									0.437,
									0.0,
									0.0,
									0.115,
									0.003,
									0.055,
									0.006,
									0.007,
									0.068,
									0.081,
									0.0,
									0.009
								],
								"e": 0.05
							},
							{
								"h": [
									0.024,
									0.017,
									0.032,
									0.003,
									0.099,
									0.433,
									0.035,
									1.0,
									0.005,
									0.058,
									0.097,
									0.007
								],
								"e": 0.157
							},
							{
								"h": [
									0.006,
									0.006,
									0.002,
									1.0,
									0.006,
									0.032,
									0.001,
									0.011,
									0.007,
									0.0,
									0.174,
									0.007
								],
								"e": 0.069
							},
							{
								"h": [
									0.011,
									0.002,
									0.01,
									0.226,
									0.021,
									0.013,
									0.029,
									0.021,
									0.007,
									0.197,
									0.003,
									0.529
								],
								"e": 0.067
							},
							{
								"h": [
									0.281,
									0.053,
									0.021,
									0.517,
									0.076,
									0.004,
									0.008,
									0.02,
									1.0,
									0.001,
									0.604,
									0.117
								],
								"e": 0.126
							}
						],
						[
							{
								"h": [
									0.137,
									1.0,
									0.189,
									1.0,
									0.949,
									0.508,
									0.008,
									1.0,
									1.0,
									0.768,
									1.0,
									0.475
								],
								"e": 1.0
							},
							{
								"h": [
									0.615,
									0.388,
									1.0,
									0.088,
									0.368,
									0.236,
									0.14,
									0.264,
									0.289,
									0.253,
									0.893,
									0.919
								],
								"e": 0.574
							},
							{
								"h": [
									0.12,
									0.038,
									0.058,
									1.0,
									0.681,
									0.515,
									0.172,
									0.551,
									0.157,
									0.087,
									0.682,
									0.101
								],
								"e": 0.529
							},
							{
								"h": [
									0.012,
									0.335,
									1.0,
									0.025,
									0.017,
									0.043,
									0.3,
									0.09,
									0.098,
									0.153,
									0.276,
									0.044
								],
								"e": 0.248
							},
							{
								"h": [
									0.101,
									0.048,
									0.009,
									0.103,
									0.031,
									0.14,
									0.059,
									0.896,
									0.015,
									0.065,
									0.016,
									0.011
								],
								"e": 0.109
							},
							{
								"h": [
									0.002,
									0.0,
									0.006,
									0.047,
									0.079,
									0.001,
									0.01,
									0.075,
									0.012,
									0.004,
									0.013,
									0.294
								],
								"e": 0.035
							},
							{
								"h": [
									0.293,
									0.016,
									0.021,
									0.02,
									0.063,
									0.079,
									0.276,
									0.098,
									0.183,
									0.001,
									0.018,
									0.312
								],
								"e": 0.079
							},
							{
								"h": [
									0.033,
									0.0,
									0.0,
									0.034,
									0.001,
									0.029,
									0.007,
									0.009,
									0.015,
									0.04,
									0.0,
									0.014
								],
								"e": 0.012
							},
							{
								"h": [
									0.054,
									0.017,
									0.005,
									0.009,
									0.019,
									0.164,
									0.007,
									0.104,
									0.002,
									0.085,
									0.149,
									0.004
								],
								"e": 0.05
							},
							{
								"h": [
									0.002,
									0.013,
									0.005,
									0.081,
									0.001,
									0.025,
									0.001,
									0.016,
									0.082,
									0.0,
									0.031,
									0.01
								],
								"e": 0.016
							},
							{
								"h": [
									0.012,
									0.011,
									0.027,
									0.141,
									0.007,
									0.002,
									0.042,
									0.032,
									0.008,
									0.018,
									0.005,
									0.064
								],
								"e": 0.025
							},
							{
								"h": [
									0.194,
									0.075,
									0.019,
									0.08,
									0.053,
									0.005,
									0.006,
									0.032,
									0.094,
									0.002,
									0.079,
									0.022
								],
								"e": 0.036
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " United",
					"o": " Kingdom",
					"t": " Kingdom",
					"r": [
						{
							"p": " States",
							"b": 0.039,
							"k": {
								" States": 0.839,
								" United": 0.09,
								" Kingdom": 0.039,
								" State": 0.004,
								" Nations": 0.004,
								" Wire": 0.003,
								" U": 0.001,
								" state": 0.001,
								" Way": 0.001,
								" Spirit": 0.001
							}
						},
						{
							"p": " States",
							"b": 0.067,
							"k": {
								" States": 0.908,
								" Kingdom": 0.067,
								" Nations": 0.008,
								" Way": 0.005,
								" United": 0.002,
								" State": 0.002,
								" Wire": 0.001,
								" Methodist": 0.001,
								"!": 0.0,
								"\"": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.014,
							"k": {
								" States": 0.981,
								" Kingdom": 0.014,
								" Nations": 0.003,
								" Methodist": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.02,
							"k": {
								" States": 0.975,
								" Kingdom": 0.02,
								" Nations": 0.005,
								" State": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.008,
							"k": {
								" States": 0.975,
								" Nations": 0.016,
								" Kingdom": 0.008,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.0,
							"k": {
								" States": 0.995,
								" Nations": 0.005,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.0,
							"k": {
								" States": 0.999,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.0,
							"k": {
								" States": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.007,
							"k": {
								" States": 0.993,
								" Kingdom": 0.007,
								" Nations": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " States",
							"b": 0.013,
							"k": {
								" States": 0.987,
								" Kingdom": 0.013,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Kingdom",
							"b": 0.718,
							"k": {
								" Kingdom": 0.718,
								" States": 0.282,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Kingdom",
							"b": 0.723,
							"k": {
								" Kingdom": 0.723,
								" States": 0.264,
								" Nations": 0.006,
								" Arab": 0.003,
								" State": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.343,
									0.002,
									0.49,
									0.0,
									0.02,
									0.056,
									1.0,
									0.112,
									1.0,
									1.0,
									0.494,
									1.0
								],
								"e": 0.296
							},
							{
								"h": [
									0.032,
									0.127,
									0.522,
									1.0,
									1.0,
									0.956,
									1.0,
									1.0,
									1.0,
									1.0,
									0.023,
									0.031
								],
								"e": 1.0
							},
							{
								"h": [
									0.273,
									1.0,
									0.094,
									0.04,
									0.392,
									0.122,
									0.543,
									0.523,
									0.153,
									0.132,
									0.513,
									1.0
								],
								"e": 0.734
							},
							{
								"h": [
									1.0,
									1.0,
									0.087,
									0.864,
									1.0,
									1.0,
									0.532,
									0.437,
									0.121,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.084,
									0.308,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.124,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.345,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.332,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.916,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.278,
									1.0,
									1.0,
									1.0,
									0.906
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.263,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.719,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.332
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.155,
									0.0,
									0.192,
									0.0,
									0.003,
									0.0,
									0.062,
									0.016,
									0.367,
									0.504,
									0.181,
									0.343
								],
								"e": 0.086
							},
							{
								"h": [
									0.01,
									0.046,
									0.269,
									0.022,
									0.157,
									0.564,
									0.224,
									0.182,
									0.394,
									0.184,
									0.201,
									0.029
								],
								"e": 0.217
							},
							{
								"h": [
									0.018,
									0.08,
									0.0,
									0.0,
									0.011,
									0.005,
									0.034,
									0.037,
									0.002,
									0.001,
									0.079,
									0.006
								],
								"e": 0.03
							},
							{
								"h": [
									0.029,
									0.009,
									0.0,
									0.0,
									0.042,
									0.023,
									0.002,
									0.0,
									0.0,
									0.011,
									0.005,
									0.003
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.002,
									0.046,
									0.002,
									0.027,
									0.003,
									0.007,
									0.001,
									0.008,
									0.005,
									0.018,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.012,
									0.0,
									0.008,
									0.051,
									0.001,
									0.002,
									0.0,
									0.006,
									0.009,
									0.062,
									0.226,
									0.024
								],
								"e": 0.03
							},
							{
								"h": [
									0.003,
									0.006,
									0.039,
									0.017,
									0.022,
									0.006,
									0.045,
									0.052,
									0.005,
									0.0,
									0.001,
									0.007
								],
								"e": 0.017
							},
							{
								"h": [
									0.003,
									0.002,
									0.0,
									0.004,
									0.0,
									0.007,
									0.008,
									0.002,
									0.024,
									0.008,
									0.001,
									0.007
								],
								"e": 0.004
							},
							{
								"h": [
									0.015,
									0.003,
									0.016,
									0.001,
									0.0,
									0.007,
									0.023,
									0.001,
									0.008,
									0.009,
									0.006,
									0.032
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.003,
									0.001,
									0.012,
									0.001,
									0.019,
									0.026,
									0.0,
									0.001,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.002,
									0.001,
									0.006,
									0.007,
									0.001,
									0.001,
									0.003,
									0.017,
									0.015,
									0.0,
									0.004,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.162,
									0.019,
									0.01,
									0.095,
									0.056,
									0.006,
									0.002,
									0.009,
									0.426,
									0.003,
									0.021,
									0.505
								],
								"e": 0.057
							}
						],
						[
							{
								"h": [
									0.161,
									0.001,
									0.083,
									0.0,
									0.005,
									0.0,
									0.288,
									0.052,
									0.311,
									0.357,
									0.142,
									0.403
								],
								"e": 0.093
							},
							{
								"h": [
									0.009,
									0.019,
									0.273,
									0.024,
									0.157,
									0.183,
									0.217,
									0.287,
									0.194,
									0.142,
									0.275,
									0.004
								],
								"e": 0.166
							},
							{
								"h": [
									0.019,
									0.105,
									0.0,
									0.001,
									0.007,
									0.0,
									0.008,
									0.128,
									0.0,
									0.002,
									0.135,
									0.011
								],
								"e": 0.038
							},
							{
								"h": [
									0.001,
									0.005,
									0.0,
									0.0,
									0.004,
									0.054,
									0.001,
									0.0,
									0.0,
									0.003,
									0.011,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.0,
									0.008,
									0.003,
									0.006,
									0.0,
									0.01,
									0.024,
									0.001,
									0.002,
									0.004,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.023,
									0.001,
									0.009,
									0.001,
									0.002,
									0.001,
									0.012,
									0.029,
									0.006
								],
								"e": 0.007
							},
							{
								"h": [
									0.003,
									0.002,
									0.026,
									0.01,
									0.009,
									0.006,
									0.053,
									0.054,
									0.01,
									0.0,
									0.004,
									0.004
								],
								"e": 0.013
							},
							{
								"h": [
									0.002,
									0.002,
									0.0,
									0.007,
									0.0,
									0.005,
									0.019,
									0.001,
									0.014,
									0.034,
									0.0,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.014,
									0.0,
									0.098,
									0.0,
									0.0,
									0.004,
									0.018,
									0.001,
									0.012,
									0.012,
									0.007,
									0.006
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.001,
									0.003,
									0.001,
									0.0,
									0.008,
									0.001,
									0.11,
									0.019,
									0.0,
									0.0,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.0,
									0.034,
									0.002,
									0.004,
									0.0,
									0.0,
									0.03,
									0.002,
									0.0,
									0.002,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.054,
									0.003,
									0.009,
									0.006,
									0.02,
									0.001,
									0.002,
									0.003,
									0.094,
									0.0,
									0.006,
									0.112
								],
								"e": 0.014
							}
						],
						[
							{
								"h": [
									0.173,
									0.0,
									0.167,
									0.0,
									0.003,
									0.0,
									0.043,
									0.023,
									0.499,
									0.413,
									0.144,
									0.374
								],
								"e": 0.085
							},
							{
								"h": [
									0.012,
									0.054,
									0.397,
									0.033,
									0.142,
									0.114,
									0.118,
									0.177,
									0.413,
									0.298,
									0.31,
									0.001
								],
								"e": 0.194
							},
							{
								"h": [
									0.006,
									0.119,
									0.0,
									0.0,
									0.003,
									0.002,
									0.056,
									0.125,
									0.005,
									0.0,
									0.133,
									0.014
								],
								"e": 0.046
							},
							{
								"h": [
									0.003,
									0.019,
									0.0,
									0.001,
									0.019,
									0.047,
									0.005,
									0.001,
									0.001,
									0.009,
									0.005,
									0.004
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.004,
									0.021,
									0.008,
									0.008,
									0.003,
									0.022,
									0.004,
									0.007,
									0.013,
									0.015,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.01,
									0.0,
									0.005,
									0.025,
									0.02,
									0.0,
									0.003,
									0.003,
									0.008,
									0.045,
									0.046,
									0.017
								],
								"e": 0.015
							},
							{
								"h": [
									0.019,
									0.01,
									0.044,
									0.027,
									0.01,
									0.019,
									0.056,
									0.05,
									0.03,
									0.0,
									0.004,
									0.016
								],
								"e": 0.022
							},
							{
								"h": [
									0.01,
									0.001,
									0.0,
									0.007,
									0.001,
									0.006,
									0.004,
									0.004,
									0.009,
									0.018,
									0.001,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.021,
									0.003,
									0.059,
									0.002,
									0.0,
									0.016,
									0.014,
									0.004,
									0.003,
									0.002,
									0.01,
									0.005
								],
								"e": 0.011
							},
							{
								"h": [
									0.001,
									0.002,
									0.0,
									0.003,
									0.001,
									0.008,
									0.001,
									0.027,
									0.026,
									0.0,
									0.001,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.005,
									0.0,
									0.006,
									0.008,
									0.021,
									0.001,
									0.003,
									0.008,
									0.003,
									0.0,
									0.001,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.285,
									0.006,
									0.004,
									0.014,
									0.045,
									0.003,
									0.001,
									0.003,
									0.329,
									0.002,
									0.03,
									0.018
								],
								"e": 0.023
							}
						],
						[
							{
								"h": [
									0.107,
									0.0,
									0.142,
									0.0,
									0.002,
									0.0,
									0.042,
									0.019,
									0.595,
									0.437,
									0.225,
									0.295
								],
								"e": 0.082
							},
							{
								"h": [
									0.035,
									0.051,
									0.393,
									0.029,
									0.136,
									0.265,
									0.142,
									0.209,
									0.34,
									0.313,
									0.277,
									0.001
								],
								"e": 0.206
							},
							{
								"h": [
									0.005,
									0.048,
									0.0,
									0.001,
									0.004,
									0.001,
									0.036,
									0.099,
									0.001,
									0.0,
									0.092,
									0.011
								],
								"e": 0.028
							},
							{
								"h": [
									0.025,
									0.01,
									0.0,
									0.0,
									0.02,
									0.036,
									0.003,
									0.0,
									0.0,
									0.003,
									0.003,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.002,
									0.044,
									0.002,
									0.018,
									0.001,
									0.011,
									0.004,
									0.005,
									0.012,
									0.018,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.002,
									0.0,
									0.004,
									0.045,
									0.004,
									0.001,
									0.001,
									0.004,
									0.001,
									0.013,
									0.016,
									0.02
								],
								"e": 0.008
							},
							{
								"h": [
									0.054,
									0.0,
									0.05,
									0.016,
									0.025,
									0.012,
									0.033,
									0.021,
									0.034,
									0.0,
									0.002,
									0.036
								],
								"e": 0.021
							},
							{
								"h": [
									0.008,
									0.0,
									0.0,
									0.002,
									0.001,
									0.013,
									0.004,
									0.001,
									0.013,
									0.016,
									0.0,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.091,
									0.0,
									0.014,
									0.004,
									0.001,
									0.045,
									0.005,
									0.003,
									0.001,
									0.002,
									0.003,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.005,
									0.0,
									0.003,
									0.0,
									0.013,
									0.032,
									0.0,
									0.003,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.0,
									0.001,
									0.007,
									0.008,
									0.001,
									0.005,
									0.004,
									0.001,
									0.001,
									0.002,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.53,
									0.003,
									0.002,
									0.016,
									0.009,
									0.0,
									0.001,
									0.002,
									0.179,
									0.001,
									0.031,
									0.037
								],
								"e": 0.023
							}
						],
						[
							{
								"h": [
									0.373,
									0.003,
									0.075,
									0.0,
									0.006,
									0.0,
									0.274,
									0.02,
									0.618,
									0.367,
									0.209,
									0.195
								],
								"e": 0.104
							},
							{
								"h": [
									0.02,
									0.027,
									0.243,
									0.026,
									0.176,
									0.108,
									0.146,
									0.351,
									0.111,
									0.078,
									0.347,
									0.002
								],
								"e": 0.149
							},
							{
								"h": [
									0.013,
									0.236,
									0.0,
									0.001,
									0.003,
									0.002,
									0.013,
									0.198,
									0.006,
									0.007,
									0.221,
									0.021
								],
								"e": 0.069
							},
							{
								"h": [
									0.0,
									0.005,
									0.0,
									0.001,
									0.009,
									0.069,
									0.006,
									0.004,
									0.002,
									0.008,
									0.016,
									0.036
								],
								"e": 0.018
							},
							{
								"h": [
									0.003,
									0.023,
									0.016,
									0.005,
									0.011,
									0.004,
									0.032,
									0.059,
									0.006,
									0.009,
									0.009,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.001,
									0.0,
									0.004,
									0.029,
									0.031,
									0.003,
									0.027,
									0.05,
									0.001,
									0.029,
									0.081,
									0.029
								],
								"e": 0.022
							},
							{
								"h": [
									0.016,
									0.003,
									0.015,
									0.018,
									0.015,
									0.007,
									0.079,
									0.034,
									0.009,
									0.0,
									0.008,
									0.018
								],
								"e": 0.016
							},
							{
								"h": [
									0.013,
									0.0,
									0.0,
									0.011,
									0.0,
									0.005,
									0.004,
									0.001,
									0.005,
									0.006,
									0.001,
									0.008
								],
								"e": 0.004
							},
							{
								"h": [
									0.011,
									0.001,
									0.013,
									0.001,
									0.002,
									0.014,
									0.19,
									0.005,
									0.014,
									0.012,
									0.005,
									0.004
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.004,
									0.0,
									0.003,
									0.0,
									0.021,
									0.009,
									0.0,
									0.001,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.001,
									0.002,
									0.0,
									0.0,
									0.006,
									0.001,
									0.0,
									0.0,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.418,
									0.0,
									0.0,
									0.008,
									0.002,
									0.0,
									0.0,
									0.0,
									0.139,
									0.0,
									0.014,
									0.003
								],
								"e": 0.014
							}
						],
						[
							{
								"h": [
									0.112,
									0.0,
									0.136,
									0.0,
									0.005,
									0.0,
									0.028,
									0.023,
									0.386,
									0.373,
									0.154,
									0.243
								],
								"e": 0.067
							},
							{
								"h": [
									0.038,
									0.061,
									0.439,
									0.03,
									0.128,
									0.195,
									0.099,
									0.18,
									0.24,
									0.114,
									0.263,
									0.006
								],
								"e": 0.16
							},
							{
								"h": [
									0.069,
									0.06,
									0.0,
									0.001,
									0.004,
									0.003,
									0.047,
									0.102,
									0.01,
									0.001,
									0.096,
									0.011
								],
								"e": 0.046
							},
							{
								"h": [
									0.003,
									0.003,
									0.001,
									0.001,
									0.018,
									0.026,
									0.006,
									0.001,
									0.003,
									0.004,
									0.004,
									0.006
								],
								"e": 0.009
							},
							{
								"h": [
									0.019,
									0.003,
									0.024,
									0.003,
									0.041,
									0.012,
									0.024,
									0.004,
									0.012,
									0.003,
									0.016,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.007,
									0.022,
									0.0,
									0.006,
									0.009,
									0.0,
									0.008,
									0.067,
									0.068
								],
								"e": 0.014
							},
							{
								"h": [
									0.052,
									0.005,
									0.046,
									0.015,
									0.002,
									0.013,
									0.061,
									0.029,
									0.016,
									0.0,
									0.006,
									0.026
								],
								"e": 0.019
							},
							{
								"h": [
									0.051,
									0.0,
									0.0,
									0.004,
									0.0,
									0.007,
									0.005,
									0.002,
									0.006,
									0.009,
									0.001,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.009,
									0.002,
									0.02,
									0.001,
									0.003,
									0.023,
									0.02,
									0.014,
									0.001,
									0.005,
									0.007,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.043,
									0.002,
									0.013,
									0.0,
									0.011,
									0.009,
									0.0,
									0.001,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.004,
									0.0,
									0.005,
									0.017,
									0.005,
									0.001,
									0.003,
									0.012,
									0.009,
									0.003,
									0.001,
									0.012
								],
								"e": 0.005
							},
							{
								"h": [
									0.368,
									0.02,
									0.005,
									0.238,
									0.032,
									0.001,
									0.001,
									0.003,
									0.775,
									0.001,
									0.117,
									0.074
								],
								"e": 0.057
							}
						],
						[
							{
								"h": [
									0.11,
									0.0,
									0.132,
									0.0,
									0.003,
									0.0,
									0.04,
									0.037,
									0.651,
									0.438,
									0.218,
									0.277
								],
								"e": 0.083
							},
							{
								"h": [
									0.084,
									0.104,
									0.457,
									0.033,
									0.154,
									0.27,
									0.138,
									0.221,
									0.304,
									0.263,
									0.338,
									0.001
								],
								"e": 0.223
							},
							{
								"h": [
									0.011,
									0.043,
									0.0,
									0.009,
									0.014,
									0.005,
									0.04,
									0.136,
									0.003,
									0.002,
									0.146,
									0.017
								],
								"e": 0.042
							},
							{
								"h": [
									0.02,
									0.008,
									0.001,
									0.0,
									0.023,
									0.023,
									0.005,
									0.001,
									0.0,
									0.005,
									0.007,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.002,
									0.013,
									0.006,
									0.024,
									0.01,
									0.016,
									0.022,
									0.014,
									0.008,
									0.023,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.006,
									0.009,
									0.0,
									0.006,
									0.007,
									0.0,
									0.017,
									0.011,
									0.112
								],
								"e": 0.014
							},
							{
								"h": [
									0.113,
									0.001,
									0.025,
									0.013,
									0.004,
									0.016,
									0.105,
									0.044,
									0.031,
									0.0,
									0.009,
									0.031
								],
								"e": 0.025
							},
							{
								"h": [
									0.046,
									0.001,
									0.0,
									0.001,
									0.0,
									0.017,
									0.007,
									0.006,
									0.01,
									0.006,
									0.0,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.044,
									0.001,
									0.003,
									0.002,
									0.003,
									0.021,
									0.004,
									0.023,
									0.001,
									0.005,
									0.009,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.036,
									0.001,
									0.017,
									0.001,
									0.009,
									0.025,
									0.0,
									0.001,
									0.005
								],
								"e": 0.006
							},
							{
								"h": [
									0.004,
									0.001,
									0.011,
									0.011,
									0.001,
									0.0,
									0.016,
									0.016,
									0.015,
									0.005,
									0.002,
									0.015
								],
								"e": 0.008
							},
							{
								"h": [
									0.292,
									0.037,
									0.003,
									0.155,
									0.022,
									0.002,
									0.001,
									0.006,
									0.478,
									0.001,
									0.103,
									0.082
								],
								"e": 0.045
							}
						],
						[
							{
								"h": [
									0.283,
									0.863,
									0.216,
									0.002,
									0.069,
									1.0,
									0.395,
									0.019,
									0.691,
									0.176,
									0.932,
									0.313
								],
								"e": 0.463
							},
							{
								"h": [
									0.01,
									0.034,
									0.376,
									0.031,
									0.171,
									0.422,
									0.137,
									0.109,
									0.123,
									0.116,
									0.376,
									0.623
								],
								"e": 0.341
							},
							{
								"h": [
									0.014,
									0.058,
									0.0,
									0.001,
									0.001,
									0.001,
									0.009,
									0.222,
									0.001,
									0.002,
									0.205,
									0.019
								],
								"e": 0.043
							},
							{
								"h": [
									0.509,
									0.001,
									0.0,
									0.0,
									0.002,
									0.014,
									0.025,
									0.0,
									0.001,
									0.003,
									0.048,
									0.004
								],
								"e": 0.075
							},
							{
								"h": [
									0.001,
									0.002,
									0.006,
									0.004,
									0.035,
									0.003,
									0.006,
									0.14,
									0.005,
									0.003,
									0.019,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.005,
									0.0,
									0.0,
									0.007,
									0.001,
									0.0,
									0.001,
									0.005,
									0.001,
									0.016,
									0.079,
									0.072
								],
								"e": 0.014
							},
							{
								"h": [
									0.003,
									0.003,
									0.032,
									0.003,
									0.006,
									0.007,
									0.237,
									0.013,
									0.002,
									0.0,
									0.031,
									0.003
								],
								"e": 0.021
							},
							{
								"h": [
									0.003,
									0.004,
									0.002,
									0.004,
									0.0,
									0.002,
									0.007,
									0.003,
									0.001,
									0.001,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.031,
									0.001,
									0.016,
									0.001,
									0.0,
									0.008,
									0.036,
									0.001,
									0.005,
									0.009,
									0.016,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.001,
									0.003,
									0.001,
									0.002,
									0.011,
									0.0,
									0.009,
									0.006,
									0.001,
									0.001,
									0.015
								],
								"e": 0.004
							},
							{
								"h": [
									0.001,
									0.001,
									0.002,
									0.002,
									0.0,
									0.0,
									0.001,
									0.029,
									0.011,
									0.001,
									0.0,
									0.037
								],
								"e": 0.005
							},
							{
								"h": [
									0.348,
									0.001,
									0.0,
									0.002,
									0.01,
									0.002,
									0.001,
									0.002,
									0.585,
									0.0,
									0.086,
									0.03
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.163,
									0.001,
									1.0,
									0.0,
									0.021,
									0.001,
									0.476,
									0.027,
									0.358,
									0.455,
									0.297,
									0.148
								],
								"e": 0.18
							},
							{
								"h": [
									0.047,
									0.076,
									0.554,
									0.044,
									0.227,
									0.549,
									0.503,
									0.542,
									0.206,
									0.117,
									0.552,
									0.091
								],
								"e": 0.336
							},
							{
								"h": [
									0.024,
									0.317,
									0.0,
									0.002,
									0.062,
									0.017,
									0.027,
									0.346,
									0.003,
									0.015,
									0.443,
									0.041
								],
								"e": 0.123
							},
							{
								"h": [
									0.09,
									0.006,
									0.001,
									0.004,
									0.011,
									0.035,
									0.041,
									0.011,
									0.004,
									0.01,
									0.066,
									0.018
								],
								"e": 0.034
							},
							{
								"h": [
									0.002,
									0.011,
									0.007,
									0.018,
									0.051,
									0.009,
									0.016,
									0.152,
									0.01,
									0.008,
									0.022,
									0.0
								],
								"e": 0.03
							},
							{
								"h": [
									0.357,
									1.0,
									0.001,
									0.018,
									0.01,
									0.587,
									0.003,
									0.013,
									0.119,
									0.024,
									0.261,
									0.108
								],
								"e": 0.268
							},
							{
								"h": [
									0.025,
									0.035,
									0.031,
									0.021,
									0.035,
									0.031,
									0.46,
									0.041,
									0.006,
									0.22,
									0.54,
									0.041
								],
								"e": 0.123
							},
							{
								"h": [
									0.01,
									0.023,
									0.061,
									0.022,
									0.001,
									0.008,
									0.031,
									0.024,
									0.002,
									0.112,
									0.087,
									0.074
								],
								"e": 0.039
							},
							{
								"h": [
									0.029,
									0.027,
									0.047,
									0.009,
									0.0,
									0.012,
									0.943,
									0.004,
									0.033,
									0.027,
									0.089,
									0.011
								],
								"e": 0.068
							},
							{
								"h": [
									0.01,
									0.012,
									0.006,
									0.001,
									0.006,
									0.019,
									0.006,
									0.746,
									0.077,
									0.01,
									0.0,
									0.092
								],
								"e": 0.051
							},
							{
								"h": [
									0.009,
									0.004,
									0.039,
									0.048,
									0.015,
									0.0,
									0.025,
									1.0,
									0.071,
									0.0,
									0.003,
									0.059
								],
								"e": 0.066
							},
							{
								"h": [
									0.186,
									0.006,
									0.006,
									0.007,
									0.051,
									0.002,
									0.002,
									0.003,
									0.096,
									0.001,
									0.073,
									0.009
								],
								"e": 0.019
							}
						],
						[
							{
								"h": [
									0.136,
									0.0,
									0.122,
									0.0,
									0.011,
									0.0,
									0.042,
									0.143,
									0.412,
									0.506,
									0.196,
									0.24
								],
								"e": 0.087
							},
							{
								"h": [
									0.104,
									1.0,
									0.917,
									0.074,
									0.231,
									0.797,
									0.15,
									0.411,
									0.604,
									0.102,
									0.727,
									0.085
								],
								"e": 0.495
							},
							{
								"h": [
									0.17,
									0.127,
									0.006,
									0.007,
									0.272,
									0.219,
									1.0,
									0.285,
									0.053,
									0.05,
									0.376,
									0.051
								],
								"e": 0.354
							},
							{
								"h": [
									0.015,
									0.395,
									0.001,
									0.026,
									0.031,
									0.025,
									0.064,
									0.024,
									0.01,
									0.047,
									0.029,
									0.074
								],
								"e": 0.069
							},
							{
								"h": [
									0.002,
									0.035,
									0.102,
									0.01,
									0.024,
									0.119,
									0.024,
									0.019,
									0.02,
									0.011,
									0.03,
									0.0
								],
								"e": 0.041
							},
							{
								"h": [
									0.331,
									0.0,
									0.002,
									0.155,
									0.055,
									0.021,
									0.007,
									0.005,
									0.075,
									0.038,
									0.117,
									0.028
								],
								"e": 0.068
							},
							{
								"h": [
									0.023,
									0.035,
									0.07,
									0.043,
									0.056,
									0.178,
									0.134,
									0.028,
									0.026,
									0.003,
									0.068,
									0.354
								],
								"e": 0.079
							},
							{
								"h": [
									0.061,
									0.003,
									0.001,
									0.029,
									0.002,
									0.019,
									0.014,
									0.086,
									0.005,
									0.075,
									0.012,
									0.002
								],
								"e": 0.021
							},
							{
								"h": [
									0.03,
									0.011,
									0.062,
									0.016,
									0.002,
									0.045,
									0.101,
									0.023,
									0.003,
									0.002,
									0.078,
									0.012
								],
								"e": 0.027
							},
							{
								"h": [
									0.0,
									0.004,
									0.001,
									0.023,
									0.002,
									0.002,
									0.002,
									0.128,
									0.032,
									0.001,
									0.002,
									0.018
								],
								"e": 0.011
							},
							{
								"h": [
									0.026,
									0.001,
									0.016,
									0.043,
									0.006,
									0.001,
									0.004,
									0.024,
									0.013,
									0.002,
									0.004,
									0.001
								],
								"e": 0.011
							},
							{
								"h": [
									0.249,
									0.008,
									0.003,
									0.01,
									0.057,
									0.002,
									0.001,
									0.002,
									0.181,
									0.005,
									0.03,
									1.0
								],
								"e": 0.073
							}
						],
						[
							{
								"h": [
									1.0,
									0.001,
									0.099,
									0.0,
									0.045,
									0.0,
									0.357,
									0.217,
									0.38,
									0.741,
									0.239,
									0.239
								],
								"e": 0.187
							},
							{
								"h": [
									0.12,
									0.138,
									0.753,
									0.063,
									0.336,
									1.0,
									0.771,
									0.589,
									0.194,
									0.224,
									0.868,
									0.013
								],
								"e": 0.468
							},
							{
								"h": [
									0.196,
									0.642,
									0.016,
									0.022,
									0.138,
									0.04,
									0.095,
									0.603,
									0.017,
									0.275,
									0.879,
									0.073
								],
								"e": 0.328
							},
							{
								"h": [
									0.001,
									0.224,
									0.004,
									0.097,
									0.022,
									0.178,
									0.194,
									0.224,
									0.024,
									0.102,
									0.052,
									0.059
								],
								"e": 0.106
							},
							{
								"h": [
									0.067,
									0.013,
									0.073,
									0.477,
									0.026,
									0.006,
									0.219,
									0.132,
									0.013,
									0.075,
									0.015,
									0.0
								],
								"e": 0.1
							},
							{
								"h": [
									0.01,
									0.0,
									0.007,
									0.277,
									0.024,
									0.116,
									0.028,
									0.018,
									0.141,
									0.115,
									0.265,
									0.019
								],
								"e": 0.082
							},
							{
								"h": [
									0.115,
									0.039,
									0.075,
									0.088,
									0.051,
									0.077,
									0.219,
									0.295,
									0.072,
									0.001,
									0.036,
									0.226
								],
								"e": 0.096
							},
							{
								"h": [
									0.052,
									0.005,
									0.0,
									0.077,
									0.003,
									0.121,
									0.014,
									0.043,
									0.922,
									0.106,
									0.001,
									0.004
								],
								"e": 0.075
							},
							{
								"h": [
									0.024,
									0.001,
									0.038,
									0.013,
									0.027,
									0.103,
									0.436,
									0.018,
									0.05,
									0.041,
									0.1,
									1.0
								],
								"e": 0.117
							},
							{
								"h": [
									0.007,
									0.008,
									0.011,
									0.055,
									0.001,
									0.032,
									0.003,
									0.363,
									0.201,
									0.003,
									0.002,
									0.002
								],
								"e": 0.037
							},
							{
								"h": [
									0.086,
									0.001,
									0.005,
									0.045,
									0.022,
									0.001,
									0.012,
									0.436,
									0.002,
									0.003,
									0.007,
									0.036
								],
								"e": 0.037
							},
							{
								"h": [
									0.327,
									0.003,
									0.007,
									0.04,
									0.02,
									0.002,
									0.001,
									0.001,
									0.182,
									0.0,
									0.017,
									0.006
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.196,
									0.0,
									0.142,
									0.0,
									0.019,
									0.0,
									0.041,
									0.223,
									0.705,
									0.443,
									0.132,
									0.322
								],
								"e": 0.107
							},
							{
								"h": [
									0.123,
									0.44,
									0.995,
									0.073,
									0.258,
									0.131,
									0.108,
									0.217,
									0.426,
									0.245,
									0.798,
									0.003
								],
								"e": 0.344
							},
							{
								"h": [
									0.067,
									0.133,
									0.042,
									0.031,
									0.109,
									0.242,
									0.247,
									0.702,
									0.227,
									0.042,
									0.677,
									0.083
								],
								"e": 0.298
							},
							{
								"h": [
									0.001,
									0.395,
									0.016,
									0.175,
									0.015,
									0.088,
									0.15,
									0.128,
									0.077,
									0.118,
									0.017,
									0.068
								],
								"e": 0.111
							},
							{
								"h": [
									0.021,
									0.141,
									0.052,
									0.109,
									0.012,
									0.034,
									0.139,
									0.004,
									0.006,
									0.142,
									0.017,
									0.0
								],
								"e": 0.063
							},
							{
								"h": [
									0.003,
									0.0,
									0.11,
									0.256,
									0.181,
									0.001,
									0.01,
									0.01,
									0.009,
									0.088,
									0.067,
									0.032
								],
								"e": 0.059
							},
							{
								"h": [
									0.161,
									0.006,
									0.084,
									0.109,
									0.014,
									0.061,
									0.067,
									0.088,
									0.383,
									0.0,
									0.007,
									0.255
								],
								"e": 0.082
							},
							{
								"h": [
									0.173,
									0.0,
									0.0,
									0.027,
									0.009,
									0.06,
									0.003,
									0.013,
									0.058,
									0.254,
									0.0,
									0.001
								],
								"e": 0.03
							},
							{
								"h": [
									0.012,
									0.003,
									0.022,
									0.004,
									0.004,
									0.338,
									0.009,
									0.13,
									0.001,
									0.004,
									0.02,
									0.019
								],
								"e": 0.031
							},
							{
								"h": [
									0.001,
									0.002,
									0.0,
									0.109,
									0.0,
									0.012,
									0.0,
									0.022,
									0.022,
									0.0,
									0.016,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.017,
									0.001,
									0.009,
									0.025,
									0.048,
									0.003,
									0.005,
									0.008,
									0.001,
									0.003,
									0.001,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.134,
									0.008,
									0.005,
									0.014,
									0.026,
									0.001,
									0.0,
									0.001,
									0.186,
									0.001,
									0.028,
									0.005
								],
								"e": 0.014
							}
						],
						[
							{
								"h": [
									0.125,
									0.0,
									0.126,
									0.0,
									0.019,
									0.0,
									0.044,
									0.313,
									0.837,
									0.497,
									0.219,
									0.266
								],
								"e": 0.117
							},
							{
								"h": [
									0.357,
									0.394,
									0.894,
									0.061,
									0.252,
									0.32,
									0.133,
									0.265,
									0.341,
									0.252,
									0.674,
									0.002
								],
								"e": 0.38
							},
							{
								"h": [
									0.059,
									0.051,
									0.03,
									0.104,
									0.198,
									0.137,
									0.151,
									0.416,
									0.045,
									0.058,
									0.439,
									0.06
								],
								"e": 0.215
							},
							{
								"h": [
									0.008,
									0.195,
									0.017,
									0.057,
									0.017,
									0.073,
									0.096,
									0.07,
									0.009,
									0.054,
									0.014,
									0.019
								],
								"e": 0.057
							},
							{
								"h": [
									0.018,
									0.133,
									0.067,
									0.031,
									0.022,
									0.016,
									0.096,
									0.01,
									0.006,
									0.13,
									0.019,
									0.0
								],
								"e": 0.054
							},
							{
								"h": [
									0.001,
									0.0,
									0.055,
									0.283,
									0.089,
									0.001,
									0.005,
									0.033,
									0.002,
									0.012,
									0.012,
									0.033
								],
								"e": 0.038
							},
							{
								"h": [
									0.429,
									0.001,
									0.045,
									0.089,
									0.029,
									0.057,
									0.084,
									0.039,
									0.627,
									0.0,
									0.002,
									0.333
								],
								"e": 0.107
							},
							{
								"h": [
									0.18,
									0.0,
									0.0,
									0.009,
									0.004,
									0.085,
									0.004,
									0.002,
									0.064,
									0.306,
									0.0,
									0.0
								],
								"e": 0.032
							},
							{
								"h": [
									0.098,
									0.0,
									0.005,
									0.011,
									0.009,
									0.52,
									0.003,
									0.123,
									0.001,
									0.004,
									0.013,
									0.005
								],
								"e": 0.044
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.121,
									0.0,
									0.009,
									0.0,
									0.02,
									0.041,
									0.0,
									0.038,
									0.002
								],
								"e": 0.013
							},
							{
								"h": [
									0.011,
									0.0,
									0.004,
									0.016,
									0.013,
									0.003,
									0.007,
									0.007,
									0.0,
									0.021,
									0.002,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.241,
									0.005,
									0.003,
									0.026,
									0.009,
									0.0,
									0.001,
									0.001,
									0.156,
									0.0,
									0.028,
									0.015
								],
								"e": 0.015
							}
						],
						[
							{
								"h": [
									0.476,
									0.002,
									0.068,
									0.0,
									0.055,
									0.0,
									0.335,
									0.277,
									0.866,
									0.431,
									0.209,
									0.185
								],
								"e": 0.154
							},
							{
								"h": [
									0.316,
									0.242,
									0.806,
									0.068,
									0.428,
									0.139,
									0.126,
									0.328,
									0.192,
									0.122,
									1.0,
									0.004
								],
								"e": 0.35
							},
							{
								"h": [
									0.137,
									0.212,
									0.209,
									0.056,
									0.161,
									0.231,
									0.046,
									1.0,
									0.211,
									1.0,
									1.0,
									0.104
								],
								"e": 0.544
							},
							{
								"h": [
									0.0,
									0.098,
									0.04,
									0.757,
									0.01,
									0.12,
									0.129,
									0.97,
									0.171,
									0.184,
									0.063,
									0.656
								],
								"e": 0.264
							},
							{
								"h": [
									0.285,
									1.0,
									0.023,
									0.079,
									0.014,
									0.062,
									0.166,
									0.097,
									0.005,
									0.09,
									0.009,
									0.0
								],
								"e": 0.166
							},
							{
								"h": [
									0.001,
									0.0,
									0.075,
									0.123,
									0.504,
									0.003,
									0.077,
									0.556,
									0.003,
									0.012,
									0.046,
									0.042
								],
								"e": 0.109
							},
							{
								"h": [
									0.105,
									0.004,
									0.036,
									0.153,
									0.031,
									0.021,
									0.067,
									0.03,
									0.169,
									0.0,
									0.019,
									0.107
								],
								"e": 0.053
							},
							{
								"h": [
									0.205,
									0.0,
									0.0,
									0.069,
									0.002,
									0.013,
									0.003,
									0.001,
									0.033,
									0.14,
									0.0,
									0.004
								],
								"e": 0.023
							},
							{
								"h": [
									0.021,
									0.001,
									0.005,
									0.002,
									0.012,
									0.126,
									0.088,
									0.129,
									0.011,
									0.007,
									0.014,
									0.007
								],
								"e": 0.024
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.049,
									0.0,
									0.004,
									0.0,
									0.013,
									0.006,
									0.0,
									0.008,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.001,
									0.004,
									0.001,
									0.0,
									0.01,
									0.0,
									0.002,
									0.0,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.41,
									0.0,
									0.0,
									0.007,
									0.001,
									0.0,
									0.0,
									0.0,
									0.086,
									0.0,
									0.012,
									0.002
								],
								"e": 0.013
							}
						],
						[
							{
								"h": [
									0.139,
									0.0,
									0.13,
									0.0,
									0.062,
									0.0,
									0.03,
									0.532,
									0.538,
									0.447,
									0.151,
									0.227
								],
								"e": 0.129
							},
							{
								"h": [
									0.478,
									0.505,
									0.943,
									0.062,
									0.241,
									0.223,
									0.1,
									0.219,
									0.222,
									0.084,
									0.616,
									0.011
								],
								"e": 0.367
							},
							{
								"h": [
									1.0,
									0.067,
									1.0,
									0.158,
									0.346,
									0.681,
									0.192,
									0.39,
									1.0,
									0.176,
									0.464,
									0.06
								],
								"e": 0.926
							},
							{
								"h": [
									0.001,
									0.098,
									0.891,
									1.0,
									0.015,
									0.063,
									0.486,
									0.539,
									1.0,
									0.111,
									0.028,
									0.305
								],
								"e": 0.427
							},
							{
								"h": [
									1.0,
									0.145,
									0.029,
									0.072,
									0.036,
									0.328,
									0.197,
									0.009,
									0.013,
									0.03,
									0.012,
									0.0
								],
								"e": 0.182
							},
							{
								"h": [
									0.0,
									0.0,
									0.024,
									0.042,
									0.438,
									0.0,
									0.051,
									0.064,
									0.001,
									0.007,
									0.046,
									0.066
								],
								"e": 0.052
							},
							{
								"h": [
									0.403,
									0.015,
									0.075,
									0.071,
									0.005,
									0.048,
									0.103,
									0.072,
									0.392,
									0.0,
									0.028,
									0.179
								],
								"e": 0.089
							},
							{
								"h": [
									1.0,
									0.0,
									0.0,
									0.025,
									0.002,
									0.019,
									0.006,
									0.003,
									0.035,
									0.127,
									0.001,
									0.004
								],
								"e": 0.044
							},
							{
								"h": [
									0.009,
									0.003,
									0.009,
									0.003,
									0.019,
									0.23,
									0.016,
									0.645,
									0.001,
									0.01,
									0.037,
									0.008
								],
								"e": 0.055
							},
							{
								"h": [
									0.004,
									0.0,
									0.0,
									0.87,
									0.002,
									0.026,
									0.0,
									0.005,
									0.006,
									0.0,
									0.025,
									0.003
								],
								"e": 0.041
							},
							{
								"h": [
									0.005,
									0.0,
									0.014,
									0.134,
									0.007,
									0.005,
									0.01,
									0.013,
									0.004,
									0.05,
									0.004,
									0.104
								],
								"e": 0.025
							},
							{
								"h": [
									0.433,
									0.03,
									0.007,
									0.245,
									0.048,
									0.001,
									0.001,
									0.003,
									0.963,
									0.001,
									0.126,
									0.105
								],
								"e": 0.068
							}
						],
						[
							{
								"h": [
									0.146,
									0.0,
									0.129,
									0.0,
									0.049,
									0.0,
									0.047,
									1.0,
									0.934,
									0.546,
									0.221,
									0.269
								],
								"e": 0.187
							},
							{
								"h": [
									1.0,
									0.808,
									1.0,
									0.07,
									0.294,
									0.329,
									0.142,
									0.287,
									0.293,
									0.205,
									0.818,
									0.002
								],
								"e": 0.566
							},
							{
								"h": [
									0.146,
									0.053,
									0.631,
									1.0,
									1.0,
									1.0,
									0.174,
									0.485,
									0.291,
									0.372,
									0.705,
									0.095
								],
								"e": 1.0
							},
							{
								"h": [
									0.008,
									0.269,
									1.0,
									0.369,
									0.021,
									0.06,
									0.545,
									1.0,
									0.164,
									0.165,
									0.05,
									0.126
								],
								"e": 0.326
							},
							{
								"h": [
									0.117,
									0.091,
									0.021,
									0.214,
									0.023,
									0.352,
									0.18,
									0.065,
									0.018,
									0.095,
									0.023,
									1.0
								],
								"e": 0.285
							},
							{
								"h": [
									0.001,
									0.0,
									0.021,
									0.051,
									0.206,
									0.0,
									0.063,
									0.054,
									0.001,
									0.02,
									0.011,
									0.142
								],
								"e": 0.044
							},
							{
								"h": [
									1.0,
									0.004,
									0.042,
									0.068,
									0.011,
									0.065,
									0.205,
									0.143,
									1.0,
									0.0,
									0.033,
									0.223
								],
								"e": 0.166
							},
							{
								"h": [
									0.886,
									0.001,
									0.0,
									0.004,
									0.002,
									0.069,
									0.008,
									0.008,
									0.068,
									0.077,
									0.0,
									0.004
								],
								"e": 0.042
							},
							{
								"h": [
									0.058,
									0.001,
									0.002,
									0.004,
									0.023,
									0.239,
									0.003,
									1.0,
									0.001,
									0.014,
									0.052,
									0.005
								],
								"e": 0.079
							},
							{
								"h": [
									0.003,
									0.001,
									0.001,
									1.0,
									0.001,
									0.031,
									0.001,
									0.01,
									0.025,
									0.0,
									0.021,
									0.005
								],
								"e": 0.048
							},
							{
								"h": [
									0.008,
									0.003,
									0.057,
									0.085,
									0.002,
									0.002,
									0.057,
									0.021,
									0.017,
									0.092,
									0.008,
									0.121
								],
								"e": 0.037
							},
							{
								"h": [
									0.26,
									0.094,
									0.004,
									0.306,
									0.048,
									0.003,
									0.004,
									0.005,
									0.524,
									0.001,
									0.098,
									0.089
								],
								"e": 0.063
							}
						],
						[
							{
								"h": [
									0.385,
									1.0,
									0.227,
									1.0,
									1.0,
									0.809,
									0.493,
									0.422,
									0.978,
									0.21,
									1.0,
									0.304
								],
								"e": 1.0
							},
							{
								"h": [
									0.12,
									0.249,
									0.963,
									0.07,
									0.343,
									0.501,
									0.08,
									0.115,
									0.139,
									0.115,
									0.908,
									1.0
								],
								"e": 0.587
							},
							{
								"h": [
									0.196,
									0.025,
									0.127,
									0.083,
									0.08,
									0.144,
									0.044,
									0.84,
									0.05,
									0.339,
									0.922,
									0.098
								],
								"e": 0.33
							},
							{
								"h": [
									0.108,
									0.022,
									0.104,
									0.035,
									0.002,
									0.026,
									1.0,
									0.148,
									0.106,
									0.075,
									0.211,
									0.109
								],
								"e": 0.173
							},
							{
								"h": [
									0.071,
									0.098,
									0.008,
									0.1,
									0.032,
									0.083,
									0.062,
									0.349,
									0.006,
									0.026,
									0.015,
									0.0
								],
								"e": 0.076
							},
							{
								"h": [
									0.008,
									0.0,
									0.007,
									0.048,
									0.069,
									0.0,
									0.005,
									0.083,
									0.005,
									0.015,
									0.058,
									0.051
								],
								"e": 0.026
							},
							{
								"h": [
									0.022,
									0.021,
									0.02,
									0.032,
									0.024,
									0.023,
									0.49,
									0.023,
									0.031,
									0.0,
									0.18,
									0.025
								],
								"e": 0.058
							},
							{
								"h": [
									0.045,
									0.004,
									0.001,
									0.023,
									0.002,
									0.006,
									0.006,
									0.007,
									0.009,
									0.029,
									0.0,
									0.011
								],
								"e": 0.009
							},
							{
								"h": [
									0.052,
									0.001,
									0.011,
									0.004,
									0.001,
									0.058,
									0.039,
									0.016,
									0.005,
									0.017,
									0.082,
									0.006
								],
								"e": 0.021
							},
							{
								"h": [
									0.004,
									0.001,
									0.006,
									0.015,
									0.006,
									0.021,
									0.0,
									0.022,
									0.012,
									0.002,
									0.006,
									0.025
								],
								"e": 0.009
							},
							{
								"h": [
									0.003,
									0.003,
									0.011,
									0.011,
									0.001,
									0.001,
									0.005,
									0.026,
									0.012,
									0.017,
									0.002,
									0.237
								],
								"e": 0.022
							},
							{
								"h": [
									0.212,
									0.004,
									0.001,
									0.005,
									0.017,
									0.003,
									0.002,
									0.002,
									1.0,
									0.0,
									0.095,
									0.03
								],
								"e": 0.037
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Kingdom",
					"o": ".",
					"t": ".",
					"r": [
						{
							"p": " Kingdom",
							"b": 0.0,
							"k": {
								" Kingdom": 0.996,
								"'s": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Kingdom",
							"b": 0.005,
							"k": {
								" Kingdom": 0.701,
								"'s": 0.104,
								" Independence": 0.032,
								"wide": 0.03,
								" Parliament": 0.017,
								",": 0.011,
								" Jew": 0.011,
								".": 0.005,
								" Crown": 0.005,
								" Council": 0.004
							}
						},
						{
							"p": "'s",
							"b": 0.007,
							"k": {
								"'s": 0.233,
								" Kingdom": 0.159,
								"DOM": 0.051,
								" Jew": 0.049,
								" Crown": 0.048,
								" Columbia": 0.046,
								" Independence": 0.04,
								"wide": 0.035,
								",": 0.034,
								" Dollar": 0.033
							}
						},
						{
							"p": "'s",
							"b": 0.012,
							"k": {
								"'s": 0.431,
								" Columbia": 0.13,
								" Crown": 0.049,
								",": 0.048,
								" Independence": 0.044,
								" Kingdom": 0.039,
								" Jew": 0.028,
								" crown": 0.027,
								" and": 0.016,
								".": 0.012
							}
						},
						{
							"p": "'s",
							"b": 0.047,
							"k": {
								"'s": 0.643,
								",": 0.128,
								" and": 0.087,
								".": 0.047,
								" Columbia": 0.012,
								" crown": 0.006,
								" \u00e2\u0122\u0136": 0.005,
								" (": 0.004,
								";": 0.003,
								" in": 0.003
							}
						},
						{
							"p": ".",
							"b": 0.268,
							"k": {
								".": 0.268,
								"'s": 0.258,
								",": 0.224,
								" and": 0.127,
								" Columbia": 0.031,
								";": 0.026,
								" in": 0.005,
								" Parliament": 0.005,
								" (": 0.004,
								" Independence": 0.004
							}
						},
						{
							"p": ".",
							"b": 0.36,
							"k": {
								".": 0.36,
								",": 0.24,
								" and": 0.139,
								";": 0.127,
								"'s": 0.064,
								" Columbia": 0.045,
								" (": 0.005,
								" Independence": 0.004,
								" in": 0.002,
								"/": 0.001
							}
						},
						{
							"p": ".",
							"b": 0.477,
							"k": {
								".": 0.477,
								";": 0.385,
								",": 0.072,
								" and": 0.058,
								" Columbia": 0.002,
								" Independence": 0.002,
								"'s": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.899,
							"k": {
								".": 0.899,
								";": 0.055,
								",": 0.027,
								" and": 0.019,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.959,
							"k": {
								".": 0.959,
								";": 0.018,
								" and": 0.013,
								",": 0.01,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.918,
							"k": {
								".": 0.918,
								" and": 0.045,
								",": 0.027,
								";": 0.008,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.615,
							"k": {
								".": 0.615,
								",": 0.167,
								" and": 0.136,
								";": 0.024,
								" (": 0.007,
								".\"": 0.004,
								" with": 0.003,
								" as": 0.003,
								" ": 0.002,
								" but": 0.002
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.0,
									0.129,
									0.0,
									0.013,
									0.004,
									1.0,
									0.017,
									1.0,
									1.0,
									0.443,
									1.0
								],
								"e": 0.324
							},
							{
								"h": [
									0.108,
									0.19,
									0.501,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.017,
									0.099
								],
								"e": 1.0
							},
							{
								"h": [
									0.296,
									1.0,
									0.028,
									0.05,
									0.346,
									0.284,
									0.612,
									0.51,
									0.1,
									0.213,
									0.357,
									1.0
								],
								"e": 0.885
							},
							{
								"h": [
									1.0,
									1.0,
									0.066,
									1.0,
									1.0,
									1.0,
									0.685,
									1.0,
									0.338,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.205,
									0.882,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.665,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.558,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.267,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.521,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.504,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									0.961,
									0.534,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.267,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.096
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.505,
									0.0,
									0.036,
									0.0,
									0.0,
									0.0,
									0.16,
									0.002,
									0.184,
									0.42,
									0.245,
									0.637
								],
								"e": 0.109
							},
							{
								"h": [
									0.025,
									0.046,
									0.238,
									0.022,
									0.178,
									0.318,
									0.232,
									0.196,
									0.323,
									0.087,
									0.159,
									0.016
								],
								"e": 0.172
							},
							{
								"h": [
									0.022,
									0.048,
									0.0,
									0.001,
									0.009,
									0.013,
									0.044,
									0.043,
									0.005,
									0.003,
									0.059,
									0.007
								],
								"e": 0.037
							},
							{
								"h": [
									0.046,
									0.014,
									0.0,
									0.0,
									0.08,
									0.064,
									0.003,
									0.001,
									0.001,
									0.007,
									0.004,
									0.005
								],
								"e": 0.027
							},
							{
								"h": [
									0.0,
									0.01,
									0.045,
									0.002,
									0.04,
									0.006,
									0.01,
									0.005,
									0.011,
									0.006,
									0.031,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.003,
									0.0,
									0.018,
									0.073,
									0.002,
									0.012,
									0.0,
									0.006,
									0.014,
									0.025,
									0.165,
									0.118
								],
								"e": 0.029
							},
							{
								"h": [
									0.004,
									0.002,
									0.036,
									0.011,
									0.005,
									0.005,
									0.03,
									0.019,
									0.003,
									0.0,
									0.002,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.003,
									0.0,
									0.003,
									0.011,
									0.024,
									0.007,
									0.005,
									0.002,
									0.002,
									0.0,
									0.009
								],
								"e": 0.004
							},
							{
								"h": [
									0.004,
									0.002,
									0.018,
									0.005,
									0.0,
									0.002,
									0.011,
									0.0,
									0.007,
									0.005,
									0.003,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.002,
									0.008,
									0.003,
									0.002,
									0.005,
									0.021,
									0.004,
									0.021,
									0.003,
									0.001,
									0.0,
									0.005
								],
								"e": 0.006
							},
							{
								"h": [
									0.002,
									0.006,
									0.254,
									0.059,
									0.026,
									0.005,
									0.004,
									0.026,
									0.004,
									0.0,
									0.004,
									0.006
								],
								"e": 0.028
							},
							{
								"h": [
									0.551,
									0.056,
									0.021,
									0.073,
									0.081,
									0.042,
									0.008,
									0.05,
									0.092,
									0.013,
									0.067,
									0.228
								],
								"e": 0.077
							}
						],
						[
							{
								"h": [
									0.163,
									0.002,
									0.064,
									0.0,
									0.0,
									0.001,
									0.167,
									0.001,
									0.287,
									0.378,
									0.198,
									0.407
								],
								"e": 0.095
							},
							{
								"h": [
									0.013,
									0.022,
									0.236,
									0.023,
									0.168,
									0.155,
									0.554,
									0.248,
									0.285,
									0.138,
									0.209,
									0.019
								],
								"e": 0.205
							},
							{
								"h": [
									0.019,
									0.103,
									0.0,
									0.001,
									0.001,
									0.001,
									0.007,
									0.138,
									0.0,
									0.002,
									0.082,
									0.008
								],
								"e": 0.047
							},
							{
								"h": [
									0.003,
									0.005,
									0.0,
									0.0,
									0.015,
									0.042,
									0.001,
									0.001,
									0.0,
									0.002,
									0.006,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.002,
									0.018,
									0.0,
									0.03,
									0.0,
									0.014,
									0.016,
									0.004,
									0.004,
									0.009,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.0,
									0.004,
									0.027,
									0.001,
									0.012,
									0.0,
									0.001,
									0.004,
									0.014,
									0.019,
									0.024
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.0,
									0.037,
									0.004,
									0.002,
									0.005,
									0.016,
									0.026,
									0.001,
									0.0,
									0.001,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.004,
									0.0,
									0.002,
									0.003,
									0.014,
									0.009,
									0.003,
									0.001,
									0.001,
									0.0,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.001,
									0.04,
									0.002,
									0.0,
									0.002,
									0.005,
									0.0,
									0.002,
									0.004,
									0.007,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.003,
									0.006,
									0.001,
									0.004,
									0.018,
									0.003,
									0.043,
									0.004,
									0.001,
									0.0,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.002,
									0.042,
									0.011,
									0.002,
									0.001,
									0.018,
									0.012,
									0.001,
									0.001,
									0.007,
									0.005
								],
								"e": 0.009
							},
							{
								"h": [
									0.118,
									0.069,
									0.01,
									0.03,
									0.026,
									0.022,
									0.013,
									0.035,
									0.094,
									0.009,
									0.016,
									0.054
								],
								"e": 0.035
							}
						],
						[
							{
								"h": [
									0.221,
									0.0,
									0.035,
									0.0,
									0.0,
									0.0,
									0.055,
									0.002,
									0.142,
									0.306,
									0.287,
									0.464
								],
								"e": 0.077
							},
							{
								"h": [
									0.023,
									0.043,
									0.359,
									0.03,
									0.156,
									0.077,
									0.306,
									0.124,
									0.454,
									0.124,
									0.233,
									0.008
								],
								"e": 0.176
							},
							{
								"h": [
									0.009,
									0.08,
									0.0,
									0.001,
									0.002,
									0.006,
									0.072,
									0.143,
									0.011,
									0.002,
									0.087,
									0.014
								],
								"e": 0.057
							},
							{
								"h": [
									0.008,
									0.023,
									0.0,
									0.0,
									0.035,
									0.096,
									0.005,
									0.002,
									0.005,
									0.014,
									0.01,
									0.007
								],
								"e": 0.021
							},
							{
								"h": [
									0.002,
									0.037,
									0.035,
									0.006,
									0.006,
									0.002,
									0.011,
									0.016,
									0.015,
									0.011,
									0.018,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.01,
									0.0,
									0.015,
									0.053,
									0.018,
									0.004,
									0.001,
									0.006,
									0.077,
									0.04,
									0.058,
									0.048
								],
								"e": 0.023
							},
							{
								"h": [
									0.016,
									0.011,
									0.048,
									0.016,
									0.002,
									0.011,
									0.063,
									0.007,
									0.003,
									0.0,
									0.003,
									0.015
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.003,
									0.001,
									0.011,
									0.049,
									0.091,
									0.004,
									0.01,
									0.001,
									0.009,
									0.004,
									0.034
								],
								"e": 0.013
							},
							{
								"h": [
									0.003,
									0.08,
									0.041,
									0.006,
									0.002,
									0.016,
									0.027,
									0.0,
									0.005,
									0.016,
									0.009,
									0.006
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.031,
									0.005,
									0.012,
									0.003,
									0.011,
									0.007,
									0.009,
									0.003,
									0.002,
									0.008,
									0.013
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.005,
									0.047,
									0.074,
									0.184,
									0.097,
									0.004,
									0.039,
									0.016,
									0.001,
									0.001,
									0.002
								],
								"e": 0.026
							},
							{
								"h": [
									0.762,
									0.031,
									0.014,
									0.041,
									0.037,
									0.037,
									0.017,
									0.045,
									0.122,
									0.017,
									0.067,
									0.037
								],
								"e": 0.055
							}
						],
						[
							{
								"h": [
									0.493,
									0.0,
									0.038,
									0.0,
									0.0,
									0.0,
									0.063,
									0.002,
									0.151,
									0.321,
									0.238,
									0.323
								],
								"e": 0.082
							},
							{
								"h": [
									0.046,
									0.049,
									0.327,
									0.027,
									0.144,
									0.251,
									0.234,
									0.19,
									0.311,
									0.167,
									0.205,
									0.003
								],
								"e": 0.181
							},
							{
								"h": [
									0.007,
									0.026,
									0.0,
									0.004,
									0.005,
									0.002,
									0.035,
									0.115,
									0.002,
									0.002,
									0.061,
									0.011
								],
								"e": 0.032
							},
							{
								"h": [
									0.102,
									0.017,
									0.0,
									0.0,
									0.026,
									0.118,
									0.004,
									0.001,
									0.0,
									0.015,
									0.004,
									0.003
								],
								"e": 0.033
							},
							{
								"h": [
									0.001,
									0.008,
									0.06,
									0.003,
									0.006,
									0.001,
									0.012,
									0.007,
									0.014,
									0.008,
									0.018,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.013,
									0.0,
									0.018,
									0.091,
									0.008,
									0.006,
									0.0,
									0.003,
									0.02,
									0.008,
									0.023,
									0.021
								],
								"e": 0.012
							},
							{
								"h": [
									0.016,
									0.003,
									0.038,
									0.007,
									0.005,
									0.003,
									0.023,
									0.004,
									0.003,
									0.0,
									0.001,
									0.009
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.004,
									0.021,
									0.042,
									0.005,
									0.007,
									0.001,
									0.006,
									0.001,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.019,
									0.008,
									0.034,
									0.006,
									0.003,
									0.01,
									0.005,
									0.0,
									0.004,
									0.013,
									0.003,
									0.005
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.017,
									0.004,
									0.004,
									0.002,
									0.002,
									0.001,
									0.015,
									0.003,
									0.0,
									0.01,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.004,
									0.004,
									0.014,
									0.061,
									0.028,
									0.003,
									0.01,
									0.005,
									0.001,
									0.001,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.321,
									0.007,
									0.002,
									0.01,
									0.033,
									0.006,
									0.005,
									0.018,
									0.01,
									0.002,
									0.017,
									0.005
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.119,
									0.005,
									0.027,
									0.0,
									0.005,
									0.001,
									0.441,
									0.002,
									0.168,
									0.294,
									0.295,
									0.204
								],
								"e": 0.095
							},
							{
								"h": [
									0.026,
									0.013,
									0.203,
									0.024,
									0.18,
									0.098,
									0.04,
									0.18,
									0.153,
									0.057,
									0.232,
									0.009
								],
								"e": 0.109
							},
							{
								"h": [
									0.024,
									0.104,
									0.0,
									0.001,
									0.001,
									0.002,
									0.01,
									0.209,
									0.013,
									0.011,
									0.127,
									0.016
								],
								"e": 0.064
							},
							{
								"h": [
									0.001,
									0.003,
									0.0,
									0.0,
									0.025,
									0.064,
									0.004,
									0.002,
									0.001,
									0.01,
									0.01,
									0.04
								],
								"e": 0.016
							},
							{
								"h": [
									0.002,
									0.024,
									0.017,
									0.003,
									0.006,
									0.006,
									0.019,
									0.028,
									0.003,
									0.022,
									0.006,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.0,
									0.006,
									0.057,
									0.017,
									0.003,
									0.001,
									0.047,
									0.003,
									0.014,
									0.01,
									0.098
								],
								"e": 0.016
							},
							{
								"h": [
									0.021,
									0.001,
									0.026,
									0.017,
									0.001,
									0.003,
									0.035,
									0.022,
									0.01,
									0.0,
									0.005,
									0.016
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.005,
									0.017,
									0.043,
									0.002,
									0.008,
									0.009,
									0.001,
									0.001,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.007,
									0.002,
									0.005,
									0.001,
									0.006,
									0.006,
									0.015,
									0.001,
									0.009,
									0.013,
									0.005,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.001,
									0.001,
									0.007,
									0.003,
									0.002,
									0.004,
									0.001,
									0.005,
									0.001,
									0.001,
									0.011,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.002,
									0.003,
									0.006,
									0.008,
									0.002,
									0.018,
									0.01,
									0.003,
									0.001,
									0.005,
									0.001,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.15,
									0.007,
									0.002,
									0.023,
									0.004,
									0.004,
									0.003,
									0.008,
									0.497,
									0.003,
									0.016,
									0.005
								],
								"e": 0.027
							}
						],
						[
							{
								"h": [
									0.381,
									0.0,
									0.032,
									0.0,
									0.0,
									0.0,
									0.042,
									0.003,
									0.24,
									0.295,
									0.256,
									0.16
								],
								"e": 0.075
							},
							{
								"h": [
									0.053,
									0.049,
									0.359,
									0.027,
									0.129,
									0.111,
									0.089,
									0.145,
									0.208,
									0.086,
									0.187,
									0.011
								],
								"e": 0.129
							},
							{
								"h": [
									0.086,
									0.067,
									0.0,
									0.003,
									0.006,
									0.006,
									0.048,
									0.108,
									0.015,
									0.003,
									0.061,
									0.009
								],
								"e": 0.06
							},
							{
								"h": [
									0.002,
									0.003,
									0.001,
									0.0,
									0.021,
									0.085,
									0.005,
									0.002,
									0.004,
									0.004,
									0.003,
									0.017
								],
								"e": 0.014
							},
							{
								"h": [
									0.017,
									0.015,
									0.041,
									0.004,
									0.044,
									0.033,
									0.024,
									0.012,
									0.021,
									0.01,
									0.018,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.001,
									0.0,
									0.009,
									0.015,
									0.068,
									0.001,
									0.001,
									0.01,
									0.007,
									0.005,
									0.07,
									0.038
								],
								"e": 0.013
							},
							{
								"h": [
									0.047,
									0.001,
									0.025,
									0.01,
									0.001,
									0.008,
									0.01,
									0.024,
									0.017,
									0.0,
									0.004,
									0.041
								],
								"e": 0.011
							},
							{
								"h": [
									0.005,
									0.001,
									0.001,
									0.006,
									0.022,
									0.085,
									0.003,
									0.015,
									0.002,
									0.002,
									0.021,
									0.009
								],
								"e": 0.01
							},
							{
								"h": [
									0.004,
									0.021,
									0.014,
									0.003,
									0.004,
									0.014,
									0.015,
									0.005,
									0.003,
									0.007,
									0.007,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.005,
									0.005,
									0.025,
									0.003,
									0.011,
									0.006,
									0.017,
									0.001,
									0.0,
									0.027,
									0.005
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.01,
									0.018,
									0.012,
									0.116,
									0.028,
									0.013,
									0.034,
									0.016,
									0.004,
									0.002,
									0.01
								],
								"e": 0.017
							},
							{
								"h": [
									1.0,
									0.021,
									0.004,
									0.04,
									0.013,
									0.037,
									0.014,
									0.017,
									0.084,
									0.016,
									0.094,
									0.015
								],
								"e": 0.051
							}
						],
						[
							{
								"h": [
									0.511,
									0.0,
									0.034,
									0.0,
									0.0,
									0.0,
									0.055,
									0.004,
									0.149,
									0.313,
									0.221,
									0.329
								],
								"e": 0.081
							},
							{
								"h": [
									0.097,
									0.081,
									0.374,
									0.03,
									0.154,
									0.219,
									0.231,
									0.199,
									0.272,
									0.155,
									0.243,
									0.003
								],
								"e": 0.189
							},
							{
								"h": [
									0.015,
									0.026,
									0.0,
									0.014,
									0.013,
									0.007,
									0.034,
									0.144,
									0.005,
									0.004,
									0.085,
									0.015
								],
								"e": 0.045
							},
							{
								"h": [
									0.056,
									0.009,
									0.002,
									0.0,
									0.028,
									0.066,
									0.005,
									0.001,
									0.0,
									0.01,
									0.005,
									0.006
								],
								"e": 0.022
							},
							{
								"h": [
									0.002,
									0.002,
									0.015,
									0.006,
									0.01,
									0.021,
									0.02,
									0.02,
									0.022,
									0.009,
									0.016,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.002,
									0.0,
									0.012,
									0.007,
									0.028,
									0.002,
									0.001,
									0.004,
									0.004,
									0.005,
									0.022,
									0.025
								],
								"e": 0.007
							},
							{
								"h": [
									0.034,
									0.002,
									0.023,
									0.012,
									0.001,
									0.004,
									0.009,
									0.018,
									0.019,
									0.0,
									0.003,
									0.013
								],
								"e": 0.009
							},
							{
								"h": [
									0.003,
									0.004,
									0.001,
									0.003,
									0.015,
									0.024,
									0.006,
									0.016,
									0.004,
									0.001,
									0.004,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.007,
									0.014,
									0.007,
									0.004,
									0.002,
									0.005,
									0.004,
									0.007,
									0.002,
									0.01,
									0.005,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.001,
									0.007,
									0.008,
									0.02,
									0.003,
									0.006,
									0.002,
									0.022,
									0.004,
									0.001,
									0.012,
									0.01
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.005,
									0.005,
									0.02,
									0.078,
									0.026,
									0.005,
									0.013,
									0.012,
									0.003,
									0.002,
									0.015
								],
								"e": 0.011
							},
							{
								"h": [
									0.316,
									0.018,
									0.002,
									0.027,
									0.025,
									0.009,
									0.002,
									0.01,
									0.033,
									0.003,
									0.064,
									0.008
								],
								"e": 0.024
							}
						],
						[
							{
								"h": [
									0.364,
									0.001,
									0.049,
									0.004,
									0.017,
									0.0,
									0.313,
									0.052,
									0.226,
									0.421,
									0.351,
									0.56
								],
								"e": 0.141
							},
							{
								"h": [
									0.111,
									0.024,
									0.321,
									0.027,
									0.183,
									0.625,
									0.056,
									0.065,
									0.172,
									0.168,
									0.273,
									0.016
								],
								"e": 0.181
							},
							{
								"h": [
									0.032,
									0.096,
									0.0,
									0.001,
									0.007,
									0.002,
									0.008,
									0.237,
									0.003,
									0.007,
									0.111,
									0.015
								],
								"e": 0.061
							},
							{
								"h": [
									0.006,
									0.001,
									0.0,
									0.001,
									0.037,
									0.022,
									0.028,
									0.002,
									0.0,
									0.005,
									0.015,
									0.007
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.004,
									0.01,
									0.01,
									0.087,
									0.003,
									0.011,
									0.038,
									0.041,
									0.011,
									0.012,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									0.003,
									0.0,
									0.022,
									0.014,
									0.005,
									0.003,
									0.002,
									0.006,
									0.003,
									0.008,
									0.13,
									0.064
								],
								"e": 0.017
							},
							{
								"h": [
									0.009,
									0.004,
									0.033,
									0.006,
									0.001,
									0.005,
									0.004,
									0.022,
									0.032,
									0.0,
									0.006,
									0.004
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.002,
									0.001,
									0.002,
									0.01,
									0.016,
									0.003,
									0.016,
									0.001,
									0.001,
									0.001,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.001,
									0.005,
									0.002,
									0.002,
									0.009,
									0.003,
									0.001,
									0.002,
									0.005,
									0.003,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.002,
									0.001,
									0.021,
									0.004,
									0.008,
									0.008,
									0.002,
									0.003,
									0.003,
									0.0,
									0.002,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.001,
									0.002,
									0.004,
									0.001,
									0.01,
									0.005,
									0.003,
									0.0,
									0.005,
									0.004,
									0.007
								],
								"e": 0.003
							},
							{
								"h": [
									0.043,
									0.008,
									0.002,
									0.007,
									0.008,
									0.002,
									0.002,
									0.007,
									0.029,
									0.001,
									0.011,
									0.008
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.285,
									0.001,
									0.019,
									0.0,
									0.002,
									0.001,
									0.253,
									0.001,
									0.102,
									0.221,
									0.33,
									0.176
								],
								"e": 0.077
							},
							{
								"h": [
									0.163,
									0.034,
									0.463,
									0.035,
									0.234,
									0.862,
									0.109,
									0.133,
									0.246,
									0.119,
									0.373,
									0.085
								],
								"e": 0.265
							},
							{
								"h": [
									0.01,
									0.089,
									0.0,
									0.001,
									0.013,
									0.013,
									0.021,
									0.328,
									0.006,
									0.017,
									0.236,
									0.031
								],
								"e": 0.085
							},
							{
								"h": [
									0.017,
									0.002,
									0.0,
									0.001,
									0.209,
									0.027,
									0.016,
									0.01,
									0.001,
									0.007,
									0.071,
									0.003
								],
								"e": 0.047
							},
							{
								"h": [
									0.001,
									0.006,
									0.007,
									0.007,
									0.152,
									0.008,
									0.015,
									0.332,
									0.048,
									0.006,
									0.015,
									0.0
								],
								"e": 0.056
							},
							{
								"h": [
									0.026,
									0.001,
									0.003,
									0.016,
									0.013,
									0.005,
									0.001,
									0.003,
									0.002,
									0.028,
									0.104,
									0.146
								],
								"e": 0.025
							},
							{
								"h": [
									0.01,
									0.001,
									0.036,
									0.006,
									0.003,
									0.018,
									0.07,
									0.034,
									0.008,
									0.004,
									0.02,
									0.007
								],
								"e": 0.017
							},
							{
								"h": [
									0.001,
									0.007,
									0.001,
									0.006,
									0.007,
									0.038,
									0.007,
									0.07,
									0.001,
									0.001,
									0.001,
									0.009
								],
								"e": 0.008
							},
							{
								"h": [
									0.008,
									0.01,
									0.015,
									0.002,
									0.001,
									0.007,
									0.022,
									0.001,
									0.004,
									0.012,
									0.013,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.004,
									0.004,
									0.032,
									0.002,
									0.004,
									0.013,
									0.003,
									0.024,
									0.009,
									0.001,
									0.005,
									0.008
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.004,
									0.009,
									0.025,
									0.014,
									0.02,
									0.008,
									0.011,
									0.014,
									0.013,
									0.002,
									0.006
								],
								"e": 0.009
							},
							{
								"h": [
									0.473,
									0.018,
									0.003,
									0.031,
									0.014,
									0.007,
									0.001,
									0.027,
									0.73,
									0.003,
									0.023,
									0.019
								],
								"e": 0.048
							}
						],
						[
							{
								"h": [
									0.652,
									0.0,
									0.034,
									0.0,
									0.0,
									0.0,
									0.094,
									0.013,
									0.136,
									0.336,
									0.214,
									0.616
								],
								"e": 0.103
							},
							{
								"h": [
									0.285,
									1.0,
									0.715,
									0.067,
									0.23,
									0.348,
									0.142,
									0.307,
									0.503,
									0.068,
									0.501,
									0.032
								],
								"e": 0.41
							},
							{
								"h": [
									0.102,
									0.082,
									0.006,
									0.013,
									0.181,
									0.283,
									1.0,
									0.358,
									0.1,
									0.108,
									0.259,
									0.058
								],
								"e": 0.38
							},
							{
								"h": [
									0.016,
									0.482,
									0.001,
									0.018,
									0.073,
									0.071,
									0.063,
									0.044,
									0.047,
									0.142,
									0.044,
									0.13
								],
								"e": 0.1
							},
							{
								"h": [
									0.005,
									0.142,
									0.105,
									0.013,
									0.085,
									0.214,
									0.035,
									0.087,
									0.057,
									0.02,
									0.021,
									0.0
								],
								"e": 0.067
							},
							{
								"h": [
									1.0,
									0.044,
									0.017,
									0.289,
									0.15,
									0.098,
									0.001,
									0.03,
									0.294,
									0.049,
									0.144,
									0.065
								],
								"e": 0.17
							},
							{
								"h": [
									0.012,
									0.048,
									0.104,
									0.012,
									0.025,
									0.089,
									0.096,
									0.012,
									0.009,
									0.079,
									0.025,
									0.146
								],
								"e": 0.052
							},
							{
								"h": [
									0.011,
									0.014,
									0.024,
									0.112,
									0.029,
									0.345,
									0.011,
									0.735,
									0.003,
									0.109,
									0.022,
									0.196
								],
								"e": 0.088
							},
							{
								"h": [
									0.067,
									1.0,
									0.061,
									0.031,
									0.021,
									0.109,
									0.205,
									0.005,
									0.009,
									0.011,
									0.073,
									0.013
								],
								"e": 0.134
							},
							{
								"h": [
									0.001,
									0.06,
									0.021,
									0.158,
									0.045,
									0.065,
									0.035,
									0.094,
									0.016,
									0.004,
									0.018,
									0.416
								],
								"e": 0.07
							},
							{
								"h": [
									0.01,
									0.013,
									0.237,
									0.486,
									1.0,
									0.05,
									0.002,
									0.055,
									0.074,
									0.01,
									0.008,
									0.006
								],
								"e": 0.111
							},
							{
								"h": [
									0.959,
									0.038,
									0.02,
									0.059,
									0.117,
									0.022,
									0.009,
									0.338,
									0.278,
									0.019,
									0.145,
									1.0
								],
								"e": 0.194
							}
						],
						[
							{
								"h": [
									0.565,
									0.001,
									1.0,
									0.0,
									0.016,
									0.0,
									0.253,
									0.002,
									0.339,
									0.518,
									0.258,
									0.632
								],
								"e": 0.307
							},
							{
								"h": [
									0.282,
									0.103,
									0.647,
									0.052,
									0.363,
									0.64,
									0.251,
									0.277,
									0.309,
									0.239,
									0.585,
									0.079
								],
								"e": 0.359
							},
							{
								"h": [
									0.156,
									0.243,
									0.011,
									0.015,
									0.089,
									0.042,
									0.067,
									0.674,
									0.026,
									0.367,
									0.492,
									0.059
								],
								"e": 0.275
							},
							{
								"h": [
									0.042,
									0.153,
									0.003,
									0.008,
									0.012,
									0.137,
									0.088,
									0.103,
									0.015,
									0.297,
									0.047,
									0.028
								],
								"e": 0.08
							},
							{
								"h": [
									0.045,
									0.05,
									0.098,
									0.048,
									0.153,
									0.007,
									0.135,
									0.1,
									0.025,
									0.081,
									0.314,
									0.0
								],
								"e": 0.115
							},
							{
								"h": [
									0.01,
									0.001,
									0.033,
									0.472,
									0.017,
									0.031,
									0.002,
									0.011,
									0.12,
									0.058,
									0.234,
									0.111
								],
								"e": 0.064
							},
							{
								"h": [
									0.175,
									0.007,
									0.063,
									0.09,
									0.004,
									0.027,
									0.076,
									0.054,
									0.018,
									0.004,
									0.007,
									0.047
								],
								"e": 0.037
							},
							{
								"h": [
									0.002,
									0.017,
									0.005,
									0.061,
									0.058,
									0.084,
									0.013,
									0.324,
									0.06,
									0.009,
									0.013,
									0.024
								],
								"e": 0.038
							},
							{
								"h": [
									0.011,
									0.048,
									0.01,
									0.007,
									0.017,
									0.15,
									0.102,
									0.003,
									0.013,
									0.021,
									0.052,
									0.017
								],
								"e": 0.034
							},
							{
								"h": [
									0.01,
									0.007,
									0.034,
									0.031,
									0.005,
									0.046,
									0.008,
									0.025,
									0.024,
									0.005,
									0.019,
									0.005
								],
								"e": 0.017
							},
							{
								"h": [
									0.019,
									0.006,
									0.017,
									0.022,
									0.001,
									0.056,
									0.029,
									0.021,
									0.004,
									0.019,
									0.005,
									0.004
								],
								"e": 0.015
							},
							{
								"h": [
									0.373,
									0.018,
									0.005,
									0.047,
									0.02,
									0.015,
									0.01,
									0.027,
									0.484,
									0.01,
									0.009,
									0.013
								],
								"e": 0.041
							}
						],
						[
							{
								"h": [
									0.233,
									0.0,
									0.027,
									0.0,
									0.0,
									0.0,
									0.041,
									0.016,
									0.158,
									0.315,
									0.249,
									0.51
								],
								"e": 0.08
							},
							{
								"h": [
									0.185,
									0.28,
									0.854,
									0.064,
									0.279,
									0.057,
									0.315,
									0.166,
									0.448,
									0.135,
									0.56,
									0.007
								],
								"e": 0.293
							},
							{
								"h": [
									0.083,
									0.097,
									0.044,
									0.07,
									0.118,
									0.513,
									0.288,
									0.752,
									0.336,
									0.12,
									0.393,
									0.071
								],
								"e": 0.383
							},
							{
								"h": [
									0.003,
									0.457,
									0.036,
									0.051,
									0.035,
									0.158,
									0.136,
									0.183,
									0.322,
									0.192,
									0.035,
									0.128
								],
								"e": 0.153
							},
							{
								"h": [
									0.049,
									0.924,
									0.079,
									0.067,
									0.009,
									0.022,
									0.073,
									0.018,
									0.022,
									0.129,
									0.017,
									0.0
								],
								"e": 0.095
							},
							{
								"h": [
									0.007,
									0.0,
									0.205,
									0.542,
									0.189,
									0.023,
									0.001,
									0.042,
									0.397,
									0.039,
									0.049,
									0.101
								],
								"e": 0.091
							},
							{
								"h": [
									0.23,
									0.009,
									0.078,
									0.092,
									0.003,
									0.087,
									0.05,
									0.025,
									0.063,
									0.001,
									0.004,
									0.604
								],
								"e": 0.061
							},
							{
								"h": [
									0.031,
									0.003,
									0.003,
									0.165,
									0.569,
									0.413,
									0.009,
									0.049,
									0.006,
									0.133,
									0.002,
									0.011
								],
								"e": 0.074
							},
							{
								"h": [
									0.004,
									0.109,
									0.022,
									0.003,
									0.039,
									0.534,
									0.02,
									0.04,
									0.007,
									0.039,
									0.042,
									0.026
								],
								"e": 0.051
							},
							{
								"h": [
									0.002,
									0.02,
									0.005,
									0.107,
									0.001,
									0.023,
									0.003,
									0.01,
									0.004,
									0.001,
									0.301,
									0.005
								],
								"e": 0.022
							},
							{
								"h": [
									0.005,
									0.007,
									0.026,
									0.038,
									0.227,
									1.0,
									0.006,
									0.045,
									0.017,
									0.014,
									0.002,
									0.002
								],
								"e": 0.06
							},
							{
								"h": [
									0.384,
									0.012,
									0.007,
									0.059,
									0.016,
									0.027,
									0.01,
									0.017,
									0.115,
									0.018,
									0.079,
									0.022
								],
								"e": 0.038
							}
						],
						[
							{
								"h": [
									0.579,
									0.0,
									0.032,
									0.0,
									0.001,
									0.0,
									0.054,
									0.025,
									0.171,
									0.351,
									0.206,
									0.374
								],
								"e": 0.093
							},
							{
								"h": [
									0.395,
									0.314,
									0.706,
									0.054,
									0.26,
									0.187,
									0.233,
									0.241,
									0.3,
									0.171,
									0.47,
									0.003
								],
								"e": 0.304
							},
							{
								"h": [
									0.073,
									0.032,
									0.019,
									0.216,
									0.272,
									0.179,
									0.134,
									0.45,
									0.082,
									0.13,
									0.252,
									0.053
								],
								"e": 0.275
							},
							{
								"h": [
									0.028,
									0.302,
									0.082,
									0.047,
									0.021,
									0.211,
									0.13,
									0.086,
									0.036,
									0.218,
									0.017,
									0.066
								],
								"e": 0.11
							},
							{
								"h": [
									0.041,
									0.369,
									0.091,
									0.043,
									0.009,
									0.026,
									0.108,
									0.017,
									0.018,
									0.083,
									0.016,
									0.0
								],
								"e": 0.066
							},
							{
								"h": [
									0.003,
									0.0,
									0.195,
									0.599,
									0.165,
									0.01,
									0.001,
									0.028,
									0.088,
									0.005,
									0.01,
									0.038
								],
								"e": 0.056
							},
							{
								"h": [
									0.193,
									0.006,
									0.038,
									0.055,
									0.004,
									0.032,
									0.028,
									0.019,
									0.079,
									0.0,
									0.002,
									0.354
								],
								"e": 0.038
							},
							{
								"h": [
									0.027,
									0.001,
									0.0,
									0.077,
									0.239,
									0.136,
									0.012,
									0.018,
									0.005,
									0.099,
									0.001,
									0.001
								],
								"e": 0.035
							},
							{
								"h": [
									0.017,
									0.007,
									0.013,
									0.007,
									0.078,
									0.2,
									0.003,
									0.04,
									0.005,
									0.036,
									0.03,
									0.024
								],
								"e": 0.031
							},
							{
								"h": [
									0.002,
									0.012,
									0.008,
									0.059,
									0.001,
									0.006,
									0.001,
									0.019,
									0.006,
									0.001,
									0.514,
									0.003
								],
								"e": 0.026
							},
							{
								"h": [
									0.002,
									0.005,
									0.003,
									0.018,
									0.042,
									0.32,
									0.004,
									0.01,
									0.005,
									0.021,
									0.001,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.186,
									0.004,
									0.001,
									0.016,
									0.021,
									0.003,
									0.003,
									0.012,
									0.006,
									0.001,
									0.017,
									0.003
								],
								"e": 0.012
							}
						],
						[
							{
								"h": [
									0.132,
									0.005,
									0.023,
									0.0,
									0.038,
									0.001,
									0.467,
									0.018,
									0.206,
									0.334,
									0.269,
									0.225
								],
								"e": 0.113
							},
							{
								"h": [
									0.262,
									0.095,
									0.642,
									0.056,
									0.436,
									0.089,
									0.019,
									0.163,
									0.253,
									0.116,
									0.63,
									0.008
								],
								"e": 0.234
							},
							{
								"h": [
									0.158,
									0.101,
									0.16,
									0.049,
									0.059,
									0.14,
									0.036,
									1.0,
									0.344,
									0.706,
									0.521,
									0.073
								],
								"e": 0.417
							},
							{
								"h": [
									0.0,
									0.075,
									0.014,
									0.084,
									0.013,
									0.11,
									0.061,
									0.331,
									0.13,
									0.264,
									0.069,
									0.633
								],
								"e": 0.142
							},
							{
								"h": [
									0.158,
									1.0,
									0.03,
									0.035,
									0.01,
									0.096,
									0.124,
									0.073,
									0.003,
									0.171,
									0.007,
									0.0
								],
								"e": 0.116
							},
							{
								"h": [
									0.0,
									0.0,
									0.049,
									0.299,
									0.388,
									0.002,
									0.002,
									0.385,
									0.013,
									0.006,
									0.006,
									0.093
								],
								"e": 0.069
							},
							{
								"h": [
									0.185,
									0.002,
									0.031,
									0.115,
									0.002,
									0.03,
									0.028,
									0.043,
									0.143,
									0.0,
									0.005,
									0.409
								],
								"e": 0.049
							},
							{
								"h": [
									0.02,
									0.0,
									0.0,
									0.117,
									0.152,
									0.158,
									0.004,
									0.005,
									0.034,
									0.051,
									0.0,
									0.0
								],
								"e": 0.031
							},
							{
								"h": [
									0.009,
									0.0,
									0.002,
									0.003,
									0.133,
									0.353,
									0.002,
									0.124,
									0.023,
									0.022,
									0.028,
									0.003
								],
								"e": 0.041
							},
							{
								"h": [
									0.002,
									0.0,
									0.006,
									0.126,
									0.001,
									0.007,
									0.001,
									0.003,
									0.002,
									0.001,
									0.244,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.002,
									0.002,
									0.002,
									0.006,
									0.002,
									0.352,
									0.009,
									0.006,
									0.0,
									0.138,
									0.001,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									0.161,
									0.004,
									0.001,
									0.04,
									0.001,
									0.002,
									0.001,
									0.004,
									1.0,
									0.001,
									0.021,
									0.007
								],
								"e": 0.044
							}
						],
						[
							{
								"h": [
									0.485,
									0.0,
									0.029,
									0.0,
									0.002,
									0.0,
									0.04,
									0.042,
									0.307,
									0.346,
									0.23,
									0.183
								],
								"e": 0.095
							},
							{
								"h": [
									0.541,
									0.357,
									0.735,
									0.052,
									0.239,
									0.09,
									0.092,
									0.174,
									0.19,
									0.081,
									0.416,
									0.011
								],
								"e": 0.268
							},
							{
								"h": [
									1.0,
									0.077,
									1.0,
									0.204,
									0.483,
									0.95,
									0.182,
									0.386,
									1.0,
									0.326,
									0.256,
									0.043
								],
								"e": 1.0
							},
							{
								"h": [
									0.001,
									0.085,
									0.429,
									0.326,
									0.014,
									0.163,
									0.353,
									0.698,
									1.0,
									0.122,
									0.022,
									0.643
								],
								"e": 0.353
							},
							{
								"h": [
									1.0,
									0.572,
									0.045,
									0.07,
									0.054,
									0.813,
									0.198,
									0.035,
									0.015,
									0.07,
									0.012,
									0.0
								],
								"e": 0.241
							},
							{
								"h": [
									0.0,
									0.0,
									0.101,
									0.11,
									0.921,
									0.001,
									0.008,
									0.089,
									0.041,
									0.003,
									0.048,
									0.038
								],
								"e": 0.064
							},
							{
								"h": [
									0.639,
									0.004,
									0.028,
									0.053,
									0.001,
									0.055,
									0.012,
									0.079,
									0.29,
									0.0,
									0.004,
									0.86
								],
								"e": 0.087
							},
							{
								"h": [
									0.265,
									0.002,
									0.0,
									0.149,
									0.199,
									0.202,
									0.003,
									0.018,
									0.011,
									0.033,
									0.006,
									0.002
								],
								"e": 0.052
							},
							{
								"h": [
									0.007,
									0.003,
									0.005,
									0.006,
									0.069,
									0.305,
									0.004,
									1.0,
									0.004,
									0.019,
									0.04,
									0.003
								],
								"e": 0.076
							},
							{
								"h": [
									0.002,
									0.002,
									0.004,
									0.548,
									0.003,
									0.013,
									0.002,
									0.007,
									0.002,
									0.0,
									1.0,
									0.001
								],
								"e": 0.062
							},
							{
								"h": [
									0.002,
									0.011,
									0.008,
									0.009,
									0.059,
									0.36,
									0.021,
									0.026,
									0.008,
									0.081,
									0.002,
									0.008
								],
								"e": 0.03
							},
							{
								"h": [
									0.294,
									0.013,
									0.002,
									0.032,
									0.01,
									0.02,
									0.016,
									0.008,
									0.065,
									0.009,
									0.099,
									0.013
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.66,
									0.0,
									0.032,
									0.0,
									0.002,
									0.0,
									0.056,
									0.076,
									0.19,
									0.38,
									0.202,
									0.382
								],
								"e": 0.112
							},
							{
								"h": [
									1.0,
									0.579,
									0.779,
									0.06,
									0.288,
									0.183,
									0.239,
									0.247,
									0.255,
									0.146,
									0.562,
									0.003
								],
								"e": 0.406
							},
							{
								"h": [
									0.163,
									0.033,
									0.309,
									1.0,
									1.0,
									1.0,
									0.138,
									0.48,
									0.31,
									0.566,
									0.364,
									0.077
								],
								"e": 0.908
							},
							{
								"h": [
									0.015,
									0.287,
									1.0,
									0.349,
									0.021,
									0.141,
									0.379,
									0.53,
									0.142,
									0.293,
									0.044,
									0.278
								],
								"e": 0.34
							},
							{
								"h": [
									0.201,
									0.124,
									0.024,
									0.163,
									0.013,
									0.727,
									0.212,
									0.064,
									0.019,
									0.081,
									0.013,
									0.0
								],
								"e": 0.134
							},
							{
								"h": [
									0.001,
									0.0,
									0.165,
									0.071,
									0.486,
									0.003,
									0.008,
									0.04,
									0.03,
									0.004,
									0.024,
									0.032
								],
								"e": 0.044
							},
							{
								"h": [
									0.455,
									0.014,
									0.029,
									0.058,
									0.002,
									0.033,
									0.013,
									0.071,
									0.445,
									0.0,
									0.004,
									0.246
								],
								"e": 0.065
							},
							{
								"h": [
									0.174,
									0.01,
									0.0,
									0.074,
									0.096,
									0.046,
									0.008,
									0.013,
									0.014,
									0.021,
									0.001,
									0.001
								],
								"e": 0.029
							},
							{
								"h": [
									0.009,
									0.002,
									0.003,
									0.007,
									0.031,
									0.077,
									0.001,
									0.714,
									0.002,
									0.026,
									0.028,
									0.008
								],
								"e": 0.049
							},
							{
								"h": [
									0.002,
									0.003,
									0.007,
									0.369,
									0.002,
									0.008,
									0.001,
									0.015,
									0.01,
									0.001,
									0.359,
									0.003
								],
								"e": 0.033
							},
							{
								"h": [
									0.001,
									0.005,
									0.003,
									0.014,
									0.029,
									0.199,
									0.007,
									0.009,
									0.006,
									0.076,
									0.001,
									0.013
								],
								"e": 0.019
							},
							{
								"h": [
									0.181,
									0.016,
									0.002,
									0.03,
									0.016,
									0.005,
									0.002,
									0.005,
									0.027,
									0.002,
									0.053,
									0.005
								],
								"e": 0.017
							}
						],
						[
							{
								"h": [
									0.452,
									0.001,
									0.048,
									1.0,
									0.205,
									0.0,
									0.345,
									1.0,
									0.284,
									0.512,
									0.327,
									0.63
								],
								"e": 0.743
							},
							{
								"h": [
									0.969,
									0.194,
									0.797,
									0.058,
									0.371,
									0.437,
									0.028,
									0.066,
									0.187,
									0.223,
									0.636,
									0.008
								],
								"e": 0.348
							},
							{
								"h": [
									0.339,
									0.049,
									0.591,
									0.11,
									0.534,
									0.24,
									0.04,
									0.827,
									0.162,
									0.848,
									0.454,
									0.071
								],
								"e": 0.606
							},
							{
								"h": [
									0.0,
									0.018,
									0.094,
									0.293,
									0.011,
									0.04,
									1.0,
									0.545,
									0.08,
									0.098,
									0.081,
									0.245
								],
								"e": 0.195
							},
							{
								"h": [
									0.049,
									0.191,
									0.015,
									0.255,
									0.068,
									0.079,
									0.11,
									0.108,
									0.025,
									0.09,
									0.009,
									1.0
								],
								"e": 0.301
							},
							{
								"h": [
									0.002,
									0.0,
									0.33,
									0.108,
									0.163,
									0.002,
									0.016,
									0.132,
									0.016,
									0.006,
									0.118,
									0.051
								],
								"e": 0.059
							},
							{
								"h": [
									0.11,
									0.03,
									0.022,
									0.052,
									0.003,
									0.041,
									0.007,
									0.084,
									0.72,
									0.0,
									0.011,
									0.093
								],
								"e": 0.061
							},
							{
								"h": [
									0.134,
									0.004,
									0.0,
									0.051,
									0.054,
									0.03,
									0.005,
									0.032,
									0.008,
									0.022,
									0.0,
									0.001
								],
								"e": 0.022
							},
							{
								"h": [
									0.005,
									0.0,
									0.004,
									0.007,
									0.024,
									0.268,
									0.001,
									0.073,
									0.006,
									0.017,
									0.018,
									0.003
								],
								"e": 0.022
							},
							{
								"h": [
									0.003,
									0.001,
									0.027,
									0.106,
									0.018,
									0.017,
									0.001,
									0.004,
									0.011,
									0.001,
									0.067,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.002,
									0.002,
									0.002,
									0.001,
									0.044,
									0.008,
									0.004,
									0.0,
									0.124,
									0.004,
									0.004
								],
								"e": 0.012
							},
							{
								"h": [
									0.018,
									0.012,
									0.002,
									0.012,
									0.005,
									0.002,
									0.002,
									0.003,
									0.031,
									0.0,
									0.013,
									0.012
								],
								"e": 0.008
							}
						],
						[
							{
								"h": [
									0.103,
									1.0,
									0.126,
									0.016,
									1.0,
									1.0,
									0.314,
									0.019,
									0.163,
									0.048,
									1.0,
									0.075
								],
								"e": 1.0
							},
							{
								"h": [
									0.428,
									0.237,
									1.0,
									0.075,
									0.504,
									0.191,
									0.187,
									0.125,
									0.245,
									0.138,
									1.0,
									1.0
								],
								"e": 0.693
							},
							{
								"h": [
									0.067,
									0.097,
									0.12,
									0.029,
									0.114,
									0.666,
									0.07,
									0.901,
									0.182,
									1.0,
									1.0,
									0.139
								],
								"e": 0.523
							},
							{
								"h": [
									0.338,
									0.06,
									0.04,
									0.053,
									0.006,
									0.056,
									0.621,
									0.972,
									0.099,
									0.338,
									0.588,
									0.115
								],
								"e": 0.285
							},
							{
								"h": [
									0.073,
									0.291,
									0.016,
									0.116,
									0.29,
									0.384,
									0.123,
									1.0,
									0.043,
									0.045,
									0.079,
									0.0
								],
								"e": 0.203
							},
							{
								"h": [
									0.016,
									0.0,
									0.058,
									0.164,
									0.189,
									0.002,
									0.004,
									0.059,
									0.012,
									0.018,
									0.121,
									0.148
								],
								"e": 0.045
							},
							{
								"h": [
									0.182,
									0.018,
									0.048,
									0.025,
									0.004,
									0.241,
									0.146,
									0.282,
									0.173,
									0.001,
									0.037,
									0.204
								],
								"e": 0.087
							},
							{
								"h": [
									0.081,
									0.034,
									0.0,
									0.117,
									0.074,
									0.112,
									0.016,
									0.858,
									0.025,
									0.015,
									0.001,
									0.015
								],
								"e": 0.066
							},
							{
								"h": [
									0.057,
									0.008,
									0.006,
									0.023,
									0.08,
									0.289,
									0.011,
									0.122,
									0.019,
									0.046,
									0.174,
									0.019
								],
								"e": 0.059
							},
							{
								"h": [
									0.031,
									0.037,
									0.092,
									0.153,
									0.016,
									0.169,
									0.014,
									0.043,
									0.058,
									0.005,
									0.117,
									0.017
								],
								"e": 0.053
							},
							{
								"h": [
									0.012,
									0.005,
									0.006,
									0.033,
									0.005,
									0.085,
									0.019,
									0.037,
									0.014,
									0.369,
									0.006,
									0.006
								],
								"e": 0.039
							},
							{
								"h": [
									0.268,
									0.038,
									0.002,
									0.047,
									0.048,
									0.006,
									0.006,
									0.015,
									0.871,
									0.005,
									0.068,
									0.071
								],
								"e": 0.063
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				}
			]
		},
		{
			"c": [
				{
					"i": "Hero",
					"o": ".",
					"t": ":",
					"r": [
						{
							"p": " ",
							"b": 0.005,
							"k": {
								" ": 0.028,
								",": 0.015,
								"s": 0.012,
								".": 0.009,
								" and": 0.009,
								" to": 0.007,
								" of": 0.006,
								"il": 0.006,
								" King": 0.006,
								"lets": 0.006
							}
						},
						{
							"p": ",",
							"b": 0.007,
							"k": {
								",": 0.058,
								" ": 0.038,
								" the": 0.034,
								" and": 0.034,
								".": 0.028,
								" in": 0.02,
								" to": 0.018,
								"-": 0.017,
								" of": 0.017,
								" a": 0.016
							}
						},
						{
							"p": ",",
							"b": 0.004,
							"k": {
								",": 0.028,
								" the": 0.025,
								" and": 0.019,
								" ": 0.016,
								".": 0.015,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.004,
							"k": {
								",": 0.028,
								" the": 0.025,
								" and": 0.019,
								" ": 0.016,
								".": 0.015,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.004,
							"k": {
								",": 0.028,
								" the": 0.025,
								" and": 0.018,
								" ": 0.016,
								".": 0.015,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.004,
							"k": {
								",": 0.027,
								" the": 0.024,
								" and": 0.018,
								" ": 0.016,
								".": 0.015,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.004,
							"k": {
								",": 0.027,
								" the": 0.024,
								" and": 0.018,
								".": 0.015,
								" ": 0.015,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.004,
							"k": {
								",": 0.027,
								" the": 0.024,
								" and": 0.018,
								".": 0.015,
								" ": 0.015,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								" of": 0.01,
								"-": 0.009
							}
						},
						{
							"p": ",",
							"b": 0.004,
							"k": {
								",": 0.028,
								" the": 0.024,
								" and": 0.018,
								".": 0.015,
								" ": 0.015,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								"-": 0.01,
								" of": 0.01
							}
						},
						{
							"p": ",",
							"b": 0.004,
							"k": {
								",": 0.028,
								" the": 0.024,
								" and": 0.018,
								" ": 0.016,
								".": 0.015,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								"-": 0.01,
								" of": 0.01
							}
						},
						{
							"p": ",",
							"b": 0.004,
							"k": {
								",": 0.029,
								" the": 0.024,
								" and": 0.019,
								" ": 0.016,
								".": 0.015,
								" a": 0.013,
								" in": 0.013,
								" to": 0.011,
								"-": 0.01,
								" of": 0.01
							}
						},
						{
							"p": ".",
							"b": 0.014,
							"k": {
								".": 0.045,
								",": 0.044,
								" ": 0.034,
								"-": 0.02,
								" of": 0.018,
								" and": 0.018,
								":": 0.014,
								"'s": 0.013,
								" to": 0.011,
								" is": 0.011
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ":",
					"o": " ",
					"t": " Harry",
					"r": [
						{
							"p": " '",
							"b": 0.0,
							"k": {
								" '": 0.07,
								":": 0.027,
								" \"": 0.024,
								" D": 0.02,
								" ": 0.011,
								" A": 0.014,
								" http": 0.014,
								" Re": 0.012,
								" The": 0.011
							}
						},
						{
							"p": " Who",
							"b": 0.0,
							"k": {
								" Who": 0.032,
								" D": 0.028,
								" A": 0.021,
								" M": 0.018,
								" '": 0.018,
								":": 0.016,
								" ": 0.016,
								" -": 0.016,
								" One": 0.016,
								" :": 0.013
							}
						},
						{
							"p": ":",
							"b": 0.0,
							"k": {
								":": 0.022,
								" http": 0.022,
								" Who": 0.021,
								" -": 0.02,
								" Py": 0.017,
								" :": 0.015,
								" Posts": 0.015,
								" ": 0.014,
								" D": 0.014,
								" 1": 0.012
							}
						},
						{
							"p": " Who",
							"b": 0.0,
							"k": {
								" Who": 0.039,
								" Death": 0.021,
								":": 0.018,
								" http": 0.018,
								" Ret": 0.018,
								" -": 0.016,
								" M": 0.015,
								" Red": 0.014,
								" Fl": 0.012,
								" Py": 0.012
							}
						},
						{
							"p": " Battle",
							"b": 0.0,
							"k": {
								" Battle": 0.019,
								" R": 0.017,
								" -": 0.015,
								" Ph": 0.014,
								" Red": 0.014,
								" Death": 0.014,
								" Gl": 0.013,
								" Dog": 0.013,
								" M": 0.011,
								" Dark": 0.011
							}
						},
						{
							"p": " Dark",
							"b": 0.0,
							"k": {
								" Dark": 0.024,
								" Sword": 0.021,
								" Red": 0.019,
								" Battle": 0.019,
								" R": 0.016,
								" Ret": 0.016,
								" Death": 0.015,
								" M": 0.013,
								" Who": 0.012,
								" Unknown": 0.012
							}
						},
						{
							"p": " Dark",
							"b": 0.001,
							"k": {
								" Dark": 0.076,
								" Shadow": 0.055,
								" Unknown": 0.024,
								" M": 0.022,
								" Battle": 0.022,
								" Sword": 0.02,
								" Ret": 0.019,
								" R": 0.017,
								" Death": 0.013,
								" Mario": 0.012
							}
						},
						{
							"p": " Unknown",
							"b": 0.001,
							"k": {
								" Unknown": 0.074,
								" Dark": 0.025,
								" Who": 0.024,
								" Battle": 0.023,
								" Shadow": 0.018,
								" Master": 0.017,
								" Death": 0.017,
								" Sword": 0.016,
								" Zombie": 0.015,
								" [": 0.014
							}
						},
						{
							"p": " Unknown",
							"b": 0.001,
							"k": {
								" Unknown": 0.072,
								" Battle": 0.024,
								" The": 0.02,
								" Dark": 0.02,
								" [": 0.017,
								" Yes": 0.017,
								" Yeah": 0.016,
								" Who": 0.015,
								" Shadow": 0.014,
								" Sword": 0.014
							}
						},
						{
							"p": " The",
							"b": 0.001,
							"k": {
								" The": 0.06,
								" Yeah": 0.049,
								" Yes": 0.036,
								" [": 0.024,
								" You": 0.018,
								" Shadow": 0.017,
								" ": 0.015,
								" What": 0.015,
								" No": 0.014,
								" Dark": 0.014
							}
						},
						{
							"p": " The",
							"b": 0.001,
							"k": {
								" The": 0.067,
								" ": 0.04,
								" I": 0.016,
								" \"": 0.015,
								" You": 0.015,
								" Yes": 0.013,
								" [": 0.01,
								" A": 0.009,
								" What": 0.009,
								" No": 0.008
							}
						},
						{
							"p": " ",
							"b": 0.001,
							"k": {
								" ": 0.042,
								" The": 0.031,
								" \"": 0.027,
								" I": 0.021,
								" You": 0.011,
								" 1": 0.008,
								" [": 0.008,
								" A": 0.007,
								" This": 0.006,
								" No": 0.005
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.006,
									1.0,
									1.0,
									1.0,
									0.202,
									1.0,
									1.0,
									0.182,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.031,
									1.0,
									0.416,
									0.994,
									0.044,
									1.0,
									0.018,
									0.059,
									1.0,
									0.557,
									0.723,
									0.302
								],
								"e": 0.626
							},
							{
								"h": [
									0.005,
									0.082,
									0.019,
									0.86,
									0.061,
									0.171,
									0.016,
									0.039,
									0.236,
									0.121,
									1.0,
									0.352
								],
								"e": 0.247
							},
							{
								"h": [
									0.086,
									0.024,
									0.005,
									0.015,
									0.034,
									0.098,
									0.029,
									0.223,
									0.027,
									0.005,
									0.098,
									0.046
								],
								"e": 0.055
							},
							{
								"h": [
									0.008,
									0.041,
									0.143,
									0.051,
									0.011,
									0.06,
									0.037,
									0.015,
									0.209,
									0.034,
									0.004,
									0.022
								],
								"e": 0.05
							},
							{
								"h": [
									0.061,
									0.047,
									0.076,
									0.1,
									0.047,
									0.053,
									0.047,
									0.004,
									0.023,
									0.076,
									0.026,
									0.0
								],
								"e": 0.046
							},
							{
								"h": [
									0.03,
									0.0,
									0.324,
									0.085,
									0.014,
									0.022,
									0.06,
									0.029,
									0.017,
									0.015,
									0.094,
									0.095
								],
								"e": 0.06
							},
							{
								"h": [
									0.143,
									0.045,
									0.025,
									0.062,
									0.119,
									0.059,
									0.078,
									0.036,
									0.032,
									0.012,
									0.032,
									0.057
								],
								"e": 0.057
							},
							{
								"h": [
									0.029,
									0.036,
									0.007,
									0.06,
									0.03,
									0.049,
									0.044,
									0.083,
									0.036,
									0.061,
									0.065,
									0.027
								],
								"e": 0.044
							},
							{
								"h": [
									0.087,
									0.016,
									0.058,
									0.051,
									0.046,
									0.035,
									0.044,
									0.097,
									0.064,
									0.043,
									0.119,
									0.041
								],
								"e": 0.058
							},
							{
								"h": [
									0.115,
									0.027,
									0.028,
									0.064,
									0.045,
									0.088,
									0.029,
									0.037,
									0.092,
									0.009,
									0.021,
									0.019
								],
								"e": 0.047
							},
							{
								"h": [
									0.096,
									0.026,
									0.044,
									0.074,
									0.134,
									0.034,
									0.028,
									0.078,
									0.028,
									0.032,
									0.023,
									0.055
								],
								"e": 0.053
							},
							{
								"h": [
									0.678,
									0.21,
									0.087,
									0.141,
									0.184,
									0.126,
									0.042,
									0.212,
									1.0,
									0.044,
									0.224,
									0.216
								],
								"e": 0.286
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Harry",
					"o": " Potter",
					"t": " Potter",
					"r": [
						{
							"p": " Potter",
							"b": 0.99,
							"k": {
								" Potter": 0.99,
								" Harry": 0.01,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 0.999,
							"k": {
								" Potter": 0.999,
								" Harry": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 0.999,
							"k": {
								" Potter": 0.999,
								" Harry": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 1.0,
							"k": {
								" Potter": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 1.0,
							"k": {
								" Potter": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 0.999,
							"k": {
								" Potter": 0.999,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 0.999,
							"k": {
								" Potter": 0.999,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 0.998,
							"k": {
								" Potter": 0.998,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 0.995,
							"k": {
								" Potter": 0.995,
								" Smith": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 0.994,
							"k": {
								" Potter": 0.994,
								" Smith": 0.001,
								" Harry": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 0.91,
							"k": {
								" Potter": 0.91,
								",": 0.006,
								"'s": 0.004,
								" Smith": 0.003,
								" Harry": 0.002,
								" Kane": 0.002,
								" Dresden": 0.002,
								"!": 0.001,
								"-": 0.001,
								".": 0.001
							}
						},
						{
							"p": " Potter",
							"b": 0.512,
							"k": {
								" Potter": 0.512,
								",": 0.041,
								" ": 0.019,
								"'s": 0.018,
								".": 0.012,
								" and": 0.008,
								" Dresden": 0.008,
								" \"": 0.007,
								" (": 0.006,
								"/": 0.005
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.001,
									1.0,
									0.076,
									0.236,
									0.122,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.916
								],
								"e": 0.874
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.002,
									0.213
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.531,
									1.0,
									1.0,
									0.536,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.538,
									0.85,
									1.0,
									1.0,
									1.0,
									1.0,
									0.682,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.558,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.34,
									0.001,
									0.321,
									0.0,
									0.011,
									0.0,
									0.346,
									0.429,
									0.651,
									0.633,
									0.592,
									0.489
								],
								"e": 0.38
							},
							{
								"h": [
									0.192,
									0.939,
									0.094,
									0.389,
									0.1,
									0.324,
									0.194,
									0.12,
									0.301,
									0.135,
									1.0,
									0.077
								],
								"e": 0.315
							},
							{
								"h": [
									0.22,
									0.179,
									1.0,
									0.334,
									0.574,
									1.0,
									0.115,
									0.202,
									0.922,
									0.174,
									0.155,
									0.045
								],
								"e": 0.397
							},
							{
								"h": [
									0.003,
									0.385,
									1.0,
									1.0,
									0.026,
									0.053,
									0.31,
									0.548,
									1.0,
									0.159,
									0.01,
									0.109
								],
								"e": 0.33
							},
							{
								"h": [
									0.211,
									1.0,
									0.334,
									0.062,
									0.069,
									0.218,
									0.268,
									0.014,
									0.028,
									0.516,
									0.063,
									1.0
								],
								"e": 0.353
							},
							{
								"h": [
									0.006,
									0.0,
									0.961,
									0.259,
									0.135,
									0.008,
									0.255,
									0.034,
									0.064,
									0.055,
									0.15,
									0.08
								],
								"e": 0.128
							},
							{
								"h": [
									0.954,
									0.037,
									0.047,
									0.072,
									0.203,
									0.113,
									0.065,
									0.092,
									0.334,
									0.007,
									0.013,
									0.505
								],
								"e": 0.159
							},
							{
								"h": [
									0.899,
									0.024,
									0.005,
									0.052,
									0.089,
									0.147,
									0.037,
									0.068,
									0.048,
									0.118,
									0.017,
									0.015
								],
								"e": 0.096
							},
							{
								"h": [
									0.116,
									0.031,
									0.065,
									0.042,
									0.076,
									0.14,
									0.079,
									0.593,
									0.115,
									0.039,
									0.095,
									0.055
								],
								"e": 0.106
							},
							{
								"h": [
									0.069,
									0.042,
									0.007,
									0.39,
									0.033,
									0.032,
									0.01,
									0.051,
									0.062,
									0.008,
									0.105,
									0.03
								],
								"e": 0.061
							},
							{
								"h": [
									0.031,
									0.018,
									0.023,
									0.03,
									0.138,
									0.09,
									0.016,
									0.099,
									0.057,
									0.025,
									0.029,
									0.037
								],
								"e": 0.048
							},
							{
								"h": [
									0.131,
									0.037,
									0.076,
									0.057,
									0.115,
									0.03,
									0.02,
									0.026,
									0.835,
									0.027,
									0.047,
									0.033
								],
								"e": 0.098
							}
						],
						[
							{
								"h": [
									0.491,
									1.0,
									0.146,
									1.0,
									1.0,
									1.0,
									0.47,
									0.239,
									0.272,
									0.071,
									0.801,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.337,
									0.258,
									0.161,
									0.392,
									0.135,
									0.158,
									0.137,
									0.063,
									0.14,
									0.073,
									0.969,
									1.0
								],
								"e": 0.343
							},
							{
								"h": [
									0.205,
									0.104,
									0.305,
									0.459,
									0.075,
									0.062,
									0.034,
									0.889,
									0.065,
									0.244,
									0.343,
									0.113
								],
								"e": 0.233
							},
							{
								"h": [
									0.052,
									0.036,
									0.151,
									0.035,
									0.004,
									0.022,
									0.499,
									0.12,
									0.046,
									0.121,
									0.097,
									0.062
								],
								"e": 0.097
							},
							{
								"h": [
									0.065,
									0.058,
									0.062,
									0.082,
									0.059,
									0.059,
									0.052,
									0.573,
									0.008,
									0.05,
									0.022,
									0.0
								],
								"e": 0.092
							},
							{
								"h": [
									0.065,
									0.001,
									0.089,
									0.135,
									0.038,
									0.01,
									0.096,
									0.021,
									0.062,
									0.03,
									0.209,
									0.038
								],
								"e": 0.06
							},
							{
								"h": [
									0.17,
									0.024,
									0.019,
									0.025,
									0.182,
									0.094,
									0.104,
									0.066,
									0.047,
									0.006,
									0.068,
									0.04
								],
								"e": 0.064
							},
							{
								"h": [
									0.048,
									0.079,
									0.007,
									0.022,
									0.049,
									0.026,
									0.073,
									0.058,
									0.092,
									0.015,
									0.011,
									0.023
								],
								"e": 0.041
							},
							{
								"h": [
									0.021,
									0.005,
									0.068,
									0.015,
									0.048,
									0.051,
									0.041,
									0.077,
									0.032,
									0.065,
									0.072,
									0.043
								],
								"e": 0.043
							},
							{
								"h": [
									0.182,
									0.004,
									0.032,
									0.083,
									0.021,
									0.048,
									0.013,
									0.02,
									0.161,
									0.01,
									0.02,
									0.004
								],
								"e": 0.046
							},
							{
								"h": [
									0.101,
									0.013,
									0.008,
									0.035,
									0.047,
									0.023,
									0.033,
									0.058,
									0.012,
									0.008,
									0.016,
									0.017
								],
								"e": 0.03
							},
							{
								"h": [
									0.122,
									0.06,
									0.024,
									0.026,
									0.048,
									0.01,
									0.016,
									0.005,
									1.0,
									0.008,
									0.042,
									0.05
								],
								"e": 0.09
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Potter",
					"o": " and",
					"t": ".",
					"r": [
						{
							"p": " Potter",
							"b": 0.0,
							"k": {
								" Potter": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 0.0,
							"k": {
								" Potter": 0.999,
								" Weasley": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 0.0,
							"k": {
								" Potter": 0.996,
								" Weasley": 0.002,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 0.0,
							"k": {
								" Potter": 0.996,
								" canon": 0.001,
								" Weasley": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " Potter",
							"b": 0.0,
							"k": {
								" Potter": 0.956,
								"'s": 0.006,
								" canon": 0.003,
								" Fan": 0.002,
								" trilogy": 0.002,
								" fandom": 0.002,
								",": 0.001,
								"land": 0.001,
								" Book": 0.001,
								" Foundation": 0.001
							}
						},
						{
							"p": " Potter",
							"b": 0.001,
							"k": {
								" Potter": 0.736,
								",": 0.021,
								"'s": 0.021,
								" Book": 0.011,
								"land": 0.01,
								" Holmes": 0.01,
								" Fan": 0.009,
								" Foundation": 0.006,
								" trilogy": 0.006,
								" Express": 0.005
							}
						},
						{
							"p": " Potter",
							"b": 0.006,
							"k": {
								" Potter": 0.251,
								",": 0.092,
								"'s": 0.08,
								" Dracula": 0.024,
								" and": 0.021,
								":": 0.019,
								" Adventure": 0.015,
								" Company": 0.014,
								" franchise": 0.014,
								" Chronicles": 0.013
							}
						},
						{
							"p": ",",
							"b": 0.006,
							"k": {
								",": 0.136,
								"'s": 0.097,
								":": 0.075,
								"/": 0.053,
								" and": 0.031,
								" Potter": 0.028,
								" Adventure": 0.027,
								" &": 0.021,
								" Book": 0.02,
								" Chronicles": 0.019
							}
						},
						{
							"p": ":",
							"b": 0.011,
							"k": {
								":": 0.225,
								",": 0.122,
								" ": 0.061,
								"'s": 0.059,
								"/": 0.056,
								" and": 0.038,
								" -": 0.034,
								" &": 0.019,
								" (": 0.018,
								" Online": 0.018
							}
						},
						{
							"p": ":",
							"b": 0.02,
							"k": {
								":": 0.451,
								",": 0.163,
								" and": 0.056,
								"'s": 0.043,
								" ": 0.034,
								"/": 0.022,
								".": 0.02,
								" -": 0.017,
								" (": 0.015,
								" Potter": 0.015
							}
						},
						{
							"p": ",",
							"b": 0.031,
							"k": {
								",": 0.195,
								":": 0.19,
								" and": 0.086,
								" ": 0.064,
								" -": 0.034,
								" (": 0.032,
								".": 0.031,
								"/": 0.03,
								"'s": 0.025,
								"!": 0.022
							}
						},
						{
							"p": " and",
							"b": 0.032,
							"k": {
								" and": 0.141,
								" ": 0.127,
								",": 0.097,
								":": 0.059,
								" -": 0.05,
								" (": 0.045,
								".": 0.032,
								"\"": 0.017,
								" is": 0.016,
								"/": 0.014
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.773,
									0.0,
									0.914,
									0.019,
									0.03,
									0.031,
									1.0,
									0.063,
									0.917,
									1.0,
									0.821,
									0.681
								],
								"e": 0.541
							},
							{
								"h": [
									0.866,
									1.0,
									1.0,
									1.0,
									1.0,
									0.896,
									0.614,
									1.0,
									1.0,
									1.0,
									0.002,
									0.178
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.289,
									1.0,
									1.0,
									0.879,
									1.0,
									0.878,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.943,
									1.0,
									1.0,
									1.0,
									0.462,
									1.0,
									0.942,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.954,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.626,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.795,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.949,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.942,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									1.0,
									0.0,
									0.388,
									0.0,
									0.005,
									0.0,
									0.309,
									0.029,
									0.577,
									0.615,
									0.605,
									0.588
								],
								"e": 0.347
							},
							{
								"h": [
									0.251,
									0.921,
									0.126,
									0.27,
									0.112,
									0.398,
									0.227,
									0.177,
									0.479,
									0.125,
									0.815,
									0.041
								],
								"e": 0.352
							},
							{
								"h": [
									0.226,
									0.153,
									0.857,
									0.207,
									0.576,
									1.0,
									0.116,
									0.155,
									0.553,
									0.131,
									0.135,
									0.043
								],
								"e": 0.326
							},
							{
								"h": [
									0.003,
									0.256,
									1.0,
									0.403,
									0.028,
									0.045,
									0.205,
									0.283,
									1.0,
									0.117,
									0.016,
									0.101
								],
								"e": 0.216
							},
							{
								"h": [
									0.181,
									1.0,
									0.163,
									0.087,
									0.072,
									0.158,
									0.142,
									0.041,
									0.017,
									0.185,
									0.1,
									0.0
								],
								"e": 0.171
							},
							{
								"h": [
									0.003,
									0.0,
									0.358,
									0.544,
									0.095,
									0.009,
									0.011,
									0.018,
									0.097,
									0.055,
									0.045,
									0.091
								],
								"e": 0.084
							},
							{
								"h": [
									1.0,
									0.051,
									0.058,
									0.048,
									0.013,
									0.063,
									0.04,
									0.136,
									0.244,
									0.003,
									0.014,
									0.607
								],
								"e": 0.133
							},
							{
								"h": [
									0.338,
									0.017,
									0.006,
									0.042,
									0.481,
									0.137,
									0.021,
									0.062,
									0.098,
									0.317,
									0.007,
									0.014
								],
								"e": 0.104
							},
							{
								"h": [
									0.079,
									0.107,
									0.092,
									0.133,
									0.116,
									0.312,
									0.119,
									0.734,
									0.135,
									0.092,
									0.104,
									0.065
								],
								"e": 0.146
							},
							{
								"h": [
									0.059,
									0.054,
									0.036,
									0.674,
									0.102,
									0.049,
									0.037,
									0.039,
									0.016,
									0.013,
									0.418,
									0.076
								],
								"e": 0.099
							},
							{
								"h": [
									0.03,
									0.032,
									0.075,
									0.228,
									0.47,
									0.588,
									0.031,
									0.17,
									0.077,
									0.159,
									0.036,
									0.429
								],
								"e": 0.165
							},
							{
								"h": [
									1.0,
									0.132,
									0.208,
									0.264,
									0.134,
									0.086,
									0.037,
									0.083,
									0.344,
									0.06,
									0.262,
									0.192
								],
								"e": 0.199
							}
						],
						[
							{
								"h": [
									0.495,
									0.053,
									1.0,
									0.269,
									0.705,
									0.014,
									0.649,
									1.0,
									1.0,
									0.204,
									0.679,
									1.0
								],
								"e": 0.737
							},
							{
								"h": [
									1.0,
									0.224,
									0.21,
									0.268,
									0.149,
									0.561,
									1.0,
									0.085,
									0.242,
									0.196,
									0.837,
									0.119
								],
								"e": 0.425
							},
							{
								"h": [
									0.609,
									0.06,
									1.0,
									0.322,
									0.866,
									0.092,
									0.031,
									0.605,
									0.055,
									0.389,
									0.319,
									0.111
								],
								"e": 0.326
							},
							{
								"h": [
									0.009,
									0.02,
									0.592,
									0.505,
									0.003,
									0.02,
									1.0,
									0.296,
									0.089,
									0.176,
									0.064,
									0.132
								],
								"e": 0.189
							},
							{
								"h": [
									0.138,
									0.07,
									0.019,
									0.103,
									0.184,
									0.07,
									0.101,
									0.385,
									0.004,
									0.03,
									0.024,
									1.0
								],
								"e": 0.218
							},
							{
								"h": [
									0.005,
									0.0,
									0.762,
									0.23,
									0.07,
									0.005,
									0.54,
									0.042,
									0.021,
									0.017,
									0.037,
									0.216
								],
								"e": 0.117
							},
							{
								"h": [
									0.31,
									0.009,
									0.036,
									0.07,
									0.016,
									0.039,
									0.018,
									0.057,
									1.0,
									0.003,
									0.013,
									0.103
								],
								"e": 0.088
							},
							{
								"h": [
									0.441,
									0.011,
									0.008,
									0.02,
									0.179,
									0.033,
									0.025,
									0.047,
									0.08,
									0.023,
									0.011,
									0.021
								],
								"e": 0.057
							},
							{
								"h": [
									0.028,
									0.006,
									0.023,
									0.031,
									0.034,
									0.178,
									0.008,
									0.532,
									0.018,
									0.044,
									0.054,
									0.031
								],
								"e": 0.06
							},
							{
								"h": [
									0.073,
									0.007,
									0.018,
									0.409,
									0.031,
									0.027,
									0.013,
									0.022,
									0.045,
									0.006,
									0.071,
									0.005
								],
								"e": 0.043
							},
							{
								"h": [
									0.043,
									0.009,
									0.015,
									0.042,
									0.018,
									0.135,
									0.02,
									0.021,
									0.007,
									0.137,
									0.018,
									0.043
								],
								"e": 0.038
							},
							{
								"h": [
									0.351,
									0.145,
									0.037,
									0.086,
									0.048,
									0.008,
									0.015,
									0.006,
									0.446,
									0.01,
									0.13,
									0.116
								],
								"e": 0.099
							}
						],
						[
							{
								"h": [
									0.401,
									1.0,
									0.432,
									1.0,
									1.0,
									1.0,
									0.624,
									0.046,
									0.303,
									0.076,
									1.0,
									0.774
								],
								"e": 1.0
							},
							{
								"h": [
									0.195,
									0.121,
									0.397,
									0.286,
									0.205,
									1.0,
									0.639,
									0.119,
									0.21,
									0.156,
									1.0,
									1.0
								],
								"e": 0.515
							},
							{
								"h": [
									0.102,
									0.042,
									0.307,
									0.126,
									0.207,
									0.119,
									0.032,
									1.0,
									0.155,
									0.627,
									0.645,
									0.22
								],
								"e": 0.268
							},
							{
								"h": [
									0.027,
									0.025,
									0.376,
									0.057,
									0.002,
									0.03,
									0.224,
									0.562,
									0.072,
									0.016,
									0.168,
									0.091
								],
								"e": 0.109
							},
							{
								"h": [
									0.041,
									0.099,
									0.044,
									0.11,
									0.106,
									0.082,
									0.142,
									1.0,
									0.023,
									0.021,
									0.067,
									0.0
								],
								"e": 0.129
							},
							{
								"h": [
									0.026,
									0.003,
									0.12,
									0.06,
									0.061,
									0.004,
									0.043,
									0.071,
									0.109,
									0.007,
									0.05,
									0.12
								],
								"e": 0.05
							},
							{
								"h": [
									0.202,
									0.02,
									0.047,
									0.017,
									0.041,
									0.109,
									0.07,
									0.131,
									0.307,
									0.002,
									0.041,
									0.046
								],
								"e": 0.069
							},
							{
								"h": [
									0.194,
									0.094,
									0.014,
									0.042,
									0.036,
									0.05,
									0.062,
									0.033,
									0.039,
									0.048,
									0.008,
									0.031
								],
								"e": 0.049
							},
							{
								"h": [
									0.033,
									0.027,
									0.049,
									0.032,
									0.05,
									0.123,
									0.044,
									0.11,
									0.041,
									0.115,
									0.116,
									0.047
								],
								"e": 0.061
							},
							{
								"h": [
									0.189,
									0.013,
									0.024,
									0.235,
									0.02,
									0.129,
									0.02,
									0.064,
									0.12,
									0.012,
									0.059,
									0.009
								],
								"e": 0.064
							},
							{
								"h": [
									0.058,
									0.024,
									0.02,
									0.04,
									0.026,
									0.083,
									0.047,
									0.124,
									0.023,
									0.157,
									0.041,
									0.148
								],
								"e": 0.061
							},
							{
								"h": [
									0.409,
									0.094,
									0.091,
									0.181,
									0.244,
									0.035,
									0.035,
									0.04,
									1.0,
									0.049,
									0.401,
									0.123
								],
								"e": 0.198
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ".",
					"o": " ",
					"t": " Vill",
					"r": [
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.13,
								" The": 0.073,
								" We": 0.045,
								" It": 0.04,
								" You": 0.024,
								" I": 0.023,
								" That": 0.023,
								" He": 0.022,
								" To": 0.019,
								" This": 0.018
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.103,
								" ": 0.096,
								" It": 0.079,
								" We": 0.038,
								" That": 0.035,
								" There": 0.03,
								" This": 0.028,
								" To": 0.028,
								" They": 0.026,
								" He": 0.023
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.155,
								" The": 0.114,
								" It": 0.08,
								" There": 0.05,
								" That": 0.03,
								" When": 0.025,
								" His": 0.023,
								" To": 0.021,
								" This": 0.02,
								" If": 0.02
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.302,
								" The": 0.085,
								" It": 0.053,
								" There": 0.037,
								" When": 0.034,
								" His": 0.03,
								"  ": 0.026,
								" This": 0.023,
								" Also": 0.023,
								" He": 0.02
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.218,
								" The": 0.139,
								" It": 0.064,
								" There": 0.036,
								" When": 0.031,
								" Also": 0.025,
								" This": 0.023,
								" You": 0.022,
								" If": 0.022,
								" They": 0.022
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.207,
								" It": 0.086,
								" The": 0.075,
								" You": 0.05,
								" This": 0.037,
								" If": 0.032,
								" That": 0.031,
								" They": 0.028,
								" There": 0.028,
								" Also": 0.028
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.267,
								" You": 0.075,
								" It": 0.058,
								" The": 0.053,
								" That": 0.034,
								" He": 0.027,
								" This": 0.027,
								" They": 0.027,
								"  ": 0.025,
								" Also": 0.024
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.244,
								" You": 0.137,
								" The": 0.053,
								" It": 0.046,
								" This": 0.036,
								"  ": 0.032,
								" Also": 0.029,
								" Who": 0.025,
								" He": 0.021,
								" To": 0.019
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.212,
								" You": 0.169,
								" It": 0.068,
								" This": 0.055,
								" The": 0.047,
								"  ": 0.045,
								" Also": 0.031,
								" If": 0.02,
								" They": 0.015,
								" There": 0.015
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.411,
								"  ": 0.127,
								" You": 0.054,
								" The": 0.044,
								" It": 0.033,
								" He": 0.026,
								" This": 0.023,
								" Harry": 0.013,
								" If": 0.011,
								" And": 0.009
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.44,
								"  ": 0.094,
								" Harry": 0.035,
								" He": 0.034,
								" The": 0.03,
								" You": 0.028,
								" It": 0.019,
								" This": 0.017,
								" I": 0.015,
								"<|endoftext|>": 0.008
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.282,
								" I": 0.03,
								"  ": 0.03,
								" He": 0.029,
								" The": 0.024,
								" Harry": 0.02,
								" You": 0.017,
								" It": 0.016,
								" (": 0.013,
								" This": 0.013
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.002,
									0.528,
									0.143,
									0.461,
									0.24,
									1.0,
									0.316,
									0.061,
									1.0,
									0.914,
									0.87
								],
								"e": 0.588
							},
							{
								"h": [
									1.0,
									1.0,
									0.93,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.004,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.137,
									0.516,
									1.0,
									0.503,
									1.0,
									0.764,
									1.0,
									0.183,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.362,
									0.608,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.219,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.796,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.143,
									0.049,
									1.0,
									0.285,
									0.302,
									0.192,
									0.058,
									0.582,
									0.325,
									0.755,
									1.0,
									0.46
								],
								"e": 0.483
							},
							{
								"h": [
									0.285,
									0.918,
									0.116,
									0.184,
									0.099,
									0.465,
									0.383,
									0.129,
									0.372,
									0.167,
									0.609,
									0.57
								],
								"e": 0.337
							},
							{
								"h": [
									0.174,
									0.125,
									0.014,
									0.247,
									0.199,
									1.0,
									0.154,
									0.07,
									0.955,
									0.02,
									0.112,
									0.032
								],
								"e": 0.259
							},
							{
								"h": [
									0.012,
									0.903,
									0.967,
									0.029,
									0.05,
									0.214,
									0.061,
									0.016,
									0.552,
									0.711,
									0.04,
									0.165
								],
								"e": 0.247
							},
							{
								"h": [
									0.147,
									0.756,
									0.394,
									0.207,
									0.084,
									0.202,
									0.411,
									0.016,
									0.064,
									0.417,
									0.104,
									0.0
								],
								"e": 0.244
							},
							{
								"h": [
									0.0,
									0.0,
									0.411,
									0.446,
									0.103,
									0.006,
									0.004,
									0.024,
									0.014,
									0.024,
									0.165,
									0.064
								],
								"e": 0.075
							},
							{
								"h": [
									1.0,
									0.217,
									0.053,
									0.186,
									0.127,
									0.177,
									0.044,
									0.217,
									0.174,
									0.0,
									0.018,
									0.172
								],
								"e": 0.162
							},
							{
								"h": [
									0.035,
									0.014,
									0.001,
									0.154,
									0.577,
									0.146,
									0.045,
									0.883,
									0.093,
									0.129,
									0.005,
									0.002
								],
								"e": 0.134
							},
							{
								"h": [
									0.033,
									0.028,
									0.089,
									0.264,
									0.071,
									0.053,
									0.233,
									0.035,
									0.22,
									0.087,
									0.128,
									0.038
								],
								"e": 0.099
							},
							{
								"h": [
									0.132,
									0.036,
									0.063,
									0.132,
									0.511,
									0.044,
									0.029,
									0.075,
									0.063,
									0.007,
									0.036,
									0.018
								],
								"e": 0.084
							},
							{
								"h": [
									0.051,
									0.02,
									0.044,
									0.047,
									0.08,
									0.113,
									0.034,
									0.136,
									0.016,
									0.191,
									0.015,
									0.06
								],
								"e": 0.063
							},
							{
								"h": [
									0.746,
									0.13,
									0.116,
									0.157,
									0.115,
									0.181,
									0.021,
									0.104,
									0.652,
									0.044,
									0.222,
									0.207
								],
								"e": 0.189
							}
						],
						[
							{
								"h": [
									0.201,
									0.0,
									0.089,
									0.206,
									0.291,
									0.039,
									0.63,
									0.501,
									0.024,
									0.39,
									0.301,
									0.406
								],
								"e": 0.266
							},
							{
								"h": [
									0.623,
									0.345,
									0.229,
									0.247,
									0.177,
									0.088,
									0.169,
									0.175,
									0.168,
									0.132,
									0.79,
									0.556
								],
								"e": 0.285
							},
							{
								"h": [
									0.238,
									0.043,
									0.261,
									1.0,
									0.608,
									0.156,
									0.047,
									0.522,
									0.168,
									0.446,
									0.285,
									0.085
								],
								"e": 0.382
							},
							{
								"h": [
									0.005,
									0.032,
									0.613,
									0.009,
									0.019,
									0.052,
									1.0,
									0.067,
									0.076,
									0.089,
									0.036,
									0.187
								],
								"e": 0.143
							},
							{
								"h": [
									0.011,
									0.012,
									0.029,
									0.312,
									0.059,
									0.059,
									0.181,
									0.013,
									0.016,
									0.029,
									0.009,
									0.0
								],
								"e": 0.058
							},
							{
								"h": [
									0.006,
									0.001,
									0.852,
									0.141,
									0.057,
									0.042,
									0.01,
									0.002,
									0.041,
									0.063,
									0.046,
									0.038
								],
								"e": 0.071
							},
							{
								"h": [
									0.188,
									0.041,
									0.027,
									0.045,
									0.059,
									0.081,
									0.026,
									0.104,
									0.384,
									0.016,
									0.006,
									0.023
								],
								"e": 0.063
							},
							{
								"h": [
									0.039,
									0.019,
									0.03,
									0.036,
									0.071,
									0.031,
									0.064,
									0.058,
									0.074,
									0.019,
									0.055,
									0.044
								],
								"e": 0.043
							},
							{
								"h": [
									0.013,
									0.033,
									0.035,
									0.04,
									0.016,
									0.031,
									0.065,
									0.02,
									0.047,
									0.106,
									0.112,
									0.044
								],
								"e": 0.044
							},
							{
								"h": [
									0.11,
									0.007,
									0.044,
									0.061,
									0.026,
									0.04,
									0.092,
									0.05,
									0.248,
									0.041,
									0.019,
									0.015
								],
								"e": 0.057
							},
							{
								"h": [
									0.207,
									0.046,
									0.081,
									0.074,
									0.017,
									0.025,
									0.092,
									0.235,
									0.006,
									0.044,
									0.06,
									0.041
								],
								"e": 0.071
							},
							{
								"h": [
									0.395,
									0.282,
									0.083,
									0.08,
									0.047,
									0.022,
									0.036,
									0.025,
									0.183,
									0.028,
									0.361,
									0.112
								],
								"e": 0.118
							}
						],
						[
							{
								"h": [
									0.63,
									0.0,
									0.143,
									0.679,
									0.197,
									0.061,
									0.749,
									0.421,
									0.011,
									0.36,
									0.232,
									0.41
								],
								"e": 0.355
							},
							{
								"h": [
									0.318,
									0.284,
									0.414,
									0.272,
									0.2,
									0.133,
									0.097,
									0.245,
									0.144,
									0.071,
									1.0,
									0.388
								],
								"e": 0.276
							},
							{
								"h": [
									0.066,
									0.06,
									1.0,
									0.449,
									0.187,
									0.829,
									0.043,
									1.0,
									0.678,
									1.0,
									0.454,
									0.16
								],
								"e": 0.579
							},
							{
								"h": [
									0.005,
									0.057,
									0.546,
									0.113,
									0.018,
									0.084,
									0.574,
									1.0,
									0.328,
									0.068,
									0.125,
									0.376
								],
								"e": 0.23
							},
							{
								"h": [
									0.01,
									0.018,
									0.014,
									0.536,
									0.076,
									0.05,
									0.305,
									0.215,
									0.024,
									0.019,
									0.013,
									1.0
								],
								"e": 0.252
							},
							{
								"h": [
									0.002,
									0.0,
									0.128,
									0.013,
									0.1,
									0.015,
									0.082,
									0.007,
									0.013,
									0.022,
									0.165,
									0.127
								],
								"e": 0.047
							},
							{
								"h": [
									0.118,
									0.03,
									0.042,
									0.039,
									0.148,
									0.175,
									0.018,
									0.341,
									0.921,
									0.003,
									0.009,
									0.025
								],
								"e": 0.111
							},
							{
								"h": [
									0.184,
									0.017,
									0.008,
									0.029,
									0.02,
									0.026,
									0.117,
									0.017,
									0.156,
									0.005,
									0.009,
									0.014
								],
								"e": 0.049
							},
							{
								"h": [
									0.006,
									0.007,
									0.041,
									0.01,
									0.011,
									0.057,
									0.05,
									0.017,
									0.093,
									0.204,
									0.068,
									0.027
								],
								"e": 0.045
							},
							{
								"h": [
									0.192,
									0.006,
									0.056,
									0.109,
									0.009,
									0.064,
									0.054,
									0.046,
									0.346,
									0.024,
									0.016,
									0.003
								],
								"e": 0.067
							},
							{
								"h": [
									0.218,
									0.032,
									0.017,
									0.038,
									0.015,
									0.012,
									0.06,
									0.091,
									0.004,
									0.035,
									0.051,
									0.032
								],
								"e": 0.046
							},
							{
								"h": [
									0.272,
									0.08,
									0.108,
									0.237,
									0.112,
									0.038,
									0.03,
									0.055,
									0.246,
									0.05,
									0.252,
									0.173
								],
								"e": 0.125
							}
						],
						[
							{
								"h": [
									0.059,
									1.0,
									0.239,
									1.0,
									1.0,
									1.0,
									0.013,
									1.0,
									1.0,
									0.591,
									0.84,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.65,
									0.749,
									1.0,
									0.271,
									0.147,
									0.386,
									0.29,
									0.155,
									0.377,
									0.098,
									0.707,
									0.501
								],
								"e": 0.408
							},
							{
								"h": [
									0.179,
									0.14,
									0.3,
									0.147,
									0.496,
									0.581,
									0.725,
									0.657,
									0.701,
									0.166,
									0.223,
									0.108
								],
								"e": 0.418
							},
							{
								"h": [
									0.004,
									0.144,
									0.23,
									0.241,
									0.036,
									0.099,
									0.193,
									0.453,
									0.866,
									0.225,
									0.153,
									0.313
								],
								"e": 0.213
							},
							{
								"h": [
									0.108,
									0.285,
									0.193,
									0.25,
									0.06,
									0.18,
									0.171,
									1.0,
									0.115,
									0.042,
									0.095,
									0.0
								],
								"e": 0.236
							},
							{
								"h": [
									0.01,
									0.001,
									0.053,
									0.151,
									0.294,
									0.047,
									0.017,
									0.029,
									0.024,
									0.055,
									0.168,
									0.351
								],
								"e": 0.084
							},
							{
								"h": [
									0.095,
									0.074,
									0.077,
									0.068,
									0.124,
									0.131,
									0.072,
									0.27,
									0.063,
									0.004,
									0.029,
									0.048
								],
								"e": 0.08
							},
							{
								"h": [
									0.012,
									0.025,
									0.01,
									0.023,
									0.075,
									0.093,
									0.091,
									0.024,
									0.027,
									0.029,
									0.018,
									0.026
								],
								"e": 0.036
							},
							{
								"h": [
									0.073,
									0.007,
									0.036,
									0.059,
									0.018,
									0.036,
									0.066,
									0.018,
									0.096,
									0.145,
									0.079,
									0.039
								],
								"e": 0.052
							},
							{
								"h": [
									0.126,
									0.035,
									0.127,
									0.043,
									0.046,
									0.017,
									0.011,
									0.056,
									0.014,
									0.005,
									0.018,
									0.02
								],
								"e": 0.041
							},
							{
								"h": [
									0.026,
									0.014,
									0.042,
									0.046,
									0.066,
									0.078,
									0.034,
									0.095,
									0.017,
									0.187,
									0.011,
									0.05
								],
								"e": 0.052
							},
							{
								"h": [
									0.956,
									0.079,
									0.13,
									0.264,
									0.118,
									0.241,
									0.018,
									0.159,
									1.0,
									0.025,
									0.27,
									0.21
								],
								"e": 0.238
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Vill",
					"o": "ain",
					"t": "ain",
					"r": [
						{
							"p": " Vill",
							"b": 0.0,
							"k": {
								" Vill": 0.991,
								"Vill": 0.005,
								"anova": 0.003,
								"agers": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " Vill",
							"b": 0.002,
							"k": {
								" Vill": 0.955,
								"agers": 0.018,
								"anova": 0.015,
								"Vill": 0.004,
								"ain": 0.002,
								"ard": 0.001,
								"age": 0.001,
								"ains": 0.001,
								"iage": 0.001,
								"!": 0.0
							}
						},
						{
							"p": " Vill",
							"b": 0.084,
							"k": {
								" Vill": 0.342,
								"anova": 0.244,
								"agers": 0.206,
								"ain": 0.084,
								"ains": 0.075,
								"iage": 0.013,
								"Vill": 0.01,
								"age": 0.008,
								"ard": 0.002,
								"ages": 0.002
							}
						},
						{
							"p": "agers",
							"b": 0.146,
							"k": {
								"agers": 0.397,
								"anova": 0.254,
								"ain": 0.146,
								"ains": 0.12,
								" Vill": 0.041,
								"iage": 0.01,
								"ages": 0.009,
								"age": 0.008,
								"Vill": 0.006,
								"aged": 0.003
							}
						},
						{
							"p": "ain",
							"b": 0.466,
							"k": {
								"ain": 0.466,
								"anova": 0.196,
								"ains": 0.18,
								"agers": 0.084,
								"age": 0.023,
								"ages": 0.018,
								"aged": 0.012,
								" Vill": 0.008,
								"iage": 0.007,
								"Vill": 0.001
							}
						},
						{
							"p": "ain",
							"b": 0.364,
							"k": {
								"ain": 0.364,
								"anova": 0.289,
								"ains": 0.156,
								"agers": 0.061,
								"age": 0.052,
								"ages": 0.051,
								"aged": 0.017,
								"iage": 0.004,
								"aine": 0.001,
								"!": 0.0
							}
						},
						{
							"p": "anova",
							"b": 0.339,
							"k": {
								"anova": 0.38,
								"ain": 0.339,
								"agers": 0.078,
								"ages": 0.076,
								"age": 0.066,
								"ains": 0.044,
								"aged": 0.011,
								"ager": 0.001,
								"aine": 0.001,
								"iage": 0.001
							}
						},
						{
							"p": "anova",
							"b": 0.115,
							"k": {
								"anova": 0.428,
								"ages": 0.158,
								"ains": 0.145,
								"ain": 0.115,
								"age": 0.1,
								"agers": 0.04,
								"aged": 0.007,
								"ard": 0.001,
								"aries": 0.001,
								"ager": 0.001
							}
						},
						{
							"p": "anova",
							"b": 0.154,
							"k": {
								"anova": 0.424,
								"ains": 0.305,
								"ain": 0.154,
								"ages": 0.066,
								"age": 0.033,
								"agers": 0.01,
								"aged": 0.002,
								"ager": 0.002,
								"ard": 0.001,
								"!": 0.0
							}
						},
						{
							"p": "anova",
							"b": 0.165,
							"k": {
								"anova": 0.56,
								"ains": 0.183,
								"ain": 0.165,
								"ages": 0.041,
								"age": 0.03,
								"agers": 0.008,
								"ager": 0.003,
								"agic": 0.002,
								"ard": 0.001,
								"aged": 0.001
							}
						},
						{
							"p": "ains",
							"b": 0.251,
							"k": {
								"ains": 0.323,
								"ain": 0.251,
								"anova": 0.205,
								"age": 0.043,
								"ager": 0.035,
								"agers": 0.032,
								"ages": 0.03,
								"ard": 0.007,
								"aged": 0.006,
								"at": 0.004
							}
						},
						{
							"p": "ain",
							"b": 0.618,
							"k": {
								"ain": 0.618,
								"ains": 0.34,
								"ager": 0.005,
								"ene": 0.003,
								"an": 0.002,
								"ages": 0.002,
								"agers": 0.002,
								"as": 0.001,
								"us": 0.001,
								"ant": 0.001
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.977,
									0.0,
									0.401,
									0.002,
									0.02,
									0.012,
									1.0,
									0.338,
									0.547,
									1.0,
									0.811,
									0.346
								],
								"e": 0.321
							},
							{
								"h": [
									0.467,
									0.179,
									0.949,
									1.0,
									1.0,
									0.906,
									1.0,
									1.0,
									1.0,
									1.0,
									0.006,
									0.032
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.022,
									0.603,
									0.654,
									0.114,
									0.953,
									0.948,
									0.373,
									0.397,
									1.0,
									1.0
								],
								"e": 0.94
							},
							{
								"h": [
									1.0,
									1.0,
									0.368,
									1.0,
									1.0,
									1.0,
									1.0,
									0.517,
									0.12,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.78,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.347,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.619,
									0.0,
									0.215,
									0.0,
									0.001,
									0.0,
									0.665,
									0.254,
									0.953,
									0.641,
									0.605,
									0.235
								],
								"e": 0.241
							},
							{
								"h": [
									0.187,
									0.172,
									0.183,
									0.11,
									0.116,
									0.489,
									0.063,
									0.245,
									0.257,
									0.202,
									0.598,
									0.012
								],
								"e": 0.25
							},
							{
								"h": [
									0.135,
									0.12,
									0.029,
									0.133,
									0.146,
									0.108,
									0.149,
									0.144,
									0.313,
									0.05,
									0.145,
									0.034
								],
								"e": 0.173
							},
							{
								"h": [
									0.018,
									0.263,
									0.185,
									0.075,
									0.026,
									0.12,
									0.126,
									0.036,
									0.04,
									0.07,
									0.031,
									0.036
								],
								"e": 0.087
							},
							{
								"h": [
									0.032,
									0.207,
									0.189,
									0.025,
									0.092,
									0.082,
									0.273,
									0.005,
									0.249,
									0.326,
									0.067,
									0.0
								],
								"e": 0.142
							},
							{
								"h": [
									0.058,
									0.0,
									0.284,
									0.423,
									0.027,
									0.021,
									0.035,
									0.007,
									0.187,
									0.048,
									0.265,
									0.052
								],
								"e": 0.096
							},
							{
								"h": [
									0.108,
									0.159,
									0.041,
									0.314,
									0.286,
									0.038,
									0.055,
									0.495,
									0.022,
									0.0,
									0.065,
									0.022
								],
								"e": 0.106
							},
							{
								"h": [
									0.018,
									0.044,
									0.0,
									0.043,
									0.005,
									0.174,
									0.017,
									0.396,
									0.005,
									0.013,
									0.001,
									0.002
								],
								"e": 0.048
							},
							{
								"h": [
									0.064,
									0.018,
									0.023,
									0.034,
									0.004,
									0.016,
									0.136,
									0.049,
									0.106,
									0.033,
									0.044,
									0.027
								],
								"e": 0.043
							},
							{
								"h": [
									0.048,
									0.009,
									0.014,
									0.086,
									0.047,
									0.022,
									0.017,
									0.052,
									0.035,
									0.005,
									0.003,
									0.034
								],
								"e": 0.029
							},
							{
								"h": [
									0.012,
									0.007,
									0.013,
									0.008,
									0.016,
									0.002,
									0.004,
									0.013,
									0.043,
									0.011,
									0.032,
									0.053
								],
								"e": 0.017
							},
							{
								"h": [
									0.04,
									0.004,
									0.007,
									0.014,
									0.075,
									0.01,
									0.005,
									0.004,
									1.0,
									0.005,
									0.01,
									0.015
								],
								"e": 0.054
							}
						],
						[
							{
								"h": [
									0.612,
									0.001,
									0.786,
									0.0,
									0.008,
									0.0,
									0.329,
									0.116,
									1.0,
									0.465,
									0.502,
									0.718
								],
								"e": 0.264
							},
							{
								"h": [
									0.465,
									0.137,
									0.28,
									0.15,
									0.137,
									0.257,
									0.112,
									0.451,
									0.164,
									0.148,
									0.79,
									0.001
								],
								"e": 0.286
							},
							{
								"h": [
									0.715,
									0.116,
									0.057,
									1.0,
									0.164,
									0.046,
									0.039,
									0.544,
									0.026,
									0.52,
									0.324,
									0.064
								],
								"e": 0.332
							},
							{
								"h": [
									0.0,
									0.05,
									0.082,
									0.026,
									0.024,
									0.027,
									0.515,
									0.174,
									0.017,
									0.06,
									0.068,
									0.048
								],
								"e": 0.075
							},
							{
								"h": [
									0.063,
									0.02,
									0.022,
									0.056,
									0.005,
									0.023,
									0.135,
									0.108,
									0.012,
									0.01,
									0.013,
									0.0
								],
								"e": 0.042
							},
							{
								"h": [
									0.016,
									0.0,
									0.028,
									0.059,
									0.018,
									0.005,
									0.023,
									0.001,
									0.021,
									0.062,
									0.073,
									0.015
								],
								"e": 0.024
							},
							{
								"h": [
									0.016,
									0.055,
									0.024,
									0.048,
									0.278,
									0.029,
									0.018,
									0.059,
									0.007,
									0.0,
									0.011,
									0.005
								],
								"e": 0.033
							},
							{
								"h": [
									0.007,
									0.032,
									0.0,
									0.011,
									0.001,
									0.025,
									0.039,
									0.023,
									0.003,
									0.002,
									0.0,
									0.001
								],
								"e": 0.011
							},
							{
								"h": [
									0.025,
									0.001,
									0.018,
									0.006,
									0.004,
									0.006,
									0.069,
									0.015,
									0.028,
									0.053,
									0.039,
									0.018
								],
								"e": 0.022
							},
							{
								"h": [
									0.019,
									0.0,
									0.031,
									0.027,
									0.006,
									0.031,
									0.004,
									0.022,
									0.113,
									0.004,
									0.001,
									0.002
								],
								"e": 0.019
							},
							{
								"h": [
									0.023,
									0.004,
									0.005,
									0.003,
									0.019,
									0.001,
									0.005,
									0.003,
									0.004,
									0.003,
									0.004,
									0.01
								],
								"e": 0.007
							},
							{
								"h": [
									0.051,
									0.008,
									0.003,
									0.004,
									0.01,
									0.001,
									0.002,
									0.001,
									0.433,
									0.001,
									0.002,
									0.002
								],
								"e": 0.024
							}
						],
						[
							{
								"h": [
									0.955,
									0.0,
									1.0,
									0.001,
									0.007,
									0.0,
									0.754,
									0.227,
									0.492,
									0.568,
									0.291,
									0.885
								],
								"e": 0.304
							},
							{
								"h": [
									0.088,
									0.074,
									0.483,
									0.178,
									0.16,
									1.0,
									0.049,
									0.575,
									0.154,
									0.132,
									0.994,
									0.001
								],
								"e": 0.328
							},
							{
								"h": [
									0.039,
									0.123,
									0.073,
									0.476,
									0.028,
									0.204,
									0.027,
									1.0,
									0.118,
									0.767,
									0.617,
									0.133
								],
								"e": 0.335
							},
							{
								"h": [
									0.002,
									0.074,
									0.072,
									0.006,
									0.022,
									0.034,
									0.195,
									0.396,
									0.074,
									0.013,
									0.09,
									0.06
								],
								"e": 0.08
							},
							{
								"h": [
									0.015,
									0.038,
									0.01,
									0.036,
									0.004,
									0.01,
									0.214,
									0.016,
									0.015,
									0.011,
									0.027,
									0.0
								],
								"e": 0.033
							},
							{
								"h": [
									0.046,
									0.0,
									0.03,
									0.035,
									0.028,
									0.0,
									0.027,
									0.002,
									0.019,
									0.018,
									0.063,
									0.033
								],
								"e": 0.022
							},
							{
								"h": [
									0.016,
									0.01,
									0.025,
									0.034,
									0.666,
									0.048,
									0.005,
									0.067,
									0.013,
									0.0,
									0.01,
									0.003
								],
								"e": 0.045
							},
							{
								"h": [
									0.012,
									0.015,
									0.0,
									0.019,
									0.0,
									0.035,
									0.07,
									0.033,
									0.005,
									0.001,
									0.0,
									0.002
								],
								"e": 0.014
							},
							{
								"h": [
									0.022,
									0.001,
									0.02,
									0.002,
									0.002,
									0.011,
									0.055,
									0.014,
									0.089,
									0.151,
									0.032,
									0.019
								],
								"e": 0.031
							},
							{
								"h": [
									0.043,
									0.001,
									0.032,
									0.052,
									0.005,
									0.049,
									0.002,
									0.029,
									0.203,
									0.002,
									0.001,
									0.002
								],
								"e": 0.031
							},
							{
								"h": [
									0.077,
									0.004,
									0.004,
									0.004,
									0.049,
									0.0,
									0.008,
									0.004,
									0.003,
									0.003,
									0.006,
									0.041
								],
								"e": 0.016
							},
							{
								"h": [
									0.021,
									0.007,
									0.008,
									0.016,
									0.035,
									0.005,
									0.007,
									0.003,
									0.164,
									0.006,
									0.006,
									0.008
								],
								"e": 0.017
							}
						],
						[
							{
								"h": [
									1.0,
									0.0,
									0.204,
									0.0,
									0.001,
									0.0,
									0.171,
									1.0,
									0.457,
									0.419,
									0.33,
									1.0
								],
								"e": 0.278
							},
							{
								"h": [
									1.0,
									1.0,
									0.944,
									0.173,
									0.15,
									0.387,
									0.095,
									0.325,
									0.359,
									0.174,
									0.721,
									0.017
								],
								"e": 0.518
							},
							{
								"h": [
									0.34,
									0.14,
									1.0,
									0.631,
									1.0,
									1.0,
									1.0,
									0.585,
									1.0,
									1.0,
									0.3,
									0.101
								],
								"e": 1.0
							},
							{
								"h": [
									0.005,
									0.69,
									1.0,
									0.607,
									0.021,
									0.112,
									0.627,
									1.0,
									1.0,
									0.156,
									0.134,
									0.183
								],
								"e": 0.461
							},
							{
								"h": [
									0.144,
									1.0,
									0.215,
									0.075,
									0.025,
									0.174,
									0.203,
									0.144,
									0.091,
									0.048,
									0.099,
									1.0
								],
								"e": 0.318
							},
							{
								"h": [
									0.032,
									0.0,
									0.097,
									0.521,
									0.436,
									0.001,
									0.244,
									0.002,
									0.021,
									0.023,
									0.231,
									0.129
								],
								"e": 0.114
							},
							{
								"h": [
									0.24,
									0.026,
									0.061,
									0.08,
									0.362,
									0.079,
									0.031,
									0.285,
									0.115,
									0.0,
									0.037,
									0.082
								],
								"e": 0.095
							},
							{
								"h": [
									0.647,
									0.018,
									0.0,
									0.019,
									0.006,
									0.346,
									0.035,
									0.04,
									0.013,
									0.016,
									0.0,
									0.001
								],
								"e": 0.068
							},
							{
								"h": [
									0.07,
									0.002,
									0.005,
									0.02,
									0.006,
									0.101,
									0.07,
									0.363,
									0.066,
									0.181,
									0.049,
									0.027
								],
								"e": 0.071
							},
							{
								"h": [
									0.017,
									0.007,
									0.027,
									0.386,
									0.004,
									0.006,
									0.003,
									0.052,
									0.009,
									0.002,
									0.011,
									0.005
								],
								"e": 0.034
							},
							{
								"h": [
									0.008,
									0.002,
									0.004,
									0.007,
									0.016,
									0.003,
									0.002,
									0.005,
									0.013,
									0.022,
									0.014,
									0.08
								],
								"e": 0.014
							},
							{
								"h": [
									0.026,
									0.002,
									0.003,
									0.01,
									0.044,
									0.005,
									0.003,
									0.002,
									0.586,
									0.002,
									0.006,
									0.016
								],
								"e": 0.033
							}
						],
						[
							{
								"h": [
									0.508,
									1.0,
									0.328,
									1.0,
									1.0,
									1.0,
									0.567,
									0.497,
									0.297,
									0.033,
									1.0,
									0.26
								],
								"e": 1.0
							},
							{
								"h": [
									0.373,
									0.259,
									1.0,
									0.204,
									0.23,
									0.03,
									0.022,
									0.054,
									0.133,
									0.083,
									1.0,
									1.0
								],
								"e": 0.514
							},
							{
								"h": [
									0.181,
									0.025,
									0.098,
									0.864,
									0.077,
									0.038,
									0.111,
									0.928,
									0.328,
									0.346,
									0.414,
									0.105
								],
								"e": 0.315
							},
							{
								"h": [
									0.021,
									0.137,
									0.36,
									0.049,
									0.001,
									0.026,
									0.838,
									0.141,
									0.019,
									0.132,
									0.172,
									0.2
								],
								"e": 0.15
							},
							{
								"h": [
									0.083,
									0.2,
									0.009,
									0.092,
									0.009,
									0.058,
									0.043,
									1.0,
									0.003,
									0.041,
									0.004,
									0.0
								],
								"e": 0.133
							},
							{
								"h": [
									0.047,
									0.0,
									0.008,
									0.141,
									0.122,
									0.003,
									0.018,
									0.024,
									0.022,
									0.001,
									0.025,
									0.007
								],
								"e": 0.028
							},
							{
								"h": [
									0.043,
									0.045,
									0.005,
									0.027,
									0.066,
									0.054,
									0.042,
									0.107,
									0.005,
									0.0,
									0.023,
									0.027
								],
								"e": 0.032
							},
							{
								"h": [
									0.066,
									0.019,
									0.002,
									0.016,
									0.003,
									0.015,
									0.021,
									0.014,
									0.002,
									0.006,
									0.001,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.008,
									0.001,
									0.003,
									0.008,
									0.006,
									0.019,
									0.077,
									0.022,
									0.015,
									0.029,
									0.021,
									0.008
								],
								"e": 0.016
							},
							{
								"h": [
									0.022,
									0.0,
									0.031,
									0.066,
									0.002,
									0.034,
									0.001,
									0.018,
									0.015,
									0.01,
									0.002,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.012,
									0.003,
									0.001,
									0.001,
									0.009,
									0.002,
									0.01,
									0.003,
									0.001,
									0.008,
									0.008,
									0.011
								],
								"e": 0.005
							},
							{
								"h": [
									0.038,
									0.001,
									0.001,
									0.001,
									0.007,
									0.001,
									0.003,
									0.0,
									0.325,
									0.001,
									0.001,
									0.0
								],
								"e": 0.017
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": "ain",
					"o": "ous",
					"t": ":",
					"r": [
						{
							"p": "ain",
							"b": 0.009,
							"k": {
								"ain": 0.279,
								"coat": 0.13,
								"ie": 0.085,
								"ter": 0.045,
								"te": 0.032,
								"/": 0.023,
								",": 0.022,
								"ous": 0.011,
								":": 0.009,
								" '": 0.008
							}
						},
						{
							"p": "ous",
							"b": 0.003,
							"k": {
								"ous": 0.437,
								" Mercenary": 0.247,
								"ain": 0.043,
								" Cipher": 0.024,
								"te": 0.021,
								"'s": 0.019,
								"ie": 0.016,
								"iac": 0.015,
								"coat": 0.014,
								"cia": 0.014
							}
						},
						{
							"p": "ous",
							"b": 0.001,
							"k": {
								"ous": 0.661,
								" Mercenary": 0.177,
								" Heroes": 0.036,
								"ess": 0.011,
								"'s": 0.008,
								"ain": 0.008,
								" Secrets": 0.005,
								"te": 0.004,
								",": 0.003,
								"ie": 0.003
							}
						},
						{
							"p": "ous",
							"b": 0.0,
							"k": {
								"ous": 0.974,
								" Mercenary": 0.007,
								" Heroes": 0.004,
								",": 0.001,
								"'s": 0.001,
								"ess": 0.001,
								" Jack": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0
							}
						},
						{
							"p": "ous",
							"b": 0.0,
							"k": {
								"ous": 0.963,
								" Mercenary": 0.014,
								"ess": 0.005,
								" Heroes": 0.002,
								"'s": 0.001,
								"ously": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": "ous",
							"b": 0.0,
							"k": {
								"ous": 0.974,
								"ess": 0.02,
								"ously": 0.002,
								"esses": 0.001,
								" Mercenary": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": "ous",
							"b": 0.0,
							"k": {
								"ous": 0.985,
								"ess": 0.012,
								"ously": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": "ous",
							"b": 0.0,
							"k": {
								"ous": 0.965,
								"ess": 0.03,
								"ously": 0.003,
								"esses": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": "ous",
							"b": 0.0,
							"k": {
								"ous": 0.995,
								"ess": 0.003,
								"ously": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": "ous",
							"b": 0.002,
							"k": {
								"ous": 0.993,
								":": 0.002,
								"ess": 0.002,
								"y": 0.001,
								"ously": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": "ous",
							"b": 0.081,
							"k": {
								"ous": 0.875,
								":": 0.081,
								"y": 0.016,
								"!": 0.003,
								".": 0.003,
								" of": 0.003,
								"ously": 0.003,
								"ess": 0.002,
								",": 0.001,
								"?": 0.001
							}
						},
						{
							"p": "ous",
							"b": 0.229,
							"k": {
								"ous": 0.285,
								":": 0.229,
								"y": 0.069,
								" of": 0.049,
								".": 0.046,
								",": 0.02,
								"'s": 0.012,
								"?": 0.011,
								" is": 0.011,
								"!": 0.01
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.359,
									0.001,
									0.902,
									0.0,
									0.013,
									0.014,
									0.459,
									0.015,
									0.646,
									1.0,
									0.791,
									1.0
								],
								"e": 0.413
							},
							{
								"h": [
									0.032,
									0.337,
									0.867,
									1.0,
									1.0,
									1.0,
									0.199,
									1.0,
									1.0,
									1.0,
									0.006,
									0.632
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.025,
									1.0,
									0.467,
									0.249,
									0.882,
									1.0,
									0.591,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.174,
									0.972,
									1.0,
									1.0,
									0.516,
									1.0,
									0.464,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.439,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.458,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.943,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.315,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.96,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.903,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.264,
									0.0,
									0.766,
									0.0,
									0.005,
									0.0,
									0.227,
									0.011,
									0.699,
									0.852,
									0.638,
									0.686
								],
								"e": 0.323
							},
							{
								"h": [
									0.013,
									0.228,
									0.169,
									0.11,
									0.136,
									0.346,
									0.044,
									0.139,
									0.353,
									0.1,
									0.579,
									0.334
								],
								"e": 0.259
							},
							{
								"h": [
									0.14,
									0.109,
									0.002,
									0.184,
									0.079,
									0.128,
									0.124,
									0.109,
									0.296,
									0.037,
									0.157,
									0.024
								],
								"e": 0.145
							},
							{
								"h": [
									0.008,
									0.172,
									0.051,
									0.037,
									0.016,
									0.05,
									0.1,
									0.046,
									0.092,
									0.048,
									0.02,
									0.048
								],
								"e": 0.057
							},
							{
								"h": [
									0.037,
									0.168,
									0.186,
									0.024,
									0.055,
									0.097,
									0.083,
									0.018,
									0.14,
									0.123,
									0.087,
									0.0
								],
								"e": 0.098
							},
							{
								"h": [
									0.435,
									0.023,
									0.074,
									0.51,
									0.021,
									0.261,
									0.004,
									0.006,
									1.0,
									0.02,
									0.122,
									0.149
								],
								"e": 0.194
							},
							{
								"h": [
									0.181,
									0.315,
									0.062,
									0.131,
									0.116,
									0.07,
									0.139,
									0.121,
									0.028,
									0.5,
									0.024,
									0.055
								],
								"e": 0.14
							},
							{
								"h": [
									0.016,
									0.03,
									0.026,
									0.061,
									0.012,
									0.032,
									0.012,
									0.144,
									0.01,
									0.056,
									0.354,
									0.047
								],
								"e": 0.065
							},
							{
								"h": [
									0.049,
									0.406,
									0.061,
									0.079,
									0.009,
									0.027,
									0.243,
									0.052,
									0.036,
									0.035,
									0.068,
									0.023
								],
								"e": 0.083
							},
							{
								"h": [
									0.062,
									0.034,
									0.024,
									0.073,
									0.066,
									0.021,
									0.022,
									0.023,
									0.02,
									0.021,
									0.033,
									0.284
								],
								"e": 0.052
							},
							{
								"h": [
									0.041,
									0.043,
									0.073,
									0.116,
									0.068,
									0.024,
									0.057,
									0.11,
									0.118,
									0.02,
									0.01,
									0.183
								],
								"e": 0.068
							},
							{
								"h": [
									0.338,
									0.043,
									0.081,
									0.138,
									0.119,
									0.026,
									0.027,
									0.049,
									0.819,
									0.036,
									0.096,
									0.095
								],
								"e": 0.105
							}
						],
						[
							{
								"h": [
									0.328,
									0.012,
									0.732,
									0.002,
									0.034,
									0.0,
									0.16,
									0.003,
									0.111,
									0.816,
									0.404,
									0.368
								],
								"e": 0.226
							},
							{
								"h": [
									0.04,
									0.129,
									0.277,
									0.141,
									0.155,
									0.156,
									0.056,
									0.115,
									0.192,
									0.085,
									0.745,
									0.07
								],
								"e": 0.206
							},
							{
								"h": [
									0.358,
									0.112,
									0.027,
									0.373,
									0.077,
									0.04,
									0.035,
									0.45,
									0.045,
									0.304,
									0.345,
									0.047
								],
								"e": 0.197
							},
							{
								"h": [
									0.001,
									0.044,
									0.04,
									0.013,
									0.013,
									0.025,
									0.485,
									0.234,
									0.021,
									0.028,
									0.032,
									0.068
								],
								"e": 0.062
							},
							{
								"h": [
									0.036,
									0.03,
									0.021,
									0.037,
									0.007,
									0.055,
									0.037,
									0.064,
									0.007,
									0.005,
									0.006,
									0.0
								],
								"e": 0.026
							},
							{
								"h": [
									0.029,
									0.0,
									0.028,
									0.055,
									0.017,
									0.032,
									0.001,
									0.002,
									0.036,
									0.027,
									0.14,
									0.043
								],
								"e": 0.031
							},
							{
								"h": [
									0.033,
									0.079,
									0.021,
									0.044,
									0.039,
									0.037,
									0.026,
									0.069,
									0.019,
									0.008,
									0.008,
									0.009
								],
								"e": 0.031
							},
							{
								"h": [
									0.01,
									0.006,
									0.001,
									0.019,
									0.001,
									0.007,
									0.023,
									0.017,
									0.008,
									0.012,
									0.007,
									0.018
								],
								"e": 0.011
							},
							{
								"h": [
									0.01,
									0.013,
									0.021,
									0.012,
									0.003,
									0.009,
									0.049,
									0.029,
									0.013,
									0.025,
									0.027,
									0.006
								],
								"e": 0.017
							},
							{
								"h": [
									0.018,
									0.003,
									0.01,
									0.029,
									0.015,
									0.022,
									0.017,
									0.024,
									0.045,
									0.007,
									0.007,
									0.005
								],
								"e": 0.016
							},
							{
								"h": [
									0.049,
									0.023,
									0.029,
									0.038,
									0.009,
									0.004,
									0.031,
									0.028,
									0.013,
									0.016,
									0.015,
									0.012
								],
								"e": 0.022
							},
							{
								"h": [
									0.322,
									0.108,
									0.073,
									0.024,
									0.047,
									0.004,
									0.021,
									0.01,
									0.387,
									0.007,
									0.053,
									0.019
								],
								"e": 0.06
							}
						],
						[
							{
								"h": [
									0.333,
									0.001,
									0.559,
									0.009,
									0.021,
									0.0,
									0.331,
									0.022,
									0.148,
									0.78,
									0.173,
									0.538
								],
								"e": 0.232
							},
							{
								"h": [
									0.022,
									0.073,
									0.468,
									0.158,
									0.195,
									0.501,
									0.018,
									0.136,
									0.123,
									0.047,
									0.95,
									0.115
								],
								"e": 0.248
							},
							{
								"h": [
									0.062,
									0.143,
									0.072,
									0.237,
									0.047,
									0.208,
									0.039,
									0.87,
									0.26,
									0.643,
									0.546,
									0.11
								],
								"e": 0.287
							},
							{
								"h": [
									0.001,
									0.044,
									0.142,
									0.015,
									0.045,
									0.028,
									0.31,
									0.519,
									0.086,
									0.011,
									0.058,
									0.097
								],
								"e": 0.087
							},
							{
								"h": [
									0.013,
									0.042,
									0.017,
									0.076,
									0.004,
									0.022,
									0.103,
									0.09,
									0.013,
									0.004,
									0.01,
									0.0
								],
								"e": 0.032
							},
							{
								"h": [
									0.029,
									0.0,
									0.017,
									0.033,
									0.021,
									0.0,
									0.0,
									0.002,
									0.01,
									0.029,
									0.033,
									0.074
								],
								"e": 0.019
							},
							{
								"h": [
									0.033,
									0.007,
									0.03,
									0.025,
									0.147,
									0.047,
									0.012,
									0.084,
									0.009,
									0.002,
									0.012,
									0.01
								],
								"e": 0.035
							},
							{
								"h": [
									0.017,
									0.003,
									0.001,
									0.012,
									0.001,
									0.021,
									0.056,
									0.011,
									0.003,
									0.009,
									0.004,
									0.022
								],
								"e": 0.014
							},
							{
								"h": [
									0.02,
									0.007,
									0.026,
									0.006,
									0.001,
									0.01,
									0.142,
									0.008,
									0.047,
									0.038,
									0.028,
									0.011
								],
								"e": 0.026
							},
							{
								"h": [
									0.037,
									0.004,
									0.011,
									0.061,
									0.012,
									0.038,
									0.016,
									0.037,
									0.121,
									0.005,
									0.005,
									0.005
								],
								"e": 0.028
							},
							{
								"h": [
									0.165,
									0.038,
									0.021,
									0.047,
									0.016,
									0.002,
									0.069,
									0.058,
									0.009,
									0.006,
									0.025,
									0.046
								],
								"e": 0.04
							},
							{
								"h": [
									0.29,
									0.099,
									0.135,
									0.086,
									0.113,
									0.021,
									0.05,
									0.05,
									0.342,
									0.027,
									0.174,
									0.038
								],
								"e": 0.095
							}
						],
						[
							{
								"h": [
									0.469,
									0.0,
									1.0,
									0.0,
									0.009,
									0.0,
									0.068,
									0.025,
									1.0,
									0.704,
									0.413,
									0.863
								],
								"e": 0.359
							},
							{
								"h": [
									0.07,
									1.0,
									1.0,
									0.175,
									0.186,
									0.346,
									0.08,
									0.202,
									0.375,
									0.067,
									0.713,
									0.362
								],
								"e": 0.452
							},
							{
								"h": [
									0.399,
									0.095,
									0.184,
									0.728,
									0.404,
									1.0,
									1.0,
									0.567,
									1.0,
									0.641,
									0.304,
									0.096
								],
								"e": 0.679
							},
							{
								"h": [
									0.005,
									0.455,
									0.429,
									0.277,
									0.019,
									0.057,
									0.54,
									0.865,
									1.0,
									0.152,
									0.076,
									0.403
								],
								"e": 0.305
							},
							{
								"h": [
									0.177,
									0.77,
									0.271,
									0.141,
									0.034,
									0.21,
									0.076,
									0.071,
									0.094,
									0.018,
									0.082,
									0.0
								],
								"e": 0.156
							},
							{
								"h": [
									0.025,
									0.0,
									0.065,
									0.846,
									0.236,
									0.002,
									0.007,
									0.004,
									0.09,
									0.047,
									0.233,
									0.141
								],
								"e": 0.108
							},
							{
								"h": [
									1.0,
									0.052,
									0.155,
									0.109,
									0.208,
									0.1,
									0.074,
									0.146,
									0.085,
									0.012,
									0.026,
									0.735
								],
								"e": 0.189
							},
							{
								"h": [
									1.0,
									0.015,
									0.003,
									0.034,
									0.011,
									0.156,
									0.036,
									0.078,
									0.005,
									0.199,
									0.022,
									0.007
								],
								"e": 0.108
							},
							{
								"h": [
									0.092,
									0.053,
									0.03,
									0.071,
									0.035,
									0.315,
									0.092,
									1.0,
									0.084,
									0.119,
									0.086,
									0.02
								],
								"e": 0.127
							},
							{
								"h": [
									0.029,
									0.015,
									0.024,
									1.0,
									0.023,
									0.014,
									0.005,
									0.03,
									0.008,
									0.003,
									0.116,
									0.077
								],
								"e": 0.072
							},
							{
								"h": [
									0.011,
									0.009,
									0.035,
									0.138,
									0.134,
									0.045,
									0.014,
									0.069,
									0.05,
									0.033,
									0.002,
									0.253
								],
								"e": 0.061
							},
							{
								"h": [
									0.258,
									0.022,
									0.06,
									0.213,
									0.056,
									0.008,
									0.017,
									0.028,
									1.0,
									0.009,
									0.118,
									0.09
								],
								"e": 0.096
							}
						],
						[
							{
								"h": [
									1.0,
									0.032,
									0.241,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.266,
									0.586,
									0.209,
									0.301
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.241,
									0.909,
									0.181,
									0.264,
									0.035,
									1.0,
									0.099,
									0.224,
									0.078,
									1.0,
									0.057
								],
								"e": 0.662
							},
							{
								"h": [
									0.387,
									0.031,
									1.0,
									0.861,
									1.0,
									0.068,
									0.067,
									0.861,
									0.296,
									0.644,
									0.467,
									0.085
								],
								"e": 0.619
							},
							{
								"h": [
									0.002,
									0.145,
									1.0,
									1.0,
									0.002,
									0.024,
									1.0,
									0.236,
									0.117,
									0.152,
									0.024,
									0.874
								],
								"e": 0.311
							},
							{
								"h": [
									0.059,
									0.237,
									0.01,
									0.985,
									0.009,
									0.132,
									0.088,
									0.12,
									0.008,
									0.079,
									0.009,
									1.0
								],
								"e": 0.256
							},
							{
								"h": [
									0.005,
									0.0,
									0.192,
									0.142,
									0.102,
									0.002,
									0.104,
									0.376,
									0.01,
									0.003,
									0.164,
									0.027
								],
								"e": 0.091
							},
							{
								"h": [
									0.138,
									0.389,
									0.008,
									0.049,
									0.033,
									0.053,
									0.019,
									0.177,
									0.921,
									0.001,
									0.025,
									0.096
								],
								"e": 0.128
							},
							{
								"h": [
									0.373,
									0.007,
									0.005,
									0.082,
									0.055,
									0.011,
									0.024,
									0.061,
									0.108,
									0.039,
									0.004,
									0.013
								],
								"e": 0.058
							},
							{
								"h": [
									0.007,
									0.012,
									0.004,
									0.017,
									0.022,
									0.275,
									0.067,
									0.233,
									0.013,
									0.046,
									0.027,
									0.014
								],
								"e": 0.048
							},
							{
								"h": [
									0.029,
									0.001,
									0.02,
									0.19,
									0.008,
									0.011,
									0.016,
									0.013,
									0.009,
									0.007,
									0.023,
									0.003
								],
								"e": 0.02
							},
							{
								"h": [
									0.032,
									0.011,
									0.02,
									0.018,
									0.01,
									0.01,
									0.084,
									0.051,
									0.001,
									0.057,
									0.027,
									0.011
								],
								"e": 0.028
							},
							{
								"h": [
									0.187,
									0.016,
									0.058,
									0.038,
									0.026,
									0.004,
									0.025,
									0.007,
									0.538,
									0.011,
									0.032,
									0.021
								],
								"e": 0.046
							}
						],
						[
							{
								"h": [
									0.116,
									1.0,
									0.118,
									0.144,
									0.918,
									1.0,
									0.165,
									0.009,
									0.174,
									0.243,
									1.0,
									0.324
								],
								"e": 0.901
							},
							{
								"h": [
									0.031,
									0.166,
									0.642,
									0.166,
									0.198,
									0.086,
									0.019,
									0.23,
									0.136,
									0.047,
									0.892,
									1.0
								],
								"e": 0.331
							},
							{
								"h": [
									0.059,
									0.013,
									0.065,
									0.173,
									0.052,
									0.115,
									0.077,
									0.869,
									0.223,
									0.223,
									0.598,
									0.133
								],
								"e": 0.222
							},
							{
								"h": [
									0.044,
									0.203,
									0.221,
									0.078,
									0.002,
									0.009,
									0.129,
									0.742,
									0.105,
									0.018,
									0.262,
									0.163
								],
								"e": 0.138
							},
							{
								"h": [
									0.018,
									0.049,
									0.017,
									0.491,
									0.016,
									0.154,
									0.022,
									1.0,
									0.02,
									0.021,
									0.014,
									0.0
								],
								"e": 0.134
							},
							{
								"h": [
									0.009,
									0.0,
									0.011,
									0.049,
									0.034,
									0.001,
									0.007,
									0.033,
									0.001,
									0.002,
									0.056,
									0.057
								],
								"e": 0.019
							},
							{
								"h": [
									0.052,
									0.155,
									0.012,
									0.039,
									0.021,
									0.043,
									0.064,
									0.101,
									0.086,
									0.0,
									0.033,
									0.042
								],
								"e": 0.05
							},
							{
								"h": [
									0.029,
									0.005,
									0.004,
									0.097,
									0.011,
									0.009,
									0.015,
									0.025,
									0.06,
									0.02,
									0.002,
									0.011
								],
								"e": 0.024
							},
							{
								"h": [
									0.031,
									0.018,
									0.009,
									0.011,
									0.02,
									0.052,
									0.203,
									0.052,
									0.026,
									0.085,
									0.029,
									0.012
								],
								"e": 0.04
							},
							{
								"h": [
									0.028,
									0.002,
									0.042,
									0.058,
									0.007,
									0.015,
									0.005,
									0.012,
									0.009,
									0.012,
									0.018,
									0.005
								],
								"e": 0.016
							},
							{
								"h": [
									0.03,
									0.025,
									0.008,
									0.012,
									0.034,
									0.018,
									0.024,
									0.055,
									0.008,
									0.04,
									0.013,
									0.037
								],
								"e": 0.025
							},
							{
								"h": [
									0.284,
									0.032,
									0.019,
									0.065,
									0.036,
									0.007,
									0.009,
									0.015,
									0.828,
									0.013,
									0.044,
									0.037
								],
								"e": 0.063
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ":",
					"o": " Harry",
					"t": " Voldemort",
					"r": [
						{
							"p": " '",
							"b": 0.0,
							"k": {
								" '": 0.065,
								" \"": 0.043,
								" The": 0.03,
								" ": 0.02,
								" [": 0.026,
								" http": 0.02,
								" A": 0.019,
								" -": 0.019,
								"19": 0.016
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.058,
								" Three": 0.031,
								" \"": 0.03,
								" '": 0.03,
								" -": 0.027,
								" [": 0.023,
								" http": 0.023,
								" A": 0.019,
								" 1": 0.017,
								" Rise": 0.017
							}
						},
						{
							"p": " Rise",
							"b": 0.0,
							"k": {
								" Rise": 0.088,
								" The": 0.064,
								" Yeah": 0.031,
								" http": 0.03,
								" \"": 0.028,
								" 1": 0.019,
								" An": 0.017,
								" '": 0.014,
								" Who": 0.014,
								" Unknown": 0.014
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.086,
								" http": 0.052,
								" Yeah": 0.037,
								" An": 0.032,
								" Death": 0.031,
								" Who": 0.03,
								" Yes": 0.017,
								" Rise": 0.017,
								" https": 0.015,
								" ": 0.013
							}
						},
						{
							"p": " No",
							"b": 0.0,
							"k": {
								" No": 0.061,
								" Death": 0.048,
								" TBD": 0.041,
								" Yeah": 0.04,
								" Who": 0.032,
								" An": 0.031,
								" Yes": 0.03,
								" 1": 0.023,
								" The": 0.022,
								" N": 0.018
							}
						},
						{
							"p": " No",
							"b": 0.0,
							"k": {
								" No": 0.074,
								" Unknown": 0.054,
								" Yes": 0.043,
								" TBD": 0.043,
								" Sl": 0.037,
								" None": 0.035,
								" An": 0.031,
								" Death": 0.023,
								" TBA": 0.023,
								" Spy": 0.021
							}
						},
						{
							"p": " None",
							"b": 0.0,
							"k": {
								" None": 0.15,
								" Unknown": 0.055,
								" An": 0.044,
								" Death": 0.041,
								" No": 0.028,
								" Don": 0.028,
								" The": 0.024,
								" Me": 0.02,
								" none": 0.02,
								" Man": 0.017
							}
						},
						{
							"p": " Unknown",
							"b": 0.0,
							"k": {
								" Unknown": 0.234,
								" None": 0.095,
								" Me": 0.042,
								" Don": 0.031,
								" Evil": 0.019,
								" Harry": 0.017,
								" Man": 0.016,
								" Death": 0.016,
								" No": 0.013,
								" Dr": 0.013
							}
						},
						{
							"p": " Harry",
							"b": 0.001,
							"k": {
								" Harry": 0.324,
								" Robin": 0.051,
								" Tom": 0.022,
								" James": 0.021,
								" Doctor": 0.021,
								" Unknown": 0.02,
								" Me": 0.019,
								" Batman": 0.019,
								" Man": 0.015,
								" Ben": 0.015
							}
						},
						{
							"p": " Harry",
							"b": 0.008,
							"k": {
								" Harry": 0.987,
								" Voldemort": 0.008,
								" Hermione": 0.003,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " Harry",
							"b": 0.296,
							"k": {
								" Harry": 0.542,
								" Voldemort": 0.296,
								" Hermione": 0.061,
								" Draco": 0.044,
								" Dumbledore": 0.011,
								" Snape": 0.006,
								" Malfoy": 0.004,
								" Neville": 0.003,
								" Severus": 0.003,
								" Lucius": 0.003
							}
						},
						{
							"p": " Harry",
							"b": 0.07,
							"k": {
								" Harry": 0.146,
								" Voldemort": 0.07,
								" The": 0.041,
								" Hermione": 0.031,
								" Draco": 0.027,
								" Ron": 0.012,
								" Dumbledore": 0.01,
								" Lord": 0.009,
								" A": 0.008,
								" Death": 0.008
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.002,
									0.581,
									0.014,
									0.658,
									0.06,
									1.0,
									0.333,
									0.058,
									1.0,
									0.62,
									0.49
								],
								"e": 0.592
							},
							{
								"h": [
									0.345,
									0.374,
									0.759,
									1.0,
									1.0,
									0.985,
									1.0,
									1.0,
									1.0,
									1.0,
									0.009,
									0.607
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.1,
									0.382,
									1.0,
									0.302,
									1.0,
									0.456,
									1.0,
									0.143,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.328,
									0.092,
									1.0,
									1.0,
									1.0,
									0.104,
									0.022,
									0.339,
									1.0,
									1.0,
									0.478
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.885,
									1.0,
									0.115,
									1.0,
									1.0,
									1.0,
									0.516,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.765,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.479,
									0.667,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.22,
									0.673,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.937,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.249,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.392,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.573,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									0.887,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.061,
									1.0,
									0.376,
									0.022,
									0.261,
									1.0,
									0.033,
									0.136,
									0.793,
									0.808,
									1.0,
									0.127
								],
								"e": 0.731
							},
							{
								"h": [
									0.068,
									0.121,
									0.144,
									0.089,
									0.126,
									1.0,
									0.221,
									0.123,
									0.373,
									0.18,
									0.499,
									0.695
								],
								"e": 0.325
							},
							{
								"h": [
									0.085,
									0.066,
									0.001,
									0.012,
									0.056,
									0.019,
									0.071,
									0.037,
									0.054,
									0.002,
									0.135,
									0.019
								],
								"e": 0.06
							},
							{
								"h": [
									0.209,
									0.017,
									0.006,
									0.008,
									0.13,
									0.209,
									0.007,
									0.0,
									0.041,
									0.06,
									0.057,
									0.028
								],
								"e": 0.113
							},
							{
								"h": [
									0.006,
									0.015,
									0.191,
									0.004,
									0.202,
									0.015,
									0.081,
									0.137,
									0.169,
									0.028,
									0.086,
									0.0
								],
								"e": 0.096
							},
							{
								"h": [
									0.003,
									0.0,
									0.062,
									0.159,
									0.005,
									0.004,
									0.005,
									0.007,
									0.005,
									0.041,
									0.122,
									0.376
								],
								"e": 0.056
							},
							{
								"h": [
									0.036,
									0.028,
									0.073,
									0.051,
									0.051,
									0.025,
									0.119,
									0.017,
									0.002,
									0.0,
									0.003,
									0.006
								],
								"e": 0.035
							},
							{
								"h": [
									0.003,
									0.011,
									0.0,
									0.014,
									0.005,
									0.04,
									0.015,
									0.008,
									0.009,
									0.01,
									0.004,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.062,
									0.016,
									0.036,
									0.046,
									0.008,
									0.013,
									0.004,
									0.024,
									0.012,
									0.014,
									0.046,
									0.015
								],
								"e": 0.025
							},
							{
								"h": [
									0.015,
									0.019,
									0.014,
									0.065,
									0.023,
									0.029,
									0.006,
									0.016,
									0.018,
									0.007,
									0.004,
									0.005
								],
								"e": 0.017
							},
							{
								"h": [
									0.015,
									0.006,
									0.02,
									0.029,
									0.052,
									0.015,
									0.008,
									0.029,
									0.028,
									0.037,
									0.012,
									0.013
								],
								"e": 0.022
							},
							{
								"h": [
									0.524,
									0.201,
									0.086,
									0.069,
									0.193,
									0.084,
									0.019,
									0.058,
									0.781,
									0.016,
									0.179,
									0.093
								],
								"e": 0.142
							}
						],
						[
							{
								"h": [
									0.252,
									0.001,
									0.147,
									0.006,
									0.224,
									0.009,
									0.309,
									0.292,
									0.025,
									0.584,
									0.219,
									0.777
								],
								"e": 0.268
							},
							{
								"h": [
									0.581,
									0.195,
									0.252,
									0.128,
									0.186,
									0.028,
									0.087,
									0.177,
									0.177,
									0.145,
									0.726,
									0.143
								],
								"e": 0.237
							},
							{
								"h": [
									0.296,
									0.092,
									0.017,
									0.117,
									0.079,
									0.028,
									0.028,
									0.21,
									0.021,
									0.112,
									0.323,
									0.04
								],
								"e": 0.147
							},
							{
								"h": [
									0.005,
									0.015,
									0.055,
									0.009,
									0.018,
									0.044,
									0.13,
									0.002,
									0.01,
									0.088,
									0.049,
									0.034
								],
								"e": 0.06
							},
							{
								"h": [
									0.017,
									0.01,
									0.006,
									0.023,
									0.07,
									0.041,
									0.071,
									0.071,
									0.034,
									0.008,
									0.024,
									0.0
								],
								"e": 0.036
							},
							{
								"h": [
									0.941,
									0.37,
									0.062,
									0.052,
									0.012,
									0.267,
									0.002,
									0.001,
									0.904,
									0.05,
									0.076,
									0.105
								],
								"e": 0.228
							},
							{
								"h": [
									0.025,
									0.047,
									0.018,
									0.068,
									0.071,
									0.055,
									0.046,
									0.032,
									0.01,
									0.162,
									0.027,
									0.002
								],
								"e": 0.051
							},
							{
								"h": [
									0.009,
									0.037,
									0.028,
									0.099,
									0.004,
									0.013,
									0.129,
									0.059,
									0.052,
									0.086,
									0.12,
									0.396
								],
								"e": 0.088
							},
							{
								"h": [
									0.023,
									0.115,
									0.191,
									0.09,
									0.046,
									0.026,
									0.227,
									0.019,
									0.061,
									0.08,
									0.376,
									0.09
								],
								"e": 0.118
							},
							{
								"h": [
									1.0,
									0.028,
									0.202,
									0.029,
									0.044,
									0.083,
									0.273,
									0.124,
									0.346,
									0.126,
									0.003,
									0.012
								],
								"e": 0.139
							},
							{
								"h": [
									0.168,
									0.047,
									0.055,
									0.168,
									0.072,
									0.007,
									0.134,
									1.0,
									0.016,
									0.021,
									0.043,
									0.097
								],
								"e": 0.131
							},
							{
								"h": [
									1.0,
									0.43,
									0.094,
									0.068,
									0.258,
									0.038,
									0.033,
									0.034,
									0.256,
									0.034,
									1.0,
									0.079
								],
								"e": 0.192
							}
						],
						[
							{
								"h": [
									0.888,
									0.0,
									0.251,
									0.012,
									0.346,
									0.007,
									0.379,
									0.377,
									0.022,
									0.409,
									0.137,
									1.0
								],
								"e": 0.376
							},
							{
								"h": [
									0.673,
									0.139,
									0.414,
									0.137,
									0.197,
									0.066,
									0.065,
									0.249,
									0.146,
									0.076,
									0.881,
									0.109
								],
								"e": 0.251
							},
							{
								"h": [
									0.112,
									0.096,
									0.19,
									0.084,
									0.288,
									0.074,
									0.018,
									0.349,
									0.138,
									0.211,
									0.452,
									0.073
								],
								"e": 0.221
							},
							{
								"h": [
									0.002,
									0.029,
									0.049,
									0.053,
									0.028,
									0.091,
									0.122,
									0.012,
									0.064,
									0.038,
									0.072,
									0.165
								],
								"e": 0.091
							},
							{
								"h": [
									0.028,
									0.014,
									0.008,
									0.037,
									0.035,
									0.03,
									0.082,
									0.09,
									0.061,
									0.005,
									0.007,
									0.0
								],
								"e": 0.035
							},
							{
								"h": [
									0.028,
									0.0,
									0.042,
									0.046,
									0.017,
									0.002,
									0.001,
									0.001,
									0.009,
									0.014,
									0.03,
									0.082
								],
								"e": 0.02
							},
							{
								"h": [
									0.056,
									0.029,
									0.05,
									0.02,
									0.131,
									0.069,
									0.005,
									0.11,
									0.009,
									0.0,
									0.039,
									0.006
								],
								"e": 0.041
							},
							{
								"h": [
									0.007,
									0.025,
									0.001,
									0.025,
									0.003,
									0.014,
									0.13,
									0.184,
									0.03,
									0.01,
									0.002,
									0.019
								],
								"e": 0.034
							},
							{
								"h": [
									0.028,
									0.027,
									0.192,
									0.078,
									0.032,
									0.032,
									1.0,
									0.014,
									0.171,
									0.11,
									0.193,
									0.199
								],
								"e": 0.193
							},
							{
								"h": [
									0.954,
									0.018,
									0.373,
									0.041,
									0.056,
									0.097,
									0.079,
									0.15,
									1.0,
									0.022,
									0.003,
									0.005
								],
								"e": 0.172
							},
							{
								"h": [
									0.447,
									0.022,
									0.027,
									0.08,
									0.124,
									0.005,
									0.081,
									0.219,
									0.009,
									0.021,
									0.036,
									0.169
								],
								"e": 0.09
							},
							{
								"h": [
									0.291,
									0.297,
									0.097,
									0.113,
									0.353,
									0.062,
									0.031,
									0.083,
									0.135,
									0.048,
									0.475,
									0.091
								],
								"e": 0.143
							}
						],
						[
							{
								"h": [
									0.101,
									0.006,
									1.0,
									0.034,
									0.178,
									0.01,
									0.023,
									0.623,
									0.409,
									0.72,
									0.354,
									0.645
								],
								"e": 0.401
							},
							{
								"h": [
									0.397,
									1.0,
									0.851,
									0.146,
									0.182,
									0.404,
									0.504,
									0.206,
									0.361,
									0.118,
									0.664,
									0.512
								],
								"e": 0.47
							},
							{
								"h": [
									0.386,
									0.231,
									0.06,
									0.083,
									0.841,
									1.0,
									0.99,
									0.247,
									0.557,
									0.069,
									0.286,
									0.059
								],
								"e": 0.521
							},
							{
								"h": [
									0.01,
									1.0,
									0.225,
									0.259,
									0.034,
									0.248,
									0.158,
									0.015,
									1.0,
									0.464,
									0.22,
									0.293
								],
								"e": 0.504
							},
							{
								"h": [
									0.069,
									1.0,
									0.29,
									0.102,
									0.087,
									0.335,
									0.14,
									0.372,
									0.437,
									0.099,
									0.062,
									0.0
								],
								"e": 0.265
							},
							{
								"h": [
									0.054,
									0.0,
									0.205,
									0.464,
									0.46,
									0.005,
									0.001,
									0.123,
									0.035,
									0.058,
									0.137,
									0.153
								],
								"e": 0.116
							},
							{
								"h": [
									0.13,
									0.081,
									0.144,
									0.112,
									0.161,
									0.072,
									0.03,
									0.196,
									0.005,
									0.002,
									0.284,
									0.049
								],
								"e": 0.104
							},
							{
								"h": [
									0.063,
									0.047,
									0.0,
									0.045,
									0.006,
									0.094,
									0.092,
									1.0,
									0.036,
									0.05,
									0.011,
									0.008
								],
								"e": 0.105
							},
							{
								"h": [
									0.152,
									0.017,
									0.154,
									0.733,
									0.047,
									0.107,
									0.044,
									0.091,
									0.255,
									0.111,
									0.281,
									0.075
								],
								"e": 0.169
							},
							{
								"h": [
									0.019,
									0.016,
									0.035,
									0.131,
									0.075,
									0.014,
									0.002,
									0.026,
									0.015,
									0.009,
									0.025,
									0.013
								],
								"e": 0.03
							},
							{
								"h": [
									0.025,
									0.004,
									0.014,
									0.054,
									0.112,
									0.028,
									0.007,
									0.054,
									0.024,
									0.089,
									0.008,
									0.006
								],
								"e": 0.035
							},
							{
								"h": [
									0.473,
									0.172,
									0.148,
									0.081,
									0.254,
									0.112,
									0.017,
									0.078,
									0.598,
									0.01,
									0.193,
									0.069
								],
								"e": 0.143
							}
						],
						[
							{
								"h": [
									0.396,
									0.001,
									0.074,
									0.054,
									0.358,
									0.007,
									0.445,
									0.868,
									0.014,
									0.429,
									0.124,
									0.183
								],
								"e": 0.294
							},
							{
								"h": [
									0.941,
									0.277,
									0.862,
									0.167,
									0.316,
									0.023,
									0.055,
									0.076,
									0.231,
									0.124,
									1.0,
									0.054
								],
								"e": 0.316
							},
							{
								"h": [
									0.453,
									0.049,
									1.0,
									1.0,
									0.349,
									0.17,
									0.079,
									0.41,
									0.223,
									0.917,
									0.404,
									0.075
								],
								"e": 0.594
							},
							{
								"h": [
									0.0,
									0.101,
									1.0,
									0.309,
									0.009,
									0.079,
									1.0,
									0.175,
									0.158,
									0.191,
									0.061,
									0.443
								],
								"e": 0.462
							},
							{
								"h": [
									0.276,
									0.155,
									0.004,
									0.844,
									0.015,
									0.25,
									0.266,
									0.27,
									0.048,
									0.149,
									0.005,
									0.0
								],
								"e": 0.206
							},
							{
								"h": [
									0.007,
									0.001,
									0.185,
									0.321,
									0.572,
									0.013,
									0.059,
									0.613,
									0.043,
									0.022,
									0.282,
									0.03
								],
								"e": 0.153
							},
							{
								"h": [
									0.091,
									0.051,
									0.005,
									0.078,
									0.02,
									0.09,
									0.041,
									0.346,
									0.213,
									0.001,
									0.014,
									0.056
								],
								"e": 0.072
							},
							{
								"h": [
									0.135,
									0.009,
									0.002,
									0.045,
									0.02,
									0.114,
									0.028,
									0.006,
									0.228,
									0.035,
									0.008,
									0.008
								],
								"e": 0.046
							},
							{
								"h": [
									0.015,
									0.006,
									0.044,
									0.04,
									0.11,
									0.103,
									0.006,
									0.103,
									0.131,
									0.067,
									0.117,
									0.046
								],
								"e": 0.068
							},
							{
								"h": [
									0.712,
									0.005,
									0.205,
									0.164,
									0.005,
									0.031,
									0.069,
									0.013,
									0.028,
									0.034,
									0.013,
									0.009
								],
								"e": 0.064
							},
							{
								"h": [
									0.06,
									0.015,
									0.007,
									0.02,
									0.042,
									0.01,
									0.097,
									0.013,
									0.002,
									0.022,
									0.019,
									0.053
								],
								"e": 0.029
							},
							{
								"h": [
									0.109,
									0.14,
									0.076,
									0.033,
									0.064,
									0.022,
									0.028,
									0.051,
									0.196,
									0.036,
									0.062,
									0.05
								],
								"e": 0.063
							}
						],
						[
							{
								"h": [
									0.344,
									0.0,
									0.21,
									0.024,
									0.152,
									0.006,
									0.126,
									0.151,
									0.028,
									0.451,
									0.086,
									0.195
								],
								"e": 0.17
							},
							{
								"h": [
									1.0,
									0.193,
									0.538,
									0.139,
									0.209,
									0.096,
									0.183,
									0.188,
									0.156,
									0.075,
									0.837,
									0.218
								],
								"e": 0.303
							},
							{
								"h": [
									0.159,
									0.034,
									0.664,
									0.126,
									0.562,
									0.208,
									0.031,
									0.378,
									0.534,
									1.0,
									0.385,
									0.092
								],
								"e": 0.447
							},
							{
								"h": [
									0.0,
									0.04,
									0.202,
									0.563,
									0.011,
									0.087,
									0.285,
									1.0,
									0.282,
									0.104,
									0.076,
									1.0
								],
								"e": 0.497
							},
							{
								"h": [
									0.079,
									0.123,
									0.007,
									1.0,
									0.036,
									0.157,
									0.168,
									0.353,
									0.048,
									0.072,
									0.008,
									1.0
								],
								"e": 0.361
							},
							{
								"h": [
									0.001,
									0.0,
									0.12,
									0.122,
									0.128,
									0.0,
									0.305,
									0.124,
									0.001,
									0.005,
									0.1,
									0.008
								],
								"e": 0.074
							},
							{
								"h": [
									0.251,
									0.067,
									0.01,
									0.059,
									0.029,
									0.088,
									0.03,
									0.415,
									1.0,
									0.0,
									0.013,
									0.094
								],
								"e": 0.14
							},
							{
								"h": [
									0.272,
									0.006,
									0.0,
									0.089,
									0.01,
									0.141,
									0.033,
									0.001,
									1.0,
									0.027,
									0.0,
									0.001
								],
								"e": 0.1
							},
							{
								"h": [
									0.016,
									0.009,
									0.034,
									0.018,
									0.164,
									0.163,
									0.023,
									1.0,
									0.032,
									0.118,
									0.068,
									0.015
								],
								"e": 0.126
							},
							{
								"h": [
									0.214,
									0.002,
									0.31,
									0.315,
									0.004,
									0.037,
									0.013,
									0.01,
									0.038,
									0.008,
									0.063,
									0.006
								],
								"e": 0.061
							},
							{
								"h": [
									0.065,
									0.015,
									0.007,
									0.015,
									0.096,
									0.024,
									0.053,
									0.014,
									0.002,
									0.037,
									0.009,
									0.109
								],
								"e": 0.035
							},
							{
								"h": [
									0.287,
									0.149,
									0.019,
									0.059,
									0.04,
									0.023,
									0.005,
									0.046,
									0.205,
									0.025,
									0.045,
									0.054
								],
								"e": 0.062
							}
						],
						[
							{
								"h": [
									0.039,
									0.836,
									0.289,
									1.0,
									1.0,
									0.588,
									0.02,
									1.0,
									1.0,
									0.664,
									0.576,
									0.106
								],
								"e": 1.0
							},
							{
								"h": [
									0.371,
									0.559,
									1.0,
									0.174,
									0.232,
									0.905,
									0.184,
									0.148,
									0.339,
									0.13,
									0.844,
									1.0
								],
								"e": 0.486
							},
							{
								"h": [
									0.414,
									0.119,
									0.244,
									0.266,
									0.346,
									0.448,
									0.372,
									1.0,
									0.933,
									0.071,
									0.428,
									0.101
								],
								"e": 0.495
							},
							{
								"h": [
									0.317,
									0.065,
									0.143,
									0.329,
									0.244,
									0.096,
									0.123,
									0.019,
									0.485,
									0.237,
									0.277,
									0.21
								],
								"e": 0.331
							},
							{
								"h": [
									0.153,
									0.224,
									0.173,
									0.078,
									0.207,
									0.186,
									0.278,
									1.0,
									0.207,
									0.05,
									0.083,
									0.0
								],
								"e": 0.229
							},
							{
								"h": [
									0.038,
									0.001,
									0.157,
									0.468,
									0.028,
									0.018,
									0.069,
									0.072,
									0.01,
									0.039,
									0.109,
									0.349
								],
								"e": 0.095
							},
							{
								"h": [
									0.076,
									0.013,
									0.061,
									0.079,
									0.071,
									0.083,
									0.209,
									0.031,
									0.011,
									0.0,
									0.021,
									0.029
								],
								"e": 0.057
							},
							{
								"h": [
									0.072,
									0.01,
									0.001,
									0.057,
									0.004,
									0.123,
									0.025,
									0.012,
									0.167,
									0.031,
									0.001,
									0.006
								],
								"e": 0.037
							},
							{
								"h": [
									0.306,
									0.02,
									0.023,
									0.018,
									0.034,
									0.086,
									0.005,
									0.426,
									0.005,
									0.049,
									0.119,
									0.013
								],
								"e": 0.088
							},
							{
								"h": [
									0.011,
									0.021,
									0.007,
									0.525,
									0.004,
									0.052,
									0.002,
									0.024,
									0.003,
									0.003,
									0.01,
									0.004
								],
								"e": 0.042
							},
							{
								"h": [
									0.011,
									0.006,
									0.009,
									0.023,
									0.028,
									0.025,
									0.004,
									0.063,
									0.016,
									0.096,
									0.008,
									0.023
								],
								"e": 0.026
							},
							{
								"h": [
									0.328,
									0.454,
									0.054,
									0.027,
									0.183,
									0.075,
									0.012,
									0.097,
									1.0,
									0.006,
									0.162,
									0.07
								],
								"e": 0.147
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Voldemort",
					"o": ".",
					"t": ".",
					"r": [
						{
							"p": " Voldemort",
							"b": 0.0,
							"k": {
								" Voldemort": 0.996,
								" Malfoy": 0.002,
								" Potter": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " Voldemort",
							"b": 0.0,
							"k": {
								" Voldemort": 0.968,
								" Potter": 0.015,
								" Malfoy": 0.008,
								"'s": 0.002,
								"oldemort": 0.001,
								" Dumbledore": 0.001,
								" Snape": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0
							}
						},
						{
							"p": " Voldemort",
							"b": 0.0,
							"k": {
								" Voldemort": 0.962,
								" Potter": 0.013,
								" Malfoy": 0.011,
								" Dumbledore": 0.004,
								" Snape": 0.003,
								"'s": 0.002,
								"oldemort": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0
							}
						},
						{
							"p": " Voldemort",
							"b": 0.0,
							"k": {
								" Voldemort": 0.935,
								" Potter": 0.024,
								" Malfoy": 0.02,
								" Dumbledore": 0.008,
								"'s": 0.004,
								" Snape": 0.004,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": " Voldemort",
							"b": 0.0,
							"k": {
								" Voldemort": 0.657,
								" Potter": 0.132,
								"'s": 0.109,
								" Malfoy": 0.023,
								" Dumbledore": 0.018,
								" Snape": 0.011,
								",": 0.01,
								" Holmes": 0.003,
								" Bane": 0.003,
								"/": 0.001
							}
						},
						{
							"p": "'s",
							"b": 0.012,
							"k": {
								"'s": 0.364,
								" Voldemort": 0.272,
								" Potter": 0.107,
								",": 0.101,
								" Dumbledore": 0.018,
								" TAMADRA": 0.017,
								".": 0.012,
								" Holmes": 0.012,
								" Snape": 0.012,
								" is": 0.008
							}
						},
						{
							"p": "'s",
							"b": 0.032,
							"k": {
								"'s": 0.441,
								",": 0.239,
								" Voldemort": 0.139,
								" Potter": 0.041,
								".": 0.032,
								" Dumbledore": 0.009,
								" Holmes": 0.007,
								"/": 0.006,
								" is": 0.006,
								" TAMADRA": 0.005
							}
						},
						{
							"p": ".",
							"b": 0.263,
							"k": {
								".": 0.263,
								" Voldemort": 0.209,
								"'s": 0.199,
								",": 0.188,
								" Potter": 0.03,
								" himself": 0.011,
								"/": 0.01,
								";": 0.008,
								" is": 0.006,
								" TAMADRA": 0.006
							}
						},
						{
							"p": ".",
							"b": 0.957,
							"k": {
								".": 0.957,
								",": 0.011,
								"'s": 0.009,
								" Voldemort": 0.006,
								";": 0.002,
								" Potter": 0.002,
								"!": 0.001,
								"?": 0.001,
								" ": 0.001,
								".\"": 0.001
							}
						},
						{
							"p": ".",
							"b": 0.994,
							"k": {
								".": 0.994,
								",": 0.001,
								"'s": 0.001,
								".\"": 0.001,
								" himself": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.989,
							"k": {
								".": 0.989,
								",": 0.002,
								"!": 0.001,
								" ": 0.001,
								"'s": 0.001,
								".\"": 0.001,
								" himself": 0.001,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.817,
							"k": {
								".": 0.817,
								",": 0.032,
								" ": 0.015,
								" (": 0.012,
								"'s": 0.01,
								"!": 0.007,
								" and": 0.006,
								" is": 0.005,
								".\"": 0.004,
								"...": 0.004
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.0,
									1.0,
									0.003,
									0.025,
									0.001,
									1.0,
									0.488,
									1.0,
									1.0,
									0.762,
									0.193
								],
								"e": 0.326
							},
							{
								"h": [
									0.573,
									0.228,
									0.856,
									1.0,
									1.0,
									0.399,
									1.0,
									1.0,
									1.0,
									1.0,
									0.007,
									0.079
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.295,
									0.428,
									1.0,
									0.152,
									1.0,
									0.534,
									0.41,
									0.615,
									0.691,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.38,
									1.0,
									1.0,
									1.0,
									0.536,
									0.296,
									0.318,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.517,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.263,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.511,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.468,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.494
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.63,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.589,
									0.001,
									0.235,
									0.0,
									0.002,
									0.0,
									0.964,
									0.155,
									0.499,
									0.612,
									0.659,
									0.635
								],
								"e": 0.209
							},
							{
								"h": [
									0.196,
									0.143,
									0.194,
									0.091,
									0.132,
									0.137,
									0.538,
									0.219,
									0.399,
									0.17,
									0.406,
									0.054
								],
								"e": 0.302
							},
							{
								"h": [
									0.085,
									0.077,
									0.01,
									0.046,
									0.122,
									0.042,
									0.111,
									0.084,
									0.116,
									0.016,
									0.131,
									0.019
								],
								"e": 0.098
							},
							{
								"h": [
									0.011,
									0.104,
									0.027,
									0.025,
									0.042,
									0.042,
									0.023,
									0.006,
									0.029,
									0.032,
									0.015,
									0.042
								],
								"e": 0.038
							},
							{
								"h": [
									0.014,
									0.058,
									0.119,
									0.012,
									0.07,
									0.028,
									0.107,
									0.01,
									0.031,
									0.078,
									0.075,
									0.0
								],
								"e": 0.061
							},
							{
								"h": [
									0.004,
									0.002,
									0.078,
									0.232,
									0.019,
									0.01,
									0.023,
									0.004,
									0.064,
									0.032,
									0.067,
									0.192
								],
								"e": 0.046
							},
							{
								"h": [
									0.062,
									0.026,
									0.07,
									0.066,
									0.009,
									0.024,
									0.037,
									0.047,
									0.014,
									0.008,
									0.004,
									0.078
								],
								"e": 0.035
							},
							{
								"h": [
									0.024,
									0.026,
									0.02,
									0.012,
									0.046,
									0.036,
									0.007,
									0.024,
									0.003,
									0.037,
									0.015,
									0.008
								],
								"e": 0.021
							},
							{
								"h": [
									0.033,
									0.047,
									0.025,
									0.021,
									0.008,
									0.017,
									0.026,
									0.023,
									0.02,
									0.011,
									0.025,
									0.008
								],
								"e": 0.022
							},
							{
								"h": [
									0.005,
									0.046,
									0.004,
									0.033,
									0.047,
									0.021,
									0.021,
									0.034,
									0.005,
									0.01,
									0.032,
									0.011
								],
								"e": 0.022
							},
							{
								"h": [
									0.01,
									0.035,
									0.032,
									0.052,
									0.181,
									0.016,
									0.009,
									0.076,
									0.06,
									0.003,
									0.036,
									0.042
								],
								"e": 0.042
							},
							{
								"h": [
									0.363,
									0.067,
									0.084,
									0.031,
									0.126,
									0.063,
									0.046,
									0.072,
									0.367,
									0.045,
									0.067,
									0.261
								],
								"e": 0.103
							}
						],
						[
							{
								"h": [
									0.802,
									0.01,
									0.955,
									0.0,
									0.019,
									0.0,
									0.875,
									0.25,
									0.329,
									0.125,
									0.737,
									0.526
								],
								"e": 0.221
							},
							{
								"h": [
									0.227,
									0.056,
									0.285,
									0.111,
									0.174,
									0.202,
									0.1,
									0.128,
									0.271,
									0.195,
									0.515,
									0.035
								],
								"e": 0.241
							},
							{
								"h": [
									0.15,
									0.02,
									0.005,
									0.078,
									0.006,
									0.004,
									0.035,
									0.243,
									0.006,
									0.076,
									0.251,
									0.049
								],
								"e": 0.085
							},
							{
								"h": [
									0.002,
									0.015,
									0.012,
									0.005,
									0.006,
									0.011,
									0.096,
									0.006,
									0.005,
									0.011,
									0.04,
									0.031
								],
								"e": 0.019
							},
							{
								"h": [
									0.005,
									0.005,
									0.032,
									0.016,
									0.056,
									0.012,
									0.023,
									0.158,
									0.017,
									0.016,
									0.077,
									0.0
								],
								"e": 0.044
							},
							{
								"h": [
									0.007,
									0.001,
									0.013,
									0.05,
									0.005,
									0.009,
									0.015,
									0.001,
									0.014,
									0.031,
									0.045,
									0.393
								],
								"e": 0.037
							},
							{
								"h": [
									0.015,
									0.002,
									0.036,
									0.036,
									0.005,
									0.02,
									0.019,
									0.021,
									0.004,
									0.005,
									0.007,
									0.006
								],
								"e": 0.015
							},
							{
								"h": [
									0.002,
									0.014,
									0.005,
									0.004,
									0.014,
									0.005,
									0.014,
									0.038,
									0.002,
									0.005,
									0.002,
									0.018
								],
								"e": 0.009
							},
							{
								"h": [
									0.007,
									0.012,
									0.016,
									0.006,
									0.001,
									0.01,
									0.03,
									0.005,
									0.007,
									0.007,
									0.012,
									0.004
								],
								"e": 0.01
							},
							{
								"h": [
									0.012,
									0.006,
									0.004,
									0.007,
									0.009,
									0.01,
									0.011,
									0.018,
									0.008,
									0.005,
									0.004,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.018,
									0.012,
									0.01,
									0.035,
									0.005,
									0.008,
									0.028,
									0.031,
									0.013,
									0.002,
									0.018,
									0.015
								],
								"e": 0.016
							},
							{
								"h": [
									0.15,
									0.173,
									0.018,
									0.014,
									0.034,
									0.014,
									0.017,
									0.008,
									0.742,
									0.01,
									0.033,
									0.024
								],
								"e": 0.059
							}
						],
						[
							{
								"h": [
									0.418,
									0.003,
									0.969,
									0.0,
									0.011,
									0.0,
									0.814,
									0.166,
									0.238,
									0.169,
									0.477,
									0.33
								],
								"e": 0.172
							},
							{
								"h": [
									0.117,
									0.038,
									0.482,
									0.119,
									0.197,
									1.0,
									0.196,
									0.159,
									0.285,
									0.323,
									0.615,
									0.033
								],
								"e": 0.409
							},
							{
								"h": [
									0.035,
									0.018,
									0.012,
									0.048,
									0.012,
									0.009,
									0.029,
									0.391,
									0.044,
									0.162,
									0.448,
									0.079
								],
								"e": 0.112
							},
							{
								"h": [
									0.002,
									0.027,
									0.014,
									0.004,
									0.006,
									0.028,
									0.043,
									0.016,
									0.015,
									0.004,
									0.065,
									0.047
								],
								"e": 0.024
							},
							{
								"h": [
									0.003,
									0.017,
									0.03,
									0.039,
									0.036,
									0.011,
									0.079,
									0.026,
									0.018,
									0.026,
									0.173,
									0.0
								],
								"e": 0.046
							},
							{
								"h": [
									0.055,
									0.001,
									0.021,
									0.028,
									0.012,
									0.001,
									0.004,
									0.003,
									0.031,
									0.024,
									0.029,
									0.119
								],
								"e": 0.023
							},
							{
								"h": [
									0.06,
									0.012,
									0.058,
									0.03,
									0.034,
									0.045,
									0.019,
									0.04,
									0.005,
									0.001,
									0.019,
									0.009
								],
								"e": 0.027
							},
							{
								"h": [
									0.004,
									0.013,
									0.008,
									0.005,
									0.005,
									0.008,
									0.035,
									0.051,
									0.005,
									0.012,
									0.001,
									0.163
								],
								"e": 0.02
							},
							{
								"h": [
									0.012,
									0.04,
									0.03,
									0.002,
									0.002,
									0.013,
									0.085,
									0.005,
									0.016,
									0.007,
									0.012,
									0.016
								],
								"e": 0.02
							},
							{
								"h": [
									0.009,
									0.007,
									0.005,
									0.007,
									0.004,
									0.014,
									0.015,
									0.061,
									0.012,
									0.006,
									0.004,
									0.005
								],
								"e": 0.012
							},
							{
								"h": [
									0.033,
									0.024,
									0.017,
									0.026,
									0.007,
									0.006,
									0.034,
									0.05,
									0.036,
									0.002,
									0.032,
									0.046
								],
								"e": 0.026
							},
							{
								"h": [
									0.113,
									0.109,
									0.04,
									0.021,
									0.122,
									0.043,
									0.037,
									0.043,
									0.632,
									0.037,
									0.058,
									0.046
								],
								"e": 0.074
							}
						],
						[
							{
								"h": [
									0.495,
									0.0,
									0.164,
									0.0,
									0.004,
									0.0,
									0.458,
									0.555,
									0.398,
									0.395,
									0.392,
									1.0
								],
								"e": 0.191
							},
							{
								"h": [
									0.646,
									0.493,
									0.852,
									0.132,
									0.145,
									0.097,
									0.384,
									0.237,
									0.406,
									0.171,
									0.5,
									0.083
								],
								"e": 0.423
							},
							{
								"h": [
									0.205,
									0.109,
									0.13,
									0.128,
									0.508,
									0.219,
									0.621,
									0.266,
									0.196,
									0.172,
									0.227,
									0.054
								],
								"e": 0.324
							},
							{
								"h": [
									0.005,
									0.527,
									0.082,
									0.053,
									0.028,
									0.046,
									0.139,
									0.059,
									0.257,
									0.099,
									0.073,
									0.187
								],
								"e": 0.126
							},
							{
								"h": [
									0.048,
									0.789,
									0.185,
									0.066,
									0.033,
									0.23,
									0.126,
									0.053,
									0.071,
									0.051,
									0.056,
									0.0
								],
								"e": 0.146
							},
							{
								"h": [
									1.0,
									0.005,
									0.045,
									0.322,
									0.243,
									0.017,
									0.009,
									0.019,
									0.271,
									0.102,
									0.155,
									0.101
								],
								"e": 0.169
							},
							{
								"h": [
									0.028,
									0.12,
									0.106,
									0.066,
									0.028,
									0.112,
									0.044,
									0.057,
									0.016,
									0.027,
									0.152,
									0.167
								],
								"e": 0.079
							},
							{
								"h": [
									0.05,
									0.017,
									0.078,
									0.023,
									0.026,
									0.189,
									0.038,
									0.802,
									0.012,
									0.169,
									0.023,
									0.956
								],
								"e": 0.144
							},
							{
								"h": [
									0.077,
									0.232,
									0.067,
									0.048,
									0.027,
									0.057,
									0.165,
									0.101,
									0.025,
									0.021,
									0.061,
									0.033
								],
								"e": 0.074
							},
							{
								"h": [
									0.003,
									0.031,
									0.003,
									0.075,
									0.031,
									0.03,
									0.024,
									0.054,
									0.003,
									0.003,
									0.023,
									0.293
								],
								"e": 0.044
							},
							{
								"h": [
									0.005,
									0.01,
									0.064,
									0.091,
									0.46,
									0.03,
									0.003,
									0.061,
									0.049,
									0.003,
									0.03,
									0.013
								],
								"e": 0.057
							},
							{
								"h": [
									0.37,
									0.022,
									0.048,
									0.036,
									0.091,
									0.04,
									0.019,
									0.109,
									0.417,
									0.01,
									0.083,
									0.306
								],
								"e": 0.094
							}
						],
						[
							{
								"h": [
									0.361,
									0.003,
									0.067,
									0.004,
									0.041,
									0.0,
									0.432,
									0.487,
									0.488,
									0.382,
									0.247,
									0.167
								],
								"e": 0.14
							},
							{
								"h": [
									0.267,
									0.143,
									0.838,
									0.141,
									0.209,
									0.048,
									0.016,
									0.079,
									0.223,
									0.118,
									0.66,
									0.006
								],
								"e": 0.244
							},
							{
								"h": [
									0.296,
									0.019,
									0.066,
									0.749,
									0.062,
									0.04,
									0.091,
									0.332,
									0.185,
									1.0,
									0.285,
									0.077
								],
								"e": 0.29
							},
							{
								"h": [
									0.0,
									0.086,
									0.157,
									0.034,
									0.023,
									0.047,
									0.484,
									0.242,
									0.035,
									0.119,
									0.035,
									0.297
								],
								"e": 0.117
							},
							{
								"h": [
									0.032,
									0.105,
									0.013,
									0.525,
									0.012,
									0.087,
									0.085,
									0.136,
									0.035,
									0.237,
									0.019,
									0.0
								],
								"e": 0.108
							},
							{
								"h": [
									0.002,
									0.0,
									0.032,
									0.222,
									0.066,
									0.005,
									0.019,
									0.074,
									0.032,
									0.006,
									0.055,
									0.11
								],
								"e": 0.042
							},
							{
								"h": [
									0.178,
									0.011,
									0.013,
									0.05,
									0.005,
									0.042,
									0.014,
									0.097,
									0.014,
									0.001,
									0.014,
									0.052
								],
								"e": 0.035
							},
							{
								"h": [
									0.024,
									0.008,
									0.002,
									0.014,
									0.06,
									0.043,
									0.008,
									0.086,
									0.019,
									0.02,
									0.008,
									0.039
								],
								"e": 0.023
							},
							{
								"h": [
									0.011,
									0.004,
									0.009,
									0.015,
									0.005,
									0.047,
									0.005,
									0.015,
									0.015,
									0.012,
									0.013,
									0.011
								],
								"e": 0.012
							},
							{
								"h": [
									0.006,
									0.004,
									0.008,
									0.024,
									0.006,
									0.008,
									0.037,
									0.02,
									0.002,
									0.011,
									0.01,
									0.009
								],
								"e": 0.012
							},
							{
								"h": [
									0.011,
									0.017,
									0.017,
									0.006,
									0.008,
									0.008,
									0.038,
									0.008,
									0.003,
									0.008,
									0.024,
									0.023
								],
								"e": 0.014
							},
							{
								"h": [
									0.164,
									0.013,
									0.03,
									0.007,
									0.057,
									0.006,
									0.023,
									0.007,
									0.358,
									0.011,
									0.021,
									0.037
								],
								"e": 0.037
							}
						],
						[
							{
								"h": [
									0.298,
									0.0,
									0.088,
									0.0,
									0.004,
									0.0,
									0.239,
									0.752,
									0.138,
									0.305,
									0.099,
									0.219
								],
								"e": 0.107
							},
							{
								"h": [
									0.334,
									0.114,
									0.598,
									0.121,
									0.157,
									0.192,
									0.125,
									0.22,
									0.157,
									0.119,
									0.593,
									0.004
								],
								"e": 0.266
							},
							{
								"h": [
									0.079,
									0.01,
									0.069,
									0.155,
									0.051,
									0.055,
									0.056,
									0.33,
									0.282,
									0.677,
									0.329,
									0.074
								],
								"e": 0.199
							},
							{
								"h": [
									0.003,
									0.075,
									0.324,
									0.088,
									0.012,
									0.027,
									0.141,
									1.0,
									0.077,
									0.092,
									0.035,
									0.86
								],
								"e": 0.202
							},
							{
								"h": [
									0.085,
									0.09,
									0.025,
									0.249,
									0.154,
									0.146,
									0.111,
									0.027,
									0.035,
									0.23,
									0.035,
									0.0
								],
								"e": 0.11
							},
							{
								"h": [
									0.001,
									0.0,
									0.034,
									0.117,
									0.071,
									0.001,
									0.024,
									0.025,
									0.006,
									0.01,
									0.04,
									0.067
								],
								"e": 0.028
							},
							{
								"h": [
									0.212,
									0.006,
									0.016,
									0.062,
									0.011,
									0.055,
									0.025,
									0.102,
									0.024,
									0.0,
									0.008,
									0.183
								],
								"e": 0.049
							},
							{
								"h": [
									0.031,
									0.004,
									0.0,
									0.039,
									0.051,
									0.211,
									0.008,
									0.021,
									0.033,
									0.013,
									0.0,
									0.002
								],
								"e": 0.031
							},
							{
								"h": [
									0.015,
									0.002,
									0.009,
									0.007,
									0.028,
									0.09,
									0.013,
									0.092,
									0.028,
									0.019,
									0.021,
									0.006
								],
								"e": 0.023
							},
							{
								"h": [
									0.004,
									0.002,
									0.009,
									0.063,
									0.003,
									0.009,
									0.011,
									0.035,
									0.003,
									0.004,
									0.028,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.011,
									0.013,
									0.008,
									0.004,
									0.013,
									0.042,
									0.009,
									0.014,
									0.002,
									0.014,
									0.005,
									0.026
								],
								"e": 0.013
							},
							{
								"h": [
									0.111,
									0.034,
									0.014,
									0.03,
									0.055,
									0.015,
									0.01,
									0.015,
									1.0,
									0.021,
									0.054,
									0.066
								],
								"e": 0.062
							}
						],
						[
							{
								"h": [
									0.441,
									0.0,
									0.097,
									0.0,
									0.006,
									0.0,
									0.648,
									1.0,
									0.354,
									0.423,
									0.311,
									0.711
								],
								"e": 0.195
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.151,
									0.161,
									0.083,
									0.209,
									0.288,
									0.389,
									0.163,
									0.589,
									0.06
								],
								"e": 0.514
							},
							{
								"h": [
									0.431,
									0.162,
									1.0,
									0.674,
									0.417,
									1.0,
									0.473,
									0.808,
									1.0,
									0.261,
									0.35,
									0.088
								],
								"e": 0.785
							},
							{
								"h": [
									0.001,
									0.409,
									1.0,
									0.315,
									0.038,
									0.04,
									0.197,
									0.129,
									1.0,
									0.175,
									0.054,
									0.232
								],
								"e": 0.297
							},
							{
								"h": [
									0.194,
									1.0,
									0.191,
									0.175,
									0.059,
									0.217,
									0.43,
									0.044,
									0.059,
									0.122,
									0.068,
									1.0
								],
								"e": 0.401
							},
							{
								"h": [
									0.036,
									0.011,
									0.423,
									0.908,
									0.165,
									0.137,
									0.141,
									0.007,
									0.568,
									0.016,
									0.091,
									0.264
								],
								"e": 0.179
							},
							{
								"h": [
									1.0,
									0.026,
									0.077,
									0.123,
									0.015,
									0.139,
									0.032,
									0.104,
									0.126,
									0.001,
									0.028,
									1.0
								],
								"e": 0.177
							},
							{
								"h": [
									0.912,
									0.061,
									0.008,
									0.082,
									0.331,
									0.122,
									0.011,
									0.113,
									0.018,
									0.144,
									0.002,
									0.004
								],
								"e": 0.121
							},
							{
								"h": [
									0.051,
									0.01,
									0.016,
									0.02,
									0.043,
									1.0,
									0.012,
									0.774,
									0.015,
									0.041,
									0.086,
									0.008
								],
								"e": 0.123
							},
							{
								"h": [
									0.002,
									0.014,
									0.002,
									0.295,
									0.023,
									0.019,
									0.003,
									0.034,
									0.002,
									0.002,
									0.636,
									0.004
								],
								"e": 0.062
							},
							{
								"h": [
									0.003,
									0.005,
									0.014,
									0.011,
									0.083,
									0.149,
									0.002,
									0.04,
									0.015,
									0.029,
									0.006,
									0.019
								],
								"e": 0.029
							},
							{
								"h": [
									0.223,
									0.021,
									0.021,
									0.013,
									0.071,
									0.028,
									0.016,
									0.029,
									0.371,
									0.007,
									0.074,
									0.071
								],
								"e": 0.052
							}
						],
						[
							{
								"h": [
									0.54,
									1.0,
									0.469,
									1.0,
									1.0,
									1.0,
									0.673,
									0.345,
									0.179,
									0.096,
									1.0,
									0.434
								],
								"e": 1.0
							},
							{
								"h": [
									0.823,
									0.281,
									0.997,
									0.195,
									0.322,
									0.078,
									0.063,
									0.192,
									0.471,
									0.325,
									1.0,
									1.0
								],
								"e": 0.692
							},
							{
								"h": [
									0.156,
									0.06,
									0.54,
									1.0,
									0.055,
									0.051,
									0.204,
									1.0,
									0.291,
									0.758,
									1.0,
									0.191
								],
								"e": 0.494
							},
							{
								"h": [
									0.048,
									0.102,
									0.396,
									0.108,
									0.012,
									0.038,
									1.0,
									0.26,
									0.133,
									0.152,
									0.235,
									0.784
								],
								"e": 0.241
							},
							{
								"h": [
									0.116,
									0.092,
									0.047,
									0.656,
									0.091,
									0.325,
									0.084,
									1.0,
									0.035,
									0.137,
									0.17,
									0.0
								],
								"e": 0.258
							},
							{
								"h": [
									0.071,
									0.019,
									0.108,
									0.121,
									0.059,
									0.122,
									0.139,
									0.019,
									0.189,
									0.024,
									0.236,
									0.142
								],
								"e": 0.096
							},
							{
								"h": [
									0.163,
									0.044,
									0.06,
									0.055,
									0.073,
									0.1,
									0.104,
									0.143,
									0.121,
									0.025,
									0.031,
									0.053
								],
								"e": 0.081
							},
							{
								"h": [
									0.049,
									0.159,
									0.015,
									0.065,
									0.038,
									0.085,
									0.072,
									0.164,
									0.047,
									0.029,
									0.025,
									0.061
								],
								"e": 0.063
							},
							{
								"h": [
									0.021,
									0.022,
									0.019,
									0.014,
									0.03,
									0.147,
									0.052,
									0.175,
									0.029,
									0.082,
									0.203,
									0.052
								],
								"e": 0.063
							},
							{
								"h": [
									0.063,
									0.012,
									0.024,
									0.195,
									0.016,
									0.025,
									0.019,
									0.073,
									0.038,
									0.012,
									0.088,
									0.006
								],
								"e": 0.042
							},
							{
								"h": [
									0.049,
									0.031,
									0.008,
									0.024,
									0.013,
									0.053,
									0.109,
									0.076,
									0.022,
									0.023,
									0.045,
									0.063
								],
								"e": 0.043
							},
							{
								"h": [
									0.14,
									0.071,
									0.02,
									0.035,
									0.135,
									0.046,
									0.039,
									0.017,
									0.616,
									0.029,
									0.108,
									0.104
								],
								"e": 0.077
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ".",
					"o": " ",
					"t": " Hero",
					"r": [
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.089,
								" The": 0.078,
								" It": 0.048,
								" You": 0.031,
								" If": 0.029,
								" We": 0.028,
								" I": 0.027,
								" In": 0.024,
								" There": 0.021,
								" That": 0.021
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.102,
								" It": 0.071,
								" ": 0.059,
								" You": 0.028,
								" We": 0.027,
								" If": 0.027,
								" There": 0.026,
								" I": 0.025,
								" That": 0.024,
								" They": 0.023
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.072,
								" It": 0.071,
								" The": 0.068,
								" There": 0.044,
								" When": 0.042,
								" You": 0.041,
								" If": 0.035,
								" That": 0.027,
								" They": 0.02,
								" This": 0.019
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.129,
								" When": 0.076,
								" There": 0.068,
								" The": 0.06,
								" It": 0.047,
								" You": 0.047,
								" If": 0.033,
								" This": 0.026,
								" That": 0.02,
								" What": 0.019
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.099,
								" You": 0.068,
								" When": 0.061,
								" It": 0.058,
								" If": 0.052,
								" The": 0.05,
								" There": 0.043,
								" I": 0.02,
								" But": 0.019,
								" That": 0.018
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.097,
								" You": 0.066,
								" If": 0.063,
								" And": 0.048,
								" When": 0.033,
								" To": 0.032,
								" It": 0.031,
								" There": 0.028,
								" Whatever": 0.025,
								" ": 0.024
							}
						},
						{
							"p": " And",
							"b": 0.0,
							"k": {
								" And": 0.071,
								" The": 0.05,
								" ": 0.046,
								" You": 0.045,
								" Who": 0.037,
								" To": 0.033,
								" Nobody": 0.032,
								" He": 0.024,
								" See": 0.023,
								" They": 0.022
							}
						},
						{
							"p": " Other",
							"b": 0.0,
							"k": {
								" Other": 0.084,
								" And": 0.074,
								" Nobody": 0.056,
								" Whatever": 0.05,
								" Total": 0.031,
								" You": 0.024,
								" But": 0.023,
								" No": 0.02,
								" Weak": 0.019,
								" Even": 0.018
							}
						},
						{
							"p": " Nobody",
							"b": 0.001,
							"k": {
								" Nobody": 0.101,
								" Other": 0.076,
								" Whatever": 0.04,
								" Repeat": 0.036,
								" Total": 0.035,
								" Who": 0.031,
								" Source": 0.024,
								" And": 0.021,
								" Conclusion": 0.02,
								" ": 0.018
							}
						},
						{
							"p": " ",
							"b": 0.028,
							"k": {
								" ": 0.07,
								" Death": 0.06,
								" Author": 0.044,
								" Doctor": 0.034,
								" Hero": 0.028,
								" Monster": 0.026,
								" Vill": 0.025,
								" Nobody": 0.025,
								" Character": 0.025,
								"  ": 0.023
							}
						},
						{
							"p": " Vill",
							"b": 0.02,
							"k": {
								" Vill": 0.145,
								" ": 0.111,
								" Author": 0.05,
								" Harry": 0.046,
								" Doctor": 0.031,
								" Voldemort": 0.029,
								" Death": 0.025,
								" Character": 0.023,
								"  ": 0.02,
								" Hero": 0.02
							}
						},
						{
							"p": " ",
							"b": 0.008,
							"k": {
								" ": 0.171,
								" Vill": 0.046,
								" Harry": 0.018,
								"  ": 0.016,
								" The": 0.012,
								" (": 0.01,
								" You": 0.009,
								" Author": 0.009,
								" I": 0.008,
								" He": 0.008
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.001,
									0.475,
									0.034,
									0.117,
									0.155,
									0.805,
									0.063,
									0.037,
									1.0,
									0.67,
									1.0
								],
								"e": 0.41
							},
							{
								"h": [
									0.277,
									0.248,
									0.508,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.011,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.038,
									0.13,
									1.0,
									0.196,
									0.614,
									0.418,
									0.413,
									0.079,
									0.928,
									1.0
								],
								"e": 0.886
							},
							{
								"h": [
									1.0,
									0.726,
									0.093,
									1.0,
									1.0,
									1.0,
									0.08,
									0.125,
									0.432,
									0.795,
									1.0,
									0.391
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.697,
									1.0,
									1.0,
									1.0,
									0.134,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.469,
									1.0,
									1.0,
									0.773,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.272,
									0.776,
									1.0,
									0.757,
									1.0,
									1.0,
									1.0,
									0.996,
									0.204,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.744,
									1.0,
									1.0,
									1.0,
									0.136,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.119,
									1.0,
									0.793,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									0.792,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.783,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.904,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.132,
									0.028,
									0.956,
									0.016,
									0.065,
									0.233,
									0.038,
									0.066,
									0.203,
									0.812,
									0.762,
									0.544
								],
								"e": 0.304
							},
							{
								"h": [
									0.087,
									0.191,
									0.117,
									0.058,
									0.118,
									0.604,
									0.706,
									0.158,
									0.432,
									0.198,
									0.285,
									0.79
								],
								"e": 0.336
							},
							{
								"h": [
									0.073,
									0.043,
									0.0,
									0.017,
									0.034,
									0.051,
									0.095,
									0.034,
									0.087,
									0.002,
									0.109,
									0.013
								],
								"e": 0.062
							},
							{
								"h": [
									0.024,
									0.151,
									0.007,
									0.002,
									0.085,
									0.123,
									0.003,
									0.0,
									0.019,
									0.127,
									0.034,
									0.018
								],
								"e": 0.07
							},
							{
								"h": [
									0.017,
									0.102,
									0.349,
									0.026,
									0.097,
									0.035,
									0.119,
									0.011,
									0.066,
									0.096,
									0.095,
									0.0
								],
								"e": 0.102
							},
							{
								"h": [
									0.0,
									0.0,
									0.06,
									0.258,
									0.023,
									0.006,
									0.001,
									0.003,
									0.005,
									0.028,
									0.109,
									0.048
								],
								"e": 0.034
							},
							{
								"h": [
									0.083,
									0.296,
									0.064,
									0.103,
									0.055,
									0.082,
									0.037,
									0.13,
									0.006,
									0.001,
									0.014,
									0.031
								],
								"e": 0.066
							},
							{
								"h": [
									0.001,
									0.022,
									0.001,
									0.167,
									0.165,
									0.123,
									0.031,
									0.106,
									0.016,
									0.052,
									0.007,
									0.004
								],
								"e": 0.054
							},
							{
								"h": [
									0.031,
									0.062,
									0.181,
									0.471,
									0.017,
									0.053,
									0.137,
									0.005,
									0.591,
									0.167,
									0.069,
									0.04
								],
								"e": 0.108
							},
							{
								"h": [
									0.267,
									0.05,
									0.077,
									0.079,
									1.0,
									0.016,
									0.032,
									0.115,
									0.082,
									0.002,
									0.029,
									0.017
								],
								"e": 0.11
							},
							{
								"h": [
									0.067,
									0.023,
									0.052,
									0.035,
									0.102,
									0.068,
									0.123,
									0.175,
									0.016,
									0.096,
									0.037,
									0.225
								],
								"e": 0.071
							},
							{
								"h": [
									0.502,
									0.19,
									0.094,
									0.163,
									0.176,
									0.243,
									0.024,
									0.066,
									0.249,
									0.063,
									0.353,
									0.143
								],
								"e": 0.161
							}
						],
						[
							{
								"h": [
									0.201,
									0.0,
									0.107,
									0.006,
									0.044,
									0.039,
									0.511,
									0.042,
									0.018,
									0.51,
									0.323,
									0.48
								],
								"e": 0.17
							},
							{
								"h": [
									0.153,
									0.101,
									0.198,
									0.085,
									0.181,
									0.123,
									0.17,
									0.187,
									0.229,
									0.236,
									0.429,
									0.6
								],
								"e": 0.247
							},
							{
								"h": [
									0.081,
									0.011,
									0.001,
									0.06,
									0.069,
									0.006,
									0.03,
									0.168,
									0.012,
									0.028,
									0.253,
									0.034
								],
								"e": 0.074
							},
							{
								"h": [
									0.006,
									0.011,
									0.005,
									0.0,
									0.02,
									0.023,
									0.034,
									0.001,
									0.004,
									0.016,
									0.023,
									0.021
								],
								"e": 0.021
							},
							{
								"h": [
									0.002,
									0.003,
									0.032,
									0.032,
									0.031,
									0.014,
									0.046,
									0.011,
									0.017,
									0.013,
									0.005,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.001,
									0.001,
									0.045,
									0.043,
									0.011,
									0.033,
									0.001,
									0.001,
									0.012,
									0.049,
									0.02,
									0.037
								],
								"e": 0.019
							},
							{
								"h": [
									0.018,
									0.077,
									0.032,
									0.041,
									0.017,
									0.048,
									0.023,
									0.088,
									0.013,
									0.005,
									0.003,
									0.003
								],
								"e": 0.028
							},
							{
								"h": [
									0.0,
									0.007,
									0.006,
									0.02,
									0.012,
									0.022,
									0.029,
									0.003,
									0.011,
									0.014,
									0.005,
									0.03
								],
								"e": 0.012
							},
							{
								"h": [
									0.011,
									0.016,
									0.149,
									0.022,
									0.005,
									0.024,
									0.018,
									0.001,
									0.103,
									0.151,
									0.018,
									0.01
								],
								"e": 0.031
							},
							{
								"h": [
									0.088,
									0.005,
									0.021,
									0.021,
									0.014,
									0.011,
									0.027,
									0.034,
									0.164,
									0.009,
									0.004,
									0.012
								],
								"e": 0.026
							},
							{
								"h": [
									0.095,
									0.015,
									0.064,
									0.034,
									0.009,
									0.014,
									0.042,
									0.124,
									0.005,
									0.003,
									0.037,
									0.027
								],
								"e": 0.034
							},
							{
								"h": [
									0.226,
									0.223,
									0.065,
									0.047,
									0.041,
									0.017,
									0.021,
									0.02,
									0.047,
									0.011,
									0.491,
									0.027
								],
								"e": 0.072
							}
						],
						[
							{
								"h": [
									0.706,
									0.0,
									0.164,
									0.022,
									0.029,
									0.052,
									0.568,
									0.039,
									0.009,
									0.444,
									0.293,
									0.496
								],
								"e": 0.219
							},
							{
								"h": [
									0.102,
									0.087,
									0.323,
									0.094,
									0.193,
									0.173,
									0.091,
									0.211,
									0.212,
									0.171,
									0.552,
									0.363
								],
								"e": 0.238
							},
							{
								"h": [
									0.024,
									0.011,
									0.003,
									0.032,
									0.02,
									0.03,
									0.027,
									0.297,
									0.048,
									0.057,
									0.364,
									0.055
								],
								"e": 0.091
							},
							{
								"h": [
									0.004,
									0.022,
									0.005,
									0.005,
									0.017,
									0.031,
									0.023,
									0.011,
									0.024,
									0.017,
									0.058,
									0.045
								],
								"e": 0.031
							},
							{
								"h": [
									0.002,
									0.004,
									0.017,
									0.055,
									0.03,
									0.008,
									0.072,
									0.067,
									0.01,
									0.012,
									0.009,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.001,
									0.0,
									0.016,
									0.009,
									0.018,
									0.005,
									0.002,
									0.001,
									0.004,
									0.013,
									0.011,
									0.045
								],
								"e": 0.01
							},
							{
								"h": [
									0.015,
									0.074,
									0.031,
									0.027,
									0.013,
									0.11,
									0.01,
									0.149,
									0.024,
									0.001,
									0.004,
									0.006
								],
								"e": 0.033
							},
							{
								"h": [
									0.001,
									0.004,
									0.002,
									0.01,
									0.005,
									0.015,
									0.027,
									0.001,
									0.007,
									0.001,
									0.001,
									0.008
								],
								"e": 0.006
							},
							{
								"h": [
									0.008,
									0.006,
									0.077,
									0.001,
									0.001,
									0.024,
									0.014,
									0.001,
									0.227,
									0.143,
									0.009,
									0.005
								],
								"e": 0.023
							},
							{
								"h": [
									0.205,
									0.006,
									0.04,
									0.033,
									0.004,
									0.019,
									0.035,
									0.034,
									0.337,
									0.005,
									0.003,
									0.002
								],
								"e": 0.044
							},
							{
								"h": [
									0.124,
									0.011,
									0.012,
									0.02,
									0.013,
									0.005,
									0.028,
									0.043,
									0.002,
									0.004,
									0.03,
									0.073
								],
								"e": 0.025
							},
							{
								"h": [
									0.21,
									0.049,
									0.062,
									0.117,
									0.071,
									0.022,
									0.019,
									0.043,
									0.11,
									0.019,
									0.48,
									0.079
								],
								"e": 0.08
							}
						],
						[
							{
								"h": [
									0.051,
									1.0,
									0.234,
									0.039,
									0.196,
									1.0,
									0.009,
									0.113,
									0.701,
									0.672,
									1.0,
									0.944
								],
								"e": 0.617
							},
							{
								"h": [
									0.196,
									0.191,
									0.589,
									0.098,
									0.161,
									0.527,
									0.553,
									0.163,
									0.381,
									0.11,
									0.403,
									0.678
								],
								"e": 0.355
							},
							{
								"h": [
									0.07,
									0.061,
									0.001,
									0.008,
									0.059,
									0.023,
									0.428,
									0.183,
									0.048,
									0.008,
									0.202,
									0.034
								],
								"e": 0.117
							},
							{
								"h": [
									0.01,
									0.029,
									0.002,
									0.008,
									0.047,
									0.065,
									0.009,
									0.004,
									0.038,
									0.033,
									0.082,
									0.033
								],
								"e": 0.044
							},
							{
								"h": [
									0.018,
									0.077,
									0.134,
									0.033,
									0.052,
									0.028,
									0.055,
									0.198,
									0.156,
									0.012,
									0.056,
									0.0
								],
								"e": 0.085
							},
							{
								"h": [
									0.003,
									0.0,
									0.03,
									0.132,
									0.057,
									0.008,
									0.001,
									0.008,
									0.003,
									0.068,
									0.115,
									0.192
								],
								"e": 0.045
							},
							{
								"h": [
									0.03,
									0.12,
									0.063,
									0.096,
									0.06,
									0.084,
									0.061,
									0.116,
									0.005,
									0.002,
									0.016,
									0.022
								],
								"e": 0.054
							},
							{
								"h": [
									0.001,
									0.007,
									0.001,
									0.021,
									0.067,
									0.481,
									0.043,
									0.001,
									0.015,
									0.009,
									0.006,
									0.025
								],
								"e": 0.047
							},
							{
								"h": [
									0.035,
									0.014,
									0.054,
									0.028,
									0.009,
									0.312,
									0.017,
									0.006,
									0.541,
									0.328,
									0.019,
									0.039
								],
								"e": 0.074
							},
							{
								"h": [
									0.346,
									0.057,
									0.087,
									0.108,
									0.08,
									0.005,
									0.005,
									0.088,
									0.023,
									0.002,
									0.022,
									0.012
								],
								"e": 0.054
							},
							{
								"h": [
									0.025,
									0.017,
									0.036,
									0.027,
									0.098,
									0.115,
									0.112,
									0.152,
									0.02,
									0.261,
									0.025,
									0.174
								],
								"e": 0.078
							},
							{
								"h": [
									1.0,
									0.103,
									0.109,
									0.327,
									0.164,
									0.449,
									0.019,
									0.084,
									1.0,
									0.037,
									0.623,
									0.132
								],
								"e": 0.277
							}
						],
						[
							{
								"h": [
									0.541,
									0.0,
									0.125,
									0.048,
									0.069,
									0.016,
									0.162,
									0.173,
									0.013,
									0.395,
									0.221,
									0.273
								],
								"e": 0.163
							},
							{
								"h": [
									0.514,
									0.187,
									0.597,
									0.113,
									0.28,
									0.059,
									0.027,
									0.097,
									0.265,
									0.187,
									0.598,
									0.211
								],
								"e": 0.288
							},
							{
								"h": [
									0.317,
									0.01,
									0.038,
									0.29,
									0.092,
									0.016,
									0.05,
									0.319,
									0.061,
									0.166,
									0.307,
									0.054
								],
								"e": 0.196
							},
							{
								"h": [
									0.002,
									0.019,
									0.461,
									0.017,
									0.005,
									0.057,
									0.162,
									0.042,
									0.007,
									0.147,
									0.043,
									0.174
								],
								"e": 0.122
							},
							{
								"h": [
									0.018,
									0.06,
									0.01,
									0.246,
									0.016,
									0.088,
									0.15,
									0.03,
									0.034,
									0.213,
									0.012,
									0.0
								],
								"e": 0.075
							},
							{
								"h": [
									1.0,
									0.091,
									0.229,
									0.254,
									0.06,
									0.118,
									0.004,
									0.016,
									0.764,
									0.065,
									0.074,
									0.03
								],
								"e": 0.206
							},
							{
								"h": [
									0.083,
									0.192,
									0.006,
									0.101,
									0.008,
									0.084,
									0.049,
									0.293,
									0.059,
									0.076,
									0.031,
									0.035
								],
								"e": 0.077
							},
							{
								"h": [
									0.004,
									0.015,
									0.078,
									0.204,
									0.047,
									0.019,
									0.066,
									0.005,
									0.016,
									0.273,
									0.045,
									0.851
								],
								"e": 0.116
							},
							{
								"h": [
									0.034,
									0.074,
									0.019,
									0.046,
									0.004,
									0.033,
									0.258,
									0.007,
									0.348,
									0.072,
									0.134,
									0.027
								],
								"e": 0.068
							},
							{
								"h": [
									0.188,
									0.021,
									0.087,
									0.037,
									0.026,
									0.01,
									0.089,
									0.054,
									0.046,
									0.024,
									0.015,
									0.075
								],
								"e": 0.05
							},
							{
								"h": [
									0.146,
									0.061,
									0.084,
									0.027,
									0.043,
									0.013,
									0.24,
									1.0,
									0.002,
									0.005,
									0.182,
									0.075
								],
								"e": 0.11
							},
							{
								"h": [
									0.206,
									0.11,
									0.111,
									0.032,
									0.068,
									0.025,
									0.023,
									0.034,
									0.028,
									0.026,
									0.537,
									0.022
								],
								"e": 0.072
							}
						],
						[
							{
								"h": [
									0.321,
									0.0,
									0.208,
									0.204,
									0.148,
									0.031,
									0.076,
									0.169,
									0.014,
									0.468,
									0.214,
									0.259
								],
								"e": 0.186
							},
							{
								"h": [
									0.426,
									0.13,
									0.396,
									0.096,
									0.196,
									0.122,
									0.112,
									0.158,
									0.168,
									0.109,
									0.511,
									0.496
								],
								"e": 0.258
							},
							{
								"h": [
									0.074,
									0.004,
									0.04,
									0.048,
									0.021,
									0.041,
									0.034,
									0.307,
									0.127,
									0.168,
									0.321,
									0.062
								],
								"e": 0.135
							},
							{
								"h": [
									0.003,
									0.017,
									0.096,
									0.04,
									0.011,
									0.05,
									0.048,
									0.144,
									0.032,
									0.097,
									0.092,
									0.427
								],
								"e": 0.115
							},
							{
								"h": [
									0.076,
									0.04,
									0.018,
									0.223,
									0.027,
									0.109,
									0.199,
									0.063,
									0.015,
									0.217,
									0.008,
									0.0
								],
								"e": 0.088
							},
							{
								"h": [
									0.051,
									0.0,
									0.096,
									0.11,
									0.064,
									0.01,
									0.004,
									0.005,
									0.058,
									0.014,
									0.033,
									0.04
								],
								"e": 0.033
							},
							{
								"h": [
									0.102,
									1.0,
									0.01,
									0.135,
									0.008,
									0.088,
									0.064,
									0.297,
									0.128,
									0.003,
									0.058,
									0.053
								],
								"e": 0.123
							},
							{
								"h": [
									0.002,
									0.007,
									0.001,
									0.054,
									0.009,
									0.088,
									0.036,
									0.017,
									0.014,
									0.013,
									0.007,
									0.063
								],
								"e": 0.023
							},
							{
								"h": [
									0.003,
									0.049,
									0.049,
									0.048,
									0.003,
									0.036,
									1.0,
									0.002,
									0.723,
									0.094,
									0.078,
									0.046
								],
								"e": 0.131
							},
							{
								"h": [
									0.229,
									0.017,
									0.276,
									0.086,
									0.054,
									0.012,
									0.139,
									0.21,
									0.047,
									0.03,
									0.015,
									0.017
								],
								"e": 0.08
							},
							{
								"h": [
									0.166,
									0.126,
									0.027,
									0.031,
									0.078,
									0.031,
									0.11,
									0.483,
									0.002,
									0.031,
									0.107,
									0.669
								],
								"e": 0.112
							},
							{
								"h": [
									0.308,
									0.195,
									0.03,
									0.114,
									0.072,
									0.025,
									0.017,
									0.077,
									0.032,
									0.073,
									0.428,
									0.06
								],
								"e": 0.092
							}
						],
						[
							{
								"h": [
									0.112,
									0.017,
									1.0,
									0.382,
									0.284,
									0.102,
									0.023,
									0.691,
									0.282,
									0.709,
									0.567,
									0.564
								],
								"e": 0.445
							},
							{
								"h": [
									0.51,
									1.0,
									0.685,
									0.122,
									0.2,
									0.357,
									0.67,
									0.171,
									0.343,
									0.128,
									0.515,
									0.858
								],
								"e": 0.497
							},
							{
								"h": [
									0.277,
									0.118,
									0.026,
									0.328,
									0.265,
									1.0,
									0.276,
									0.801,
									1.0,
									0.033,
									0.337,
									0.067
								],
								"e": 0.502
							},
							{
								"h": [
									0.016,
									1.0,
									0.667,
									0.09,
									0.162,
									0.128,
									0.055,
									0.014,
									0.428,
									1.0,
									0.104,
									0.092
								],
								"e": 0.37
							},
							{
								"h": [
									0.234,
									0.791,
									0.259,
									0.474,
									0.139,
									0.37,
									0.514,
									0.127,
									0.109,
									0.266,
									0.107,
									0.0
								],
								"e": 0.298
							},
							{
								"h": [
									0.026,
									0.001,
									0.423,
									1.0,
									0.179,
									0.063,
									0.002,
									0.003,
									0.154,
									0.037,
									0.198,
									0.049
								],
								"e": 0.126
							},
							{
								"h": [
									1.0,
									0.724,
									0.05,
									1.0,
									0.08,
									0.231,
									0.107,
									0.348,
									0.024,
									0.001,
									0.621,
									0.149
								],
								"e": 0.34
							},
							{
								"h": [
									0.002,
									0.056,
									0.002,
									1.0,
									0.175,
									0.101,
									0.078,
									1.0,
									0.014,
									0.038,
									0.005,
									0.008
								],
								"e": 0.194
							},
							{
								"h": [
									0.009,
									0.014,
									0.122,
									0.248,
									0.01,
									0.026,
									0.134,
									0.012,
									1.0,
									0.724,
									0.096,
									0.005
								],
								"e": 0.104
							},
							{
								"h": [
									0.115,
									0.01,
									0.047,
									0.063,
									0.167,
									0.019,
									0.002,
									0.038,
									0.01,
									0.0,
									0.056,
									0.006
								],
								"e": 0.036
							},
							{
								"h": [
									0.017,
									0.003,
									0.008,
									0.006,
									0.03,
									0.036,
									0.015,
									0.126,
									0.002,
									0.042,
									0.007,
									0.068
								],
								"e": 0.023
							},
							{
								"h": [
									0.281,
									0.109,
									0.022,
									0.024,
									0.089,
									0.064,
									0.005,
									0.042,
									0.085,
									0.012,
									0.2,
									0.06
								],
								"e": 0.064
							}
						],
						[
							{
								"h": [
									0.812,
									0.0,
									0.103,
									0.259,
									0.193,
									0.019,
									1.0,
									0.438,
									0.01,
									0.413,
									0.222,
									0.831
								],
								"e": 0.382
							},
							{
								"h": [
									1.0,
									0.487,
									0.76,
									0.164,
									0.414,
									0.289,
									0.25,
									0.384,
									0.355,
									0.212,
									1.0,
									0.688
								],
								"e": 0.547
							},
							{
								"h": [
									0.366,
									0.056,
									1.0,
									1.0,
									0.931,
									0.317,
									0.209,
									1.0,
									0.784,
									1.0,
									1.0,
									0.16
								],
								"e": 1.0
							},
							{
								"h": [
									0.004,
									0.31,
									1.0,
									0.505,
									0.041,
									0.081,
									1.0,
									1.0,
									0.435,
									0.289,
									0.227,
									1.0
								],
								"e": 0.689
							},
							{
								"h": [
									0.155,
									0.056,
									0.105,
									1.0,
									0.133,
									0.551,
									0.386,
									0.242,
									0.026,
									0.139,
									0.01,
									1.0
								],
								"e": 0.443
							},
							{
								"h": [
									0.042,
									0.031,
									0.387,
									0.076,
									0.204,
									0.262,
									0.136,
									0.009,
									0.19,
									0.049,
									0.105,
									0.068
								],
								"e": 0.119
							},
							{
								"h": [
									0.149,
									0.467,
									0.044,
									0.094,
									0.05,
									0.305,
									0.063,
									1.0,
									1.0,
									0.035,
									0.072,
									0.05
								],
								"e": 0.271
							},
							{
								"h": [
									0.006,
									0.038,
									0.024,
									0.054,
									0.006,
									0.048,
									0.101,
									0.015,
									0.043,
									0.015,
									0.024,
									0.057
								],
								"e": 0.036
							},
							{
								"h": [
									0.008,
									0.016,
									0.094,
									0.016,
									0.025,
									0.027,
									0.006,
									0.01,
									0.583,
									0.92,
									0.055,
									0.016
								],
								"e": 0.07
							},
							{
								"h": [
									0.092,
									0.006,
									0.151,
									0.144,
									0.003,
									0.03,
									0.023,
									0.049,
									0.515,
									0.006,
									0.012,
									0.002
								],
								"e": 0.066
							},
							{
								"h": [
									0.258,
									0.015,
									0.011,
									0.023,
									0.012,
									0.016,
									0.071,
									0.259,
									0.004,
									0.012,
									0.054,
									0.054
								],
								"e": 0.05
							},
							{
								"h": [
									0.129,
									0.064,
									0.048,
									0.065,
									0.054,
									0.019,
									0.019,
									0.018,
									0.008,
									0.029,
									0.489,
									0.077
								],
								"e": 0.06
							}
						],
						[
							{
								"h": [
									0.057,
									0.971,
									0.231,
									1.0,
									1.0,
									0.771,
									0.009,
									1.0,
									1.0,
									0.693,
									0.876,
									0.997
								],
								"e": 1.0
							},
							{
								"h": [
									0.817,
									0.694,
									1.0,
									0.157,
									0.267,
									0.5,
									0.526,
									0.189,
									0.39,
									0.095,
									0.732,
									0.824
								],
								"e": 0.544
							},
							{
								"h": [
									0.323,
									0.077,
									0.227,
									0.125,
									0.643,
									0.5,
									1.0,
									0.612,
									0.528,
									0.17,
									0.502,
									0.115
								],
								"e": 0.543
							},
							{
								"h": [
									0.009,
									0.222,
									0.107,
									0.477,
									0.06,
									0.106,
									0.138,
									0.189,
									1.0,
									0.336,
									0.297,
									0.354
								],
								"e": 0.352
							},
							{
								"h": [
									0.26,
									0.732,
									0.185,
									0.163,
									0.082,
									0.252,
									0.284,
									1.0,
									0.335,
									0.054,
									0.058,
									0.0
								],
								"e": 0.326
							},
							{
								"h": [
									0.021,
									0.003,
									0.133,
									0.421,
									0.325,
									0.239,
									0.019,
									0.035,
									0.063,
									0.074,
									0.202,
									0.329
								],
								"e": 0.134
							},
							{
								"h": [
									0.074,
									0.255,
									0.074,
									0.352,
									0.148,
									0.359,
									0.269,
									0.344,
									0.036,
									0.035,
									0.151,
									0.073
								],
								"e": 0.176
							},
							{
								"h": [
									0.001,
									0.068,
									0.022,
									0.079,
									0.008,
									0.096,
									0.113,
									0.009,
									0.016,
									0.006,
									0.118,
									0.05
								],
								"e": 0.049
							},
							{
								"h": [
									0.046,
									0.04,
									0.063,
									0.057,
									0.007,
									0.019,
									0.05,
									0.011,
									0.772,
									0.633,
									0.089,
									0.033
								],
								"e": 0.079
							},
							{
								"h": [
									0.095,
									0.09,
									0.123,
									0.034,
									0.034,
									0.029,
									0.013,
									0.135,
									0.037,
									0.004,
									0.031,
									0.003
								],
								"e": 0.047
							},
							{
								"h": [
									0.021,
									0.027,
									0.042,
									0.053,
									0.15,
									0.067,
									0.08,
									0.179,
									0.024,
									0.181,
									0.031,
									0.296
								],
								"e": 0.081
							},
							{
								"h": [
									0.787,
									0.107,
									0.062,
									0.173,
									0.131,
									0.238,
									0.016,
									0.057,
									0.326,
									0.039,
									0.542,
									0.122
								],
								"e": 0.164
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Hero",
					"o": ":",
					"t": ":",
					"r": [
						{
							"p": " Hero",
							"b": 0.0,
							"k": {
								" Hero": 0.948,
								" hero": 0.03,
								" heroes": 0.003,
								" Soldier": 0.002,
								" Maker": 0.002,
								" leader": 0.001,
								" soldier": 0.001,
								" Avatar": 0.001,
								"Hero": 0.001,
								"!": 0.0
							}
						},
						{
							"p": " Hero",
							"b": 0.0,
							"k": {
								" Hero": 0.821,
								" hero": 0.072,
								" Avatar": 0.007,
								"ic": 0.006,
								"iac": 0.006,
								" heroes": 0.006,
								" soldier": 0.003,
								" Maker": 0.003,
								"Hero": 0.003,
								"ically": 0.002
							}
						},
						{
							"p": " Hero",
							"b": 0.0,
							"k": {
								" Hero": 0.287,
								"ic": 0.146,
								"ically": 0.104,
								"iac": 0.077,
								" hero": 0.028,
								"ine": 0.024,
								"ku": 0.018,
								"ics": 0.016,
								"ji": 0.015,
								"'s": 0.013
							}
						},
						{
							"p": "ically",
							"b": 0.0,
							"k": {
								"ically": 0.317,
								"ic": 0.162,
								"iac": 0.133,
								"ical": 0.04,
								" Hero": 0.036,
								"ics": 0.029,
								"ku": 0.019,
								"ines": 0.017,
								"ine": 0.016,
								" hero": 0.014
							}
						},
						{
							"p": "ically",
							"b": 0.001,
							"k": {
								"ically": 0.335,
								"ic": 0.182,
								"ical": 0.134,
								"ics": 0.096,
								"ines": 0.034,
								"iac": 0.032,
								"ine": 0.03,
								"'s": 0.011,
								"sw": 0.005,
								"dom": 0.005
							}
						},
						{
							"p": "ic",
							"b": 0.115,
							"k": {
								"ic": 0.243,
								"ically": 0.178,
								":": 0.115,
								"ics": 0.101,
								"ical": 0.069,
								"ie": 0.06,
								"ine": 0.02,
								"ess": 0.015,
								"'s": 0.011,
								"ized": 0.01
							}
						},
						{
							"p": ":",
							"b": 0.469,
							"k": {
								":": 0.469,
								"ically": 0.166,
								"ic": 0.1,
								"ie": 0.053,
								"ical": 0.025,
								"ics": 0.024,
								"'s": 0.021,
								"ess": 0.018,
								"ine": 0.014,
								"ines": 0.014
							}
						},
						{
							"p": ":",
							"b": 0.604,
							"k": {
								":": 0.604,
								"ically": 0.239,
								"ic": 0.031,
								"ine": 0.026,
								"ines": 0.019,
								"'s": 0.015,
								"ess": 0.015,
								"ie": 0.01,
								"ics": 0.006,
								"ical": 0.005
							}
						},
						{
							"p": ":",
							"b": 0.937,
							"k": {
								":": 0.937,
								"ic": 0.021,
								"ine": 0.012,
								"ically": 0.008,
								"ess": 0.006,
								"'s": 0.005,
								"/": 0.001,
								"ie": 0.001,
								"ical": 0.001,
								"ics": 0.001
							}
						},
						{
							"p": ":",
							"b": 0.999,
							"k": {
								":": 0.999,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ":",
							"b": 0.998,
							"k": {
								":": 0.998,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ":",
							"b": 0.925,
							"k": {
								":": 0.925,
								"/": 0.003,
								" of": 0.003,
								"ic": 0.003,
								"'s": 0.003,
								"(": 0.001,
								",": 0.001,
								"-": 0.001,
								".": 0.001,
								" ": 0.001
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.013,
									0.538,
									0.014,
									0.21,
									1.0,
									1.0,
									0.095,
									0.737,
									0.171,
									1.0,
									0.119
								],
								"e": 0.576
							},
							{
								"h": [
									0.058,
									0.047,
									0.41,
									1.0,
									1.0,
									0.33,
									1.0,
									0.728,
									1.0,
									1.0,
									0.01,
									0.105
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.011,
									0.17,
									0.498,
									0.034,
									0.365,
									0.341,
									0.218,
									0.091,
									0.507,
									1.0
								],
								"e": 0.642
							},
							{
								"h": [
									1.0,
									0.791,
									0.103,
									0.466,
									1.0,
									1.0,
									0.35,
									0.123,
									0.143,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.189,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.357,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.043,
									0.096,
									0.932,
									0.951,
									1.0,
									0.024,
									1.0,
									1.0,
									0.362,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.927,
									1.0,
									1.0,
									1.0,
									1.0,
									0.664,
									0.855,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.843,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.881,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.141
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.149,
									0.0,
									0.3,
									0.0,
									0.003,
									0.0,
									0.25,
									0.041,
									0.829,
									0.342,
									0.409,
									0.089
								],
								"e": 0.157
							},
							{
								"h": [
									0.035,
									0.051,
									0.146,
									0.043,
									0.138,
									0.187,
									0.266,
									0.216,
									0.606,
									0.348,
									0.253,
									0.021
								],
								"e": 0.285
							},
							{
								"h": [
									0.061,
									0.063,
									0.0,
									0.017,
									0.032,
									0.009,
									0.058,
									0.046,
									0.045,
									0.003,
									0.141,
									0.021
								],
								"e": 0.05
							},
							{
								"h": [
									0.015,
									0.118,
									0.007,
									0.004,
									0.037,
									0.204,
									0.015,
									0.001,
									0.003,
									0.032,
									0.028,
									0.018
								],
								"e": 0.056
							},
							{
								"h": [
									0.005,
									0.01,
									0.259,
									0.015,
									0.134,
									0.012,
									0.104,
									0.005,
									0.391,
									0.177,
									0.091,
									0.0
								],
								"e": 0.109
							},
							{
								"h": [
									0.003,
									1.0,
									0.117,
									0.362,
									0.006,
									1.0,
									0.009,
									0.001,
									1.0,
									0.119,
									0.285,
									0.077
								],
								"e": 0.598
							},
							{
								"h": [
									0.034,
									0.266,
									0.061,
									0.188,
									0.014,
									0.034,
									0.178,
									0.191,
									0.011,
									1.0,
									0.036,
									0.003
								],
								"e": 0.148
							},
							{
								"h": [
									0.004,
									0.014,
									0.15,
									0.048,
									0.003,
									0.009,
									0.01,
									0.101,
									0.011,
									0.006,
									0.635,
									0.009
								],
								"e": 0.067
							},
							{
								"h": [
									0.011,
									1.0,
									0.027,
									0.026,
									0.007,
									0.007,
									0.427,
									0.007,
									0.053,
									0.028,
									0.038,
									0.005
								],
								"e": 0.084
							},
							{
								"h": [
									0.09,
									0.075,
									0.058,
									0.023,
									0.064,
									0.006,
									0.067,
									0.081,
									0.008,
									0.037,
									0.009,
									0.298
								],
								"e": 0.063
							},
							{
								"h": [
									0.021,
									0.041,
									0.088,
									0.072,
									0.042,
									0.013,
									0.034,
									0.112,
									0.28,
									0.005,
									0.018,
									0.06
								],
								"e": 0.061
							},
							{
								"h": [
									0.288,
									0.036,
									0.059,
									0.077,
									0.14,
									0.066,
									0.042,
									0.057,
									0.236,
									0.049,
									0.176,
									0.293
								],
								"e": 0.115
							}
						],
						[
							{
								"h": [
									0.29,
									0.001,
									0.682,
									0.0,
									0.02,
									0.0,
									0.341,
									0.014,
									0.933,
									0.515,
									0.395,
									0.403
								],
								"e": 0.251
							},
							{
								"h": [
									0.113,
									0.037,
									0.196,
									0.062,
									0.172,
									0.109,
									0.3,
									0.478,
									0.211,
									0.357,
									0.385,
									0.004
								],
								"e": 0.293
							},
							{
								"h": [
									0.144,
									0.014,
									0.0,
									0.048,
									0.003,
									0.003,
									0.017,
									0.144,
									0.002,
									0.027,
									0.255,
									0.033
								],
								"e": 0.055
							},
							{
								"h": [
									0.001,
									0.006,
									0.002,
									0.001,
									0.164,
									0.039,
									0.053,
									0.004,
									0.001,
									0.008,
									0.041,
									0.017
								],
								"e": 0.041
							},
							{
								"h": [
									0.001,
									0.001,
									0.017,
									0.023,
									0.02,
									0.003,
									0.027,
									0.065,
									0.054,
									0.008,
									0.005,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.001,
									0.012,
									0.034,
									0.003,
									0.021,
									0.002,
									0.0,
									0.014,
									0.077,
									0.032,
									0.031
								],
								"e": 0.025
							},
							{
								"h": [
									0.015,
									0.015,
									0.014,
									0.054,
									0.005,
									0.019,
									0.027,
									0.039,
									0.005,
									0.038,
									0.01,
									0.001
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.011,
									0.004,
									0.016,
									0.001,
									0.002,
									0.005,
									0.012,
									0.003,
									0.001,
									0.005,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.003,
									0.007,
									0.003,
									0.004,
									0.001,
									0.007,
									0.035,
									0.003,
									0.018,
									0.014,
									0.007,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.02,
									0.004,
									0.021,
									0.01,
									0.005,
									0.009,
									0.009,
									0.03,
									0.016,
									0.004,
									0.002,
									0.003
								],
								"e": 0.011
							},
							{
								"h": [
									0.007,
									0.004,
									0.011,
									0.007,
									0.009,
									0.004,
									0.005,
									0.005,
									0.007,
									0.001,
									0.005,
									0.008
								],
								"e": 0.006
							},
							{
								"h": [
									0.334,
									0.065,
									0.026,
									0.029,
									0.016,
									0.007,
									0.01,
									0.009,
									0.246,
									0.004,
									0.02,
									0.011
								],
								"e": 0.046
							}
						],
						[
							{
								"h": [
									0.284,
									0.0,
									1.0,
									0.0,
									0.021,
									0.0,
									0.552,
									0.034,
									0.52,
									0.425,
									0.192,
									0.512
								],
								"e": 0.262
							},
							{
								"h": [
									0.093,
									0.02,
									0.309,
									0.073,
									0.187,
									0.28,
									0.226,
									0.546,
									0.262,
									0.368,
									0.48,
									0.002
								],
								"e": 0.338
							},
							{
								"h": [
									0.042,
									0.016,
									0.001,
									0.017,
									0.007,
									0.011,
									0.014,
									0.234,
									0.01,
									0.045,
									0.386,
									0.046
								],
								"e": 0.064
							},
							{
								"h": [
									0.001,
									0.011,
									0.001,
									0.003,
									0.189,
									0.051,
									0.013,
									0.016,
									0.003,
									0.003,
									0.058,
									0.011
								],
								"e": 0.045
							},
							{
								"h": [
									0.0,
									0.001,
									0.007,
									0.015,
									0.009,
									0.001,
									0.046,
									0.012,
									0.044,
									0.008,
									0.006,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.0,
									0.013,
									0.015,
									0.004,
									0.002,
									0.002,
									0.0,
									0.011,
									0.025,
									0.039,
									0.087
								],
								"e": 0.022
							},
							{
								"h": [
									0.012,
									0.004,
									0.023,
									0.02,
									0.005,
									0.034,
									0.004,
									0.044,
									0.006,
									0.006,
									0.006,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.004,
									0.002,
									0.011,
									0.0,
									0.002,
									0.005,
									0.002,
									0.001,
									0.0,
									0.002,
									0.007
								],
								"e": 0.003
							},
							{
								"h": [
									0.005,
									0.003,
									0.002,
									0.0,
									0.001,
									0.01,
									0.045,
									0.002,
									0.039,
									0.027,
									0.007,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.032,
									0.008,
									0.016,
									0.021,
									0.003,
									0.012,
									0.014,
									0.019,
									0.021,
									0.006,
									0.001,
									0.004
								],
								"e": 0.012
							},
							{
								"h": [
									0.013,
									0.007,
									0.006,
									0.005,
									0.016,
									0.002,
									0.007,
									0.006,
									0.002,
									0.001,
									0.015,
									0.023
								],
								"e": 0.009
							},
							{
								"h": [
									0.434,
									0.052,
									0.036,
									0.073,
									0.041,
									0.02,
									0.027,
									0.03,
									0.119,
									0.016,
									0.069,
									0.01
								],
								"e": 0.059
							}
						],
						[
							{
								"h": [
									0.238,
									0.0,
									0.163,
									0.0,
									0.004,
									0.0,
									0.107,
									0.152,
									0.484,
									0.255,
									0.269,
									0.187
								],
								"e": 0.137
							},
							{
								"h": [
									0.161,
									0.206,
									0.482,
									0.076,
									0.168,
									0.25,
									0.148,
									0.307,
									0.636,
									0.343,
									0.384,
									0.038
								],
								"e": 0.395
							},
							{
								"h": [
									0.112,
									0.107,
									0.005,
									0.047,
									0.084,
									0.045,
									0.407,
									0.164,
									0.087,
									0.027,
									0.231,
									0.042
								],
								"e": 0.143
							},
							{
								"h": [
									0.015,
									0.131,
									0.025,
									0.021,
									0.042,
									0.156,
									0.037,
									0.011,
									0.039,
									0.028,
									0.064,
									0.065
								],
								"e": 0.073
							},
							{
								"h": [
									0.018,
									0.084,
									0.229,
									0.1,
									0.075,
									0.027,
									0.105,
									0.061,
									0.262,
									0.024,
									0.096,
									0.0
								],
								"e": 0.099
							},
							{
								"h": [
									0.0,
									0.0,
									0.059,
									0.295,
									0.1,
									0.001,
									0.05,
									0.001,
									0.007,
									0.105,
									0.259,
									0.067
								],
								"e": 0.086
							},
							{
								"h": [
									0.097,
									0.004,
									0.136,
									0.098,
									0.023,
									0.089,
									0.037,
									0.15,
									0.069,
									0.008,
									0.015,
									0.036
								],
								"e": 0.064
							},
							{
								"h": [
									0.073,
									0.011,
									0.003,
									0.03,
									0.002,
									0.044,
									0.005,
									0.003,
									0.014,
									0.003,
									0.009,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.021,
									0.014,
									0.005,
									0.007,
									0.018,
									0.06,
									0.062,
									0.124,
									0.057,
									0.07,
									0.025,
									0.01
								],
								"e": 0.034
							},
							{
								"h": [
									0.031,
									0.019,
									0.035,
									0.248,
									0.015,
									0.008,
									0.007,
									0.047,
									0.003,
									0.002,
									0.022,
									0.01
								],
								"e": 0.026
							},
							{
								"h": [
									0.003,
									0.008,
									0.022,
									0.02,
									0.056,
									0.027,
									0.01,
									0.063,
									0.061,
									0.009,
									0.004,
									0.045
								],
								"e": 0.025
							},
							{
								"h": [
									0.276,
									0.013,
									0.02,
									0.172,
									0.092,
									0.024,
									0.015,
									0.03,
									0.149,
									0.01,
									0.19,
									1.0
								],
								"e": 0.145
							}
						],
						[
							{
								"h": [
									0.212,
									0.009,
									0.127,
									0.003,
									0.038,
									0.0,
									0.327,
									0.029,
									0.291,
									0.26,
									0.331,
									0.067
								],
								"e": 0.124
							},
							{
								"h": [
									0.314,
									0.087,
									0.488,
									0.088,
									0.233,
									0.327,
									0.113,
									0.208,
									0.171,
									0.14,
									0.531,
									0.006
								],
								"e": 0.328
							},
							{
								"h": [
									0.323,
									0.038,
									0.008,
									0.159,
									0.034,
									0.008,
									0.039,
									0.217,
									0.036,
									0.077,
									0.312,
									0.048
								],
								"e": 0.112
							},
							{
								"h": [
									0.0,
									0.02,
									0.012,
									0.025,
									0.025,
									0.072,
									0.173,
									0.113,
									0.005,
									0.035,
									0.085,
									0.145
								],
								"e": 0.077
							},
							{
								"h": [
									0.006,
									0.009,
									0.017,
									0.09,
									0.002,
									0.011,
									0.083,
									0.403,
									0.055,
									0.022,
									0.004,
									0.0
								],
								"e": 0.054
							},
							{
								"h": [
									0.001,
									0.0,
									0.01,
									0.077,
									0.027,
									0.001,
									0.035,
									0.01,
									0.006,
									0.051,
									0.015,
									0.021
								],
								"e": 0.026
							},
							{
								"h": [
									0.03,
									0.012,
									0.009,
									0.046,
									0.003,
									0.058,
									0.099,
									0.092,
									0.023,
									0.003,
									0.014,
									0.017
								],
								"e": 0.03
							},
							{
								"h": [
									0.02,
									0.018,
									0.004,
									0.027,
									0.005,
									0.001,
									0.006,
									0.018,
									0.009,
									0.005,
									0.006,
									0.005
								],
								"e": 0.01
							},
							{
								"h": [
									0.002,
									0.004,
									0.002,
									0.006,
									0.005,
									0.056,
									0.221,
									0.009,
									0.011,
									0.019,
									0.009,
									0.004
								],
								"e": 0.019
							},
							{
								"h": [
									0.071,
									0.001,
									0.042,
									0.043,
									0.017,
									0.008,
									0.023,
									0.03,
									0.006,
									0.007,
									0.008,
									0.002
								],
								"e": 0.019
							},
							{
								"h": [
									0.01,
									0.007,
									0.015,
									0.004,
									0.005,
									0.014,
									0.05,
									0.016,
									0.002,
									0.006,
									0.034,
									0.011
								],
								"e": 0.015
							},
							{
								"h": [
									0.152,
									0.012,
									0.027,
									0.025,
									0.014,
									0.005,
									0.031,
									0.005,
									0.18,
									0.009,
									0.025,
									0.024
								],
								"e": 0.033
							}
						],
						[
							{
								"h": [
									0.093,
									0.0,
									0.064,
									0.0,
									0.002,
									0.0,
									0.292,
									0.022,
									0.232,
									0.121,
									0.141,
									0.055
								],
								"e": 0.07
							},
							{
								"h": [
									0.268,
									0.038,
									0.354,
									0.074,
									0.186,
									0.082,
									0.232,
									0.382,
									0.209,
									0.117,
									0.472,
									0.003
								],
								"e": 0.283
							},
							{
								"h": [
									0.062,
									0.009,
									0.003,
									0.025,
									0.012,
									0.01,
									0.029,
									0.198,
									0.036,
									0.081,
									0.321,
									0.051
								],
								"e": 0.07
							},
							{
								"h": [
									0.02,
									0.017,
									0.012,
									0.019,
									0.022,
									0.048,
									0.051,
									0.115,
									0.008,
									0.025,
									0.057,
									0.088
								],
								"e": 0.057
							},
							{
								"h": [
									0.005,
									0.004,
									0.019,
									0.099,
									0.006,
									0.011,
									0.065,
									0.029,
									0.048,
									0.014,
									0.02,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.005,
									0.0,
									0.022,
									0.039,
									0.011,
									0.0,
									0.035,
									0.005,
									0.002,
									0.049,
									0.047,
									0.038
								],
								"e": 0.027
							},
							{
								"h": [
									0.008,
									0.095,
									0.007,
									0.038,
									0.002,
									0.026,
									0.138,
									0.065,
									0.032,
									0.0,
									0.035,
									0.005
								],
								"e": 0.032
							},
							{
								"h": [
									0.003,
									0.007,
									0.002,
									0.015,
									0.001,
									0.001,
									0.007,
									0.006,
									0.006,
									0.004,
									0.003,
									0.011
								],
								"e": 0.006
							},
							{
								"h": [
									0.002,
									0.005,
									0.002,
									0.002,
									0.005,
									0.025,
									0.254,
									0.004,
									0.017,
									0.018,
									0.01,
									0.002
								],
								"e": 0.017
							},
							{
								"h": [
									0.046,
									0.003,
									0.067,
									0.033,
									0.008,
									0.008,
									0.01,
									0.062,
									0.004,
									0.022,
									0.009,
									0.004
								],
								"e": 0.021
							},
							{
								"h": [
									0.014,
									0.024,
									0.004,
									0.005,
									0.091,
									0.017,
									0.014,
									0.021,
									0.025,
									0.013,
									0.017,
									0.062
								],
								"e": 0.024
							},
							{
								"h": [
									0.501,
									0.029,
									0.01,
									0.126,
									0.028,
									0.023,
									0.021,
									0.009,
									1.0,
									0.027,
									0.08,
									0.032
								],
								"e": 0.109
							}
						],
						[
							{
								"h": [
									0.115,
									0.0,
									0.182,
									0.0,
									0.009,
									0.0,
									0.177,
									0.331,
									0.752,
									0.306,
									0.302,
									0.103
								],
								"e": 0.168
							},
							{
								"h": [
									0.474,
									0.432,
									0.592,
									0.095,
									0.199,
									0.196,
									0.165,
									0.298,
									0.452,
									0.265,
									0.486,
									0.03
								],
								"e": 0.467
							},
							{
								"h": [
									0.265,
									0.137,
									0.056,
									0.323,
									0.193,
									0.318,
									0.2,
									0.528,
									0.514,
									0.035,
									0.352,
									0.064
								],
								"e": 0.315
							},
							{
								"h": [
									0.001,
									0.637,
									0.284,
									0.127,
									0.061,
									0.119,
									0.112,
									0.028,
									0.189,
									0.227,
									0.052,
									0.112
								],
								"e": 0.219
							},
							{
								"h": [
									0.042,
									0.174,
									0.319,
									0.079,
									0.115,
									0.073,
									0.324,
									0.051,
									0.654,
									0.453,
									0.038,
									0.0
								],
								"e": 0.207
							},
							{
								"h": [
									1.0,
									0.015,
									1.0,
									1.0,
									0.023,
									0.003,
									0.082,
									0.003,
									0.436,
									0.147,
									0.225,
									0.098
								],
								"e": 0.463
							},
							{
								"h": [
									0.058,
									0.259,
									0.057,
									1.0,
									0.023,
									0.045,
									0.459,
									0.2,
									0.051,
									0.03,
									0.58,
									0.013
								],
								"e": 0.189
							},
							{
								"h": [
									0.012,
									0.016,
									0.072,
									0.116,
									0.002,
									0.014,
									0.031,
									0.116,
									0.008,
									0.012,
									0.009,
									0.023
								],
								"e": 0.034
							},
							{
								"h": [
									0.008,
									0.473,
									0.028,
									0.014,
									0.019,
									0.014,
									0.316,
									0.024,
									0.02,
									0.061,
									0.066,
									0.001
								],
								"e": 0.057
							},
							{
								"h": [
									0.016,
									0.016,
									0.016,
									0.03,
									0.01,
									0.009,
									0.008,
									0.023,
									0.002,
									0.005,
									0.015,
									0.356
								],
								"e": 0.035
							},
							{
								"h": [
									0.002,
									0.004,
									0.017,
									0.014,
									0.023,
									0.026,
									0.005,
									0.032,
									0.072,
									0.008,
									0.003,
									0.025
								],
								"e": 0.018
							},
							{
								"h": [
									0.205,
									0.009,
									0.007,
									0.019,
									0.051,
									0.021,
									0.012,
									0.009,
									0.039,
									0.01,
									0.093,
									0.031
								],
								"e": 0.033
							}
						],
						[
							{
								"h": [
									0.912,
									0.001,
									0.916,
									0.004,
									0.379,
									0.0,
									0.712,
									0.31,
									1.0,
									1.0,
									0.248,
									1.0
								],
								"e": 0.537
							},
							{
								"h": [
									0.488,
									0.27,
									0.66,
									0.136,
									0.345,
									1.0,
									0.276,
									1.0,
									0.486,
									0.587,
									0.901,
									0.003
								],
								"e": 0.76
							},
							{
								"h": [
									0.497,
									0.041,
									0.202,
									1.0,
									0.267,
									0.264,
									0.13,
									0.681,
									0.132,
									1.0,
									0.997,
									0.12
								],
								"e": 0.52
							},
							{
								"h": [
									0.001,
									0.107,
									0.257,
									0.224,
									0.358,
									0.093,
									0.636,
									1.0,
									0.118,
									0.07,
									0.168,
									0.175
								],
								"e": 0.373
							},
							{
								"h": [
									0.012,
									0.01,
									0.03,
									0.373,
									0.018,
									0.072,
									0.277,
									0.472,
									0.099,
									0.047,
									0.01,
									0.0
								],
								"e": 0.112
							},
							{
								"h": [
									0.002,
									0.0,
									0.126,
									0.072,
									0.077,
									0.013,
									0.074,
									0.001,
									0.026,
									0.197,
									0.055,
									0.099
								],
								"e": 0.08
							},
							{
								"h": [
									0.085,
									0.019,
									0.018,
									0.072,
									0.004,
									0.109,
									0.032,
									0.162,
									0.119,
									0.03,
									0.035,
									0.004
								],
								"e": 0.053
							},
							{
								"h": [
									0.01,
									0.006,
									0.003,
									0.035,
									0.001,
									0.003,
									0.01,
									0.014,
									0.004,
									0.001,
									0.004,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.005,
									0.003,
									0.003,
									0.002,
									0.012,
									0.036,
									0.011,
									0.039,
									0.029,
									0.049,
									0.019,
									0.005
								],
								"e": 0.017
							},
							{
								"h": [
									0.028,
									0.001,
									0.045,
									0.067,
									0.001,
									0.012,
									0.002,
									0.035,
									0.043,
									0.002,
									0.012,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.017,
									0.004,
									0.002,
									0.003,
									0.013,
									0.007,
									0.006,
									0.006,
									0.001,
									0.003,
									0.004,
									0.008
								],
								"e": 0.006
							},
							{
								"h": [
									0.287,
									0.015,
									0.015,
									0.082,
									0.018,
									0.005,
									0.007,
									0.003,
									0.019,
									0.005,
									0.048,
									0.022
								],
								"e": 0.03
							}
						],
						[
							{
								"h": [
									0.263,
									0.0,
									0.138,
									0.0,
									0.017,
									0.0,
									0.095,
									1.0,
									0.51,
									0.267,
									0.211,
									0.249
								],
								"e": 0.255
							},
							{
								"h": [
									0.997,
									1.0,
									0.796,
									0.131,
									0.249,
									0.246,
									0.15,
									0.359,
									0.635,
									0.27,
									0.709,
									0.045
								],
								"e": 0.732
							},
							{
								"h": [
									0.477,
									0.118,
									1.0,
									0.875,
									1.0,
									1.0,
									1.0,
									0.443,
									1.0,
									0.518,
									0.582,
									0.122
								],
								"e": 1.0
							},
							{
								"h": [
									0.011,
									1.0,
									1.0,
									1.0,
									0.042,
									0.194,
									0.438,
									0.406,
									1.0,
									0.21,
									0.194,
									0.631
								],
								"e": 0.733
							},
							{
								"h": [
									0.139,
									1.0,
									0.382,
									0.393,
									0.077,
									0.22,
									0.465,
									0.164,
									0.241,
									0.056,
									0.072,
									1.0
								],
								"e": 0.52
							},
							{
								"h": [
									0.002,
									0.0,
									0.263,
									0.77,
									0.471,
									0.001,
									0.347,
									0.002,
									0.038,
									0.153,
									0.222,
									0.102
								],
								"e": 0.225
							},
							{
								"h": [
									0.185,
									0.005,
									0.079,
									0.178,
									0.031,
									0.197,
									0.125,
									0.205,
									1.0,
									0.019,
									0.06,
									0.064
								],
								"e": 0.166
							},
							{
								"h": [
									0.751,
									0.016,
									0.006,
									0.042,
									0.001,
									0.01,
									0.013,
									0.007,
									0.006,
									0.005,
									0.01,
									0.001
								],
								"e": 0.05
							},
							{
								"h": [
									0.029,
									0.014,
									0.004,
									0.005,
									0.062,
									0.059,
									0.087,
									1.0,
									0.033,
									0.09,
									0.057,
									0.009
								],
								"e": 0.088
							},
							{
								"h": [
									0.007,
									0.01,
									0.016,
									0.978,
									0.007,
									0.015,
									0.005,
									0.036,
									0.006,
									0.002,
									0.085,
									0.004
								],
								"e": 0.056
							},
							{
								"h": [
									0.003,
									0.005,
									0.016,
									0.019,
									0.062,
									0.066,
									0.009,
									0.046,
									0.029,
									0.027,
									0.004,
									0.062
								],
								"e": 0.027
							},
							{
								"h": [
									0.159,
									0.008,
									0.011,
									0.1,
									0.055,
									0.019,
									0.011,
									0.019,
									0.061,
									0.011,
									0.178,
									0.239
								],
								"e": 0.063
							}
						],
						[
							{
								"h": [
									0.286,
									1.0,
									0.105,
									1.0,
									1.0,
									0.404,
									0.398,
									0.21,
									0.205,
									0.039,
									0.977,
									0.044
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.231,
									1.0,
									0.136,
									0.39,
									0.031,
									0.164,
									0.093,
									0.181,
									0.076,
									1.0,
									1.0
								],
								"e": 0.881
							},
							{
								"h": [
									0.243,
									0.009,
									0.09,
									0.943,
									0.135,
									0.031,
									0.142,
									1.0,
									0.207,
									0.346,
									1.0,
									0.166
								],
								"e": 0.371
							},
							{
								"h": [
									0.31,
									0.177,
									0.359,
									0.047,
									0.004,
									0.057,
									1.0,
									0.283,
									0.074,
									0.337,
									0.326,
									0.525
								],
								"e": 0.399
							},
							{
								"h": [
									0.044,
									0.055,
									0.051,
									0.521,
									0.05,
									0.131,
									0.177,
									1.0,
									0.065,
									0.142,
									0.011,
									0.0
								],
								"e": 0.186
							},
							{
								"h": [
									0.006,
									0.0,
									0.035,
									0.331,
									0.053,
									0.003,
									0.044,
									0.04,
									0.014,
									0.032,
									0.135,
									0.056
								],
								"e": 0.069
							},
							{
								"h": [
									0.018,
									0.076,
									0.019,
									0.055,
									0.019,
									0.092,
									0.587,
									0.055,
									0.063,
									0.048,
									0.083,
									0.024
								],
								"e": 0.078
							},
							{
								"h": [
									0.015,
									0.03,
									0.024,
									0.053,
									0.002,
									0.004,
									0.033,
									0.031,
									0.006,
									0.023,
									0.048,
									0.004
								],
								"e": 0.021
							},
							{
								"h": [
									0.004,
									0.007,
									0.008,
									0.003,
									0.031,
									0.021,
									0.613,
									0.093,
									0.024,
									0.054,
									0.056,
									0.003
								],
								"e": 0.046
							},
							{
								"h": [
									0.211,
									0.002,
									0.103,
									0.307,
									0.012,
									0.04,
									0.013,
									0.194,
									0.01,
									0.009,
									0.018,
									0.005
								],
								"e": 0.059
							},
							{
								"h": [
									0.038,
									0.014,
									0.015,
									0.005,
									0.039,
									0.053,
									0.028,
									0.047,
									0.022,
									0.045,
									0.019,
									0.091
								],
								"e": 0.034
							},
							{
								"h": [
									0.371,
									0.026,
									0.012,
									0.077,
									0.019,
									0.008,
									0.058,
									0.02,
									0.469,
									0.014,
									0.069,
									0.036
								],
								"e": 0.071
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ":",
					"o": " Harry",
					"t": " Batman",
					"r": [
						{
							"p": " \"",
							"b": 0.0,
							"k": {
								" \"": 0.039,
								" '": 0.034,
								" The": 0.031,
								" http": 0.024,
								" ": 0.019,
								"19": 0.019,
								" -": 0.018,
								" A": 0.017,
								" [": 0.016
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.046,
								" -": 0.029,
								" http": 0.022,
								" A": 0.021,
								" Three": 0.017,
								" \"": 0.015,
								"19": 0.015,
								" Unknown": 0.015,
								" I": 0.014,
								" An": 0.014
							}
						},
						{
							"p": " Yeah",
							"b": 0.0,
							"k": {
								" Yeah": 0.054,
								" Rise": 0.048,
								" 1": 0.031,
								" Unknown": 0.028,
								" Yes": 0.025,
								" The": 0.022,
								" -": 0.019,
								" I": 0.018,
								" No": 0.018,
								" Oh": 0.018
							}
						},
						{
							"p": " Yeah",
							"b": 0.0,
							"k": {
								" Yeah": 0.13,
								" Yes": 0.081,
								" https": 0.051,
								" http": 0.047,
								" Oh": 0.026,
								" Unknown": 0.024,
								" Who": 0.023,
								" The": 0.021,
								" No": 0.018,
								" Rise": 0.013
							}
						},
						{
							"p": " Yes",
							"b": 0.0,
							"k": {
								" Yes": 0.129,
								" Yeah": 0.074,
								" Unknown": 0.041,
								" N": 0.033,
								" Dog": 0.032,
								" TBD": 0.026,
								" No": 0.023,
								" Who": 0.021,
								" https": 0.02,
								" Oh": 0.017
							}
						},
						{
							"p": " Yes",
							"b": 0.0,
							"k": {
								" Yes": 0.08,
								" ???": 0.072,
								" TBD": 0.057,
								" Oh": 0.036,
								" No": 0.031,
								" Yeah": 0.03,
								" Unknown": 0.025,
								" Meow": 0.025,
								" Nin": 0.02,
								" TBA": 0.02
							}
						},
						{
							"p": " Harry",
							"b": 0.0,
							"k": {
								" Harry": 0.14,
								" Oh": 0.137,
								" Luke": 0.06,
								" ???": 0.06,
								" Me": 0.034,
								" Yeah": 0.023,
								" Yes": 0.022,
								" Jim": 0.015,
								" James": 0.014,
								" Gr": 0.011
							}
						},
						{
							"p": " Night",
							"b": 0.001,
							"k": {
								" Night": 0.126,
								" Oh": 0.086,
								" Harry": 0.084,
								" Man": 0.051,
								" Me": 0.046,
								" Death": 0.029,
								" Nin": 0.02,
								" Sly": 0.02,
								" Yeah": 0.019,
								" Kid": 0.019
							}
						},
						{
							"p": " Harry",
							"b": 0.004,
							"k": {
								" Harry": 0.369,
								" Night": 0.05,
								" Me": 0.032,
								" Luke": 0.024,
								" Death": 0.02,
								" Oh": 0.016,
								" Man": 0.011,
								" Kid": 0.01,
								" Robin": 0.009,
								" Pol": 0.008
							}
						},
						{
							"p": " Harry",
							"b": 0.0,
							"k": {
								" Harry": 0.946,
								" Voldemort": 0.043,
								" Hermione": 0.005,
								" Draco": 0.002,
								" Dumbledore": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": " Harry",
							"b": 0.0,
							"k": {
								" Harry": 0.499,
								" Voldemort": 0.305,
								" Hermione": 0.069,
								" Draco": 0.047,
								" Dumbledore": 0.039,
								" Malfoy": 0.009,
								" Snape": 0.006,
								" Lucius": 0.004,
								" Death": 0.002,
								" Neville": 0.002
							}
						},
						{
							"p": " Harry",
							"b": 0.002,
							"k": {
								" Harry": 0.191,
								" Voldemort": 0.083,
								" Hermione": 0.039,
								" The": 0.035,
								" Draco": 0.025,
								" Dumbledore": 0.022,
								" Death": 0.014,
								" Ron": 0.011,
								" Lord": 0.009,
								" Lucius": 0.009
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.002,
									0.529,
									0.005,
									0.332,
									0.04,
									1.0,
									0.087,
									0.048,
									1.0,
									0.64,
									0.258
								],
								"e": 0.438
							},
							{
								"h": [
									0.027,
									0.12,
									0.405,
									1.0,
									1.0,
									0.787,
									1.0,
									1.0,
									1.0,
									1.0,
									0.017,
									0.461
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.01,
									0.181,
									0.74,
									0.094,
									0.483,
									0.32,
									0.683,
									0.048,
									0.815,
									1.0
								],
								"e": 0.738
							},
							{
								"h": [
									1.0,
									0.322,
									0.079,
									1.0,
									1.0,
									1.0,
									0.069,
									0.023,
									0.316,
									1.0,
									1.0,
									0.293
								],
								"e": 0.98
							},
							{
								"h": [
									1.0,
									0.344,
									1.0,
									0.097,
									1.0,
									1.0,
									1.0,
									0.145,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.988,
									0.682,
									1.0,
									1.0,
									1.0,
									0.647,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.847,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.494,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.188,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.521,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.514,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									0.559,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.054,
									0.89,
									0.324,
									0.003,
									0.101,
									1.0,
									0.028,
									0.02,
									0.645,
									0.813,
									1.0,
									0.068
								],
								"e": 0.571
							},
							{
								"h": [
									0.005,
									0.028,
									0.121,
									0.037,
									0.138,
									0.914,
									0.272,
									0.125,
									0.405,
									0.2,
									0.235,
									0.574
								],
								"e": 0.305
							},
							{
								"h": [
									0.039,
									0.048,
									0.0,
									0.002,
									0.01,
									0.002,
									0.032,
									0.026,
									0.011,
									0.0,
									0.12,
									0.01
								],
								"e": 0.027
							},
							{
								"h": [
									0.291,
									0.007,
									0.0,
									0.0,
									0.134,
									0.135,
									0.001,
									0.0,
									0.003,
									0.02,
									0.045,
									0.003
								],
								"e": 0.095
							},
							{
								"h": [
									0.0,
									0.001,
									0.126,
									0.001,
									0.202,
									0.001,
									0.025,
									0.052,
									0.189,
									0.01,
									0.054,
									0.0
								],
								"e": 0.059
							},
							{
								"h": [
									0.003,
									0.011,
									0.007,
									0.075,
									0.001,
									0.064,
									0.001,
									0.001,
									0.005,
									0.092,
									0.246,
									0.164
								],
								"e": 0.046
							},
							{
								"h": [
									0.011,
									0.008,
									0.053,
									0.017,
									0.081,
									0.012,
									0.069,
									0.004,
									0.001,
									0.021,
									0.005,
									0.002
								],
								"e": 0.021
							},
							{
								"h": [
									0.001,
									0.03,
									0.0,
									0.006,
									0.001,
									0.025,
									0.017,
									0.003,
									0.003,
									0.002,
									0.004,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.04,
									0.004,
									0.017,
									0.008,
									0.004,
									0.002,
									0.002,
									0.002,
									0.009,
									0.004,
									0.007,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.013,
									0.017,
									0.012,
									0.014,
									0.02,
									0.017,
									0.008,
									0.013,
									0.027,
									0.003,
									0.002,
									0.009
								],
								"e": 0.012
							},
							{
								"h": [
									0.022,
									0.007,
									0.021,
									0.018,
									0.027,
									0.007,
									0.009,
									0.044,
									0.031,
									0.006,
									0.008,
									0.01
								],
								"e": 0.016
							},
							{
								"h": [
									0.541,
									0.202,
									0.088,
									0.058,
									0.226,
									0.069,
									0.022,
									0.048,
									0.789,
									0.022,
									0.137,
									0.046
								],
								"e": 0.117
							}
						],
						[
							{
								"h": [
									0.252,
									0.0,
									0.16,
									0.001,
									0.073,
									0.008,
									0.297,
									0.042,
									0.022,
									0.652,
									0.257,
									0.398
								],
								"e": 0.19
							},
							{
								"h": [
									0.04,
									0.061,
									0.186,
									0.055,
									0.182,
									0.024,
									0.113,
									0.166,
									0.193,
									0.22,
									0.376,
									0.107
								],
								"e": 0.19
							},
							{
								"h": [
									0.159,
									0.051,
									0.0,
									0.014,
									0.011,
									0.002,
									0.015,
									0.116,
									0.003,
									0.005,
									0.264,
									0.02
								],
								"e": 0.055
							},
							{
								"h": [
									0.01,
									0.004,
									0.002,
									0.001,
									0.018,
									0.036,
									0.012,
									0.0,
									0.001,
									0.021,
									0.034,
									0.003
								],
								"e": 0.021
							},
							{
								"h": [
									0.001,
									0.0,
									0.01,
									0.001,
									0.064,
									0.003,
									0.015,
									0.017,
									0.043,
									0.004,
									0.017,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.166,
									1.0,
									0.011,
									0.017,
									0.001,
									1.0,
									0.001,
									0.0,
									0.331,
									0.084,
									0.026,
									0.063
								],
								"e": 0.218
							},
							{
								"h": [
									0.013,
									0.017,
									0.016,
									0.03,
									0.179,
									0.022,
									0.055,
									0.013,
									0.002,
									1.0,
									0.038,
									0.001
								],
								"e": 0.097
							},
							{
								"h": [
									0.002,
									0.104,
									0.023,
									0.042,
									0.002,
									0.013,
									0.183,
									0.063,
									0.063,
									0.025,
									0.068,
									0.103
								],
								"e": 0.051
							},
							{
								"h": [
									0.011,
									0.012,
									0.075,
									0.021,
									0.01,
									0.004,
									0.212,
									0.001,
									0.064,
									0.016,
									0.057,
									0.017
								],
								"e": 0.038
							},
							{
								"h": [
									0.407,
									0.038,
									0.086,
									0.013,
									0.032,
									0.025,
									0.145,
									0.055,
									0.364,
									0.04,
									0.002,
									0.024
								],
								"e": 0.077
							},
							{
								"h": [
									0.113,
									0.022,
									0.035,
									0.048,
									0.049,
									0.003,
									0.071,
									0.939,
									0.008,
									0.002,
									0.022,
									0.075
								],
								"e": 0.07
							},
							{
								"h": [
									0.977,
									0.348,
									0.083,
									0.041,
									0.204,
									0.023,
									0.029,
									0.027,
									0.238,
									0.022,
									0.755,
									0.058
								],
								"e": 0.139
							}
						],
						[
							{
								"h": [
									0.925,
									0.0,
									0.268,
									0.001,
									0.106,
									0.005,
									0.359,
									0.051,
									0.02,
									0.442,
									0.175,
									0.518
								],
								"e": 0.267
							},
							{
								"h": [
									0.055,
									0.046,
									0.285,
									0.06,
									0.193,
									0.058,
									0.075,
									0.192,
									0.176,
									0.135,
									0.457,
									0.077
								],
								"e": 0.19
							},
							{
								"h": [
									0.054,
									0.043,
									0.001,
									0.009,
									0.035,
									0.004,
									0.009,
									0.178,
									0.018,
									0.009,
									0.324,
									0.033
								],
								"e": 0.058
							},
							{
								"h": [
									0.002,
									0.008,
									0.002,
									0.003,
									0.026,
									0.028,
									0.009,
									0.0,
									0.003,
									0.012,
									0.034,
									0.011
								],
								"e": 0.02
							},
							{
								"h": [
									0.001,
									0.001,
									0.005,
									0.002,
									0.031,
									0.001,
									0.014,
									0.025,
									0.052,
									0.004,
									0.005,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.015,
									0.0,
									0.004,
									0.013,
									0.001,
									0.006,
									0.0,
									0.0,
									0.005,
									0.011,
									0.009,
									0.038
								],
								"e": 0.008
							},
							{
								"h": [
									0.017,
									0.011,
									0.042,
									0.012,
									0.19,
									0.025,
									0.013,
									0.035,
									0.001,
									0.001,
									0.055,
									0.002
								],
								"e": 0.029
							},
							{
								"h": [
									0.002,
									0.142,
									0.001,
									0.01,
									0.001,
									0.008,
									0.143,
									0.209,
									0.023,
									0.004,
									0.001,
									0.004
								],
								"e": 0.03
							},
							{
								"h": [
									0.011,
									0.003,
									0.052,
									0.009,
									0.005,
									0.003,
									0.586,
									0.002,
									0.089,
									0.02,
									0.024,
									0.022
								],
								"e": 0.056
							},
							{
								"h": [
									0.38,
									0.023,
									0.199,
									0.018,
									0.013,
									0.028,
									0.059,
									0.065,
									0.719,
									0.009,
									0.001,
									0.011
								],
								"e": 0.083
							},
							{
								"h": [
									0.258,
									0.016,
									0.023,
									0.032,
									0.095,
									0.002,
									0.049,
									0.331,
									0.006,
									0.002,
									0.028,
									0.094
								],
								"e": 0.057
							},
							{
								"h": [
									0.462,
									0.209,
									0.069,
									0.065,
									0.286,
									0.036,
									0.03,
									0.056,
									0.124,
									0.033,
									0.316,
									0.05
								],
								"e": 0.1
							}
						],
						[
							{
								"h": [
									0.092,
									0.005,
									0.941,
									0.003,
									0.054,
									0.008,
									0.019,
									0.078,
									0.332,
									0.751,
									0.428,
									0.296
								],
								"e": 0.266
							},
							{
								"h": [
									0.027,
									0.254,
									0.461,
									0.065,
									0.184,
									0.403,
									0.616,
									0.178,
									0.35,
									0.134,
									0.351,
									0.411
								],
								"e": 0.352
							},
							{
								"h": [
									0.116,
									0.18,
									0.0,
									0.008,
									0.086,
									0.041,
									0.393,
									0.119,
									0.06,
									0.003,
									0.217,
									0.026
								],
								"e": 0.132
							},
							{
								"h": [
									0.026,
									0.153,
									0.01,
									0.008,
									0.035,
									0.129,
									0.014,
									0.001,
									0.052,
									0.085,
									0.086,
									0.027
								],
								"e": 0.091
							},
							{
								"h": [
									0.003,
									0.057,
									0.185,
									0.012,
									0.103,
									0.02,
									0.046,
									0.118,
									0.395,
									0.035,
									0.033,
									0.0
								],
								"e": 0.089
							},
							{
								"h": [
									0.017,
									0.0,
									0.036,
									0.124,
									0.075,
									0.01,
									0.0,
									0.012,
									0.011,
									0.083,
									0.165,
									0.057
								],
								"e": 0.045
							},
							{
								"h": [
									0.032,
									0.029,
									0.151,
									0.03,
									0.188,
									0.034,
									0.039,
									0.047,
									0.003,
									0.008,
									0.157,
									0.014
								],
								"e": 0.057
							},
							{
								"h": [
									0.011,
									0.325,
									0.0,
									0.024,
									0.002,
									0.083,
									0.091,
									1.0,
									0.023,
									0.016,
									0.003,
									0.006
								],
								"e": 0.082
							},
							{
								"h": [
									0.058,
									0.002,
									0.044,
									0.062,
									0.014,
									0.011,
									0.012,
									0.013,
									0.063,
									0.031,
									0.04,
									0.012
								],
								"e": 0.03
							},
							{
								"h": [
									0.017,
									0.014,
									0.021,
									0.034,
									0.047,
									0.008,
									0.002,
									0.023,
									0.029,
									0.002,
									0.012,
									0.012
								],
								"e": 0.017
							},
							{
								"h": [
									0.047,
									0.004,
									0.01,
									0.025,
									0.049,
									0.013,
									0.006,
									0.069,
									0.018,
									0.017,
									0.004,
									0.006
								],
								"e": 0.019
							},
							{
								"h": [
									0.526,
									0.153,
									0.14,
									0.1,
									0.258,
									0.074,
									0.022,
									0.061,
									0.668,
									0.013,
									0.14,
									0.033
								],
								"e": 0.121
							}
						],
						[
							{
								"h": [
									0.398,
									0.001,
									0.075,
									0.004,
									0.107,
									0.006,
									0.437,
									0.111,
									0.012,
									0.472,
									0.177,
									0.095
								],
								"e": 0.169
							},
							{
								"h": [
									0.068,
									0.084,
									0.495,
									0.075,
									0.29,
									0.022,
									0.08,
									0.083,
									0.239,
									0.155,
									0.536,
									0.032
								],
								"e": 0.219
							},
							{
								"h": [
									0.186,
									0.039,
									0.002,
									0.091,
									0.047,
									0.007,
									0.037,
									0.184,
									0.027,
									0.033,
									0.315,
									0.033
								],
								"e": 0.092
							},
							{
								"h": [
									0.0,
									0.017,
									0.023,
									0.009,
									0.01,
									0.051,
									0.082,
									0.007,
									0.009,
									0.024,
									0.027,
									0.028
								],
								"e": 0.046
							},
							{
								"h": [
									0.008,
									0.009,
									0.004,
									0.036,
									0.003,
									0.012,
									0.047,
									0.052,
									0.043,
									0.016,
									0.003,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.004,
									0.002,
									0.007,
									0.051,
									0.041,
									0.028,
									0.001,
									0.03,
									0.021,
									0.14,
									0.09,
									0.015
								],
								"e": 0.034
							},
							{
								"h": [
									0.034,
									0.007,
									0.01,
									0.027,
									0.019,
									0.046,
									0.052,
									0.088,
									0.005,
									0.005,
									0.012,
									0.016
								],
								"e": 0.026
							},
							{
								"h": [
									0.031,
									0.05,
									0.001,
									0.02,
									0.007,
									0.036,
									0.03,
									0.012,
									0.101,
									0.03,
									0.005,
									0.004
								],
								"e": 0.023
							},
							{
								"h": [
									0.006,
									0.001,
									0.024,
									0.006,
									0.015,
									0.013,
									0.005,
									0.011,
									0.037,
									0.013,
									0.021,
									0.006
								],
								"e": 0.013
							},
							{
								"h": [
									0.337,
									0.009,
									0.12,
									0.06,
									0.005,
									0.013,
									0.032,
									0.009,
									0.072,
									0.007,
									0.007,
									0.014
								],
								"e": 0.04
							},
							{
								"h": [
									0.034,
									0.008,
									0.008,
									0.009,
									0.026,
									0.006,
									0.044,
									0.021,
									0.001,
									0.003,
									0.012,
									0.039
								],
								"e": 0.016
							},
							{
								"h": [
									0.16,
									0.13,
									0.072,
									0.026,
									0.069,
									0.015,
									0.023,
									0.036,
									0.117,
									0.029,
									0.031,
									0.021
								],
								"e": 0.047
							}
						],
						[
							{
								"h": [
									0.334,
									0.0,
									0.202,
									0.002,
									0.048,
									0.005,
									0.112,
									0.018,
									0.022,
									0.471,
									0.12,
									0.099
								],
								"e": 0.118
							},
							{
								"h": [
									0.077,
									0.052,
									0.331,
									0.062,
									0.2,
									0.081,
									0.204,
									0.152,
									0.178,
									0.096,
									0.446,
									0.146
								],
								"e": 0.21
							},
							{
								"h": [
									0.073,
									0.016,
									0.001,
									0.012,
									0.062,
									0.009,
									0.017,
									0.169,
									0.059,
									0.034,
									0.293,
									0.038
								],
								"e": 0.068
							},
							{
								"h": [
									0.0,
									0.007,
									0.005,
									0.013,
									0.012,
									0.035,
									0.03,
									0.031,
									0.013,
									0.017,
									0.036,
									0.064
								],
								"e": 0.043
							},
							{
								"h": [
									0.002,
									0.008,
									0.005,
									0.04,
									0.04,
									0.005,
									0.029,
									0.069,
									0.044,
									0.011,
									0.005,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									0.001,
									0.0,
									0.011,
									0.026,
									0.011,
									0.0,
									0.001,
									0.007,
									0.0,
									0.03,
									0.057,
									0.006
								],
								"e": 0.011
							},
							{
								"h": [
									0.092,
									0.008,
									0.007,
									0.028,
									0.023,
									0.052,
									0.063,
									0.062,
									0.065,
									0.0,
									0.023,
									0.019
								],
								"e": 0.035
							},
							{
								"h": [
									0.043,
									0.016,
									0.0,
									0.024,
									0.004,
									0.036,
									0.027,
									0.005,
									0.151,
									0.007,
									0.0,
									0.001
								],
								"e": 0.021
							},
							{
								"h": [
									0.005,
									0.001,
									0.024,
									0.003,
									0.036,
									0.017,
									0.038,
									0.079,
									0.022,
									0.011,
									0.011,
									0.003
								],
								"e": 0.019
							},
							{
								"h": [
									0.155,
									0.002,
									0.167,
									0.143,
									0.003,
									0.018,
									0.005,
									0.015,
									0.078,
									0.001,
									0.07,
									0.008
								],
								"e": 0.04
							},
							{
								"h": [
									0.022,
									0.006,
									0.004,
									0.006,
									0.04,
									0.013,
									0.014,
									0.015,
									0.001,
									0.005,
									0.005,
									0.054
								],
								"e": 0.014
							},
							{
								"h": [
									0.477,
									0.12,
									0.013,
									0.039,
									0.053,
									0.014,
									0.005,
									0.035,
									0.196,
									0.015,
									0.029,
									0.026
								],
								"e": 0.049
							}
						],
						[
							{
								"h": [
									0.035,
									0.977,
									0.271,
									0.078,
									0.299,
									0.599,
									0.018,
									0.116,
									0.852,
									0.697,
									0.79,
									0.053
								],
								"e": 0.56
							},
							{
								"h": [
									0.027,
									0.165,
									0.561,
									0.082,
									0.229,
									1.0,
									0.23,
									0.14,
									0.342,
									0.141,
									0.46,
									0.833
								],
								"e": 0.409
							},
							{
								"h": [
									0.121,
									0.116,
									0.0,
									0.026,
									0.033,
									0.019,
									0.159,
									0.412,
									0.096,
									0.003,
									0.328,
									0.041
								],
								"e": 0.122
							},
							{
								"h": [
									0.517,
									0.02,
									0.005,
									0.007,
									0.297,
									0.054,
									0.007,
									0.0,
									0.016,
									0.04,
									0.117,
									0.013
								],
								"e": 0.158
							},
							{
								"h": [
									0.004,
									0.008,
									0.152,
									0.006,
									0.22,
									0.007,
									0.055,
									0.167,
									0.199,
									0.011,
									0.053,
									0.0
								],
								"e": 0.077
							},
							{
								"h": [
									0.025,
									0.0,
									0.033,
									0.097,
									0.002,
									0.001,
									0.0,
									0.004,
									0.001,
									0.182,
									0.208,
									0.164
								],
								"e": 0.051
							},
							{
								"h": [
									0.02,
									0.002,
									0.047,
									0.026,
									0.071,
									0.026,
									0.193,
									0.006,
									0.002,
									0.0,
									0.024,
									0.006
								],
								"e": 0.031
							},
							{
								"h": [
									0.003,
									0.008,
									0.0,
									0.019,
									0.001,
									0.052,
									0.016,
									0.005,
									0.008,
									0.005,
									0.0,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.147,
									0.003,
									0.007,
									0.004,
									0.007,
									0.004,
									0.002,
									0.017,
									0.005,
									0.016,
									0.017,
									0.001
								],
								"e": 0.017
							},
							{
								"h": [
									0.006,
									0.012,
									0.005,
									0.096,
									0.003,
									0.028,
									0.001,
									0.028,
									0.005,
									0.0,
									0.007,
									0.007
								],
								"e": 0.014
							},
							{
								"h": [
									0.013,
									0.003,
									0.007,
									0.009,
									0.01,
									0.011,
									0.002,
									0.071,
									0.016,
									0.01,
									0.003,
									0.01
								],
								"e": 0.011
							},
							{
								"h": [
									0.348,
									0.383,
									0.04,
									0.018,
									0.221,
									0.043,
									0.015,
									0.064,
									1.0,
									0.009,
									0.114,
									0.033
								],
								"e": 0.112
							}
						],
						[
							{
								"h": [
									0.829,
									0.002,
									0.241,
									0.028,
									1.0,
									0.0,
									0.88,
									0.147,
									0.016,
									0.553,
									0.234,
									1.0
								],
								"e": 0.497
							},
							{
								"h": [
									0.247,
									0.409,
									0.674,
									0.113,
									0.399,
									0.095,
									0.234,
									0.398,
									0.287,
									0.193,
									0.888,
									0.191
								],
								"e": 0.456
							},
							{
								"h": [
									0.555,
									0.17,
									0.149,
									0.471,
									0.325,
									0.071,
									0.094,
									0.615,
									0.542,
									0.327,
									0.975,
									0.099
								],
								"e": 0.459
							},
							{
								"h": [
									0.01,
									0.03,
									0.125,
									0.303,
									0.078,
									0.07,
									0.323,
									0.069,
									0.059,
									0.489,
									0.168,
									0.174
								],
								"e": 0.268
							},
							{
								"h": [
									0.038,
									0.004,
									0.014,
									0.07,
									0.111,
									0.02,
									0.102,
									0.22,
									0.042,
									0.027,
									0.031,
									0.0
								],
								"e": 0.067
							},
							{
								"h": [
									1.0,
									0.122,
									0.04,
									0.047,
									0.013,
									0.4,
									0.005,
									0.001,
									0.602,
									0.071,
									0.042,
									0.109
								],
								"e": 0.185
							},
							{
								"h": [
									0.137,
									0.055,
									0.032,
									0.054,
									0.232,
									0.102,
									0.125,
									0.213,
									0.049,
									0.199,
									0.26,
									0.029
								],
								"e": 0.112
							},
							{
								"h": [
									0.053,
									0.039,
									0.014,
									0.121,
									0.012,
									0.056,
									0.506,
									0.889,
									0.552,
									0.12,
									0.014,
									0.301
								],
								"e": 0.164
							},
							{
								"h": [
									0.069,
									0.042,
									0.071,
									0.035,
									0.214,
									0.022,
									1.0,
									0.043,
									0.412,
									0.098,
									0.401,
									0.093
								],
								"e": 0.179
							},
							{
								"h": [
									0.226,
									0.087,
									0.254,
									0.168,
									0.04,
									0.041,
									0.094,
									0.35,
									1.0,
									0.015,
									0.033,
									0.019
								],
								"e": 0.139
							},
							{
								"h": [
									0.204,
									0.022,
									0.022,
									0.067,
									0.156,
									0.008,
									0.14,
									1.0,
									0.013,
									0.013,
									0.046,
									0.196
								],
								"e": 0.105
							},
							{
								"h": [
									0.528,
									0.367,
									0.106,
									0.097,
									0.522,
									0.065,
									0.066,
									0.068,
									0.061,
									0.092,
									1.0,
									0.178
								],
								"e": 0.196
							}
						],
						[
							{
								"h": [
									0.099,
									0.004,
									1.0,
									0.082,
									0.212,
									0.005,
									0.021,
									0.561,
									0.394,
									0.776,
									0.361,
									0.328
								],
								"e": 0.392
							},
							{
								"h": [
									0.155,
									1.0,
									0.795,
									0.11,
									0.295,
									0.408,
									0.678,
									0.194,
									0.355,
									0.118,
									0.661,
									0.506
								],
								"e": 0.568
							},
							{
								"h": [
									0.456,
									0.251,
									0.027,
									0.15,
									1.0,
									1.0,
									1.0,
									0.366,
									0.804,
									0.073,
									0.548,
									0.084
								],
								"e": 0.647
							},
							{
								"h": [
									0.032,
									1.0,
									0.364,
									0.406,
									0.067,
									0.193,
									0.179,
									0.031,
									1.0,
									0.569,
									0.315,
									0.237
								],
								"e": 0.636
							},
							{
								"h": [
									0.052,
									1.0,
									0.256,
									0.084,
									0.101,
									0.129,
									0.252,
									0.567,
									0.417,
									0.124,
									0.032,
									0.0
								],
								"e": 0.309
							},
							{
								"h": [
									0.069,
									0.0,
									0.196,
									0.222,
									0.256,
									0.003,
									0.001,
									0.071,
									0.052,
									0.118,
									0.189,
									0.103
								],
								"e": 0.108
							},
							{
								"h": [
									0.137,
									0.031,
									0.103,
									0.094,
									0.348,
									0.109,
									0.129,
									0.1,
									0.031,
									0.004,
									0.421,
									0.043
								],
								"e": 0.115
							},
							{
								"h": [
									0.063,
									0.102,
									0.0,
									0.084,
									0.006,
									0.052,
									0.078,
									0.421,
									0.074,
									0.04,
									0.001,
									0.001
								],
								"e": 0.054
							},
							{
								"h": [
									0.083,
									0.001,
									0.02,
									0.038,
									0.126,
									0.012,
									0.003,
									0.06,
									0.059,
									0.053,
									0.214,
									0.007
								],
								"e": 0.051
							},
							{
								"h": [
									0.004,
									0.011,
									0.03,
									0.091,
									0.019,
									0.007,
									0.001,
									0.034,
									0.02,
									0.001,
									0.047,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.036,
									0.002,
									0.005,
									0.034,
									0.049,
									0.024,
									0.004,
									0.042,
									0.007,
									0.052,
									0.004,
									0.005
								],
								"e": 0.02
							},
							{
								"h": [
									0.196,
									0.113,
									0.057,
									0.038,
									0.129,
									0.046,
									0.012,
									0.03,
									0.279,
									0.01,
									0.083,
									0.023
								],
								"e": 0.058
							}
						],
						[
							{
								"h": [
									0.584,
									0.0,
									0.115,
									0.032,
									0.507,
									0.006,
									0.688,
									1.0,
									0.011,
									0.389,
									0.173,
									0.08
								],
								"e": 0.389
							},
							{
								"h": [
									1.0,
									0.252,
									1.0,
									0.12,
									0.428,
									0.07,
									0.206,
									0.127,
									0.318,
									0.165,
									1.0,
									0.052
								],
								"e": 0.581
							},
							{
								"h": [
									0.479,
									0.021,
									1.0,
									1.0,
									0.826,
									0.2,
									0.131,
									1.0,
									0.946,
									1.0,
									1.0,
									0.139
								],
								"e": 1.0
							},
							{
								"h": [
									0.0,
									0.319,
									1.0,
									0.499,
									0.049,
									0.061,
									1.0,
									1.0,
									0.106,
									0.609,
									0.168,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.051,
									0.099,
									0.046,
									1.0,
									0.108,
									0.087,
									0.263,
									0.385,
									0.119,
									0.269,
									0.015,
									1.0
								],
								"e": 0.49
							},
							{
								"h": [
									0.006,
									0.001,
									0.151,
									0.214,
									0.185,
									0.015,
									0.241,
									0.162,
									0.089,
									0.154,
									0.409,
									0.024
								],
								"e": 0.146
							},
							{
								"h": [
									0.696,
									0.019,
									0.022,
									0.095,
									0.104,
									0.188,
									0.178,
									0.041,
									0.46,
									0.006,
									0.045,
									0.237
								],
								"e": 0.164
							},
							{
								"h": [
									0.402,
									0.098,
									0.001,
									0.027,
									0.01,
									0.138,
									0.037,
									0.017,
									0.174,
									0.027,
									0.007,
									0.003
								],
								"e": 0.07
							},
							{
								"h": [
									0.008,
									0.001,
									0.036,
									0.008,
									0.285,
									0.021,
									0.002,
									0.602,
									0.024,
									0.005,
									0.02,
									0.003
								],
								"e": 0.072
							},
							{
								"h": [
									0.062,
									0.006,
									0.204,
									0.271,
									0.004,
									0.026,
									0.012,
									0.025,
									0.071,
									0.004,
									0.127,
									0.006
								],
								"e": 0.053
							},
							{
								"h": [
									0.022,
									0.008,
									0.005,
									0.006,
									0.038,
									0.041,
									0.023,
									0.005,
									0.001,
									0.045,
									0.004,
									0.04
								],
								"e": 0.02
							},
							{
								"h": [
									0.273,
									0.081,
									0.02,
									0.013,
									0.027,
									0.009,
									0.012,
									0.021,
									0.127,
									0.024,
									0.044,
									0.014
								],
								"e": 0.034
							}
						],
						[
							{
								"h": [
									0.039,
									1.0,
									0.284,
									1.0,
									0.976,
									0.492,
									0.02,
									0.562,
									1.0,
									0.745,
									0.747,
									0.055
								],
								"e": 1.0
							},
							{
								"h": [
									0.113,
									0.533,
									0.853,
									0.118,
									0.33,
									0.957,
									0.224,
									0.161,
									0.349,
									0.119,
									0.736,
									1.0
								],
								"e": 0.549
							},
							{
								"h": [
									0.478,
									0.129,
									0.065,
									0.308,
									0.43,
									0.298,
									0.355,
									0.76,
									1.0,
									0.047,
									0.707,
									0.108
								],
								"e": 0.459
							},
							{
								"h": [
									0.291,
									0.195,
									0.205,
									0.645,
									0.46,
									0.078,
									0.083,
									0.029,
									0.542,
									0.27,
									0.455,
									0.164
								],
								"e": 0.476
							},
							{
								"h": [
									0.097,
									0.153,
									0.446,
									0.097,
									0.456,
									0.133,
									0.28,
									1.0,
									0.211,
									0.122,
									0.056,
									0.0
								],
								"e": 0.285
							},
							{
								"h": [
									0.014,
									0.0,
									0.112,
									0.37,
									0.016,
									0.044,
									0.041,
									0.019,
									0.005,
									0.167,
									0.53,
									0.447
								],
								"e": 0.128
							},
							{
								"h": [
									0.137,
									0.009,
									0.047,
									0.05,
									0.202,
									0.102,
									0.282,
									0.026,
									0.037,
									0.001,
									0.03,
									0.036
								],
								"e": 0.069
							},
							{
								"h": [
									0.023,
									0.023,
									0.0,
									0.091,
									0.002,
									0.04,
									0.047,
									0.012,
									0.021,
									0.026,
									0.001,
									0.001
								],
								"e": 0.022
							},
							{
								"h": [
									0.311,
									0.001,
									0.005,
									0.013,
									0.04,
									0.014,
									0.004,
									0.067,
									0.016,
									0.033,
									0.053,
									0.012
								],
								"e": 0.043
							},
							{
								"h": [
									0.006,
									0.017,
									0.018,
									0.573,
									0.007,
									0.053,
									0.001,
									0.064,
									0.025,
									0.001,
									0.024,
									0.002
								],
								"e": 0.048
							},
							{
								"h": [
									0.035,
									0.004,
									0.006,
									0.008,
									0.012,
									0.023,
									0.003,
									0.045,
									0.008,
									0.032,
									0.004,
									0.011
								],
								"e": 0.014
							},
							{
								"h": [
									0.365,
									0.317,
									0.04,
									0.03,
									0.143,
									0.05,
									0.02,
									0.053,
									0.826,
									0.008,
									0.13,
									0.04
								],
								"e": 0.102
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Batman",
					"o": ".",
					"t": ".",
					"r": [
						{
							"p": " Batman",
							"b": 0.0,
							"k": {
								" Batman": 0.91,
								"Batman": 0.02,
								" Comics": 0.017,
								" Begins": 0.007,
								" Arkham": 0.005,
								" Superman": 0.004,
								" villain": 0.002,
								" Avatar": 0.002,
								" Dark": 0.001,
								" comic": 0.001
							}
						},
						{
							"p": " Batman",
							"b": 0.0,
							"k": {
								" Batman": 0.708,
								" Begins": 0.063,
								"Batman": 0.025,
								" Comics": 0.016,
								" Arkham": 0.015,
								" Superman": 0.01,
								"'s": 0.007,
								" Hood": 0.006,
								" Knight": 0.004,
								" Lara": 0.004
							}
						},
						{
							"p": " Batman",
							"b": 0.0,
							"k": {
								" Batman": 0.655,
								" Begins": 0.083,
								" Arkham": 0.054,
								"Batman": 0.046,
								"'s": 0.021,
								" Comics": 0.015,
								" Superman": 0.009,
								" Knight": 0.007,
								" Hood": 0.005,
								" Bat": 0.004
							}
						},
						{
							"p": " Batman",
							"b": 0.0,
							"k": {
								" Batman": 0.504,
								" Begins": 0.213,
								"'s": 0.034,
								"Batman": 0.031,
								" Arkham": 0.029,
								" Comics": 0.027,
								" Dark": 0.009,
								" Jones": 0.009,
								" Knight": 0.009,
								" Superman": 0.008
							}
						},
						{
							"p": " Begins",
							"b": 0.0,
							"k": {
								" Begins": 0.351,
								" Batman": 0.295,
								"'s": 0.077,
								" Comics": 0.025,
								" Dark": 0.024,
								" Jones": 0.02,
								" Arkham": 0.011,
								" Knight": 0.009,
								"\u00e2\u0126\u00a2": 0.008,
								" Bros": 0.008
							}
						},
						{
							"p": " Begins",
							"b": 0.007,
							"k": {
								" Begins": 0.388,
								"'s": 0.235,
								" Batman": 0.12,
								",": 0.04,
								" Comics": 0.036,
								" Jones": 0.012,
								" Hood": 0.011,
								" Dark": 0.009,
								"\u00e2\u0126\u00a2": 0.009,
								".": 0.007
							}
						},
						{
							"p": " Begins",
							"b": 0.014,
							"k": {
								" Begins": 0.61,
								"'s": 0.128,
								",": 0.052,
								"/": 0.032,
								" Comics": 0.027,
								".": 0.014,
								" Batman": 0.014,
								" Jones": 0.012,
								" and": 0.008,
								" &": 0.006
							}
						},
						{
							"p": " Begins",
							"b": 0.119,
							"k": {
								" Begins": 0.552,
								"'s": 0.133,
								".": 0.119,
								",": 0.053,
								"/": 0.034,
								" Batman": 0.015,
								" Comics": 0.009,
								";": 0.008,
								" and": 0.005,
								" Jr": 0.005
							}
						},
						{
							"p": ".",
							"b": 0.957,
							"k": {
								".": 0.957,
								",": 0.008,
								"'s": 0.007,
								" Begins": 0.006,
								"!": 0.004,
								";": 0.004,
								"/": 0.002,
								"?": 0.002,
								".\"": 0.001,
								" Batman": 0.001
							}
						},
						{
							"p": ".",
							"b": 0.997,
							"k": {
								".": 0.997,
								"!": 0.001,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.985,
							"k": {
								".": 0.985,
								"!": 0.009,
								",": 0.001,
								"?": 0.001,
								".\"": 0.001,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.878,
							"k": {
								".": 0.878,
								"!": 0.025,
								",": 0.016,
								" (": 0.007,
								"/": 0.006,
								"?": 0.006,
								" or": 0.006,
								" and": 0.004,
								".\"": 0.004,
								" ": 0.003
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.78,
									0.001,
									1.0,
									0.005,
									0.025,
									0.005,
									1.0,
									0.203,
									0.83,
									1.0,
									0.599,
									0.19
								],
								"e": 0.239
							},
							{
								"h": [
									0.113,
									0.057,
									0.414,
									1.0,
									1.0,
									0.915,
									1.0,
									1.0,
									1.0,
									1.0,
									0.015,
									0.04
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.141,
									0.307,
									1.0,
									0.088,
									0.771,
									0.679,
									0.228,
									0.201,
									0.488,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.25,
									1.0,
									1.0,
									1.0,
									0.788,
									0.487,
									0.289,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.133,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.463,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.234,
									1.0,
									1.0,
									0.51,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.921,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.351,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.738
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									1.0,
									0.0,
									0.27,
									0.0,
									0.002,
									0.0,
									0.549,
									0.067,
									0.958,
									0.964,
									0.382,
									0.174
								],
								"e": 0.152
							},
							{
								"h": [
									0.031,
									0.029,
									0.149,
									0.036,
									0.142,
									0.261,
									0.378,
									0.159,
									0.432,
									0.148,
									0.258,
									0.018
								],
								"e": 0.223
							},
							{
								"h": [
									0.026,
									0.044,
									0.0,
									0.009,
									0.022,
									0.005,
									0.072,
									0.061,
									0.022,
									0.002,
									0.116,
									0.012
								],
								"e": 0.043
							},
							{
								"h": [
									0.008,
									0.034,
									0.001,
									0.002,
									0.037,
									0.028,
									0.007,
									0.001,
									0.001,
									0.009,
									0.011,
									0.006
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.003,
									0.137,
									0.003,
									0.155,
									0.005,
									0.026,
									0.01,
									0.057,
									0.022,
									0.082,
									0.0
								],
								"e": 0.055
							},
							{
								"h": [
									0.002,
									0.001,
									0.042,
									0.064,
									0.007,
									0.012,
									0.015,
									0.001,
									0.047,
									0.032,
									0.18,
									0.189
								],
								"e": 0.04
							},
							{
								"h": [
									0.028,
									0.014,
									0.072,
									0.036,
									0.005,
									0.014,
									0.024,
									0.02,
									0.004,
									0.004,
									0.006,
									0.01
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.022,
									0.009,
									0.003,
									0.01,
									0.037,
									0.004,
									0.019,
									0.0,
									0.003,
									0.012,
									0.005
								],
								"e": 0.01
							},
							{
								"h": [
									0.009,
									0.052,
									0.012,
									0.012,
									0.002,
									0.006,
									0.026,
									0.002,
									0.012,
									0.005,
									0.009,
									0.002
								],
								"e": 0.011
							},
							{
								"h": [
									0.007,
									0.016,
									0.003,
									0.013,
									0.049,
									0.018,
									0.009,
									0.013,
									0.005,
									0.008,
									0.017,
									0.036
								],
								"e": 0.015
							},
							{
								"h": [
									0.008,
									0.019,
									0.044,
									0.07,
									0.149,
									0.015,
									0.007,
									0.09,
									0.12,
									0.001,
									0.03,
									0.025
								],
								"e": 0.042
							},
							{
								"h": [
									0.373,
									0.04,
									0.082,
									0.032,
									0.119,
									0.04,
									0.029,
									0.053,
									0.17,
									0.047,
									0.084,
									0.478
								],
								"e": 0.094
							}
						],
						[
							{
								"h": [
									0.476,
									0.001,
									0.278,
									0.0,
									0.01,
									0.0,
									0.334,
									0.026,
									1.0,
									0.501,
									0.305,
									0.678
								],
								"e": 0.138
							},
							{
								"h": [
									0.083,
									0.027,
									0.193,
									0.049,
									0.156,
									0.551,
									0.025,
									0.218,
									0.221,
									0.196,
									0.364,
									0.018
								],
								"e": 0.213
							},
							{
								"h": [
									0.056,
									0.011,
									0.0,
									0.025,
									0.004,
									0.001,
									0.022,
									0.183,
									0.001,
									0.019,
									0.193,
									0.021
								],
								"e": 0.046
							},
							{
								"h": [
									0.004,
									0.004,
									0.0,
									0.0,
									0.07,
									0.017,
									0.015,
									0.001,
									0.0,
									0.006,
									0.02,
									0.006
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.0,
									0.011,
									0.003,
									0.04,
									0.002,
									0.012,
									0.136,
									0.047,
									0.005,
									0.071,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.002,
									0.001,
									0.003,
									0.016,
									0.002,
									0.026,
									0.006,
									0.0,
									0.009,
									0.066,
									0.086,
									0.186
								],
								"e": 0.029
							},
							{
								"h": [
									0.006,
									0.001,
									0.026,
									0.021,
									0.004,
									0.008,
									0.022,
									0.012,
									0.001,
									0.007,
									0.009,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.015,
									0.008,
									0.001,
									0.003,
									0.013,
									0.012,
									0.047,
									0.001,
									0.0,
									0.014,
									0.014
								],
								"e": 0.009
							},
							{
								"h": [
									0.006,
									0.021,
									0.005,
									0.007,
									0.0,
									0.004,
									0.025,
									0.0,
									0.005,
									0.004,
									0.009,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.009,
									0.004,
									0.008,
									0.004,
									0.014,
									0.012,
									0.006,
									0.009,
									0.01,
									0.004,
									0.003,
									0.005
								],
								"e": 0.007
							},
							{
								"h": [
									0.019,
									0.01,
									0.007,
									0.031,
									0.005,
									0.008,
									0.014,
									0.015,
									0.012,
									0.001,
									0.017,
									0.01
								],
								"e": 0.012
							},
							{
								"h": [
									0.147,
									0.11,
									0.038,
									0.024,
									0.033,
									0.008,
									0.017,
									0.008,
									0.445,
									0.014,
									0.021,
									0.037
								],
								"e": 0.049
							}
						],
						[
							{
								"h": [
									0.301,
									0.0,
									0.275,
									0.0,
									0.004,
									0.0,
									0.634,
									0.024,
									0.552,
									0.829,
									0.165,
									0.811
								],
								"e": 0.139
							},
							{
								"h": [
									0.045,
									0.014,
									0.319,
									0.056,
									0.186,
									1.0,
									0.107,
									0.263,
									0.246,
									0.219,
									0.444,
									0.014
								],
								"e": 0.287
							},
							{
								"h": [
									0.015,
									0.022,
									0.0,
									0.01,
									0.003,
									0.002,
									0.021,
									0.322,
									0.007,
									0.025,
									0.309,
									0.033
								],
								"e": 0.061
							},
							{
								"h": [
									0.003,
									0.007,
									0.001,
									0.0,
									0.154,
									0.023,
									0.005,
									0.002,
									0.001,
									0.003,
									0.042,
									0.008
								],
								"e": 0.027
							},
							{
								"h": [
									0.001,
									0.001,
									0.009,
									0.003,
									0.038,
									0.001,
									0.03,
									0.025,
									0.159,
									0.009,
									0.045,
									0.0
								],
								"e": 0.032
							},
							{
								"h": [
									0.054,
									0.006,
									0.005,
									0.006,
									0.002,
									0.014,
									0.002,
									0.002,
									0.061,
									0.023,
									0.05,
									0.183
								],
								"e": 0.029
							},
							{
								"h": [
									0.017,
									0.004,
									0.038,
									0.013,
									0.012,
									0.014,
									0.028,
									0.021,
									0.001,
									0.012,
									0.022,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.1,
									0.031,
									0.001,
									0.001,
									0.019,
									0.029,
									0.062,
									0.001,
									0.001,
									0.015,
									0.174
								],
								"e": 0.03
							},
							{
								"h": [
									0.013,
									0.096,
									0.007,
									0.002,
									0.0,
									0.004,
									0.169,
									0.0,
									0.01,
									0.007,
									0.013,
									0.003
								],
								"e": 0.024
							},
							{
								"h": [
									0.018,
									0.007,
									0.011,
									0.003,
									0.01,
									0.03,
									0.031,
									0.026,
									0.018,
									0.009,
									0.003,
									0.012
								],
								"e": 0.016
							},
							{
								"h": [
									0.03,
									0.031,
									0.028,
									0.05,
									0.012,
									0.008,
									0.019,
									0.041,
									0.025,
									0.001,
									0.056,
									0.054
								],
								"e": 0.031
							},
							{
								"h": [
									0.138,
									0.088,
									0.131,
									0.03,
									0.147,
									0.026,
									0.051,
									0.037,
									0.692,
									0.052,
									0.059,
									0.053
								],
								"e": 0.091
							}
						],
						[
							{
								"h": [
									0.441,
									0.0,
									0.163,
									0.0,
									0.002,
									0.0,
									0.171,
									0.141,
									0.523,
									0.591,
									0.245,
									0.315
								],
								"e": 0.097
							},
							{
								"h": [
									0.132,
									0.16,
									0.485,
									0.059,
									0.162,
									0.256,
									0.241,
									0.242,
									0.426,
									0.163,
									0.353,
									0.033
								],
								"e": 0.283
							},
							{
								"h": [
									0.068,
									0.086,
									0.002,
									0.017,
									0.059,
									0.019,
									0.439,
									0.193,
									0.033,
									0.015,
									0.164,
									0.026
								],
								"e": 0.12
							},
							{
								"h": [
									0.008,
									0.107,
									0.003,
									0.002,
									0.045,
									0.037,
									0.018,
									0.003,
									0.015,
									0.016,
									0.037,
									0.03
								],
								"e": 0.028
							},
							{
								"h": [
									0.004,
									0.084,
									0.159,
									0.007,
									0.09,
									0.029,
									0.028,
									0.092,
									0.147,
									0.023,
									0.047,
									0.0
								],
								"e": 0.07
							},
							{
								"h": [
									0.573,
									0.021,
									0.027,
									0.106,
									0.09,
									0.044,
									0.004,
									0.009,
									0.301,
									0.061,
									0.254,
									0.244
								],
								"e": 0.133
							},
							{
								"h": [
									0.026,
									0.068,
									0.053,
									0.052,
									0.02,
									0.038,
									0.042,
									0.029,
									0.003,
									0.177,
									0.103,
									0.041
								],
								"e": 0.058
							},
							{
								"h": [
									0.003,
									0.068,
									0.18,
									0.008,
									0.007,
									0.119,
									0.026,
									0.664,
									0.001,
									0.011,
									0.041,
									0.443
								],
								"e": 0.095
							},
							{
								"h": [
									0.052,
									0.817,
									0.016,
									0.022,
									0.006,
									0.024,
									0.126,
									0.008,
									0.026,
									0.009,
									0.032,
									0.011
								],
								"e": 0.072
							},
							{
								"h": [
									0.004,
									0.035,
									0.005,
									0.026,
									0.052,
									0.053,
									0.025,
									0.03,
									0.003,
									0.014,
									0.013,
									0.382
								],
								"e": 0.046
							},
							{
								"h": [
									0.003,
									0.009,
									0.081,
									0.16,
									0.772,
									0.027,
									0.002,
									0.077,
									0.089,
									0.002,
									0.033,
									0.016
								],
								"e": 0.074
							},
							{
								"h": [
									0.317,
									0.017,
									0.037,
									0.053,
									0.116,
									0.027,
									0.016,
									0.109,
									0.308,
									0.01,
									0.096,
									1.0
								],
								"e": 0.116
							}
						],
						[
							{
								"h": [
									0.425,
									0.0,
									0.155,
									0.0,
									0.004,
									0.0,
									0.356,
									0.079,
									0.437,
									0.212,
									0.167,
									0.095
								],
								"e": 0.073
							},
							{
								"h": [
									0.119,
									0.061,
									0.502,
									0.066,
									0.213,
									0.098,
									0.045,
									0.108,
									0.235,
									0.085,
									0.499,
									0.004
								],
								"e": 0.189
							},
							{
								"h": [
									0.078,
									0.038,
									0.003,
									0.083,
									0.034,
									0.004,
									0.048,
									0.254,
									0.014,
									0.029,
									0.208,
									0.032
								],
								"e": 0.079
							},
							{
								"h": [
									0.0,
									0.035,
									0.005,
									0.002,
									0.006,
									0.028,
									0.038,
									0.003,
									0.007,
									0.013,
									0.017,
									0.029
								],
								"e": 0.016
							},
							{
								"h": [
									0.005,
									0.011,
									0.02,
									0.062,
									0.003,
									0.017,
									0.015,
									0.183,
									0.041,
									0.084,
									0.017,
									0.0
								],
								"e": 0.036
							},
							{
								"h": [
									0.001,
									0.0,
									0.004,
									0.047,
									0.023,
									0.054,
									0.001,
									0.029,
									0.053,
									0.023,
									0.024,
									0.095
								],
								"e": 0.029
							},
							{
								"h": [
									0.079,
									0.004,
									0.015,
									0.011,
									0.003,
									0.022,
									0.012,
									0.056,
									0.001,
									0.001,
									0.023,
									0.021
								],
								"e": 0.016
							},
							{
								"h": [
									0.002,
									0.023,
									0.008,
									0.003,
									0.018,
									0.019,
									0.008,
									0.117,
									0.003,
									0.002,
									0.016,
									0.02
								],
								"e": 0.015
							},
							{
								"h": [
									0.008,
									0.012,
									0.003,
									0.005,
									0.004,
									0.03,
									0.009,
									0.002,
									0.008,
									0.004,
									0.009,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									0.01,
									0.003,
									0.014,
									0.01,
									0.016,
									0.005,
									0.033,
									0.012,
									0.002,
									0.009,
									0.006,
									0.009
								],
								"e": 0.011
							},
							{
								"h": [
									0.008,
									0.013,
									0.024,
									0.01,
									0.012,
									0.007,
									0.033,
									0.011,
									0.002,
									0.004,
									0.019,
									0.022
								],
								"e": 0.014
							},
							{
								"h": [
									0.122,
									0.01,
									0.015,
									0.006,
									0.047,
									0.007,
									0.023,
									0.007,
									0.168,
									0.022,
									0.008,
									0.03
								],
								"e": 0.026
							}
						],
						[
							{
								"h": [
									0.29,
									0.0,
									0.033,
									0.0,
									0.0,
									0.0,
									0.095,
									0.064,
									0.317,
									0.38,
									0.114,
									0.084
								],
								"e": 0.047
							},
							{
								"h": [
									0.108,
									0.036,
									0.361,
									0.057,
									0.167,
									0.246,
									0.345,
									0.249,
									0.176,
									0.088,
									0.431,
									0.004
								],
								"e": 0.233
							},
							{
								"h": [
									0.027,
									0.017,
									0.001,
									0.013,
									0.005,
									0.005,
									0.036,
									0.226,
									0.018,
									0.041,
									0.217,
									0.03
								],
								"e": 0.056
							},
							{
								"h": [
									0.019,
									0.03,
									0.008,
									0.002,
									0.005,
									0.015,
									0.012,
									0.012,
									0.005,
									0.007,
									0.028,
									0.091
								],
								"e": 0.02
							},
							{
								"h": [
									0.006,
									0.007,
									0.014,
									0.047,
									0.037,
									0.042,
									0.034,
									0.026,
									0.046,
									0.049,
									0.017,
									0.0
								],
								"e": 0.031
							},
							{
								"h": [
									0.002,
									0.0,
									0.013,
									0.023,
									0.026,
									0.0,
									0.003,
									0.011,
									0.001,
									0.007,
									0.02,
									0.065
								],
								"e": 0.011
							},
							{
								"h": [
									0.103,
									0.001,
									0.016,
									0.015,
									0.002,
									0.03,
									0.029,
									0.029,
									0.007,
									0.0,
									0.018,
									0.034
								],
								"e": 0.017
							},
							{
								"h": [
									0.002,
									0.005,
									0.0,
									0.004,
									0.009,
									0.057,
									0.005,
									0.062,
									0.002,
									0.001,
									0.0,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.007,
									0.003,
									0.002,
									0.002,
									0.01,
									0.032,
									0.017,
									0.007,
									0.013,
									0.005,
									0.008,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.005,
									0.001,
									0.017,
									0.034,
									0.008,
									0.013,
									0.004,
									0.02,
									0.006,
									0.002,
									0.017,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.012,
									0.016,
									0.005,
									0.005,
									0.026,
									0.051,
									0.008,
									0.009,
									0.002,
									0.007,
									0.005,
									0.018
								],
								"e": 0.012
							},
							{
								"h": [
									0.187,
									0.018,
									0.01,
									0.027,
									0.03,
									0.009,
									0.008,
									0.008,
									1.0,
									0.027,
									0.032,
									0.052
								],
								"e": 0.06
							}
						],
						[
							{
								"h": [
									0.844,
									0.0,
									0.122,
									0.0,
									0.004,
									0.0,
									0.355,
									0.254,
									0.781,
									0.758,
									0.24,
									0.174
								],
								"e": 0.124
							},
							{
								"h": [
									0.269,
									0.256,
									0.572,
									0.073,
									0.187,
									0.215,
									0.222,
									0.192,
									0.378,
									0.136,
									0.444,
									0.023
								],
								"e": 0.305
							},
							{
								"h": [
									0.089,
									0.066,
									0.017,
									0.087,
									0.069,
									0.099,
									0.227,
									0.489,
									0.144,
									0.016,
									0.24,
									0.038
								],
								"e": 0.167
							},
							{
								"h": [
									0.001,
									0.108,
									0.04,
									0.013,
									0.059,
									0.024,
									0.031,
									0.004,
									0.039,
									0.066,
									0.028,
									0.027
								],
								"e": 0.04
							},
							{
								"h": [
									0.012,
									0.053,
									0.132,
									0.028,
									0.097,
									0.02,
									0.117,
									0.06,
									0.142,
									0.034,
									0.047,
									0.0
								],
								"e": 0.074
							},
							{
								"h": [
									0.026,
									0.0,
									0.183,
									0.227,
									0.053,
									0.0,
									0.017,
									0.003,
									0.02,
									0.02,
									0.067,
									0.229
								],
								"e": 0.055
							},
							{
								"h": [
									0.379,
									0.004,
									0.06,
									0.05,
									0.006,
									0.05,
									0.042,
									0.032,
									0.014,
									0.0,
									0.055,
									0.076
								],
								"e": 0.043
							},
							{
								"h": [
									0.016,
									0.011,
									0.0,
									0.011,
									0.032,
									0.065,
									0.008,
									0.049,
									0.0,
									0.014,
									0.0,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.012,
									0.01,
									0.009,
									0.011,
									0.011,
									0.167,
									0.012,
									0.107,
									0.007,
									0.009,
									0.029,
									0.001
								],
								"e": 0.027
							},
							{
								"h": [
									0.003,
									0.006,
									0.002,
									0.099,
									0.022,
									0.026,
									0.002,
									0.023,
									0.001,
									0.001,
									0.222,
									0.013
								],
								"e": 0.025
							},
							{
								"h": [
									0.002,
									0.004,
									0.031,
									0.023,
									0.122,
									0.136,
									0.001,
									0.031,
									0.057,
									0.011,
									0.007,
									0.013
								],
								"e": 0.029
							},
							{
								"h": [
									0.208,
									0.014,
									0.02,
									0.015,
									0.065,
									0.018,
									0.013,
									0.028,
									0.272,
									0.008,
									0.076,
									0.106
								],
								"e": 0.047
							}
						],
						[
							{
								"h": [
									0.775,
									0.003,
									0.128,
									0.002,
									0.048,
									0.0,
									0.5,
									0.117,
									0.664,
									0.965,
									0.257,
									1.0
								],
								"e": 0.172
							},
							{
								"h": [
									0.265,
									0.221,
									0.655,
									0.102,
									0.319,
									0.39,
									0.032,
									0.468,
									0.486,
									0.247,
									0.8,
									0.014
								],
								"e": 0.394
							},
							{
								"h": [
									0.126,
									0.049,
									0.033,
									0.349,
									0.042,
									0.032,
									0.186,
									0.824,
									0.067,
									0.365,
									0.769,
									0.085
								],
								"e": 0.268
							},
							{
								"h": [
									0.001,
									0.036,
									0.038,
									0.028,
									0.231,
									0.055,
									0.318,
									0.099,
									0.045,
									0.047,
									0.164,
									0.14
								],
								"e": 0.107
							},
							{
								"h": [
									0.027,
									0.009,
									0.019,
									0.091,
									0.031,
									0.097,
									0.127,
									1.0,
									0.169,
									0.027,
									0.167,
									0.0
								],
								"e": 0.129
							},
							{
								"h": [
									0.014,
									0.001,
									0.023,
									0.043,
									0.023,
									0.01,
									0.035,
									0.008,
									0.026,
									0.185,
									0.035,
									0.123
								],
								"e": 0.043
							},
							{
								"h": [
									0.084,
									0.009,
									0.046,
									0.028,
									0.01,
									0.06,
									0.057,
									0.089,
									0.009,
									0.001,
									0.071,
									0.016
								],
								"e": 0.035
							},
							{
								"h": [
									0.004,
									0.015,
									0.006,
									0.007,
									0.005,
									0.095,
									0.054,
									0.247,
									0.009,
									0.002,
									0.005,
									0.054
								],
								"e": 0.03
							},
							{
								"h": [
									0.026,
									0.032,
									0.005,
									0.005,
									0.012,
									0.052,
									0.066,
									0.009,
									0.03,
									0.028,
									0.078,
									0.024
								],
								"e": 0.029
							},
							{
								"h": [
									0.017,
									0.006,
									0.026,
									0.084,
									0.014,
									0.021,
									0.006,
									0.034,
									0.032,
									0.004,
									0.036,
									0.006
								],
								"e": 0.021
							},
							{
								"h": [
									0.034,
									0.021,
									0.008,
									0.047,
									0.02,
									0.056,
									0.025,
									0.035,
									0.014,
									0.013,
									0.045,
									0.04
								],
								"e": 0.029
							},
							{
								"h": [
									0.175,
									0.064,
									0.065,
									0.05,
									0.145,
									0.026,
									0.051,
									0.011,
									0.374,
									0.05,
									0.055,
									0.128
								],
								"e": 0.075
							}
						],
						[
							{
								"h": [
									0.445,
									0.0,
									0.128,
									0.0,
									0.004,
									0.0,
									0.153,
									0.659,
									0.517,
									0.599,
									0.194,
									0.4
								],
								"e": 0.127
							},
							{
								"h": [
									0.584,
									0.776,
									0.796,
									0.098,
									0.236,
									0.238,
									0.168,
									0.261,
									0.451,
									0.135,
									0.623,
									0.038
								],
								"e": 0.462
							},
							{
								"h": [
									0.259,
									0.093,
									0.272,
									0.232,
									0.516,
									0.316,
									1.0,
									0.487,
									0.303,
									0.228,
									0.39,
									0.072
								],
								"e": 0.503
							},
							{
								"h": [
									0.004,
									0.721,
									0.085,
									0.061,
									0.042,
									0.05,
									0.189,
									0.088,
									0.406,
									0.1,
									0.124,
									0.229
								],
								"e": 0.165
							},
							{
								"h": [
									0.048,
									1.0,
									0.199,
									0.043,
									0.059,
									0.278,
									0.146,
									0.279,
									0.116,
									0.065,
									0.038,
									0.0
								],
								"e": 0.218
							},
							{
								"h": [
									1.0,
									0.001,
									0.117,
									0.368,
									0.625,
									0.002,
									0.016,
									0.03,
									0.157,
									0.074,
									0.134,
									0.271
								],
								"e": 0.202
							},
							{
								"h": [
									0.245,
									0.073,
									0.057,
									0.12,
									0.027,
									0.186,
									0.058,
									0.048,
									0.038,
									0.004,
									0.26,
									0.248
								],
								"e": 0.094
							},
							{
								"h": [
									0.046,
									0.025,
									0.012,
									0.05,
									0.015,
									0.038,
									0.037,
									0.248,
									0.003,
									0.054,
									0.008,
									0.102
								],
								"e": 0.042
							},
							{
								"h": [
									0.06,
									0.205,
									0.006,
									0.015,
									0.037,
									0.077,
									0.021,
									0.091,
									0.023,
									0.009,
									0.095,
									0.012
								],
								"e": 0.047
							},
							{
								"h": [
									0.002,
									0.018,
									0.006,
									0.11,
									0.018,
									0.038,
									0.009,
									0.045,
									0.004,
									0.004,
									0.047,
									0.295
								],
								"e": 0.039
							},
							{
								"h": [
									0.006,
									0.006,
									0.07,
									0.089,
									0.428,
									0.093,
									0.002,
									0.059,
									0.056,
									0.009,
									0.019,
									0.013
								],
								"e": 0.052
							},
							{
								"h": [
									0.197,
									0.011,
									0.027,
									0.025,
									0.082,
									0.023,
									0.012,
									0.048,
									0.232,
									0.009,
									0.06,
									0.43
								],
								"e": 0.064
							}
						],
						[
							{
								"h": [
									0.354,
									0.0,
									0.245,
									0.046,
									0.025,
									0.0,
									0.232,
									0.364,
									0.36,
									0.4,
									0.16,
									0.075
								],
								"e": 0.104
							},
							{
								"h": [
									0.401,
									0.311,
									1.0,
									0.105,
									0.307,
									0.279,
									0.084,
									0.199,
									0.346,
									0.105,
									0.92,
									0.011
								],
								"e": 0.381
							},
							{
								"h": [
									0.275,
									0.014,
									0.311,
									0.762,
									0.132,
									0.073,
									0.202,
									0.975,
									0.209,
									1.0,
									0.701,
									0.113
								],
								"e": 0.495
							},
							{
								"h": [
									0.0,
									0.388,
									0.119,
									0.044,
									0.008,
									0.028,
									0.377,
									1.0,
									0.116,
									0.096,
									0.163,
									0.898
								],
								"e": 0.258
							},
							{
								"h": [
									0.058,
									0.13,
									0.038,
									0.467,
									0.066,
									0.387,
									0.137,
									0.89,
									0.06,
									0.412,
									0.025,
									0.0
								],
								"e": 0.218
							},
							{
								"h": [
									0.004,
									0.0,
									0.045,
									0.288,
									0.212,
									0.018,
									0.104,
									0.154,
									0.052,
									0.035,
									0.188,
									0.087
								],
								"e": 0.09
							},
							{
								"h": [
									0.888,
									0.008,
									0.021,
									0.067,
									0.009,
									0.131,
									0.051,
									0.066,
									0.053,
									0.001,
									0.015,
									0.561
								],
								"e": 0.093
							},
							{
								"h": [
									0.024,
									0.023,
									0.002,
									0.023,
									0.016,
									0.134,
									0.013,
									0.084,
									0.006,
									0.025,
									0.016,
									0.006
								],
								"e": 0.026
							},
							{
								"h": [
									0.022,
									0.002,
									0.004,
									0.02,
									0.063,
									0.173,
									0.005,
									0.038,
									0.122,
									0.005,
									0.036,
									0.013
								],
								"e": 0.039
							},
							{
								"h": [
									0.056,
									0.002,
									0.052,
									0.082,
									0.012,
									0.014,
									0.023,
									0.032,
									0.023,
									0.012,
									0.071,
									0.014
								],
								"e": 0.029
							},
							{
								"h": [
									0.136,
									0.029,
									0.021,
									0.017,
									0.042,
									0.071,
									0.076,
									0.024,
									0.003,
									0.021,
									0.015,
									0.059
								],
								"e": 0.043
							},
							{
								"h": [
									0.12,
									0.053,
									0.014,
									0.017,
									0.039,
									0.012,
									0.04,
									0.007,
									0.184,
									0.061,
									0.02,
									0.056
								],
								"e": 0.041
							}
						],
						[
							{
								"h": [
									0.928,
									0.0,
									0.114,
									0.0,
									0.012,
									0.0,
									0.354,
									1.0,
									0.834,
									0.796,
									0.213,
									0.204
								],
								"e": 0.175
							},
							{
								"h": [
									1.0,
									1.0,
									0.838,
									0.103,
									0.247,
									0.198,
									0.19,
									0.222,
									0.396,
									0.125,
									0.677,
									0.024
								],
								"e": 0.532
							},
							{
								"h": [
									0.326,
									0.087,
									1.0,
									0.792,
									0.48,
									1.0,
									0.481,
									0.837,
									1.0,
									0.198,
									0.517,
									0.088
								],
								"e": 0.908
							},
							{
								"h": [
									0.0,
									0.968,
									1.0,
									0.554,
									0.043,
									0.028,
									0.26,
									0.189,
									1.0,
									0.258,
									0.091,
									0.189
								],
								"e": 0.408
							},
							{
								"h": [
									0.225,
									0.517,
									0.199,
									0.382,
									0.107,
									0.284,
									0.535,
									0.104,
									0.117,
									0.237,
									0.033,
									1.0
								],
								"e": 0.485
							},
							{
								"h": [
									0.004,
									0.0,
									0.579,
									1.0,
									0.245,
									0.0,
									0.283,
									0.011,
									0.093,
									0.018,
									0.05,
									0.164
								],
								"e": 0.168
							},
							{
								"h": [
									1.0,
									0.016,
									0.051,
									0.082,
									0.02,
									0.129,
									0.066,
									0.112,
									0.325,
									0.0,
									0.017,
									0.509
								],
								"e": 0.129
							},
							{
								"h": [
									0.418,
									0.008,
									0.0,
									0.028,
									0.051,
									0.033,
									0.01,
									0.013,
									0.002,
									0.101,
									0.0,
									0.001
								],
								"e": 0.052
							},
							{
								"h": [
									0.012,
									0.001,
									0.004,
									0.028,
									0.051,
									0.568,
									0.004,
									1.0,
									0.01,
									0.004,
									0.067,
									0.007
								],
								"e": 0.12
							},
							{
								"h": [
									0.005,
									0.004,
									0.004,
									0.552,
									0.011,
									0.033,
									0.001,
									0.031,
									0.002,
									0.001,
									0.597,
									0.004
								],
								"e": 0.067
							},
							{
								"h": [
									0.003,
									0.002,
									0.02,
									0.01,
									0.093,
									0.306,
									0.001,
									0.018,
									0.025,
									0.043,
									0.007,
									0.007
								],
								"e": 0.036
							},
							{
								"h": [
									0.196,
									0.014,
									0.012,
									0.016,
									0.042,
									0.013,
									0.013,
									0.019,
									0.22,
									0.005,
									0.057,
									0.094
								],
								"e": 0.038
							}
						],
						[
							{
								"h": [
									0.374,
									1.0,
									0.185,
									1.0,
									1.0,
									1.0,
									0.89,
									0.573,
									0.657,
									0.085,
									1.0,
									0.258
								],
								"e": 1.0
							},
							{
								"h": [
									0.758,
									0.317,
									0.793,
									0.116,
									0.372,
									0.272,
									0.162,
									0.165,
									0.281,
									0.199,
									1.0,
									1.0
								],
								"e": 0.793
							},
							{
								"h": [
									0.313,
									0.025,
									0.437,
									1.0,
									0.148,
									0.134,
									0.199,
									1.0,
									0.167,
									0.926,
									1.0,
									0.141
								],
								"e": 0.573
							},
							{
								"h": [
									0.187,
									0.135,
									0.255,
									0.12,
									0.006,
									0.026,
									1.0,
									0.145,
									0.106,
									0.04,
									0.254,
									0.353
								],
								"e": 0.22
							},
							{
								"h": [
									0.062,
									0.085,
									0.023,
									0.224,
									0.098,
									0.129,
									0.086,
									0.993,
									0.046,
									0.157,
									0.122,
									0.0
								],
								"e": 0.156
							},
							{
								"h": [
									0.01,
									0.001,
									0.033,
									0.113,
									0.075,
									0.006,
									0.034,
									0.076,
									0.046,
									0.01,
									0.171,
									0.118
								],
								"e": 0.051
							},
							{
								"h": [
									0.227,
									0.021,
									0.038,
									0.044,
									0.01,
									0.118,
									0.072,
									0.165,
									0.025,
									0.001,
									0.049,
									0.054
								],
								"e": 0.055
							},
							{
								"h": [
									0.015,
									0.042,
									0.002,
									0.011,
									0.018,
									0.044,
									0.014,
									0.035,
									0.015,
									0.008,
									0.002,
									0.005
								],
								"e": 0.016
							},
							{
								"h": [
									0.033,
									0.009,
									0.006,
									0.008,
									0.022,
									0.133,
									0.021,
									0.021,
									0.027,
									0.043,
									0.1,
									0.069
								],
								"e": 0.04
							},
							{
								"h": [
									0.075,
									0.005,
									0.051,
									0.226,
									0.017,
									0.039,
									0.011,
									0.034,
									0.05,
									0.017,
									0.043,
									0.003
								],
								"e": 0.041
							},
							{
								"h": [
									0.087,
									0.011,
									0.02,
									0.013,
									0.022,
									0.092,
									0.029,
									0.088,
									0.024,
									0.062,
									0.02,
									0.032
								],
								"e": 0.041
							},
							{
								"h": [
									0.175,
									0.059,
									0.034,
									0.053,
									0.137,
									0.012,
									0.033,
									0.02,
									0.419,
									0.013,
									0.074,
									0.055
								],
								"e": 0.064
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ".",
					"o": " Vill",
					"t": " Vill",
					"r": [
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.103,
								" It": 0.054,
								" ": 0.038,
								" I": 0.031,
								" We": 0.028,
								" If": 0.028,
								" You": 0.027,
								" In": 0.023,
								" There": 0.021,
								" That": 0.021
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.107,
								" It": 0.075,
								" ": 0.037,
								" I": 0.034,
								" We": 0.027,
								" There": 0.024,
								" They": 0.023,
								" That": 0.023,
								" You": 0.022,
								" If": 0.019
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.06,
								" ": 0.047,
								" It": 0.043,
								" You": 0.034,
								" That": 0.032,
								" There": 0.023,
								" When": 0.022,
								" I": 0.021,
								" If": 0.021,
								" And": 0.02
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.123,
								" The": 0.067,
								" When": 0.05,
								"<|endoftext|>": 0.045,
								" There": 0.037,
								" It": 0.033,
								" That": 0.033,
								" You": 0.026,
								" Also": 0.02,
								"  ": 0.018
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.107,
								" When": 0.047,
								" It": 0.041,
								" The": 0.04,
								" You": 0.035,
								" I": 0.03,
								" There": 0.029,
								" Who": 0.025,
								" If": 0.024,
								"  ": 0.023
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.083,
								" He": 0.063,
								" Can": 0.039,
								" ": 0.036,
								" And": 0.036,
								" When": 0.032,
								" You": 0.031,
								" If": 0.025,
								" Some": 0.024,
								" Look": 0.022
							}
						},
						{
							"p": " He",
							"b": 0.0,
							"k": {
								" He": 0.145,
								" ": 0.092,
								" And": 0.086,
								" The": 0.046,
								" You": 0.034,
								" Or": 0.03,
								" Look": 0.029,
								"<|endoftext|>": 0.025,
								" Can": 0.022,
								" All": 0.02
							}
						},
						{
							"p": " And",
							"b": 0.004,
							"k": {
								" And": 0.093,
								" Can": 0.074,
								" Kid": 0.06,
								" He": 0.051,
								" ": 0.033,
								" Let": 0.024,
								" Or": 0.021,
								" Where": 0.021,
								" Total": 0.02,
								" You": 0.019
							}
						},
						{
							"p": "<|endoftext|>",
							"b": 0.027,
							"k": {
								"<|endoftext|>": 0.098,
								" ": 0.078,
								" Other": 0.038,
								" Let": 0.036,
								" View": 0.031,
								" Vill": 0.027,
								" All": 0.025,
								" Can": 0.025,
								" Kid": 0.022,
								" Who": 0.02
							}
						},
						{
							"p": " Vill",
							"b": 0.751,
							"k": {
								" Vill": 0.751,
								" Hero": 0.196,
								" ": 0.02,
								"  ": 0.006,
								"<|endoftext|>": 0.005,
								" Kid": 0.003,
								" View": 0.001,
								" Monster": 0.001,
								" Character": 0.001,
								"!": 0.0
							}
						},
						{
							"p": " Vill",
							"b": 0.901,
							"k": {
								" Vill": 0.901,
								" Hero": 0.091,
								" ": 0.003,
								"  ": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " Vill",
							"b": 0.49,
							"k": {
								" Vill": 0.49,
								" Hero": 0.133,
								" ": 0.11,
								"  ": 0.013,
								"<|endoftext|>": 0.009,
								" Batman": 0.005,
								" (": 0.004,
								" The": 0.004,
								" Character": 0.004,
								" [": 0.003
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.001,
									0.424,
									0.015,
									0.056,
									0.12,
									0.474,
									0.029,
									0.029,
									1.0,
									0.587,
									1.0
								],
								"e": 0.325
							},
							{
								"h": [
									0.077,
									0.109,
									0.365,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.021,
									0.637
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.022,
									0.058,
									1.0,
									0.188,
									0.447,
									0.421,
									0.472,
									0.057,
									0.588,
									1.0
								],
								"e": 0.796
							},
							{
								"h": [
									1.0,
									0.409,
									0.16,
									1.0,
									1.0,
									1.0,
									0.09,
									0.173,
									0.409,
									0.656,
									1.0,
									0.648
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.67,
									1.0,
									0.897,
									1.0,
									1.0,
									1.0,
									0.081,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.23,
									1.0,
									1.0,
									0.945,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.3,
									1.0,
									1.0,
									0.981,
									1.0,
									1.0,
									1.0,
									1.0,
									0.176,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.346,
									1.0,
									1.0,
									1.0,
									1.0,
									0.221,
									1.0,
									1.0,
									0.461,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.242,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.999,
									0.511,
									1.0,
									1.0,
									1.0,
									0.057
								],
								"e": 1.0
							},
							{
								"h": [
									0.291,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									0.302,
									0.326
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.116,
									0.018,
									0.817,
									0.003,
									0.026,
									0.243,
									0.02,
									0.02,
									0.153,
									0.798,
									0.63,
									0.562
								],
								"e": 0.231
							},
							{
								"h": [
									0.019,
									0.061,
									0.122,
									0.027,
									0.113,
									0.696,
									0.831,
									0.182,
									0.499,
									0.21,
									0.189,
									0.582
								],
								"e": 0.313
							},
							{
								"h": [
									0.042,
									0.028,
									0.0,
									0.003,
									0.009,
									0.013,
									0.059,
									0.034,
									0.027,
									0.0,
									0.077,
									0.007
								],
								"e": 0.03
							},
							{
								"h": [
									0.026,
									0.028,
									0.001,
									0.0,
									0.076,
									0.061,
									0.0,
									0.0,
									0.001,
									0.028,
									0.024,
									0.006
								],
								"e": 0.036
							},
							{
								"h": [
									0.002,
									0.012,
									0.229,
									0.005,
									0.101,
									0.005,
									0.036,
									0.006,
									0.072,
									0.04,
									0.075,
									0.0
								],
								"e": 0.065
							},
							{
								"h": [
									0.0,
									0.0,
									0.009,
									0.088,
									0.004,
									0.011,
									0.0,
									0.001,
									0.002,
									0.029,
									0.149,
									0.051
								],
								"e": 0.024
							},
							{
								"h": [
									0.031,
									0.079,
									0.047,
									0.064,
									0.022,
									0.03,
									0.034,
									0.041,
									0.001,
									0.001,
									0.013,
									0.008
								],
								"e": 0.031
							},
							{
								"h": [
									0.0,
									0.023,
									0.002,
									0.058,
									0.028,
									0.032,
									0.015,
									0.077,
									0.002,
									0.007,
									0.005,
									0.002
								],
								"e": 0.021
							},
							{
								"h": [
									0.011,
									0.024,
									0.056,
									0.067,
									0.003,
									0.013,
									0.063,
									0.002,
									0.113,
									0.117,
									0.027,
									0.008
								],
								"e": 0.031
							},
							{
								"h": [
									0.054,
									0.016,
									0.045,
									0.038,
									0.598,
									0.007,
									0.008,
									0.069,
									0.01,
									0.004,
									0.011,
									0.006
								],
								"e": 0.052
							},
							{
								"h": [
									0.02,
									0.011,
									0.022,
									0.041,
									0.073,
									0.048,
									0.082,
									0.168,
									0.016,
									0.037,
									0.031,
									0.011
								],
								"e": 0.045
							},
							{
								"h": [
									0.468,
									0.206,
									0.115,
									0.359,
									0.152,
									0.263,
									0.048,
									0.1,
									0.319,
									0.083,
									0.292,
									0.541
								],
								"e": 0.269
							}
						],
						[
							{
								"h": [
									0.186,
									0.0,
									0.103,
									0.001,
									0.016,
									0.037,
									0.296,
									0.011,
									0.015,
									0.553,
									0.306,
									0.453
								],
								"e": 0.131
							},
							{
								"h": [
									0.033,
									0.039,
									0.186,
									0.04,
									0.163,
									0.11,
									0.124,
									0.157,
									0.242,
									0.255,
									0.305,
									0.424
								],
								"e": 0.198
							},
							{
								"h": [
									0.041,
									0.005,
									0.0,
									0.008,
									0.016,
									0.001,
									0.018,
									0.126,
									0.003,
									0.004,
									0.168,
									0.018
								],
								"e": 0.031
							},
							{
								"h": [
									0.009,
									0.002,
									0.0,
									0.0,
									0.026,
									0.016,
									0.005,
									0.0,
									0.0,
									0.004,
									0.013,
									0.008
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.001,
									0.024,
									0.012,
									0.035,
									0.002,
									0.017,
									0.005,
									0.026,
									0.005,
									0.006,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.003,
									0.011,
									0.021,
									0.003,
									0.068,
									0.002,
									0.0,
									0.006,
									0.08,
									0.045,
									0.037
								],
								"e": 0.021
							},
							{
								"h": [
									0.009,
									0.012,
									0.027,
									0.028,
									0.006,
									0.023,
									0.02,
									0.03,
									0.005,
									0.009,
									0.005,
									0.001
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.004,
									0.024,
									0.009,
									0.004,
									0.005,
									0.016,
									0.006,
									0.003,
									0.002,
									0.015,
									0.022
								],
								"e": 0.009
							},
							{
								"h": [
									0.004,
									0.029,
									0.057,
									0.006,
									0.001,
									0.013,
									0.006,
									0.001,
									0.019,
									0.084,
									0.005,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.027,
									0.001,
									0.012,
									0.008,
									0.015,
									0.005,
									0.027,
									0.009,
									0.033,
									0.008,
									0.002,
									0.012
								],
								"e": 0.012
							},
							{
								"h": [
									0.076,
									0.02,
									0.047,
									0.047,
									0.004,
									0.009,
									0.041,
									0.015,
									0.007,
									0.002,
									0.031,
									0.003
								],
								"e": 0.025
							},
							{
								"h": [
									0.121,
									0.782,
									0.249,
									0.155,
									0.042,
									0.018,
									0.046,
									0.035,
									0.106,
									0.022,
									0.063,
									0.078
								],
								"e": 0.143
							}
						],
						[
							{
								"h": [
									0.694,
									0.0,
									0.156,
									0.004,
									0.009,
									0.044,
									0.33,
									0.01,
									0.007,
									0.47,
									0.302,
									0.468
								],
								"e": 0.174
							},
							{
								"h": [
									0.023,
									0.034,
									0.285,
									0.044,
									0.168,
									0.145,
									0.067,
									0.138,
									0.252,
									0.236,
									0.391,
									0.236
								],
								"e": 0.192
							},
							{
								"h": [
									0.011,
									0.005,
									0.0,
									0.004,
									0.004,
									0.006,
									0.015,
									0.206,
									0.013,
									0.006,
									0.225,
									0.026
								],
								"e": 0.038
							},
							{
								"h": [
									0.005,
									0.003,
									0.0,
									0.0,
									0.019,
									0.017,
									0.003,
									0.001,
									0.002,
									0.004,
									0.034,
									0.013
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.001,
									0.008,
									0.023,
									0.053,
									0.001,
									0.026,
									0.053,
									0.017,
									0.006,
									0.007,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.001,
									0.001,
									0.003,
									0.006,
									0.004,
									0.04,
									0.005,
									0.001,
									0.004,
									0.016,
									0.016,
									0.045
								],
								"e": 0.011
							},
							{
								"h": [
									0.007,
									0.009,
									0.023,
									0.022,
									0.002,
									0.039,
									0.007,
									0.038,
									0.014,
									0.004,
									0.004,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.004,
									0.012,
									0.002,
									0.001,
									0.002,
									0.008,
									0.004,
									0.001,
									0.0,
									0.002,
									0.005
								],
								"e": 0.003
							},
							{
								"h": [
									0.004,
									0.01,
									0.033,
									0.0,
									0.0,
									0.007,
									0.01,
									0.001,
									0.022,
									0.079,
									0.002,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.04,
									0.001,
									0.007,
									0.005,
									0.006,
									0.006,
									0.017,
									0.008,
									0.02,
									0.004,
									0.002,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.061,
									0.009,
									0.008,
									0.019,
									0.004,
									0.005,
									0.011,
									0.005,
									0.002,
									0.002,
									0.027,
									0.012
								],
								"e": 0.013
							},
							{
								"h": [
									0.163,
									0.099,
									0.268,
									0.235,
									0.08,
									0.023,
									0.054,
									0.057,
									0.304,
									0.05,
									0.088,
									0.104
								],
								"e": 0.146
							}
						],
						[
							{
								"h": [
									0.045,
									0.814,
									0.204,
									0.005,
									0.063,
									1.0,
									0.004,
									0.026,
									0.548,
									0.681,
									0.974,
									0.824
								],
								"e": 0.458
							},
							{
								"h": [
									0.037,
									0.059,
									0.418,
									0.046,
									0.147,
									0.626,
									0.682,
									0.167,
									0.41,
									0.113,
									0.29,
									0.501
								],
								"e": 0.302
							},
							{
								"h": [
									0.029,
									0.054,
									0.0,
									0.001,
									0.009,
									0.003,
									0.241,
									0.114,
									0.009,
									0.001,
									0.12,
									0.015
								],
								"e": 0.058
							},
							{
								"h": [
									0.015,
									0.004,
									0.0,
									0.0,
									0.047,
									0.038,
									0.002,
									0.0,
									0.002,
									0.007,
									0.042,
									0.011
								],
								"e": 0.023
							},
							{
								"h": [
									0.002,
									0.013,
									0.074,
									0.007,
									0.064,
									0.004,
									0.018,
									0.132,
									0.167,
									0.006,
									0.034,
									0.0
								],
								"e": 0.058
							},
							{
								"h": [
									0.002,
									0.001,
									0.004,
									0.039,
									0.01,
									0.033,
									0.0,
									0.003,
									0.002,
									0.055,
									0.133,
									0.138
								],
								"e": 0.032
							},
							{
								"h": [
									0.005,
									0.026,
									0.063,
									0.062,
									0.037,
									0.036,
									0.044,
									0.027,
									0.001,
									0.005,
									0.026,
									0.004
								],
								"e": 0.031
							},
							{
								"h": [
									0.0,
									0.009,
									0.013,
									0.008,
									0.007,
									0.069,
									0.028,
									0.007,
									0.002,
									0.001,
									0.014,
									0.028
								],
								"e": 0.017
							},
							{
								"h": [
									0.011,
									0.037,
									0.029,
									0.01,
									0.003,
									0.043,
									0.018,
									0.003,
									0.103,
									0.272,
									0.01,
									0.014
								],
								"e": 0.028
							},
							{
								"h": [
									0.071,
									0.025,
									0.032,
									0.029,
									0.125,
									0.007,
									0.002,
									0.07,
									0.005,
									0.004,
									0.008,
									0.008
								],
								"e": 0.027
							},
							{
								"h": [
									0.007,
									0.011,
									0.026,
									0.031,
									0.073,
									0.093,
									0.074,
									0.252,
									0.029,
									0.097,
									0.017,
									0.015
								],
								"e": 0.06
							},
							{
								"h": [
									1.0,
									0.122,
									0.107,
									0.724,
									0.253,
									0.43,
									0.042,
									0.188,
									1.0,
									0.048,
									0.428,
									1.0
								],
								"e": 0.483
							}
						],
						[
							{
								"h": [
									0.536,
									0.0,
									0.123,
									0.005,
									0.019,
									0.016,
									0.091,
									0.036,
									0.011,
									0.423,
									0.245,
									0.266
								],
								"e": 0.118
							},
							{
								"h": [
									0.111,
									0.071,
									0.448,
									0.053,
									0.239,
									0.067,
									0.032,
									0.096,
									0.285,
									0.197,
									0.434,
									0.145
								],
								"e": 0.208
							},
							{
								"h": [
									0.131,
									0.007,
									0.001,
									0.029,
									0.018,
									0.002,
									0.028,
									0.189,
									0.013,
									0.015,
									0.191,
									0.025
								],
								"e": 0.055
							},
							{
								"h": [
									0.003,
									0.002,
									0.03,
									0.001,
									0.005,
									0.032,
									0.02,
									0.002,
									0.001,
									0.027,
									0.022,
									0.047
								],
								"e": 0.021
							},
							{
								"h": [
									0.002,
									0.008,
									0.006,
									0.053,
									0.013,
									0.011,
									0.048,
									0.013,
									0.039,
									0.066,
									0.012,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.405,
									0.98,
									0.018,
									0.071,
									0.011,
									0.584,
									0.0,
									0.005,
									0.297,
									0.158,
									0.035,
									0.027
								],
								"e": 0.2
							},
							{
								"h": [
									0.024,
									0.024,
									0.005,
									0.065,
									0.003,
									0.041,
									0.046,
									0.12,
									0.005,
									0.152,
									0.05,
									0.012
								],
								"e": 0.051
							},
							{
								"h": [
									0.001,
									0.017,
									1.0,
									0.127,
									0.02,
									0.005,
									0.072,
									0.04,
									0.004,
									0.085,
									0.308,
									0.877
								],
								"e": 0.176
							},
							{
								"h": [
									0.016,
									0.377,
									0.008,
									0.03,
									0.001,
									0.031,
									0.072,
									0.002,
									0.049,
									0.076,
									0.079,
									0.004
								],
								"e": 0.053
							},
							{
								"h": [
									0.184,
									0.006,
									0.164,
									0.01,
									0.123,
									0.003,
									0.883,
									0.011,
									0.015,
									0.098,
									0.005,
									0.045
								],
								"e": 0.082
							},
							{
								"h": [
									0.403,
									0.121,
									0.071,
									0.031,
									0.016,
									0.007,
									1.0,
									1.0,
									0.003,
									0.003,
									0.538,
									0.048
								],
								"e": 0.195
							},
							{
								"h": [
									0.076,
									0.192,
									0.184,
									0.076,
									0.074,
									0.03,
									0.087,
									0.134,
									0.023,
									0.178,
									0.391,
									0.066
								],
								"e": 0.152
							}
						],
						[
							{
								"h": [
									0.302,
									0.0,
									0.197,
									0.028,
									0.045,
									0.031,
									0.04,
									0.037,
									0.011,
									0.479,
									0.236,
									0.247
								],
								"e": 0.112
							},
							{
								"h": [
									0.085,
									0.04,
									0.312,
									0.045,
									0.17,
									0.124,
									0.12,
									0.132,
									0.192,
									0.126,
									0.366,
									0.36
								],
								"e": 0.189
							},
							{
								"h": [
									0.031,
									0.002,
									0.0,
									0.004,
									0.004,
									0.006,
									0.02,
									0.181,
									0.026,
									0.014,
									0.197,
									0.028
								],
								"e": 0.039
							},
							{
								"h": [
									0.005,
									0.003,
									0.005,
									0.001,
									0.01,
									0.027,
									0.006,
									0.005,
									0.002,
									0.015,
									0.043,
									0.075
								],
								"e": 0.02
							},
							{
								"h": [
									0.006,
									0.005,
									0.011,
									0.04,
									0.028,
									0.01,
									0.051,
									0.017,
									0.017,
									0.073,
									0.007,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.025,
									0.0,
									0.012,
									0.03,
									0.014,
									0.003,
									0.0,
									0.001,
									0.009,
									0.02,
									0.013,
									0.027
								],
								"e": 0.012
							},
							{
								"h": [
									0.043,
									0.06,
									0.005,
									0.076,
									0.002,
									0.04,
									0.045,
									0.09,
									0.013,
									0.0,
									0.089,
									0.012
								],
								"e": 0.034
							},
							{
								"h": [
									0.0,
									0.006,
									0.004,
									0.018,
									0.004,
									0.008,
									0.029,
									0.095,
									0.001,
									0.002,
									0.008,
									0.025
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.097,
									0.012,
									0.01,
									0.0,
									0.021,
									0.29,
									0.002,
									0.065,
									0.064,
									0.024,
									0.005
								],
								"e": 0.04
							},
							{
								"h": [
									0.02,
									0.003,
									0.1,
									0.023,
									0.072,
									0.005,
									0.333,
									0.022,
									0.012,
									0.077,
									0.006,
									0.004
								],
								"e": 0.039
							},
							{
								"h": [
									0.176,
									0.162,
									0.01,
									0.045,
									0.065,
									0.021,
									0.12,
									0.052,
									0.002,
									0.013,
									0.134,
									0.105
								],
								"e": 0.073
							},
							{
								"h": [
									0.159,
									0.327,
									0.059,
									0.181,
									0.035,
									0.023,
									0.031,
									0.133,
									0.065,
									0.269,
									0.213,
									0.061
								],
								"e": 0.147
							}
						],
						[
							{
								"h": [
									0.099,
									0.014,
									0.922,
									0.039,
									0.081,
									0.108,
									0.012,
									0.132,
									0.22,
									0.722,
									0.598,
									0.544
								],
								"e": 0.255
							},
							{
								"h": [
									0.102,
									0.334,
									0.499,
									0.059,
									0.178,
									0.485,
									0.811,
									0.177,
									0.355,
									0.128,
									0.378,
									0.679
								],
								"e": 0.373
							},
							{
								"h": [
									0.103,
									0.084,
									0.0,
									0.024,
									0.033,
									0.098,
									0.144,
									0.42,
									0.145,
									0.002,
									0.2,
									0.028
								],
								"e": 0.117
							},
							{
								"h": [
									0.024,
									0.101,
									0.027,
									0.002,
									0.144,
									0.056,
									0.006,
									0.0,
									0.015,
									0.162,
									0.049,
									0.026
								],
								"e": 0.077
							},
							{
								"h": [
									0.013,
									0.072,
									0.133,
									0.06,
									0.136,
									0.027,
									0.117,
									0.045,
									0.11,
									0.09,
									0.068,
									0.0
								],
								"e": 0.088
							},
							{
								"h": [
									0.007,
									0.0,
									0.042,
									0.181,
									0.019,
									0.004,
									0.0,
									0.001,
									0.012,
									0.044,
									0.153,
									0.06
								],
								"e": 0.037
							},
							{
								"h": [
									0.252,
									0.09,
									0.027,
									0.445,
									0.032,
									0.064,
									0.134,
									0.097,
									0.002,
									0.0,
									0.498,
									0.026
								],
								"e": 0.114
							},
							{
								"h": [
									0.0,
									0.074,
									0.001,
									0.385,
									0.024,
									0.023,
									0.048,
									1.0,
									0.001,
									0.008,
									0.001,
									0.001
								],
								"e": 0.109
							},
							{
								"h": [
									0.005,
									0.008,
									0.052,
									0.054,
									0.002,
									0.008,
									0.039,
									0.006,
									0.176,
									0.443,
									0.052,
									0.001
								],
								"e": 0.038
							},
							{
								"h": [
									0.019,
									0.007,
									0.015,
									0.03,
									0.156,
									0.012,
									0.001,
									0.023,
									0.001,
									0.001,
									0.021,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.003,
									0.002,
									0.005,
									0.006,
									0.016,
									0.028,
									0.012,
									0.069,
									0.002,
									0.013,
									0.006,
									0.005
								],
								"e": 0.013
							},
							{
								"h": [
									0.24,
									0.089,
									0.029,
									0.046,
									0.07,
									0.078,
									0.011,
									0.052,
									0.1,
									0.02,
									0.109,
									0.081
								],
								"e": 0.084
							}
						],
						[
							{
								"h": [
									0.801,
									0.0,
									0.107,
									0.026,
									0.047,
									0.015,
									0.6,
									0.09,
									0.009,
									0.477,
									0.269,
									0.659
								],
								"e": 0.238
							},
							{
								"h": [
									0.191,
									0.196,
									0.65,
									0.082,
									0.351,
									0.295,
									0.197,
									0.321,
									0.407,
									0.27,
									0.742,
									0.474
								],
								"e": 0.396
							},
							{
								"h": [
									0.154,
									0.029,
									0.017,
									0.1,
									0.154,
									0.049,
									0.112,
									0.585,
									0.175,
									0.101,
									0.631,
									0.075
								],
								"e": 0.193
							},
							{
								"h": [
									0.004,
									0.04,
									0.064,
									0.021,
									0.049,
									0.048,
									0.135,
									0.052,
									0.041,
									0.052,
									0.107,
									0.371
								],
								"e": 0.099
							},
							{
								"h": [
									0.022,
									0.013,
									0.059,
									0.341,
									0.107,
									0.07,
									0.119,
									0.075,
									0.039,
									0.045,
									0.008,
									0.0
								],
								"e": 0.079
							},
							{
								"h": [
									0.013,
									0.01,
									0.051,
									0.042,
									0.033,
									0.094,
									0.007,
									0.002,
									0.043,
									0.082,
									0.055,
									0.044
								],
								"e": 0.038
							},
							{
								"h": [
									0.076,
									0.053,
									0.028,
									0.061,
									0.006,
									0.109,
									0.039,
									0.269,
									0.098,
									0.005,
									0.113,
									0.019
								],
								"e": 0.071
							},
							{
								"h": [
									0.002,
									0.009,
									0.032,
									0.018,
									0.002,
									0.006,
									0.051,
									0.041,
									0.003,
									0.003,
									0.019,
									0.045
								],
								"e": 0.017
							},
							{
								"h": [
									0.004,
									0.023,
									0.037,
									0.004,
									0.003,
									0.013,
									0.002,
									0.007,
									0.062,
									0.34,
									0.012,
									0.003
								],
								"e": 0.021
							},
							{
								"h": [
									0.007,
									0.001,
									0.036,
									0.025,
									0.004,
									0.006,
									0.023,
									0.011,
									0.03,
									0.006,
									0.005,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.121,
									0.029,
									0.013,
									0.021,
									0.008,
									0.013,
									0.055,
									0.022,
									0.006,
									0.005,
									0.063,
									0.01
								],
								"e": 0.027
							},
							{
								"h": [
									0.093,
									0.126,
									0.265,
									0.159,
									0.072,
									0.015,
									0.062,
									0.021,
									0.016,
									0.107,
									0.037,
									0.135
								],
								"e": 0.108
							}
						],
						[
							{
								"h": [
									0.051,
									0.9,
									0.205,
									0.093,
									0.263,
									0.768,
									0.004,
									0.179,
									0.784,
									0.709,
									0.981,
									0.815
								],
								"e": 0.546
							},
							{
								"h": [
									0.156,
									0.232,
									0.726,
									0.08,
									0.239,
									0.642,
									0.637,
									0.182,
									0.414,
									0.098,
									0.556,
									0.646
								],
								"e": 0.404
							},
							{
								"h": [
									0.119,
									0.066,
									0.002,
									0.009,
									0.083,
									0.055,
									0.535,
									0.321,
									0.081,
									0.012,
									0.298,
									0.048
								],
								"e": 0.151
							},
							{
								"h": [
									0.014,
									0.023,
									0.005,
									0.012,
									0.062,
									0.057,
									0.02,
									0.008,
									0.046,
									0.056,
									0.123,
									0.099
								],
								"e": 0.06
							},
							{
								"h": [
									0.022,
									0.11,
									0.088,
									0.025,
									0.086,
									0.031,
									0.081,
									0.308,
									0.299,
									0.024,
									0.032,
									0.0
								],
								"e": 0.116
							},
							{
								"h": [
									0.006,
									0.0,
									0.02,
									0.1,
									0.046,
									0.005,
									0.0,
									0.01,
									0.005,
									0.072,
									0.118,
									0.228
								],
								"e": 0.047
							},
							{
								"h": [
									0.018,
									0.041,
									0.058,
									0.19,
									0.072,
									0.114,
									0.291,
									0.074,
									0.006,
									0.001,
									0.287,
									0.01
								],
								"e": 0.09
							},
							{
								"h": [
									0.0,
									0.034,
									0.005,
									0.047,
									0.001,
									0.022,
									0.084,
									0.028,
									0.002,
									0.002,
									0.009,
									0.02
								],
								"e": 0.02
							},
							{
								"h": [
									0.016,
									0.024,
									0.031,
									0.038,
									0.002,
									0.006,
									0.024,
									0.012,
									0.124,
									0.428,
									0.058,
									0.006
								],
								"e": 0.035
							},
							{
								"h": [
									0.029,
									0.027,
									0.036,
									0.01,
									0.039,
									0.036,
									0.002,
									0.087,
									0.006,
									0.003,
									0.011,
									0.001
								],
								"e": 0.023
							},
							{
								"h": [
									0.007,
									0.011,
									0.017,
									0.037,
									0.058,
									0.052,
									0.04,
									0.154,
									0.03,
									0.064,
									0.018,
									0.017
								],
								"e": 0.044
							},
							{
								"h": [
									0.668,
									0.094,
									0.058,
									0.236,
									0.193,
									0.164,
									0.03,
									0.093,
									0.396,
									0.037,
									0.192,
									0.396
								],
								"e": 0.23
							}
						],
						[
							{
								"h": [
									0.323,
									0.0,
									0.182,
									0.125,
									0.104,
									0.022,
									0.278,
									0.157,
									0.013,
									0.501,
									0.193,
									0.274
								],
								"e": 0.186
							},
							{
								"h": [
									0.616,
									0.331,
									0.992,
									0.093,
									0.382,
									0.129,
									0.057,
									0.137,
									0.376,
									0.163,
									0.826,
									0.273
								],
								"e": 0.422
							},
							{
								"h": [
									0.215,
									0.004,
									0.14,
									0.307,
									0.122,
									0.054,
									0.121,
									0.905,
									0.474,
									0.272,
									0.626,
									0.105
								],
								"e": 0.338
							},
							{
								"h": [
									0.005,
									0.057,
									0.436,
									0.093,
									0.031,
									0.07,
									0.252,
									0.272,
									0.027,
									0.264,
									0.118,
									1.0
								],
								"e": 0.249
							},
							{
								"h": [
									0.034,
									0.134,
									0.038,
									0.359,
									0.052,
									0.081,
									0.421,
									0.042,
									0.071,
									0.509,
									0.007,
									0.0
								],
								"e": 0.143
							},
							{
								"h": [
									1.0,
									0.11,
									0.095,
									0.342,
									0.098,
									0.382,
									0.002,
									0.026,
									0.686,
									0.129,
									0.08,
									0.046
								],
								"e": 0.239
							},
							{
								"h": [
									0.29,
									0.357,
									0.013,
									0.492,
									0.052,
									0.222,
									0.312,
									0.269,
									0.021,
									0.129,
									0.413,
									0.686
								],
								"e": 0.262
							},
							{
								"h": [
									0.002,
									0.046,
									0.446,
									0.735,
									0.007,
									0.041,
									0.438,
									0.189,
									0.01,
									0.243,
									1.0,
									0.735
								],
								"e": 0.273
							},
							{
								"h": [
									0.031,
									0.084,
									0.267,
									0.072,
									0.002,
									0.146,
									1.0,
									0.025,
									0.802,
									0.252,
									0.566,
									0.01
								],
								"e": 0.212
							},
							{
								"h": [
									0.762,
									0.006,
									0.316,
									0.092,
									0.174,
									0.024,
									0.758,
									0.179,
									0.118,
									0.194,
									0.062,
									0.023
								],
								"e": 0.166
							},
							{
								"h": [
									0.611,
									0.35,
									0.107,
									0.099,
									0.074,
									0.066,
									0.754,
									0.601,
									0.011,
									0.042,
									0.292,
									1.0
								],
								"e": 0.323
							},
							{
								"h": [
									0.418,
									0.876,
									0.136,
									0.123,
									0.071,
									0.021,
									0.151,
									0.188,
									0.078,
									0.18,
									1.0,
									0.106
								],
								"e": 0.294
							}
						],
						[
							{
								"h": [
									0.115,
									0.013,
									1.0,
									0.429,
									0.291,
									0.091,
									0.014,
									0.709,
									0.275,
									0.782,
									0.604,
									0.552
								],
								"e": 0.463
							},
							{
								"h": [
									0.36,
									1.0,
									0.766,
									0.087,
									0.259,
									0.488,
									0.756,
									0.195,
									0.352,
									0.109,
									0.615,
									0.815
								],
								"e": 0.549
							},
							{
								"h": [
									0.39,
									0.085,
									0.017,
									0.239,
									0.324,
									1.0,
									0.27,
									0.737,
									1.0,
									0.028,
									0.415,
									0.07
								],
								"e": 0.457
							},
							{
								"h": [
									0.012,
									1.0,
									1.0,
									0.149,
									0.184,
									0.083,
									0.067,
									0.032,
									0.449,
									1.0,
									0.178,
									0.242
								],
								"e": 0.482
							},
							{
								"h": [
									0.3,
									1.0,
									0.279,
									0.456,
									0.135,
									0.616,
									0.632,
									0.077,
									0.17,
									0.689,
									0.057,
									0.0
								],
								"e": 0.391
							},
							{
								"h": [
									0.003,
									0.0,
									0.116,
									1.0,
									0.131,
									0.003,
									0.0,
									0.005,
									0.024,
									0.036,
									0.136,
									0.081
								],
								"e": 0.094
							},
							{
								"h": [
									1.0,
									0.341,
									0.027,
									1.0,
									0.048,
									0.191,
									0.228,
									0.261,
									0.014,
									0.0,
									0.248,
									0.121
								],
								"e": 0.241
							},
							{
								"h": [
									0.002,
									0.02,
									0.0,
									0.385,
									0.015,
									0.011,
									0.044,
									0.357,
									0.005,
									0.028,
									0.003,
									0.001
								],
								"e": 0.058
							},
							{
								"h": [
									0.016,
									0.002,
									0.016,
									0.108,
									0.002,
									0.022,
									0.038,
									0.015,
									0.393,
									0.344,
									0.062,
									0.003
								],
								"e": 0.051
							},
							{
								"h": [
									0.013,
									0.004,
									0.02,
									0.046,
									0.023,
									0.009,
									0.001,
									0.031,
									0.002,
									0.0,
									0.035,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.002,
									0.002,
									0.005,
									0.005,
									0.01,
									0.032,
									0.009,
									0.049,
									0.001,
									0.022,
									0.005,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.232,
									0.084,
									0.03,
									0.042,
									0.053,
									0.052,
									0.013,
									0.033,
									0.057,
									0.012,
									0.083,
									0.069
								],
								"e": 0.067
							}
						],
						[
							{
								"h": [
									0.71,
									0.0,
									0.115,
									0.231,
									0.23,
									0.006,
									1.0,
									0.247,
									0.011,
									0.596,
									0.291,
									0.322
								],
								"e": 0.362
							},
							{
								"h": [
									1.0,
									0.418,
									0.827,
									0.105,
									0.457,
									0.238,
									0.112,
									0.26,
									0.271,
									0.174,
									1.0,
									1.0
								],
								"e": 0.569
							},
							{
								"h": [
									0.59,
									0.01,
									1.0,
									1.0,
									0.98,
									0.358,
									0.134,
									1.0,
									0.601,
									1.0,
									1.0,
									0.141
								],
								"e": 1.0
							},
							{
								"h": [
									0.02,
									0.039,
									0.759,
									0.303,
									0.032,
									0.021,
									1.0,
									1.0,
									0.304,
									0.172,
									0.184,
									0.874
								],
								"e": 0.52
							},
							{
								"h": [
									0.099,
									0.103,
									0.035,
									1.0,
									0.197,
									0.245,
									0.402,
									0.138,
									0.033,
									0.17,
									0.011,
									1.0
								],
								"e": 0.437
							},
							{
								"h": [
									0.019,
									0.006,
									0.188,
									0.186,
									0.12,
									0.046,
									0.204,
									0.022,
									0.115,
									0.138,
									0.087,
									0.033
								],
								"e": 0.104
							},
							{
								"h": [
									0.553,
									0.217,
									0.035,
									0.235,
									0.004,
									0.198,
									0.046,
									0.653,
									1.0,
									0.003,
									0.08,
									0.036
								],
								"e": 0.291
							},
							{
								"h": [
									0.031,
									0.007,
									0.014,
									0.039,
									0.009,
									0.003,
									0.073,
									0.02,
									0.041,
									0.007,
									0.018,
									0.014
								],
								"e": 0.025
							},
							{
								"h": [
									0.003,
									0.013,
									0.041,
									0.003,
									0.002,
									0.044,
									0.005,
									0.071,
									0.04,
									0.856,
									0.016,
									0.005
								],
								"e": 0.043
							},
							{
								"h": [
									0.022,
									0.0,
									0.017,
									0.121,
									0.002,
									0.005,
									0.039,
									0.01,
									0.055,
									0.006,
									0.012,
									0.002
								],
								"e": 0.023
							},
							{
								"h": [
									0.145,
									0.01,
									0.035,
									0.01,
									0.002,
									0.023,
									0.058,
									0.04,
									0.007,
									0.019,
									0.034,
									0.01
								],
								"e": 0.029
							},
							{
								"h": [
									0.1,
									0.168,
									0.178,
									0.17,
									0.049,
									0.012,
									0.049,
									0.077,
									0.011,
									0.011,
									0.06,
									0.108
								],
								"e": 0.091
							}
						],
						[
							{
								"h": [
									0.06,
									1.0,
									0.22,
									1.0,
									1.0,
									0.687,
									0.005,
									1.0,
									1.0,
									0.781,
									1.0,
									0.832
								],
								"e": 1.0
							},
							{
								"h": [
									0.682,
									0.688,
									1.0,
									0.112,
									0.352,
									0.624,
									0.586,
									0.199,
									0.399,
									0.08,
									0.861,
									0.76
								],
								"e": 0.595
							},
							{
								"h": [
									0.443,
									0.055,
									0.173,
									0.092,
									0.686,
									0.705,
									1.0,
									0.554,
									0.599,
									0.152,
									0.597,
									0.114
								],
								"e": 0.535
							},
							{
								"h": [
									0.009,
									0.15,
									0.213,
									0.792,
									0.073,
									0.072,
									0.19,
									0.399,
									1.0,
									0.325,
									0.391,
									0.847
								],
								"e": 0.466
							},
							{
								"h": [
									0.313,
									0.894,
									0.139,
									0.167,
									0.109,
									0.328,
									0.313,
									1.0,
									0.225,
									0.084,
									0.03,
									0.0
								],
								"e": 0.355
							},
							{
								"h": [
									0.006,
									0.003,
									0.086,
									0.461,
									0.197,
									0.262,
									0.016,
									0.041,
									0.047,
									0.063,
									0.118,
									0.292
								],
								"e": 0.116
							},
							{
								"h": [
									0.099,
									0.083,
									0.085,
									0.501,
									0.111,
									0.294,
									0.416,
									0.168,
									0.03,
									0.012,
									0.09,
									0.084
								],
								"e": 0.153
							},
							{
								"h": [
									0.001,
									0.046,
									0.009,
									0.06,
									0.002,
									0.022,
									0.055,
									0.009,
									0.004,
									0.01,
									0.013,
									0.013
								],
								"e": 0.02
							},
							{
								"h": [
									0.01,
									0.027,
									0.018,
									0.068,
									0.008,
									0.023,
									0.026,
									0.06,
									0.198,
									0.942,
									0.072,
									0.01
								],
								"e": 0.062
							},
							{
								"h": [
									0.038,
									0.017,
									0.047,
									0.058,
									0.048,
									0.088,
									0.002,
									0.08,
									0.005,
									0.004,
									0.032,
									0.001
								],
								"e": 0.034
							},
							{
								"h": [
									0.005,
									0.007,
									0.014,
									0.019,
									0.041,
									0.08,
									0.043,
									0.103,
									0.021,
									0.098,
									0.012,
									0.011
								],
								"e": 0.041
							},
							{
								"h": [
									0.288,
									0.084,
									0.033,
									0.158,
									0.139,
									0.122,
									0.02,
									0.105,
									0.287,
									0.039,
									0.145,
									0.263
								],
								"e": 0.158
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Vill",
					"o": "ain",
					"t": "ain",
					"r": [
						{
							"p": " Vill",
							"b": 0.0,
							"k": {
								" Vill": 0.987,
								"anova": 0.006,
								"Vill": 0.004,
								"ains": 0.001,
								"agers": 0.001,
								"iage": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": " Vill",
							"b": 0.02,
							"k": {
								" Vill": 0.85,
								"agers": 0.034,
								"anova": 0.033,
								"ains": 0.022,
								"ain": 0.02,
								"iage": 0.015,
								"Vill": 0.006,
								"age": 0.002,
								" vill": 0.002,
								" Hots": 0.002
							}
						},
						{
							"p": "anova",
							"b": 0.232,
							"k": {
								"anova": 0.426,
								"ain": 0.232,
								"ains": 0.125,
								"agers": 0.116,
								"iage": 0.039,
								" Vill": 0.028,
								"aries": 0.005,
								"aro": 0.004,
								"age": 0.002,
								"Vill": 0.002
							}
						},
						{
							"p": "anova",
							"b": 0.081,
							"k": {
								"anova": 0.788,
								"ain": 0.081,
								"ains": 0.052,
								"agers": 0.04,
								"iage": 0.029,
								"aries": 0.002,
								"age": 0.001,
								"ages": 0.001,
								"agic": 0.001,
								" Vill": 0.001
							}
						},
						{
							"p": "anova",
							"b": 0.232,
							"k": {
								"anova": 0.683,
								"ain": 0.232,
								"ains": 0.057,
								"iage": 0.017,
								"agers": 0.004,
								"age": 0.002,
								"ages": 0.001,
								"agic": 0.001,
								"!": 0.0,
								"\"": 0.0
							}
						},
						{
							"p": "ain",
							"b": 0.698,
							"k": {
								"ain": 0.698,
								"anova": 0.279,
								"age": 0.009,
								"ains": 0.005,
								"iage": 0.003,
								"agers": 0.002,
								"ages": 0.001,
								"enge": 0.001,
								"agic": 0.001,
								"!": 0.0
							}
						},
						{
							"p": "ain",
							"b": 0.992,
							"k": {
								"ain": 0.992,
								"anova": 0.004,
								"enge": 0.002,
								"age": 0.001,
								"ains": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": "ain",
							"b": 0.998,
							"k": {
								"ain": 0.998,
								"ains": 0.001,
								"enge": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": "ain",
							"b": 0.998,
							"k": {
								"ain": 0.998,
								"ains": 0.001,
								"enge": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": "ain",
							"b": 0.999,
							"k": {
								"ain": 0.999,
								"ains": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": "ain",
							"b": 0.999,
							"k": {
								"ain": 0.999,
								"ains": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": "ain",
							"b": 0.999,
							"k": {
								"ain": 0.999,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.338,
									0.0,
									0.299,
									0.0,
									0.004,
									0.005,
									1.0,
									0.083,
									0.415,
									0.634,
									0.364,
									0.254
								],
								"e": 0.18
							},
							{
								"h": [
									0.077,
									0.028,
									0.348,
									1.0,
									1.0,
									0.66,
									1.0,
									1.0,
									1.0,
									1.0,
									0.019,
									0.007
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.019,
									0.092,
									0.38,
									0.031,
									0.347,
									0.691,
									0.302,
									0.123,
									0.379,
									1.0
								],
								"e": 0.698
							},
							{
								"h": [
									0.994,
									0.359,
									0.02,
									0.304,
									1.0,
									1.0,
									0.146,
									0.089,
									0.026,
									1.0,
									1.0,
									1.0
								],
								"e": 0.88
							},
							{
								"h": [
									1.0,
									0.174,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.244,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.149,
									0.134,
									1.0,
									1.0,
									1.0,
									0.095,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.489,
									1.0,
									1.0,
									1.0,
									1.0,
									0.253,
									0.096,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.076,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.398,
									0.711,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.273,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.182,
									0.0,
									0.144,
									0.0,
									0.0,
									0.0,
									0.547,
									0.022,
									0.633,
									0.408,
									0.247,
									0.186
								],
								"e": 0.12
							},
							{
								"h": [
									0.023,
									0.014,
									0.142,
									0.022,
									0.129,
									0.412,
									0.156,
									0.322,
									0.333,
									0.284,
									0.197,
									0.007
								],
								"e": 0.214
							},
							{
								"h": [
									0.033,
									0.033,
									0.0,
									0.002,
									0.006,
									0.002,
									0.044,
									0.076,
									0.014,
									0.001,
									0.091,
									0.009
								],
								"e": 0.027
							},
							{
								"h": [
									0.059,
									0.015,
									0.0,
									0.0,
									0.077,
									0.093,
									0.001,
									0.0,
									0.0,
									0.007,
									0.022,
									0.003
								],
								"e": 0.038
							},
							{
								"h": [
									0.001,
									0.002,
									0.117,
									0.002,
									0.167,
									0.002,
									0.03,
									0.002,
									0.295,
									0.055,
									0.069,
									0.0
								],
								"e": 0.066
							},
							{
								"h": [
									0.005,
									0.0,
									0.015,
									0.103,
									0.002,
									0.003,
									0.006,
									0.0,
									0.024,
									0.092,
									0.178,
									0.037
								],
								"e": 0.04
							},
							{
								"h": [
									0.01,
									0.044,
									0.061,
									0.055,
									0.035,
									0.01,
									0.052,
									0.207,
									0.001,
									0.0,
									0.022,
									0.002
								],
								"e": 0.039
							},
							{
								"h": [
									0.001,
									0.03,
									0.0,
									0.029,
									0.0,
									0.014,
									0.005,
									0.052,
									0.004,
									0.002,
									0.0,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.014,
									0.006,
									0.005,
									0.01,
									0.0,
									0.002,
									0.028,
									0.002,
									0.035,
									0.011,
									0.018,
									0.009
								],
								"e": 0.011
							},
							{
								"h": [
									0.018,
									0.004,
									0.014,
									0.004,
									0.009,
									0.003,
									0.003,
									0.065,
									0.002,
									0.002,
									0.0,
									0.006
								],
								"e": 0.01
							},
							{
								"h": [
									0.008,
									0.006,
									0.007,
									0.007,
									0.008,
									0.0,
									0.008,
									0.021,
									0.025,
									0.0,
									0.009,
									0.018
								],
								"e": 0.009
							},
							{
								"h": [
									0.012,
									0.003,
									0.004,
									0.009,
									0.035,
									0.006,
									0.005,
									0.004,
									0.046,
									0.017,
									0.018,
									0.003
								],
								"e": 0.014
							}
						],
						[
							{
								"h": [
									0.202,
									0.0,
									0.845,
									0.0,
									0.001,
									0.0,
									0.317,
									0.009,
									1.0,
									0.383,
									0.308,
									0.515
								],
								"e": 0.184
							},
							{
								"h": [
									0.046,
									0.014,
									0.182,
									0.032,
									0.134,
									0.313,
									0.149,
									0.485,
									0.17,
									0.208,
									0.31,
									0.001
								],
								"e": 0.205
							},
							{
								"h": [
									0.132,
									0.032,
									0.0,
									0.011,
									0.004,
									0.0,
									0.011,
									0.18,
									0.001,
									0.006,
									0.163,
									0.017
								],
								"e": 0.042
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.0,
									0.04,
									0.025,
									0.005,
									0.0,
									0.0,
									0.003,
									0.04,
									0.003
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.0,
									0.02,
									0.005,
									0.007,
									0.001,
									0.023,
									0.044,
									0.025,
									0.003,
									0.011,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.0,
									0.003,
									0.016,
									0.001,
									0.001,
									0.003,
									0.0,
									0.001,
									0.127,
									0.062,
									0.014
								],
								"e": 0.02
							},
							{
								"h": [
									0.004,
									0.006,
									0.041,
									0.016,
									0.022,
									0.008,
									0.013,
									0.028,
									0.001,
									0.0,
									0.005,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.004,
									0.0,
									0.004,
									0.0,
									0.005,
									0.004,
									0.005,
									0.002,
									0.0,
									0.0,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.009,
									0.001,
									0.004,
									0.002,
									0.0,
									0.003,
									0.012,
									0.001,
									0.016,
									0.016,
									0.006,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.005,
									0.0,
									0.008,
									0.004,
									0.002,
									0.005,
									0.001,
									0.011,
									0.005,
									0.001,
									0.0,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.005,
									0.001,
									0.002,
									0.002,
									0.006,
									0.0,
									0.002,
									0.001,
									0.003,
									0.0,
									0.0,
									0.005
								],
								"e": 0.002
							},
							{
								"h": [
									0.019,
									0.003,
									0.001,
									0.004,
									0.003,
									0.001,
									0.001,
									0.002,
									0.024,
									0.001,
									0.0,
									0.001
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.342,
									0.0,
									1.0,
									0.0,
									0.001,
									0.0,
									0.699,
									0.016,
									0.492,
									0.449,
									0.218,
									0.651
								],
								"e": 0.202
							},
							{
								"h": [
									0.012,
									0.008,
									0.264,
									0.038,
									0.144,
									1.0,
									0.06,
									0.455,
									0.205,
									0.269,
									0.39,
									0.001
								],
								"e": 0.262
							},
							{
								"h": [
									0.01,
									0.014,
									0.0,
									0.005,
									0.001,
									0.001,
									0.009,
									0.267,
									0.003,
									0.007,
									0.246,
									0.025
								],
								"e": 0.037
							},
							{
								"h": [
									0.012,
									0.002,
									0.0,
									0.0,
									0.023,
									0.02,
									0.001,
									0.001,
									0.0,
									0.001,
									0.052,
									0.002
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.001,
									0.005,
									0.005,
									0.006,
									0.0,
									0.029,
									0.012,
									0.013,
									0.004,
									0.015,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.002,
									0.0,
									0.003,
									0.009,
									0.001,
									0.0,
									0.003,
									0.0,
									0.002,
									0.014,
									0.012,
									0.017
								],
								"e": 0.006
							},
							{
								"h": [
									0.003,
									0.001,
									0.025,
									0.013,
									0.01,
									0.008,
									0.002,
									0.014,
									0.001,
									0.0,
									0.003,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.004,
									0.0,
									0.002,
									0.004,
									0.001,
									0.001,
									0.0,
									0.0,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.012,
									0.0,
									0.002,
									0.0,
									0.0,
									0.002,
									0.005,
									0.001,
									0.022,
									0.015,
									0.003,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.005,
									0.0,
									0.004,
									0.003,
									0.001,
									0.006,
									0.001,
									0.014,
									0.003,
									0.0,
									0.0,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.022,
									0.001,
									0.002,
									0.002,
									0.013,
									0.0,
									0.003,
									0.001,
									0.001,
									0.0,
									0.001,
									0.015
								],
								"e": 0.005
							},
							{
								"h": [
									0.01,
									0.003,
									0.004,
									0.014,
									0.017,
									0.003,
									0.008,
									0.005,
									0.006,
									0.005,
									0.001,
									0.001
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.305,
									0.0,
									0.15,
									0.0,
									0.0,
									0.0,
									0.136,
									0.058,
									0.306,
									0.283,
									0.208,
									0.564
								],
								"e": 0.109
							},
							{
								"h": [
									0.077,
									0.066,
									0.334,
									0.038,
									0.144,
									0.478,
									0.221,
									0.41,
									0.396,
									0.248,
									0.293,
									0.013
								],
								"e": 0.285
							},
							{
								"h": [
									0.047,
									0.06,
									0.0,
									0.002,
									0.013,
									0.004,
									0.237,
									0.146,
									0.012,
									0.003,
									0.124,
									0.017
								],
								"e": 0.063
							},
							{
								"h": [
									0.064,
									0.023,
									0.001,
									0.001,
									0.055,
									0.063,
									0.004,
									0.001,
									0.002,
									0.012,
									0.049,
									0.01
								],
								"e": 0.036
							},
							{
								"h": [
									0.002,
									0.012,
									0.158,
									0.006,
									0.065,
									0.005,
									0.031,
									0.037,
									0.168,
									0.007,
									0.069,
									0.0
								],
								"e": 0.051
							},
							{
								"h": [
									0.001,
									0.0,
									0.006,
									0.064,
									0.019,
									0.0,
									0.036,
									0.0,
									0.001,
									0.033,
									0.214,
									0.046
								],
								"e": 0.038
							},
							{
								"h": [
									0.021,
									0.001,
									0.109,
									0.018,
									0.044,
									0.023,
									0.02,
									0.046,
									0.009,
									0.0,
									0.008,
									0.006
								],
								"e": 0.031
							},
							{
								"h": [
									0.009,
									0.005,
									0.0,
									0.008,
									0.0,
									0.048,
									0.006,
									0.003,
									0.004,
									0.001,
									0.0,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.022,
									0.001,
									0.002,
									0.005,
									0.001,
									0.017,
									0.012,
									0.027,
									0.028,
									0.045,
									0.013,
									0.011
								],
								"e": 0.014
							},
							{
								"h": [
									0.004,
									0.004,
									0.009,
									0.033,
									0.003,
									0.003,
									0.001,
									0.07,
									0.001,
									0.001,
									0.001,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.003,
									0.002,
									0.002,
									0.005,
									0.005,
									0.001,
									0.005,
									0.015,
									0.011,
									0.002,
									0.002,
									0.043
								],
								"e": 0.007
							},
							{
								"h": [
									0.009,
									0.001,
									0.001,
									0.008,
									0.025,
									0.003,
									0.002,
									0.002,
									0.018,
									0.01,
									0.013,
									0.004
								],
								"e": 0.008
							}
						],
						[
							{
								"h": [
									0.164,
									1.0,
									0.316,
									0.002,
									0.078,
									1.0,
									0.571,
									0.031,
									0.296,
									0.023,
									1.0,
									0.194
								],
								"e": 0.48
							},
							{
								"h": [
									0.031,
									0.02,
									0.402,
									0.045,
									0.19,
									0.04,
									0.051,
									0.068,
									0.146,
									0.1,
									0.419,
									1.0
								],
								"e": 0.438
							},
							{
								"h": [
									0.026,
									0.01,
									0.0,
									0.006,
									0.001,
									0.0,
									0.027,
									0.213,
									0.004,
									0.002,
									0.167,
									0.019
								],
								"e": 0.031
							},
							{
								"h": [
									1.0,
									0.002,
									0.0,
									0.0,
									0.004,
									0.024,
									0.005,
									0.0,
									0.0,
									0.006,
									0.061,
									0.008
								],
								"e": 0.116
							},
							{
								"h": [
									0.001,
									0.001,
									0.005,
									0.007,
									0.012,
									0.002,
									0.011,
									0.359,
									0.004,
									0.003,
									0.011,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.004,
									0.0,
									0.001,
									0.023,
									0.006,
									0.001,
									0.002,
									0.002,
									0.002,
									0.006,
									0.032,
									0.008
								],
								"e": 0.008
							},
							{
								"h": [
									0.008,
									0.003,
									0.012,
									0.015,
									0.004,
									0.018,
									0.07,
									0.051,
									0.0,
									0.0,
									0.013,
									0.003
								],
								"e": 0.016
							},
							{
								"h": [
									0.002,
									0.006,
									0.009,
									0.008,
									0.0,
									0.002,
									0.006,
									0.015,
									0.001,
									0.001,
									0.0,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.001,
									0.001,
									0.005,
									0.0,
									0.005,
									0.076,
									0.001,
									0.009,
									0.016,
									0.009,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.015,
									0.0,
									0.038,
									0.006,
									0.005,
									0.011,
									0.001,
									0.009,
									0.002,
									0.005,
									0.0,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.01,
									0.002,
									0.001,
									0.001,
									0.002,
									0.0,
									0.014,
									0.003,
									0.001,
									0.0,
									0.005,
									0.013
								],
								"e": 0.004
							},
							{
								"h": [
									0.022,
									0.001,
									0.0,
									0.003,
									0.002,
									0.0,
									0.001,
									0.0,
									0.027,
									0.001,
									0.0,
									0.0
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.038,
									0.0,
									0.059,
									0.0,
									0.0,
									0.0,
									0.127,
									0.033,
									0.175,
									0.277,
									0.123,
									0.125
								],
								"e": 0.051
							},
							{
								"h": [
									0.089,
									0.014,
									0.278,
									0.039,
									0.151,
									0.231,
									0.205,
									0.326,
									0.16,
									0.092,
									0.379,
									0.0
								],
								"e": 0.199
							},
							{
								"h": [
									0.012,
									0.005,
									0.0,
									0.004,
									0.004,
									0.001,
									0.021,
									0.208,
									0.011,
									0.01,
									0.188,
									0.024
								],
								"e": 0.032
							},
							{
								"h": [
									0.067,
									0.003,
									0.001,
									0.0,
									0.009,
									0.021,
									0.004,
									0.002,
									0.0,
									0.006,
									0.033,
									0.017
								],
								"e": 0.018
							},
							{
								"h": [
									0.002,
									0.001,
									0.005,
									0.016,
									0.019,
									0.002,
									0.031,
									0.012,
									0.021,
									0.006,
									0.016,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.144,
									1.0,
									0.004,
									0.019,
									0.005,
									1.0,
									0.001,
									0.001,
									0.71,
									0.025,
									0.014,
									0.014
								],
								"e": 0.409
							},
							{
								"h": [
									0.006,
									0.05,
									0.005,
									0.03,
									0.005,
									0.015,
									0.058,
									0.053,
									0.003,
									1.0,
									0.041,
									0.003
								],
								"e": 0.188
							},
							{
								"h": [
									0.0,
									0.01,
									0.627,
									0.013,
									0.0,
									0.002,
									0.016,
									0.007,
									0.001,
									0.004,
									1.0,
									0.018
								],
								"e": 0.158
							},
							{
								"h": [
									0.005,
									0.07,
									0.002,
									0.005,
									0.0,
									0.003,
									0.681,
									0.005,
									0.08,
									0.015,
									0.035,
									0.011
								],
								"e": 0.051
							},
							{
								"h": [
									0.037,
									0.011,
									0.072,
									0.011,
									0.007,
									0.005,
									0.032,
									0.023,
									0.008,
									1.0,
									0.0,
									0.002
								],
								"e": 0.098
							},
							{
								"h": [
									0.143,
									0.426,
									0.003,
									0.005,
									0.076,
									0.0,
									0.064,
									0.129,
									0.008,
									0.001,
									0.025,
									0.217
								],
								"e": 0.077
							},
							{
								"h": [
									0.021,
									0.01,
									0.001,
									0.01,
									0.004,
									0.003,
									0.006,
									0.004,
									0.023,
									0.196,
									0.051,
									0.0
								],
								"e": 0.027
							}
						],
						[
							{
								"h": [
									0.138,
									0.0,
									0.089,
									0.0,
									0.0,
									0.0,
									0.402,
									0.08,
									0.54,
									0.341,
									0.199,
									0.215
								],
								"e": 0.104
							},
							{
								"h": [
									0.164,
									0.111,
									0.414,
									0.05,
									0.167,
									0.581,
									0.138,
									0.394,
									0.324,
									0.194,
									0.38,
									0.015
								],
								"e": 0.304
							},
							{
								"h": [
									0.072,
									0.106,
									0.001,
									0.018,
									0.02,
									0.027,
									0.124,
									0.435,
									0.062,
									0.004,
									0.191,
									0.027
								],
								"e": 0.094
							},
							{
								"h": [
									0.018,
									0.056,
									0.007,
									0.002,
									0.1,
									0.07,
									0.005,
									0.001,
									0.01,
									0.03,
									0.049,
									0.011
								],
								"e": 0.05
							},
							{
								"h": [
									0.003,
									0.019,
									0.196,
									0.004,
									0.113,
									0.009,
									0.071,
									0.015,
									0.664,
									0.075,
									0.087,
									0.0
								],
								"e": 0.102
							},
							{
								"h": [
									1.0,
									0.003,
									0.116,
									0.153,
									0.004,
									0.146,
									0.003,
									0.001,
									0.63,
									0.109,
									0.156,
									0.061
								],
								"e": 0.214
							},
							{
								"h": [
									0.019,
									0.107,
									0.025,
									0.33,
									0.066,
									0.019,
									0.151,
									0.367,
									0.006,
									0.0,
									0.203,
									0.004
								],
								"e": 0.101
							},
							{
								"h": [
									0.001,
									0.116,
									0.004,
									0.055,
									0.0,
									0.014,
									0.007,
									0.198,
									0.001,
									0.002,
									0.0,
									0.001
								],
								"e": 0.026
							},
							{
								"h": [
									0.01,
									0.014,
									0.006,
									0.009,
									0.0,
									0.001,
									0.038,
									0.006,
									0.027,
									0.035,
									0.044,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.009,
									0.005,
									0.006,
									0.01,
									0.003,
									0.004,
									0.002,
									0.036,
									0.001,
									0.005,
									0.0,
									0.006
								],
								"e": 0.007
							},
							{
								"h": [
									0.007,
									0.004,
									0.002,
									0.003,
									0.005,
									0.0,
									0.007,
									0.017,
									0.012,
									0.0,
									0.009,
									0.022
								],
								"e": 0.007
							},
							{
								"h": [
									0.005,
									0.002,
									0.001,
									0.003,
									0.02,
									0.003,
									0.003,
									0.001,
									0.009,
									0.019,
									0.005,
									0.001
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									1.0,
									0.004,
									0.751,
									0.0,
									0.002,
									0.0,
									0.606,
									0.057,
									0.564,
									1.0,
									0.409,
									1.0
								],
								"e": 0.297
							},
							{
								"h": [
									0.099,
									0.088,
									0.557,
									0.071,
									0.271,
									0.745,
									0.129,
									0.834,
									0.315,
									0.283,
									0.713,
									0.001
								],
								"e": 0.396
							},
							{
								"h": [
									0.169,
									0.047,
									0.006,
									0.133,
									0.02,
									0.023,
									0.069,
									0.69,
									0.05,
									0.176,
									0.623,
									0.063
								],
								"e": 0.157
							},
							{
								"h": [
									0.042,
									0.009,
									0.011,
									0.007,
									0.069,
									0.048,
									0.132,
									0.098,
									0.007,
									0.025,
									0.166,
									0.045
								],
								"e": 0.075
							},
							{
								"h": [
									0.015,
									0.003,
									0.024,
									0.093,
									0.018,
									0.018,
									0.123,
									0.276,
									0.028,
									0.023,
									0.013,
									0.0
								],
								"e": 0.05
							},
							{
								"h": [
									0.005,
									0.0,
									0.025,
									0.043,
									0.019,
									0.043,
									0.005,
									0.001,
									0.024,
									0.088,
									0.045,
									0.022
								],
								"e": 0.031
							},
							{
								"h": [
									0.02,
									0.01,
									0.018,
									0.027,
									0.019,
									0.04,
									0.024,
									0.055,
									0.01,
									0.0,
									0.021,
									0.002
								],
								"e": 0.022
							},
							{
								"h": [
									0.004,
									0.015,
									0.002,
									0.012,
									0.0,
									0.005,
									0.012,
									0.017,
									0.003,
									0.0,
									0.0,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.012,
									0.001,
									0.004,
									0.002,
									0.002,
									0.009,
									0.01,
									0.009,
									0.063,
									0.111,
									0.022,
									0.004
								],
								"e": 0.018
							},
							{
								"h": [
									0.011,
									0.0,
									0.035,
									0.016,
									0.001,
									0.005,
									0.001,
									0.008,
									0.008,
									0.008,
									0.001,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.052,
									0.007,
									0.001,
									0.002,
									0.011,
									0.0,
									0.009,
									0.001,
									0.001,
									0.0,
									0.001,
									0.021
								],
								"e": 0.008
							},
							{
								"h": [
									0.01,
									0.002,
									0.002,
									0.044,
									0.015,
									0.001,
									0.009,
									0.001,
									0.011,
									0.006,
									0.0,
									0.002
								],
								"e": 0.009
							}
						],
						[
							{
								"h": [
									0.315,
									0.0,
									0.135,
									0.0,
									0.0,
									0.0,
									0.12,
									0.236,
									0.307,
									0.292,
									0.177,
									0.763
								],
								"e": 0.133
							},
							{
								"h": [
									0.312,
									0.286,
									0.557,
									0.069,
									0.206,
									0.536,
									0.218,
									0.465,
									0.408,
									0.174,
									0.544,
									0.021
								],
								"e": 0.417
							},
							{
								"h": [
									0.171,
									0.074,
									0.01,
									0.032,
									0.108,
									0.07,
									0.544,
									0.35,
									0.124,
									0.057,
									0.313,
									0.046
								],
								"e": 0.185
							},
							{
								"h": [
									0.058,
									0.143,
									0.023,
									0.023,
									0.062,
									0.092,
									0.047,
									0.022,
									0.046,
									0.061,
									0.159,
									0.08
								],
								"e": 0.103
							},
							{
								"h": [
									0.014,
									0.123,
									0.204,
									0.026,
									0.081,
									0.035,
									0.128,
									0.073,
									0.248,
									0.028,
									0.078,
									0.0
								],
								"e": 0.096
							},
							{
								"h": [
									0.004,
									0.0,
									0.026,
									0.157,
									0.053,
									0.002,
									0.019,
									0.001,
									0.01,
									0.058,
									0.208,
									0.076
								],
								"e": 0.055
							},
							{
								"h": [
									0.035,
									0.002,
									0.052,
									0.03,
									0.056,
									0.077,
									0.136,
									0.063,
									0.098,
									0.0,
									0.029,
									0.01
								],
								"e": 0.056
							},
							{
								"h": [
									0.117,
									0.039,
									0.0,
									0.019,
									0.0,
									0.012,
									0.02,
									0.025,
									0.002,
									0.001,
									0.0,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.037,
									0.002,
									0.002,
									0.009,
									0.001,
									0.009,
									0.046,
									0.173,
									0.026,
									0.082,
									0.1,
									0.011
								],
								"e": 0.032
							},
							{
								"h": [
									0.005,
									0.006,
									0.012,
									0.13,
									0.001,
									0.007,
									0.004,
									0.065,
									0.002,
									0.003,
									0.002,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.004,
									0.004,
									0.002,
									0.007,
									0.008,
									0.001,
									0.008,
									0.01,
									0.01,
									0.007,
									0.004,
									0.074
								],
								"e": 0.01
							},
							{
								"h": [
									0.006,
									0.002,
									0.001,
									0.006,
									0.022,
									0.003,
									0.003,
									0.002,
									0.01,
									0.022,
									0.008,
									0.001
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.073,
									0.001,
									0.075,
									0.0,
									0.008,
									0.0,
									0.705,
									0.169,
									0.203,
									0.303,
									0.158,
									0.216
								],
								"e": 0.103
							},
							{
								"h": [
									0.238,
									0.11,
									0.762,
									0.083,
									0.261,
									0.381,
									0.45,
									0.204,
									0.252,
									0.096,
									0.808,
									0.004
								],
								"e": 0.383
							},
							{
								"h": [
									0.175,
									0.009,
									0.03,
									0.34,
									0.029,
									0.011,
									0.129,
									0.846,
									0.144,
									0.253,
									0.566,
									0.086
								],
								"e": 0.209
							},
							{
								"h": [
									0.001,
									0.051,
									0.018,
									0.011,
									0.041,
									0.063,
									0.18,
									1.0,
									0.006,
									0.077,
									0.226,
									0.366
								],
								"e": 0.219
							},
							{
								"h": [
									0.102,
									0.029,
									0.032,
									0.194,
									0.14,
									0.041,
									0.309,
									1.0,
									0.082,
									0.099,
									0.012,
									0.0
								],
								"e": 0.163
							},
							{
								"h": [
									0.039,
									0.0,
									0.008,
									0.123,
									0.041,
									0.02,
									0.022,
									0.008,
									0.042,
									0.145,
									0.068,
									0.024
								],
								"e": 0.049
							},
							{
								"h": [
									0.015,
									0.09,
									0.014,
									0.066,
									0.066,
									0.092,
									0.396,
									0.232,
									0.01,
									0.001,
									0.064,
									0.019
								],
								"e": 0.085
							},
							{
								"h": [
									0.008,
									0.035,
									0.011,
									0.044,
									0.0,
									0.006,
									0.035,
									0.05,
									0.002,
									0.01,
									0.0,
									0.002
								],
								"e": 0.016
							},
							{
								"h": [
									0.008,
									0.004,
									0.002,
									0.008,
									0.001,
									0.005,
									1.0,
									0.13,
									0.151,
									0.04,
									0.157,
									0.006
								],
								"e": 0.08
							},
							{
								"h": [
									0.329,
									0.001,
									0.082,
									0.105,
									0.009,
									0.012,
									0.01,
									0.075,
									0.027,
									0.008,
									0.001,
									0.001
								],
								"e": 0.047
							},
							{
								"h": [
									0.035,
									0.013,
									0.004,
									0.003,
									0.01,
									0.001,
									0.017,
									0.011,
									0.003,
									0.002,
									0.004,
									0.189
								],
								"e": 0.019
							},
							{
								"h": [
									0.025,
									0.006,
									0.001,
									0.008,
									0.003,
									0.0,
									0.004,
									0.005,
									0.023,
									0.004,
									0.011,
									0.0
								],
								"e": 0.008
							}
						],
						[
							{
								"h": [
									0.141,
									0.0,
									0.087,
									0.0,
									0.0,
									0.0,
									0.4,
									0.287,
									0.591,
									0.36,
									0.183,
									0.251
								],
								"e": 0.128
							},
							{
								"h": [
									0.538,
									0.393,
									0.629,
									0.076,
									0.227,
									0.67,
									0.139,
									0.479,
									0.328,
									0.164,
									0.61,
									0.018
								],
								"e": 0.477
							},
							{
								"h": [
									0.258,
									0.12,
									0.105,
									0.203,
									0.184,
									0.32,
									0.256,
									0.73,
									0.47,
									0.049,
									0.425,
									0.063
								],
								"e": 0.332
							},
							{
								"h": [
									0.008,
									0.474,
									0.241,
									0.166,
									0.094,
									0.099,
									0.069,
									0.065,
									0.257,
									0.173,
									0.178,
									0.087
								],
								"e": 0.275
							},
							{
								"h": [
									0.066,
									0.246,
									0.383,
									0.061,
									0.16,
									0.152,
									0.439,
									0.043,
									0.967,
									0.78,
									0.056,
									0.0
								],
								"e": 0.299
							},
							{
								"h": [
									0.858,
									0.0,
									0.974,
									0.562,
									0.038,
									0.0,
									0.033,
									0.002,
									0.049,
									0.302,
									0.114,
									0.085
								],
								"e": 0.249
							},
							{
								"h": [
									0.093,
									0.551,
									0.017,
									1.0,
									0.123,
									0.054,
									0.119,
									0.848,
									0.091,
									0.0,
									0.219,
									0.018
								],
								"e": 0.244
							},
							{
								"h": [
									0.017,
									0.037,
									0.001,
									0.166,
									0.0,
									0.008,
									0.01,
									0.925,
									0.004,
									0.034,
									0.0,
									0.005
								],
								"e": 0.066
							},
							{
								"h": [
									0.02,
									0.017,
									0.001,
									0.011,
									0.0,
									0.004,
									0.097,
									0.049,
									0.107,
									0.04,
									0.067,
									0.005
								],
								"e": 0.028
							},
							{
								"h": [
									0.01,
									0.003,
									0.01,
									0.062,
									0.001,
									0.003,
									0.002,
									0.029,
									0.001,
									0.001,
									0.001,
									0.008
								],
								"e": 0.009
							},
							{
								"h": [
									0.008,
									0.003,
									0.003,
									0.002,
									0.005,
									0.001,
									0.006,
									0.007,
									0.013,
									0.002,
									0.006,
									0.018
								],
								"e": 0.006
							},
							{
								"h": [
									0.005,
									0.002,
									0.0,
									0.003,
									0.017,
									0.002,
									0.003,
									0.001,
									0.01,
									0.013,
									0.002,
									0.001
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.263,
									0.001,
									0.719,
									0.0,
									0.012,
									0.0,
									0.563,
									0.484,
									0.417,
									0.259,
									0.238,
									0.3
								],
								"e": 0.194
							},
							{
								"h": [
									0.312,
									0.197,
									0.685,
									0.095,
									0.314,
									0.338,
									0.316,
									0.716,
									0.199,
									0.201,
									0.933,
									0.001
								],
								"e": 0.451
							},
							{
								"h": [
									0.49,
									0.025,
									0.603,
									1.0,
									0.19,
									0.138,
									0.101,
									0.919,
									0.124,
									1.0,
									1.0,
									0.109
								],
								"e": 0.56
							},
							{
								"h": [
									0.017,
									0.041,
									0.104,
									0.058,
									0.041,
									0.045,
									1.0,
									0.382,
									0.047,
									0.09,
									0.376,
									0.193
								],
								"e": 0.268
							},
							{
								"h": [
									0.119,
									0.022,
									0.025,
									0.385,
									0.044,
									0.039,
									0.342,
									0.529,
									0.036,
									0.086,
									0.038,
									0.0
								],
								"e": 0.146
							},
							{
								"h": [
									0.016,
									0.0,
									0.096,
									0.13,
									0.044,
									0.001,
									0.133,
									0.003,
									0.025,
									0.208,
									0.063,
									0.026
								],
								"e": 0.067
							},
							{
								"h": [
									0.13,
									0.125,
									0.056,
									0.147,
									0.01,
									0.108,
									0.025,
									0.16,
									0.099,
									0.0,
									0.072,
									0.013
								],
								"e": 0.086
							},
							{
								"h": [
									0.033,
									0.011,
									0.002,
									0.032,
									0.0,
									0.003,
									0.009,
									0.158,
									0.068,
									0.005,
									0.0,
									0.001
								],
								"e": 0.022
							},
							{
								"h": [
									0.01,
									0.001,
									0.002,
									0.002,
									0.003,
									0.022,
									0.024,
									0.102,
									0.041,
									0.175,
									0.015,
									0.008
								],
								"e": 0.027
							},
							{
								"h": [
									0.024,
									0.0,
									0.017,
									0.118,
									0.001,
									0.005,
									0.002,
									0.01,
									0.009,
									0.0,
									0.001,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.006,
									0.001,
									0.002,
									0.001,
									0.002,
									0.001,
									0.004,
									0.005,
									0.002,
									0.001,
									0.001,
									0.014
								],
								"e": 0.003
							},
							{
								"h": [
									0.012,
									0.001,
									0.001,
									0.013,
									0.005,
									0.0,
									0.002,
									0.001,
									0.005,
									0.001,
									0.0,
									0.001
								],
								"e": 0.004
							}
						],
						[
							{
								"h": [
									0.34,
									0.0,
									0.137,
									0.0,
									0.001,
									0.0,
									0.123,
									1.0,
									0.337,
									0.317,
									0.167,
									0.909
								],
								"e": 0.218
							},
							{
								"h": [
									1.0,
									1.0,
									0.768,
									0.1,
									0.278,
									0.523,
									0.208,
									0.531,
									0.389,
									0.131,
									0.829,
									0.027
								],
								"e": 0.72
							},
							{
								"h": [
									0.573,
									0.073,
									1.0,
									0.38,
									1.0,
									1.0,
									1.0,
									0.57,
									1.0,
									0.75,
									0.669,
									0.102
								],
								"e": 1.0
							},
							{
								"h": [
									0.033,
									1.0,
									1.0,
									1.0,
									0.056,
									0.132,
									0.549,
									0.886,
									1.0,
									0.294,
									0.43,
									0.689
								],
								"e": 1.0
							},
							{
								"h": [
									0.201,
									1.0,
									0.343,
									0.162,
									0.092,
									0.301,
									0.548,
									0.175,
									0.158,
									0.101,
									0.073,
									1.0
								],
								"e": 0.565
							},
							{
								"h": [
									0.001,
									0.0,
									0.289,
									0.503,
									0.225,
									0.0,
									0.602,
									0.002,
									0.006,
									0.084,
									0.193,
									0.141
								],
								"e": 0.191
							},
							{
								"h": [
									0.238,
									0.01,
									0.064,
									0.167,
									0.088,
									0.144,
									0.136,
									0.155,
									1.0,
									0.0,
									0.025,
									0.058
								],
								"e": 0.212
							},
							{
								"h": [
									0.287,
									0.034,
									0.0,
									0.033,
									0.0,
									0.013,
									0.013,
									0.033,
									0.016,
									0.009,
									0.0,
									0.0
								],
								"e": 0.034
							},
							{
								"h": [
									0.036,
									0.0,
									0.001,
									0.014,
									0.002,
									0.044,
									0.025,
									1.0,
									0.047,
									0.068,
									0.076,
									0.011
								],
								"e": 0.076
							},
							{
								"h": [
									0.006,
									0.002,
									0.01,
									0.464,
									0.001,
									0.013,
									0.001,
									0.083,
									0.001,
									0.0,
									0.006,
									0.0
								],
								"e": 0.034
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.005,
									0.005,
									0.002,
									0.003,
									0.005,
									0.009,
									0.01,
									0.002,
									0.045
								],
								"e": 0.006
							},
							{
								"h": [
									0.006,
									0.002,
									0.0,
									0.002,
									0.01,
									0.002,
									0.001,
									0.001,
									0.008,
									0.013,
									0.006,
									0.0
								],
								"e": 0.004
							}
						],
						[
							{
								"h": [
									0.177,
									0.971,
									0.319,
									1.0,
									1.0,
									0.693,
									0.608,
									0.437,
									0.349,
									0.025,
									0.914,
									0.285
								],
								"e": 1.0
							},
							{
								"h": [
									0.385,
									0.216,
									1.0,
									0.104,
									0.382,
									0.044,
									0.033,
									0.066,
									0.183,
									0.102,
									1.0,
									0.721
								],
								"e": 0.579
							},
							{
								"h": [
									0.38,
									0.003,
									0.296,
									0.731,
									0.096,
									0.08,
									0.173,
									1.0,
									0.353,
									0.527,
									0.786,
									0.098
								],
								"e": 0.416
							},
							{
								"h": [
									0.289,
									0.056,
									0.096,
									0.073,
									0.004,
									0.02,
									0.461,
									0.137,
									0.015,
									0.152,
									0.395,
									0.201
								],
								"e": 0.212
							},
							{
								"h": [
									0.145,
									0.095,
									0.01,
									0.114,
									0.044,
									0.079,
									0.167,
									0.895,
									0.004,
									0.048,
									0.003,
									0.0
								],
								"e": 0.133
							},
							{
								"h": [
									0.007,
									0.0,
									0.057,
									0.205,
									0.127,
									0.0,
									0.019,
									0.027,
									0.015,
									0.009,
									0.023,
									0.034
								],
								"e": 0.05
							},
							{
								"h": [
									0.078,
									0.028,
									0.006,
									0.064,
									0.015,
									0.156,
									0.335,
									0.214,
									0.054,
									0.0,
									0.013,
									0.054
								],
								"e": 0.085
							},
							{
								"h": [
									0.069,
									0.006,
									0.003,
									0.06,
									0.001,
									0.002,
									0.012,
									0.067,
									0.005,
									0.027,
									0.0,
									0.001
								],
								"e": 0.018
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.009,
									0.002,
									0.005,
									0.048,
									0.051,
									0.021,
									0.028,
									0.145,
									0.001
								],
								"e": 0.02
							},
							{
								"h": [
									0.023,
									0.0,
									0.022,
									0.067,
									0.003,
									0.033,
									0.001,
									0.029,
									0.002,
									0.003,
									0.001,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.006,
									0.001,
									0.001,
									0.0,
									0.002,
									0.001,
									0.013,
									0.011,
									0.001,
									0.003,
									0.009,
									0.007
								],
								"e": 0.004
							},
							{
								"h": [
									0.045,
									0.001,
									0.0,
									0.001,
									0.002,
									0.0,
									0.001,
									0.0,
									1.0,
									0.0,
									0.001,
									0.0
								],
								"e": 0.086
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": "ain",
					"o": ":",
					"t": ":",
					"r": [
						{
							"p": "ain",
							"b": 0.002,
							"k": {
								"ain": 0.591,
								"ie": 0.059,
								"ter": 0.031,
								"coat": 0.019,
								"ling": 0.013,
								",": 0.011,
								"ous": 0.01,
								"/": 0.007,
								"ings": 0.006,
								"ian": 0.005
							}
						},
						{
							"p": "ous",
							"b": 0.001,
							"k": {
								"ous": 0.532,
								"ain": 0.056,
								" Mercenary": 0.044,
								"'s": 0.024,
								" Cipher": 0.017,
								"ess": 0.016,
								"ie": 0.015,
								"ter": 0.012,
								"cia": 0.011,
								",": 0.009
							}
						},
						{
							"p": "ous",
							"b": 0.0,
							"k": {
								"ous": 0.818,
								"ess": 0.021,
								"'s": 0.02,
								" Mercenary": 0.011,
								"ain": 0.01,
								"iac": 0.005,
								",": 0.004,
								" Heroes": 0.004,
								"ie": 0.003,
								" King": 0.003
							}
						},
						{
							"p": "ous",
							"b": 0.0,
							"k": {
								"ous": 0.981,
								"'s": 0.003,
								"ess": 0.002,
								" Mercenary": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": "ous",
							"b": 0.0,
							"k": {
								"ous": 0.972,
								"ess": 0.014,
								"'s": 0.002,
								" Mercenary": 0.002,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": "ous",
							"b": 0.062,
							"k": {
								"ous": 0.684,
								"ess": 0.193,
								":": 0.062,
								"'s": 0.008,
								"ie": 0.002,
								" Queen": 0.002,
								",": 0.001,
								"/": 0.001,
								"es": 0.001,
								"ly": 0.001
							}
						},
						{
							"p": "ous",
							"b": 0.08,
							"k": {
								"ous": 0.852,
								":": 0.08,
								"ess": 0.048,
								"'s": 0.004,
								" vs": 0.002,
								" Crew": 0.001,
								" Enemy": 0.001,
								" Weak": 0.001,
								"!": 0.0,
								"\"": 0.0
							}
						},
						{
							"p": ":",
							"b": 0.89,
							"k": {
								":": 0.89,
								"ous": 0.107,
								"ess": 0.002,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": ":",
							"b": 0.999,
							"k": {
								":": 0.999,
								"ous": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": ":",
							"b": 1.0,
							"k": {
								":": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ":",
							"b": 1.0,
							"k": {
								":": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ":",
							"b": 0.985,
							"k": {
								":": 0.985,
								"/": 0.001,
								"'s": 0.001,
								" 1": 0.001,
								" (": 0.001,
								" 2": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.287,
									0.0,
									0.609,
									0.0,
									0.002,
									0.007,
									0.36,
									0.004,
									0.577,
									0.842,
									0.43,
									1.0
								],
								"e": 0.22
							},
							{
								"h": [
									0.007,
									0.054,
									0.345,
									1.0,
									1.0,
									0.75,
									0.111,
									1.0,
									1.0,
									1.0,
									0.023,
									0.076
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.004,
									0.173,
									0.386,
									0.068,
									0.437,
									0.627,
									0.38,
									0.288,
									0.386,
									1.0
								],
								"e": 0.928
							},
							{
								"h": [
									0.621,
									0.424,
									0.042,
									1.0,
									1.0,
									1.0,
									0.286,
									0.392,
									0.135,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.218,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.259,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.023,
									0.088,
									1.0,
									1.0,
									1.0,
									0.061,
									1.0,
									1.0,
									0.848,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.121,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.208,
									1.0,
									0.228,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.517,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.813
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.387
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.18,
									0.0,
									0.484,
									0.0,
									0.0,
									0.0,
									0.148,
									0.001,
									0.547,
									0.712,
									0.31,
									0.746
								],
								"e": 0.159
							},
							{
								"h": [
									0.002,
									0.021,
									0.14,
									0.02,
									0.136,
									0.346,
									0.048,
									0.204,
									0.461,
									0.141,
									0.192,
									0.148
								],
								"e": 0.213
							},
							{
								"h": [
									0.036,
									0.026,
									0.0,
									0.003,
									0.005,
									0.002,
									0.041,
									0.047,
									0.012,
									0.001,
									0.09,
									0.007
								],
								"e": 0.029
							},
							{
								"h": [
									0.036,
									0.013,
									0.0,
									0.0,
									0.06,
									0.047,
									0.001,
									0.0,
									0.0,
									0.004,
									0.009,
									0.003
								],
								"e": 0.025
							},
							{
								"h": [
									0.0,
									0.001,
									0.166,
									0.001,
									0.125,
									0.002,
									0.01,
									0.003,
									0.137,
									0.021,
									0.103,
									0.0
								],
								"e": 0.056
							},
							{
								"h": [
									0.01,
									0.025,
									0.006,
									0.078,
									0.001,
									0.044,
									0.0,
									0.0,
									0.207,
									0.093,
									0.161,
									0.096
								],
								"e": 0.067
							},
							{
								"h": [
									0.017,
									0.048,
									0.039,
									0.091,
									0.03,
									0.01,
									0.081,
									0.08,
									0.0,
									0.161,
									0.026,
									0.002
								],
								"e": 0.046
							},
							{
								"h": [
									0.0,
									0.01,
									0.227,
									0.046,
									0.001,
									0.011,
									0.011,
									0.089,
									0.002,
									0.006,
									0.893,
									0.091
								],
								"e": 0.088
							},
							{
								"h": [
									0.017,
									0.601,
									0.056,
									0.008,
									0.001,
									0.003,
									0.251,
									0.001,
									0.018,
									0.016,
									0.015,
									0.007
								],
								"e": 0.054
							},
							{
								"h": [
									0.044,
									0.075,
									0.02,
									0.007,
									0.06,
									0.002,
									0.015,
									0.031,
									0.002,
									0.017,
									0.004,
									0.595
								],
								"e": 0.044
							},
							{
								"h": [
									0.015,
									0.03,
									0.11,
									0.167,
									0.017,
									0.002,
									0.041,
									0.087,
									0.334,
									0.001,
									0.004,
									0.058
								],
								"e": 0.06
							},
							{
								"h": [
									0.219,
									0.022,
									0.062,
									0.045,
									0.071,
									0.026,
									0.027,
									0.06,
									0.086,
									0.032,
									0.119,
									0.233
								],
								"e": 0.071
							}
						],
						[
							{
								"h": [
									0.246,
									0.005,
									0.648,
									0.0,
									0.002,
									0.0,
									0.116,
									0.0,
									0.11,
									0.86,
									0.272,
									0.336
								],
								"e": 0.131
							},
							{
								"h": [
									0.004,
									0.015,
									0.192,
									0.027,
									0.137,
									0.18,
									0.039,
									0.14,
									0.196,
									0.144,
									0.294,
									0.046
								],
								"e": 0.159
							},
							{
								"h": [
									0.054,
									0.015,
									0.0,
									0.005,
									0.002,
									0.0,
									0.011,
									0.133,
									0.001,
									0.003,
									0.164,
									0.013
								],
								"e": 0.033
							},
							{
								"h": [
									0.006,
									0.001,
									0.0,
									0.0,
									0.028,
									0.029,
									0.01,
									0.0,
									0.0,
									0.001,
									0.013,
									0.002
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.0,
									0.029,
									0.002,
									0.006,
									0.002,
									0.006,
									0.013,
									0.014,
									0.001,
									0.006,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.009,
									0.0,
									0.004,
									0.0,
									0.0,
									0.004,
									0.085,
									0.088,
									0.028
								],
								"e": 0.018
							},
							{
								"h": [
									0.005,
									0.002,
									0.018,
									0.03,
									0.005,
									0.005,
									0.01,
									0.021,
									0.0,
									0.011,
									0.008,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.005,
									0.006,
									0.008,
									0.0,
									0.002,
									0.007,
									0.01,
									0.001,
									0.001,
									0.007,
									0.017
								],
								"e": 0.005
							},
							{
								"h": [
									0.004,
									0.014,
									0.006,
									0.003,
									0.0,
									0.002,
									0.026,
									0.001,
									0.008,
									0.011,
									0.002,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.004,
									0.002,
									0.005,
									0.002,
									0.006,
									0.003,
									0.004,
									0.018,
									0.006,
									0.002,
									0.001,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.007,
									0.005,
									0.007,
									0.021,
									0.003,
									0.001,
									0.006,
									0.001,
									0.007,
									0.0,
									0.002,
									0.008
								],
								"e": 0.005
							},
							{
								"h": [
									0.307,
									0.047,
									0.023,
									0.022,
									0.007,
									0.002,
									0.009,
									0.009,
									0.084,
									0.003,
									0.007,
									0.009
								],
								"e": 0.025
							}
						],
						[
							{
								"h": [
									0.26,
									0.001,
									0.459,
									0.0,
									0.001,
									0.0,
									0.234,
									0.002,
									0.151,
									0.795,
									0.137,
									0.495
								],
								"e": 0.13
							},
							{
								"h": [
									0.003,
									0.01,
									0.275,
									0.03,
									0.16,
									0.43,
									0.014,
									0.147,
									0.159,
									0.111,
									0.372,
									0.057
								],
								"e": 0.181
							},
							{
								"h": [
									0.01,
									0.012,
									0.0,
									0.003,
									0.001,
									0.001,
									0.012,
									0.214,
									0.005,
									0.006,
									0.229,
									0.021
								],
								"e": 0.039
							},
							{
								"h": [
									0.011,
									0.001,
									0.0,
									0.0,
									0.076,
									0.026,
									0.004,
									0.001,
									0.0,
									0.001,
									0.027,
									0.003
								],
								"e": 0.022
							},
							{
								"h": [
									0.0,
									0.0,
									0.012,
									0.004,
									0.003,
									0.0,
									0.015,
									0.027,
									0.022,
									0.001,
									0.008,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.006,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.049,
									0.012,
									0.031
								],
								"e": 0.009
							},
							{
								"h": [
									0.003,
									0.001,
									0.02,
									0.011,
									0.003,
									0.006,
									0.002,
									0.016,
									0.0,
									0.001,
									0.003,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.001,
									0.003,
									0.003,
									0.0,
									0.001,
									0.006,
									0.001,
									0.0,
									0.001,
									0.003,
									0.014
								],
								"e": 0.003
							},
							{
								"h": [
									0.009,
									0.004,
									0.003,
									0.0,
									0.0,
									0.001,
									0.029,
									0.0,
									0.018,
									0.01,
									0.002,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.007,
									0.002,
									0.003,
									0.002,
									0.002,
									0.004,
									0.007,
									0.013,
									0.006,
									0.001,
									0.0,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.022,
									0.013,
									0.008,
									0.027,
									0.008,
									0.0,
									0.02,
									0.001,
									0.002,
									0.0,
									0.008,
									0.039
								],
								"e": 0.012
							},
							{
								"h": [
									0.358,
									0.032,
									0.035,
									0.039,
									0.022,
									0.012,
									0.027,
									0.028,
									0.02,
									0.014,
									0.012,
									0.007
								],
								"e": 0.033
							}
						],
						[
							{
								"h": [
									0.33,
									0.0,
									0.685,
									0.0,
									0.0,
									0.0,
									0.043,
									0.002,
									0.806,
									0.627,
									0.289,
									0.668
								],
								"e": 0.177
							},
							{
								"h": [
									0.006,
									0.085,
									0.381,
									0.034,
									0.164,
									0.463,
									0.089,
									0.284,
									0.438,
									0.094,
									0.291,
									0.177
								],
								"e": 0.279
							},
							{
								"h": [
									0.053,
									0.039,
									0.0,
									0.003,
									0.008,
									0.004,
									0.253,
									0.127,
									0.012,
									0.002,
									0.128,
									0.016
								],
								"e": 0.073
							},
							{
								"h": [
									0.05,
									0.022,
									0.001,
									0.0,
									0.053,
									0.037,
									0.006,
									0.001,
									0.002,
									0.009,
									0.022,
									0.015
								],
								"e": 0.029
							},
							{
								"h": [
									0.001,
									0.008,
									0.206,
									0.006,
									0.065,
									0.006,
									0.011,
									0.013,
									0.093,
									0.002,
									0.075,
									0.0
								],
								"e": 0.044
							},
							{
								"h": [
									0.0,
									0.0,
									0.003,
									0.064,
									0.011,
									0.0,
									0.001,
									0.0,
									0.004,
									0.093,
									0.232,
									0.052
								],
								"e": 0.036
							},
							{
								"h": [
									0.046,
									0.002,
									0.1,
									0.075,
									0.037,
									0.021,
									0.018,
									0.059,
									0.001,
									0.005,
									0.008,
									0.013
								],
								"e": 0.034
							},
							{
								"h": [
									0.009,
									0.005,
									0.014,
									0.022,
									0.001,
									0.077,
									0.012,
									0.007,
									0.001,
									0.005,
									0.041,
									0.002
								],
								"e": 0.018
							},
							{
								"h": [
									0.029,
									0.02,
									0.011,
									0.007,
									0.011,
									0.041,
									0.057,
									0.023,
									0.065,
									0.067,
									0.019,
									0.007
								],
								"e": 0.026
							},
							{
								"h": [
									0.022,
									0.028,
									0.015,
									0.108,
									0.023,
									0.006,
									0.003,
									0.043,
									0.002,
									0.002,
									0.018,
									0.004
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.006,
									0.024,
									0.065,
									0.046,
									0.006,
									0.01,
									0.091,
									0.088,
									0.001,
									0.001,
									0.072
								],
								"e": 0.03
							},
							{
								"h": [
									0.214,
									0.008,
									0.028,
									0.152,
									0.052,
									0.009,
									0.01,
									0.034,
									0.029,
									0.006,
									0.171,
									1.0
								],
								"e": 0.113
							}
						],
						[
							{
								"h": [
									0.808,
									0.017,
									0.2,
									0.012,
									0.055,
									0.0,
									0.8,
									0.066,
									0.282,
									0.596,
									0.204,
									0.293
								],
								"e": 0.237
							},
							{
								"h": [
									0.066,
									0.023,
									0.392,
									0.035,
									0.202,
									0.057,
									1.0,
									0.112,
									0.247,
									0.113,
									0.423,
									0.054
								],
								"e": 0.411
							},
							{
								"h": [
									0.042,
									0.009,
									0.0,
									0.008,
									0.011,
									0.0,
									0.019,
									0.186,
									0.004,
									0.004,
									0.187,
									0.015
								],
								"e": 0.038
							},
							{
								"h": [
									0.044,
									0.002,
									0.001,
									0.001,
									0.009,
									0.026,
									0.008,
									0.0,
									0.0,
									0.004,
									0.006,
									0.021
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.001,
									0.008,
									0.026,
									0.008,
									0.003,
									0.012,
									0.026,
									0.012,
									0.003,
									0.019,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.0,
									0.004,
									0.019,
									0.004,
									0.0,
									0.015,
									0.019,
									0.001,
									0.028,
									0.188,
									0.017
								],
								"e": 0.025
							},
							{
								"h": [
									0.019,
									0.01,
									0.006,
									0.036,
									0.003,
									0.013,
									0.016,
									0.062,
									0.013,
									0.0,
									0.014,
									0.006
								],
								"e": 0.015
							},
							{
								"h": [
									0.011,
									0.012,
									0.01,
									0.033,
									0.008,
									0.002,
									0.009,
									0.027,
									0.017,
									0.007,
									0.011,
									0.006
								],
								"e": 0.013
							},
							{
								"h": [
									0.002,
									0.006,
									0.002,
									0.004,
									0.003,
									0.086,
									0.094,
									0.004,
									0.004,
									0.021,
									0.003,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.03,
									0.001,
									0.011,
									0.017,
									0.015,
									0.003,
									0.017,
									0.008,
									0.003,
									0.006,
									0.003,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.015,
									0.007,
									0.01,
									0.009,
									0.002,
									0.002,
									0.09,
									0.003,
									0.001,
									0.002,
									0.012,
									0.01
								],
								"e": 0.013
							},
							{
								"h": [
									0.251,
									0.006,
									0.036,
									0.019,
									0.004,
									0.002,
									0.027,
									0.003,
									0.18,
									0.007,
									0.002,
									0.02
								],
								"e": 0.026
							}
						],
						[
							{
								"h": [
									0.083,
									0.987,
									0.084,
									0.002,
									0.058,
									1.0,
									0.112,
									0.001,
									0.15,
									0.215,
									1.0,
									0.307
								],
								"e": 0.501
							},
							{
								"h": [
									0.004,
									0.011,
									0.301,
									0.032,
									0.15,
									0.095,
									0.021,
									0.251,
									0.162,
									0.068,
									0.359,
									0.615
								],
								"e": 0.231
							},
							{
								"h": [
									0.008,
									0.002,
									0.0,
									0.002,
									0.001,
									0.0,
									0.019,
									0.166,
									0.004,
									0.002,
									0.221,
									0.021
								],
								"e": 0.033
							},
							{
								"h": [
									1.0,
									0.003,
									0.0,
									0.0,
									0.007,
									0.014,
									0.001,
									0.001,
									0.0,
									0.001,
									0.064,
									0.009
								],
								"e": 0.134
							},
							{
								"h": [
									0.0,
									0.0,
									0.012,
									0.014,
									0.008,
									0.002,
									0.006,
									0.224,
									0.04,
									0.002,
									0.017,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.007,
									0.002,
									0.0,
									0.001,
									0.002,
									0.001,
									0.012,
									0.036,
									0.065
								],
								"e": 0.011
							},
							{
								"h": [
									0.005,
									0.011,
									0.003,
									0.018,
									0.001,
									0.007,
									0.035,
									0.026,
									0.001,
									0.001,
									0.019,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.004,
									0.003,
									0.009,
									0.001,
									0.001,
									0.007,
									0.004,
									0.001,
									0.002,
									0.009,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.003,
									0.003,
									0.001,
									0.001,
									0.011,
									0.055,
									0.001,
									0.006,
									0.018,
									0.002,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.025,
									0.002,
									0.018,
									0.004,
									0.01,
									0.003,
									0.004,
									0.016,
									0.004,
									0.021,
									0.002,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.01,
									0.016,
									0.004,
									0.008,
									0.028,
									0.002,
									0.016,
									0.003,
									0.03,
									0.001,
									0.005,
									0.022
								],
								"e": 0.011
							},
							{
								"h": [
									0.487,
									0.02,
									0.009,
									0.032,
									0.008,
									0.006,
									0.005,
									0.006,
									0.581,
									0.01,
									0.005,
									0.009
								],
								"e": 0.047
							}
						],
						[
							{
								"h": [
									0.146,
									0.0,
									0.352,
									0.0,
									0.001,
									0.0,
									0.122,
									0.002,
									0.439,
									0.617,
									0.226,
									0.863
								],
								"e": 0.138
							},
							{
								"h": [
									0.017,
									0.121,
									0.466,
									0.043,
									0.199,
									0.439,
									0.05,
									0.222,
									0.339,
									0.124,
									0.376,
									0.287
								],
								"e": 0.299
							},
							{
								"h": [
									0.109,
									0.083,
									0.0,
									0.027,
									0.019,
									0.024,
									0.131,
									0.374,
									0.066,
									0.004,
									0.182,
									0.025
								],
								"e": 0.111
							},
							{
								"h": [
									0.022,
									0.033,
									0.006,
									0.002,
									0.065,
									0.054,
									0.011,
									0.001,
									0.009,
									0.022,
									0.025,
									0.012
								],
								"e": 0.036
							},
							{
								"h": [
									0.002,
									0.015,
									0.273,
									0.009,
									0.045,
									0.008,
									0.031,
									0.013,
									0.271,
									0.028,
									0.075,
									0.0
								],
								"e": 0.067
							},
							{
								"h": [
									1.0,
									1.0,
									0.043,
									0.179,
									0.003,
									1.0,
									0.005,
									0.0,
									1.0,
									0.112,
									0.122,
									0.119
								],
								"e": 0.648
							},
							{
								"h": [
									0.038,
									0.117,
									0.019,
									0.261,
									0.05,
									0.015,
									0.093,
									0.118,
									0.004,
									0.873,
									0.112,
									0.013
								],
								"e": 0.129
							},
							{
								"h": [
									0.002,
									0.079,
									1.0,
									0.093,
									0.001,
									0.019,
									0.035,
									0.321,
									0.001,
									0.013,
									0.242,
									0.226
								],
								"e": 0.159
							},
							{
								"h": [
									0.013,
									0.584,
									0.078,
									0.007,
									0.005,
									0.007,
									0.39,
									0.003,
									0.023,
									0.063,
									0.042,
									0.002
								],
								"e": 0.067
							},
							{
								"h": [
									0.013,
									0.062,
									0.014,
									0.006,
									0.013,
									0.005,
									0.008,
									0.01,
									0.001,
									0.016,
									0.01,
									0.971
								],
								"e": 0.046
							},
							{
								"h": [
									0.002,
									0.01,
									0.024,
									0.087,
									0.013,
									0.003,
									0.009,
									0.034,
									0.099,
									0.001,
									0.001,
									0.012
								],
								"e": 0.021
							},
							{
								"h": [
									0.281,
									0.007,
									0.007,
									0.013,
									0.017,
									0.016,
									0.009,
									0.011,
									0.015,
									0.01,
									0.034,
									0.027
								],
								"e": 0.022
							}
						],
						[
							{
								"h": [
									0.335,
									0.012,
									1.0,
									0.002,
									0.062,
									0.0,
									0.158,
									0.002,
									0.068,
									1.0,
									0.182,
									0.377
								],
								"e": 0.169
							},
							{
								"h": [
									0.017,
									0.1,
									0.611,
									0.058,
									0.3,
									1.0,
									0.038,
									0.288,
									0.262,
									0.205,
									0.704,
									0.065
								],
								"e": 0.378
							},
							{
								"h": [
									0.101,
									0.025,
									0.001,
									0.101,
									0.058,
									0.016,
									0.076,
									0.564,
									0.046,
									0.108,
									0.614,
									0.053
								],
								"e": 0.155
							},
							{
								"h": [
									0.092,
									0.009,
									0.009,
									0.009,
									0.104,
									0.057,
									0.292,
									0.144,
									0.005,
									0.013,
									0.099,
									0.073
								],
								"e": 0.095
							},
							{
								"h": [
									0.003,
									0.002,
									0.044,
									0.105,
									0.017,
									0.027,
									0.048,
									0.268,
									0.049,
									0.013,
									0.036,
									0.0
								],
								"e": 0.052
							},
							{
								"h": [
									0.001,
									0.003,
									0.01,
									0.025,
									0.004,
									0.056,
									0.002,
									0.0,
									0.034,
									0.218,
									0.417,
									0.094
								],
								"e": 0.076
							},
							{
								"h": [
									0.032,
									0.006,
									0.011,
									0.04,
									0.004,
									0.025,
									0.018,
									0.059,
									0.01,
									0.163,
									0.016,
									0.002
								],
								"e": 0.029
							},
							{
								"h": [
									0.002,
									0.021,
									0.02,
									0.021,
									0.001,
									0.001,
									0.023,
									0.037,
									0.001,
									0.001,
									0.016,
									0.016
								],
								"e": 0.013
							},
							{
								"h": [
									0.005,
									0.008,
									0.005,
									0.003,
									0.002,
									0.007,
									0.004,
									0.002,
									0.017,
									0.063,
									0.008,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.015,
									0.001,
									0.013,
									0.005,
									0.002,
									0.004,
									0.003,
									0.014,
									0.014,
									0.002,
									0.003,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.047,
									0.023,
									0.003,
									0.02,
									0.007,
									0.001,
									0.03,
									0.002,
									0.001,
									0.001,
									0.009,
									0.024
								],
								"e": 0.014
							},
							{
								"h": [
									0.248,
									0.018,
									0.024,
									0.054,
									0.01,
									0.003,
									0.013,
									0.003,
									0.005,
									0.009,
									0.005,
									0.015
								],
								"e": 0.021
							}
						],
						[
							{
								"h": [
									0.369,
									0.0,
									0.652,
									0.0,
									0.002,
									0.0,
									0.042,
									0.006,
									0.887,
									0.647,
									0.249,
									0.664
								],
								"e": 0.182
							},
							{
								"h": [
									0.032,
									0.325,
									0.657,
									0.062,
									0.252,
									0.503,
									0.096,
									0.263,
									0.405,
									0.098,
									0.55,
									0.341
								],
								"e": 0.411
							},
							{
								"h": [
									0.211,
									0.046,
									0.001,
									0.048,
									0.087,
									0.071,
									0.564,
									0.329,
									0.118,
									0.04,
									0.294,
									0.045
								],
								"e": 0.211
							},
							{
								"h": [
									0.052,
									0.125,
									0.02,
									0.01,
									0.051,
									0.058,
									0.055,
									0.023,
									0.045,
									0.055,
									0.066,
									0.119
								],
								"e": 0.081
							},
							{
								"h": [
									0.008,
									0.097,
									0.248,
									0.052,
									0.062,
									0.053,
									0.045,
									0.053,
									0.149,
									0.009,
									0.059,
									0.0
								],
								"e": 0.075
							},
							{
								"h": [
									0.001,
									0.001,
									0.024,
									0.179,
									0.042,
									0.004,
									0.005,
									0.001,
									0.038,
									0.12,
									0.278,
									0.113
								],
								"e": 0.067
							},
							{
								"h": [
									0.147,
									0.004,
									0.039,
									0.143,
									0.051,
									0.053,
									0.058,
									0.107,
									0.031,
									0.037,
									0.039,
									0.024
								],
								"e": 0.058
							},
							{
								"h": [
									0.309,
									0.082,
									0.061,
									0.056,
									0.001,
									0.022,
									0.034,
									0.069,
									0.002,
									0.017,
									0.222,
									0.006
								],
								"e": 0.061
							},
							{
								"h": [
									0.037,
									0.059,
									0.013,
									0.008,
									0.034,
									0.096,
									0.043,
									0.315,
									0.05,
									0.165,
									0.099,
									0.01
								],
								"e": 0.06
							},
							{
								"h": [
									0.012,
									0.028,
									0.019,
									0.379,
									0.009,
									0.009,
									0.005,
									0.033,
									0.004,
									0.003,
									0.075,
									0.002
								],
								"e": 0.033
							},
							{
								"h": [
									0.002,
									0.007,
									0.018,
									0.064,
									0.052,
									0.017,
									0.015,
									0.053,
									0.037,
									0.004,
									0.001,
									0.083
								],
								"e": 0.026
							},
							{
								"h": [
									0.166,
									0.005,
									0.015,
									0.121,
									0.025,
									0.009,
									0.006,
									0.026,
									0.012,
									0.008,
									0.074,
									0.177
								],
								"e": 0.042
							}
						],
						[
							{
								"h": [
									0.051,
									0.002,
									0.061,
									0.0,
									0.012,
									0.0,
									0.181,
									0.001,
									0.168,
									0.285,
									0.131,
									0.241
								],
								"e": 0.064
							},
							{
								"h": [
									0.049,
									0.089,
									0.872,
									0.066,
									0.309,
									0.236,
									0.016,
									0.137,
									0.241,
									0.114,
									0.819,
									0.106
								],
								"e": 0.303
							},
							{
								"h": [
									0.097,
									0.003,
									0.006,
									0.121,
									0.115,
									0.007,
									0.084,
									0.811,
									0.085,
									0.168,
									0.606,
									0.071
								],
								"e": 0.19
							},
							{
								"h": [
									0.017,
									0.064,
									0.014,
									0.009,
									0.024,
									0.065,
									0.145,
									0.286,
									0.007,
									0.027,
									0.107,
									0.183
								],
								"e": 0.095
							},
							{
								"h": [
									0.008,
									0.024,
									0.034,
									0.217,
									0.06,
									0.043,
									0.078,
									0.21,
									0.239,
									0.054,
									0.012,
									0.0
								],
								"e": 0.084
							},
							{
								"h": [
									0.001,
									0.001,
									0.014,
									0.12,
									0.021,
									0.009,
									0.001,
									0.011,
									0.03,
									0.089,
									0.11,
									0.053
								],
								"e": 0.04
							},
							{
								"h": [
									0.026,
									0.057,
									0.015,
									0.052,
									0.025,
									0.035,
									0.096,
									0.114,
									0.007,
									0.026,
									0.04,
									0.019
								],
								"e": 0.042
							},
							{
								"h": [
									0.022,
									0.055,
									0.041,
									0.092,
									0.002,
									0.007,
									0.062,
									0.144,
									0.004,
									0.066,
									0.292,
									0.008
								],
								"e": 0.055
							},
							{
								"h": [
									0.004,
									0.014,
									0.013,
									0.001,
									0.011,
									0.046,
									0.129,
									0.059,
									0.02,
									0.045,
									0.023,
									0.001
								],
								"e": 0.022
							},
							{
								"h": [
									0.127,
									0.001,
									0.043,
									0.075,
									0.012,
									0.018,
									0.01,
									0.093,
									0.011,
									0.004,
									0.015,
									0.001
								],
								"e": 0.032
							},
							{
								"h": [
									0.068,
									0.029,
									0.011,
									0.01,
									0.024,
									0.011,
									0.053,
									0.006,
									0.024,
									0.013,
									0.016,
									0.119
								],
								"e": 0.03
							},
							{
								"h": [
									0.275,
									0.021,
									0.022,
									0.038,
									0.005,
									0.005,
									0.036,
									0.012,
									0.301,
									0.013,
									0.013,
									0.011
								],
								"e": 0.037
							}
						],
						[
							{
								"h": [
									0.156,
									0.0,
									0.342,
									0.0,
									0.005,
									0.0,
									0.126,
									0.006,
									0.472,
									0.651,
									0.211,
									0.868
								],
								"e": 0.142
							},
							{
								"h": [
									0.066,
									0.37,
									0.715,
									0.065,
									0.28,
									0.439,
									0.062,
									0.25,
									0.337,
									0.115,
									0.608,
									0.417
								],
								"e": 0.435
							},
							{
								"h": [
									0.402,
									0.101,
									0.005,
									0.27,
									0.152,
									0.272,
									0.256,
									0.663,
									0.516,
									0.05,
									0.374,
									0.059
								],
								"e": 0.373
							},
							{
								"h": [
									0.011,
									0.353,
									0.246,
									0.073,
									0.066,
									0.083,
									0.125,
									0.077,
									0.334,
									0.103,
									0.086,
									0.134
								],
								"e": 0.212
							},
							{
								"h": [
									0.057,
									0.302,
									0.455,
									0.1,
									0.075,
									0.128,
									0.15,
									0.017,
									0.427,
									0.218,
									0.038,
									0.0
								],
								"e": 0.187
							},
							{
								"h": [
									0.598,
									0.0,
									0.402,
									0.647,
									0.024,
									0.0,
									0.003,
									0.002,
									0.291,
									0.183,
									0.059,
									0.199
								],
								"e": 0.229
							},
							{
								"h": [
									0.201,
									0.879,
									0.017,
									0.754,
									0.066,
									0.042,
									0.063,
									0.3,
									0.031,
									0.002,
									0.133,
									0.075
								],
								"e": 0.174
							},
							{
								"h": [
									0.03,
									0.036,
									0.088,
									0.232,
									0.002,
									0.013,
									0.039,
									0.874,
									0.003,
									0.108,
									0.041,
									0.282
								],
								"e": 0.116
							},
							{
								"h": [
									0.017,
									0.493,
									0.019,
									0.006,
									0.01,
									0.017,
									0.742,
									0.044,
									0.094,
									0.066,
									0.062,
									0.004
								],
								"e": 0.084
							},
							{
								"h": [
									0.012,
									0.04,
									0.02,
									0.046,
									0.004,
									0.003,
									0.004,
									0.021,
									0.001,
									0.008,
									0.035,
									1.0
								],
								"e": 0.048
							},
							{
								"h": [
									0.002,
									0.005,
									0.015,
									0.069,
									0.013,
									0.007,
									0.01,
									0.014,
									0.08,
									0.003,
									0.001,
									0.017
								],
								"e": 0.017
							},
							{
								"h": [
									0.32,
									0.006,
									0.006,
									0.02,
									0.012,
									0.01,
									0.007,
									0.014,
									0.012,
									0.008,
									0.023,
									0.026
								],
								"e": 0.021
							}
						],
						[
							{
								"h": [
									0.336,
									0.006,
									0.077,
									0.0,
									0.092,
									0.0,
									0.144,
									0.005,
									0.036,
									0.627,
									0.143,
									0.17
								],
								"e": 0.098
							},
							{
								"h": [
									0.058,
									0.17,
									0.787,
									0.076,
									0.369,
									0.287,
									0.049,
									0.231,
									0.196,
									0.146,
									0.955,
									0.149
								],
								"e": 0.368
							},
							{
								"h": [
									0.471,
									0.022,
									0.076,
									0.53,
									0.217,
									0.125,
									0.089,
									0.895,
									0.101,
									0.792,
									0.969,
									0.092
								],
								"e": 0.463
							},
							{
								"h": [
									0.022,
									0.051,
									0.078,
									0.063,
									0.046,
									0.081,
									1.0,
									0.425,
									0.033,
									0.059,
									0.226,
									0.132
								],
								"e": 0.211
							},
							{
								"h": [
									0.012,
									0.018,
									0.04,
									0.329,
									0.017,
									0.091,
									0.125,
									0.16,
									0.049,
									0.033,
									0.022,
									0.0
								],
								"e": 0.074
							},
							{
								"h": [
									0.005,
									0.001,
									0.042,
									0.093,
									0.025,
									0.005,
									0.001,
									0.001,
									0.102,
									0.137,
									0.118,
									0.057
								],
								"e": 0.05
							},
							{
								"h": [
									0.174,
									0.031,
									0.027,
									0.187,
									0.006,
									0.09,
									0.017,
									0.188,
									0.016,
									0.024,
									0.047,
									0.012
								],
								"e": 0.058
							},
							{
								"h": [
									0.023,
									0.016,
									0.022,
									0.051,
									0.002,
									0.003,
									0.01,
									0.197,
									0.006,
									0.016,
									0.016,
									0.013
								],
								"e": 0.024
							},
							{
								"h": [
									0.004,
									0.011,
									0.003,
									0.002,
									0.004,
									0.015,
									0.011,
									0.017,
									0.038,
									0.069,
									0.003,
									0.002
								],
								"e": 0.013
							},
							{
								"h": [
									0.007,
									0.0,
									0.006,
									0.067,
									0.001,
									0.004,
									0.005,
									0.018,
									0.008,
									0.002,
									0.007,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.017,
									0.007,
									0.006,
									0.011,
									0.003,
									0.002,
									0.014,
									0.002,
									0.005,
									0.002,
									0.005,
									0.029
								],
								"e": 0.008
							},
							{
								"h": [
									0.232,
									0.014,
									0.028,
									0.039,
									0.005,
									0.003,
									0.013,
									0.006,
									0.008,
									0.002,
									0.007,
									0.01
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.397,
									0.0,
									0.661,
									0.0,
									0.008,
									0.0,
									0.044,
									0.023,
									1.0,
									0.7,
									0.239,
									0.664
								],
								"e": 0.199
							},
							{
								"h": [
									0.119,
									1.0,
									0.912,
									0.089,
									0.35,
									0.469,
									0.096,
									0.285,
									0.385,
									0.087,
									0.847,
									0.464
								],
								"e": 0.643
							},
							{
								"h": [
									0.78,
									0.046,
									0.107,
									0.528,
									0.661,
									1.0,
									1.0,
									0.56,
									1.0,
									0.529,
									0.603,
									0.102
								],
								"e": 0.937
							},
							{
								"h": [
									0.034,
									1.0,
									1.0,
									0.42,
									0.049,
									0.084,
									0.647,
									1.0,
									1.0,
									0.245,
									0.165,
									0.923
								],
								"e": 0.745
							},
							{
								"h": [
									0.109,
									1.0,
									0.417,
									0.254,
									0.058,
									0.512,
									0.199,
									0.055,
									0.07,
									0.028,
									0.06,
									0.0
								],
								"e": 0.271
							},
							{
								"h": [
									0.001,
									0.0,
									0.293,
									0.645,
									0.19,
									0.0,
									0.009,
									0.003,
									0.035,
									0.147,
									0.178,
									0.133
								],
								"e": 0.141
							},
							{
								"h": [
									0.934,
									0.02,
									0.049,
									0.543,
									0.081,
									0.112,
									0.052,
									0.32,
									0.187,
									0.001,
									0.046,
									0.133
								],
								"e": 0.175
							},
							{
								"h": [
									1.0,
									0.022,
									0.002,
									0.112,
									0.002,
									0.032,
									0.026,
									0.041,
									0.015,
									0.102,
									0.007,
									0.002
								],
								"e": 0.092
							},
							{
								"h": [
									0.048,
									0.031,
									0.015,
									0.008,
									0.06,
									0.319,
									0.028,
									1.0,
									0.124,
									0.152,
									0.102,
									0.011
								],
								"e": 0.111
							},
							{
								"h": [
									0.021,
									0.02,
									0.018,
									0.811,
									0.007,
									0.014,
									0.002,
									0.038,
									0.003,
									0.001,
									0.135,
									0.004
								],
								"e": 0.055
							},
							{
								"h": [
									0.001,
									0.004,
									0.018,
									0.049,
									0.07,
									0.048,
									0.01,
									0.043,
									0.055,
									0.011,
									0.001,
									0.053
								],
								"e": 0.028
							},
							{
								"h": [
									0.136,
									0.005,
									0.011,
									0.096,
									0.025,
									0.007,
									0.005,
									0.023,
									0.018,
									0.008,
									0.08,
									0.068
								],
								"e": 0.032
							}
						],
						[
							{
								"h": [
									1.0,
									0.015,
									0.189,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.342,
									0.691,
									0.176,
									0.289
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.22,
									1.0,
									0.081,
									0.433,
									0.058,
									0.733,
									0.1,
									0.292,
									0.13,
									1.0,
									0.121
								],
								"e": 0.855
							},
							{
								"h": [
									0.652,
									0.003,
									1.0,
									1.0,
									1.0,
									0.107,
									0.112,
									1.0,
									0.365,
									1.0,
									0.797,
									0.086
								],
								"e": 1.0
							},
							{
								"h": [
									0.014,
									0.098,
									0.595,
									0.517,
									0.007,
									0.029,
									0.756,
									0.175,
									0.056,
									0.126,
									0.05,
									0.535
								],
								"e": 0.307
							},
							{
								"h": [
									0.11,
									0.087,
									0.017,
									0.825,
									0.02,
									0.143,
									0.147,
									0.083,
									0.009,
									0.052,
									0.008,
									1.0
								],
								"e": 0.327
							},
							{
								"h": [
									0.0,
									0.0,
									0.403,
									0.277,
									0.135,
									0.0,
									0.247,
									0.231,
									0.014,
									0.03,
									0.078,
									0.064
								],
								"e": 0.172
							},
							{
								"h": [
									0.377,
									0.084,
									0.007,
									0.101,
									0.007,
									0.116,
									0.037,
									0.241,
									1.0,
									0.0,
									0.012,
									0.159
								],
								"e": 0.201
							},
							{
								"h": [
									0.83,
									0.013,
									0.002,
									0.225,
									0.026,
									0.004,
									0.02,
									0.14,
									0.129,
									0.254,
									0.004,
									0.011
								],
								"e": 0.121
							},
							{
								"h": [
									0.004,
									0.003,
									0.002,
									0.003,
									0.02,
									0.931,
									0.036,
									0.848,
									0.007,
									0.048,
									0.051,
									0.0
								],
								"e": 0.099
							},
							{
								"h": [
									0.033,
									0.0,
									0.011,
									0.156,
									0.008,
									0.011,
									0.003,
									0.024,
									0.003,
									0.001,
									0.041,
									0.001
								],
								"e": 0.018
							},
							{
								"h": [
									0.008,
									0.002,
									0.007,
									0.003,
									0.002,
									0.018,
									0.042,
									0.009,
									0.001,
									0.026,
									0.012,
									0.005
								],
								"e": 0.012
							},
							{
								"h": [
									0.296,
									0.004,
									0.012,
									0.007,
									0.003,
									0.001,
									0.013,
									0.003,
									0.905,
									0.004,
									0.005,
									0.046
								],
								"e": 0.053
							}
						],
						[
							{
								"h": [
									0.094,
									1.0,
									0.079,
									0.154,
									0.925,
									0.667,
									0.127,
									0.006,
									0.178,
									0.245,
									0.94,
									0.3
								],
								"e": 0.616
							},
							{
								"h": [
									0.089,
									0.138,
									0.732,
									0.069,
									0.304,
									0.099,
									0.019,
									0.227,
									0.175,
									0.069,
									0.864,
									1.0
								],
								"e": 0.425
							},
							{
								"h": [
									0.157,
									0.001,
									0.113,
									0.222,
									0.162,
									0.171,
									0.092,
									0.692,
									0.424,
									0.422,
									1.0,
									0.116
								],
								"e": 0.392
							},
							{
								"h": [
									0.192,
									0.102,
									0.17,
									0.131,
									0.009,
									0.014,
									0.145,
									0.579,
									0.102,
									0.038,
									0.59,
									0.252
								],
								"e": 0.243
							},
							{
								"h": [
									0.04,
									0.04,
									0.012,
									0.589,
									0.016,
									0.109,
									0.087,
									1.0,
									0.028,
									0.019,
									0.007,
									0.0
								],
								"e": 0.157
							},
							{
								"h": [
									0.002,
									0.0,
									0.049,
									0.06,
									0.067,
									0.0,
									0.024,
									0.037,
									0.007,
									0.029,
									0.032,
									0.196
								],
								"e": 0.053
							},
							{
								"h": [
									0.067,
									0.325,
									0.006,
									0.089,
									0.004,
									0.1,
									0.171,
									0.145,
									0.042,
									0.0,
									0.021,
									0.059
								],
								"e": 0.081
							},
							{
								"h": [
									0.049,
									0.011,
									0.003,
									0.13,
									0.002,
									0.002,
									0.045,
									0.084,
									0.008,
									0.106,
									0.004,
									0.034
								],
								"e": 0.037
							},
							{
								"h": [
									0.005,
									0.005,
									0.005,
									0.001,
									0.014,
									0.038,
									0.132,
									0.144,
									0.048,
									0.053,
									0.099,
									0.001
								],
								"e": 0.034
							},
							{
								"h": [
									0.032,
									0.003,
									0.047,
									0.07,
									0.01,
									0.015,
									0.001,
									0.07,
									0.005,
									0.009,
									0.034,
									0.007
								],
								"e": 0.022
							},
							{
								"h": [
									0.005,
									0.006,
									0.005,
									0.007,
									0.014,
									0.019,
									0.01,
									0.004,
									0.131,
									0.039,
									0.004,
									0.04
								],
								"e": 0.02
							},
							{
								"h": [
									0.53,
									0.004,
									0.002,
									0.034,
									0.005,
									0.003,
									0.002,
									0.004,
									1.0,
									0.004,
									0.018,
									0.078
								],
								"e": 0.067
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ":",
					"o": " Voldemort",
					"t": " The",
					"r": [
						{
							"p": " The",
							"b": 0.052,
							"k": {
								" The": 0.052,
								" '": 0.029,
								" http": 0.025,
								"19": 0.02,
								" \"": 0.017,
								" ": 0.016,
								" [": 0.016,
								" -": 0.015,
								"59": 0.014,
								" A": 0.011
							}
						},
						{
							"p": " The",
							"b": 0.058,
							"k": {
								" The": 0.058,
								"19": 0.031,
								" -": 0.019,
								" http": 0.016,
								" Three": 0.015,
								" A": 0.014,
								" Unknown": 0.014,
								" '": 0.013,
								"59": 0.013,
								" 1": 0.012
							}
						},
						{
							"p": " The",
							"b": 0.035,
							"k": {
								" The": 0.035,
								" 1": 0.027,
								" Yeah": 0.027,
								" An": 0.023,
								" Rise": 0.023,
								" Yes": 0.019,
								" \"": 0.014,
								" -": 0.013,
								"19": 0.013,
								" Unknown": 0.013
							}
						},
						{
							"p": " The",
							"b": 0.065,
							"k": {
								" The": 0.065,
								" Death": 0.053,
								" Yeah": 0.041,
								" Yes": 0.019,
								" An": 0.018,
								" My": 0.018,
								" Who": 0.015,
								" Great": 0.014,
								" Py": 0.013,
								"19": 0.012
							}
						},
						{
							"p": " Yeah",
							"b": 0.014,
							"k": {
								" Yeah": 0.101,
								" Yes": 0.063,
								" No": 0.03,
								" Death": 0.027,
								" An": 0.026,
								" Oh": 0.022,
								" TBD": 0.022,
								" Rise": 0.015,
								" Buster": 0.015,
								" The": 0.014
							}
						},
						{
							"p": " ???",
							"b": 0.009,
							"k": {
								" ???": 0.047,
								" TBD": 0.026,
								" No": 0.025,
								" Pol": 0.025,
								" Rod": 0.023,
								" Oh": 0.022,
								" R": 0.021,
								" Yes": 0.019,
								" Bans": 0.019,
								" An": 0.017
							}
						},
						{
							"p": " Man",
							"b": 0.009,
							"k": {
								" Man": 0.081,
								" Gun": 0.069,
								" Rod": 0.055,
								" ???": 0.039,
								" D": 0.037,
								" Bob": 0.027,
								" Dod": 0.026,
								" An": 0.018,
								" Nin": 0.015,
								" Duck": 0.014
							}
						},
						{
							"p": " Man",
							"b": 0.001,
							"k": {
								" Man": 0.139,
								" Duck": 0.066,
								" D": 0.063,
								" Night": 0.043,
								" Kid": 0.04,
								" Bob": 0.034,
								" Pol": 0.033,
								" Ben": 0.025,
								" Nin": 0.024,
								" Death": 0.022
							}
						},
						{
							"p": " Kid",
							"b": 0.001,
							"k": {
								" Kid": 0.071,
								" Robin": 0.056,
								" Superman": 0.045,
								" Night": 0.044,
								" Batman": 0.039,
								" Man": 0.037,
								" Pol": 0.035,
								" Gun": 0.034,
								" Harry": 0.026,
								" Tom": 0.023
							}
						},
						{
							"p": " Superman",
							"b": 0.0,
							"k": {
								" Superman": 0.338,
								" Batman": 0.274,
								" Harry": 0.231,
								" Voldemort": 0.084,
								" Robin": 0.008,
								" Spider": 0.006,
								" Death": 0.004,
								" Joker": 0.004,
								" Dracula": 0.004,
								" Frankenstein": 0.003
							}
						},
						{
							"p": " Superman",
							"b": 0.006,
							"k": {
								" Superman": 0.522,
								" Batman": 0.097,
								" Voldemort": 0.085,
								" Harry": 0.043,
								" Dracula": 0.029,
								" Spider": 0.026,
								" Robin": 0.015,
								" Joker": 0.015,
								" The": 0.006,
								" Death": 0.006
							}
						},
						{
							"p": " Voldemort",
							"b": 0.07,
							"k": {
								" The": 0.07,
								" Voldemort": 0.07,
								" Harry": 0.055,
								" Superman": 0.054,
								" Batman": 0.034,
								" Robin": 0.025,
								" Lord": 0.023,
								" the": 0.012,
								" Death": 0.012,
								" Spider": 0.011
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.002,
									0.477,
									0.002,
									0.188,
									0.029,
									1.0,
									0.043,
									0.039,
									1.0,
									0.656,
									0.332
								],
								"e": 0.329
							},
							{
								"h": [
									0.035,
									0.071,
									0.342,
									1.0,
									1.0,
									0.665,
									1.0,
									1.0,
									1.0,
									1.0,
									0.027,
									0.337
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.012,
									0.133,
									0.554,
									0.12,
									0.461,
									0.62,
									0.704,
									0.085,
									0.555,
									1.0
								],
								"e": 0.924
							},
							{
								"h": [
									0.962,
									0.175,
									0.042,
									0.688,
									1.0,
									1.0,
									0.088,
									0.021,
									0.224,
									1.0,
									1.0,
									0.746
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.259,
									1.0,
									0.156,
									1.0,
									1.0,
									1.0,
									0.332,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.265,
									0.484,
									0.991,
									1.0,
									1.0,
									0.236,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.958,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.726,
									0.701,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.216,
									0.528,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.024,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.309,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.374,
									1.0,
									1.0,
									1.0,
									0.214
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.003,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.048,
									0.592,
									0.279,
									0.0,
									0.048,
									1.0,
									0.024,
									0.007,
									0.516,
									0.795,
									0.947,
									0.09
								],
								"e": 0.411
							},
							{
								"h": [
									0.004,
									0.011,
									0.135,
									0.019,
									0.133,
									0.739,
									0.299,
									0.132,
									0.482,
									0.202,
									0.176,
									0.446
								],
								"e": 0.269
							},
							{
								"h": [
									0.023,
									0.023,
									0.0,
									0.0,
									0.002,
									0.001,
									0.026,
									0.047,
									0.003,
									0.0,
									0.094,
									0.005
								],
								"e": 0.021
							},
							{
								"h": [
									0.383,
									0.001,
									0.0,
									0.0,
									0.138,
									0.077,
									0.0,
									0.0,
									0.0,
									0.005,
									0.017,
									0.002
								],
								"e": 0.09
							},
							{
								"h": [
									0.0,
									0.0,
									0.086,
									0.0,
									0.097,
									0.0,
									0.01,
									0.019,
									0.066,
									0.005,
									0.05,
									0.0
								],
								"e": 0.037
							},
							{
								"h": [
									0.001,
									0.0,
									0.003,
									0.04,
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.097,
									0.234,
									0.104
								],
								"e": 0.035
							},
							{
								"h": [
									0.004,
									0.003,
									0.064,
									0.011,
									0.082,
									0.005,
									0.057,
									0.003,
									0.0,
									0.002,
									0.008,
									0.001
								],
								"e": 0.021
							},
							{
								"h": [
									0.0,
									0.011,
									0.0,
									0.002,
									0.001,
									0.015,
									0.006,
									0.001,
									0.001,
									0.001,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.016,
									0.001,
									0.009,
									0.003,
									0.001,
									0.001,
									0.001,
									0.001,
									0.003,
									0.002,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.005,
									0.004,
									0.002,
									0.007,
									0.016,
									0.005,
									0.002,
									0.004,
									0.011,
									0.0,
									0.001,
									0.006
								],
								"e": 0.005
							},
							{
								"h": [
									0.011,
									0.003,
									0.011,
									0.008,
									0.015,
									0.002,
									0.004,
									0.01,
									0.014,
									0.001,
									0.004,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									0.274,
									0.119,
									0.045,
									0.064,
									0.138,
									0.037,
									0.013,
									0.021,
									0.707,
									0.01,
									0.098,
									0.034
								],
								"e": 0.07
							}
						],
						[
							{
								"h": [
									0.241,
									0.0,
									0.146,
									0.0,
									0.03,
									0.007,
									0.288,
									0.014,
									0.02,
									0.714,
									0.273,
									0.486
								],
								"e": 0.146
							},
							{
								"h": [
									0.033,
									0.023,
									0.19,
									0.028,
									0.16,
									0.028,
									0.112,
									0.151,
									0.214,
									0.289,
									0.289,
									0.078
								],
								"e": 0.164
							},
							{
								"h": [
									0.06,
									0.023,
									0.0,
									0.002,
									0.002,
									0.0,
									0.01,
									0.153,
									0.001,
									0.002,
									0.19,
									0.01
								],
								"e": 0.035
							},
							{
								"h": [
									0.01,
									0.001,
									0.0,
									0.0,
									0.016,
									0.027,
									0.003,
									0.0,
									0.0,
									0.007,
									0.013,
									0.002
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.0,
									0.006,
									0.001,
									0.025,
									0.001,
									0.007,
									0.006,
									0.026,
									0.003,
									0.01,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.041,
									0.121,
									0.003,
									0.01,
									0.0,
									0.117,
									0.0,
									0.0,
									0.052,
									0.103,
									0.055,
									0.057
								],
								"e": 0.061
							},
							{
								"h": [
									0.005,
									0.026,
									0.021,
									0.016,
									0.242,
									0.01,
									0.03,
									0.011,
									0.001,
									0.266,
									0.043,
									0.001
								],
								"e": 0.057
							},
							{
								"h": [
									0.0,
									0.037,
									0.018,
									0.009,
									0.001,
									0.016,
									0.139,
									0.015,
									0.017,
									0.005,
									0.011,
									0.112
								],
								"e": 0.025
							},
							{
								"h": [
									0.006,
									0.002,
									0.036,
									0.003,
									0.002,
									0.003,
									0.049,
									0.0,
									0.037,
									0.006,
									0.01,
									0.003
								],
								"e": 0.012
							},
							{
								"h": [
									0.069,
									0.01,
									0.01,
									0.009,
									0.01,
									0.013,
									0.007,
									0.027,
									0.13,
									0.001,
									0.0,
									0.034
								],
								"e": 0.021
							},
							{
								"h": [
									0.014,
									0.004,
									0.01,
									0.017,
									0.057,
									0.001,
									0.013,
									0.181,
									0.006,
									0.0,
									0.003,
									0.025
								],
								"e": 0.021
							},
							{
								"h": [
									0.63,
									0.16,
									0.057,
									0.06,
									0.11,
									0.01,
									0.014,
									0.014,
									0.269,
									0.011,
									0.384,
									0.021
								],
								"e": 0.078
							}
						],
						[
							{
								"h": [
									0.94,
									0.0,
									0.251,
									0.0,
									0.04,
									0.004,
									0.342,
									0.014,
									0.017,
									0.477,
									0.203,
									0.623
								],
								"e": 0.21
							},
							{
								"h": [
									0.048,
									0.017,
									0.268,
									0.029,
									0.17,
									0.081,
									0.085,
									0.17,
									0.221,
									0.201,
									0.348,
									0.067
								],
								"e": 0.166
							},
							{
								"h": [
									0.017,
									0.017,
									0.0,
									0.001,
									0.006,
									0.001,
									0.006,
									0.214,
									0.003,
									0.002,
									0.236,
									0.016
								],
								"e": 0.037
							},
							{
								"h": [
									0.003,
									0.002,
									0.0,
									0.0,
									0.025,
									0.031,
									0.002,
									0.0,
									0.0,
									0.004,
									0.022,
									0.006
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.0,
									0.003,
									0.001,
									0.021,
									0.0,
									0.007,
									0.01,
									0.034,
									0.002,
									0.003,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.008,
									0.0,
									0.003,
									0.007,
									0.0,
									0.001,
									0.0,
									0.0,
									0.001,
									0.021,
									0.018,
									0.036
								],
								"e": 0.008
							},
							{
								"h": [
									0.01,
									0.03,
									0.035,
									0.008,
									0.273,
									0.011,
									0.009,
									0.033,
									0.0,
									0.001,
									0.061,
									0.002
								],
								"e": 0.035
							},
							{
								"h": [
									0.001,
									0.074,
									0.001,
									0.004,
									0.0,
									0.015,
									0.144,
									0.135,
									0.011,
									0.002,
									0.0,
									0.008
								],
								"e": 0.021
							},
							{
								"h": [
									0.008,
									0.001,
									0.029,
									0.001,
									0.001,
									0.002,
									0.159,
									0.001,
									0.048,
									0.006,
									0.007,
									0.005
								],
								"e": 0.019
							},
							{
								"h": [
									0.068,
									0.008,
									0.023,
									0.009,
									0.004,
									0.012,
									0.006,
									0.023,
									0.281,
									0.0,
									0.0,
									0.014
								],
								"e": 0.026
							},
							{
								"h": [
									0.09,
									0.005,
									0.012,
									0.036,
									0.202,
									0.001,
									0.025,
									0.133,
									0.004,
									0.0,
									0.007,
									0.079
								],
								"e": 0.038
							},
							{
								"h": [
									0.203,
									0.151,
									0.12,
									0.08,
									0.208,
									0.014,
									0.023,
									0.057,
									0.191,
									0.023,
									0.288,
									0.032
								],
								"e": 0.08
							}
						],
						[
							{
								"h": [
									0.083,
									0.004,
									0.849,
									0.0,
									0.018,
									0.007,
									0.017,
									0.018,
									0.266,
									0.764,
									0.466,
									0.335
								],
								"e": 0.188
							},
							{
								"h": [
									0.018,
									0.093,
									0.359,
									0.032,
									0.169,
									0.357,
									0.721,
									0.205,
									0.393,
									0.14,
									0.272,
									0.31
								],
								"e": 0.296
							},
							{
								"h": [
									0.043,
									0.097,
									0.0,
									0.001,
									0.008,
									0.005,
									0.235,
									0.126,
									0.008,
									0.0,
									0.136,
									0.011
								],
								"e": 0.079
							},
							{
								"h": [
									0.038,
									0.026,
									0.0,
									0.0,
									0.038,
									0.073,
									0.002,
									0.0,
									0.003,
									0.021,
									0.038,
									0.012
								],
								"e": 0.042
							},
							{
								"h": [
									0.0,
									0.006,
									0.108,
									0.003,
									0.077,
									0.004,
									0.018,
									0.081,
									0.173,
									0.011,
									0.023,
									0.0
								],
								"e": 0.05
							},
							{
								"h": [
									0.005,
									0.0,
									0.015,
									0.051,
									0.019,
									0.001,
									0.0,
									0.003,
									0.002,
									0.109,
									0.231,
									0.047
								],
								"e": 0.036
							},
							{
								"h": [
									0.019,
									0.024,
									0.115,
									0.028,
									0.206,
									0.017,
									0.021,
									0.017,
									0.001,
									0.004,
									0.158,
									0.007
								],
								"e": 0.051
							},
							{
								"h": [
									0.002,
									0.05,
									0.0,
									0.005,
									0.002,
									0.071,
									0.06,
									0.348,
									0.005,
									0.003,
									0.002,
									0.007
								],
								"e": 0.026
							},
							{
								"h": [
									0.025,
									0.002,
									0.024,
									0.005,
									0.003,
									0.006,
									0.003,
									0.002,
									0.013,
									0.01,
									0.009,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.004,
									0.005,
									0.002,
									0.013,
									0.027,
									0.002,
									0.001,
									0.008,
									0.013,
									0.0,
									0.004,
									0.011
								],
								"e": 0.007
							},
							{
								"h": [
									0.024,
									0.002,
									0.005,
									0.012,
									0.051,
									0.006,
									0.003,
									0.02,
									0.01,
									0.006,
									0.002,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.217,
									0.093,
									0.052,
									0.082,
									0.116,
									0.034,
									0.011,
									0.03,
									0.762,
									0.006,
									0.128,
									0.02
								],
								"e": 0.069
							}
						],
						[
							{
								"h": [
									0.369,
									0.001,
									0.072,
									0.0,
									0.03,
									0.006,
									0.426,
									0.024,
									0.011,
									0.504,
									0.22,
									0.119
								],
								"e": 0.116
							},
							{
								"h": [
									0.047,
									0.028,
									0.398,
									0.036,
									0.235,
									0.023,
									0.102,
									0.068,
									0.272,
									0.187,
									0.412,
									0.026
								],
								"e": 0.168
							},
							{
								"h": [
									0.053,
									0.022,
									0.0,
									0.009,
									0.004,
									0.001,
									0.021,
									0.187,
									0.004,
									0.005,
									0.2,
									0.014
								],
								"e": 0.042
							},
							{
								"h": [
									0.001,
									0.004,
									0.001,
									0.0,
									0.01,
									0.048,
									0.01,
									0.0,
									0.0,
									0.01,
									0.011,
									0.018
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.001,
									0.002,
									0.018,
									0.01,
									0.003,
									0.017,
									0.041,
									0.016,
									0.004,
									0.006,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.002,
									0.001,
									0.004,
									0.033,
									0.011,
									0.005,
									0.005,
									0.014,
									0.004,
									0.139,
									0.201,
									0.016
								],
								"e": 0.034
							},
							{
								"h": [
									0.014,
									0.009,
									0.009,
									0.014,
									0.031,
									0.026,
									0.04,
									0.051,
									0.01,
									0.002,
									0.012,
									0.005
								],
								"e": 0.018
							},
							{
								"h": [
									0.005,
									0.014,
									0.001,
									0.005,
									0.002,
									0.045,
									0.021,
									0.003,
									0.018,
									0.006,
									0.001,
									0.006
								],
								"e": 0.009
							},
							{
								"h": [
									0.005,
									0.0,
									0.007,
									0.003,
									0.003,
									0.004,
									0.003,
									0.003,
									0.035,
									0.006,
									0.006,
									0.004
								],
								"e": 0.006
							},
							{
								"h": [
									0.055,
									0.007,
									0.011,
									0.019,
									0.003,
									0.008,
									0.003,
									0.004,
									0.04,
									0.001,
									0.001,
									0.022
								],
								"e": 0.012
							},
							{
								"h": [
									0.013,
									0.004,
									0.003,
									0.011,
									0.039,
									0.001,
									0.012,
									0.016,
									0.001,
									0.001,
									0.003,
									0.016
								],
								"e": 0.008
							},
							{
								"h": [
									0.127,
									0.058,
									0.021,
									0.017,
									0.065,
									0.009,
									0.011,
									0.026,
									0.158,
									0.015,
									0.033,
									0.01
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.306,
									0.0,
									0.187,
									0.0,
									0.014,
									0.005,
									0.104,
									0.003,
									0.018,
									0.486,
									0.146,
									0.124
								],
								"e": 0.086
							},
							{
								"h": [
									0.05,
									0.017,
									0.28,
									0.029,
									0.169,
									0.093,
									0.268,
									0.145,
									0.208,
									0.105,
									0.336,
									0.117
								],
								"e": 0.171
							},
							{
								"h": [
									0.017,
									0.008,
									0.0,
									0.001,
									0.006,
									0.001,
									0.009,
									0.167,
									0.009,
									0.005,
									0.186,
									0.016
								],
								"e": 0.031
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.0,
									0.012,
									0.03,
									0.002,
									0.001,
									0.001,
									0.004,
									0.016,
									0.027
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.001,
									0.003,
									0.014,
									0.014,
									0.001,
									0.014,
									0.05,
									0.022,
									0.005,
									0.005,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.0,
									0.005,
									0.014,
									0.004,
									0.0,
									0.017,
									0.003,
									0.0,
									0.019,
									0.052,
									0.007
								],
								"e": 0.01
							},
							{
								"h": [
									0.032,
									0.013,
									0.007,
									0.014,
									0.017,
									0.021,
									0.052,
									0.044,
									0.031,
									0.0,
									0.025,
									0.006
								],
								"e": 0.021
							},
							{
								"h": [
									0.005,
									0.008,
									0.0,
									0.005,
									0.001,
									0.047,
									0.015,
									0.003,
									0.021,
									0.001,
									0.0,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.001,
									0.015,
									0.002,
									0.004,
									0.008,
									0.019,
									0.016,
									0.02,
									0.007,
									0.003,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.044,
									0.001,
									0.033,
									0.063,
									0.001,
									0.007,
									0.001,
									0.003,
									0.041,
									0.0,
									0.01,
									0.02
								],
								"e": 0.014
							},
							{
								"h": [
									0.029,
									0.006,
									0.003,
									0.006,
									0.049,
									0.004,
									0.016,
									0.007,
									0.001,
									0.001,
									0.002,
									0.034
								],
								"e": 0.011
							},
							{
								"h": [
									0.378,
									0.082,
									0.013,
									0.024,
									0.05,
									0.012,
									0.004,
									0.033,
									0.228,
									0.013,
									0.036,
									0.02
								],
								"e": 0.04
							}
						],
						[
							{
								"h": [
									0.031,
									0.807,
									0.251,
									0.004,
									0.081,
									0.615,
									0.016,
									0.018,
									0.705,
									0.711,
									0.944,
									0.064
								],
								"e": 0.394
							},
							{
								"h": [
									0.017,
									0.055,
									0.434,
									0.041,
									0.201,
									0.991,
									0.263,
									0.147,
									0.355,
									0.147,
									0.353,
									0.676
								],
								"e": 0.344
							},
							{
								"h": [
									0.042,
									0.053,
									0.0,
									0.002,
									0.002,
									0.002,
									0.088,
									0.347,
									0.01,
									0.0,
									0.193,
									0.016
								],
								"e": 0.064
							},
							{
								"h": [
									1.0,
									0.002,
									0.0,
									0.0,
									0.27,
									0.032,
									0.001,
									0.0,
									0.001,
									0.011,
									0.047,
									0.005
								],
								"e": 0.186
							},
							{
								"h": [
									0.0,
									0.001,
									0.069,
									0.001,
									0.117,
									0.001,
									0.016,
									0.171,
									0.086,
									0.005,
									0.038,
									0.0
								],
								"e": 0.045
							},
							{
								"h": [
									0.01,
									0.001,
									0.009,
									0.044,
									0.001,
									0.006,
									0.003,
									0.001,
									0.001,
									0.131,
									0.158,
									0.13
								],
								"e": 0.038
							},
							{
								"h": [
									0.006,
									0.001,
									0.039,
									0.013,
									0.041,
									0.015,
									0.193,
									0.003,
									0.001,
									0.001,
									0.025,
									0.003
								],
								"e": 0.028
							},
							{
								"h": [
									0.0,
									0.004,
									0.0,
									0.004,
									0.0,
									0.037,
									0.003,
									0.004,
									0.001,
									0.001,
									0.0,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.053,
									0.001,
									0.004,
									0.003,
									0.001,
									0.001,
									0.001,
									0.002,
									0.002,
									0.006,
									0.003,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.003,
									0.004,
									0.001,
									0.045,
									0.003,
									0.008,
									0.0,
									0.007,
									0.002,
									0.0,
									0.002,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.009,
									0.002,
									0.006,
									0.006,
									0.007,
									0.003,
									0.001,
									0.023,
									0.009,
									0.002,
									0.002,
									0.01
								],
								"e": 0.006
							},
							{
								"h": [
									0.25,
									0.266,
									0.027,
									0.041,
									0.136,
									0.026,
									0.01,
									0.037,
									1.0,
									0.005,
									0.135,
									0.035
								],
								"e": 0.081
							}
						],
						[
							{
								"h": [
									0.839,
									0.002,
									0.247,
									0.002,
									0.245,
									0.0,
									0.893,
									0.025,
									0.015,
									0.649,
									0.321,
									1.0
								],
								"e": 0.323
							},
							{
								"h": [
									0.159,
									0.147,
									0.595,
									0.057,
									0.32,
									0.112,
									0.247,
									0.395,
									0.357,
									0.295,
									0.679,
									0.154
								],
								"e": 0.35
							},
							{
								"h": [
									0.152,
									0.081,
									0.003,
									0.05,
									0.037,
									0.009,
									0.051,
									0.59,
									0.076,
									0.06,
									0.653,
									0.045
								],
								"e": 0.154
							},
							{
								"h": [
									0.031,
									0.006,
									0.005,
									0.021,
									0.075,
									0.05,
									0.087,
									0.007,
									0.004,
									0.137,
									0.091,
									0.129
								],
								"e": 0.086
							},
							{
								"h": [
									0.019,
									0.001,
									0.006,
									0.047,
									0.076,
									0.007,
									0.045,
									0.187,
									0.034,
									0.018,
									0.026,
									0.0
								],
								"e": 0.038
							},
							{
								"h": [
									0.435,
									1.0,
									0.026,
									0.025,
									0.005,
									1.0,
									0.028,
									0.001,
									0.31,
									0.117,
									0.096,
									0.109
								],
								"e": 0.395
							},
							{
								"h": [
									0.05,
									0.231,
									0.025,
									0.029,
									0.315,
									0.056,
									0.139,
									0.233,
									0.026,
									1.0,
									0.274,
									0.027
								],
								"e": 0.213
							},
							{
								"h": [
									0.013,
									0.094,
									0.027,
									0.037,
									0.002,
									0.116,
									0.705,
									0.821,
									0.123,
									0.053,
									0.008,
									0.538
								],
								"e": 0.135
							},
							{
								"h": [
									0.04,
									0.017,
									0.045,
									0.009,
									0.056,
									0.01,
									0.504,
									0.013,
									0.189,
									0.05,
									0.172,
									0.02
								],
								"e": 0.082
							},
							{
								"h": [
									0.11,
									0.039,
									0.046,
									0.104,
									0.026,
									0.02,
									0.015,
									0.109,
									0.695,
									0.002,
									0.004,
									0.043
								],
								"e": 0.069
							},
							{
								"h": [
									0.116,
									0.015,
									0.017,
									0.13,
									0.242,
									0.002,
									0.084,
									0.412,
									0.011,
									0.003,
									0.02,
									0.303
								],
								"e": 0.091
							},
							{
								"h": [
									0.423,
									0.495,
									0.154,
									0.212,
									0.383,
									0.034,
									0.081,
									0.073,
									0.12,
									0.105,
									0.831,
									0.117
								],
								"e": 0.183
							}
						],
						[
							{
								"h": [
									0.088,
									0.003,
									0.918,
									0.004,
									0.047,
									0.004,
									0.019,
									0.078,
									0.313,
									0.793,
									0.447,
									0.343
								],
								"e": 0.213
							},
							{
								"h": [
									0.087,
									0.334,
									0.611,
									0.056,
									0.256,
									0.395,
									0.802,
									0.215,
									0.389,
									0.125,
									0.516,
									0.397
								],
								"e": 0.41
							},
							{
								"h": [
									0.139,
									0.126,
									0.0,
									0.009,
									0.063,
									0.078,
									0.529,
									0.315,
									0.071,
									0.006,
									0.322,
									0.031
								],
								"e": 0.187
							},
							{
								"h": [
									0.052,
									0.137,
									0.008,
									0.009,
									0.075,
									0.106,
									0.022,
									0.001,
									0.04,
									0.132,
									0.128,
									0.088
								],
								"e": 0.127
							},
							{
								"h": [
									0.005,
									0.082,
									0.123,
									0.023,
									0.097,
									0.024,
									0.088,
									0.542,
									0.219,
									0.047,
									0.024,
									0.0
								],
								"e": 0.106
							},
							{
								"h": [
									0.032,
									0.0,
									0.107,
									0.085,
									0.067,
									0.006,
									0.001,
									0.018,
									0.02,
									0.138,
									0.206,
									0.098
								],
								"e": 0.064
							},
							{
								"h": [
									0.068,
									0.041,
									0.072,
									0.078,
									0.247,
									0.067,
									0.155,
									0.024,
									0.01,
									0.015,
									0.389,
									0.018
								],
								"e": 0.092
							},
							{
								"h": [
									0.007,
									0.079,
									0.0,
									0.036,
									0.003,
									0.047,
									0.08,
									1.0,
									0.008,
									0.008,
									0.001,
									0.003
								],
								"e": 0.054
							},
							{
								"h": [
									0.031,
									0.002,
									0.012,
									0.006,
									0.015,
									0.004,
									0.002,
									0.006,
									0.014,
									0.027,
									0.095,
									0.005
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.007,
									0.006,
									0.041,
									0.013,
									0.003,
									0.001,
									0.011,
									0.01,
									0.0,
									0.013,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.035,
									0.002,
									0.004,
									0.026,
									0.067,
									0.009,
									0.003,
									0.014,
									0.007,
									0.018,
									0.004,
									0.003
								],
								"e": 0.014
							},
							{
								"h": [
									0.101,
									0.097,
									0.031,
									0.034,
									0.071,
									0.023,
									0.008,
									0.023,
									0.626,
									0.006,
									0.075,
									0.021
								],
								"e": 0.048
							}
						],
						[
							{
								"h": [
									0.584,
									0.0,
									0.114,
									0.002,
									0.106,
									0.005,
									0.711,
									0.144,
									0.009,
									0.421,
									0.244,
									0.093
								],
								"e": 0.181
							},
							{
								"h": [
									0.549,
									0.088,
									0.809,
									0.062,
									0.341,
									0.115,
									0.262,
									0.111,
									0.326,
									0.174,
									0.767,
									0.043
								],
								"e": 0.336
							},
							{
								"h": [
									0.108,
									0.011,
									0.012,
									0.078,
									0.069,
									0.019,
									0.067,
									0.861,
									0.11,
									0.123,
									0.603,
									0.054
								],
								"e": 0.174
							},
							{
								"h": [
									0.0,
									0.071,
									0.044,
									0.009,
									0.057,
									0.07,
									0.138,
									0.034,
									0.005,
									0.131,
									0.06,
									0.484
								],
								"e": 0.147
							},
							{
								"h": [
									0.01,
									0.011,
									0.021,
									0.419,
									0.046,
									0.024,
									0.122,
									0.342,
									0.07,
									0.115,
									0.012,
									0.0
								],
								"e": 0.093
							},
							{
								"h": [
									0.007,
									0.009,
									0.046,
									0.135,
									0.069,
									0.033,
									0.026,
									0.029,
									0.078,
									0.165,
									0.173,
									0.029
								],
								"e": 0.071
							},
							{
								"h": [
									0.166,
									0.031,
									0.02,
									0.078,
									0.074,
									0.099,
									0.07,
									0.035,
									0.047,
									0.022,
									0.074,
									0.094
								],
								"e": 0.067
							},
							{
								"h": [
									0.041,
									0.033,
									0.006,
									0.007,
									0.004,
									0.116,
									0.026,
									0.024,
									0.023,
									0.006,
									0.01,
									0.005
								],
								"e": 0.023
							},
							{
								"h": [
									0.004,
									0.003,
									0.027,
									0.008,
									0.03,
									0.007,
									0.003,
									0.072,
									0.036,
									0.002,
									0.006,
									0.007
								],
								"e": 0.017
							},
							{
								"h": [
									0.031,
									0.005,
									0.045,
									0.089,
									0.002,
									0.014,
									0.003,
									0.009,
									0.03,
									0.001,
									0.02,
									0.012
								],
								"e": 0.017
							},
							{
								"h": [
									0.02,
									0.011,
									0.004,
									0.004,
									0.043,
									0.01,
									0.019,
									0.002,
									0.001,
									0.015,
									0.003,
									0.026
								],
								"e": 0.012
							},
							{
								"h": [
									0.276,
									0.057,
									0.011,
									0.006,
									0.028,
									0.009,
									0.009,
									0.017,
									0.152,
									0.024,
									0.049,
									0.007
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.034,
									0.895,
									0.265,
									0.047,
									0.227,
									0.504,
									0.018,
									0.074,
									0.827,
									0.766,
									0.973,
									0.064
								],
								"e": 0.449
							},
							{
								"h": [
									0.065,
									0.171,
									0.663,
									0.06,
									0.283,
									1.0,
									0.263,
									0.167,
									0.359,
									0.125,
									0.569,
									0.824
								],
								"e": 0.426
							},
							{
								"h": [
									0.15,
									0.063,
									0.0,
									0.019,
									0.023,
									0.022,
									0.182,
									0.575,
									0.083,
									0.004,
									0.397,
									0.038
								],
								"e": 0.136
							},
							{
								"h": [
									0.478,
									0.025,
									0.005,
									0.015,
									0.425,
									0.058,
									0.011,
									0.001,
									0.028,
									0.08,
									0.194,
									0.073
								],
								"e": 0.199
							},
							{
								"h": [
									0.015,
									0.017,
									0.174,
									0.02,
									0.19,
									0.012,
									0.102,
									0.384,
									0.097,
									0.07,
									0.041,
									0.0
								],
								"e": 0.098
							},
							{
								"h": [
									0.004,
									0.0,
									0.067,
									0.245,
									0.005,
									0.0,
									0.002,
									0.004,
									0.0,
									0.172,
									0.211,
									0.313
								],
								"e": 0.077
							},
							{
								"h": [
									0.041,
									0.004,
									0.035,
									0.038,
									0.125,
									0.049,
									0.219,
									0.018,
									0.008,
									0.0,
									0.049,
									0.022
								],
								"e": 0.049
							},
							{
								"h": [
									0.003,
									0.002,
									0.0,
									0.012,
									0.001,
									0.023,
									0.017,
									0.015,
									0.003,
									0.003,
									0.0,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.076,
									0.0,
									0.002,
									0.014,
									0.003,
									0.004,
									0.002,
									0.005,
									0.006,
									0.008,
									0.008,
									0.012
								],
								"e": 0.012
							},
							{
								"h": [
									0.003,
									0.006,
									0.002,
									0.337,
									0.006,
									0.016,
									0.0,
									0.014,
									0.011,
									0.0,
									0.005,
									0.001
								],
								"e": 0.018
							},
							{
								"h": [
									0.036,
									0.003,
									0.007,
									0.005,
									0.01,
									0.007,
									0.002,
									0.012,
									0.006,
									0.007,
									0.002,
									0.009
								],
								"e": 0.008
							},
							{
								"h": [
									0.3,
									0.248,
									0.024,
									0.068,
									0.091,
									0.026,
									0.013,
									0.029,
									0.92,
									0.004,
									0.129,
									0.034
								],
								"e": 0.078
							}
						],
						[
							{
								"h": [
									0.195,
									0.0,
									0.114,
									0.003,
									0.442,
									0.002,
									0.768,
									1.0,
									0.011,
									0.695,
									0.259,
									0.184
								],
								"e": 0.352
							},
							{
								"h": [
									0.79,
									0.152,
									0.724,
									0.071,
									0.4,
									0.14,
									0.184,
									0.202,
									0.24,
									0.194,
									0.931,
									0.245
								],
								"e": 0.414
							},
							{
								"h": [
									0.587,
									0.036,
									0.164,
									0.277,
									1.0,
									0.074,
									0.106,
									0.883,
									0.117,
									0.593,
									1.0,
									0.075
								],
								"e": 0.512
							},
							{
								"h": [
									0.002,
									0.037,
									0.082,
									0.406,
									0.068,
									0.044,
									1.0,
									0.073,
									0.014,
									0.392,
									0.186,
									0.401
								],
								"e": 0.368
							},
							{
								"h": [
									0.094,
									0.006,
									0.011,
									0.227,
									0.112,
									0.047,
									0.229,
									0.232,
									0.054,
									0.071,
									0.015,
									0.0
								],
								"e": 0.093
							},
							{
								"h": [
									1.0,
									0.189,
									0.136,
									0.109,
									0.022,
									0.137,
									0.016,
									0.01,
									0.541,
									0.126,
									0.09,
									0.076
								],
								"e": 0.273
							},
							{
								"h": [
									0.213,
									1.0,
									0.051,
									0.093,
									0.439,
									0.194,
									0.074,
									1.0,
									0.053,
									0.096,
									0.712,
									0.046
								],
								"e": 0.318
							},
							{
								"h": [
									0.066,
									0.039,
									0.017,
									0.056,
									0.008,
									0.211,
									0.575,
									0.8,
									1.0,
									0.2,
									0.007,
									0.485
								],
								"e": 0.205
							},
							{
								"h": [
									0.011,
									0.009,
									0.04,
									0.034,
									0.038,
									0.052,
									1.0,
									0.124,
									0.705,
									0.122,
									0.108,
									0.105
								],
								"e": 0.165
							},
							{
								"h": [
									0.303,
									0.034,
									0.113,
									0.426,
									0.03,
									0.032,
									0.03,
									0.205,
									1.0,
									0.003,
									0.008,
									0.024
								],
								"e": 0.122
							},
							{
								"h": [
									0.191,
									0.009,
									0.037,
									0.029,
									0.61,
									0.006,
									0.036,
									1.0,
									0.011,
									0.013,
									0.011,
									1.0
								],
								"e": 0.182
							},
							{
								"h": [
									0.533,
									0.396,
									0.121,
									0.157,
									0.416,
									0.012,
									0.061,
									0.116,
									0.18,
									0.013,
									0.681,
									0.086
								],
								"e": 0.154
							}
						],
						[
							{
								"h": [
									0.101,
									0.003,
									1.0,
									0.043,
									0.149,
									0.003,
									0.022,
									0.379,
									0.367,
									0.877,
									0.457,
									0.352
								],
								"e": 0.304
							},
							{
								"h": [
									0.397,
									1.0,
									0.849,
									0.081,
									0.361,
									0.352,
									0.807,
									0.231,
									0.382,
									0.104,
									0.798,
									0.464
								],
								"e": 0.613
							},
							{
								"h": [
									0.486,
									0.122,
									0.018,
									0.094,
									0.57,
									1.0,
									1.0,
									0.522,
									0.559,
									0.089,
									0.648,
									0.073
								],
								"e": 0.625
							},
							{
								"h": [
									0.032,
									1.0,
									0.361,
									0.586,
									0.093,
									0.163,
									0.267,
									0.042,
									1.0,
									0.773,
									0.348,
									0.821
								],
								"e": 0.874
							},
							{
								"h": [
									0.077,
									1.0,
									0.305,
									0.153,
									0.128,
									0.337,
									0.412,
									0.781,
									0.122,
									0.215,
									0.032,
									0.0
								],
								"e": 0.359
							},
							{
								"h": [
									0.033,
									0.0,
									1.0,
									0.326,
									0.231,
									0.0,
									0.002,
									0.07,
									0.019,
									0.199,
									0.275,
									0.199
								],
								"e": 0.188
							},
							{
								"h": [
									0.558,
									0.082,
									0.12,
									0.265,
									0.286,
									0.154,
									0.11,
									0.073,
									0.056,
									0.001,
									0.354,
									0.119
								],
								"e": 0.175
							},
							{
								"h": [
									0.025,
									0.051,
									0.0,
									0.043,
									0.004,
									0.08,
									0.057,
									0.692,
									0.051,
									0.079,
									0.0,
									0.003
								],
								"e": 0.054
							},
							{
								"h": [
									0.038,
									0.001,
									0.007,
									0.018,
									0.048,
									0.027,
									0.004,
									0.017,
									0.029,
									0.011,
									0.054,
									0.009
								],
								"e": 0.022
							},
							{
								"h": [
									0.002,
									0.005,
									0.006,
									0.15,
									0.007,
									0.003,
									0.001,
									0.014,
									0.005,
									0.0,
									0.022,
									0.002
								],
								"e": 0.011
							},
							{
								"h": [
									0.028,
									0.001,
									0.003,
									0.013,
									0.038,
									0.021,
									0.002,
									0.008,
									0.006,
									0.041,
									0.002,
									0.002
								],
								"e": 0.014
							},
							{
								"h": [
									0.067,
									0.074,
									0.015,
									0.024,
									0.046,
									0.014,
									0.005,
									0.018,
									0.404,
									0.005,
									0.048,
									0.01
								],
								"e": 0.031
							}
						],
						[
							{
								"h": [
									0.519,
									0.0,
									0.078,
									0.039,
									0.227,
									0.003,
									0.682,
									0.427,
									0.013,
									0.592,
									0.215,
									0.123
								],
								"e": 0.252
							},
							{
								"h": [
									0.867,
									0.246,
									1.0,
									0.08,
									0.5,
									0.02,
									0.091,
									0.056,
									0.318,
									0.2,
									1.0,
									0.035
								],
								"e": 0.418
							},
							{
								"h": [
									0.821,
									0.015,
									1.0,
									1.0,
									0.884,
									0.215,
									0.146,
									1.0,
									0.417,
									1.0,
									0.868,
									0.077
								],
								"e": 1.0
							},
							{
								"h": [
									0.0,
									0.159,
									1.0,
									0.461,
									0.022,
									0.055,
									0.879,
									0.196,
									0.186,
									0.589,
									0.094,
									0.761
								],
								"e": 0.691
							},
							{
								"h": [
									0.376,
									0.099,
									0.009,
									1.0,
									0.041,
									0.238,
									0.247,
									0.281,
									0.007,
									0.094,
									0.005,
									0.0
								],
								"e": 0.212
							},
							{
								"h": [
									0.004,
									0.0,
									0.352,
									0.473,
									0.36,
									0.001,
									0.08,
									0.214,
									0.04,
									0.141,
									0.221,
									0.076
								],
								"e": 0.18
							},
							{
								"h": [
									0.525,
									0.039,
									0.009,
									0.116,
									0.042,
									0.163,
									0.052,
									0.08,
									0.757,
									0.0,
									0.031,
									0.349
								],
								"e": 0.172
							},
							{
								"h": [
									0.124,
									0.007,
									0.001,
									0.064,
									0.007,
									0.09,
									0.024,
									0.016,
									0.097,
									0.109,
									0.0,
									0.009
								],
								"e": 0.045
							},
							{
								"h": [
									0.017,
									0.001,
									0.004,
									0.012,
									0.075,
									0.024,
									0.001,
									0.019,
									0.059,
									0.002,
									0.021,
									0.013
								],
								"e": 0.02
							},
							{
								"h": [
									0.012,
									0.004,
									0.018,
									0.053,
									0.0,
									0.005,
									0.0,
									0.004,
									0.01,
									0.0,
									0.005,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.005,
									0.0,
									0.0,
									0.003,
									0.016,
									0.008,
									0.002,
									0.004,
									0.0,
									0.014,
									0.001,
									0.005
								],
								"e": 0.005
							},
							{
								"h": [
									0.137,
									0.012,
									0.002,
									0.002,
									0.009,
									0.001,
									0.002,
									0.005,
									0.04,
									0.002,
									0.009,
									0.0
								],
								"e": 0.009
							}
						],
						[
							{
								"h": [
									0.46,
									0.0,
									0.208,
									0.02,
									0.115,
									0.003,
									0.157,
									0.056,
									0.023,
									0.583,
									0.144,
									0.133
								],
								"e": 0.137
							},
							{
								"h": [
									1.0,
									0.164,
									0.672,
									0.062,
									0.345,
									0.073,
									0.194,
									0.12,
									0.224,
									0.1,
									0.826,
									0.14
								],
								"e": 0.381
							},
							{
								"h": [
									0.292,
									0.006,
									0.475,
									0.122,
									0.822,
									0.33,
									0.056,
									0.662,
									0.969,
									0.898,
									0.846,
									0.089
								],
								"e": 0.662
							},
							{
								"h": [
									0.0,
									0.063,
									0.214,
									1.0,
									0.025,
									0.034,
									0.223,
									1.0,
									0.339,
									0.287,
									0.175,
									1.0
								],
								"e": 0.719
							},
							{
								"h": [
									0.101,
									0.049,
									0.005,
									0.939,
									0.04,
									0.081,
									0.237,
									0.32,
									0.009,
									0.051,
									0.002,
									1.0
								],
								"e": 0.368
							},
							{
								"h": [
									0.003,
									0.0,
									0.242,
									0.122,
									0.1,
									0.0,
									0.563,
									0.145,
									0.003,
									0.042,
									0.09,
									0.039
								],
								"e": 0.142
							},
							{
								"h": [
									0.62,
									0.054,
									0.011,
									0.068,
									0.022,
									0.186,
									0.075,
									0.05,
									1.0,
									0.0,
									0.025,
									0.649
								],
								"e": 0.221
							},
							{
								"h": [
									0.097,
									0.004,
									0.0,
									0.017,
									0.002,
									0.159,
									0.008,
									0.008,
									0.102,
									0.006,
									0.0,
									0.0
								],
								"e": 0.031
							},
							{
								"h": [
									0.002,
									0.0,
									0.011,
									0.001,
									0.068,
									0.033,
									0.001,
									0.147,
									0.033,
									0.001,
									0.002,
									0.003
								],
								"e": 0.025
							},
							{
								"h": [
									0.008,
									0.0,
									0.039,
									0.164,
									0.0,
									0.008,
									0.0,
									0.003,
									0.015,
									0.0,
									0.052,
									0.001
								],
								"e": 0.017
							},
							{
								"h": [
									0.011,
									0.001,
									0.001,
									0.001,
									0.006,
									0.012,
									0.002,
									0.0,
									0.0,
									0.029,
									0.0,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.188,
									0.009,
									0.001,
									0.002,
									0.005,
									0.002,
									0.0,
									0.003,
									0.064,
									0.001,
									0.006,
									0.001
								],
								"e": 0.01
							}
						],
						[
							{
								"h": [
									0.041,
									1.0,
									0.282,
									1.0,
									1.0,
									0.45,
									0.021,
									0.513,
									1.0,
									0.866,
									1.0,
									0.063
								],
								"e": 1.0
							},
							{
								"h": [
									0.295,
									0.484,
									0.946,
									0.085,
									0.391,
									0.908,
									0.235,
									0.153,
									0.346,
									0.095,
									0.875,
									1.0
								],
								"e": 0.561
							},
							{
								"h": [
									0.641,
									0.048,
									0.075,
									0.258,
									0.337,
									0.506,
									0.354,
									0.933,
									1.0,
									0.078,
									0.842,
									0.096
								],
								"e": 0.556
							},
							{
								"h": [
									0.28,
									0.054,
									0.105,
									0.427,
									0.254,
									0.039,
									0.096,
									0.028,
									0.427,
									0.168,
									0.493,
									0.319
								],
								"e": 0.385
							},
							{
								"h": [
									0.108,
									0.12,
									0.103,
									0.088,
									0.184,
									0.081,
									0.294,
									1.0,
									0.043,
									0.05,
									0.028,
									0.0
								],
								"e": 0.161
							},
							{
								"h": [
									0.008,
									0.001,
									0.272,
									0.477,
									0.053,
									0.008,
									0.1,
									0.014,
									0.01,
									0.122,
									0.106,
									0.508
								],
								"e": 0.135
							},
							{
								"h": [
									0.234,
									0.023,
									0.054,
									0.08,
									0.09,
									0.176,
									0.586,
									0.021,
									0.148,
									0.002,
									0.068,
									0.052
								],
								"e": 0.124
							},
							{
								"h": [
									0.018,
									0.005,
									0.0,
									0.072,
									0.002,
									0.016,
									0.05,
									0.044,
									0.02,
									0.018,
									0.001,
									0.003
								],
								"e": 0.018
							},
							{
								"h": [
									0.082,
									0.001,
									0.002,
									0.029,
									0.008,
									0.012,
									0.001,
									0.03,
									0.013,
									0.056,
									0.046,
									0.043
								],
								"e": 0.029
							},
							{
								"h": [
									0.004,
									0.008,
									0.006,
									0.61,
									0.01,
									0.015,
									0.001,
									0.019,
									0.012,
									0.0,
									0.02,
									0.001
								],
								"e": 0.031
							},
							{
								"h": [
									0.078,
									0.003,
									0.005,
									0.007,
									0.005,
									0.01,
									0.002,
									0.007,
									0.004,
									0.014,
									0.004,
									0.013
								],
								"e": 0.012
							},
							{
								"h": [
									0.227,
									0.531,
									0.021,
									0.063,
									0.117,
									0.023,
									0.017,
									0.058,
									0.456,
									0.004,
									0.076,
									0.033
								],
								"e": 0.077
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " The",
					"o": " Joker",
					"t": " Joker",
					"r": [
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.113,
								" latter": 0.09,
								" most": 0.042,
								" first": 0.033,
								"oret": 0.028,
								" world": 0.027,
								" two": 0.02,
								" main": 0.018,
								" other": 0.016,
								" latest": 0.016
							}
						},
						{
							"p": " same",
							"b": 0.0,
							"k": {
								" same": 0.209,
								" latter": 0.088,
								"oret": 0.055,
								" most": 0.036,
								" first": 0.035,
								" world": 0.027,
								" Great": 0.027,
								" Rise": 0.013,
								" fastest": 0.012,
								" idea": 0.011
							}
						},
						{
							"p": "oret",
							"b": 0.0,
							"k": {
								"oret": 0.111,
								" Rise": 0.086,
								" same": 0.076,
								" Great": 0.053,
								" most": 0.04,
								" latter": 0.034,
								" first": 0.022,
								" oldest": 0.02,
								" Big": 0.018,
								" Little": 0.018
							}
						},
						{
							"p": " Rise",
							"b": 0.0,
							"k": {
								" Rise": 0.124,
								"oret": 0.061,
								" same": 0.048,
								" most": 0.047,
								" Great": 0.046,
								" biggest": 0.044,
								" vast": 0.034,
								" oldest": 0.031,
								" Little": 0.029,
								" Big": 0.022
							}
						},
						{
							"p": " Rise",
							"b": 0.0,
							"k": {
								" Rise": 0.127,
								" biggest": 0.087,
								" most": 0.062,
								" oldest": 0.05,
								" Great": 0.037,
								" longest": 0.033,
								" idea": 0.025,
								"oret": 0.025,
								" Dark": 0.023,
								" largest": 0.02
							}
						},
						{
							"p": " biggest",
							"b": 0.0,
							"k": {
								" biggest": 0.105,
								" oldest": 0.082,
								"oret": 0.047,
								" World": 0.038,
								"ta": 0.037,
								" most": 0.036,
								" Great": 0.035,
								" longest": 0.033,
								" Rise": 0.033,
								" idea": 0.031
							}
						},
						{
							"p": " Great",
							"b": 0.0,
							"k": {
								" Great": 0.137,
								" oldest": 0.092,
								" biggest": 0.09,
								"oret": 0.088,
								" same": 0.07,
								"ta": 0.063,
								" longest": 0.036,
								" World": 0.03,
								" world": 0.027,
								" largest": 0.022
							}
						},
						{
							"p": " Great",
							"b": 0.0,
							"k": {
								" Great": 0.625,
								" Third": 0.038,
								" United": 0.022,
								" biggest": 0.02,
								" Guardian": 0.014,
								" World": 0.013,
								" Dark": 0.013,
								" King": 0.012,
								" Golden": 0.009,
								" world": 0.007
							}
						},
						{
							"p": " Great",
							"b": 0.007,
							"k": {
								" Great": 0.326,
								" Dark": 0.175,
								"odor": 0.066,
								" King": 0.053,
								" Emperor": 0.027,
								" Guardian": 0.025,
								" Lord": 0.019,
								" Golden": 0.018,
								" Beast": 0.018,
								" Queen": 0.014
							}
						},
						{
							"p": " Joker",
							"b": 0.554,
							"k": {
								" Joker": 0.554,
								" Dark": 0.215,
								" Batman": 0.058,
								" Wizard": 0.049,
								" Avengers": 0.014,
								"odor": 0.013,
								" Lord": 0.011,
								" villain": 0.007,
								" Black": 0.006,
								" Death": 0.006
							}
						},
						{
							"p": " Joker",
							"b": 0.829,
							"k": {
								" Joker": 0.829,
								" Dark": 0.102,
								" Batman": 0.019,
								" Wizard": 0.016,
								" Avengers": 0.003,
								" Hulk": 0.003,
								" Black": 0.002,
								" Death": 0.002,
								" Devil": 0.002,
								" Penguin": 0.002
							}
						},
						{
							"p": " Joker",
							"b": 0.433,
							"k": {
								" Joker": 0.433,
								" Dark": 0.216,
								" Death": 0.014,
								" Wizard": 0.014,
								" Penguin": 0.012,
								" Flash": 0.009,
								" Black": 0.007,
								" Batman": 0.007,
								" Green": 0.006,
								" Man": 0.005
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.543,
									0.0,
									0.675,
									0.001,
									0.015,
									0.025,
									0.905,
									0.024,
									0.063,
									0.736,
									0.313,
									0.509
								],
								"e": 0.14
							},
							{
								"h": [
									0.072,
									0.044,
									0.333,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.019,
									0.017
								],
								"e": 0.898
							},
							{
								"h": [
									0.959,
									1.0,
									0.062,
									0.181,
									1.0,
									0.063,
									0.526,
									0.607,
									0.2,
									0.289,
									0.564,
									1.0
								],
								"e": 0.861
							},
							{
								"h": [
									1.0,
									1.0,
									0.264,
									0.713,
									1.0,
									1.0,
									0.57,
									0.048,
									0.105,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.23,
									1.0,
									0.56,
									1.0,
									1.0,
									1.0,
									0.507,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.356,
									1.0,
									0.748,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.786,
									1.0,
									1.0,
									0.588
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.453,
									1.0,
									1.0,
									1.0,
									0.985
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.609,
									1.0,
									1.0,
									1.0,
									1.0,
									0.357,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.01,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.23,
									0.0,
									0.625,
									0.0,
									0.005,
									0.004,
									0.063,
									0.008,
									0.275,
									0.636,
									0.281,
									0.407
								],
								"e": 0.083
							},
							{
								"h": [
									0.006,
									0.019,
									0.126,
									0.016,
									0.114,
									0.449,
									0.173,
									0.159,
									0.486,
									0.187,
									0.14,
									0.013
								],
								"e": 0.171
							},
							{
								"h": [
									0.026,
									0.05,
									0.0,
									0.002,
									0.006,
									0.001,
									0.052,
									0.071,
									0.006,
									0.0,
									0.103,
									0.007
								],
								"e": 0.03
							},
							{
								"h": [
									0.007,
									0.008,
									0.0,
									0.0,
									0.022,
									0.034,
									0.001,
									0.0,
									0.0,
									0.007,
									0.003,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.001,
									0.111,
									0.0,
									0.061,
									0.0,
									0.01,
									0.002,
									0.007,
									0.011,
									0.032,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.001,
									0.0,
									0.014,
									0.052,
									0.001,
									0.012,
									0.006,
									0.001,
									0.004,
									0.036,
									0.095,
									0.03
								],
								"e": 0.019
							},
							{
								"h": [
									0.006,
									0.003,
									0.062,
									0.009,
									0.018,
									0.009,
									0.017,
									0.004,
									0.003,
									0.0,
									0.004,
									0.002
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.011,
									0.0,
									0.001,
									0.001,
									0.021,
									0.003,
									0.002,
									0.002,
									0.001,
									0.002,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.007,
									0.001,
									0.009,
									0.015,
									0.002,
									0.002,
									0.013,
									0.001,
									0.006,
									0.007,
									0.009,
									0.004
								],
								"e": 0.006
							},
							{
								"h": [
									0.006,
									0.002,
									0.003,
									0.006,
									0.008,
									0.008,
									0.001,
									0.003,
									0.007,
									0.001,
									0.001,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.006,
									0.001,
									0.003,
									0.004,
									0.008,
									0.0,
									0.005,
									0.02,
									0.009,
									0.0,
									0.005,
									0.023
								],
								"e": 0.006
							},
							{
								"h": [
									0.084,
									0.028,
									0.019,
									0.012,
									0.043,
									0.021,
									0.006,
									0.004,
									0.663,
									0.005,
									0.048,
									0.011
								],
								"e": 0.034
							}
						],
						[
							{
								"h": [
									0.237,
									0.0,
									0.373,
									0.0,
									0.009,
									0.004,
									0.445,
									0.005,
									0.049,
									0.489,
									0.246,
									0.766
								],
								"e": 0.089
							},
							{
								"h": [
									0.018,
									0.018,
									0.183,
									0.023,
									0.155,
									0.192,
									0.166,
									0.214,
									0.236,
									0.149,
									0.221,
									0.001
								],
								"e": 0.141
							},
							{
								"h": [
									0.055,
									0.04,
									0.0,
									0.004,
									0.003,
									0.0,
									0.016,
									0.169,
									0.0,
									0.004,
									0.176,
									0.012
								],
								"e": 0.036
							},
							{
								"h": [
									0.001,
									0.002,
									0.0,
									0.0,
									0.053,
									0.013,
									0.004,
									0.0,
									0.0,
									0.006,
									0.007,
									0.004
								],
								"e": 0.012
							},
							{
								"h": [
									0.002,
									0.0,
									0.017,
									0.002,
									0.014,
									0.001,
									0.005,
									0.031,
									0.016,
									0.004,
									0.008,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.003,
									0.003,
									0.0,
									0.01,
									0.001,
									0.011,
									0.002,
									0.001,
									0.01,
									0.018,
									0.022,
									0.039
								],
								"e": 0.01
							},
							{
								"h": [
									0.004,
									0.026,
									0.034,
									0.005,
									0.063,
									0.008,
									0.006,
									0.01,
									0.0,
									0.002,
									0.02,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.028,
									0.01,
									0.001,
									0.0,
									0.039,
									0.03,
									0.011,
									0.009,
									0.0,
									0.003,
									0.006
								],
								"e": 0.01
							},
							{
								"h": [
									0.005,
									0.003,
									0.031,
									0.006,
									0.002,
									0.003,
									0.088,
									0.001,
									0.013,
									0.011,
									0.011,
									0.039
								],
								"e": 0.014
							},
							{
								"h": [
									0.02,
									0.001,
									0.01,
									0.007,
									0.001,
									0.014,
									0.001,
									0.008,
									0.091,
									0.0,
									0.0,
									0.006
								],
								"e": 0.009
							},
							{
								"h": [
									0.023,
									0.001,
									0.002,
									0.006,
									0.015,
									0.0,
									0.007,
									0.056,
									0.002,
									0.0,
									0.003,
									0.009
								],
								"e": 0.008
							},
							{
								"h": [
									0.24,
									0.041,
									0.005,
									0.008,
									0.031,
									0.007,
									0.003,
									0.002,
									0.178,
									0.002,
									0.025,
									0.002
								],
								"e": 0.026
							}
						],
						[
							{
								"h": [
									1.0,
									0.0,
									0.667,
									0.0,
									0.004,
									0.001,
									0.489,
									0.005,
									0.053,
									0.478,
									0.193,
									0.946
								],
								"e": 0.127
							},
							{
								"h": [
									0.01,
									0.009,
									0.27,
									0.025,
									0.164,
									0.389,
									0.305,
									0.264,
									0.219,
									0.163,
									0.27,
									0.001
								],
								"e": 0.185
							},
							{
								"h": [
									0.016,
									0.058,
									0.0,
									0.002,
									0.013,
									0.0,
									0.013,
									0.255,
									0.001,
									0.005,
									0.242,
									0.017
								],
								"e": 0.045
							},
							{
								"h": [
									0.001,
									0.005,
									0.0,
									0.002,
									0.033,
									0.019,
									0.004,
									0.0,
									0.0,
									0.004,
									0.016,
									0.004
								],
								"e": 0.012
							},
							{
								"h": [
									0.002,
									0.001,
									0.023,
									0.004,
									0.018,
									0.001,
									0.007,
									0.005,
									0.055,
									0.006,
									0.005,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.028,
									0.0,
									0.003,
									0.008,
									0.001,
									0.001,
									0.003,
									0.002,
									0.011,
									0.011,
									0.104,
									0.05
								],
								"e": 0.017
							},
							{
								"h": [
									0.02,
									0.039,
									0.03,
									0.005,
									0.153,
									0.014,
									0.012,
									0.038,
									0.001,
									0.0,
									0.086,
									0.003
								],
								"e": 0.031
							},
							{
								"h": [
									0.001,
									0.05,
									0.003,
									0.002,
									0.0,
									0.087,
									0.076,
									0.057,
									0.015,
									0.001,
									0.003,
									0.004
								],
								"e": 0.019
							},
							{
								"h": [
									0.004,
									0.006,
									0.029,
									0.01,
									0.002,
									0.003,
									0.252,
									0.001,
									0.024,
									0.014,
									0.024,
									0.046
								],
								"e": 0.025
							},
							{
								"h": [
									0.063,
									0.003,
									0.032,
									0.006,
									0.006,
									0.037,
									0.005,
									0.012,
									0.184,
									0.001,
									0.0,
									0.016
								],
								"e": 0.023
							},
							{
								"h": [
									0.122,
									0.004,
									0.012,
									0.017,
									0.051,
									0.0,
									0.019,
									0.129,
									0.007,
									0.001,
									0.019,
									0.023
								],
								"e": 0.025
							},
							{
								"h": [
									0.141,
									0.04,
									0.043,
									0.017,
									0.12,
									0.023,
									0.017,
									0.015,
									0.134,
									0.007,
									0.141,
									0.018
								],
								"e": 0.045
							}
						],
						[
							{
								"h": [
									0.09,
									0.0,
									0.494,
									0.0,
									0.005,
									0.001,
									0.015,
									0.017,
									0.496,
									0.543,
									0.266,
									0.756
								],
								"e": 0.088
							},
							{
								"h": [
									0.019,
									0.061,
									0.326,
									0.026,
									0.139,
									0.421,
									0.174,
									0.175,
									0.388,
									0.155,
									0.2,
									0.016
								],
								"e": 0.185
							},
							{
								"h": [
									0.043,
									0.08,
									0.0,
									0.001,
									0.013,
									0.002,
									0.303,
									0.149,
									0.007,
									0.002,
									0.122,
									0.012
								],
								"e": 0.07
							},
							{
								"h": [
									0.009,
									0.021,
									0.0,
									0.0,
									0.021,
									0.024,
									0.002,
									0.0,
									0.001,
									0.006,
									0.008,
									0.005
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.015,
									0.158,
									0.002,
									0.021,
									0.008,
									0.011,
									0.058,
									0.027,
									0.012,
									0.039,
									0.0
								],
								"e": 0.036
							},
							{
								"h": [
									0.191,
									0.0,
									0.027,
									0.051,
									0.016,
									0.004,
									0.003,
									0.006,
									0.023,
									0.039,
									0.144,
									0.066
								],
								"e": 0.041
							},
							{
								"h": [
									0.015,
									0.032,
									0.034,
									0.013,
									0.075,
									0.014,
									0.024,
									0.011,
									0.003,
									0.001,
									0.141,
									0.009
								],
								"e": 0.03
							},
							{
								"h": [
									0.001,
									0.028,
									0.002,
									0.006,
									0.001,
									0.181,
									0.034,
									0.139,
									0.007,
									0.003,
									0.019,
									0.002
								],
								"e": 0.025
							},
							{
								"h": [
									0.013,
									0.025,
									0.02,
									0.018,
									0.004,
									0.011,
									0.102,
									0.005,
									0.024,
									0.018,
									0.012,
									0.009
								],
								"e": 0.02
							},
							{
								"h": [
									0.005,
									0.002,
									0.006,
									0.006,
									0.006,
									0.002,
									0.001,
									0.008,
									0.01,
									0.001,
									0.001,
									0.016
								],
								"e": 0.005
							},
							{
								"h": [
									0.008,
									0.001,
									0.002,
									0.008,
									0.015,
									0.001,
									0.003,
									0.017,
									0.008,
									0.001,
									0.007,
									0.013
								],
								"e": 0.006
							},
							{
								"h": [
									0.055,
									0.016,
									0.012,
									0.009,
									0.037,
									0.014,
									0.003,
									0.005,
									0.322,
									0.002,
									0.028,
									0.003
								],
								"e": 0.02
							}
						],
						[
							{
								"h": [
									0.602,
									0.0,
									0.139,
									0.0,
									0.003,
									0.001,
									0.308,
									0.007,
									0.03,
									0.301,
									0.106,
									0.186
								],
								"e": 0.06
							},
							{
								"h": [
									0.024,
									0.031,
									0.383,
									0.03,
									0.219,
									0.101,
									0.046,
									0.106,
									0.221,
									0.141,
									0.305,
									0.0
								],
								"e": 0.13
							},
							{
								"h": [
									0.09,
									0.03,
									0.0,
									0.011,
									0.005,
									0.0,
									0.029,
									0.203,
									0.003,
									0.009,
									0.171,
									0.015
								],
								"e": 0.042
							},
							{
								"h": [
									0.0,
									0.007,
									0.001,
									0.0,
									0.013,
									0.021,
									0.015,
									0.0,
									0.0,
									0.018,
									0.008,
									0.011
								],
								"e": 0.011
							},
							{
								"h": [
									0.004,
									0.005,
									0.019,
									0.015,
									0.004,
									0.003,
									0.013,
									0.066,
									0.007,
									0.026,
									0.008,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.031,
									0.005,
									0.005,
									0.001,
									0.01,
									0.004,
									0.012,
									0.033,
									0.046
								],
								"e": 0.011
							},
							{
								"h": [
									0.023,
									0.011,
									0.011,
									0.005,
									0.035,
									0.017,
									0.015,
									0.057,
									0.001,
									0.0,
									0.016,
									0.003
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.013,
									0.002,
									0.002,
									0.0,
									0.054,
									0.018,
									0.013,
									0.004,
									0.001,
									0.002,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.006,
									0.001,
									0.007,
									0.011,
									0.004,
									0.005,
									0.008,
									0.001,
									0.021,
									0.011,
									0.011,
									0.014
								],
								"e": 0.008
							},
							{
								"h": [
									0.026,
									0.001,
									0.007,
									0.005,
									0.002,
									0.009,
									0.003,
									0.009,
									0.013,
									0.001,
									0.0,
									0.004
								],
								"e": 0.006
							},
							{
								"h": [
									0.015,
									0.002,
									0.002,
									0.004,
									0.013,
									0.0,
									0.009,
									0.029,
									0.0,
									0.0,
									0.005,
									0.015
								],
								"e": 0.006
							},
							{
								"h": [
									0.076,
									0.01,
									0.006,
									0.004,
									0.03,
									0.003,
									0.005,
									0.005,
									0.242,
									0.004,
									0.012,
									0.003
								],
								"e": 0.016
							}
						],
						[
							{
								"h": [
									0.21,
									0.0,
									0.167,
									0.0,
									0.004,
									0.0,
									0.104,
									0.004,
									0.029,
									0.335,
									0.099,
									0.252
								],
								"e": 0.039
							},
							{
								"h": [
									0.014,
									0.017,
									0.285,
									0.025,
									0.155,
									0.239,
									0.197,
									0.192,
									0.184,
									0.132,
									0.254,
									0.0
								],
								"e": 0.147
							},
							{
								"h": [
									0.026,
									0.016,
									0.0,
									0.002,
									0.005,
									0.001,
									0.027,
									0.18,
									0.006,
									0.008,
									0.173,
									0.016
								],
								"e": 0.032
							},
							{
								"h": [
									0.0,
									0.004,
									0.001,
									0.001,
									0.01,
									0.017,
									0.005,
									0.001,
									0.001,
									0.014,
									0.007,
									0.016
								],
								"e": 0.009
							},
							{
								"h": [
									0.005,
									0.005,
									0.027,
									0.02,
									0.008,
									0.003,
									0.028,
									0.005,
									0.018,
									0.018,
									0.006,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.0,
									0.002,
									0.017,
									0.005,
									0.001,
									0.007,
									0.012,
									0.003,
									0.011,
									0.022,
									0.015
								],
								"e": 0.008
							},
							{
								"h": [
									0.02,
									0.027,
									0.01,
									0.006,
									0.016,
									0.019,
									0.057,
									0.033,
									0.003,
									0.0,
									0.021,
									0.013
								],
								"e": 0.017
							},
							{
								"h": [
									0.001,
									0.009,
									0.0,
									0.006,
									0.0,
									0.121,
									0.008,
									0.016,
									0.024,
									0.002,
									0.001,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.007,
									0.001,
									0.013,
									0.011,
									0.008,
									0.009,
									0.017,
									0.013,
									0.029,
									0.013,
									0.017,
									0.022
								],
								"e": 0.013
							},
							{
								"h": [
									0.051,
									0.002,
									0.05,
									0.027,
									0.005,
									0.02,
									0.002,
									0.009,
									0.031,
									0.001,
									0.004,
									0.007
								],
								"e": 0.015
							},
							{
								"h": [
									0.035,
									0.005,
									0.003,
									0.005,
									0.036,
									0.0,
									0.012,
									0.06,
									0.001,
									0.0,
									0.003,
									0.01
								],
								"e": 0.011
							},
							{
								"h": [
									0.122,
									0.016,
									0.006,
									0.008,
									0.018,
									0.011,
									0.002,
									0.017,
									0.21,
									0.011,
									0.041,
									0.015
								],
								"e": 0.023
							}
						],
						[
							{
								"h": [
									0.205,
									0.0,
									0.607,
									0.002,
									0.01,
									0.001,
									0.047,
									0.032,
									0.599,
									0.601,
									0.286,
									0.44
								],
								"e": 0.097
							},
							{
								"h": [
									0.026,
									0.102,
									0.407,
									0.034,
									0.169,
									0.34,
									0.137,
									0.183,
									0.361,
									0.149,
									0.267,
									0.013
								],
								"e": 0.189
							},
							{
								"h": [
									0.046,
									0.14,
									0.0,
									0.006,
									0.008,
									0.006,
									0.113,
									0.42,
									0.018,
									0.001,
									0.197,
									0.019
								],
								"e": 0.084
							},
							{
								"h": [
									0.003,
									0.014,
									0.002,
									0.001,
									0.036,
									0.018,
									0.003,
									0.0,
									0.003,
									0.015,
									0.008,
									0.003
								],
								"e": 0.013
							},
							{
								"h": [
									0.002,
									0.012,
									0.09,
									0.002,
									0.041,
									0.002,
									0.018,
									0.02,
									0.017,
									0.014,
									0.021,
									0.0
								],
								"e": 0.026
							},
							{
								"h": [
									0.007,
									0.0,
									0.062,
									0.104,
									0.002,
									0.003,
									0.047,
									0.001,
									0.002,
									0.017,
									0.04,
									0.066
								],
								"e": 0.021
							},
							{
								"h": [
									0.038,
									0.002,
									0.032,
									0.008,
									0.023,
									0.04,
									0.041,
									0.005,
									0.008,
									0.0,
									0.017,
									0.01
								],
								"e": 0.017
							},
							{
								"h": [
									0.003,
									0.003,
									0.0,
									0.002,
									0.0,
									0.028,
									0.002,
									0.009,
									0.001,
									0.003,
									0.0,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.025,
									0.001,
									0.005,
									0.007,
									0.002,
									0.014,
									0.013,
									0.021,
									0.006,
									0.007,
									0.01,
									0.003
								],
								"e": 0.009
							},
							{
								"h": [
									0.007,
									0.002,
									0.003,
									0.085,
									0.002,
									0.009,
									0.0,
									0.012,
									0.002,
									0.0,
									0.003,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.005,
									0.001,
									0.001,
									0.007,
									0.006,
									0.0,
									0.002,
									0.017,
									0.002,
									0.002,
									0.001,
									0.023
								],
								"e": 0.005
							},
							{
								"h": [
									0.053,
									0.024,
									0.004,
									0.011,
									0.035,
									0.006,
									0.002,
									0.003,
									1.0,
									0.001,
									0.059,
									0.009
								],
								"e": 0.035
							}
						],
						[
							{
								"h": [
									0.77,
									0.0,
									0.488,
									0.001,
									0.034,
									0.0,
									0.716,
									0.019,
									0.042,
									0.467,
									0.221,
									1.0
								],
								"e": 0.133
							},
							{
								"h": [
									0.071,
									0.069,
									0.572,
									0.047,
									0.318,
									0.233,
									0.315,
									0.66,
									0.356,
									0.178,
									0.519,
									0.003
								],
								"e": 0.291
							},
							{
								"h": [
									0.193,
									0.129,
									0.002,
									0.052,
									0.048,
									0.006,
									0.089,
									0.569,
									0.014,
									0.068,
									0.587,
									0.041
								],
								"e": 0.142
							},
							{
								"h": [
									0.008,
									0.018,
									0.002,
									0.024,
									0.159,
									0.034,
									0.087,
									0.006,
									0.005,
									0.037,
									0.04,
									0.084
								],
								"e": 0.055
							},
							{
								"h": [
									0.05,
									0.013,
									0.028,
									0.092,
									0.041,
									0.019,
									0.053,
									0.318,
									0.069,
									0.026,
									0.022,
									0.0
								],
								"e": 0.059
							},
							{
								"h": [
									0.046,
									0.008,
									0.007,
									0.021,
									0.009,
									0.017,
									0.167,
									0.006,
									0.02,
									0.04,
									0.061,
									0.088
								],
								"e": 0.036
							},
							{
								"h": [
									0.041,
									0.18,
									0.028,
									0.008,
									0.155,
									0.069,
									0.085,
									0.145,
									0.012,
									0.002,
									0.231,
									0.02
								],
								"e": 0.073
							},
							{
								"h": [
									0.013,
									0.038,
									0.006,
									0.01,
									0.0,
									0.246,
									0.193,
									0.191,
									0.133,
									0.003,
									0.002,
									0.033
								],
								"e": 0.053
							},
							{
								"h": [
									0.033,
									0.02,
									0.076,
									0.026,
									0.048,
									0.018,
									0.659,
									0.017,
									0.155,
									0.093,
									0.142,
									0.307
								],
								"e": 0.101
							},
							{
								"h": [
									0.131,
									0.01,
									0.091,
									0.114,
									0.002,
									0.04,
									0.005,
									0.023,
									0.779,
									0.002,
									0.003,
									0.013
								],
								"e": 0.064
							},
							{
								"h": [
									0.326,
									0.007,
									0.006,
									0.041,
									0.048,
									0.0,
									0.069,
									0.259,
									0.004,
									0.005,
									0.043,
									0.092
								],
								"e": 0.055
							},
							{
								"h": [
									0.214,
									0.053,
									0.022,
									0.039,
									0.127,
									0.016,
									0.019,
									0.006,
									0.169,
									0.009,
									0.205,
									0.032
								],
								"e": 0.054
							}
						],
						[
							{
								"h": [
									0.096,
									0.0,
									0.517,
									0.0,
									0.013,
									0.001,
									0.014,
									0.074,
									0.719,
									0.563,
									0.257,
									0.769
								],
								"e": 0.105
							},
							{
								"h": [
									0.071,
									0.232,
									0.56,
									0.046,
									0.214,
									0.345,
									0.148,
									0.208,
									0.39,
									0.129,
									0.377,
									0.017
								],
								"e": 0.239
							},
							{
								"h": [
									0.133,
									0.096,
									0.002,
									0.01,
									0.08,
									0.019,
									0.586,
									0.345,
									0.047,
									0.018,
									0.278,
									0.03
								],
								"e": 0.154
							},
							{
								"h": [
									0.008,
									0.087,
									0.004,
									0.007,
									0.03,
									0.027,
									0.018,
									0.002,
									0.016,
									0.025,
									0.022,
									0.028
								],
								"e": 0.028
							},
							{
								"h": [
									0.012,
									0.154,
									0.16,
									0.014,
									0.022,
									0.043,
									0.05,
									0.232,
									0.045,
									0.048,
									0.054,
									0.0
								],
								"e": 0.071
							},
							{
								"h": [
									1.0,
									0.0,
									0.129,
									0.088,
									0.085,
									0.004,
									0.03,
									0.014,
									0.046,
									0.048,
									0.089,
									0.129
								],
								"e": 0.113
							},
							{
								"h": [
									0.109,
									0.055,
									0.041,
									0.028,
									0.101,
									0.073,
									0.085,
									0.023,
									0.023,
									0.001,
									0.231,
									0.031
								],
								"e": 0.06
							},
							{
								"h": [
									0.026,
									0.02,
									0.0,
									0.025,
									0.001,
									0.095,
									0.038,
									0.144,
									0.012,
									0.018,
									0.003,
									0.002
								],
								"e": 0.024
							},
							{
								"h": [
									0.027,
									0.014,
									0.007,
									0.014,
									0.018,
									0.012,
									0.061,
									0.049,
									0.04,
									0.065,
									0.052,
									0.015
								],
								"e": 0.029
							},
							{
								"h": [
									0.004,
									0.004,
									0.017,
									0.026,
									0.002,
									0.005,
									0.001,
									0.023,
									0.01,
									0.001,
									0.008,
									0.005
								],
								"e": 0.008
							},
							{
								"h": [
									0.014,
									0.002,
									0.002,
									0.026,
									0.022,
									0.001,
									0.005,
									0.015,
									0.004,
									0.006,
									0.01,
									0.036
								],
								"e": 0.011
							},
							{
								"h": [
									0.02,
									0.016,
									0.005,
									0.005,
									0.024,
									0.007,
									0.003,
									0.002,
									0.225,
									0.003,
									0.021,
									0.004
								],
								"e": 0.013
							}
						],
						[
							{
								"h": [
									0.154,
									0.0,
									0.182,
									0.001,
									0.013,
									0.001,
									0.472,
									0.037,
									0.093,
									0.319,
									0.111,
									0.125
								],
								"e": 0.058
							},
							{
								"h": [
									0.1,
									0.133,
									0.832,
									0.055,
									0.332,
									0.116,
									0.093,
									0.165,
									0.375,
									0.119,
									0.58,
									0.0
								],
								"e": 0.231
							},
							{
								"h": [
									0.27,
									0.015,
									0.003,
									0.103,
									0.041,
									0.006,
									0.115,
									0.82,
									0.045,
									0.16,
									0.594,
									0.056
								],
								"e": 0.167
							},
							{
								"h": [
									0.0,
									0.126,
									0.015,
									0.006,
									0.025,
									0.041,
									0.087,
									0.015,
									0.007,
									0.058,
									0.029,
									0.137
								],
								"e": 0.05
							},
							{
								"h": [
									0.035,
									0.038,
									0.06,
									0.212,
									0.017,
									0.022,
									0.117,
									0.382,
									0.039,
									0.194,
									0.011,
									0.0
								],
								"e": 0.085
							},
							{
								"h": [
									0.003,
									0.0,
									0.018,
									0.069,
									0.035,
									0.01,
									0.025,
									0.05,
									0.023,
									0.03,
									0.047,
									0.037
								],
								"e": 0.027
							},
							{
								"h": [
									0.098,
									0.02,
									0.014,
									0.012,
									0.025,
									0.137,
									0.034,
									0.02,
									0.007,
									0.0,
									0.043,
									0.09
								],
								"e": 0.034
							},
							{
								"h": [
									0.008,
									0.021,
									0.001,
									0.011,
									0.001,
									0.203,
									0.011,
									0.021,
									0.02,
									0.011,
									0.001,
									0.001
								],
								"e": 0.016
							},
							{
								"h": [
									0.005,
									0.004,
									0.013,
									0.031,
									0.016,
									0.014,
									0.015,
									0.028,
									0.083,
									0.016,
									0.026,
									0.041
								],
								"e": 0.023
							},
							{
								"h": [
									0.064,
									0.001,
									0.029,
									0.021,
									0.002,
									0.015,
									0.007,
									0.01,
									0.06,
									0.004,
									0.004,
									0.004
								],
								"e": 0.015
							},
							{
								"h": [
									0.056,
									0.007,
									0.004,
									0.005,
									0.017,
									0.0,
									0.017,
									0.015,
									0.001,
									0.004,
									0.005,
									0.03
								],
								"e": 0.011
							},
							{
								"h": [
									0.05,
									0.027,
									0.005,
									0.002,
									0.01,
									0.004,
									0.003,
									0.011,
									0.084,
									0.008,
									0.014,
									0.005
								],
								"e": 0.012
							}
						],
						[
							{
								"h": [
									0.236,
									0.0,
									0.644,
									0.014,
									0.027,
									0.001,
									0.05,
									0.134,
									0.768,
									0.651,
									0.287,
									0.443
								],
								"e": 0.121
							},
							{
								"h": [
									0.079,
									0.338,
									0.615,
									0.05,
									0.236,
									0.301,
									0.12,
									0.209,
									0.354,
									0.126,
									0.426,
									0.015
								],
								"e": 0.25
							},
							{
								"h": [
									0.15,
									0.135,
									0.006,
									0.046,
									0.05,
									0.052,
									0.226,
									0.657,
									0.103,
									0.01,
									0.396,
									0.041
								],
								"e": 0.168
							},
							{
								"h": [
									0.001,
									0.188,
									0.039,
									0.025,
									0.048,
									0.022,
									0.038,
									0.003,
									0.06,
									0.074,
									0.022,
									0.029
								],
								"e": 0.052
							},
							{
								"h": [
									0.036,
									0.1,
									0.184,
									0.019,
									0.044,
									0.038,
									0.087,
									0.064,
									0.019,
									0.124,
									0.018,
									0.0
								],
								"e": 0.066
							},
							{
								"h": [
									0.001,
									0.0,
									0.15,
									0.409,
									0.008,
									0.0,
									0.028,
									0.004,
									0.002,
									0.013,
									0.028,
									0.089
								],
								"e": 0.036
							},
							{
								"h": [
									0.108,
									0.005,
									0.023,
									0.01,
									0.065,
									0.123,
									0.062,
									0.017,
									0.074,
									0.0,
									0.009,
									0.049
								],
								"e": 0.038
							},
							{
								"h": [
									0.078,
									0.002,
									0.0,
									0.006,
									0.001,
									0.02,
									0.006,
									0.006,
									0.003,
									0.012,
									0.0,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.025,
									0.0,
									0.002,
									0.008,
									0.006,
									0.069,
									0.007,
									0.076,
									0.012,
									0.013,
									0.019,
									0.018
								],
								"e": 0.02
							},
							{
								"h": [
									0.006,
									0.002,
									0.005,
									0.416,
									0.003,
									0.013,
									0.0,
									0.015,
									0.008,
									0.0,
									0.012,
									0.001
								],
								"e": 0.022
							},
							{
								"h": [
									0.012,
									0.001,
									0.001,
									0.004,
									0.007,
									0.001,
									0.002,
									0.008,
									0.001,
									0.01,
									0.001,
									0.02
								],
								"e": 0.005
							},
							{
								"h": [
									0.05,
									0.021,
									0.002,
									0.011,
									0.02,
									0.004,
									0.002,
									0.001,
									0.934,
									0.001,
									0.043,
									0.006
								],
								"e": 0.03
							}
						],
						[
							{
								"h": [
									0.355,
									0.0,
									1.0,
									0.004,
									0.095,
									0.0,
									1.0,
									0.065,
									0.052,
									0.517,
									0.279,
									0.186
								],
								"e": 0.142
							},
							{
								"h": [
									0.174,
									0.206,
									0.741,
									0.062,
									0.391,
									0.489,
									0.26,
									0.344,
									0.257,
									0.136,
									0.688,
									0.002
								],
								"e": 0.325
							},
							{
								"h": [
									0.406,
									0.09,
									0.123,
									0.276,
									0.398,
									0.027,
									0.134,
									0.846,
									0.036,
									0.43,
									0.895,
									0.074
								],
								"e": 0.34
							},
							{
								"h": [
									0.002,
									0.053,
									0.078,
									0.443,
									0.086,
									0.023,
									0.933,
									0.105,
									0.036,
									0.106,
									0.105,
									0.179
								],
								"e": 0.169
							},
							{
								"h": [
									0.177,
									0.077,
									0.042,
									0.549,
									0.026,
									0.067,
									0.17,
									0.771,
									0.028,
									0.093,
									0.015,
									0.0
								],
								"e": 0.139
							},
							{
								"h": [
									0.03,
									0.001,
									0.01,
									0.074,
									0.037,
									0.006,
									0.1,
									0.056,
									0.037,
									0.038,
									0.033,
									0.049
								],
								"e": 0.035
							},
							{
								"h": [
									0.183,
									0.576,
									0.032,
									0.015,
									0.155,
									0.153,
									0.037,
									0.482,
									0.015,
									0.0,
									0.191,
									0.066
								],
								"e": 0.136
							},
							{
								"h": [
									0.048,
									0.022,
									0.001,
									0.011,
									0.001,
									0.396,
									0.12,
									0.114,
									0.443,
									0.011,
									0.001,
									0.005
								],
								"e": 0.065
							},
							{
								"h": [
									0.017,
									0.004,
									0.036,
									0.028,
									0.101,
									0.067,
									0.716,
									0.048,
									0.212,
									0.091,
									0.167,
									1.0
								],
								"e": 0.148
							},
							{
								"h": [
									0.29,
									0.006,
									0.065,
									0.179,
									0.006,
									0.05,
									0.006,
									0.026,
									1.0,
									0.004,
									0.003,
									0.009
								],
								"e": 0.085
							},
							{
								"h": [
									0.473,
									0.005,
									0.013,
									0.006,
									0.079,
									0.001,
									0.028,
									0.342,
									0.003,
									0.017,
									0.029,
									0.081
								],
								"e": 0.061
							},
							{
								"h": [
									0.135,
									0.042,
									0.02,
									0.041,
									0.123,
									0.008,
									0.016,
									0.016,
									0.163,
									0.001,
									0.216,
									0.032
								],
								"e": 0.049
							}
						],
						[
							{
								"h": [
									0.107,
									0.0,
									0.557,
									0.003,
									0.043,
									0.0,
									0.015,
									0.376,
									0.923,
									0.626,
									0.262,
									0.787
								],
								"e": 0.148
							},
							{
								"h": [
									0.249,
									0.767,
									0.778,
									0.065,
									0.302,
									0.282,
									0.132,
									0.235,
									0.372,
									0.105,
									0.574,
									0.018
								],
								"e": 0.36
							},
							{
								"h": [
									0.393,
									0.082,
									0.12,
									0.084,
									0.548,
									0.214,
									1.0,
									0.537,
									0.298,
									0.186,
									0.539,
									0.064
								],
								"e": 0.443
							},
							{
								"h": [
									0.005,
									0.661,
									0.143,
									0.286,
									0.028,
									0.041,
									0.17,
									0.053,
									0.323,
									0.099,
									0.053,
									0.219
								],
								"e": 0.181
							},
							{
								"h": [
									0.131,
									1.0,
									0.23,
									0.096,
									0.021,
									0.375,
									0.209,
									0.429,
									0.027,
									0.15,
									0.053,
									0.0
								],
								"e": 0.213
							},
							{
								"h": [
									0.583,
									0.0,
									0.818,
									0.38,
									0.261,
									0.001,
									0.04,
									0.042,
									0.125,
									0.046,
									0.058,
									0.14
								],
								"e": 0.154
							},
							{
								"h": [
									0.694,
									0.1,
									0.052,
									0.076,
									0.106,
									0.186,
									0.059,
									0.063,
									0.101,
									0.0,
									0.137,
									0.19
								],
								"e": 0.116
							},
							{
								"h": [
									0.112,
									0.011,
									0.0,
									0.043,
									0.002,
									0.15,
									0.022,
									0.079,
									0.039,
									0.111,
									0.001,
									0.002
								],
								"e": 0.036
							},
							{
								"h": [
									0.033,
									0.012,
									0.004,
									0.028,
									0.042,
									0.04,
									0.054,
									0.119,
									0.066,
									0.031,
									0.041,
									0.028
								],
								"e": 0.038
							},
							{
								"h": [
									0.004,
									0.003,
									0.011,
									0.049,
									0.003,
									0.002,
									0.001,
									0.033,
									0.005,
									0.001,
									0.011,
									0.003
								],
								"e": 0.009
							},
							{
								"h": [
									0.011,
									0.001,
									0.001,
									0.013,
									0.016,
									0.002,
									0.002,
									0.007,
									0.004,
									0.009,
									0.004,
									0.011
								],
								"e": 0.006
							},
							{
								"h": [
									0.012,
									0.014,
									0.003,
									0.003,
									0.014,
									0.004,
									0.002,
									0.002,
									0.099,
									0.002,
									0.01,
									0.002
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.831,
									0.0,
									0.152,
									0.006,
									0.024,
									0.0,
									0.395,
									0.123,
									0.046,
									0.354,
									0.102,
									0.186
								],
								"e": 0.088
							},
							{
								"h": [
									0.211,
									0.294,
									0.984,
									0.066,
									0.478,
									0.066,
									0.028,
									0.093,
									0.254,
									0.144,
									0.722,
									0.0
								],
								"e": 0.276
							},
							{
								"h": [
									1.0,
									0.013,
									0.355,
									0.779,
									0.393,
									0.055,
									0.161,
									0.928,
									0.187,
									1.0,
									0.709,
									0.07
								],
								"e": 0.59
							},
							{
								"h": [
									0.0,
									0.296,
									0.454,
									0.123,
									0.016,
									0.019,
									1.0,
									0.175,
									0.09,
									0.374,
									0.073,
									0.399
								],
								"e": 0.245
							},
							{
								"h": [
									0.463,
									0.357,
									0.041,
									0.428,
									0.005,
									0.169,
									0.181,
									0.368,
									0.003,
									0.25,
									0.005,
									0.0
								],
								"e": 0.168
							},
							{
								"h": [
									0.001,
									0.0,
									0.089,
									0.431,
									0.091,
									0.001,
									0.019,
									0.071,
									0.02,
									0.007,
									0.02,
									0.07
								],
								"e": 0.047
							},
							{
								"h": [
									0.316,
									0.03,
									0.01,
									0.027,
									0.022,
									0.175,
									0.032,
									0.05,
									0.072,
									0.0,
									0.006,
									0.202
								],
								"e": 0.06
							},
							{
								"h": [
									0.051,
									0.004,
									0.0,
									0.022,
									0.002,
									0.071,
									0.015,
									0.01,
									0.026,
									0.047,
									0.0,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.01,
									0.001,
									0.002,
									0.023,
									0.04,
									0.032,
									0.005,
									0.01,
									0.074,
									0.015,
									0.034,
									0.042
								],
								"e": 0.023
							},
							{
								"h": [
									0.015,
									0.001,
									0.01,
									0.012,
									0.0,
									0.004,
									0.001,
									0.02,
									0.007,
									0.0,
									0.002,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.017,
									0.001,
									0.0,
									0.002,
									0.008,
									0.0,
									0.004,
									0.011,
									0.0,
									0.005,
									0.002,
									0.007
								],
								"e": 0.004
							},
							{
								"h": [
									0.026,
									0.009,
									0.001,
									0.001,
									0.006,
									0.001,
									0.002,
									0.002,
									0.031,
									0.001,
									0.006,
									0.0
								],
								"e": 0.004
							}
						],
						[
							{
								"h": [
									0.294,
									0.0,
									0.185,
									0.002,
									0.029,
									0.0,
									0.132,
									0.086,
									0.044,
									0.403,
									0.097,
									0.259
								],
								"e": 0.058
							},
							{
								"h": [
									0.153,
									0.191,
									0.706,
									0.053,
									0.321,
									0.128,
									0.107,
									0.173,
									0.183,
									0.109,
									0.612,
									0.0
								],
								"e": 0.23
							},
							{
								"h": [
									0.35,
									0.007,
									0.098,
									0.145,
									0.347,
									0.135,
									0.117,
									0.631,
									0.336,
									0.864,
									0.727,
									0.076
								],
								"e": 0.402
							},
							{
								"h": [
									0.0,
									0.141,
									0.5,
									1.0,
									0.006,
									0.012,
									0.402,
									1.0,
									0.297,
									0.258,
									0.072,
									0.575
								],
								"e": 0.41
							},
							{
								"h": [
									0.585,
									0.448,
									0.031,
									1.0,
									0.006,
									0.131,
									0.279,
									0.039,
									0.007,
									0.127,
									0.002,
									0.0
								],
								"e": 0.198
							},
							{
								"h": [
									0.001,
									0.0,
									0.202,
									0.13,
									0.084,
									0.001,
									0.19,
									0.145,
									0.01,
									0.009,
									0.018,
									0.035
								],
								"e": 0.054
							},
							{
								"h": [
									0.274,
									0.037,
									0.008,
									0.018,
									0.009,
									0.161,
									0.024,
									0.025,
									0.213,
									0.0,
									0.006,
									1.0
								],
								"e": 0.114
							},
							{
								"h": [
									0.065,
									0.001,
									0.0,
									0.022,
									0.002,
									0.116,
									0.002,
									0.002,
									0.112,
									0.028,
									0.0,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.002,
									0.0,
									0.007,
									0.005,
									0.043,
									0.067,
									0.004,
									0.213,
									0.048,
									0.007,
									0.013,
									0.021
								],
								"e": 0.031
							},
							{
								"h": [
									0.017,
									0.0,
									0.041,
									0.094,
									0.0,
									0.007,
									0.0,
									0.011,
									0.015,
									0.0,
									0.019,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.017,
									0.001,
									0.0,
									0.002,
									0.006,
									0.001,
									0.002,
									0.006,
									0.0,
									0.011,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.021,
									0.003,
									0.0,
									0.002,
									0.002,
									0.002,
									0.0,
									0.003,
									0.009,
									0.001,
									0.008,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.289,
									0.0,
									0.699,
									0.272,
									0.118,
									0.001,
									0.058,
									1.0,
									1.0,
									0.743,
									0.292,
									0.438
								],
								"e": 0.274
							},
							{
								"h": [
									0.26,
									1.0,
									0.888,
									0.071,
									0.331,
									0.264,
									0.118,
									0.219,
									0.309,
									0.092,
									0.652,
									0.016
								],
								"e": 0.391
							},
							{
								"h": [
									0.523,
									0.106,
									1.0,
									0.468,
									0.586,
									1.0,
									0.388,
									1.0,
									1.0,
									0.158,
									0.774,
									0.094
								],
								"e": 1.0
							},
							{
								"h": [
									0.001,
									0.415,
									1.0,
									0.701,
									0.022,
									0.016,
									0.34,
									0.081,
									1.0,
									0.19,
									0.061,
									0.146
								],
								"e": 0.356
							},
							{
								"h": [
									0.261,
									0.67,
									0.104,
									0.115,
									0.024,
									0.149,
									0.227,
									0.084,
									0.009,
									0.1,
									0.01,
									1.0
								],
								"e": 0.354
							},
							{
								"h": [
									0.001,
									0.0,
									1.0,
									0.744,
									0.052,
									0.004,
									0.924,
									0.008,
									0.014,
									0.01,
									0.017,
									0.154
								],
								"e": 0.15
							},
							{
								"h": [
									0.442,
									0.008,
									0.042,
									0.019,
									0.031,
									0.267,
									0.108,
									0.03,
									1.0,
									0.0,
									0.007,
									0.229
								],
								"e": 0.147
							},
							{
								"h": [
									0.77,
									0.001,
									0.0,
									0.024,
									0.004,
									0.02,
									0.012,
									0.006,
									0.034,
									0.059,
									0.0,
									0.0
								],
								"e": 0.05
							},
							{
								"h": [
									0.017,
									0.0,
									0.001,
									0.011,
									0.029,
									0.173,
									0.007,
									1.0,
									0.044,
									0.038,
									0.039,
									0.064
								],
								"e": 0.097
							},
							{
								"h": [
									0.013,
									0.002,
									0.016,
									1.0,
									0.002,
									0.013,
									0.0,
									0.033,
									0.012,
									0.0,
									0.026,
									0.001
								],
								"e": 0.05
							},
							{
								"h": [
									0.021,
									0.001,
									0.001,
									0.004,
									0.005,
									0.002,
									0.002,
									0.007,
									0.0,
									0.031,
									0.002,
									0.016
								],
								"e": 0.007
							},
							{
								"h": [
									0.039,
									0.042,
									0.003,
									0.014,
									0.037,
									0.004,
									0.004,
									0.002,
									0.807,
									0.001,
									0.036,
									0.011
								],
								"e": 0.031
							}
						],
						[
							{
								"h": [
									0.387,
									1.0,
									0.399,
									1.0,
									1.0,
									1.0,
									0.046,
									0.707,
									0.531,
									1.0,
									1.0,
									0.127
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.502,
									1.0,
									0.093,
									0.474,
									0.373,
									0.221,
									0.357,
									0.424,
									0.372,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.499,
									0.02,
									0.166,
									1.0,
									0.606,
									0.068,
									0.203,
									0.923,
									0.264,
									0.219,
									1.0,
									0.121
								],
								"e": 0.511
							},
							{
								"h": [
									0.171,
									0.045,
									0.222,
									0.138,
									0.043,
									0.009,
									0.297,
									0.047,
									0.032,
									0.152,
									0.204,
									0.18
								],
								"e": 0.155
							},
							{
								"h": [
									0.164,
									0.102,
									0.026,
									0.083,
									0.032,
									0.065,
									0.086,
									1.0,
									0.027,
									0.065,
									0.022,
									0.0
								],
								"e": 0.11
							},
							{
								"h": [
									0.007,
									0.002,
									0.023,
									0.049,
									0.065,
									0.005,
									0.011,
									0.017,
									0.012,
									0.034,
									0.026,
									0.414
								],
								"e": 0.045
							},
							{
								"h": [
									0.101,
									0.111,
									0.026,
									0.01,
									0.1,
									0.123,
									0.299,
									0.397,
									0.033,
									0.0,
									0.044,
									0.114
								],
								"e": 0.099
							},
							{
								"h": [
									0.138,
									0.009,
									0.0,
									0.022,
									0.0,
									0.056,
									0.027,
									0.012,
									0.05,
									0.02,
									0.0,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.041,
									0.004,
									0.008,
									0.022,
									0.054,
									0.077,
									0.043,
									0.151,
									0.042,
									0.086,
									0.066,
									0.054
								],
								"e": 0.05
							},
							{
								"h": [
									0.016,
									0.01,
									0.016,
									0.152,
									0.0,
									0.027,
									0.002,
									0.022,
									0.007,
									0.004,
									0.016,
									0.018
								],
								"e": 0.018
							},
							{
								"h": [
									0.024,
									0.014,
									0.003,
									0.011,
									0.036,
									0.001,
									0.009,
									0.016,
									0.002,
									0.005,
									0.02,
									0.024
								],
								"e": 0.012
							},
							{
								"h": [
									0.056,
									0.133,
									0.017,
									0.012,
									0.076,
									0.018,
									0.01,
									0.017,
									0.558,
									0.003,
									0.069,
									0.029
								],
								"e": 0.045
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Joker",
					"o": ".",
					"t": ".",
					"r": [
						{
							"p": " Joker",
							"b": 0.0,
							"k": {
								" Joker": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Joker",
							"b": 0.0,
							"k": {
								" Joker": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Joker",
							"b": 0.0,
							"k": {
								" Joker": 0.998,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Joker",
							"b": 0.0,
							"k": {
								" Joker": 0.977,
								" killer": 0.005,
								"'s": 0.004,
								" Batman": 0.003,
								" Kaz": 0.003,
								"crow": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": " Joker",
							"b": 0.0,
							"k": {
								" Joker": 0.923,
								"'s": 0.043,
								" killer": 0.01,
								" Batman": 0.007,
								",": 0.002,
								" Kaz": 0.002,
								" King": 0.001,
								" king": 0.001,
								" Knight": 0.001,
								"!": 0.0
							}
						},
						{
							"p": " Joker",
							"b": 0.009,
							"k": {
								" Joker": 0.648,
								"'s": 0.198,
								",": 0.074,
								".": 0.009,
								" killer": 0.009,
								" Batman": 0.007,
								" Kaz": 0.006,
								";": 0.004,
								"/": 0.003,
								" and": 0.002
							}
						},
						{
							"p": "'s",
							"b": 0.049,
							"k": {
								"'s": 0.391,
								",": 0.3,
								" Joker": 0.124,
								".": 0.049,
								";": 0.026,
								"/": 0.01,
								" killer": 0.008,
								" and": 0.007,
								" (": 0.005,
								" \u00e2\u0122\u0136": 0.003
							}
						},
						{
							"p": "'s",
							"b": 0.332,
							"k": {
								"'s": 0.39,
								".": 0.332,
								",": 0.18,
								" Joker": 0.025,
								";": 0.012,
								"/": 0.008,
								" Jr": 0.007,
								" and": 0.005,
								" (": 0.004,
								" killer": 0.004
							}
						},
						{
							"p": ".",
							"b": 0.985,
							"k": {
								".": 0.985,
								",": 0.005,
								"'s": 0.004,
								";": 0.002,
								"!": 0.001,
								"?": 0.001,
								".\"": 0.001,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.999,
							"k": {
								".": 0.999,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.998,
							"k": {
								".": 0.998,
								"!": 0.001,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.938,
							"k": {
								".": 0.938,
								",": 0.012,
								"!": 0.009,
								" (": 0.005,
								"?": 0.003,
								".\"": 0.003,
								" and": 0.002,
								"...": 0.002,
								"/": 0.001,
								":": 0.001
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.913,
									0.0,
									1.0,
									0.0,
									0.003,
									0.015,
									0.788,
									0.065,
									0.905,
									0.616,
									0.337,
									0.225
								],
								"e": 0.131
							},
							{
								"h": [
									0.02,
									0.023,
									0.339,
									1.0,
									1.0,
									0.706,
									1.0,
									1.0,
									1.0,
									1.0,
									0.021,
									0.048
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.141,
									0.027,
									0.762,
									0.102,
									0.681,
									0.743,
									0.285,
									0.128,
									0.176,
									1.0
								],
								"e": 0.891
							},
							{
								"h": [
									1.0,
									1.0,
									0.113,
									0.928,
									1.0,
									1.0,
									0.3,
									0.338,
									0.39,
									1.0,
									1.0,
									0.52
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.216,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.874,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.217,
									1.0,
									1.0,
									0.482,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.453,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.469,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.311,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.224
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									1.0,
									0.0,
									0.448,
									0.0,
									0.0,
									0.0,
									0.687,
									0.025,
									0.954,
									0.751,
									0.244,
									0.639
								],
								"e": 0.111
							},
							{
								"h": [
									0.002,
									0.006,
									0.158,
									0.017,
									0.128,
									0.2,
									0.216,
									0.238,
									0.492,
									0.159,
									0.143,
									0.013
								],
								"e": 0.177
							},
							{
								"h": [
									0.016,
									0.026,
									0.0,
									0.0,
									0.009,
									0.002,
									0.043,
									0.089,
									0.011,
									0.001,
									0.042,
									0.006
								],
								"e": 0.024
							},
							{
								"h": [
									0.012,
									0.012,
									0.0,
									0.0,
									0.018,
									0.021,
									0.001,
									0.0,
									0.0,
									0.002,
									0.01,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.002,
									0.076,
									0.001,
									0.042,
									0.002,
									0.008,
									0.003,
									0.027,
									0.007,
									0.056,
									0.0
								],
								"e": 0.023
							},
							{
								"h": [
									0.005,
									0.001,
									0.018,
									0.027,
									0.001,
									0.017,
									0.001,
									0.0,
									0.022,
									0.025,
									0.173,
									0.093
								],
								"e": 0.023
							},
							{
								"h": [
									0.006,
									0.004,
									0.082,
									0.013,
									0.003,
									0.005,
									0.018,
									0.014,
									0.001,
									0.001,
									0.005,
									0.004
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.012,
									0.027,
									0.001,
									0.01,
									0.016,
									0.002,
									0.008,
									0.0,
									0.002,
									0.006,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.006,
									0.014,
									0.007,
									0.019,
									0.0,
									0.003,
									0.009,
									0.0,
									0.008,
									0.004,
									0.004,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.004,
									0.015,
									0.003,
									0.003,
									0.074,
									0.008,
									0.014,
									0.009,
									0.002,
									0.006,
									0.004,
									0.028
								],
								"e": 0.014
							},
							{
								"h": [
									0.006,
									0.016,
									0.045,
									0.059,
									0.085,
									0.009,
									0.011,
									0.059,
									0.107,
									0.001,
									0.017,
									0.032
								],
								"e": 0.034
							},
							{
								"h": [
									0.533,
									0.046,
									0.055,
									0.029,
									0.098,
									0.047,
									0.031,
									0.07,
									0.137,
									0.043,
									0.065,
									0.359
								],
								"e": 0.09
							}
						],
						[
							{
								"h": [
									0.483,
									0.001,
									0.307,
									0.0,
									0.0,
									0.0,
									0.9,
									0.019,
									0.914,
									0.485,
									0.19,
									0.759
								],
								"e": 0.095
							},
							{
								"h": [
									0.006,
									0.006,
									0.186,
									0.022,
									0.139,
									0.542,
									0.057,
									0.192,
									0.265,
									0.215,
									0.211,
									0.002
								],
								"e": 0.169
							},
							{
								"h": [
									0.035,
									0.044,
									0.0,
									0.0,
									0.001,
									0.0,
									0.015,
									0.178,
									0.0,
									0.002,
									0.08,
									0.013
								],
								"e": 0.035
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.0,
									0.076,
									0.019,
									0.003,
									0.0,
									0.0,
									0.002,
									0.009,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.0,
									0.01,
									0.001,
									0.06,
									0.0,
									0.004,
									0.038,
									0.014,
									0.001,
									0.02,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.006,
									0.0,
									0.003,
									0.0,
									0.0,
									0.001,
									0.02,
									0.078,
									0.178
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.0,
									0.035,
									0.01,
									0.003,
									0.002,
									0.006,
									0.006,
									0.0,
									0.0,
									0.004,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.006,
									0.002,
									0.001,
									0.003,
									0.005,
									0.006,
									0.024,
									0.0,
									0.0,
									0.002,
									0.008
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.005,
									0.005,
									0.005,
									0.0,
									0.002,
									0.003,
									0.0,
									0.001,
									0.001,
									0.002,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.002,
									0.001,
									0.003,
									0.001,
									0.007,
									0.003,
									0.002,
									0.003,
									0.002,
									0.001,
									0.001,
									0.004
								],
								"e": 0.002
							},
							{
								"h": [
									0.004,
									0.003,
									0.007,
									0.019,
									0.001,
									0.004,
									0.01,
									0.005,
									0.006,
									0.0,
									0.005,
									0.007
								],
								"e": 0.006
							},
							{
								"h": [
									0.13,
									0.08,
									0.015,
									0.009,
									0.01,
									0.005,
									0.008,
									0.005,
									0.338,
									0.004,
									0.008,
									0.006
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.796,
									0.001,
									0.265,
									0.0,
									0.0,
									0.0,
									0.593,
									0.01,
									0.429,
									0.849,
									0.151,
									0.75
								],
								"e": 0.088
							},
							{
								"h": [
									0.003,
									0.003,
									0.28,
									0.024,
									0.163,
									0.89,
									0.151,
									0.2,
									0.355,
									0.409,
									0.252,
									0.006
								],
								"e": 0.253
							},
							{
								"h": [
									0.004,
									0.04,
									0.0,
									0.0,
									0.001,
									0.0,
									0.016,
									0.271,
									0.002,
									0.003,
									0.118,
									0.017
								],
								"e": 0.039
							},
							{
								"h": [
									0.006,
									0.001,
									0.0,
									0.0,
									0.2,
									0.031,
									0.001,
									0.0,
									0.0,
									0.001,
									0.015,
									0.002
								],
								"e": 0.032
							},
							{
								"h": [
									0.0,
									0.0,
									0.009,
									0.002,
									0.115,
									0.0,
									0.009,
									0.011,
									0.031,
									0.002,
									0.017,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.043,
									0.001,
									0.001,
									0.002,
									0.0,
									0.002,
									0.0,
									0.0,
									0.005,
									0.023,
									0.049,
									0.22
								],
								"e": 0.02
							},
							{
								"h": [
									0.003,
									0.001,
									0.031,
									0.004,
									0.009,
									0.005,
									0.006,
									0.007,
									0.0,
									0.001,
									0.009,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.018,
									0.014,
									0.001,
									0.001,
									0.005,
									0.01,
									0.023,
									0.0,
									0.0,
									0.003,
									0.031
								],
								"e": 0.008
							},
							{
								"h": [
									0.004,
									0.026,
									0.006,
									0.001,
									0.0,
									0.001,
									0.022,
									0.0,
									0.003,
									0.002,
									0.004,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.004,
									0.002,
									0.003,
									0.0,
									0.003,
									0.006,
									0.006,
									0.008,
									0.004,
									0.001,
									0.001,
									0.004
								],
								"e": 0.004
							},
							{
								"h": [
									0.008,
									0.01,
									0.017,
									0.034,
									0.004,
									0.003,
									0.012,
									0.012,
									0.01,
									0.0,
									0.015,
									0.014
								],
								"e": 0.012
							},
							{
								"h": [
									0.163,
									0.06,
									0.045,
									0.017,
									0.064,
									0.012,
									0.023,
									0.023,
									0.614,
									0.019,
									0.016,
									0.008
								],
								"e": 0.052
							}
						],
						[
							{
								"h": [
									0.856,
									0.0,
									0.267,
									0.0,
									0.0,
									0.0,
									0.247,
									0.036,
									0.49,
									0.441,
									0.175,
									0.593
								],
								"e": 0.073
							},
							{
								"h": [
									0.009,
									0.031,
									0.322,
									0.025,
									0.13,
									0.266,
									0.254,
									0.275,
									0.488,
									0.199,
									0.196,
									0.028
								],
								"e": 0.226
							},
							{
								"h": [
									0.023,
									0.044,
									0.0,
									0.0,
									0.008,
									0.004,
									0.25,
									0.147,
									0.007,
									0.001,
									0.055,
									0.011
								],
								"e": 0.049
							},
							{
								"h": [
									0.012,
									0.03,
									0.0,
									0.0,
									0.034,
									0.025,
									0.001,
									0.0,
									0.001,
									0.004,
									0.019,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.02,
									0.093,
									0.002,
									0.061,
									0.008,
									0.011,
									0.028,
									0.092,
									0.004,
									0.05,
									0.0
								],
								"e": 0.036
							},
							{
								"h": [
									0.315,
									0.001,
									0.005,
									0.042,
									0.011,
									0.022,
									0.0,
									0.001,
									0.038,
									0.082,
									0.307,
									0.139
								],
								"e": 0.064
							},
							{
								"h": [
									0.004,
									0.04,
									0.035,
									0.017,
									0.01,
									0.014,
									0.03,
									0.011,
									0.001,
									0.014,
									0.04,
									0.015
								],
								"e": 0.021
							},
							{
								"h": [
									0.001,
									0.014,
									0.117,
									0.004,
									0.009,
									0.061,
									0.018,
									0.302,
									0.001,
									0.007,
									0.021,
									0.212
								],
								"e": 0.047
							},
							{
								"h": [
									0.034,
									0.46,
									0.023,
									0.012,
									0.002,
									0.017,
									0.104,
									0.001,
									0.024,
									0.006,
									0.014,
									0.009
								],
								"e": 0.049
							},
							{
								"h": [
									0.001,
									0.03,
									0.003,
									0.007,
									0.041,
									0.021,
									0.028,
									0.02,
									0.002,
									0.004,
									0.004,
									0.275
								],
								"e": 0.028
							},
							{
								"h": [
									0.002,
									0.006,
									0.08,
									0.18,
									0.48,
									0.025,
									0.002,
									0.054,
									0.065,
									0.001,
									0.012,
									0.016
								],
								"e": 0.055
							},
							{
								"h": [
									0.704,
									0.016,
									0.019,
									0.045,
									0.13,
									0.033,
									0.015,
									0.114,
									0.304,
									0.009,
									0.102,
									1.0
								],
								"e": 0.134
							}
						],
						[
							{
								"h": [
									0.174,
									0.001,
									0.176,
									0.0,
									0.001,
									0.0,
									0.369,
									0.005,
									0.238,
									0.284,
									0.159,
									0.193
								],
								"e": 0.041
							},
							{
								"h": [
									0.003,
									0.01,
									0.366,
									0.028,
									0.171,
									0.143,
									0.014,
									0.094,
									0.209,
									0.12,
									0.283,
									0.004
								],
								"e": 0.127
							},
							{
								"h": [
									0.026,
									0.012,
									0.0,
									0.001,
									0.001,
									0.001,
									0.036,
									0.181,
									0.004,
									0.004,
									0.076,
									0.014
								],
								"e": 0.029
							},
							{
								"h": [
									0.0,
									0.006,
									0.0,
									0.0,
									0.014,
									0.024,
									0.001,
									0.0,
									0.001,
									0.003,
									0.006,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.001,
									0.017,
									0.006,
									0.009,
									0.001,
									0.01,
									0.038,
									0.02,
									0.008,
									0.008,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.0,
									0.002,
									0.027,
									0.002,
									0.003,
									0.001,
									0.003,
									0.006,
									0.014,
									0.022,
									0.098
								],
								"e": 0.01
							},
							{
								"h": [
									0.014,
									0.002,
									0.019,
									0.006,
									0.002,
									0.006,
									0.015,
									0.014,
									0.0,
									0.0,
									0.011,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.009,
									0.004,
									0.002,
									0.011,
									0.006,
									0.009,
									0.11,
									0.001,
									0.002,
									0.009,
									0.027
								],
								"e": 0.01
							},
							{
								"h": [
									0.004,
									0.01,
									0.003,
									0.006,
									0.001,
									0.009,
									0.002,
									0.0,
									0.002,
									0.003,
									0.004,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.002,
									0.006,
									0.001,
									0.023,
									0.002,
									0.016,
									0.004,
									0.001,
									0.003,
									0.001,
									0.014
								],
								"e": 0.006
							},
							{
								"h": [
									0.005,
									0.007,
									0.042,
									0.009,
									0.005,
									0.002,
									0.034,
									0.007,
									0.002,
									0.001,
									0.017,
									0.019
								],
								"e": 0.013
							},
							{
								"h": [
									0.124,
									0.009,
									0.011,
									0.004,
									0.028,
									0.006,
									0.018,
									0.004,
									0.098,
									0.012,
									0.004,
									0.014
								],
								"e": 0.017
							}
						],
						[
							{
								"h": [
									0.178,
									0.0,
									0.191,
									0.0,
									0.0,
									0.0,
									0.124,
									0.017,
									0.184,
									0.429,
									0.041,
									0.192
								],
								"e": 0.033
							},
							{
								"h": [
									0.006,
									0.007,
									0.276,
									0.024,
									0.138,
									0.619,
									0.271,
									0.189,
									0.193,
									0.103,
									0.24,
									0.001
								],
								"e": 0.194
							},
							{
								"h": [
									0.016,
									0.01,
									0.0,
									0.0,
									0.0,
									0.001,
									0.029,
									0.164,
									0.004,
									0.007,
									0.078,
									0.013
								],
								"e": 0.025
							},
							{
								"h": [
									0.01,
									0.004,
									0.0,
									0.0,
									0.013,
									0.026,
									0.0,
									0.0,
									0.0,
									0.001,
									0.008,
									0.007
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.001,
									0.013,
									0.006,
									0.103,
									0.002,
									0.007,
									0.01,
									0.02,
									0.018,
									0.015,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.012,
									0.004,
									0.0,
									0.0,
									0.003,
									0.0,
									0.004,
									0.029,
									0.096
								],
								"e": 0.008
							},
							{
								"h": [
									0.016,
									0.0,
									0.013,
									0.005,
									0.001,
									0.006,
									0.029,
									0.012,
									0.001,
									0.0,
									0.008,
									0.007
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.009,
									0.0,
									0.002,
									0.007,
									0.033,
									0.004,
									0.043,
									0.001,
									0.001,
									0.001,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.004,
									0.002,
									0.002,
									0.006,
									0.002,
									0.013,
									0.002,
									0.001,
									0.007,
									0.005,
									0.005,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.002,
									0.007,
									0.004,
									0.022,
									0.007,
									0.005,
									0.008,
									0.002,
									0.006,
									0.002,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.006,
									0.009,
									0.008,
									0.004,
									0.013,
									0.021,
									0.012,
									0.008,
									0.001,
									0.003,
									0.005,
									0.011
								],
								"e": 0.008
							},
							{
								"h": [
									0.247,
									0.025,
									0.014,
									0.017,
									0.02,
									0.007,
									0.011,
									0.012,
									1.0,
									0.029,
									0.032,
									0.017
								],
								"e": 0.056
							}
						],
						[
							{
								"h": [
									0.83,
									0.0,
									0.224,
									0.0,
									0.0,
									0.0,
									0.587,
									0.038,
									0.811,
									0.649,
									0.194,
									0.665
								],
								"e": 0.092
							},
							{
								"h": [
									0.011,
									0.044,
									0.389,
									0.032,
									0.143,
									0.227,
									0.115,
									0.277,
									0.392,
									0.155,
									0.246,
									0.022
								],
								"e": 0.2
							},
							{
								"h": [
									0.033,
									0.042,
									0.0,
									0.002,
									0.01,
									0.009,
									0.135,
									0.308,
									0.022,
									0.001,
									0.07,
									0.014
								],
								"e": 0.054
							},
							{
								"h": [
									0.001,
									0.019,
									0.0,
									0.0,
									0.025,
									0.014,
									0.001,
									0.0,
									0.003,
									0.01,
									0.01,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.013,
									0.059,
									0.007,
									0.036,
									0.003,
									0.024,
									0.006,
									0.048,
									0.006,
									0.025,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.011,
									0.0,
									0.041,
									0.098,
									0.007,
									0.0,
									0.001,
									0.0,
									0.011,
									0.015,
									0.051,
									0.096
								],
								"e": 0.017
							},
							{
								"h": [
									0.052,
									0.002,
									0.037,
									0.019,
									0.002,
									0.011,
									0.019,
									0.017,
									0.002,
									0.0,
									0.018,
									0.025
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.016,
									0.003,
									0.006,
									0.017,
									0.041,
									0.003,
									0.066,
									0.0,
									0.007,
									0.0,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.011,
									0.006,
									0.007,
									0.029,
									0.002,
									0.043,
									0.003,
									0.007,
									0.009,
									0.008,
									0.017,
									0.002
								],
								"e": 0.011
							},
							{
								"h": [
									0.002,
									0.007,
									0.001,
									0.015,
									0.089,
									0.009,
									0.002,
									0.015,
									0.001,
									0.001,
									0.066,
									0.014
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.003,
									0.029,
									0.028,
									0.1,
									0.088,
									0.002,
									0.029,
									0.03,
									0.004,
									0.002,
									0.009
								],
								"e": 0.02
							},
							{
								"h": [
									0.314,
									0.018,
									0.01,
									0.016,
									0.034,
									0.02,
									0.011,
									0.024,
									0.18,
									0.006,
									0.048,
									0.051
								],
								"e": 0.036
							}
						],
						[
							{
								"h": [
									0.659,
									0.003,
									0.198,
									0.0,
									0.003,
									0.0,
									0.939,
									0.013,
									0.64,
									1.0,
									0.286,
									0.927
								],
								"e": 0.11
							},
							{
								"h": [
									0.014,
									0.029,
									0.547,
									0.045,
									0.253,
									0.597,
									0.025,
									0.41,
									0.63,
									0.504,
									0.447,
									0.009
								],
								"e": 0.323
							},
							{
								"h": [
									0.051,
									0.059,
									0.0,
									0.004,
									0.002,
									0.005,
									0.121,
									0.538,
									0.015,
									0.016,
									0.269,
									0.038
								],
								"e": 0.093
							},
							{
								"h": [
									0.002,
									0.007,
									0.001,
									0.001,
									0.36,
									0.057,
									0.016,
									0.003,
									0.003,
									0.008,
									0.052,
									0.022
								],
								"e": 0.063
							},
							{
								"h": [
									0.005,
									0.001,
									0.018,
									0.041,
									0.105,
									0.004,
									0.028,
									0.216,
									0.033,
									0.008,
									0.029,
									0.0
								],
								"e": 0.039
							},
							{
								"h": [
									0.006,
									0.0,
									0.009,
									0.013,
									0.003,
									0.005,
									0.004,
									0.001,
									0.004,
									0.125,
									0.136,
									0.24
								],
								"e": 0.034
							},
							{
								"h": [
									0.014,
									0.002,
									0.032,
									0.012,
									0.008,
									0.013,
									0.029,
									0.027,
									0.004,
									0.0,
									0.022,
									0.003
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.026,
									0.003,
									0.004,
									0.004,
									0.035,
									0.031,
									0.277,
									0.001,
									0.0,
									0.002,
									0.041
								],
								"e": 0.02
							},
							{
								"h": [
									0.009,
									0.013,
									0.007,
									0.005,
									0.002,
									0.017,
									0.011,
									0.001,
									0.011,
									0.016,
									0.032,
									0.007
								],
								"e": 0.011
							},
							{
								"h": [
									0.017,
									0.002,
									0.013,
									0.011,
									0.009,
									0.01,
									0.003,
									0.011,
									0.011,
									0.001,
									0.008,
									0.005
								],
								"e": 0.008
							},
							{
								"h": [
									0.014,
									0.009,
									0.006,
									0.038,
									0.005,
									0.024,
									0.025,
									0.014,
									0.009,
									0.003,
									0.014,
									0.02
								],
								"e": 0.015
							},
							{
								"h": [
									0.228,
									0.058,
									0.028,
									0.045,
									0.045,
									0.012,
									0.024,
									0.01,
									0.374,
									0.02,
									0.02,
									0.029
								],
								"e": 0.046
							}
						],
						[
							{
								"h": [
									0.865,
									0.0,
									0.229,
									0.0,
									0.0,
									0.0,
									0.225,
									0.078,
									0.479,
									0.446,
									0.156,
									0.796
								],
								"e": 0.078
							},
							{
								"h": [
									0.03,
									0.127,
									0.529,
									0.043,
									0.176,
									0.273,
									0.192,
									0.318,
									0.504,
									0.174,
									0.344,
									0.034
								],
								"e": 0.28
							},
							{
								"h": [
									0.069,
									0.051,
									0.001,
									0.003,
									0.037,
									0.034,
									0.557,
									0.291,
									0.037,
									0.012,
									0.121,
									0.027
								],
								"e": 0.11
							},
							{
								"h": [
									0.006,
									0.128,
									0.001,
									0.001,
									0.036,
									0.025,
									0.004,
									0.002,
									0.016,
									0.02,
									0.047,
									0.011
								],
								"e": 0.029
							},
							{
								"h": [
									0.004,
									0.142,
									0.099,
									0.009,
									0.054,
									0.044,
									0.04,
									0.073,
									0.088,
									0.01,
									0.036,
									0.0
								],
								"e": 0.053
							},
							{
								"h": [
									0.424,
									0.001,
									0.028,
									0.137,
									0.052,
									0.019,
									0.001,
									0.004,
									0.081,
									0.085,
									0.2,
									0.147
								],
								"e": 0.079
							},
							{
								"h": [
									0.054,
									0.039,
									0.035,
									0.034,
									0.013,
									0.059,
									0.052,
									0.028,
									0.004,
									0.006,
									0.065,
									0.097
								],
								"e": 0.036
							},
							{
								"h": [
									0.006,
									0.043,
									0.061,
									0.033,
									0.019,
									0.046,
									0.045,
									0.754,
									0.002,
									0.023,
									0.027,
									0.094
								],
								"e": 0.056
							},
							{
								"h": [
									0.046,
									0.304,
									0.009,
									0.016,
									0.01,
									0.058,
									0.05,
									0.023,
									0.029,
									0.009,
									0.063,
									0.012
								],
								"e": 0.044
							},
							{
								"h": [
									0.001,
									0.028,
									0.004,
									0.044,
									0.022,
									0.036,
									0.036,
									0.027,
									0.002,
									0.007,
									0.017,
									0.327
								],
								"e": 0.035
							},
							{
								"h": [
									0.004,
									0.01,
									0.09,
									0.135,
									0.418,
									0.074,
									0.003,
									0.051,
									0.057,
									0.004,
									0.01,
									0.019
								],
								"e": 0.052
							},
							{
								"h": [
									0.462,
									0.013,
									0.017,
									0.026,
									0.093,
									0.034,
									0.011,
									0.069,
									0.304,
									0.011,
									0.065,
									0.33
								],
								"e": 0.075
							}
						],
						[
							{
								"h": [
									0.702,
									0.0,
									0.327,
									0.0,
									0.001,
									0.0,
									0.323,
									0.021,
									0.264,
									0.28,
									0.161,
									0.209
								],
								"e": 0.057
							},
							{
								"h": [
									0.017,
									0.074,
									0.759,
									0.048,
									0.251,
									0.485,
									0.167,
									0.169,
									0.325,
									0.097,
									0.501,
									0.014
								],
								"e": 0.264
							},
							{
								"h": [
									0.127,
									0.014,
									0.002,
									0.007,
									0.005,
									0.012,
									0.14,
									0.6,
									0.032,
									0.073,
									0.241,
									0.049
								],
								"e": 0.107
							},
							{
								"h": [
									0.001,
									0.052,
									0.003,
									0.001,
									0.017,
									0.041,
									0.008,
									0.005,
									0.007,
									0.016,
									0.052,
									0.063
								],
								"e": 0.028
							},
							{
								"h": [
									0.006,
									0.025,
									0.023,
									0.053,
									0.069,
									0.006,
									0.049,
									0.102,
									0.056,
									0.058,
									0.035,
									0.0
								],
								"e": 0.043
							},
							{
								"h": [
									0.002,
									0.0,
									0.011,
									0.136,
									0.015,
									0.021,
									0.001,
									0.023,
									0.03,
									0.024,
									0.084,
									0.105
								],
								"e": 0.028
							},
							{
								"h": [
									0.102,
									0.004,
									0.016,
									0.022,
									0.006,
									0.04,
									0.041,
									0.083,
									0.004,
									0.001,
									0.009,
									0.188
								],
								"e": 0.028
							},
							{
								"h": [
									0.001,
									0.017,
									0.01,
									0.016,
									0.01,
									0.057,
									0.022,
									0.438,
									0.001,
									0.032,
									0.088,
									0.009
								],
								"e": 0.036
							},
							{
								"h": [
									0.008,
									0.004,
									0.005,
									0.023,
									0.009,
									0.065,
									0.003,
									0.004,
									0.057,
									0.004,
									0.017,
									0.007
								],
								"e": 0.016
							},
							{
								"h": [
									0.015,
									0.003,
									0.024,
									0.009,
									0.016,
									0.01,
									0.046,
									0.016,
									0.008,
									0.017,
									0.008,
									0.018
								],
								"e": 0.016
							},
							{
								"h": [
									0.062,
									0.035,
									0.055,
									0.028,
									0.022,
									0.02,
									0.131,
									0.024,
									0.003,
									0.007,
									0.022,
									0.083
								],
								"e": 0.043
							},
							{
								"h": [
									0.137,
									0.066,
									0.033,
									0.015,
									0.031,
									0.011,
									0.072,
									0.016,
									0.182,
									0.058,
									0.017,
									0.018
								],
								"e": 0.045
							}
						],
						[
							{
								"h": [
									0.869,
									0.0,
									0.221,
									0.0,
									0.0,
									0.0,
									0.583,
									0.104,
									0.856,
									0.682,
									0.186,
									0.819
								],
								"e": 0.102
							},
							{
								"h": [
									0.035,
									0.16,
									0.583,
									0.047,
									0.184,
									0.225,
									0.101,
									0.319,
									0.394,
									0.147,
									0.376,
									0.023
								],
								"e": 0.261
							},
							{
								"h": [
									0.093,
									0.051,
									0.003,
									0.014,
									0.052,
									0.068,
									0.272,
									0.463,
									0.117,
									0.013,
									0.139,
									0.03
								],
								"e": 0.123
							},
							{
								"h": [
									0.001,
									0.169,
									0.009,
									0.011,
									0.021,
									0.017,
									0.012,
									0.006,
									0.069,
									0.034,
									0.028,
									0.016
								],
								"e": 0.037
							},
							{
								"h": [
									0.009,
									0.125,
									0.09,
									0.055,
									0.037,
									0.049,
									0.082,
									0.005,
									0.039,
									0.022,
									0.022,
									0.0
								],
								"e": 0.048
							},
							{
								"h": [
									0.002,
									0.0,
									0.133,
									0.391,
									0.031,
									0.0,
									0.001,
									0.001,
									0.02,
									0.019,
									0.035,
									0.071
								],
								"e": 0.033
							},
							{
								"h": [
									0.153,
									0.005,
									0.028,
									0.037,
									0.009,
									0.029,
									0.023,
									0.067,
									0.016,
									0.0,
									0.005,
									0.205
								],
								"e": 0.03
							},
							{
								"h": [
									0.011,
									0.002,
									0.0,
									0.01,
									0.029,
									0.012,
									0.006,
									0.024,
									0.0,
									0.035,
									0.001,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.009,
									0.001,
									0.003,
									0.025,
									0.006,
									0.15,
									0.001,
									0.066,
									0.007,
									0.005,
									0.03,
									0.006
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.006,
									0.003,
									0.103,
									0.016,
									0.016,
									0.001,
									0.015,
									0.001,
									0.002,
									0.136,
									0.005
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.003,
									0.021,
									0.013,
									0.08,
									0.191,
									0.002,
									0.013,
									0.015,
									0.012,
									0.003,
									0.005
								],
								"e": 0.021
							},
							{
								"h": [
									0.3,
									0.018,
									0.006,
									0.018,
									0.025,
									0.014,
									0.011,
									0.02,
									0.159,
									0.005,
									0.031,
									0.039
								],
								"e": 0.03
							}
						],
						[
							{
								"h": [
									0.24,
									0.001,
									0.54,
									0.0,
									0.006,
									0.0,
									1.0,
									0.035,
									0.645,
									0.17,
									0.328,
									0.335
								],
								"e": 0.089
							},
							{
								"h": [
									0.035,
									0.083,
									0.673,
									0.053,
									0.303,
									1.0,
									0.263,
									0.25,
									0.355,
									0.28,
									0.586,
									0.03
								],
								"e": 0.375
							},
							{
								"h": [
									0.102,
									0.07,
									0.011,
									0.022,
									0.015,
									0.026,
									0.133,
									0.609,
									0.032,
									0.131,
									0.366,
									0.058
								],
								"e": 0.146
							},
							{
								"h": [
									0.004,
									0.021,
									0.003,
									0.01,
									0.02,
									0.051,
									0.082,
									0.016,
									0.014,
									0.011,
									0.096,
									0.067
								],
								"e": 0.044
							},
							{
								"h": [
									0.005,
									0.007,
									0.014,
									0.087,
									0.185,
									0.011,
									0.047,
									0.199,
									0.05,
									0.031,
									0.081,
									0.0
								],
								"e": 0.061
							},
							{
								"h": [
									0.003,
									0.0,
									0.008,
									0.053,
									0.006,
									0.0,
									0.001,
									0.004,
									0.004,
									0.053,
									0.133,
									0.223
								],
								"e": 0.027
							},
							{
								"h": [
									0.035,
									0.005,
									0.028,
									0.029,
									0.006,
									0.031,
									0.019,
									0.042,
									0.008,
									0.0,
									0.017,
									0.011
								],
								"e": 0.017
							},
							{
								"h": [
									0.001,
									0.008,
									0.0,
									0.004,
									0.016,
									0.012,
									0.011,
									0.072,
									0.001,
									0.001,
									0.001,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									0.017,
									0.004,
									0.005,
									0.004,
									0.002,
									0.047,
									0.004,
									0.005,
									0.006,
									0.01,
									0.03,
									0.014
								],
								"e": 0.011
							},
							{
								"h": [
									0.016,
									0.001,
									0.011,
									0.028,
									0.002,
									0.017,
									0.002,
									0.01,
									0.007,
									0.002,
									0.012,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.014,
									0.003,
									0.013,
									0.009,
									0.005,
									0.034,
									0.01,
									0.031,
									0.01,
									0.013,
									0.006,
									0.009
								],
								"e": 0.012
							},
							{
								"h": [
									0.199,
									0.03,
									0.017,
									0.046,
									0.039,
									0.008,
									0.014,
									0.012,
									0.319,
									0.006,
									0.029,
									0.015
								],
								"e": 0.037
							}
						],
						[
							{
								"h": [
									0.913,
									0.0,
									0.231,
									0.0,
									0.001,
									0.0,
									0.229,
									0.266,
									0.524,
									0.486,
									0.15,
									0.993
								],
								"e": 0.1
							},
							{
								"h": [
									0.09,
									0.448,
									0.734,
									0.061,
									0.232,
									0.251,
									0.188,
									0.357,
									0.486,
									0.146,
									0.505,
									0.04
								],
								"e": 0.396
							},
							{
								"h": [
									0.2,
									0.055,
									0.043,
									0.027,
									0.209,
									0.323,
									1.0,
									0.423,
									0.238,
									0.107,
									0.232,
									0.054
								],
								"e": 0.301
							},
							{
								"h": [
									0.005,
									0.868,
									0.033,
									0.028,
									0.033,
									0.037,
									0.04,
									0.03,
									0.249,
									0.08,
									0.095,
									0.071
								],
								"e": 0.14
							},
							{
								"h": [
									0.029,
									1.0,
									0.132,
									0.04,
									0.051,
									0.437,
									0.148,
									0.086,
									0.052,
									0.03,
									0.045,
									0.0
								],
								"e": 0.166
							},
							{
								"h": [
									1.0,
									0.0,
									0.21,
									0.496,
									0.221,
									0.021,
									0.003,
									0.012,
									0.298,
									0.065,
									0.121,
									0.099
								],
								"e": 0.17
							},
							{
								"h": [
									0.239,
									0.126,
									0.038,
									0.1,
									0.019,
									0.12,
									0.035,
									0.108,
									0.017,
									0.006,
									0.037,
									0.612
								],
								"e": 0.079
							},
							{
								"h": [
									0.021,
									0.021,
									0.056,
									0.061,
									0.023,
									0.068,
									0.049,
									0.566,
									0.006,
									0.118,
									0.022,
									0.16
								],
								"e": 0.067
							},
							{
								"h": [
									0.084,
									1.0,
									0.007,
									0.029,
									0.015,
									0.145,
									0.051,
									0.063,
									0.047,
									0.005,
									0.077,
									0.044
								],
								"e": 0.098
							},
							{
								"h": [
									0.001,
									0.014,
									0.005,
									0.214,
									0.008,
									0.033,
									0.018,
									0.082,
									0.001,
									0.007,
									0.033,
									0.394
								],
								"e": 0.048
							},
							{
								"h": [
									0.002,
									0.008,
									0.072,
									0.122,
									0.547,
									0.095,
									0.002,
									0.025,
									0.044,
									0.009,
									0.007,
									0.012
								],
								"e": 0.052
							},
							{
								"h": [
									0.251,
									0.015,
									0.014,
									0.037,
									0.086,
									0.029,
									0.012,
									0.074,
									0.409,
									0.018,
									0.066,
									0.271
								],
								"e": 0.07
							}
						],
						[
							{
								"h": [
									0.184,
									0.001,
									0.162,
									0.0,
									0.005,
									0.0,
									0.365,
									0.037,
									0.267,
									0.323,
									0.142,
									0.294
								],
								"e": 0.047
							},
							{
								"h": [
									0.021,
									0.124,
									0.919,
									0.061,
									0.32,
									0.163,
									0.009,
									0.074,
									0.28,
									0.152,
									0.631,
									0.001
								],
								"e": 0.245
							},
							{
								"h": [
									0.276,
									0.006,
									0.106,
									0.064,
									0.045,
									0.11,
									0.2,
									0.654,
									0.159,
									0.353,
									0.291,
									0.056
								],
								"e": 0.241
							},
							{
								"h": [
									0.0,
									0.177,
									0.033,
									0.021,
									0.009,
									0.021,
									0.053,
									0.03,
									0.131,
									0.052,
									0.043,
									0.051
								],
								"e": 0.063
							},
							{
								"h": [
									0.031,
									0.106,
									0.032,
									0.082,
									0.008,
									0.041,
									0.123,
									0.061,
									0.012,
									0.055,
									0.006,
									0.0
								],
								"e": 0.049
							},
							{
								"h": [
									0.002,
									0.0,
									0.114,
									0.509,
									0.074,
									0.003,
									0.003,
									0.03,
									0.05,
									0.008,
									0.016,
									0.033
								],
								"e": 0.043
							},
							{
								"h": [
									0.252,
									0.008,
									0.013,
									0.031,
									0.002,
									0.092,
									0.023,
									0.113,
									0.015,
									0.0,
									0.005,
									0.564
								],
								"e": 0.049
							},
							{
								"h": [
									0.012,
									0.005,
									0.002,
									0.041,
									0.028,
									0.018,
									0.017,
									0.275,
									0.008,
									0.045,
									0.018,
									0.028
								],
								"e": 0.027
							},
							{
								"h": [
									0.013,
									0.01,
									0.002,
									0.016,
									0.009,
									0.101,
									0.002,
									0.006,
									0.019,
									0.004,
									0.024,
									0.005
								],
								"e": 0.014
							},
							{
								"h": [
									0.003,
									0.003,
									0.007,
									0.018,
									0.004,
									0.002,
									0.007,
									0.006,
									0.001,
									0.003,
									0.009,
									0.013
								],
								"e": 0.005
							},
							{
								"h": [
									0.023,
									0.006,
									0.028,
									0.007,
									0.008,
									0.009,
									0.032,
									0.009,
									0.001,
									0.008,
									0.022,
									0.02
								],
								"e": 0.015
							},
							{
								"h": [
									0.048,
									0.025,
									0.009,
									0.003,
									0.012,
									0.003,
									0.027,
									0.01,
									0.009,
									0.01,
									0.007,
									0.071
								],
								"e": 0.017
							}
						],
						[
							{
								"h": [
									0.197,
									0.0,
									0.18,
									0.0,
									0.0,
									0.0,
									0.127,
									0.148,
									0.208,
									0.509,
									0.036,
									0.291
								],
								"e": 0.047
							},
							{
								"h": [
									0.057,
									0.095,
									0.648,
									0.049,
									0.238,
									0.606,
									0.16,
									0.157,
									0.202,
									0.096,
									0.536,
									0.0
								],
								"e": 0.269
							},
							{
								"h": [
									0.173,
									0.007,
									0.051,
									0.019,
									0.022,
									0.11,
									0.131,
									0.458,
									0.158,
									0.475,
									0.298,
									0.055
								],
								"e": 0.204
							},
							{
								"h": [
									0.001,
									0.108,
									0.026,
									0.048,
									0.008,
									0.018,
									0.022,
									0.121,
									0.144,
									0.043,
									0.059,
									0.123
								],
								"e": 0.073
							},
							{
								"h": [
									0.066,
									0.061,
									0.014,
									0.213,
									0.06,
									0.049,
									0.095,
									0.024,
									0.016,
									0.09,
									0.008,
									0.0
								],
								"e": 0.064
							},
							{
								"h": [
									0.0,
									0.0,
									0.087,
									0.147,
									0.094,
									0.0,
									0.002,
									0.048,
									0.001,
									0.005,
									0.021,
									0.042
								],
								"e": 0.028
							},
							{
								"h": [
									0.294,
									0.001,
									0.01,
									0.022,
									0.002,
									0.092,
									0.025,
									0.068,
									0.038,
									0.0,
									0.002,
									0.847
								],
								"e": 0.055
							},
							{
								"h": [
									0.003,
									0.002,
									0.0,
									0.018,
									0.01,
									0.045,
									0.003,
									0.018,
									0.002,
									0.016,
									0.0,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.004,
									0.0,
									0.001,
									0.015,
									0.057,
									0.232,
									0.0,
									0.024,
									0.022,
									0.002,
									0.012,
									0.002
								],
								"e": 0.022
							},
							{
								"h": [
									0.002,
									0.0,
									0.01,
									0.059,
									0.006,
									0.009,
									0.0,
									0.012,
									0.002,
									0.001,
									0.015,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.001,
									0.002,
									0.001,
									0.007,
									0.026,
									0.002,
									0.002,
									0.0,
									0.019,
									0.001,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.113,
									0.006,
									0.002,
									0.013,
									0.005,
									0.001,
									0.003,
									0.003,
									0.037,
									0.004,
									0.027,
									0.143
								],
								"e": 0.019
							}
						],
						[
							{
								"h": [
									0.97,
									0.0,
									0.217,
									0.0,
									0.001,
									0.0,
									0.619,
									0.519,
									0.96,
									0.758,
									0.18,
									1.0
								],
								"e": 0.145
							},
							{
								"h": [
									0.107,
									0.516,
									0.854,
									0.066,
									0.248,
									0.289,
									0.133,
									0.298,
									0.386,
									0.123,
									0.557,
									0.036
								],
								"e": 0.404
							},
							{
								"h": [
									0.306,
									0.046,
									0.316,
									0.141,
									0.453,
									1.0,
									0.488,
									0.67,
									1.0,
									0.159,
									0.283,
									0.065
								],
								"e": 0.608
							},
							{
								"h": [
									0.0,
									0.402,
									0.11,
									0.241,
									0.014,
									0.015,
									0.075,
									0.123,
									1.0,
									0.111,
									0.065,
									0.068
								],
								"e": 0.22
							},
							{
								"h": [
									0.036,
									0.622,
									0.048,
									0.247,
									0.019,
									0.123,
									0.223,
									0.013,
									0.016,
									0.025,
									0.009,
									0.0
								],
								"e": 0.111
							},
							{
								"h": [
									0.001,
									0.0,
									0.741,
									1.0,
									0.133,
									0.0,
									0.009,
									0.003,
									0.034,
									0.01,
									0.011,
									0.063
								],
								"e": 0.092
							},
							{
								"h": [
									1.0,
									0.01,
									0.041,
									0.064,
									0.008,
									0.102,
									0.022,
									0.118,
									0.218,
									0.0,
									0.004,
									0.907
								],
								"e": 0.109
							},
							{
								"h": [
									0.145,
									0.002,
									0.0,
									0.042,
									0.153,
									0.013,
									0.01,
									0.019,
									0.003,
									0.127,
									0.0,
									0.0
								],
								"e": 0.04
							},
							{
								"h": [
									0.006,
									0.001,
									0.003,
									0.02,
									0.02,
									0.565,
									0.001,
									1.0,
									0.012,
									0.007,
									0.062,
									0.016
								],
								"e": 0.088
							},
							{
								"h": [
									0.001,
									0.005,
									0.003,
									0.202,
									0.006,
									0.012,
									0.002,
									0.015,
									0.001,
									0.005,
									0.486,
									0.006
								],
								"e": 0.04
							},
							{
								"h": [
									0.002,
									0.002,
									0.01,
									0.005,
									0.024,
									0.397,
									0.001,
									0.008,
									0.012,
									0.037,
									0.002,
									0.003
								],
								"e": 0.029
							},
							{
								"h": [
									0.191,
									0.015,
									0.006,
									0.012,
									0.021,
									0.009,
									0.008,
									0.009,
									0.146,
									0.005,
									0.025,
									0.024
								],
								"e": 0.022
							}
						],
						[
							{
								"h": [
									0.506,
									0.0,
									0.473,
									0.0,
									0.004,
									0.0,
									0.483,
									1.0,
									1.0,
									0.687,
									0.143,
									0.277
								],
								"e": 0.164
							},
							{
								"h": [
									1.0,
									1.0,
									0.883,
									0.08,
									0.314,
									0.185,
									0.223,
									0.61,
									0.443,
									0.201,
									0.792,
									0.004
								],
								"e": 0.892
							},
							{
								"h": [
									0.326,
									0.029,
									1.0,
									1.0,
									1.0,
									0.468,
									0.614,
									0.684,
									0.479,
									0.75,
									0.429,
									0.104
								],
								"e": 1.0
							},
							{
								"h": [
									0.002,
									0.152,
									1.0,
									1.0,
									0.029,
									0.085,
									0.411,
									0.599,
									0.185,
									0.301,
									0.121,
									0.116
								],
								"e": 0.457
							},
							{
								"h": [
									0.078,
									0.226,
									0.037,
									0.157,
									0.027,
									0.101,
									0.145,
									0.205,
									0.038,
									0.055,
									0.023,
									1.0
								],
								"e": 0.276
							},
							{
								"h": [
									0.001,
									0.0,
									0.779,
									0.413,
									0.158,
									0.0,
									0.117,
									0.007,
									0.019,
									0.027,
									0.022,
									0.063
								],
								"e": 0.089
							},
							{
								"h": [
									0.647,
									0.099,
									0.05,
									0.055,
									0.009,
									0.099,
									0.016,
									0.174,
									0.636,
									0.0,
									0.005,
									0.589
								],
								"e": 0.127
							},
							{
								"h": [
									0.546,
									0.007,
									0.0,
									0.028,
									0.069,
									0.037,
									0.023,
									0.019,
									0.024,
									0.059,
									0.0,
									0.001
								],
								"e": 0.059
							},
							{
								"h": [
									0.011,
									0.007,
									0.009,
									0.009,
									0.05,
									0.513,
									0.004,
									0.952,
									0.01,
									0.013,
									0.039,
									0.026
								],
								"e": 0.087
							},
							{
								"h": [
									0.005,
									0.01,
									0.008,
									0.363,
									0.001,
									0.008,
									0.002,
									0.021,
									0.001,
									0.002,
									0.33,
									0.011
								],
								"e": 0.041
							},
							{
								"h": [
									0.002,
									0.002,
									0.005,
									0.009,
									0.017,
									0.24,
									0.002,
									0.005,
									0.011,
									0.039,
									0.003,
									0.028
								],
								"e": 0.023
							},
							{
								"h": [
									0.043,
									0.015,
									0.002,
									0.051,
									0.016,
									0.01,
									0.006,
									0.013,
									0.097,
									0.001,
									0.038,
									0.015
								],
								"e": 0.021
							}
						],
						[
							{
								"h": [
									0.748,
									1.0,
									0.532,
									1.0,
									1.0,
									1.0,
									0.694,
									0.301,
									0.459,
									0.058,
									1.0,
									0.499
								],
								"e": 1.0
							},
							{
								"h": [
									0.087,
									0.335,
									1.0,
									0.081,
									0.456,
									0.214,
									0.131,
									0.085,
									0.33,
									0.088,
									1.0,
									1.0
								],
								"e": 0.814
							},
							{
								"h": [
									0.288,
									0.012,
									0.548,
									0.086,
									0.098,
									0.415,
									0.343,
									1.0,
									0.443,
									1.0,
									1.0,
									0.174
								],
								"e": 0.609
							},
							{
								"h": [
									0.25,
									0.071,
									0.151,
									0.492,
									0.007,
									0.054,
									1.0,
									1.0,
									0.096,
									0.056,
									0.724,
									1.0
								],
								"e": 0.532
							},
							{
								"h": [
									0.037,
									0.048,
									0.017,
									0.421,
									0.124,
									0.208,
									0.116,
									1.0,
									0.018,
									0.052,
									0.038,
									0.0
								],
								"e": 0.155
							},
							{
								"h": [
									0.023,
									0.0,
									0.049,
									0.088,
									0.07,
									0.003,
									0.016,
									0.02,
									0.008,
									0.006,
									0.318,
									0.188
								],
								"e": 0.046
							},
							{
								"h": [
									0.163,
									0.016,
									0.02,
									0.03,
									0.012,
									0.111,
									0.048,
									0.148,
									0.071,
									0.0,
									0.038,
									0.041
								],
								"e": 0.047
							},
							{
								"h": [
									0.022,
									0.086,
									0.001,
									0.024,
									0.021,
									0.033,
									0.024,
									0.104,
									0.015,
									0.005,
									0.001,
									0.012
								],
								"e": 0.024
							},
							{
								"h": [
									0.009,
									0.016,
									0.005,
									0.013,
									0.014,
									0.153,
									0.011,
									0.021,
									0.066,
									0.039,
									0.107,
									0.037
								],
								"e": 0.037
							},
							{
								"h": [
									0.077,
									0.007,
									0.035,
									0.148,
									0.004,
									0.022,
									0.006,
									0.019,
									0.012,
									0.011,
									0.047,
									0.002
								],
								"e": 0.028
							},
							{
								"h": [
									0.059,
									0.005,
									0.011,
									0.011,
									0.006,
									0.103,
									0.022,
									0.041,
									0.009,
									0.055,
									0.018,
									0.019
								],
								"e": 0.029
							},
							{
								"h": [
									0.201,
									0.02,
									0.012,
									0.053,
									0.039,
									0.009,
									0.011,
									0.005,
									0.333,
									0.01,
									0.023,
									0.033
								],
								"e": 0.036
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ".",
					"o": " Hero",
					"t": " Hero",
					"r": [
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.113,
								" It": 0.055,
								" If": 0.035,
								" In": 0.029,
								" You": 0.025,
								" I": 0.024,
								" And": 0.023,
								" There": 0.023,
								" ": 0.021,
								" He": 0.021
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.117,
								" It": 0.084,
								" There": 0.034,
								" ": 0.032,
								" He": 0.03,
								" If": 0.027,
								" They": 0.026,
								" When": 0.026,
								" We": 0.022,
								" You": 0.022
							}
						},
						{
							"p": " It",
							"b": 0.0,
							"k": {
								" It": 0.081,
								" The": 0.065,
								" ": 0.049,
								" You": 0.047,
								" His": 0.042,
								" That": 0.041,
								" He": 0.031,
								" If": 0.028,
								" And": 0.024,
								" When": 0.024
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.188,
								" The": 0.083,
								" It": 0.053,
								" When": 0.041,
								" There": 0.038,
								"<|endoftext|>": 0.037,
								" That": 0.034,
								" If": 0.03,
								" You": 0.022,
								" They": 0.02
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.148,
								" It": 0.058,
								" You": 0.051,
								" If": 0.039,
								" That": 0.027,
								" There": 0.026,
								" When": 0.023,
								" Who": 0.023,
								" Its": 0.023,
								" They": 0.021
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.081,
								" The": 0.053,
								" He": 0.049,
								" You": 0.041,
								" And": 0.036,
								" If": 0.036,
								" They": 0.036,
								" Some": 0.034,
								" It": 0.033,
								" Or": 0.026
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.173,
								" etc": 0.109,
								" And": 0.081,
								"<|endoftext|>": 0.05,
								" He": 0.045,
								" All": 0.035,
								" Or": 0.034,
								" The": 0.031,
								" You": 0.024,
								" They": 0.02
							}
						},
						{
							"p": " ",
							"b": 0.006,
							"k": {
								" ": 0.131,
								" And": 0.12,
								" Kid": 0.061,
								" He": 0.057,
								" Or": 0.049,
								"<|endoftext|>": 0.043,
								" You": 0.04,
								" All": 0.033,
								" Total": 0.024,
								" Finally": 0.017
							}
						},
						{
							"p": " ",
							"b": 0.05,
							"k": {
								" ": 0.241,
								"<|endoftext|>": 0.128,
								" All": 0.076,
								" Hero": 0.05,
								" You": 0.041,
								" And": 0.037,
								" These": 0.029,
								" Note": 0.026,
								" Total": 0.021,
								" Who": 0.014
							}
						},
						{
							"p": " Hero",
							"b": 0.963,
							"k": {
								" Hero": 0.963,
								" ": 0.023,
								"  ": 0.002,
								"<|endoftext|>": 0.002,
								" All": 0.001,
								" Vill": 0.001,
								" Heroes": 0.001,
								" Kid": 0.001,
								"!": 0.0,
								"\"": 0.0
							}
						},
						{
							"p": " Hero",
							"b": 0.934,
							"k": {
								" Hero": 0.934,
								" Vill": 0.035,
								" ": 0.018,
								" Heroes": 0.002,
								"  ": 0.001,
								" Batman": 0.001,
								"<|endoftext|>": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0
							}
						},
						{
							"p": " Hero",
							"b": 0.399,
							"k": {
								" Hero": 0.399,
								" ": 0.184,
								" Vill": 0.103,
								"  ": 0.014,
								"<|endoftext|>": 0.012,
								" Batman": 0.008,
								" Heroes": 0.007,
								" The": 0.006,
								" (": 0.005,
								" I": 0.003
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.782,
									0.001,
									0.379,
									0.007,
									0.031,
									0.098,
									0.519,
									0.014,
									0.021,
									0.814,
									0.397,
									1.0
								],
								"e": 0.248
							},
							{
								"h": [
									0.037,
									0.069,
									0.231,
									1.0,
									1.0,
									0.951,
									1.0,
									1.0,
									1.0,
									1.0,
									0.025,
									0.272
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.019,
									0.08,
									0.683,
									0.198,
									0.239,
									0.569,
									0.402,
									0.066,
									0.253,
									1.0
								],
								"e": 0.82
							},
							{
								"h": [
									1.0,
									0.96,
									0.212,
									1.0,
									1.0,
									1.0,
									0.153,
									0.304,
									0.28,
									1.0,
									1.0,
									0.637
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.965,
									1.0,
									1.0,
									1.0,
									0.12,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.285,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.523,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.186,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.336,
									1.0,
									1.0,
									1.0,
									1.0,
									0.463,
									1.0,
									1.0,
									0.136,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.713,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.387,
									1.0,
									1.0,
									1.0,
									0.054
								],
								"e": 1.0
							},
							{
								"h": [
									0.288,
									0.825,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									0.236,
									0.199
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.081,
									0.01,
									0.687,
									0.001,
									0.012,
									0.261,
									0.019,
									0.007,
									0.109,
									0.633,
									0.384,
									0.586
								],
								"e": 0.166
							},
							{
								"h": [
									0.007,
									0.029,
									0.106,
									0.013,
									0.112,
									0.656,
									0.889,
									0.209,
									0.573,
									0.193,
									0.101,
									0.261
								],
								"e": 0.273
							},
							{
								"h": [
									0.022,
									0.013,
									0.0,
									0.001,
									0.002,
									0.004,
									0.026,
									0.048,
									0.007,
									0.0,
									0.037,
									0.004
								],
								"e": 0.016
							},
							{
								"h": [
									0.024,
									0.034,
									0.0,
									0.0,
									0.073,
									0.083,
									0.0,
									0.0,
									0.0,
									0.02,
									0.015,
									0.002
								],
								"e": 0.03
							},
							{
								"h": [
									0.001,
									0.008,
									0.236,
									0.004,
									0.081,
									0.002,
									0.034,
									0.003,
									0.123,
									0.051,
									0.091,
									0.0
								],
								"e": 0.062
							},
							{
								"h": [
									0.0,
									0.0,
									0.014,
									0.07,
									0.001,
									0.007,
									0.0,
									0.001,
									0.001,
									0.017,
									0.081,
									0.026
								],
								"e": 0.013
							},
							{
								"h": [
									0.015,
									0.031,
									0.032,
									0.036,
									0.019,
									0.013,
									0.024,
									0.028,
									0.0,
									0.001,
									0.015,
									0.003
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.015,
									0.006,
									0.024,
									0.03,
									0.029,
									0.011,
									0.07,
									0.001,
									0.002,
									0.002,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.01,
									0.007,
									0.052,
									0.027,
									0.002,
									0.006,
									0.049,
									0.001,
									0.054,
									0.096,
									0.01,
									0.006
								],
								"e": 0.018
							},
							{
								"h": [
									0.045,
									0.015,
									0.024,
									0.02,
									0.877,
									0.006,
									0.008,
									0.039,
									0.008,
									0.003,
									0.008,
									0.007
								],
								"e": 0.054
							},
							{
								"h": [
									0.02,
									0.012,
									0.022,
									0.037,
									0.071,
									0.041,
									0.063,
									0.153,
									0.016,
									0.014,
									0.02,
									0.007
								],
								"e": 0.039
							},
							{
								"h": [
									0.437,
									0.161,
									0.124,
									0.276,
									0.122,
									0.218,
									0.04,
									0.095,
									0.259,
									0.085,
									0.241,
									0.435
								],
								"e": 0.241
							}
						],
						[
							{
								"h": [
									0.142,
									0.0,
									0.1,
									0.0,
									0.006,
									0.036,
									0.314,
									0.004,
									0.012,
									0.484,
									0.213,
									0.412
								],
								"e": 0.103
							},
							{
								"h": [
									0.009,
									0.017,
									0.147,
									0.018,
									0.15,
									0.134,
									0.129,
									0.159,
									0.294,
									0.317,
									0.164,
									0.155
								],
								"e": 0.17
							},
							{
								"h": [
									0.018,
									0.002,
									0.0,
									0.001,
									0.002,
									0.0,
									0.009,
									0.128,
									0.0,
									0.001,
									0.075,
									0.009
								],
								"e": 0.017
							},
							{
								"h": [
									0.005,
									0.001,
									0.0,
									0.0,
									0.019,
									0.015,
									0.001,
									0.0,
									0.0,
									0.002,
									0.005,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.026,
									0.004,
									0.025,
									0.0,
									0.011,
									0.002,
									0.035,
									0.005,
									0.005,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.001,
									0.013,
									0.016,
									0.001,
									0.02,
									0.0,
									0.0,
									0.001,
									0.027,
									0.013,
									0.017
								],
								"e": 0.008
							},
							{
								"h": [
									0.004,
									0.005,
									0.02,
									0.02,
									0.005,
									0.009,
									0.013,
									0.015,
									0.001,
									0.005,
									0.004,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.004,
									0.024,
									0.003,
									0.004,
									0.004,
									0.017,
									0.008,
									0.001,
									0.0,
									0.005,
									0.021
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.012,
									0.045,
									0.004,
									0.0,
									0.005,
									0.005,
									0.0,
									0.005,
									0.036,
									0.003,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.024,
									0.001,
									0.008,
									0.003,
									0.029,
									0.004,
									0.019,
									0.004,
									0.026,
									0.006,
									0.001,
									0.008
								],
								"e": 0.009
							},
							{
								"h": [
									0.097,
									0.024,
									0.052,
									0.047,
									0.003,
									0.006,
									0.047,
									0.016,
									0.006,
									0.001,
									0.037,
									0.002
								],
								"e": 0.027
							},
							{
								"h": [
									0.092,
									0.716,
									0.26,
									0.139,
									0.033,
									0.018,
									0.047,
									0.038,
									0.094,
									0.028,
									0.066,
									0.06
								],
								"e": 0.141
							}
						],
						[
							{
								"h": [
									0.53,
									0.0,
									0.154,
									0.0,
									0.003,
									0.038,
									0.338,
									0.003,
									0.005,
									0.413,
									0.23,
									0.421
								],
								"e": 0.131
							},
							{
								"h": [
									0.006,
									0.015,
									0.205,
									0.02,
									0.148,
									0.182,
									0.091,
									0.138,
									0.326,
									0.364,
									0.207,
									0.092
								],
								"e": 0.176
							},
							{
								"h": [
									0.003,
									0.002,
									0.0,
									0.001,
									0.001,
									0.001,
									0.008,
									0.187,
									0.002,
									0.001,
									0.094,
									0.012
								],
								"e": 0.02
							},
							{
								"h": [
									0.002,
									0.002,
									0.0,
									0.0,
									0.014,
									0.017,
									0.001,
									0.0,
									0.0,
									0.001,
									0.01,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.006,
									0.008,
									0.036,
									0.0,
									0.016,
									0.01,
									0.021,
									0.007,
									0.007,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.0,
									0.002,
									0.003,
									0.0,
									0.004,
									0.0,
									0.0,
									0.0,
									0.011,
									0.012,
									0.033
								],
								"e": 0.005
							},
							{
								"h": [
									0.003,
									0.003,
									0.011,
									0.015,
									0.002,
									0.013,
									0.004,
									0.013,
									0.003,
									0.001,
									0.003,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.003,
									0.008,
									0.001,
									0.001,
									0.002,
									0.009,
									0.005,
									0.0,
									0.0,
									0.0,
									0.003
								],
								"e": 0.002
							},
							{
								"h": [
									0.002,
									0.003,
									0.025,
									0.0,
									0.0,
									0.002,
									0.008,
									0.0,
									0.005,
									0.027,
									0.001,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.036,
									0.0,
									0.004,
									0.002,
									0.007,
									0.004,
									0.01,
									0.003,
									0.019,
									0.002,
									0.001,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.065,
									0.011,
									0.008,
									0.019,
									0.003,
									0.003,
									0.012,
									0.006,
									0.002,
									0.001,
									0.022,
									0.005
								],
								"e": 0.012
							},
							{
								"h": [
									0.125,
									0.081,
									0.196,
									0.171,
									0.059,
									0.021,
									0.05,
									0.055,
									0.292,
									0.048,
									0.083,
									0.056
								],
								"e": 0.125
							}
						],
						[
							{
								"h": [
									0.032,
									0.53,
									0.173,
									0.0,
									0.02,
									1.0,
									0.004,
									0.006,
									0.393,
									0.563,
									0.694,
									0.728
								],
								"e": 0.331
							},
							{
								"h": [
									0.01,
									0.024,
									0.238,
									0.02,
									0.135,
									0.695,
									0.678,
									0.189,
									0.436,
									0.104,
									0.154,
									0.219
								],
								"e": 0.249
							},
							{
								"h": [
									0.009,
									0.033,
									0.0,
									0.0,
									0.001,
									0.0,
									0.092,
									0.093,
									0.001,
									0.0,
									0.046,
									0.006
								],
								"e": 0.031
							},
							{
								"h": [
									0.018,
									0.002,
									0.0,
									0.0,
									0.043,
									0.038,
									0.0,
									0.0,
									0.0,
									0.002,
									0.018,
									0.001
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.004,
									0.105,
									0.002,
									0.071,
									0.001,
									0.014,
									0.068,
									0.416,
									0.005,
									0.043,
									0.0
								],
								"e": 0.062
							},
							{
								"h": [
									0.001,
									0.0,
									0.002,
									0.029,
									0.002,
									0.006,
									0.0,
									0.001,
									0.0,
									0.043,
									0.072,
									0.07
								],
								"e": 0.016
							},
							{
								"h": [
									0.004,
									0.014,
									0.036,
									0.061,
									0.032,
									0.011,
									0.036,
									0.014,
									0.0,
									0.003,
									0.013,
									0.001
								],
								"e": 0.02
							},
							{
								"h": [
									0.0,
									0.007,
									0.009,
									0.003,
									0.008,
									0.061,
									0.033,
									0.009,
									0.001,
									0.0,
									0.003,
									0.021
								],
								"e": 0.014
							},
							{
								"h": [
									0.01,
									0.01,
									0.041,
									0.005,
									0.001,
									0.024,
									0.022,
									0.001,
									0.047,
									0.156,
									0.006,
									0.007
								],
								"e": 0.017
							},
							{
								"h": [
									0.054,
									0.038,
									0.019,
									0.018,
									0.122,
									0.008,
									0.001,
									0.053,
									0.004,
									0.003,
									0.007,
									0.008
								],
								"e": 0.022
							},
							{
								"h": [
									0.006,
									0.011,
									0.027,
									0.036,
									0.091,
									0.1,
									0.044,
									0.213,
									0.032,
									0.044,
									0.009,
									0.009
								],
								"e": 0.055
							},
							{
								"h": [
									1.0,
									0.092,
									0.103,
									0.637,
									0.194,
									0.373,
									0.034,
									0.178,
									1.0,
									0.048,
									0.347,
									1.0
								],
								"e": 0.472
							}
						],
						[
							{
								"h": [
									0.406,
									0.0,
									0.113,
									0.0,
									0.005,
									0.017,
									0.093,
									0.007,
									0.008,
									0.368,
									0.198,
									0.256
								],
								"e": 0.084
							},
							{
								"h": [
									0.019,
									0.025,
									0.269,
									0.022,
									0.195,
									0.073,
									0.031,
									0.084,
									0.328,
									0.225,
									0.227,
									0.059
								],
								"e": 0.15
							},
							{
								"h": [
									0.036,
									0.004,
									0.0,
									0.003,
									0.001,
									0.0,
									0.013,
									0.145,
									0.001,
									0.001,
									0.074,
									0.01
								],
								"e": 0.021
							},
							{
								"h": [
									0.002,
									0.001,
									0.002,
									0.0,
									0.005,
									0.033,
									0.003,
									0.0,
									0.0,
									0.009,
									0.007,
									0.005
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.002,
									0.006,
									0.013,
									0.01,
									0.002,
									0.024,
									0.007,
									0.048,
									0.02,
									0.011,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.378,
									0.173,
									0.014,
									0.05,
									0.002,
									0.12,
									0.0,
									0.001,
									0.06,
									0.088,
									0.025,
									0.015
								],
								"e": 0.072
							},
							{
								"h": [
									0.023,
									0.021,
									0.005,
									0.043,
									0.004,
									0.015,
									0.033,
									0.033,
									0.002,
									0.067,
									0.031,
									0.002
								],
								"e": 0.025
							},
							{
								"h": [
									0.0,
									0.011,
									0.348,
									0.039,
									0.019,
									0.003,
									0.057,
									0.05,
									0.002,
									0.013,
									0.05,
									0.699
								],
								"e": 0.079
							},
							{
								"h": [
									0.02,
									0.107,
									0.004,
									0.007,
									0.0,
									0.011,
									0.053,
									0.0,
									0.011,
									0.041,
									0.014,
									0.002
								],
								"e": 0.019
							},
							{
								"h": [
									0.092,
									0.007,
									0.055,
									0.003,
									0.094,
									0.002,
									0.24,
									0.003,
									0.007,
									0.027,
									0.002,
									0.038
								],
								"e": 0.035
							},
							{
								"h": [
									0.168,
									0.07,
									0.095,
									0.028,
									0.009,
									0.003,
									0.55,
									0.188,
									0.002,
									0.001,
									0.247,
									0.024
								],
								"e": 0.091
							},
							{
								"h": [
									0.069,
									0.128,
									0.184,
									0.095,
									0.057,
									0.026,
									0.069,
									0.099,
									0.019,
									0.112,
									0.07,
									0.05
								],
								"e": 0.105
							}
						],
						[
							{
								"h": [
									0.217,
									0.0,
									0.177,
									0.002,
									0.012,
									0.031,
									0.04,
									0.006,
									0.008,
									0.401,
									0.188,
									0.243
								],
								"e": 0.073
							},
							{
								"h": [
									0.015,
									0.014,
									0.194,
									0.018,
									0.138,
									0.136,
									0.141,
									0.122,
									0.218,
									0.159,
									0.187,
									0.138
								],
								"e": 0.143
							},
							{
								"h": [
									0.008,
									0.001,
									0.0,
									0.0,
									0.0,
									0.001,
									0.009,
									0.133,
									0.002,
									0.001,
									0.073,
									0.011
								],
								"e": 0.016
							},
							{
								"h": [
									0.004,
									0.001,
									0.0,
									0.0,
									0.007,
									0.03,
									0.001,
									0.0,
									0.0,
									0.005,
									0.012,
									0.011
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.001,
									0.007,
									0.012,
									0.03,
									0.002,
									0.024,
									0.013,
									0.026,
									0.032,
									0.008,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.015,
									0.0,
									0.01,
									0.02,
									0.003,
									0.002,
									0.0,
									0.0,
									0.003,
									0.016,
									0.02,
									0.027
								],
								"e": 0.008
							},
							{
								"h": [
									0.03,
									0.042,
									0.003,
									0.036,
									0.002,
									0.014,
									0.031,
									0.026,
									0.004,
									0.001,
									0.068,
									0.002
								],
								"e": 0.019
							},
							{
								"h": [
									0.0,
									0.007,
									0.007,
									0.007,
									0.003,
									0.008,
									0.021,
									0.113,
									0.0,
									0.001,
									0.011,
									0.032
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.042,
									0.006,
									0.003,
									0.0,
									0.008,
									0.257,
									0.001,
									0.024,
									0.044,
									0.007,
									0.004
								],
								"e": 0.021
							},
							{
								"h": [
									0.02,
									0.004,
									0.046,
									0.007,
									0.054,
									0.004,
									0.108,
									0.009,
									0.006,
									0.028,
									0.002,
									0.003
								],
								"e": 0.018
							},
							{
								"h": [
									0.118,
									0.136,
									0.01,
									0.041,
									0.031,
									0.012,
									0.101,
									0.027,
									0.002,
									0.004,
									0.077,
									0.051
								],
								"e": 0.049
							},
							{
								"h": [
									0.139,
									0.307,
									0.054,
									0.117,
									0.038,
									0.022,
									0.032,
									0.118,
									0.065,
									0.254,
									0.133,
									0.038
								],
								"e": 0.132
							}
						],
						[
							{
								"h": [
									0.068,
									0.009,
									0.836,
									0.002,
									0.017,
									0.117,
									0.012,
									0.017,
									0.161,
									0.598,
									0.456,
									0.531
								],
								"e": 0.168
							},
							{
								"h": [
									0.023,
									0.119,
									0.283,
									0.025,
									0.155,
									0.553,
									0.906,
									0.212,
									0.384,
									0.124,
									0.196,
									0.3
								],
								"e": 0.292
							},
							{
								"h": [
									0.025,
									0.048,
									0.0,
									0.002,
									0.002,
									0.006,
									0.052,
									0.274,
									0.01,
									0.0,
									0.072,
									0.01
								],
								"e": 0.044
							},
							{
								"h": [
									0.027,
									0.066,
									0.001,
									0.0,
									0.15,
									0.064,
									0.001,
									0.0,
									0.0,
									0.072,
									0.016,
									0.003
								],
								"e": 0.044
							},
							{
								"h": [
									0.002,
									0.018,
									0.156,
									0.011,
									0.111,
									0.003,
									0.075,
									0.03,
									0.183,
									0.079,
									0.08,
									0.0
								],
								"e": 0.069
							},
							{
								"h": [
									0.004,
									0.0,
									0.035,
									0.11,
									0.003,
									0.003,
									0.0,
									0.0,
									0.003,
									0.029,
									0.083,
									0.031
								],
								"e": 0.017
							},
							{
								"h": [
									0.093,
									0.036,
									0.016,
									0.171,
									0.024,
									0.018,
									0.056,
									0.046,
									0.0,
									0.0,
									0.183,
									0.007
								],
								"e": 0.047
							},
							{
								"h": [
									0.0,
									0.053,
									0.002,
									0.085,
									0.016,
									0.021,
									0.029,
									1.0,
									0.0,
									0.002,
									0.002,
									0.002
								],
								"e": 0.059
							},
							{
								"h": [
									0.003,
									0.005,
									0.038,
									0.016,
									0.001,
									0.003,
									0.047,
									0.001,
									0.106,
									0.303,
									0.014,
									0.001
								],
								"e": 0.022
							},
							{
								"h": [
									0.011,
									0.006,
									0.008,
									0.009,
									0.165,
									0.008,
									0.001,
									0.012,
									0.001,
									0.0,
									0.011,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.003,
									0.002,
									0.006,
									0.01,
									0.015,
									0.019,
									0.01,
									0.05,
									0.002,
									0.004,
									0.003,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.203,
									0.066,
									0.027,
									0.039,
									0.053,
									0.065,
									0.009,
									0.045,
									0.085,
									0.019,
									0.069,
									0.052
								],
								"e": 0.069
							}
						],
						[
							{
								"h": [
									0.641,
									0.0,
									0.11,
									0.001,
									0.009,
									0.013,
									0.637,
									0.012,
									0.008,
									0.446,
									0.24,
									0.474
								],
								"e": 0.171
							},
							{
								"h": [
									0.038,
									0.066,
									0.428,
									0.036,
									0.284,
									0.375,
									0.239,
									0.365,
									0.543,
									0.428,
									0.386,
									0.182
								],
								"e": 0.327
							},
							{
								"h": [
									0.044,
									0.013,
									0.0,
									0.01,
									0.01,
									0.003,
									0.051,
									0.415,
									0.012,
									0.007,
									0.248,
									0.03
								],
								"e": 0.063
							},
							{
								"h": [
									0.003,
									0.019,
									0.004,
									0.0,
									0.043,
									0.036,
									0.019,
									0.001,
									0.001,
									0.012,
									0.031,
									0.062
								],
								"e": 0.023
							},
							{
								"h": [
									0.002,
									0.002,
									0.04,
									0.063,
									0.081,
									0.008,
									0.049,
									0.029,
									0.036,
									0.024,
									0.005,
									0.0
								],
								"e": 0.029
							},
							{
								"h": [
									0.006,
									0.003,
									0.04,
									0.019,
									0.005,
									0.04,
									0.001,
									0.001,
									0.008,
									0.044,
									0.063,
									0.038
								],
								"e": 0.02
							},
							{
								"h": [
									0.025,
									0.019,
									0.014,
									0.029,
									0.005,
									0.032,
									0.024,
									0.093,
									0.02,
									0.005,
									0.057,
									0.003
								],
								"e": 0.026
							},
							{
								"h": [
									0.0,
									0.012,
									0.05,
									0.007,
									0.001,
									0.006,
									0.06,
									0.095,
									0.001,
									0.0,
									0.023,
									0.05
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.016,
									0.026,
									0.002,
									0.001,
									0.004,
									0.003,
									0.002,
									0.016,
									0.136,
									0.006,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.008,
									0.001,
									0.015,
									0.006,
									0.003,
									0.004,
									0.014,
									0.004,
									0.023,
									0.003,
									0.001,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.095,
									0.023,
									0.013,
									0.021,
									0.006,
									0.007,
									0.067,
									0.017,
									0.004,
									0.001,
									0.049,
									0.004
								],
								"e": 0.022
							},
							{
								"h": [
									0.076,
									0.101,
									0.231,
									0.107,
									0.05,
									0.015,
									0.056,
									0.021,
									0.014,
									0.103,
									0.028,
									0.078
								],
								"e": 0.092
							}
						],
						[
							{
								"h": [
									0.035,
									0.665,
									0.176,
									0.003,
									0.044,
									0.76,
									0.004,
									0.018,
									0.554,
									0.584,
									0.791,
									0.66
								],
								"e": 0.355
							},
							{
								"h": [
									0.03,
									0.08,
									0.399,
									0.034,
									0.202,
									0.793,
									0.644,
									0.21,
									0.446,
									0.097,
									0.289,
									0.285
								],
								"e": 0.308
							},
							{
								"h": [
									0.026,
									0.039,
									0.0,
									0.001,
									0.004,
									0.003,
									0.188,
									0.206,
									0.005,
									0.001,
									0.103,
									0.016
								],
								"e": 0.059
							},
							{
								"h": [
									0.022,
									0.008,
									0.0,
									0.0,
									0.063,
									0.042,
									0.001,
									0.0,
									0.001,
									0.012,
									0.037,
									0.007
								],
								"e": 0.024
							},
							{
								"h": [
									0.002,
									0.021,
									0.105,
									0.004,
									0.1,
									0.005,
									0.046,
									0.197,
									0.618,
									0.014,
									0.045,
									0.0
								],
								"e": 0.098
							},
							{
								"h": [
									0.001,
									0.0,
									0.005,
									0.056,
									0.007,
									0.008,
									0.0,
									0.002,
									0.001,
									0.053,
									0.091,
									0.133
								],
								"e": 0.025
							},
							{
								"h": [
									0.01,
									0.02,
									0.037,
									0.106,
									0.054,
									0.029,
									0.19,
									0.037,
									0.001,
									0.004,
									0.067,
									0.003
								],
								"e": 0.046
							},
							{
								"h": [
									0.0,
									0.031,
									0.03,
									0.018,
									0.002,
									0.025,
									0.122,
									0.083,
									0.001,
									0.001,
									0.026,
									0.02
								],
								"e": 0.026
							},
							{
								"h": [
									0.013,
									0.02,
									0.036,
									0.008,
									0.001,
									0.003,
									0.043,
									0.005,
									0.091,
									0.247,
									0.032,
									0.004
								],
								"e": 0.023
							},
							{
								"h": [
									0.026,
									0.076,
									0.027,
									0.004,
									0.031,
									0.044,
									0.003,
									0.06,
									0.004,
									0.004,
									0.007,
									0.002
								],
								"e": 0.023
							},
							{
								"h": [
									0.008,
									0.016,
									0.024,
									0.055,
									0.074,
									0.05,
									0.033,
									0.135,
									0.04,
									0.02,
									0.012,
									0.011
								],
								"e": 0.044
							},
							{
								"h": [
									0.734,
									0.085,
									0.057,
									0.194,
									0.154,
									0.159,
									0.026,
									0.087,
									0.389,
									0.037,
									0.139,
									0.367
								],
								"e": 0.223
							}
						],
						[
							{
								"h": [
									0.243,
									0.0,
									0.17,
									0.005,
									0.017,
									0.021,
									0.304,
									0.016,
									0.009,
									0.43,
									0.175,
									0.246
								],
								"e": 0.104
							},
							{
								"h": [
									0.1,
									0.118,
									0.589,
									0.041,
									0.299,
									0.193,
									0.08,
									0.12,
									0.418,
									0.164,
									0.424,
									0.097
								],
								"e": 0.252
							},
							{
								"h": [
									0.053,
									0.002,
									0.0,
									0.02,
									0.006,
									0.003,
									0.051,
									0.572,
									0.025,
									0.014,
									0.223,
									0.037
								],
								"e": 0.072
							},
							{
								"h": [
									0.005,
									0.017,
									0.028,
									0.001,
									0.032,
									0.071,
									0.025,
									0.004,
									0.0,
									0.074,
									0.034,
									0.096
								],
								"e": 0.037
							},
							{
								"h": [
									0.002,
									0.02,
									0.032,
									0.085,
									0.072,
									0.006,
									0.186,
									0.029,
									0.134,
									0.15,
									0.013,
									0.0
								],
								"e": 0.054
							},
							{
								"h": [
									0.72,
									0.177,
									0.037,
									0.201,
									0.018,
									0.282,
									0.0,
									0.008,
									0.216,
									0.102,
									0.052,
									0.039
								],
								"e": 0.135
							},
							{
								"h": [
									0.211,
									0.417,
									0.011,
									0.204,
									0.049,
									0.077,
									0.203,
									0.179,
									0.004,
									0.224,
									0.211,
									0.162
								],
								"e": 0.155
							},
							{
								"h": [
									0.001,
									0.035,
									1.0,
									0.264,
									0.006,
									0.037,
									0.275,
									0.545,
									0.003,
									0.071,
									1.0,
									0.687
								],
								"e": 0.295
							},
							{
								"h": [
									0.018,
									0.089,
									0.173,
									0.037,
									0.001,
									0.049,
									1.0,
									0.009,
									0.513,
									0.224,
									0.171,
									0.01
								],
								"e": 0.123
							},
							{
								"h": [
									0.841,
									0.009,
									0.206,
									0.029,
									0.251,
									0.023,
									0.936,
									0.088,
									0.074,
									0.182,
									0.029,
									0.028
								],
								"e": 0.162
							},
							{
								"h": [
									0.684,
									0.486,
									0.134,
									0.117,
									0.042,
									0.038,
									1.0,
									1.0,
									0.01,
									0.015,
									0.279,
									1.0
								],
								"e": 0.381
							},
							{
								"h": [
									0.379,
									1.0,
									0.14,
									0.097,
									0.086,
									0.028,
									0.172,
									0.214,
									0.084,
									0.255,
									1.0,
									0.079
								],
								"e": 0.342
							}
						],
						[
							{
								"h": [
									0.079,
									0.009,
									0.912,
									0.013,
									0.047,
									0.098,
									0.014,
									0.063,
									0.2,
									0.647,
									0.502,
									0.511
								],
								"e": 0.195
							},
							{
								"h": [
									0.068,
									0.326,
									0.427,
									0.038,
									0.217,
									0.577,
									0.844,
									0.23,
									0.382,
									0.108,
									0.314,
									0.358
								],
								"e": 0.361
							},
							{
								"h": [
									0.073,
									0.05,
									0.0,
									0.015,
									0.011,
									0.04,
									0.095,
									0.42,
									0.048,
									0.001,
									0.139,
									0.023
								],
								"e": 0.08
							},
							{
								"h": [
									0.016,
									0.584,
									0.043,
									0.001,
									0.22,
									0.09,
									0.007,
									0.001,
									0.009,
									0.375,
									0.054,
									0.022
								],
								"e": 0.121
							},
							{
								"h": [
									0.031,
									0.202,
									0.303,
									0.068,
									0.139,
									0.061,
									0.338,
									0.038,
									0.275,
									0.548,
									0.09,
									0.0
								],
								"e": 0.174
							},
							{
								"h": [
									0.002,
									0.0,
									0.091,
									0.554,
									0.02,
									0.001,
									0.0,
									0.002,
									0.005,
									0.03,
									0.072,
									0.058
								],
								"e": 0.036
							},
							{
								"h": [
									0.35,
									0.101,
									0.014,
									0.356,
									0.04,
									0.057,
									0.115,
									0.13,
									0.002,
									0.0,
									0.108,
									0.032
								],
								"e": 0.082
							},
							{
								"h": [
									0.0,
									0.011,
									0.0,
									0.079,
									0.013,
									0.008,
									0.031,
									0.509,
									0.001,
									0.007,
									0.003,
									0.001
								],
								"e": 0.033
							},
							{
								"h": [
									0.008,
									0.001,
									0.011,
									0.033,
									0.001,
									0.006,
									0.035,
									0.002,
									0.2,
									0.223,
									0.022,
									0.002
								],
								"e": 0.025
							},
							{
								"h": [
									0.013,
									0.005,
									0.015,
									0.012,
									0.027,
									0.006,
									0.001,
									0.017,
									0.001,
									0.001,
									0.015,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.003,
									0.002,
									0.006,
									0.008,
									0.009,
									0.02,
									0.01,
									0.046,
									0.002,
									0.005,
									0.004,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.195,
									0.065,
									0.03,
									0.035,
									0.04,
									0.045,
									0.011,
									0.032,
									0.05,
									0.012,
									0.053,
									0.044
								],
								"e": 0.056
							}
						],
						[
							{
								"h": [
									0.525,
									0.0,
									0.116,
									0.007,
									0.032,
									0.006,
									1.0,
									0.021,
									0.008,
									0.519,
									0.267,
									0.256
								],
								"e": 0.2
							},
							{
								"h": [
									0.146,
									0.145,
									0.533,
									0.047,
									0.367,
									0.346,
									0.137,
									0.261,
									0.361,
									0.239,
									0.525,
									0.367
								],
								"e": 0.347
							},
							{
								"h": [
									0.11,
									0.003,
									0.004,
									0.067,
									0.047,
									0.018,
									0.055,
									0.608,
									0.027,
									0.045,
									0.357,
									0.049
								],
								"e": 0.115
							},
							{
								"h": [
									0.007,
									0.013,
									0.023,
									0.002,
									0.029,
									0.018,
									0.083,
									0.013,
									0.005,
									0.039,
									0.045,
									0.093
								],
								"e": 0.037
							},
							{
								"h": [
									0.003,
									0.009,
									0.025,
									0.122,
									0.107,
									0.019,
									0.159,
									0.038,
									0.043,
									0.091,
									0.008,
									0.0
								],
								"e": 0.049
							},
							{
								"h": [
									0.009,
									0.001,
									0.083,
									0.095,
									0.011,
									0.012,
									0.002,
									0.003,
									0.022,
									0.096,
									0.043,
									0.04
								],
								"e": 0.027
							},
							{
								"h": [
									0.187,
									0.065,
									0.018,
									0.116,
									0.005,
									0.049,
									0.021,
									0.143,
									0.047,
									0.002,
									0.056,
									0.005
								],
								"e": 0.049
							},
							{
								"h": [
									0.002,
									0.006,
									0.015,
									0.012,
									0.006,
									0.002,
									0.072,
									0.055,
									0.002,
									0.001,
									0.011,
									0.013
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.008,
									0.024,
									0.001,
									0.0,
									0.009,
									0.005,
									0.012,
									0.007,
									0.279,
									0.004,
									0.002
								],
								"e": 0.014
							},
							{
								"h": [
									0.014,
									0.0,
									0.007,
									0.024,
									0.002,
									0.004,
									0.024,
									0.003,
									0.029,
									0.003,
									0.003,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.128,
									0.008,
									0.029,
									0.01,
									0.001,
									0.011,
									0.045,
									0.03,
									0.007,
									0.004,
									0.032,
									0.005
								],
								"e": 0.022
							},
							{
								"h": [
									0.07,
									0.121,
									0.168,
									0.108,
									0.041,
									0.012,
									0.049,
									0.079,
									0.011,
									0.011,
									0.046,
									0.059
								],
								"e": 0.078
							}
						],
						[
							{
								"h": [
									0.041,
									0.793,
									0.19,
									0.028,
									0.144,
									0.675,
									0.005,
									0.08,
									0.705,
									0.648,
									0.875,
									0.645
								],
								"e": 0.424
							},
							{
								"h": [
									0.112,
									0.226,
									0.563,
									0.049,
									0.287,
									0.822,
									0.607,
									0.231,
									0.436,
									0.082,
									0.445,
									0.337
								],
								"e": 0.385
							},
							{
								"h": [
									0.081,
									0.032,
									0.0,
									0.006,
									0.024,
									0.024,
									0.33,
									0.319,
									0.028,
									0.005,
									0.194,
									0.035
								],
								"e": 0.105
							},
							{
								"h": [
									0.015,
									0.049,
									0.006,
									0.003,
									0.072,
									0.06,
									0.01,
									0.005,
									0.013,
									0.064,
									0.1,
									0.054
								],
								"e": 0.046
							},
							{
								"h": [
									0.018,
									0.144,
									0.198,
									0.02,
									0.115,
									0.042,
									0.16,
									0.308,
									0.45,
									0.049,
									0.042,
									0.0
								],
								"e": 0.136
							},
							{
								"h": [
									0.002,
									0.0,
									0.023,
									0.238,
									0.025,
									0.005,
									0.0,
									0.007,
									0.002,
									0.055,
									0.089,
									0.184
								],
								"e": 0.037
							},
							{
								"h": [
									0.057,
									0.044,
									0.049,
									0.293,
									0.104,
									0.072,
									0.277,
									0.103,
									0.003,
									0.001,
									0.028,
									0.026
								],
								"e": 0.08
							},
							{
								"h": [
									0.0,
									0.018,
									0.006,
									0.02,
									0.002,
									0.025,
									0.086,
									0.024,
									0.003,
									0.003,
									0.002,
									0.011
								],
								"e": 0.015
							},
							{
								"h": [
									0.008,
									0.018,
									0.017,
									0.017,
									0.003,
									0.011,
									0.037,
									0.019,
									0.126,
									0.553,
									0.036,
									0.004
								],
								"e": 0.034
							},
							{
								"h": [
									0.043,
									0.046,
									0.041,
									0.025,
									0.035,
									0.105,
									0.002,
									0.063,
									0.004,
									0.004,
									0.017,
									0.001
								],
								"e": 0.031
							},
							{
								"h": [
									0.006,
									0.01,
									0.018,
									0.032,
									0.058,
									0.069,
									0.031,
									0.082,
									0.027,
									0.036,
									0.007,
									0.008
								],
								"e": 0.037
							},
							{
								"h": [
									0.345,
									0.089,
									0.038,
									0.131,
									0.122,
									0.134,
									0.02,
									0.116,
									0.282,
									0.047,
									0.112,
									0.255
								],
								"e": 0.164
							}
						],
						[
							{
								"h": [
									0.637,
									0.0,
									0.131,
									0.021,
									0.032,
									0.01,
									0.137,
									0.111,
									0.012,
									0.434,
									0.232,
									0.233
								],
								"e": 0.132
							},
							{
								"h": [
									0.204,
									0.175,
									0.678,
									0.049,
									0.425,
									0.079,
									0.027,
									0.069,
									0.353,
									0.249,
									0.544,
									0.077
								],
								"e": 0.296
							},
							{
								"h": [
									0.373,
									0.001,
									0.019,
									0.135,
									0.074,
									0.021,
									0.07,
									0.633,
									0.044,
									0.087,
									0.272,
									0.045
								],
								"e": 0.165
							},
							{
								"h": [
									0.001,
									0.027,
									0.568,
									0.01,
									0.007,
									0.034,
									0.143,
									0.029,
									0.004,
									0.176,
									0.058,
									0.152
								],
								"e": 0.103
							},
							{
								"h": [
									0.017,
									0.093,
									0.019,
									0.182,
									0.03,
									0.043,
									0.243,
									0.029,
									0.028,
									0.165,
									0.008,
									0.0
								],
								"e": 0.064
							},
							{
								"h": [
									1.0,
									0.071,
									0.096,
									0.923,
									0.047,
									0.095,
									0.0,
									0.018,
									0.474,
									0.094,
									0.017,
									0.032
								],
								"e": 0.169
							},
							{
								"h": [
									0.303,
									0.28,
									0.006,
									0.216,
									0.014,
									0.152,
									0.2,
									0.208,
									0.011,
									0.023,
									0.067,
									0.879
								],
								"e": 0.159
							},
							{
								"h": [
									0.002,
									0.009,
									0.114,
									0.58,
									0.008,
									0.006,
									0.192,
									0.091,
									0.013,
									0.349,
									0.088,
									0.815
								],
								"e": 0.146
							},
							{
								"h": [
									0.022,
									0.04,
									0.003,
									0.007,
									0.001,
									0.018,
									0.039,
									0.012,
									0.092,
									0.117,
									0.117,
									0.002
								],
								"e": 0.028
							},
							{
								"h": [
									0.082,
									0.004,
									0.048,
									0.019,
									0.013,
									0.009,
									0.043,
									0.015,
									0.003,
									0.009,
									0.007,
									0.004
								],
								"e": 0.017
							},
							{
								"h": [
									0.105,
									0.039,
									0.022,
									0.012,
									0.008,
									0.006,
									0.344,
									0.226,
									0.002,
									0.003,
									0.15,
									0.027
								],
								"e": 0.061
							},
							{
								"h": [
									0.052,
									0.109,
									0.052,
									0.008,
									0.032,
									0.012,
									0.047,
									0.078,
									0.001,
									0.039,
									0.048,
									0.055
								],
								"e": 0.056
							}
						],
						[
							{
								"h": [
									0.339,
									0.0,
									0.206,
									0.139,
									0.095,
									0.021,
									0.058,
									0.1,
									0.011,
									0.483,
									0.22,
									0.225
								],
								"e": 0.146
							},
							{
								"h": [
									0.196,
									0.119,
									0.468,
									0.039,
									0.288,
									0.15,
									0.102,
									0.102,
									0.21,
									0.154,
									0.454,
									0.165
								],
								"e": 0.25
							},
							{
								"h": [
									0.094,
									0.001,
									0.014,
									0.02,
									0.016,
									0.054,
									0.039,
									0.444,
									0.084,
									0.075,
									0.28,
									0.05
								],
								"e": 0.106
							},
							{
								"h": [
									0.001,
									0.026,
									0.117,
									0.017,
									0.006,
									0.022,
									0.043,
									0.075,
									0.008,
									0.123,
									0.096,
									0.286
								],
								"e": 0.071
							},
							{
								"h": [
									0.037,
									0.031,
									0.007,
									0.255,
									0.047,
									0.03,
									0.203,
									0.045,
									0.018,
									0.153,
									0.003,
									0.0
								],
								"e": 0.063
							},
							{
								"h": [
									0.018,
									0.0,
									0.106,
									0.235,
									0.048,
									0.001,
									0.0,
									0.006,
									0.019,
									0.02,
									0.026,
									0.059
								],
								"e": 0.029
							},
							{
								"h": [
									0.403,
									0.444,
									0.004,
									0.26,
									0.011,
									0.099,
									0.147,
									0.182,
									0.024,
									0.0,
									0.069,
									0.703
								],
								"e": 0.152
							},
							{
								"h": [
									0.001,
									0.009,
									0.001,
									0.255,
									0.003,
									0.015,
									0.038,
									0.168,
									0.003,
									0.023,
									0.001,
									0.005
								],
								"e": 0.03
							},
							{
								"h": [
									0.003,
									0.002,
									0.008,
									0.013,
									0.003,
									0.036,
									0.298,
									0.031,
									0.158,
									0.112,
									0.061,
									0.003
								],
								"e": 0.038
							},
							{
								"h": [
									0.025,
									0.002,
									0.083,
									0.064,
									0.036,
									0.009,
									0.015,
									0.07,
									0.004,
									0.007,
									0.011,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									0.034,
									0.02,
									0.002,
									0.009,
									0.01,
									0.027,
									0.039,
									0.015,
									0.0,
									0.016,
									0.008,
									0.039
								],
								"e": 0.019
							},
							{
								"h": [
									0.207,
									0.098,
									0.01,
									0.041,
									0.007,
									0.006,
									0.005,
									0.028,
									0.004,
									0.036,
									0.095,
									0.092
								],
								"e": 0.054
							}
						],
						[
							{
								"h": [
									0.103,
									0.011,
									1.0,
									0.219,
									0.215,
									0.084,
									0.017,
									0.464,
									0.252,
									0.739,
									0.553,
									0.494
								],
								"e": 0.346
							},
							{
								"h": [
									0.208,
									0.932,
									0.628,
									0.054,
									0.307,
									0.526,
									0.696,
									0.206,
									0.356,
									0.088,
									0.488,
									0.42
								],
								"e": 0.492
							},
							{
								"h": [
									0.246,
									0.033,
									0.005,
									0.144,
									0.104,
									0.725,
									0.165,
									0.636,
									0.425,
									0.013,
									0.273,
									0.052
								],
								"e": 0.281
							},
							{
								"h": [
									0.009,
									1.0,
									0.531,
									0.029,
									0.091,
									0.066,
									0.037,
									0.015,
									0.111,
									0.777,
									0.14,
									0.083
								],
								"e": 0.232
							},
							{
								"h": [
									0.139,
									0.572,
									0.15,
									0.282,
									0.113,
									0.166,
									0.867,
									0.101,
									0.096,
									0.51,
									0.052,
									0.0
								],
								"e": 0.244
							},
							{
								"h": [
									0.003,
									0.0,
									0.302,
									0.968,
									0.061,
									0.001,
									0.0,
									0.002,
									0.03,
									0.023,
									0.045,
									0.064
								],
								"e": 0.063
							},
							{
								"h": [
									1.0,
									0.308,
									0.027,
									0.656,
									0.034,
									0.121,
									0.17,
									0.2,
									0.008,
									0.0,
									0.127,
									0.088
								],
								"e": 0.163
							},
							{
								"h": [
									0.001,
									0.006,
									0.0,
									0.182,
									0.016,
									0.004,
									0.037,
									0.441,
									0.004,
									0.015,
									0.002,
									0.001
								],
								"e": 0.037
							},
							{
								"h": [
									0.007,
									0.001,
									0.008,
									0.015,
									0.0,
									0.006,
									0.01,
									0.008,
									0.216,
									0.178,
									0.018,
									0.002
								],
								"e": 0.021
							},
							{
								"h": [
									0.008,
									0.004,
									0.015,
									0.009,
									0.005,
									0.006,
									0.001,
									0.012,
									0.001,
									0.0,
									0.021,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.001,
									0.002,
									0.003,
									0.001,
									0.014,
									0.004,
									0.011,
									0.001,
									0.004,
									0.002,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.109,
									0.063,
									0.019,
									0.018,
									0.046,
									0.024,
									0.009,
									0.024,
									0.013,
									0.008,
									0.012,
									0.024
								],
								"e": 0.034
							}
						],
						[
							{
								"h": [
									1.0,
									0.0,
									0.665,
									0.415,
									0.226,
									0.375,
									0.031,
									0.714,
									0.203,
									1.0,
									0.533,
									0.211
								],
								"e": 0.479
							},
							{
								"h": [
									1.0,
									0.762,
									0.69,
									0.068,
									0.456,
									0.618,
									0.534,
									0.345,
									0.515,
									0.321,
									0.728,
									1.0
								],
								"e": 0.782
							},
							{
								"h": [
									0.625,
									0.013,
									0.105,
									1.0,
									1.0,
									0.227,
									0.127,
									0.586,
									1.0,
									0.155,
									0.416,
									0.094
								],
								"e": 0.641
							},
							{
								"h": [
									0.019,
									0.121,
									1.0,
									0.04,
									0.038,
									0.104,
									0.277,
									0.071,
									0.06,
									0.24,
									0.166,
									0.169
								],
								"e": 0.209
							},
							{
								"h": [
									0.063,
									0.128,
									0.034,
									0.919,
									0.024,
									0.163,
									0.52,
									0.057,
									0.1,
									0.161,
									0.026,
									0.0
								],
								"e": 0.161
							},
							{
								"h": [
									0.003,
									0.0,
									0.42,
									0.405,
									0.183,
									0.008,
									0.0,
									0.009,
									0.058,
									0.07,
									0.05,
									0.016
								],
								"e": 0.071
							},
							{
								"h": [
									0.634,
									0.311,
									0.033,
									0.243,
									0.012,
									0.216,
									0.036,
									0.427,
									0.281,
									0.001,
									0.069,
									0.035
								],
								"e": 0.163
							},
							{
								"h": [
									0.008,
									0.009,
									0.003,
									0.094,
									0.014,
									0.008,
									0.139,
									0.021,
									0.021,
									0.003,
									0.007,
									0.004
								],
								"e": 0.025
							},
							{
								"h": [
									0.003,
									0.022,
									0.022,
									0.003,
									0.001,
									0.012,
									0.003,
									0.007,
									0.008,
									0.169,
									0.005,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.006,
									0.006,
									0.01,
									0.013,
									0.001,
									0.003,
									0.002,
									0.007,
									0.002,
									0.003,
									0.006,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.003,
									0.003,
									0.004,
									0.004,
									0.002,
									0.013,
									0.004,
									0.022,
									0.017,
									0.002,
									0.014,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.123,
									0.036,
									0.016,
									0.027,
									0.06,
									0.029,
									0.018,
									0.071,
									0.007,
									0.006,
									0.012,
									0.013
								],
								"e": 0.041
							}
						],
						[
							{
								"h": [
									0.632,
									0.0,
									0.166,
									0.312,
									0.103,
									0.021,
									0.719,
									0.327,
									0.014,
									0.589,
									0.306,
									0.296
								],
								"e": 0.31
							},
							{
								"h": [
									0.568,
									0.618,
									0.828,
									0.072,
									0.653,
									0.209,
									0.095,
									0.208,
									0.34,
									0.25,
									1.0,
									0.247
								],
								"e": 0.549
							},
							{
								"h": [
									0.522,
									0.003,
									1.0,
									0.396,
									0.672,
									0.894,
									0.187,
									1.0,
									0.5,
									1.0,
									1.0,
									0.141
								],
								"e": 1.0
							},
							{
								"h": [
									0.004,
									0.109,
									0.26,
									0.092,
									0.028,
									0.03,
									1.0,
									1.0,
									0.354,
									0.101,
									0.207,
									1.0
								],
								"e": 0.437
							},
							{
								"h": [
									0.059,
									0.062,
									0.018,
									1.0,
									0.092,
									0.33,
									0.597,
									0.147,
									0.025,
									0.14,
									0.006,
									1.0
								],
								"e": 0.451
							},
							{
								"h": [
									0.004,
									0.0,
									0.285,
									0.26,
									0.155,
									0.008,
									0.088,
									0.019,
									0.03,
									0.041,
									0.158,
									0.063
								],
								"e": 0.077
							},
							{
								"h": [
									0.443,
									0.068,
									0.02,
									0.102,
									0.007,
									0.228,
									0.026,
									0.433,
									1.0,
									0.001,
									0.061,
									0.034
								],
								"e": 0.224
							},
							{
								"h": [
									0.04,
									0.015,
									0.003,
									0.034,
									0.004,
									0.011,
									0.073,
									0.041,
									0.007,
									0.001,
									0.003,
									0.007
								],
								"e": 0.02
							},
							{
								"h": [
									0.0,
									0.004,
									0.013,
									0.001,
									0.001,
									0.02,
									0.002,
									0.054,
									0.02,
									0.211,
									0.005,
									0.003
								],
								"e": 0.016
							},
							{
								"h": [
									0.018,
									0.0,
									0.02,
									0.056,
									0.001,
									0.003,
									0.01,
									0.003,
									0.047,
									0.004,
									0.005,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.177,
									0.003,
									0.006,
									0.005,
									0.001,
									0.016,
									0.026,
									0.013,
									0.002,
									0.01,
									0.042,
									0.003
								],
								"e": 0.021
							},
							{
								"h": [
									0.037,
									0.072,
									0.076,
									0.107,
									0.029,
									0.008,
									0.053,
									0.019,
									0.009,
									0.042,
									0.027,
									0.084
								],
								"e": 0.058
							}
						],
						[
							{
								"h": [
									0.057,
									1.0,
									0.218,
									1.0,
									1.0,
									0.616,
									0.006,
									1.0,
									1.0,
									0.78,
									1.0,
									0.656
								],
								"e": 1.0
							},
							{
								"h": [
									0.563,
									1.0,
									1.0,
									0.086,
									0.483,
									1.0,
									0.529,
									0.233,
									0.423,
									0.07,
									0.859,
									0.457
								],
								"e": 0.682
							},
							{
								"h": [
									0.495,
									0.022,
									0.2,
									0.122,
									0.512,
									1.0,
									1.0,
									0.642,
									0.574,
									0.172,
									0.553,
									0.11
								],
								"e": 0.615
							},
							{
								"h": [
									0.012,
									0.534,
									0.415,
									0.449,
									0.104,
									0.096,
									0.247,
									0.578,
									1.0,
									0.478,
									0.418,
									0.798
								],
								"e": 0.53
							},
							{
								"h": [
									0.244,
									0.955,
									0.206,
									0.102,
									0.152,
									0.263,
									0.465,
									1.0,
									0.303,
									0.116,
									0.038,
									0.0
								],
								"e": 0.364
							},
							{
								"h": [
									0.009,
									0.006,
									0.087,
									0.5,
									0.139,
									0.092,
									0.011,
									0.024,
									0.028,
									0.054,
									0.111,
									0.191
								],
								"e": 0.076
							},
							{
								"h": [
									0.13,
									0.128,
									0.06,
									0.406,
									0.066,
									0.276,
									0.445,
									0.225,
									0.016,
									0.018,
									0.102,
									0.088
								],
								"e": 0.143
							},
							{
								"h": [
									0.002,
									0.024,
									0.022,
									0.064,
									0.005,
									0.021,
									0.101,
									0.037,
									0.007,
									0.005,
									0.009,
									0.014
								],
								"e": 0.023
							},
							{
								"h": [
									0.015,
									0.025,
									0.031,
									0.018,
									0.007,
									0.025,
									0.034,
									0.078,
									0.147,
									0.602,
									0.053,
									0.007
								],
								"e": 0.048
							},
							{
								"h": [
									0.036,
									0.045,
									0.056,
									0.056,
									0.032,
									0.086,
									0.003,
									0.081,
									0.005,
									0.005,
									0.033,
									0.001
								],
								"e": 0.036
							},
							{
								"h": [
									0.007,
									0.01,
									0.017,
									0.037,
									0.054,
									0.11,
									0.027,
									0.083,
									0.017,
									0.068,
									0.007,
									0.005
								],
								"e": 0.043
							},
							{
								"h": [
									0.29,
									0.078,
									0.033,
									0.141,
									0.118,
									0.128,
									0.02,
									0.089,
									0.287,
									0.041,
									0.097,
									0.209
								],
								"e": 0.149
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Hero",
					"o": ":",
					"t": ":",
					"r": [
						{
							"p": " Hero",
							"b": 0.0,
							"k": {
								" Hero": 0.901,
								" hero": 0.052,
								" Maker": 0.004,
								" heroes": 0.003,
								" Soldier": 0.003,
								" Avatar": 0.003,
								"board": 0.002,
								" soldier": 0.002,
								" leader": 0.001,
								" Player": 0.001
							}
						},
						{
							"p": " Hero",
							"b": 0.0,
							"k": {
								" Hero": 0.526,
								" hero": 0.069,
								" Avatar": 0.025,
								" Squad": 0.015,
								" soldier": 0.014,
								" Soldier": 0.012,
								"ic": 0.01,
								"ine": 0.01,
								"board": 0.01,
								" Slayer": 0.009
							}
						},
						{
							"p": "ically",
							"b": 0.0,
							"k": {
								"ically": 0.195,
								"ic": 0.092,
								"ku": 0.067,
								" Hero": 0.054,
								"ine": 0.049,
								"iac": 0.045,
								"ji": 0.042,
								"ines": 0.033,
								"'s": 0.024,
								"board": 0.023
							}
						},
						{
							"p": "ically",
							"b": 0.0,
							"k": {
								"ically": 0.664,
								"ical": 0.048,
								"ic": 0.046,
								"ku": 0.02,
								"ics": 0.019,
								"iac": 0.015,
								"'s": 0.012,
								"ines": 0.012,
								"ine": 0.01,
								"ji": 0.009
							}
						},
						{
							"p": "ically",
							"b": 0.001,
							"k": {
								"ically": 0.719,
								"ical": 0.062,
								"ic": 0.047,
								"ics": 0.047,
								"'s": 0.014,
								"ines": 0.008,
								"ine": 0.007,
								"iac": 0.005,
								"ji": 0.003,
								"ie": 0.002
							}
						},
						{
							"p": "ically",
							"b": 0.107,
							"k": {
								"ically": 0.447,
								":": 0.107,
								"'s": 0.078,
								"ical": 0.076,
								"ics": 0.038,
								"ic": 0.037,
								"ized": 0.023,
								"ie": 0.019,
								"ine": 0.012,
								" =": 0.008
							}
						},
						{
							"p": ":",
							"b": 0.626,
							"k": {
								":": 0.626,
								"'s": 0.129,
								"ically": 0.126,
								"ie": 0.023,
								"ic": 0.013,
								"ical": 0.01,
								" vs": 0.005,
								"ine": 0.004,
								"ics": 0.004,
								".": 0.003
							}
						},
						{
							"p": ":",
							"b": 0.985,
							"k": {
								":": 0.985,
								"'s": 0.009,
								"ically": 0.002,
								"ine": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": ":",
							"b": 0.997,
							"k": {
								":": 0.997,
								"'s": 0.002,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": ":",
							"b": 1.0,
							"k": {
								":": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ":",
							"b": 1.0,
							"k": {
								":": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ":",
							"b": 0.993,
							"k": {
								":": 0.993,
								",": 0.001,
								".": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.556,
									0.009,
									0.436,
									0.001,
									0.071,
									1.0,
									1.0,
									0.027,
									0.351,
									0.125,
									0.567,
									0.175
								],
								"e": 0.403
							},
							{
								"h": [
									0.006,
									0.009,
									0.219,
									1.0,
									1.0,
									0.163,
									1.0,
									0.635,
									1.0,
									1.0,
									0.017,
									0.028
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.006,
									0.038,
									0.357,
									0.016,
									0.154,
									0.605,
									0.139,
									0.052,
									0.143,
									1.0
								],
								"e": 0.53
							},
							{
								"h": [
									0.577,
									0.495,
									0.043,
									0.332,
									1.0,
									1.0,
									0.274,
									0.2,
									0.085,
									1.0,
									1.0,
									1.0
								],
								"e": 0.839
							},
							{
								"h": [
									1.0,
									0.412,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.28,
									0.677,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.063,
									0.232,
									0.875,
									1.0,
									1.0,
									0.066,
									1.0,
									1.0,
									0.529,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.646,
									1.0,
									1.0,
									1.0,
									1.0,
									0.117,
									0.392,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.742,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.435,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.068,
									0.0,
									0.217,
									0.0,
									0.001,
									0.0,
									0.211,
									0.006,
									0.353,
									0.246,
									0.194,
									0.141
								],
								"e": 0.088
							},
							{
								"h": [
									0.002,
									0.006,
									0.124,
									0.013,
									0.121,
									0.076,
									0.381,
									0.2,
									0.724,
									0.337,
									0.11,
									0.009
								],
								"e": 0.227
							},
							{
								"h": [
									0.019,
									0.023,
									0.0,
									0.0,
									0.002,
									0.0,
									0.017,
									0.065,
									0.003,
									0.0,
									0.048,
									0.006
								],
								"e": 0.014
							},
							{
								"h": [
									0.009,
									0.005,
									0.0,
									0.0,
									0.05,
									0.108,
									0.0,
									0.0,
									0.0,
									0.002,
									0.008,
									0.001
								],
								"e": 0.022
							},
							{
								"h": [
									0.0,
									0.001,
									0.156,
									0.001,
									0.086,
									0.0,
									0.022,
									0.001,
									0.216,
									0.044,
									0.084,
									0.0
								],
								"e": 0.049
							},
							{
								"h": [
									0.003,
									0.357,
									0.025,
									0.097,
									0.0,
									0.305,
									0.002,
									0.0,
									0.204,
									0.093,
									0.218,
									0.044
								],
								"e": 0.154
							},
							{
								"h": [
									0.008,
									0.034,
									0.034,
									0.04,
									0.013,
									0.007,
									0.052,
									0.08,
									0.0,
									0.577,
									0.014,
									0.0
								],
								"e": 0.07
							},
							{
								"h": [
									0.0,
									0.007,
									0.405,
									0.014,
									0.002,
									0.009,
									0.008,
									0.026,
									0.002,
									0.001,
									0.457,
									0.015
								],
								"e": 0.051
							},
							{
								"h": [
									0.008,
									0.348,
									0.027,
									0.008,
									0.001,
									0.003,
									0.141,
									0.0,
									0.025,
									0.016,
									0.011,
									0.007
								],
								"e": 0.034
							},
							{
								"h": [
									0.063,
									0.037,
									0.028,
									0.005,
									0.048,
									0.003,
									0.031,
									0.083,
									0.005,
									0.052,
									0.002,
									0.361
								],
								"e": 0.048
							},
							{
								"h": [
									0.01,
									0.034,
									0.071,
									0.108,
									0.032,
									0.002,
									0.018,
									0.039,
									0.433,
									0.0,
									0.006,
									0.033
								],
								"e": 0.05
							},
							{
								"h": [
									0.027,
									0.012,
									0.032,
									0.017,
									0.094,
									0.024,
									0.015,
									0.032,
									0.0,
									0.043,
									0.065,
									0.023
								],
								"e": 0.034
							}
						],
						[
							{
								"h": [
									0.141,
									0.001,
									0.644,
									0.0,
									0.004,
									0.0,
									0.313,
									0.002,
									0.489,
									0.449,
									0.237,
									0.562
								],
								"e": 0.179
							},
							{
								"h": [
									0.004,
									0.004,
									0.147,
									0.018,
									0.143,
									0.076,
									0.36,
									0.429,
									0.249,
									0.407,
									0.176,
									0.002
								],
								"e": 0.228
							},
							{
								"h": [
									0.033,
									0.008,
									0.0,
									0.001,
									0.0,
									0.0,
									0.006,
									0.125,
									0.0,
									0.001,
									0.077,
									0.009
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.0,
									0.166,
									0.015,
									0.001,
									0.0,
									0.0,
									0.0,
									0.009,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.0,
									0.0,
									0.011,
									0.001,
									0.01,
									0.0,
									0.005,
									0.007,
									0.039,
									0.002,
									0.004,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.008,
									0.0,
									0.025,
									0.0,
									0.0,
									0.002,
									0.06,
									0.024,
									0.015
								],
								"e": 0.013
							},
							{
								"h": [
									0.003,
									0.001,
									0.02,
									0.013,
									0.004,
									0.003,
									0.011,
									0.018,
									0.0,
									0.028,
									0.005,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.012,
									0.01,
									0.004,
									0.001,
									0.002,
									0.004,
									0.007,
									0.001,
									0.0,
									0.005,
									0.004
								],
								"e": 0.004
							},
							{
								"h": [
									0.002,
									0.014,
									0.003,
									0.003,
									0.0,
									0.003,
									0.007,
									0.0,
									0.004,
									0.006,
									0.002,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.011,
									0.001,
									0.01,
									0.002,
									0.009,
									0.005,
									0.005,
									0.014,
									0.011,
									0.003,
									0.0,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.004,
									0.007,
									0.007,
									0.01,
									0.004,
									0.001,
									0.004,
									0.001,
									0.007,
									0.0,
									0.003,
									0.008
								],
								"e": 0.004
							},
							{
								"h": [
									0.051,
									0.023,
									0.011,
									0.009,
									0.006,
									0.003,
									0.006,
									0.005,
									0.001,
									0.005,
									0.001,
									0.005
								],
								"e": 0.01
							}
						],
						[
							{
								"h": [
									0.144,
									0.0,
									0.927,
									0.0,
									0.004,
									0.0,
									0.506,
									0.004,
									0.271,
									0.367,
									0.131,
									0.705
								],
								"e": 0.195
							},
							{
								"h": [
									0.004,
									0.002,
									0.203,
									0.021,
									0.146,
									0.197,
									0.301,
									0.414,
									0.354,
									0.549,
									0.216,
									0.001
								],
								"e": 0.276
							},
							{
								"h": [
									0.008,
									0.005,
									0.0,
									0.0,
									0.0,
									0.0,
									0.005,
									0.182,
									0.0,
									0.001,
									0.104,
									0.011
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.0,
									0.212,
									0.021,
									0.0,
									0.0,
									0.0,
									0.0,
									0.011,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.001,
									0.004,
									0.0,
									0.008,
									0.001,
									0.02,
									0.002,
									0.003,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.003,
									0.0,
									0.001,
									0.0,
									0.0,
									0.001,
									0.015,
									0.01,
									0.033
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.0,
									0.017,
									0.005,
									0.002,
									0.005,
									0.001,
									0.015,
									0.0,
									0.003,
									0.002,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.003,
									0.004,
									0.002,
									0.0,
									0.001,
									0.003,
									0.001,
									0.0,
									0.0,
									0.002,
									0.005
								],
								"e": 0.002
							},
							{
								"h": [
									0.003,
									0.006,
									0.002,
									0.0,
									0.0,
									0.002,
									0.011,
									0.0,
									0.007,
									0.007,
									0.002,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.012,
									0.002,
									0.009,
									0.002,
									0.003,
									0.005,
									0.01,
									0.009,
									0.009,
									0.006,
									0.0,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.009,
									0.016,
									0.007,
									0.01,
									0.006,
									0.0,
									0.008,
									0.001,
									0.002,
									0.0,
									0.01,
									0.024
								],
								"e": 0.008
							},
							{
								"h": [
									0.044,
									0.018,
									0.018,
									0.013,
									0.014,
									0.009,
									0.019,
									0.016,
									0.0,
									0.018,
									0.003,
									0.003
								],
								"e": 0.015
							}
						],
						[
							{
								"h": [
									0.112,
									0.0,
									0.122,
									0.0,
									0.001,
									0.0,
									0.088,
									0.012,
									0.203,
									0.196,
									0.162,
									0.23
								],
								"e": 0.073
							},
							{
								"h": [
									0.005,
									0.016,
									0.22,
									0.021,
									0.132,
									0.137,
									0.177,
									0.281,
									0.682,
									0.337,
									0.171,
									0.018
								],
								"e": 0.247
							},
							{
								"h": [
									0.016,
									0.051,
									0.0,
									0.0,
									0.002,
									0.0,
									0.084,
									0.104,
									0.002,
									0.0,
									0.052,
									0.008
								],
								"e": 0.029
							},
							{
								"h": [
									0.021,
									0.004,
									0.0,
									0.0,
									0.057,
									0.068,
									0.0,
									0.0,
									0.0,
									0.001,
									0.012,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.0,
									0.004,
									0.143,
									0.003,
									0.067,
									0.001,
									0.018,
									0.006,
									0.213,
									0.005,
									0.077,
									0.0
								],
								"e": 0.042
							},
							{
								"h": [
									0.0,
									0.0,
									0.005,
									0.054,
									0.003,
									0.0,
									0.012,
									0.0,
									0.0,
									0.051,
									0.168,
									0.017
								],
								"e": 0.028
							},
							{
								"h": [
									0.015,
									0.0,
									0.058,
									0.03,
									0.016,
									0.016,
									0.012,
									0.047,
									0.002,
									0.004,
									0.006,
									0.002
								],
								"e": 0.019
							},
							{
								"h": [
									0.004,
									0.007,
									0.015,
									0.009,
									0.001,
									0.045,
									0.005,
									0.003,
									0.004,
									0.001,
									0.012,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.01,
									0.018,
									0.008,
									0.004,
									0.004,
									0.028,
									0.043,
									0.008,
									0.044,
									0.031,
									0.01,
									0.01
								],
								"e": 0.017
							},
							{
								"h": [
									0.018,
									0.014,
									0.022,
									0.053,
									0.017,
									0.007,
									0.003,
									0.072,
									0.002,
									0.004,
									0.006,
									0.005
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.005,
									0.015,
									0.021,
									0.053,
									0.006,
									0.005,
									0.031,
									0.078,
									0.001,
									0.001,
									0.021
								],
								"e": 0.017
							},
							{
								"h": [
									0.025,
									0.004,
									0.008,
									0.048,
									0.049,
									0.009,
									0.005,
									0.018,
									0.0,
									0.008,
									0.086,
									0.073
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.107,
									0.004,
									0.112,
									0.0,
									0.004,
									0.0,
									0.302,
									0.002,
									0.145,
									0.223,
									0.257,
									0.096
								],
								"e": 0.078
							},
							{
								"h": [
									0.008,
									0.006,
									0.248,
									0.024,
									0.166,
									0.202,
									0.129,
									0.189,
									0.202,
									0.145,
									0.237,
									0.003
								],
								"e": 0.184
							},
							{
								"h": [
									0.043,
									0.015,
									0.0,
									0.001,
									0.0,
									0.0,
									0.01,
									0.131,
									0.001,
									0.001,
									0.073,
									0.01
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.035,
									0.034,
									0.001,
									0.0,
									0.0,
									0.001,
									0.012,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.0,
									0.005,
									0.002,
									0.001,
									0.0,
									0.011,
									0.059,
									0.027,
									0.001,
									0.004,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.01,
									0.001,
									0.001,
									0.003,
									0.001,
									0.001,
									0.082,
									0.007,
									0.009
								],
								"e": 0.009
							},
							{
								"h": [
									0.006,
									0.001,
									0.006,
									0.009,
									0.002,
									0.008,
									0.028,
									0.023,
									0.0,
									0.002,
									0.012,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.015,
									0.007,
									0.005,
									0.003,
									0.001,
									0.003,
									0.015,
									0.002,
									0.001,
									0.007,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.007,
									0.001,
									0.003,
									0.001,
									0.014,
									0.042,
									0.0,
									0.003,
									0.007,
									0.002,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.041,
									0.001,
									0.028,
									0.003,
									0.027,
									0.005,
									0.017,
									0.007,
									0.003,
									0.004,
									0.0,
									0.001
								],
								"e": 0.011
							},
							{
								"h": [
									0.008,
									0.009,
									0.013,
									0.005,
									0.001,
									0.001,
									0.047,
									0.001,
									0.002,
									0.0,
									0.015,
									0.013
								],
								"e": 0.01
							},
							{
								"h": [
									0.046,
									0.004,
									0.013,
									0.005,
									0.004,
									0.002,
									0.013,
									0.002,
									0.001,
									0.009,
									0.0,
									0.005
								],
								"e": 0.008
							}
						],
						[
							{
								"h": [
									0.042,
									0.0,
									0.052,
									0.0,
									0.0,
									0.0,
									0.252,
									0.001,
									0.103,
									0.093,
									0.106,
									0.078
								],
								"e": 0.044
							},
							{
								"h": [
									0.006,
									0.003,
									0.191,
									0.019,
									0.136,
									0.046,
									0.26,
									0.281,
									0.248,
									0.154,
									0.204,
									0.001
								],
								"e": 0.17
							},
							{
								"h": [
									0.008,
									0.003,
									0.0,
									0.0,
									0.0,
									0.0,
									0.008,
									0.113,
									0.001,
									0.001,
									0.072,
									0.009
								],
								"e": 0.011
							},
							{
								"h": [
									0.017,
									0.0,
									0.0,
									0.0,
									0.023,
									0.017,
									0.0,
									0.0,
									0.0,
									0.001,
									0.007,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.0,
									0.005,
									0.002,
									0.004,
									0.0,
									0.008,
									0.004,
									0.025,
									0.001,
									0.015,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.0,
									0.001,
									0.005,
									0.001,
									0.0,
									0.002,
									0.0,
									0.0,
									0.048,
									0.013,
									0.023
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.005,
									0.003,
									0.006,
									0.001,
									0.004,
									0.028,
									0.015,
									0.0,
									0.0,
									0.023,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.006,
									0.002,
									0.002,
									0.001,
									0.001,
									0.003,
									0.003,
									0.001,
									0.0,
									0.002,
									0.005
								],
								"e": 0.002
							},
							{
								"h": [
									0.002,
									0.008,
									0.002,
									0.001,
									0.001,
									0.007,
									0.039,
									0.0,
									0.005,
									0.006,
									0.002,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.026,
									0.002,
									0.026,
									0.003,
									0.007,
									0.004,
									0.005,
									0.011,
									0.004,
									0.01,
									0.001,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.007,
									0.021,
									0.003,
									0.004,
									0.031,
									0.002,
									0.008,
									0.001,
									0.027,
									0.001,
									0.007,
									0.026
								],
								"e": 0.01
							},
							{
								"h": [
									0.093,
									0.009,
									0.004,
									0.017,
									0.009,
									0.007,
									0.008,
									0.003,
									0.004,
									0.022,
									0.002,
									0.003
								],
								"e": 0.013
							}
						],
						[
							{
								"h": [
									0.051,
									0.0,
									0.147,
									0.0,
									0.001,
									0.0,
									0.156,
									0.013,
									0.334,
									0.236,
									0.209,
									0.146
								],
								"e": 0.079
							},
							{
								"h": [
									0.013,
									0.03,
									0.272,
									0.027,
									0.147,
									0.119,
									0.279,
									0.277,
									0.473,
									0.267,
									0.216,
									0.016
								],
								"e": 0.248
							},
							{
								"h": [
									0.028,
									0.055,
									0.0,
									0.001,
									0.002,
									0.001,
									0.038,
									0.249,
									0.005,
									0.0,
									0.071,
									0.011
								],
								"e": 0.032
							},
							{
								"h": [
									0.001,
									0.009,
									0.0,
									0.0,
									0.076,
									0.049,
									0.0,
									0.0,
									0.0,
									0.006,
									0.007,
									0.001
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.004,
									0.172,
									0.001,
									0.079,
									0.0,
									0.034,
									0.004,
									0.363,
									0.052,
									0.036,
									0.0
								],
								"e": 0.054
							},
							{
								"h": [
									0.289,
									0.002,
									0.079,
									0.082,
									0.001,
									0.0,
									0.003,
									0.0,
									0.024,
									0.173,
									0.114,
									0.06
								],
								"e": 0.081
							},
							{
								"h": [
									0.006,
									0.016,
									0.014,
									0.144,
									0.012,
									0.005,
									0.086,
									0.07,
									0.001,
									0.002,
									0.07,
									0.001
								],
								"e": 0.032
							},
							{
								"h": [
									0.0,
									0.01,
									0.042,
									0.011,
									0.001,
									0.011,
									0.02,
									0.047,
									0.0,
									0.001,
									0.005,
									0.017
								],
								"e": 0.012
							},
							{
								"h": [
									0.006,
									0.29,
									0.039,
									0.004,
									0.001,
									0.003,
									0.207,
									0.0,
									0.018,
									0.043,
									0.018,
									0.001
								],
								"e": 0.037
							},
							{
								"h": [
									0.011,
									0.013,
									0.009,
									0.002,
									0.012,
									0.004,
									0.005,
									0.027,
									0.001,
									0.009,
									0.003,
									0.321
								],
								"e": 0.024
							},
							{
								"h": [
									0.001,
									0.005,
									0.013,
									0.025,
									0.018,
									0.004,
									0.004,
									0.01,
									0.159,
									0.0,
									0.001,
									0.009
								],
								"e": 0.015
							},
							{
								"h": [
									0.024,
									0.003,
									0.003,
									0.004,
									0.026,
									0.012,
									0.004,
									0.004,
									0.0,
									0.016,
									0.025,
									0.006
								],
								"e": 0.011
							}
						],
						[
							{
								"h": [
									0.481,
									0.0,
									1.0,
									0.0,
									0.027,
									0.0,
									0.677,
									0.013,
									0.609,
									1.0,
									0.223,
									1.0
								],
								"e": 0.329
							},
							{
								"h": [
									0.011,
									0.021,
									0.394,
									0.04,
									0.25,
									1.0,
									0.352,
									1.0,
									0.61,
									0.806,
									0.408,
									0.001
								],
								"e": 0.603
							},
							{
								"h": [
									0.067,
									0.019,
									0.0,
									0.005,
									0.003,
									0.002,
									0.033,
									0.378,
									0.002,
									0.009,
									0.238,
									0.025
								],
								"e": 0.044
							},
							{
								"h": [
									0.001,
									0.002,
									0.0,
									0.0,
									0.5,
									0.032,
									0.006,
									0.002,
									0.0,
									0.002,
									0.026,
									0.005
								],
								"e": 0.056
							},
							{
								"h": [
									0.0,
									0.0,
									0.014,
									0.009,
									0.01,
									0.001,
									0.031,
									0.046,
									0.055,
									0.006,
									0.006,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.0,
									0.009,
									0.01,
									0.003,
									0.01,
									0.002,
									0.0,
									0.002,
									0.165,
									0.022,
									0.038
								],
								"e": 0.023
							},
							{
								"h": [
									0.013,
									0.001,
									0.009,
									0.012,
									0.002,
									0.012,
									0.011,
									0.039,
									0.001,
									0.007,
									0.01,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.011,
									0.004,
									0.006,
									0.001,
									0.001,
									0.008,
									0.017,
									0.0,
									0.0,
									0.004,
									0.004
								],
								"e": 0.004
							},
							{
								"h": [
									0.002,
									0.008,
									0.003,
									0.001,
									0.001,
									0.007,
									0.004,
									0.001,
									0.01,
									0.021,
									0.007,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.019,
									0.0,
									0.016,
									0.004,
									0.001,
									0.008,
									0.002,
									0.015,
									0.026,
									0.001,
									0.001,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.01,
									0.009,
									0.002,
									0.005,
									0.008,
									0.001,
									0.005,
									0.001,
									0.001,
									0.0,
									0.004,
									0.009
								],
								"e": 0.005
							},
							{
								"h": [
									0.033,
									0.008,
									0.008,
									0.028,
									0.008,
									0.002,
									0.007,
									0.001,
									0.0,
									0.008,
									0.002,
									0.007
								],
								"e": 0.009
							}
						],
						[
							{
								"h": [
									0.119,
									0.0,
									0.105,
									0.0,
									0.001,
									0.0,
									0.078,
									0.028,
									0.209,
									0.205,
									0.157,
									0.27
								],
								"e": 0.078
							},
							{
								"h": [
									0.021,
									0.06,
									0.356,
									0.038,
									0.182,
									0.163,
									0.202,
									0.344,
									0.667,
									0.276,
									0.319,
									0.025
								],
								"e": 0.312
							},
							{
								"h": [
									0.044,
									0.058,
									0.0,
									0.002,
									0.008,
									0.002,
									0.174,
									0.206,
									0.008,
									0.002,
									0.11,
									0.019
								],
								"e": 0.052
							},
							{
								"h": [
									0.02,
									0.015,
									0.0,
									0.0,
									0.059,
									0.07,
									0.002,
									0.0,
									0.001,
									0.004,
									0.022,
									0.007
								],
								"e": 0.023
							},
							{
								"h": [
									0.001,
									0.021,
									0.194,
									0.006,
									0.063,
									0.002,
									0.045,
									0.012,
									0.164,
									0.008,
									0.055,
									0.0
								],
								"e": 0.044
							},
							{
								"h": [
									0.0,
									0.0,
									0.011,
									0.072,
									0.007,
									0.0,
									0.014,
									0.0,
									0.001,
									0.072,
									0.102,
									0.029
								],
								"e": 0.027
							},
							{
								"h": [
									0.02,
									0.0,
									0.029,
									0.037,
									0.02,
									0.025,
									0.05,
									0.054,
									0.02,
									0.003,
									0.017,
									0.003
								],
								"e": 0.024
							},
							{
								"h": [
									0.035,
									0.016,
									0.021,
									0.011,
									0.001,
									0.01,
									0.015,
									0.028,
									0.001,
									0.001,
									0.014,
									0.003
								],
								"e": 0.011
							},
							{
								"h": [
									0.016,
									0.025,
									0.008,
									0.003,
									0.007,
									0.018,
									0.038,
									0.054,
									0.031,
									0.037,
									0.029,
									0.007
								],
								"e": 0.021
							},
							{
								"h": [
									0.008,
									0.012,
									0.011,
									0.101,
									0.009,
									0.016,
									0.003,
									0.04,
									0.004,
									0.004,
									0.015,
									0.003
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.005,
									0.013,
									0.021,
									0.059,
									0.014,
									0.006,
									0.017,
									0.041,
									0.001,
									0.001,
									0.029
								],
								"e": 0.015
							},
							{
								"h": [
									0.021,
									0.002,
									0.005,
									0.036,
									0.026,
									0.009,
									0.004,
									0.012,
									0.0,
									0.008,
									0.04,
									0.023
								],
								"e": 0.016
							}
						],
						[
							{
								"h": [
									0.151,
									0.819,
									0.095,
									0.003,
									0.057,
									0.561,
									0.404,
									0.006,
									0.094,
									0.031,
									0.963,
									0.056
								],
								"e": 0.38
							},
							{
								"h": [
									0.021,
									0.016,
									0.516,
									0.041,
									0.269,
									0.024,
									0.251,
									0.085,
									0.197,
									0.065,
									0.452,
									0.514
								],
								"e": 0.442
							},
							{
								"h": [
									0.024,
									0.004,
									0.0,
									0.003,
									0.001,
									0.0,
									0.033,
									0.465,
									0.002,
									0.002,
									0.21,
									0.029
								],
								"e": 0.039
							},
							{
								"h": [
									1.0,
									0.003,
									0.0,
									0.0,
									0.008,
									0.026,
									0.005,
									0.0,
									0.0,
									0.008,
									0.043,
									0.008
								],
								"e": 0.141
							},
							{
								"h": [
									0.0,
									0.001,
									0.017,
									0.008,
									0.057,
									0.001,
									0.019,
									0.121,
									0.06,
									0.013,
									0.011,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.002,
									0.0,
									0.002,
									0.04,
									0.002,
									0.002,
									0.003,
									0.002,
									0.001,
									0.055,
									0.078,
									0.033
								],
								"e": 0.02
							},
							{
								"h": [
									0.005,
									0.01,
									0.009,
									0.014,
									0.011,
									0.016,
									0.165,
									0.046,
									0.001,
									0.011,
									0.039,
									0.003
								],
								"e": 0.026
							},
							{
								"h": [
									0.001,
									0.034,
									0.035,
									0.013,
									0.001,
									0.004,
									0.013,
									0.073,
									0.001,
									0.003,
									0.052,
									0.005
								],
								"e": 0.015
							},
							{
								"h": [
									0.004,
									0.007,
									0.008,
									0.001,
									0.003,
									0.007,
									0.068,
									0.004,
									0.012,
									0.02,
									0.015,
									0.002
								],
								"e": 0.011
							},
							{
								"h": [
									0.262,
									0.002,
									0.062,
									0.02,
									0.01,
									0.034,
									0.008,
									0.054,
									0.013,
									0.007,
									0.003,
									0.003
								],
								"e": 0.035
							},
							{
								"h": [
									0.046,
									0.021,
									0.014,
									0.006,
									0.014,
									0.005,
									0.029,
									0.003,
									0.027,
									0.002,
									0.011,
									0.088
								],
								"e": 0.021
							},
							{
								"h": [
									0.079,
									0.012,
									0.007,
									0.017,
									0.007,
									0.004,
									0.028,
									0.007,
									0.001,
									0.018,
									0.001,
									0.001
								],
								"e": 0.014
							}
						],
						[
							{
								"h": [
									0.053,
									0.0,
									0.14,
									0.0,
									0.002,
									0.0,
									0.153,
									0.037,
									0.361,
									0.255,
									0.206,
									0.162
								],
								"e": 0.086
							},
							{
								"h": [
									0.046,
									0.102,
									0.4,
									0.04,
									0.193,
									0.119,
									0.338,
									0.331,
									0.481,
									0.23,
									0.345,
									0.018
								],
								"e": 0.322
							},
							{
								"h": [
									0.079,
									0.064,
									0.0,
									0.009,
									0.011,
									0.008,
									0.077,
									0.371,
									0.026,
									0.001,
									0.141,
									0.022
								],
								"e": 0.057
							},
							{
								"h": [
									0.002,
									0.072,
									0.005,
									0.001,
									0.057,
									0.059,
									0.005,
									0.001,
									0.005,
									0.027,
									0.019,
									0.009
								],
								"e": 0.032
							},
							{
								"h": [
									0.002,
									0.037,
									0.33,
									0.006,
									0.159,
									0.007,
									0.134,
									0.008,
									1.0,
									0.249,
									0.042,
									0.0
								],
								"e": 0.142
							},
							{
								"h": [
									1.0,
									1.0,
									0.484,
									0.245,
									0.002,
									1.0,
									0.003,
									0.001,
									1.0,
									0.312,
									0.122,
									0.138
								],
								"e": 0.604
							},
							{
								"h": [
									0.024,
									0.166,
									0.009,
									0.41,
									0.025,
									0.014,
									0.078,
									0.144,
									0.006,
									1.0,
									0.067,
									0.003
								],
								"e": 0.149
							},
							{
								"h": [
									0.001,
									0.028,
									0.548,
									0.035,
									0.001,
									0.006,
									0.023,
									0.223,
									0.0,
									0.008,
									0.916,
									0.147
								],
								"e": 0.107
							},
							{
								"h": [
									0.011,
									0.729,
									0.008,
									0.004,
									0.002,
									0.004,
									0.208,
									0.003,
									0.043,
									0.034,
									0.033,
									0.004
								],
								"e": 0.058
							},
							{
								"h": [
									0.012,
									0.015,
									0.013,
									0.011,
									0.006,
									0.004,
									0.007,
									0.035,
									0.002,
									0.024,
									0.008,
									0.438
								],
								"e": 0.033
							},
							{
								"h": [
									0.002,
									0.005,
									0.011,
									0.022,
									0.016,
									0.007,
									0.005,
									0.005,
									0.125,
									0.001,
									0.001,
									0.009
								],
								"e": 0.013
							},
							{
								"h": [
									0.033,
									0.003,
									0.003,
									0.007,
									0.019,
									0.01,
									0.004,
									0.007,
									0.0,
									0.012,
									0.013,
									0.005
								],
								"e": 0.009
							}
						],
						[
							{
								"h": [
									0.062,
									0.001,
									0.327,
									0.0,
									0.034,
									0.0,
									0.342,
									0.078,
									0.207,
									0.531,
									0.354,
									0.111
								],
								"e": 0.137
							},
							{
								"h": [
									0.044,
									0.042,
									0.471,
									0.049,
									0.308,
									0.441,
									0.514,
									0.412,
									0.334,
									0.416,
									0.545,
									0.002
								],
								"e": 0.435
							},
							{
								"h": [
									0.133,
									0.012,
									0.001,
									0.024,
									0.021,
									0.007,
									0.038,
									0.467,
									0.005,
									0.04,
									0.341,
									0.04
								],
								"e": 0.067
							},
							{
								"h": [
									0.001,
									0.003,
									0.001,
									0.001,
									0.19,
									0.026,
									0.033,
									0.013,
									0.001,
									0.005,
									0.049,
									0.009
								],
								"e": 0.034
							},
							{
								"h": [
									0.0,
									0.002,
									0.011,
									0.02,
									0.01,
									0.001,
									0.049,
									0.079,
									0.027,
									0.014,
									0.017,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.004,
									0.001,
									0.018,
									0.034,
									0.003,
									0.122,
									0.003,
									0.0,
									0.023,
									0.077,
									0.016,
									0.029
								],
								"e": 0.035
							},
							{
								"h": [
									0.058,
									0.008,
									0.015,
									0.054,
									0.002,
									0.03,
									0.015,
									0.057,
									0.003,
									0.069,
									0.017,
									0.001
								],
								"e": 0.026
							},
							{
								"h": [
									0.002,
									0.036,
									0.011,
									0.016,
									0.001,
									0.001,
									0.004,
									0.104,
									0.002,
									0.001,
									0.031,
									0.003
								],
								"e": 0.013
							},
							{
								"h": [
									0.002,
									0.013,
									0.003,
									0.001,
									0.001,
									0.009,
									0.004,
									0.004,
									0.007,
									0.052,
									0.004,
									0.004
								],
								"e": 0.008
							},
							{
								"h": [
									0.014,
									0.0,
									0.008,
									0.026,
									0.001,
									0.007,
									0.005,
									0.017,
									0.012,
									0.002,
									0.002,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.006,
									0.003,
									0.003,
									0.005,
									0.002,
									0.002,
									0.004,
									0.001,
									0.004,
									0.0,
									0.002,
									0.01
								],
								"e": 0.003
							},
							{
								"h": [
									0.038,
									0.006,
									0.005,
									0.018,
									0.005,
									0.003,
									0.008,
									0.003,
									0.0,
									0.002,
									0.001,
									0.002
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.128,
									0.0,
									0.106,
									0.0,
									0.003,
									0.0,
									0.08,
									0.103,
									0.23,
									0.228,
									0.155,
									0.305
								],
								"e": 0.095
							},
							{
								"h": [
									0.073,
									0.191,
									0.49,
									0.054,
									0.245,
									0.173,
									0.237,
									0.379,
									0.627,
									0.211,
									0.484,
									0.032
								],
								"e": 0.409
							},
							{
								"h": [
									0.125,
									0.057,
									0.002,
									0.018,
									0.042,
									0.023,
									0.308,
									0.305,
									0.045,
									0.015,
									0.212,
									0.039
								],
								"e": 0.1
							},
							{
								"h": [
									0.018,
									0.088,
									0.013,
									0.007,
									0.066,
									0.099,
									0.015,
									0.009,
									0.016,
									0.018,
									0.048,
									0.039
								],
								"e": 0.054
							},
							{
								"h": [
									0.006,
									0.123,
									0.337,
									0.023,
									0.069,
									0.017,
									0.16,
									0.028,
									0.13,
									0.025,
									0.062,
									0.0
								],
								"e": 0.078
							},
							{
								"h": [
									0.0,
									0.0,
									0.107,
									0.199,
									0.017,
									0.0,
									0.036,
									0.0,
									0.002,
									0.087,
									0.094,
									0.044
								],
								"e": 0.047
							},
							{
								"h": [
									0.115,
									0.002,
									0.037,
									0.159,
									0.033,
									0.048,
									0.084,
									0.132,
									0.125,
									0.001,
									0.024,
									0.011
								],
								"e": 0.066
							},
							{
								"h": [
									0.06,
									0.033,
									0.004,
									0.021,
									0.001,
									0.02,
									0.013,
									0.04,
									0.007,
									0.005,
									0.003,
									0.001
								],
								"e": 0.016
							},
							{
								"h": [
									0.024,
									0.047,
									0.008,
									0.005,
									0.014,
									0.068,
									0.032,
									0.201,
									0.065,
									0.052,
									0.031,
									0.011
								],
								"e": 0.041
							},
							{
								"h": [
									0.015,
									0.012,
									0.017,
									0.235,
									0.007,
									0.019,
									0.002,
									0.058,
									0.003,
									0.003,
									0.03,
									0.006
								],
								"e": 0.026
							},
							{
								"h": [
									0.001,
									0.004,
									0.012,
									0.021,
									0.068,
									0.023,
									0.005,
									0.012,
									0.064,
									0.002,
									0.001,
									0.022
								],
								"e": 0.017
							},
							{
								"h": [
									0.018,
									0.002,
									0.003,
									0.023,
									0.024,
									0.008,
									0.003,
									0.011,
									0.0,
									0.013,
									0.042,
									0.006
								],
								"e": 0.013
							}
						],
						[
							{
								"h": [
									0.125,
									0.004,
									0.101,
									0.002,
									0.024,
									0.0,
									0.312,
									0.015,
									0.167,
									0.268,
									0.257,
									0.118
								],
								"e": 0.092
							},
							{
								"h": [
									0.094,
									0.055,
									0.597,
									0.052,
									0.324,
									0.358,
									0.132,
									0.163,
									0.252,
									0.157,
									0.556,
									0.004
								],
								"e": 0.348
							},
							{
								"h": [
									0.383,
									0.005,
									0.004,
									0.059,
									0.022,
									0.007,
									0.056,
									0.497,
									0.024,
									0.044,
									0.266,
									0.036
								],
								"e": 0.087
							},
							{
								"h": [
									0.0,
									0.007,
									0.003,
									0.004,
									0.044,
									0.034,
									0.041,
									0.054,
									0.002,
									0.015,
									0.073,
									0.035
								],
								"e": 0.034
							},
							{
								"h": [
									0.003,
									0.009,
									0.011,
									0.018,
									0.004,
									0.004,
									0.103,
									0.178,
									0.021,
									0.013,
									0.003,
									0.0
								],
								"e": 0.03
							},
							{
								"h": [
									0.0,
									0.0,
									0.015,
									0.086,
									0.007,
									0.002,
									0.016,
									0.005,
									0.002,
									0.141,
									0.007,
									0.023
								],
								"e": 0.025
							},
							{
								"h": [
									0.05,
									0.008,
									0.005,
									0.031,
									0.002,
									0.049,
									0.09,
									0.098,
									0.005,
									0.001,
									0.015,
									0.018
								],
								"e": 0.03
							},
							{
								"h": [
									0.008,
									0.013,
									0.003,
									0.015,
									0.004,
									0.001,
									0.008,
									0.138,
									0.006,
									0.007,
									0.007,
									0.005
								],
								"e": 0.013
							},
							{
								"h": [
									0.006,
									0.009,
									0.001,
									0.002,
									0.002,
									0.024,
									0.021,
									0.01,
									0.007,
									0.015,
									0.024,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.034,
									0.0,
									0.019,
									0.017,
									0.01,
									0.019,
									0.004,
									0.021,
									0.002,
									0.001,
									0.004,
									0.003
								],
								"e": 0.01
							},
							{
								"h": [
									0.008,
									0.004,
									0.008,
									0.003,
									0.001,
									0.005,
									0.022,
									0.002,
									0.005,
									0.002,
									0.019,
									0.007
								],
								"e": 0.007
							},
							{
								"h": [
									0.111,
									0.003,
									0.005,
									0.003,
									0.004,
									0.001,
									0.008,
									0.002,
									0.219,
									0.005,
									0.001,
									0.0
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.05,
									0.0,
									0.049,
									0.0,
									0.001,
									0.0,
									0.276,
									0.011,
									0.123,
									0.112,
									0.102,
									0.099
								],
								"e": 0.051
							},
							{
								"h": [
									0.091,
									0.026,
									0.435,
									0.041,
									0.246,
									0.069,
									0.278,
									0.234,
									0.244,
									0.122,
									0.482,
									0.001
								],
								"e": 0.27
							},
							{
								"h": [
									0.085,
									0.002,
									0.001,
									0.009,
									0.007,
									0.008,
									0.036,
									0.335,
									0.027,
									0.036,
									0.272,
									0.036
								],
								"e": 0.052
							},
							{
								"h": [
									0.013,
									0.006,
									0.004,
									0.008,
									0.021,
									0.02,
									0.015,
									0.079,
									0.004,
									0.015,
									0.062,
									0.026
								],
								"e": 0.031
							},
							{
								"h": [
									0.003,
									0.004,
									0.004,
									0.044,
									0.008,
									0.003,
									0.073,
									0.021,
									0.014,
									0.008,
									0.004,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.006,
									0.0,
									0.04,
									0.031,
									0.005,
									0.0,
									0.015,
									0.002,
									0.002,
									0.084,
									0.013,
									0.058
								],
								"e": 0.023
							},
							{
								"h": [
									0.015,
									0.14,
									0.005,
									0.043,
									0.002,
									0.03,
									0.144,
									0.078,
									0.008,
									0.0,
									0.027,
									0.01
								],
								"e": 0.038
							},
							{
								"h": [
									0.002,
									0.006,
									0.004,
									0.012,
									0.001,
									0.001,
									0.017,
									0.056,
									0.002,
									0.01,
									0.004,
									0.044
								],
								"e": 0.012
							},
							{
								"h": [
									0.005,
									0.011,
									0.003,
									0.001,
									0.005,
									0.012,
									0.091,
									0.017,
									0.034,
									0.009,
									0.029,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.029,
									0.003,
									0.04,
									0.032,
									0.003,
									0.016,
									0.002,
									0.06,
									0.003,
									0.008,
									0.006,
									0.012
								],
								"e": 0.015
							},
							{
								"h": [
									0.005,
									0.008,
									0.004,
									0.004,
									0.013,
									0.01,
									0.007,
									0.001,
									0.096,
									0.009,
									0.004,
									0.024
								],
								"e": 0.012
							},
							{
								"h": [
									0.107,
									0.003,
									0.001,
									0.025,
									0.007,
									0.002,
									0.005,
									0.003,
									0.062,
									0.013,
									0.006,
									0.001
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.058,
									0.0,
									0.14,
									0.0,
									0.008,
									0.0,
									0.163,
									0.209,
									0.409,
									0.291,
									0.207,
									0.173
								],
								"e": 0.119
							},
							{
								"h": [
									0.148,
									0.289,
									0.586,
									0.059,
									0.263,
									0.157,
									0.429,
									0.328,
									0.42,
									0.16,
									0.524,
									0.031
								],
								"e": 0.467
							},
							{
								"h": [
									0.27,
									0.045,
									0.014,
									0.089,
									0.105,
									0.14,
									0.134,
									0.539,
									0.228,
									0.019,
									0.284,
									0.05
								],
								"e": 0.166
							},
							{
								"h": [
									0.001,
									0.143,
									0.061,
									0.031,
									0.044,
									0.038,
									0.028,
									0.023,
									0.056,
									0.072,
									0.045,
									0.036
								],
								"e": 0.079
							},
							{
								"h": [
									0.008,
									0.137,
									0.164,
									0.033,
									0.052,
									0.016,
									0.28,
									0.02,
									0.163,
									0.198,
									0.016,
									0.0
								],
								"e": 0.088
							},
							{
								"h": [
									0.317,
									0.0,
									1.0,
									0.492,
									0.007,
									0.0,
									0.029,
									0.001,
									0.114,
									0.104,
									0.028,
									0.065
								],
								"e": 0.166
							},
							{
								"h": [
									0.068,
									0.655,
									0.015,
									1.0,
									0.017,
									0.047,
									0.084,
									0.178,
									0.046,
									0.002,
									0.058,
									0.007
								],
								"e": 0.151
							},
							{
								"h": [
									0.014,
									0.006,
									0.022,
									0.074,
									0.002,
									0.002,
									0.014,
									0.213,
									0.001,
									0.013,
									0.02,
									0.068
								],
								"e": 0.029
							},
							{
								"h": [
									0.014,
									0.085,
									0.006,
									0.002,
									0.002,
									0.007,
									0.051,
									0.019,
									0.034,
									0.032,
									0.034,
									0.002
								],
								"e": 0.02
							},
							{
								"h": [
									0.004,
									0.006,
									0.01,
									0.017,
									0.002,
									0.004,
									0.003,
									0.028,
									0.001,
									0.006,
									0.013,
									0.133
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.002,
									0.003,
									0.008,
									0.007,
									0.009,
									0.001,
									0.002,
									0.035,
									0.001,
									0.001,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.035,
									0.002,
									0.001,
									0.005,
									0.012,
									0.008,
									0.002,
									0.003,
									0.0,
									0.007,
									0.006,
									0.004
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.169,
									0.0,
									0.182,
									0.0,
									0.022,
									0.0,
									0.257,
									0.496,
									0.453,
									0.309,
									0.213,
									0.089
								],
								"e": 0.18
							},
							{
								"h": [
									1.0,
									0.376,
									0.597,
									0.074,
									0.339,
									0.126,
									0.202,
									0.531,
									0.616,
									0.502,
									0.78,
									0.001
								],
								"e": 0.839
							},
							{
								"h": [
									0.94,
									0.021,
									0.056,
									1.0,
									0.635,
									0.072,
									0.127,
									0.52,
									0.3,
									0.149,
									0.434,
									0.079
								],
								"e": 0.409
							},
							{
								"h": [
									0.003,
									0.041,
									0.238,
									0.016,
									0.095,
									0.157,
									0.083,
									0.075,
									0.004,
									0.065,
									0.119,
									0.1
								],
								"e": 0.123
							},
							{
								"h": [
									0.012,
									0.028,
									0.02,
									0.027,
									0.006,
									0.01,
									0.296,
									0.082,
									0.069,
									0.068,
									0.026,
									0.0
								],
								"e": 0.05
							},
							{
								"h": [
									0.001,
									0.0,
									0.062,
									0.054,
									0.024,
									0.003,
									0.006,
									0.0,
									0.015,
									0.064,
									0.041,
									0.02
								],
								"e": 0.025
							},
							{
								"h": [
									0.049,
									0.056,
									0.039,
									0.169,
									0.007,
									0.087,
									0.013,
									0.184,
									0.011,
									0.001,
									0.021,
									0.02
								],
								"e": 0.051
							},
							{
								"h": [
									0.027,
									0.014,
									0.001,
									0.068,
									0.005,
									0.002,
									0.013,
									0.389,
									0.014,
									0.006,
									0.023,
									0.0
								],
								"e": 0.032
							},
							{
								"h": [
									0.015,
									0.016,
									0.004,
									0.002,
									0.004,
									0.023,
									0.012,
									0.011,
									0.006,
									0.025,
									0.009,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.008,
									0.005,
									0.006,
									0.01,
									0.002,
									0.003,
									0.001,
									0.026,
									0.002,
									0.003,
									0.01,
									0.005
								],
								"e": 0.006
							},
							{
								"h": [
									0.001,
									0.001,
									0.002,
									0.002,
									0.011,
									0.006,
									0.001,
									0.002,
									0.009,
									0.001,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.006,
									0.0,
									0.0,
									0.014,
									0.007,
									0.004,
									0.002,
									0.003,
									0.0,
									0.002,
									0.006,
									0.001
								],
								"e": 0.004
							}
						],
						[
							{
								"h": [
									1.0,
									0.003,
									0.373,
									0.019,
									0.179,
									0.0,
									0.452,
									0.239,
									1.0,
									0.352,
									0.313,
									0.197
								],
								"e": 0.331
							},
							{
								"h": [
									0.174,
									0.258,
									0.725,
									0.078,
									0.514,
									0.536,
									0.769,
									0.338,
									0.205,
									0.12,
									1.0,
									0.006
								],
								"e": 0.633
							},
							{
								"h": [
									0.713,
									0.005,
									0.195,
									0.141,
									0.364,
									0.224,
									0.106,
									0.812,
									0.134,
									1.0,
									1.0,
									0.126
								],
								"e": 0.43
							},
							{
								"h": [
									0.002,
									0.019,
									0.026,
									0.069,
									0.133,
									0.061,
									0.6,
									0.426,
									0.014,
									0.024,
									0.166,
									0.196
								],
								"e": 0.189
							},
							{
								"h": [
									0.004,
									0.02,
									0.009,
									0.209,
									0.01,
									0.018,
									0.313,
									0.143,
									0.029,
									0.024,
									0.01,
									0.0
								],
								"e": 0.067
							},
							{
								"h": [
									0.001,
									0.0,
									0.102,
									0.085,
									0.022,
									0.0,
									0.066,
									0.002,
									0.001,
									0.077,
									0.023,
									0.038
								],
								"e": 0.036
							},
							{
								"h": [
									0.152,
									0.012,
									0.016,
									0.048,
									0.003,
									0.131,
									0.013,
									0.189,
									0.092,
									0.0,
									0.007,
									0.012
								],
								"e": 0.058
							},
							{
								"h": [
									0.029,
									0.013,
									0.0,
									0.04,
									0.003,
									0.005,
									0.006,
									0.016,
									0.007,
									0.002,
									0.0,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.001,
									0.011,
									0.036,
									0.001,
									0.039,
									0.017,
									0.066,
									0.01,
									0.004
								],
								"e": 0.015
							},
							{
								"h": [
									0.026,
									0.0,
									0.018,
									0.053,
									0.0,
									0.007,
									0.002,
									0.021,
									0.022,
									0.001,
									0.007,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.011,
									0.001,
									0.001,
									0.002,
									0.005,
									0.006,
									0.003,
									0.001,
									0.001,
									0.001,
									0.003,
									0.004
								],
								"e": 0.003
							},
							{
								"h": [
									0.031,
									0.003,
									0.003,
									0.031,
									0.004,
									0.002,
									0.004,
									0.0,
									0.0,
									0.003,
									0.001,
									0.002
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.153,
									0.0,
									0.109,
									0.0,
									0.018,
									0.0,
									0.088,
									1.0,
									0.274,
									0.271,
									0.155,
									0.353
								],
								"e": 0.24
							},
							{
								"h": [
									0.424,
									1.0,
									0.878,
									0.097,
									0.393,
									0.206,
									0.238,
									0.409,
									0.597,
									0.177,
									0.908,
									0.057
								],
								"e": 0.882
							},
							{
								"h": [
									0.787,
									0.039,
									1.0,
									0.391,
									1.0,
									1.0,
									1.0,
									0.612,
									1.0,
									0.627,
									0.63,
									0.125
								],
								"e": 1.0
							},
							{
								"h": [
									0.012,
									1.0,
									1.0,
									1.0,
									0.069,
									0.144,
									0.483,
									1.0,
									1.0,
									0.241,
									0.201,
									0.913
								],
								"e": 1.0
							},
							{
								"h": [
									0.112,
									1.0,
									0.422,
									0.13,
									0.076,
									0.137,
									0.537,
									0.109,
									0.116,
									0.066,
									0.044,
									1.0
								],
								"e": 0.453
							},
							{
								"h": [
									0.0,
									0.0,
									0.683,
									0.38,
									0.117,
									0.0,
									0.621,
									0.001,
									0.005,
									0.045,
									0.051,
									0.032
								],
								"e": 0.168
							},
							{
								"h": [
									0.352,
									0.011,
									0.046,
									0.218,
									0.018,
									0.173,
									0.116,
									0.22,
									1.0,
									0.003,
									0.036,
									0.063
								],
								"e": 0.222
							},
							{
								"h": [
									1.0,
									0.018,
									0.009,
									0.059,
									0.002,
									0.015,
									0.012,
									0.046,
									0.016,
									0.011,
									0.004,
									0.001
								],
								"e": 0.077
							},
							{
								"h": [
									0.023,
									0.017,
									0.012,
									0.007,
									0.028,
									0.158,
									0.03,
									1.0,
									0.074,
									0.056,
									0.049,
									0.011
								],
								"e": 0.101
							},
							{
								"h": [
									0.012,
									0.008,
									0.022,
									0.502,
									0.006,
									0.019,
									0.001,
									0.083,
									0.003,
									0.002,
									0.09,
									0.005
								],
								"e": 0.046
							},
							{
								"h": [
									0.001,
									0.002,
									0.009,
									0.019,
									0.086,
									0.052,
									0.004,
									0.012,
									0.036,
									0.005,
									0.001,
									0.016
								],
								"e": 0.018
							},
							{
								"h": [
									0.018,
									0.002,
									0.003,
									0.034,
									0.017,
									0.007,
									0.003,
									0.009,
									0.0,
									0.011,
									0.041,
									0.008
								],
								"e": 0.013
							}
						],
						[
							{
								"h": [
									0.187,
									1.0,
									0.098,
									1.0,
									1.0,
									0.456,
									0.483,
									0.137,
									0.115,
									0.037,
									1.0,
									0.063
								],
								"e": 1.0
							},
							{
								"h": [
									0.282,
									0.14,
									1.0,
									0.078,
									0.537,
									0.037,
									0.194,
									0.072,
									0.187,
									0.058,
									0.96,
									1.0
								],
								"e": 0.906
							},
							{
								"h": [
									0.397,
									0.002,
									0.134,
									0.399,
									0.147,
									0.047,
									0.166,
									1.0,
									0.246,
									0.473,
									0.992,
									0.16
								],
								"e": 0.348
							},
							{
								"h": [
									0.397,
									0.14,
									0.355,
									0.05,
									0.007,
									0.044,
									1.0,
									0.728,
									0.051,
									0.416,
									0.381,
									0.689
								],
								"e": 0.494
							},
							{
								"h": [
									0.053,
									0.132,
									0.039,
									0.285,
									0.065,
									0.083,
									0.265,
									1.0,
									0.032,
									0.182,
									0.011,
									0.0
								],
								"e": 0.204
							},
							{
								"h": [
									0.004,
									0.001,
									0.042,
									0.374,
									0.042,
									0.003,
									0.043,
									0.019,
									0.013,
									0.061,
									0.04,
									0.045
								],
								"e": 0.058
							},
							{
								"h": [
									0.069,
									0.104,
									0.014,
									0.056,
									0.005,
									0.082,
									0.531,
									0.102,
									0.016,
									0.005,
									0.046,
									0.022
								],
								"e": 0.08
							},
							{
								"h": [
									0.015,
									0.014,
									0.01,
									0.122,
									0.004,
									0.003,
									0.01,
									0.086,
									0.006,
									0.029,
									0.006,
									0.002
								],
								"e": 0.022
							},
							{
								"h": [
									0.007,
									0.006,
									0.005,
									0.002,
									0.024,
									0.056,
									0.039,
									0.123,
									0.021,
									0.032,
									0.06,
									0.002
								],
								"e": 0.028
							},
							{
								"h": [
									0.079,
									0.001,
									0.039,
									0.137,
									0.005,
									0.04,
									0.002,
									0.165,
									0.005,
									0.002,
									0.022,
									0.003
								],
								"e": 0.033
							},
							{
								"h": [
									0.014,
									0.008,
									0.006,
									0.004,
									0.008,
									0.043,
									0.01,
									0.002,
									0.017,
									0.043,
									0.004,
									0.035
								],
								"e": 0.016
							},
							{
								"h": [
									0.134,
									0.007,
									0.002,
									0.015,
									0.003,
									0.002,
									0.011,
									0.004,
									1.0,
									0.009,
									0.001,
									0.0
								],
								"e": 0.101
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ":",
					"o": " The",
					"t": " Luke",
					"r": [
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.051,
								" \"": 0.022,
								" http": 0.021,
								"19": 0.02,
								" '": 0.016,
								" ": 0.012,
								" A": 0.012,
								" -": 0.012,
								" I": 0.01,
								" An": 0.01
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.048,
								" Unknown": 0.022,
								" A": 0.02,
								"19": 0.02,
								" An": 0.015,
								" Three": 0.015,
								" http": 0.014,
								" -": 0.013,
								" Yes": 0.012,
								" \"": 0.01
							}
						},
						{
							"p": " Yes",
							"b": 0.0,
							"k": {
								" Yes": 0.036,
								" Unknown": 0.035,
								" Yeah": 0.028,
								" 1": 0.025,
								" An": 0.024,
								" No": 0.023,
								" The": 0.02,
								" I": 0.016,
								" 48": 0.016,
								" Oh": 0.014
							}
						},
						{
							"p": " Yes",
							"b": 0.0,
							"k": {
								" Yes": 0.069,
								" Yeah": 0.05,
								" Who": 0.037,
								" Unknown": 0.029,
								" Song": 0.028,
								" Oh": 0.027,
								" The": 0.024,
								" https": 0.019,
								" An": 0.018,
								" No": 0.018
							}
						},
						{
							"p": " Yes",
							"b": 0.0,
							"k": {
								" Yes": 0.121,
								" N": 0.034,
								" Unknown": 0.029,
								" Oh": 0.027,
								" Meow": 0.027,
								" Yeah": 0.026,
								" Battle": 0.023,
								" Who": 0.022,
								" ???": 0.02,
								" No": 0.019
							}
						},
						{
							"p": " ???",
							"b": 0.002,
							"k": {
								" ???": 0.049,
								" Bans": 0.038,
								" TBD": 0.031,
								" R": 0.026,
								" D": 0.024,
								" Meow": 0.02,
								" Night": 0.017,
								" N": 0.015,
								" Song": 0.015,
								" An": 0.013
							}
						},
						{
							"p": " Bob",
							"b": 0.036,
							"k": {
								" Bob": 0.098,
								" Harry": 0.038,
								" Dog": 0.036,
								" Luke": 0.036,
								" Man": 0.032,
								" Ben": 0.029,
								" Robin": 0.029,
								" D": 0.026,
								" Night": 0.022,
								" Van": 0.02
							}
						},
						{
							"p": " Man",
							"b": 0.008,
							"k": {
								" Man": 0.108,
								" Night": 0.089,
								" Ben": 0.079,
								" Bob": 0.075,
								" Duck": 0.026,
								" Harry": 0.024,
								" Mal": 0.021,
								" Kid": 0.02,
								" D": 0.019,
								" Guy": 0.018
							}
						},
						{
							"p": " Batman",
							"b": 0.004,
							"k": {
								" Batman": 0.251,
								" Harry": 0.105,
								" Kid": 0.072,
								" Night": 0.05,
								" King": 0.031,
								" Superman": 0.026,
								" Darth": 0.026,
								" Robin": 0.024,
								" Bob": 0.022,
								" Pol": 0.017
							}
						},
						{
							"p": " Batman",
							"b": 0.0,
							"k": {
								" Batman": 0.704,
								" Harry": 0.204,
								" Superman": 0.042,
								" Joker": 0.008,
								" Cyborg": 0.007,
								" Deadpool": 0.003,
								" Doctor": 0.002,
								" Robin": 0.002,
								" Spider": 0.002,
								" Darth": 0.002
							}
						},
						{
							"p": " Batman",
							"b": 0.003,
							"k": {
								" Batman": 0.472,
								" Superman": 0.216,
								" Harry": 0.042,
								" Deadpool": 0.027,
								" Spider": 0.025,
								" The": 0.019,
								" Joker": 0.017,
								" Captain": 0.011,
								" Robin": 0.011,
								" Dracula": 0.01
							}
						},
						{
							"p": " The",
							"b": 0.003,
							"k": {
								" The": 0.138,
								" Batman": 0.077,
								" Harry": 0.035,
								" Superman": 0.031,
								" the": 0.017,
								" Robin": 0.016,
								" Spider": 0.015,
								" Lord": 0.014,
								" Captain": 0.013,
								" Black": 0.012
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.945,
									0.002,
									0.422,
									0.001,
									0.122,
									0.023,
									0.867,
									0.034,
									0.032,
									0.739,
									0.503,
									0.393
								],
								"e": 0.27
							},
							{
								"h": [
									0.01,
									0.03,
									0.218,
									1.0,
									1.0,
									0.494,
									1.0,
									1.0,
									1.0,
									1.0,
									0.03,
									0.224
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.003,
									0.1,
									0.376,
									0.049,
									0.211,
									0.573,
									0.582,
									0.031,
									0.229,
									1.0
								],
								"e": 0.613
							},
							{
								"h": [
									1.0,
									0.245,
									0.035,
									0.968,
									1.0,
									1.0,
									0.058,
									0.019,
									0.151,
									1.0,
									1.0,
									0.132
								],
								"e": 0.641
							},
							{
								"h": [
									1.0,
									0.315,
									1.0,
									0.08,
									1.0,
									1.0,
									1.0,
									0.108,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.52,
									0.307,
									1.0,
									1.0,
									1.0,
									0.265,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.568,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.746,
									0.816,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.208,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.035,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.909,
									1.0,
									1.0,
									1.0,
									0.447
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.041,
									0.41,
									0.232,
									0.0,
									0.03,
									1.0,
									0.019,
									0.004,
									0.424,
									0.572,
									0.679,
									0.11
								],
								"e": 0.347
							},
							{
								"h": [
									0.001,
									0.004,
									0.109,
									0.011,
									0.121,
									0.653,
									0.317,
									0.159,
									0.489,
									0.205,
									0.106,
									0.331
								],
								"e": 0.24
							},
							{
								"h": [
									0.012,
									0.021,
									0.0,
									0.0,
									0.001,
									0.0,
									0.01,
									0.043,
									0.001,
									0.0,
									0.043,
									0.003
								],
								"e": 0.011
							},
							{
								"h": [
									0.29,
									0.001,
									0.0,
									0.0,
									0.156,
									0.069,
									0.0,
									0.0,
									0.0,
									0.002,
									0.014,
									0.0
								],
								"e": 0.047
							},
							{
								"h": [
									0.0,
									0.0,
									0.061,
									0.0,
									0.099,
									0.0,
									0.003,
									0.007,
									0.1,
									0.002,
									0.035,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.001,
									0.002,
									0.001,
									0.018,
									0.0,
									0.014,
									0.0,
									0.0,
									0.001,
									0.073,
									0.305,
									0.071
								],
								"e": 0.024
							},
							{
								"h": [
									0.002,
									0.001,
									0.049,
									0.011,
									0.075,
									0.003,
									0.022,
									0.002,
									0.0,
									0.039,
									0.006,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.015,
									0.0,
									0.001,
									0.0,
									0.019,
									0.008,
									0.001,
									0.0,
									0.0,
									0.001,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.025,
									0.001,
									0.021,
									0.002,
									0.0,
									0.0,
									0.002,
									0.0,
									0.005,
									0.002,
									0.001,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.005,
									0.004,
									0.001,
									0.002,
									0.019,
									0.003,
									0.002,
									0.005,
									0.015,
									0.0,
									0.0,
									0.007
								],
								"e": 0.005
							},
							{
								"h": [
									0.009,
									0.002,
									0.013,
									0.005,
									0.015,
									0.001,
									0.002,
									0.024,
									0.016,
									0.0,
									0.003,
									0.009
								],
								"e": 0.007
							},
							{
								"h": [
									0.307,
									0.096,
									0.026,
									0.044,
									0.091,
									0.029,
									0.009,
									0.014,
									0.843,
									0.009,
									0.079,
									0.041
								],
								"e": 0.055
							}
						],
						[
							{
								"h": [
									0.209,
									0.0,
									0.137,
									0.0,
									0.017,
									0.006,
									0.236,
									0.009,
									0.017,
									0.555,
									0.213,
									0.568
								],
								"e": 0.126
							},
							{
								"h": [
									0.006,
									0.008,
									0.144,
									0.015,
									0.138,
									0.017,
									0.107,
									0.162,
									0.231,
									0.349,
									0.175,
									0.054
								],
								"e": 0.146
							},
							{
								"h": [
									0.031,
									0.016,
									0.0,
									0.0,
									0.0,
									0.0,
									0.004,
									0.112,
									0.0,
									0.0,
									0.082,
									0.006
								],
								"e": 0.016
							},
							{
								"h": [
									0.01,
									0.0,
									0.0,
									0.0,
									0.018,
									0.024,
									0.0,
									0.0,
									0.0,
									0.002,
									0.007,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.006,
									0.0,
									0.041,
									0.0,
									0.002,
									0.003,
									0.042,
									0.001,
									0.011,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.023,
									0.197,
									0.001,
									0.003,
									0.0,
									0.313,
									0.0,
									0.0,
									0.052,
									0.083,
									0.04,
									0.024
								],
								"e": 0.071
							},
							{
								"h": [
									0.001,
									0.003,
									0.015,
									0.016,
									0.259,
									0.005,
									0.032,
									0.007,
									0.0,
									0.997,
									0.051,
									0.0
								],
								"e": 0.091
							},
							{
								"h": [
									0.0,
									0.09,
									0.006,
									0.003,
									0.0,
									0.021,
									0.127,
									0.018,
									0.008,
									0.0,
									0.007,
									0.116
								],
								"e": 0.021
							},
							{
								"h": [
									0.008,
									0.002,
									0.108,
									0.003,
									0.001,
									0.001,
									0.043,
									0.0,
									0.014,
									0.006,
									0.01,
									0.006
								],
								"e": 0.014
							},
							{
								"h": [
									0.07,
									0.009,
									0.005,
									0.002,
									0.01,
									0.01,
									0.003,
									0.013,
									0.175,
									0.001,
									0.0,
									0.028
								],
								"e": 0.016
							},
							{
								"h": [
									0.005,
									0.002,
									0.005,
									0.006,
									0.044,
									0.001,
									0.006,
									0.209,
									0.003,
									0.0,
									0.001,
									0.037
								],
								"e": 0.014
							},
							{
								"h": [
									0.687,
									0.088,
									0.025,
									0.048,
									0.06,
									0.006,
									0.007,
									0.007,
									0.282,
									0.008,
									0.223,
									0.023
								],
								"e": 0.054
							}
						],
						[
							{
								"h": [
									0.837,
									0.0,
									0.225,
									0.0,
									0.022,
									0.004,
									0.282,
									0.009,
									0.015,
									0.372,
									0.169,
									0.729
								],
								"e": 0.179
							},
							{
								"h": [
									0.009,
									0.006,
									0.191,
									0.015,
									0.143,
									0.043,
									0.081,
									0.169,
									0.243,
									0.276,
									0.206,
									0.041
								],
								"e": 0.143
							},
							{
								"h": [
									0.008,
									0.011,
									0.0,
									0.0,
									0.001,
									0.0,
									0.003,
									0.143,
									0.001,
									0.0,
									0.092,
									0.008
								],
								"e": 0.016
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.0,
									0.027,
									0.018,
									0.0,
									0.0,
									0.0,
									0.001,
									0.007,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.0,
									0.025,
									0.0,
									0.002,
									0.003,
									0.048,
									0.001,
									0.003,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.005,
									0.0,
									0.0,
									0.002,
									0.0,
									0.002,
									0.0,
									0.0,
									0.001,
									0.009,
									0.009,
									0.013
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.003,
									0.017,
									0.007,
									0.12,
									0.003,
									0.008,
									0.011,
									0.0,
									0.002,
									0.092,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.252,
									0.0,
									0.001,
									0.0,
									0.011,
									0.111,
									0.061,
									0.003,
									0.0,
									0.0,
									0.005
								],
								"e": 0.023
							},
							{
								"h": [
									0.007,
									0.0,
									0.065,
									0.001,
									0.0,
									0.0,
									0.15,
									0.0,
									0.017,
									0.007,
									0.005,
									0.007
								],
								"e": 0.018
							},
							{
								"h": [
									0.103,
									0.006,
									0.013,
									0.002,
									0.002,
									0.01,
									0.003,
									0.011,
									0.421,
									0.0,
									0.0,
									0.01
								],
								"e": 0.024
							},
							{
								"h": [
									0.054,
									0.003,
									0.008,
									0.023,
									0.158,
									0.0,
									0.016,
									0.176,
									0.002,
									0.0,
									0.003,
									0.139
								],
								"e": 0.03
							},
							{
								"h": [
									0.209,
									0.104,
									0.065,
									0.062,
									0.147,
									0.011,
									0.016,
									0.034,
									0.262,
									0.014,
									0.18,
									0.027
								],
								"e": 0.059
							}
						],
						[
							{
								"h": [
									0.072,
									0.003,
									0.721,
									0.0,
									0.008,
									0.006,
									0.013,
									0.009,
									0.214,
									0.57,
									0.364,
									0.377
								],
								"e": 0.152
							},
							{
								"h": [
									0.003,
									0.027,
									0.213,
									0.016,
									0.147,
									0.315,
									0.709,
									0.214,
									0.389,
									0.135,
									0.163,
									0.223
								],
								"e": 0.242
							},
							{
								"h": [
									0.015,
									0.091,
									0.0,
									0.0,
									0.001,
									0.0,
									0.079,
									0.079,
									0.001,
									0.0,
									0.051,
									0.005
								],
								"e": 0.034
							},
							{
								"h": [
									0.05,
									0.005,
									0.0,
									0.0,
									0.04,
									0.05,
									0.0,
									0.0,
									0.0,
									0.004,
									0.015,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.001,
									0.1,
									0.0,
									0.071,
									0.0,
									0.005,
									0.016,
									0.248,
									0.005,
									0.021,
									0.0
								],
								"e": 0.041
							},
							{
								"h": [
									0.002,
									0.0,
									0.003,
									0.019,
									0.002,
									0.002,
									0.0,
									0.001,
									0.001,
									0.054,
									0.236,
									0.018
								],
								"e": 0.016
							},
							{
								"h": [
									0.002,
									0.004,
									0.104,
									0.028,
									0.227,
									0.007,
									0.019,
									0.009,
									0.0,
									0.008,
									0.198,
									0.002
								],
								"e": 0.04
							},
							{
								"h": [
									0.0,
									0.152,
									0.0,
									0.002,
									0.001,
									0.078,
									0.036,
									0.119,
									0.002,
									0.001,
									0.0,
									0.004
								],
								"e": 0.019
							},
							{
								"h": [
									0.029,
									0.001,
									0.03,
									0.001,
									0.001,
									0.004,
									0.005,
									0.001,
									0.01,
									0.008,
									0.005,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.005,
									0.003,
									0.001,
									0.005,
									0.02,
									0.002,
									0.0,
									0.01,
									0.023,
									0.0,
									0.001,
									0.007
								],
								"e": 0.005
							},
							{
								"h": [
									0.026,
									0.001,
									0.004,
									0.009,
									0.047,
									0.004,
									0.002,
									0.038,
									0.01,
									0.002,
									0.002,
									0.005
								],
								"e": 0.009
							},
							{
								"h": [
									0.226,
									0.07,
									0.023,
									0.065,
									0.086,
									0.024,
									0.007,
									0.022,
									1.0,
									0.006,
									0.102,
									0.036
								],
								"e": 0.057
							}
						],
						[
							{
								"h": [
									0.348,
									0.0,
									0.065,
									0.0,
									0.011,
									0.005,
									0.359,
									0.01,
									0.009,
									0.39,
									0.188,
									0.139
								],
								"e": 0.094
							},
							{
								"h": [
									0.006,
									0.008,
									0.249,
									0.018,
									0.19,
									0.016,
									0.096,
									0.074,
									0.288,
									0.216,
									0.24,
									0.017
								],
								"e": 0.134
							},
							{
								"h": [
									0.019,
									0.018,
									0.0,
									0.001,
									0.0,
									0.0,
									0.009,
									0.112,
									0.0,
									0.0,
									0.077,
									0.007
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.0,
									0.011,
									0.029,
									0.001,
									0.0,
									0.0,
									0.001,
									0.004,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.001,
									0.002,
									0.0,
									0.004,
									0.008,
									0.036,
									0.001,
									0.003,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.004,
									0.001,
									0.009,
									0.0,
									0.002,
									0.003,
									0.181,
									0.046,
									0.006
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.002,
									0.009,
									0.011,
									0.02,
									0.007,
									0.034,
									0.011,
									0.0,
									0.004,
									0.016,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.029,
									0.0,
									0.001,
									0.001,
									0.027,
									0.024,
									0.001,
									0.013,
									0.001,
									0.0,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.009,
									0.0,
									0.012,
									0.001,
									0.001,
									0.001,
									0.004,
									0.0,
									0.011,
									0.003,
									0.003,
									0.006
								],
								"e": 0.004
							},
							{
								"h": [
									0.047,
									0.01,
									0.005,
									0.004,
									0.002,
									0.007,
									0.001,
									0.001,
									0.036,
									0.0,
									0.0,
									0.023
								],
								"e": 0.008
							},
							{
								"h": [
									0.004,
									0.002,
									0.002,
									0.007,
									0.019,
									0.0,
									0.006,
									0.015,
									0.001,
									0.0,
									0.001,
									0.029
								],
								"e": 0.005
							},
							{
								"h": [
									0.1,
									0.035,
									0.011,
									0.015,
									0.028,
									0.006,
									0.005,
									0.016,
									0.199,
									0.011,
									0.014,
									0.013
								],
								"e": 0.019
							}
						],
						[
							{
								"h": [
									0.264,
									0.0,
									0.172,
									0.0,
									0.005,
									0.004,
									0.084,
									0.001,
									0.015,
									0.364,
									0.124,
									0.145
								],
								"e": 0.071
							},
							{
								"h": [
									0.007,
									0.004,
									0.177,
									0.014,
									0.136,
									0.061,
									0.248,
									0.134,
									0.216,
									0.131,
									0.194,
									0.078
								],
								"e": 0.133
							},
							{
								"h": [
									0.007,
									0.005,
									0.0,
									0.0,
									0.001,
									0.0,
									0.004,
									0.097,
									0.001,
									0.0,
									0.068,
									0.007
								],
								"e": 0.011
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.0,
									0.012,
									0.019,
									0.0,
									0.0,
									0.0,
									0.001,
									0.005,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.001,
									0.03,
									0.0,
									0.002,
									0.007,
									0.029,
									0.001,
									0.005,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.003,
									0.0,
									0.0,
									0.0,
									0.001,
									0.0,
									0.031,
									0.045,
									0.004
								],
								"e": 0.004
							},
							{
								"h": [
									0.006,
									0.001,
									0.004,
									0.01,
									0.011,
									0.008,
									0.033,
									0.012,
									0.002,
									0.0,
									0.045,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.013,
									0.0,
									0.0,
									0.0,
									0.062,
									0.006,
									0.002,
									0.007,
									0.0,
									0.0,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.001,
									0.027,
									0.0,
									0.002,
									0.001,
									0.033,
									0.002,
									0.009,
									0.003,
									0.001,
									0.004
								],
								"e": 0.006
							},
							{
								"h": [
									0.042,
									0.0,
									0.019,
									0.009,
									0.001,
									0.004,
									0.0,
									0.001,
									0.043,
									0.0,
									0.002,
									0.018
								],
								"e": 0.008
							},
							{
								"h": [
									0.009,
									0.002,
									0.001,
									0.003,
									0.029,
									0.001,
									0.008,
									0.005,
									0.001,
									0.0,
									0.001,
									0.037
								],
								"e": 0.006
							},
							{
								"h": [
									0.355,
									0.044,
									0.005,
									0.023,
									0.031,
									0.008,
									0.002,
									0.016,
									0.271,
									0.007,
									0.02,
									0.018
								],
								"e": 0.027
							}
						],
						[
							{
								"h": [
									0.026,
									0.631,
									0.22,
									0.0,
									0.027,
									0.611,
									0.013,
									0.006,
									0.592,
									0.527,
									0.782,
									0.075
								],
								"e": 0.328
							},
							{
								"h": [
									0.002,
									0.015,
									0.258,
									0.02,
									0.167,
									0.958,
									0.288,
									0.174,
									0.363,
									0.148,
									0.206,
									0.496
								],
								"e": 0.294
							},
							{
								"h": [
									0.011,
									0.054,
									0.0,
									0.0,
									0.0,
									0.0,
									0.029,
									0.185,
									0.001,
									0.0,
									0.07,
									0.007
								],
								"e": 0.027
							},
							{
								"h": [
									0.701,
									0.0,
									0.0,
									0.0,
									0.348,
									0.021,
									0.0,
									0.0,
									0.0,
									0.002,
									0.018,
									0.0
								],
								"e": 0.089
							},
							{
								"h": [
									0.0,
									0.0,
									0.063,
									0.0,
									0.109,
									0.0,
									0.003,
									0.015,
									0.108,
									0.001,
									0.025,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.007,
									0.0,
									0.002,
									0.008,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.149,
									0.171,
									0.068
								],
								"e": 0.021
							},
							{
								"h": [
									0.001,
									0.0,
									0.031,
									0.007,
									0.035,
									0.005,
									0.075,
									0.001,
									0.0,
									0.0,
									0.022,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.001,
									0.0,
									0.02,
									0.004,
									0.002,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.021,
									0.001,
									0.008,
									0.001,
									0.0,
									0.0,
									0.003,
									0.0,
									0.002,
									0.005,
									0.001,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.002,
									0.0,
									0.004,
									0.004,
									0.005,
									0.0,
									0.008,
									0.002,
									0.0,
									0.0,
									0.005
								],
								"e": 0.003
							},
							{
								"h": [
									0.009,
									0.001,
									0.006,
									0.005,
									0.006,
									0.001,
									0.001,
									0.036,
									0.009,
									0.0,
									0.001,
									0.018
								],
								"e": 0.005
							},
							{
								"h": [
									0.291,
									0.191,
									0.012,
									0.03,
									0.071,
									0.02,
									0.006,
									0.018,
									0.961,
									0.006,
									0.085,
									0.041
								],
								"e": 0.056
							}
						],
						[
							{
								"h": [
									0.755,
									0.001,
									0.238,
									0.0,
									0.072,
									0.0,
									0.746,
									0.007,
									0.014,
									0.533,
									0.296,
									1.0
								],
								"e": 0.251
							},
							{
								"h": [
									0.023,
									0.039,
									0.401,
									0.03,
									0.254,
									0.075,
									0.251,
									0.432,
									0.401,
									0.391,
									0.394,
									0.102
								],
								"e": 0.283
							},
							{
								"h": [
									0.057,
									0.057,
									0.0,
									0.005,
									0.003,
									0.0,
									0.021,
									0.332,
									0.007,
									0.002,
									0.247,
									0.021
								],
								"e": 0.049
							},
							{
								"h": [
									0.013,
									0.001,
									0.0,
									0.001,
									0.102,
									0.036,
									0.004,
									0.0,
									0.0,
									0.023,
									0.026,
									0.002
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.0,
									0.005,
									0.003,
									0.072,
									0.0,
									0.009,
									0.029,
									0.035,
									0.004,
									0.018,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.17,
									0.017,
									0.002,
									0.004,
									0.0,
									0.106,
									0.0,
									0.0,
									0.056,
									0.074,
									0.05,
									0.038
								],
								"e": 0.043
							},
							{
								"h": [
									0.005,
									0.012,
									0.017,
									0.019,
									0.146,
									0.014,
									0.07,
									0.069,
									0.001,
									0.088,
									0.49,
									0.005
								],
								"e": 0.052
							},
							{
								"h": [
									0.0,
									0.04,
									0.001,
									0.003,
									0.0,
									0.048,
									0.35,
									0.321,
									0.017,
									0.002,
									0.001,
									0.497
								],
								"e": 0.057
							},
							{
								"h": [
									0.025,
									0.012,
									0.138,
									0.002,
									0.015,
									0.001,
									0.34,
									0.001,
									0.053,
									0.035,
									0.064,
									0.015
								],
								"e": 0.05
							},
							{
								"h": [
									0.066,
									0.039,
									0.014,
									0.01,
									0.008,
									0.008,
									0.002,
									0.029,
									0.51,
									0.0,
									0.001,
									0.023
								],
								"e": 0.031
							},
							{
								"h": [
									0.035,
									0.003,
									0.005,
									0.071,
									0.166,
									0.001,
									0.03,
									0.401,
									0.005,
									0.0,
									0.004,
									0.204
								],
								"e": 0.047
							},
							{
								"h": [
									0.379,
									0.274,
									0.064,
									0.162,
									0.178,
									0.021,
									0.033,
									0.029,
									0.116,
									0.05,
									0.362,
									0.092
								],
								"e": 0.102
							}
						],
						[
							{
								"h": [
									0.074,
									0.002,
									0.786,
									0.0,
									0.012,
									0.003,
									0.014,
									0.018,
									0.249,
									0.588,
									0.379,
									0.366
								],
								"e": 0.162
							},
							{
								"h": [
									0.011,
									0.082,
									0.356,
									0.028,
									0.209,
									0.376,
									0.806,
									0.227,
									0.391,
									0.124,
									0.301,
									0.287
								],
								"e": 0.307
							},
							{
								"h": [
									0.034,
									0.116,
									0.0,
									0.001,
									0.004,
									0.002,
									0.165,
									0.163,
									0.005,
									0.0,
									0.109,
									0.012
								],
								"e": 0.06
							},
							{
								"h": [
									0.076,
									0.016,
									0.0,
									0.0,
									0.087,
									0.061,
									0.001,
									0.0,
									0.001,
									0.013,
									0.036,
									0.002
								],
								"e": 0.028
							},
							{
								"h": [
									0.0,
									0.007,
									0.107,
									0.001,
									0.069,
									0.001,
									0.018,
									0.056,
									0.213,
									0.013,
									0.02,
									0.0
								],
								"e": 0.046
							},
							{
								"h": [
									0.012,
									0.0,
									0.01,
									0.019,
									0.004,
									0.001,
									0.0,
									0.002,
									0.003,
									0.081,
									0.208,
									0.033
								],
								"e": 0.019
							},
							{
								"h": [
									0.006,
									0.003,
									0.05,
									0.038,
									0.223,
									0.018,
									0.095,
									0.009,
									0.001,
									0.002,
									0.333,
									0.002
								],
								"e": 0.046
							},
							{
								"h": [
									0.0,
									0.018,
									0.0,
									0.004,
									0.001,
									0.027,
									0.043,
									0.143,
									0.002,
									0.001,
									0.0,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.022,
									0.001,
									0.014,
									0.001,
									0.003,
									0.001,
									0.002,
									0.001,
									0.007,
									0.011,
									0.029,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.005,
									0.002,
									0.009,
									0.008,
									0.003,
									0.0,
									0.009,
									0.015,
									0.0,
									0.004,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.031,
									0.001,
									0.003,
									0.021,
									0.043,
									0.005,
									0.002,
									0.024,
									0.006,
									0.005,
									0.002,
									0.003
								],
								"e": 0.01
							},
							{
								"h": [
									0.107,
									0.066,
									0.014,
									0.021,
									0.048,
									0.018,
									0.005,
									0.015,
									0.994,
									0.005,
									0.05,
									0.028
								],
								"e": 0.041
							}
						],
						[
							{
								"h": [
									0.522,
									0.0,
									0.103,
									0.0,
									0.027,
									0.005,
									0.622,
									0.032,
									0.007,
									0.319,
									0.225,
									0.104
								],
								"e": 0.129
							},
							{
								"h": [
									0.068,
									0.023,
									0.503,
									0.032,
									0.26,
									0.077,
									0.305,
									0.103,
									0.351,
									0.186,
									0.439,
									0.024
								],
								"e": 0.223
							},
							{
								"h": [
									0.034,
									0.008,
									0.0,
									0.005,
									0.005,
									0.001,
									0.028,
									0.445,
									0.008,
									0.003,
									0.22,
									0.023
								],
								"e": 0.044
							},
							{
								"h": [
									0.001,
									0.009,
									0.001,
									0.0,
									0.069,
									0.042,
									0.006,
									0.001,
									0.0,
									0.018,
									0.021,
									0.01
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.001,
									0.022,
									0.022,
									0.126,
									0.001,
									0.019,
									0.051,
									0.098,
									0.019,
									0.012,
									0.0
								],
								"e": 0.034
							},
							{
								"h": [
									0.002,
									0.0,
									0.005,
									0.015,
									0.004,
									0.005,
									0.019,
									0.011,
									0.01,
									0.201,
									0.644,
									0.015
								],
								"e": 0.044
							},
							{
								"h": [
									0.054,
									0.004,
									0.015,
									0.041,
									0.055,
									0.032,
									0.114,
									0.016,
									0.016,
									0.005,
									0.083,
									0.022
								],
								"e": 0.034
							},
							{
								"h": [
									0.002,
									0.04,
									0.001,
									0.001,
									0.001,
									0.186,
									0.014,
									0.008,
									0.008,
									0.0,
									0.002,
									0.006
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.002,
									0.051,
									0.003,
									0.006,
									0.001,
									0.005,
									0.005,
									0.018,
									0.001,
									0.002,
									0.01
								],
								"e": 0.008
							},
							{
								"h": [
									0.031,
									0.004,
									0.017,
									0.016,
									0.002,
									0.011,
									0.001,
									0.003,
									0.034,
									0.0,
									0.006,
									0.007
								],
								"e": 0.009
							},
							{
								"h": [
									0.007,
									0.004,
									0.002,
									0.002,
									0.021,
									0.003,
									0.009,
									0.002,
									0.001,
									0.002,
									0.001,
									0.033
								],
								"e": 0.006
							},
							{
								"h": [
									0.264,
									0.027,
									0.005,
									0.007,
									0.018,
									0.007,
									0.004,
									0.011,
									0.212,
									0.014,
									0.032,
									0.007
								],
								"e": 0.021
							}
						],
						[
							{
								"h": [
									0.028,
									0.738,
									0.233,
									0.003,
									0.054,
									0.498,
									0.014,
									0.015,
									0.689,
									0.567,
									0.853,
									0.072
								],
								"e": 0.346
							},
							{
								"h": [
									0.007,
									0.041,
									0.391,
									0.03,
									0.228,
									1.0,
									0.284,
									0.192,
									0.369,
									0.127,
									0.326,
									0.611
								],
								"e": 0.343
							},
							{
								"h": [
									0.033,
									0.062,
									0.0,
									0.001,
									0.001,
									0.001,
									0.058,
									0.276,
									0.005,
									0.0,
									0.135,
									0.015
								],
								"e": 0.043
							},
							{
								"h": [
									0.55,
									0.004,
									0.0,
									0.0,
									0.642,
									0.032,
									0.0,
									0.0,
									0.001,
									0.011,
									0.072,
									0.001
								],
								"e": 0.106
							},
							{
								"h": [
									0.0,
									0.001,
									0.143,
									0.001,
									0.306,
									0.001,
									0.013,
									0.125,
									0.165,
									0.009,
									0.033,
									0.0
								],
								"e": 0.069
							},
							{
								"h": [
									0.006,
									0.0,
									0.007,
									0.027,
									0.0,
									0.02,
									0.001,
									0.001,
									0.001,
									0.174,
									0.503,
									0.217
								],
								"e": 0.05
							},
							{
								"h": [
									0.007,
									0.001,
									0.021,
									0.017,
									0.117,
									0.014,
									0.154,
									0.008,
									0.001,
									0.001,
									0.037,
									0.002
								],
								"e": 0.026
							},
							{
								"h": [
									0.0,
									0.003,
									0.0,
									0.002,
									0.0,
									0.017,
									0.024,
									0.016,
									0.0,
									0.0,
									0.001,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.039,
									0.002,
									0.006,
									0.005,
									0.0,
									0.0,
									0.008,
									0.0,
									0.007,
									0.005,
									0.004,
									0.006
								],
								"e": 0.007
							},
							{
								"h": [
									0.003,
									0.005,
									0.001,
									0.024,
									0.005,
									0.01,
									0.0,
									0.011,
									0.011,
									0.0,
									0.001,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.038,
									0.002,
									0.01,
									0.005,
									0.01,
									0.003,
									0.002,
									0.023,
									0.007,
									0.001,
									0.002,
									0.018
								],
								"e": 0.008
							},
							{
								"h": [
									0.338,
									0.169,
									0.011,
									0.045,
									0.046,
									0.019,
									0.008,
									0.015,
									0.865,
									0.004,
									0.078,
									0.037
								],
								"e": 0.053
							}
						],
						[
							{
								"h": [
									0.17,
									0.0,
									0.106,
									0.0,
									0.095,
									0.002,
									0.623,
									0.191,
									0.009,
									0.536,
									0.242,
									0.193
								],
								"e": 0.152
							},
							{
								"h": [
									0.084,
									0.04,
									0.472,
									0.037,
									0.31,
									0.088,
									0.164,
									0.223,
									0.27,
									0.231,
									0.541,
									0.15
								],
								"e": 0.26
							},
							{
								"h": [
									0.158,
									0.024,
									0.0,
									0.019,
									0.058,
									0.002,
									0.039,
									0.447,
									0.007,
									0.013,
									0.357,
									0.031
								],
								"e": 0.074
							},
							{
								"h": [
									0.002,
									0.006,
									0.001,
									0.008,
									0.059,
									0.028,
									0.038,
									0.001,
									0.0,
									0.048,
									0.052,
									0.007
								],
								"e": 0.026
							},
							{
								"h": [
									0.002,
									0.0,
									0.009,
									0.01,
									0.104,
									0.002,
									0.03,
									0.025,
									0.067,
									0.014,
									0.009,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									1.0,
									1.0,
									0.009,
									0.014,
									0.001,
									1.0,
									0.002,
									0.002,
									0.334,
									0.106,
									0.072,
									0.031
								],
								"e": 0.36
							},
							{
								"h": [
									0.016,
									0.052,
									0.028,
									0.036,
									0.318,
									0.042,
									0.076,
									0.197,
									0.002,
									1.0,
									0.639,
									0.006
								],
								"e": 0.152
							},
							{
								"h": [
									0.001,
									0.162,
									0.01,
									0.006,
									0.002,
									0.091,
									0.3,
									0.643,
									0.115,
									0.014,
									0.013,
									0.696
								],
								"e": 0.095
							},
							{
								"h": [
									0.006,
									0.015,
									0.079,
									0.011,
									0.005,
									0.003,
									1.0,
									0.005,
									0.169,
									0.128,
									0.056,
									0.092
								],
								"e": 0.106
							},
							{
								"h": [
									0.206,
									0.035,
									0.026,
									0.034,
									0.009,
									0.013,
									0.008,
									0.052,
									0.622,
									0.001,
									0.001,
									0.017
								],
								"e": 0.047
							},
							{
								"h": [
									0.096,
									0.003,
									0.011,
									0.011,
									0.379,
									0.002,
									0.014,
									1.0,
									0.005,
									0.001,
									0.004,
									1.0
								],
								"e": 0.116
							},
							{
								"h": [
									0.529,
									0.194,
									0.046,
									0.095,
									0.243,
									0.007,
									0.033,
									0.056,
									0.189,
									0.01,
									0.352,
									0.06
								],
								"e": 0.092
							}
						],
						[
							{
								"h": [
									0.084,
									0.003,
									0.858,
									0.002,
									0.03,
									0.003,
									0.017,
									0.066,
									0.289,
									0.652,
									0.409,
									0.365
								],
								"e": 0.185
							},
							{
								"h": [
									0.044,
									0.231,
									0.505,
									0.041,
									0.287,
									0.348,
									0.814,
									0.245,
									0.389,
									0.106,
									0.46,
									0.34
								],
								"e": 0.384
							},
							{
								"h": [
									0.101,
									0.107,
									0.0,
									0.005,
									0.027,
									0.021,
									0.3,
									0.249,
									0.032,
									0.001,
									0.21,
									0.026
								],
								"e": 0.098
							},
							{
								"h": [
									0.057,
									0.099,
									0.004,
									0.01,
									0.104,
									0.089,
									0.009,
									0.001,
									0.017,
									0.07,
									0.088,
									0.012
								],
								"e": 0.06
							},
							{
								"h": [
									0.003,
									0.072,
									0.259,
									0.007,
									0.097,
									0.013,
									0.065,
									0.109,
									0.141,
									0.052,
									0.026,
									0.0
								],
								"e": 0.082
							},
							{
								"h": [
									0.029,
									0.0,
									0.053,
									0.047,
									0.009,
									0.0,
									0.0,
									0.01,
									0.007,
									0.116,
									0.282,
									0.06
								],
								"e": 0.036
							},
							{
								"h": [
									0.032,
									0.007,
									0.07,
									0.114,
									0.265,
									0.036,
									0.108,
									0.02,
									0.006,
									0.002,
									0.377,
									0.011
								],
								"e": 0.068
							},
							{
								"h": [
									0.0,
									0.105,
									0.0,
									0.005,
									0.001,
									0.065,
									0.029,
									0.476,
									0.01,
									0.005,
									0.0,
									0.003
								],
								"e": 0.029
							},
							{
								"h": [
									0.021,
									0.001,
									0.006,
									0.003,
									0.008,
									0.004,
									0.004,
									0.001,
									0.014,
									0.005,
									0.018,
									0.005
								],
								"e": 0.008
							},
							{
								"h": [
									0.002,
									0.003,
									0.002,
									0.024,
									0.004,
									0.002,
									0.0,
									0.013,
									0.01,
									0.0,
									0.007,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.027,
									0.001,
									0.002,
									0.009,
									0.023,
									0.012,
									0.001,
									0.014,
									0.006,
									0.012,
									0.001,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.079,
									0.048,
									0.006,
									0.01,
									0.032,
									0.01,
									0.003,
									0.012,
									0.574,
									0.004,
									0.03,
									0.011
								],
								"e": 0.024
							}
						],
						[
							{
								"h": [
									0.48,
									0.0,
									0.073,
									0.002,
									0.048,
									0.003,
									0.577,
									0.083,
									0.011,
									0.46,
									0.208,
									0.134
								],
								"e": 0.138
							},
							{
								"h": [
									0.084,
									0.059,
									0.612,
									0.04,
									0.388,
									0.016,
									0.087,
									0.057,
									0.345,
									0.239,
									0.57,
									0.023
								],
								"e": 0.243
							},
							{
								"h": [
									0.204,
									0.011,
									0.001,
									0.056,
									0.046,
									0.005,
									0.052,
									0.473,
									0.023,
									0.017,
									0.292,
									0.03
								],
								"e": 0.081
							},
							{
								"h": [
									0.0,
									0.02,
									0.008,
									0.009,
									0.028,
									0.03,
									0.034,
									0.002,
									0.004,
									0.049,
									0.028,
									0.008
								],
								"e": 0.025
							},
							{
								"h": [
									0.009,
									0.007,
									0.005,
									0.033,
									0.01,
									0.008,
									0.037,
									0.032,
									0.017,
									0.014,
									0.003,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.003,
									0.0,
									0.004,
									0.023,
									0.013,
									0.005,
									0.0,
									0.022,
									0.012,
									0.217,
									0.055,
									0.018
								],
								"e": 0.025
							},
							{
								"h": [
									0.062,
									0.005,
									0.01,
									0.054,
									0.028,
									0.039,
									0.056,
									0.024,
									0.007,
									0.001,
									0.054,
									0.054
								],
								"e": 0.033
							},
							{
								"h": [
									0.003,
									0.008,
									0.001,
									0.004,
									0.001,
									0.086,
									0.016,
									0.014,
									0.027,
									0.005,
									0.0,
									0.009
								],
								"e": 0.01
							},
							{
								"h": [
									0.009,
									0.001,
									0.003,
									0.002,
									0.011,
									0.002,
									0.001,
									0.001,
									0.016,
									0.001,
									0.005,
									0.012
								],
								"e": 0.005
							},
							{
								"h": [
									0.011,
									0.008,
									0.008,
									0.007,
									0.0,
									0.003,
									0.0,
									0.001,
									0.012,
									0.0,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.002,
									0.008,
									0.002,
									0.001,
									0.003,
									0.0,
									0.003,
									0.0,
									0.007
								],
								"e": 0.002
							},
							{
								"h": [
									0.112,
									0.007,
									0.001,
									0.001,
									0.005,
									0.001,
									0.001,
									0.003,
									0.037,
									0.002,
									0.003,
									0.0
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.39,
									0.0,
									0.193,
									0.001,
									0.027,
									0.003,
									0.126,
									0.012,
									0.019,
									0.439,
									0.137,
									0.147
								],
								"e": 0.092
							},
							{
								"h": [
									0.103,
									0.038,
									0.416,
									0.03,
									0.266,
									0.054,
									0.177,
									0.103,
									0.236,
									0.126,
									0.469,
									0.097
								],
								"e": 0.209
							},
							{
								"h": [
									0.079,
									0.004,
									0.0,
									0.006,
									0.039,
									0.007,
									0.022,
									0.307,
									0.047,
									0.014,
									0.272,
									0.032
								],
								"e": 0.054
							},
							{
								"h": [
									0.001,
									0.009,
									0.002,
									0.009,
									0.03,
									0.017,
									0.009,
									0.007,
									0.005,
									0.032,
									0.047,
									0.013
								],
								"e": 0.02
							},
							{
								"h": [
									0.002,
									0.004,
									0.002,
									0.036,
									0.056,
									0.002,
									0.027,
									0.026,
									0.014,
									0.006,
									0.002,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.0,
									0.007,
									0.013,
									0.004,
									0.0,
									0.001,
									0.011,
									0.0,
									0.073,
									0.046,
									0.014
								],
								"e": 0.011
							},
							{
								"h": [
									0.11,
									0.003,
									0.007,
									0.032,
									0.014,
									0.044,
									0.063,
									0.011,
									0.031,
									0.0,
									0.046,
									0.087
								],
								"e": 0.039
							},
							{
								"h": [
									0.001,
									0.003,
									0.0,
									0.001,
									0.0,
									0.176,
									0.004,
									0.006,
									0.014,
									0.0,
									0.0,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.001,
									0.001,
									0.016,
									0.0,
									0.014,
									0.002,
									0.001,
									0.008,
									0.013,
									0.0,
									0.001,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.006,
									0.0,
									0.022,
									0.014,
									0.0,
									0.005,
									0.0,
									0.001,
									0.026,
									0.0,
									0.009,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.005,
									0.0,
									0.0,
									0.001,
									0.004,
									0.005,
									0.002,
									0.0,
									0.0,
									0.004,
									0.0,
									0.005
								],
								"e": 0.002
							},
							{
								"h": [
									0.186,
									0.006,
									0.0,
									0.003,
									0.003,
									0.001,
									0.0,
									0.002,
									0.057,
									0.001,
									0.003,
									0.001
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.033,
									0.867,
									0.249,
									0.05,
									0.212,
									0.439,
									0.017,
									0.087,
									0.835,
									0.647,
									0.936,
									0.069
								],
								"e": 0.425
							},
							{
								"h": [
									0.03,
									0.115,
									0.577,
									0.044,
									0.316,
									0.891,
									0.248,
									0.172,
									0.356,
									0.1,
									0.503,
									0.722
								],
								"e": 0.394
							},
							{
								"h": [
									0.128,
									0.043,
									0.0,
									0.013,
									0.016,
									0.011,
									0.11,
									0.419,
									0.054,
									0.001,
									0.276,
									0.035
								],
								"e": 0.078
							},
							{
								"h": [
									0.267,
									0.008,
									0.001,
									0.006,
									0.335,
									0.02,
									0.003,
									0.0,
									0.006,
									0.021,
									0.144,
									0.004
								],
								"e": 0.069
							},
							{
								"h": [
									0.002,
									0.006,
									0.075,
									0.003,
									0.152,
									0.003,
									0.037,
									0.059,
									0.054,
									0.007,
									0.016,
									0.0
								],
								"e": 0.037
							},
							{
								"h": [
									0.003,
									0.0,
									0.01,
									0.04,
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.126,
									0.084,
									0.162
								],
								"e": 0.027
							},
							{
								"h": [
									0.025,
									0.002,
									0.029,
									0.032,
									0.067,
									0.031,
									0.319,
									0.008,
									0.005,
									0.0,
									0.04,
									0.003
								],
								"e": 0.04
							},
							{
								"h": [
									0.0,
									0.002,
									0.0,
									0.005,
									0.0,
									0.007,
									0.044,
									0.025,
									0.001,
									0.001,
									0.0,
									0.01
								],
								"e": 0.005
							},
							{
								"h": [
									0.031,
									0.002,
									0.003,
									0.008,
									0.0,
									0.001,
									0.004,
									0.001,
									0.008,
									0.026,
									0.011,
									0.015
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.007,
									0.002,
									0.038,
									0.006,
									0.009,
									0.001,
									0.015,
									0.011,
									0.0,
									0.003,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.073,
									0.002,
									0.006,
									0.005,
									0.006,
									0.004,
									0.001,
									0.011,
									0.006,
									0.002,
									0.003,
									0.018
								],
								"e": 0.009
							},
							{
								"h": [
									0.241,
									0.34,
									0.008,
									0.037,
									0.055,
									0.018,
									0.009,
									0.029,
									0.506,
									0.004,
									0.046,
									0.031
								],
								"e": 0.051
							}
						],
						[
							{
								"h": [
									1.0,
									0.0,
									0.34,
									0.038,
									0.512,
									0.005,
									0.056,
									0.321,
									0.153,
									1.0,
									0.448,
									0.108
								],
								"e": 0.304
							},
							{
								"h": [
									0.208,
									0.368,
									0.615,
									0.056,
									0.403,
									0.291,
									0.453,
									0.37,
									0.445,
									0.378,
									0.768,
									1.0
								],
								"e": 0.589
							},
							{
								"h": [
									0.623,
									0.032,
									0.002,
									1.0,
									0.387,
									0.044,
									0.11,
									0.497,
									0.356,
									0.041,
									0.476,
									0.074
								],
								"e": 0.338
							},
							{
								"h": [
									0.022,
									0.024,
									0.12,
									0.013,
									0.138,
									0.141,
									0.029,
									0.002,
									0.005,
									0.196,
									0.152,
									0.062
								],
								"e": 0.102
							},
							{
								"h": [
									0.049,
									0.008,
									0.018,
									0.005,
									0.008,
									0.019,
									0.111,
									0.049,
									0.081,
									0.034,
									0.007,
									0.0
								],
								"e": 0.042
							},
							{
								"h": [
									0.257,
									0.004,
									0.031,
									0.015,
									0.02,
									0.052,
									0.0,
									0.002,
									0.369,
									0.355,
									0.037,
									0.027
								],
								"e": 0.094
							},
							{
								"h": [
									0.017,
									0.159,
									0.045,
									0.042,
									0.101,
									0.063,
									0.057,
									0.221,
									0.03,
									0.025,
									0.3,
									0.032
								],
								"e": 0.088
							},
							{
								"h": [
									0.001,
									0.015,
									0.001,
									0.014,
									0.002,
									0.029,
									0.12,
									0.397,
									0.055,
									0.002,
									0.005,
									0.327
								],
								"e": 0.043
							},
							{
								"h": [
									0.029,
									0.076,
									0.016,
									0.01,
									0.003,
									0.006,
									0.078,
									0.004,
									0.041,
									0.123,
									0.024,
									0.103
								],
								"e": 0.041
							},
							{
								"h": [
									0.023,
									0.027,
									0.006,
									0.026,
									0.003,
									0.005,
									0.006,
									0.043,
									0.004,
									0.001,
									0.012,
									0.234
								],
								"e": 0.031
							},
							{
								"h": [
									0.022,
									0.002,
									0.007,
									0.005,
									0.031,
									0.006,
									0.001,
									0.02,
									0.03,
									0.001,
									0.009,
									0.008
								],
								"e": 0.01
							},
							{
								"h": [
									0.363,
									0.278,
									0.017,
									0.053,
									0.087,
									0.028,
									0.027,
									0.038,
									0.213,
									0.004,
									0.035,
									0.042
								],
								"e": 0.055
							}
						],
						[
							{
								"h": [
									0.381,
									0.0,
									0.171,
									0.036,
									0.542,
									0.001,
									0.7,
									1.0,
									0.016,
									0.587,
									0.276,
									0.259
								],
								"e": 0.358
							},
							{
								"h": [
									0.559,
									0.214,
									0.748,
									0.058,
									0.556,
									0.067,
									0.082,
									0.175,
									0.294,
									0.193,
									1.0,
									0.099
								],
								"e": 0.471
							},
							{
								"h": [
									0.631,
									0.015,
									0.109,
									0.167,
									0.845,
									0.074,
									0.104,
									0.721,
									0.362,
									0.31,
									0.947,
									0.084
								],
								"e": 0.392
							},
							{
								"h": [
									0.002,
									0.035,
									0.016,
									0.201,
									0.059,
									0.031,
									0.249,
									0.043,
									0.043,
									0.414,
									0.142,
									0.142
								],
								"e": 0.159
							},
							{
								"h": [
									0.014,
									0.01,
									0.004,
									0.078,
									0.119,
									0.017,
									0.17,
									0.051,
									0.029,
									0.028,
									0.008,
									0.0
								],
								"e": 0.055
							},
							{
								"h": [
									0.023,
									0.0,
									0.05,
									0.084,
									0.013,
									0.001,
									0.0,
									0.005,
									0.03,
									0.055,
									0.054,
									0.016
								],
								"e": 0.026
							},
							{
								"h": [
									0.126,
									0.042,
									0.025,
									0.056,
									0.231,
									0.093,
									0.057,
									0.857,
									0.053,
									0.001,
									0.802,
									0.092
								],
								"e": 0.165
							},
							{
								"h": [
									0.008,
									0.251,
									0.0,
									0.015,
									0.008,
									0.327,
									0.404,
									1.0,
									0.608,
									0.026,
									0.0,
									0.023
								],
								"e": 0.129
							},
							{
								"h": [
									0.004,
									0.002,
									0.118,
									0.026,
									0.046,
									0.009,
									0.265,
									0.031,
									0.325,
									0.116,
									0.061,
									0.622
								],
								"e": 0.112
							},
							{
								"h": [
									0.255,
									0.029,
									0.043,
									0.135,
									0.008,
									0.006,
									0.006,
									0.049,
									0.568,
									0.001,
									0.011,
									0.006
								],
								"e": 0.054
							},
							{
								"h": [
									0.12,
									0.001,
									0.004,
									0.017,
									0.215,
									0.005,
									0.018,
									0.54,
									0.002,
									0.005,
									0.006,
									0.166
								],
								"e": 0.051
							},
							{
								"h": [
									0.363,
									0.112,
									0.029,
									0.082,
									0.099,
									0.007,
									0.019,
									0.026,
									0.11,
									0.018,
									0.22,
									0.067
								],
								"e": 0.06
							}
						],
						[
							{
								"h": [
									0.115,
									0.003,
									1.0,
									0.084,
									0.184,
									0.002,
									0.022,
									0.681,
									0.365,
									0.79,
									0.452,
									0.383
								],
								"e": 0.34
							},
							{
								"h": [
									0.299,
									1.0,
									0.919,
									0.073,
									0.468,
									0.382,
									0.724,
									0.241,
									0.382,
									0.088,
									0.888,
									0.448
								],
								"e": 0.706
							},
							{
								"h": [
									0.655,
									0.074,
									0.018,
									0.112,
									0.643,
									1.0,
									1.0,
									0.493,
									0.875,
									0.071,
									0.613,
									0.086
								],
								"e": 0.593
							},
							{
								"h": [
									0.047,
									1.0,
									0.311,
									0.661,
									0.121,
									0.121,
									0.187,
									0.042,
									1.0,
									0.526,
									0.305,
									0.199
								],
								"e": 0.582
							},
							{
								"h": [
									0.073,
									1.0,
									0.288,
									0.034,
									0.1,
									0.154,
									0.254,
									0.275,
									0.072,
									0.151,
									0.024,
									0.0
								],
								"e": 0.278
							},
							{
								"h": [
									0.003,
									0.0,
									0.566,
									0.215,
									0.042,
									0.0,
									0.0,
									0.022,
									0.009,
									0.056,
									0.128,
									0.051
								],
								"e": 0.093
							},
							{
								"h": [
									0.175,
									0.012,
									0.071,
									0.183,
									0.151,
									0.11,
									0.12,
									0.037,
									0.049,
									0.001,
									0.267,
									0.061
								],
								"e": 0.096
							},
							{
								"h": [
									0.003,
									0.056,
									0.0,
									0.01,
									0.004,
									0.065,
									0.043,
									0.369,
									0.056,
									0.018,
									0.0,
									0.001
								],
								"e": 0.029
							},
							{
								"h": [
									0.015,
									0.001,
									0.007,
									0.005,
									0.032,
									0.021,
									0.004,
									0.007,
									0.03,
									0.009,
									0.044,
									0.023
								],
								"e": 0.017
							},
							{
								"h": [
									0.003,
									0.004,
									0.006,
									0.069,
									0.007,
									0.003,
									0.0,
									0.032,
									0.018,
									0.0,
									0.027,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.048,
									0.001,
									0.003,
									0.018,
									0.034,
									0.027,
									0.001,
									0.018,
									0.004,
									0.03,
									0.001,
									0.004
								],
								"e": 0.014
							},
							{
								"h": [
									0.068,
									0.045,
									0.006,
									0.013,
									0.033,
									0.01,
									0.003,
									0.011,
									0.546,
									0.003,
									0.031,
									0.011
								],
								"e": 0.023
							}
						],
						[
							{
								"h": [
									0.786,
									0.0,
									0.118,
									0.023,
									0.354,
									0.003,
									1.0,
									0.894,
									0.009,
									0.403,
									0.254,
									0.1
								],
								"e": 0.336
							},
							{
								"h": [
									1.0,
									0.182,
									1.0,
									0.063,
									0.532,
									0.066,
									0.234,
									0.084,
									0.336,
									0.17,
									0.974,
									0.036
								],
								"e": 0.581
							},
							{
								"h": [
									0.653,
									0.007,
									1.0,
									0.721,
									1.0,
									0.234,
									0.153,
									1.0,
									0.99,
									1.0,
									1.0,
									0.136
								],
								"e": 1.0
							},
							{
								"h": [
									0.001,
									0.442,
									1.0,
									1.0,
									0.076,
									0.076,
									1.0,
									1.0,
									0.095,
									0.921,
									0.174,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.098,
									0.184,
									0.078,
									1.0,
									0.174,
									0.149,
									0.35,
									0.273,
									0.037,
									0.371,
									0.016,
									1.0
								],
								"e": 0.578
							},
							{
								"h": [
									0.002,
									0.0,
									0.153,
									0.255,
									0.115,
									0.006,
									0.202,
									0.163,
									0.058,
									0.19,
									0.54,
									0.043
								],
								"e": 0.148
							},
							{
								"h": [
									1.0,
									0.014,
									0.023,
									0.141,
									0.024,
									0.209,
									0.137,
									0.022,
									1.0,
									0.003,
									0.092,
									0.338
								],
								"e": 0.274
							},
							{
								"h": [
									0.063,
									0.018,
									0.001,
									0.004,
									0.004,
									0.203,
									0.017,
									0.01,
									0.083,
									0.008,
									0.001,
									0.012
								],
								"e": 0.028
							},
							{
								"h": [
									0.004,
									0.002,
									0.025,
									0.003,
									0.091,
									0.027,
									0.003,
									0.168,
									0.05,
									0.002,
									0.007,
									0.035
								],
								"e": 0.038
							},
							{
								"h": [
									0.018,
									0.003,
									0.021,
									0.098,
									0.001,
									0.009,
									0.0,
									0.009,
									0.032,
									0.0,
									0.029,
									0.003
								],
								"e": 0.015
							},
							{
								"h": [
									0.008,
									0.002,
									0.001,
									0.001,
									0.015,
									0.027,
									0.005,
									0.001,
									0.001,
									0.081,
									0.0,
									0.014
								],
								"e": 0.014
							},
							{
								"h": [
									0.29,
									0.022,
									0.002,
									0.003,
									0.006,
									0.003,
									0.001,
									0.007,
									0.111,
									0.005,
									0.02,
									0.0
								],
								"e": 0.014
							}
						],
						[
							{
								"h": [
									0.041,
									1.0,
									0.272,
									1.0,
									1.0,
									0.393,
									0.022,
									0.582,
									1.0,
									0.746,
									1.0,
									0.067
								],
								"e": 1.0
							},
							{
								"h": [
									0.143,
									0.442,
									0.9,
									0.069,
									0.481,
									0.982,
									0.257,
									0.206,
									0.366,
									0.089,
									0.83,
									0.933
								],
								"e": 0.597
							},
							{
								"h": [
									0.701,
									0.05,
									0.029,
									0.233,
									0.261,
									0.349,
									0.341,
									0.711,
									1.0,
									0.04,
									0.75,
									0.107
								],
								"e": 0.4
							},
							{
								"h": [
									0.213,
									0.143,
									0.118,
									0.811,
									0.402,
									0.056,
									0.061,
									0.03,
									0.302,
									0.28,
									0.59,
									0.09
								],
								"e": 0.317
							},
							{
								"h": [
									0.144,
									0.172,
									0.216,
									0.066,
									0.391,
									0.132,
									0.313,
									1.0,
									0.096,
									0.148,
									0.034,
									0.0
								],
								"e": 0.28
							},
							{
								"h": [
									0.008,
									0.0,
									0.127,
									0.316,
									0.014,
									0.028,
									0.02,
									0.011,
									0.006,
									0.163,
									0.449,
									0.533
								],
								"e": 0.116
							},
							{
								"h": [
									0.229,
									0.013,
									0.042,
									0.116,
									0.132,
									0.121,
									0.389,
									0.047,
									0.129,
									0.002,
									0.05,
									0.054
								],
								"e": 0.108
							},
							{
								"h": [
									0.004,
									0.004,
									0.0,
									0.023,
									0.001,
									0.024,
									0.044,
									0.026,
									0.011,
									0.004,
									0.005,
									0.003
								],
								"e": 0.009
							},
							{
								"h": [
									0.164,
									0.007,
									0.005,
									0.016,
									0.004,
									0.004,
									0.009,
									0.018,
									0.032,
									0.067,
									0.042,
									0.113
								],
								"e": 0.038
							},
							{
								"h": [
									0.009,
									0.015,
									0.007,
									0.318,
									0.012,
									0.03,
									0.001,
									0.041,
									0.036,
									0.001,
									0.018,
									0.001
								],
								"e": 0.032
							},
							{
								"h": [
									0.189,
									0.003,
									0.006,
									0.004,
									0.006,
									0.014,
									0.002,
									0.017,
									0.006,
									0.012,
									0.004,
									0.014
								],
								"e": 0.019
							},
							{
								"h": [
									0.226,
									0.355,
									0.009,
									0.057,
									0.069,
									0.019,
									0.012,
									0.037,
									0.754,
									0.004,
									0.054,
									0.04
								],
								"e": 0.061
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Luke",
					"o": " Skywalker",
					"t": " Skywalker",
					"r": [
						{
							"p": " Skywalker",
							"b": 0.975,
							"k": {
								" Skywalker": 0.975,
								" Luke": 0.014,
								"warm": 0.011,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.996,
							"k": {
								" Skywalker": 0.996,
								"warm": 0.002,
								" Luke": 0.001,
								" Cage": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.999,
							"k": {
								" Skywalker": 0.999,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.993,
							"k": {
								" Skywalker": 0.993,
								"warm": 0.007,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.991,
							"k": {
								" Skywalker": 0.991,
								"warm": 0.009,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.991,
							"k": {
								" Skywalker": 0.991,
								"warm": 0.009,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 1.0,
							"k": {
								" Skywalker": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.996,
							"k": {
								" Skywalker": 0.996,
								"warm": 0.004,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.998,
							"k": {
								" Skywalker": 0.998,
								"warm": 0.002,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.999,
							"k": {
								" Skywalker": 0.999,
								" Cage": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.94,
							"k": {
								" Skywalker": 0.94,
								" Cage": 0.042,
								".": 0.008,
								"-": 0.001,
								" Vader": 0.001,
								"warm": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.567,
							"k": {
								" Skywalker": 0.567,
								" Cage": 0.392,
								".": 0.016,
								" Evans": 0.003,
								"-": 0.001,
								" Gall": 0.001,
								" Wilson": 0.001,
								" Shaw": 0.001,
								"!": 0.0,
								"\"": 0.0
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.616,
									0.0,
									1.0,
									0.0,
									0.003,
									0.007,
									1.0,
									0.075,
									0.84,
									0.475,
									0.173,
									0.56
								],
								"e": 0.137
							},
							{
								"h": [
									0.118,
									0.036,
									0.19,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.025,
									0.02
								],
								"e": 0.925
							},
							{
								"h": [
									1.0,
									1.0,
									0.21,
									0.236,
									1.0,
									0.086,
									0.399,
									1.0,
									0.304,
									0.174,
									0.132,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.492,
									1.0,
									1.0,
									1.0,
									0.603,
									0.187,
									0.149,
									1.0,
									1.0,
									0.698
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.509,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.526,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.006,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.249,
									0.0,
									0.216,
									0.0,
									0.0,
									0.0,
									0.148,
									0.014,
									0.441,
									0.302,
									0.156,
									0.519
								],
								"e": 0.053
							},
							{
								"h": [
									0.018,
									0.008,
									0.104,
									0.01,
									0.123,
									0.51,
									0.331,
									0.394,
									0.375,
									0.305,
									0.11,
									0.007
								],
								"e": 0.204
							},
							{
								"h": [
									0.013,
									0.056,
									0.0,
									0.001,
									0.002,
									0.001,
									0.027,
									0.088,
									0.003,
									0.0,
									0.04,
									0.005
								],
								"e": 0.024
							},
							{
								"h": [
									0.017,
									0.003,
									0.0,
									0.0,
									0.019,
									0.012,
									0.0,
									0.0,
									0.0,
									0.001,
									0.004,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.0,
									0.057,
									0.0,
									0.028,
									0.0,
									0.003,
									0.001,
									0.018,
									0.003,
									0.025,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.0,
									0.003,
									0.015,
									0.0,
									0.001,
									0.003,
									0.0,
									0.001,
									0.017,
									0.386,
									0.031
								],
								"e": 0.02
							},
							{
								"h": [
									0.005,
									0.001,
									0.05,
									0.008,
									0.023,
									0.002,
									0.008,
									0.004,
									0.0,
									0.0,
									0.004,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.005,
									0.001,
									0.001,
									0.001,
									0.034,
									0.006,
									0.003,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.008,
									0.002,
									0.006,
									0.003,
									0.0,
									0.001,
									0.012,
									0.0,
									0.003,
									0.002,
									0.003,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.009,
									0.003,
									0.001,
									0.001,
									0.009,
									0.008,
									0.001,
									0.005,
									0.011,
									0.002,
									0.0,
									0.018
								],
								"e": 0.005
							},
							{
								"h": [
									0.007,
									0.006,
									0.006,
									0.009,
									0.019,
									0.001,
									0.007,
									0.009,
									0.021,
									0.0,
									0.007,
									0.006
								],
								"e": 0.008
							},
							{
								"h": [
									0.013,
									0.007,
									0.018,
									0.004,
									0.052,
									0.01,
									0.007,
									0.004,
									0.373,
									0.006,
									0.009,
									0.008
								],
								"e": 0.016
							}
						],
						[
							{
								"h": [
									0.727,
									0.0,
									0.317,
									0.0,
									0.002,
									0.0,
									0.332,
									0.003,
									0.845,
									0.433,
									0.235,
									0.878
								],
								"e": 0.097
							},
							{
								"h": [
									0.022,
									0.009,
									0.116,
									0.013,
									0.12,
									0.34,
									0.082,
									0.382,
									0.199,
									0.148,
									0.154,
									0.003
								],
								"e": 0.135
							},
							{
								"h": [
									0.018,
									0.024,
									0.0,
									0.001,
									0.0,
									0.0,
									0.008,
									0.155,
									0.0,
									0.0,
									0.058,
									0.007
								],
								"e": 0.02
							},
							{
								"h": [
									0.011,
									0.0,
									0.0,
									0.0,
									0.099,
									0.002,
									0.0,
									0.0,
									0.0,
									0.0,
									0.007,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.0,
									0.01,
									0.0,
									0.025,
									0.0,
									0.001,
									0.025,
									0.055,
									0.0,
									0.029,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.003,
									0.0,
									0.0,
									0.002,
									0.0,
									0.002,
									0.001,
									0.0,
									0.001,
									0.024,
									0.102,
									0.018
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.0,
									0.021,
									0.005,
									0.016,
									0.001,
									0.021,
									0.003,
									0.0,
									0.0,
									0.015,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.02,
									0.002,
									0.001,
									0.0,
									0.011,
									0.023,
									0.009,
									0.0,
									0.0,
									0.001,
									0.007
								],
								"e": 0.005
							},
							{
								"h": [
									0.006,
									0.001,
									0.007,
									0.001,
									0.0,
									0.001,
									0.016,
									0.0,
									0.002,
									0.004,
									0.004,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.021,
									0.001,
									0.003,
									0.0,
									0.006,
									0.013,
									0.002,
									0.002,
									0.031,
									0.003,
									0.0,
									0.005
								],
								"e": 0.006
							},
							{
								"h": [
									0.013,
									0.001,
									0.002,
									0.007,
									0.013,
									0.0,
									0.004,
									0.013,
									0.004,
									0.0,
									0.002,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.025,
									0.01,
									0.007,
									0.004,
									0.01,
									0.002,
									0.004,
									0.001,
									0.706,
									0.002,
									0.011,
									0.007
								],
								"e": 0.016
							}
						],
						[
							{
								"h": [
									0.864,
									0.0,
									0.63,
									0.0,
									0.0,
									0.0,
									0.336,
									0.003,
									0.687,
									0.573,
									0.211,
									0.944
								],
								"e": 0.111
							},
							{
								"h": [
									0.008,
									0.005,
									0.169,
									0.014,
									0.135,
									0.405,
									0.385,
									0.462,
									0.304,
									0.264,
									0.185,
									0.0
								],
								"e": 0.198
							},
							{
								"h": [
									0.002,
									0.108,
									0.0,
									0.001,
									0.0,
									0.0,
									0.007,
									0.233,
									0.001,
									0.001,
									0.077,
									0.01
								],
								"e": 0.039
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.0,
									0.066,
									0.008,
									0.0,
									0.0,
									0.0,
									0.0,
									0.007,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.0,
									0.007,
									0.0,
									0.004,
									0.0,
									0.002,
									0.003,
									0.045,
									0.001,
									0.021,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.156,
									0.01,
									0.0,
									0.001,
									0.0,
									0.022,
									0.0,
									0.0,
									0.015,
									0.017,
									0.112,
									0.029
								],
								"e": 0.021
							},
							{
								"h": [
									0.001,
									0.001,
									0.016,
									0.004,
									0.059,
									0.002,
									0.014,
									0.006,
									0.0,
									0.007,
									0.048,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.036,
									0.013,
									0.001,
									0.0,
									0.013,
									0.051,
									0.03,
									0.0,
									0.0,
									0.01,
									0.226
								],
								"e": 0.029
							},
							{
								"h": [
									0.008,
									0.012,
									0.014,
									0.0,
									0.0,
									0.0,
									0.093,
									0.0,
									0.003,
									0.003,
									0.003,
									0.008
								],
								"e": 0.011
							},
							{
								"h": [
									0.033,
									0.002,
									0.006,
									0.0,
									0.002,
									0.014,
									0.01,
									0.003,
									0.082,
									0.001,
									0.0,
									0.023
								],
								"e": 0.012
							},
							{
								"h": [
									0.025,
									0.008,
									0.007,
									0.008,
									0.021,
									0.0,
									0.009,
									0.02,
									0.01,
									0.0,
									0.007,
									0.008
								],
								"e": 0.01
							},
							{
								"h": [
									0.012,
									0.007,
									0.033,
									0.004,
									0.032,
									0.004,
									0.009,
									0.005,
									0.508,
									0.007,
									0.026,
									0.01
								],
								"e": 0.019
							}
						],
						[
							{
								"h": [
									0.191,
									0.0,
									0.183,
									0.0,
									0.0,
									0.0,
									0.141,
									0.016,
									0.273,
									0.251,
									0.162,
									0.812
								],
								"e": 0.051
							},
							{
								"h": [
									0.03,
									0.019,
									0.174,
									0.014,
									0.127,
									0.268,
									0.329,
									0.277,
									0.398,
									0.193,
									0.147,
									0.009
								],
								"e": 0.174
							},
							{
								"h": [
									0.01,
									0.087,
									0.0,
									0.0,
									0.001,
									0.0,
									0.113,
									0.126,
									0.001,
									0.0,
									0.036,
									0.007
								],
								"e": 0.04
							},
							{
								"h": [
									0.007,
									0.005,
									0.0,
									0.0,
									0.015,
									0.013,
									0.0,
									0.0,
									0.0,
									0.001,
									0.008,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.002,
									0.051,
									0.0,
									0.021,
									0.0,
									0.003,
									0.008,
									0.018,
									0.002,
									0.021,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.095,
									0.0,
									0.002,
									0.013,
									0.002,
									0.001,
									0.001,
									0.0,
									0.003,
									0.013,
									0.101,
									0.045
								],
								"e": 0.014
							},
							{
								"h": [
									0.003,
									0.001,
									0.024,
									0.011,
									0.025,
									0.003,
									0.018,
									0.007,
									0.0,
									0.001,
									0.112,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.022,
									0.003,
									0.001,
									0.0,
									0.092,
									0.01,
									0.143,
									0.0,
									0.001,
									0.001,
									0.022
								],
								"e": 0.018
							},
							{
								"h": [
									0.016,
									0.021,
									0.004,
									0.003,
									0.001,
									0.003,
									0.063,
									0.001,
									0.008,
									0.004,
									0.005,
									0.006
								],
								"e": 0.01
							},
							{
								"h": [
									0.006,
									0.003,
									0.001,
									0.002,
									0.007,
									0.004,
									0.002,
									0.009,
									0.007,
									0.002,
									0.0,
									0.086
								],
								"e": 0.009
							},
							{
								"h": [
									0.004,
									0.003,
									0.004,
									0.006,
									0.037,
									0.001,
									0.003,
									0.007,
									0.01,
									0.0,
									0.007,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.006,
									0.002,
									0.01,
									0.005,
									0.023,
									0.004,
									0.005,
									0.003,
									0.363,
									0.002,
									0.01,
									0.006
								],
								"e": 0.011
							}
						],
						[
							{
								"h": [
									0.126,
									0.0,
									0.203,
									0.0,
									0.0,
									0.0,
									0.447,
									0.004,
									0.179,
									0.193,
									0.11,
									0.317
								],
								"e": 0.044
							},
							{
								"h": [
									0.02,
									0.018,
									0.211,
									0.015,
									0.152,
									0.059,
									0.082,
									0.224,
									0.15,
									0.157,
									0.203,
									0.0
								],
								"e": 0.109
							},
							{
								"h": [
									0.035,
									0.026,
									0.0,
									0.004,
									0.0,
									0.0,
									0.013,
									0.146,
									0.001,
									0.001,
									0.051,
									0.007
								],
								"e": 0.023
							},
							{
								"h": [
									0.0,
									0.002,
									0.0,
									0.0,
									0.008,
									0.009,
									0.0,
									0.0,
									0.0,
									0.001,
									0.005,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.0,
									0.008,
									0.001,
									0.001,
									0.001,
									0.001,
									0.031,
									0.012,
									0.007,
									0.002,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.003,
									0.0,
									0.003,
									0.0,
									0.006,
									0.002,
									0.023,
									0.036,
									0.01
								],
								"e": 0.005
							},
							{
								"h": [
									0.003,
									0.0,
									0.011,
									0.002,
									0.013,
									0.001,
									0.01,
									0.01,
									0.0,
									0.0,
									0.016,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.014,
									0.001,
									0.0,
									0.001,
									0.035,
									0.009,
									0.015,
									0.0,
									0.0,
									0.0,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.004,
									0.001,
									0.002,
									0.001,
									0.0,
									0.002,
									0.002,
									0.0,
									0.002,
									0.001,
									0.002,
									0.003
								],
								"e": 0.002
							},
							{
								"h": [
									0.03,
									0.0,
									0.006,
									0.001,
									0.001,
									0.007,
									0.004,
									0.002,
									0.017,
									0.002,
									0.0,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.008,
									0.003,
									0.002,
									0.002,
									0.005,
									0.0,
									0.008,
									0.006,
									0.001,
									0.0,
									0.004,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.023,
									0.002,
									0.004,
									0.001,
									0.018,
									0.001,
									0.005,
									0.002,
									0.395,
									0.003,
									0.005,
									0.003
								],
								"e": 0.01
							}
						],
						[
							{
								"h": [
									0.082,
									0.0,
									0.229,
									0.0,
									0.0,
									0.0,
									0.1,
									0.01,
									0.216,
									0.233,
									0.099,
									0.223
								],
								"e": 0.032
							},
							{
								"h": [
									0.016,
									0.007,
									0.15,
									0.012,
									0.123,
									0.228,
									0.267,
									0.455,
									0.171,
									0.157,
									0.169,
									0.0
								],
								"e": 0.145
							},
							{
								"h": [
									0.002,
									0.026,
									0.0,
									0.0,
									0.0,
									0.0,
									0.009,
									0.139,
									0.001,
									0.001,
									0.051,
									0.008
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.0,
									0.014,
									0.009,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.0,
									0.008,
									0.002,
									0.014,
									0.001,
									0.003,
									0.003,
									0.012,
									0.004,
									0.004,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.004,
									0.0,
									0.0,
									0.002,
									0.001,
									0.0,
									0.0,
									0.003,
									0.0,
									0.007,
									0.041,
									0.01
								],
								"e": 0.004
							},
							{
								"h": [
									0.004,
									0.0,
									0.009,
									0.002,
									0.004,
									0.003,
									0.012,
									0.006,
									0.0,
									0.0,
									0.015,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.003,
									0.0,
									0.0,
									0.0,
									0.085,
									0.004,
									0.008,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.005,
									0.0,
									0.001,
									0.0,
									0.001,
									0.002,
									0.004,
									0.001,
									0.001,
									0.001,
									0.001,
									0.002
								],
								"e": 0.001
							},
							{
								"h": [
									0.007,
									0.0,
									0.006,
									0.001,
									0.0,
									0.004,
									0.0,
									0.002,
									0.021,
									0.0,
									0.001,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.007,
									0.001,
									0.0,
									0.001,
									0.008,
									0.001,
									0.001,
									0.003,
									0.001,
									0.0,
									0.001,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.017,
									0.002,
									0.002,
									0.003,
									0.016,
									0.001,
									0.001,
									0.002,
									1.0,
									0.002,
									0.005,
									0.004
								],
								"e": 0.017
							}
						],
						[
							{
								"h": [
									0.19,
									0.0,
									0.148,
									0.0,
									0.0,
									0.0,
									0.086,
									0.017,
									0.348,
									0.248,
									0.175,
									0.432
								],
								"e": 0.043
							},
							{
								"h": [
									0.036,
									0.031,
									0.21,
									0.017,
									0.127,
									0.325,
									0.244,
									0.347,
									0.351,
									0.184,
									0.185,
									0.006
								],
								"e": 0.178
							},
							{
								"h": [
									0.012,
									0.106,
									0.0,
									0.001,
									0.001,
									0.001,
									0.051,
									0.27,
									0.003,
									0.0,
									0.049,
									0.008
								],
								"e": 0.044
							},
							{
								"h": [
									0.002,
									0.003,
									0.0,
									0.0,
									0.036,
									0.006,
									0.0,
									0.0,
									0.0,
									0.001,
									0.005,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.001,
									0.037,
									0.0,
									0.032,
									0.0,
									0.004,
									0.006,
									0.026,
									0.003,
									0.014,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.004,
									0.0,
									0.005,
									0.015,
									0.001,
									0.0,
									0.002,
									0.0,
									0.0,
									0.02,
									0.215,
									0.069
								],
								"e": 0.016
							},
							{
								"h": [
									0.012,
									0.0,
									0.034,
									0.004,
									0.012,
									0.004,
									0.008,
									0.002,
									0.0,
									0.0,
									0.01,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.038,
									0.002,
									0.003,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.016,
									0.001,
									0.002,
									0.001,
									0.0,
									0.005,
									0.007,
									0.002,
									0.001,
									0.001,
									0.002,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.004,
									0.001,
									0.008,
									0.0,
									0.01,
									0.003,
									0.0,
									0.003,
									0.009
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.001,
									0.001,
									0.004,
									0.007,
									0.002,
									0.001,
									0.004,
									0.008,
									0.0,
									0.001,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.007,
									0.001,
									0.002,
									0.003,
									0.028,
									0.004,
									0.002,
									0.001,
									0.309,
									0.001,
									0.006,
									0.004
								],
								"e": 0.009
							}
						],
						[
							{
								"h": [
									1.0,
									0.002,
									0.634,
									0.0,
									0.0,
									0.0,
									0.505,
									0.005,
									0.872,
									1.0,
									0.355,
									0.871
								],
								"e": 0.142
							},
							{
								"h": [
									0.025,
									0.033,
									0.313,
									0.025,
									0.192,
									0.269,
									0.19,
									0.65,
									0.389,
									0.212,
									0.325,
									0.0
								],
								"e": 0.214
							},
							{
								"h": [
									0.015,
									0.192,
									0.0,
									0.004,
									0.0,
									0.001,
									0.049,
									0.452,
									0.004,
									0.004,
									0.168,
									0.021
								],
								"e": 0.081
							},
							{
								"h": [
									0.003,
									0.002,
									0.0,
									0.0,
									0.245,
									0.008,
									0.001,
									0.0,
									0.0,
									0.002,
									0.023,
									0.003
								],
								"e": 0.03
							},
							{
								"h": [
									0.0,
									0.001,
									0.008,
									0.006,
									0.005,
									0.001,
									0.007,
									0.117,
									0.086,
									0.002,
									0.025,
									0.0
								],
								"e": 0.023
							},
							{
								"h": [
									0.022,
									0.0,
									0.001,
									0.004,
									0.001,
									0.001,
									0.003,
									0.001,
									0.002,
									0.124,
									0.036,
									0.044
								],
								"e": 0.017
							},
							{
								"h": [
									0.003,
									0.002,
									0.019,
									0.005,
									0.039,
									0.006,
									0.026,
									0.016,
									0.0,
									0.0,
									0.087,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.006,
									0.001,
									0.001,
									0.0,
									0.027,
									0.055,
									0.053,
									0.0,
									0.0,
									0.001,
									0.032
								],
								"e": 0.012
							},
							{
								"h": [
									0.031,
									0.002,
									0.014,
									0.001,
									0.001,
									0.002,
									0.084,
									0.001,
									0.006,
									0.013,
									0.011,
									0.014
								],
								"e": 0.014
							},
							{
								"h": [
									0.019,
									0.002,
									0.008,
									0.002,
									0.003,
									0.012,
									0.001,
									0.006,
									0.131,
									0.001,
									0.001,
									0.011
								],
								"e": 0.012
							},
							{
								"h": [
									0.026,
									0.002,
									0.002,
									0.012,
									0.028,
									0.001,
									0.013,
									0.023,
									0.003,
									0.0,
									0.004,
									0.007
								],
								"e": 0.009
							},
							{
								"h": [
									0.014,
									0.004,
									0.01,
									0.006,
									0.015,
									0.003,
									0.007,
									0.001,
									0.516,
									0.006,
									0.015,
									0.017
								],
								"e": 0.015
							}
						],
						[
							{
								"h": [
									0.192,
									0.0,
									0.164,
									0.0,
									0.0,
									0.0,
									0.121,
									0.024,
									0.269,
									0.251,
									0.163,
									0.832
								],
								"e": 0.051
							},
							{
								"h": [
									0.063,
									0.063,
									0.28,
									0.024,
									0.154,
									0.235,
									0.274,
									0.295,
									0.385,
									0.143,
									0.252,
									0.007
								],
								"e": 0.187
							},
							{
								"h": [
									0.021,
									0.11,
									0.0,
									0.002,
									0.004,
									0.002,
									0.204,
									0.235,
									0.003,
									0.001,
									0.069,
									0.014
								],
								"e": 0.067
							},
							{
								"h": [
									0.004,
									0.014,
									0.0,
									0.0,
									0.019,
									0.012,
									0.001,
									0.0,
									0.001,
									0.002,
									0.016,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.013,
									0.042,
									0.0,
									0.02,
									0.002,
									0.009,
									0.017,
									0.017,
									0.003,
									0.012,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.352,
									0.0,
									0.005,
									0.018,
									0.007,
									0.0,
									0.001,
									0.001,
									0.003,
									0.017,
									0.033,
									0.064
								],
								"e": 0.026
							},
							{
								"h": [
									0.01,
									0.002,
									0.022,
									0.008,
									0.016,
									0.009,
									0.02,
									0.005,
									0.001,
									0.0,
									0.095,
									0.004
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.003,
									0.0,
									0.003,
									0.001,
									0.019,
									0.007,
									0.041,
									0.0,
									0.002,
									0.0,
									0.008
								],
								"e": 0.005
							},
							{
								"h": [
									0.027,
									0.008,
									0.001,
									0.004,
									0.001,
									0.005,
									0.007,
									0.003,
									0.003,
									0.004,
									0.011,
									0.005
								],
								"e": 0.006
							},
							{
								"h": [
									0.002,
									0.002,
									0.001,
									0.005,
									0.002,
									0.002,
									0.001,
									0.012,
									0.007,
									0.001,
									0.001,
									0.071
								],
								"e": 0.008
							},
							{
								"h": [
									0.005,
									0.002,
									0.003,
									0.012,
									0.042,
									0.004,
									0.001,
									0.004,
									0.009,
									0.0,
									0.007,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.004,
									0.001,
									0.004,
									0.003,
									0.016,
									0.003,
									0.003,
									0.001,
									0.254,
									0.002,
									0.006,
									0.005
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.189,
									0.0,
									0.301,
									0.0,
									0.001,
									0.0,
									0.391,
									0.006,
									0.365,
									0.327,
									0.065,
									0.306
								],
								"e": 0.053
							},
							{
								"h": [
									0.043,
									0.06,
									0.408,
									0.027,
									0.192,
									0.083,
									0.052,
									0.196,
									0.248,
									0.166,
									0.356,
									0.0
								],
								"e": 0.154
							},
							{
								"h": [
									0.03,
									0.012,
									0.0,
									0.011,
									0.002,
									0.001,
									0.037,
									0.48,
									0.006,
									0.006,
									0.138,
									0.019
								],
								"e": 0.05
							},
							{
								"h": [
									0.0,
									0.016,
									0.0,
									0.0,
									0.01,
									0.009,
									0.002,
									0.001,
									0.0,
									0.002,
									0.018,
									0.013
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.002,
									0.029,
									0.008,
									0.016,
									0.007,
									0.008,
									0.067,
									0.016,
									0.018,
									0.003,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.003,
									0.0,
									0.001,
									0.012,
									0.003,
									0.002,
									0.005,
									0.022,
									0.002,
									0.019,
									0.292,
									0.012
								],
								"e": 0.017
							},
							{
								"h": [
									0.034,
									0.001,
									0.019,
									0.007,
									0.015,
									0.014,
									0.018,
									0.009,
									0.001,
									0.0,
									0.009,
									0.016
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.003,
									0.0,
									0.001,
									0.001,
									0.086,
									0.003,
									0.011,
									0.0,
									0.001,
									0.0,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.007,
									0.0,
									0.002,
									0.002,
									0.002,
									0.005,
									0.001,
									0.002,
									0.006,
									0.001,
									0.003,
									0.005
								],
								"e": 0.003
							},
							{
								"h": [
									0.038,
									0.0,
									0.013,
									0.003,
									0.001,
									0.004,
									0.002,
									0.004,
									0.051,
									0.002,
									0.002,
									0.009
								],
								"e": 0.009
							},
							{
								"h": [
									0.043,
									0.004,
									0.001,
									0.005,
									0.017,
									0.002,
									0.012,
									0.003,
									0.001,
									0.0,
									0.003,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.011,
									0.004,
									0.004,
									0.002,
									0.012,
									0.002,
									0.004,
									0.003,
									0.351,
									0.004,
									0.004,
									0.002
								],
								"e": 0.009
							}
						],
						[
							{
								"h": [
									0.193,
									0.0,
									0.142,
									0.0,
									0.001,
									0.0,
									0.082,
									0.037,
									0.368,
									0.255,
									0.18,
									0.445
								],
								"e": 0.046
							},
							{
								"h": [
									0.077,
									0.088,
									0.31,
									0.025,
									0.153,
									0.312,
									0.221,
									0.383,
									0.351,
									0.151,
									0.278,
									0.005
								],
								"e": 0.201
							},
							{
								"h": [
									0.032,
									0.118,
									0.0,
									0.004,
									0.003,
									0.004,
									0.093,
									0.392,
									0.009,
									0.001,
									0.091,
									0.015
								],
								"e": 0.066
							},
							{
								"h": [
									0.001,
									0.027,
									0.001,
									0.0,
									0.03,
									0.007,
									0.001,
									0.0,
									0.002,
									0.004,
									0.016,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.009,
									0.053,
									0.002,
									0.022,
									0.003,
									0.021,
									0.013,
									0.02,
									0.013,
									0.01,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.0,
									0.013,
									0.047,
									0.002,
									0.0,
									0.025,
									0.001,
									0.001,
									0.012,
									0.221,
									0.088
								],
								"e": 0.022
							},
							{
								"h": [
									0.03,
									0.0,
									0.026,
									0.006,
									0.031,
									0.009,
									0.012,
									0.006,
									0.007,
									0.0,
									0.005,
									0.011
								],
								"e": 0.011
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.002,
									0.001,
									0.023,
									0.005,
									0.002,
									0.0,
									0.002,
									0.0,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.02,
									0.0,
									0.001,
									0.005,
									0.001,
									0.014,
									0.005,
									0.004,
									0.001,
									0.001,
									0.007,
									0.006
								],
								"e": 0.005
							},
							{
								"h": [
									0.003,
									0.002,
									0.001,
									0.01,
									0.002,
									0.01,
									0.0,
									0.008,
									0.007,
									0.0,
									0.007,
									0.004
								],
								"e": 0.004
							},
							{
								"h": [
									0.006,
									0.001,
									0.001,
									0.003,
									0.01,
									0.005,
									0.001,
									0.002,
									0.003,
									0.0,
									0.002,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.008,
									0.001,
									0.001,
									0.004,
									0.019,
									0.003,
									0.002,
									0.001,
									0.25,
									0.001,
									0.005,
									0.004
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.373,
									0.001,
									0.601,
									0.0,
									0.004,
									0.0,
									0.256,
									0.008,
									1.0,
									0.814,
									0.214,
									0.171
								],
								"e": 0.094
							},
							{
								"h": [
									0.056,
									0.072,
									0.372,
									0.029,
									0.218,
									0.293,
									0.785,
									0.621,
									0.225,
									0.158,
									0.415,
									0.001
								],
								"e": 0.266
							},
							{
								"h": [
									0.034,
									0.15,
									0.0,
									0.019,
									0.002,
									0.004,
									0.046,
									0.501,
									0.007,
									0.02,
									0.21,
									0.027
								],
								"e": 0.089
							},
							{
								"h": [
									0.0,
									0.005,
									0.001,
									0.0,
									0.094,
									0.007,
									0.006,
									0.003,
									0.001,
									0.003,
									0.042,
									0.009
								],
								"e": 0.017
							},
							{
								"h": [
									0.001,
									0.002,
									0.01,
									0.011,
									0.014,
									0.002,
									0.011,
									0.137,
									0.033,
									0.013,
									0.027,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.009,
									0.0,
									0.001,
									0.007,
									0.002,
									0.0,
									0.005,
									0.008,
									0.001,
									0.076,
									0.113,
									0.038
								],
								"e": 0.016
							},
							{
								"h": [
									0.007,
									0.002,
									0.018,
									0.005,
									0.035,
									0.008,
									0.023,
									0.025,
									0.001,
									0.0,
									0.057,
									0.002
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.01,
									0.0,
									0.001,
									0.0,
									0.06,
									0.025,
									0.023,
									0.001,
									0.001,
									0.0,
									0.007
								],
								"e": 0.008
							},
							{
								"h": [
									0.012,
									0.002,
									0.005,
									0.0,
									0.002,
									0.003,
									0.047,
									0.002,
									0.009,
									0.01,
									0.012,
									0.02
								],
								"e": 0.01
							},
							{
								"h": [
									0.107,
									0.002,
									0.018,
									0.004,
									0.003,
									0.023,
									0.003,
									0.009,
									0.162,
									0.001,
									0.001,
									0.008
								],
								"e": 0.022
							},
							{
								"h": [
									0.048,
									0.001,
									0.002,
									0.005,
									0.045,
									0.002,
									0.009,
									0.018,
									0.003,
									0.0,
									0.002,
									0.008
								],
								"e": 0.01
							},
							{
								"h": [
									0.021,
									0.003,
									0.01,
									0.006,
									0.022,
									0.002,
									0.005,
									0.002,
									0.615,
									0.001,
									0.011,
									0.008
								],
								"e": 0.015
							}
						],
						[
							{
								"h": [
									0.2,
									0.0,
									0.16,
									0.0,
									0.001,
									0.0,
									0.124,
									0.07,
									0.294,
									0.267,
									0.169,
									0.883
								],
								"e": 0.057
							},
							{
								"h": [
									0.139,
									0.186,
									0.39,
									0.033,
									0.187,
									0.193,
									0.259,
									0.325,
									0.347,
									0.11,
									0.364,
									0.006
								],
								"e": 0.22
							},
							{
								"h": [
									0.054,
									0.099,
									0.0,
									0.011,
									0.017,
									0.015,
									0.336,
									0.337,
									0.014,
									0.004,
									0.127,
									0.026
								],
								"e": 0.105
							},
							{
								"h": [
									0.003,
									0.083,
									0.005,
									0.001,
									0.016,
									0.016,
									0.005,
									0.001,
									0.011,
									0.01,
									0.039,
									0.014
								],
								"e": 0.017
							},
							{
								"h": [
									0.002,
									0.073,
									0.064,
									0.002,
									0.023,
									0.015,
									0.034,
									0.042,
									0.013,
									0.008,
									0.015,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.506,
									0.0,
									0.028,
									0.057,
									0.024,
									0.001,
									0.005,
									0.003,
									0.032,
									0.016,
									0.036,
									0.05
								],
								"e": 0.042
							},
							{
								"h": [
									0.052,
									0.006,
									0.025,
									0.021,
									0.02,
									0.019,
									0.016,
									0.015,
									0.005,
									0.001,
									0.061,
									0.022
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.005,
									0.002,
									0.006,
									0.001,
									0.047,
									0.007,
									0.077,
									0.0,
									0.012,
									0.001,
									0.033
								],
								"e": 0.013
							},
							{
								"h": [
									0.055,
									0.112,
									0.0,
									0.007,
									0.002,
									0.01,
									0.014,
									0.01,
									0.007,
									0.003,
									0.012,
									0.011
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.002,
									0.002,
									0.015,
									0.002,
									0.002,
									0.001,
									0.039,
									0.004,
									0.001,
									0.004,
									0.074
								],
								"e": 0.011
							},
							{
								"h": [
									0.004,
									0.001,
									0.003,
									0.006,
									0.038,
									0.005,
									0.001,
									0.003,
									0.01,
									0.001,
									0.004,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.003,
									0.001,
									0.004,
									0.004,
									0.012,
									0.002,
									0.002,
									0.001,
									0.19,
									0.002,
									0.004,
									0.002
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.13,
									0.0,
									0.19,
									0.0,
									0.002,
									0.0,
									0.449,
									0.024,
									0.205,
									0.217,
									0.115,
									0.349
								],
								"e": 0.048
							},
							{
								"h": [
									0.07,
									0.128,
									0.512,
									0.032,
									0.258,
									0.04,
									0.047,
									0.178,
									0.176,
									0.144,
									0.436,
									0.0
								],
								"e": 0.17
							},
							{
								"h": [
									0.227,
									0.015,
									0.001,
									0.094,
									0.007,
									0.005,
									0.06,
									0.504,
									0.028,
									0.02,
									0.171,
									0.026
								],
								"e": 0.097
							},
							{
								"h": [
									0.0,
									0.026,
									0.006,
									0.001,
									0.007,
									0.005,
									0.011,
									0.002,
									0.002,
									0.008,
									0.033,
									0.011
								],
								"e": 0.01
							},
							{
								"h": [
									0.002,
									0.019,
									0.015,
									0.016,
									0.002,
									0.013,
									0.014,
									0.099,
									0.005,
									0.041,
									0.001,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.002,
									0.0,
									0.003,
									0.026,
									0.007,
									0.002,
									0.001,
									0.034,
									0.008,
									0.012,
									0.025,
									0.008
								],
								"e": 0.009
							},
							{
								"h": [
									0.031,
									0.0,
									0.011,
									0.017,
									0.007,
									0.018,
									0.009,
									0.011,
									0.001,
									0.0,
									0.005,
									0.034
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.002,
									0.0,
									0.002,
									0.003,
									0.04,
									0.003,
									0.019,
									0.001,
									0.003,
									0.0,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.009,
									0.001,
									0.001,
									0.003,
									0.003,
									0.006,
									0.0,
									0.001,
									0.004,
									0.0,
									0.004,
									0.005
								],
								"e": 0.003
							},
							{
								"h": [
									0.021,
									0.0,
									0.005,
									0.003,
									0.0,
									0.002,
									0.0,
									0.003,
									0.009,
									0.0,
									0.001,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.009,
									0.001,
									0.0,
									0.001,
									0.005,
									0.001,
									0.003,
									0.002,
									0.0,
									0.001,
									0.001,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.018,
									0.002,
									0.001,
									0.0,
									0.005,
									0.0,
									0.002,
									0.001,
									0.078,
									0.001,
									0.003,
									0.0
								],
								"e": 0.004
							}
						],
						[
							{
								"h": [
									0.089,
									0.0,
									0.226,
									0.0,
									0.001,
									0.0,
									0.101,
									0.061,
									0.248,
									0.271,
									0.103,
									0.241
								],
								"e": 0.039
							},
							{
								"h": [
									0.079,
									0.065,
									0.344,
									0.025,
									0.194,
									0.141,
									0.161,
									0.363,
									0.161,
									0.12,
									0.37,
									0.0
								],
								"e": 0.167
							},
							{
								"h": [
									0.021,
									0.014,
									0.0,
									0.011,
									0.009,
									0.006,
									0.035,
									0.372,
									0.03,
									0.03,
									0.175,
									0.028
								],
								"e": 0.06
							},
							{
								"h": [
									0.0,
									0.014,
									0.008,
									0.003,
									0.006,
									0.005,
									0.004,
									0.011,
									0.003,
									0.004,
									0.022,
									0.035
								],
								"e": 0.01
							},
							{
								"h": [
									0.003,
									0.017,
									0.01,
									0.041,
									0.013,
									0.017,
									0.023,
									0.013,
									0.006,
									0.019,
									0.002,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.0,
									0.007,
									0.011,
									0.011,
									0.0,
									0.004,
									0.029,
									0.001,
									0.007,
									0.035,
									0.012
								],
								"e": 0.008
							},
							{
								"h": [
									0.037,
									0.0,
									0.011,
									0.009,
									0.003,
									0.023,
									0.008,
									0.004,
									0.007,
									0.0,
									0.003,
									0.074
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.001,
									0.001,
									0.067,
									0.001,
									0.002,
									0.0,
									0.001,
									0.0,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.008,
									0.0,
									0.0,
									0.001,
									0.007,
									0.01,
									0.0,
									0.01,
									0.002,
									0.0,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.004,
									0.0,
									0.006,
									0.008,
									0.0,
									0.003,
									0.0,
									0.004,
									0.011,
									0.0,
									0.007,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.0,
									0.0,
									0.0,
									0.003,
									0.002,
									0.0,
									0.0,
									0.0,
									0.001,
									0.0,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.005,
									0.0,
									0.0,
									0.002,
									0.005,
									0.0,
									0.0,
									0.0,
									0.108,
									0.001,
									0.002,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.215,
									0.0,
									0.143,
									0.0,
									0.002,
									0.0,
									0.086,
									0.173,
									0.413,
									0.282,
									0.189,
									0.463
								],
								"e": 0.06
							},
							{
								"h": [
									0.166,
									0.254,
									0.46,
									0.036,
									0.199,
									0.333,
									0.294,
									0.395,
									0.31,
									0.124,
									0.409,
									0.004
								],
								"e": 0.258
							},
							{
								"h": [
									0.096,
									0.109,
									0.005,
									0.037,
									0.024,
									0.05,
									0.149,
									0.559,
									0.082,
									0.006,
									0.18,
									0.033
								],
								"e": 0.128
							},
							{
								"h": [
									0.001,
									0.057,
									0.018,
									0.004,
									0.016,
									0.005,
									0.005,
									0.002,
									0.028,
									0.012,
									0.03,
									0.014
								],
								"e": 0.016
							},
							{
								"h": [
									0.003,
									0.06,
									0.031,
									0.005,
									0.017,
									0.009,
									0.044,
									0.021,
									0.008,
									0.018,
									0.006,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.0,
									0.034,
									0.066,
									0.009,
									0.0,
									0.012,
									0.001,
									0.001,
									0.013,
									0.053,
									0.067
								],
								"e": 0.016
							},
							{
								"h": [
									0.09,
									0.001,
									0.035,
									0.009,
									0.014,
									0.021,
									0.014,
									0.008,
									0.025,
									0.0,
									0.003,
									0.032
								],
								"e": 0.017
							},
							{
								"h": [
									0.01,
									0.0,
									0.0,
									0.004,
									0.002,
									0.012,
									0.005,
									0.001,
									0.0,
									0.009,
									0.0,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.01,
									0.0,
									0.001,
									0.01,
									0.002,
									0.032,
									0.002,
									0.021,
									0.002,
									0.001,
									0.009,
									0.008
								],
								"e": 0.007
							},
							{
								"h": [
									0.003,
									0.002,
									0.001,
									0.024,
									0.001,
									0.009,
									0.0,
									0.009,
									0.01,
									0.0,
									0.016,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.008,
									0.0,
									0.0,
									0.002,
									0.005,
									0.006,
									0.001,
									0.002,
									0.002,
									0.001,
									0.002,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.008,
									0.002,
									0.001,
									0.003,
									0.023,
									0.002,
									0.002,
									0.001,
									0.224,
									0.001,
									0.005,
									0.004
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.173,
									0.0,
									0.276,
									0.0,
									0.01,
									0.0,
									0.089,
									0.23,
									0.498,
									0.363,
									0.248,
									0.241
								],
								"e": 0.072
							},
							{
								"h": [
									1.0,
									0.4,
									0.479,
									0.045,
									0.243,
									0.237,
									0.706,
									0.558,
									0.35,
									0.187,
									0.581,
									0.001
								],
								"e": 0.441
							},
							{
								"h": [
									0.232,
									0.025,
									0.028,
									1.0,
									0.116,
									0.049,
									0.149,
									0.57,
									0.145,
									0.044,
									0.264,
									0.051
								],
								"e": 0.296
							},
							{
								"h": [
									0.001,
									0.036,
									0.074,
									0.002,
									0.014,
									0.021,
									0.01,
									0.008,
									0.002,
									0.023,
									0.063,
									0.039
								],
								"e": 0.026
							},
							{
								"h": [
									0.011,
									0.02,
									0.013,
									0.012,
									0.006,
									0.013,
									0.061,
									0.178,
									0.014,
									0.018,
									0.013,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.002,
									0.0,
									0.006,
									0.024,
									0.03,
									0.0,
									0.001,
									0.001,
									0.002,
									0.025,
									0.039,
									0.087
								],
								"e": 0.015
							},
							{
								"h": [
									0.02,
									0.008,
									0.04,
									0.01,
									0.014,
									0.016,
									0.007,
									0.035,
									0.005,
									0.0,
									0.023,
									0.021
								],
								"e": 0.015
							},
							{
								"h": [
									0.004,
									0.001,
									0.0,
									0.006,
									0.001,
									0.018,
									0.011,
									0.005,
									0.002,
									0.003,
									0.0,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.009,
									0.003,
									0.003,
									0.001,
									0.005,
									0.035,
									0.031,
									0.01,
									0.003,
									0.004,
									0.007,
									0.014
								],
								"e": 0.009
							},
							{
								"h": [
									0.008,
									0.004,
									0.004,
									0.008,
									0.001,
									0.009,
									0.002,
									0.028,
									0.009,
									0.0,
									0.01,
									0.011
								],
								"e": 0.007
							},
							{
								"h": [
									0.006,
									0.0,
									0.0,
									0.002,
									0.01,
									0.005,
									0.001,
									0.002,
									0.005,
									0.0,
									0.004,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.003,
									0.001,
									0.003,
									0.01,
									0.003,
									0.004,
									0.001,
									0.321,
									0.0,
									0.003,
									0.002
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.512,
									0.002,
									0.371,
									0.004,
									0.01,
									0.0,
									0.483,
									0.091,
									0.592,
									0.453,
									0.195,
									0.29
								],
								"e": 0.091
							},
							{
								"h": [
									0.238,
									0.365,
									0.589,
									0.046,
									0.351,
									0.252,
									0.313,
									0.453,
									0.214,
									0.129,
									0.742,
									0.001
								],
								"e": 0.316
							},
							{
								"h": [
									0.183,
									0.037,
									0.028,
									0.118,
									0.034,
									0.077,
									0.134,
									0.822,
									0.178,
									0.368,
									0.565,
									0.073
								],
								"e": 0.278
							},
							{
								"h": [
									0.001,
									0.034,
									0.012,
									0.01,
									0.115,
									0.01,
									0.058,
									0.069,
									0.017,
									0.012,
									0.109,
									0.128
								],
								"e": 0.053
							},
							{
								"h": [
									0.01,
									0.045,
									0.011,
									0.059,
									0.01,
									0.014,
									0.072,
									0.108,
									0.031,
									0.017,
									0.054,
									0.0
								],
								"e": 0.037
							},
							{
								"h": [
									0.057,
									0.0,
									0.007,
									0.028,
									0.015,
									0.0,
									0.002,
									0.04,
									0.006,
									0.022,
									0.069,
									0.027
								],
								"e": 0.017
							},
							{
								"h": [
									0.024,
									0.004,
									0.01,
									0.018,
									0.052,
									0.029,
									0.025,
									0.146,
									0.01,
									0.0,
									0.167,
									0.02
								],
								"e": 0.038
							},
							{
								"h": [
									0.004,
									0.025,
									0.0,
									0.007,
									0.001,
									0.153,
									0.039,
									0.023,
									0.007,
									0.006,
									0.0,
									0.003
								],
								"e": 0.016
							},
							{
								"h": [
									0.011,
									0.001,
									0.005,
									0.002,
									0.009,
									0.009,
									0.034,
									0.012,
									0.026,
									0.016,
									0.013,
									0.058
								],
								"e": 0.016
							},
							{
								"h": [
									0.123,
									0.002,
									0.029,
									0.013,
									0.001,
									0.016,
									0.002,
									0.011,
									0.111,
									0.001,
									0.006,
									0.004
								],
								"e": 0.021
							},
							{
								"h": [
									0.051,
									0.001,
									0.001,
									0.003,
									0.034,
									0.006,
									0.008,
									0.011,
									0.002,
									0.001,
									0.003,
									0.003
								],
								"e": 0.009
							},
							{
								"h": [
									0.015,
									0.002,
									0.008,
									0.009,
									0.013,
									0.001,
									0.006,
									0.001,
									0.554,
									0.002,
									0.021,
									0.014
								],
								"e": 0.015
							}
						],
						[
							{
								"h": [
									0.239,
									0.0,
									0.167,
									0.0,
									0.004,
									0.0,
									0.135,
									0.571,
									0.344,
									0.309,
									0.18,
									0.974
								],
								"e": 0.102
							},
							{
								"h": [
									0.577,
									0.942,
									0.721,
									0.059,
									0.287,
									0.18,
									0.272,
									0.336,
									0.322,
									0.086,
									0.666,
									0.006
								],
								"e": 0.412
							},
							{
								"h": [
									0.323,
									0.078,
									0.187,
									0.182,
									0.296,
									0.521,
									1.0,
									0.645,
									0.279,
									0.115,
									0.372,
									0.076
								],
								"e": 0.539
							},
							{
								"h": [
									0.002,
									0.781,
									0.247,
									0.148,
									0.014,
									0.02,
									0.131,
									0.062,
									0.405,
									0.105,
									0.151,
									0.202
								],
								"e": 0.185
							},
							{
								"h": [
									0.026,
									0.738,
									0.08,
									0.011,
									0.027,
									0.087,
									0.136,
									0.077,
									0.011,
									0.031,
									0.011,
									0.0
								],
								"e": 0.085
							},
							{
								"h": [
									0.551,
									0.001,
									0.398,
									0.19,
									0.099,
									0.01,
									0.011,
									0.006,
									0.236,
									0.011,
									0.021,
									0.046
								],
								"e": 0.107
							},
							{
								"h": [
									0.194,
									0.01,
									0.033,
									0.034,
									0.022,
									0.048,
									0.017,
									0.028,
									0.041,
									0.007,
									0.055,
									0.102
								],
								"e": 0.04
							},
							{
								"h": [
									0.024,
									0.004,
									0.001,
									0.02,
									0.002,
									0.054,
									0.01,
									0.071,
									0.001,
									0.033,
									0.001,
									0.015
								],
								"e": 0.017
							},
							{
								"h": [
									0.035,
									0.104,
									0.001,
									0.01,
									0.01,
									0.028,
									0.011,
									0.046,
									0.015,
									0.004,
									0.023,
									0.025
								],
								"e": 0.025
							},
							{
								"h": [
									0.003,
									0.003,
									0.003,
									0.048,
									0.002,
									0.002,
									0.001,
									0.072,
									0.006,
									0.001,
									0.013,
									0.062
								],
								"e": 0.017
							},
							{
								"h": [
									0.005,
									0.001,
									0.003,
									0.008,
									0.039,
									0.012,
									0.001,
									0.004,
									0.008,
									0.002,
									0.005,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.003,
									0.001,
									0.004,
									0.006,
									0.013,
									0.002,
									0.002,
									0.001,
									0.176,
									0.002,
									0.005,
									0.002
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.228,
									0.0,
									0.313,
									0.001,
									0.008,
									0.0,
									0.442,
									0.114,
									0.453,
									0.397,
									0.067,
									0.334
								],
								"e": 0.07
							},
							{
								"h": [
									0.26,
									0.484,
									0.822,
									0.051,
									0.357,
									0.055,
									0.04,
									0.179,
									0.215,
									0.138,
									0.729,
									0.0
								],
								"e": 0.291
							},
							{
								"h": [
									0.407,
									0.01,
									0.308,
									0.858,
									0.15,
									0.144,
									0.163,
									0.978,
									0.467,
									1.0,
									0.586,
									0.096
								],
								"e": 0.658
							},
							{
								"h": [
									0.0,
									0.584,
									0.356,
									0.041,
									0.006,
									0.015,
									0.213,
									1.0,
									0.085,
									0.076,
									0.146,
									1.0
								],
								"e": 0.304
							},
							{
								"h": [
									0.035,
									0.297,
									0.049,
									0.258,
									0.013,
									0.369,
									0.13,
									0.263,
									0.006,
									0.249,
									0.002,
									0.0
								],
								"e": 0.135
							},
							{
								"h": [
									0.001,
									0.0,
									0.031,
									0.172,
									0.055,
									0.008,
									0.047,
									0.185,
									0.034,
									0.011,
									0.134,
									0.012
								],
								"e": 0.049
							},
							{
								"h": [
									0.3,
									0.002,
									0.021,
									0.029,
									0.01,
									0.06,
									0.009,
									0.021,
									0.062,
									0.0,
									0.004,
									0.22
								],
								"e": 0.047
							},
							{
								"h": [
									0.017,
									0.002,
									0.0,
									0.01,
									0.006,
									0.07,
									0.003,
									0.005,
									0.002,
									0.013,
									0.0,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.006,
									0.0,
									0.001,
									0.004,
									0.032,
									0.043,
									0.001,
									0.057,
									0.012,
									0.001,
									0.01,
									0.011
								],
								"e": 0.014
							},
							{
								"h": [
									0.022,
									0.0,
									0.013,
									0.042,
									0.001,
									0.004,
									0.001,
									0.009,
									0.022,
									0.001,
									0.014,
									0.004
								],
								"e": 0.01
							},
							{
								"h": [
									0.028,
									0.002,
									0.001,
									0.002,
									0.013,
									0.01,
									0.007,
									0.001,
									0.001,
									0.007,
									0.003,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.009,
									0.004,
									0.002,
									0.002,
									0.006,
									0.001,
									0.002,
									0.001,
									0.106,
									0.002,
									0.004,
									0.0
								],
								"e": 0.004
							}
						],
						[
							{
								"h": [
									0.238,
									0.0,
									0.15,
									0.001,
									0.009,
									0.0,
									0.095,
									1.0,
									0.472,
									0.31,
									0.192,
									0.483
								],
								"e": 0.13
							},
							{
								"h": [
									0.655,
									1.0,
									0.719,
									0.055,
									0.28,
									0.264,
									0.232,
									0.41,
									0.294,
									0.103,
									0.645,
									0.004
								],
								"e": 0.432
							},
							{
								"h": [
									0.496,
									0.106,
									1.0,
									0.501,
									0.3,
									1.0,
									0.407,
									0.917,
									1.0,
									0.128,
									0.462,
									0.09
								],
								"e": 0.948
							},
							{
								"h": [
									0.0,
									0.825,
									1.0,
									0.362,
									0.012,
									0.012,
									0.124,
									0.16,
									1.0,
									0.132,
									0.117,
									0.208
								],
								"e": 0.339
							},
							{
								"h": [
									0.132,
									1.0,
									0.069,
									0.102,
									0.021,
									0.282,
									0.425,
									0.063,
									0.013,
									0.16,
									0.007,
									1.0
								],
								"e": 0.337
							},
							{
								"h": [
									0.001,
									0.0,
									0.167,
									0.502,
									0.079,
									0.0,
									0.252,
									0.006,
									0.003,
									0.01,
									0.116,
									0.108
								],
								"e": 0.086
							},
							{
								"h": [
									0.559,
									0.003,
									0.048,
									0.026,
									0.027,
									0.081,
									0.013,
									0.032,
									0.375,
									0.0,
									0.002,
									0.255
								],
								"e": 0.092
							},
							{
								"h": [
									0.184,
									0.0,
									0.0,
									0.017,
									0.005,
									0.034,
									0.005,
									0.001,
									0.001,
									0.058,
									0.0,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.018,
									0.001,
									0.001,
									0.015,
									0.013,
									0.201,
									0.003,
									0.25,
									0.007,
									0.002,
									0.031,
									0.028
								],
								"e": 0.042
							},
							{
								"h": [
									0.006,
									0.005,
									0.003,
									0.153,
									0.002,
									0.013,
									0.0,
									0.03,
									0.01,
									0.001,
									0.073,
									0.003
								],
								"e": 0.023
							},
							{
								"h": [
									0.014,
									0.001,
									0.0,
									0.001,
									0.008,
									0.017,
									0.001,
									0.002,
									0.001,
									0.004,
									0.002,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.009,
									0.002,
									0.001,
									0.005,
									0.026,
									0.002,
									0.002,
									0.001,
									0.202,
									0.001,
									0.006,
									0.005
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.426,
									1.0,
									0.618,
									1.0,
									1.0,
									1.0,
									0.573,
									0.249,
									0.496,
									0.007,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.934,
									0.828,
									1.0,
									0.07,
									0.533,
									0.104,
									0.223,
									0.372,
									0.127,
									0.041,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.959,
									0.044,
									0.205,
									0.583,
									0.197,
									0.209,
									0.19,
									0.96,
									0.223,
									0.618,
									1.0,
									0.138
								],
								"e": 0.632
							},
							{
								"h": [
									0.05,
									0.146,
									0.412,
									0.086,
									0.001,
									0.01,
									1.0,
									0.189,
									0.135,
									0.149,
									0.259,
									0.21
								],
								"e": 0.24
							},
							{
								"h": [
									0.11,
									0.195,
									0.017,
									0.231,
									0.032,
									0.053,
									0.072,
									1.0,
									0.007,
									0.08,
									0.018,
									0.0
								],
								"e": 0.132
							},
							{
								"h": [
									0.01,
									0.0,
									0.012,
									0.111,
									0.072,
									0.001,
									0.021,
									0.108,
									0.013,
									0.006,
									0.315,
									0.031
								],
								"e": 0.042
							},
							{
								"h": [
									0.144,
									0.025,
									0.022,
									0.015,
									0.036,
									0.051,
									0.064,
									0.102,
									0.008,
									0.0,
									0.055,
									0.071
								],
								"e": 0.043
							},
							{
								"h": [
									0.009,
									0.036,
									0.0,
									0.023,
									0.005,
									0.117,
									0.03,
									0.019,
									0.02,
									0.011,
									0.0,
									0.004
								],
								"e": 0.019
							},
							{
								"h": [
									0.005,
									0.001,
									0.004,
									0.001,
									0.011,
									0.068,
									0.028,
									0.042,
									0.038,
									0.012,
									0.031,
									0.04
								],
								"e": 0.022
							},
							{
								"h": [
									0.08,
									0.006,
									0.027,
									0.053,
									0.005,
									0.019,
									0.004,
									0.008,
									0.148,
									0.012,
									0.024,
									0.003
								],
								"e": 0.027
							},
							{
								"h": [
									0.057,
									0.001,
									0.003,
									0.004,
									0.051,
									0.011,
									0.006,
									0.023,
									0.003,
									0.006,
									0.002,
									0.003
								],
								"e": 0.012
							},
							{
								"h": [
									0.039,
									0.006,
									0.002,
									0.023,
									0.013,
									0.002,
									0.005,
									0.001,
									0.806,
									0.003,
									0.016,
									0.012
								],
								"e": 0.02
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Skywalker",
					"o": ".",
					"t": ".",
					"r": [
						{
							"p": " Skywalker",
							"b": 0.0,
							"k": {
								" Skywalker": 0.999,
								" Kenobi": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.0,
							"k": {
								" Skywalker": 0.99,
								" Kenobi": 0.01,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.0,
							"k": {
								" Skywalker": 0.992,
								" Kenobi": 0.008,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.0,
							"k": {
								" Skywalker": 0.981,
								" Kenobi": 0.017,
								" Vader": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.0,
							"k": {
								" Skywalker": 0.983,
								" Kenobi": 0.012,
								",": 0.001,
								"'s": 0.001,
								" Vader": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": " Skywalker",
							"b": 0.005,
							"k": {
								" Skywalker": 0.78,
								" Kenobi": 0.125,
								",": 0.061,
								".": 0.005,
								"'s": 0.005,
								" Jr": 0.005,
								" and": 0.002,
								"/": 0.001,
								";": 0.001,
								" (": 0.001
							}
						},
						{
							"p": " Skywalker",
							"b": 0.016,
							"k": {
								" Skywalker": 0.751,
								",": 0.112,
								" Kenobi": 0.042,
								" Jr": 0.031,
								".": 0.016,
								"'s": 0.008,
								";": 0.007,
								" (": 0.004,
								" III": 0.004,
								"/": 0.003
							}
						},
						{
							"p": ".",
							"b": 0.423,
							"k": {
								".": 0.423,
								",": 0.246,
								" Skywalker": 0.205,
								" Jr": 0.065,
								";": 0.018,
								"'s": 0.011,
								" (": 0.006,
								" Kenobi": 0.006,
								"/": 0.005,
								" and": 0.003
							}
						},
						{
							"p": ".",
							"b": 0.978,
							"k": {
								".": 0.978,
								",": 0.014,
								" Skywalker": 0.003,
								";": 0.002,
								"'s": 0.001,
								" Jr": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": ".",
							"b": 1.0,
							"k": {
								".": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.999,
							"k": {
								".": 0.999,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.956,
							"k": {
								".": 0.956,
								",": 0.008,
								" (": 0.006,
								"!": 0.004,
								" and": 0.002,
								"...": 0.002,
								"/": 0.001,
								":": 0.001,
								";": 0.001,
								"?": 0.001
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.377,
									0.0,
									1.0,
									0.0,
									0.002,
									0.019,
									0.897,
									0.005,
									0.909,
									0.507,
									0.273,
									0.11
								],
								"e": 0.132
							},
							{
								"h": [
									0.09,
									0.084,
									0.177,
									1.0,
									1.0,
									0.2,
									0.553,
									1.0,
									1.0,
									1.0,
									0.025,
									0.049
								],
								"e": 0.915
							},
							{
								"h": [
									0.609,
									1.0,
									0.091,
									0.153,
									1.0,
									0.122,
									0.47,
									1.0,
									0.323,
									0.04,
									0.094,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.18,
									0.89,
									1.0,
									1.0,
									0.087,
									0.4,
									0.521,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.363,
									1.0,
									0.819,
									1.0,
									0.951,
									1.0,
									0.455,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.635,
									1.0,
									1.0,
									0.418,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.908,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.242,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.768,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.675,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.365,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.802,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.178
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.169,
									0.0,
									0.524,
									0.0,
									0.0,
									0.0,
									0.454,
									0.001,
									0.561,
									0.273,
									0.18,
									0.262
								],
								"e": 0.074
							},
							{
								"h": [
									0.008,
									0.016,
									0.1,
									0.012,
									0.153,
									0.1,
									0.177,
									0.328,
									0.442,
									0.171,
									0.131,
									0.006
								],
								"e": 0.162
							},
							{
								"h": [
									0.011,
									0.015,
									0.0,
									0.0,
									0.006,
									0.001,
									0.039,
									0.084,
									0.003,
									0.0,
									0.03,
									0.005
								],
								"e": 0.02
							},
							{
								"h": [
									0.02,
									0.004,
									0.0,
									0.0,
									0.032,
									0.019,
									0.0,
									0.0,
									0.0,
									0.002,
									0.006,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.001,
									0.071,
									0.0,
									0.036,
									0.001,
									0.002,
									0.003,
									0.021,
									0.005,
									0.096,
									0.0
								],
								"e": 0.026
							},
							{
								"h": [
									0.001,
									0.0,
									0.002,
									0.013,
									0.001,
									0.008,
									0.0,
									0.0,
									0.003,
									0.021,
									0.133,
									0.07
								],
								"e": 0.011
							},
							{
								"h": [
									0.005,
									0.001,
									0.084,
									0.008,
									0.002,
									0.003,
									0.013,
									0.005,
									0.0,
									0.001,
									0.007,
									0.003
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.007,
									0.024,
									0.001,
									0.008,
									0.02,
									0.006,
									0.009,
									0.0,
									0.001,
									0.005,
									0.007
								],
								"e": 0.007
							},
							{
								"h": [
									0.005,
									0.057,
									0.016,
									0.006,
									0.0,
									0.001,
									0.036,
									0.0,
									0.003,
									0.004,
									0.004,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.009,
									0.003,
									0.001,
									0.056,
									0.007,
									0.006,
									0.005,
									0.001,
									0.004,
									0.003,
									0.07
								],
								"e": 0.012
							},
							{
								"h": [
									0.005,
									0.017,
									0.036,
									0.095,
									0.12,
									0.006,
									0.005,
									0.044,
									0.126,
									0.0,
									0.008,
									0.025
								],
								"e": 0.033
							},
							{
								"h": [
									0.574,
									0.029,
									0.061,
									0.017,
									0.06,
									0.043,
									0.033,
									0.03,
									0.079,
									0.053,
									0.056,
									0.24
								],
								"e": 0.066
							}
						],
						[
							{
								"h": [
									0.164,
									0.0,
									0.275,
									0.0,
									0.001,
									0.0,
									0.217,
									0.0,
									0.315,
									0.408,
									0.254,
									0.304
								],
								"e": 0.063
							},
							{
								"h": [
									0.028,
									0.007,
									0.109,
									0.013,
									0.122,
									0.186,
									0.045,
									0.31,
									0.289,
									0.202,
									0.168,
									0.001
								],
								"e": 0.15
							},
							{
								"h": [
									0.012,
									0.001,
									0.0,
									0.001,
									0.001,
									0.0,
									0.009,
									0.132,
									0.0,
									0.0,
									0.043,
									0.007
								],
								"e": 0.016
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.0,
									0.039,
									0.006,
									0.0,
									0.0,
									0.0,
									0.001,
									0.007,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.0,
									0.013,
									0.001,
									0.031,
									0.0,
									0.002,
									0.011,
									0.024,
									0.001,
									0.033,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.0,
									0.003,
									0.003,
									0.0,
									0.01,
									0.001,
									0.0,
									0.001,
									0.026,
									0.026,
									0.398
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.0,
									0.063,
									0.019,
									0.001,
									0.001,
									0.012,
									0.005,
									0.001,
									0.001,
									0.011,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.005,
									0.004,
									0.0,
									0.003,
									0.007,
									0.016,
									0.031,
									0.0,
									0.0,
									0.008,
									0.016
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.019,
									0.002,
									0.001,
									0.0,
									0.001,
									0.006,
									0.0,
									0.001,
									0.002,
									0.002,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.003,
									0.002,
									0.001,
									0.001,
									0.006,
									0.003,
									0.001,
									0.002,
									0.001,
									0.001,
									0.001,
									0.009
								],
								"e": 0.002
							},
							{
								"h": [
									0.004,
									0.003,
									0.004,
									0.016,
									0.001,
									0.003,
									0.004,
									0.003,
									0.006,
									0.0,
									0.005,
									0.008
								],
								"e": 0.005
							},
							{
								"h": [
									0.131,
									0.059,
									0.015,
									0.011,
									0.006,
									0.003,
									0.01,
									0.003,
									0.304,
									0.009,
									0.006,
									0.01
								],
								"e": 0.027
							}
						],
						[
							{
								"h": [
									0.082,
									0.0,
									0.264,
									0.0,
									0.0,
									0.0,
									0.105,
									0.0,
									0.34,
									0.539,
									0.216,
									0.215
								],
								"e": 0.056
							},
							{
								"h": [
									0.015,
									0.005,
									0.144,
									0.013,
									0.144,
									1.0,
									0.099,
									0.276,
									0.302,
									0.369,
									0.195,
									0.001
								],
								"e": 0.317
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.006,
									0.188,
									0.001,
									0.0,
									0.058,
									0.009
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.0,
									0.084,
									0.009,
									0.0,
									0.0,
									0.0,
									0.0,
									0.008,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.0,
									0.019,
									0.001,
									0.008,
									0.0,
									0.003,
									0.039,
									0.029,
									0.001,
									0.017,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.011,
									0.0,
									0.001,
									0.001,
									0.0,
									0.0,
									0.0,
									0.001,
									0.001,
									0.016,
									0.008,
									0.203
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.0,
									0.055,
									0.005,
									0.001,
									0.003,
									0.015,
									0.007,
									0.0,
									0.0,
									0.022,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.007,
									0.004,
									0.0,
									0.0,
									0.002,
									0.016,
									0.014,
									0.0,
									0.0,
									0.001,
									0.032
								],
								"e": 0.004
							},
							{
								"h": [
									0.005,
									0.033,
									0.004,
									0.0,
									0.0,
									0.001,
									0.04,
									0.0,
									0.001,
									0.002,
									0.003,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.002,
									0.002,
									0.002,
									0.0,
									0.002,
									0.008,
									0.005,
									0.004,
									0.001,
									0.001,
									0.0,
									0.009
								],
								"e": 0.003
							},
							{
								"h": [
									0.004,
									0.013,
									0.011,
									0.025,
									0.003,
									0.002,
									0.009,
									0.005,
									0.012,
									0.0,
									0.01,
									0.025
								],
								"e": 0.01
							},
							{
								"h": [
									0.125,
									0.029,
									0.048,
									0.011,
									0.037,
									0.013,
									0.027,
									0.012,
									0.286,
									0.024,
									0.013,
									0.011
								],
								"e": 0.036
							}
						],
						[
							{
								"h": [
									0.139,
									0.0,
									0.344,
									0.0,
									0.0,
									0.0,
									0.275,
									0.002,
									0.532,
									0.188,
									0.149,
									0.532
								],
								"e": 0.066
							},
							{
								"h": [
									0.012,
									0.041,
									0.15,
									0.015,
									0.144,
									0.074,
									0.151,
									0.308,
									0.424,
									0.163,
									0.171,
									0.009
								],
								"e": 0.161
							},
							{
								"h": [
									0.009,
									0.034,
									0.0,
									0.0,
									0.005,
									0.001,
									0.12,
									0.107,
									0.001,
									0.0,
									0.028,
									0.007
								],
								"e": 0.036
							},
							{
								"h": [
									0.004,
									0.004,
									0.0,
									0.0,
									0.029,
									0.017,
									0.0,
									0.0,
									0.0,
									0.001,
									0.01,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.003,
									0.055,
									0.0,
									0.033,
									0.002,
									0.004,
									0.025,
									0.041,
									0.003,
									0.053,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									1.0,
									0.0,
									0.003,
									0.012,
									0.003,
									0.003,
									0.0,
									0.0,
									0.012,
									0.046,
									0.145,
									0.118
								],
								"e": 0.079
							},
							{
								"h": [
									0.001,
									0.006,
									0.063,
									0.02,
									0.012,
									0.006,
									0.031,
									0.006,
									0.0,
									0.005,
									0.183,
									0.005
								],
								"e": 0.026
							},
							{
								"h": [
									0.0,
									0.03,
									0.135,
									0.002,
									0.003,
									0.033,
									0.017,
									0.566,
									0.0,
									0.002,
									0.009,
									0.741
								],
								"e": 0.062
							},
							{
								"h": [
									0.042,
									0.997,
									0.05,
									0.008,
									0.001,
									0.003,
									0.321,
									0.001,
									0.007,
									0.004,
									0.013,
									0.004
								],
								"e": 0.067
							},
							{
								"h": [
									0.001,
									0.024,
									0.003,
									0.002,
									0.034,
									0.012,
									0.012,
									0.015,
									0.001,
									0.002,
									0.002,
									0.471
								],
								"e": 0.029
							},
							{
								"h": [
									0.002,
									0.007,
									0.068,
									0.351,
									0.607,
									0.009,
									0.001,
									0.035,
									0.069,
									0.001,
									0.006,
									0.011
								],
								"e": 0.059
							},
							{
								"h": [
									0.834,
									0.008,
									0.016,
									0.032,
									0.065,
									0.024,
									0.017,
									0.05,
									0.154,
									0.017,
									0.082,
									1.0
								],
								"e": 0.104
							}
						],
						[
							{
								"h": [
									0.114,
									0.0,
									0.102,
									0.0,
									0.0,
									0.0,
									0.218,
									0.0,
									0.389,
									0.165,
									0.103,
									0.136
								],
								"e": 0.037
							},
							{
								"h": [
									0.008,
									0.014,
									0.174,
									0.015,
									0.131,
									0.037,
									0.056,
									0.107,
									0.217,
									0.136,
									0.22,
									0.0
								],
								"e": 0.103
							},
							{
								"h": [
									0.006,
									0.005,
									0.0,
									0.001,
									0.001,
									0.0,
									0.013,
									0.121,
									0.001,
									0.0,
									0.037,
									0.008
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.0,
									0.007,
									0.015,
									0.0,
									0.0,
									0.0,
									0.001,
									0.004,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.0,
									0.013,
									0.003,
									0.002,
									0.001,
									0.002,
									0.005,
									0.015,
									0.012,
									0.007,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.006,
									0.0,
									0.003,
									0.009,
									0.0,
									0.004,
									0.0,
									0.001,
									0.006,
									0.016,
									0.039,
									0.138
								],
								"e": 0.01
							},
							{
								"h": [
									0.004,
									0.001,
									0.044,
									0.009,
									0.001,
									0.001,
									0.019,
									0.008,
									0.0,
									0.0,
									0.039,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.012,
									0.005,
									0.001,
									0.006,
									0.006,
									0.01,
									0.26,
									0.0,
									0.0,
									0.007,
									0.059
								],
								"e": 0.013
							},
							{
								"h": [
									0.007,
									0.024,
									0.004,
									0.003,
									0.0,
									0.004,
									0.007,
									0.0,
									0.001,
									0.001,
									0.004,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.002,
									0.002,
									0.005,
									0.0,
									0.019,
									0.001,
									0.013,
									0.003,
									0.0,
									0.001,
									0.001,
									0.022
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.009,
									0.021,
									0.006,
									0.004,
									0.001,
									0.01,
									0.002,
									0.003,
									0.0,
									0.008,
									0.027
								],
								"e": 0.009
							},
							{
								"h": [
									0.1,
									0.005,
									0.009,
									0.002,
									0.011,
									0.005,
									0.017,
									0.002,
									0.044,
									0.014,
									0.001,
									0.012
								],
								"e": 0.011
							}
						],
						[
							{
								"h": [
									0.037,
									0.0,
									0.148,
									0.0,
									0.0,
									0.0,
									0.268,
									0.0,
									0.141,
									0.125,
									0.063,
									0.112
								],
								"e": 0.027
							},
							{
								"h": [
									0.006,
									0.008,
									0.133,
									0.012,
									0.117,
									0.106,
									0.15,
									0.344,
									0.172,
									0.099,
									0.187,
									0.0
								],
								"e": 0.129
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.008,
									0.102,
									0.001,
									0.0,
									0.037,
									0.006
								],
								"e": 0.012
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.0,
									0.012,
									0.009,
									0.0,
									0.0,
									0.0,
									0.0,
									0.005,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.0,
									0.017,
									0.001,
									0.009,
									0.003,
									0.005,
									0.005,
									0.013,
									0.01,
									0.016,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.0,
									0.002,
									0.002,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0,
									0.004,
									0.018,
									0.09
								],
								"e": 0.004
							},
							{
								"h": [
									0.012,
									0.0,
									0.015,
									0.005,
									0.001,
									0.002,
									0.019,
									0.007,
									0.0,
									0.0,
									0.016,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.002,
									0.0,
									0.0,
									0.003,
									0.031,
									0.005,
									0.072,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.004,
									0.001,
									0.002,
									0.001,
									0.001,
									0.004,
									0.01,
									0.0,
									0.001,
									0.001,
									0.003,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.001,
									0.0,
									0.004,
									0.002,
									0.004,
									0.003,
									0.001,
									0.005,
									0.001,
									0.0,
									0.002,
									0.003
								],
								"e": 0.002
							},
							{
								"h": [
									0.003,
									0.004,
									0.003,
									0.004,
									0.017,
									0.014,
									0.002,
									0.001,
									0.002,
									0.002,
									0.001,
									0.011
								],
								"e": 0.004
							},
							{
								"h": [
									0.266,
									0.01,
									0.009,
									0.018,
									0.013,
									0.005,
									0.006,
									0.004,
									1.0,
									0.02,
									0.009,
									0.013
								],
								"e": 0.051
							}
						],
						[
							{
								"h": [
									0.13,
									0.0,
									0.272,
									0.0,
									0.0,
									0.0,
									0.367,
									0.001,
									0.439,
									0.216,
									0.159,
									0.27
								],
								"e": 0.057
							},
							{
								"h": [
									0.016,
									0.048,
									0.178,
									0.018,
									0.138,
									0.08,
									0.075,
									0.326,
									0.358,
									0.138,
									0.211,
									0.006
								],
								"e": 0.15
							},
							{
								"h": [
									0.011,
									0.028,
									0.0,
									0.001,
									0.003,
									0.001,
									0.073,
									0.201,
									0.003,
									0.0,
									0.035,
									0.008
								],
								"e": 0.035
							},
							{
								"h": [
									0.002,
									0.003,
									0.0,
									0.0,
									0.047,
									0.011,
									0.0,
									0.0,
									0.0,
									0.003,
									0.011,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.002,
									0.047,
									0.001,
									0.028,
									0.001,
									0.003,
									0.007,
									0.032,
									0.004,
									0.052,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.003,
									0.0,
									0.003,
									0.025,
									0.002,
									0.0,
									0.0,
									0.0,
									0.0,
									0.015,
									0.117,
									0.09
								],
								"e": 0.01
							},
							{
								"h": [
									0.03,
									0.0,
									0.051,
									0.009,
									0.002,
									0.006,
									0.007,
									0.006,
									0.0,
									0.0,
									0.035,
									0.011
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.003,
									0.001,
									0.001,
									0.007,
									0.035,
									0.006,
									0.039,
									0.0,
									0.002,
									0.0,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.005,
									0.015,
									0.016,
									0.014,
									0.001,
									0.021,
									0.021,
									0.002,
									0.002,
									0.005,
									0.014,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.004,
									0.002,
									0.005,
									0.027,
									0.006,
									0.001,
									0.009,
									0.0,
									0.0,
									0.04,
									0.023
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.002,
									0.013,
									0.024,
									0.099,
									0.052,
									0.0,
									0.015,
									0.057,
									0.002,
									0.001,
									0.011
								],
								"e": 0.016
							},
							{
								"h": [
									0.472,
									0.008,
									0.011,
									0.007,
									0.014,
									0.024,
									0.009,
									0.008,
									0.069,
									0.011,
									0.05,
									0.058
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.234,
									0.0,
									0.126,
									0.0,
									0.001,
									0.0,
									0.13,
									0.002,
									0.197,
									1.0,
									0.292,
									0.225
								],
								"e": 0.075
							},
							{
								"h": [
									0.039,
									0.028,
									0.256,
									0.023,
									0.178,
									0.256,
									0.026,
									0.475,
									0.537,
									0.333,
									0.333,
									0.001
								],
								"e": 0.241
							},
							{
								"h": [
									0.007,
									0.003,
									0.0,
									0.004,
									0.002,
									0.001,
									0.039,
									0.341,
									0.003,
									0.001,
									0.135,
									0.02
								],
								"e": 0.042
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.0,
									0.284,
									0.022,
									0.001,
									0.001,
									0.0,
									0.003,
									0.03,
									0.006
								],
								"e": 0.044
							},
							{
								"h": [
									0.0,
									0.001,
									0.024,
									0.012,
									0.015,
									0.005,
									0.014,
									0.077,
									0.036,
									0.005,
									0.077,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.008,
									0.0,
									0.006,
									0.004,
									0.001,
									0.002,
									0.001,
									0.001,
									0.002,
									0.054,
									0.007,
									0.154
								],
								"e": 0.013
							},
							{
								"h": [
									0.005,
									0.001,
									0.058,
									0.016,
									0.001,
									0.005,
									0.037,
									0.024,
									0.001,
									0.0,
									0.088,
									0.001
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.006,
									0.002,
									0.001,
									0.002,
									0.01,
									0.045,
									0.156,
									0.0,
									0.0,
									0.002,
									0.075
								],
								"e": 0.013
							},
							{
								"h": [
									0.007,
									0.031,
									0.004,
									0.001,
									0.0,
									0.007,
									0.016,
									0.001,
									0.003,
									0.008,
									0.011,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.003,
									0.001,
									0.003,
									0.003,
									0.006,
									0.007,
									0.001,
									0.006,
									0.002,
									0.0,
									0.004,
									0.015
								],
								"e": 0.004
							},
							{
								"h": [
									0.004,
									0.005,
									0.003,
									0.019,
									0.002,
									0.013,
									0.008,
									0.003,
									0.01,
									0.001,
									0.005,
									0.017
								],
								"e": 0.007
							},
							{
								"h": [
									0.144,
									0.016,
									0.018,
									0.018,
									0.021,
									0.007,
									0.012,
									0.003,
									0.185,
									0.024,
									0.009,
									0.031
								],
								"e": 0.025
							}
						],
						[
							{
								"h": [
									0.139,
									0.0,
									0.272,
									0.0,
									0.0,
									0.0,
									0.247,
									0.002,
									0.51,
									0.189,
									0.139,
									0.672
								],
								"e": 0.066
							},
							{
								"h": [
									0.023,
									0.102,
									0.236,
									0.023,
									0.164,
									0.064,
									0.123,
									0.305,
									0.402,
									0.127,
									0.284,
									0.008
								],
								"e": 0.177
							},
							{
								"h": [
									0.018,
									0.027,
									0.0,
									0.001,
									0.015,
									0.003,
									0.206,
									0.187,
									0.004,
									0.0,
									0.053,
									0.013
								],
								"e": 0.054
							},
							{
								"h": [
									0.002,
									0.012,
									0.0,
									0.0,
									0.033,
									0.02,
									0.0,
									0.0,
									0.001,
									0.003,
									0.022,
									0.002
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.014,
									0.057,
									0.002,
									0.028,
									0.01,
									0.012,
									0.032,
									0.032,
									0.007,
									0.052,
									0.0
								],
								"e": 0.026
							},
							{
								"h": [
									0.293,
									0.0,
									0.005,
									0.023,
									0.013,
									0.001,
									0.0,
									0.001,
									0.01,
									0.036,
									0.085,
									0.11
								],
								"e": 0.032
							},
							{
								"h": [
									0.008,
									0.008,
									0.048,
									0.037,
									0.013,
									0.016,
									0.032,
									0.011,
									0.0,
									0.001,
									0.248,
									0.021
								],
								"e": 0.033
							},
							{
								"h": [
									0.001,
									0.018,
									0.017,
									0.008,
									0.002,
									0.02,
									0.016,
									0.403,
									0.0,
									0.006,
									0.002,
									0.236
								],
								"e": 0.027
							},
							{
								"h": [
									0.055,
									0.319,
									0.021,
									0.016,
									0.004,
									0.008,
									0.048,
									0.007,
									0.007,
									0.004,
									0.042,
									0.005
								],
								"e": 0.029
							},
							{
								"h": [
									0.0,
									0.01,
									0.004,
									0.008,
									0.013,
									0.013,
									0.004,
									0.021,
									0.002,
									0.002,
									0.006,
									0.225
								],
								"e": 0.016
							},
							{
								"h": [
									0.003,
									0.005,
									0.047,
									0.223,
									0.473,
									0.029,
									0.001,
									0.021,
									0.054,
									0.002,
									0.005,
									0.009
								],
								"e": 0.043
							},
							{
								"h": [
									0.637,
									0.005,
									0.014,
									0.015,
									0.053,
									0.021,
									0.01,
									0.021,
									0.09,
									0.009,
									0.049,
									0.247
								],
								"e": 0.048
							}
						],
						[
							{
								"h": [
									0.104,
									0.0,
									0.098,
									0.0,
									0.0,
									0.0,
									0.418,
									0.0,
									0.163,
									0.259,
									0.111,
									0.106
								],
								"e": 0.039
							},
							{
								"h": [
									0.025,
									0.037,
									0.318,
									0.024,
									0.17,
									0.089,
									0.083,
									0.138,
									0.337,
									0.15,
									0.363,
									0.006
								],
								"e": 0.164
							},
							{
								"h": [
									0.011,
									0.001,
									0.0,
									0.006,
									0.001,
									0.001,
									0.037,
									0.369,
									0.003,
									0.001,
									0.109,
									0.02
								],
								"e": 0.041
							},
							{
								"h": [
									0.001,
									0.008,
									0.0,
									0.0,
									0.02,
									0.023,
									0.001,
									0.001,
									0.0,
									0.004,
									0.016,
									0.006
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.002,
									0.034,
									0.008,
									0.022,
									0.019,
									0.017,
									0.019,
									0.028,
									0.036,
									0.012,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.006,
									0.0,
									0.007,
									0.044,
									0.003,
									0.007,
									0.0,
									0.005,
									0.01,
									0.021,
									0.242,
									0.095
								],
								"e": 0.019
							},
							{
								"h": [
									0.05,
									0.002,
									0.026,
									0.031,
									0.005,
									0.01,
									0.028,
									0.041,
									0.001,
									0.0,
									0.024,
									0.061
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.008,
									0.005,
									0.004,
									0.003,
									0.097,
									0.011,
									0.369,
									0.0,
									0.003,
									0.026,
									0.023
								],
								"e": 0.023
							},
							{
								"h": [
									0.015,
									0.003,
									0.008,
									0.024,
									0.004,
									0.013,
									0.012,
									0.001,
									0.006,
									0.002,
									0.022,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.017,
									0.001,
									0.022,
									0.003,
									0.012,
									0.004,
									0.012,
									0.007,
									0.012,
									0.004,
									0.005,
									0.015
								],
								"e": 0.01
							},
							{
								"h": [
									0.044,
									0.02,
									0.022,
									0.009,
									0.024,
									0.009,
									0.032,
									0.005,
									0.004,
									0.002,
									0.013,
									0.027
								],
								"e": 0.019
							},
							{
								"h": [
									0.131,
									0.025,
									0.028,
									0.013,
									0.016,
									0.009,
									0.045,
									0.007,
									0.196,
									0.035,
									0.007,
									0.015
								],
								"e": 0.03
							}
						],
						[
							{
								"h": [
									0.139,
									0.0,
									0.254,
									0.0,
									0.0,
									0.0,
									0.358,
									0.002,
									0.452,
									0.221,
									0.157,
									0.307
								],
								"e": 0.058
							},
							{
								"h": [
									0.03,
									0.103,
									0.257,
									0.025,
									0.16,
									0.074,
									0.071,
									0.354,
									0.353,
									0.118,
									0.308,
									0.005
								],
								"e": 0.173
							},
							{
								"h": [
									0.027,
									0.03,
									0.0,
									0.003,
									0.01,
									0.004,
									0.125,
									0.278,
									0.011,
									0.0,
									0.064,
									0.014
								],
								"e": 0.054
							},
							{
								"h": [
									0.001,
									0.026,
									0.0,
									0.0,
									0.039,
									0.014,
									0.0,
									0.0,
									0.002,
									0.011,
									0.041,
									0.003
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.011,
									0.078,
									0.006,
									0.02,
									0.009,
									0.012,
									0.007,
									0.022,
									0.024,
									0.039,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.0,
									0.0,
									0.006,
									0.099,
									0.006,
									0.0,
									0.0,
									0.001,
									0.001,
									0.01,
									0.135,
									0.084
								],
								"e": 0.014
							},
							{
								"h": [
									0.056,
									0.001,
									0.031,
									0.019,
									0.008,
									0.012,
									0.009,
									0.022,
									0.001,
									0.0,
									0.013,
									0.048
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.003,
									0.0,
									0.003,
									0.008,
									0.017,
									0.011,
									0.02,
									0.0,
									0.005,
									0.001,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.005,
									0.003,
									0.006,
									0.024,
									0.003,
									0.035,
									0.006,
									0.007,
									0.001,
									0.002,
									0.026,
									0.003
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.003,
									0.003,
									0.022,
									0.009,
									0.01,
									0.001,
									0.006,
									0.001,
									0.001,
									0.075,
									0.009
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.002,
									0.01,
									0.014,
									0.102,
									0.104,
									0.001,
									0.009,
									0.028,
									0.004,
									0.001,
									0.006
								],
								"e": 0.014
							},
							{
								"h": [
									0.536,
									0.008,
									0.008,
									0.008,
									0.012,
									0.014,
									0.01,
									0.009,
									0.048,
									0.007,
									0.034,
									0.048
								],
								"e": 0.026
							}
						],
						[
							{
								"h": [
									0.113,
									0.0,
									0.017,
									0.0,
									0.0,
									0.0,
									0.056,
									0.001,
									0.213,
									0.552,
									0.166,
									0.091
								],
								"e": 0.04
							},
							{
								"h": [
									0.065,
									0.05,
									0.308,
									0.025,
									0.19,
									0.54,
									0.121,
									0.355,
									0.339,
									0.251,
									0.427,
									0.002
								],
								"e": 0.288
							},
							{
								"h": [
									0.024,
									0.002,
									0.0,
									0.016,
									0.007,
									0.002,
									0.05,
									0.359,
									0.005,
									0.005,
									0.164,
									0.025
								],
								"e": 0.054
							},
							{
								"h": [
									0.0,
									0.003,
									0.001,
									0.001,
									0.122,
									0.016,
									0.004,
									0.003,
									0.001,
									0.006,
									0.046,
									0.012
								],
								"e": 0.025
							},
							{
								"h": [
									0.0,
									0.001,
									0.042,
									0.012,
									0.032,
									0.012,
									0.02,
									0.071,
									0.033,
									0.024,
									0.182,
									0.0
								],
								"e": 0.044
							},
							{
								"h": [
									0.001,
									0.0,
									0.011,
									0.012,
									0.003,
									0.001,
									0.001,
									0.004,
									0.002,
									0.064,
									0.139,
									0.43
								],
								"e": 0.028
							},
							{
								"h": [
									0.016,
									0.001,
									0.061,
									0.019,
									0.001,
									0.008,
									0.03,
									0.025,
									0.005,
									0.0,
									0.029,
									0.004
								],
								"e": 0.015
							},
							{
								"h": [
									0.002,
									0.004,
									0.001,
									0.001,
									0.005,
									0.005,
									0.017,
									0.043,
									0.0,
									0.0,
									0.002,
									0.014
								],
								"e": 0.005
							},
							{
								"h": [
									0.017,
									0.012,
									0.003,
									0.001,
									0.001,
									0.015,
									0.007,
									0.003,
									0.001,
									0.007,
									0.017,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									0.01,
									0.001,
									0.006,
									0.011,
									0.003,
									0.01,
									0.001,
									0.005,
									0.004,
									0.001,
									0.005,
									0.007
								],
								"e": 0.005
							},
							{
								"h": [
									0.009,
									0.003,
									0.005,
									0.006,
									0.003,
									0.029,
									0.007,
									0.011,
									0.017,
									0.005,
									0.004,
									0.014
								],
								"e": 0.009
							},
							{
								"h": [
									0.201,
									0.017,
									0.027,
									0.021,
									0.02,
									0.006,
									0.011,
									0.009,
									0.351,
									0.007,
									0.028,
									0.014
								],
								"e": 0.032
							}
						],
						[
							{
								"h": [
									0.15,
									0.0,
									0.268,
									0.0,
									0.0,
									0.0,
									0.252,
									0.004,
									0.554,
									0.201,
									0.139,
									0.8
								],
								"e": 0.072
							},
							{
								"h": [
									0.048,
									0.24,
									0.323,
									0.032,
									0.188,
									0.053,
									0.11,
									0.332,
									0.37,
									0.099,
									0.397,
									0.007
								],
								"e": 0.211
							},
							{
								"h": [
									0.046,
									0.026,
									0.0,
									0.005,
									0.063,
									0.022,
									0.337,
									0.257,
									0.02,
									0.002,
									0.098,
									0.025
								],
								"e": 0.093
							},
							{
								"h": [
									0.002,
									0.063,
									0.001,
									0.001,
									0.029,
									0.029,
									0.003,
									0.001,
									0.01,
									0.011,
									0.061,
									0.011
								],
								"e": 0.022
							},
							{
								"h": [
									0.005,
									0.07,
									0.098,
									0.007,
									0.029,
									0.077,
									0.034,
									0.057,
									0.022,
									0.018,
									0.064,
									0.0
								],
								"e": 0.045
							},
							{
								"h": [
									0.199,
									0.0,
									0.019,
									0.074,
									0.043,
									0.001,
									0.001,
									0.002,
									0.016,
									0.027,
									0.145,
									0.11
								],
								"e": 0.034
							},
							{
								"h": [
									0.042,
									0.015,
									0.048,
									0.083,
									0.027,
									0.031,
									0.028,
									0.045,
									0.002,
									0.0,
									0.117,
									0.112
								],
								"e": 0.039
							},
							{
								"h": [
									0.005,
									0.034,
									0.027,
									0.016,
									0.003,
									0.033,
									0.021,
									0.424,
									0.0,
									0.025,
									0.003,
									0.547
								],
								"e": 0.045
							},
							{
								"h": [
									0.096,
									1.0,
									0.014,
									0.028,
									0.009,
									0.019,
									0.074,
									0.016,
									0.014,
									0.003,
									0.057,
									0.016
								],
								"e": 0.06
							},
							{
								"h": [
									0.001,
									0.007,
									0.007,
									0.043,
									0.008,
									0.018,
									0.004,
									0.041,
									0.001,
									0.002,
									0.016,
									0.376
								],
								"e": 0.026
							},
							{
								"h": [
									0.002,
									0.005,
									0.042,
									0.219,
									0.509,
									0.047,
									0.001,
									0.016,
									0.049,
									0.004,
									0.004,
									0.008
								],
								"e": 0.044
							},
							{
								"h": [
									0.403,
									0.005,
									0.012,
									0.022,
									0.061,
									0.017,
									0.011,
									0.02,
									0.162,
									0.018,
									0.053,
									0.175
								],
								"e": 0.045
							}
						],
						[
							{
								"h": [
									0.125,
									0.0,
									0.085,
									0.0,
									0.001,
									0.0,
									0.217,
									0.001,
									0.416,
									0.185,
									0.097,
									0.191
								],
								"e": 0.04
							},
							{
								"h": [
									0.025,
									0.075,
									0.386,
									0.03,
									0.201,
									0.029,
									0.032,
									0.079,
									0.287,
									0.127,
									0.445,
									0.0
								],
								"e": 0.153
							},
							{
								"h": [
									0.042,
									0.002,
									0.001,
									0.032,
									0.012,
									0.004,
									0.057,
									0.369,
									0.014,
									0.005,
									0.126,
									0.025
								],
								"e": 0.058
							},
							{
								"h": [
									0.0,
									0.018,
									0.006,
									0.0,
									0.008,
									0.011,
									0.006,
									0.002,
									0.002,
									0.008,
									0.029,
									0.011
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.008,
									0.025,
									0.04,
									0.002,
									0.029,
									0.018,
									0.012,
									0.007,
									0.087,
									0.005,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.003,
									0.0,
									0.015,
									0.111,
									0.015,
									0.001,
									0.0,
									0.01,
									0.013,
									0.008,
									0.04,
									0.046
								],
								"e": 0.014
							},
							{
								"h": [
									0.033,
									0.001,
									0.017,
									0.042,
									0.001,
									0.016,
									0.008,
									0.054,
									0.002,
									0.0,
									0.013,
									0.089
								],
								"e": 0.017
							},
							{
								"h": [
									0.002,
									0.012,
									0.002,
									0.007,
									0.006,
									0.018,
									0.013,
									0.377,
									0.001,
									0.006,
									0.005,
									0.075
								],
								"e": 0.019
							},
							{
								"h": [
									0.022,
									0.023,
									0.003,
									0.015,
									0.003,
									0.019,
									0.006,
									0.001,
									0.005,
									0.001,
									0.021,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.001,
									0.005,
									0.002,
									0.004,
									0.001,
									0.002,
									0.005,
									0.001,
									0.0,
									0.003,
									0.013
								],
								"e": 0.003
							},
							{
								"h": [
									0.011,
									0.005,
									0.01,
									0.003,
									0.006,
									0.007,
									0.007,
									0.003,
									0.002,
									0.003,
									0.009,
									0.009
								],
								"e": 0.007
							},
							{
								"h": [
									0.062,
									0.01,
									0.007,
									0.001,
									0.007,
									0.002,
									0.02,
									0.002,
									0.002,
									0.006,
									0.002,
									0.075
								],
								"e": 0.011
							}
						],
						[
							{
								"h": [
									0.041,
									0.0,
									0.128,
									0.0,
									0.0,
									0.0,
									0.284,
									0.001,
									0.15,
									0.144,
									0.06,
									0.153
								],
								"e": 0.029
							},
							{
								"h": [
									0.032,
									0.052,
									0.282,
									0.023,
									0.166,
									0.069,
									0.094,
									0.243,
									0.186,
									0.078,
									0.385,
									0.0
								],
								"e": 0.148
							},
							{
								"h": [
									0.013,
									0.001,
									0.001,
									0.007,
									0.013,
									0.005,
									0.029,
									0.251,
									0.026,
									0.004,
									0.128,
									0.021
								],
								"e": 0.042
							},
							{
								"h": [
									0.001,
									0.015,
									0.002,
									0.001,
									0.009,
									0.006,
									0.001,
									0.004,
									0.004,
									0.007,
									0.04,
									0.019
								],
								"e": 0.01
							},
							{
								"h": [
									0.003,
									0.006,
									0.019,
									0.028,
									0.007,
									0.062,
									0.036,
									0.015,
									0.009,
									0.059,
									0.01,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.0,
									0.0,
									0.014,
									0.018,
									0.012,
									0.0,
									0.0,
									0.005,
									0.0,
									0.006,
									0.023,
									0.043
								],
								"e": 0.006
							},
							{
								"h": [
									0.074,
									0.0,
									0.011,
									0.019,
									0.001,
									0.025,
									0.009,
									0.02,
									0.002,
									0.0,
									0.003,
									0.257
								],
								"e": 0.021
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.002,
									0.003,
									0.051,
									0.004,
									0.047,
									0.001,
									0.001,
									0.0,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.006,
									0.0,
									0.002,
									0.007,
									0.018,
									0.04,
									0.001,
									0.009,
									0.003,
									0.001,
									0.008,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.0,
									0.006,
									0.016,
									0.001,
									0.004,
									0.0,
									0.008,
									0.002,
									0.0,
									0.009,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.001,
									0.009,
									0.019,
									0.0,
									0.0,
									0.0,
									0.007,
									0.0,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.149,
									0.002,
									0.001,
									0.011,
									0.003,
									0.001,
									0.002,
									0.001,
									0.022,
									0.002,
									0.009,
									0.149
								],
								"e": 0.014
							}
						],
						[
							{
								"h": [
									0.156,
									0.0,
									0.255,
									0.0,
									0.0,
									0.0,
									0.378,
									0.006,
									0.486,
									0.247,
									0.159,
									0.358
								],
								"e": 0.063
							},
							{
								"h": [
									0.079,
									0.297,
									0.377,
									0.035,
									0.205,
									0.104,
									0.079,
									0.36,
									0.358,
									0.101,
									0.443,
									0.007
								],
								"e": 0.24
							},
							{
								"h": [
									0.086,
									0.019,
									0.003,
									0.026,
									0.06,
									0.051,
									0.209,
									0.386,
									0.087,
									0.003,
									0.128,
									0.029
								],
								"e": 0.111
							},
							{
								"h": [
									0.001,
									0.06,
									0.004,
									0.003,
									0.024,
									0.01,
									0.003,
									0.003,
									0.029,
									0.03,
									0.076,
									0.014
								],
								"e": 0.024
							},
							{
								"h": [
									0.007,
									0.092,
									0.046,
									0.024,
									0.017,
									0.028,
									0.03,
									0.014,
									0.01,
									0.026,
									0.025,
									0.0
								],
								"e": 0.03
							},
							{
								"h": [
									0.0,
									0.0,
									0.019,
									0.14,
									0.025,
									0.0,
									0.0,
									0.001,
									0.001,
									0.008,
									0.04,
									0.073
								],
								"e": 0.015
							},
							{
								"h": [
									0.178,
									0.001,
									0.032,
									0.034,
									0.007,
									0.022,
									0.009,
									0.027,
									0.004,
									0.0,
									0.004,
									0.125
								],
								"e": 0.026
							},
							{
								"h": [
									0.007,
									0.001,
									0.0,
									0.009,
									0.03,
									0.011,
									0.015,
									0.013,
									0.0,
									0.022,
									0.0,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.003,
									0.002,
									0.004,
									0.033,
									0.007,
									0.124,
									0.003,
									0.079,
									0.002,
									0.003,
									0.035,
									0.003
								],
								"e": 0.021
							},
							{
								"h": [
									0.001,
									0.003,
									0.004,
									0.026,
									0.005,
									0.009,
									0.001,
									0.008,
									0.001,
									0.001,
									0.167,
									0.008
								],
								"e": 0.012
							},
							{
								"h": [
									0.002,
									0.001,
									0.004,
									0.006,
									0.031,
									0.221,
									0.0,
									0.007,
									0.038,
									0.013,
									0.001,
									0.005
								],
								"e": 0.016
							},
							{
								"h": [
									0.37,
									0.007,
									0.008,
									0.005,
									0.009,
									0.009,
									0.007,
									0.004,
									0.058,
									0.005,
									0.024,
									0.022
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.263,
									0.0,
									0.315,
									0.0,
									0.0,
									0.0,
									0.344,
									0.01,
									0.393,
									0.309,
									0.202,
									0.128
								],
								"e": 0.066
							},
							{
								"h": [
									0.206,
									0.198,
									0.376,
									0.039,
									0.202,
									0.039,
									0.485,
									0.676,
									0.304,
									0.083,
									0.59,
									0.001
								],
								"e": 0.325
							},
							{
								"h": [
									0.107,
									0.009,
									0.007,
									0.602,
									0.146,
									0.053,
									0.264,
									0.404,
									0.106,
									0.016,
									0.204,
									0.05
								],
								"e": 0.221
							},
							{
								"h": [
									0.001,
									0.081,
									0.071,
									0.004,
									0.025,
									0.077,
									0.003,
									0.013,
									0.006,
									0.04,
									0.067,
									0.071
								],
								"e": 0.049
							},
							{
								"h": [
									0.032,
									0.061,
									0.022,
									0.052,
									0.004,
									0.15,
									0.051,
									0.029,
									0.032,
									0.018,
									0.026,
									0.0
								],
								"e": 0.041
							},
							{
								"h": [
									0.001,
									0.0,
									0.072,
									0.077,
									0.07,
									0.0,
									0.0,
									0.001,
									0.005,
									0.014,
									0.075,
									0.088
								],
								"e": 0.022
							},
							{
								"h": [
									0.026,
									0.013,
									0.083,
									0.065,
									0.005,
									0.016,
									0.008,
									0.068,
									0.016,
									0.0,
									0.034,
									0.074
								],
								"e": 0.028
							},
							{
								"h": [
									0.004,
									0.008,
									0.0,
									0.006,
									0.011,
									0.009,
									0.041,
									0.018,
									0.002,
									0.011,
									0.0,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.004,
									0.015,
									0.012,
									0.004,
									0.007,
									0.049,
									0.017,
									0.027,
									0.002,
									0.007,
									0.02,
									0.007
								],
								"e": 0.012
							},
							{
								"h": [
									0.002,
									0.004,
									0.01,
									0.018,
									0.003,
									0.004,
									0.001,
									0.016,
									0.0,
									0.001,
									0.055,
									0.016
								],
								"e": 0.008
							},
							{
								"h": [
									0.002,
									0.001,
									0.002,
									0.004,
									0.007,
									0.115,
									0.0,
									0.004,
									0.021,
									0.008,
									0.001,
									0.008
								],
								"e": 0.009
							},
							{
								"h": [
									0.065,
									0.006,
									0.002,
									0.009,
									0.009,
									0.008,
									0.004,
									0.004,
									0.022,
									0.001,
									0.017,
									0.005
								],
								"e": 0.009
							}
						],
						[
							{
								"h": [
									0.135,
									0.0,
									0.136,
									0.002,
									0.007,
									0.0,
									0.203,
									0.01,
									0.248,
									0.398,
									0.216,
									0.121
								],
								"e": 0.052
							},
							{
								"h": [
									0.201,
									0.249,
									0.457,
									0.04,
									0.286,
									0.121,
									0.048,
									0.202,
									0.382,
									0.147,
									0.712,
									0.005
								],
								"e": 0.286
							},
							{
								"h": [
									0.082,
									0.001,
									0.029,
									0.066,
									0.114,
									0.056,
									0.12,
									0.58,
									0.07,
									0.088,
									0.432,
									0.061
								],
								"e": 0.173
							},
							{
								"h": [
									0.0,
									0.026,
									0.009,
									0.018,
									0.098,
									0.02,
									0.032,
									0.19,
									0.02,
									0.032,
									0.158,
									0.155
								],
								"e": 0.071
							},
							{
								"h": [
									0.008,
									0.017,
									0.041,
									0.053,
									0.027,
									0.092,
									0.122,
									0.212,
									0.019,
									0.054,
									0.104,
									0.0
								],
								"e": 0.068
							},
							{
								"h": [
									0.007,
									0.0,
									0.078,
									0.031,
									0.036,
									0.0,
									0.005,
									0.022,
									0.002,
									0.035,
									0.155,
									0.218
								],
								"e": 0.03
							},
							{
								"h": [
									0.038,
									0.003,
									0.035,
									0.03,
									0.002,
									0.023,
									0.06,
									0.114,
									0.068,
									0.0,
									0.054,
									0.016
								],
								"e": 0.033
							},
							{
								"h": [
									0.014,
									0.019,
									0.0,
									0.005,
									0.011,
									0.015,
									0.039,
									0.043,
									0.006,
									0.002,
									0.0,
									0.005
								],
								"e": 0.011
							},
							{
								"h": [
									0.008,
									0.002,
									0.002,
									0.001,
									0.005,
									0.044,
									0.002,
									0.011,
									0.008,
									0.013,
									0.025,
									0.008
								],
								"e": 0.01
							},
							{
								"h": [
									0.019,
									0.001,
									0.018,
									0.037,
									0.002,
									0.017,
									0.002,
									0.007,
									0.005,
									0.0,
									0.018,
									0.007
								],
								"e": 0.01
							},
							{
								"h": [
									0.017,
									0.002,
									0.004,
									0.007,
									0.004,
									0.096,
									0.009,
									0.006,
									0.011,
									0.028,
									0.007,
									0.009
								],
								"e": 0.013
							},
							{
								"h": [
									0.176,
									0.009,
									0.013,
									0.036,
									0.013,
									0.003,
									0.006,
									0.001,
									0.373,
									0.006,
									0.012,
									0.04
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.183,
									0.0,
									0.277,
									0.0,
									0.0,
									0.0,
									0.277,
									0.024,
									0.62,
									0.232,
									0.143,
									1.0
								],
								"e": 0.087
							},
							{
								"h": [
									0.19,
									1.0,
									0.584,
									0.056,
									0.281,
									0.057,
									0.106,
									0.327,
									0.375,
									0.081,
									0.706,
									0.009
								],
								"e": 0.394
							},
							{
								"h": [
									0.254,
									0.017,
									0.076,
									0.091,
									0.887,
									0.715,
									1.0,
									0.483,
									0.337,
									0.048,
									0.293,
									0.071
								],
								"e": 0.506
							},
							{
								"h": [
									0.002,
									0.656,
									0.046,
									0.061,
									0.032,
									0.031,
									0.057,
									0.103,
									0.457,
									0.141,
									0.236,
									0.173
								],
								"e": 0.178
							},
							{
								"h": [
									0.071,
									0.536,
									0.115,
									0.025,
									0.046,
									0.462,
									0.132,
									0.121,
									0.017,
									0.074,
									0.037,
									0.0
								],
								"e": 0.137
							},
							{
								"h": [
									0.235,
									0.001,
									0.103,
									0.202,
									0.217,
									0.091,
									0.003,
									0.007,
									0.528,
									0.014,
									0.124,
									0.095
								],
								"e": 0.114
							},
							{
								"h": [
									0.147,
									0.057,
									0.058,
									0.143,
									0.014,
									0.077,
									0.04,
									0.083,
									0.01,
									0.017,
									0.114,
									0.369
								],
								"e": 0.075
							},
							{
								"h": [
									0.033,
									0.037,
									0.027,
									0.05,
									0.011,
									0.029,
									0.042,
									0.498,
									0.002,
									0.053,
									0.007,
									0.497
								],
								"e": 0.057
							},
							{
								"h": [
									0.097,
									0.948,
									0.013,
									0.03,
									0.025,
									0.056,
									0.056,
									0.081,
									0.028,
									0.005,
									0.087,
									0.041
								],
								"e": 0.073
							},
							{
								"h": [
									0.001,
									0.01,
									0.016,
									0.09,
									0.007,
									0.024,
									0.006,
									0.066,
									0.002,
									0.003,
									0.048,
									0.408
								],
								"e": 0.036
							},
							{
								"h": [
									0.003,
									0.006,
									0.058,
									0.287,
									0.37,
									0.104,
									0.001,
									0.018,
									0.045,
									0.013,
									0.006,
									0.009
								],
								"e": 0.047
							},
							{
								"h": [
									0.418,
									0.005,
									0.011,
									0.03,
									0.053,
									0.015,
									0.009,
									0.015,
									0.164,
									0.014,
									0.051,
									0.202
								],
								"e": 0.045
							}
						],
						[
							{
								"h": [
									0.131,
									0.0,
									0.096,
									0.0,
									0.001,
									0.0,
									0.482,
									0.002,
									0.182,
									0.315,
									0.111,
									0.138
								],
								"e": 0.046
							},
							{
								"h": [
									0.131,
									0.22,
									0.613,
									0.045,
									0.285,
									0.065,
									0.048,
									0.119,
									0.311,
									0.132,
									0.716,
									0.002
								],
								"e": 0.256
							},
							{
								"h": [
									0.132,
									0.001,
									0.379,
									0.427,
									0.105,
									0.124,
									0.155,
									0.702,
									0.208,
									0.187,
									0.469,
									0.094
								],
								"e": 0.359
							},
							{
								"h": [
									0.0,
									0.327,
									0.297,
									0.021,
									0.012,
									0.029,
									0.063,
									0.462,
									0.08,
									0.154,
									0.119,
									0.389
								],
								"e": 0.181
							},
							{
								"h": [
									0.024,
									0.163,
									0.068,
									0.193,
									0.021,
									1.0,
									0.209,
									0.06,
									0.011,
									0.448,
									0.009,
									0.0
								],
								"e": 0.163
							},
							{
								"h": [
									0.002,
									0.0,
									0.055,
									0.4,
									0.067,
									0.032,
									0.002,
									0.053,
									0.094,
									0.013,
									0.134,
									0.068
								],
								"e": 0.057
							},
							{
								"h": [
									0.249,
									0.004,
									0.028,
									0.096,
									0.003,
									0.052,
									0.017,
									0.089,
									0.007,
									0.0,
									0.01,
									0.669
								],
								"e": 0.063
							},
							{
								"h": [
									0.013,
									0.006,
									0.003,
									0.03,
									0.014,
									0.057,
									0.015,
									0.135,
									0.002,
									0.026,
									0.012,
									0.008
								],
								"e": 0.02
							},
							{
								"h": [
									0.012,
									0.003,
									0.003,
									0.025,
									0.066,
									0.113,
									0.007,
									0.048,
									0.014,
									0.002,
									0.062,
									0.003
								],
								"e": 0.028
							},
							{
								"h": [
									0.011,
									0.001,
									0.019,
									0.047,
									0.006,
									0.004,
									0.003,
									0.013,
									0.004,
									0.003,
									0.054,
									0.007
								],
								"e": 0.011
							},
							{
								"h": [
									0.028,
									0.01,
									0.015,
									0.004,
									0.016,
									0.09,
									0.013,
									0.003,
									0.002,
									0.068,
									0.011,
									0.015
								],
								"e": 0.019
							},
							{
								"h": [
									0.09,
									0.025,
									0.009,
									0.011,
									0.006,
									0.003,
									0.021,
									0.005,
									0.015,
									0.016,
									0.006,
									0.264
								],
								"e": 0.027
							}
						],
						[
							{
								"h": [
									0.182,
									0.0,
									0.262,
									0.0,
									0.001,
									0.0,
									0.423,
									0.03,
									0.543,
									0.271,
									0.159,
									0.41
								],
								"e": 0.075
							},
							{
								"h": [
									0.289,
									0.991,
									0.581,
									0.054,
									0.281,
									0.071,
									0.064,
									0.377,
									0.339,
									0.082,
									0.686,
									0.006
								],
								"e": 0.409
							},
							{
								"h": [
									0.375,
									0.025,
									0.406,
									0.32,
									0.672,
									1.0,
									0.54,
									0.628,
									1.0,
									0.047,
									0.336,
									0.078
								],
								"e": 0.695
							},
							{
								"h": [
									0.0,
									0.729,
									0.21,
									0.222,
									0.017,
									0.02,
									0.045,
									0.173,
									1.0,
									0.252,
									0.285,
									0.213
								],
								"e": 0.289
							},
							{
								"h": [
									0.227,
									1.0,
									0.111,
									0.279,
									0.029,
									0.6,
									0.202,
									0.029,
									0.013,
									0.274,
									0.032,
									0.0
								],
								"e": 0.238
							},
							{
								"h": [
									0.0,
									0.0,
									0.071,
									1.0,
									0.206,
									0.0,
									0.0,
									0.008,
									0.004,
									0.008,
									0.095,
									0.095
								],
								"e": 0.081
							},
							{
								"h": [
									1.0,
									0.003,
									0.044,
									0.124,
									0.011,
									0.094,
									0.009,
									0.106,
									0.025,
									0.0,
									0.005,
									0.918
								],
								"e": 0.124
							},
							{
								"h": [
									0.185,
									0.001,
									0.0,
									0.04,
									0.089,
									0.02,
									0.011,
									0.019,
									0.001,
									0.071,
									0.001,
									0.001
								],
								"e": 0.038
							},
							{
								"h": [
									0.008,
									0.006,
									0.003,
									0.046,
									0.081,
									0.444,
									0.002,
									1.0,
									0.005,
									0.005,
									0.096,
									0.014
								],
								"e": 0.12
							},
							{
								"h": [
									0.001,
									0.006,
									0.005,
									0.142,
									0.007,
									0.019,
									0.001,
									0.016,
									0.001,
									0.003,
									1.0,
									0.009
								],
								"e": 0.059
							},
							{
								"h": [
									0.002,
									0.001,
									0.005,
									0.005,
									0.062,
									0.642,
									0.0,
									0.005,
									0.016,
									0.098,
									0.001,
									0.002
								],
								"e": 0.038
							},
							{
								"h": [
									0.408,
									0.006,
									0.008,
									0.007,
									0.008,
									0.008,
									0.007,
									0.004,
									0.067,
									0.004,
									0.026,
									0.034
								],
								"e": 0.02
							}
						],
						[
							{
								"h": [
									1.0,
									0.0,
									0.408,
									0.01,
									0.112,
									0.001,
									0.501,
									1.0,
									0.724,
									0.042,
									0.281,
									0.351
								],
								"e": 0.34
							},
							{
								"h": [
									1.0,
									0.482,
									0.791,
									0.062,
									0.445,
									0.074,
									0.437,
									0.483,
									0.25,
									0.048,
									0.978,
									0.025
								],
								"e": 0.612
							},
							{
								"h": [
									1.0,
									0.003,
									1.0,
									1.0,
									0.921,
									0.287,
									0.155,
									0.653,
									0.159,
									1.0,
									0.745,
									0.118
								],
								"e": 0.983
							},
							{
								"h": [
									0.0,
									0.204,
									1.0,
									1.0,
									0.007,
									0.043,
									1.0,
									0.814,
									0.408,
									0.272,
									0.298,
									0.797
								],
								"e": 0.652
							},
							{
								"h": [
									0.108,
									0.157,
									0.048,
									1.0,
									0.1,
									0.261,
									0.249,
									0.183,
									0.015,
									0.202,
									0.142,
									1.0
								],
								"e": 0.432
							},
							{
								"h": [
									0.003,
									0.0,
									0.676,
									0.32,
									0.122,
									0.005,
									0.128,
									0.026,
									0.031,
									0.02,
									0.82,
									0.527
								],
								"e": 0.143
							},
							{
								"h": [
									0.337,
									0.022,
									0.077,
									0.099,
									0.004,
									0.037,
									0.051,
									0.185,
									1.0,
									0.0,
									0.041,
									0.137
								],
								"e": 0.138
							},
							{
								"h": [
									0.09,
									0.008,
									0.001,
									0.017,
									0.099,
									0.054,
									0.048,
									0.076,
									0.026,
									0.005,
									0.013,
									0.01
								],
								"e": 0.034
							},
							{
								"h": [
									0.002,
									0.01,
									0.001,
									0.002,
									0.002,
									0.282,
									0.012,
									0.12,
									0.014,
									0.013,
									0.019,
									0.02
								],
								"e": 0.032
							},
							{
								"h": [
									0.025,
									0.008,
									0.017,
									0.213,
									0.004,
									0.005,
									0.002,
									0.006,
									0.01,
									0.003,
									0.061,
									0.007
								],
								"e": 0.023
							},
							{
								"h": [
									0.034,
									0.003,
									0.009,
									0.007,
									0.004,
									0.312,
									0.002,
									0.007,
									0.004,
									0.358,
									0.007,
									0.007
								],
								"e": 0.046
							},
							{
								"h": [
									0.248,
									0.028,
									0.008,
									0.042,
									0.006,
									0.003,
									0.007,
									0.002,
									0.247,
									0.007,
									0.008,
									0.011
								],
								"e": 0.026
							}
						],
						[
							{
								"h": [
									0.174,
									1.0,
									0.805,
									1.0,
									1.0,
									1.0,
									1.0,
									0.007,
									1.0,
									0.045,
									1.0,
									0.506
								],
								"e": 1.0
							},
							{
								"h": [
									0.136,
									0.251,
									1.0,
									0.067,
									0.489,
									0.108,
									1.0,
									0.351,
									0.276,
									0.098,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.158,
									0.006,
									0.729,
									0.537,
									0.235,
									0.306,
									0.167,
									0.645,
									0.642,
									0.461,
									1.0,
									0.14
								],
								"e": 0.663
							},
							{
								"h": [
									0.711,
									0.189,
									0.209,
									0.103,
									0.001,
									0.044,
									0.12,
									1.0,
									0.331,
									0.19,
									0.596,
									0.751
								],
								"e": 0.417
							},
							{
								"h": [
									0.055,
									0.095,
									0.062,
									0.432,
									0.081,
									0.353,
									0.142,
									1.0,
									0.018,
									0.118,
									0.158,
									0.001
								],
								"e": 0.216
							},
							{
								"h": [
									0.088,
									0.043,
									0.131,
									0.08,
									0.121,
									0.139,
									0.022,
									0.047,
									0.162,
									0.011,
									1.0,
									0.491
								],
								"e": 0.125
							},
							{
								"h": [
									0.183,
									0.043,
									0.056,
									0.024,
									0.004,
									0.074,
									0.068,
									0.321,
									0.162,
									0.019,
									0.083,
									0.13
								],
								"e": 0.083
							},
							{
								"h": [
									0.018,
									0.031,
									0.01,
									0.031,
									0.015,
									0.016,
									0.051,
									0.075,
									0.017,
									0.01,
									0.074,
									0.06
								],
								"e": 0.03
							},
							{
								"h": [
									0.037,
									0.103,
									0.002,
									0.001,
									0.022,
									0.158,
									0.115,
									0.069,
									0.067,
									0.023,
									0.063,
									0.02
								],
								"e": 0.048
							},
							{
								"h": [
									0.017,
									0.009,
									0.042,
									0.183,
									0.002,
									0.013,
									0.012,
									0.021,
									0.019,
									0.018,
									0.103,
									0.029
								],
								"e": 0.031
							},
							{
								"h": [
									0.033,
									0.019,
									0.013,
									0.021,
									0.008,
									0.221,
									0.011,
									0.044,
									0.022,
									0.146,
									0.021,
									0.064
								],
								"e": 0.043
							},
							{
								"h": [
									0.249,
									0.034,
									0.017,
									0.026,
									0.034,
									0.009,
									0.03,
									0.015,
									0.195,
									0.051,
									0.018,
									0.078
								],
								"e": 0.041
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ".",
					"o": " Vill",
					"t": " Vill",
					"r": [
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.117,
								" It": 0.046,
								" And": 0.031,
								" In": 0.029,
								" If": 0.029,
								" He": 0.024,
								" I": 0.022,
								" There": 0.02,
								" You": 0.019,
								" ": 0.017
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.093,
								" It": 0.069,
								" He": 0.035,
								" ": 0.033,
								" There": 0.029,
								" When": 0.027,
								" I": 0.026,
								" His": 0.024,
								" If": 0.023,
								" We": 0.021
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.071,
								" It": 0.065,
								" The": 0.048,
								" And": 0.034,
								" That": 0.033,
								" There": 0.032,
								" You": 0.029,
								" His": 0.029,
								" When": 0.027,
								" He": 0.024
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.325,
								" The": 0.061,
								"<|endoftext|>": 0.039,
								" It": 0.036,
								" There": 0.032,
								" When": 0.03,
								" And": 0.018,
								" That": 0.018,
								" If": 0.017,
								"  ": 0.015
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.23,
								" It": 0.033,
								"<|endoftext|>": 0.033,
								" And": 0.025,
								" There": 0.025,
								"  ": 0.022,
								" He": 0.021,
								" His": 0.02,
								" You": 0.018,
								" When": 0.015
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.138,
								" He": 0.106,
								" The": 0.044,
								" His": 0.036,
								" Can": 0.026,
								" And": 0.025,
								" When": 0.021,
								" Where": 0.02,
								" It": 0.019,
								" Some": 0.019
							}
						},
						{
							"p": " ",
							"b": 0.0,
							"k": {
								" ": 0.239,
								"<|endoftext|>": 0.1,
								" He": 0.098,
								" And": 0.063,
								" All": 0.031,
								" ----------------------------------------------------------------": 0.031,
								" Or": 0.026,
								" The": 0.019,
								" Finally": 0.019,
								" Can": 0.017
							}
						},
						{
							"p": " ",
							"b": 0.005,
							"k": {
								" ": 0.155,
								" Kid": 0.107,
								" And": 0.075,
								"<|endoftext|>": 0.069,
								" Total": 0.038,
								" He": 0.037,
								" Can": 0.033,
								" All": 0.024,
								" Or": 0.02,
								" You": 0.014
							}
						},
						{
							"p": " ",
							"b": 0.01,
							"k": {
								" ": 0.289,
								"<|endoftext|>": 0.273,
								" All": 0.047,
								" Total": 0.026,
								"  ": 0.018,
								" Note": 0.015,
								" Kid": 0.014,
								" Other": 0.012,
								" Let": 0.012,
								" You": 0.01
							}
						},
						{
							"p": " Hero",
							"b": 0.138,
							"k": {
								" Hero": 0.553,
								" ": 0.197,
								" Vill": 0.138,
								"<|endoftext|>": 0.035,
								"  ": 0.025,
								" Kid": 0.005,
								" All": 0.003,
								" View": 0.002,
								" You": 0.001,
								" Super": 0.001
							}
						},
						{
							"p": " Vill",
							"b": 0.662,
							"k": {
								" Vill": 0.662,
								" Hero": 0.29,
								" ": 0.03,
								"  ": 0.004,
								"<|endoftext|>": 0.003,
								" Batman": 0.001,
								" Heroes": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0
							}
						},
						{
							"p": " Vill",
							"b": 0.325,
							"k": {
								" Vill": 0.325,
								" Hero": 0.193,
								" ": 0.19,
								"  ": 0.017,
								"<|endoftext|>": 0.016,
								" Batman": 0.006,
								" (": 0.005,
								" Heroes": 0.005,
								" The": 0.004,
								" [": 0.003
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.723,
									0.001,
									0.334,
									0.004,
									0.023,
									0.087,
									0.543,
									0.01,
									0.018,
									0.837,
									0.304,
									1.0
								],
								"e": 0.189
							},
							{
								"h": [
									0.061,
									0.051,
									0.128,
									1.0,
									1.0,
									0.894,
									0.944,
									1.0,
									1.0,
									1.0,
									0.033,
									0.301
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.019,
									0.129,
									1.0,
									0.154,
									0.166,
									0.889,
									0.355,
									0.079,
									0.161,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.529,
									0.185,
									1.0,
									1.0,
									1.0,
									0.112,
									0.162,
									0.207,
									1.0,
									1.0,
									0.619
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.868,
									0.069,
									1.0,
									0.596,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.393,
									1.0,
									1.0,
									0.768,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.476,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.347,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.504,
									1.0,
									1.0,
									1.0,
									1.0,
									0.643,
									1.0,
									1.0,
									0.479,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.607,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.895,
									1.0,
									1.0,
									1.0,
									0.092
								],
								"e": 1.0
							},
							{
								"h": [
									0.317,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									0.505,
									0.194
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.07,
									0.007,
									0.578,
									0.0,
									0.01,
									0.272,
									0.018,
									0.005,
									0.087,
									0.623,
									0.275,
									0.62
								],
								"e": 0.128
							},
							{
								"h": [
									0.01,
									0.017,
									0.073,
									0.007,
									0.105,
									0.611,
									0.965,
									0.238,
									0.504,
									0.182,
									0.08,
									0.29
								],
								"e": 0.242
							},
							{
								"h": [
									0.013,
									0.011,
									0.0,
									0.001,
									0.001,
									0.001,
									0.016,
									0.072,
									0.003,
									0.0,
									0.029,
									0.003
								],
								"e": 0.014
							},
							{
								"h": [
									0.025,
									0.005,
									0.0,
									0.0,
									0.095,
									0.061,
									0.0,
									0.0,
									0.0,
									0.004,
									0.007,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.0,
									0.001,
									0.159,
									0.001,
									0.063,
									0.0,
									0.01,
									0.001,
									0.139,
									0.013,
									0.091,
									0.0
								],
								"e": 0.049
							},
							{
								"h": [
									0.0,
									0.0,
									0.003,
									0.023,
									0.0,
									0.005,
									0.0,
									0.0,
									0.0,
									0.02,
									0.138,
									0.019
								],
								"e": 0.012
							},
							{
								"h": [
									0.007,
									0.008,
									0.031,
									0.022,
									0.02,
									0.008,
									0.022,
									0.025,
									0.0,
									0.001,
									0.014,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.021,
									0.006,
									0.01,
									0.009,
									0.016,
									0.013,
									0.109,
									0.0,
									0.001,
									0.004,
									0.001
								],
								"e": 0.011
							},
							{
								"h": [
									0.007,
									0.007,
									0.048,
									0.02,
									0.001,
									0.002,
									0.061,
									0.0,
									0.034,
									0.066,
									0.007,
									0.003
								],
								"e": 0.015
							},
							{
								"h": [
									0.041,
									0.01,
									0.04,
									0.006,
									0.513,
									0.004,
									0.005,
									0.039,
									0.007,
									0.003,
									0.002,
									0.004
								],
								"e": 0.039
							},
							{
								"h": [
									0.017,
									0.011,
									0.018,
									0.033,
									0.065,
									0.017,
									0.066,
									0.237,
									0.015,
									0.006,
									0.016,
									0.011
								],
								"e": 0.031
							},
							{
								"h": [
									0.445,
									0.168,
									0.104,
									0.23,
									0.114,
									0.226,
									0.04,
									0.088,
									0.323,
									0.074,
									0.383,
									0.389
								],
								"e": 0.22
							}
						],
						[
							{
								"h": [
									0.124,
									0.0,
									0.091,
									0.0,
									0.005,
									0.035,
									0.314,
									0.002,
									0.01,
									0.509,
									0.162,
									0.404
								],
								"e": 0.077
							},
							{
								"h": [
									0.009,
									0.009,
									0.096,
									0.01,
									0.134,
									0.113,
									0.107,
									0.147,
									0.283,
									0.348,
									0.127,
									0.161
								],
								"e": 0.143
							},
							{
								"h": [
									0.007,
									0.001,
									0.0,
									0.001,
									0.001,
									0.0,
									0.005,
									0.16,
									0.0,
									0.0,
									0.052,
									0.005
								],
								"e": 0.013
							},
							{
								"h": [
									0.004,
									0.0,
									0.0,
									0.0,
									0.019,
									0.005,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.0,
									0.018,
									0.0,
									0.02,
									0.0,
									0.002,
									0.0,
									0.033,
									0.001,
									0.004,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.001,
									0.004,
									0.006,
									0.0,
									0.027,
									0.0,
									0.0,
									0.001,
									0.032,
									0.026,
									0.011
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.001,
									0.019,
									0.012,
									0.004,
									0.005,
									0.016,
									0.006,
									0.0,
									0.011,
									0.007,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.004,
									0.036,
									0.001,
									0.001,
									0.001,
									0.016,
									0.021,
									0.0,
									0.0,
									0.022,
									0.013
								],
								"e": 0.006
							},
							{
								"h": [
									0.001,
									0.013,
									0.045,
									0.002,
									0.0,
									0.002,
									0.005,
									0.0,
									0.003,
									0.028,
									0.002,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.018,
									0.001,
									0.008,
									0.001,
									0.014,
									0.003,
									0.013,
									0.003,
									0.018,
									0.005,
									0.0,
									0.007
								],
								"e": 0.006
							},
							{
								"h": [
									0.068,
									0.019,
									0.035,
									0.031,
									0.002,
									0.003,
									0.035,
									0.024,
									0.006,
									0.0,
									0.028,
									0.004
								],
								"e": 0.019
							},
							{
								"h": [
									0.102,
									0.703,
									0.252,
									0.115,
									0.03,
									0.019,
									0.041,
									0.032,
									0.114,
									0.025,
									0.078,
									0.047
								],
								"e": 0.123
							}
						],
						[
							{
								"h": [
									0.465,
									0.0,
									0.138,
									0.0,
									0.002,
									0.036,
									0.337,
									0.002,
									0.004,
									0.436,
									0.185,
									0.417
								],
								"e": 0.097
							},
							{
								"h": [
									0.006,
									0.008,
									0.124,
									0.01,
									0.129,
									0.146,
									0.066,
									0.112,
									0.31,
									0.419,
									0.155,
									0.095
								],
								"e": 0.147
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0,
									0.005,
									0.212,
									0.0,
									0.0,
									0.061,
									0.007
								],
								"e": 0.015
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.0,
									0.014,
									0.007,
									0.0,
									0.0,
									0.0,
									0.0,
									0.004,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.0,
									0.004,
									0.001,
									0.027,
									0.0,
									0.003,
									0.002,
									0.014,
									0.001,
									0.006,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.001,
									0.0,
									0.004,
									0.0,
									0.0,
									0.0,
									0.009,
									0.013,
									0.018
								],
								"e": 0.004
							},
							{
								"h": [
									0.001,
									0.0,
									0.008,
									0.009,
									0.001,
									0.007,
									0.004,
									0.007,
									0.001,
									0.001,
									0.003,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.002,
									0.01,
									0.0,
									0.0,
									0.001,
									0.007,
									0.01,
									0.0,
									0.0,
									0.001,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.001,
									0.003,
									0.023,
									0.0,
									0.0,
									0.001,
									0.01,
									0.0,
									0.003,
									0.025,
									0.001,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.028,
									0.0,
									0.004,
									0.0,
									0.005,
									0.003,
									0.006,
									0.003,
									0.011,
									0.001,
									0.0,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.046,
									0.007,
									0.005,
									0.011,
									0.002,
									0.001,
									0.009,
									0.008,
									0.002,
									0.0,
									0.016,
									0.008
								],
								"e": 0.008
							},
							{
								"h": [
									0.127,
									0.079,
									0.222,
									0.13,
									0.05,
									0.02,
									0.04,
									0.043,
									0.309,
									0.042,
									0.097,
									0.053
								],
								"e": 0.109
							}
						],
						[
							{
								"h": [
									0.028,
									0.39,
									0.146,
									0.0,
									0.012,
									1.0,
									0.004,
									0.003,
									0.314,
									0.572,
									0.528,
									0.713
								],
								"e": 0.248
							},
							{
								"h": [
									0.008,
									0.01,
									0.119,
									0.01,
									0.117,
									0.649,
									0.733,
									0.186,
									0.373,
									0.098,
									0.117,
									0.238
								],
								"e": 0.207
							},
							{
								"h": [
									0.003,
									0.028,
									0.0,
									0.0,
									0.0,
									0.0,
									0.049,
									0.1,
									0.0,
									0.0,
									0.029,
									0.003
								],
								"e": 0.023
							},
							{
								"h": [
									0.02,
									0.0,
									0.0,
									0.0,
									0.044,
									0.022,
									0.0,
									0.0,
									0.0,
									0.0,
									0.008,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.0,
									0.066,
									0.0,
									0.058,
									0.0,
									0.003,
									0.022,
									0.326,
									0.001,
									0.032,
									0.0
								],
								"e": 0.044
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.008,
									0.0,
									0.005,
									0.0,
									0.0,
									0.0,
									0.033,
									0.1,
									0.039
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.002,
									0.034,
									0.039,
									0.042,
									0.007,
									0.029,
									0.009,
									0.0,
									0.003,
									0.026,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.009,
									0.011,
									0.001,
									0.002,
									0.022,
									0.03,
									0.023,
									0.0,
									0.0,
									0.008,
									0.01
								],
								"e": 0.008
							},
							{
								"h": [
									0.007,
									0.012,
									0.034,
									0.002,
									0.001,
									0.009,
									0.029,
									0.0,
									0.026,
									0.132,
									0.004,
									0.004
								],
								"e": 0.013
							},
							{
								"h": [
									0.041,
									0.019,
									0.023,
									0.004,
									0.083,
									0.003,
									0.001,
									0.042,
									0.003,
									0.003,
									0.002,
									0.008
								],
								"e": 0.015
							},
							{
								"h": [
									0.005,
									0.009,
									0.019,
									0.027,
									0.077,
									0.039,
									0.05,
									0.34,
									0.027,
									0.017,
									0.007,
									0.015
								],
								"e": 0.038
							},
							{
								"h": [
									1.0,
									0.094,
									0.086,
									0.489,
									0.179,
									0.338,
									0.033,
									0.159,
									1.0,
									0.043,
									0.555,
									1.0
								],
								"e": 0.414
							}
						],
						[
							{
								"h": [
									0.371,
									0.0,
									0.102,
									0.0,
									0.002,
									0.017,
									0.097,
									0.003,
									0.007,
									0.385,
									0.162,
									0.256
								],
								"e": 0.064
							},
							{
								"h": [
									0.014,
									0.011,
									0.142,
									0.011,
									0.154,
									0.063,
									0.033,
									0.08,
									0.315,
									0.267,
									0.166,
									0.06
								],
								"e": 0.121
							},
							{
								"h": [
									0.011,
									0.003,
									0.0,
									0.001,
									0.0,
									0.0,
									0.006,
									0.15,
									0.0,
									0.0,
									0.047,
									0.005
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.0,
									0.006,
									0.017,
									0.0,
									0.0,
									0.0,
									0.001,
									0.003,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.0,
									0.003,
									0.002,
									0.008,
									0.0,
									0.005,
									0.002,
									0.025,
									0.003,
									0.011,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.603,
									0.383,
									0.002,
									0.01,
									0.0,
									0.14,
									0.0,
									0.0,
									0.028,
									0.1,
									0.02,
									0.008
								],
								"e": 0.089
							},
							{
								"h": [
									0.004,
									0.002,
									0.005,
									0.035,
									0.004,
									0.006,
									0.036,
									0.014,
									0.0,
									0.156,
									0.05,
									0.0
								],
								"e": 0.031
							},
							{
								"h": [
									0.0,
									0.016,
									1.0,
									0.012,
									0.004,
									0.002,
									0.081,
									0.119,
									0.0,
									0.003,
									0.211,
									0.376
								],
								"e": 0.1
							},
							{
								"h": [
									0.01,
									0.189,
									0.005,
									0.007,
									0.0,
									0.003,
									0.065,
									0.0,
									0.008,
									0.029,
									0.014,
									0.001
								],
								"e": 0.023
							},
							{
								"h": [
									0.102,
									0.006,
									0.09,
									0.0,
									0.093,
									0.002,
									0.416,
									0.003,
									0.006,
									0.034,
									0.0,
									0.039
								],
								"e": 0.046
							},
							{
								"h": [
									0.296,
									0.102,
									0.064,
									0.025,
									0.009,
									0.001,
									0.811,
									0.776,
									0.003,
									0.0,
									0.376,
									0.055
								],
								"e": 0.125
							},
							{
								"h": [
									0.069,
									0.153,
									0.17,
									0.053,
									0.049,
									0.028,
									0.067,
									0.108,
									0.021,
									0.147,
									0.231,
									0.042
								],
								"e": 0.108
							}
						],
						[
							{
								"h": [
									0.189,
									0.0,
									0.159,
									0.0,
									0.006,
									0.032,
									0.037,
									0.002,
									0.006,
									0.408,
									0.154,
									0.244
								],
								"e": 0.055
							},
							{
								"h": [
									0.011,
									0.005,
									0.103,
									0.009,
									0.111,
									0.11,
									0.117,
									0.109,
									0.207,
									0.168,
									0.135,
									0.146
								],
								"e": 0.109
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0,
									0.004,
									0.131,
									0.0,
									0.0,
									0.043,
									0.005
								],
								"e": 0.01
							},
							{
								"h": [
									0.003,
									0.0,
									0.0,
									0.0,
									0.007,
									0.015,
									0.0,
									0.0,
									0.0,
									0.001,
									0.004,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.0,
									0.004,
									0.001,
									0.015,
									0.0,
									0.005,
									0.002,
									0.015,
									0.005,
									0.006,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.028,
									0.0,
									0.002,
									0.004,
									0.0,
									0.0,
									0.0,
									0.0,
									0.001,
									0.017,
									0.016,
									0.009
								],
								"e": 0.005
							},
							{
								"h": [
									0.004,
									0.004,
									0.002,
									0.023,
									0.002,
									0.005,
									0.023,
									0.015,
									0.001,
									0.0,
									0.102,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.006,
									0.011,
									0.002,
									0.001,
									0.003,
									0.023,
									0.25,
									0.0,
									0.0,
									0.01,
									0.022
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.056,
									0.008,
									0.002,
									0.0,
									0.002,
									0.317,
									0.0,
									0.014,
									0.026,
									0.004,
									0.002
								],
								"e": 0.02
							},
							{
								"h": [
									0.015,
									0.002,
									0.071,
									0.001,
									0.048,
									0.002,
									0.146,
									0.006,
									0.006,
									0.029,
									0.001,
									0.003
								],
								"e": 0.02
							},
							{
								"h": [
									0.133,
									0.12,
									0.007,
									0.039,
									0.044,
									0.004,
									0.096,
									0.046,
									0.002,
									0.001,
									0.087,
									0.089
								],
								"e": 0.048
							},
							{
								"h": [
									0.149,
									0.269,
									0.054,
									0.097,
									0.031,
									0.022,
									0.03,
									0.115,
									0.065,
									0.261,
									0.203,
									0.033
								],
								"e": 0.12
							}
						],
						[
							{
								"h": [
									0.057,
									0.006,
									0.734,
									0.0,
									0.007,
									0.124,
									0.011,
									0.005,
									0.13,
									0.603,
									0.36,
									0.549
								],
								"e": 0.128
							},
							{
								"h": [
									0.015,
									0.046,
									0.139,
									0.012,
									0.125,
									0.546,
									1.0,
									0.216,
									0.338,
									0.122,
									0.143,
									0.337
								],
								"e": 0.241
							},
							{
								"h": [
									0.009,
									0.039,
									0.0,
									0.001,
									0.0,
									0.001,
									0.025,
									0.248,
									0.001,
									0.0,
									0.042,
									0.005
								],
								"e": 0.03
							},
							{
								"h": [
									0.032,
									0.004,
									0.0,
									0.0,
									0.194,
									0.036,
									0.0,
									0.0,
									0.0,
									0.008,
									0.006,
									0.0
								],
								"e": 0.035
							},
							{
								"h": [
									0.0,
									0.001,
									0.09,
									0.001,
									0.117,
									0.0,
									0.013,
									0.006,
									0.149,
									0.013,
									0.074,
									0.0
								],
								"e": 0.047
							},
							{
								"h": [
									0.004,
									0.0,
									0.005,
									0.018,
									0.0,
									0.001,
									0.0,
									0.0,
									0.001,
									0.035,
									0.146,
									0.02
								],
								"e": 0.014
							},
							{
								"h": [
									0.019,
									0.004,
									0.012,
									0.076,
									0.03,
									0.007,
									0.041,
									0.028,
									0.0,
									0.0,
									0.186,
									0.002
								],
								"e": 0.027
							},
							{
								"h": [
									0.0,
									0.042,
									0.001,
									0.022,
									0.005,
									0.007,
									0.028,
									1.0,
									0.0,
									0.0,
									0.001,
									0.0
								],
								"e": 0.037
							},
							{
								"h": [
									0.003,
									0.003,
									0.041,
									0.009,
									0.0,
									0.001,
									0.043,
									0.0,
									0.049,
									0.155,
									0.008,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.008,
									0.003,
									0.007,
									0.002,
									0.109,
									0.005,
									0.0,
									0.01,
									0.0,
									0.0,
									0.002,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.001,
									0.004,
									0.005,
									0.013,
									0.007,
									0.008,
									0.077,
									0.002,
									0.001,
									0.002,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.209,
									0.062,
									0.023,
									0.026,
									0.046,
									0.057,
									0.008,
									0.034,
									0.088,
									0.016,
									0.11,
									0.049
								],
								"e": 0.059
							}
						],
						[
							{
								"h": [
									0.581,
									0.0,
									0.106,
									0.0,
									0.003,
									0.012,
									0.641,
									0.004,
									0.007,
									0.494,
									0.209,
									0.409
								],
								"e": 0.124
							},
							{
								"h": [
									0.026,
									0.028,
									0.239,
									0.018,
									0.217,
									0.343,
									0.205,
									0.332,
									0.525,
									0.516,
									0.275,
									0.184
								],
								"e": 0.265
							},
							{
								"h": [
									0.011,
									0.009,
									0.0,
									0.003,
									0.002,
									0.0,
									0.024,
									0.406,
									0.002,
									0.001,
									0.153,
									0.016
								],
								"e": 0.038
							},
							{
								"h": [
									0.003,
									0.002,
									0.0,
									0.0,
									0.046,
									0.014,
									0.001,
									0.0,
									0.0,
									0.002,
									0.012,
									0.007
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.0,
									0.026,
									0.007,
									0.039,
									0.001,
									0.009,
									0.005,
									0.024,
									0.004,
									0.006,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.012,
									0.002,
									0.008,
									0.005,
									0.001,
									0.023,
									0.0,
									0.0,
									0.004,
									0.045,
									0.034,
									0.016
								],
								"e": 0.011
							},
							{
								"h": [
									0.003,
									0.002,
									0.012,
									0.014,
									0.004,
									0.01,
									0.016,
									0.027,
									0.003,
									0.003,
									0.087,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.007,
									0.036,
									0.001,
									0.0,
									0.001,
									0.039,
									0.123,
									0.0,
									0.0,
									0.033,
									0.029
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.012,
									0.022,
									0.001,
									0.0,
									0.001,
									0.002,
									0.0,
									0.007,
									0.081,
									0.002,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.004,
									0.0,
									0.014,
									0.001,
									0.002,
									0.002,
									0.008,
									0.002,
									0.014,
									0.002,
									0.0,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.063,
									0.017,
									0.008,
									0.011,
									0.005,
									0.002,
									0.037,
									0.019,
									0.005,
									0.0,
									0.03,
									0.006
								],
								"e": 0.013
							},
							{
								"h": [
									0.075,
									0.082,
									0.202,
									0.077,
									0.041,
									0.012,
									0.042,
									0.014,
									0.015,
									0.081,
									0.032,
									0.057
								],
								"e": 0.071
							}
						],
						[
							{
								"h": [
									0.029,
									0.515,
									0.15,
									0.0,
									0.014,
									0.757,
									0.004,
									0.004,
									0.436,
									0.587,
									0.637,
									0.622
								],
								"e": 0.259
							},
							{
								"h": [
									0.017,
									0.026,
									0.192,
									0.017,
									0.157,
									0.766,
									0.712,
									0.198,
									0.382,
									0.094,
									0.209,
									0.313
								],
								"e": 0.243
							},
							{
								"h": [
									0.007,
									0.03,
									0.0,
									0.0,
									0.001,
									0.0,
									0.088,
									0.187,
									0.001,
									0.0,
									0.058,
									0.007
								],
								"e": 0.036
							},
							{
								"h": [
									0.028,
									0.001,
									0.0,
									0.0,
									0.068,
									0.024,
									0.0,
									0.0,
									0.0,
									0.002,
									0.012,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.0,
									0.001,
									0.059,
									0.001,
									0.077,
									0.0,
									0.009,
									0.04,
									0.43,
									0.002,
									0.031,
									0.0
								],
								"e": 0.056
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.01,
									0.001,
									0.002,
									0.0,
									0.0,
									0.0,
									0.035,
									0.081,
									0.062
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.002,
									0.025,
									0.046,
									0.064,
									0.011,
									0.115,
									0.015,
									0.0,
									0.001,
									0.161,
									0.001
								],
								"e": 0.032
							},
							{
								"h": [
									0.0,
									0.014,
									0.005,
									0.003,
									0.0,
									0.009,
									0.079,
									0.077,
									0.0,
									0.0,
									0.01,
									0.007
								],
								"e": 0.01
							},
							{
								"h": [
									0.01,
									0.009,
									0.027,
									0.005,
									0.0,
									0.001,
									0.041,
									0.001,
									0.038,
									0.144,
									0.017,
									0.002
								],
								"e": 0.014
							},
							{
								"h": [
									0.016,
									0.026,
									0.021,
									0.001,
									0.023,
									0.017,
									0.001,
									0.038,
									0.003,
									0.002,
									0.001,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.005,
									0.009,
									0.014,
									0.034,
									0.054,
									0.017,
									0.028,
									0.175,
									0.029,
									0.008,
									0.008,
									0.014
								],
								"e": 0.027
							},
							{
								"h": [
									0.707,
									0.072,
									0.046,
									0.133,
									0.138,
									0.123,
									0.023,
									0.062,
									0.395,
									0.029,
									0.211,
									0.322
								],
								"e": 0.181
							}
						],
						[
							{
								"h": [
									0.203,
									0.0,
									0.153,
									0.0,
									0.005,
									0.021,
									0.31,
									0.003,
									0.007,
									0.448,
									0.15,
									0.24
								],
								"e": 0.074
							},
							{
								"h": [
									0.055,
									0.042,
									0.3,
									0.02,
									0.218,
									0.162,
									0.07,
									0.107,
									0.39,
									0.182,
									0.294,
									0.097
								],
								"e": 0.174
							},
							{
								"h": [
									0.012,
									0.002,
									0.0,
									0.005,
									0.001,
									0.0,
									0.023,
									0.502,
									0.003,
									0.001,
									0.128,
									0.016
								],
								"e": 0.037
							},
							{
								"h": [
									0.005,
									0.001,
									0.001,
									0.0,
									0.036,
									0.029,
									0.002,
									0.0,
									0.0,
									0.009,
									0.012,
									0.011
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.001,
									0.023,
									0.01,
									0.05,
									0.001,
									0.032,
									0.007,
									0.092,
									0.023,
									0.006,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.789,
									0.056,
									0.004,
									0.043,
									0.002,
									0.147,
									0.0,
									0.002,
									0.059,
									0.094,
									0.072,
									0.021
								],
								"e": 0.075
							},
							{
								"h": [
									0.028,
									0.04,
									0.008,
									0.126,
									0.068,
									0.028,
									0.205,
									0.105,
									0.001,
									0.113,
									0.248,
									0.035
								],
								"e": 0.077
							},
							{
								"h": [
									0.0,
									0.031,
									0.449,
									0.06,
									0.002,
									0.019,
									0.276,
									0.407,
									0.001,
									0.014,
									1.0,
									0.344
								],
								"e": 0.142
							},
							{
								"h": [
									0.014,
									0.057,
									0.178,
									0.016,
									0.0,
									0.012,
									1.0,
									0.001,
									0.22,
									0.123,
									0.089,
									0.004
								],
								"e": 0.086
							},
							{
								"h": [
									0.8,
									0.005,
									0.251,
									0.005,
									0.129,
									0.017,
									0.506,
									0.061,
									0.084,
									0.107,
									0.005,
									0.024
								],
								"e": 0.115
							},
							{
								"h": [
									0.494,
									0.296,
									0.08,
									0.074,
									0.042,
									0.011,
									0.608,
									1.0,
									0.011,
									0.003,
									0.235,
									1.0
								],
								"e": 0.234
							},
							{
								"h": [
									0.381,
									0.756,
									0.113,
									0.072,
									0.062,
									0.021,
									0.133,
									0.185,
									0.088,
									0.176,
									1.0,
									0.058
								],
								"e": 0.236
							}
						],
						[
							{
								"h": [
									0.064,
									0.007,
									0.8,
									0.001,
									0.013,
									0.103,
									0.013,
									0.012,
									0.158,
									0.648,
									0.411,
									0.513
								],
								"e": 0.138
							},
							{
								"h": [
									0.033,
									0.102,
									0.205,
									0.018,
									0.162,
									0.584,
									0.939,
									0.225,
									0.338,
									0.109,
									0.22,
									0.404
								],
								"e": 0.272
							},
							{
								"h": [
									0.021,
									0.04,
									0.0,
									0.002,
									0.001,
									0.002,
									0.041,
									0.343,
									0.004,
									0.0,
									0.076,
									0.009
								],
								"e": 0.043
							},
							{
								"h": [
									0.022,
									0.033,
									0.001,
									0.0,
									0.28,
									0.05,
									0.0,
									0.0,
									0.0,
									0.041,
									0.019,
									0.002
								],
								"e": 0.051
							},
							{
								"h": [
									0.002,
									0.008,
									0.183,
									0.006,
									0.112,
									0.004,
									0.053,
									0.008,
									0.198,
									0.089,
									0.07,
									0.0
								],
								"e": 0.07
							},
							{
								"h": [
									0.004,
									0.0,
									0.011,
									0.076,
									0.002,
									0.001,
									0.0,
									0.001,
									0.001,
									0.032,
									0.18,
									0.042
								],
								"e": 0.02
							},
							{
								"h": [
									0.065,
									0.011,
									0.009,
									0.152,
									0.041,
									0.02,
									0.091,
									0.056,
									0.0,
									0.0,
									0.102,
									0.005
								],
								"e": 0.037
							},
							{
								"h": [
									0.0,
									0.014,
									0.0,
									0.02,
									0.003,
									0.004,
									0.025,
									0.577,
									0.0,
									0.001,
									0.004,
									0.001
								],
								"e": 0.022
							},
							{
								"h": [
									0.007,
									0.002,
									0.009,
									0.018,
									0.0,
									0.001,
									0.033,
									0.0,
									0.085,
									0.114,
									0.01,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.007,
									0.002,
									0.012,
									0.002,
									0.017,
									0.004,
									0.0,
									0.011,
									0.001,
									0.0,
									0.002,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.002,
									0.001,
									0.004,
									0.004,
									0.007,
									0.006,
									0.007,
									0.061,
									0.001,
									0.002,
									0.002,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.206,
									0.063,
									0.025,
									0.022,
									0.037,
									0.041,
									0.01,
									0.023,
									0.048,
									0.009,
									0.08,
									0.04
								],
								"e": 0.047
							}
						],
						[
							{
								"h": [
									0.455,
									0.0,
									0.107,
									0.001,
									0.008,
									0.005,
									1.0,
									0.004,
									0.007,
									0.536,
									0.231,
									0.234
								],
								"e": 0.138
							},
							{
								"h": [
									0.075,
									0.049,
									0.285,
									0.022,
									0.268,
									0.296,
									0.114,
									0.222,
									0.363,
									0.282,
									0.366,
									0.331
								],
								"e": 0.242
							},
							{
								"h": [
									0.024,
									0.003,
									0.0,
									0.013,
									0.006,
									0.001,
									0.024,
									0.527,
									0.003,
									0.004,
									0.2,
									0.022
								],
								"e": 0.049
							},
							{
								"h": [
									0.005,
									0.001,
									0.001,
									0.0,
									0.03,
									0.006,
									0.004,
									0.0,
									0.0,
									0.004,
									0.014,
									0.011
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.001,
									0.013,
									0.01,
									0.063,
									0.002,
									0.028,
									0.008,
									0.024,
									0.013,
									0.008,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.011,
									0.001,
									0.015,
									0.024,
									0.002,
									0.012,
									0.0,
									0.001,
									0.008,
									0.108,
									0.046,
									0.019
								],
								"e": 0.017
							},
							{
								"h": [
									0.022,
									0.004,
									0.013,
									0.043,
									0.003,
									0.015,
									0.024,
									0.032,
									0.006,
									0.002,
									0.076,
									0.001
								],
								"e": 0.017
							},
							{
								"h": [
									0.001,
									0.005,
									0.018,
									0.003,
									0.001,
									0.001,
									0.058,
									0.091,
									0.001,
									0.0,
									0.025,
									0.008
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.01,
									0.019,
									0.0,
									0.0,
									0.002,
									0.004,
									0.002,
									0.003,
									0.177,
									0.002,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.009,
									0.0,
									0.005,
									0.005,
									0.001,
									0.002,
									0.013,
									0.002,
									0.02,
									0.002,
									0.001,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.091,
									0.007,
									0.019,
									0.006,
									0.001,
									0.003,
									0.034,
									0.034,
									0.006,
									0.001,
									0.02,
									0.006
								],
								"e": 0.014
							},
							{
								"h": [
									0.077,
									0.098,
									0.148,
									0.067,
									0.033,
									0.01,
									0.038,
									0.058,
									0.01,
									0.009,
									0.06,
									0.041
								],
								"e": 0.059
							}
						],
						[
							{
								"h": [
									0.034,
									0.638,
									0.162,
									0.002,
									0.034,
									0.67,
									0.004,
									0.012,
									0.549,
									0.649,
									0.733,
									0.592
								],
								"e": 0.29
							},
							{
								"h": [
									0.049,
									0.063,
									0.271,
									0.023,
									0.21,
									0.81,
									0.676,
									0.212,
									0.375,
									0.083,
									0.309,
									0.369
								],
								"e": 0.28
							},
							{
								"h": [
									0.019,
									0.024,
									0.0,
									0.001,
									0.003,
									0.001,
									0.146,
									0.265,
									0.002,
									0.0,
									0.103,
									0.014
								],
								"e": 0.052
							},
							{
								"h": [
									0.02,
									0.003,
									0.0,
									0.0,
									0.081,
									0.033,
									0.0,
									0.0,
									0.0,
									0.008,
									0.033,
									0.005
								],
								"e": 0.023
							},
							{
								"h": [
									0.001,
									0.007,
									0.113,
									0.003,
									0.093,
									0.003,
									0.03,
									0.07,
									0.311,
									0.007,
									0.032,
									0.0
								],
								"e": 0.062
							},
							{
								"h": [
									0.001,
									0.0,
									0.003,
									0.039,
									0.003,
									0.001,
									0.0,
									0.002,
									0.001,
									0.036,
									0.097,
									0.107
								],
								"e": 0.02
							},
							{
								"h": [
									0.008,
									0.004,
									0.031,
									0.124,
									0.112,
									0.027,
									0.211,
									0.032,
									0.001,
									0.001,
									0.061,
									0.004
								],
								"e": 0.045
							},
							{
								"h": [
									0.0,
									0.017,
									0.004,
									0.004,
									0.0,
									0.008,
									0.063,
									0.042,
									0.0,
									0.001,
									0.003,
									0.007
								],
								"e": 0.009
							},
							{
								"h": [
									0.007,
									0.013,
									0.012,
									0.011,
									0.001,
									0.003,
									0.043,
									0.005,
									0.053,
									0.327,
									0.018,
									0.003
								],
								"e": 0.021
							},
							{
								"h": [
									0.027,
									0.016,
									0.033,
									0.006,
									0.027,
									0.048,
									0.001,
									0.045,
									0.003,
									0.003,
									0.004,
									0.001
								],
								"e": 0.016
							},
							{
								"h": [
									0.003,
									0.006,
									0.011,
									0.018,
									0.042,
									0.025,
									0.03,
									0.125,
									0.021,
									0.013,
									0.006,
									0.012
								],
								"e": 0.022
							},
							{
								"h": [
									0.323,
									0.064,
									0.027,
									0.084,
									0.104,
									0.104,
									0.016,
									0.079,
									0.309,
									0.034,
									0.166,
									0.217
								],
								"e": 0.13
							}
						],
						[
							{
								"h": [
									0.567,
									0.0,
									0.119,
									0.002,
									0.008,
									0.01,
									0.14,
									0.018,
									0.009,
									0.45,
									0.207,
									0.22
								],
								"e": 0.084
							},
							{
								"h": [
									0.089,
									0.054,
									0.334,
									0.022,
									0.3,
									0.071,
									0.029,
									0.061,
									0.341,
									0.3,
									0.368,
									0.08
								],
								"e": 0.193
							},
							{
								"h": [
									0.075,
									0.001,
									0.0,
									0.023,
									0.008,
									0.001,
									0.029,
									0.519,
									0.003,
									0.006,
									0.148,
									0.019
								],
								"e": 0.053
							},
							{
								"h": [
									0.001,
									0.002,
									0.012,
									0.0,
									0.01,
									0.015,
									0.008,
									0.0,
									0.0,
									0.019,
									0.025,
									0.018
								],
								"e": 0.011
							},
							{
								"h": [
									0.001,
									0.006,
									0.01,
									0.027,
									0.027,
									0.005,
									0.037,
									0.006,
									0.014,
									0.024,
									0.006,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									1.0,
									0.035,
									0.006,
									0.133,
									0.004,
									0.067,
									0.0,
									0.004,
									0.112,
									0.105,
									0.017,
									0.017
								],
								"e": 0.081
							},
							{
								"h": [
									0.043,
									0.017,
									0.005,
									0.136,
									0.012,
									0.042,
									0.154,
									0.093,
									0.001,
									0.021,
									0.1,
									0.117
								],
								"e": 0.053
							},
							{
								"h": [
									0.0,
									0.011,
									0.262,
									0.127,
									0.001,
									0.004,
									0.192,
									0.14,
									0.002,
									0.078,
									0.179,
									0.367
								],
								"e": 0.08
							},
							{
								"h": [
									0.014,
									0.056,
									0.003,
									0.005,
									0.0,
									0.004,
									0.04,
									0.001,
									0.044,
									0.054,
									0.095,
									0.001
								],
								"e": 0.021
							},
							{
								"h": [
									0.07,
									0.002,
									0.043,
									0.003,
									0.012,
									0.007,
									0.042,
									0.013,
									0.002,
									0.008,
									0.001,
									0.003
								],
								"e": 0.012
							},
							{
								"h": [
									0.125,
									0.038,
									0.011,
									0.007,
									0.006,
									0.002,
									0.372,
									0.895,
									0.001,
									0.001,
									0.18,
									0.038
								],
								"e": 0.07
							},
							{
								"h": [
									0.061,
									0.102,
									0.037,
									0.004,
									0.025,
									0.012,
									0.039,
									0.078,
									0.001,
									0.038,
									0.145,
									0.051
								],
								"e": 0.052
							}
						],
						[
							{
								"h": [
									0.288,
									0.0,
									0.186,
									0.012,
									0.025,
									0.021,
									0.053,
									0.017,
									0.009,
									0.487,
									0.196,
									0.214
								],
								"e": 0.074
							},
							{
								"h": [
									0.089,
									0.033,
									0.234,
									0.017,
									0.205,
									0.129,
									0.086,
									0.084,
									0.198,
									0.167,
									0.306,
									0.177
								],
								"e": 0.158
							},
							{
								"h": [
									0.017,
									0.0,
									0.0,
									0.003,
									0.001,
									0.002,
									0.015,
									0.35,
									0.006,
									0.004,
									0.143,
									0.02
								],
								"e": 0.034
							},
							{
								"h": [
									0.001,
									0.002,
									0.003,
									0.0,
									0.007,
									0.01,
									0.002,
									0.001,
									0.0,
									0.012,
									0.029,
									0.028
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.002,
									0.004,
									0.023,
									0.029,
									0.004,
									0.034,
									0.005,
									0.009,
									0.022,
									0.002,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.024,
									0.0,
									0.013,
									0.032,
									0.004,
									0.0,
									0.0,
									0.001,
									0.004,
									0.023,
									0.023,
									0.022
								],
								"e": 0.009
							},
							{
								"h": [
									0.045,
									0.03,
									0.002,
									0.144,
									0.015,
									0.028,
									0.092,
									0.101,
									0.003,
									0.0,
									0.108,
									0.123
								],
								"e": 0.049
							},
							{
								"h": [
									0.0,
									0.009,
									0.001,
									0.052,
									0.001,
									0.007,
									0.038,
									0.269,
									0.001,
									0.005,
									0.001,
									0.004
								],
								"e": 0.016
							},
							{
								"h": [
									0.003,
									0.002,
									0.011,
									0.007,
									0.001,
									0.008,
									0.334,
									0.004,
									0.083,
									0.048,
									0.034,
									0.001
								],
								"e": 0.026
							},
							{
								"h": [
									0.018,
									0.001,
									0.112,
									0.014,
									0.033,
									0.005,
									0.013,
									0.051,
									0.003,
									0.006,
									0.002,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.027,
									0.015,
									0.001,
									0.007,
									0.012,
									0.008,
									0.03,
									0.031,
									0.0,
									0.004,
									0.009,
									0.052
								],
								"e": 0.014
							},
							{
								"h": [
									0.217,
									0.078,
									0.008,
									0.03,
									0.005,
									0.005,
									0.005,
									0.025,
									0.004,
									0.036,
									0.166,
									0.086
								],
								"e": 0.047
							}
						],
						[
							{
								"h": [
									0.084,
									0.008,
									0.88,
									0.013,
									0.049,
									0.088,
									0.015,
									0.065,
									0.198,
									0.74,
									0.476,
									0.48
								],
								"e": 0.171
							},
							{
								"h": [
									0.085,
									0.266,
									0.305,
									0.026,
									0.224,
									0.534,
									0.766,
									0.191,
									0.318,
									0.091,
									0.334,
									0.475
								],
								"e": 0.313
							},
							{
								"h": [
									0.064,
									0.025,
									0.0,
									0.018,
									0.01,
									0.031,
									0.068,
									0.486,
									0.029,
									0.001,
									0.144,
									0.02
								],
								"e": 0.071
							},
							{
								"h": [
									0.013,
									0.052,
									0.01,
									0.0,
									0.12,
									0.035,
									0.001,
									0.0,
									0.003,
									0.081,
									0.045,
									0.008
								],
								"e": 0.04
							},
							{
								"h": [
									0.007,
									0.02,
									0.078,
									0.022,
									0.122,
									0.011,
									0.12,
									0.018,
									0.068,
									0.082,
									0.043,
									0.0
								],
								"e": 0.055
							},
							{
								"h": [
									0.003,
									0.0,
									0.028,
									0.106,
									0.004,
									0.0,
									0.0,
									0.0,
									0.005,
									0.027,
									0.063,
									0.038
								],
								"e": 0.016
							},
							{
								"h": [
									0.137,
									0.024,
									0.016,
									0.242,
									0.03,
									0.032,
									0.123,
									0.075,
									0.001,
									0.0,
									0.109,
									0.009
								],
								"e": 0.053
							},
							{
								"h": [
									0.0,
									0.004,
									0.0,
									0.029,
									0.003,
									0.001,
									0.026,
									0.366,
									0.0,
									0.003,
									0.001,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.005,
									0.001,
									0.006,
									0.009,
									0.0,
									0.001,
									0.011,
									0.001,
									0.084,
									0.093,
									0.008,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.004,
									0.002,
									0.008,
									0.002,
									0.003,
									0.003,
									0.0,
									0.008,
									0.001,
									0.0,
									0.003,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.001,
									0.001,
									0.004,
									0.003,
									0.018,
									0.001,
									0.001,
									0.001,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.123,
									0.056,
									0.017,
									0.01,
									0.042,
									0.022,
									0.008,
									0.016,
									0.014,
									0.007,
									0.018,
									0.021
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.843,
									0.0,
									0.619,
									0.02,
									0.043,
									0.416,
									0.029,
									0.084,
									0.16,
									1.0,
									0.457,
									0.196
								],
								"e": 0.219
							},
							{
								"h": [
									0.365,
									0.253,
									0.349,
									0.032,
									0.321,
									0.613,
									0.604,
									0.331,
									0.468,
									0.32,
									0.503,
									1.0
								],
								"e": 0.473
							},
							{
								"h": [
									0.182,
									0.01,
									0.0,
									0.215,
									0.115,
									0.014,
									0.06,
									0.475,
									0.082,
									0.011,
									0.231,
									0.039
								],
								"e": 0.141
							},
							{
								"h": [
									0.021,
									0.013,
									0.022,
									0.0,
									0.045,
									0.05,
									0.009,
									0.0,
									0.001,
									0.031,
									0.044,
									0.031
								],
								"e": 0.031
							},
							{
								"h": [
									0.004,
									0.006,
									0.019,
									0.07,
									0.014,
									0.012,
									0.09,
									0.007,
									0.08,
									0.017,
									0.018,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.006,
									0.0,
									0.035,
									0.066,
									0.016,
									0.005,
									0.0,
									0.001,
									0.02,
									0.078,
									0.059,
									0.012
								],
								"e": 0.019
							},
							{
								"h": [
									0.041,
									0.022,
									0.022,
									0.107,
									0.008,
									0.052,
									0.022,
									0.077,
									0.022,
									0.0,
									0.16,
									0.003
								],
								"e": 0.038
							},
							{
								"h": [
									0.0,
									0.007,
									0.001,
									0.014,
									0.002,
									0.003,
									0.118,
									0.026,
									0.003,
									0.0,
									0.006,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.004,
									0.011,
									0.014,
									0.002,
									0.0,
									0.002,
									0.003,
									0.001,
									0.004,
									0.085,
									0.003,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.004,
									0.003,
									0.005,
									0.002,
									0.001,
									0.002,
									0.001,
									0.006,
									0.001,
									0.001,
									0.001,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.002,
									0.002,
									0.003,
									0.002,
									0.001,
									0.004,
									0.002,
									0.036,
									0.014,
									0.001,
									0.009,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.155,
									0.028,
									0.013,
									0.017,
									0.051,
									0.023,
									0.013,
									0.043,
									0.008,
									0.004,
									0.014,
									0.01
								],
								"e": 0.033
							}
						],
						[
							{
								"h": [
									0.56,
									0.0,
									0.158,
									0.024,
									0.024,
									0.02,
									0.704,
									0.052,
									0.012,
									0.626,
									0.285,
									0.267
								],
								"e": 0.151
							},
							{
								"h": [
									0.255,
									0.189,
									0.46,
									0.034,
									0.457,
									0.2,
									0.086,
									0.17,
									0.345,
									0.292,
									0.665,
									0.237
								],
								"e": 0.326
							},
							{
								"h": [
									0.092,
									0.002,
									0.006,
									0.073,
									0.071,
									0.039,
									0.08,
									0.808,
									0.042,
									0.06,
									0.529,
									0.057
								],
								"e": 0.148
							},
							{
								"h": [
									0.003,
									0.012,
									0.007,
									0.001,
									0.029,
									0.011,
									0.042,
									0.006,
									0.009,
									0.014,
									0.056,
									0.098
								],
								"e": 0.029
							},
							{
								"h": [
									0.008,
									0.005,
									0.008,
									0.068,
									0.04,
									0.027,
									0.108,
									0.021,
									0.013,
									0.026,
									0.006,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.003,
									0.0,
									0.041,
									0.071,
									0.017,
									0.002,
									0.0,
									0.005,
									0.007,
									0.034,
									0.073,
									0.019
								],
								"e": 0.017
							},
							{
								"h": [
									0.036,
									0.004,
									0.012,
									0.044,
									0.004,
									0.051,
									0.019,
									0.079,
									0.078,
									0.0,
									0.062,
									0.003
								],
								"e": 0.031
							},
							{
								"h": [
									0.004,
									0.008,
									0.002,
									0.006,
									0.001,
									0.003,
									0.045,
									0.049,
									0.001,
									0.0,
									0.003,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.003,
									0.01,
									0.0,
									0.0,
									0.005,
									0.001,
									0.012,
									0.006,
									0.118,
									0.002,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.011,
									0.0,
									0.014,
									0.011,
									0.001,
									0.002,
									0.006,
									0.002,
									0.027,
									0.002,
									0.001,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.124,
									0.003,
									0.004,
									0.004,
									0.001,
									0.005,
									0.017,
									0.018,
									0.002,
									0.002,
									0.03,
									0.004
								],
								"e": 0.013
							},
							{
								"h": [
									0.039,
									0.062,
									0.067,
									0.076,
									0.025,
									0.007,
									0.048,
									0.013,
									0.009,
									0.037,
									0.031,
									0.064
								],
								"e": 0.046
							}
						],
						[
							{
								"h": [
									0.047,
									0.844,
									0.187,
									0.057,
									0.208,
									0.608,
									0.005,
									0.13,
									0.778,
									0.787,
									0.889,
									0.578
								],
								"e": 0.418
							},
							{
								"h": [
									0.221,
									0.273,
									0.507,
									0.042,
									0.348,
									1.0,
									0.599,
									0.204,
									0.367,
									0.072,
									0.591,
									0.502
								],
								"e": 0.411
							},
							{
								"h": [
									0.107,
									0.016,
									0.001,
									0.014,
									0.05,
									0.039,
									0.421,
									0.498,
									0.036,
									0.009,
									0.289,
									0.042
								],
								"e": 0.15
							},
							{
								"h": [
									0.017,
									0.046,
									0.009,
									0.006,
									0.11,
									0.053,
									0.012,
									0.004,
									0.031,
									0.068,
									0.133,
									0.085
								],
								"e": 0.063
							},
							{
								"h": [
									0.022,
									0.058,
									0.116,
									0.012,
									0.13,
									0.021,
									0.098,
									0.207,
									0.222,
									0.018,
									0.028,
									0.0
								],
								"e": 0.093
							},
							{
								"h": [
									0.002,
									0.0,
									0.015,
									0.079,
									0.015,
									0.011,
									0.0,
									0.004,
									0.004,
									0.03,
									0.081,
									0.096
								],
								"e": 0.023
							},
							{
								"h": [
									0.019,
									0.013,
									0.035,
									0.18,
									0.059,
									0.09,
									0.268,
									0.07,
									0.004,
									0.002,
									0.121,
									0.013
								],
								"e": 0.06
							},
							{
								"h": [
									0.0,
									0.012,
									0.002,
									0.011,
									0.001,
									0.006,
									0.068,
									0.029,
									0.001,
									0.001,
									0.004,
									0.006
								],
								"e": 0.008
							},
							{
								"h": [
									0.01,
									0.012,
									0.019,
									0.011,
									0.003,
									0.008,
									0.036,
									0.024,
									0.056,
									0.339,
									0.026,
									0.004
								],
								"e": 0.025
							},
							{
								"h": [
									0.018,
									0.013,
									0.035,
									0.016,
									0.021,
									0.037,
									0.001,
									0.058,
									0.003,
									0.002,
									0.008,
									0.001
								],
								"e": 0.017
							},
							{
								"h": [
									0.003,
									0.005,
									0.009,
									0.02,
									0.037,
									0.042,
									0.023,
									0.124,
									0.012,
									0.028,
									0.004,
									0.007
								],
								"e": 0.023
							},
							{
								"h": [
									0.266,
									0.055,
									0.024,
									0.092,
									0.096,
									0.098,
									0.015,
									0.058,
									0.313,
									0.029,
									0.147,
									0.176
								],
								"e": 0.117
							}
						],
						[
							{
								"h": [
									0.32,
									0.0,
									0.177,
									0.065,
									0.058,
									0.016,
									0.487,
									0.084,
									0.011,
									0.572,
									0.19,
									0.215
								],
								"e": 0.138
							},
							{
								"h": [
									0.513,
									0.255,
									0.609,
									0.038,
									0.454,
									0.138,
									0.053,
									0.083,
									0.356,
									0.177,
									0.635,
									0.139
								],
								"e": 0.348
							},
							{
								"h": [
									0.147,
									0.001,
									0.061,
									0.303,
									0.085,
									0.039,
									0.097,
									1.0,
									0.162,
									0.167,
									0.504,
									0.082
								],
								"e": 0.254
							},
							{
								"h": [
									0.002,
									0.085,
									0.46,
									0.029,
									0.029,
									0.04,
									0.211,
									0.073,
									0.009,
									0.248,
									0.101,
									0.853
								],
								"e": 0.184
							},
							{
								"h": [
									0.024,
									0.127,
									0.047,
									0.323,
									0.059,
									0.079,
									0.464,
									0.033,
									0.041,
									0.348,
									0.006,
									0.0
								],
								"e": 0.129
							},
							{
								"h": [
									0.775,
									0.25,
									0.047,
									0.538,
									0.057,
									0.284,
									0.0,
									0.029,
									0.691,
									0.092,
									0.048,
									0.038
								],
								"e": 0.187
							},
							{
								"h": [
									0.205,
									0.159,
									0.01,
									0.356,
									0.046,
									0.143,
									0.31,
									0.286,
									0.008,
									0.106,
									0.219,
									0.533
								],
								"e": 0.186
							},
							{
								"h": [
									0.002,
									0.017,
									0.257,
									0.469,
									0.004,
									0.011,
									0.263,
									0.204,
									0.003,
									0.235,
									0.257,
									0.176
								],
								"e": 0.121
							},
							{
								"h": [
									0.01,
									0.037,
									0.075,
									0.012,
									0.003,
									0.078,
									0.444,
									0.056,
									0.171,
									0.195,
									0.187,
									0.004
								],
								"e": 0.073
							},
							{
								"h": [
									0.284,
									0.003,
									0.185,
									0.06,
									0.049,
									0.013,
									0.087,
									0.121,
									0.028,
									0.032,
									0.023,
									0.011
								],
								"e": 0.057
							},
							{
								"h": [
									0.226,
									0.151,
									0.038,
									0.038,
									0.028,
									0.041,
									0.377,
									0.67,
									0.006,
									0.027,
									0.082,
									0.42
								],
								"e": 0.123
							},
							{
								"h": [
									0.294,
									0.569,
									0.047,
									0.021,
									0.019,
									0.016,
									0.041,
									0.156,
									0.005,
									0.077,
									0.663,
									0.194
								],
								"e": 0.154
							}
						],
						[
							{
								"h": [
									0.105,
									0.009,
									1.0,
									0.242,
									0.232,
									0.082,
									0.019,
									0.487,
									0.256,
									0.858,
									0.539,
									0.466
								],
								"e": 0.353
							},
							{
								"h": [
									0.354,
									0.964,
									0.486,
									0.04,
									0.343,
									0.629,
									0.822,
									0.227,
									0.321,
									0.082,
									0.547,
									0.606
								],
								"e": 0.526
							},
							{
								"h": [
									0.31,
									0.029,
									0.004,
									0.243,
									0.154,
									0.592,
									0.184,
									0.785,
									0.352,
									0.016,
									0.369,
									0.057
								],
								"e": 0.346
							},
							{
								"h": [
									0.009,
									1.0,
									0.633,
									0.022,
									0.135,
									0.083,
									0.03,
									0.007,
									0.132,
									0.818,
									0.165,
									0.154
								],
								"e": 0.311
							},
							{
								"h": [
									0.264,
									0.513,
									0.206,
									0.279,
									0.128,
									0.331,
									1.0,
									0.037,
									0.117,
									1.0,
									0.054,
									0.0
								],
								"e": 0.358
							},
							{
								"h": [
									0.002,
									0.0,
									0.206,
									1.0,
									0.078,
									0.001,
									0.0,
									0.006,
									0.009,
									0.029,
									0.122,
									0.078
								],
								"e": 0.077
							},
							{
								"h": [
									1.0,
									0.09,
									0.02,
									0.707,
									0.058,
									0.126,
									0.159,
									0.319,
									0.007,
									0.0,
									0.069,
									0.083
								],
								"e": 0.181
							},
							{
								"h": [
									0.001,
									0.007,
									0.0,
									0.094,
									0.006,
									0.002,
									0.034,
									0.332,
									0.004,
									0.011,
									0.005,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.019,
									0.003,
									0.006,
									0.013,
									0.001,
									0.007,
									0.013,
									0.017,
									0.164,
									0.288,
									0.021,
									0.001
								],
								"e": 0.026
							},
							{
								"h": [
									0.005,
									0.003,
									0.011,
									0.007,
									0.003,
									0.006,
									0.0,
									0.017,
									0.0,
									0.0,
									0.02,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.001,
									0.001,
									0.008,
									0.002,
									0.02,
									0.001,
									0.006,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.11,
									0.075,
									0.021,
									0.013,
									0.047,
									0.025,
									0.011,
									0.017,
									0.018,
									0.007,
									0.021,
									0.035
								],
								"e": 0.034
							}
						],
						[
							{
								"h": [
									0.899,
									0.0,
									0.144,
									0.108,
									0.112,
									0.022,
									0.514,
									0.234,
									0.015,
									0.524,
									0.235,
									0.271
								],
								"e": 0.22
							},
							{
								"h": [
									0.881,
									0.56,
									0.818,
									0.055,
									0.787,
									0.15,
									0.099,
									0.379,
									0.247,
									0.194,
									0.955,
									0.747
								],
								"e": 0.609
							},
							{
								"h": [
									0.55,
									0.003,
									0.233,
									1.0,
									0.413,
									0.121,
									0.114,
									0.888,
									0.119,
									0.788,
									0.833,
									0.123
								],
								"e": 0.644
							},
							{
								"h": [
									0.002,
									0.084,
									1.0,
									0.063,
									0.045,
									0.026,
									1.0,
									0.12,
									0.063,
									0.209,
									0.118,
									0.403
								],
								"e": 0.309
							},
							{
								"h": [
									0.028,
									0.091,
									0.026,
									0.531,
									0.029,
									0.064,
									0.56,
									0.034,
									0.04,
									0.121,
									0.005,
									0.0
								],
								"e": 0.118
							},
							{
								"h": [
									0.01,
									0.002,
									0.855,
									0.758,
									0.126,
									0.032,
									0.004,
									0.017,
									0.076,
									0.084,
									0.169,
									0.022
								],
								"e": 0.128
							},
							{
								"h": [
									0.294,
									0.049,
									0.023,
									0.105,
									0.009,
									0.1,
									0.057,
									0.131,
									0.317,
									0.002,
									0.085,
									0.012
								],
								"e": 0.098
							},
							{
								"h": [
									0.004,
									0.011,
									0.006,
									0.029,
									0.008,
									0.002,
									0.096,
									0.057,
									0.01,
									0.005,
									0.038,
									0.008
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.011,
									0.009,
									0.001,
									0.001,
									0.02,
									0.004,
									0.039,
									0.007,
									0.087,
									0.003,
									0.002
								],
								"e": 0.011
							},
							{
								"h": [
									0.042,
									0.001,
									0.027,
									0.02,
									0.001,
									0.001,
									0.017,
									0.002,
									0.028,
									0.004,
									0.002,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.107,
									0.007,
									0.039,
									0.013,
									0.001,
									0.011,
									0.008,
									0.002,
									0.002,
									0.006,
									0.038,
									0.001
								],
								"e": 0.018
							},
							{
								"h": [
									0.055,
									0.172,
									0.241,
									0.198,
									0.023,
									0.013,
									0.039,
									0.008,
									0.016,
									0.033,
									0.013,
									0.072
								],
								"e": 0.081
							}
						],
						[
							{
								"h": [
									1.0,
									0.0,
									0.168,
									0.378,
									0.062,
									0.008,
									0.897,
									0.266,
									0.017,
									0.651,
									0.23,
									0.506
								],
								"e": 0.319
							},
							{
								"h": [
									0.3,
									0.415,
									1.0,
									0.063,
									0.647,
									0.25,
									0.072,
									0.304,
									0.3,
									0.191,
									1.0,
									0.414
								],
								"e": 0.481
							},
							{
								"h": [
									0.229,
									0.006,
									1.0,
									0.504,
									0.207,
									0.707,
									0.155,
									0.769,
									1.0,
									1.0,
									1.0,
									0.136
								],
								"e": 0.979
							},
							{
								"h": [
									0.008,
									0.184,
									0.646,
									0.197,
									0.06,
									0.036,
									0.588,
									1.0,
									0.39,
									0.217,
									0.28,
									0.831
								],
								"e": 0.503
							},
							{
								"h": [
									0.054,
									0.068,
									0.033,
									0.69,
									0.058,
									0.276,
									0.853,
									0.275,
									0.086,
									0.116,
									0.005,
									1.0
								],
								"e": 0.472
							},
							{
								"h": [
									0.004,
									0.002,
									0.224,
									0.178,
									0.167,
									0.041,
									0.1,
									0.034,
									0.023,
									0.035,
									0.293,
									0.022
								],
								"e": 0.086
							},
							{
								"h": [
									0.141,
									0.033,
									0.013,
									0.045,
									0.004,
									0.196,
									0.017,
									0.263,
									1.0,
									0.002,
									0.029,
									0.019
								],
								"e": 0.166
							},
							{
								"h": [
									0.032,
									0.003,
									0.01,
									0.018,
									0.002,
									0.001,
									0.056,
									0.064,
									0.008,
									0.001,
									0.018,
									0.003
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.006,
									0.019,
									0.0,
									0.004,
									0.014,
									0.002,
									0.252,
									0.006,
									0.118,
									0.003,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.009,
									0.0,
									0.008,
									0.046,
									0.0,
									0.001,
									0.005,
									0.003,
									0.032,
									0.001,
									0.004,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.054,
									0.005,
									0.004,
									0.008,
									0.001,
									0.016,
									0.004,
									0.005,
									0.001,
									0.012,
									0.009,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.048,
									0.041,
									0.126,
									0.115,
									0.026,
									0.004,
									0.02,
									0.016,
									0.012,
									0.015,
									0.031,
									0.071
								],
								"e": 0.048
							}
						],
						[
							{
								"h": [
									0.061,
									1.0,
									0.207,
									1.0,
									1.0,
									0.57,
									0.007,
									1.0,
									1.0,
									0.924,
									1.0,
									0.585
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.88,
									0.07,
									0.561,
									0.986,
									0.595,
									0.241,
									0.374,
									0.067,
									0.971,
									0.678
								],
								"e": 0.742
							},
							{
								"h": [
									0.489,
									0.016,
									0.27,
									0.298,
									0.684,
									1.0,
									1.0,
									0.752,
									0.54,
									0.237,
									0.673,
									0.117
								],
								"e": 0.801
							},
							{
								"h": [
									0.017,
									0.38,
									0.707,
									0.591,
									0.12,
									0.097,
									0.248,
									0.374,
									1.0,
									0.693,
									0.397,
									1.0
								],
								"e": 0.618
							},
							{
								"h": [
									0.34,
									0.754,
									0.209,
									0.134,
									0.122,
									0.229,
									0.538,
									1.0,
									0.184,
									0.084,
									0.033,
									0.0
								],
								"e": 0.387
							},
							{
								"h": [
									0.012,
									0.009,
									0.086,
									0.431,
									0.114,
									0.163,
									0.019,
									0.029,
									0.035,
									0.045,
									0.142,
									0.19
								],
								"e": 0.087
							},
							{
								"h": [
									0.136,
									0.113,
									0.052,
									0.388,
									0.1,
									0.312,
									0.383,
									0.232,
									0.034,
									0.018,
									0.08,
									0.173
								],
								"e": 0.152
							},
							{
								"h": [
									0.003,
									0.011,
									0.01,
									0.03,
									0.002,
									0.01,
									0.092,
									0.041,
									0.004,
									0.006,
									0.031,
									0.015
								],
								"e": 0.016
							},
							{
								"h": [
									0.012,
									0.032,
									0.018,
									0.016,
									0.011,
									0.032,
									0.036,
									0.19,
									0.142,
									0.477,
									0.034,
									0.007
								],
								"e": 0.054
							},
							{
								"h": [
									0.049,
									0.027,
									0.054,
									0.058,
									0.025,
									0.049,
									0.001,
									0.085,
									0.005,
									0.004,
									0.03,
									0.001
								],
								"e": 0.031
							},
							{
								"h": [
									0.004,
									0.008,
									0.016,
									0.026,
									0.053,
									0.087,
									0.036,
									0.144,
									0.019,
									0.07,
									0.007,
									0.011
								],
								"e": 0.04
							},
							{
								"h": [
									0.297,
									0.073,
									0.033,
									0.113,
									0.126,
									0.117,
									0.019,
									0.088,
									0.33,
									0.038,
									0.187,
									0.26
								],
								"e": 0.146
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Vill",
					"o": "ain",
					"t": "ain",
					"r": [
						{
							"p": " Vill",
							"b": 0.0,
							"k": {
								" Vill": 0.986,
								"anova": 0.009,
								"Vill": 0.002,
								"ains": 0.001,
								"agers": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0
							}
						},
						{
							"p": " Vill",
							"b": 0.021,
							"k": {
								" Vill": 0.873,
								"anova": 0.038,
								"ain": 0.021,
								"ains": 0.019,
								"agers": 0.013,
								"iage": 0.008,
								" Hots": 0.003,
								"Vill": 0.003,
								" vill": 0.002,
								"age": 0.001
							}
						},
						{
							"p": "anova",
							"b": 0.251,
							"k": {
								"anova": 0.538,
								"ain": 0.251,
								"ains": 0.09,
								"agers": 0.052,
								"iage": 0.018,
								" Vill": 0.014,
								"aries": 0.006,
								"aro": 0.005,
								"oso": 0.003,
								"age": 0.002
							}
						},
						{
							"p": "anova",
							"b": 0.092,
							"k": {
								"anova": 0.795,
								"ain": 0.092,
								"ains": 0.069,
								"iage": 0.02,
								"agers": 0.014,
								"aries": 0.002,
								"age": 0.001,
								"ages": 0.001,
								"agic": 0.001,
								" Vill": 0.001
							}
						},
						{
							"p": "ain",
							"b": 0.584,
							"k": {
								"ain": 0.584,
								"anova": 0.3,
								"ains": 0.09,
								"iage": 0.013,
								"agers": 0.003,
								"age": 0.002,
								"ages": 0.001,
								"aries": 0.001,
								"enge": 0.001,
								"aine": 0.001
							}
						},
						{
							"p": "ain",
							"b": 0.948,
							"k": {
								"ain": 0.948,
								"anova": 0.039,
								"age": 0.005,
								"ains": 0.002,
								"enge": 0.002,
								"ages": 0.001,
								"agers": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0
							}
						},
						{
							"p": "ain",
							"b": 0.989,
							"k": {
								"ain": 0.989,
								"enge": 0.009,
								"age": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": "ain",
							"b": 0.997,
							"k": {
								"ain": 0.997,
								"enge": 0.003,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": "ain",
							"b": 0.999,
							"k": {
								"ain": 0.999,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": "ain",
							"b": 1.0,
							"k": {
								"ain": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": "ain",
							"b": 1.0,
							"k": {
								"ain": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": "ain",
							"b": 0.999,
							"k": {
								"ain": 0.999,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.344,
									0.0,
									0.264,
									0.0,
									0.001,
									0.003,
									0.92,
									0.041,
									0.39,
									0.507,
									0.221,
									0.252
								],
								"e": 0.117
							},
							{
								"h": [
									0.043,
									0.009,
									0.152,
									1.0,
									1.0,
									0.595,
									1.0,
									0.609,
									1.0,
									1.0,
									0.029,
									0.003
								],
								"e": 1.0
							},
							{
								"h": [
									0.78,
									1.0,
									0.01,
									0.069,
									0.317,
									0.011,
									0.131,
									1.0,
									0.189,
									0.054,
									0.089,
									1.0
								],
								"e": 0.561
							},
							{
								"h": [
									0.997,
									0.294,
									0.019,
									0.254,
									1.0,
									1.0,
									0.147,
									0.062,
									0.02,
									1.0,
									1.0,
									1.0
								],
								"e": 0.713
							},
							{
								"h": [
									1.0,
									0.366,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.16,
									0.977,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.197,
									0.528,
									0.503,
									1.0,
									1.0,
									0.286,
									0.945,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									0.611,
									1.0,
									1.0,
									1.0,
									1.0,
									0.171,
									0.206,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.732,
									1.0,
									1.0,
									0.097,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.374,
									0.481,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.433,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.164,
									0.0,
									0.116,
									0.0,
									0.0,
									0.0,
									0.439,
									0.008,
									0.546,
									0.307,
									0.127,
									0.201
								],
								"e": 0.072
							},
							{
								"h": [
									0.007,
									0.003,
									0.099,
									0.007,
									0.116,
									0.33,
									0.214,
									0.23,
									0.302,
									0.291,
									0.089,
									0.005
								],
								"e": 0.165
							},
							{
								"h": [
									0.009,
									0.013,
									0.0,
									0.0,
									0.001,
									0.0,
									0.012,
									0.099,
									0.001,
									0.0,
									0.029,
									0.003
								],
								"e": 0.01
							},
							{
								"h": [
									0.045,
									0.001,
									0.0,
									0.0,
									0.103,
									0.051,
									0.0,
									0.0,
									0.0,
									0.0,
									0.006,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.0,
									0.0,
									0.05,
									0.0,
									0.1,
									0.0,
									0.006,
									0.0,
									0.276,
									0.012,
									0.075,
									0.0
								],
								"e": 0.038
							},
							{
								"h": [
									0.01,
									0.0,
									0.002,
									0.023,
									0.0,
									0.002,
									0.0,
									0.0,
									0.003,
									0.069,
									0.11,
									0.015
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.004,
									0.037,
									0.025,
									0.037,
									0.002,
									0.018,
									0.049,
									0.0,
									0.0,
									0.021,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.021,
									0.001,
									0.005,
									0.0,
									0.01,
									0.005,
									0.023,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.013,
									0.004,
									0.005,
									0.005,
									0.0,
									0.0,
									0.014,
									0.0,
									0.014,
									0.008,
									0.006,
									0.006
								],
								"e": 0.006
							},
							{
								"h": [
									0.014,
									0.002,
									0.01,
									0.001,
									0.007,
									0.002,
									0.001,
									0.035,
									0.001,
									0.002,
									0.0,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.006,
									0.005,
									0.004,
									0.005,
									0.006,
									0.0,
									0.006,
									0.013,
									0.017,
									0.0,
									0.004,
									0.013
								],
								"e": 0.006
							},
							{
								"h": [
									0.006,
									0.002,
									0.003,
									0.004,
									0.025,
									0.004,
									0.004,
									0.003,
									0.007,
									0.024,
									0.011,
									0.0
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.192,
									0.0,
									0.828,
									0.0,
									0.0,
									0.0,
									0.263,
									0.003,
									1.0,
									0.334,
									0.19,
									0.482
								],
								"e": 0.13
							},
							{
								"h": [
									0.01,
									0.002,
									0.113,
									0.009,
									0.112,
									0.32,
									0.137,
									0.322,
									0.178,
									0.261,
									0.139,
									0.0
								],
								"e": 0.151
							},
							{
								"h": [
									0.021,
									0.009,
									0.0,
									0.001,
									0.0,
									0.0,
									0.003,
									0.153,
									0.0,
									0.0,
									0.045,
									0.005
								],
								"e": 0.011
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.0,
									0.042,
									0.007,
									0.0,
									0.0,
									0.0,
									0.0,
									0.007,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.009,
									0.0,
									0.005,
									0.0,
									0.002,
									0.003,
									0.025,
									0.0,
									0.008,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.003,
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.076,
									0.046,
									0.005
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.0,
									0.045,
									0.007,
									0.024,
									0.001,
									0.008,
									0.004,
									0.0,
									0.0,
									0.005,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.006,
									0.0,
									0.001,
									0.0,
									0.003,
									0.004,
									0.004,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.006,
									0.001,
									0.003,
									0.001,
									0.0,
									0.001,
									0.003,
									0.0,
									0.003,
									0.008,
									0.002,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.003,
									0.0,
									0.004,
									0.0,
									0.002,
									0.003,
									0.001,
									0.004,
									0.002,
									0.0,
									0.0,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.002,
									0.001,
									0.001,
									0.001,
									0.002,
									0.0,
									0.001,
									0.001,
									0.002,
									0.0,
									0.0,
									0.003
								],
								"e": 0.001
							},
							{
								"h": [
									0.009,
									0.004,
									0.001,
									0.002,
									0.002,
									0.0,
									0.001,
									0.002,
									0.003,
									0.002,
									0.0,
									0.0
								],
								"e": 0.002
							}
						],
						[
							{
								"h": [
									0.325,
									0.0,
									1.0,
									0.0,
									0.0,
									0.0,
									0.576,
									0.004,
									0.479,
									0.395,
									0.15,
									0.612
								],
								"e": 0.143
							},
							{
								"h": [
									0.003,
									0.001,
									0.138,
									0.01,
									0.114,
									1.0,
									0.062,
									0.263,
									0.238,
									0.425,
									0.167,
									0.0
								],
								"e": 0.205
							},
							{
								"h": [
									0.001,
									0.003,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.199,
									0.0,
									0.0,
									0.062,
									0.007
								],
								"e": 0.011
							},
							{
								"h": [
									0.009,
									0.0,
									0.0,
									0.0,
									0.029,
									0.006,
									0.0,
									0.0,
									0.0,
									0.0,
									0.008,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.0,
									0.003,
									0.0,
									0.003,
									0.001,
									0.007,
									0.001,
									0.01,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.008,
									0.006,
									0.006
								],
								"e": 0.002
							},
							{
								"h": [
									0.0,
									0.0,
									0.016,
									0.005,
									0.008,
									0.001,
									0.001,
									0.001,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.001,
									0.002,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.006,
									0.0,
									0.002,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0,
									0.003,
									0.005,
									0.001,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.003,
									0.0,
									0.002,
									0.0,
									0.0,
									0.003,
									0.0,
									0.004,
									0.001,
									0.0,
									0.0,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.009,
									0.001,
									0.001,
									0.001,
									0.004,
									0.0,
									0.002,
									0.0,
									0.0,
									0.0,
									0.0,
									0.007
								],
								"e": 0.002
							},
							{
								"h": [
									0.003,
									0.003,
									0.003,
									0.003,
									0.009,
									0.002,
									0.009,
									0.005,
									0.001,
									0.008,
									0.0,
									0.0
								],
								"e": 0.004
							}
						],
						[
							{
								"h": [
									0.28,
									0.0,
									0.125,
									0.0,
									0.0,
									0.0,
									0.106,
									0.011,
									0.262,
									0.225,
									0.127,
									0.506
								],
								"e": 0.065
							},
							{
								"h": [
									0.011,
									0.007,
									0.122,
									0.01,
									0.112,
									0.478,
									0.281,
									0.278,
									0.34,
									0.262,
									0.128,
									0.008
								],
								"e": 0.194
							},
							{
								"h": [
									0.006,
									0.035,
									0.0,
									0.0,
									0.0,
									0.0,
									0.045,
									0.094,
									0.0,
									0.0,
									0.027,
									0.004
								],
								"e": 0.017
							},
							{
								"h": [
									0.071,
									0.001,
									0.0,
									0.0,
									0.053,
									0.021,
									0.0,
									0.0,
									0.0,
									0.0,
									0.008,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.0,
									0.075,
									0.0,
									0.053,
									0.0,
									0.004,
									0.003,
									0.178,
									0.001,
									0.048,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.012,
									0.001,
									0.0,
									0.002,
									0.0,
									0.0,
									0.018,
									0.129,
									0.013
								],
								"e": 0.015
							},
							{
								"h": [
									0.002,
									0.0,
									0.051,
									0.009,
									0.035,
									0.003,
									0.009,
									0.007,
									0.0,
									0.0,
									0.006,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.004,
									0.0,
									0.001,
									0.0,
									0.025,
									0.006,
									0.002,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.011,
									0.0,
									0.002,
									0.001,
									0.0,
									0.005,
									0.008,
									0.002,
									0.007,
									0.025,
									0.005,
									0.006
								],
								"e": 0.006
							},
							{
								"h": [
									0.003,
									0.002,
									0.004,
									0.004,
									0.003,
									0.001,
									0.0,
									0.042,
									0.001,
									0.001,
									0.0,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.002,
									0.001,
									0.001,
									0.003,
									0.003,
									0.0,
									0.003,
									0.008,
									0.008,
									0.0,
									0.0,
									0.026
								],
								"e": 0.004
							},
							{
								"h": [
									0.004,
									0.001,
									0.001,
									0.004,
									0.016,
									0.002,
									0.001,
									0.002,
									0.003,
									0.012,
									0.01,
									0.0
								],
								"e": 0.004
							}
						],
						[
							{
								"h": [
									0.162,
									0.819,
									0.304,
									0.0,
									0.012,
									1.0,
									0.503,
									0.004,
									0.288,
									0.019,
									0.761,
									0.191
								],
								"e": 0.341
							},
							{
								"h": [
									0.003,
									0.002,
									0.159,
									0.011,
									0.126,
									0.041,
									0.063,
									0.046,
									0.151,
									0.118,
									0.173,
									0.534
								],
								"e": 0.255
							},
							{
								"h": [
									0.003,
									0.004,
									0.0,
									0.0,
									0.0,
									0.0,
									0.005,
									0.124,
									0.0,
									0.0,
									0.036,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									1.0,
									0.0,
									0.0,
									0.0,
									0.006,
									0.008,
									0.0,
									0.0,
									0.0,
									0.0,
									0.008,
									0.0
								],
								"e": 0.071
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.0,
									0.009,
									0.0,
									0.001,
									0.03,
									0.003,
									0.0,
									0.012,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.002,
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.006,
									0.017,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.0,
									0.009,
									0.005,
									0.004,
									0.001,
									0.044,
									0.003,
									0.0,
									0.0,
									0.014,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.005,
									0.007,
									0.001,
									0.0,
									0.001,
									0.005,
									0.009,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.002,
									0.0,
									0.0,
									0.029,
									0.0,
									0.002,
									0.007,
									0.003,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.009,
									0.0,
									0.021,
									0.0,
									0.005,
									0.006,
									0.001,
									0.002,
									0.001,
									0.003,
									0.0,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.004,
									0.001,
									0.001,
									0.0,
									0.001,
									0.0,
									0.008,
									0.001,
									0.001,
									0.0,
									0.002,
									0.007
								],
								"e": 0.002
							},
							{
								"h": [
									0.013,
									0.001,
									0.0,
									0.001,
									0.001,
									0.0,
									0.001,
									0.0,
									0.004,
									0.002,
									0.0,
									0.0
								],
								"e": 0.002
							}
						],
						[
							{
								"h": [
									0.034,
									0.0,
									0.053,
									0.0,
									0.0,
									0.0,
									0.101,
									0.004,
									0.155,
									0.221,
									0.089,
									0.125
								],
								"e": 0.031
							},
							{
								"h": [
									0.009,
									0.001,
									0.115,
									0.009,
									0.103,
									0.226,
									0.243,
									0.184,
									0.171,
									0.117,
									0.151,
									0.0
								],
								"e": 0.126
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0,
									0.004,
									0.115,
									0.0,
									0.0,
									0.038,
									0.005
								],
								"e": 0.007
							},
							{
								"h": [
									0.057,
									0.0,
									0.0,
									0.0,
									0.009,
									0.006,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.0,
									0.011,
									0.0,
									0.002,
									0.001,
									0.011,
									0.0,
									0.012,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.095,
									1.0,
									0.0,
									0.002,
									0.0,
									0.527,
									0.0,
									0.0,
									0.063,
									0.022,
									0.008,
									0.005
								],
								"e": 0.149
							},
							{
								"h": [
									0.0,
									0.003,
									0.002,
									0.006,
									0.004,
									0.001,
									0.02,
									0.005,
									0.0,
									1.0,
									0.038,
									0.0
								],
								"e": 0.135
							},
							{
								"h": [
									0.0,
									0.013,
									0.902,
									0.001,
									0.0,
									0.001,
									0.009,
									0.005,
									0.0,
									0.0,
									0.773,
									0.014
								],
								"e": 0.098
							},
							{
								"h": [
									0.004,
									0.118,
									0.001,
									0.002,
									0.0,
									0.0,
									0.384,
									0.0,
									0.02,
									0.007,
									0.009,
									0.009
								],
								"e": 0.027
							},
							{
								"h": [
									0.028,
									0.008,
									0.044,
									0.0,
									0.005,
									0.003,
									0.019,
									0.006,
									0.005,
									0.545,
									0.0,
									0.002
								],
								"e": 0.039
							},
							{
								"h": [
									0.093,
									0.303,
									0.002,
									0.003,
									0.036,
									0.0,
									0.045,
									0.04,
									0.006,
									0.0,
									0.014,
									0.116
								],
								"e": 0.041
							},
							{
								"h": [
									0.012,
									0.016,
									0.001,
									0.003,
									0.003,
									0.004,
									0.01,
									0.005,
									0.002,
									0.456,
									0.025,
									0.0
								],
								"e": 0.031
							}
						],
						[
							{
								"h": [
									0.12,
									0.0,
									0.079,
									0.0,
									0.0,
									0.0,
									0.323,
									0.006,
									0.477,
									0.271,
									0.132,
									0.217
								],
								"e": 0.061
							},
							{
								"h": [
									0.017,
									0.01,
									0.145,
									0.012,
									0.114,
									0.634,
									0.205,
									0.26,
									0.272,
									0.209,
									0.156,
									0.01
								],
								"e": 0.185
							},
							{
								"h": [
									0.006,
									0.054,
									0.0,
									0.0,
									0.0,
									0.0,
									0.021,
									0.199,
									0.001,
									0.0,
									0.035,
									0.005
								],
								"e": 0.022
							},
							{
								"h": [
									0.024,
									0.001,
									0.0,
									0.0,
									0.135,
									0.024,
									0.0,
									0.0,
									0.0,
									0.001,
									0.005,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.0,
									0.0,
									0.068,
									0.0,
									0.087,
									0.0,
									0.005,
									0.001,
									0.497,
									0.007,
									0.086,
									0.0
								],
								"e": 0.05
							},
							{
								"h": [
									0.47,
									0.002,
									0.006,
									0.011,
									0.0,
									0.072,
									0.0,
									0.0,
									0.032,
									0.106,
									0.106,
									0.019
								],
								"e": 0.051
							},
							{
								"h": [
									0.001,
									0.005,
									0.009,
									0.06,
									0.042,
									0.001,
									0.046,
									0.053,
									0.0,
									0.0,
									0.06,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.0,
									0.052,
									0.002,
									0.003,
									0.0,
									0.007,
									0.005,
									0.061,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.006,
									0.009,
									0.005,
									0.003,
									0.0,
									0.0,
									0.025,
									0.0,
									0.014,
									0.02,
									0.013,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.007,
									0.002,
									0.003,
									0.0,
									0.002,
									0.002,
									0.001,
									0.013,
									0.0,
									0.004,
									0.0,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.005,
									0.003,
									0.001,
									0.003,
									0.002,
									0.0,
									0.006,
									0.009,
									0.01,
									0.0,
									0.002,
									0.011
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.001,
									0.009,
									0.002,
									0.002,
									0.001,
									0.001,
									0.024,
									0.003,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									1.0,
									0.003,
									0.831,
									0.0,
									0.0,
									0.0,
									0.523,
									0.004,
									0.613,
									1.0,
									0.345,
									0.705
								],
								"e": 0.21
							},
							{
								"h": [
									0.01,
									0.009,
									0.251,
									0.018,
									0.175,
									0.959,
									0.12,
									0.6,
									0.348,
									0.491,
									0.287,
									0.001
								],
								"e": 0.289
							},
							{
								"h": [
									0.012,
									0.013,
									0.0,
									0.002,
									0.0,
									0.0,
									0.013,
									0.361,
									0.001,
									0.001,
									0.131,
									0.014
								],
								"e": 0.024
							},
							{
								"h": [
									0.048,
									0.0,
									0.0,
									0.0,
									0.096,
									0.01,
									0.001,
									0.0,
									0.0,
									0.001,
									0.021,
									0.001
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.0,
									0.007,
									0.001,
									0.009,
									0.0,
									0.008,
									0.017,
									0.016,
									0.002,
									0.009,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.003,
									0.0,
									0.001,
									0.004,
									0.0,
									0.047,
									0.0,
									0.0,
									0.002,
									0.052,
									0.022,
									0.007
								],
								"e": 0.011
							},
							{
								"h": [
									0.001,
									0.0,
									0.01,
									0.007,
									0.021,
									0.002,
									0.01,
									0.005,
									0.0,
									0.0,
									0.015,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.016,
									0.001,
									0.001,
									0.0,
									0.002,
									0.009,
									0.011,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.004,
									0.002,
									0.002,
									0.001,
									0.0,
									0.001,
									0.003,
									0.0,
									0.008,
									0.038,
									0.005,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.006,
									0.0,
									0.011,
									0.0,
									0.0,
									0.002,
									0.001,
									0.002,
									0.003,
									0.003,
									0.0,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.012,
									0.003,
									0.0,
									0.001,
									0.003,
									0.0,
									0.003,
									0.0,
									0.001,
									0.0,
									0.0,
									0.007
								],
								"e": 0.002
							},
							{
								"h": [
									0.003,
									0.001,
									0.002,
									0.009,
									0.006,
									0.001,
									0.006,
									0.001,
									0.001,
									0.009,
									0.0,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.275,
									0.0,
									0.115,
									0.0,
									0.0,
									0.0,
									0.091,
									0.01,
									0.255,
									0.229,
									0.124,
									0.626
								],
								"e": 0.067
							},
							{
								"h": [
									0.023,
									0.02,
									0.188,
									0.016,
									0.134,
									0.631,
									0.296,
									0.309,
									0.343,
									0.195,
									0.22,
									0.014
								],
								"e": 0.223
							},
							{
								"h": [
									0.011,
									0.038,
									0.0,
									0.0,
									0.001,
									0.0,
									0.081,
									0.159,
									0.001,
									0.0,
									0.053,
									0.007
								],
								"e": 0.026
							},
							{
								"h": [
									0.085,
									0.002,
									0.0,
									0.0,
									0.064,
									0.024,
									0.0,
									0.0,
									0.0,
									0.001,
									0.015,
									0.001
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.001,
									0.075,
									0.0,
									0.062,
									0.0,
									0.009,
									0.005,
									0.204,
									0.002,
									0.047,
									0.0
								],
								"e": 0.03
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.012,
									0.001,
									0.001,
									0.001,
									0.0,
									0.0,
									0.025,
									0.116,
									0.017
								],
								"e": 0.015
							},
							{
								"h": [
									0.002,
									0.0,
									0.02,
									0.006,
									0.034,
									0.006,
									0.06,
									0.008,
									0.002,
									0.0,
									0.017,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.004,
									0.02,
									0.0,
									0.002,
									0.0,
									0.007,
									0.019,
									0.02,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.015,
									0.001,
									0.001,
									0.002,
									0.0,
									0.001,
									0.028,
									0.01,
									0.008,
									0.032,
									0.044,
									0.006
								],
								"e": 0.011
							},
							{
								"h": [
									0.004,
									0.004,
									0.006,
									0.009,
									0.001,
									0.005,
									0.001,
									0.033,
									0.001,
									0.005,
									0.0,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.003,
									0.001,
									0.005,
									0.005,
									0.0,
									0.006,
									0.005,
									0.009,
									0.0,
									0.001,
									0.041
								],
								"e": 0.006
							},
							{
								"h": [
									0.003,
									0.001,
									0.001,
									0.003,
									0.013,
									0.002,
									0.002,
									0.002,
									0.002,
									0.023,
									0.005,
									0.0
								],
								"e": 0.004
							}
						],
						[
							{
								"h": [
									0.069,
									0.001,
									0.069,
									0.0,
									0.0,
									0.0,
									0.652,
									0.007,
									0.183,
									0.255,
									0.134,
									0.202
								],
								"e": 0.058
							},
							{
								"h": [
									0.018,
									0.008,
									0.288,
									0.02,
									0.156,
									0.522,
									0.611,
									0.115,
									0.248,
									0.094,
									0.311,
									0.002
								],
								"e": 0.223
							},
							{
								"h": [
									0.01,
									0.003,
									0.0,
									0.002,
									0.0,
									0.0,
									0.021,
									0.368,
									0.001,
									0.001,
									0.1,
									0.014
								],
								"e": 0.022
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.0,
									0.061,
									0.015,
									0.001,
									0.0,
									0.0,
									0.001,
									0.021,
									0.003
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.0,
									0.011,
									0.002,
									0.118,
									0.0,
									0.015,
									0.068,
									0.081,
									0.005,
									0.01,
									0.0
								],
								"e": 0.026
							},
							{
								"h": [
									0.014,
									0.001,
									0.0,
									0.008,
									0.0,
									0.025,
									0.0,
									0.0,
									0.004,
									0.156,
									0.061,
									0.01
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.01,
									0.008,
									0.016,
									0.07,
									0.008,
									0.198,
									0.075,
									0.0,
									0.001,
									0.036,
									0.001
								],
								"e": 0.03
							},
							{
								"h": [
									0.0,
									0.026,
									0.009,
									0.003,
									0.0,
									0.004,
									0.018,
									0.032,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.006,
									0.004,
									0.002,
									0.004,
									0.0,
									0.001,
									0.598,
									0.002,
									0.056,
									0.022,
									0.048,
									0.005
								],
								"e": 0.036
							},
							{
								"h": [
									0.337,
									0.001,
									0.059,
									0.003,
									0.008,
									0.008,
									0.01,
									0.031,
									0.028,
									0.01,
									0.0,
									0.001
								],
								"e": 0.035
							},
							{
								"h": [
									0.033,
									0.014,
									0.003,
									0.002,
									0.004,
									0.0,
									0.015,
									0.004,
									0.003,
									0.0,
									0.004,
									0.112
								],
								"e": 0.012
							},
							{
								"h": [
									0.016,
									0.016,
									0.002,
									0.003,
									0.002,
									0.001,
									0.006,
									0.007,
									0.004,
									0.013,
									0.005,
									0.0
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.119,
									0.0,
									0.077,
									0.0,
									0.0,
									0.0,
									0.317,
									0.009,
									0.511,
									0.283,
									0.135,
									0.239
								],
								"e": 0.063
							},
							{
								"h": [
									0.032,
									0.024,
									0.21,
									0.017,
									0.139,
									0.785,
									0.219,
									0.307,
									0.272,
									0.182,
									0.235,
									0.013
								],
								"e": 0.22
							},
							{
								"h": [
									0.014,
									0.06,
									0.0,
									0.001,
									0.001,
									0.0,
									0.037,
									0.273,
									0.002,
									0.0,
									0.065,
									0.009
								],
								"e": 0.031
							},
							{
								"h": [
									0.016,
									0.004,
									0.0,
									0.0,
									0.144,
									0.035,
									0.0,
									0.0,
									0.0,
									0.003,
									0.019,
									0.001
								],
								"e": 0.024
							},
							{
								"h": [
									0.0,
									0.002,
									0.126,
									0.0,
									0.127,
									0.001,
									0.024,
									0.002,
									1.0,
									0.053,
									0.065,
									0.0
								],
								"e": 0.086
							},
							{
								"h": [
									0.831,
									0.0,
									0.038,
									0.03,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.372,
									0.107,
									0.034
								],
								"e": 0.083
							},
							{
								"h": [
									0.003,
									0.02,
									0.005,
									0.15,
									0.078,
									0.004,
									0.057,
									0.118,
									0.001,
									0.0,
									0.065,
									0.0
								],
								"e": 0.038
							},
							{
								"h": [
									0.0,
									0.019,
									0.001,
									0.007,
									0.0,
									0.004,
									0.007,
									0.481,
									0.0,
									0.001,
									0.0,
									0.004
								],
								"e": 0.021
							},
							{
								"h": [
									0.013,
									0.019,
									0.001,
									0.003,
									0.0,
									0.0,
									0.053,
									0.0,
									0.032,
									0.016,
									0.019,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.007,
									0.001,
									0.005,
									0.002,
									0.001,
									0.001,
									0.002,
									0.008,
									0.001,
									0.002,
									0.0,
									0.004
								],
								"e": 0.003
							},
							{
								"h": [
									0.006,
									0.003,
									0.002,
									0.002,
									0.002,
									0.0,
									0.005,
									0.003,
									0.01,
									0.0,
									0.002,
									0.009
								],
								"e": 0.003
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.001,
									0.008,
									0.002,
									0.002,
									0.001,
									0.001,
									0.014,
									0.001,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.24,
									0.001,
									0.813,
									0.0,
									0.001,
									0.0,
									0.45,
									0.013,
									0.398,
									0.221,
									0.201,
									0.242
								],
								"e": 0.108
							},
							{
								"h": [
									0.018,
									0.014,
									0.29,
									0.023,
									0.188,
									0.406,
									0.346,
									0.41,
									0.225,
									0.294,
									0.366,
									0.001
								],
								"e": 0.246
							},
							{
								"h": [
									0.022,
									0.005,
									0.0,
									0.006,
									0.001,
									0.0,
									0.016,
									0.381,
									0.001,
									0.002,
									0.17,
									0.018
								],
								"e": 0.027
							},
							{
								"h": [
									0.013,
									0.0,
									0.0,
									0.0,
									0.057,
									0.009,
									0.004,
									0.0,
									0.0,
									0.001,
									0.034,
									0.002
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.0,
									0.006,
									0.002,
									0.024,
									0.0,
									0.017,
									0.029,
									0.022,
									0.005,
									0.027,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.006,
									0.0,
									0.002,
									0.01,
									0.0,
									0.001,
									0.0,
									0.0,
									0.002,
									0.16,
									0.033,
									0.011
								],
								"e": 0.015
							},
							{
								"h": [
									0.004,
									0.003,
									0.016,
									0.022,
									0.012,
									0.003,
									0.012,
									0.007,
									0.0,
									0.0,
									0.03,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.011,
									0.001,
									0.002,
									0.0,
									0.001,
									0.006,
									0.094,
									0.001,
									0.0,
									0.0,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.005,
									0.002,
									0.001,
									0.001,
									0.0,
									0.001,
									0.006,
									0.001,
									0.003,
									0.046,
									0.002,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.006,
									0.0,
									0.004,
									0.002,
									0.0,
									0.002,
									0.001,
									0.002,
									0.003,
									0.0,
									0.0,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.002,
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.001,
									0.001,
									0.001,
									0.0,
									0.0,
									0.005
								],
								"e": 0.001
							},
							{
								"h": [
									0.005,
									0.001,
									0.001,
									0.002,
									0.002,
									0.0,
									0.002,
									0.001,
									0.001,
									0.002,
									0.0,
									0.0
								],
								"e": 0.001
							}
						],
						[
							{
								"h": [
									0.29,
									0.0,
									0.117,
									0.0,
									0.0,
									0.0,
									0.093,
									0.024,
									0.276,
									0.248,
									0.129,
									0.706
								],
								"e": 0.074
							},
							{
								"h": [
									0.048,
									0.052,
									0.261,
									0.023,
									0.165,
									0.675,
									0.3,
									0.34,
									0.326,
									0.152,
									0.318,
									0.019
								],
								"e": 0.256
							},
							{
								"h": [
									0.026,
									0.038,
									0.0,
									0.001,
									0.004,
									0.001,
									0.129,
									0.219,
									0.003,
									0.001,
									0.099,
									0.014
								],
								"e": 0.039
							},
							{
								"h": [
									0.057,
									0.009,
									0.0,
									0.0,
									0.062,
									0.035,
									0.001,
									0.0,
									0.0,
									0.004,
									0.037,
									0.004
								],
								"e": 0.02
							},
							{
								"h": [
									0.0,
									0.007,
									0.134,
									0.002,
									0.074,
									0.002,
									0.03,
									0.008,
									0.139,
									0.007,
									0.05,
									0.0
								],
								"e": 0.037
							},
							{
								"h": [
									0.0,
									0.0,
									0.005,
									0.028,
									0.002,
									0.0,
									0.004,
									0.0,
									0.0,
									0.042,
									0.127,
									0.037
								],
								"e": 0.021
							},
							{
								"h": [
									0.01,
									0.001,
									0.023,
									0.034,
									0.058,
									0.012,
									0.088,
									0.018,
									0.013,
									0.0,
									0.013,
									0.001
								],
								"e": 0.022
							},
							{
								"h": [
									0.007,
									0.015,
									0.0,
									0.002,
									0.0,
									0.008,
									0.012,
									0.032,
									0.001,
									0.0,
									0.0,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.021,
									0.001,
									0.001,
									0.004,
									0.0,
									0.005,
									0.021,
									0.037,
									0.013,
									0.031,
									0.03,
									0.005
								],
								"e": 0.012
							},
							{
								"h": [
									0.005,
									0.001,
									0.006,
									0.029,
									0.001,
									0.01,
									0.0,
									0.051,
									0.001,
									0.001,
									0.0,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.004,
									0.003,
									0.0,
									0.003,
									0.002,
									0.01,
									0.001,
									0.001,
									0.037
								],
								"e": 0.005
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.001,
									0.007,
									0.002,
									0.001,
									0.001,
									0.001,
									0.02,
									0.003,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.167,
									0.959,
									0.315,
									0.002,
									0.044,
									0.695,
									0.532,
									0.012,
									0.331,
									0.02,
									0.87,
									0.249
								],
								"e": 0.335
							},
							{
								"h": [
									0.019,
									0.012,
									0.351,
									0.023,
									0.211,
									0.064,
									0.045,
									0.038,
									0.185,
									0.122,
									0.374,
									0.571
								],
								"e": 0.319
							},
							{
								"h": [
									0.018,
									0.001,
									0.0,
									0.003,
									0.0,
									0.0,
									0.023,
									0.37,
									0.001,
									0.001,
									0.121,
									0.013
								],
								"e": 0.023
							},
							{
								"h": [
									0.67,
									0.001,
									0.0,
									0.0,
									0.008,
									0.006,
									0.001,
									0.0,
									0.0,
									0.002,
									0.036,
									0.001
								],
								"e": 0.051
							},
							{
								"h": [
									0.0,
									0.001,
									0.003,
									0.001,
									0.04,
									0.0,
									0.007,
									0.057,
									0.003,
									0.002,
									0.004,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.002,
									0.0,
									0.001,
									0.008,
									0.001,
									0.0,
									0.0,
									0.001,
									0.001,
									0.012,
									0.014,
									0.01
								],
								"e": 0.004
							},
							{
								"h": [
									0.004,
									0.004,
									0.005,
									0.016,
									0.013,
									0.012,
									0.36,
									0.041,
									0.0,
									0.0,
									0.008,
									0.002
								],
								"e": 0.027
							},
							{
								"h": [
									0.001,
									0.004,
									0.002,
									0.003,
									0.0,
									0.001,
									0.007,
									0.042,
									0.0,
									0.001,
									0.0,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.003,
									0.0,
									0.0,
									0.026,
									0.001,
									0.007,
									0.014,
									0.043,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.017,
									0.0,
									0.012,
									0.002,
									0.002,
									0.024,
									0.001,
									0.006,
									0.001,
									0.003,
									0.0,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.005,
									0.001,
									0.001,
									0.0,
									0.001,
									0.0,
									0.012,
									0.003,
									0.001,
									0.0,
									0.008,
									0.007
								],
								"e": 0.003
							},
							{
								"h": [
									0.039,
									0.001,
									0.0,
									0.001,
									0.002,
									0.0,
									0.001,
									0.001,
									0.344,
									0.001,
									0.0,
									0.0
								],
								"e": 0.02
							}
						],
						[
							{
								"h": [
									0.036,
									0.0,
									0.052,
									0.0,
									0.0,
									0.0,
									0.104,
									0.016,
									0.176,
									0.264,
									0.095,
									0.159
								],
								"e": 0.036
							},
							{
								"h": [
									0.073,
									0.009,
									0.238,
									0.019,
									0.161,
									0.341,
									0.176,
									0.147,
									0.173,
									0.091,
									0.332,
									0.0
								],
								"e": 0.167
							},
							{
								"h": [
									0.008,
									0.001,
									0.0,
									0.002,
									0.001,
									0.0,
									0.014,
									0.279,
									0.003,
									0.002,
									0.132,
									0.016
								],
								"e": 0.02
							},
							{
								"h": [
									0.03,
									0.001,
									0.0,
									0.0,
									0.011,
									0.005,
									0.001,
									0.0,
									0.0,
									0.002,
									0.021,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.002,
									0.038,
									0.0,
									0.015,
									0.002,
									0.012,
									0.002,
									0.005,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.344,
									0.628,
									0.006,
									0.007,
									0.001,
									1.0,
									0.0,
									0.0,
									0.601,
									0.052,
									0.013,
									0.02
								],
								"e": 0.211
							},
							{
								"h": [
									0.002,
									0.135,
									0.002,
									0.052,
									0.03,
									0.01,
									0.197,
									0.079,
									0.001,
									0.211,
									0.034,
									0.003
								],
								"e": 0.067
							},
							{
								"h": [
									0.0,
									0.023,
									0.674,
									0.009,
									0.0,
									0.002,
									0.026,
									0.107,
									0.0,
									0.007,
									1.0,
									0.171
								],
								"e": 0.126
							},
							{
								"h": [
									0.004,
									0.823,
									0.001,
									0.004,
									0.0,
									0.001,
									1.0,
									0.008,
									0.079,
									0.006,
									0.159,
									0.03
								],
								"e": 0.113
							},
							{
								"h": [
									0.043,
									0.025,
									0.062,
									0.005,
									0.002,
									0.008,
									0.078,
									0.039,
									0.009,
									1.0,
									0.0,
									0.007
								],
								"e": 0.076
							},
							{
								"h": [
									0.442,
									0.553,
									0.005,
									0.007,
									0.01,
									0.0,
									0.103,
									0.055,
									0.033,
									0.0,
									0.034,
									0.204
								],
								"e": 0.089
							},
							{
								"h": [
									0.007,
									0.011,
									0.001,
									0.004,
									0.003,
									0.003,
									0.008,
									0.007,
									0.014,
									0.277,
									0.043,
									0.0
								],
								"e": 0.023
							}
						],
						[
							{
								"h": [
									0.134,
									0.0,
									0.078,
									0.0,
									0.0,
									0.0,
									0.338,
									0.035,
									0.575,
									0.316,
									0.143,
									0.265
								],
								"e": 0.072
							},
							{
								"h": [
									0.084,
									0.067,
									0.31,
									0.026,
									0.18,
									0.827,
									0.237,
									0.295,
									0.251,
									0.138,
									0.348,
									0.022
								],
								"e": 0.265
							},
							{
								"h": [
									0.046,
									0.043,
									0.0,
									0.008,
									0.007,
									0.006,
									0.06,
									0.379,
									0.016,
									0.001,
									0.13,
									0.02
								],
								"e": 0.044
							},
							{
								"h": [
									0.016,
									0.008,
									0.001,
									0.0,
									0.084,
									0.021,
									0.001,
									0.0,
									0.001,
									0.008,
									0.034,
									0.002
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.006,
									0.062,
									0.001,
									0.107,
									0.001,
									0.035,
									0.003,
									0.291,
									0.035,
									0.044,
									0.0
								],
								"e": 0.042
							},
							{
								"h": [
									0.973,
									0.004,
									0.112,
									0.054,
									0.001,
									0.382,
									0.0,
									0.0,
									0.956,
									0.077,
									0.05,
									0.028
								],
								"e": 0.176
							},
							{
								"h": [
									0.007,
									0.139,
									0.008,
									0.294,
									0.116,
									0.005,
									0.102,
									0.139,
									0.004,
									0.001,
									0.061,
									0.001
								],
								"e": 0.066
							},
							{
								"h": [
									0.001,
									0.018,
									0.002,
									0.01,
									0.0,
									0.002,
									0.009,
									1.0,
									0.0,
									0.002,
									0.003,
									0.003
								],
								"e": 0.04
							},
							{
								"h": [
									0.011,
									0.008,
									0.001,
									0.002,
									0.0,
									0.0,
									0.018,
									0.004,
									0.032,
									0.021,
									0.023,
									0.002
								],
								"e": 0.009
							},
							{
								"h": [
									0.004,
									0.001,
									0.003,
									0.005,
									0.0,
									0.001,
									0.001,
									0.008,
									0.001,
									0.004,
									0.0,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.003,
									0.002,
									0.001,
									0.001,
									0.001,
									0.0,
									0.002,
									0.001,
									0.004,
									0.0,
									0.003,
									0.004
								],
								"e": 0.002
							},
							{
								"h": [
									0.005,
									0.001,
									0.0,
									0.001,
									0.007,
									0.002,
									0.002,
									0.0,
									0.002,
									0.01,
									0.0,
									0.0
								],
								"e": 0.002
							}
						],
						[
							{
								"h": [
									0.199,
									0.0,
									0.184,
									0.0,
									0.0,
									0.0,
									0.245,
									0.067,
									0.445,
									0.396,
									0.162,
									0.186
								],
								"e": 0.079
							},
							{
								"h": [
									0.216,
									0.091,
									0.343,
									0.033,
									0.222,
									0.565,
									0.294,
									0.527,
									0.398,
									0.235,
									0.544,
									0.002
								],
								"e": 0.358
							},
							{
								"h": [
									0.109,
									0.008,
									0.0,
									0.251,
									0.026,
									0.004,
									0.053,
									0.368,
									0.024,
									0.008,
									0.211,
									0.031
								],
								"e": 0.072
							},
							{
								"h": [
									0.006,
									0.007,
									0.003,
									0.0,
									0.075,
									0.056,
									0.002,
									0.0,
									0.0,
									0.007,
									0.041,
									0.017
								],
								"e": 0.024
							},
							{
								"h": [
									0.001,
									0.001,
									0.012,
									0.002,
									0.004,
									0.001,
									0.06,
									0.014,
									0.086,
									0.008,
									0.028,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.003,
									0.0,
									0.008,
									0.017,
									0.002,
									0.025,
									0.0,
									0.0,
									0.023,
									0.033,
									0.031,
									0.012
								],
								"e": 0.012
							},
							{
								"h": [
									0.004,
									0.005,
									0.028,
									0.044,
									0.012,
									0.009,
									0.017,
									0.025,
									0.001,
									0.0,
									0.024,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.026,
									0.0,
									0.004,
									0.0,
									0.002,
									0.023,
									0.218,
									0.001,
									0.0,
									0.0,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.048,
									0.003,
									0.002,
									0.001,
									0.0,
									0.002,
									0.005,
									0.002,
									0.004,
									0.017,
									0.006,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.004,
									0.001,
									0.001,
									0.004,
									0.0,
									0.002,
									0.0,
									0.008,
									0.001,
									0.002,
									0.0,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.0,
									0.003,
									0.0,
									0.0,
									0.001,
									0.001,
									0.0,
									0.001,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.01,
									0.0,
									0.0,
									0.002,
									0.002,
									0.002,
									0.002,
									0.001,
									0.0,
									0.004,
									0.0,
									0.0
								],
								"e": 0.002
							}
						],
						[
							{
								"h": [
									0.636,
									0.002,
									0.302,
									0.0,
									0.006,
									0.0,
									1.0,
									0.061,
									0.281,
									0.256,
									0.196,
									0.444
								],
								"e": 0.131
							},
							{
								"h": [
									0.063,
									0.073,
									0.454,
									0.036,
									0.306,
									0.571,
									0.078,
									0.243,
									0.224,
									0.101,
									0.625,
									0.002
								],
								"e": 0.261
							},
							{
								"h": [
									0.072,
									0.002,
									0.003,
									0.041,
									0.007,
									0.006,
									0.045,
									0.609,
									0.019,
									0.074,
									0.484,
									0.05
								],
								"e": 0.075
							},
							{
								"h": [
									0.005,
									0.003,
									0.001,
									0.0,
									0.038,
									0.017,
									0.033,
									0.003,
									0.0,
									0.005,
									0.066,
									0.035
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.001,
									0.003,
									0.022,
									0.006,
									0.001,
									0.101,
									0.023,
									0.01,
									0.009,
									0.026,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.003,
									0.0,
									0.009,
									0.037,
									0.002,
									0.001,
									0.001,
									0.0,
									0.003,
									0.054,
									0.022,
									0.006
								],
								"e": 0.011
							},
							{
								"h": [
									0.009,
									0.004,
									0.014,
									0.023,
									0.017,
									0.017,
									0.009,
									0.013,
									0.006,
									0.0,
									0.011,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.018,
									0.0,
									0.004,
									0.0,
									0.003,
									0.015,
									0.02,
									0.005,
									0.0,
									0.0,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.004,
									0.001,
									0.001,
									0.001,
									0.001,
									0.002,
									0.001,
									0.007,
									0.011,
									0.07,
									0.009,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.009,
									0.0,
									0.015,
									0.006,
									0.0,
									0.003,
									0.0,
									0.002,
									0.003,
									0.003,
									0.0,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.007,
									0.001,
									0.001,
									0.001,
									0.002,
									0.0,
									0.002,
									0.001,
									0.0,
									0.0,
									0.001,
									0.005
								],
								"e": 0.001
							},
							{
								"h": [
									0.006,
									0.001,
									0.001,
									0.007,
									0.005,
									0.0,
									0.005,
									0.0,
									0.001,
									0.005,
									0.0,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.335,
									0.0,
									0.119,
									0.0,
									0.0,
									0.0,
									0.101,
									0.17,
									0.324,
									0.292,
									0.14,
									0.823
								],
								"e": 0.1
							},
							{
								"h": [
									0.233,
									0.261,
									0.485,
									0.044,
									0.255,
									0.738,
									0.266,
									0.339,
									0.312,
									0.127,
									0.584,
									0.04
								],
								"e": 0.413
							},
							{
								"h": [
									0.152,
									0.028,
									0.003,
									0.026,
									0.079,
									0.035,
									0.428,
									0.406,
									0.062,
									0.027,
									0.299,
									0.044
								],
								"e": 0.129
							},
							{
								"h": [
									0.054,
									0.106,
									0.014,
									0.008,
									0.07,
									0.059,
									0.036,
									0.006,
									0.017,
									0.041,
									0.121,
									0.069
								],
								"e": 0.065
							},
							{
								"h": [
									0.008,
									0.066,
									0.156,
									0.009,
									0.079,
									0.013,
									0.108,
									0.034,
									0.139,
									0.023,
									0.041,
									0.0
								],
								"e": 0.058
							},
							{
								"h": [
									0.0,
									0.0,
									0.035,
									0.059,
									0.008,
									0.0,
									0.034,
									0.0,
									0.0,
									0.02,
									0.043,
									0.025
								],
								"e": 0.021
							},
							{
								"h": [
									0.038,
									0.003,
									0.026,
									0.052,
									0.03,
									0.044,
									0.143,
									0.028,
									0.134,
									0.0,
									0.018,
									0.009
								],
								"e": 0.048
							},
							{
								"h": [
									0.088,
									0.027,
									0.0,
									0.007,
									0.0,
									0.007,
									0.014,
									0.025,
									0.003,
									0.001,
									0.0,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.02,
									0.001,
									0.002,
									0.005,
									0.0,
									0.014,
									0.026,
									0.145,
									0.02,
									0.041,
									0.049,
									0.007
								],
								"e": 0.023
							},
							{
								"h": [
									0.004,
									0.001,
									0.006,
									0.094,
									0.0,
									0.007,
									0.0,
									0.071,
									0.001,
									0.001,
									0.002,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.004,
									0.005,
									0.001,
									0.002,
									0.003,
									0.008,
									0.002,
									0.001,
									0.022
								],
								"e": 0.004
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.002,
									0.007,
									0.002,
									0.001,
									0.001,
									0.002,
									0.017,
									0.003,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.077,
									0.001,
									0.072,
									0.0,
									0.006,
									0.0,
									0.772,
									0.102,
									0.226,
									0.318,
									0.147,
									0.242
								],
								"e": 0.08
							},
							{
								"h": [
									0.181,
									0.062,
									0.582,
									0.04,
									0.297,
									0.66,
									0.335,
									0.097,
									0.231,
									0.086,
									0.649,
									0.004
								],
								"e": 0.316
							},
							{
								"h": [
									0.127,
									0.002,
									0.011,
									0.203,
									0.022,
									0.006,
									0.095,
									0.706,
									0.078,
									0.111,
									0.455,
									0.072
								],
								"e": 0.119
							},
							{
								"h": [
									0.001,
									0.026,
									0.009,
									0.003,
									0.047,
									0.024,
									0.1,
									0.183,
									0.002,
									0.055,
									0.157,
									0.236
								],
								"e": 0.078
							},
							{
								"h": [
									0.048,
									0.032,
									0.03,
									0.059,
									0.121,
									0.017,
									0.236,
									0.356,
									0.067,
									0.101,
									0.008,
									0.0
								],
								"e": 0.099
							},
							{
								"h": [
									0.021,
									0.001,
									0.004,
									0.085,
									0.013,
									0.007,
									0.005,
									0.004,
									0.029,
									0.3,
									0.047,
									0.015
								],
								"e": 0.038
							},
							{
								"h": [
									0.016,
									0.077,
									0.009,
									0.059,
									0.047,
									0.04,
									0.442,
									0.207,
									0.005,
									0.0,
									0.029,
									0.012
								],
								"e": 0.064
							},
							{
								"h": [
									0.005,
									0.018,
									0.002,
									0.024,
									0.0,
									0.004,
									0.01,
									0.056,
									0.002,
									0.005,
									0.0,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.008,
									0.002,
									0.001,
									0.004,
									0.001,
									0.006,
									0.302,
									0.068,
									0.053,
									0.034,
									0.117,
									0.003
								],
								"e": 0.034
							},
							{
								"h": [
									0.124,
									0.0,
									0.034,
									0.038,
									0.003,
									0.01,
									0.002,
									0.071,
									0.012,
									0.002,
									0.0,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.011,
									0.006,
									0.001,
									0.001,
									0.002,
									0.0,
									0.007,
									0.002,
									0.002,
									0.002,
									0.001,
									0.047
								],
								"e": 0.005
							},
							{
								"h": [
									0.027,
									0.007,
									0.0,
									0.004,
									0.001,
									0.0,
									0.002,
									0.003,
									1.0,
									0.005,
									0.004,
									0.0
								],
								"e": 0.051
							}
						],
						[
							{
								"h": [
									0.142,
									0.0,
									0.079,
									0.0,
									0.0,
									0.0,
									0.383,
									0.198,
									0.658,
									0.354,
									0.147,
									0.284
								],
								"e": 0.097
							},
							{
								"h": [
									0.331,
									0.266,
									0.494,
									0.041,
									0.262,
									0.851,
									0.203,
									0.346,
									0.254,
									0.128,
									0.554,
									0.027
								],
								"e": 0.423
							},
							{
								"h": [
									0.23,
									0.052,
									0.021,
									0.136,
									0.101,
									0.128,
									0.173,
									0.618,
									0.213,
									0.02,
									0.355,
									0.055
								],
								"e": 0.178
							},
							{
								"h": [
									0.007,
									0.137,
									0.075,
									0.031,
									0.077,
									0.061,
									0.031,
									0.012,
									0.058,
									0.098,
									0.143,
									0.053
								],
								"e": 0.098
							},
							{
								"h": [
									0.02,
									0.17,
									0.173,
									0.014,
									0.118,
									0.051,
									0.502,
									0.011,
									0.561,
									0.738,
									0.061,
									0.0
								],
								"e": 0.194
							},
							{
								"h": [
									1.0,
									0.0,
									1.0,
									0.349,
									0.018,
									0.0,
									0.004,
									0.002,
									0.024,
									0.371,
									0.1,
									0.049
								],
								"e": 0.207
							},
							{
								"h": [
									0.057,
									0.473,
									0.012,
									1.0,
									0.164,
									0.03,
									0.093,
									0.552,
									0.044,
									0.0,
									0.04,
									0.012
								],
								"e": 0.185
							},
							{
								"h": [
									0.023,
									0.008,
									0.0,
									0.052,
									0.0,
									0.004,
									0.007,
									0.357,
									0.002,
									0.011,
									0.0,
									0.004
								],
								"e": 0.023
							},
							{
								"h": [
									0.016,
									0.013,
									0.0,
									0.002,
									0.0,
									0.002,
									0.033,
									0.035,
									0.08,
									0.043,
									0.042,
									0.005
								],
								"e": 0.019
							},
							{
								"h": [
									0.003,
									0.001,
									0.002,
									0.048,
									0.0,
									0.002,
									0.001,
									0.02,
									0.0,
									0.001,
									0.001,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.001,
									0.001,
									0.003,
									0.001,
									0.001,
									0.006
								],
								"e": 0.001
							},
							{
								"h": [
									0.003,
									0.002,
									0.0,
									0.001,
									0.007,
									0.002,
									0.002,
									0.0,
									0.002,
									0.008,
									0.0,
									0.0
								],
								"e": 0.002
							}
						],
						[
							{
								"h": [
									0.277,
									0.0,
									0.101,
									0.0,
									0.008,
									0.0,
									0.2,
									0.122,
									0.286,
									0.29,
									0.088,
									0.447
								],
								"e": 0.081
							},
							{
								"h": [
									0.498,
									0.221,
									0.862,
									0.062,
									0.536,
									0.146,
									0.102,
									0.705,
									0.163,
									0.113,
									0.965,
									0.015
								],
								"e": 0.509
							},
							{
								"h": [
									1.0,
									0.015,
									0.182,
									0.719,
									0.2,
									0.036,
									0.091,
									0.719,
									0.048,
									1.0,
									0.772,
									0.1
								],
								"e": 0.385
							},
							{
								"h": [
									0.0,
									0.029,
									0.049,
									0.223,
									0.03,
									0.035,
									1.0,
									0.444,
									0.017,
									0.076,
									0.275,
									0.223
								],
								"e": 0.229
							},
							{
								"h": [
									0.085,
									0.04,
									0.016,
									0.176,
									0.004,
									0.014,
									0.47,
									0.213,
									0.05,
									0.091,
									0.006,
									0.0
								],
								"e": 0.103
							},
							{
								"h": [
									0.01,
									0.0,
									0.126,
									0.147,
									0.028,
									0.001,
									0.011,
									0.002,
									0.017,
									0.293,
									0.101,
									0.014
								],
								"e": 0.058
							},
							{
								"h": [
									0.05,
									0.029,
									0.024,
									0.094,
									0.025,
									0.022,
									0.024,
									0.051,
									0.013,
									0.0,
									0.037,
									0.014
								],
								"e": 0.034
							},
							{
								"h": [
									0.015,
									0.01,
									0.0,
									0.017,
									0.0,
									0.003,
									0.013,
									0.116,
									0.018,
									0.001,
									0.0,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.008,
									0.003,
									0.002,
									0.001,
									0.002,
									0.013,
									0.007,
									0.027,
									0.007,
									0.022,
									0.004,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.004,
									0.0,
									0.01,
									0.032,
									0.0,
									0.001,
									0.0,
									0.003,
									0.005,
									0.0,
									0.0,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.001,
									0.0,
									0.001
								],
								"e": 0.0
							},
							{
								"h": [
									0.02,
									0.001,
									0.001,
									0.008,
									0.001,
									0.0,
									0.0,
									0.0,
									0.003,
									0.0,
									0.0,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.32,
									0.0,
									0.112,
									0.0,
									0.013,
									0.0,
									0.834,
									0.739,
									0.264,
									0.68,
									0.144,
									1.0
								],
								"e": 0.207
							},
							{
								"h": [
									0.163,
									0.137,
									1.0,
									0.072,
									0.475,
									0.18,
									0.221,
									1.0,
									0.206,
									0.127,
									1.0,
									0.008
								],
								"e": 0.468
							},
							{
								"h": [
									0.367,
									0.012,
									0.392,
									0.443,
									0.173,
									0.111,
									0.111,
									0.618,
									0.245,
									0.829,
									1.0,
									0.127
								],
								"e": 0.384
							},
							{
								"h": [
									0.003,
									0.121,
									0.028,
									0.124,
									0.042,
									0.029,
									0.202,
									1.0,
									0.098,
									0.028,
									0.407,
									0.232
								],
								"e": 0.237
							},
							{
								"h": [
									0.028,
									0.039,
									0.023,
									0.203,
									0.011,
									0.018,
									0.537,
									0.037,
									0.047,
									0.081,
									0.04,
									0.0
								],
								"e": 0.089
							},
							{
								"h": [
									0.005,
									0.0,
									0.125,
									0.103,
									0.046,
									0.0,
									0.018,
									0.002,
									0.02,
									0.043,
									0.047,
									0.014
								],
								"e": 0.038
							},
							{
								"h": [
									0.076,
									0.016,
									0.012,
									0.055,
									0.009,
									0.062,
									0.004,
									0.054,
									0.05,
									0.0,
									0.009,
									0.005
								],
								"e": 0.035
							},
							{
								"h": [
									0.033,
									0.001,
									0.0,
									0.019,
									0.0,
									0.001,
									0.005,
									0.011,
									0.03,
									0.005,
									0.0,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.015,
									0.001,
									0.001,
									0.0,
									0.001,
									0.01,
									0.001,
									0.094,
									0.007,
									0.032,
									0.005,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.003,
									0.0,
									0.003,
									0.06,
									0.0,
									0.001,
									0.0,
									0.004,
									0.003,
									0.0,
									0.001,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002
								],
								"e": 0.0
							},
							{
								"h": [
									0.005,
									0.002,
									0.001,
									0.01,
									0.002,
									0.0,
									0.001,
									0.001,
									0.001,
									0.002,
									0.0,
									0.0
								],
								"e": 0.002
							}
						],
						[
							{
								"h": [
									0.373,
									0.0,
									0.126,
									0.0,
									0.001,
									0.0,
									0.113,
									1.0,
									0.375,
									0.333,
									0.145,
									0.922
								],
								"e": 0.2
							},
							{
								"h": [
									1.0,
									1.0,
									0.841,
									0.074,
									0.4,
									0.78,
									0.284,
									0.411,
									0.316,
									0.112,
									0.937,
									0.056
								],
								"e": 0.888
							},
							{
								"h": [
									0.704,
									0.026,
									1.0,
									0.541,
									1.0,
									1.0,
									1.0,
									0.629,
									1.0,
									0.72,
									0.733,
									0.119
								],
								"e": 1.0
							},
							{
								"h": [
									0.035,
									1.0,
									1.0,
									1.0,
									0.084,
									0.109,
									0.974,
									0.643,
									1.0,
									0.353,
									0.422,
									0.98
								],
								"e": 1.0
							},
							{
								"h": [
									0.273,
									1.0,
									0.315,
									0.15,
									0.078,
									0.192,
									0.589,
									0.139,
									0.148,
									0.137,
									0.068,
									1.0
								],
								"e": 0.545
							},
							{
								"h": [
									0.0,
									0.0,
									0.287,
									0.316,
									0.104,
									0.0,
									1.0,
									0.002,
									0.0,
									0.039,
									0.095,
									0.049
								],
								"e": 0.182
							},
							{
								"h": [
									0.273,
									0.017,
									0.049,
									0.162,
									0.05,
									0.163,
									0.166,
									0.105,
									1.0,
									0.0,
									0.012,
									0.108
								],
								"e": 0.229
							},
							{
								"h": [
									0.71,
									0.006,
									0.0,
									0.018,
									0.001,
									0.012,
									0.011,
									0.014,
									0.034,
									0.005,
									0.0,
									0.0
								],
								"e": 0.055
							},
							{
								"h": [
									0.025,
									0.0,
									0.001,
									0.006,
									0.002,
									0.058,
									0.016,
									1.0,
									0.04,
									0.047,
									0.041,
									0.01
								],
								"e": 0.083
							},
							{
								"h": [
									0.008,
									0.001,
									0.007,
									0.405,
									0.0,
									0.009,
									0.0,
									0.091,
									0.001,
									0.001,
									0.005,
									0.0
								],
								"e": 0.033
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.003,
									0.004,
									0.002,
									0.002,
									0.002,
									0.006,
									0.005,
									0.0,
									0.023
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.002,
									0.006,
									0.002,
									0.001,
									0.001,
									0.002,
									0.012,
									0.003,
									0.0
								],
								"e": 0.002
							}
						],
						[
							{
								"h": [
									0.216,
									1.0,
									0.355,
									1.0,
									1.0,
									0.587,
									0.675,
									0.344,
									0.444,
									0.025,
									1.0,
									0.325
								],
								"e": 1.0
							},
							{
								"h": [
									0.328,
									0.153,
									0.985,
									0.064,
									0.489,
									0.086,
									0.033,
									0.037,
									0.197,
									0.119,
									0.921,
									1.0
								],
								"e": 0.696
							},
							{
								"h": [
									0.427,
									0.001,
									0.381,
									1.0,
									0.124,
									0.081,
									0.161,
									0.964,
									0.311,
									0.444,
									0.789,
									0.103
								],
								"e": 0.395
							},
							{
								"h": [
									0.44,
									0.071,
									0.155,
									0.072,
									0.012,
									0.021,
									0.692,
									0.151,
									0.026,
									0.157,
									0.521,
									0.336
								],
								"e": 0.244
							},
							{
								"h": [
									0.201,
									0.177,
									0.012,
									0.104,
									0.068,
									0.072,
									0.238,
									1.0,
									0.005,
									0.047,
									0.007,
									0.0
								],
								"e": 0.202
							},
							{
								"h": [
									0.004,
									0.0,
									0.032,
									0.131,
									0.058,
									0.0,
									0.007,
									0.023,
									0.008,
									0.022,
									0.02,
									0.014
								],
								"e": 0.034
							},
							{
								"h": [
									0.088,
									0.064,
									0.012,
									0.067,
									0.012,
									0.11,
									0.433,
									0.164,
									0.022,
									0.0,
									0.006,
									0.05
								],
								"e": 0.079
							},
							{
								"h": [
									0.07,
									0.002,
									0.001,
									0.03,
									0.001,
									0.002,
									0.005,
									0.023,
									0.008,
									0.01,
									0.0,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.005,
									0.001,
									0.0,
									0.007,
									0.002,
									0.007,
									0.018,
									0.043,
									0.016,
									0.026,
									0.08,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.018,
									0.0,
									0.015,
									0.059,
									0.002,
									0.033,
									0.0,
									0.018,
									0.001,
									0.002,
									0.0,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.005,
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.01,
									0.002,
									0.001,
									0.004,
									0.005,
									0.006
								],
								"e": 0.003
							},
							{
								"h": [
									0.053,
									0.001,
									0.0,
									0.003,
									0.002,
									0.0,
									0.002,
									0.001,
									0.812,
									0.001,
									0.0,
									0.0
								],
								"e": 0.043
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": "ain",
					"o": ":",
					"t": ":",
					"r": [
						{
							"p": "ain",
							"b": 0.001,
							"k": {
								"ain": 0.67,
								"ie": 0.026,
								"ter": 0.014,
								"ling": 0.011,
								"ous": 0.01,
								" Bat": 0.009,
								",": 0.008,
								"ian": 0.006,
								" Bar": 0.006,
								" Palace": 0.005
							}
						},
						{
							"p": "ous",
							"b": 0.001,
							"k": {
								"ous": 0.45,
								"ain": 0.04,
								"'s": 0.036,
								" Spectre": 0.034,
								" Mercenary": 0.021,
								"ess": 0.02,
								",": 0.012,
								"ify": 0.009,
								" Prince": 0.009,
								" Cipher": 0.009
							}
						},
						{
							"p": "ous",
							"b": 0.0,
							"k": {
								"ous": 0.685,
								"'s": 0.026,
								"ess": 0.026,
								" Spectre": 0.018,
								" Mercenary": 0.015,
								" Siege": 0.012,
								"ain": 0.01,
								",": 0.008,
								"istan": 0.006,
								" Wraith": 0.005
							}
						},
						{
							"p": "ous",
							"b": 0.0,
							"k": {
								"ous": 0.963,
								"'s": 0.005,
								"ess": 0.004,
								" Wraith": 0.002,
								",": 0.001,
								" Zone": 0.001,
								" Kid": 0.001,
								" Skull": 0.001,
								" Siege": 0.001,
								" Mercenary": 0.001
							}
						},
						{
							"p": "ous",
							"b": 0.0,
							"k": {
								"ous": 0.952,
								"ess": 0.019,
								"'s": 0.004,
								" Mercenary": 0.002,
								",": 0.001,
								" Leader": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": "ous",
							"b": 0.02,
							"k": {
								"ous": 0.923,
								"ess": 0.045,
								":": 0.02,
								"'s": 0.003,
								",": 0.001,
								" vs": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": "ous",
							"b": 0.042,
							"k": {
								"ous": 0.926,
								":": 0.042,
								"ess": 0.027,
								"'s": 0.002,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0
							}
						},
						{
							"p": ":",
							"b": 0.965,
							"k": {
								":": 0.965,
								"ous": 0.035,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": ":",
							"b": 1.0,
							"k": {
								":": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ":",
							"b": 1.0,
							"k": {
								":": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ":",
							"b": 1.0,
							"k": {
								":": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ":",
							"b": 0.997,
							"k": {
								":": 0.997,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.232,
									0.0,
									0.524,
									0.0,
									0.001,
									0.005,
									0.266,
									0.002,
									0.455,
									0.702,
									0.277,
									1.0
								],
								"e": 0.137
							},
							{
								"h": [
									0.006,
									0.025,
									0.142,
									1.0,
									1.0,
									0.506,
									0.109,
									1.0,
									1.0,
									1.0,
									0.034,
									0.014
								],
								"e": 1.0
							},
							{
								"h": [
									0.977,
									1.0,
									0.001,
									0.097,
									0.338,
									0.031,
									0.178,
									0.97,
									0.31,
									0.163,
									0.096,
									1.0
								],
								"e": 0.835
							},
							{
								"h": [
									0.417,
									0.287,
									0.026,
									1.0,
									1.0,
									1.0,
									0.209,
									0.379,
									0.074,
									1.0,
									1.0,
									0.654
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.475,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.263,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.027,
									0.21,
									1.0,
									1.0,
									1.0,
									0.194,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.812,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.126,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.333,
									1.0,
									0.948,
									1.0,
									1.0,
									1.0,
									1.0,
									0.273,
									1.0,
									1.0,
									0.974,
									0.809
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.647,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.133,
									0.0,
									0.383,
									0.0,
									0.0,
									0.0,
									0.1,
									0.0,
									0.398,
									0.563,
									0.172,
									0.796
								],
								"e": 0.094
							},
							{
								"h": [
									0.001,
									0.006,
									0.091,
									0.006,
									0.118,
									0.212,
									0.055,
									0.242,
									0.453,
									0.145,
									0.095,
									0.034
								],
								"e": 0.152
							},
							{
								"h": [
									0.012,
									0.011,
									0.0,
									0.0,
									0.001,
									0.0,
									0.012,
									0.063,
									0.001,
									0.0,
									0.032,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.021,
									0.001,
									0.0,
									0.0,
									0.091,
									0.03,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.0,
									0.0,
									0.066,
									0.0,
									0.078,
									0.0,
									0.002,
									0.0,
									0.11,
									0.004,
									0.084,
									0.0
								],
								"e": 0.034
							},
							{
								"h": [
									0.013,
									0.022,
									0.001,
									0.011,
									0.0,
									0.037,
									0.0,
									0.0,
									0.032,
									0.1,
									0.139,
									0.057
								],
								"e": 0.031
							},
							{
								"h": [
									0.002,
									0.007,
									0.029,
									0.043,
									0.027,
									0.002,
									0.023,
									0.021,
									0.0,
									0.109,
									0.02,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.0,
									0.009,
									0.763,
									0.005,
									0.0,
									0.008,
									0.012,
									0.015,
									0.0,
									0.0,
									0.486,
									0.09
								],
								"e": 0.061
							},
							{
								"h": [
									0.014,
									0.269,
									0.054,
									0.002,
									0.0,
									0.001,
									0.125,
									0.0,
									0.007,
									0.009,
									0.005,
									0.005
								],
								"e": 0.026
							},
							{
								"h": [
									0.031,
									0.026,
									0.01,
									0.001,
									0.044,
									0.002,
									0.011,
									0.024,
									0.002,
									0.022,
									0.001,
									0.594
								],
								"e": 0.034
							},
							{
								"h": [
									0.011,
									0.025,
									0.092,
									0.172,
									0.029,
									0.0,
									0.009,
									0.024,
									0.416,
									0.0,
									0.002,
									0.017
								],
								"e": 0.047
							},
							{
								"h": [
									0.024,
									0.01,
									0.025,
									0.006,
									0.047,
									0.01,
									0.012,
									0.031,
									0.0,
									0.029,
									0.03,
									0.001
								],
								"e": 0.02
							}
						],
						[
							{
								"h": [
									0.196,
									0.002,
									0.585,
									0.0,
									0.001,
									0.0,
									0.08,
									0.0,
									0.091,
									0.775,
									0.176,
									0.308
								],
								"e": 0.081
							},
							{
								"h": [
									0.001,
									0.004,
									0.11,
									0.008,
									0.11,
									0.154,
									0.038,
									0.167,
									0.199,
									0.207,
									0.14,
									0.011
								],
								"e": 0.122
							},
							{
								"h": [
									0.011,
									0.004,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.114,
									0.0,
									0.0,
									0.05,
									0.004
								],
								"e": 0.011
							},
							{
								"h": [
									0.005,
									0.0,
									0.0,
									0.0,
									0.029,
									0.009,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.0,
									0.011,
									0.0,
									0.004,
									0.0,
									0.001,
									0.001,
									0.008,
									0.0,
									0.004,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.001,
									0.0,
									0.007,
									0.0,
									0.0,
									0.001,
									0.055,
									0.053,
									0.017
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.0,
									0.022,
									0.01,
									0.004,
									0.001,
									0.006,
									0.004,
									0.0,
									0.012,
									0.007,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.01,
									0.017,
									0.001,
									0.0,
									0.001,
									0.006,
									0.004,
									0.0,
									0.0,
									0.015,
									0.013
								],
								"e": 0.004
							},
							{
								"h": [
									0.002,
									0.021,
									0.006,
									0.001,
									0.0,
									0.0,
									0.008,
									0.0,
									0.001,
									0.004,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.004,
									0.001,
									0.003,
									0.0,
									0.008,
									0.002,
									0.002,
									0.008,
									0.004,
									0.001,
									0.0,
									0.005
								],
								"e": 0.003
							},
							{
								"h": [
									0.004,
									0.006,
									0.005,
									0.017,
									0.002,
									0.0,
									0.005,
									0.0,
									0.006,
									0.0,
									0.002,
									0.005
								],
								"e": 0.004
							},
							{
								"h": [
									0.047,
									0.019,
									0.006,
									0.003,
									0.003,
									0.001,
									0.005,
									0.006,
									0.0,
									0.005,
									0.0,
									0.001
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.206,
									0.0,
									0.423,
									0.0,
									0.0,
									0.0,
									0.163,
									0.001,
									0.124,
									0.722,
									0.098,
									0.457
								],
								"e": 0.082
							},
							{
								"h": [
									0.001,
									0.002,
									0.133,
									0.008,
									0.12,
									0.358,
									0.016,
									0.161,
									0.187,
									0.201,
									0.168,
									0.013
								],
								"e": 0.137
							},
							{
								"h": [
									0.002,
									0.003,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.157,
									0.0,
									0.0,
									0.064,
									0.005
								],
								"e": 0.013
							},
							{
								"h": [
									0.009,
									0.0,
									0.0,
									0.0,
									0.083,
									0.008,
									0.0,
									0.0,
									0.0,
									0.0,
									0.005,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.0,
									0.003,
									0.0,
									0.002,
									0.0,
									0.001,
									0.002,
									0.008,
									0.0,
									0.005,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.028,
									0.005,
									0.013
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.0,
									0.013,
									0.004,
									0.002,
									0.001,
									0.001,
									0.002,
									0.0,
									0.001,
									0.002,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.002,
									0.01,
									0.0,
									0.0,
									0.0,
									0.005,
									0.0,
									0.0,
									0.0,
									0.006,
									0.006
								],
								"e": 0.002
							},
							{
								"h": [
									0.006,
									0.005,
									0.002,
									0.0,
									0.0,
									0.0,
									0.01,
									0.0,
									0.002,
									0.003,
									0.001,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.006,
									0.001,
									0.002,
									0.0,
									0.003,
									0.003,
									0.003,
									0.007,
									0.005,
									0.001,
									0.0,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.012,
									0.019,
									0.008,
									0.018,
									0.003,
									0.0,
									0.019,
									0.0,
									0.002,
									0.0,
									0.003,
									0.015
								],
								"e": 0.008
							},
							{
								"h": [
									0.025,
									0.014,
									0.015,
									0.004,
									0.007,
									0.006,
									0.019,
									0.019,
									0.0,
									0.013,
									0.001,
									0.0
								],
								"e": 0.01
							}
						],
						[
							{
								"h": [
									0.252,
									0.0,
									0.56,
									0.0,
									0.0,
									0.0,
									0.028,
									0.0,
									0.593,
									0.524,
									0.184,
									0.6
								],
								"e": 0.103
							},
							{
								"h": [
									0.001,
									0.013,
									0.127,
									0.009,
									0.122,
									0.332,
									0.097,
									0.307,
									0.404,
									0.095,
									0.134,
									0.04
								],
								"e": 0.174
							},
							{
								"h": [
									0.007,
									0.023,
									0.0,
									0.0,
									0.0,
									0.0,
									0.051,
									0.081,
									0.0,
									0.0,
									0.03,
									0.003
								],
								"e": 0.021
							},
							{
								"h": [
									0.043,
									0.001,
									0.0,
									0.0,
									0.052,
									0.015,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.0,
									0.084,
									0.0,
									0.056,
									0.0,
									0.001,
									0.002,
									0.074,
									0.0,
									0.045,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.006,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.051,
									0.136,
									0.018
								],
								"e": 0.015
							},
							{
								"h": [
									0.003,
									0.0,
									0.046,
									0.022,
									0.027,
									0.003,
									0.008,
									0.012,
									0.0,
									0.004,
									0.005,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.006,
									0.028,
									0.002,
									0.0,
									0.038,
									0.014,
									0.002,
									0.0,
									0.001,
									0.027,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.019,
									0.02,
									0.011,
									0.002,
									0.001,
									0.011,
									0.048,
									0.003,
									0.018,
									0.027,
									0.008,
									0.004
								],
								"e": 0.012
							},
							{
								"h": [
									0.01,
									0.012,
									0.007,
									0.012,
									0.022,
									0.006,
									0.002,
									0.044,
									0.002,
									0.004,
									0.003,
									0.005
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.004,
									0.017,
									0.047,
									0.084,
									0.001,
									0.002,
									0.023,
									0.104,
									0.0,
									0.0,
									0.014
								],
								"e": 0.018
							},
							{
								"h": [
									0.018,
									0.003,
									0.007,
									0.019,
									0.029,
									0.003,
									0.004,
									0.022,
									0.0,
									0.007,
									0.048,
									0.002
								],
								"e": 0.014
							}
						],
						[
							{
								"h": [
									0.64,
									0.008,
									0.184,
									0.001,
									0.009,
									0.0,
									0.599,
									0.012,
									0.234,
									0.528,
									0.152,
									0.287
								],
								"e": 0.134
							},
							{
								"h": [
									0.009,
									0.003,
									0.141,
									0.008,
									0.126,
									0.048,
									1.0,
									0.119,
									0.253,
									0.143,
									0.182,
									0.014
								],
								"e": 0.288
							},
							{
								"h": [
									0.005,
									0.003,
									0.0,
									0.0,
									0.0,
									0.0,
									0.004,
									0.107,
									0.0,
									0.0,
									0.043,
									0.003
								],
								"e": 0.01
							},
							{
								"h": [
									0.023,
									0.0,
									0.0,
									0.0,
									0.012,
									0.009,
									0.0,
									0.0,
									0.0,
									0.0,
									0.001,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.0,
									0.007,
									0.0,
									0.001,
									0.003,
									0.006,
									0.0,
									0.018,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.001,
									0.0,
									0.0,
									0.002,
									0.001,
									0.0,
									0.026,
									0.155,
									0.008
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.001,
									0.007,
									0.008,
									0.003,
									0.002,
									0.011,
									0.008,
									0.0,
									0.0,
									0.012,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.012,
									0.019,
									0.002,
									0.001,
									0.001,
									0.009,
									0.009,
									0.002,
									0.0,
									0.005,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.005,
									0.008,
									0.001,
									0.001,
									0.0,
									0.01,
									0.034,
									0.0,
									0.001,
									0.007,
									0.001,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.018,
									0.001,
									0.006,
									0.001,
									0.02,
									0.003,
									0.007,
									0.003,
									0.002,
									0.002,
									0.0,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.005,
									0.005,
									0.009,
									0.006,
									0.0,
									0.0,
									0.033,
									0.0,
									0.002,
									0.0,
									0.004,
									0.007
								],
								"e": 0.006
							},
							{
								"h": [
									0.047,
									0.003,
									0.013,
									0.002,
									0.001,
									0.001,
									0.009,
									0.002,
									0.0,
									0.006,
									0.0,
									0.0
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.059,
									0.716,
									0.074,
									0.0,
									0.009,
									1.0,
									0.074,
									0.0,
									0.118,
									0.179,
									0.764,
									0.308
								],
								"e": 0.345
							},
							{
								"h": [
									0.001,
									0.001,
									0.112,
									0.007,
									0.095,
									0.071,
									0.025,
									0.244,
									0.171,
									0.088,
									0.15,
									0.15
								],
								"e": 0.126
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0,
									0.004,
									0.091,
									0.0,
									0.0,
									0.047,
									0.004
								],
								"e": 0.008
							},
							{
								"h": [
									1.0,
									0.0,
									0.0,
									0.0,
									0.007,
									0.004,
									0.0,
									0.0,
									0.0,
									0.0,
									0.007,
									0.0
								],
								"e": 0.137
							},
							{
								"h": [
									0.0,
									0.0,
									0.003,
									0.0,
									0.004,
									0.0,
									0.0,
									0.025,
									0.014,
									0.0,
									0.011,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.011,
									0.025,
									0.026
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.001,
									0.001,
									0.003,
									0.001,
									0.001,
									0.016,
									0.005,
									0.0,
									0.002,
									0.019,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.011,
									0.014,
									0.0,
									0.0,
									0.001,
									0.006,
									0.002,
									0.0,
									0.0,
									0.007,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.002,
									0.008,
									0.002,
									0.0,
									0.0,
									0.001,
									0.04,
									0.0,
									0.002,
									0.007,
									0.001,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.02,
									0.001,
									0.012,
									0.0,
									0.012,
									0.002,
									0.001,
									0.005,
									0.004,
									0.007,
									0.0,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.005,
									0.011,
									0.003,
									0.003,
									0.008,
									0.0,
									0.006,
									0.0,
									0.022,
									0.0,
									0.002,
									0.006
								],
								"e": 0.005
							},
							{
								"h": [
									0.078,
									0.008,
									0.003,
									0.003,
									0.003,
									0.002,
									0.003,
									0.004,
									0.0,
									0.009,
									0.0,
									0.0
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.103,
									0.0,
									0.303,
									0.0,
									0.0,
									0.0,
									0.083,
									0.0,
									0.325,
									0.514,
									0.155,
									0.877
								],
								"e": 0.085
							},
							{
								"h": [
									0.002,
									0.014,
									0.147,
									0.01,
									0.128,
									0.351,
									0.062,
									0.25,
									0.299,
									0.131,
									0.16,
									0.073
								],
								"e": 0.172
							},
							{
								"h": [
									0.01,
									0.037,
									0.0,
									0.0,
									0.0,
									0.0,
									0.023,
									0.168,
									0.001,
									0.0,
									0.037,
									0.004
								],
								"e": 0.026
							},
							{
								"h": [
									0.018,
									0.0,
									0.0,
									0.0,
									0.097,
									0.02,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.0,
									0.0,
									0.086,
									0.0,
									0.034,
									0.0,
									0.002,
									0.002,
									0.197,
									0.002,
									0.061,
									0.0
								],
								"e": 0.033
							},
							{
								"h": [
									0.466,
									0.768,
									0.002,
									0.006,
									0.0,
									1.0,
									0.0,
									0.0,
									0.071,
									0.143,
									0.133,
									0.05
								],
								"e": 0.229
							},
							{
								"h": [
									0.002,
									0.01,
									0.009,
									0.049,
									0.029,
									0.001,
									0.027,
									0.018,
									0.0,
									0.386,
									0.035,
									0.0
								],
								"e": 0.046
							},
							{
								"h": [
									0.0,
									0.046,
									1.0,
									0.003,
									0.0,
									0.009,
									0.024,
									0.048,
									0.0,
									0.0,
									0.125,
									0.072
								],
								"e": 0.06
							},
							{
								"h": [
									0.009,
									0.464,
									0.073,
									0.002,
									0.0,
									0.0,
									0.298,
									0.0,
									0.008,
									0.026,
									0.011,
									0.001
								],
								"e": 0.045
							},
							{
								"h": [
									0.006,
									0.023,
									0.004,
									0.0,
									0.015,
									0.002,
									0.004,
									0.008,
									0.001,
									0.012,
									0.001,
									0.553
								],
								"e": 0.024
							},
							{
								"h": [
									0.001,
									0.008,
									0.019,
									0.06,
									0.017,
									0.0,
									0.002,
									0.008,
									0.2,
									0.0,
									0.0,
									0.003
								],
								"e": 0.017
							},
							{
								"h": [
									0.022,
									0.004,
									0.003,
									0.001,
									0.008,
									0.007,
									0.005,
									0.006,
									0.0,
									0.009,
									0.005,
									0.0
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.267,
									0.006,
									1.0,
									0.0,
									0.006,
									0.0,
									0.11,
									0.0,
									0.06,
									1.0,
									0.152,
									0.268
								],
								"e": 0.107
							},
							{
								"h": [
									0.002,
									0.013,
									0.25,
									0.014,
									0.18,
									1.0,
									0.042,
									0.35,
									0.304,
									0.4,
									0.291,
									0.014
								],
								"e": 0.286
							},
							{
								"h": [
									0.01,
									0.007,
									0.0,
									0.001,
									0.001,
									0.0,
									0.016,
									0.292,
									0.001,
									0.001,
									0.142,
									0.011
								],
								"e": 0.028
							},
							{
								"h": [
									0.086,
									0.0,
									0.0,
									0.0,
									0.126,
									0.013,
									0.001,
									0.0,
									0.0,
									0.0,
									0.012,
									0.001
								],
								"e": 0.033
							},
							{
								"h": [
									0.0,
									0.0,
									0.012,
									0.002,
									0.009,
									0.0,
									0.003,
									0.025,
									0.021,
									0.001,
									0.021,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.003,
									0.0,
									0.001,
									0.0,
									0.101,
									0.0,
									0.0,
									0.005,
									0.123,
									0.166,
									0.033
								],
								"e": 0.03
							},
							{
								"h": [
									0.001,
									0.0,
									0.007,
									0.008,
									0.004,
									0.002,
									0.009,
									0.007,
									0.0,
									0.161,
									0.012,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.046,
									0.06,
									0.001,
									0.0,
									0.001,
									0.022,
									0.018,
									0.0,
									0.0,
									0.042,
									0.014
								],
								"e": 0.013
							},
							{
								"h": [
									0.003,
									0.014,
									0.004,
									0.001,
									0.0,
									0.001,
									0.003,
									0.0,
									0.002,
									0.022,
									0.003,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.016,
									0.001,
									0.006,
									0.0,
									0.004,
									0.003,
									0.001,
									0.006,
									0.012,
									0.001,
									0.0,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.023,
									0.021,
									0.002,
									0.013,
									0.002,
									0.0,
									0.018,
									0.0,
									0.001,
									0.0,
									0.004,
									0.01
								],
								"e": 0.008
							},
							{
								"h": [
									0.024,
									0.01,
									0.008,
									0.008,
									0.005,
									0.001,
									0.01,
									0.002,
									0.0,
									0.011,
									0.0,
									0.001
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.267,
									0.0,
									0.541,
									0.0,
									0.0,
									0.0,
									0.027,
									0.0,
									0.636,
									0.533,
									0.178,
									0.55
								],
								"e": 0.103
							},
							{
								"h": [
									0.003,
									0.03,
									0.198,
									0.014,
									0.153,
									0.417,
									0.112,
									0.282,
									0.365,
									0.106,
									0.229,
									0.083
								],
								"e": 0.211
							},
							{
								"h": [
									0.013,
									0.023,
									0.0,
									0.0,
									0.001,
									0.0,
									0.089,
									0.146,
									0.001,
									0.0,
									0.055,
									0.006
								],
								"e": 0.033
							},
							{
								"h": [
									0.057,
									0.001,
									0.0,
									0.0,
									0.053,
									0.017,
									0.0,
									0.0,
									0.0,
									0.001,
									0.006,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.0,
									0.001,
									0.08,
									0.001,
									0.05,
									0.0,
									0.003,
									0.006,
									0.102,
									0.001,
									0.032,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									0.0,
									0.001,
									0.001,
									0.007,
									0.0,
									0.005,
									0.0,
									0.0,
									0.002,
									0.054,
									0.154,
									0.032
								],
								"e": 0.018
							},
							{
								"h": [
									0.006,
									0.0,
									0.017,
									0.02,
									0.034,
									0.005,
									0.031,
									0.016,
									0.001,
									0.036,
									0.023,
									0.001
								],
								"e": 0.017
							},
							{
								"h": [
									0.004,
									0.048,
									0.135,
									0.004,
									0.0,
									0.012,
									0.038,
									0.045,
									0.0,
									0.001,
									0.375,
									0.004
								],
								"e": 0.034
							},
							{
								"h": [
									0.024,
									0.052,
									0.011,
									0.001,
									0.001,
									0.011,
									0.024,
									0.02,
									0.01,
									0.042,
									0.039,
									0.005
								],
								"e": 0.017
							},
							{
								"h": [
									0.006,
									0.013,
									0.005,
									0.023,
									0.009,
									0.008,
									0.003,
									0.026,
									0.003,
									0.004,
									0.01,
									0.003
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.004,
									0.015,
									0.048,
									0.07,
									0.003,
									0.004,
									0.01,
									0.054,
									0.0,
									0.0,
									0.012
								],
								"e": 0.014
							},
							{
								"h": [
									0.016,
									0.002,
									0.004,
									0.015,
									0.013,
									0.003,
									0.002,
									0.018,
									0.0,
									0.007,
									0.011,
									0.001
								],
								"e": 0.008
							}
						],
						[
							{
								"h": [
									0.038,
									0.001,
									0.054,
									0.0,
									0.001,
									0.0,
									0.13,
									0.0,
									0.126,
									0.247,
									0.109,
									0.223
								],
								"e": 0.039
							},
							{
								"h": [
									0.004,
									0.009,
									0.292,
									0.015,
									0.169,
									0.253,
									0.02,
									0.132,
									0.227,
									0.121,
									0.321,
									0.025
								],
								"e": 0.153
							},
							{
								"h": [
									0.007,
									0.001,
									0.0,
									0.001,
									0.001,
									0.0,
									0.016,
									0.341,
									0.001,
									0.0,
									0.118,
									0.011
								],
								"e": 0.027
							},
							{
								"h": [
									0.018,
									0.001,
									0.0,
									0.0,
									0.033,
									0.018,
									0.0,
									0.0,
									0.0,
									0.0,
									0.01,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.0,
									0.012,
									0.003,
									0.046,
									0.0,
									0.004,
									0.023,
									0.191,
									0.003,
									0.008,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.001,
									0.002,
									0.0,
									0.005,
									0.0,
									0.016,
									0.0,
									0.001,
									0.003,
									0.095,
									0.098,
									0.022
								],
								"e": 0.017
							},
							{
								"h": [
									0.002,
									0.006,
									0.008,
									0.013,
									0.027,
									0.005,
									0.057,
									0.048,
									0.0,
									0.028,
									0.029,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.0,
									0.067,
									0.099,
									0.004,
									0.001,
									0.005,
									0.035,
									0.097,
									0.0,
									0.002,
									0.23,
									0.006
								],
								"e": 0.03
							},
							{
								"h": [
									0.004,
									0.011,
									0.009,
									0.001,
									0.0,
									0.004,
									0.058,
									0.002,
									0.006,
									0.014,
									0.005,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.101,
									0.001,
									0.029,
									0.003,
									0.011,
									0.02,
									0.004,
									0.036,
									0.011,
									0.002,
									0.002,
									0.001
								],
								"e": 0.018
							},
							{
								"h": [
									0.037,
									0.02,
									0.013,
									0.009,
									0.009,
									0.001,
									0.027,
									0.0,
									0.02,
									0.0,
									0.006,
									0.036
								],
								"e": 0.015
							},
							{
								"h": [
									0.047,
									0.012,
									0.01,
									0.004,
									0.002,
									0.002,
									0.014,
									0.006,
									0.0,
									0.013,
									0.0,
									0.0
								],
								"e": 0.008
							}
						],
						[
							{
								"h": [
									0.107,
									0.0,
									0.295,
									0.0,
									0.0,
									0.0,
									0.084,
									0.0,
									0.341,
									0.536,
									0.158,
									0.831
								],
								"e": 0.085
							},
							{
								"h": [
									0.005,
									0.029,
									0.211,
									0.014,
									0.157,
									0.378,
									0.081,
									0.274,
									0.294,
									0.127,
									0.24,
									0.107
								],
								"e": 0.206
							},
							{
								"h": [
									0.023,
									0.045,
									0.0,
									0.001,
									0.001,
									0.0,
									0.038,
									0.24,
									0.002,
									0.0,
									0.063,
									0.007
								],
								"e": 0.037
							},
							{
								"h": [
									0.013,
									0.003,
									0.0,
									0.0,
									0.109,
									0.032,
									0.0,
									0.0,
									0.0,
									0.002,
									0.009,
									0.001
								],
								"e": 0.025
							},
							{
								"h": [
									0.0,
									0.002,
									0.138,
									0.001,
									0.061,
									0.001,
									0.007,
									0.002,
									0.44,
									0.015,
									0.035,
									0.0
								],
								"e": 0.056
							},
							{
								"h": [
									0.497,
									0.0,
									0.02,
									0.018,
									0.0,
									0.0,
									0.0,
									0.0,
									0.019,
									0.298,
									0.07,
									0.097
								],
								"e": 0.081
							},
							{
								"h": [
									0.006,
									0.061,
									0.005,
									0.124,
									0.049,
									0.003,
									0.028,
									0.047,
									0.0,
									0.001,
									0.056,
									0.001
								],
								"e": 0.029
							},
							{
								"h": [
									0.0,
									0.023,
									0.156,
									0.006,
									0.0,
									0.006,
									0.028,
									0.35,
									0.0,
									0.002,
									0.055,
									0.27
								],
								"e": 0.044
							},
							{
								"h": [
									0.014,
									0.598,
									0.015,
									0.001,
									0.0,
									0.001,
									0.374,
									0.001,
									0.022,
									0.022,
									0.015,
									0.002
								],
								"e": 0.05
							},
							{
								"h": [
									0.006,
									0.017,
									0.006,
									0.002,
									0.004,
									0.002,
									0.004,
									0.01,
									0.001,
									0.009,
									0.002,
									0.807
								],
								"e": 0.031
							},
							{
								"h": [
									0.001,
									0.005,
									0.012,
									0.048,
									0.012,
									0.001,
									0.003,
									0.003,
									0.146,
									0.0,
									0.0,
									0.004
								],
								"e": 0.013
							},
							{
								"h": [
									0.029,
									0.003,
									0.002,
									0.002,
									0.005,
									0.004,
									0.004,
									0.008,
									0.0,
									0.007,
									0.002,
									0.0
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.25,
									0.004,
									0.075,
									0.0,
									0.004,
									0.0,
									0.093,
									0.0,
									0.027,
									0.543,
									0.12,
									0.137
								],
								"e": 0.049
							},
							{
								"h": [
									0.004,
									0.015,
									0.295,
									0.017,
									0.202,
									0.279,
									0.062,
									0.244,
									0.221,
									0.237,
									0.378,
									0.03
								],
								"e": 0.202
							},
							{
								"h": [
									0.027,
									0.005,
									0.0,
									0.003,
									0.001,
									0.0,
									0.015,
									0.361,
									0.001,
									0.002,
									0.181,
									0.014
								],
								"e": 0.035
							},
							{
								"h": [
									0.021,
									0.001,
									0.0,
									0.0,
									0.053,
									0.018,
									0.002,
									0.0,
									0.0,
									0.001,
									0.02,
									0.001
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.0,
									0.01,
									0.003,
									0.011,
									0.001,
									0.006,
									0.012,
									0.023,
									0.002,
									0.014,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.002,
									0.0,
									0.001,
									0.004,
									0.0,
									0.01,
									0.0,
									0.0,
									0.011,
									0.1,
									0.059,
									0.028
								],
								"e": 0.015
							},
							{
								"h": [
									0.005,
									0.001,
									0.011,
									0.027,
									0.006,
									0.005,
									0.01,
									0.013,
									0.0,
									0.016,
									0.026,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.039,
									0.043,
									0.002,
									0.0,
									0.001,
									0.011,
									0.091,
									0.0,
									0.0,
									0.032,
									0.011
								],
								"e": 0.013
							},
							{
								"h": [
									0.004,
									0.017,
									0.003,
									0.001,
									0.0,
									0.001,
									0.005,
									0.0,
									0.003,
									0.023,
									0.001,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.0,
									0.002,
									0.002,
									0.001,
									0.003,
									0.002,
									0.007,
									0.004,
									0.001,
									0.0,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.005,
									0.005,
									0.003,
									0.011,
									0.001,
									0.0,
									0.006,
									0.0,
									0.004,
									0.0,
									0.002,
									0.011
								],
								"e": 0.004
							},
							{
								"h": [
									0.025,
									0.005,
									0.006,
									0.003,
									0.002,
									0.001,
									0.009,
									0.003,
									0.0,
									0.002,
									0.0,
									0.0
								],
								"e": 0.004
							}
						],
						[
							{
								"h": [
									0.28,
									0.0,
									0.548,
									0.0,
									0.0,
									0.0,
									0.028,
									0.001,
									0.705,
									0.576,
									0.186,
									0.521
								],
								"e": 0.108
							},
							{
								"h": [
									0.007,
									0.067,
									0.273,
									0.02,
									0.189,
									0.426,
									0.119,
									0.296,
									0.345,
									0.098,
									0.329,
									0.117
								],
								"e": 0.249
							},
							{
								"h": [
									0.035,
									0.023,
									0.0,
									0.001,
									0.003,
									0.001,
									0.138,
									0.208,
									0.003,
									0.001,
									0.099,
									0.012
								],
								"e": 0.049
							},
							{
								"h": [
									0.046,
									0.007,
									0.0,
									0.0,
									0.055,
									0.025,
									0.001,
									0.0,
									0.0,
									0.003,
									0.014,
									0.003
								],
								"e": 0.022
							},
							{
								"h": [
									0.0,
									0.007,
									0.143,
									0.003,
									0.05,
									0.003,
									0.009,
									0.005,
									0.053,
									0.002,
									0.036,
									0.0
								],
								"e": 0.029
							},
							{
								"h": [
									0.0,
									0.0,
									0.007,
									0.019,
									0.001,
									0.0,
									0.0,
									0.0,
									0.001,
									0.074,
									0.116,
									0.045
								],
								"e": 0.019
							},
							{
								"h": [
									0.029,
									0.002,
									0.019,
									0.074,
									0.063,
									0.01,
									0.035,
									0.048,
									0.003,
									0.001,
									0.029,
									0.002
								],
								"e": 0.026
							},
							{
								"h": [
									0.011,
									0.015,
									0.009,
									0.006,
									0.0,
									0.017,
									0.029,
									0.054,
									0.001,
									0.003,
									0.01,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.045,
									0.115,
									0.011,
									0.001,
									0.002,
									0.029,
									0.023,
									0.034,
									0.023,
									0.049,
									0.033,
									0.005
								],
								"e": 0.024
							},
							{
								"h": [
									0.012,
									0.009,
									0.007,
									0.047,
									0.005,
									0.016,
									0.002,
									0.042,
									0.002,
									0.002,
									0.015,
									0.009
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.003,
									0.018,
									0.046,
									0.123,
									0.008,
									0.002,
									0.006,
									0.106,
									0.001,
									0.0,
									0.011
								],
								"e": 0.02
							},
							{
								"h": [
									0.017,
									0.002,
									0.003,
									0.012,
									0.013,
									0.003,
									0.002,
									0.013,
									0.0,
									0.007,
									0.009,
									0.0
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.75,
									0.008,
									0.178,
									0.008,
									0.044,
									0.0,
									0.743,
									0.039,
									0.276,
									0.608,
									0.159,
									0.252
								],
								"e": 0.17
							},
							{
								"h": [
									0.053,
									0.015,
									0.31,
									0.017,
									0.217,
									0.067,
									0.811,
									0.093,
									0.295,
									0.172,
									0.376,
									0.042
								],
								"e": 0.329
							},
							{
								"h": [
									0.032,
									0.001,
									0.0,
									0.003,
									0.004,
									0.0,
									0.017,
									0.356,
									0.001,
									0.001,
									0.131,
									0.011
								],
								"e": 0.031
							},
							{
								"h": [
									0.017,
									0.001,
									0.0,
									0.0,
									0.014,
									0.009,
									0.001,
									0.0,
									0.0,
									0.001,
									0.005,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.001,
									0.004,
									0.006,
									0.019,
									0.001,
									0.005,
									0.009,
									0.006,
									0.002,
									0.008,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.005,
									0.007,
									0.001,
									0.0,
									0.013,
									0.007,
									0.001,
									0.037,
									0.064,
									0.025
								],
								"e": 0.013
							},
							{
								"h": [
									0.017,
									0.008,
									0.006,
									0.021,
									0.006,
									0.012,
									0.04,
									0.067,
									0.012,
									0.0,
									0.012,
									0.004
								],
								"e": 0.016
							},
							{
								"h": [
									0.008,
									0.012,
									0.006,
									0.008,
									0.003,
									0.003,
									0.015,
									0.121,
									0.009,
									0.004,
									0.006,
									0.007
								],
								"e": 0.012
							},
							{
								"h": [
									0.007,
									0.008,
									0.002,
									0.001,
									0.001,
									0.049,
									0.016,
									0.02,
									0.001,
									0.014,
									0.012,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.019,
									0.0,
									0.005,
									0.004,
									0.008,
									0.01,
									0.001,
									0.006,
									0.001,
									0.0,
									0.002,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.004,
									0.002,
									0.008,
									0.003,
									0.001,
									0.001,
									0.016,
									0.0,
									0.003,
									0.001,
									0.008,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.118,
									0.002,
									0.005,
									0.001,
									0.001,
									0.0,
									0.004,
									0.001,
									0.029,
									0.002,
									0.0,
									0.0
								],
								"e": 0.009
							}
						],
						[
							{
								"h": [
									0.064,
									0.855,
									0.07,
									0.002,
									0.051,
									0.663,
									0.084,
									0.0,
									0.136,
									0.202,
									0.862,
									0.27
								],
								"e": 0.322
							},
							{
								"h": [
									0.005,
									0.009,
									0.235,
									0.014,
									0.156,
									0.097,
									0.024,
									0.191,
									0.175,
									0.093,
									0.318,
									0.336
								],
								"e": 0.196
							},
							{
								"h": [
									0.007,
									0.0,
									0.0,
									0.0,
									0.001,
									0.0,
									0.013,
									0.239,
									0.001,
									0.0,
									0.153,
									0.013
								],
								"e": 0.024
							},
							{
								"h": [
									0.394,
									0.001,
									0.0,
									0.0,
									0.014,
									0.004,
									0.0,
									0.0,
									0.0,
									0.0,
									0.045,
									0.001
								],
								"e": 0.061
							},
							{
								"h": [
									0.0,
									0.0,
									0.003,
									0.004,
									0.011,
									0.001,
									0.003,
									0.091,
									0.012,
									0.001,
									0.005,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.002,
									0.0,
									0.0,
									0.001,
									0.001,
									0.001,
									0.033,
									0.024,
									0.069
								],
								"e": 0.011
							},
							{
								"h": [
									0.004,
									0.022,
									0.002,
									0.019,
									0.004,
									0.012,
									0.114,
									0.045,
									0.001,
									0.0,
									0.019,
									0.002
								],
								"e": 0.02
							},
							{
								"h": [
									0.0,
									0.025,
									0.013,
									0.004,
									0.0,
									0.001,
									0.019,
									0.071,
									0.0,
									0.001,
									0.015,
									0.036
								],
								"e": 0.011
							},
							{
								"h": [
									0.004,
									0.01,
									0.004,
									0.0,
									0.001,
									0.003,
									0.06,
									0.004,
									0.014,
									0.014,
									0.013,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.022,
									0.001,
									0.022,
									0.002,
									0.009,
									0.013,
									0.001,
									0.019,
									0.004,
									0.003,
									0.002,
									0.007
								],
								"e": 0.009
							},
							{
								"h": [
									0.003,
									0.004,
									0.004,
									0.002,
									0.006,
									0.002,
									0.003,
									0.0,
									0.085,
									0.001,
									0.001,
									0.009
								],
								"e": 0.007
							},
							{
								"h": [
									0.092,
									0.002,
									0.001,
									0.006,
									0.002,
									0.001,
									0.001,
									0.003,
									0.002,
									0.003,
									0.001,
									0.0
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.115,
									0.0,
									0.298,
									0.0,
									0.001,
									0.0,
									0.092,
									0.001,
									0.389,
									0.605,
									0.167,
									0.775
								],
								"e": 0.089
							},
							{
								"h": [
									0.014,
									0.074,
									0.312,
									0.021,
									0.213,
									0.416,
									0.086,
									0.253,
									0.268,
									0.103,
									0.355,
									0.193
								],
								"e": 0.26
							},
							{
								"h": [
									0.082,
									0.031,
									0.0,
									0.007,
									0.006,
									0.005,
									0.068,
									0.351,
									0.018,
									0.001,
									0.126,
									0.016
								],
								"e": 0.058
							},
							{
								"h": [
									0.017,
									0.007,
									0.001,
									0.0,
									0.092,
									0.023,
									0.001,
									0.0,
									0.001,
									0.005,
									0.023,
									0.002
								],
								"e": 0.025
							},
							{
								"h": [
									0.0,
									0.008,
									0.068,
									0.004,
									0.035,
									0.001,
									0.013,
									0.007,
									0.138,
									0.012,
									0.021,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									1.0,
									1.0,
									0.044,
									0.031,
									0.001,
									0.944,
									0.004,
									0.0,
									0.744,
									0.135,
									0.031,
									0.086
								],
								"e": 0.361
							},
							{
								"h": [
									0.02,
									0.267,
									0.008,
									0.309,
									0.049,
									0.009,
									0.045,
									0.071,
									0.006,
									0.411,
									0.075,
									0.002
								],
								"e": 0.093
							},
							{
								"h": [
									0.001,
									0.018,
									0.548,
									0.018,
									0.001,
									0.002,
									0.024,
									1.0,
									0.0,
									0.006,
									1.0,
									1.0
								],
								"e": 0.166
							},
							{
								"h": [
									0.015,
									0.275,
									0.016,
									0.001,
									0.0,
									0.001,
									0.067,
									0.005,
									0.023,
									0.024,
									0.026,
									0.001
								],
								"e": 0.023
							},
							{
								"h": [
									0.001,
									0.014,
									0.005,
									0.003,
									0.002,
									0.001,
									0.003,
									0.007,
									0.001,
									0.007,
									0.004,
									0.311
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.002,
									0.003,
									0.016,
									0.006,
									0.001,
									0.001,
									0.001,
									0.046,
									0.0,
									0.0,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.031,
									0.001,
									0.001,
									0.002,
									0.003,
									0.003,
									0.002,
									0.003,
									0.0,
									0.004,
									0.001,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.157,
									0.0,
									0.215,
									0.0,
									0.001,
									0.0,
									0.056,
									0.001,
									0.514,
									0.801,
									0.214,
									0.135
								],
								"e": 0.078
							},
							{
								"h": [
									0.014,
									0.098,
									0.322,
									0.025,
									0.232,
									0.397,
									0.223,
									0.531,
									0.436,
									0.322,
									0.511,
									0.007
								],
								"e": 0.343
							},
							{
								"h": [
									0.113,
									0.009,
									0.0,
									0.137,
									0.037,
									0.004,
									0.073,
									0.352,
									0.026,
									0.008,
									0.203,
									0.027
								],
								"e": 0.086
							},
							{
								"h": [
									0.004,
									0.007,
									0.006,
									0.0,
									0.051,
									0.084,
									0.004,
									0.001,
									0.0,
									0.007,
									0.041,
									0.019
								],
								"e": 0.033
							},
							{
								"h": [
									0.003,
									0.004,
									0.014,
									0.007,
									0.005,
									0.01,
									0.019,
									0.021,
									0.061,
									0.005,
									0.057,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.003,
									0.016,
									0.007,
									0.007,
									0.003,
									0.311,
									0.001,
									0.0,
									0.097,
									0.12,
									0.043,
									0.018
								],
								"e": 0.049
							},
							{
								"h": [
									0.013,
									0.022,
									0.025,
									0.069,
									0.009,
									0.012,
									0.008,
									0.038,
									0.003,
									0.041,
									0.07,
									0.003
								],
								"e": 0.026
							},
							{
								"h": [
									0.001,
									0.05,
									0.014,
									0.017,
									0.001,
									0.001,
									0.031,
									0.763,
									0.002,
									0.002,
									0.216,
									0.003
								],
								"e": 0.051
							},
							{
								"h": [
									0.02,
									0.03,
									0.005,
									0.001,
									0.0,
									0.005,
									0.014,
									0.002,
									0.005,
									0.017,
									0.008,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.004,
									0.008,
									0.003,
									0.002,
									0.001,
									0.001,
									0.002,
									0.009,
									0.001,
									0.002,
									0.003,
									0.011
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.002,
									0.005,
									0.001,
									0.0,
									0.001,
									0.011,
									0.0,
									0.0,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.005,
									0.0,
									0.0,
									0.004,
									0.002,
									0.002,
									0.003,
									0.003,
									0.0,
									0.001,
									0.001,
									0.0
								],
								"e": 0.002
							}
						],
						[
							{
								"h": [
									0.134,
									0.001,
									0.271,
									0.0,
									0.005,
									0.0,
									0.21,
									0.001,
									0.036,
									0.94,
									0.078,
									0.22
								],
								"e": 0.07
							},
							{
								"h": [
									0.04,
									0.063,
									0.473,
									0.027,
									0.346,
									0.439,
									0.06,
									0.153,
									0.215,
									0.182,
									0.653,
									0.033
								],
								"e": 0.279
							},
							{
								"h": [
									0.111,
									0.002,
									0.0,
									0.015,
									0.034,
									0.006,
									0.042,
									0.596,
									0.016,
									0.05,
									0.51,
									0.038
								],
								"e": 0.093
							},
							{
								"h": [
									0.026,
									0.004,
									0.001,
									0.001,
									0.078,
									0.031,
									0.074,
									0.009,
									0.0,
									0.004,
									0.058,
									0.02
								],
								"e": 0.037
							},
							{
								"h": [
									0.0,
									0.001,
									0.007,
									0.032,
									0.008,
									0.005,
									0.034,
									0.054,
									0.037,
									0.006,
									0.023,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.0,
									0.006,
									0.017,
									0.001,
									0.001,
									0.0,
									0.0,
									0.002,
									0.074,
									0.057,
									0.018
								],
								"e": 0.012
							},
							{
								"h": [
									0.015,
									0.001,
									0.011,
									0.03,
									0.005,
									0.019,
									0.009,
									0.03,
									0.003,
									0.005,
									0.017,
									0.001
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.046,
									0.001,
									0.005,
									0.001,
									0.002,
									0.015,
									0.033,
									0.0,
									0.001,
									0.001,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.004,
									0.001,
									0.001,
									0.001,
									0.001,
									0.003,
									0.001,
									0.001,
									0.004,
									0.039,
									0.003,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.009,
									0.0,
									0.006,
									0.003,
									0.0,
									0.002,
									0.001,
									0.006,
									0.01,
									0.0,
									0.001,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.006,
									0.002,
									0.001,
									0.003,
									0.001,
									0.0,
									0.004,
									0.0,
									0.001,
									0.0,
									0.002,
									0.003
								],
								"e": 0.002
							},
							{
								"h": [
									0.022,
									0.002,
									0.003,
									0.005,
									0.001,
									0.001,
									0.004,
									0.001,
									0.0,
									0.002,
									0.0,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.315,
									0.0,
									0.594,
									0.0,
									0.002,
									0.0,
									0.032,
									0.004,
									0.846,
									0.682,
									0.201,
									0.494
								],
								"e": 0.122
							},
							{
								"h": [
									0.033,
									0.273,
									0.512,
									0.037,
									0.305,
									0.469,
									0.116,
									0.281,
									0.322,
									0.085,
									0.614,
									0.234
								],
								"e": 0.397
							},
							{
								"h": [
									0.213,
									0.016,
									0.0,
									0.024,
									0.049,
									0.035,
									0.425,
									0.405,
									0.062,
									0.02,
									0.281,
									0.039
								],
								"e": 0.157
							},
							{
								"h": [
									0.043,
									0.103,
									0.012,
									0.004,
									0.052,
									0.046,
									0.033,
									0.011,
									0.023,
									0.04,
									0.076,
									0.075
								],
								"e": 0.073
							},
							{
								"h": [
									0.007,
									0.072,
									0.169,
									0.017,
									0.04,
									0.028,
									0.031,
									0.026,
									0.039,
									0.008,
									0.024,
									0.0
								],
								"e": 0.043
							},
							{
								"h": [
									0.0,
									0.0,
									0.044,
									0.053,
									0.005,
									0.0,
									0.002,
									0.0,
									0.002,
									0.051,
									0.056,
									0.032
								],
								"e": 0.02
							},
							{
								"h": [
									0.145,
									0.008,
									0.023,
									0.109,
									0.035,
									0.035,
									0.058,
									0.093,
									0.029,
									0.005,
									0.044,
									0.016
								],
								"e": 0.047
							},
							{
								"h": [
									0.114,
									0.031,
									0.026,
									0.018,
									0.001,
									0.016,
									0.031,
									0.101,
									0.002,
									0.009,
									0.025,
									0.002
								],
								"e": 0.025
							},
							{
								"h": [
									0.051,
									0.071,
									0.016,
									0.002,
									0.005,
									0.06,
									0.02,
									0.134,
									0.025,
									0.055,
									0.058,
									0.004
								],
								"e": 0.033
							},
							{
								"h": [
									0.01,
									0.006,
									0.008,
									0.087,
									0.004,
									0.014,
									0.001,
									0.053,
									0.003,
									0.002,
									0.04,
									0.009
								],
								"e": 0.017
							},
							{
								"h": [
									0.001,
									0.003,
									0.013,
									0.048,
									0.149,
									0.016,
									0.001,
									0.007,
									0.068,
									0.001,
									0.0,
									0.008
								],
								"e": 0.02
							},
							{
								"h": [
									0.016,
									0.001,
									0.002,
									0.015,
									0.009,
									0.003,
									0.002,
									0.011,
									0.0,
									0.007,
									0.007,
									0.0
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.042,
									0.001,
									0.054,
									0.0,
									0.01,
									0.0,
									0.156,
									0.001,
									0.154,
									0.299,
									0.12,
									0.193
								],
								"e": 0.045
							},
							{
								"h": [
									0.048,
									0.058,
									0.584,
									0.03,
									0.323,
									0.288,
									0.02,
									0.114,
									0.229,
									0.119,
									0.657,
									0.084
								],
								"e": 0.266
							},
							{
								"h": [
									0.098,
									0.001,
									0.002,
									0.063,
									0.072,
									0.004,
									0.068,
									0.705,
									0.045,
									0.08,
									0.483,
									0.058
								],
								"e": 0.124
							},
							{
								"h": [
									0.008,
									0.038,
									0.007,
									0.003,
									0.028,
									0.035,
									0.059,
									0.083,
									0.002,
									0.027,
									0.105,
									0.109
								],
								"e": 0.059
							},
							{
								"h": [
									0.006,
									0.045,
									0.031,
									0.099,
									0.039,
									0.03,
									0.058,
									0.1,
									0.071,
									0.049,
									0.008,
									0.0
								],
								"e": 0.05
							},
							{
								"h": [
									0.001,
									0.0,
									0.009,
									0.065,
									0.011,
									0.002,
									0.002,
									0.005,
									0.017,
									0.137,
									0.043,
									0.036
								],
								"e": 0.026
							},
							{
								"h": [
									0.042,
									0.037,
									0.011,
									0.051,
									0.019,
									0.031,
									0.148,
									0.168,
									0.009,
									0.004,
									0.039,
									0.01
								],
								"e": 0.044
							},
							{
								"h": [
									0.016,
									0.031,
									0.015,
									0.05,
									0.002,
									0.005,
									0.024,
									0.146,
									0.003,
									0.022,
									0.011,
									0.005
								],
								"e": 0.023
							},
							{
								"h": [
									0.009,
									0.01,
									0.003,
									0.001,
									0.004,
									0.026,
									0.028,
									0.064,
									0.009,
									0.024,
									0.022,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.025,
									0.001,
									0.015,
									0.017,
									0.006,
									0.024,
									0.001,
									0.075,
									0.004,
									0.0,
									0.011,
									0.002
								],
								"e": 0.014
							},
							{
								"h": [
									0.01,
									0.007,
									0.006,
									0.006,
									0.006,
									0.012,
									0.007,
									0.0,
									0.013,
									0.012,
									0.002,
									0.021
								],
								"e": 0.008
							},
							{
								"h": [
									0.1,
									0.005,
									0.003,
									0.005,
									0.001,
									0.001,
									0.005,
									0.004,
									0.209,
									0.006,
									0.0,
									0.0
								],
								"e": 0.023
							}
						],
						[
							{
								"h": [
									0.124,
									0.0,
									0.307,
									0.0,
									0.005,
									0.0,
									0.102,
									0.004,
									0.449,
									0.679,
									0.176,
									0.734
								],
								"e": 0.096
							},
							{
								"h": [
									0.056,
									0.262,
									0.497,
									0.034,
									0.318,
									0.398,
									0.091,
									0.306,
									0.277,
									0.099,
									0.571,
									0.241
								],
								"e": 0.392
							},
							{
								"h": [
									0.392,
									0.039,
									0.001,
									0.112,
									0.073,
									0.124,
									0.178,
									0.589,
									0.235,
									0.022,
									0.326,
									0.047
								],
								"e": 0.22
							},
							{
								"h": [
									0.007,
									0.137,
									0.068,
									0.014,
									0.079,
									0.065,
									0.033,
									0.021,
									0.068,
									0.063,
									0.097,
									0.054
								],
								"e": 0.107
							},
							{
								"h": [
									0.027,
									0.262,
									0.183,
									0.045,
									0.059,
									0.069,
									0.135,
									0.011,
									0.262,
									0.186,
									0.033,
									0.0
								],
								"e": 0.121
							},
							{
								"h": [
									0.318,
									0.0,
									0.476,
									0.248,
									0.01,
									0.0,
									0.001,
									0.002,
									0.127,
									0.333,
									0.052,
									0.149
								],
								"e": 0.142
							},
							{
								"h": [
									0.127,
									0.715,
									0.011,
									0.954,
									0.091,
									0.032,
									0.05,
									0.326,
									0.024,
									0.0,
									0.055,
									0.026
								],
								"e": 0.156
							},
							{
								"h": [
									0.026,
									0.008,
									0.039,
									0.092,
									0.001,
									0.009,
									0.021,
									0.364,
									0.001,
									0.021,
									0.04,
									0.52
								],
								"e": 0.064
							},
							{
								"h": [
									0.036,
									0.435,
									0.005,
									0.001,
									0.001,
									0.004,
									0.108,
									0.064,
									0.054,
									0.041,
									0.037,
									0.001
								],
								"e": 0.04
							},
							{
								"h": [
									0.001,
									0.017,
									0.005,
									0.024,
									0.001,
									0.004,
									0.002,
									0.02,
									0.0,
									0.005,
									0.031,
									0.196
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.002,
									0.003,
									0.013,
									0.005,
									0.003,
									0.0,
									0.001,
									0.023,
									0.001,
									0.0,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.026,
									0.001,
									0.001,
									0.002,
									0.002,
									0.004,
									0.002,
									0.003,
									0.0,
									0.004,
									0.001,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.454,
									0.003,
									0.349,
									0.0,
									0.077,
									0.0,
									0.088,
									0.001,
									0.209,
									0.809,
									0.136,
									0.247
								],
								"e": 0.101
							},
							{
								"h": [
									0.048,
									0.149,
									0.851,
									0.046,
									0.584,
									0.189,
									0.074,
									0.512,
									0.219,
									0.131,
									0.923,
									0.151
								],
								"e": 0.417
							},
							{
								"h": [
									0.903,
									0.008,
									0.035,
									0.276,
									0.187,
									0.039,
									0.061,
									0.677,
									0.058,
									0.911,
									0.765,
									0.078
								],
								"e": 0.356
							},
							{
								"h": [
									0.0,
									0.039,
									0.046,
									0.058,
									0.055,
									0.069,
									1.0,
									0.254,
									0.019,
									0.061,
									0.126,
									0.417
								],
								"e": 0.22
							},
							{
								"h": [
									0.074,
									0.037,
									0.023,
									0.201,
									0.003,
									0.037,
									0.188,
									0.071,
									0.036,
									0.037,
									0.014,
									0.0
								],
								"e": 0.067
							},
							{
								"h": [
									0.004,
									0.0,
									0.035,
									0.076,
									0.01,
									0.007,
									0.001,
									0.002,
									0.113,
									0.296,
									0.115,
									0.024
								],
								"e": 0.05
							},
							{
								"h": [
									0.077,
									0.01,
									0.017,
									0.162,
									0.012,
									0.023,
									0.02,
									0.111,
									0.009,
									0.006,
									0.072,
									0.009
								],
								"e": 0.038
							},
							{
								"h": [
									0.015,
									0.036,
									0.014,
									0.044,
									0.002,
									0.002,
									0.028,
									0.226,
									0.008,
									0.005,
									0.019,
									0.019
								],
								"e": 0.026
							},
							{
								"h": [
									0.002,
									0.024,
									0.003,
									0.001,
									0.002,
									0.013,
									0.012,
									0.014,
									0.004,
									0.014,
									0.004,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.004,
									0.0,
									0.006,
									0.011,
									0.001,
									0.001,
									0.002,
									0.012,
									0.007,
									0.001,
									0.006,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.002,
									0.003,
									0.006,
									0.0,
									0.004,
									0.002,
									0.0,
									0.001,
									0.006,
									0.001,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.075,
									0.006,
									0.006,
									0.016,
									0.001,
									0.001,
									0.003,
									0.001,
									0.0,
									0.002,
									0.0,
									0.0
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.473,
									0.002,
									0.421,
									0.0,
									0.005,
									0.0,
									0.157,
									0.001,
									0.17,
									0.649,
									0.11,
									0.42
								],
								"e": 0.096
							},
							{
								"h": [
									0.025,
									0.116,
									1.0,
									0.054,
									0.556,
									0.596,
									0.039,
									0.676,
									0.208,
									0.155,
									1.0,
									0.099
								],
								"e": 0.451
							},
							{
								"h": [
									0.481,
									0.009,
									0.107,
									0.359,
									0.201,
									0.111,
									0.093,
									0.614,
									0.244,
									1.0,
									0.953,
									0.104
								],
								"e": 0.436
							},
							{
								"h": [
									0.011,
									0.14,
									0.052,
									0.102,
									0.085,
									0.073,
									0.7,
									0.703,
									0.068,
									0.022,
									0.384,
									0.569
								],
								"e": 0.311
							},
							{
								"h": [
									0.031,
									0.032,
									0.073,
									0.229,
									0.004,
									0.065,
									0.254,
									0.103,
									0.056,
									0.033,
									0.016,
									0.0
								],
								"e": 0.08
							},
							{
								"h": [
									0.0,
									0.0,
									0.028,
									0.048,
									0.014,
									0.0,
									0.0,
									0.001,
									0.006,
									0.132,
									0.036,
									0.03
								],
								"e": 0.023
							},
							{
								"h": [
									0.082,
									0.01,
									0.007,
									0.056,
									0.004,
									0.046,
									0.004,
									0.139,
									0.003,
									0.0,
									0.012,
									0.007
								],
								"e": 0.026
							},
							{
								"h": [
									0.007,
									0.006,
									0.005,
									0.022,
									0.001,
									0.002,
									0.024,
									0.009,
									0.002,
									0.012,
									0.003,
									0.003
								],
								"e": 0.009
							},
							{
								"h": [
									0.014,
									0.014,
									0.002,
									0.0,
									0.002,
									0.007,
									0.004,
									0.008,
									0.006,
									0.025,
									0.005,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.003,
									0.0,
									0.002,
									0.015,
									0.0,
									0.001,
									0.001,
									0.02,
									0.005,
									0.0,
									0.006,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.006,
									0.009,
									0.001,
									0.016,
									0.001,
									0.001,
									0.006,
									0.0,
									0.001,
									0.001,
									0.002,
									0.016
								],
								"e": 0.005
							},
							{
								"h": [
									0.012,
									0.008,
									0.007,
									0.013,
									0.002,
									0.001,
									0.013,
									0.006,
									0.0,
									0.005,
									0.0,
									0.0
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.363,
									0.0,
									0.625,
									0.0,
									0.01,
									0.0,
									0.036,
									0.024,
									1.0,
									0.775,
									0.215,
									0.486
								],
								"e": 0.143
							},
							{
								"h": [
									0.145,
									1.0,
									0.884,
									0.062,
									0.49,
									0.472,
									0.123,
									0.337,
									0.33,
									0.08,
									0.992,
									0.385
								],
								"e": 0.776
							},
							{
								"h": [
									1.0,
									0.015,
									0.099,
									0.529,
									0.667,
									1.0,
									1.0,
									0.644,
									1.0,
									0.624,
									0.674,
									0.11
								],
								"e": 1.0
							},
							{
								"h": [
									0.032,
									1.0,
									0.767,
									0.348,
									0.051,
									0.099,
									0.73,
									1.0,
									1.0,
									0.357,
									0.215,
									1.0
								],
								"e": 0.927
							},
							{
								"h": [
									0.222,
									1.0,
									0.346,
									0.195,
									0.035,
									0.313,
									0.191,
									0.043,
									0.038,
									0.053,
									0.036,
									0.0
								],
								"e": 0.236
							},
							{
								"h": [
									0.0,
									0.0,
									0.501,
									0.362,
									0.062,
									0.0,
									0.006,
									0.002,
									0.004,
									0.095,
									0.108,
									0.059
								],
								"e": 0.106
							},
							{
								"h": [
									1.0,
									0.029,
									0.032,
									0.297,
									0.054,
									0.106,
									0.08,
									0.33,
									0.127,
									0.0,
									0.03,
									0.18
								],
								"e": 0.168
							},
							{
								"h": [
									0.847,
									0.009,
									0.003,
									0.07,
									0.003,
									0.033,
									0.032,
									0.056,
									0.023,
									0.048,
									0.004,
									0.001
								],
								"e": 0.083
							},
							{
								"h": [
									0.066,
									0.078,
									0.012,
									0.004,
									0.019,
									0.23,
									0.019,
									0.749,
									0.061,
									0.07,
									0.062,
									0.007
								],
								"e": 0.078
							},
							{
								"h": [
									0.021,
									0.009,
									0.007,
									0.478,
									0.004,
									0.017,
									0.001,
									0.082,
									0.002,
									0.002,
									0.156,
									0.009
								],
								"e": 0.053
							},
							{
								"h": [
									0.001,
									0.003,
									0.013,
									0.058,
									0.177,
									0.044,
									0.002,
									0.006,
									0.069,
									0.006,
									0.0,
									0.009
								],
								"e": 0.025
							},
							{
								"h": [
									0.016,
									0.002,
									0.002,
									0.021,
									0.01,
									0.003,
									0.002,
									0.014,
									0.0,
									0.007,
									0.007,
									0.0
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									1.0,
									0.009,
									0.193,
									1.0,
									0.945,
									0.0,
									1.0,
									1.0,
									0.372,
									0.798,
									0.181,
									0.239
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.176,
									0.883,
									0.047,
									0.532,
									0.091,
									0.648,
									0.09,
									0.312,
									0.168,
									0.935,
									0.119
								],
								"e": 0.919
							},
							{
								"h": [
									0.833,
									0.001,
									1.0,
									1.0,
									1.0,
									0.108,
									0.12,
									1.0,
									0.329,
									0.994,
									0.795,
									0.088
								],
								"e": 0.969
							},
							{
								"h": [
									0.012,
									0.135,
									1.0,
									0.732,
									0.016,
									0.036,
									0.865,
									0.285,
									0.081,
									0.158,
									0.082,
									0.639
								],
								"e": 0.503
							},
							{
								"h": [
									0.234,
									0.228,
									0.017,
									0.765,
									0.023,
									0.213,
									0.17,
									0.116,
									0.005,
									0.065,
									0.011,
									1.0
								],
								"e": 0.376
							},
							{
								"h": [
									0.0,
									0.0,
									0.261,
									0.168,
									0.086,
									0.0,
									0.388,
									0.227,
									0.005,
									0.055,
									0.084,
									0.038
								],
								"e": 0.157
							},
							{
								"h": [
									0.433,
									0.07,
									0.012,
									0.091,
									0.005,
									0.132,
									0.067,
									0.345,
									1.0,
									0.0,
									0.008,
									0.121
								],
								"e": 0.224
							},
							{
								"h": [
									1.0,
									0.006,
									0.001,
									0.108,
									0.03,
									0.006,
									0.011,
									0.056,
									0.217,
									0.049,
									0.001,
									0.001
								],
								"e": 0.119
							},
							{
								"h": [
									0.015,
									0.006,
									0.001,
									0.001,
									0.013,
									0.543,
									0.009,
									1.0,
									0.004,
									0.022,
									0.034,
									0.0
								],
								"e": 0.086
							},
							{
								"h": [
									0.016,
									0.0,
									0.006,
									0.094,
									0.007,
									0.014,
									0.001,
									0.02,
									0.002,
									0.0,
									0.052,
									0.002
								],
								"e": 0.015
							},
							{
								"h": [
									0.003,
									0.001,
									0.004,
									0.002,
									0.001,
									0.014,
									0.01,
									0.0,
									0.002,
									0.04,
									0.005,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.205,
									0.001,
									0.003,
									0.006,
									0.001,
									0.0,
									0.003,
									0.001,
									0.169,
									0.002,
									0.0,
									0.0
								],
								"e": 0.024
							}
						],
						[
							{
								"h": [
									0.075,
									1.0,
									0.074,
									0.159,
									1.0,
									0.509,
									0.104,
									0.005,
									0.18,
									0.252,
									1.0,
									0.254
								],
								"e": 0.54
							},
							{
								"h": [
									0.126,
									0.119,
									0.747,
									0.042,
									0.417,
									0.118,
									0.026,
									0.203,
									0.175,
									0.076,
									0.863,
									1.0
								],
								"e": 0.533
							},
							{
								"h": [
									0.214,
									0.001,
									0.13,
									0.197,
									0.187,
									0.181,
									0.101,
									0.661,
									0.405,
									0.51,
									1.0,
									0.119
								],
								"e": 0.399
							},
							{
								"h": [
									0.176,
									0.127,
									0.241,
									0.18,
									0.015,
									0.016,
									0.149,
									0.952,
									0.123,
									0.074,
									0.647,
									0.335
								],
								"e": 0.357
							},
							{
								"h": [
									0.064,
									0.075,
									0.008,
									0.396,
									0.012,
									0.106,
									0.108,
									1.0,
									0.011,
									0.027,
									0.005,
									0.0
								],
								"e": 0.174
							},
							{
								"h": [
									0.001,
									0.0,
									0.06,
									0.048,
									0.051,
									0.0,
									0.032,
									0.052,
									0.011,
									0.06,
									0.034,
									0.117
								],
								"e": 0.049
							},
							{
								"h": [
									0.113,
									0.283,
									0.005,
									0.107,
									0.007,
									0.12,
									0.192,
									0.277,
									0.038,
									0.0,
									0.013,
									0.076
								],
								"e": 0.095
							},
							{
								"h": [
									0.054,
									0.02,
									0.004,
									0.085,
									0.002,
									0.003,
									0.016,
									0.023,
									0.012,
									0.028,
									0.005,
									0.017
								],
								"e": 0.024
							},
							{
								"h": [
									0.006,
									0.006,
									0.001,
									0.001,
									0.012,
									0.021,
									0.029,
									0.207,
									0.028,
									0.026,
									0.042,
									0.002
								],
								"e": 0.023
							},
							{
								"h": [
									0.016,
									0.001,
									0.028,
									0.035,
									0.007,
									0.021,
									0.0,
									0.048,
									0.003,
									0.003,
									0.025,
									0.003
								],
								"e": 0.015
							},
							{
								"h": [
									0.002,
									0.002,
									0.003,
									0.003,
									0.007,
									0.026,
									0.002,
									0.0,
									0.032,
									0.066,
									0.001,
									0.003
								],
								"e": 0.012
							},
							{
								"h": [
									0.21,
									0.002,
									0.001,
									0.01,
									0.002,
									0.001,
									0.001,
									0.002,
									1.0,
									0.002,
									0.001,
									0.0
								],
								"e": 0.088
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": ":",
					"o": " The",
					"t": " Darth",
					"r": [
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.047,
								"19": 0.036,
								" '": 0.015,
								"59": 0.013,
								"54": 0.013,
								"57": 0.012,
								" [": 0.01,
								" \"": 0.009,
								" -": 0.009,
								" An": 0.009
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.045,
								"19": 0.038,
								" A": 0.015,
								" Three": 0.014,
								" An": 0.013,
								" Unknown": 0.013,
								" '": 0.012,
								" -": 0.01,
								"59": 0.01,
								" Yes": 0.01
							}
						},
						{
							"p": " Yes",
							"b": 0.0,
							"k": {
								" Yes": 0.032,
								" 1": 0.03,
								" An": 0.026,
								" The": 0.024,
								" No": 0.016,
								" Yeah": 0.015,
								" 48": 0.013,
								" Py": 0.013,
								" TBD": 0.013,
								" -": 0.012
							}
						},
						{
							"p": " My",
							"b": 0.0,
							"k": {
								" My": 0.037,
								" The": 0.032,
								" Death": 0.029,
								" Yeah": 0.027,
								" Yes": 0.022,
								" No": 0.021,
								" Great": 0.02,
								" Py": 0.02,
								" Song": 0.016,
								" N": 0.014
							}
						},
						{
							"p": " Yes",
							"b": 0.0,
							"k": {
								" Yes": 0.065,
								" No": 0.043,
								" none": 0.036,
								" Yeah": 0.035,
								" TBD": 0.032,
								" Oh": 0.026,
								" An": 0.019,
								" My": 0.018,
								" None": 0.016,
								" Where": 0.016
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.119,
								" An": 0.089,
								" Life": 0.048,
								" No": 0.038,
								" Sl": 0.024,
								" A": 0.016,
								" Man": 0.016,
								" Res": 0.016,
								" R": 0.015,
								" Survival": 0.014
							}
						},
						{
							"p": " The",
							"b": 0.0,
							"k": {
								" The": 0.116,
								" Man": 0.114,
								" An": 0.087,
								" Rod": 0.05,
								" Unknown": 0.023,
								" Id": 0.021,
								" Sl": 0.02,
								" Gun": 0.019,
								" Bob": 0.014,
								" Don": 0.011
							}
						},
						{
							"p": " Man",
							"b": 0.022,
							"k": {
								" Man": 0.239,
								" Guy": 0.056,
								" Th": 0.038,
								" Mal": 0.035,
								" Y": 0.033,
								" Bob": 0.032,
								" Darth": 0.022,
								" Sl": 0.018,
								" Gy": 0.018,
								" Vlad": 0.017
							}
						},
						{
							"p": " Darth",
							"b": 0.223,
							"k": {
								" Darth": 0.223,
								" Harry": 0.086,
								" Luke": 0.081,
								" Tom": 0.033,
								" Man": 0.025,
								" Princess": 0.024,
								" Yuri": 0.02,
								" Mal": 0.018,
								" Kid": 0.018,
								" Tony": 0.017
							}
						},
						{
							"p": " Darth",
							"b": 0.91,
							"k": {
								" Darth": 0.91,
								" Harry": 0.039,
								" Luke": 0.031,
								" Obi": 0.004,
								" Voldemort": 0.002,
								" Princess": 0.001,
								" Captain": 0.001,
								" Superman": 0.001,
								" Jedi": 0.001,
								" Vader": 0.001
							}
						},
						{
							"p": " Darth",
							"b": 0.858,
							"k": {
								" Darth": 0.858,
								" Luke": 0.051,
								" Obi": 0.026,
								" Vader": 0.009,
								" Captain": 0.006,
								" Leia": 0.006,
								" Harry": 0.005,
								" Princess": 0.005,
								" Jedi": 0.005,
								" The": 0.003
							}
						},
						{
							"p": " The",
							"b": 0.115,
							"k": {
								" The": 0.172,
								" Darth": 0.115,
								" Luke": 0.033,
								" the": 0.03,
								" Harry": 0.02,
								" Captain": 0.019,
								" Princess": 0.018,
								" Obi": 0.018,
								" Lord": 0.015,
								" Han": 0.01
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.001,
									0.389,
									0.0,
									0.087,
									0.019,
									0.598,
									0.046,
									0.026,
									0.766,
									0.387,
									0.45
								],
								"e": 0.187
							},
							{
								"h": [
									0.016,
									0.024,
									0.144,
									1.0,
									1.0,
									0.473,
									1.0,
									1.0,
									1.0,
									1.0,
									0.038,
									0.178
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.006,
									0.085,
									0.358,
									0.065,
									0.164,
									1.0,
									0.513,
									0.063,
									0.147,
									1.0
								],
								"e": 0.798
							},
							{
								"h": [
									1.0,
									0.159,
									0.026,
									0.629,
									1.0,
									1.0,
									0.071,
									0.015,
									0.111,
									1.0,
									1.0,
									0.363
								],
								"e": 1.0
							},
							{
								"h": [
									0.813,
									0.362,
									1.0,
									0.069,
									1.0,
									1.0,
									1.0,
									0.222,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.256,
									0.054,
									0.509,
									1.0,
									1.0,
									0.304,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.64,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.674,
									1.0,
									0.631,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.037,
									0.362,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.025,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.295,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.001,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.041,
									0.285,
									0.2,
									0.0,
									0.023,
									1.0,
									0.012,
									0.006,
									0.339,
									0.572,
									0.481,
									0.131
								],
								"e": 0.249
							},
							{
								"h": [
									0.001,
									0.003,
									0.089,
									0.006,
									0.114,
									0.579,
									0.359,
									0.183,
									0.404,
									0.185,
									0.092,
									0.267
								],
								"e": 0.205
							},
							{
								"h": [
									0.008,
									0.013,
									0.0,
									0.0,
									0.0,
									0.0,
									0.007,
									0.069,
									0.0,
									0.0,
									0.036,
									0.002
								],
								"e": 0.01
							},
							{
								"h": [
									0.303,
									0.0,
									0.0,
									0.0,
									0.151,
									0.045,
									0.0,
									0.0,
									0.0,
									0.0,
									0.005,
									0.0
								],
								"e": 0.067
							},
							{
								"h": [
									0.0,
									0.0,
									0.033,
									0.0,
									0.052,
									0.0,
									0.001,
									0.002,
									0.046,
									0.001,
									0.036,
									0.0
								],
								"e": 0.023
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.005,
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.067,
									0.216,
									0.042
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.0,
									0.05,
									0.007,
									0.083,
									0.002,
									0.015,
									0.001,
									0.0,
									0.0,
									0.006,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.008,
									0.0,
									0.0,
									0.0,
									0.011,
									0.003,
									0.0,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.029,
									0.001,
									0.017,
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.002,
									0.001,
									0.001,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.002,
									0.003,
									0.001,
									0.0,
									0.018,
									0.003,
									0.001,
									0.003,
									0.005,
									0.0,
									0.0,
									0.007
								],
								"e": 0.004
							},
							{
								"h": [
									0.012,
									0.002,
									0.013,
									0.007,
									0.017,
									0.0,
									0.002,
									0.017,
									0.014,
									0.0,
									0.002,
									0.01
								],
								"e": 0.006
							},
							{
								"h": [
									0.186,
									0.083,
									0.03,
									0.024,
									0.072,
									0.029,
									0.006,
									0.016,
									0.847,
									0.008,
									0.059,
									0.023
								],
								"e": 0.045
							}
						],
						[
							{
								"h": [
									0.221,
									0.0,
									0.125,
									0.0,
									0.013,
									0.006,
									0.157,
									0.012,
									0.014,
									0.594,
									0.159,
									0.633
								],
								"e": 0.087
							},
							{
								"h": [
									0.005,
									0.005,
									0.111,
									0.008,
									0.127,
									0.015,
									0.075,
									0.149,
									0.226,
									0.345,
									0.144,
									0.04
								],
								"e": 0.119
							},
							{
								"h": [
									0.012,
									0.01,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.144,
									0.0,
									0.0,
									0.064,
									0.004
								],
								"e": 0.014
							},
							{
								"h": [
									0.006,
									0.0,
									0.0,
									0.0,
									0.013,
									0.011,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.0,
									0.004,
									0.0,
									0.013,
									0.0,
									0.001,
									0.001,
									0.022,
									0.0,
									0.006,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.009,
									0.007,
									0.0,
									0.001,
									0.0,
									0.079,
									0.0,
									0.0,
									0.006,
									0.065,
									0.041,
									0.019
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.001,
									0.02,
									0.009,
									0.161,
									0.002,
									0.011,
									0.002,
									0.0,
									0.14,
									0.027,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.0,
									0.049,
									0.009,
									0.001,
									0.0,
									0.012,
									0.04,
									0.002,
									0.001,
									0.0,
									0.012,
									0.116
								],
								"e": 0.016
							},
							{
								"h": [
									0.005,
									0.001,
									0.085,
									0.001,
									0.0,
									0.0,
									0.031,
									0.0,
									0.004,
									0.003,
									0.006,
									0.004
								],
								"e": 0.011
							},
							{
								"h": [
									0.059,
									0.006,
									0.003,
									0.0,
									0.01,
									0.009,
									0.002,
									0.011,
									0.065,
									0.0,
									0.0,
									0.027
								],
								"e": 0.012
							},
							{
								"h": [
									0.011,
									0.002,
									0.005,
									0.009,
									0.044,
									0.0,
									0.006,
									0.265,
									0.003,
									0.0,
									0.001,
									0.05
								],
								"e": 0.016
							},
							{
								"h": [
									0.577,
									0.068,
									0.022,
									0.029,
									0.044,
									0.007,
									0.006,
									0.007,
									0.363,
									0.006,
									0.235,
									0.016
								],
								"e": 0.045
							}
						],
						[
							{
								"h": [
									0.856,
									0.0,
									0.211,
									0.0,
									0.014,
									0.003,
									0.18,
									0.01,
									0.012,
									0.402,
									0.132,
									0.821
								],
								"e": 0.123
							},
							{
								"h": [
									0.008,
									0.003,
									0.136,
									0.008,
									0.124,
									0.036,
									0.059,
									0.142,
									0.228,
									0.296,
									0.165,
									0.028
								],
								"e": 0.114
							},
							{
								"h": [
									0.003,
									0.008,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.169,
									0.0,
									0.0,
									0.067,
									0.005
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.0,
									0.016,
									0.012,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.0,
									0.01,
									0.0,
									0.001,
									0.001,
									0.017,
									0.001,
									0.002,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.004,
									0.0,
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.014,
									0.015,
									0.01
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.001,
									0.022,
									0.005,
									0.228,
									0.002,
									0.003,
									0.005,
									0.0,
									0.0,
									0.069,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.0,
									0.113,
									0.0,
									0.0,
									0.0,
									0.008,
									0.05,
									0.008,
									0.0,
									0.0,
									0.0,
									0.007
								],
								"e": 0.013
							},
							{
								"h": [
									0.006,
									0.0,
									0.05,
									0.0,
									0.0,
									0.0,
									0.083,
									0.0,
									0.005,
									0.002,
									0.004,
									0.006
								],
								"e": 0.011
							},
							{
								"h": [
									0.089,
									0.003,
									0.01,
									0.0,
									0.002,
									0.007,
									0.002,
									0.01,
									0.225,
									0.0,
									0.0,
									0.009
								],
								"e": 0.018
							},
							{
								"h": [
									0.118,
									0.004,
									0.008,
									0.035,
									0.161,
									0.0,
									0.028,
									0.125,
									0.002,
									0.0,
									0.004,
									0.16
								],
								"e": 0.03
							},
							{
								"h": [
									0.151,
									0.071,
									0.061,
									0.023,
									0.125,
									0.011,
									0.011,
									0.027,
									0.263,
									0.01,
									0.144,
									0.018
								],
								"e": 0.043
							}
						],
						[
							{
								"h": [
									0.072,
									0.002,
									0.64,
									0.0,
									0.005,
									0.006,
									0.008,
									0.008,
									0.169,
									0.588,
									0.268,
									0.427
								],
								"e": 0.105
							},
							{
								"h": [
									0.002,
									0.012,
									0.126,
									0.008,
									0.127,
									0.289,
									0.817,
									0.232,
									0.332,
									0.122,
									0.132,
									0.18
								],
								"e": 0.206
							},
							{
								"h": [
									0.006,
									0.061,
									0.0,
									0.0,
									0.0,
									0.0,
									0.045,
									0.085,
									0.0,
									0.0,
									0.033,
									0.003
								],
								"e": 0.029
							},
							{
								"h": [
									0.046,
									0.001,
									0.0,
									0.0,
									0.035,
									0.025,
									0.0,
									0.0,
									0.0,
									0.001,
									0.006,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.0,
									0.05,
									0.0,
									0.054,
									0.0,
									0.002,
									0.009,
									0.092,
									0.001,
									0.015,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.003,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.054,
									0.18,
									0.011
								],
								"e": 0.013
							},
							{
								"h": [
									0.002,
									0.003,
									0.042,
									0.017,
									0.173,
									0.003,
									0.006,
									0.004,
									0.0,
									0.001,
									0.197,
									0.001
								],
								"e": 0.029
							},
							{
								"h": [
									0.0,
									0.041,
									0.0,
									0.0,
									0.0,
									0.044,
									0.02,
									0.016,
									0.0,
									0.0,
									0.001,
									0.004
								],
								"e": 0.008
							},
							{
								"h": [
									0.027,
									0.002,
									0.03,
									0.001,
									0.0,
									0.002,
									0.004,
									0.0,
									0.004,
									0.004,
									0.004,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.002,
									0.003,
									0.001,
									0.001,
									0.013,
									0.001,
									0.0,
									0.006,
									0.008,
									0.0,
									0.001,
									0.009
								],
								"e": 0.004
							},
							{
								"h": [
									0.023,
									0.001,
									0.004,
									0.014,
									0.047,
									0.002,
									0.001,
									0.022,
									0.008,
									0.001,
									0.001,
									0.008
								],
								"e": 0.007
							},
							{
								"h": [
									0.17,
									0.048,
									0.027,
									0.028,
									0.061,
									0.023,
									0.006,
									0.019,
									1.0,
									0.005,
									0.088,
									0.023
								],
								"e": 0.045
							}
						],
						[
							{
								"h": [
									0.355,
									0.0,
									0.059,
									0.0,
									0.005,
									0.005,
									0.243,
									0.007,
									0.007,
									0.418,
									0.149,
									0.161
								],
								"e": 0.062
							},
							{
								"h": [
									0.004,
									0.004,
									0.155,
									0.008,
									0.153,
									0.014,
									0.082,
									0.066,
									0.282,
									0.243,
									0.185,
									0.013
								],
								"e": 0.106
							},
							{
								"h": [
									0.006,
									0.011,
									0.0,
									0.0,
									0.0,
									0.0,
									0.005,
									0.117,
									0.0,
									0.0,
									0.052,
									0.003
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.009,
									0.02,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.0,
									0.006,
									0.0,
									0.001,
									0.005,
									0.011,
									0.0,
									0.005,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.003,
									0.0,
									0.001,
									0.0,
									0.109,
									0.152,
									0.005
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.0,
									0.008,
									0.006,
									0.029,
									0.003,
									0.016,
									0.004,
									0.0,
									0.002,
									0.016,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.019,
									0.001,
									0.0,
									0.0,
									0.012,
									0.008,
									0.0,
									0.001,
									0.0,
									0.0,
									0.005
								],
								"e": 0.003
							},
							{
								"h": [
									0.009,
									0.0,
									0.006,
									0.001,
									0.0,
									0.0,
									0.002,
									0.0,
									0.003,
									0.001,
									0.002,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.023,
									0.006,
									0.002,
									0.0,
									0.001,
									0.005,
									0.001,
									0.001,
									0.011,
									0.0,
									0.0,
									0.019
								],
								"e": 0.005
							},
							{
								"h": [
									0.004,
									0.001,
									0.002,
									0.009,
									0.024,
									0.0,
									0.003,
									0.017,
									0.001,
									0.0,
									0.001,
									0.028
								],
								"e": 0.004
							},
							{
								"h": [
									0.16,
									0.022,
									0.009,
									0.004,
									0.02,
									0.004,
									0.003,
									0.012,
									0.288,
									0.008,
									0.014,
									0.007
								],
								"e": 0.017
							}
						],
						[
							{
								"h": [
									0.262,
									0.0,
									0.158,
									0.0,
									0.002,
									0.004,
									0.053,
									0.001,
									0.012,
									0.376,
									0.097,
									0.171
								],
								"e": 0.048
							},
							{
								"h": [
									0.005,
									0.002,
									0.111,
									0.007,
									0.106,
									0.052,
									0.242,
									0.125,
									0.199,
									0.139,
									0.147,
									0.055
								],
								"e": 0.104
							},
							{
								"h": [
									0.002,
									0.003,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.097,
									0.0,
									0.0,
									0.042,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.0,
									0.01,
									0.012,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.0,
									0.008,
									0.0,
									0.001,
									0.004,
									0.011,
									0.0,
									0.003,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.0,
									0.018,
									0.057,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.002,
									0.001,
									0.003,
									0.006,
									0.016,
									0.003,
									0.016,
									0.005,
									0.001,
									0.0,
									0.036,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.008,
									0.0,
									0.0,
									0.0,
									0.02,
									0.006,
									0.001,
									0.001,
									0.0,
									0.0,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.001,
									0.0,
									0.013,
									0.0,
									0.0,
									0.0,
									0.009,
									0.0,
									0.002,
									0.002,
									0.001,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.023,
									0.0,
									0.008,
									0.001,
									0.001,
									0.004,
									0.0,
									0.001,
									0.014,
									0.0,
									0.001,
									0.014
								],
								"e": 0.004
							},
							{
								"h": [
									0.009,
									0.001,
									0.001,
									0.003,
									0.021,
									0.001,
									0.003,
									0.005,
									0.001,
									0.0,
									0.0,
									0.023
								],
								"e": 0.003
							},
							{
								"h": [
									0.294,
									0.024,
									0.005,
									0.012,
									0.022,
									0.005,
									0.001,
									0.016,
									0.292,
									0.004,
									0.013,
									0.008
								],
								"e": 0.021
							}
						],
						[
							{
								"h": [
									0.025,
									0.458,
									0.197,
									0.0,
									0.01,
									0.636,
									0.008,
									0.003,
									0.475,
									0.539,
									0.598,
									0.089
								],
								"e": 0.233
							},
							{
								"h": [
									0.002,
									0.006,
									0.148,
									0.01,
									0.131,
									0.876,
									0.332,
									0.181,
									0.313,
									0.139,
									0.158,
									0.402
								],
								"e": 0.242
							},
							{
								"h": [
									0.004,
									0.034,
									0.0,
									0.0,
									0.0,
									0.0,
									0.014,
									0.165,
									0.0,
									0.0,
									0.042,
									0.003
								],
								"e": 0.021
							},
							{
								"h": [
									0.937,
									0.0,
									0.0,
									0.0,
									0.271,
									0.012,
									0.0,
									0.0,
									0.0,
									0.0,
									0.006,
									0.0
								],
								"e": 0.148
							},
							{
								"h": [
									0.0,
									0.0,
									0.021,
									0.0,
									0.074,
									0.0,
									0.001,
									0.013,
									0.053,
									0.0,
									0.023,
									0.0
								],
								"e": 0.023
							},
							{
								"h": [
									0.003,
									0.0,
									0.0,
									0.001,
									0.0,
									0.004,
									0.0,
									0.0,
									0.0,
									0.09,
									0.185,
									0.036
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.0,
									0.016,
									0.003,
									0.074,
									0.002,
									0.032,
									0.001,
									0.0,
									0.0,
									0.012,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.002,
									0.0,
									0.0,
									0.0,
									0.025,
									0.002,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.028,
									0.001,
									0.007,
									0.001,
									0.0,
									0.0,
									0.002,
									0.0,
									0.0,
									0.002,
									0.001,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.003,
									0.0,
									0.001,
									0.003,
									0.003,
									0.0,
									0.004,
									0.001,
									0.0,
									0.0,
									0.004
								],
								"e": 0.002
							},
							{
								"h": [
									0.01,
									0.001,
									0.005,
									0.006,
									0.006,
									0.001,
									0.0,
									0.026,
									0.007,
									0.0,
									0.001,
									0.016
								],
								"e": 0.004
							},
							{
								"h": [
									0.171,
									0.174,
									0.016,
									0.013,
									0.057,
									0.015,
									0.005,
									0.017,
									0.875,
									0.004,
									0.052,
									0.023
								],
								"e": 0.043
							}
						],
						[
							{
								"h": [
									0.774,
									0.001,
									0.235,
									0.0,
									0.024,
									0.0,
									0.485,
									0.004,
									0.012,
									0.598,
									0.246,
									1.0
								],
								"e": 0.161
							},
							{
								"h": [
									0.013,
									0.018,
									0.263,
									0.014,
									0.196,
									0.07,
									0.214,
									0.435,
									0.394,
									0.447,
									0.294,
									0.067
								],
								"e": 0.227
							},
							{
								"h": [
									0.014,
									0.038,
									0.0,
									0.001,
									0.0,
									0.0,
									0.01,
									0.32,
									0.001,
									0.0,
									0.161,
									0.01
								],
								"e": 0.036
							},
							{
								"h": [
									0.019,
									0.0,
									0.0,
									0.0,
									0.055,
									0.016,
									0.0,
									0.0,
									0.0,
									0.004,
									0.011,
									0.001
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.0,
									0.004,
									0.001,
									0.031,
									0.0,
									0.003,
									0.021,
									0.023,
									0.002,
									0.014,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.166,
									0.076,
									0.0,
									0.001,
									0.0,
									0.706,
									0.006,
									0.0,
									0.026,
									0.075,
									0.088,
									0.032
								],
								"e": 0.117
							},
							{
								"h": [
									0.002,
									0.005,
									0.012,
									0.007,
									0.237,
									0.004,
									0.036,
									0.016,
									0.001,
									1.0,
									0.202,
									0.001
								],
								"e": 0.127
							},
							{
								"h": [
									0.0,
									0.076,
									0.008,
									0.001,
									0.0,
									0.026,
									0.142,
									0.066,
									0.002,
									0.0,
									0.004,
									0.418
								],
								"e": 0.043
							},
							{
								"h": [
									0.017,
									0.009,
									0.065,
									0.001,
									0.002,
									0.001,
									0.189,
									0.0,
									0.014,
									0.012,
									0.043,
									0.008
								],
								"e": 0.027
							},
							{
								"h": [
									0.09,
									0.027,
									0.012,
									0.001,
									0.018,
									0.009,
									0.002,
									0.021,
									0.351,
									0.0,
									0.0,
									0.025
								],
								"e": 0.03
							},
							{
								"h": [
									0.124,
									0.005,
									0.007,
									0.109,
									0.161,
									0.0,
									0.05,
									0.384,
									0.006,
									0.0,
									0.005,
									0.242
								],
								"e": 0.052
							},
							{
								"h": [
									0.286,
									0.236,
									0.058,
									0.065,
									0.105,
									0.018,
									0.024,
									0.028,
									0.121,
									0.03,
									0.623,
									0.056
								],
								"e": 0.082
							}
						],
						[
							{
								"h": [
									0.072,
									0.002,
									0.704,
									0.0,
									0.003,
									0.003,
									0.009,
									0.007,
									0.193,
									0.599,
									0.292,
									0.401
								],
								"e": 0.11
							},
							{
								"h": [
									0.006,
									0.027,
									0.199,
									0.013,
									0.157,
									0.354,
									0.938,
									0.241,
									0.336,
									0.119,
									0.225,
									0.232
								],
								"e": 0.247
							},
							{
								"h": [
									0.01,
									0.074,
									0.0,
									0.0,
									0.0,
									0.0,
									0.08,
									0.143,
									0.001,
									0.0,
									0.062,
									0.005
								],
								"e": 0.042
							},
							{
								"h": [
									0.081,
									0.002,
									0.0,
									0.0,
									0.079,
									0.028,
									0.0,
									0.0,
									0.0,
									0.002,
									0.011,
									0.0
								],
								"e": 0.029
							},
							{
								"h": [
									0.0,
									0.0,
									0.043,
									0.0,
									0.075,
									0.0,
									0.004,
									0.045,
									0.113,
									0.004,
									0.015,
									0.0
								],
								"e": 0.035
							},
							{
								"h": [
									0.011,
									0.0,
									0.001,
									0.003,
									0.0,
									0.005,
									0.0,
									0.0,
									0.001,
									0.061,
									0.142,
									0.02
								],
								"e": 0.014
							},
							{
								"h": [
									0.004,
									0.002,
									0.019,
									0.015,
									0.198,
									0.008,
									0.043,
									0.003,
									0.0,
									0.012,
									0.266,
									0.001
								],
								"e": 0.035
							},
							{
								"h": [
									0.0,
									0.027,
									0.0,
									0.001,
									0.0,
									0.025,
									0.042,
									0.113,
									0.0,
									0.0,
									0.001,
									0.005
								],
								"e": 0.012
							},
							{
								"h": [
									0.028,
									0.002,
									0.016,
									0.001,
									0.001,
									0.0,
									0.002,
									0.0,
									0.003,
									0.006,
									0.023,
									0.002
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.005,
									0.002,
									0.001,
									0.004,
									0.002,
									0.0,
									0.005,
									0.005,
									0.0,
									0.002,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.029,
									0.001,
									0.004,
									0.035,
									0.046,
									0.002,
									0.001,
									0.016,
									0.005,
									0.002,
									0.002,
									0.006
								],
								"e": 0.009
							},
							{
								"h": [
									0.096,
									0.042,
									0.016,
									0.009,
									0.035,
									0.013,
									0.004,
									0.014,
									0.737,
									0.004,
									0.045,
									0.018
								],
								"e": 0.029
							}
						],
						[
							{
								"h": [
									0.531,
									0.0,
									0.098,
									0.0,
									0.007,
									0.004,
									0.412,
									0.012,
									0.005,
									0.332,
									0.187,
									0.118
								],
								"e": 0.08
							},
							{
								"h": [
									0.029,
									0.009,
									0.297,
									0.015,
									0.188,
									0.068,
									0.243,
									0.103,
									0.313,
									0.185,
									0.313,
									0.016
								],
								"e": 0.15
							},
							{
								"h": [
									0.008,
									0.005,
									0.0,
									0.001,
									0.0,
									0.0,
									0.012,
									0.381,
									0.001,
									0.0,
									0.128,
									0.01
								],
								"e": 0.028
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.0,
									0.06,
									0.027,
									0.0,
									0.0,
									0.0,
									0.003,
									0.006,
									0.004
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.0,
									0.01,
									0.004,
									0.03,
									0.0,
									0.006,
									0.045,
									0.058,
									0.008,
									0.009,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.004,
									0.001,
									0.036,
									0.004,
									0.001,
									0.006,
									0.126,
									0.19,
									0.01
								],
								"e": 0.023
							},
							{
								"h": [
									0.009,
									0.002,
									0.012,
									0.029,
									0.083,
									0.014,
									0.029,
									0.01,
									0.001,
									0.027,
									0.081,
									0.005
								],
								"e": 0.022
							},
							{
								"h": [
									0.001,
									0.023,
									0.012,
									0.0,
									0.0,
									0.062,
									0.013,
									0.012,
									0.001,
									0.0,
									0.013,
									0.012
								],
								"e": 0.011
							},
							{
								"h": [
									0.004,
									0.003,
									0.03,
									0.001,
									0.001,
									0.0,
									0.003,
									0.001,
									0.005,
									0.001,
									0.001,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.019,
									0.003,
									0.011,
									0.002,
									0.001,
									0.008,
									0.001,
									0.002,
									0.01,
									0.0,
									0.001,
									0.008
								],
								"e": 0.005
							},
							{
								"h": [
									0.009,
									0.003,
									0.002,
									0.003,
									0.022,
									0.001,
									0.01,
									0.004,
									0.001,
									0.001,
									0.001,
									0.04
								],
								"e": 0.005
							},
							{
								"h": [
									0.236,
									0.015,
									0.005,
									0.004,
									0.012,
									0.006,
									0.003,
									0.01,
									0.213,
									0.009,
									0.02,
									0.003
								],
								"e": 0.016
							}
						],
						[
							{
								"h": [
									0.027,
									0.554,
									0.209,
									0.0,
									0.013,
									0.52,
									0.009,
									0.004,
									0.543,
									0.573,
									0.674,
									0.082
								],
								"e": 0.24
							},
							{
								"h": [
									0.003,
									0.013,
									0.215,
									0.014,
									0.162,
									0.928,
									0.323,
									0.192,
									0.319,
									0.123,
									0.235,
									0.487
								],
								"e": 0.27
							},
							{
								"h": [
									0.01,
									0.039,
									0.0,
									0.0,
									0.0,
									0.0,
									0.024,
									0.217,
									0.0,
									0.0,
									0.072,
									0.005
								],
								"e": 0.028
							},
							{
								"h": [
									0.656,
									0.0,
									0.0,
									0.0,
									0.522,
									0.022,
									0.0,
									0.0,
									0.0,
									0.002,
									0.02,
									0.0
								],
								"e": 0.151
							},
							{
								"h": [
									0.0,
									0.0,
									0.046,
									0.0,
									0.13,
									0.0,
									0.003,
									0.033,
									0.079,
									0.005,
									0.029,
									0.0
								],
								"e": 0.038
							},
							{
								"h": [
									0.002,
									0.0,
									0.001,
									0.005,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.124,
									0.222,
									0.09
								],
								"e": 0.027
							},
							{
								"h": [
									0.002,
									0.0,
									0.009,
									0.007,
									0.176,
									0.005,
									0.063,
									0.003,
									0.0,
									0.0,
									0.016,
									0.001
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.012,
									0.014,
									0.004,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.054,
									0.001,
									0.005,
									0.002,
									0.0,
									0.0,
									0.003,
									0.0,
									0.001,
									0.002,
									0.002,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.004,
									0.001,
									0.003,
									0.003,
									0.007,
									0.0,
									0.005,
									0.005,
									0.0,
									0.0,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.046,
									0.002,
									0.006,
									0.006,
									0.009,
									0.001,
									0.001,
									0.018,
									0.005,
									0.0,
									0.001,
									0.019
								],
								"e": 0.006
							},
							{
								"h": [
									0.208,
									0.145,
									0.014,
									0.017,
									0.036,
									0.013,
									0.006,
									0.015,
									0.703,
									0.003,
									0.055,
									0.024
								],
								"e": 0.038
							}
						],
						[
							{
								"h": [
									0.163,
									0.0,
									0.1,
									0.0,
									0.02,
									0.001,
									0.389,
									0.053,
									0.007,
									0.561,
									0.197,
									0.203
								],
								"e": 0.081
							},
							{
								"h": [
									0.032,
									0.014,
									0.293,
									0.017,
									0.222,
									0.073,
									0.141,
									0.195,
									0.28,
									0.258,
									0.384,
									0.095
								],
								"e": 0.178
							},
							{
								"h": [
									0.032,
									0.015,
									0.0,
									0.002,
									0.004,
									0.0,
									0.017,
									0.379,
									0.001,
									0.002,
									0.201,
									0.013
								],
								"e": 0.038
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.0,
									0.055,
									0.016,
									0.003,
									0.0,
									0.0,
									0.006,
									0.016,
									0.003
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.0,
									0.006,
									0.002,
									0.042,
									0.001,
									0.012,
									0.021,
									0.034,
									0.005,
									0.009,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.362,
									0.013,
									0.001,
									0.004,
									0.0,
									0.09,
									0.001,
									0.0,
									0.032,
									0.102,
									0.077,
									0.027
								],
								"e": 0.064
							},
							{
								"h": [
									0.006,
									0.015,
									0.017,
									0.02,
									0.299,
									0.011,
									0.024,
									0.036,
									0.001,
									0.061,
									0.235,
									0.001
								],
								"e": 0.046
							},
							{
								"h": [
									0.001,
									0.04,
									0.007,
									0.002,
									0.001,
									0.051,
									0.099,
									0.098,
									0.012,
									0.001,
									0.005,
									0.319
								],
								"e": 0.036
							},
							{
								"h": [
									0.008,
									0.007,
									0.045,
									0.004,
									0.001,
									0.001,
									0.33,
									0.001,
									0.037,
									0.03,
									0.024,
									0.025
								],
								"e": 0.037
							},
							{
								"h": [
									0.112,
									0.013,
									0.015,
									0.005,
									0.007,
									0.014,
									0.003,
									0.034,
									0.258,
									0.0,
									0.0,
									0.018
								],
								"e": 0.026
							},
							{
								"h": [
									0.113,
									0.003,
									0.011,
									0.017,
									0.456,
									0.001,
									0.017,
									0.412,
									0.005,
									0.001,
									0.004,
									0.613
								],
								"e": 0.067
							},
							{
								"h": [
									0.331,
									0.159,
									0.064,
									0.037,
									0.148,
									0.008,
									0.019,
									0.043,
									0.191,
									0.004,
									0.292,
									0.032
								],
								"e": 0.063
							}
						],
						[
							{
								"h": [
									0.08,
									0.002,
									0.766,
									0.0,
									0.006,
									0.002,
									0.01,
									0.016,
									0.221,
									0.658,
									0.325,
									0.389
								],
								"e": 0.12
							},
							{
								"h": [
									0.016,
									0.064,
									0.279,
									0.018,
									0.198,
									0.335,
									0.951,
									0.252,
									0.335,
									0.105,
									0.325,
									0.271
								],
								"e": 0.276
							},
							{
								"h": [
									0.024,
									0.066,
									0.0,
									0.0,
									0.002,
									0.001,
									0.131,
									0.196,
									0.002,
									0.0,
									0.109,
									0.009
								],
								"e": 0.054
							},
							{
								"h": [
									0.06,
									0.009,
									0.0,
									0.0,
									0.103,
									0.044,
									0.001,
									0.0,
									0.0,
									0.01,
									0.026,
									0.003
								],
								"e": 0.039
							},
							{
								"h": [
									0.0,
									0.003,
									0.094,
									0.001,
									0.096,
									0.002,
									0.016,
									0.049,
									0.071,
									0.017,
									0.023,
									0.0
								],
								"e": 0.043
							},
							{
								"h": [
									0.011,
									0.0,
									0.007,
									0.007,
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.091,
									0.178,
									0.041
								],
								"e": 0.021
							},
							{
								"h": [
									0.019,
									0.003,
									0.027,
									0.047,
									0.249,
									0.015,
									0.041,
									0.01,
									0.001,
									0.0,
									0.222,
									0.003
								],
								"e": 0.04
							},
							{
								"h": [
									0.0,
									0.013,
									0.0,
									0.002,
									0.0,
									0.046,
									0.024,
									0.098,
									0.001,
									0.001,
									0.0,
									0.003
								],
								"e": 0.01
							},
							{
								"h": [
									0.037,
									0.001,
									0.006,
									0.002,
									0.002,
									0.002,
									0.002,
									0.0,
									0.006,
									0.003,
									0.013,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.001,
									0.003,
									0.001,
									0.004,
									0.002,
									0.002,
									0.0,
									0.007,
									0.003,
									0.0,
									0.003,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.023,
									0.001,
									0.002,
									0.013,
									0.027,
									0.006,
									0.001,
									0.009,
									0.004,
									0.004,
									0.001,
									0.006
								],
								"e": 0.006
							},
							{
								"h": [
									0.063,
									0.032,
									0.008,
									0.005,
									0.022,
									0.008,
									0.002,
									0.01,
									0.41,
									0.003,
									0.024,
									0.007
								],
								"e": 0.017
							}
						],
						[
							{
								"h": [
									0.47,
									0.0,
									0.066,
									0.0,
									0.009,
									0.003,
									0.384,
									0.02,
									0.008,
									0.485,
									0.178,
									0.147
								],
								"e": 0.08
							},
							{
								"h": [
									0.03,
									0.019,
									0.347,
									0.017,
									0.264,
									0.014,
									0.075,
									0.048,
									0.334,
									0.277,
									0.391,
									0.017
								],
								"e": 0.157
							},
							{
								"h": [
									0.04,
									0.006,
									0.0,
									0.004,
									0.003,
									0.0,
									0.021,
									0.37,
									0.002,
									0.001,
									0.155,
									0.011
								],
								"e": 0.034
							},
							{
								"h": [
									0.0,
									0.002,
									0.0,
									0.0,
									0.026,
									0.021,
									0.002,
									0.0,
									0.0,
									0.009,
									0.009,
									0.004
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.001,
									0.003,
									0.007,
									0.036,
									0.001,
									0.009,
									0.025,
									0.006,
									0.004,
									0.004,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.002,
									0.0,
									0.002,
									0.008,
									0.001,
									0.0,
									0.003,
									0.005,
									0.002,
									0.111,
									0.142,
									0.016
								],
								"e": 0.017
							},
							{
								"h": [
									0.021,
									0.002,
									0.007,
									0.035,
									0.045,
									0.015,
									0.033,
									0.014,
									0.01,
									0.0,
									0.05,
									0.011
								],
								"e": 0.018
							},
							{
								"h": [
									0.001,
									0.004,
									0.001,
									0.002,
									0.0,
									0.022,
									0.01,
									0.006,
									0.004,
									0.001,
									0.0,
									0.02
								],
								"e": 0.004
							},
							{
								"h": [
									0.016,
									0.001,
									0.003,
									0.001,
									0.003,
									0.001,
									0.001,
									0.0,
									0.005,
									0.0,
									0.003,
									0.004
								],
								"e": 0.003
							},
							{
								"h": [
									0.007,
									0.003,
									0.003,
									0.001,
									0.0,
									0.002,
									0.0,
									0.0,
									0.004,
									0.0,
									0.0,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.003,
									0.008,
									0.001,
									0.001,
									0.004,
									0.0,
									0.001,
									0.0,
									0.009
								],
								"e": 0.001
							},
							{
								"h": [
									0.165,
									0.004,
									0.001,
									0.0,
									0.003,
									0.001,
									0.0,
									0.002,
									0.048,
									0.001,
									0.003,
									0.0
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.373,
									0.0,
									0.178,
									0.0,
									0.006,
									0.002,
									0.079,
									0.003,
									0.014,
									0.446,
									0.115,
									0.164
								],
								"e": 0.058
							},
							{
								"h": [
									0.036,
									0.01,
									0.237,
									0.013,
									0.177,
									0.048,
									0.172,
									0.091,
									0.213,
									0.139,
									0.318,
									0.066
								],
								"e": 0.13
							},
							{
								"h": [
									0.013,
									0.002,
									0.0,
									0.0,
									0.002,
									0.0,
									0.008,
									0.234,
									0.003,
									0.001,
									0.136,
									0.011
								],
								"e": 0.022
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.0,
									0.033,
									0.013,
									0.0,
									0.0,
									0.0,
									0.004,
									0.013,
									0.005
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.006,
									0.027,
									0.001,
									0.009,
									0.025,
									0.006,
									0.003,
									0.002,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.0,
									0.002,
									0.003,
									0.0,
									0.0,
									0.025,
									0.002,
									0.0,
									0.04,
									0.089,
									0.012
								],
								"e": 0.012
							},
							{
								"h": [
									0.019,
									0.002,
									0.005,
									0.023,
									0.024,
									0.02,
									0.035,
									0.009,
									0.014,
									0.0,
									0.033,
									0.02
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.002,
									0.0,
									0.001,
									0.0,
									0.061,
									0.005,
									0.006,
									0.004,
									0.0,
									0.0,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.0,
									0.008,
									0.0,
									0.003,
									0.002,
									0.001,
									0.002,
									0.004,
									0.0,
									0.0,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.005,
									0.0,
									0.012,
									0.002,
									0.0,
									0.004,
									0.0,
									0.0,
									0.008,
									0.0,
									0.002,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.009,
									0.0,
									0.0,
									0.001,
									0.003,
									0.002,
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.005
								],
								"e": 0.001
							},
							{
								"h": [
									0.147,
									0.003,
									0.0,
									0.001,
									0.002,
									0.001,
									0.0,
									0.001,
									0.057,
									0.0,
									0.002,
									0.0
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.031,
									0.677,
									0.223,
									0.002,
									0.038,
									0.457,
									0.01,
									0.017,
									0.65,
									0.649,
									0.772,
									0.076
								],
								"e": 0.269
							},
							{
								"h": [
									0.01,
									0.031,
									0.317,
									0.019,
									0.212,
									0.838,
									0.281,
									0.166,
									0.307,
									0.099,
									0.346,
									0.572
								],
								"e": 0.287
							},
							{
								"h": [
									0.031,
									0.026,
									0.0,
									0.001,
									0.001,
									0.001,
									0.042,
									0.301,
									0.003,
									0.0,
									0.137,
									0.011
								],
								"e": 0.038
							},
							{
								"h": [
									0.435,
									0.001,
									0.0,
									0.0,
									0.322,
									0.014,
									0.0,
									0.0,
									0.0,
									0.003,
									0.04,
									0.001
								],
								"e": 0.102
							},
							{
								"h": [
									0.0,
									0.0,
									0.023,
									0.0,
									0.142,
									0.0,
									0.007,
									0.066,
									0.031,
									0.003,
									0.019,
									0.0
								],
								"e": 0.031
							},
							{
								"h": [
									0.006,
									0.0,
									0.002,
									0.007,
									0.0,
									0.005,
									0.002,
									0.0,
									0.0,
									0.089,
									0.106,
									0.12
								],
								"e": 0.024
							},
							{
								"h": [
									0.006,
									0.001,
									0.01,
									0.014,
									0.109,
									0.012,
									0.235,
									0.003,
									0.002,
									0.0,
									0.016,
									0.001
								],
								"e": 0.028
							},
							{
								"h": [
									0.0,
									0.002,
									0.0,
									0.002,
									0.0,
									0.007,
									0.034,
									0.025,
									0.001,
									0.0,
									0.001,
									0.01
								],
								"e": 0.005
							},
							{
								"h": [
									0.047,
									0.003,
									0.003,
									0.003,
									0.0,
									0.0,
									0.002,
									0.0,
									0.002,
									0.01,
									0.007,
									0.009
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.007,
									0.002,
									0.004,
									0.003,
									0.006,
									0.0,
									0.006,
									0.004,
									0.0,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.076,
									0.001,
									0.003,
									0.005,
									0.005,
									0.001,
									0.0,
									0.012,
									0.004,
									0.0,
									0.002,
									0.021
								],
								"e": 0.006
							},
							{
								"h": [
									0.174,
									0.262,
									0.014,
									0.014,
									0.044,
									0.011,
									0.005,
									0.027,
									0.393,
									0.003,
									0.032,
									0.018
								],
								"e": 0.037
							}
						],
						[
							{
								"h": [
									0.988,
									0.0,
									0.311,
									0.001,
									0.076,
									0.005,
									0.036,
									0.053,
									0.119,
									1.0,
									0.365,
									0.117
								],
								"e": 0.139
							},
							{
								"h": [
									0.065,
									0.112,
									0.354,
									0.025,
									0.27,
									0.271,
									0.502,
									0.367,
									0.432,
									0.392,
									0.532,
									0.672
								],
								"e": 0.38
							},
							{
								"h": [
									0.146,
									0.023,
									0.0,
									0.066,
									0.02,
									0.003,
									0.05,
									0.376,
									0.023,
									0.003,
									0.249,
									0.026
								],
								"e": 0.078
							},
							{
								"h": [
									0.027,
									0.003,
									0.002,
									0.0,
									0.131,
									0.085,
									0.002,
									0.0,
									0.0,
									0.037,
									0.039,
									0.031
								],
								"e": 0.057
							},
							{
								"h": [
									0.003,
									0.001,
									0.009,
									0.001,
									0.004,
									0.004,
									0.031,
									0.054,
									0.047,
									0.015,
									0.008,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									1.0,
									1.0,
									0.003,
									0.003,
									0.002,
									1.0,
									0.0,
									0.001,
									0.417,
									0.503,
									0.087,
									0.018
								],
								"e": 0.484
							},
							{
								"h": [
									0.011,
									0.058,
									0.021,
									0.014,
									0.187,
									0.019,
									0.083,
									0.055,
									0.014,
									0.556,
									0.196,
									0.008
								],
								"e": 0.102
							},
							{
								"h": [
									0.0,
									0.018,
									0.016,
									0.007,
									0.0,
									0.03,
									0.089,
									0.054,
									0.004,
									0.001,
									0.067,
									0.244
								],
								"e": 0.035
							},
							{
								"h": [
									0.029,
									0.076,
									0.017,
									0.003,
									0.001,
									0.002,
									0.033,
									0.001,
									0.016,
									0.053,
									0.021,
									0.036
								],
								"e": 0.025
							},
							{
								"h": [
									0.016,
									0.025,
									0.006,
									0.003,
									0.005,
									0.003,
									0.005,
									0.023,
									0.001,
									0.0,
									0.003,
									0.277
								],
								"e": 0.029
							},
							{
								"h": [
									0.035,
									0.002,
									0.004,
									0.007,
									0.031,
									0.003,
									0.001,
									0.02,
									0.022,
									0.0,
									0.007,
									0.006
								],
								"e": 0.009
							},
							{
								"h": [
									0.348,
									0.265,
									0.032,
									0.019,
									0.055,
									0.018,
									0.018,
									0.025,
									0.216,
									0.003,
									0.03,
									0.021
								],
								"e": 0.044
							}
						],
						[
							{
								"h": [
									0.363,
									0.0,
									0.167,
									0.002,
									0.093,
									0.001,
									0.434,
									0.219,
									0.013,
									0.625,
									0.242,
									0.269
								],
								"e": 0.134
							},
							{
								"h": [
									0.164,
									0.065,
									0.477,
									0.026,
									0.376,
									0.062,
									0.06,
									0.154,
									0.298,
									0.218,
									0.663,
									0.068
								],
								"e": 0.238
							},
							{
								"h": [
									0.103,
									0.009,
									0.001,
									0.012,
									0.042,
									0.004,
									0.043,
									0.561,
									0.023,
									0.026,
									0.481,
									0.031
								],
								"e": 0.085
							},
							{
								"h": [
									0.001,
									0.004,
									0.0,
									0.002,
									0.063,
									0.018,
									0.021,
									0.001,
									0.001,
									0.069,
									0.047,
									0.051
								],
								"e": 0.04
							},
							{
								"h": [
									0.005,
									0.001,
									0.004,
									0.018,
									0.03,
									0.005,
									0.077,
									0.055,
									0.015,
									0.016,
									0.008,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.055,
									0.0,
									0.011,
									0.021,
									0.003,
									0.012,
									0.001,
									0.002,
									0.019,
									0.065,
									0.072,
									0.012
								],
								"e": 0.021
							},
							{
								"h": [
									0.053,
									0.023,
									0.016,
									0.022,
									0.287,
									0.032,
									0.038,
									0.155,
									0.012,
									0.032,
									0.374,
									0.016
								],
								"e": 0.072
							},
							{
								"h": [
									0.004,
									0.165,
									0.002,
									0.005,
									0.003,
									0.073,
									0.215,
									1.0,
									0.073,
									0.004,
									0.001,
									0.013
								],
								"e": 0.082
							},
							{
								"h": [
									0.005,
									0.002,
									0.062,
									0.011,
									0.008,
									0.005,
									0.117,
									0.007,
									0.077,
									0.04,
									0.037,
									0.147
								],
								"e": 0.038
							},
							{
								"h": [
									0.123,
									0.015,
									0.026,
									0.017,
									0.005,
									0.007,
									0.004,
									0.03,
									0.204,
									0.0,
									0.002,
									0.006
								],
								"e": 0.025
							},
							{
								"h": [
									0.167,
									0.001,
									0.003,
									0.019,
									0.196,
									0.002,
									0.022,
									0.147,
									0.002,
									0.002,
									0.004,
									0.098
								],
								"e": 0.03
							},
							{
								"h": [
									0.23,
									0.084,
									0.031,
									0.033,
									0.047,
									0.006,
									0.01,
									0.021,
									0.098,
									0.008,
									0.145,
									0.034
								],
								"e": 0.036
							}
						],
						[
							{
								"h": [
									0.108,
									0.002,
									0.896,
									0.003,
									0.029,
									0.002,
									0.013,
									0.122,
									0.276,
									0.797,
									0.38,
									0.389
								],
								"e": 0.155
							},
							{
								"h": [
									0.09,
									0.257,
									0.523,
									0.033,
									0.31,
									0.378,
									0.855,
									0.247,
									0.329,
									0.09,
									0.603,
									0.357
								],
								"e": 0.384
							},
							{
								"h": [
									0.13,
									0.044,
									0.0,
									0.005,
									0.027,
									0.038,
									0.406,
									0.354,
									0.039,
									0.004,
									0.296,
									0.027
								],
								"e": 0.131
							},
							{
								"h": [
									0.056,
									0.103,
									0.005,
									0.004,
									0.107,
									0.066,
									0.012,
									0.0,
									0.024,
									0.087,
									0.09,
									0.055
								],
								"e": 0.105
							},
							{
								"h": [
									0.004,
									0.06,
									0.105,
									0.005,
									0.103,
									0.02,
									0.063,
									0.166,
									0.038,
									0.046,
									0.022,
									0.0
								],
								"e": 0.066
							},
							{
								"h": [
									0.002,
									0.0,
									0.115,
									0.038,
									0.006,
									0.0,
									0.0,
									0.004,
									0.001,
									0.063,
									0.112,
									0.036
								],
								"e": 0.029
							},
							{
								"h": [
									0.112,
									0.008,
									0.029,
									0.081,
									0.178,
									0.046,
									0.085,
									0.017,
									0.007,
									0.0,
									0.334,
									0.018
								],
								"e": 0.062
							},
							{
								"h": [
									0.002,
									0.039,
									0.0,
									0.005,
									0.002,
									0.048,
									0.036,
									0.489,
									0.007,
									0.004,
									0.0,
									0.001
								],
								"e": 0.032
							},
							{
								"h": [
									0.028,
									0.003,
									0.008,
									0.003,
									0.011,
									0.011,
									0.002,
									0.002,
									0.011,
									0.006,
									0.035,
									0.014
								],
								"e": 0.011
							},
							{
								"h": [
									0.002,
									0.003,
									0.003,
									0.012,
									0.002,
									0.002,
									0.0,
									0.019,
									0.005,
									0.0,
									0.013,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.045,
									0.001,
									0.003,
									0.026,
									0.035,
									0.012,
									0.001,
									0.014,
									0.003,
									0.01,
									0.001,
									0.007
								],
								"e": 0.01
							},
							{
								"h": [
									0.057,
									0.028,
									0.007,
									0.007,
									0.023,
									0.008,
									0.002,
									0.009,
									0.371,
									0.003,
									0.026,
									0.007
								],
								"e": 0.016
							}
						],
						[
							{
								"h": [
									0.778,
									0.0,
									0.111,
									0.001,
									0.055,
									0.003,
									0.655,
									0.182,
									0.007,
									0.417,
									0.229,
									0.107
								],
								"e": 0.136
							},
							{
								"h": [
									0.312,
									0.059,
									0.601,
									0.028,
									0.356,
									0.058,
									0.185,
									0.08,
									0.302,
									0.175,
									0.655,
									0.024
								],
								"e": 0.258
							},
							{
								"h": [
									0.102,
									0.004,
									0.005,
									0.042,
									0.041,
									0.01,
									0.058,
									0.713,
									0.059,
									0.069,
									0.496,
									0.046
								],
								"e": 0.114
							},
							{
								"h": [
									0.0,
									0.052,
									0.018,
									0.004,
									0.073,
									0.051,
									0.059,
									0.01,
									0.002,
									0.123,
									0.044,
									0.314
								],
								"e": 0.111
							},
							{
								"h": [
									0.009,
									0.016,
									0.032,
									0.19,
									0.042,
									0.034,
									0.119,
									0.213,
									0.02,
									0.149,
									0.012,
									0.0
								],
								"e": 0.078
							},
							{
								"h": [
									0.001,
									0.0,
									0.017,
									0.091,
									0.02,
									0.009,
									0.015,
									0.016,
									0.031,
									0.15,
									0.169,
									0.027
								],
								"e": 0.041
							},
							{
								"h": [
									0.166,
									0.007,
									0.015,
									0.088,
									0.043,
									0.074,
									0.046,
									0.015,
									0.051,
									0.003,
									0.071,
									0.073
								],
								"e": 0.05
							},
							{
								"h": [
									0.02,
									0.007,
									0.005,
									0.003,
									0.002,
									0.069,
									0.012,
									0.017,
									0.016,
									0.004,
									0.002,
									0.011
								],
								"e": 0.012
							},
							{
								"h": [
									0.006,
									0.003,
									0.013,
									0.001,
									0.023,
									0.015,
									0.002,
									0.033,
									0.017,
									0.001,
									0.004,
									0.011
								],
								"e": 0.011
							},
							{
								"h": [
									0.01,
									0.002,
									0.014,
									0.013,
									0.001,
									0.007,
									0.0,
									0.004,
									0.006,
									0.0,
									0.007,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.01,
									0.002,
									0.001,
									0.002,
									0.016,
									0.008,
									0.004,
									0.003,
									0.0,
									0.021,
									0.0,
									0.022
								],
								"e": 0.006
							},
							{
								"h": [
									0.223,
									0.011,
									0.002,
									0.001,
									0.004,
									0.003,
									0.001,
									0.005,
									0.126,
									0.003,
									0.013,
									0.0
								],
								"e": 0.011
							}
						],
						[
							{
								"h": [
									0.038,
									0.817,
									0.245,
									0.035,
									0.166,
									0.407,
									0.013,
									0.105,
									0.776,
									0.753,
									0.864,
									0.072
								],
								"e": 0.343
							},
							{
								"h": [
									0.042,
									0.115,
									0.51,
									0.031,
									0.316,
									0.947,
									0.293,
									0.197,
									0.316,
									0.091,
									0.559,
									0.737
								],
								"e": 0.381
							},
							{
								"h": [
									0.153,
									0.03,
									0.0,
									0.01,
									0.01,
									0.015,
									0.125,
									0.479,
									0.045,
									0.002,
									0.353,
									0.032
								],
								"e": 0.093
							},
							{
								"h": [
									0.286,
									0.012,
									0.002,
									0.004,
									0.379,
									0.044,
									0.004,
									0.0,
									0.008,
									0.047,
									0.16,
									0.028
								],
								"e": 0.135
							},
							{
								"h": [
									0.008,
									0.011,
									0.073,
									0.006,
									0.171,
									0.011,
									0.08,
									0.215,
									0.047,
									0.079,
									0.035,
									0.0
								],
								"e": 0.073
							},
							{
								"h": [
									0.002,
									0.0,
									0.024,
									0.091,
									0.002,
									0.0,
									0.0,
									0.002,
									0.0,
									0.142,
									0.187,
									0.234
								],
								"e": 0.052
							},
							{
								"h": [
									0.069,
									0.002,
									0.019,
									0.077,
									0.251,
									0.039,
									0.198,
									0.02,
									0.009,
									0.0,
									0.02,
									0.018
								],
								"e": 0.05
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.006,
									0.0,
									0.02,
									0.028,
									0.008,
									0.003,
									0.001,
									0.0,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.219,
									0.003,
									0.005,
									0.006,
									0.001,
									0.003,
									0.004,
									0.004,
									0.009,
									0.026,
									0.024,
									0.046
								],
								"e": 0.026
							},
							{
								"h": [
									0.003,
									0.011,
									0.004,
									0.031,
									0.005,
									0.016,
									0.0,
									0.018,
									0.011,
									0.0,
									0.007,
									0.001
								],
								"e": 0.009
							},
							{
								"h": [
									0.129,
									0.002,
									0.003,
									0.004,
									0.005,
									0.004,
									0.0,
									0.017,
									0.004,
									0.002,
									0.002,
									0.018
								],
								"e": 0.009
							},
							{
								"h": [
									0.157,
									0.219,
									0.011,
									0.019,
									0.05,
									0.01,
									0.006,
									0.034,
									0.534,
									0.002,
									0.034,
									0.024
								],
								"e": 0.039
							}
						],
						[
							{
								"h": [
									0.65,
									0.0,
									0.08,
									0.006,
									0.063,
									0.002,
									1.0,
									0.171,
									0.008,
									0.517,
									0.211,
									0.258
								],
								"e": 0.163
							},
							{
								"h": [
									0.568,
									0.219,
									0.873,
									0.044,
									0.651,
									0.018,
									0.084,
									0.295,
									0.277,
									0.209,
									1.0,
									0.173
								],
								"e": 0.439
							},
							{
								"h": [
									0.507,
									0.019,
									0.012,
									0.135,
									0.131,
									0.019,
									0.087,
									0.611,
									0.068,
									0.449,
									0.858,
									0.062
								],
								"e": 0.257
							},
							{
								"h": [
									0.0,
									0.029,
									0.051,
									0.034,
									0.027,
									0.072,
									0.49,
									0.019,
									0.022,
									0.3,
									0.08,
									0.233
								],
								"e": 0.23
							},
							{
								"h": [
									0.409,
									0.028,
									0.018,
									0.152,
									0.011,
									0.04,
									0.332,
									0.159,
									0.053,
									0.228,
									0.01,
									0.0
								],
								"e": 0.132
							},
							{
								"h": [
									0.368,
									0.006,
									0.021,
									0.08,
									0.018,
									0.054,
									0.008,
									0.01,
									0.345,
									0.387,
									0.14,
									0.042
								],
								"e": 0.132
							},
							{
								"h": [
									0.23,
									0.227,
									0.044,
									0.076,
									0.448,
									0.086,
									0.079,
									0.235,
									0.047,
									0.028,
									0.219,
									0.027
								],
								"e": 0.137
							},
							{
								"h": [
									0.065,
									0.049,
									0.008,
									0.041,
									0.002,
									0.125,
									0.132,
									0.19,
									1.0,
									0.006,
									0.028,
									0.471
								],
								"e": 0.12
							},
							{
								"h": [
									0.01,
									0.008,
									0.035,
									0.008,
									0.024,
									0.019,
									0.137,
									0.019,
									0.144,
									0.055,
									0.065,
									0.319
								],
								"e": 0.062
							},
							{
								"h": [
									0.249,
									0.065,
									0.029,
									0.075,
									0.009,
									0.009,
									0.007,
									0.048,
									0.168,
									0.002,
									0.013,
									0.032
								],
								"e": 0.045
							},
							{
								"h": [
									0.072,
									0.002,
									0.019,
									0.006,
									0.089,
									0.01,
									0.007,
									0.304,
									0.001,
									0.017,
									0.004,
									0.095
								],
								"e": 0.028
							},
							{
								"h": [
									0.344,
									0.131,
									0.012,
									0.069,
									0.026,
									0.01,
									0.005,
									0.014,
									0.281,
									0.009,
									0.502,
									0.04
								],
								"e": 0.057
							}
						],
						[
							{
								"h": [
									0.93,
									0.0,
									0.093,
									0.009,
									0.185,
									0.0,
									0.884,
									0.571,
									0.012,
									0.589,
									0.22,
									0.826
								],
								"e": 0.265
							},
							{
								"h": [
									0.596,
									0.162,
									1.0,
									0.048,
									0.487,
									0.067,
									0.087,
									0.413,
									0.261,
									0.194,
									0.971,
									0.073
								],
								"e": 0.429
							},
							{
								"h": [
									0.408,
									0.016,
									0.142,
									0.135,
									0.442,
									0.081,
									0.116,
									0.547,
									0.322,
									0.492,
									0.992,
									0.075
								],
								"e": 0.374
							},
							{
								"h": [
									0.002,
									0.114,
									0.047,
									0.664,
									0.041,
									0.088,
									0.263,
									0.078,
									0.048,
									0.208,
									0.202,
									0.792
								],
								"e": 0.396
							},
							{
								"h": [
									0.307,
									0.031,
									0.072,
									0.275,
									0.025,
									0.116,
									0.234,
									0.107,
									0.056,
									0.268,
									0.009,
									0.0
								],
								"e": 0.147
							},
							{
								"h": [
									0.012,
									0.0,
									0.067,
									0.088,
									0.018,
									0.0,
									0.002,
									0.003,
									0.007,
									0.057,
									0.132,
									0.045
								],
								"e": 0.034
							},
							{
								"h": [
									0.236,
									0.258,
									0.021,
									0.059,
									0.38,
									0.101,
									0.025,
									1.0,
									0.042,
									0.0,
									0.16,
									0.059
								],
								"e": 0.2
							},
							{
								"h": [
									0.093,
									0.181,
									0.0,
									0.022,
									0.006,
									0.106,
									0.193,
									0.671,
									0.901,
									0.007,
									0.0,
									0.008
								],
								"e": 0.122
							},
							{
								"h": [
									0.009,
									0.008,
									0.045,
									0.006,
									0.13,
									0.029,
									1.0,
									0.145,
									0.365,
									0.065,
									0.045,
									0.248
								],
								"e": 0.152
							},
							{
								"h": [
									0.269,
									0.036,
									0.111,
									0.074,
									0.01,
									0.007,
									0.008,
									0.096,
									1.0,
									0.001,
									0.011,
									0.004
								],
								"e": 0.087
							},
							{
								"h": [
									0.603,
									0.005,
									0.008,
									0.028,
									0.259,
									0.005,
									0.046,
									0.501,
									0.001,
									0.021,
									0.007,
									0.819
								],
								"e": 0.096
							},
							{
								"h": [
									0.184,
									0.129,
									0.017,
									0.046,
									0.102,
									0.008,
									0.015,
									0.024,
									0.107,
									0.01,
									0.564,
									0.029
								],
								"e": 0.057
							}
						],
						[
							{
								"h": [
									0.133,
									0.002,
									1.0,
									0.055,
									0.134,
									0.002,
									0.017,
									0.849,
									0.335,
									0.942,
									0.424,
									0.387
								],
								"e": 0.284
							},
							{
								"h": [
									0.448,
									1.0,
									0.908,
									0.055,
									0.491,
									0.398,
									0.865,
									0.291,
									0.342,
									0.084,
									0.984,
									0.492
								],
								"e": 0.711
							},
							{
								"h": [
									0.61,
									0.046,
									0.02,
									0.112,
									0.422,
									1.0,
									1.0,
									0.539,
									0.594,
									0.109,
									0.726,
									0.076
								],
								"e": 0.609
							},
							{
								"h": [
									0.055,
									1.0,
									0.298,
									0.405,
									0.12,
									0.135,
									0.286,
									0.045,
									1.0,
									0.738,
									0.284,
									0.796
								],
								"e": 0.997
							},
							{
								"h": [
									0.126,
									1.0,
									0.234,
									0.089,
									0.097,
									0.238,
									0.382,
									0.457,
									0.05,
									0.363,
									0.036,
									0.0
								],
								"e": 0.327
							},
							{
								"h": [
									0.003,
									0.0,
									1.0,
									0.342,
									0.076,
									0.0,
									0.001,
									0.014,
									0.004,
									0.129,
									0.222,
									0.069
								],
								"e": 0.172
							},
							{
								"h": [
									1.0,
									0.08,
									0.046,
									0.37,
									0.256,
									0.172,
									0.092,
									0.065,
									0.047,
									0.0,
									0.186,
									0.189
								],
								"e": 0.183
							},
							{
								"h": [
									0.03,
									0.017,
									0.0,
									0.017,
									0.005,
									0.112,
									0.031,
									0.164,
									0.038,
									0.015,
									0.0,
									0.002
								],
								"e": 0.027
							},
							{
								"h": [
									0.057,
									0.006,
									0.006,
									0.006,
									0.044,
									0.043,
									0.002,
									0.019,
									0.027,
									0.006,
									0.042,
									0.037
								],
								"e": 0.025
							},
							{
								"h": [
									0.003,
									0.006,
									0.004,
									0.063,
									0.002,
									0.002,
									0.0,
									0.03,
									0.006,
									0.0,
									0.037,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.049,
									0.001,
									0.003,
									0.018,
									0.039,
									0.032,
									0.001,
									0.011,
									0.004,
									0.031,
									0.002,
									0.006
								],
								"e": 0.014
							},
							{
								"h": [
									0.059,
									0.031,
									0.007,
									0.006,
									0.023,
									0.008,
									0.002,
									0.011,
									0.442,
									0.003,
									0.027,
									0.008
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.764,
									0.0,
									0.082,
									0.03,
									0.155,
									0.002,
									0.643,
									0.755,
									0.012,
									0.669,
									0.222,
									0.153
								],
								"e": 0.229
							},
							{
								"h": [
									0.674,
									0.225,
									0.987,
									0.046,
									0.62,
									0.014,
									0.056,
									0.044,
									0.356,
									0.277,
									0.986,
									0.028
								],
								"e": 0.43
							},
							{
								"h": [
									0.972,
									0.005,
									1.0,
									1.0,
									1.0,
									0.238,
									0.147,
									0.922,
									0.407,
									1.0,
									0.895,
									0.08
								],
								"e": 1.0
							},
							{
								"h": [
									0.0,
									0.265,
									1.0,
									0.588,
									0.038,
									0.076,
									1.0,
									0.195,
									0.168,
									0.82,
									0.12,
									0.916
								],
								"e": 0.976
							},
							{
								"h": [
									1.0,
									0.311,
									0.014,
									1.0,
									0.054,
									0.433,
									0.34,
									0.41,
									0.006,
									0.121,
									0.009,
									0.0
								],
								"e": 0.353
							},
							{
								"h": [
									0.001,
									0.0,
									0.157,
									0.4,
									0.157,
									0.001,
									0.055,
									0.122,
									0.016,
									0.187,
									0.286,
									0.034
								],
								"e": 0.135
							},
							{
								"h": [
									0.889,
									0.017,
									0.016,
									0.167,
									0.037,
									0.174,
									0.062,
									0.078,
									0.941,
									0.0,
									0.022,
									0.327
								],
								"e": 0.202
							},
							{
								"h": [
									0.145,
									0.004,
									0.0,
									0.025,
									0.004,
									0.052,
									0.008,
									0.003,
									0.118,
									0.015,
									0.0,
									0.004
								],
								"e": 0.03
							},
							{
								"h": [
									0.029,
									0.001,
									0.002,
									0.005,
									0.075,
									0.034,
									0.0,
									0.012,
									0.03,
									0.001,
									0.017,
									0.031
								],
								"e": 0.02
							},
							{
								"h": [
									0.015,
									0.005,
									0.016,
									0.016,
									0.0,
									0.003,
									0.0,
									0.002,
									0.01,
									0.0,
									0.006,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.008,
									0.0,
									0.0,
									0.003,
									0.012,
									0.008,
									0.002,
									0.003,
									0.0,
									0.018,
									0.0,
									0.011
								],
								"e": 0.005
							},
							{
								"h": [
									0.128,
									0.004,
									0.001,
									0.001,
									0.003,
									0.001,
									0.0,
									0.002,
									0.042,
									0.001,
									0.002,
									0.0
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.557,
									0.0,
									0.213,
									0.018,
									0.093,
									0.002,
									0.12,
									0.099,
									0.019,
									0.594,
									0.14,
									0.162
								],
								"e": 0.108
							},
							{
								"h": [
									1.0,
									0.146,
									0.755,
									0.037,
									0.453,
									0.051,
									0.153,
									0.09,
									0.22,
									0.12,
									0.875,
									0.121
								],
								"e": 0.422
							},
							{
								"h": [
									0.358,
									0.003,
									0.524,
									0.123,
									0.785,
									0.379,
									0.068,
									0.579,
									0.982,
									0.95,
									0.868,
									0.092
								],
								"e": 0.675
							},
							{
								"h": [
									0.001,
									0.121,
									0.26,
									1.0,
									0.042,
									0.046,
									0.271,
									1.0,
									0.289,
									0.544,
									0.175,
									1.0
								],
								"e": 0.95
							},
							{
								"h": [
									0.231,
									0.152,
									0.007,
									0.853,
									0.039,
									0.133,
									0.335,
									0.425,
									0.005,
									0.088,
									0.003,
									1.0
								],
								"e": 0.474
							},
							{
								"h": [
									0.001,
									0.0,
									0.152,
									0.143,
									0.061,
									0.0,
									0.588,
									0.119,
									0.004,
									0.085,
									0.202,
									0.029
								],
								"e": 0.153
							},
							{
								"h": [
									0.621,
									0.029,
									0.01,
									0.118,
									0.041,
									0.174,
									0.083,
									0.06,
									0.828,
									0.0,
									0.018,
									0.693
								],
								"e": 0.209
							},
							{
								"h": [
									0.08,
									0.003,
									0.0,
									0.009,
									0.001,
									0.172,
									0.005,
									0.001,
									0.142,
									0.001,
									0.0,
									0.0
								],
								"e": 0.028
							},
							{
								"h": [
									0.003,
									0.0,
									0.005,
									0.001,
									0.098,
									0.049,
									0.001,
									0.115,
									0.031,
									0.001,
									0.003,
									0.018
								],
								"e": 0.029
							},
							{
								"h": [
									0.012,
									0.0,
									0.06,
									0.046,
									0.0,
									0.006,
									0.0,
									0.002,
									0.013,
									0.0,
									0.02,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.027,
									0.0,
									0.0,
									0.002,
									0.007,
									0.017,
									0.001,
									0.0,
									0.0,
									0.045,
									0.0,
									0.004
								],
								"e": 0.008
							},
							{
								"h": [
									0.162,
									0.004,
									0.001,
									0.001,
									0.002,
									0.001,
									0.0,
									0.002,
									0.08,
									0.0,
									0.002,
									0.0
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.048,
									1.0,
									0.28,
									1.0,
									1.0,
									0.369,
									0.017,
									1.0,
									1.0,
									0.916,
									1.0,
									0.077
								],
								"e": 1.0
							},
							{
								"h": [
									0.254,
									0.475,
									0.967,
									0.056,
									0.549,
									1.0,
									0.295,
									0.224,
									0.316,
									0.079,
									0.957,
									1.0
								],
								"e": 0.618
							},
							{
								"h": [
									0.86,
									0.025,
									0.058,
									0.285,
									0.214,
									0.671,
									0.336,
									0.787,
									1.0,
									0.086,
									1.0,
									0.104
								],
								"e": 0.563
							},
							{
								"h": [
									0.294,
									0.071,
									0.102,
									0.301,
									0.297,
									0.047,
									0.078,
									0.018,
									0.334,
									0.237,
									0.494,
									0.245
								],
								"e": 0.417
							},
							{
								"h": [
									0.127,
									0.162,
									0.057,
									0.053,
									0.213,
									0.096,
									0.347,
									1.0,
									0.029,
									0.076,
									0.032,
									0.0
								],
								"e": 0.191
							},
							{
								"h": [
									0.017,
									0.001,
									0.168,
									0.319,
									0.025,
									0.025,
									0.058,
									0.006,
									0.02,
									0.16,
									0.161,
									0.27
								],
								"e": 0.108
							},
							{
								"h": [
									0.369,
									0.012,
									0.016,
									0.063,
									0.087,
									0.252,
									0.959,
									0.028,
									0.229,
									0.001,
									0.021,
									0.105
								],
								"e": 0.159
							},
							{
								"h": [
									0.022,
									0.004,
									0.0,
									0.055,
									0.001,
									0.025,
									0.036,
									0.009,
									0.032,
									0.008,
									0.007,
									0.007
								],
								"e": 0.017
							},
							{
								"h": [
									0.102,
									0.011,
									0.005,
									0.013,
									0.004,
									0.009,
									0.006,
									0.031,
									0.026,
									0.052,
									0.059,
									0.11
								],
								"e": 0.034
							},
							{
								"h": [
									0.005,
									0.018,
									0.011,
									0.098,
									0.006,
									0.021,
									0.001,
									0.033,
									0.013,
									0.0,
									0.026,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.178,
									0.003,
									0.007,
									0.009,
									0.009,
									0.015,
									0.001,
									0.029,
									0.005,
									0.011,
									0.005,
									0.039
								],
								"e": 0.016
							},
							{
								"h": [
									0.14,
									0.175,
									0.023,
									0.017,
									0.067,
									0.014,
									0.007,
									0.04,
									0.69,
									0.004,
									0.078,
									0.059
								],
								"e": 0.048
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Darth",
					"o": " Vader",
					"t": " Vader",
					"r": [
						{
							"p": " Vader",
							"b": 0.984,
							"k": {
								" Vader": 0.984,
								" Darth": 0.015,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 0.998,
							"k": {
								" Vader": 0.998,
								" Darth": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 1.0,
							"k": {
								" Vader": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 1.0,
							"k": {
								" Vader": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 0.999,
							"k": {
								" Vader": 0.999,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 1.0,
							"k": {
								" Vader": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 1.0,
							"k": {
								" Vader": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 1.0,
							"k": {
								" Vader": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 1.0,
							"k": {
								" Vader": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 1.0,
							"k": {
								" Vader": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 0.999,
							"k": {
								" Vader": 0.999,
								" Maul": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 0.953,
							"k": {
								" Vader": 0.953,
								" Maul": 0.03,
								" Sid": 0.005,
								" Bane": 0.003,
								" Mal": 0.001,
								" Plague": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						}
					],
					"a": [
						[
							{
								"h": [
									0.708,
									0.0,
									0.705,
									0.0,
									0.012,
									0.001,
									1.0,
									0.164,
									0.795,
									0.722,
									0.255,
									0.266
								],
								"e": 0.123
							},
							{
								"h": [
									0.248,
									0.043,
									0.131,
									1.0,
									1.0,
									0.406,
									1.0,
									1.0,
									1.0,
									1.0,
									0.031,
									0.041
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.248,
									0.148,
									1.0,
									0.082,
									0.312,
									1.0,
									0.349,
									0.242,
									0.083,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.524,
									0.203,
									0.395,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.846,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.578,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.005,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.559,
									0.0,
									0.159,
									0.0,
									0.0,
									0.0,
									0.501,
									0.032,
									0.614,
									0.518,
									0.166,
									0.311
								],
								"e": 0.064
							},
							{
								"h": [
									0.023,
									0.008,
									0.087,
									0.007,
									0.126,
									0.101,
									0.249,
									0.3,
									0.338,
									0.199,
									0.093,
									0.033
								],
								"e": 0.155
							},
							{
								"h": [
									0.011,
									0.016,
									0.0,
									0.001,
									0.002,
									0.0,
									0.019,
									0.108,
									0.003,
									0.0,
									0.03,
									0.003
								],
								"e": 0.015
							},
							{
								"h": [
									0.047,
									0.002,
									0.0,
									0.0,
									0.024,
									0.011,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.0,
									0.054,
									0.0,
									0.034,
									0.0,
									0.002,
									0.001,
									0.006,
									0.001,
									0.037,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.001,
									0.002,
									0.009,
									0.0,
									0.001,
									0.001,
									0.0,
									0.001,
									0.01,
									0.096,
									0.013
								],
								"e": 0.008
							},
							{
								"h": [
									0.002,
									0.001,
									0.077,
									0.003,
									0.016,
									0.001,
									0.007,
									0.001,
									0.0,
									0.0,
									0.004,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.004,
									0.001,
									0.0,
									0.0,
									0.022,
									0.002,
									0.001,
									0.0,
									0.0,
									0.001,
									0.003
								],
								"e": 0.002
							},
							{
								"h": [
									0.009,
									0.001,
									0.011,
									0.001,
									0.0,
									0.001,
									0.012,
									0.0,
									0.001,
									0.001,
									0.002,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.002,
									0.002,
									0.001,
									0.001,
									0.004,
									0.003,
									0.001,
									0.004,
									0.011,
									0.001,
									0.0,
									0.014
								],
								"e": 0.003
							},
							{
								"h": [
									0.004,
									0.002,
									0.004,
									0.005,
									0.01,
									0.0,
									0.001,
									0.011,
									0.024,
									0.0,
									0.004,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.02,
									0.009,
									0.012,
									0.003,
									0.036,
									0.006,
									0.004,
									0.004,
									0.347,
									0.006,
									0.016,
									0.022
								],
								"e": 0.015
							}
						],
						[
							{
								"h": [
									0.247,
									0.0,
									0.152,
									0.0,
									0.001,
									0.0,
									0.151,
									0.004,
									0.325,
									0.501,
									0.213,
									0.507
								],
								"e": 0.048
							},
							{
								"h": [
									0.019,
									0.006,
									0.094,
									0.008,
									0.123,
									0.09,
									0.032,
									0.393,
									0.237,
									0.189,
									0.137,
									0.01
								],
								"e": 0.119
							},
							{
								"h": [
									0.009,
									0.002,
									0.0,
									0.001,
									0.0,
									0.0,
									0.006,
									0.141,
									0.0,
									0.0,
									0.044,
									0.006
								],
								"e": 0.012
							},
							{
								"h": [
									0.024,
									0.0,
									0.0,
									0.0,
									0.049,
									0.003,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.0,
									0.016,
									0.0,
									0.005,
									0.0,
									0.001,
									0.017,
									0.057,
									0.0,
									0.005,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.002,
									0.0,
									0.011,
									0.0,
									0.0,
									0.001,
									0.046,
									0.043,
									0.052
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.001,
									0.031,
									0.002,
									0.02,
									0.001,
									0.015,
									0.001,
									0.0,
									0.001,
									0.018,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.013,
									0.003,
									0.0,
									0.0,
									0.022,
									0.012,
									0.003,
									0.0,
									0.0,
									0.001,
									0.016
								],
								"e": 0.004
							},
							{
								"h": [
									0.01,
									0.0,
									0.013,
									0.001,
									0.0,
									0.0,
									0.041,
									0.0,
									0.001,
									0.001,
									0.004,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.011,
									0.001,
									0.005,
									0.0,
									0.003,
									0.013,
									0.004,
									0.003,
									0.03,
									0.003,
									0.0,
									0.005
								],
								"e": 0.005
							},
							{
								"h": [
									0.015,
									0.002,
									0.004,
									0.014,
									0.009,
									0.0,
									0.012,
									0.023,
									0.005,
									0.0,
									0.004,
									0.004
								],
								"e": 0.007
							},
							{
								"h": [
									0.078,
									0.014,
									0.015,
									0.002,
									0.025,
									0.001,
									0.006,
									0.002,
									0.748,
									0.002,
									0.017,
									0.007
								],
								"e": 0.022
							}
						],
						[
							{
								"h": [
									0.233,
									0.0,
									0.217,
									0.0,
									0.0,
									0.0,
									0.143,
									0.005,
									0.515,
									0.69,
									0.108,
									0.547
								],
								"e": 0.053
							},
							{
								"h": [
									0.008,
									0.004,
									0.123,
									0.008,
									0.128,
									0.615,
									0.086,
									0.333,
									0.322,
									0.391,
									0.156,
									0.002
								],
								"e": 0.222
							},
							{
								"h": [
									0.001,
									0.004,
									0.0,
									0.0,
									0.0,
									0.0,
									0.005,
									0.198,
									0.0,
									0.0,
									0.058,
									0.007
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.0,
									0.047,
									0.005,
									0.0,
									0.0,
									0.0,
									0.0,
									0.004,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.011,
									0.0,
									0.008,
									0.0,
									0.002,
									0.003,
									0.059,
									0.0,
									0.025,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.247,
									0.002,
									0.0,
									0.001,
									0.0,
									0.002,
									0.0,
									0.0,
									0.012,
									0.039,
									0.077,
									0.028
								],
								"e": 0.023
							},
							{
								"h": [
									0.001,
									0.001,
									0.022,
									0.002,
									0.085,
									0.001,
									0.01,
									0.002,
									0.0,
									0.0,
									0.098,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.01,
									0.003,
									0.0,
									0.0,
									0.03,
									0.032,
									0.015,
									0.0,
									0.0,
									0.001,
									0.207
								],
								"e": 0.02
							},
							{
								"h": [
									0.011,
									0.002,
									0.024,
									0.0,
									0.0,
									0.0,
									0.08,
									0.0,
									0.001,
									0.001,
									0.004,
									0.007
								],
								"e": 0.007
							},
							{
								"h": [
									0.009,
									0.002,
									0.006,
									0.0,
									0.001,
									0.01,
									0.003,
									0.007,
									0.072,
									0.001,
									0.0,
									0.037
								],
								"e": 0.009
							},
							{
								"h": [
									0.022,
									0.004,
									0.011,
									0.016,
									0.034,
									0.0,
									0.009,
									0.025,
									0.011,
									0.0,
									0.005,
									0.009
								],
								"e": 0.011
							},
							{
								"h": [
									0.031,
									0.012,
									0.032,
									0.001,
									0.049,
									0.004,
									0.008,
									0.005,
									0.328,
									0.007,
									0.023,
									0.006
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.668,
									0.0,
									0.117,
									0.0,
									0.0,
									0.0,
									0.216,
									0.025,
									0.422,
									0.355,
									0.144,
									0.59
								],
								"e": 0.054
							},
							{
								"h": [
									0.024,
									0.013,
									0.102,
									0.008,
									0.111,
									0.084,
									0.325,
									0.266,
									0.396,
									0.19,
									0.125,
									0.055
								],
								"e": 0.173
							},
							{
								"h": [
									0.006,
									0.036,
									0.0,
									0.0,
									0.0,
									0.0,
									0.075,
									0.09,
									0.001,
									0.0,
									0.024,
									0.004
								],
								"e": 0.024
							},
							{
								"h": [
									0.014,
									0.001,
									0.0,
									0.0,
									0.019,
									0.012,
									0.0,
									0.0,
									0.0,
									0.001,
									0.004,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.001,
									0.046,
									0.0,
									0.021,
									0.0,
									0.001,
									0.003,
									0.034,
									0.001,
									0.031,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.049,
									0.0,
									0.001,
									0.006,
									0.001,
									0.0,
									0.0,
									0.0,
									0.001,
									0.019,
									0.163,
									0.015
								],
								"e": 0.014
							},
							{
								"h": [
									0.003,
									0.001,
									0.035,
									0.004,
									0.035,
									0.002,
									0.008,
									0.001,
									0.0,
									0.0,
									0.078,
									0.001
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.01,
									0.0,
									0.0,
									0.0,
									0.052,
									0.008,
									0.047,
									0.0,
									0.0,
									0.0,
									0.007
								],
								"e": 0.007
							},
							{
								"h": [
									0.012,
									0.002,
									0.009,
									0.004,
									0.0,
									0.002,
									0.051,
									0.0,
									0.002,
									0.002,
									0.006,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.001,
									0.014,
									0.001,
									0.0,
									0.012,
									0.01,
									0.0,
									0.0,
									0.014
								],
								"e": 0.004
							},
							{
								"h": [
									0.004,
									0.001,
									0.003,
									0.013,
									0.009,
									0.0,
									0.001,
									0.01,
									0.01,
									0.0,
									0.003,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.012,
									0.002,
									0.012,
									0.003,
									0.025,
									0.003,
									0.004,
									0.003,
									0.18,
									0.002,
									0.019,
									0.019
								],
								"e": 0.011
							}
						],
						[
							{
								"h": [
									0.491,
									0.0,
									0.045,
									0.0,
									0.0,
									0.0,
									0.319,
									0.008,
									0.271,
									0.232,
									0.12,
									0.164
								],
								"e": 0.036
							},
							{
								"h": [
									0.014,
									0.008,
									0.132,
									0.008,
									0.124,
									0.16,
									0.036,
									0.144,
									0.184,
									0.122,
									0.166,
									0.004
								],
								"e": 0.102
							},
							{
								"h": [
									0.008,
									0.005,
									0.0,
									0.0,
									0.0,
									0.0,
									0.008,
									0.11,
									0.0,
									0.0,
									0.034,
									0.005
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.0,
									0.008,
									0.009,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.0,
									0.007,
									0.0,
									0.009,
									0.0,
									0.001,
									0.014,
									0.024,
									0.002,
									0.013,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.002,
									0.0,
									0.001,
									0.0,
									0.001,
									0.0,
									0.015,
									0.018,
									0.011
								],
								"e": 0.003
							},
							{
								"h": [
									0.002,
									0.0,
									0.02,
									0.001,
									0.007,
									0.001,
									0.008,
									0.002,
									0.0,
									0.0,
									0.007,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.004,
									0.001,
									0.0,
									0.0,
									0.056,
									0.003,
									0.006,
									0.0,
									0.0,
									0.0,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.006,
									0.0,
									0.003,
									0.001,
									0.0,
									0.0,
									0.004,
									0.0,
									0.001,
									0.001,
									0.001,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.01,
									0.001,
									0.005,
									0.0,
									0.001,
									0.003,
									0.003,
									0.002,
									0.019,
									0.001,
									0.0,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.002,
									0.001,
									0.003,
									0.005,
									0.0,
									0.005,
									0.003,
									0.0,
									0.0,
									0.001,
									0.003
								],
								"e": 0.002
							},
							{
								"h": [
									0.033,
									0.001,
									0.002,
									0.0,
									0.01,
									0.001,
									0.002,
									0.002,
									0.378,
									0.001,
									0.003,
									0.001
								],
								"e": 0.009
							}
						],
						[
							{
								"h": [
									0.134,
									0.0,
									0.093,
									0.0,
									0.0,
									0.0,
									0.222,
									0.011,
									0.17,
									0.323,
									0.108,
									0.167
								],
								"e": 0.029
							},
							{
								"h": [
									0.009,
									0.003,
									0.098,
									0.007,
									0.097,
									0.131,
									0.284,
									0.306,
									0.18,
									0.111,
									0.14,
									0.001
								],
								"e": 0.127
							},
							{
								"h": [
									0.001,
									0.003,
									0.0,
									0.0,
									0.0,
									0.0,
									0.006,
									0.093,
									0.0,
									0.0,
									0.032,
									0.004
								],
								"e": 0.008
							},
							{
								"h": [
									0.053,
									0.0,
									0.0,
									0.0,
									0.007,
									0.005,
									0.0,
									0.0,
									0.0,
									0.0,
									0.001,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.0,
									0.009,
									0.001,
									0.008,
									0.0,
									0.001,
									0.001,
									0.025,
									0.002,
									0.014,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.003,
									0.0,
									0.0,
									0.002,
									0.0,
									0.001,
									0.0,
									0.0,
									0.002,
									0.008,
									0.016,
									0.012
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.0,
									0.013,
									0.001,
									0.005,
									0.001,
									0.006,
									0.001,
									0.0,
									0.0,
									0.014,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.003,
									0.001,
									0.0,
									0.0,
									0.137,
									0.001,
									0.003,
									0.0,
									0.0,
									0.0,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.006,
									0.0,
									0.003,
									0.0,
									0.0,
									0.001,
									0.012,
									0.0,
									0.001,
									0.001,
									0.001,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.004,
									0.0,
									0.006,
									0.0,
									0.001,
									0.003,
									0.0,
									0.001,
									0.032,
									0.0,
									0.0,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.001,
									0.001,
									0.002,
									0.008,
									0.0,
									0.001,
									0.003,
									0.001,
									0.0,
									0.0,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.029,
									0.003,
									0.001,
									0.001,
									0.013,
									0.001,
									0.001,
									0.002,
									0.632,
									0.002,
									0.003,
									0.003
								],
								"e": 0.012
							}
						],
						[
							{
								"h": [
									0.443,
									0.0,
									0.095,
									0.0,
									0.0,
									0.0,
									0.325,
									0.011,
									0.492,
									0.434,
									0.152,
									0.269
								],
								"e": 0.048
							},
							{
								"h": [
									0.026,
									0.014,
									0.118,
									0.009,
									0.1,
									0.098,
									0.139,
									0.247,
									0.331,
									0.147,
									0.149,
									0.035
								],
								"e": 0.136
							},
							{
								"h": [
									0.006,
									0.041,
									0.0,
									0.0,
									0.0,
									0.0,
									0.03,
									0.144,
									0.001,
									0.0,
									0.028,
									0.004
								],
								"e": 0.024
							},
							{
								"h": [
									0.008,
									0.001,
									0.0,
									0.0,
									0.027,
									0.006,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.0,
									0.037,
									0.0,
									0.042,
									0.0,
									0.002,
									0.001,
									0.012,
									0.001,
									0.03,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.009,
									0.005,
									0.003,
									0.006,
									0.0,
									0.011,
									0.001,
									0.0,
									0.002,
									0.007,
									0.044,
									0.019
								],
								"e": 0.007
							},
							{
								"h": [
									0.003,
									0.0,
									0.049,
									0.001,
									0.008,
									0.001,
									0.006,
									0.0,
									0.0,
									0.0,
									0.006,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.023,
									0.001,
									0.002,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.018,
									0.002,
									0.003,
									0.001,
									0.0,
									0.001,
									0.016,
									0.0,
									0.0,
									0.002,
									0.001,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.001,
									0.0,
									0.003,
									0.0,
									0.005,
									0.002,
									0.0,
									0.0,
									0.009
								],
								"e": 0.002
							},
							{
								"h": [
									0.002,
									0.001,
									0.001,
									0.005,
									0.004,
									0.0,
									0.0,
									0.009,
									0.01,
									0.0,
									0.001,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.017,
									0.011,
									0.005,
									0.002,
									0.029,
									0.004,
									0.002,
									0.002,
									0.428,
									0.001,
									0.014,
									0.01
								],
								"e": 0.013
							}
						],
						[
							{
								"h": [
									0.425,
									0.001,
									0.198,
									0.0,
									0.002,
									0.001,
									0.114,
									0.002,
									0.277,
									1.0,
									0.311,
									0.36
								],
								"e": 0.062
							},
							{
								"h": [
									0.023,
									0.016,
									0.205,
									0.014,
									0.158,
									0.106,
									0.024,
									0.784,
									0.439,
									0.316,
									0.259,
									0.024
								],
								"e": 0.204
							},
							{
								"h": [
									0.005,
									0.008,
									0.0,
									0.001,
									0.0,
									0.0,
									0.025,
									0.287,
									0.001,
									0.001,
									0.112,
									0.013
								],
								"e": 0.027
							},
							{
								"h": [
									0.041,
									0.001,
									0.0,
									0.0,
									0.195,
									0.008,
									0.0,
									0.0,
									0.0,
									0.001,
									0.01,
									0.001
								],
								"e": 0.028
							},
							{
								"h": [
									0.0,
									0.0,
									0.019,
									0.003,
									0.019,
									0.0,
									0.003,
									0.082,
									0.185,
									0.002,
									0.046,
									0.0
								],
								"e": 0.033
							},
							{
								"h": [
									0.036,
									0.005,
									0.0,
									0.002,
									0.0,
									0.079,
									0.002,
									0.0,
									0.012,
									0.041,
									0.042,
									0.041
								],
								"e": 0.021
							},
							{
								"h": [
									0.002,
									0.003,
									0.023,
									0.003,
									0.069,
									0.003,
									0.027,
									0.005,
									0.0,
									0.001,
									0.135,
									0.001
								],
								"e": 0.019
							},
							{
								"h": [
									0.0,
									0.034,
									0.005,
									0.001,
									0.0,
									0.053,
									0.05,
									0.03,
									0.0,
									0.0,
									0.001,
									0.072
								],
								"e": 0.016
							},
							{
								"h": [
									0.034,
									0.004,
									0.011,
									0.004,
									0.0,
									0.001,
									0.606,
									0.0,
									0.002,
									0.009,
									0.031,
									0.008
								],
								"e": 0.027
							},
							{
								"h": [
									0.018,
									0.01,
									0.003,
									0.0,
									0.007,
									0.011,
									0.006,
									0.007,
									0.084,
									0.002,
									0.0,
									0.01
								],
								"e": 0.01
							},
							{
								"h": [
									0.024,
									0.003,
									0.005,
									0.031,
									0.029,
									0.0,
									0.032,
									0.038,
									0.007,
									0.0,
									0.016,
									0.014
								],
								"e": 0.016
							},
							{
								"h": [
									0.034,
									0.022,
									0.023,
									0.003,
									0.066,
									0.004,
									0.009,
									0.003,
									0.341,
									0.009,
									0.051,
									0.023
								],
								"e": 0.024
							}
						],
						[
							{
								"h": [
									0.613,
									0.0,
									0.098,
									0.0,
									0.0,
									0.0,
									0.183,
									0.012,
									0.424,
									0.346,
									0.133,
									0.674
								],
								"e": 0.052
							},
							{
								"h": [
									0.033,
									0.028,
									0.155,
									0.013,
									0.117,
									0.085,
									0.28,
									0.298,
									0.394,
									0.15,
									0.204,
									0.055
								],
								"e": 0.178
							},
							{
								"h": [
									0.009,
									0.035,
									0.0,
									0.0,
									0.001,
									0.0,
									0.114,
									0.133,
									0.001,
									0.0,
									0.04,
									0.006
								],
								"e": 0.032
							},
							{
								"h": [
									0.013,
									0.002,
									0.0,
									0.0,
									0.021,
									0.01,
									0.0,
									0.0,
									0.0,
									0.001,
									0.006,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.003,
									0.037,
									0.0,
									0.021,
									0.001,
									0.003,
									0.005,
									0.033,
									0.001,
									0.038,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.292,
									0.008,
									0.003,
									0.006,
									0.001,
									0.024,
									0.001,
									0.0,
									0.018,
									0.024,
									0.127,
									0.019
								],
								"e": 0.03
							},
							{
								"h": [
									0.009,
									0.001,
									0.028,
									0.003,
									0.029,
									0.005,
									0.014,
									0.001,
									0.0,
									0.004,
									0.098,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.033,
									0.001,
									0.001,
									0.0,
									0.016,
									0.005,
									0.082,
									0.0,
									0.001,
									0.003,
									0.007
								],
								"e": 0.01
							},
							{
								"h": [
									0.012,
									0.003,
									0.003,
									0.005,
									0.0,
									0.002,
									0.02,
									0.002,
									0.001,
									0.003,
									0.03,
									0.003
								],
								"e": 0.006
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.002,
									0.005,
									0.001,
									0.0,
									0.01,
									0.015,
									0.001,
									0.0,
									0.006
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.0,
									0.002,
									0.024,
									0.012,
									0.0,
									0.0,
									0.006,
									0.005,
									0.0,
									0.003,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.005,
									0.002,
									0.006,
									0.002,
									0.013,
									0.002,
									0.002,
									0.002,
									0.096,
									0.002,
									0.01,
									0.014
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.418,
									0.0,
									0.362,
									0.0,
									0.001,
									0.0,
									0.348,
									0.008,
									0.383,
									0.342,
									0.087,
									0.148
								],
								"e": 0.05
							},
							{
								"h": [
									0.021,
									0.018,
									0.237,
									0.014,
									0.133,
									0.143,
									0.052,
									0.188,
									0.334,
									0.111,
									0.273,
									0.003
								],
								"e": 0.131
							},
							{
								"h": [
									0.011,
									0.002,
									0.0,
									0.001,
									0.0,
									0.0,
									0.018,
									0.277,
									0.0,
									0.001,
									0.084,
									0.011
								],
								"e": 0.023
							},
							{
								"h": [
									0.0,
									0.005,
									0.0,
									0.0,
									0.013,
									0.011,
									0.0,
									0.0,
									0.0,
									0.001,
									0.008,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.001,
									0.016,
									0.003,
									0.028,
									0.001,
									0.006,
									0.043,
									0.068,
									0.006,
									0.012,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.005,
									0.0,
									0.028,
									0.001,
									0.003,
									0.004,
									0.022,
									0.085,
									0.018
								],
								"e": 0.012
							},
							{
								"h": [
									0.005,
									0.0,
									0.02,
									0.003,
									0.015,
									0.006,
									0.009,
									0.003,
									0.0,
									0.001,
									0.003,
									0.003
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.006,
									0.002,
									0.0,
									0.0,
									0.151,
									0.001,
									0.012,
									0.0,
									0.0,
									0.001,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.005,
									0.0,
									0.003,
									0.002,
									0.0,
									0.0,
									0.006,
									0.0,
									0.002,
									0.001,
									0.001,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.018,
									0.001,
									0.009,
									0.001,
									0.001,
									0.003,
									0.003,
									0.003,
									0.089,
									0.001,
									0.0,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.01,
									0.001,
									0.001,
									0.002,
									0.008,
									0.0,
									0.004,
									0.001,
									0.001,
									0.0,
									0.001,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.014,
									0.002,
									0.001,
									0.001,
									0.007,
									0.002,
									0.001,
									0.002,
									0.203,
									0.002,
									0.002,
									0.001
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.43,
									0.0,
									0.089,
									0.0,
									0.0,
									0.0,
									0.312,
									0.011,
									0.517,
									0.439,
									0.15,
									0.292
								],
								"e": 0.048
							},
							{
								"h": [
									0.039,
									0.029,
									0.169,
									0.013,
									0.109,
									0.108,
									0.13,
									0.275,
									0.334,
									0.136,
									0.214,
									0.035
								],
								"e": 0.15
							},
							{
								"h": [
									0.011,
									0.045,
									0.0,
									0.0,
									0.0,
									0.0,
									0.047,
									0.183,
									0.001,
									0.0,
									0.047,
									0.006
								],
								"e": 0.031
							},
							{
								"h": [
									0.005,
									0.005,
									0.0,
									0.0,
									0.023,
									0.006,
									0.0,
									0.0,
									0.0,
									0.0,
									0.005,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.001,
									0.041,
									0.001,
									0.029,
									0.001,
									0.006,
									0.003,
									0.013,
									0.003,
									0.024,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.0,
									0.006,
									0.012,
									0.0,
									0.0,
									0.001,
									0.0,
									0.0,
									0.005,
									0.033,
									0.016
								],
								"e": 0.005
							},
							{
								"h": [
									0.005,
									0.0,
									0.032,
									0.003,
									0.025,
									0.003,
									0.013,
									0.001,
									0.001,
									0.0,
									0.004,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.017,
									0.002,
									0.003,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.018,
									0.0,
									0.003,
									0.002,
									0.0,
									0.001,
									0.012,
									0.0,
									0.0,
									0.001,
									0.004,
									0.003
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.003,
									0.0,
									0.004,
									0.0,
									0.003,
									0.006,
									0.0,
									0.0,
									0.004
								],
								"e": 0.002
							},
							{
								"h": [
									0.003,
									0.0,
									0.0,
									0.003,
									0.006,
									0.0,
									0.0,
									0.004,
									0.004,
									0.0,
									0.001,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.023,
									0.009,
									0.003,
									0.004,
									0.019,
									0.003,
									0.002,
									0.002,
									0.298,
									0.001,
									0.011,
									0.01
								],
								"e": 0.01
							}
						],
						[
							{
								"h": [
									0.243,
									0.0,
									0.024,
									0.0,
									0.0,
									0.0,
									0.095,
									0.001,
									0.665,
									0.82,
									0.194,
									0.089
								],
								"e": 0.044
							},
							{
								"h": [
									0.033,
									0.023,
									0.237,
									0.016,
									0.161,
									1.0,
									0.146,
									0.458,
									0.309,
									0.269,
									0.331,
									0.009
								],
								"e": 0.304
							},
							{
								"h": [
									0.014,
									0.005,
									0.0,
									0.002,
									0.0,
									0.0,
									0.023,
									0.28,
									0.0,
									0.001,
									0.122,
									0.014
								],
								"e": 0.028
							},
							{
								"h": [
									0.01,
									0.002,
									0.0,
									0.0,
									0.107,
									0.012,
									0.001,
									0.0,
									0.0,
									0.001,
									0.016,
									0.002
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.001,
									0.025,
									0.005,
									0.024,
									0.001,
									0.006,
									0.081,
									0.07,
									0.005,
									0.048,
									0.0
								],
								"e": 0.024
							},
							{
								"h": [
									0.011,
									0.0,
									0.0,
									0.004,
									0.0,
									0.004,
									0.001,
									0.0,
									0.003,
									0.044,
									0.06,
									0.039
								],
								"e": 0.012
							},
							{
								"h": [
									0.004,
									0.003,
									0.027,
									0.003,
									0.052,
									0.005,
									0.02,
									0.009,
									0.0,
									0.0,
									0.079,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.008,
									0.001,
									0.0,
									0.0,
									0.098,
									0.028,
									0.035,
									0.0,
									0.0,
									0.0,
									0.021
								],
								"e": 0.01
							},
							{
								"h": [
									0.015,
									0.002,
									0.009,
									0.002,
									0.0,
									0.001,
									0.498,
									0.0,
									0.003,
									0.007,
									0.015,
									0.011
								],
								"e": 0.021
							},
							{
								"h": [
									0.031,
									0.002,
									0.009,
									0.001,
									0.002,
									0.014,
									0.007,
									0.011,
									0.14,
									0.002,
									0.0,
									0.008
								],
								"e": 0.013
							},
							{
								"h": [
									0.044,
									0.002,
									0.006,
									0.015,
									0.043,
									0.0,
									0.013,
									0.034,
									0.007,
									0.0,
									0.003,
									0.01
								],
								"e": 0.013
							},
							{
								"h": [
									0.06,
									0.012,
									0.027,
									0.003,
									0.067,
									0.003,
									0.006,
									0.008,
									0.409,
									0.001,
									0.011,
									0.014
								],
								"e": 0.021
							}
						],
						[
							{
								"h": [
									0.625,
									0.0,
									0.093,
									0.0,
									0.0,
									0.0,
									0.179,
									0.02,
									0.457,
									0.362,
									0.133,
									0.757
								],
								"e": 0.055
							},
							{
								"h": [
									0.053,
									0.064,
									0.214,
									0.017,
									0.133,
									0.076,
									0.268,
									0.315,
									0.368,
									0.121,
									0.282,
									0.057
								],
								"e": 0.19
							},
							{
								"h": [
									0.018,
									0.031,
									0.0,
									0.0,
									0.002,
									0.001,
									0.165,
									0.168,
									0.003,
									0.0,
									0.068,
									0.011
								],
								"e": 0.042
							},
							{
								"h": [
									0.011,
									0.009,
									0.0,
									0.0,
									0.022,
									0.014,
									0.0,
									0.0,
									0.001,
									0.003,
									0.013,
									0.002
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.013,
									0.052,
									0.001,
									0.016,
									0.003,
									0.01,
									0.008,
									0.029,
									0.003,
									0.038,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.222,
									0.0,
									0.013,
									0.013,
									0.003,
									0.0,
									0.001,
									0.001,
									0.01,
									0.026,
									0.09,
									0.019
								],
								"e": 0.022
							},
							{
								"h": [
									0.025,
									0.001,
									0.031,
									0.009,
									0.02,
									0.007,
									0.011,
									0.003,
									0.001,
									0.0,
									0.05,
									0.004
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.008,
									0.0,
									0.002,
									0.0,
									0.027,
									0.005,
									0.059,
									0.0,
									0.004,
									0.0,
									0.019
								],
								"e": 0.007
							},
							{
								"h": [
									0.028,
									0.006,
									0.001,
									0.008,
									0.0,
									0.004,
									0.017,
									0.003,
									0.002,
									0.001,
									0.022,
									0.007
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.006,
									0.002,
									0.001,
									0.0,
									0.035,
									0.008,
									0.0,
									0.001,
									0.005
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.0,
									0.001,
									0.009,
									0.008,
									0.001,
									0.0,
									0.003,
									0.006,
									0.001,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.005,
									0.002,
									0.005,
									0.003,
									0.011,
									0.002,
									0.001,
									0.001,
									0.111,
									0.002,
									0.007,
									0.008
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.489,
									0.0,
									0.037,
									0.0,
									0.0,
									0.0,
									0.292,
									0.01,
									0.299,
									0.248,
									0.116,
									0.202
								],
								"e": 0.036
							},
							{
								"h": [
									0.031,
									0.033,
									0.285,
									0.016,
									0.169,
									0.136,
									0.021,
									0.098,
									0.22,
									0.124,
									0.331,
									0.001
								],
								"e": 0.128
							},
							{
								"h": [
									0.035,
									0.002,
									0.0,
									0.003,
									0.003,
									0.0,
									0.025,
									0.26,
									0.002,
									0.002,
									0.091,
									0.013
								],
								"e": 0.027
							},
							{
								"h": [
									0.0,
									0.007,
									0.0,
									0.0,
									0.01,
									0.006,
									0.001,
									0.0,
									0.0,
									0.003,
									0.013,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.003,
									0.012,
									0.003,
									0.016,
									0.001,
									0.005,
									0.031,
									0.01,
									0.009,
									0.008,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.007,
									0.001,
									0.0,
									0.0,
									0.005,
									0.001,
									0.009,
									0.014,
									0.006
								],
								"e": 0.003
							},
							{
								"h": [
									0.008,
									0.0,
									0.018,
									0.005,
									0.004,
									0.006,
									0.01,
									0.002,
									0.001,
									0.0,
									0.001,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.087,
									0.003,
									0.01,
									0.0,
									0.001,
									0.0,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.008,
									0.0,
									0.001,
									0.003,
									0.0,
									0.001,
									0.002,
									0.0,
									0.001,
									0.0,
									0.003,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.006,
									0.0,
									0.003,
									0.001,
									0.0,
									0.001,
									0.0,
									0.002,
									0.01,
									0.0,
									0.0,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.001,
									0.003,
									0.0,
									0.001,
									0.001,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.03,
									0.001,
									0.0,
									0.0,
									0.002,
									0.0,
									0.0,
									0.0,
									0.08,
									0.0,
									0.002,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.134,
									0.0,
									0.084,
									0.0,
									0.001,
									0.0,
									0.212,
									0.022,
									0.185,
									0.364,
									0.107,
									0.203
								],
								"e": 0.031
							},
							{
								"h": [
									0.028,
									0.015,
									0.199,
									0.013,
									0.124,
									0.091,
									0.18,
									0.204,
									0.168,
									0.09,
									0.283,
									0.0
								],
								"e": 0.123
							},
							{
								"h": [
									0.007,
									0.002,
									0.0,
									0.0,
									0.001,
									0.0,
									0.016,
									0.184,
									0.002,
									0.001,
									0.088,
									0.011
								],
								"e": 0.019
							},
							{
								"h": [
									0.005,
									0.004,
									0.0,
									0.0,
									0.007,
									0.004,
									0.0,
									0.0,
									0.001,
									0.002,
									0.009,
									0.004
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.002,
									0.009,
									0.009,
									0.012,
									0.001,
									0.009,
									0.004,
									0.012,
									0.006,
									0.008,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.0,
									0.003,
									0.004,
									0.001,
									0.0,
									0.002,
									0.004,
									0.004,
									0.008,
									0.024,
									0.011
								],
								"e": 0.004
							},
							{
								"h": [
									0.007,
									0.0,
									0.012,
									0.004,
									0.002,
									0.006,
									0.004,
									0.001,
									0.002,
									0.0,
									0.002,
									0.008
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.111,
									0.001,
									0.001,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.005,
									0.0,
									0.003,
									0.0,
									0.001,
									0.002,
									0.003,
									0.002,
									0.001,
									0.0,
									0.001,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.002,
									0.0,
									0.004,
									0.001,
									0.0,
									0.002,
									0.0,
									0.001,
									0.012,
									0.0,
									0.0,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.001,
									0.004,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.009,
									0.0,
									0.0,
									0.0,
									0.003,
									0.0,
									0.0,
									0.0,
									0.064,
									0.0,
									0.001,
									0.001
								],
								"e": 0.002
							}
						],
						[
							{
								"h": [
									0.451,
									0.0,
									0.087,
									0.0,
									0.0,
									0.0,
									0.313,
									0.031,
									0.561,
									0.477,
									0.153,
									0.318
								],
								"e": 0.052
							},
							{
								"h": [
									0.073,
									0.068,
									0.249,
									0.018,
									0.135,
									0.151,
									0.143,
									0.254,
									0.324,
									0.121,
									0.305,
									0.045
								],
								"e": 0.182
							},
							{
								"h": [
									0.03,
									0.03,
									0.0,
									0.002,
									0.002,
									0.002,
									0.076,
									0.243,
									0.007,
									0.0,
									0.086,
									0.012
								],
								"e": 0.039
							},
							{
								"h": [
									0.004,
									0.009,
									0.0,
									0.0,
									0.018,
									0.005,
									0.0,
									0.0,
									0.002,
									0.001,
									0.011,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.007,
									0.023,
									0.001,
									0.028,
									0.001,
									0.011,
									0.005,
									0.005,
									0.003,
									0.016,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.002,
									0.001,
									0.02,
									0.013,
									0.001,
									0.004,
									0.005,
									0.0,
									0.004,
									0.006,
									0.011,
									0.019
								],
								"e": 0.006
							},
							{
								"h": [
									0.011,
									0.0,
									0.041,
									0.004,
									0.013,
									0.008,
									0.022,
									0.001,
									0.006,
									0.0,
									0.004,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.001,
									0.0,
									0.001,
									0.0,
									0.012,
									0.005,
									0.003,
									0.0,
									0.002,
									0.0,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.011,
									0.0,
									0.001,
									0.002,
									0.0,
									0.003,
									0.008,
									0.003,
									0.001,
									0.003,
									0.009,
									0.005
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.006,
									0.0,
									0.004,
									0.0,
									0.003,
									0.007,
									0.0,
									0.0,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.004,
									0.0,
									0.0,
									0.002,
									0.006,
									0.0,
									0.0,
									0.003,
									0.003,
									0.0,
									0.001,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.018,
									0.017,
									0.004,
									0.004,
									0.026,
									0.002,
									0.001,
									0.003,
									0.265,
									0.001,
									0.007,
									0.008
								],
								"e": 0.01
							}
						],
						[
							{
								"h": [
									0.324,
									0.0,
									0.102,
									0.0,
									0.0,
									0.0,
									0.346,
									0.036,
									0.536,
									0.48,
									0.149,
									0.209
								],
								"e": 0.049
							},
							{
								"h": [
									0.235,
									0.148,
									0.272,
									0.022,
									0.161,
									0.097,
									0.612,
									0.572,
									0.29,
									0.165,
									0.448,
									0.008
								],
								"e": 0.29
							},
							{
								"h": [
									0.049,
									0.015,
									0.0,
									0.058,
									0.006,
									0.002,
									0.078,
									0.268,
									0.01,
									0.004,
									0.139,
									0.022
								],
								"e": 0.054
							},
							{
								"h": [
									0.009,
									0.01,
									0.002,
									0.0,
									0.053,
									0.025,
									0.001,
									0.0,
									0.0,
									0.007,
									0.015,
									0.007
								],
								"e": 0.014
							},
							{
								"h": [
									0.002,
									0.006,
									0.018,
									0.002,
									0.008,
									0.006,
									0.014,
									0.059,
									0.034,
									0.004,
									0.015,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.01,
									0.002,
									0.003,
									0.006,
									0.006,
									0.078,
									0.002,
									0.0,
									0.03,
									0.05,
									0.036,
									0.023
								],
								"e": 0.02
							},
							{
								"h": [
									0.009,
									0.001,
									0.047,
									0.004,
									0.017,
									0.01,
									0.013,
									0.011,
									0.001,
									0.0,
									0.035,
									0.008
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.009,
									0.0,
									0.001,
									0.0,
									0.024,
									0.018,
									0.017,
									0.0,
									0.001,
									0.0,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.018,
									0.011,
									0.005,
									0.001,
									0.001,
									0.008,
									0.073,
									0.002,
									0.001,
									0.011,
									0.015,
									0.016
								],
								"e": 0.01
							},
							{
								"h": [
									0.005,
									0.005,
									0.003,
									0.004,
									0.001,
									0.007,
									0.003,
									0.015,
									0.004,
									0.001,
									0.001,
									0.028
								],
								"e": 0.006
							},
							{
								"h": [
									0.004,
									0.001,
									0.001,
									0.003,
									0.015,
									0.001,
									0.001,
									0.003,
									0.009,
									0.0,
									0.004,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.01,
									0.025,
									0.003,
									0.002,
									0.034,
									0.007,
									0.009,
									0.005,
									0.584,
									0.001,
									0.006,
									0.007
								],
								"e": 0.016
							}
						],
						[
							{
								"h": [
									0.319,
									0.0,
									0.077,
									0.0,
									0.002,
									0.0,
									0.235,
									0.01,
									1.0,
									0.454,
									0.133,
									0.165
								],
								"e": 0.05
							},
							{
								"h": [
									0.106,
									0.085,
									0.377,
									0.024,
									0.238,
									0.121,
									0.102,
									0.213,
									0.314,
									0.106,
									0.536,
									0.004
								],
								"e": 0.194
							},
							{
								"h": [
									0.057,
									0.002,
									0.0,
									0.006,
									0.004,
									0.002,
									0.056,
									0.429,
									0.008,
									0.018,
									0.296,
									0.037
								],
								"e": 0.062
							},
							{
								"h": [
									0.001,
									0.018,
									0.001,
									0.0,
									0.07,
									0.018,
									0.008,
									0.004,
									0.002,
									0.008,
									0.047,
									0.009
								],
								"e": 0.019
							},
							{
								"h": [
									0.004,
									0.006,
									0.026,
									0.027,
									0.01,
									0.003,
									0.03,
									0.075,
									0.053,
									0.018,
									0.043,
									0.0
								],
								"e": 0.026
							},
							{
								"h": [
									0.112,
									0.0,
									0.005,
									0.011,
									0.003,
									0.003,
									0.002,
									0.003,
									0.012,
									0.024,
									0.033,
									0.019
								],
								"e": 0.014
							},
							{
								"h": [
									0.011,
									0.005,
									0.013,
									0.007,
									0.092,
									0.013,
									0.023,
									0.037,
									0.001,
									0.0,
									0.207,
									0.007
								],
								"e": 0.029
							},
							{
								"h": [
									0.0,
									0.059,
									0.001,
									0.001,
									0.0,
									0.172,
									0.078,
									0.028,
									0.004,
									0.002,
									0.0,
									0.014
								],
								"e": 0.019
							},
							{
								"h": [
									0.011,
									0.001,
									0.006,
									0.011,
									0.001,
									0.003,
									0.259,
									0.003,
									0.009,
									0.013,
									0.036,
									0.042
								],
								"e": 0.019
							},
							{
								"h": [
									0.043,
									0.005,
									0.021,
									0.002,
									0.001,
									0.009,
									0.005,
									0.012,
									0.133,
									0.001,
									0.0,
									0.006
								],
								"e": 0.014
							},
							{
								"h": [
									0.035,
									0.001,
									0.003,
									0.008,
									0.057,
									0.0,
									0.008,
									0.016,
									0.004,
									0.0,
									0.009,
									0.004
								],
								"e": 0.011
							},
							{
								"h": [
									0.032,
									0.006,
									0.009,
									0.003,
									0.054,
									0.002,
									0.003,
									0.003,
									0.351,
									0.003,
									0.016,
									0.022
								],
								"e": 0.016
							}
						],
						[
							{
								"h": [
									0.693,
									0.0,
									0.098,
									0.0,
									0.0,
									0.0,
									0.189,
									0.102,
									0.518,
									0.413,
									0.141,
									0.88
								],
								"e": 0.067
							},
							{
								"h": [
									0.19,
									0.276,
									0.403,
									0.03,
									0.195,
									0.083,
									0.244,
									0.331,
									0.361,
									0.104,
									0.5,
									0.072
								],
								"e": 0.276
							},
							{
								"h": [
									0.089,
									0.019,
									0.001,
									0.006,
									0.025,
									0.017,
									0.437,
									0.297,
									0.043,
									0.008,
									0.182,
									0.029
								],
								"e": 0.104
							},
							{
								"h": [
									0.009,
									0.079,
									0.006,
									0.002,
									0.02,
									0.016,
									0.007,
									0.001,
									0.026,
									0.026,
									0.045,
									0.019
								],
								"e": 0.022
							},
							{
								"h": [
									0.002,
									0.093,
									0.058,
									0.004,
									0.018,
									0.015,
									0.034,
									0.017,
									0.017,
									0.012,
									0.024,
									0.0
								],
								"e": 0.023
							},
							{
								"h": [
									0.061,
									0.0,
									0.207,
									0.041,
									0.012,
									0.0,
									0.003,
									0.002,
									0.017,
									0.016,
									0.065,
									0.014
								],
								"e": 0.023
							},
							{
								"h": [
									0.091,
									0.004,
									0.035,
									0.016,
									0.022,
									0.016,
									0.014,
									0.004,
									0.01,
									0.0,
									0.041,
									0.016
								],
								"e": 0.018
							},
							{
								"h": [
									0.002,
									0.024,
									0.0,
									0.004,
									0.0,
									0.036,
									0.01,
									0.065,
									0.001,
									0.012,
									0.0,
									0.004
								],
								"e": 0.01
							},
							{
								"h": [
									0.018,
									0.009,
									0.001,
									0.012,
									0.002,
									0.015,
									0.021,
									0.016,
									0.004,
									0.003,
									0.046,
									0.016
								],
								"e": 0.012
							},
							{
								"h": [
									0.001,
									0.001,
									0.002,
									0.025,
									0.002,
									0.001,
									0.0,
									0.055,
									0.014,
									0.001,
									0.002,
									0.006
								],
								"e": 0.008
							},
							{
								"h": [
									0.004,
									0.0,
									0.002,
									0.013,
									0.008,
									0.002,
									0.0,
									0.004,
									0.005,
									0.002,
									0.002,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.004,
									0.002,
									0.004,
									0.004,
									0.01,
									0.002,
									0.001,
									0.001,
									0.106,
									0.002,
									0.008,
									0.01
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.476,
									0.0,
									0.371,
									0.0,
									0.005,
									0.0,
									0.363,
									0.064,
									0.453,
									0.399,
									0.088,
									0.175
								],
								"e": 0.06
							},
							{
								"h": [
									0.091,
									0.106,
									0.481,
									0.027,
									0.22,
									0.113,
									0.036,
									0.143,
									0.298,
									0.099,
									0.54,
									0.002
								],
								"e": 0.184
							},
							{
								"h": [
									0.108,
									0.001,
									0.004,
									0.03,
									0.009,
									0.006,
									0.066,
									0.466,
									0.018,
									0.062,
									0.301,
									0.046
								],
								"e": 0.085
							},
							{
								"h": [
									0.0,
									0.112,
									0.007,
									0.001,
									0.009,
									0.016,
									0.013,
									0.036,
									0.007,
									0.026,
									0.045,
									0.101
								],
								"e": 0.03
							},
							{
								"h": [
									0.005,
									0.071,
									0.028,
									0.058,
									0.019,
									0.027,
									0.059,
									0.126,
									0.02,
									0.063,
									0.007,
									0.0
								],
								"e": 0.038
							},
							{
								"h": [
									0.0,
									0.0,
									0.005,
									0.05,
									0.007,
									0.002,
									0.004,
									0.021,
									0.006,
									0.011,
									0.026,
									0.015
								],
								"e": 0.011
							},
							{
								"h": [
									0.031,
									0.0,
									0.021,
									0.01,
									0.007,
									0.016,
									0.005,
									0.006,
									0.006,
									0.0,
									0.002,
									0.018
								],
								"e": 0.009
							},
							{
								"h": [
									0.001,
									0.003,
									0.0,
									0.002,
									0.001,
									0.12,
									0.001,
									0.005,
									0.001,
									0.002,
									0.0,
									0.001
								],
								"e": 0.005
							},
							{
								"h": [
									0.006,
									0.0,
									0.001,
									0.002,
									0.002,
									0.007,
									0.006,
									0.014,
									0.004,
									0.001,
									0.006,
									0.002
								],
								"e": 0.004
							},
							{
								"h": [
									0.009,
									0.001,
									0.01,
									0.007,
									0.001,
									0.003,
									0.001,
									0.006,
									0.025,
									0.001,
									0.0,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.008,
									0.001,
									0.0,
									0.001,
									0.006,
									0.0,
									0.002,
									0.001,
									0.0,
									0.001,
									0.0,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.011,
									0.002,
									0.001,
									0.0,
									0.002,
									0.001,
									0.0,
									0.001,
									0.088,
									0.001,
									0.002,
									0.0
								],
								"e": 0.003
							}
						],
						[
							{
								"h": [
									0.503,
									0.0,
									0.091,
									0.0,
									0.001,
									0.0,
									0.336,
									0.138,
									0.625,
									0.526,
									0.157,
									0.345
								],
								"e": 0.064
							},
							{
								"h": [
									0.24,
									0.253,
									0.4,
									0.029,
									0.186,
									0.113,
									0.118,
									0.284,
									0.307,
									0.104,
									0.472,
									0.044
								],
								"e": 0.249
							},
							{
								"h": [
									0.122,
									0.034,
									0.003,
									0.024,
									0.02,
									0.032,
									0.183,
									0.373,
									0.069,
									0.006,
									0.209,
									0.032
								],
								"e": 0.101
							},
							{
								"h": [
									0.002,
									0.114,
									0.019,
									0.004,
									0.013,
									0.011,
									0.006,
									0.005,
									0.043,
									0.009,
									0.034,
									0.018
								],
								"e": 0.021
							},
							{
								"h": [
									0.007,
									0.098,
									0.053,
									0.018,
									0.03,
									0.026,
									0.077,
									0.011,
									0.008,
									0.032,
									0.02,
									0.0
								],
								"e": 0.031
							},
							{
								"h": [
									0.0,
									0.0,
									0.067,
									0.099,
									0.008,
									0.0,
									0.006,
									0.001,
									0.001,
									0.005,
									0.018,
									0.024
								],
								"e": 0.014
							},
							{
								"h": [
									0.057,
									0.001,
									0.052,
									0.011,
									0.026,
									0.021,
									0.015,
									0.007,
									0.037,
									0.0,
									0.001,
									0.017
								],
								"e": 0.017
							},
							{
								"h": [
									0.005,
									0.0,
									0.0,
									0.003,
									0.0,
									0.036,
									0.004,
									0.002,
									0.0,
									0.007,
									0.0,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.02,
									0.0,
									0.001,
									0.003,
									0.001,
									0.021,
									0.007,
									0.04,
									0.002,
									0.004,
									0.023,
									0.02
								],
								"e": 0.01
							},
							{
								"h": [
									0.001,
									0.002,
									0.001,
									0.052,
									0.001,
									0.008,
									0.0,
									0.013,
									0.007,
									0.0,
									0.003,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.003,
									0.0,
									0.0,
									0.001,
									0.009,
									0.001,
									0.0,
									0.003,
									0.002,
									0.001,
									0.001,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.017,
									0.015,
									0.002,
									0.006,
									0.026,
									0.002,
									0.001,
									0.003,
									0.238,
									0.0,
									0.007,
									0.01
								],
								"e": 0.01
							}
						],
						[
							{
								"h": [
									0.425,
									0.0,
									0.137,
									0.0,
									0.004,
									0.0,
									0.327,
									0.061,
									0.153,
									0.095,
									0.197,
									0.54
								],
								"e": 0.05
							},
							{
								"h": [
									0.263,
									0.216,
									0.663,
									0.038,
									0.369,
									0.106,
									0.171,
									0.584,
									0.182,
									0.061,
									0.758,
									0.08
								],
								"e": 0.326
							},
							{
								"h": [
									0.231,
									0.004,
									0.004,
									0.075,
									0.004,
									0.01,
									0.071,
									0.441,
									0.042,
									0.177,
									0.477,
									0.07
								],
								"e": 0.14
							},
							{
								"h": [
									0.001,
									0.078,
									0.014,
									0.017,
									0.006,
									0.022,
									0.124,
									0.045,
									0.032,
									0.053,
									0.061,
									0.068
								],
								"e": 0.047
							},
							{
								"h": [
									0.008,
									0.034,
									0.042,
									0.075,
									0.007,
									0.016,
									0.067,
									0.302,
									0.016,
									0.035,
									0.025,
									0.0
								],
								"e": 0.048
							},
							{
								"h": [
									0.01,
									0.0,
									0.005,
									0.067,
									0.01,
									0.004,
									0.005,
									0.004,
									0.012,
									0.113,
									0.049,
									0.044
								],
								"e": 0.023
							},
							{
								"h": [
									0.068,
									0.022,
									0.046,
									0.01,
									0.03,
									0.021,
									0.062,
									0.042,
									0.003,
									0.0,
									0.045,
									0.036
								],
								"e": 0.029
							},
							{
								"h": [
									0.002,
									0.014,
									0.0,
									0.005,
									0.0,
									0.122,
									0.04,
									0.02,
									0.004,
									0.001,
									0.0,
									0.011
								],
								"e": 0.011
							},
							{
								"h": [
									0.007,
									0.001,
									0.005,
									0.001,
									0.002,
									0.017,
									0.087,
									0.012,
									0.016,
									0.014,
									0.032,
									0.048
								],
								"e": 0.015
							},
							{
								"h": [
									0.042,
									0.002,
									0.029,
									0.013,
									0.002,
									0.01,
									0.01,
									0.007,
									0.089,
									0.004,
									0.001,
									0.004
								],
								"e": 0.014
							},
							{
								"h": [
									0.029,
									0.001,
									0.013,
									0.003,
									0.022,
									0.002,
									0.004,
									0.01,
									0.002,
									0.002,
									0.005,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.057,
									0.018,
									0.003,
									0.014,
									0.026,
									0.002,
									0.004,
									0.001,
									1.0,
									0.001,
									0.013,
									0.013
								],
								"e": 0.024
							}
						],
						[
							{
								"h": [
									1.0,
									0.0,
									1.0,
									0.001,
									0.036,
									0.0,
									0.517,
									0.039,
									0.424,
									0.162,
									0.191,
									0.655
								],
								"e": 0.112
							},
							{
								"h": [
									0.073,
									0.152,
									0.868,
									0.046,
									0.399,
									0.209,
									0.167,
									0.504,
									0.241,
									0.121,
									0.791,
									0.069
								],
								"e": 0.327
							},
							{
								"h": [
									0.113,
									0.011,
									0.018,
									0.054,
									0.013,
									0.017,
									0.111,
									0.493,
									0.066,
									0.157,
									0.66,
									0.086
								],
								"e": 0.153
							},
							{
								"h": [
									0.029,
									0.136,
									0.009,
									0.006,
									0.002,
									0.034,
									0.038,
									0.088,
									0.104,
									0.032,
									0.181,
									0.063
								],
								"e": 0.062
							},
							{
								"h": [
									0.009,
									0.024,
									0.057,
									0.073,
									0.013,
									0.023,
									0.086,
									0.065,
									0.015,
									0.074,
									0.085,
									0.0
								],
								"e": 0.046
							},
							{
								"h": [
									0.563,
									0.002,
									0.029,
									0.064,
									0.017,
									0.004,
									0.001,
									0.007,
									0.356,
									0.032,
									0.237,
									0.026
								],
								"e": 0.082
							},
							{
								"h": [
									0.09,
									0.11,
									0.033,
									0.02,
									0.169,
									0.023,
									0.027,
									0.141,
									0.01,
									0.0,
									0.136,
									0.057
								],
								"e": 0.063
							},
							{
								"h": [
									0.002,
									0.044,
									0.003,
									0.012,
									0.0,
									0.208,
									0.097,
									0.096,
									0.015,
									0.011,
									0.001,
									0.116
								],
								"e": 0.035
							},
							{
								"h": [
									0.038,
									0.014,
									0.003,
									0.003,
									0.002,
									0.018,
									0.719,
									0.028,
									0.039,
									0.013,
									0.052,
									0.073
								],
								"e": 0.044
							},
							{
								"h": [
									0.023,
									0.016,
									0.033,
									0.021,
									0.001,
									0.006,
									0.013,
									0.03,
									0.384,
									0.009,
									0.001,
									0.024
								],
								"e": 0.03
							},
							{
								"h": [
									0.023,
									0.003,
									0.009,
									0.012,
									0.042,
									0.001,
									0.01,
									0.027,
									0.005,
									0.001,
									0.008,
									0.014
								],
								"e": 0.012
							},
							{
								"h": [
									0.019,
									0.015,
									0.007,
									0.006,
									0.058,
									0.003,
									0.007,
									0.005,
									0.271,
									0.006,
									0.033,
									0.02
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.761,
									0.0,
									0.1,
									0.0,
									0.002,
									0.0,
									0.203,
									0.514,
									0.59,
									0.463,
									0.145,
									1.0
								],
								"e": 0.105
							},
							{
								"h": [
									0.624,
									0.981,
									0.704,
									0.05,
									0.291,
									0.081,
									0.243,
									0.379,
									0.358,
									0.094,
									0.78,
									0.076
								],
								"e": 0.49
							},
							{
								"h": [
									0.323,
									0.018,
									0.182,
									0.081,
									0.291,
									0.32,
									1.0,
									0.432,
									0.435,
									0.147,
									0.412,
									0.075
								],
								"e": 0.442
							},
							{
								"h": [
									0.007,
									0.509,
									0.214,
									0.118,
									0.018,
									0.027,
									0.089,
									0.042,
									0.708,
									0.134,
									0.12,
									0.202
								],
								"e": 0.169
							},
							{
								"h": [
									0.021,
									0.976,
									0.094,
									0.029,
									0.016,
									0.126,
									0.139,
									0.031,
									0.02,
									0.053,
									0.029,
									0.0
								],
								"e": 0.096
							},
							{
								"h": [
									0.026,
									0.0,
									0.894,
									0.209,
									0.071,
									0.0,
									0.011,
									0.006,
									0.019,
									0.02,
									0.068,
									0.02
								],
								"e": 0.07
							},
							{
								"h": [
									0.547,
									0.019,
									0.057,
									0.056,
									0.022,
									0.048,
									0.013,
									0.017,
									0.073,
									0.0,
									0.03,
									0.125
								],
								"e": 0.064
							},
							{
								"h": [
									0.015,
									0.008,
									0.0,
									0.013,
									0.001,
									0.076,
									0.009,
									0.066,
									0.005,
									0.028,
									0.0,
									0.002
								],
								"e": 0.014
							},
							{
								"h": [
									0.029,
									0.007,
									0.001,
									0.019,
									0.005,
									0.06,
									0.016,
									0.101,
									0.01,
									0.002,
									0.048,
									0.029
								],
								"e": 0.024
							},
							{
								"h": [
									0.001,
									0.001,
									0.002,
									0.105,
									0.002,
									0.001,
									0.0,
									0.102,
									0.015,
									0.001,
									0.007,
									0.004
								],
								"e": 0.016
							},
							{
								"h": [
									0.004,
									0.0,
									0.001,
									0.008,
									0.01,
									0.005,
									0.0,
									0.003,
									0.004,
									0.005,
									0.002,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.004,
									0.002,
									0.004,
									0.004,
									0.01,
									0.002,
									0.001,
									0.001,
									0.128,
									0.002,
									0.008,
									0.01
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.583,
									0.0,
									0.038,
									0.0,
									0.007,
									0.0,
									0.338,
									0.199,
									0.377,
									0.312,
									0.125,
									0.264
								],
								"e": 0.058
							},
							{
								"h": [
									0.29,
									0.37,
									0.817,
									0.042,
									0.348,
									0.121,
									0.017,
									0.095,
									0.232,
									0.119,
									0.778,
									0.001
								],
								"e": 0.296
							},
							{
								"h": [
									0.549,
									0.002,
									0.313,
									0.423,
									0.426,
									0.085,
									0.153,
									0.609,
									0.197,
									0.444,
									0.48,
									0.077
								],
								"e": 0.444
							},
							{
								"h": [
									0.0,
									0.391,
									0.159,
									0.014,
									0.008,
									0.015,
									0.12,
									0.152,
									0.286,
									0.144,
									0.127,
									0.2
								],
								"e": 0.13
							},
							{
								"h": [
									0.024,
									0.469,
									0.04,
									0.224,
									0.019,
									0.082,
									0.118,
									0.183,
									0.009,
									0.16,
									0.007,
									0.0
								],
								"e": 0.095
							},
							{
								"h": [
									0.0,
									0.0,
									0.049,
									0.162,
									0.03,
									0.0,
									0.006,
									0.074,
									0.004,
									0.009,
									0.015,
									0.007
								],
								"e": 0.026
							},
							{
								"h": [
									0.127,
									0.001,
									0.029,
									0.02,
									0.006,
									0.033,
									0.008,
									0.016,
									0.032,
									0.0,
									0.001,
									0.058
								],
								"e": 0.023
							},
							{
								"h": [
									0.006,
									0.001,
									0.0,
									0.004,
									0.003,
									0.146,
									0.002,
									0.006,
									0.007,
									0.009,
									0.0,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.012,
									0.0,
									0.001,
									0.008,
									0.007,
									0.012,
									0.001,
									0.02,
									0.004,
									0.001,
									0.011,
									0.004
								],
								"e": 0.006
							},
							{
								"h": [
									0.01,
									0.0,
									0.008,
									0.017,
									0.0,
									0.001,
									0.0,
									0.007,
									0.019,
									0.0,
									0.002,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.003,
									0.0,
									0.0,
									0.0,
									0.005,
									0.001,
									0.001,
									0.001,
									0.0,
									0.001,
									0.0,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.032,
									0.001,
									0.0,
									0.0,
									0.002,
									0.0,
									0.0,
									0.0,
									0.08,
									0.0,
									0.002,
									0.0
								],
								"e": 0.004
							}
						],
						[
							{
								"h": [
									0.159,
									0.0,
									0.087,
									0.0,
									0.008,
									0.0,
									0.244,
									0.41,
									0.233,
									0.441,
									0.113,
									0.248
								],
								"e": 0.066
							},
							{
								"h": [
									0.336,
									0.209,
									0.641,
									0.038,
									0.284,
									0.091,
									0.162,
									0.213,
									0.169,
									0.069,
									0.719,
									0.0
								],
								"e": 0.268
							},
							{
								"h": [
									0.126,
									0.002,
									0.169,
									0.078,
									0.136,
									0.129,
									0.108,
									0.417,
									0.292,
									0.447,
									0.499,
									0.078
								],
								"e": 0.285
							},
							{
								"h": [
									0.002,
									0.248,
									0.194,
									0.138,
									0.005,
									0.009,
									0.05,
									1.0,
									0.397,
									0.113,
									0.082,
									0.398
								],
								"e": 0.24
							},
							{
								"h": [
									0.084,
									0.417,
									0.023,
									0.47,
									0.014,
									0.1,
									0.186,
									0.021,
									0.01,
									0.096,
									0.008,
									0.0
								],
								"e": 0.107
							},
							{
								"h": [
									0.0,
									0.0,
									0.124,
									0.086,
									0.038,
									0.0,
									0.024,
									0.084,
									0.021,
									0.008,
									0.019,
									0.017
								],
								"e": 0.031
							},
							{
								"h": [
									0.087,
									0.003,
									0.021,
									0.017,
									0.004,
									0.031,
									0.005,
									0.009,
									0.104,
									0.0,
									0.001,
									0.143
								],
								"e": 0.031
							},
							{
								"h": [
									0.003,
									0.0,
									0.0,
									0.004,
									0.001,
									0.265,
									0.001,
									0.001,
									0.004,
									0.002,
									0.0,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.007,
									0.0,
									0.001,
									0.001,
									0.011,
									0.034,
									0.002,
									0.089,
									0.007,
									0.0,
									0.006,
									0.003
								],
								"e": 0.012
							},
							{
								"h": [
									0.004,
									0.0,
									0.011,
									0.034,
									0.0,
									0.002,
									0.0,
									0.005,
									0.015,
									0.0,
									0.001,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.004,
									0.0,
									0.0,
									0.001,
									0.007,
									0.001,
									0.0,
									0.0,
									0.0,
									0.003,
									0.0,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.012,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0,
									0.0,
									0.0,
									0.051,
									0.0,
									0.002,
									0.0
								],
								"e": 0.002
							}
						],
						[
							{
								"h": [
									0.577,
									0.0,
									0.093,
									0.0,
									0.006,
									0.0,
									0.376,
									1.0,
									0.741,
									0.615,
									0.167,
									0.409
								],
								"e": 0.137
							},
							{
								"h": [
									1.0,
									1.0,
									0.766,
									0.051,
									0.304,
									0.119,
									0.119,
									0.333,
									0.292,
									0.079,
									0.771,
									0.055
								],
								"e": 0.525
							},
							{
								"h": [
									0.546,
									0.023,
									1.0,
									0.424,
									0.347,
									1.0,
									0.461,
									0.581,
									1.0,
									0.159,
									0.545,
									0.095
								],
								"e": 0.87
							},
							{
								"h": [
									0.002,
									0.475,
									0.471,
									0.148,
									0.011,
									0.011,
									0.07,
									0.097,
									1.0,
									0.043,
									0.095,
									0.172
								],
								"e": 0.198
							},
							{
								"h": [
									0.063,
									1.0,
									0.04,
									0.098,
									0.031,
									0.14,
									0.271,
									0.025,
									0.005,
									0.054,
									0.017,
									1.0
								],
								"e": 0.295
							},
							{
								"h": [
									0.002,
									0.001,
									0.651,
									0.258,
									0.044,
									0.006,
									0.084,
									0.003,
									0.037,
									0.008,
									0.008,
									0.035
								],
								"e": 0.065
							},
							{
								"h": [
									0.198,
									0.006,
									0.051,
									0.016,
									0.019,
									0.102,
									0.026,
									0.009,
									0.425,
									0.0,
									0.007,
									0.115
								],
								"e": 0.07
							},
							{
								"h": [
									0.057,
									0.002,
									0.0,
									0.014,
									0.002,
									0.044,
									0.007,
									0.004,
									0.001,
									0.049,
									0.0,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.013,
									0.001,
									0.001,
									0.004,
									0.003,
									0.078,
									0.014,
									0.324,
									0.008,
									0.007,
									0.036,
									0.035
								],
								"e": 0.036
							},
							{
								"h": [
									0.002,
									0.002,
									0.003,
									0.306,
									0.001,
									0.007,
									0.0,
									0.018,
									0.012,
									0.001,
									0.01,
									0.003
								],
								"e": 0.023
							},
							{
								"h": [
									0.006,
									0.001,
									0.001,
									0.002,
									0.012,
									0.004,
									0.001,
									0.005,
									0.003,
									0.004,
									0.002,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.016,
									0.015,
									0.006,
									0.007,
									0.036,
									0.002,
									0.002,
									0.004,
									0.328,
									0.001,
									0.014,
									0.026
								],
								"e": 0.014
							}
						],
						[
							{
								"h": [
									0.603,
									1.0,
									0.644,
									1.0,
									1.0,
									1.0,
									0.666,
									0.078,
									0.133,
									0.081,
									1.0,
									0.668
								],
								"e": 1.0
							},
							{
								"h": [
									0.346,
									0.578,
									1.0,
									0.058,
									0.481,
									0.067,
									0.056,
									0.276,
									0.252,
									0.075,
									1.0,
									1.0
								],
								"e": 0.736
							},
							{
								"h": [
									0.47,
									0.001,
									0.308,
									1.0,
									0.037,
									0.088,
									0.268,
									0.614,
									0.189,
									1.0,
									1.0,
									0.14
								],
								"e": 0.596
							},
							{
								"h": [
									0.631,
									0.191,
									0.287,
									0.124,
									0.0,
									0.024,
									1.0,
									0.144,
									0.074,
									0.329,
									0.275,
									0.422
								],
								"e": 0.329
							},
							{
								"h": [
									0.045,
									0.11,
									0.029,
									0.429,
									0.02,
									0.145,
									0.101,
									1.0,
									0.002,
									0.08,
									0.037,
									0.0
								],
								"e": 0.148
							},
							{
								"h": [
									0.008,
									0.003,
									0.041,
									0.063,
									0.044,
									0.049,
									0.031,
									0.011,
									0.069,
									0.01,
									0.061,
									0.101
								],
								"e": 0.038
							},
							{
								"h": [
									0.118,
									0.035,
									0.033,
									0.024,
									0.017,
									0.09,
									0.124,
									0.061,
									0.013,
									0.001,
									0.033,
									0.1
								],
								"e": 0.05
							},
							{
								"h": [
									0.013,
									0.01,
									0.0,
									0.014,
									0.0,
									0.106,
									0.028,
									0.021,
									0.044,
									0.002,
									0.001,
									0.008
								],
								"e": 0.015
							},
							{
								"h": [
									0.006,
									0.001,
									0.002,
									0.005,
									0.006,
									0.043,
									0.06,
									0.044,
									0.032,
									0.038,
									0.267,
									0.052
								],
								"e": 0.038
							},
							{
								"h": [
									0.051,
									0.004,
									0.017,
									0.049,
									0.003,
									0.015,
									0.006,
									0.012,
									0.267,
									0.006,
									0.005,
									0.001
								],
								"e": 0.024
							},
							{
								"h": [
									0.02,
									0.001,
									0.001,
									0.005,
									0.018,
									0.003,
									0.005,
									0.033,
									0.001,
									0.005,
									0.008,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.048,
									0.003,
									0.001,
									0.009,
									0.033,
									0.001,
									0.001,
									0.002,
									0.997,
									0.001,
									0.012,
									0.015
								],
								"e": 0.022
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				},
				{
					"i": " Vader",
					"o": ".",
					"t": ".",
					"r": [
						{
							"p": " Vader",
							"b": 0.0,
							"k": {
								" Vader": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 0.0,
							"k": {
								" Vader": 0.999,
								" Kenobi": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 0.0,
							"k": {
								" Vader": 0.995,
								" Skywalker": 0.002,
								" Kenobi": 0.002,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 0.0,
							"k": {
								" Vader": 0.994,
								" Kenobi": 0.003,
								" Skywalker": 0.002,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 0.0,
							"k": {
								" Vader": 0.985,
								" Kenobi": 0.005,
								"'s": 0.004,
								" Skywalker": 0.002,
								",": 0.001,
								" III": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0
							}
						},
						{
							"p": " Vader",
							"b": 0.009,
							"k": {
								" Vader": 0.867,
								",": 0.043,
								" Kenobi": 0.025,
								"'s": 0.014,
								" III": 0.012,
								".": 0.009,
								";": 0.005,
								" Jr": 0.004,
								" II": 0.003,
								" Skywalker": 0.003
							}
						},
						{
							"p": " Vader",
							"b": 0.075,
							"k": {
								" Vader": 0.638,
								",": 0.11,
								".": 0.075,
								";": 0.052,
								" III": 0.034,
								" Jr": 0.033,
								" Kenobi": 0.011,
								" Skywalker": 0.009,
								"'s": 0.008,
								" (": 0.005
							}
						},
						{
							"p": ".",
							"b": 0.909,
							"k": {
								".": 0.909,
								",": 0.046,
								";": 0.018,
								" Jr": 0.011,
								" Vader": 0.005,
								" (": 0.003,
								"/": 0.001,
								" ": 0.001,
								"'s": 0.001,
								".\"": 0.001
							}
						},
						{
							"p": ".",
							"b": 0.997,
							"k": {
								".": 0.997,
								",": 0.001,
								";": 0.001,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0
							}
						},
						{
							"p": ".",
							"b": 1.0,
							"k": {
								".": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ".",
							"b": 1.0,
							"k": {
								".": 1.0,
								"!": 0.0,
								"\"": 0.0,
								"#": 0.0,
								"$": 0.0,
								"%": 0.0,
								"&": 0.0,
								"'": 0.0,
								"(": 0.0,
								")": 0.0
							}
						},
						{
							"p": ".",
							"b": 0.974,
							"k": {
								".": 0.974,
								" (": 0.006,
								",": 0.003,
								"!": 0.002,
								"/": 0.001,
								" ": 0.001,
								" and": 0.001,
								".\"": 0.001,
								" .": 0.001,
								"...": 0.001
							}
						}
					],
					"a": [
						[
							{
								"h": [
									1.0,
									0.0,
									0.737,
									0.0,
									0.002,
									0.005,
									0.764,
									0.011,
									0.698,
									0.501,
									0.165,
									0.167
								],
								"e": 0.108
							},
							{
								"h": [
									0.111,
									0.055,
									0.118,
									1.0,
									1.0,
									0.271,
									0.625,
									1.0,
									1.0,
									1.0,
									0.021,
									0.081
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									0.039,
									0.063,
									1.0,
									0.06,
									0.362,
									1.0,
									0.201,
									0.04,
									0.045,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.727,
									0.133,
									1.0,
									1.0,
									1.0,
									0.075,
									0.172,
									0.408,
									1.0,
									1.0,
									0.313
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.482,
									1.0,
									0.586,
									1.0,
									1.0,
									1.0,
									0.275,
									1.0,
									1.0,
									1.0,
									0.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.402,
									1.0,
									0.509,
									0.412,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.363,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.71,
									1.0,
									1.0,
									0.724
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.943,
									1.0,
									1.0,
									1.0,
									0.897
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									0.475,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.5,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.864,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0
								],
								"e": 1.0
							},
							{
								"h": [
									0.889,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.0,
									1.0,
									1.0,
									0.002
								],
								"e": 1.0
							}
						],
						[
							{
								"h": [
									0.703,
									0.0,
									0.142,
									0.0,
									0.0,
									0.0,
									0.303,
									0.001,
									0.308,
									0.415,
									0.103,
									0.391
								],
								"e": 0.052
							},
							{
								"h": [
									0.007,
									0.006,
									0.078,
									0.007,
									0.154,
									0.117,
									0.268,
									0.292,
									0.39,
									0.182,
									0.083,
									0.03
								],
								"e": 0.162
							},
							{
								"h": [
									0.013,
									0.012,
									0.0,
									0.0,
									0.003,
									0.0,
									0.022,
									0.099,
									0.001,
									0.0,
									0.019,
									0.003
								],
								"e": 0.016
							},
							{
								"h": [
									0.02,
									0.001,
									0.0,
									0.0,
									0.026,
									0.012,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.0,
									0.045,
									0.0,
									0.037,
									0.0,
									0.001,
									0.001,
									0.016,
									0.001,
									0.052,
									0.0
								],
								"e": 0.018
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.008,
									0.0,
									0.007,
									0.0,
									0.0,
									0.003,
									0.012,
									0.063,
									0.049
								],
								"e": 0.007
							},
							{
								"h": [
									0.002,
									0.0,
									0.091,
									0.005,
									0.002,
									0.001,
									0.009,
									0.003,
									0.0,
									0.001,
									0.004,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.007,
									0.023,
									0.001,
									0.008,
									0.01,
									0.003,
									0.004,
									0.0,
									0.0,
									0.004,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.003,
									0.007,
									0.01,
									0.002,
									0.0,
									0.0,
									0.012,
									0.0,
									0.001,
									0.002,
									0.001,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.005,
									0.001,
									0.0,
									0.034,
									0.002,
									0.004,
									0.004,
									0.0,
									0.003,
									0.002,
									0.02
								],
								"e": 0.006
							},
							{
								"h": [
									0.002,
									0.01,
									0.025,
									0.081,
									0.084,
									0.002,
									0.002,
									0.031,
									0.093,
									0.0,
									0.005,
									0.01
								],
								"e": 0.021
							},
							{
								"h": [
									0.6,
									0.017,
									0.026,
									0.011,
									0.031,
									0.031,
									0.015,
									0.032,
									0.06,
									0.034,
									0.039,
									0.008
								],
								"e": 0.039
							}
						],
						[
							{
								"h": [
									0.471,
									0.0,
									0.047,
									0.0,
									0.0,
									0.0,
									0.267,
									0.0,
									0.861,
									0.63,
									0.175,
									0.361
								],
								"e": 0.06
							},
							{
								"h": [
									0.011,
									0.004,
									0.085,
									0.008,
									0.136,
									0.143,
									0.044,
									0.277,
									0.29,
									0.335,
									0.114,
									0.003
								],
								"e": 0.144
							},
							{
								"h": [
									0.012,
									0.002,
									0.0,
									0.0,
									0.0,
									0.0,
									0.004,
									0.123,
									0.0,
									0.0,
									0.027,
									0.005
								],
								"e": 0.012
							},
							{
								"h": [
									0.005,
									0.0,
									0.0,
									0.0,
									0.075,
									0.008,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.0,
									0.013,
									0.0,
									0.013,
									0.0,
									0.001,
									0.003,
									0.045,
									0.0,
									0.008,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.001,
									0.0,
									0.008,
									0.0,
									0.0,
									0.0,
									0.017,
									0.018,
									0.322
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.0,
									0.079,
									0.01,
									0.001,
									0.0,
									0.004,
									0.001,
									0.0,
									0.001,
									0.005,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.003,
									0.005,
									0.0,
									0.001,
									0.003,
									0.006,
									0.01,
									0.0,
									0.0,
									0.002,
									0.008
								],
								"e": 0.002
							},
							{
								"h": [
									0.001,
									0.003,
									0.002,
									0.0,
									0.0,
									0.0,
									0.004,
									0.0,
									0.0,
									0.0,
									0.001,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.001,
									0.001,
									0.001,
									0.0,
									0.004,
									0.001,
									0.001,
									0.001,
									0.0,
									0.0,
									0.0,
									0.003
								],
								"e": 0.001
							},
							{
								"h": [
									0.001,
									0.002,
									0.003,
									0.017,
									0.001,
									0.001,
									0.003,
									0.002,
									0.006,
									0.0,
									0.002,
									0.004
								],
								"e": 0.003
							},
							{
								"h": [
									0.113,
									0.043,
									0.009,
									0.005,
									0.004,
									0.004,
									0.005,
									0.004,
									0.215,
									0.005,
									0.004,
									0.0
								],
								"e": 0.018
							}
						],
						[
							{
								"h": [
									0.261,
									0.0,
									0.077,
									0.0,
									0.0,
									0.0,
									0.156,
									0.0,
									0.899,
									0.649,
									0.157,
									0.297
								],
								"e": 0.055
							},
							{
								"h": [
									0.005,
									0.003,
									0.106,
									0.008,
									0.152,
									1.0,
									0.126,
									0.203,
									0.333,
									0.567,
									0.13,
									0.006
								],
								"e": 0.304
							},
							{
								"h": [
									0.002,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.156,
									0.0,
									0.0,
									0.034,
									0.006
								],
								"e": 0.013
							},
							{
								"h": [
									0.002,
									0.0,
									0.0,
									0.0,
									0.071,
									0.014,
									0.0,
									0.0,
									0.0,
									0.0,
									0.005,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.0,
									0.016,
									0.0,
									0.022,
									0.0,
									0.002,
									0.009,
									0.067,
									0.001,
									0.01,
									0.0
								],
								"e": 0.015
							},
							{
								"h": [
									0.007,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.017,
									0.007,
									0.101
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.0,
									0.052,
									0.003,
									0.001,
									0.001,
									0.004,
									0.002,
									0.0,
									0.0,
									0.007,
									0.0
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.003,
									0.002,
									0.0,
									0.0,
									0.001,
									0.007,
									0.004,
									0.0,
									0.0,
									0.0,
									0.014
								],
								"e": 0.002
							},
							{
								"h": [
									0.002,
									0.003,
									0.002,
									0.0,
									0.0,
									0.0,
									0.008,
									0.0,
									0.0,
									0.0,
									0.001,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.0,
									0.001,
									0.001,
									0.001,
									0.002,
									0.001,
									0.0,
									0.0,
									0.002
								],
								"e": 0.001
							},
							{
								"h": [
									0.001,
									0.004,
									0.005,
									0.025,
									0.001,
									0.001,
									0.004,
									0.002,
									0.008,
									0.0,
									0.002,
									0.007
								],
								"e": 0.004
							},
							{
								"h": [
									0.101,
									0.015,
									0.016,
									0.006,
									0.019,
									0.009,
									0.01,
									0.011,
									0.287,
									0.01,
									0.004,
									0.0
								],
								"e": 0.022
							}
						],
						[
							{
								"h": [
									0.694,
									0.0,
									0.09,
									0.0,
									0.0,
									0.0,
									0.188,
									0.001,
									0.315,
									0.284,
									0.115,
									0.56
								],
								"e": 0.048
							},
							{
								"h": [
									0.01,
									0.015,
									0.1,
									0.009,
									0.134,
									0.086,
									0.336,
									0.372,
									0.448,
									0.191,
									0.111,
									0.046
								],
								"e": 0.186
							},
							{
								"h": [
									0.008,
									0.018,
									0.0,
									0.0,
									0.001,
									0.0,
									0.072,
									0.093,
									0.0,
									0.0,
									0.017,
									0.004
								],
								"e": 0.023
							},
							{
								"h": [
									0.013,
									0.002,
									0.0,
									0.0,
									0.028,
									0.014,
									0.0,
									0.0,
									0.0,
									0.0,
									0.004,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.0,
									0.042,
									0.0,
									0.034,
									0.0,
									0.001,
									0.005,
									0.048,
									0.001,
									0.03,
									0.0
								],
								"e": 0.019
							},
							{
								"h": [
									0.4,
									0.0,
									0.0,
									0.006,
									0.001,
									0.001,
									0.0,
									0.0,
									0.003,
									0.043,
									0.161,
									0.045
								],
								"e": 0.037
							},
							{
								"h": [
									0.001,
									0.004,
									0.058,
									0.012,
									0.008,
									0.003,
									0.014,
									0.003,
									0.0,
									0.003,
									0.06,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.0,
									0.011,
									0.071,
									0.001,
									0.004,
									0.029,
									0.011,
									0.094,
									0.0,
									0.0,
									0.004,
									0.311
								],
								"e": 0.024
							},
							{
								"h": [
									0.016,
									0.18,
									0.034,
									0.004,
									0.0,
									0.002,
									0.133,
									0.0,
									0.004,
									0.002,
									0.005,
									0.002
								],
								"e": 0.025
							},
							{
								"h": [
									0.0,
									0.014,
									0.001,
									0.001,
									0.028,
									0.006,
									0.019,
									0.012,
									0.001,
									0.001,
									0.001,
									0.175
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.003,
									0.074,
									0.339,
									0.424,
									0.005,
									0.001,
									0.05,
									0.061,
									0.0,
									0.004,
									0.004
								],
								"e": 0.044
							},
							{
								"h": [
									1.0,
									0.006,
									0.008,
									0.027,
									0.035,
									0.021,
									0.007,
									0.069,
									0.117,
									0.009,
									0.068,
									0.057
								],
								"e": 0.053
							}
						],
						[
							{
								"h": [
									0.35,
									0.001,
									0.081,
									0.0,
									0.0,
									0.0,
									0.509,
									0.0,
									0.273,
									0.345,
									0.162,
									0.125
								],
								"e": 0.044
							},
							{
								"h": [
									0.003,
									0.005,
									0.118,
									0.008,
									0.127,
									0.045,
									0.034,
									0.084,
									0.217,
									0.121,
									0.14,
									0.007
								],
								"e": 0.086
							},
							{
								"h": [
									0.006,
									0.005,
									0.0,
									0.0,
									0.0,
									0.0,
									0.005,
									0.093,
									0.0,
									0.0,
									0.021,
									0.004
								],
								"e": 0.011
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.0,
									0.011,
									0.014,
									0.0,
									0.0,
									0.0,
									0.0,
									0.001,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.0,
									0.007,
									0.0,
									0.011,
									0.0,
									0.001,
									0.001,
									0.026,
									0.002,
									0.012,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.001,
									0.0,
									0.0,
									0.003,
									0.0,
									0.0,
									0.0,
									0.001,
									0.001,
									0.008,
									0.16,
									0.104
								],
								"e": 0.011
							},
							{
								"h": [
									0.002,
									0.0,
									0.048,
									0.006,
									0.001,
									0.001,
									0.009,
									0.003,
									0.0,
									0.0,
									0.016,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.005,
									0.002,
									0.0,
									0.004,
									0.005,
									0.004,
									0.041,
									0.0,
									0.0,
									0.002,
									0.028
								],
								"e": 0.004
							},
							{
								"h": [
									0.011,
									0.002,
									0.002,
									0.001,
									0.0,
									0.001,
									0.003,
									0.0,
									0.001,
									0.001,
									0.001,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.001,
									0.001,
									0.002,
									0.0,
									0.012,
									0.0,
									0.006,
									0.002,
									0.0,
									0.001,
									0.0,
									0.013
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.004,
									0.011,
									0.007,
									0.002,
									0.0,
									0.007,
									0.002,
									0.002,
									0.0,
									0.003,
									0.014
								],
								"e": 0.005
							},
							{
								"h": [
									0.148,
									0.003,
									0.003,
									0.001,
									0.006,
									0.003,
									0.007,
									0.002,
									0.049,
									0.005,
									0.001,
									0.0
								],
								"e": 0.008
							}
						],
						[
							{
								"h": [
									0.202,
									0.0,
									0.071,
									0.0,
									0.0,
									0.0,
									0.168,
									0.001,
									0.126,
									0.15,
									0.053,
									0.104
								],
								"e": 0.021
							},
							{
								"h": [
									0.004,
									0.003,
									0.085,
									0.007,
									0.104,
									0.126,
									0.159,
									0.268,
									0.151,
									0.117,
									0.119,
									0.001
								],
								"e": 0.112
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0,
									0.004,
									0.073,
									0.0,
									0.0,
									0.02,
									0.003
								],
								"e": 0.007
							},
							{
								"h": [
									0.008,
									0.0,
									0.0,
									0.0,
									0.011,
									0.015,
									0.0,
									0.0,
									0.0,
									0.0,
									0.001,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.0,
									0.012,
									0.0,
									0.049,
									0.0,
									0.001,
									0.002,
									0.032,
									0.002,
									0.025,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.001,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.003,
									0.034,
									0.092
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.0,
									0.006,
									0.003,
									0.001,
									0.001,
									0.009,
									0.002,
									0.0,
									0.0,
									0.009,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.005,
									0.0,
									0.0,
									0.002,
									0.03,
									0.003,
									0.008,
									0.0,
									0.0,
									0.0,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.002,
									0.0,
									0.002,
									0.0,
									0.0,
									0.002,
									0.004,
									0.0,
									0.001,
									0.001,
									0.001,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.001,
									0.001,
									0.002,
									0.0,
									0.003,
									0.001,
									0.001,
									0.002,
									0.001,
									0.0,
									0.0,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.002,
									0.004,
									0.003,
									0.003,
									0.008,
									0.004,
									0.001,
									0.001,
									0.002,
									0.0,
									0.001,
									0.007
								],
								"e": 0.003
							},
							{
								"h": [
									0.2,
									0.007,
									0.004,
									0.01,
									0.01,
									0.006,
									0.004,
									0.004,
									1.0,
									0.014,
									0.006,
									0.0
								],
								"e": 0.043
							}
						],
						[
							{
								"h": [
									0.506,
									0.0,
									0.075,
									0.0,
									0.0,
									0.0,
									0.219,
									0.001,
									0.248,
									0.317,
									0.109,
									0.349
								],
								"e": 0.04
							},
							{
								"h": [
									0.011,
									0.013,
									0.113,
									0.01,
									0.12,
									0.109,
									0.148,
									0.295,
									0.336,
									0.163,
									0.131,
									0.045
								],
								"e": 0.152
							},
							{
								"h": [
									0.009,
									0.021,
									0.0,
									0.0,
									0.0,
									0.0,
									0.037,
									0.14,
									0.0,
									0.0,
									0.018,
									0.003
								],
								"e": 0.023
							},
							{
								"h": [
									0.004,
									0.001,
									0.0,
									0.0,
									0.045,
									0.007,
									0.0,
									0.0,
									0.0,
									0.0,
									0.002,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.0,
									0.029,
									0.0,
									0.031,
									0.0,
									0.001,
									0.002,
									0.035,
									0.001,
									0.042,
									0.0
								],
								"e": 0.017
							},
							{
								"h": [
									0.003,
									0.0,
									0.0,
									0.007,
									0.0,
									0.038,
									0.0,
									0.0,
									0.006,
									0.013,
									0.149,
									0.059
								],
								"e": 0.014
							},
							{
								"h": [
									0.008,
									0.0,
									0.026,
									0.004,
									0.003,
									0.001,
									0.004,
									0.003,
									0.0,
									0.0,
									0.011,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.019,
									0.003,
									0.001,
									0.004,
									0.037,
									0.003,
									0.015,
									0.0,
									0.0,
									0.001,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.004,
									0.003,
									0.008,
									0.004,
									0.0,
									0.004,
									0.009,
									0.0,
									0.001,
									0.003,
									0.005,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.003,
									0.0,
									0.001,
									0.035,
									0.002,
									0.001,
									0.006,
									0.0,
									0.001,
									0.013,
									0.007
								],
								"e": 0.005
							},
							{
								"h": [
									0.0,
									0.002,
									0.01,
									0.042,
									0.058,
									0.014,
									0.0,
									0.016,
									0.035,
									0.0,
									0.001,
									0.003
								],
								"e": 0.01
							},
							{
								"h": [
									0.529,
									0.005,
									0.004,
									0.004,
									0.007,
									0.017,
									0.004,
									0.01,
									0.065,
									0.007,
									0.024,
									0.001
								],
								"e": 0.02
							}
						],
						[
							{
								"h": [
									0.379,
									0.002,
									0.043,
									0.0,
									0.0,
									0.01,
									0.138,
									0.0,
									0.642,
									1.0,
									0.426,
									0.19
								],
								"e": 0.07
							},
							{
								"h": [
									0.01,
									0.012,
									0.185,
									0.013,
									0.167,
									0.17,
									0.02,
									0.463,
									0.528,
									0.568,
									0.214,
									0.054
								],
								"e": 0.243
							},
							{
								"h": [
									0.006,
									0.003,
									0.0,
									0.0,
									0.0,
									0.0,
									0.017,
									0.24,
									0.001,
									0.0,
									0.068,
									0.011
								],
								"e": 0.025
							},
							{
								"h": [
									0.045,
									0.0,
									0.0,
									0.0,
									0.435,
									0.021,
									0.0,
									0.0,
									0.0,
									0.001,
									0.011,
									0.0
								],
								"e": 0.065
							},
							{
								"h": [
									0.0,
									0.0,
									0.013,
									0.002,
									0.048,
									0.0,
									0.003,
									0.025,
									0.082,
									0.002,
									0.036,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									0.005,
									0.001,
									0.001,
									0.001,
									0.0,
									0.038,
									0.001,
									0.0,
									0.003,
									0.022,
									0.043,
									0.159
								],
								"e": 0.015
							},
							{
								"h": [
									0.001,
									0.0,
									0.034,
									0.006,
									0.002,
									0.001,
									0.023,
									0.004,
									0.0,
									0.007,
									0.031,
									0.0
								],
								"e": 0.01
							},
							{
								"h": [
									0.0,
									0.031,
									0.019,
									0.001,
									0.0,
									0.006,
									0.021,
									0.095,
									0.0,
									0.0,
									0.005,
									0.042
								],
								"e": 0.011
							},
							{
								"h": [
									0.003,
									0.009,
									0.002,
									0.0,
									0.0,
									0.002,
									0.009,
									0.0,
									0.001,
									0.003,
									0.008,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.004,
									0.001,
									0.002,
									0.0,
									0.009,
									0.002,
									0.002,
									0.003,
									0.001,
									0.001,
									0.001,
									0.004
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.006,
									0.003,
									0.045,
									0.001,
									0.003,
									0.011,
									0.005,
									0.012,
									0.0,
									0.006,
									0.011
								],
								"e": 0.007
							},
							{
								"h": [
									0.141,
									0.017,
									0.012,
									0.007,
									0.016,
									0.009,
									0.013,
									0.005,
									0.22,
									0.022,
									0.007,
									0.0
								],
								"e": 0.021
							}
						],
						[
							{
								"h": [
									0.66,
									0.0,
									0.07,
									0.0,
									0.0,
									0.0,
									0.165,
									0.001,
									0.314,
									0.27,
									0.111,
									0.648
								],
								"e": 0.047
							},
							{
								"h": [
									0.014,
									0.032,
									0.151,
									0.014,
									0.139,
									0.084,
									0.244,
									0.389,
									0.416,
									0.158,
									0.18,
									0.054
								],
								"e": 0.189
							},
							{
								"h": [
									0.012,
									0.014,
									0.0,
									0.0,
									0.001,
									0.0,
									0.107,
									0.136,
									0.001,
									0.0,
									0.029,
									0.006
								],
								"e": 0.03
							},
							{
								"h": [
									0.014,
									0.003,
									0.0,
									0.0,
									0.04,
									0.015,
									0.0,
									0.0,
									0.0,
									0.001,
									0.007,
									0.0
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.001,
									0.043,
									0.0,
									0.039,
									0.001,
									0.003,
									0.01,
									0.049,
									0.001,
									0.041,
									0.0
								],
								"e": 0.022
							},
							{
								"h": [
									0.439,
									0.029,
									0.0,
									0.009,
									0.002,
									0.324,
									0.0,
									0.0,
									0.038,
									0.045,
									0.194,
									0.056
								],
								"e": 0.082
							},
							{
								"h": [
									0.003,
									0.005,
									0.032,
									0.016,
									0.019,
									0.006,
									0.029,
									0.005,
									0.0,
									0.232,
									0.129,
									0.005
								],
								"e": 0.052
							},
							{
								"h": [
									0.0,
									0.106,
									0.643,
									0.006,
									0.002,
									0.027,
									0.025,
									0.759,
									0.0,
									0.002,
									0.092,
									0.317
								],
								"e": 0.094
							},
							{
								"h": [
									0.026,
									0.33,
									0.016,
									0.011,
									0.0,
									0.004,
									0.057,
									0.002,
									0.006,
									0.002,
									0.037,
									0.003
								],
								"e": 0.028
							},
							{
								"h": [
									0.0,
									0.015,
									0.002,
									0.003,
									0.015,
									0.011,
									0.021,
									0.014,
									0.001,
									0.007,
									0.002,
									0.222
								],
								"e": 0.018
							},
							{
								"h": [
									0.002,
									0.004,
									0.096,
									0.37,
									0.425,
									0.014,
									0.001,
									0.046,
									0.076,
									0.0,
									0.006,
									0.008
								],
								"e": 0.049
							},
							{
								"h": [
									0.71,
									0.004,
									0.008,
									0.017,
									0.03,
									0.025,
									0.005,
									0.048,
									0.086,
									0.009,
									0.041,
									0.014
								],
								"e": 0.036
							}
						],
						[
							{
								"h": [
									0.61,
									0.0,
									0.177,
									0.0,
									0.0,
									0.0,
									0.252,
									0.0,
									0.282,
									0.194,
									0.069,
									0.136
								],
								"e": 0.039
							},
							{
								"h": [
									0.015,
									0.013,
									0.207,
									0.014,
									0.147,
									0.209,
									0.085,
									0.191,
									0.349,
									0.134,
									0.223,
									0.008
								],
								"e": 0.157
							},
							{
								"h": [
									0.008,
									0.001,
									0.0,
									0.001,
									0.0,
									0.0,
									0.019,
									0.237,
									0.0,
									0.0,
									0.054,
									0.01
								],
								"e": 0.023
							},
							{
								"h": [
									0.0,
									0.002,
									0.0,
									0.0,
									0.032,
									0.02,
									0.0,
									0.0,
									0.0,
									0.0,
									0.005,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.0,
									0.012,
									0.002,
									0.094,
									0.001,
									0.004,
									0.006,
									0.091,
									0.006,
									0.018,
									0.0
								],
								"e": 0.027
							},
							{
								"h": [
									0.003,
									0.002,
									0.001,
									0.015,
									0.001,
									0.129,
									0.0,
									0.002,
									0.015,
									0.028,
									0.224,
									0.082
								],
								"e": 0.03
							},
							{
								"h": [
									0.012,
									0.001,
									0.012,
									0.02,
									0.006,
									0.003,
									0.018,
									0.03,
									0.0,
									0.004,
									0.013,
									0.019
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.02,
									0.081,
									0.003,
									0.002,
									0.05,
									0.012,
									0.972,
									0.0,
									0.002,
									0.207,
									0.026
								],
								"e": 0.057
							},
							{
								"h": [
									0.008,
									0.002,
									0.007,
									0.004,
									0.0,
									0.005,
									0.009,
									0.0,
									0.005,
									0.001,
									0.007,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.007,
									0.002,
									0.01,
									0.001,
									0.005,
									0.002,
									0.011,
									0.005,
									0.004,
									0.003,
									0.001,
									0.007
								],
								"e": 0.005
							},
							{
								"h": [
									0.018,
									0.013,
									0.018,
									0.015,
									0.012,
									0.002,
									0.03,
									0.003,
									0.002,
									0.0,
									0.006,
									0.034
								],
								"e": 0.014
							},
							{
								"h": [
									0.094,
									0.027,
									0.018,
									0.009,
									0.013,
									0.008,
									0.028,
									0.007,
									0.137,
									0.028,
									0.003,
									0.0
								],
								"e": 0.022
							}
						],
						[
							{
								"h": [
									0.501,
									0.0,
									0.069,
									0.0,
									0.0,
									0.0,
									0.212,
									0.001,
									0.259,
									0.316,
									0.11,
									0.382
								],
								"e": 0.04
							},
							{
								"h": [
									0.017,
									0.025,
									0.159,
									0.013,
									0.13,
									0.112,
									0.142,
									0.325,
									0.326,
									0.151,
									0.186,
									0.046
								],
								"e": 0.166
							},
							{
								"h": [
									0.017,
									0.023,
									0.0,
									0.0,
									0.001,
									0.0,
									0.057,
									0.178,
									0.001,
									0.0,
									0.03,
									0.005
								],
								"e": 0.03
							},
							{
								"h": [
									0.002,
									0.004,
									0.0,
									0.0,
									0.045,
									0.008,
									0.0,
									0.0,
									0.0,
									0.001,
									0.008,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.0,
									0.001,
									0.039,
									0.001,
									0.025,
									0.001,
									0.004,
									0.003,
									0.028,
									0.003,
									0.032,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.023,
									0.001,
									0.0,
									0.0,
									0.0,
									0.001,
									0.01,
									0.064,
									0.046
								],
								"e": 0.006
							},
							{
								"h": [
									0.015,
									0.0,
									0.013,
									0.009,
									0.01,
									0.002,
									0.006,
									0.011,
									0.0,
									0.0,
									0.004,
									0.007
								],
								"e": 0.006
							},
							{
								"h": [
									0.0,
									0.002,
									0.0,
									0.001,
									0.003,
									0.009,
									0.007,
									0.02,
									0.0,
									0.002,
									0.0,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.003,
									0.0,
									0.002,
									0.005,
									0.0,
									0.005,
									0.003,
									0.001,
									0.001,
									0.001,
									0.007,
									0.0
								],
								"e": 0.002
							},
							{
								"h": [
									0.0,
									0.002,
									0.001,
									0.003,
									0.008,
									0.004,
									0.0,
									0.003,
									0.0,
									0.001,
									0.014,
									0.004
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.001,
									0.008,
									0.022,
									0.044,
									0.021,
									0.0,
									0.008,
									0.019,
									0.0,
									0.001,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.572,
									0.004,
									0.002,
									0.004,
									0.006,
									0.01,
									0.003,
									0.012,
									0.037,
									0.004,
									0.014,
									0.001
								],
								"e": 0.017
							}
						],
						[
							{
								"h": [
									0.213,
									0.0,
									0.012,
									0.0,
									0.0,
									0.0,
									0.138,
									0.0,
									0.315,
									0.465,
									0.181,
									0.106
								],
								"e": 0.033
							},
							{
								"h": [
									0.022,
									0.019,
									0.211,
									0.015,
									0.176,
									0.83,
									0.158,
									0.279,
									0.336,
									0.479,
									0.269,
									0.015
								],
								"e": 0.312
							},
							{
								"h": [
									0.012,
									0.003,
									0.0,
									0.001,
									0.001,
									0.0,
									0.018,
									0.229,
									0.001,
									0.0,
									0.081,
									0.012
								],
								"e": 0.026
							},
							{
								"h": [
									0.003,
									0.001,
									0.0,
									0.0,
									0.123,
									0.026,
									0.0,
									0.0,
									0.0,
									0.0,
									0.017,
									0.0
								],
								"e": 0.021
							},
							{
								"h": [
									0.0,
									0.0,
									0.024,
									0.002,
									0.12,
									0.001,
									0.004,
									0.018,
									0.064,
									0.006,
									0.101,
									0.0
								],
								"e": 0.039
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.004,
									0.0,
									0.002,
									0.0,
									0.001,
									0.002,
									0.05,
									0.133,
									0.215
								],
								"e": 0.019
							},
							{
								"h": [
									0.004,
									0.0,
									0.045,
									0.011,
									0.001,
									0.002,
									0.018,
									0.005,
									0.0,
									0.0,
									0.015,
									0.0
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.005,
									0.003,
									0.0,
									0.002,
									0.004,
									0.007,
									0.052,
									0.0,
									0.0,
									0.001,
									0.008
								],
								"e": 0.003
							},
							{
								"h": [
									0.007,
									0.003,
									0.001,
									0.0,
									0.0,
									0.004,
									0.002,
									0.0,
									0.0,
									0.002,
									0.004,
									0.001
								],
								"e": 0.002
							},
							{
								"h": [
									0.003,
									0.001,
									0.002,
									0.002,
									0.001,
									0.003,
									0.001,
									0.002,
									0.001,
									0.001,
									0.001,
									0.002
								],
								"e": 0.002
							},
							{
								"h": [
									0.002,
									0.002,
									0.005,
									0.007,
									0.001,
									0.006,
									0.004,
									0.005,
									0.012,
									0.0,
									0.002,
									0.004
								],
								"e": 0.004
							},
							{
								"h": [
									0.122,
									0.011,
									0.01,
									0.012,
									0.013,
									0.005,
									0.005,
									0.007,
									0.347,
									0.004,
									0.006,
									0.0
								],
								"e": 0.021
							}
						],
						[
							{
								"h": [
									0.688,
									0.0,
									0.066,
									0.0,
									0.0,
									0.0,
									0.165,
									0.001,
									0.337,
									0.282,
									0.113,
									0.734
								],
								"e": 0.05
							},
							{
								"h": [
									0.026,
									0.067,
									0.204,
									0.018,
									0.152,
									0.076,
									0.214,
									0.412,
									0.389,
									0.131,
									0.246,
									0.053
								],
								"e": 0.201
							},
							{
								"h": [
									0.027,
									0.013,
									0.0,
									0.0,
									0.004,
									0.001,
									0.159,
									0.172,
									0.002,
									0.0,
									0.05,
									0.01
								],
								"e": 0.042
							},
							{
								"h": [
									0.01,
									0.014,
									0.0,
									0.0,
									0.036,
									0.022,
									0.0,
									0.0,
									0.0,
									0.002,
									0.016,
									0.0
								],
								"e": 0.012
							},
							{
								"h": [
									0.0,
									0.006,
									0.065,
									0.001,
									0.03,
									0.006,
									0.008,
									0.016,
									0.037,
									0.003,
									0.047,
									0.0
								],
								"e": 0.025
							},
							{
								"h": [
									0.2,
									0.0,
									0.002,
									0.028,
									0.007,
									0.001,
									0.0,
									0.001,
									0.007,
									0.041,
									0.221,
									0.057
								],
								"e": 0.03
							},
							{
								"h": [
									0.015,
									0.01,
									0.028,
									0.044,
									0.03,
									0.01,
									0.022,
									0.024,
									0.0,
									0.001,
									0.068,
									0.021
								],
								"e": 0.023
							},
							{
								"h": [
									0.001,
									0.035,
									0.037,
									0.008,
									0.002,
									0.028,
									0.031,
									0.285,
									0.0,
									0.008,
									0.001,
									0.441
								],
								"e": 0.036
							},
							{
								"h": [
									0.046,
									0.246,
									0.017,
									0.012,
									0.001,
									0.007,
									0.047,
									0.003,
									0.008,
									0.001,
									0.041,
									0.007
								],
								"e": 0.027
							},
							{
								"h": [
									0.001,
									0.005,
									0.004,
									0.017,
									0.007,
									0.019,
									0.006,
									0.03,
									0.001,
									0.002,
									0.006,
									0.272
								],
								"e": 0.019
							},
							{
								"h": [
									0.001,
									0.003,
									0.068,
									0.289,
									0.466,
									0.023,
									0.001,
									0.024,
									0.059,
									0.001,
									0.003,
									0.004
								],
								"e": 0.042
							},
							{
								"h": [
									0.429,
									0.005,
									0.008,
									0.043,
									0.037,
									0.021,
									0.005,
									0.042,
									0.202,
									0.014,
									0.047,
									0.012
								],
								"e": 0.038
							}
						],
						[
							{
								"h": [
									0.353,
									0.001,
									0.068,
									0.0,
									0.001,
									0.0,
									0.509,
									0.0,
									0.31,
									0.372,
									0.169,
									0.156
								],
								"e": 0.045
							},
							{
								"h": [
									0.006,
									0.024,
									0.247,
									0.015,
									0.174,
									0.046,
									0.021,
									0.063,
									0.283,
									0.132,
									0.269,
									0.003
								],
								"e": 0.12
							},
							{
								"h": [
									0.029,
									0.001,
									0.0,
									0.002,
									0.001,
									0.0,
									0.019,
									0.228,
									0.002,
									0.0,
									0.058,
									0.011
								],
								"e": 0.025
							},
							{
								"h": [
									0.0,
									0.002,
									0.0,
									0.0,
									0.012,
									0.008,
									0.0,
									0.0,
									0.0,
									0.001,
									0.007,
									0.0
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.001,
									0.013,
									0.004,
									0.012,
									0.002,
									0.005,
									0.004,
									0.015,
									0.011,
									0.007,
									0.0
								],
								"e": 0.008
							},
							{
								"h": [
									0.001,
									0.0,
									0.003,
									0.037,
									0.002,
									0.0,
									0.0,
									0.004,
									0.002,
									0.005,
									0.109,
									0.044
								],
								"e": 0.009
							},
							{
								"h": [
									0.011,
									0.0,
									0.012,
									0.026,
									0.003,
									0.007,
									0.009,
									0.029,
									0.001,
									0.0,
									0.006,
									0.019
								],
								"e": 0.009
							},
							{
								"h": [
									0.0,
									0.007,
									0.003,
									0.005,
									0.005,
									0.012,
									0.011,
									0.284,
									0.001,
									0.002,
									0.003,
									0.074
								],
								"e": 0.014
							},
							{
								"h": [
									0.027,
									0.002,
									0.004,
									0.003,
									0.0,
									0.006,
									0.005,
									0.0,
									0.004,
									0.001,
									0.01,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.001,
									0.002,
									0.0,
									0.002,
									0.001,
									0.001,
									0.002,
									0.0,
									0.0,
									0.001,
									0.01
								],
								"e": 0.002
							},
							{
								"h": [
									0.005,
									0.003,
									0.007,
									0.003,
									0.004,
									0.001,
									0.007,
									0.005,
									0.001,
									0.0,
									0.004,
									0.007
								],
								"e": 0.004
							},
							{
								"h": [
									0.065,
									0.01,
									0.004,
									0.001,
									0.005,
									0.002,
									0.011,
									0.003,
									0.001,
									0.003,
									0.002,
									0.005
								],
								"e": 0.006
							}
						],
						[
							{
								"h": [
									0.214,
									0.0,
									0.062,
									0.0,
									0.0,
									0.0,
									0.171,
									0.001,
									0.143,
									0.164,
									0.053,
									0.126
								],
								"e": 0.022
							},
							{
								"h": [
									0.016,
									0.013,
									0.17,
									0.013,
									0.132,
									0.108,
									0.1,
									0.204,
									0.159,
									0.103,
									0.235,
									0.001
								],
								"e": 0.121
							},
							{
								"h": [
									0.006,
									0.001,
									0.0,
									0.0,
									0.001,
									0.0,
									0.011,
									0.148,
									0.002,
									0.0,
									0.056,
									0.009
								],
								"e": 0.017
							},
							{
								"h": [
									0.001,
									0.001,
									0.0,
									0.0,
									0.01,
									0.007,
									0.0,
									0.0,
									0.0,
									0.001,
									0.01,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.001,
									0.012,
									0.004,
									0.043,
									0.003,
									0.008,
									0.006,
									0.019,
									0.008,
									0.017,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.0,
									0.002,
									0.008,
									0.002,
									0.0,
									0.0,
									0.002,
									0.0,
									0.006,
									0.049,
									0.051
								],
								"e": 0.005
							},
							{
								"h": [
									0.016,
									0.0,
									0.004,
									0.011,
									0.001,
									0.009,
									0.007,
									0.01,
									0.0,
									0.0,
									0.002,
									0.061
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.002,
									0.0,
									0.002,
									0.001,
									0.038,
									0.003,
									0.049,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.005
							},
							{
								"h": [
									0.002,
									0.0,
									0.003,
									0.002,
									0.002,
									0.019,
									0.001,
									0.001,
									0.003,
									0.0,
									0.006,
									0.0
								],
								"e": 0.003
							},
							{
								"h": [
									0.001,
									0.0,
									0.003,
									0.005,
									0.002,
									0.002,
									0.0,
									0.004,
									0.001,
									0.0,
									0.002,
									0.0
								],
								"e": 0.001
							},
							{
								"h": [
									0.0,
									0.0,
									0.001,
									0.001,
									0.007,
									0.003,
									0.0,
									0.001,
									0.0,
									0.001,
									0.0,
									0.001
								],
								"e": 0.001
							},
							{
								"h": [
									0.109,
									0.002,
									0.0,
									0.006,
									0.002,
									0.001,
									0.001,
									0.001,
									0.012,
									0.001,
									0.007,
									0.008
								],
								"e": 0.005
							}
						],
						[
							{
								"h": [
									0.524,
									0.0,
									0.067,
									0.0,
									0.0,
									0.0,
									0.217,
									0.002,
									0.283,
									0.344,
									0.114,
									0.426
								],
								"e": 0.043
							},
							{
								"h": [
									0.043,
									0.063,
									0.231,
									0.019,
									0.16,
									0.174,
									0.166,
									0.299,
									0.323,
									0.136,
									0.263,
									0.07
								],
								"e": 0.209
							},
							{
								"h": [
									0.046,
									0.014,
									0.0,
									0.001,
									0.005,
									0.002,
									0.087,
									0.231,
									0.006,
									0.0,
									0.056,
									0.011
								],
								"e": 0.04
							},
							{
								"h": [
									0.002,
									0.008,
									0.0,
									0.0,
									0.029,
									0.007,
									0.0,
									0.0,
									0.001,
									0.002,
									0.015,
									0.0
								],
								"e": 0.007
							},
							{
								"h": [
									0.0,
									0.005,
									0.024,
									0.005,
									0.025,
									0.002,
									0.01,
									0.007,
									0.011,
									0.003,
									0.02,
									0.0
								],
								"e": 0.013
							},
							{
								"h": [
									0.0,
									0.0,
									0.004,
									0.036,
									0.004,
									0.007,
									0.0,
									0.0,
									0.012,
									0.01,
									0.039,
									0.057
								],
								"e": 0.009
							},
							{
								"h": [
									0.051,
									0.0,
									0.017,
									0.016,
									0.01,
									0.005,
									0.008,
									0.013,
									0.001,
									0.0,
									0.002,
									0.02
								],
								"e": 0.01
							},
							{
								"h": [
									0.002,
									0.004,
									0.002,
									0.004,
									0.013,
									0.008,
									0.011,
									0.021,
									0.0,
									0.007,
									0.002,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.001,
									0.003,
									0.006,
									0.0,
									0.02,
									0.002,
									0.009,
									0.001,
									0.002,
									0.016,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.003,
									0.001,
									0.003,
									0.004,
									0.003,
									0.001,
									0.002,
									0.0,
									0.002,
									0.037,
									0.006
								],
								"e": 0.003
							},
							{
								"h": [
									0.0,
									0.001,
									0.004,
									0.008,
									0.016,
									0.046,
									0.0,
									0.005,
									0.023,
									0.001,
									0.0,
									0.001
								],
								"e": 0.006
							},
							{
								"h": [
									0.353,
									0.003,
									0.002,
									0.002,
									0.005,
									0.007,
									0.002,
									0.007,
									0.048,
									0.004,
									0.009,
									0.0
								],
								"e": 0.012
							}
						],
						[
							{
								"h": [
									0.366,
									0.0,
									0.11,
									0.0,
									0.0,
									0.0,
									0.376,
									0.002,
									0.321,
									0.307,
									0.123,
									0.174
								],
								"e": 0.042
							},
							{
								"h": [
									0.105,
									0.071,
									0.241,
									0.022,
									0.165,
									0.063,
									0.918,
									0.577,
									0.348,
									0.125,
									0.37,
									0.007
								],
								"e": 0.301
							},
							{
								"h": [
									0.051,
									0.006,
									0.0,
									0.033,
									0.012,
									0.002,
									0.124,
									0.245,
									0.012,
									0.001,
									0.093,
									0.021
								],
								"e": 0.057
							},
							{
								"h": [
									0.01,
									0.016,
									0.002,
									0.0,
									0.051,
									0.077,
									0.0,
									0.0,
									0.0,
									0.007,
									0.015,
									0.003
								],
								"e": 0.024
							},
							{
								"h": [
									0.002,
									0.007,
									0.026,
									0.005,
									0.008,
									0.018,
									0.017,
									0.014,
									0.048,
									0.007,
									0.027,
									0.0
								],
								"e": 0.02
							},
							{
								"h": [
									0.001,
									0.0,
									0.007,
									0.028,
									0.013,
									0.038,
									0.0,
									0.0,
									0.019,
									0.025,
									0.072,
									0.047
								],
								"e": 0.015
							},
							{
								"h": [
									0.01,
									0.003,
									0.054,
									0.031,
									0.008,
									0.006,
									0.008,
									0.019,
									0.002,
									0.0,
									0.021,
									0.015
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.033,
									0.005,
									0.005,
									0.004,
									0.01,
									0.037,
									0.039,
									0.001,
									0.003,
									0.011,
									0.003
								],
								"e": 0.011
							},
							{
								"h": [
									0.003,
									0.023,
									0.006,
									0.001,
									0.001,
									0.015,
									0.01,
									0.004,
									0.001,
									0.005,
									0.007,
									0.002
								],
								"e": 0.005
							},
							{
								"h": [
									0.001,
									0.005,
									0.006,
									0.003,
									0.001,
									0.001,
									0.003,
									0.006,
									0.0,
									0.002,
									0.02,
									0.013
								],
								"e": 0.004
							},
							{
								"h": [
									0.0,
									0.001,
									0.002,
									0.005,
									0.005,
									0.03,
									0.0,
									0.003,
									0.017,
									0.001,
									0.001,
									0.001
								],
								"e": 0.004
							},
							{
								"h": [
									0.057,
									0.004,
									0.001,
									0.004,
									0.004,
									0.009,
									0.003,
									0.01,
									0.023,
									0.001,
									0.009,
									0.0
								],
								"e": 0.007
							}
						],
						[
							{
								"h": [
									0.442,
									0.001,
									0.042,
									0.0,
									0.0,
									0.0,
									0.215,
									0.0,
									0.459,
									0.261,
									0.22,
									0.166
								],
								"e": 0.041
							},
							{
								"h": [
									0.079,
									0.079,
									0.315,
									0.022,
									0.233,
									0.216,
									0.077,
									0.139,
									0.408,
									0.178,
									0.435,
									0.044
								],
								"e": 0.233
							},
							{
								"h": [
									0.049,
									0.001,
									0.0,
									0.004,
									0.004,
									0.002,
									0.042,
									0.346,
									0.007,
									0.006,
									0.205,
									0.029
								],
								"e": 0.055
							},
							{
								"h": [
									0.002,
									0.006,
									0.0,
									0.0,
									0.1,
									0.024,
									0.002,
									0.004,
									0.001,
									0.004,
									0.072,
									0.007
								],
								"e": 0.025
							},
							{
								"h": [
									0.0,
									0.002,
									0.029,
									0.011,
									0.07,
									0.008,
									0.024,
									0.055,
									0.049,
									0.015,
									0.062,
									0.0
								],
								"e": 0.036
							},
							{
								"h": [
									0.005,
									0.0,
									0.006,
									0.01,
									0.004,
									0.001,
									0.0,
									0.002,
									0.001,
									0.02,
									0.122,
									0.141
								],
								"e": 0.014
							},
							{
								"h": [
									0.009,
									0.0,
									0.023,
									0.012,
									0.002,
									0.006,
									0.05,
									0.02,
									0.003,
									0.0,
									0.028,
									0.001
								],
								"e": 0.014
							},
							{
								"h": [
									0.001,
									0.048,
									0.001,
									0.003,
									0.003,
									0.01,
									0.015,
									0.071,
									0.001,
									0.0,
									0.0,
									0.005
								],
								"e": 0.009
							},
							{
								"h": [
									0.004,
									0.002,
									0.001,
									0.0,
									0.0,
									0.012,
									0.001,
									0.001,
									0.003,
									0.005,
									0.012,
									0.002
								],
								"e": 0.003
							},
							{
								"h": [
									0.006,
									0.001,
									0.005,
									0.005,
									0.001,
									0.003,
									0.001,
									0.003,
									0.001,
									0.001,
									0.004,
									0.001
								],
								"e": 0.003
							},
							{
								"h": [
									0.005,
									0.002,
									0.003,
									0.007,
									0.001,
									0.017,
									0.006,
									0.003,
									0.009,
									0.002,
									0.004,
									0.004
								],
								"e": 0.004
							},
							{
								"h": [
									0.107,
									0.005,
									0.005,
									0.016,
									0.009,
									0.004,
									0.003,
									0.001,
									0.359,
									0.004,
									0.003,
									0.0
								],
								"e": 0.019
							}
						],
						[
							{
								"h": [
									0.769,
									0.0,
									0.068,
									0.0,
									0.0,
									0.0,
									0.178,
									0.004,
									0.402,
									0.322,
									0.122,
									0.858
								],
								"e": 0.058
							},
							{
								"h": [
									0.096,
									0.269,
									0.377,
									0.033,
									0.221,
									0.083,
									0.209,
									0.398,
									0.385,
									0.116,
									0.434,
									0.082
								],
								"e": 0.296
							},
							{
								"h": [
									0.13,
									0.008,
									0.0,
									0.004,
									0.049,
									0.021,
									0.456,
									0.303,
									0.024,
									0.002,
									0.141,
									0.028
								],
								"e": 0.115
							},
							{
								"h": [
									0.008,
									0.141,
									0.001,
									0.001,
									0.04,
									0.024,
									0.003,
									0.001,
									0.012,
									0.026,
									0.066,
									0.008
								],
								"e": 0.033
							},
							{
								"h": [
									0.004,
									0.047,
									0.093,
									0.003,
									0.041,
									0.052,
									0.032,
									0.034,
									0.026,
									0.014,
									0.025,
									0.0
								],
								"e": 0.038
							},
							{
								"h": [
									0.413,
									0.001,
									0.018,
									0.093,
									0.03,
									0.026,
									0.0,
									0.002,
									0.16,
									0.029,
									0.153,
									0.057
								],
								"e": 0.058
							},
							{
								"h": [
									0.06,
									0.039,
									0.035,
									0.077,
									0.019,
									0.029,
									0.043,
									0.039,
									0.002,
									0.026,
									0.109,
									0.071
								],
								"e": 0.044
							},
							{
								"h": [
									0.008,
									0.103,
									0.305,
									0.037,
									0.009,
									0.031,
									0.058,
									1.0,
									0.001,
									0.012,
									0.012,
									0.568
								],
								"e": 0.088
							},
							{
								"h": [
									0.061,
									0.659,
									0.017,
									0.012,
									0.003,
									0.028,
									0.042,
									0.018,
									0.015,
									0.002,
									0.074,
									0.016
								],
								"e": 0.051
							},
							{
								"h": [
									0.001,
									0.008,
									0.007,
									0.037,
									0.007,
									0.022,
									0.011,
									0.039,
									0.002,
									0.005,
									0.019,
									0.339
								],
								"e": 0.026
							},
							{
								"h": [
									0.001,
									0.004,
									0.092,
									0.347,
									0.34,
									0.056,
									0.001,
									0.029,
									0.058,
									0.003,
									0.004,
									0.005
								],
								"e": 0.044
							},
							{
								"h": [
									0.454,
									0.005,
									0.007,
									0.057,
									0.034,
									0.017,
									0.005,
									0.031,
									0.219,
									0.012,
									0.046,
									0.014
								],
								"e": 0.039
							}
						],
						[
							{
								"h": [
									0.712,
									0.0,
									0.174,
									0.0,
									0.0,
									0.0,
									0.277,
									0.001,
									0.343,
									0.224,
									0.072,
									0.163
								],
								"e": 0.043
							},
							{
								"h": [
									0.075,
									0.075,
									0.404,
									0.025,
									0.238,
									0.183,
									0.053,
									0.154,
									0.334,
									0.131,
									0.429,
									0.005
								],
								"e": 0.211
							},
							{
								"h": [
									0.077,
									0.001,
									0.001,
									0.021,
									0.014,
									0.008,
									0.069,
									0.395,
									0.017,
									0.019,
									0.202,
									0.041
								],
								"e": 0.076
							},
							{
								"h": [
									0.0,
									0.067,
									0.004,
									0.001,
									0.02,
									0.029,
									0.003,
									0.009,
									0.004,
									0.013,
									0.038,
									0.027
								],
								"e": 0.024
							},
							{
								"h": [
									0.002,
									0.035,
									0.025,
									0.04,
									0.077,
									0.078,
									0.057,
									0.018,
									0.037,
									0.074,
									0.011,
									0.0
								],
								"e": 0.043
							},
							{
								"h": [
									0.001,
									0.0,
									0.01,
									0.18,
									0.015,
									0.016,
									0.001,
									0.02,
									0.04,
									0.023,
									0.114,
									0.077
								],
								"e": 0.027
							},
							{
								"h": [
									0.064,
									0.001,
									0.015,
									0.061,
									0.004,
									0.014,
									0.015,
									0.064,
									0.001,
									0.0,
									0.006,
									0.154
								],
								"e": 0.024
							},
							{
								"h": [
									0.002,
									0.011,
									0.021,
									0.026,
									0.008,
									0.036,
									0.014,
									0.447,
									0.001,
									0.025,
									0.009,
									0.009
								],
								"e": 0.024
							},
							{
								"h": [
									0.011,
									0.001,
									0.004,
									0.005,
									0.007,
									0.06,
									0.005,
									0.01,
									0.011,
									0.001,
									0.031,
									0.001
								],
								"e": 0.01
							},
							{
								"h": [
									0.004,
									0.001,
									0.008,
									0.012,
									0.003,
									0.003,
									0.003,
									0.011,
									0.002,
									0.002,
									0.011,
									0.004
								],
								"e": 0.005
							},
							{
								"h": [
									0.009,
									0.006,
									0.012,
									0.006,
									0.009,
									0.018,
									0.012,
									0.002,
									0.001,
									0.007,
									0.004,
									0.021
								],
								"e": 0.009
							},
							{
								"h": [
									0.067,
									0.026,
									0.006,
									0.005,
									0.005,
									0.003,
									0.012,
									0.005,
									0.006,
									0.01,
									0.003,
									0.029
								],
								"e": 0.013
							}
						],
						[
							{
								"h": [
									0.602,
									0.0,
									0.069,
									0.0,
									0.0,
									0.0,
									0.236,
									0.007,
									0.324,
									0.377,
									0.117,
									0.468
								],
								"e": 0.049
							},
							{
								"h": [
									0.141,
									0.211,
									0.365,
									0.029,
									0.216,
									0.122,
									0.147,
									0.337,
									0.31,
									0.116,
									0.407,
									0.064
								],
								"e": 0.272
							},
							{
								"h": [
									0.185,
									0.017,
									0.001,
									0.014,
									0.053,
									0.032,
									0.225,
									0.36,
									0.06,
									0.002,
									0.143,
									0.028
								],
								"e": 0.11
							},
							{
								"h": [
									0.001,
									0.105,
									0.005,
									0.003,
									0.022,
									0.015,
									0.002,
									0.002,
									0.032,
									0.023,
									0.06,
									0.006
								],
								"e": 0.027
							},
							{
								"h": [
									0.021,
									0.072,
									0.054,
									0.072,
									0.033,
									0.056,
									0.077,
									0.012,
									0.016,
									0.033,
									0.031,
									0.0
								],
								"e": 0.046
							},
							{
								"h": [
									0.0,
									0.0,
									0.016,
									0.326,
									0.034,
									0.0,
									0.0,
									0.001,
									0.002,
									0.008,
									0.048,
									0.061
								],
								"e": 0.024
							},
							{
								"h": [
									0.283,
									0.0,
									0.019,
									0.072,
									0.017,
									0.016,
									0.007,
									0.061,
									0.004,
									0.0,
									0.002,
									0.126
								],
								"e": 0.035
							},
							{
								"h": [
									0.028,
									0.001,
									0.0,
									0.012,
									0.039,
									0.013,
									0.008,
									0.024,
									0.001,
									0.03,
									0.0,
									0.001
								],
								"e": 0.013
							},
							{
								"h": [
									0.004,
									0.001,
									0.002,
									0.007,
									0.006,
									0.095,
									0.001,
									0.12,
									0.003,
									0.003,
									0.032,
									0.001
								],
								"e": 0.017
							},
							{
								"h": [
									0.0,
									0.005,
									0.001,
									0.019,
									0.006,
									0.005,
									0.001,
									0.005,
									0.0,
									0.002,
									0.205,
									0.007
								],
								"e": 0.011
							},
							{
								"h": [
									0.0,
									0.001,
									0.004,
									0.006,
									0.03,
									0.117,
									0.0,
									0.004,
									0.009,
									0.008,
									0.0,
									0.001
								],
								"e": 0.008
							},
							{
								"h": [
									0.352,
									0.003,
									0.002,
									0.004,
									0.004,
									0.006,
									0.002,
									0.006,
									0.048,
									0.003,
									0.01,
									0.001
								],
								"e": 0.012
							}
						],
						[
							{
								"h": [
									0.51,
									0.0,
									0.212,
									0.0,
									0.002,
									0.0,
									0.61,
									0.021,
									0.522,
									0.045,
									0.207,
									0.302
								],
								"e": 0.062
							},
							{
								"h": [
									0.295,
									0.153,
									0.546,
									0.036,
									0.393,
									0.093,
									0.451,
									0.503,
									0.225,
									0.069,
									0.607,
									0.089
								],
								"e": 0.38
							},
							{
								"h": [
									0.383,
									0.005,
									0.002,
									0.032,
									0.021,
									0.011,
									0.054,
									0.382,
									0.024,
									0.086,
									0.317,
									0.058
								],
								"e": 0.135
							},
							{
								"h": [
									0.001,
									0.058,
									0.016,
									0.013,
									0.009,
									0.058,
									0.067,
									0.017,
									0.015,
									0.048,
									0.078,
									0.031
								],
								"e": 0.051
							},
							{
								"h": [
									0.011,
									0.022,
									0.05,
									0.079,
									0.036,
									0.029,
									0.079,
									0.069,
									0.037,
									0.071,
									0.031,
									0.0
								],
								"e": 0.052
							},
							{
								"h": [
									0.002,
									0.0,
									0.04,
									0.158,
									0.015,
									0.009,
									0.002,
									0.006,
									0.017,
									0.03,
									0.206,
									0.375
								],
								"e": 0.04
							},
							{
								"h": [
									0.082,
									0.005,
									0.056,
									0.069,
									0.003,
									0.009,
									0.031,
									0.04,
									0.015,
									0.0,
									0.036,
									0.009
								],
								"e": 0.026
							},
							{
								"h": [
									0.008,
									0.006,
									0.002,
									0.006,
									0.025,
									0.032,
									0.03,
									0.079,
									0.003,
									0.001,
									0.003,
									0.008
								],
								"e": 0.013
							},
							{
								"h": [
									0.001,
									0.004,
									0.001,
									0.0,
									0.0,
									0.058,
									0.01,
									0.008,
									0.002,
									0.003,
									0.006,
									0.002
								],
								"e": 0.006
							},
							{
								"h": [
									0.005,
									0.002,
									0.005,
									0.022,
									0.002,
									0.001,
									0.001,
									0.003,
									0.002,
									0.002,
									0.009,
									0.003
								],
								"e": 0.004
							},
							{
								"h": [
									0.007,
									0.002,
									0.007,
									0.005,
									0.002,
									0.062,
									0.002,
									0.001,
									0.002,
									0.026,
									0.002,
									0.003
								],
								"e": 0.008
							},
							{
								"h": [
									0.127,
									0.019,
									0.005,
									0.019,
									0.004,
									0.003,
									0.005,
									0.003,
									0.198,
									0.004,
									0.002,
									0.0
								],
								"e": 0.015
							}
						],
						[
							{
								"h": [
									0.938,
									0.007,
									0.385,
									0.002,
									0.051,
									0.0,
									0.588,
									0.002,
									0.921,
									0.143,
									0.296,
									0.324
								],
								"e": 0.098
							},
							{
								"h": [
									0.172,
									0.105,
									0.714,
									0.043,
									0.458,
									0.243,
									0.702,
									0.454,
									0.262,
									0.147,
									0.648,
									0.335
								],
								"e": 0.505
							},
							{
								"h": [
									0.112,
									0.005,
									0.008,
									0.036,
									0.018,
									0.021,
									0.066,
									0.385,
									0.074,
									0.074,
									0.426,
									0.063
								],
								"e": 0.132
							},
							{
								"h": [
									0.017,
									0.066,
									0.008,
									0.01,
									0.007,
									0.086,
									0.018,
									0.037,
									0.029,
									0.034,
									0.267,
									0.037
								],
								"e": 0.067
							},
							{
								"h": [
									0.012,
									0.023,
									0.062,
									0.064,
									0.082,
									0.052,
									0.09,
									0.252,
									0.061,
									0.085,
									0.096,
									0.0
								],
								"e": 0.094
							},
							{
								"h": [
									0.057,
									0.0,
									0.014,
									0.043,
									0.02,
									0.002,
									0.0,
									0.004,
									0.016,
									0.015,
									0.271,
									0.14
								],
								"e": 0.027
							},
							{
								"h": [
									0.045,
									0.018,
									0.031,
									0.017,
									0.004,
									0.021,
									0.03,
									0.083,
									0.003,
									0.0,
									0.043,
									0.013
								],
								"e": 0.025
							},
							{
								"h": [
									0.002,
									0.008,
									0.004,
									0.01,
									0.006,
									0.008,
									0.037,
									0.045,
									0.002,
									0.003,
									0.002,
									0.035
								],
								"e": 0.01
							},
							{
								"h": [
									0.022,
									0.017,
									0.001,
									0.0,
									0.002,
									0.045,
									0.042,
									0.004,
									0.02,
									0.004,
									0.02,
									0.003
								],
								"e": 0.013
							},
							{
								"h": [
									0.005,
									0.003,
									0.006,
									0.027,
									0.001,
									0.002,
									0.004,
									0.012,
									0.002,
									0.003,
									0.024,
									0.009
								],
								"e": 0.006
							},
							{
								"h": [
									0.007,
									0.006,
									0.012,
									0.024,
									0.003,
									0.044,
									0.006,
									0.003,
									0.013,
									0.01,
									0.005,
									0.015
								],
								"e": 0.01
							},
							{
								"h": [
									0.151,
									0.019,
									0.013,
									0.015,
									0.022,
									0.007,
									0.012,
									0.016,
									0.228,
									0.027,
									0.004,
									0.001
								],
								"e": 0.025
							}
						],
						[
							{
								"h": [
									0.865,
									0.0,
									0.068,
									0.0,
									0.0,
									0.0,
									0.194,
									0.021,
									0.467,
									0.363,
									0.125,
									1.0
								],
								"e": 0.068
							},
							{
								"h": [
									0.363,
									1.0,
									0.645,
									0.054,
									0.326,
									0.081,
									0.209,
									0.461,
									0.388,
									0.099,
									0.676,
									0.096
								],
								"e": 0.537
							},
							{
								"h": [
									0.505,
									0.007,
									0.031,
									0.064,
									0.546,
									0.401,
									1.0,
									0.44,
									0.286,
									0.044,
									0.324,
									0.071
								],
								"e": 0.446
							},
							{
								"h": [
									0.006,
									1.0,
									0.044,
									0.062,
									0.035,
									0.045,
									0.052,
									0.031,
									0.387,
									0.16,
									0.183,
									0.076
								],
								"e": 0.206
							},
							{
								"h": [
									0.056,
									0.568,
									0.16,
									0.031,
									0.031,
									0.391,
									0.15,
									0.052,
									0.023,
									0.064,
									0.031,
									0.0
								],
								"e": 0.139
							},
							{
								"h": [
									1.0,
									0.002,
									0.1,
									0.472,
									0.243,
									0.034,
									0.001,
									0.008,
									0.896,
									0.044,
									0.225,
									0.09
								],
								"e": 0.193
							},
							{
								"h": [
									0.421,
									0.364,
									0.048,
									0.27,
									0.023,
									0.081,
									0.053,
									0.178,
									0.01,
									0.009,
									0.099,
									0.663
								],
								"e": 0.15
							},
							{
								"h": [
									0.039,
									0.019,
									0.091,
									0.115,
									0.026,
									0.044,
									0.048,
									0.679,
									0.007,
									0.074,
									0.012,
									1.0
								],
								"e": 0.095
							},
							{
								"h": [
									0.061,
									1.0,
									0.018,
									0.023,
									0.014,
									0.084,
									0.078,
									0.081,
									0.04,
									0.002,
									0.085,
									0.033
								],
								"e": 0.083
							},
							{
								"h": [
									0.001,
									0.012,
									0.01,
									0.279,
									0.007,
									0.019,
									0.009,
									0.078,
									0.002,
									0.006,
									0.094,
									0.489
								],
								"e": 0.052
							},
							{
								"h": [
									0.001,
									0.004,
									0.088,
									0.36,
									0.587,
									0.179,
									0.001,
									0.027,
									0.056,
									0.011,
									0.004,
									0.005
								],
								"e": 0.057
							},
							{
								"h": [
									0.398,
									0.005,
									0.007,
									0.077,
									0.037,
									0.02,
									0.005,
									0.041,
									0.22,
									0.014,
									0.048,
									0.017
								],
								"e": 0.042
							}
						],
						[
							{
								"h": [
									0.435,
									0.001,
									0.071,
									0.001,
									0.008,
									0.0,
									0.618,
									0.005,
									0.414,
									0.477,
									0.184,
									0.206
								],
								"e": 0.058
							},
							{
								"h": [
									0.074,
									0.268,
									0.687,
									0.04,
									0.363,
									0.047,
									0.015,
									0.061,
									0.327,
									0.126,
									0.62,
									0.003
								],
								"e": 0.266
							},
							{
								"h": [
									0.485,
									0.001,
									0.068,
									0.241,
									0.119,
									0.097,
									0.116,
									0.532,
									0.263,
									0.1,
									0.322,
									0.071
								],
								"e": 0.281
							},
							{
								"h": [
									0.0,
									0.189,
									0.232,
									0.044,
									0.011,
									0.024,
									0.078,
									0.115,
									0.143,
									0.076,
									0.08,
									0.067
								],
								"e": 0.126
							},
							{
								"h": [
									0.048,
									0.332,
									0.05,
									0.37,
									0.017,
									0.237,
									0.14,
									0.027,
									0.014,
									0.287,
									0.007,
									0.0
								],
								"e": 0.134
							},
							{
								"h": [
									0.0,
									0.0,
									0.126,
									0.753,
									0.145,
									0.002,
									0.001,
									0.095,
									0.055,
									0.008,
									0.137,
									0.069
								],
								"e": 0.082
							},
							{
								"h": [
									0.178,
									0.004,
									0.019,
									0.12,
									0.002,
									0.046,
									0.012,
									0.141,
									0.027,
									0.0,
									0.004,
									0.273
								],
								"e": 0.05
							},
							{
								"h": [
									0.018,
									0.005,
									0.002,
									0.062,
									0.044,
									0.019,
									0.011,
									0.283,
									0.01,
									0.027,
									0.003,
									0.015
								],
								"e": 0.026
							},
							{
								"h": [
									0.03,
									0.003,
									0.004,
									0.008,
									0.005,
									0.093,
									0.004,
									0.014,
									0.015,
									0.001,
									0.036,
									0.001
								],
								"e": 0.015
							},
							{
								"h": [
									0.002,
									0.001,
									0.007,
									0.021,
									0.002,
									0.001,
									0.001,
									0.007,
									0.001,
									0.001,
									0.017,
									0.005
								],
								"e": 0.004
							},
							{
								"h": [
									0.006,
									0.002,
									0.008,
									0.003,
									0.005,
									0.027,
									0.006,
									0.005,
									0.001,
									0.01,
									0.006,
									0.004
								],
								"e": 0.006
							},
							{
								"h": [
									0.062,
									0.01,
									0.003,
									0.002,
									0.005,
									0.001,
									0.009,
									0.003,
									0.001,
									0.003,
									0.002,
									0.039
								],
								"e": 0.01
							}
						],
						[
							{
								"h": [
									0.254,
									0.0,
									0.064,
									0.0,
									0.001,
									0.0,
									0.2,
									0.02,
									0.186,
									0.197,
									0.057,
									0.157
								],
								"e": 0.029
							},
							{
								"h": [
									0.232,
									0.185,
									0.524,
									0.035,
									0.3,
									0.121,
									0.095,
									0.217,
									0.173,
									0.081,
									0.585,
									0.001
								],
								"e": 0.273
							},
							{
								"h": [
									0.118,
									0.001,
									0.078,
									0.07,
									0.119,
									0.155,
									0.076,
									0.339,
									0.371,
									0.16,
									0.34,
									0.062
								],
								"e": 0.238
							},
							{
								"h": [
									0.001,
									0.148,
									0.097,
									0.059,
									0.008,
									0.019,
									0.015,
									0.228,
									0.16,
									0.088,
									0.108,
									0.088
								],
								"e": 0.116
							},
							{
								"h": [
									0.057,
									0.133,
									0.033,
									0.257,
									0.043,
									0.297,
									0.207,
									0.035,
									0.017,
									0.176,
									0.017,
									0.0
								],
								"e": 0.112
							},
							{
								"h": [
									0.0,
									0.0,
									0.074,
									0.19,
									0.103,
									0.0,
									0.002,
									0.067,
									0.001,
									0.009,
									0.056,
									0.078
								],
								"e": 0.039
							},
							{
								"h": [
									0.218,
									0.001,
									0.008,
									0.054,
									0.002,
									0.06,
									0.009,
									0.069,
									0.019,
									0.0,
									0.001,
									1.0
								],
								"e": 0.069
							},
							{
								"h": [
									0.009,
									0.002,
									0.0,
									0.022,
									0.013,
									0.08,
									0.004,
									0.026,
									0.003,
									0.009,
									0.0,
									0.0
								],
								"e": 0.014
							},
							{
								"h": [
									0.003,
									0.0,
									0.002,
									0.006,
									0.059,
									0.314,
									0.001,
									0.055,
									0.02,
									0.001,
									0.029,
									0.001
								],
								"e": 0.03
							},
							{
								"h": [
									0.001,
									0.0,
									0.007,
									0.229,
									0.002,
									0.004,
									0.0,
									0.013,
									0.002,
									0.0,
									0.042,
									0.0
								],
								"e": 0.016
							},
							{
								"h": [
									0.001,
									0.0,
									0.001,
									0.001,
									0.012,
									0.082,
									0.0,
									0.001,
									0.0,
									0.032,
									0.0,
									0.001
								],
								"e": 0.007
							},
							{
								"h": [
									0.15,
									0.002,
									0.001,
									0.007,
									0.003,
									0.001,
									0.001,
									0.001,
									0.002,
									0.001,
									0.008,
									1.0
								],
								"e": 0.123
							}
						],
						[
							{
								"h": [
									0.703,
									0.0,
									0.071,
									0.0,
									0.001,
									0.0,
									0.267,
									0.051,
									0.385,
									0.442,
									0.124,
									0.564
								],
								"e": 0.065
							},
							{
								"h": [
									0.648,
									0.876,
									0.681,
									0.052,
									0.347,
									0.129,
									0.141,
									0.385,
									0.304,
									0.086,
									0.659,
									0.092
								],
								"e": 0.558
							},
							{
								"h": [
									0.87,
									0.011,
									0.267,
									0.262,
									0.839,
									1.0,
									0.535,
									0.558,
									1.0,
									0.063,
									0.385,
									0.082
								],
								"e": 0.784
							},
							{
								"h": [
									0.001,
									0.534,
									0.175,
									0.144,
									0.017,
									0.017,
									0.036,
									0.053,
									1.0,
									0.137,
									0.155,
									0.057
								],
								"e": 0.244
							},
							{
								"h": [
									0.328,
									1.0,
									0.049,
									0.484,
									0.028,
									0.434,
									0.338,
									0.04,
									0.01,
									0.065,
									0.021,
									0.0
								],
								"e": 0.25
							},
							{
								"h": [
									0.0,
									0.0,
									0.194,
									1.0,
									0.339,
									0.043,
									0.002,
									0.004,
									0.206,
									0.012,
									0.039,
									0.095
								],
								"e": 0.113
							},
							{
								"h": [
									1.0,
									0.002,
									0.027,
									0.112,
									0.011,
									0.124,
									0.021,
									0.067,
									0.127,
									0.0,
									0.004,
									0.78
								],
								"e": 0.121
							},
							{
								"h": [
									0.281,
									0.003,
									0.001,
									0.088,
									0.203,
									0.014,
									0.011,
									0.016,
									0.004,
									0.224,
									0.005,
									0.001
								],
								"e": 0.077
							},
							{
								"h": [
									0.002,
									0.003,
									0.004,
									0.012,
									0.021,
									0.511,
									0.004,
									1.0,
									0.008,
									0.005,
									0.065,
									0.002
								],
								"e": 0.096
							},
							{
								"h": [
									0.0,
									0.006,
									0.002,
									0.117,
									0.007,
									0.006,
									0.001,
									0.008,
									0.0,
									0.004,
									1.0,
									0.014
								],
								"e": 0.048
							},
							{
								"h": [
									0.001,
									0.001,
									0.008,
									0.009,
									0.042,
									0.925,
									0.0,
									0.008,
									0.033,
									0.059,
									0.001,
									0.001
								],
								"e": 0.048
							},
							{
								"h": [
									0.35,
									0.002,
									0.003,
									0.006,
									0.005,
									0.006,
									0.002,
									0.01,
									0.05,
									0.005,
									0.016,
									0.001
								],
								"e": 0.013
							}
						],
						[
							{
								"h": [
									0.783,
									0.013,
									0.263,
									0.117,
									0.145,
									0.009,
									1.0,
									1.0,
									1.0,
									0.106,
									0.626,
									0.273
								],
								"e": 0.355
							},
							{
								"h": [
									1.0,
									0.627,
									0.838,
									0.056,
									0.532,
									0.162,
									1.0,
									0.322,
									0.338,
									0.192,
									0.83,
									0.179
								],
								"e": 0.764
							},
							{
								"h": [
									0.901,
									0.001,
									0.933,
									1.0,
									0.551,
									0.127,
									0.183,
									0.516,
									0.687,
									1.0,
									0.67,
									0.112
								],
								"e": 0.993
							},
							{
								"h": [
									0.026,
									0.188,
									1.0,
									0.733,
									0.008,
									0.089,
									1.0,
									0.694,
									0.139,
									0.344,
									0.398,
									1.0
								],
								"e": 0.76
							},
							{
								"h": [
									0.103,
									0.139,
									0.027,
									1.0,
									0.156,
									0.954,
									0.293,
									0.172,
									0.012,
									0.197,
									0.041,
									1.0
								],
								"e": 0.507
							},
							{
								"h": [
									0.002,
									0.001,
									1.0,
									0.218,
									0.144,
									0.042,
									0.099,
									0.03,
									0.09,
									0.012,
									0.493,
									0.958
								],
								"e": 0.19
							},
							{
								"h": [
									0.403,
									0.026,
									0.048,
									0.148,
									0.002,
									0.044,
									0.039,
									0.102,
									1.0,
									0.007,
									0.033,
									0.036
								],
								"e": 0.14
							},
							{
								"h": [
									0.449,
									0.009,
									0.005,
									0.063,
									0.053,
									0.036,
									0.032,
									0.118,
									0.079,
									0.005,
									0.023,
									0.007
								],
								"e": 0.069
							},
							{
								"h": [
									0.001,
									0.003,
									0.001,
									0.002,
									0.003,
									0.246,
									0.006,
									0.15,
									0.013,
									0.012,
									0.052,
									0.004
								],
								"e": 0.029
							},
							{
								"h": [
									0.023,
									0.002,
									0.009,
									0.156,
									0.001,
									0.002,
									0.003,
									0.006,
									0.005,
									0.002,
									0.428,
									0.003
								],
								"e": 0.03
							},
							{
								"h": [
									0.01,
									0.002,
									0.004,
									0.01,
									0.001,
									0.302,
									0.004,
									0.002,
									0.002,
									0.087,
									0.004,
									0.003
								],
								"e": 0.024
							},
							{
								"h": [
									0.091,
									0.005,
									0.002,
									0.017,
									0.007,
									0.001,
									0.003,
									0.003,
									0.055,
									0.005,
									0.003,
									0.0
								],
								"e": 0.008
							}
						],
						[
							{
								"h": [
									0.978,
									1.0,
									1.0,
									1.0,
									1.0,
									1.0,
									0.515,
									0.017,
									0.69,
									0.018,
									1.0,
									0.669
								],
								"e": 1.0
							},
							{
								"h": [
									0.348,
									0.27,
									1.0,
									0.06,
									0.556,
									0.168,
									0.248,
									0.144,
									0.399,
									0.333,
									1.0,
									1.0
								],
								"e": 0.783
							},
							{
								"h": [
									0.254,
									0.001,
									1.0,
									0.307,
									0.105,
									0.284,
									0.129,
									0.604,
									0.543,
									0.601,
									1.0,
									0.154
								],
								"e": 0.712
							},
							{
								"h": [
									0.222,
									0.175,
									0.308,
									0.283,
									0.005,
									0.069,
									0.26,
									1.0,
									0.384,
									0.159,
									0.827,
									0.096
								],
								"e": 0.45
							},
							{
								"h": [
									0.063,
									0.137,
									0.022,
									0.448,
									0.181,
									0.358,
									0.263,
									1.0,
									0.024,
									0.168,
									0.129,
									0.001
								],
								"e": 0.275
							},
							{
								"h": [
									0.025,
									0.006,
									0.052,
									0.045,
									0.082,
									0.025,
									0.018,
									0.018,
									0.053,
									0.004,
									0.41,
									0.123
								],
								"e": 0.047
							},
							{
								"h": [
									0.145,
									0.036,
									0.017,
									0.022,
									0.002,
									0.074,
									0.047,
									0.121,
									0.058,
									0.003,
									0.047,
									0.061
								],
								"e": 0.047
							},
							{
								"h": [
									0.023,
									0.03,
									0.006,
									0.045,
									0.006,
									0.011,
									0.034,
									0.039,
									0.011,
									0.005,
									0.006,
									0.009
								],
								"e": 0.018
							},
							{
								"h": [
									0.01,
									0.013,
									0.001,
									0.001,
									0.008,
									0.09,
									0.017,
									0.038,
									0.032,
									0.015,
									0.092,
									0.007
								],
								"e": 0.023
							},
							{
								"h": [
									0.015,
									0.003,
									0.009,
									0.085,
									0.001,
									0.004,
									0.003,
									0.019,
									0.003,
									0.003,
									0.039,
									0.002
								],
								"e": 0.012
							},
							{
								"h": [
									0.007,
									0.002,
									0.003,
									0.006,
									0.001,
									0.11,
									0.004,
									0.006,
									0.011,
									0.028,
									0.003,
									0.008
								],
								"e": 0.011
							},
							{
								"h": [
									0.113,
									0.011,
									0.006,
									0.018,
									0.013,
									0.004,
									0.006,
									0.005,
									0.194,
									0.009,
									0.008,
									0.002
								],
								"e": 0.017
							}
						],
						[
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							},
							{
								"h": [
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0,
									0.0
								],
								"e": 0.0
							}
						]
					]
				}
			]
		}
	]
}